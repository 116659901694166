@import "~StyleVariables";

.ReportcenterContent {
	position: relative;
	overflow-y: auto;
	height: 100%;
	display: flex;
	flex-direction: column;

	&__header {
		z-index: 1004;
		display: flex;
		flex-wrap: nowrap;
		padding: 10px 15px;
		height: 60px;

		& > .Column {
			align-self: center;
		}
	}

	&__title {
		flex-flow: column;
		flex: 0 0 auto;
		max-width: 300px;

		.Text,
		.Title {
			text-overflow: ellipsis;
			white-space: nowrap;
			width: auto;
			overflow: hidden;
		}

		.DropDownMenu {
			display: block;
		}
	}

	&__filters {
		padding-left: 10px;
	}

	&__actions {
		flex: 0 0 auto;

		.Icon {
			font-size: 14px;
		}
	}

	&__filter-toggle {
		border: none !important;
		text-shadow: none !important;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: 5px;
		transition: all ease-in-out 0.2s;

		.Text {
			margin-left: 5px;
		}

		.Icon,
		.Text {
			transition: color ease-in-out 0.2s;
		}

		&:hover {
			.Icon,
			.Text {
				color: $grey-13;
			}
		}
	}
	&__save-col {
		padding-left: 10px;

		& > div {
			opacity: 0;
			visibility: hidden;
			margin-left: -10px;
			transition: $transition-small-bounce;
		}
		&--visible {
			& > div {
				opacity: 1;
				visibility: visible;
				margin-left: 0;
			}
		}
	}

	&__save-options {
		display: flex;
		align-items: center;
	}

	&__textContainer {
		display: flex;
		align-items: center;

		&--allowEdit {
			&:hover {
				.ReportcenterContent__editIcon {
					opacity: 1;
					transform: translateX(0);
				}

				.ReportcenterContent__headerDescription {
					.Icon {
						transform: translateX(0);
					}
				}
			}
		}
	}

	&__headerDescription {
		font-size: 14px;
		&--allowEdit {
			.Icon {
				transform: translateX(-20px);
				transition: $transition-small-bounce;
			}
		}
	}

	&__editIcon {
		opacity: 0;
		cursor: pointer;
		transform: translateX(-5px);
		transition: $transition-small-bounce;
	}

	&__dashboardName,
	&__dashboardDescription {
		&--allowEdit {
			cursor: pointer;
		}
	}

	&__schedule-email {
		&--blue-icon {
			.Icon {
				color: $bright-blue;
			}
		}
		&--grey-icon {
			.Icon {
				color: $grey-9;
			}
		}
	}

	&__schedule-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;
		margin-left: -4px;

		.schedule-button-text {
			padding: 4px 8px;
		}
	}
	&__dashboard-options {
		.DropDownMenu__content {
			margin-top: 4px;
		}
		.TableRow {
			height: 50px;
		}
	}
}
