@import "../../../../styles/variables.scss";

.ClientSignals {
	&__mail {
		line-height: 16px;
	}
	.Table {
		.TableHeader__column:last-child {
			text-align: right;
			padding-right: 8 * $space;
		}

		.Icon {
			cursor: pointer;
		}
	}
}
