@keyframes animate_pulse_animation {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.5;
	}
}
@keyframes animate_pulse_scale {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.15);
	}
	100% {
		transform: scale(1);
	}
}
.Animate {
	--delay: 0ms;
	--delay-out: 0ms;
	--max-height: 0px;
	$transitionTime: 300ms;
	$transitionTimeSlow: 800ms;
	$transitionTimeFast: 100ms;

	transition-property: all;
	transition-delay: var(--delay-out);
	transition-timing-function: cubic-bezier(0.8, 1.4, 0.64, 1);

	pointer-events: none;
	&--visible {
		transition-delay: var(--delay);
		pointer-events: unset;
	}

	&--speed-slow {
		transition-duration: $transitionTimeSlow;
	}

	&--speed-normal {
		transition-duration: $transitionTime;
	}

	&--speed-fast {
		transition-duration: $transitionTimeFast;
	}

	&--bounce {
		transition-timing-function: cubic-bezier(0.8, 1.56, 0.8, 1);
	}

	&--height {
		max-height: 0px;
		&.Animate--visible {
			max-height: var(--max-height);
		}
	}

	&--pulse,
	&--pulseScale {
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	&--pulse {
		animation-name: animate_pulse_animation;
	}
	&--pulseScale {
		animation-name: animate_pulse_scale;
	}
	&--disabled {
		transition-property: none;
		animation: none;
	}
	&--fade {
		opacity: 0;
		visibility: hidden;
	}
	&--fade {
		&.Animate--visible {
			opacity: 1;
			visibility: visible;
		}
	}

	$slideStart: 20%;
	$slideStartComplete: 100%;
	$slideDirections: "top", "right", "bottom", "left";

	&--slide-bottom {
		transform: translateY($slideStart);

		&-complete {
			transform: translateY($slideStartComplete);
		}
	}

	&--slide-top {
		transform: translateY($slideStart * -1);

		&-complete {
			transform: translateY($slideStartComplete * -1);
		}
	}

	&--slide-left {
		transform: translateX($slideStart * -1);

		&-complete {
			transform: translateX($slideStartComplete * -1);
		}
	}

	&--slide-right {
		transform: translateX($slideStart);

		&-complete {
			transform: translateX($slideStartComplete);
		}
	}
	@each $direction in $slideDirections {
		&--slide-#{$direction},
		&--slide-#{$direction}-complete {
			&.Animate--visible {
				transform: translateY(0%) translateX(0%);
			}
		}
	}
}
