.FloatingSaveButton {
	position: fixed;
	visibility: hidden;
	z-index: 9999;
	left: 50%;
	bottom: -50px;
	margin-left: -150px;
	transition: all cubic-bezier(0.68, -0.55, 0.37, 2) 300ms;
}

.FloatingSaveButton .Button {
	width: 300px;
}

.FloatingSaveButton--visible {
	visibility: visible;
	bottom: 30px;
}
