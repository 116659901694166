@import "~StyleVariables";

.PlanPhonecallsDrawer--prospecting {
	width: 100%;

	.PlanPhonecallsDrawer__content {
		background-color: transparent;
		height: auto;
		overflow: visible;
	}
	.TodoTimePicker {
		margin-top: 10px;
		width: calc(100% - 10px);
	}
	.PlanPhonecallsDrawer__title {
		display: flex;
		align-items: center;

		.Toggle {
			margin-right: 20px;
			margin-bottom: 5px;
		}
	}

	.PlanPhonecallsDrawer__toggle-row {
		animation: fadeIn 400ms;
	}
}
