@import "~StyleVariables";

.TicketInfo {
	width: 420px;
	height: fit-content;

	display: flex;
	flex-direction: column;
	gap: $space * 5;
	border-left: $border;

	.ClickableItem {
		&__title-col {
			overflow: hidden;
		}
	}

	.Icon-edit, .Icon-user-plus {
		color: $grey-8;
	}

	&__visitTooltip {
		max-width: 300px;
	}

	&__matchesFoundBox {
		width: 390px;
		border: none;
	}

	&__emailOnTicket {
		height: 52px;

		display: flex;
		align-items: center;

		margin-left: 32px;

		.Row {
			align-items: center;
		}
	}

	&__suggestedDropdown {
        position: static; // To position the dropdown correctly relative to the suggestion box
    }

	&__visitContact {
		width: 100%;
		overflow: hidden;
	}

	&__changeContact {
		align-self: center;
	}

	&__addEmail {
		width: 100%;
	}

	hr {
		margin: 0px;
		border-color: $grey-6;
	}

	.inline-confirm-container {
		right: 225px;
		top: 70px;
	}

	&__contact,
	&__client {
		width: 100%;
	}

	&__align {
		padding-left: 70px;
	}

	&__accountManagers {
		display: flex;
		align-items: center;
	}

	&__delete {
		.inline-confirm-modal {
			width: 100%;
		}
		.inline-confirm-modal-hidden {
			width: 100%;
		}
	}

	&__tickets {
		.ButtonSelect {
			width: 100%;
		}

		.ClickableItem {
			border-top: $border;
		}
	}

	.CustomFields {
		margin-bottom: unset;

		&__content {
			display: flex;
			flex-direction: column;
			gap: $space * 4;
		}

		.FormComponent__message {
			display: none;
		}
	}

	.Icon-email {
		width: 14px;
	}

}
