@import "~StyleVariables";

.AppointmentSidebarCalendar {
	&__header {
		position: sticky;
		top: 0;
		z-index: 4;
		background-color: $white;
	}

	.CalendarView__CalendarWrapper {
		flex: 1;
	}
}
