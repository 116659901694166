@import "~StyleVariables";

.SalesCoachTable {
	margin: 30px;
	border-radius: 4px;
	max-width: 840px;

	&__header {
		display: flex;
		justify-content: space-between;
		padding: 20px;
	}

	.TableRow--inactive {
		.Icon {
			color: $grey-8;
		}
	}

	.TableColumn:last-child {
		width: 170px;
		padding-right: 15px;
	}

	.TableColumn {
		cursor: pointer;

		.Tooltip {
			float: left;
		}
	}

	&__actions {
		display: flex;
		justify-content: space-between;
	}

	&__toggle {
		display: flex;
		padding-right: 20px;

		.Text {
			margin-left: 10px;
		}
	}
	.roleBoxes {
		> span {
			align-items: center;
			background: rgba($bright-blue, 0.2);
			color: $bright-blue;
			padding: 3px 5px;
			margin-right: 4px;
			font-size: 10px;
			border-radius: $border-radius;
		}
		.Text {
			font-size: 10px;
			display: contents;
		}
	}

	.userAvatars {
		> div {
			.Avatar {
				box-shadow: 0 0 0 2px $white;
				vertical-align: bottom;
			}

			.Tooltip {
				position: relative;
				z-index: 50;
			}
			.Tooltip:nth-child(2) {
				position: relative;
				left: -5px;
				z-index: 51;
			}
			.Tooltip:nth-child(3) {
				position: relative;
				left: -10px;
				z-index: 52;
			}
		}
		.Text {
			display: inline;
			font-size: 10px;
			position: absolute;
			left: 70px;
		}
	}
}
