.CreateTemplateFlow {
	&__tabContent {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.CreateTemplateFlowParticipants {
		margin-top: 60px;
	}
}
