@import "~StyleVariables";

.SplitSubscriptionButton {
	width: 950px;
	margin-top: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: $border-radius;
	cursor: pointer;
	border: 1px solid $grey-6;

	> div {
		padding: 12px;
	}

	img {
		margin-bottom: -4px;
		padding: 4px;
	}

	transition-property: box-shadow, background-color;
	transition-timing-function: ease;
	transition-duration: 200ms;

	&:hover:not(&--disabled) {
		box-shadow: $box-shadow-xl;
	}

	&--disabled {
		cursor: unset;
		background-color: $grey-1;
	}
}
