@import "~StyleVariables";

.SalesProcessToolTip {
	&__checklist {
		display: flex;
		align-items: center;
		height: 100%;
		background-color: $grey-3;
		border-radius: 4px;
		padding: 2px 6px;

		&--allGood {
			background-color: $super-light-green;
			color: $green;
		}
	}

	&__checklistItems {
		.Text {
			display: inline;
		}
	}
}
