@import "~StyleVariables";

.BarchartWidget {
	$firstColWidth: 20%;
	$chartBaseLeft: 15px;
	overflow-x: hidden;
	position: relative;

	&--loading {
		overflow-y: hidden;
	}

	&__bar-wrap {
		position: relative;
	}

	&--loading.BarchartWidget--large,
	&--has-data.BarchartWidget--large {
		&:before {
			content: "";
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			width: $firstColWidth;
			background-color: $grey-1;
		}
	}

	.Table {
		table-layout: fixed;

		.TableColumn:first-of-type {
			.Text:not(.BarchartWidget__noResultRowText) {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				text-transform: capitalize;
			}
		}
	}

	&__row {
		transition: all ease-in-out 200ms;

		.TableColumn {
			border: none;
			padding: 4px 15px;
			transition: all ease-in-out 200ms;
		}

		&--large {
			.TableColumn {
				padding-top: 0;
				padding-bottom: 0;
				&:first-of-type {
					width: $firstColWidth;
				}
			}
		}

		&--loading {
			.Skeleton:before {
				content: "";
				display: block;
				height: 12px;
				width: 50%;
			}
			&:nth-child(2n) {
				.TableColumn:first-of-type .Skeleton:before {
					width: 80%;
				}
				.BarchartWidgetBar {
					width: 60% !important;
				}
			}

			.BarchartWidget__small-row-header {
				.Column--align-right .Skeleton:before {
					float: right;
				}
			}

			.BarchartWidgetBar {
				width: 83% !important;
			}
		}

		&:hover {
			cursor: pointer;
			.TableColumn {
				background-color: $grey-1;
			}
		}
		&--large:hover .TableColumn:first-of-type {
			background-color: $grey-2;
		}
	}

	&__goal-label {
		position: sticky;
		padding: 0 15px 0 $firstColWidth;
		height: 5px;
		top: 0;
		z-index: 1001;
		left: 0;
		right: 0;
		transition: box-shadow ease-out 200ms;

		&--scrolled {
			box-shadow: 0 3px 6px rgba($black, 0.2);
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: $white;
		}

		&-inner {
			position: relative;
			margin-left: $chartBaseLeft;
		}

		.Text {
			position: absolute;
			top: 0;
			margin-left: -3px;
		}
	}

	&--large {
		.BarchartWidget__goal-label {
			height: 20px;
		}
	}

	&__small-row-header .Column {
		height: 14px;
		overflow: hidden;

		.Text {
			line-height: 14px;
		}
	}

	.TableRow {
		.BarchartWidget__barchartColumn {
			&--padding-top {
				padding-top: 6px;
			}
		}
	}
}
