@import "~StyleVariables";

.MailOpenClickIndicator {
	&__read-click .Icon {
		font-size: 12px;
	}

	&__actions {
		display: flex;
		align-items: center;
		.RelatedButtons {
			margin-right: 4px;
			.Button {
				height: 32px;
			}
		}
	}

	&__container {
		display: flex;
		align-items: center;
	}

	&__container > * {
		margin-right: $space * 2;
	}

	&__event {
		.Icon {
			background-color: $grey-3;
			padding: $space $space * 2;
			color: $grey-10;
			&:hover {
				color: $grey-11;
			}
		}

		&__read {
			border-radius: $space 0px 0px $space;
			&--hasRead {
				transition: all 0.2s ease-out;
				color: $success-6 !important;
				background-color: $success-2 !important;
				&:hover {
					background-color: $success-3 !important;
				}
			}
		}
		&__click {
			border-radius: 0px $space $space 0px;
			&--hasClicked {
				transition: all 0.2s ease-out;
				color: $success-6 !important;
				background-color: $success-2 !important;
				&:hover {
					background-color: $success-3 !important;
				}
			}
		}
	}
}
