@import "~StyleVariables";

.RelatedButtons {
	&__double-icon {
		height: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;
		flex-direction: column;
		&__icons {
			height: initial;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 8px;

			&--large {
				font-size: 16px;
			}
		}

		.Tooltip {
			display: flex !important;
			align-items: center;
		}

		.Tooltip:hover {
			cursor: pointer;
		}

		.Button {
			height: 16px;
		}

		.Text {
			width: 100%;
		}
	}

	&__subtitle,
	&__ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.Column {
		display: flex;
		flex-direction: column;
	}

	&__full {
		border-radius: 4px;
		padding: 2px 4px;
		flex-wrap: nowrap;
		max-width: 100%;

		&:hover {
			background-color: $grey-2;
			color: $grey-10;
		}

		&__icon-column {
			flex-grow: 0;
			justify-content: center;
		}

		// Staircase of max-widths is needed for text ellipsis to apply properly
		&__text-column {
			max-width: 90%;
			.Text {
				max-width: 100%;
			}
		}
	}

	.Button {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		max-width: 100%;
		.Text {
			max-width: 100%;
		}
	}
	.Button:hover {
		background-color: $grey-2;
		color: $grey-10;
	}
}
