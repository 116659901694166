.JourneyStatusesPlaceholder {
	min-height: 155px;
	margin-bottom: 20px;
}

.JourneyStatusesPlaceholder .CardContent {
	padding-right: 275px;
}

.JourneyStatusesPlaceholder__img-wrapper {
	display: flex;
	position: absolute !important;
	top: -20px;
	right: 120px;
	height: 100%;
}
.JourneyStatusesPlaceholder__img {
	transform: translateX(50%);
	height: 100px;
	margin: auto;
}
