@import "~StyleVariables";

// Merge with old stype below when released to all customers
#edit-order-modal.fresh {
	$padding: 15px;
	.up-modal-header {
		padding: 0px;
		display: flex;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		.header-info-wrapper {
			padding: 0px 20px;
			width: 60%;
		}
		.risk-chip {
			display: flex;
			align-items: center;

			.RiskChip {
				padding-left: 12px;
			}
		}
		h2 {
			right: 270px;

			@media (min-width: 1010px) {
				& {
					right: 370px;
				}
			}
		}
	}

	.TicketWidget {
		box-shadow: none;
		margin: 0 -14px;
		border: none;
		border-radius: 0;
	}

	.OpportunityTodos {
		margin-top: 27px;
	}

	.CommentInput {
		margin-top: 27px;
	}

	.OpportunityWinLossButtons,
	.save-button-wrapper,
	.MoveSubscriptionButtonContainer {
		display: inline-block;
	}

	.footer-button-container {
		margin-right: 10px;

		> .up-btn {
			margin-left: 0;
		}
	}

	.no-border {
		border: 0;
	}

	// Timeline overrides to fit with new todo top-section
	table.timeline.has-todo {
		& > tbody > tr > td {
			padding-left: 0;

			.details-table-arrow {
				left: -10px;
			}
		}

		.icon-td {
			padding-top: 16px;
			padding-left: 5px;

			.line {
				top: 0;
				left: 19px;
				bottom: -16px;
				height: initial;
			}
		}

		.timeline-cat-divider {
			height: 30px;
			padding: 0;

			.icon-td {
				padding-top: 0;
			}

			.title {
				padding-top: 0;
			}
		}
	}

	&.sticky-btn {
		ul.tabs > li.add-row-sticky {
			@include opacity(1);
			visibility: visible;
			margin-top: 0;
		}
	}

	ul.tabs {
		background-color: $grey-4;
		border-bottom: none;
		height: 40px;

		& > li {
			& > a {
				border-bottom: none;
				border-top: 3px solid transparent;
				height: 40px;
				line-height: 37px;
				color: $grey-10;
				padding: 0 30px;
				@include transition(all ease 200ms);

				&:hover {
					color: $blue;
					text-decoration: none;
				}

				&.current {
					border-top: 4px solid $green;
					color: $green;

					&.darker {
						background-color: $grey-2;
					}
				}
			}

			&.add-row-sticky {
				padding: 6px 15px;
				@include transition(all ease 200ms);
				@include opacity(0);
				visibility: none;
				margin-top: 10px;
			}
		}
	}

	.up-modal-content {
		top: $modal-header-height;
		// Same height as $controls-height in _modal_scss since the scroll gets hidden behind the control bar otherwise
		margin-bottom: 57px;

		&.account-selected {
			.hide-when-account {
				display: none !important;
			}

			.show-when-account {
				display: block !important;
			}
		}

		.notes-root {
			left: $modal-sidebar-width;
			position: absolute;
			top: 40px;
			bottom: 0;
			right: 0;
		}

		&.has-tabs {
			.modal-center {
				padding-top: 55px;
			}
		}

		.modal-sidebar {
			.up-rating-label {
				position: absolute;
				top: 3px;
				right: 3px;
			}

			#select-account-section {
				button.change-company {
					position: absolute;
					right: 3px;
					top: 13px;
				}

				& .onHover {
					visibility: hidden;
					transition: all ease 200ms;
					opacity: 0;
					position: absolute;
					right: 0;
					top: 13px;
				}

				&:hover .onHover {
					visibility: visible;
					opacity: 1;
				}
			}

			.sidebar-account-title {
				margin-top: 3px;
				margin-right: 25px;
			}

			.sidebar-contact-select-wrap {
				padding-bottom: 0;
			}

			.sidebar-contact-section {
				padding-bottom: 40px;
			}

			.relations-signal-widget-container {
				margin: 0 -8px 0 -8px;
			}

			.dashed-divider {
				display: block;
				clear: both;
				border-bottom: 2px dashed $grey-10;
				padding-top: 7px;
				margin-bottom: 7px;
			}

			#links-section {
				ul {
					list-style-type: none;
					padding-left: 0;
					margin-left: 0;
				}
			}

			.order-calc-row {
				display: block;

				.row-left,
				.row-right {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 50%;
					display: inline-block;
					float: left;
					font-size: 11px;
					line-height: 18px;

					&.bold {
						font-weight: $boldFontWeight;
					}

					&.large {
						font-size: 15px;
						line-height: 28px;
					}
				}

				.row-right {
					text-align: right;
				}
			}

			.sidebar-section {
				.up-rating-label {
					display: inline-block;
					vertical-align: top;
				}

				.indicator-wrapper {
					display: inline-block;
					margin-right: 10px;
					margin-top: 3px;
				}

				.select2-dropdown-open {
					width: 400px;
					z-index: 1002;
				}
			}
		}

		.modal-center {
			#order-files {
				margin-top: -15px;
				margin-left: -15px;
				margin-right: -15px;
			}

			.widget-area {
				display: flex;
				background-color: $white;
				@include box-shadow(0 1px 2px rgba($black, 0.1));
				margin-bottom: 10px;
				height: 60px;
				margin-left: -$padding;
				margin-right: -$padding;
				margin-top: -$padding;

				.up-widget {
					position: relative;
					height: 100%;
					padding: 10px 20px;
					font-size: 12px;
					width: 25%;

					.widget-title {
						color: $grey-10;
						display: block;
					}

					.widget-content {
						display: block;
						font-size: 14px;
						margin-top: 5px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}

					.date-picker-fix {
						position: absolute;
						width: 100%;
						height: 100%;
						@include opacity(0);
						left: 0;
						top: 0;
						cursor: pointer;
					}
				}

				.click-disabled {
					background-color: $grey-4;
					color: $grey-11;
					font-style: italic;
					cursor: default;
				}

				.danger {
					background-color: $super-light-red;
				}

				.days-diff {
					font-style: italic;
					color: $grey-8;
					font-size: 12px;
				}

				.red {
					color: $red;
				}
			}

			.timeline {
				.title {
					h2 {
						margin-top: 0;
					}
				}

				.line {
					top: 42px;
				}
			}

			.row-wrapper {
				width: 100%;
				float: left;

				.fa-info-circle {
					margin-left: 4px;
				}
			}

			.modal-section-divider {
				background-color: $grey-2;
				margin-left: -$padding;
				margin-right: -$padding;
				margin-top: $padding;
				margin-bottom: $padding;
				padding: 11px $padding;
				position: relative;

				&.no-margin-bottom {
					margin-bottom: 0;
				}

				h3 {
					color: $black;
					margin: 0;
					padding: 0;
					font-size: 22px;
					display: inline-block;
					font-weight: $boldFontWeight;
				}

				button {
					position: absolute;
					right: $padding;
					top: 5px;
				}
			}

			.order-rows {
				margin: 0;
				margin-left: -$padding;
				margin-right: -$padding;

				table.main-table {
					background-color: transparent;

					& > tbody > tr:hover > td,
					& > tbody > tr > td {
						border-left: none;
						border-right: none;
					}

					thead {
						@media (max-width: 767px) {
							& {
								display: none;
							}
						}
					}
				}
			}

			#form-notes {
				margin-left: -$padding;
				margin-right: -$padding;
				margin-bottom: -$padding;

				#notes {
					background-color: $white;
					border: none;
					outline: none;
					width: 100%;
					padding: $padding;
				}
			}
		}

		.hide-when-account {
			display: block !important;
		}

		.show-when-account {
			display: none !important;
		}

		#select-account-cover {
			position: absolute;
			top: 0;
			bottom: 0;
			left: $modal-sidebar-width;
			right: 0;
			background-color: $grey-1;
			z-index: 1001;
			padding: 41px 20px 0 193px;
			color: $grey-10;
			font-size: 20px;
		}
	}
}

#edit-order-modal {
	height: 100%;

	.NumberInput {
		.Input {
			width: 100%;
		}
	}

	.AgreementModal__tabs {
		position: absolute;
		top: 45px;
		z-index: 1001;
		left: 0;
		right: 0;
		height: 50px;

		.ColorSwitcher {
			display: inline-block;
			margin-right: 4px;
		}
	}

	.EditAgreementForm {
		.col-md-6 {
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	ul.tabs {
		margin-top: -1px;
	}

	.calc-field,
	.order-field {
		.calculation-field-value {
			height: 100%;
			display: flex;
			align-items: center;

			&:before {
				color: $grey-8;
				content: "⨍(x)";
				font-family: FontAwesome;
				position: absolute;
			}
			&:not(.hide-icon):after {
				content: "\f059";
				font-family: FontAwesome;
				position: absolute;
			}

			& > span {
				min-width: 0;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.calc-field {
		background-color: $white;
		border: 1px solid $grey-8;
		border-radius: 2px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 3px 0 0 0 $grey-8;
		width: 100%;
		height: 26px;
		.calculation-field-value {
			padding-left: 35px;
			&:before {
				left: 8px;
			}
			&:after {
				right: 5px;
			}
			& > span {
				padding-right: 20px;
			}
		}
	}

	.order-field {
		.calculation-field-value {
			padding-left: 25px;
			line-height: 21px;

			&:before {
				left: 24px;
			}
			&:after {
				right: 20px;
			}
			& > span {
				padding-right: 4px;
			}
		}
	}

	.price.calc-field {
		background-color: $white;
		border: 1px solid $grey-8;
		border-radius: 4px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), inset 3px 0 0 0 $grey-8;
		width: 16.6%;
		height: 36px;

		.calculation-field-value {
			span {
				width: 80%;
				overflow: hidden;
			}
		}
	}

	#form-add-order-row,
	#form-search-products {
		margin-top: 12px;
	}

	#form-search-products {
		margin-left: 8px;
	}

	.order-rows th {
		border-top: none;
		border-left: none;
		border-right: none;
		height: 24px;
		font-size: 11px;

		.price {
			padding-right: 10px;
			text-align: right;
		}

		.discount {
			text-align: right;
		}

		.total {
			text-align: right;
			padding-right: 13px;
		}

		.total-cm {
			text-align: left;
		}
	}

	.order-rows tr.sum-row {
		td {
			border: none;
			cursor: default;

			table.sum-table {
				float: right;
				text-align: right;
				margin-right: 23px;
				width: auto;
				margin-top: 10px;

				tr {
					& > td {
						border: none;
						padding-left: 30px;
						line-height: 15px;

						&:hover {
							border: none;
						}

						&.large {
							line-height: 25px;
							font-size: 16px;
						}

						&.large,
						&.bold {
							font-weight: $boldFontWeight;
						}
					}
				}
			}
		}

		&:hover td {
			background-color: initial;
		}
	}

	.up-modal-content {
		top: $modal-header-height + $sub-nav-height;
		padding: 0;

		&.no-nav-bar {
			top: $modal-header-height;
		}

		.up-panel {
			padding: 15px;

			.up-panel-content {
				// margin-bottom: 58px;

				table#dashboard-table tr {
					td {
						// HALF WIDTH TD
						&.half {
							width: 50%;
							vertical-align: top;
							padding: 10px;

							&:first-child {
								border-right: 1px solid $grey-4;
							}

							h2 {
								margin-bottom: 10px;
								margin-top: 100px;

								&.order-value {
									font-size: 36px;
									letter-spacing: 1px;
								}
							}

							.value-label {
								font-size: 12px;
								display: block;
							}

							.value {
								font-size: 16px;
								display: inline-block;
								line-height: 25px;
								height: 25px;
								vertical-align: top;

								&.no-value {
									color: $grey-10;
								}
							}

							.created-by {
								font-size: 12px;
								display: block;
								margin-top: 10px;
								color: $grey-10;
							}

							img.gravatar {
								@include border-radius(25px);
								border: 1px solid $grey-8;
								display: inline-block;
							}

							.order-date {
								font-size: 16px;
							}

							.stage-indicator-wrap {
								height: 20px;
								display: inline-block;
								vertical-align: top;
								margin-right: 5px;
								margin-bottom: 10px;

								&.signed {
									.stage-indicator {
										background-color: $green !important;
									}
								}

								.stage-indicator {
									width: 10px;
									height: 100%;
									display: inline-block;
									margin-left: 2px;
									background-color: $grey-4;

									&:last-child {
										margin-right: 0;
									}

									&.filled {
										background-color: $link-color;
									}
								}
							}
						}
						&.left {
							width: 25%;
							vertical-align: top;
							padding: 10px;

							&:first-child {
								border-right: 1px solid $grey-4;
							}

							h2 {
								margin-top: 5px;
								height: 25px;

								&.order-value {
									font-size: 36px;
									letter-spacing: 1px;
								}
							}
							h3 {
								font-size: 18px;
								font-weight: normal;
								margin-bottom: 4px;
							}
							.order-name {
								font-size: 15px;
								margin-bottom: 10px;
							}
							.email-field {
								margin-bottom: 15px;
								padding-bottom: 5px;
							}
							.edit-order-sidebar-item {
								margin-bottom: 1px;
							}
							.dimmed {
								color: $grey-8;
							}
							.small-text {
								font-size: 13px;
							}
							.list-unstyled {
								list-style: none;
							}
							.timeline-list {
								position: relative;
								min-height: 40px;
								padding: 0 0 0 50px;
							}

							.link-color {
								color: $bright-blue;
							}
							.timeline-image {
								top: 0px;
								left: 0px;
								position: absolute;
							}
							.wrapper {
								margin-top: 100px;
								width: 900px;
								display: block;
								margin-left: auto;
								margin-right: auto;
								height: 300px;
							}
							.up-modal-header {
								width: 900px;
								display: block;
								border-bottom: 1px solid $grey-8;
								background-color: $blue;
								padding-left: 10px;
								height: 46px !important;
								line-height: 46px !important;
							}
							.modal-title {
								margin: 0;
								font-weight: $regularFontWeight;
								color: $white;
								font-size: 20px;
								line-height: 46px !important;
								height: 46px !important;
							}
							.up-widget {
								display: inline-block;
								width: 147px;
								height: 65px;
								padding: 5px;
								margin: 0 10px 10px 0;
								vertical-align: top;
								border: 1px solid $grey-8;
								border-top: 3px solid $bright-blue;
								color: $black;
								background-color: $white;
								-webkit-box-shadow: 0 0 10px $grey-4;
								box-shadow: 0 0 10px $grey-4;
							}

							.widget-title {
								text-align: center;
								display: block;
								border-bottom: 1px solid $grey-4;
							}
							.widget-content {
								text-align: center;
								font-size: 16px;
								display: block;
								margin-top: 5px;
							}
							.value-label {
								font-size: 14px;
								display: block;
								margin-bottom: 10px;
							}

							.value {
								font-size: 16px;
								display: inline-block;
								line-height: 25px;
								height: 25px;
								vertical-align: top;

								&.no-value {
									color: $grey-10;
								}
							}

							.created-by {
								font-size: 12px;
								display: block;
								margin-top: 10px;
								color: $grey-10;
							}

							img.gravatar {
								@include border-radius(25px);
								border: 1px solid $grey-8;
								display: inline-block;
							}

							.client-phone {
								font-size: 14px;
								color: $link-color;
								margin-top: 5px;
								margin-bottom: 5px;
							}
							.client-city {
								font-size: 14px;
								margin-bottom: 5px;
							}
							.client-total-sales {
								font-size: 14px;
								color: $link-color;
								margin-bottom: 25px;
								display: inline-block;
							}
							.card {
								margin-bottom: 25px;
							}
							.stage-indicator-wrap {
								height: 20px;
								display: inline-block;
								vertical-align: top;
								margin-right: 5px;
								margin-bottom: 10px;

								&.signed {
									.stage-indicator {
										background-color: $green !important;
									}
								}

								.stage-indicator {
									width: 10px;
									height: 100%;
									display: inline-block;
									margin-left: 2px;
									background-color: $grey-4;

									&:last-child {
										margin-right: 0;
									}

									&.filled {
										background-color: $link-color;
									}
								}
							}
						}
						&.right {
							width: 75%;
							vertical-align: top;
							padding: 0px !important;

							.modal-section {
								padding: $padding-horizontal;
							}

							.widget-area {
								border-bottom: 1px solid $grey-8;
								margin-bottom: 10px;
							}

							.timeline-section {
								padding: 20px 10px;
								background-color: $grey-2;
								border-top: 1px solid $grey-4;
							}

							.test {
								@include widget-card();
								border: 1px solid $grey-8;
								height: 55px;
								width: 147px;
								padding: 5px;
							}

							.date-picker-fix {
								position: absolute;
								width: 100%;
								height: 100%;
								@include opacity(0);
								left: 0;
								top: 0;
							}

							.icon {
								position: relative;
								color: $grey-8;
								-khtml-border-radius: 40px;
								border-radius: 40px;
								-webkit-background-clip: padding-box;
								background-clip: padding-box;
								background-color: $white;
								border: 1px solid $grey-8;
								width: 40px;
								height: 40px;
								text-align: center;
								font-size: 25px;
								padding-left: 3px;
							}

							.schedule-header {
								margin-bottom: 20px;
							}
							.activity-list-header {
								margin-bottom: 15px;
							}
							.timeline-time {
								color: $grey-6;
							}
							.hostiry-card {
								margin-bottom: 10px;
							}
							.dimmed {
								color: $grey-8;
							}
							.small-text {
								font-size: 13px;
							}
							.list-unstyled {
								list-style: none;
							}
							.timeline-list {
								position: relative;
								min-height: 40px;
								padding: 0 0 0 50px;
							}
							.product-list {
								list-style: none;
								display: inline;
							}
							.link-color {
								color: $bright-blue;
							}
							.timeline-image {
								top: 0px;
								left: 0px;
								position: absolute;
							}
							.wrapper {
								margin-top: 100px;
								width: 900px;
								display: block;
								margin-left: auto;
								margin-right: auto;
								height: 300px;
							}
							.up-modal-header {
								width: 900px;
								display: block;
								border-bottom: 1px solid $grey-8;
								background-color: $blue;
								padding-left: 10px;
								height: 46px !important;
								line-height: 46px !important;
							}
							.modal-title {
								margin: 0;
								font-weight: $regularFontWeight;
								color: $white;
								font-size: 20px;
								line-height: 46px !important;
								height: 46px !important;
							}
							.up-widget {
								font-size: 12px;
								position: relative;
								display: inline-block;
								width: 147px;
								height: 60px;
								padding: 10px;
								margin: 0 10px 10px 0;
								vertical-align: top;
								color: $black;
								background-color: $white;
								box-shadow: none;
							}

							.widget-title {
								display: block;
								text-transform: uppercase;
								font-size: 12px;
								color: rgba($black, 0.5);
							}

							.widget-content {
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								font-size: 16px;
								display: block;
								margin-top: 5px;
							}

							width: 99%;
							vertical-align: top;
							padding: 10px;

							&:first-child {
								border-right: 1px solid $grey-4;
							}

							h2 {
								margin-bottom: 10px;
								margin-top: 5px;
								font-size: 18px;
								&.order-value {
									font-size: 36px;
									letter-spacing: 1px;
								}
							}

							.value-label {
								font-size: 14px;
								display: block;
								margin-bottom: 10px;
							}

							.value {
								font-size: 16px;
								display: inline-block;
								line-height: 25px;
								height: 25px;
								vertical-align: top;

								&.no-value {
									color: $grey-10;
								}
							}

							.created-by {
								font-size: 12px;
								display: block;
								margin-top: 10px;
								color: $grey-10;
							}

							img.gravatar {
								@include border-radius(25px);
								border: 1px solid $grey-8;
								display: inline-block;
							}

							.client-phone {
								font-size: 14px;
								color: $link-color;
								margin-top: 5px;
								margin-bottom: 5px;
							}
							.client-city {
								font-size: 14px;
								margin-bottom: 5px;
							}
							.client-total-sales {
								font-size: 14px;

								margin-bottom: 25px;
								display: inline-block;
							}
							.card {
								margin-bottom: 25px;
							}
							.stage-indicator-wrap {
								height: 20px;
								display: inline-block;
								vertical-align: top;
								margin-right: 5px;
								margin-bottom: 10px;

								&.signed {
									.stage-indicator {
										background-color: $green !important;
									}
								}

								.stage-indicator {
									width: 10px;
									height: 100%;
									display: inline-block;
									margin-left: 2px;
									background-color: $grey-4;

									&:last-child {
										margin-right: 0;
									}

									&.filled {
										background-color: $link-color;
									}
								}
							}
						}

						// FULL WIDTH TD
						&.full {
							h2 {
								margin: 10px;
								display: inline-block;
							}

							.add-activity {
								font-size: 12px;
								line-height: 43px;
								vertical-align: bottom;
							}

							#add-comment-wrapper {
								$height: 35px;
								position: relative;
								display: inline-block;
								height: $height;

								#add-comment-gravatar {
									position: absolute;
									top: 1px;
									left: 1px;
									border: 0;
									margin: 0;
								}

								#add-comment {
									height: $height;
									display: inline-block;
									width: 300px;
									padding-left: $height + 10px;
									margin: 0;
									@include border-radius(0);
								}
							}
						}
					}
				}

				.order-rows {
					table {
						thead {
							th {
								&.product {
									width: 50%;
								}

								&.price {
									width: 16%;
								}

								&.total {
									width: 18%;
									text-align: right;
								}
							}
						}
						tfoot {
							td {
								padding: 5px 10px;
								text-align: right;
								vertical-align: bottom;

								&.add {
									vertical-align: top;
									text-align: left;
								}

								&.convert-rate {
									padding: 0 9px 5px 9px;
									width: 20px;
									min-width: 38px;
								}
							}
						}
					}
				}

				#order-files {
					padding: 0 10px 10px 10px;
					background: $grey-1;
				}
			}
		}
	}

	.no-transition {
		transition: none !important;
	}
}

.right-aligned-btn {
	position: absolute;
	top: 2px;
	right: 10px;
}

.link-color {
	color: $bright-blue;
}

#next-order-date {
	display: block;
	font-size: 22px;
}
