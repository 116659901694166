.OptOutModal {
	&__add-active,
	&__add-language {
		padding: 20px;
		line-height: 17px;
	}
	&__add-language-select {
		margin-top: 5px;
		width: 240px;
	}
	&__warning {
		display: flex;
		margin-top: 8px;
		margin-bottom: 8px;

		.Icon {
			margin-right: 8px;
			margin-top: 8px;
		}
	}
	&__related-content {
		margin-top: 8px;
		margin-bottom: 8px;

		.Text {
			margin-top: 8px;
		}
	}
}
