.AdminLoginStats__content {
	padding: 30px 30px 80px;
	max-width: 900px;
}

.AdminLoginStats__loading-row .Loader {
	margin: 0 auto;
}


.WrappedPortedLoginStats#admin {
	#admin-root {
		left: 0;
		.AdminLoginStats {
			left: 0;
		}
	}
}
