@import "~StyleVariables";
.ReportcenterTVSettings {
	background-color: $white;
	height: 100%;
	width: 440px;
	z-index: 10002 !important;

	&__form {
		display: flex; 
		flex-direction: column;
		padding: 16px 16px 0px 16px;
		row-gap: 16px;
	}

	.DrawerHeader > .Row > .Button {
		height: 100%;
		border-radius: 0;
	}
}