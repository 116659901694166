@import "~StyleVariables";

.ReportcenterWidgetLibrary {
	--sidebarMinWidth: 240px;
	--sidebarMaxWidth: 320px;

	height: calc(100% - #{$header-height});
	background-color: $white;
	display: flex;

	&__suggestionBox {
		background-color: $super-light-blue;
		width: 100%;
		border-radius: 4px;
		padding: 0px 150px 16px 16px;
		background-image: url('../../../img/CantFind.svg');
		background-position: bottom right;
		background-repeat: no-repeat;
		margin-bottom: calc(100vh - 450px); // 450px is right about the size of a title+grid

		.Icon {
			margin-right: 8px;
		}

		.Row {
			padding-top: 16px;
		}
	}

	&__sidebar {
		overflow: auto;
		background-color: $grey-1;
		max-height: 100%;
		width: 20vw;
		min-width: var(--sidebarMinWidth);
		max-width: var(--sidebarMaxWidth);
	}

	&__sectionTitle {
		padding-bottom: 1em;
		.Icon {
			font-size: 20px;
		}
	}

	&__title {
		display: flex;
		align-items: center;
		padding-top: 0.75em;
		margin-bottom: 0.25em;

		.Icon {
			font-size: 14px;
		}

		.AssistChip {
			margin-left: 16px;
		}
	}

	&__descriptionIcon {
		color: $grey-12;
		padding-left: 5px;
		transition: cubic-bezier(0.075, 0.5, 0.165, 1) 0.4s;

		&--expanded {
			color: $bright-blue;
		}

		&:hover {
			color: $bright-blue;
			cursor: pointer;
		}
	}
	&__content {
		padding: 40px;
		width: calc(100% - 20vw);
		max-width: calc(100% - var(--sidebarMinWidth));
		min-width: calc(100% - var(--sidebarMaxWidth));
		overflow: auto;
		max-height: 100%;
	}

	&__widget {
		cursor: pointer;
		border: 1px solid $grey-6;
		border-radius: 3px;
		transition: all ease-out 200ms;

		.ReportcenterWidget {
			height: 210px;

			.ReportcenterWidget--w1 .BigNumberWidget .Title {
				font-size: 2.4vw;
			}
			.ProgressBarWidget {
				align-self: center;
				margin-top: 20px;
			}

			& > .Block {
				display: flex;
				align-items: center;
				height: 50px;
				padding: 0 15px;
				border-top: 1px solid $grey-4;
			}
		}

		&:hover {
			border-color: $bright-blue;
		}
		margin-bottom: 4em;
	}

	&__grid {
		max-width: 900px;
		margin-top: 20px;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto;
		column-gap: 15px;
		row-gap: 15px;
	}

	&__section {
		padding-bottom: 5em;

		&:last-of-type {
			padding-bottom: 0;
		} 
	}

	&__subtitle {
		color: $grey-12;
		transition: cubic-bezier(0.075, 0.5, 0.165, 1.5) 0.75s;
		opacity: 0;
		max-height: 0px;
		width: 100%;
		max-width: 900px;
		text-align: justify;

		&--expanded {
			max-height: 5em;
			opacity: 1;
		}
	}
}
