@import "~@upsales/components/Utils/colors";

.FirstScreen {
	overflow: hidden;
	height: 100vh;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	&__fireworks {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	&__champagne {
		height: 50px;
		width: 50px;
		display: inline-block;
		margin-left: 8px;
	}

	&__con {
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		justify-content: center;

		.Headline {
			text-align: center;
			display: inline-block;
		}

		.Button {
			z-index: 10000;
		}
	}
}
