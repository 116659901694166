@import "~StyleVariables";

.OpenCallModal {
	&__close {
		color: grey;
		position: absolute;
		top: 8px;
		right: 8px;
		margin-bottom: 12px;
	}
	&__linkRow {
		color: $bright-blue;
		line-height: 20px;
		font-size: 13px;
	}
	&__linkIcon {
		width: 20px;
		cursor: pointer;
	}
	.Avatar {
		height: 30px;
		width: 30px;
		font-size: 30px;
		&--gravatar {
			height: 30px;
			width: 30px;
		}
		&--initials {
			line-height: 30px;
		}
	}
	&__related {
		margin: 15px -15px -15px;
		background: #f4f4f4;
		position: relative;
		bottom: 0;
		padding: 15px;
		border-top: 1px solid $grey-7;
		label {
			color: $grey-12;
			display: block;
			font-weight: $regularFontWeight;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			margin-bottom: $space;
		}
	}
	.Avatar--gravatar {
		box-shadow: unset;
	}
}
