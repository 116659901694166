.DetectedDuplicatesPage {
	overflow: auto;
}

.DetectedDuplicatesCardHeader {
	position: sticky;
	top: 0;
	border-top: 2px solid #002f65;
	background: white;
	z-index: 1000;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

	&--no-frame {
		border-top: 0px;
	}
}

.DetectedDuplicatesText__inline {
	display: inline;
}
