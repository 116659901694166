@import "~StyleVariables";

.ClientPlanListElement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space * 2;

    &--danger {
        color: $danger-5;
    }

}