@import "~StyleVariables";

.PlannedAppointments {
	border-top: 1px solid $grey-5;

	&__list {
		margin-bottom: 10px;
	}

	&__description {
		&:hover {
			cursor: pointer;
		}
		margin-left: 10px;
	}

	&__link {
		&:hover {
			color: $bright-blue;
		}
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.RadioListItem,
	.single-item {
		border: 1px solid $grey-5;
		padding: 10px;
		margin: 0;
		border-bottom: none;

		&:last-child {
			border-bottom: 1px solid $grey-5;
		}

		& > .Label {
			width: 100%;
			margin-left: 10px;
			font-weight: normal;
		}

		.RadioItem {
			flex-shrink: 0;
		}
	}

	.Icon {
		font-size: 14px;
	}

	.CardHeader {
		.Title {
			line-height: 20px !important;
			padding: 10px 0;
		}
	}

	&__actions {
		padding-bottom: 10px;
	}
}
