@import "~StyleVariables";

.ReportcenterDrilldownEmpty {
    text-align: center;
    &__empty-wrapper {
		background-color: $grey-1;
		& td img {
			margin-top: 3rem;
            margin-bottom: 2rem;
			max-width: 20rem;
		}
	}
}