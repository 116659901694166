.MailTemplateRow .TableColumn--tools {
	white-space: nowrap;

	.fa-spinner {
		margin-top: 6px;
		margin-right: 6px;
	}
}

.MailTemplateRow--PREVIEWLIST {
	padding-top: 16px;
	padding-bottom: 16px;
	height: 112px;
}

.MailTemplateRow__preview {
	display: flex;
	align-items: center;
}

.MailTemplateRow__preview .TemplateCardPreview {
	width: 64px;
	height: 80px;
}

.MailTemplateRow__preview > .Text {
	margin-left: 20px;
}
