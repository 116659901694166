.FormPreview-area {
	height: 100%;
	position: relative;
	overflow: hidden;

	&--isLoading {
		iframe {
			top: 100% !important;
		}
	}

	&__Curtain {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 1;
		opacity: 0;
		top: 0;
		left: 0;
	}

	iframe {
		border: none;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		will-change: transform;
		position: absolute;
		top: 0;
		transition: top cubic-bezier(0, 0, 0.08, 1.27) 400ms;
	}

	.Loader {
		position: absolute;
		z-index: 5;
		left: calc(50% - 25px);
		top: calc(50% - 25px);
	}
}
