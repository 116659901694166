@import "~StyleVariables";

.NavbarBrandDropdown {
	position: relative;
	height: 100%;
	z-index: 1100;

	&__trigger {
		.Text {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.Icon {
			transition: all ease-out 300ms;
			position: absolute;
			right: 5px;
		}

		&--open .Icon {
			transform: rotate(180deg);
		}
	}

	&__dropdown-row {
		width: 100%;
		height: 45px;
		padding: 10px 11px;
		transition: all ease-out 300ms;
		cursor: pointer;

		.Text {
			color: $grey-12;
			transition: color ease-out 300ms;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		&:hover {
			background-color: $grey-1;

			.Text {
				color: $medium-blue;
			}
		}
	}

	&__dropdown-row-logo {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: left center;
		background-size: contain;
		display: block;
		line-height: 25px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
