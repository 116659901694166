@import "~StyleVariables";

.UpdateSplitSubs {
	position: relative;
	padding: 16px 42px;
	overflow: hidden;

	&__left-container {
		flex-direction: column;
		display: flex;

		.Button {
			max-width: 350px;
		}
	}

	&__checkboxes {
		display: flex;
		flex-direction: column;
		max-width: 350px;
		> div {
			display: flex;
			align-items: center;
			gap: 12px;
			margin: 12px 0;
			&:nth-of-type(2) {
				cursor: pointer;
			}
		}
	}

	&__back-button {
		position: absolute;
		left: 14px;
		top: 20px;
		cursor: pointer;
	}
}
