@import "~StyleVariables";

.TableTokens {
	flex: 1;

	&__placeholder {
		color: $grey-10;
		font-style: italic;
		-webkit-font-smoothing: antialiased;
	}

	&__token {
		background: rgba($bright-blue, 0.2);
		padding: 3px 5px;
		color: $bright-blue;
		font-size: 12px;
		line-height: 16px;
		border-radius: $border-radius;
	}

	&__more {
		line-height: 20px;
		display: inline-block;
		font-size: 12px;
		margin-left: 4px;
		color: $bright-blue;
	}

	&__token+&__token {
		margin-left: 5px;
	}
}