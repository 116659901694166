@supports (--css: variables) {
	input[type='range'].multirange {
		padding: 0;
		margin: 0;
		display: inline-block;
		vertical-align: top;
	}

	input[type='range'].multirange.original {
		position: absolute;
	}

	input[type='range'].multirange.original::-webkit-slider-thumb {
		position: relative;
		z-index: 2;
	}

	input[type='range'].multirange.original::-moz-range-thumb {
		transform: scale(1); /* FF doesn't apply position it seems */
		z-index: 1;
	}

	input[type='range'].multirange::-moz-range-track {
		border-color: transparent; /* needed to switch FF to "styleable" control */
	}

	input[type='range'].multirange.ghost {
		position: relative;
		background: var(--track-background);
		--track-background: linear-gradient(
				to right,
				transparent var(--low),
				var(--range-color) 0,
				var(--range-color) var(--high),
				transparent 0
			)
			no-repeat 0 45% / 100% 40%;
		--range-color: hsl(190, 80%, 40%);
	}

	input[type='range'].multirange.ghost::-webkit-slider-runnable-track {
		background: var(--track-background);
	}

	input[type='range'].multirange.ghost::-moz-range-track {
		background: var(--track-background);
	}
}
