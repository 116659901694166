.ToolTipOrderRows {
	&__tooltip {
		min-width: 200px;
		max-width: 260px;

		&__showMore {
			margin-top: 8px;
		}
	}

	&__tooltipRow {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;

		&__value {
			white-space: nowrap;
			padding-left: 10px;
		}
	}
}
