@import "~StyleVariables";

.MailTemplatePreviewAddMenu {
	$height: 450px;
	width: 300px;
	height: $height;
	border-radius: 3px;
	padding: 0 5px 5px 5px;
	$headline-height: 40px;

	.Headline {
		position: relative;
		text-align: center;
		height: $headline-height;
		line-height: $headline-height;

		.Button {
			position: absolute;
			right: 0;
			top: 0;
			height: $headline-height;
			line-height: $headline-height;
		}
	}

	.Row {
		$col-height: ($height - $headline-height - 5px)/8;
		height: $col-height;
		cursor: pointer;
		opacity: 0.5;
		transition: all ease-out 200ms;

		&:hover {
			opacity: 1;

			.Column .Text {
				opacity: 1;
				visibility: visible;
			}
		}

		.Column {
			position: relative;
			text-align: center;

			.Text {
				position: relative;
				line-height: $col-height;
				height: $col-height;
				opacity: 0;
				visibility: hidden;
				transition: all ease-out 200ms;
			}

			&:before {
				content: "";
				background-color: $green;
				position: absolute;
				top: 5px;
				bottom: 5px;
				left: 5px;
				right: 5px;
				border-radius: 3px;
			}
		}
	}
}
