@import "~StyleVariables";

.File {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 8px;
    background-color: $white;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 2px 2px 4px 0px $grey-8;
	width: 200px;
	cursor: pointer;
	position: relative;
	&:hover {
		background-color: $grey-1;
	}

	img {
		width: 184px;
		height: 200px;
	}

	.Text {
		white-space: nowrap; 
		overflow: hidden;
		text-overflow: ellipsis; 
		margin-left: 12px;
		height: 22px;
		.Icon {
			margin-right: 8px;
			font-size: 14px;
		}
	}

	&--isInEditMode {
		animation: scale 0.4s ease;
		&:hover {
			background-color: $white;
			cursor: default;
		}
	}

	&--isDragging {
		visibility: hidden;
	}

	&__trash {
		animation: show 0.4s ease;
		transition: all 0.25s ease;
		position: absolute;
		right: -8px;
		top: -6px;
		color: $red;
		font-size: 16px;
		cursor: pointer;
		&:hover {
			scale: 1.12;
		}
	}
}

@keyframes scale {
	0% {
		scale: 1;
		transform: rotate(0deg);
	}
	60% {
		transform: rotate(1deg);
		scale: 1.03;
	}
	100% {
		transform: rotate(0deg);
		scale: 1;
	}
}

@keyframes show {
	0% {
		scale: 0;
		opacity: 0;
		transform: rotate(0deg);
	}
	30% {
		transform: rotate(-8deg);
	}
	60% {
		scale: 1.1;
		transform: rotate(8deg);
	}
	100% {
		transform: rotate(0deg);
		scale: 1;
		opacity: 1;
	}
}