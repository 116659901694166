@import "~StyleVariables";

.CompetitorDrawer {
	$drawerContentHeight: 125px;
	display: flex;
	height: 100%;
	max-width: 600px;
	flex-direction: column;
	background-color: $grey-2;

	&__drawerHeaderContent {
		width: 100%;
		height: $drawerContentHeight;
		overflow: hidden;
	}

	&__competitorRowContainer {
		overflow: auto;
	}

	&__columnNumberOfTimesAdded {
		text-align: right;
	}

	&__normalMode,
	&__mergeMode,
	&__pendingMergeMode {
		opacity: 0;
		height: 100%;
		visibility: hidden;
		transition: $transition-small-bounce;

		&--visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&__normalMode {
		transform: translateY($drawerContentHeight);

		&--visible {
			transform: translateY(0);
		}
	}

	&__mergeMode {
		transform: translateY(-$drawerContentHeight * 2);

		&--visible {
			transform: translateY(-$drawerContentHeight);
		}
	}

	&__pendingMergeMode {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		background-color: $white;
		transform: translateY(-$drawerContentHeight * 3);

		&--visible {
			transform: translateY(-$drawerContentHeight * 2);
		}
	}

	&__pendingMergeLoader,
	&__pendingMergeDone {
		opacity: 0;
		transition: $transition-small-bounce;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		&--visible {
			opacity: 1;
		}
	}

	&__pendingMergeDone {
		&--visible {
			transform: translateY(-60px);
		}
	}

	&__mergeImage {
		height: 100%;
	}

	&__normalMode {
		display: flex;
	}

	&__normalModeLeftSide,
	&__mergeMode {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__buttonContainer,
	&__keepCompetitorContainer {
		display: flex;
		align-items: center;
	}

	&__keepCompetitorText {
		flex-shrink: 0;
	}

	&__keepCompetitorSelector {
		min-width: 0;
		width: 100%;
	}

	&__mergeButtonBlock {
		min-width: 0;
	}

	th:nth-child(even),
	td:nth-child(odd) {
		text-align: right;
		padding-right: 15px;
	}

	.DrawerHeader {
		height: auto;
	}
}
