.MultiUpdateAppointment {
	.LabeledInput {
		&__top-right {
			margin-left: auto;
		}
	}

	.TimeInput__listContainer,
	.DateInput__popup {
		display: none;

		&--open {
			display: unset;
			z-index: 1010;
		}
	}
}
