@import "~StyleVariables";

.TaskList {
	&__outcomeActions {
		.outcomeButtonNo {
			margin-right: $space * 2;
		}
	}

	&__quickactions {
		display: flex;
		justify-content: end;
	}
}
