.CssEditor {
	#editor-wrap {
		height: 249px;
	}

	.upsales-loader {
		width: 30px;
		height: 30px;
		position: relative;
		bottom: 45px;
		right: 10px;
	}
}
