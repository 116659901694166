@import "~StyleVariables";

.SplitDateRows {
	transition: opacity 0.2s ease;
	position: absolute;

	&__add-row-button {
		margin-top: 20px;
		.Icon {
			margin-right: 8px;
		}
	}

	&--hide {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}
}