@import "~StyleVariables";

.Embed {
	.Label {
		font-weight: $boldFontWeight;
	}

	.input-group-addon {
		width: 60px;
	}

	.CopyButton {
		bottom: 12px;
		right: 0px;
	}

	&__Description {
		padding: 15px 20px;
	}

	&__Size {
		margin-top: 10px;
	}

	&__Editor {
		height: 100%;
	}
}
