@import "~StyleVariables";

.SubMenuItem {
	border-radius: $border-radius $border-radius 0 0;
	cursor: pointer;
	height: stretch;
	padding: 0 $space * 3;
	transition: all ease 200ms;

	&--has-dropdown {
		padding-right: 0;
	}

	&__title {
		white-space: nowrap;
	}

	&__drop-trigger,
	&__title {
		color: rgba($white, 0.8);
		font-size: 13px; // This is not right, but it is how the old one is
	}

	&--active {
		background-color: $white;

		.SubMenuItem__drop-trigger,
		.SubMenuItem__title {
			color: $green;
		}
	}

	&__drop-trigger {
		height: stretch;
		width: $space * 5;
		border-radius: 0 $border-radius 0 0;

		& > .Icon {
			transition: $transition-small-ease;
			transform: rotate(0deg);
			font-size: 11px;
		}

		&--active {
			& > .Icon {
				transform: rotate(180deg);
			}
		}
		
		&--active,
		&:hover {
			background-color: rgba($white, 0.1);
		}
	}

	&:hover:not(.SubMenuItem--active) {
		background-color: rgba($white, 0.1);

		.SubMenuItem__title {
			color: $white;
		}
	}
}
