@import "variables";
@import "mixins";

.inserted-tag {
	@include border-radius(3px);
	background-color: $grey-2;
	border: 1px solid $grey-8;
	display: inline-block;
	padding: 3px;
	color: $grey-13;
	font-weight: $boldFontWeight;
}
