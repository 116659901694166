@import "~StyleVariables";

.up-modal.default.PreviewPdfModal {
	border-radius: 4px;
}

.PreviewPdf {
	margin-top: 0px;
	color: $black;
	height: 92vh;

	&__chooseOrderModal {
		padding: 40px;
	}

	&__loading-orders {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	&__order {
		gap: 12px;
		border-bottom: 1px solid $grey-6;
		text-align: left;
		display: flex;
		flex-direction: row;
		word-break: break-word;

		&-title {
			font-size: 14px;
			color: $black;
		}

		&-text {
			font-size: 12px;
			color: $grey-11;
		}
	}

	.DropDownMenu__content.DropDownMenu__content--addMinWidth {
		width: 330px;
	}

	.DocumentTemplateEditorDesign__sidebar-section {
		margin-top: 10px;
		border: 1px solid $grey-4;
		border-radius: 4px;
		padding: 0px;

		.DocumentTemplateEditorDesign__sidebar-section-input-row {
			border: 1px solid $grey-4;
			margin: 0px;
			padding: 5px;
			cursor: pointer;
		}

		.DocumentTemplateEditorDesign__sidebar-section-input-row:hover {
			background-color: $grey-1;
		}
	}

	.up-modal-controls {
		background-color: white;
		height: 57px;
		padding: $space * 2;
		z-index: 1005;
		bottom: 0;
		position: sticky;
		display: flex;
		align-items: center;
		justify-content: flex-end; // to match the placement in the previous iteration
		top: 100%;
		border-top: 1px solid $grey-6;

		.btn.btn-link.btn-sm {
			color: $green;
			font-size: 14px;
		}
	}

	a {
		color: $black;
	}

	.close-button {
		float: right;
		align-self: center;
		align-items: center;
	}

	.download,
	.email {
		margin: 0 5px 0 5px;
		text-decoration: none;
		border: none;
		background: none;
		max-width: 20px;
		display: flex;
		justify-content: center;

		.fa {
			font-size: 14px;
		}
	}

	.up-btn.btn-grey {
		background-color: inherit;
		border: none;

		.Icon {
			color: $black;
			font-size: 14px;
		}
	}

	.options {
		button {
			background: none;
			border: none;
		}

		.fa {
			color: $black;
			font-weight: $boldFontWeight;
		}
	}

	.page_count,
	.page_num {
		font-size: 14px;
	}

	.up-modal-content {
		padding: 0 !important;
		z-index: 1 !important;
		position: absolute;
		left: 0;
		top: 48px;
		height: 88%;
		right: 0;
		background-color: $white !important;
		overflow-x: auto;
		overflow-y: auto;
		filter: alpha(opacity=0);
		transition: opacity linear 0.2s;

		.error-card {
			top: 56px;
		}
	}

	canvas {
		direction: ltr;
		margin-top: 0em;
	}

	.PreviewPdf__inner {
		margin: 0 auto;
		max-width: 770px;
	}

	.preview--modal--header {
		margin-bottom: 5px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		display: block;
		z-index: 3;
		min-height: 48px;
		background: $white;
		box-shadow: 0px 1px 2px 0px #1d3d4833;
	}

	&__header--column {
		display: flex;
		align-items: center;
	}

	&__header--column--center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__header--column--left {
		display: flex;
		align-items: center;
		padding-left: 10px;
		justify-content: left;
	}


	&__dropdownTrigger {
		display: flex;
		align-items: center;
		text-align: left;
	}

	&__dropdownTriggerDescription {
		display: flex;
		align-items: center;
		text-align: left;
		justify-content: space-evenly;
		max-width: 200px;
		cursor: pointer;

		.PreviewPdf__dropdownTriggerDescription--text {
			overflow: hidden;
		}

		.Icon {
			padding: 0 8px;
		}
	}

	.pdf-loader {
		float: left;
	}
}