@import "~StyleVariables";

.ReportcenterFilterDateSelect {
	display: flex;
	flex-direction: column;
	width: 950px;
	padding: 22px 18px;

	.DateInput {
		width: 250px;

		&:nth-of-type(2) {
			margin-left: 12px;
		}

		&__popup {
			position: fixed;
		}
	}
	.Row {
		padding-bottom: 16px;

		.Tooltip {
			margin-left: $space * 2;
		}
	}
	.RadioListItem {
		margin-bottom: 8px;
		font-size: 13px;
	}
	&__fiscal-year-toggle {
		display: flex;
		padding-left: 10px;
		align-items: center;

		.Text {
			font-weight: $regularFontWeight;
			margin-left: 8px;
		}
	}
}
