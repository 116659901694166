@import "~StyleVariables";

.EditorWrap {
	margin-right: 380px;
	transition: margin-right ease-out 200ms;

	&__sidebar {
		background-color: $white;
		box-shadow: -1px 0 2px 0 rgba($black, 0.2);
		width: 380px;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		transition: box-shadow ease-out 200ms, transform ease-out 200ms;
		transform: translateX(101%);
		z-index: 1003;

		&__visible {
			transform: translateX(0%);
		}
	}

	&__sidebar-toggle {
		position: absolute;
		z-index: 1001;
		top: 15px;
		left: -65px;
	}

	&__sidebar-handle {
		width: 10px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		cursor: col-resize;
		transition: background-color ease-out 200ms;
		border-left: 1px solid transparent;
		z-index: 1000;

		&:hover {
			border-left-color: $bright-blue;
		}

		.Icon {
			position: absolute;
			top: 50%;
			margin-top: -6px;
			left: 1px;
			color: #cad2dd;
			font-size: 12px;
		}
	}
}

.EditorWrap--sidebar-resizing {
	transition: none;
	cursor: col-resize;
	.EditorWrap__sidebar {
		box-shadow: -4px 0 8px 0 rgba($black, 0.2);
	}

	.EditorWrap__sidebar-handle {
		border-left-color: $bright-blue;
	}
}

.EditorWrap--sidebar-visible .EditorWrap__sidebar {
	transform: none;
}

.EditorWrap:not(.EditorWrap--sidebar-visible) {
	margin-right: 0 !important;
}
