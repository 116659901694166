@import "~StyleVariables";

.SentimentChart {

	&--loading {
		.SentimentChart__dot {
			animation: pulseDot 2s ease-in-out infinite;
		}
	}

	&__grid {
		display: grid;
		grid-auto-flow: column;
		grid-template-rows: repeat(11, 1fr);
		gap: 4px;
	}

	&__dot {
		width: 12px;
		height: 12px;
		border-radius: 35%;
	}
}

@keyframes pulseDot {
	0% {
		opacity: 0.35;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.35;
	}
}