.portedMaxCeilingReachedOnSeats {
	display: block;
}

.MaxCeilingReachedOnSeats.MaxCeilingReachedOnSeats {
	width: 80%;
	margin: 0 auto;
	max-width: 750px;
	display: flex;
	flex-direction: column;
	padding-top: 0px;
	position: static;

	.ModalHeader {
		position: absolute;
	}

	.Label {
		.Text {
			display: inline;
		}
	}
}
