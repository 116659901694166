@import "~StyleVariables";

.ReportcenterHeader {
	position: sticky;
	top: 0;
	z-index: 10000;
	background-color: $white;

	&__topbar {
		display: flex;
		align-items: center;
		height: $header-height;
		box-shadow: 0 1px 2px rgba($black, 0.2);
	}

	&__info {
		padding: 0 16px;
		padding-right: 32px;
		width: 25vw;
		min-width: 300px;
		max-width: 400px;
		.Text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.Title {
			div {
				max-width: 340px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	&__steps {
		display: flex;
		align-items: center;

		.step {
			border-bottom: 2px solid transparent;
			height: $header-height;
			margin: 0 auto;
			padding: 0px 20px;
			text-align: center;
			display: flex;
			align-items: center;

			.steps-bullet {
				display: inline-block;
				background-color: $grey-6;
				border-radius: 100%;
				height: 20px;
				width: 20px;
				min-width: 20px;
				line-height: 20px;
				margin-right: 10px;
				color: $white;
				font-style: normal;
			}
		}
		.step.clickable {
			cursor: pointer;
			font-style: normal !important;
		}
		.step.is-active {
			color: $grey-13;
			font-weight: bold;
			border-bottom: 4px solid $green;

			.steps-bullet {
				background-color: $green;
			}
		}
		.step:not(.is-active) {
			color: $grey-9;
			font-style: italic;
		}
	}

	&__confirm {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.transparent-btn {
			padding-right: 20px;
		}
		.confirm-btn {
			padding-left: 12px;
			height: $header-height;
			border-radius: 0px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			&--disabled {
				&:hover {
					background-color: $grey-4;
					cursor: not-allowed;
				}
			}
		}
	}

	&__super {
		font-size: 12px;
		font-weight: $regularFontWeight;
		line-height: 14px;
	}
	&__title {
		font-size: 16px;
		font-weight: $boldFontWeight;
		line-height: 19px;

		.Icon {
			padding-left: 10px;
		}
	}
}
