@import "~StyleVariables";

.ReportcenterGrid {
	position: relative;
	height: 100%;
	overflow-y: scroll;

	.react-resizable-handle {
		position: absolute;
		bottom: 0;
		right: 0;
		height: 30px;
		width: 30px;
		cursor: se-resize;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
		z-index: 5;
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-bottom: 30px solid $super-light-blue;
			border-left: 30px solid transparent;
		}

		&:after {
			$color: rgba($bright-blue, 0.5);
			content: "";
			position: absolute;
			right: 5px;
			bottom: 1px;
			width: 7px;
			height: 14px;
			border-right: none;
			border-bottom: none;
			border-left: 2px solid $color;
			transform: rotate(45deg);
			background-image: linear-gradient($color, $color);
			background-repeat: no-repeat;
			background-size: 2px 50%;
			background-position: right center;
		}
	}

	&__widget {
		border: 1px solid $grey-4;
		border-radius: 3px;
		transition-timing-function: ease-out;
		transition-duration: 200ms;
		transition-property: transform, border-color, box-shadow !important;

		.ReportcenterWidgetActionMenu {
			visibility: hidden;
			z-index: 1001;
			opacity: 0;
			transition: all ease-out 200ms;
		}

		.ReportcenterWidgetActiveFilters {
			opacity: 0;
			visibility: hidden;
			transition: all ease-out 200ms;
		}

		.ReportcenterWidgetInfo {
			opacity: 0;
			visibility: hidden;
			transition: all ease-out 200ms;
		}

		&--actions-open,
		&:hover {
			.ReportcenterWidgetActionMenu {
				visibility: visible;
				opacity: 1;
			}

			.ReportcenterWidgetActiveFilters {
				opacity: 1;
				visibility: visible;
			}

			.ReportcenterWidgetInfo {
				opacity: 1;
				visibility: visible;
			}
		}

		&.react-draggable-dragging,
		&:hover:not(.ReportcenterGrid__widget--edit-disabled) {
			border-color: $bright-blue;
			box-shadow: 0 2px 4px 0 rgba($black, 0.2);

			.react-resizable-handle,
			.ReportcenterGrid__resize-handle {
				visibility: visible;
				opacity: 1;
			}
		}

		&--actions-open {
			z-index: 1002;
		}

		&--unavailable {
			display: flex;
			align-items: center;
			justify-content: center;

			.Block.unavailable-info {
				display: flex;
				text-align: center;
			}
		}
	}

	.react-grid-placeholder {
		background-color: $grey-6;
		border-radius: 3px;
	}
}
