// move this file to /directives

.reset,
.reset .row {
	// .reset *:not(img):not(ul):not(ol) {
	background: none;
	border: none;
	bottom: auto;
	clear: none;
	cursor: default;
	float: none;
	font-size: medium;
	font-style: normal;
	font-weight: normal;
	height: auto;
	left: auto;
	letter-spacing: normal;
	line-height: normal;
	max-height: initial;
	max-width: initial;
	min-height: initial;
	min-width: initial;
	overflow: visible;
	position: static;
	right: auto;
	text-align: left;
	text-decoration: none;
	text-indent: 0;
	text-transform: none;
	top: auto;
	visibility: visible;
	white-space: normal;
	width: initial;
	z-index: auto;
	margin-left: initial;
	margin-right: initial;
	margin-top: initial;
	margin-bottom: initial;
	padding: 0;
}

.template-preview-fake-body {
	table.body .profile-header table.container,
	table.body .profile-footer table.container,
	table.body table.container.main-container {
		margin: 0 auto !important;
	}

	img.thumbnail {
		border: none;
		border-radius: 0;
		box-shadow: none;

		&:hover {
			border: none;
			border-radius: 0;
			box-shadow: none;
		}
	}

	.row {
		margin-bottom: 0;
	}

	center {
		text-align: center;
	}
}

.template-editor {
	$tools-width: 45px;
	$tools-width-large: 300px;
	$animationMs: 300ms;
	$animationDelayMs: ($animationMs / 2);

	@include keyframes(tools_exit) {
		0% {
			right: $tools-width-large;
			bottom: initial;
		}
		50% {
			bottom: initial;
		}
		100% {
			bottom: 0;
			right: 0;
		}
	}

	@include keyframes(tools_enter) {
		0% {
			right: 0;
			bottom: 0;
		}
		50% {
			right: 0;
			bottom: initial;
		}
		100% {
			right: $tools-width-large;
			bottom: initial;
		}
	}

	position: absolute;
	width: 100%;
	overflow: hidden;
	height: 100%;
	background-color: $grey-1;

	&.drawer-open {
		.template-editor-preview {
			padding-right: $tools-width-large;
		}

		.template-editor-tools {
			.editor-tools-panel {
				&.visible:not(#main-tools) {
					@include transition(all ease $animationMs 0ms);
				}

				&#main-tools {
					right: $tools-width-large - 1px;
					// Show - animation
					@include transition(all ease $animationMs);
					// @include animation(tools_enter $animationMs);
					// @include animation-delay($animationDelayMs);

					.tool-icon {
						&#tool-back-btn {
							display: block;
						}
					}
				}
			}
		}
	}

	#disabled-curtain {
		background-color: rgba($grey-8, 0.5);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		display: none;
	}

	.template-editor-tools {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;

		.editor-tools-panel {
			width: $tools-width;
			position: absolute;
			top: 0;
			bottom: 0;
			right: -310px;
			@include transition(all ease $animationMs);
			background-color: $white;
			border-left: 1px solid $grey-8;
			padding: 0;
			font-size: 12px;
			z-index: 1001;
			@include box-shadow(0 0 10px rgba($black, 0.3));

			&#main-tools {
				bottom: initial;
				border: 1px solid $grey-8;
				width: $tools-width;
				background-color: $blue;
				border: none;
				// hide - animation
				@include transition(all ease $animationMs);
				// @include animation(tools_exit $animationMs);
				// @include animation-delay(0ms);
			}

			&.visible {
				right: 0;
			}

			&.large {
				width: $tools-width-large;
				padding: 15px;
				overflow: scroll;
			}

			hr {
				border-color: $grey-8;
				margin-left: -15px;
				margin-right: -15px;
			}

			.clear-column {
				margin-top: 4px;
			}

			#column-dropdown {
				&.open {
					.dropdown-toggle {
						color: $bright-blue;
					}
				}

				.dropdown-menu {
					$width: 250px;
					top: 0;
					left: -$width;
					width: $width;
					border: none;
					z-index: 100000;

					li.dropdown-header {
						text-align: center;
						color: $grey-11;
						padding-top: 4px;
					}

					li.columns-row {
						padding: 7px 0;
						border-bottom: 1px solid $grey-4;
						color: $grey-10;

						&:hover {
							color: $bright-blue;
							background-color: $grey-1;
							table.col-preview-table {
								td {
									background-color: $bright-blue;
									color: $white;
								}
							}
						}

						table.col-preview-table {
							table-layout: fixed;
							width: 100%;
							border-spacing: 7px;
							border-collapse: separate;

							td {
								background-color: $grey-4;
								text-align: center;
								height: 27px;
								font-size: 11px;
								@include border-radius(3px);
							}
						}
					}
				}
			}

			h3 {
				display: inline-block;
				font-size: 16px;
				margin: 11px 0 15px 0;

				&.setting-header {
					margin-top: 0;
					margin-bottom: 0;
				}
			}

			.tool-section {
				margin-bottom: 10px;

				&.sub-section {
					padding-left: 5px;
				}

				.content-warning {
					line-height: 1.5;
					color: $red;
				}

				.warning-box {
					position: relative;
					margin: 10px 0;
					padding: 5px;
					padding-left: 25px;
					background-color: rgba($orange, 0.2);
					border: 1px solid rgba($orange, 0.2);
					@include border-radius(3px);

					.fa {
						color: $orange;
						position: absolute;
						top: 8px;
						left: 7px;
					}
				}
			}

			ul.tool-tabs {
				$size: 36px;
				display: block;
				list-style-type: none;
				padding: 0;
				margin: -15px;
				margin-bottom: 0;
				overflow: hidden;

				li {
					background-color: $grey-4;
					display: inline-block;
					width: 50%;
					height: $size;
					line-height: $size;
					text-align: center;
					border-bottom: 1px solid $grey-8;
					color: $grey-10;
					transition: all ease 200ms;

					&:hover:not(.selected-tab) {
						color: $grey-11;
						background-color: $grey-3;
					}

					&.selected-tab {
						position: relative;
						background-color: $white;
						border-bottom: none;
						z-index: 1000;
						color: $grey-11;
						@include box-shadow(0 0 5px rgba($black, 0.2));
					}
				}
			}

			#tool-titlebar {
				$height: 46px;
				position: relative;
				margin: -15px;
				margin-bottom: 15px;
				border-bottom: 1px solid $grey-4;
				height: $height;

				button {
					height: $height;
					position: relative;
					z-index: 1000;
				}

				h2 {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					text-align: center;
					width: 100%;
					font-size: 15px;
					line-height: $height;
				}
			}

			.content-type-select {
				margin: 5px -15px 15px -15px;
				padding: 10px;
				background-color: $grey-1;
				border-top: 1px solid $grey-4;
				border-bottom: 1px solid $grey-4;
			}

			.content-type {
				display: inline-block;
				width: 50%;
				padding: 5px;

				&:hover {
					.content-type-inner {
						@include box-shadow(0 1px 2px rgba($black, 0.4));

						span {
							color: $bright-blue;
						}

						.icon {
							color: $bright-blue;

							.icon-fill {
								fill: $bright-blue;
							}
						}
					}
				}

				.content-type-inner {
					background-color: $white;
					width: 100%;
					text-align: center;
					border-radius: $border-radius;
					padding: 20px 0;
					@include transition(all ease-out 200ms);
					@include box-shadow(0 1px 2px rgba($black, 0.2));

					.icon {
						height: 33px;
						display: block;
						font-size: 28px;
						color: $grey-8;
						margin-bottom: 5px;
						@include transition(all ease-out 200ms);
					}

					svg.icon {
						margin-left: auto;
						margin-right: auto;

						.icon-fill {
							fill: $grey-8;
						}
					}
					span {
						font-size: 15px;
						color: $grey-11;
					}
				}
			}

			.tool-icon {
				border: none;
				background-color: $blue;
				color: rgba($white, 0.8);
				outline: none;
				display: block;
				width: $tools-width - 1px; // minus border
				height: $tools-width;
				@include transition(all ease 200ms);
				@include border-radius(0);
				font-size: 14px;

				&:hover {
					color: $white;
					background-color: rgba($white, 0.1);
				}

				&#tool-back-btn {
					background-color: $dark-blue;
					display: none;
					position: relative;
				}
			}

			.separator {
				display: block;
				height: 1px;
				background-color: rgba($white, 0.2);
				margin: 0 5px;
			}

			.btn.active {
				background-color: $bright-blue;
			}

			.alignment-btns {
				width: 100%;

				& > .btn {
					width: 33%;
				}
			}

			.hr-style-btns {
				width: 100%;

				& > .btn {
					width: 33%;

					&.btn-bright-blue .hr-style-preview {
						border-color: $white;
					}
				}

				.hr-style-preview {
					display: block;
					border: 0;
					border-top: 4px solid $grey-10;

					&.dashed {
						border-style: dashed;
					}

					&.dotted {
						border-style: dotted;
					}
				}
			}

			.btn-sizes {
				width: 100%;

				& > .btn {
					width: 25%;
				}
			}

			.color-btn {
				$size: 20px;
				width: $size;
				height: $size;
				border: 1px solid transparent;
				margin: 3px;
				@include box-shadow(0 1px 2px rgba($black, 0.5));
				@include border-radius($size);

				&.selected {
					border-color: $bright-blue;
					@include box-shadow(none);
				}
			}

			#cke_texteditor {
				margin: 15px -15px 15px -16px;
				width: auto !important;
				@include box-shadow(none);
			}
		}
	}

	.template-editor-standard-templates {
		padding: 20px;
		max-height: 100%;
		overflow-y: scroll;

		#template-gallery {
			padding-right: 0;
			margin-top: 20px;
		}
	}

	.template-editor-preview {
		position: relative;
		background-color: $grey-1;
		padding-right: 0;
		height: 100%;
		overflow-y: scroll;
		@include transition(padding-right ease $animationMs);

		#row-tools {
			position: absolute;
			top: 0;
			right: 0;
			background-color: $bright-blue;
			width: 46px;
			display: none;
			text-align: center;
			z-index: 1000;

			button {
				display: block;
				width: 100%;
				font-size: 16px;
			}
		}

		.row-indicator {
			position: absolute;
			left: 0;
			right: 0;
			height: 1px;
			background-color: $bright-blue;
			display: none;
			@include opacity(0.5);

			&#top {
				top: 0;
			}

			&#bottom {
				bottom: 0;
			}

			&#add {
				z-index: 10000;
				opacity: 1;
				background-color: rgba($bright-blue, 0.5);

				.fa {
					width: 30px;
					height: 30px;
					position: absolute;
					z-index: 1006;
					background: $bright-blue;
					color: $white;
					left: 50%;
					margin-left: -15px;
					top: -15px;
					text-align: center;
					line-height: 30px;
					border-radius: 100%;
					transition: all ease 0.2s;
				}

				&.open {
					.fa {
						transform: rotate(45deg);
					}
				}
			}
		}

		& > .fake-body {
			@import "../../node_modules/foundation-emails/scss/foundation-emails";
			height: 100%;

			& > div {
				height: 100%;

				& > table.body {
					height: 100%;
					margin-bottom: 0 !important;
				}
			}

			table.callout {
				margin-bottom: 0;
				margin-bottom: 0;
			}

			.row.editable-row {
				&.active {
					&:before {
						content: "";
						display: block;
						position: absolute;
						top: 0;
						left: -30px;
						right: -30px;
						bottom: 0;
						border-top: 1px solid $bright-blue;
						border-bottom: 1px solid $bright-blue;
					}
				}
			}

			th.columns.editable-column {
				min-height: 20px;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: 1px solid transparent;
					background-color: transparent;
					@include transition(all ease 200ms);
				}

				&.selected-column:before {
					border: 1px solid $bright-blue;
					background-color: rgba($bright-blue, 0.1);
				}

				th.callout-inner.editor-column-placeholder,
				td.callout-inner.editor-column-placeholder {
					background-color: rgba($bright-blue, 0.1);
					border: 1px dashed $bright-blue;
					width: 100%;

					p {
						color: $bright-blue !important;
						text-align: center !important;
						padding-top: 10px !important;
						font-family: "Roboto", sans-serif !important;
					}

					&:hover {
						background-color: rgba($bright-blue, 0.2);
					}
				}

				&:hover {
					&:before {
						border-color: $bright-blue;
					}
				}
			}

			table.body .profile-header table.container,
			table.body .profile-footer table.container,
			table.body table.container.main-container {
				margin: 0 auto !important;
			}

			.header-container:before,
			.footer-container:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 1px solid transparent;
				background-color: transparent;
				display: block;
				@include transition(all ease 200ms);
			}

			.header-container.header-selected:before,
			.footer-container.footer-selected:before {
				border: 1px solid $bright-blue;
				background-color: rgba($bright-blue, 0.1);
			}

			img.thumbnail {
				border: none;
				border-radius: 0;
				box-shadow: none;

				&:hover {
					border: none;
					border-radius: 0;
					box-shadow: none;
				}
			}

			.row {
				margin-bottom: 0 !important;
			}

			center {
				text-align: center;
			}

			.profile-header,
			.profile-footer {
				position: relative;

				// Prevents links from getting clicked in preview
				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: transparent;
					z-index: 1000;
				}
			}

			.profile-header {
				img {
					height: 30px;
				}
			}
		}
	}

	&.drawer-open {
		.template-editor-preview {
			.row-indicator {
				&#add {
					.fa {
						width: 30px;
						height: 30px;
						position: absolute;
						z-index: 5000;
						background: $bright-blue;
						color: $white;
						left: 50%;
						margin-left: -160px;
						top: -15px;
						text-align: center;
						line-height: 30px;
						border-radius: 100%;
					}

					.dropdown-menu {
						margin-left: -270px;
					}
				}
			}
		}
	}

	#no-account-profile {
		margin: 50px auto;
		width: 400px;
		text-align: center;
		color: $grey-10;

		h2 {
			color: $blue;
			font-size: 24px;
			line-height: 40px;
		}

		p {
			margin: 20px 0;
			line-height: 22px;
		}

		#text-edit-btn-wrap {
			margin-top: 10px;
		}
	}
}

$mailEditorDropdownlistWidth: 300px;
$mailEditorDropdownbtnSize: 35px;

#add-row-curtain-email {
	visibility: hidden;
	@include opacity(0);
	@include transition(all ease 200ms);

	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba($black, 0.2);
	z-index: 1003;

	&.block {
		visibility: visible;
		opacity: 1;
	}
}

#column-size-menu {
	visibility: hidden;
	@include opacity(0);
	@include transition(all ease 200ms);
	position: absolute;
	background-color: $bright-blue;
	@include border-radius(3px);
	width: $mailEditorDropdownlistWidth;
	height: 0;
	left: 50%;
	margin-left: -($mailEditorDropdownlistWidth/2);
	text-align: center;
	z-index: 1004;
	padding: 0;
	color: $white;
	overflow: hidden;

	.columns-header {
		letter-spacing: 0.5px;
		font-size: 14px;
	}

	.columns-row {
		padding: 7px 0;
		border-bottom: 2px solid rgba($black, 0.2);

		&:last-child {
			border: none;
		}

		&:hover {
			.col-preview-table td {
				background-color: rgba($black, 0.5);
			}
		}
	}

	.col-preview-table {
		table-layout: fixed;
		width: 100%;
		border-spacing: 7px;
		border-collapse: separate;

		td {
			background-color: rgba($black, 0.2);
			text-align: center;
			height: 30px;
			font-size: 11px;
			letter-spacing: 0.5px;
			@include border-radius(3px);
			@include transition(background-color ease 200ms);
		}
	}

	&.open {
		visibility: visible;
		opacity: 1;
		height: auto;
		border-top: 15px solid $bright-blue;
	}
}

.logo-preview {
	width: 50%;
	border: 2px $grey-5 solid;
	float: left;
	height: 60px;
	position: relative;

	.logo-img {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		width: auto;
		max-height: 100%;
		height: auto;
		max-width: 100%;
		padding: 10px 10px 10px 25px;
		pointer-events: none;
	}
}

.logo-preview--dark {
	background: $grey-11;
}

.logo-preview--bright {
	background: $grey-1;
}

.no-logo {
	margin-top: 22px;
	font-size: 12px;
	line-height: 1;
	width: 100%;
	text-align: center;
	display: inline-block;
	color: $link-color;

	&:hover {
		text-decoration: underline;
	}
}

.logo-preview--selected {
	border: 2px $bright-blue solid;
	position: relative;

	&:before {
		font-family: FontAwesome;
		content: "\f00c";
		position: absolute;
		top: 5px;
		left: 5px;
		width: 15px;
		font-size: 10px;
		height: 15px;
		border-radius: 50%;
		background: $bright-blue;
		color: $white;
		line-height: 15px;
		vertical-align: middle;
		text-align: center;
	}
}
