@import "~StyleVariables";

.ListViewWidgets {
  display: grid;
  justify-content: center;
  grid-template-columns: 32.5% 32.5% 32.5%;
  grid-gap: 10px;
  padding: 10px;

  &__widget {
    border: 1px solid $grey-4;
    border-radius: 3px;
    transition-timing-function: ease-out;
    transition-duration: 200ms;
    transition-property: transform, border-color, box-shadow !important;
    position: relative;

    .ReportcenterWidgetLegend {
      overflow-y: hidden;
    }

    .ReportcenterWidget__previewWrapper {
      height: 250px;
    }
    .ReportcenterWidget__header,
    .ReportcenterWidget__content {
      z-index: 0;
    }

    .ReportcenterLink {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;

      .Icon {
        color: $dark-blue;
      }
    }

    &:hover {
      border-color: $bright-blue;
			box-shadow: 0 2px 4px 0 rgba($black, 0.2);

      .ReportcenterLink {
        display: block;
      }
    }
  }
}
