.ForecastingGraph {
	&__chip {
		opacity: 1;
		position: absolute;
		transition: opacity 0.3s ease;

		&--hideChip {
			opacity: 0;
		}
	}
	&__budget-chip {
		position: absolute;
	}
}
