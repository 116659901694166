.FlowStepEnd {
	&__edit-icon {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		top: 8px;
		right: 8px;
		transition: all ease-out 300ms;
	}
	&:hover &__edit-icon {
		opacity: 1;
		visibility: visible;
	}
}
