.ActivityTimelinePhonecall {
	&__audio-section {
		display: flex;
		align-items: center;
		.Avatar {
			height: 30px;
			width: 30px;
			font-size: 30px;
			margin-left: 5px;
			&--gravatar {
				height: 30px;
				width: 30px;
			}
			&--initials {
				line-height: 30px;
			}
		}
		.play-icon {
			font-size: 18px;
		}
	}
}
