@mixin sidebar() {
	#sidebar {
		position: fixed;
		left: 0;
		top: $main-top;
		bottom: 0;
		width: $sidebar-width;
		z-index: 5030;

		button.sidebar-btn {
			position: relative;
			background-color: transparent;
			width: 35px;
			height: 35px;
			outline: none;
			border: 1px solid transparent;
			z-index: 20001;
			border-right: none;
			font-size: 16px;
			color: $black;
			border-radius: $border-radius;
			transition: all ease-out 300ms;
			margin-bottom: 8px;

			&:hover {
				color: $green;
				background-color: $grey-3;
			}
		}

		.dropdown {
			&.open {
				button.dropdown-toggle {
					box-shadow: none;
					background-color: $white;
					border-color: $grey-8;
					color: $green;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			.dropdown-menu.datepicker,
			.dropdown-menu {
				z-index: 20000;
				left: 34px !important;
				top: -2px !important;
				border-radius: 0 $border-radius $border-radius $border-radius;

				.dropdown-header {
					padding: 7px 20px;
				}

				.divider {
					margin-top: 8px;
				}
			}

			&.date-dropdown {
				.dropdown-menu {
					td {
						min-width: 40px;
					}
					.btn {
						border: none;
						span {
							padding: 5px;
							display: block;
						}

						&.btn-info,
						&:hover {
							background-color: $green !important;
							color: $white !important;
						}
					}
				}
				&.open {
					.dropdown-menu {
						display: block !important;
					}
				}
			}
		}

		.dropdown-menu.datepicker {
			width: 320px;
		}

		&[open] {
			width: $sidebar-width-expanded;

			.btn-group-vertical {
				display: block;
			}
		}

		.btn-flat {
			@include border-radius(0);
			background-color: rgba($white, 0.9);
			width: 38px;
			height: 35px;

			&:hover {
				background-color: $white;
			}
		}

		#sidebar-calendar {
			border-top: 3px solid;
		}

		#latest-accounts-list {
			ul {
				li {
				}
			}
		}

		#sidebar-toggler {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
			line-height: 50px;
			font-size: 20px;
			text-align: center;
			cursor: pointer;
			text-shadow: 1px 1px $white;

			&.hover,
			&:hover {
				color: $green;
			}
		}

		#sidebar-chat-container {
			#chat-icon {
				border-radius: $border-radius $border-radius $border-radius $border-radius;
				z-index: 1001;

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					content: "";
					width: 100%;
					height: 100%;
					background-color: transparent;
					border-radius: $border-radius;
					border: 5px solid $green;
					@include animation(unread-pulse 2000ms);
					@include animation-iteration-count(infinite);
					@include opacity(1);
					@include transform(scale(1));
					z-index: 1000;
					display: none;
				}

				.Icon-exclamation {
					position: absolute;
					top: 4px;
					right: 5px;
					font-size: 12px;
					display: none;
				}

				&.unread-messages {
					background-color: $green;
					color: $super-light-green;

					&:before {
						display: block;
					}

					.Icon-exclamation {
						display: block;
					}
				}
			}

			.sidebar-btn {
				@include transition(all ease 200ms);
				background-color: transparent;
				@include border-radius(3px 3px 0 0);
				border: none;
				margin-bottom: 0;

				&:hover {
					color: $green;
					background-color: $grey-3;
				}
			}
		}
	}
}
