.progress {
	height: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin-bottom: 0px;
	border-radius: 4px;
	background-color: $grey-4;

	&.progress-md {
		height: 6px;
		border-radius: 3px;
	}

	&.progress-sm {
		height: 3px;
		border-radius: 2px;
	}

	&.dark {
		background-color: $grey-8;
	}

	.progress-bar {
		-webkit-box-shadow: none;
		box-shadow: none;
		// border-radius: 4px;
		background-color: $white;

		&.bright-blue {
			background-color: $bright-blue;
		}

		&.orange {
			background-color: $orange;
		}

		&.dark-orange {
			background-color: $dark-orange;
		}

		&.brown-orange {
			background-color: $brown;
		}

		&.blue {
			background-color: $bright-blue;
		}

		&.red {
			background-color: $red;
		}

		&.success {
			background-color: $bright-green !important;
		}
	}
}
