@import "~StyleVariables";
.RelationRow {
	display: flex;
	align-items: center;
	&:hover {
		background-color: $grey-1;
		cursor: pointer;
	}
	&__appointment,
	&__projectPlan,
	&__subscription,
	&__activity,
	&__ticket,
	&__agreement,
	&__opportunity {
		display: flex;
		align-items: center;
	}
	&__activity {
		height: 40px;
	}
	&__subscription {
		width: 100%;
		justify-content: flex-end;
	}

	&__journey {
		display: flex;
		align-items: center;
		&--contact {
			height: 10px;
		}
	}
	&__client {
		display: flex;
		flex-direction: column;
	}
	&__contact {
		display: flex;
		&__titles {
			display: flex;
			flex-direction: column;
		}
	}

	.Icon {
		&--sm {
			height: 28px;
			width: 28px;
			font-size: 28px;
		}
	}

	&__remove-button {
		justify-self: flex-end;
		align-self: center;
		right: 0;
		position: absolute;
	}
	&__truncate {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 250px;
	}

	&__truncateSubscriptionOrder {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 500px;
	}

	&--missingAccess {
		background-color: $grey-4;
		&:hover {
			cursor: default;
		}
	}

	&--clickableSubtitle {
		&:hover {
			background-color: $white;
			cursor: default;
		}
	}
}
