@import "~StyleVariables";

$lottieHeight: 118px;
$lottieWidth: 313px;

.WidgetCategorySelect {
    height: 100%;
    padding: 32px 115px 20px 115px;
    width: 1200px;
    align-self: center;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .Title {
            font-size: 28px;
            line-height: 34px;
        }

        .ReportcenterWidgetLibrarySearch {
            padding: 0;
            width: $lottieWidth;
            
            .Input {
                height: 28px;
                color: $black;
                &:focus-within, &:hover {
                    .Input__icon {
                        color: $black;
                    }
                }
            }

            .Input__input {
                font-size: 12px;
                height: 30px;
                border-radius: $border-radius;
            }

            .Input__icon {
                line-height: 30px;
                height: 30px;
            }

            .ReportcenterWidgetLibrarySearchDropdown {
                width: $lottieWidth;
                margin-top: 4px;
            }
        }
    }

    .Lottie {
        margin: 0px;
    }

    &__categories, 
    &__addon-banner {
        margin-top: 24px;
    }

    &__categories {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        gap: 16px;
    }
}