#experimental-feature {
	.up-modal-header {
		border-top: 5px solid $bright-blue;
		@include border-radius(0);
	}

	.up-modal-content {
		padding: 0 70px 15px;

		h4 {
			margin-top: 60px;
			margin-bottom: 25px;
		}

		p {
			margin: 0 0 25px;
			color: $grey-10;
		}

		.up-btn {
			width: 200px;
			display: block;
			margin: 10px auto;
		}

		.btn-bright-blue {
			margin-top: 30px;
		}
	}

	#corner-ribbon {
		position: absolute;
		top: 33px;
		left: -35px;
		transform: rotate(-45deg);
		color: $white;
		background-color: $purple;
		z-index: 1000;
		text-align: center;
		width: 170px;
		font-size: 14px;
		text-transform: uppercase;
		height: 25px;
		line-height: 25px;
		@include box-shadow(0 2px 3px rgba($black, 0.2));

		&.se {
			background-color: $green;
			border: 2px solid $white;
			height: 30px;
			line-height: 27px;
			top: 30px;
			left: -36px;
		}
	}
}
