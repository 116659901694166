.CreateGroupMail.CreateGroupMail {
	margin-top: 0 ;
	
	.select2-container {
		width: 100%;
	}

	.Label {
		margin-top: 18px;
	}

	&__next-step {
		margin-top: 40px;
	}
}
