@import "~StyleVariables";

.AdvancedSearchResults {
	$height: 50px;
	position: fixed;
	top: calc(100% - #{$height});
	left: 48px;
	right: 0;
	height: 100%;
	box-shadow: 0 -1px 6px rgba($black, 0.1);
	background-color: $white;
	transition: top ease-out 300ms;

	&--expanded {
		top: 82px;
		overflow-y: auto;
	}

	&__header {
		height: $height;
		border-bottom: 1px solid $grey-3;
		position: relative;
	}

	&__collapsed-header,
	&__expanded-header {
		position: absolute;
		top: 0;
		left: $space * 3;
		right: $space * 3;
		bottom: 0;
		align-items: center;

		.ButtonGroup {
			display: inline-block;
		}
	}

	&__title {
		& > .Text,
		& > .Title {
			display: inline-block;
		}

		.ButtonGroup,
		.Text {
			margin-left: $space * 2;
		}
	}
}
