@import "~StyleVariables";

.ClientCardHeader {
	height: $header-height;
	position: sticky;
	top: $main-top;
	z-index: 100;
	background-color: white;
	justify-content: space-between;
	align-content: center;
	vertical-align: middle;
	box-shadow: $box-shadow-md;
	flex-wrap: nowrap;

	> .Row {
		top: 4px;
		height: 100%;
		justify-content: flex-start;
		white-space: nowrap;
		flex-wrap: nowrap;
		min-width: 0px;
		overflow: hidden;

		.ClientCardHeaderTab {
			padding: 0px 12px;
			border-top: 4px solid rgba(0, 0, 0, 0);
			border-bottom: 4px solid rgba($white, 0);
			flex-grow: 0;
			color: $grey-11;
			text-decoration: none;

			.Text {
				line-height: normal;
			}
			.Text__title {
				color: inherit;
			}

			&__content {
				height: 100%;
				align-items: center;
				flex-wrap: nowrap;
			}

			&--selected {
				border-bottom: 4px solid $dark-green !important;
				color: $black;
			}
		}

		.Tooltip {
			width: 100%;
		}

		> :hover {
			transition: all ease-out 0.3s;
			border-bottom: 4px solid $grey-7;
			color: $grey-13;
		}
	}

	&--remove-frame {
		top: $main-top - 15px;
	}
	&__tabs,
	&__util-buttons {
		> * {
			cursor: pointer;
		}
	}
}
