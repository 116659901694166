@import "_variables.scss";

.SendWebhook {
	$tag-list-width: 280px;

	overflow-x: visible !important;
	overflow-y: visible !important;

	.ModalHeader {
		border-top: none;
	}

	.Textarea__input {
		$LINEHEIGHT: 24px; // Numbers from Textarea component
		$PADDING: 10px; // same here
		height: $LINEHEIGHT * 8 + $PADDING; // Fit 8 lines + padding in the textarea nicely
	}

	&--showTagList {
		transform: translateX(-$tag-list-width / 2) !important;
	}

	&__content {
		width: 750px;
		max-height: 95vh;
		overflow-y: auto !important;
		background-color: $white; // This is so the tag list won't bleed through the modal when animating
	}

	&__grid {
		display: grid;
		grid-template-columns: auto min-content;
		grid-template-rows: repeat(2, auto);
		column-gap: 30px;
		row-gap: 10px;
	}

	&__content-type {
		display: flex;
		gap: 5px;

		> .Tooltip {
			margin: auto 5px;
		}
	}

	&__drop-down {
		width: 100%;

		> .select2-container {
			border-color: $grey-6;
			box-shadow: $box-shadow-md;
		}
	}

	&__header-text {
		gap: 30px;
		.Title {
			margin: auto 0px;
		}
	}

	.ModalControls .Row {
		justify-content: flex-end;
	}

	&__tags {
		position: absolute;
		visibility: hidden;
		top: 0;
		right: 0;
		height: 100%;
		width: $tag-list-width;
		z-index: -1;

		transition: all 300ms cubic-bezier(0.8, 0, 0.8, 1);

		&--translateTagsList {
			visibility: visible;
			transform: translateX($tag-list-width);
		}

		#tag-list {
			top: 0;
			height: 100%;
			width: $tag-list-width;

			#hide-tags-btn {
				position: absolute;
				top: 5px;
				right: 10px;
			}

			> div,
			ul,
			h2,
			p {
				width: $tag-list-width !important;
			}
		}
	}
}
