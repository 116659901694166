@import "~StyleVariables";

.DrawerWrap {
	&__curtain {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $black;
		opacity: 0;
		visibility: hidden;
		transition: all ease 300ms;
		z-index: 10001;
	}

	&__drawer {
		position: absolute;
		top: 0;
		right: -100%;
		height: 100%;
		overflow: hidden;
		transition: right 300ms;
		z-index: 10001;
		box-shadow: -10px 0 20px 0 rgba(47, 57, 71, 0.2);
	}

	&--open &__curtain {
		opacity: 0.2;
		visibility: visible;
	}

	&--open &__drawer {
		right: 0;
	}
}
