@import "~StyleVariables";

.MailEditor {

	&__toolbar {
		position: absolute;
		width: 100%;
		min-height: 36px;
	}

	&__template-placeholder {
		background: $white;
		width: 86%;
		z-index: 1;
		border: $border;
	}

	.FileUploadButtonWrapper {
		z-index: 1;
		background: $white;
		border: $border;
		width: 14%;
		border-left: none;

		.FileUploadButton {
			width: 100%;
			margin-top: -33px;
			display: flex;
			justify-content: center;

			.Button {
				color: $grey-10;
				width: auto;
			}
		}
	}

	.TextEditor {
		.cke_top {
			margin-bottom: 25px;
		}

		.cke_contents {
			textarea {
				margin-top: 10px;
			}
		}
	}

	&--hasTicketResponseTemplates {
		.TemplateDropdown {
			background: $white;
			width: 86%;
			z-index: 1;
			border: $border;

			.Button {
				width: 100%;
				height: 100%;
			}

			.Select__selected {
				border-right: 0;
				border-radius: 0;

				.Text {
					font-style: normal;
				}
			}

			&__menuButton {
				border-radius: 0;
			}

			&__title {
				max-width: inherit;
				min-width: 1%;

				.Tooltip {
					.Text {
						padding-left: $space * 2;
					}
				}
			}

			&__close {
				margin: 0 16px;

				.Icon {
					cursor: pointer;
					color: $grey-10;

					&:hover {
						color: $grey-12;
					}
				}
			}

			&__selectItem {
				max-width: 100%;
			}

			&--isDisplayingTemplateFalse {
				.Select__selected {
					border: none;

					.Text {
						padding-right: $space;
					}
				}
			}

			&--newTicket {
				border-color: $grey-4;
				width: 80%;
			}
		}

		.TemplateSaveDropDown {
			&__input {
				margin-top: $space;
			}

			&__toggle {
				margin-top: $space * 3;

				.Block {
					cursor: pointer;
				}

				.Toggle {
					margin-right: $space;
				}
			}

			&__button {
				margin-top: $space * 5;
			}
		}
	}

	&__bold {
		font-weight: $boldFontWeight;
	}
}

.TemplateDropdown {
	&__selectItem {
		max-width: 100%;
	}

	&__noResults {
		min-height: inherit;
		background-color: $grey-2;
	}
}