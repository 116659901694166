@import "~StyleVariables";

.ListLeads {
	.ProcessedBy {
		width: max-content;
	}
	&__score {
		&--isZero {
			color: $grey-8;
			font-style: italic;
		}
		&--isNanZero {
			font-weight: 700;
		}
	}

	&__notInUpsalesCol {
		display: flex;
		flex-direction: row;
		align-items: baseline;
	}

	&__clientName {
		width: max-content;
	}
	&__addButton {
		margin-top: 6px;
    	margin-left: 4px;
	}

	&__leadRow {
		&--externalClient {
			background-color: rgba($bright-blue, 0.1);
			height: 48px;
		}
	}
}
