@import "~StyleVariables";

.ProductCategorySelect {
	width: 400px;
	position: relative;

	&--useDiscount {
		width: 270px;
	}

	&--tiered, &--bundle {
		.AssistChip {
			display: block !important;
		}
	}

	// This is needed if we don't want to close the drop down when we click on the input field
	.Select__selected {
		z-index: 10000;
	}
	
	&__select-width {
		width: 450px !important;
	}

	.AssistChip {
		z-index: 10001;
		right: 28px;
		top: 7px;
		display: none;
		position: absolute;

		&::before {
			content: "";
			position: absolute;
			left: -21px;
			background: linear-gradient(-90deg, white 20%, transparent);
			height: 20px;
			width: 20px;
		}
	}

	&--tiered .AssistChip {
		pointer-events: none;
	}
	
	&__productButtonSelect {
		width: 96%;
    	margin: 8px;
	}

	&__selectOption {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__selectedItem {
		position: relative;
		
		.Text {
			width: 178px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		
		&__category {
			top: -12px;
			position: absolute;
			font-size: 10px !important;
		}
		
		&--center {
			margin-top: 4px;
			margin-bottom: -4px;
		}

		&--hasIcon {
			padding-left: 20px;
		
			.Icon {
				position: absolute;
				top: $space;
				left: 0px;
			}
		}

		&--center.ProductCategorySelect__selectedItem--hasIcon {
			.Icon {
				position: absolute;
				top: 0;
				left: 0px;
			}
		}
	}

	&__selectedItemWrapper {
		display: flex;
		align-items: center;
		gap: 4px;
	}
}