.Typography {
	.Tabs {
		margin-left: -20px;
		margin-right: -20px;

		&::after {
			content: "";
			position: absolute;
			width: 100%;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
			height: 2px;
			left: 0;
			right: 0;
			bottom: 2px;
		}

		.Tab {
			flex-grow: 0;
			padding: 10px 20px;
		}
	}

	&__WeightSelect {
		font-size: 12px;
		margin-left: 5px;
		margin-right: 5px;
	}
}
