@import "../../../styles/variables";
@import "../../../styles/mixins";

.AccountProfilePreview {
	position: fixed;
	width: 400px;
	right: 0;
	top: 0;
	background: $white;
	position: absolute;
	height: 100%;
	overflow: auto;
	box-shadow: 1px 0 15px rgba($black, 0.2);
}

.AccountProfilePreview__Body {
	width: 360px;
	background: $white;
	margin: 0 auto;
	padding: 15px 0;

	> .Button.btn-link {
		padding: 0;
	}

	.fake-banner-wrap {
		display: block;

		.fake-banner {
			display: inline-block;
			margin-right: 10px;
			margin-bottom: 10px;
			box-shadow: 0 1px 5px rgba($black, 0.2);
			vertical-align: top;
			width: 300px;
			height: 250px;

			iframe {
				border: none;
			}
		}
	}
}

.AccountProfilePreview__Body .Text--lg {
	margin-top: 25px;
}

.AccountProfilePreview__Body .fake-banner {
	margin: 10px 0;
}

#FakeContentWrapper {
	overflow: hidden;
	@include border-radius(3px);
	@include box-shadow(0px 1px 5px rgba($black, 0.2));

	.Text {
		margin-bottom: 1em;
	}

	.profile-header {
		position: relative;
		display: block;
		padding: 10px 20px;

		#contact-us {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			font-size: 12px;
			display: flex;
			align-items: center;
			padding: 0 20px;
		}
	}

	.profile-content {
		padding: 25px 15px;
		margin: 15px;
		line-height: 1.3em;
	}
	.profile-button {
		border: none;
		padding: 7px 20px;
		font-style: 12px;
		min-width: 120px;
	}
	.profile-footer {
		padding: 0px 20px 10px 20px;
		line-height: 20px;
		font-size: 12px;

		& > .pull-right {
			text-align: right;
		}
	}
}
