@import "~StyleVariables";

.DocumentTemplateAvailableTags {
    &__info-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        background-color: $super-light-blue;
        border-radius: $border-radius;
        padding: 8px;
    }

    &__tag {
        color: $grey-11;
        word-break: break-word;
    }

    &__tag-row {
        padding: 8px 12px;

        .Icon {
            transition: opacity 0.3s ease;
            opacity: 0;
        }

        display: flex;
        align-items: center;
        word-break: break-word;
        cursor: pointer;
    }

    &__tag-row:hover {
        background-color: $grey-1;

        .Icon {
            opacity: 1;
        }
    }

    &__tag-column {
        width: auto;
        flex-basis: auto;
        max-width: 80%;
    }

    &__content {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__sidebar-section-content {
        width: 100%;
    }
}