@import "./variables";

.SettingsToggle {
	height: 100%;
	display: flex;
	position: relative;
	background: $white;
	border-radius: 4px;

	&__left-column {
		padding: 20px 30px;
		width: 100%;
		position: relative;
		z-index: 100;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 18px;
		.Label {
			display: flex;
			align-items: center;
		}

		.Toggle {
			margin-right: 10px;
		}
		.ctxUnderline {
			border-bottom: none;
			color: $bright-blue;
		}

		&--toggleDisabled {
			.Label,
			.Title,
			.Text {
				color: $grey-10;
				font-style: italic;
			}
			.ctxUnderline {
				font-style: italic;
			}
		}
	}

	&--toggleDisabled {
		background: $grey-2;
	}

	&__right-column {
		position: relative;
		min-width: 370px;
		margin-left: 20px;
		z-index: 150;
		background-color: $grey-1;

		img {
			width: 300px;
			position: absolute;
			bottom: 0;
			left: 30px;
		}
	}

	&__card-slice {
		position: absolute;
		width: 100px;
		background-color: $grey-1;
		left: 98%;
		transform: skew(-13deg);
		top: 0;
		bottom: 0;
	}
}
