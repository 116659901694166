@import "~StyleVariables";

.FieldType {
	&__Container {
		padding: 5px 20px;

		.Text {
			&--xl {
				margin-bottom: 10px;
			}

			&--md {
				line-height: 20px;
			}
		}
	}

	.Card {
		border: 1px solid $grey-6;
		border-radius: 2px;
		box-shadow: 0 1px 2px 0 rgba($black, 0.1);
		padding: 20px;
		margin: 15px auto;
		cursor: pointer;
		transition: all ease 200ms;

		&:hover {
			background-color: $grey-1;
		}
	}
}
