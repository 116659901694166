@import "~StyleVariables";

.SalesCoachModal {
	.Tabs {
		white-space: nowrap;
	}

	.Tab {
		.ColorSwitcher {
			display: inline-block;
			margin-right: 5px;
		}

		.Text {
			display: inline-block;
		}
	}

	&__controls {
		white-space: nowrap;
		margin-left: auto;
	}

	&__header {
		display: flex;

		.Title {
			display: inline-block;
			max-width: 50vw;
			overflow: hidden;
			display: flex;
			text-overflow: ellipsis;
			align-items: center;
			padding: 5px 10px;
		}
	}

	&__content {
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
}
