@import "~StyleVariables";

.CustomerPortfolioPotentialTab {
	$pageTop: 82px;
	$headerHeight: 50px;
	&__infoBox {
		width: 840px;
	}

	.AssistChip {
		width: fit-content;
	}

	.TableHeader {
		position: sticky;
		top: $pageTop + $headerHeight;
		z-index: 11;
	}
}
