.EasyBookingSettingsFollowup {
	.Label .Title {
		display: inline;
	}

	&__assignWrapper {
		display: inline-flex;
		margin-top: 8px;
		margin-left: 2px;
		 
		.Text {
			margin-left: 8px;
		}
	}
}
