@import "../../../styles/_variables";

.RequestChangePassword {
	&__rule-fulfilled-indicator {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.9);
		color: transparent;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 100%;
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}

	&__rule-fulfilled-indicator--fullfilled {
		background-color: $bright-green;
		color: $green;
	}

	&__rule-checker-wrap {
		display: flex;

		.Row {
			height: 40px;
			align-items: center;
		}
	}

	&__input-wrap {
		.Input__input {
			padding-right: 40px;
		}

		.Icon {
			position: absolute;
			right: 0;
			top: 0;
			height: 50px;
			line-height: 50px;
			width: 40px;
			text-align: center;
			color: $green;
			transition: all ease 200ms;
			cursor: pointer;
		}

		.Icon:hover {
			color: $black;
		}
	}
}
