@import "~StyleVariables";

$tab-height: 50px;

.FormDesign {
	color: $black;
	font-size: 14px;

	&__ScrollContainer {
		max-height: calc(100vh - #{$header-height} - (#{$tab-height} * 2));
		overflow-y: auto;
	}

	.Headline--xs {
		margin-bottom: 10px;
	}

	.tool-main-section {
		padding-top: 0;
		border-bottom: none;

		h3 {
			font-size: 18px;
		}

		.tool-section {
			padding: 0;
			line-height: 36px;
			margin-bottom: 10px;

			label {
				font-weight: $regularFontWeight;
				font-size: 14px;
				color: $black;
			}
		}
	}

	.bg-toggle > div {
		display: inline-block;
		margin-right: 10px;
	}

	.color-input {
		border-radius: 2px;
		width: 80px;
		height: 34px;
	}

	.CustomCss {
		margin-top: 20px;
		border-radius: 2px;
		padding: 30px 81px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		box-shadow: none;

		.Text {
			margin-bottom: 15px;
		}
	}
}

#editor-toolbox {
	.FormDesign {
		.field-row {
			transition: all ease 200ms;
			padding: 20px 20px 10px;

			&.last {
				border: none;
				margin-bottom: 80vh;
			}
		}
	}
}
