#top-graph-wrapper {
	$graph-sidebar-width: 200px;

	background-color: $grey-1;
	position: relative;
	@include border-radius($border-radius);

	#title {
		display: inline-block;
		line-height: $header-height;
	}

	.graph-header {
		background-color: $grey-1;
		height: $header-height;
		@include border-radius($border-radius $border-radius 0 0);

		#title {
			margin-left: 15px;
		}

		.actions {
			margin-left: 15px;

			&.center {
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				text-align: center;
				top: 0;
			}

			.graph-date-wrapper {
				padding-top: 20px;

				button.up-btn {
					height: 36px;
					position: relative;

					&.interval {
						border: 1px solid $grey-8;
						box-shadow: none;
						@include border-radius($border-radius 0 0 $border-radius);
						// border-right-width: 0;
					}
				}

				.btn-group {
				}

				.calendar {
					display: inline-block;
					@include border-radius(0);
					margin-left: -1px;

					.fa {
						top: 10px;
					}

					.form-control {
						border-radius: 0;
						height: 36px;
					}

					&.last {
						.form-control {
							@include border-radius(0 $border-radius $border-radius 0);
							margin-left: -1px;
						}
					}
				}
			}

			.vs-badge {
				position: absolute;
				color: $grey-8;
				display: inline-block;
				background-color: $white;
				border-radius: 50%;
				border: 1px solid $grey-8;
				font-size: 8px;
				padding: 0px;
				width: 17px;
				height: 17px;
				line-height: 17px;
				z-index: 999;
				right: -10px;
				text-align: center;
			}

			.graph-series-wrapper {
				$graph-button-width: 150px;

				.graph-series-btn {
					color: $grey-13;
					position: relative;
					border: 1px solid $grey-8;
					box-shadow: none;
					@include border-radius($border-radius 0 0 $border-radius);
					margin-left: -1px;
					text-align: left;
					min-width: $graph-button-width;

					&.first {
						padding-right: 12px;
						margin-left: 10px;
						color: $bright-blue;
					}

					&.second {
						border-radius: 0;
						padding-left: 20px;
						padding-right: 12px;
						@include border-radius(0 $border-radius $border-radius 0);
						color: $orange;
					}

					.caret {
						float: right;
						margin-top: 8px;
						color: $grey-8;
					}
				}

				.dropdown-menu {
					max-width: $graph-button-width * 2;
					margin-top: -2px;
					@include box-shadow(1px 1px 2px rgba($black, 0.1));
					text-align: left;
					@include border-radius(0 0 $border-radius $border-radius);
					right: 0;
					left: auto;
					padding: 0;
					border-top: none;

					li {
						a {
							padding: 10px;
							display: block;

							&.disabled {
								color: $grey-8;

								&:hover {
									background-color: $white;
									color: $grey-8;
								}
							}

							&:hover {
								background-color: $super-light-blue;
								color: $blue;
								text-decoration: none;
							}
						}
					}

					.divider {
						margin: 0;
					}

					.first {
						width: 148px;
						float: left;
						padding: 0;
					}

					.second {
						width: 149px;
						float: left;
						border-left: 1px solid $grey-8;
						padding: 0;
					}
				}
			}
		}
	}

	.no-content-info {
		padding: 100px 30px;
		text-align: center;
		@include border-radius($border-radius);
		background-color: $grey-6;
		color: $grey-11;

		img {
			display: block;
			margin: 0 auto 40px auto;
			max-width: 100%;
			text-align: center;
		}

		h2 {
			font-size: 200%;
			margin-bottom: 10px;
			color: $grey-11;
		}
	}

	.graph-main {
		background-color: $grey-1;
		padding: 20px;
	}

	.graph-bottom {
		@include border-radius(0 0 $border-radius $border-radius);
		height: 90px;

		.graph-stat-col {
			text-align: center;
			padding: 14px 20px;
			border-radius: $border-radius 0 0 $border-radius;
			border: 1px solid $grey-4;
			background-color: $white;
			@include box-shadow(1px 1px 2px rgba($black, 0.1));

			&:last-of-type {
				border-left: none;
				border-radius: 0 $border-radius $border-radius 0;
			}

			h3 {
				color: $grey-13;
				margin: 0;
			}

			.description {
				color: $grey-10;
				text-transform: uppercase;
				font-size: 10px;
				margin-bottom: 0;
			}
		}
	}
}

#account-card {
	&.has-ad-graph {
		ul.nav {
			clear: none !important;
			margin: 0 !important;
			border-bottom: none !important;
		}

		#main-content {
			.page-content {
				margin-top: 0 !important;
			}
		}
	}
}
