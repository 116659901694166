@import "~StyleVariables";

.EditCallListDrawer {
	width: 480px;
	height: 100%;
	background-color: $white;

	.Label--required {
		font-weight: $boldFontWeight;
	}

	.DrawerHeader > .Row .Button {
		height: 100%;
		border-radius: 0;
	}

	&__content {
		overflow: auto;
		max-height: calc(100vh - 50px);
	}
}