@import "~StyleVariables";

.AssignModalActivityRow__close-col {
	opacity: 0;
}

.AssignModalActivityRow:hover .AssignModalActivityRow__close-col {
	opacity: 1;
}

.AssignModalActivityRow__user .Avatar {
	display: inline-block;
	margin-right: 5px;
}
.AssignModalActivityRow__user .Text {
	display: inline-block;
}

.AssignModalActivityRow__user .Icon {
	color: $grey-8;
	vertical-align: top;
	margin-left: 5px;
	margin-top: 5px;
}

.AssignModalActivityRow__user--inactive {
	color: $grey-8;
	font-style: italic;
}

.AssignModalActivityRow__user__multi-avatar--inactive img,
.AssignModalActivityRow__user--inactive .Avatar img {
	filter: grayscale(100%);
}

.AssignModalActivityRow__user__name {
	display: inline-block;
	max-width: 142px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


.AssignModalActivityRow__type-column {
	overflow-wrap: break-word; /* Break long words */
	-ms-word-break: break-all;  /* Ensures compatibility with older browsers, IE */
	word-break: break-all;
	word-break: break-word; /* Non standard for WebKit */
	width: 68%;
	white-space: normal; /* Allow text to wrap */
}   