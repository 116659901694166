@import "~StyleVariables";

.PublishingOptions {
	.input-group {
		margin: 5px 0 15px;

		.form-control {
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
			background-color: $white;
			padding-left: 10px;
			padding-right: 10px;
		}

		.Button {
			border: none;
			box-shadow: none;
			border-radius: 0;
			height: 32px;
		}

		&-addon {
			padding: 0;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
	}

	&__Header {
		padding-left: 30px;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		position: relative;

		.Tabs {
			display: block;
		}

		.Headline,
		.Text {
			margin-bottom: 20px;
		}

		&--left-col {
			padding-top: 30px;
		}

		&--right-col {
			background-size: 157px;
			position: relative;
			overflow: hidden;

			&:before {
				content: "";
				position: absolute;
				width: 296px;
				height: 300px;
				top: -30px;
				left: 146px;
				-webkit-transform: rotate(7deg);
				transform: rotate(7deg);
				z-index: 3;
				background-color: $grey-4;
			}
		}
	}

	&__IframePreview {
		z-index: 3;
		position: absolute;
		height: 100%;
		overflow: hidden;
		top: 10px;
		width: 100%;

		.FormPreview-area {
			width: 250px;
			left: calc(50% - 125px);
		}

		iframe {
			width: 400px;
			transform: scale(0.5);
			height: 500px;
		}

		&:before {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: 1;
			opacity: 0;
		}
	}

	&__Content {
		padding: 2px 27px;
		background-color: $grey-1;

		.Card:last-child {
			margin-bottom: 400px;
		}

		.Text--xl {
			margin-bottom: 10px;
		}

		> .Card {
			max-width: 800px;
			width: 100%;
			margin: 25px auto;
		}
	}

	a.Button,
	a.Button:hover {
		text-decoration: none;
	}
}
