@import "~StyleVariables";
@keyframes fromAbove {
	0% {
		transform: translateY(-50%);
	}
	100% {
		transform: translateY(0);
	}
}
.GoalsInput {
	transition: all 0.3s linear;
	border-color: white;
	display: flex;
	align-items: center;
	&--hovered {
		.Input {
			border-bottom: 1px solid $grey-8;
		}
	}
	&--focused {
		.Input {
			border-bottom: 1px solid $bright-blue;
		}
	}
	.Tooltip {
		animation: 0.25s fromAbove;

		> .Icon {
			cursor: pointer;
			font-size: 20px;
		}
	}
	&__input {
		&--isZero {
			input {
				color: $grey-8 !important;
				font-style: italic;
			}
		}
	}
	&__icon-placeholder {
		width: 20px;
	}
}
