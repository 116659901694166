@import "../../../../../styles/_variables";

td.DataSourceColumn,
div.DataSourceColumn {
	padding: 5px 15px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	border-top: 1px solid $grey-2;

	&.journey {
		text-align: right;

		.JourneyStepIcon {
			display: inline;
		}
	}

	&.unlinkSubaccount {
		text-align: right;

		.inline-confirm-modal .inline-confirm-container {
			top: auto;

			right: 18px;
			max-width: 400px;
			white-space: normal;
			text-align: left;
			line-height: 1.42857;
		}

		.InlineConfirm.inline-confirm-modal {
			.inline-confirm-tab.keep-position {
				background-color: $white;
			}
		}

		.btn.up-btn.btn,
		.up-btn.btn {
			background-color: $green;
		}
	}

	&.confirm-open {
		.show-on-row-hover {
			display: initial;
		}

		.expand-on-row-hover {
			max-width: 300px;
		}

		.margin-left-on-hover {
			margin-left: 5px;
		}

		.hide-on-row-hover {
			display: none;
		}
	}

	.subaccount-flag {
		width: 14px;
	}

	.chevron-icon {
		cursor: pointer;
		background-color: $grey-2 !important;
		transition: ease-in 0.2s;
		border-radius: $border-radius;
		// Need 5px to match the mock
		padding: 5px;
		height: 24px;

		&:hover {
			background-color: $grey-5 !important;
		}
	}

	&.overflow-visible {
		overflow: visible !important;

		span {
			overflow: visible !important;
		}
	}

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.row-text {
		height: 16px;
		font-size: 14px;
		line-height: 14px;
		display: block;
	}

	.no-account-manager {
		color: $grey-10;
		font-style: italic;
	}

	.with-account-manager {
		display: grid;
		grid-template-columns: auto 1fr;
		justify-items: start;
		align-items: center;
		gap: $space;
	}

	.subtitle {
		font-size: 11px;
		line-height: 11px;
		margin-top: 5px;
		display: block;
		color: $grey-10;
	}

	&.view-column {
		text-align: right;

		button {
			padding-right: 0;
		}
	}

	&.group-size-column {
		.fa {
			color: $grey-8;
		}
	}

	.grey-text {
		color: $grey-10;
	}

	&.add-company-column {
		text-align: right;
		overflow: visible !important;

		.merge-buy-group {
			float: right;
		}

		button {
			min-width: 50px;
		}

		> :not(first-child) {
			margin-left: 5px;
		}

		.Logo {
			height: 20px;
			width: 19px;
			margin-top: 5px;
			display: inline-block;

			svg {
				height: 100%;
			}

			.Logo__letter:not(.Logo__letter--u) {
				display: none;
			}
		}

		.added-text {
			color: $bright-green;
		}

		.merge-wrapper {
			height: 40px;
			line-height: 40px;
			vertical-align: middle;
			float: right;

			.select2-container {
				width: 250px;
				display: inline-block;
				margin-right: 10px;
				text-align: left;
			}

			> :not(first-child) {
				margin-left: 5px;
			}
		}
	}

	&.parent-line-column {
		position: relative;

		&:before {
			content: "";
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			border-left: 1px solid $grey-6;
		}

		&:after {
			content: "";
			width: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			border-top: 1px solid $grey-6;
		}
	}

	&.last-parent-line-column {
		position: relative;

		&:before {
			content: "";
			height: 50%;
			width: 50%;
			position: absolute;
			top: 0;
			right: 0;
			border-left: 1px solid $grey-6;
			border-bottom: 1px solid $grey-6;
			border-radius: 2px;
		}
	}

	&.straight-parent-line-column {
		position: relative;

		&:before {
			content: "";
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			border-left: 1px solid $grey-6;
		}
	}

	.create-account-content__icon {
		margin-right: 2px;
	}

	.create-account-content__text {
		font-size: 14px;
		line-height: 14px;
		display: block;
	}

	.create-account-content__subtext-wrap {
		line-height: initial;
		margin-top: 3px;
	}

	.create-account-content__subtext {
		display: inline-block;
		font-size: 11px;
		line-height: 11px;
		color: $grey-10;

		&:not(:last-child) {
			margin-right: 10px;
		}
	}

	&.accountManager {
		display: table-cell;
		vertical-align: middle;
		line-height: 1;

		.Tooltip {
			display: table-cell;
			float: left;
			margin-right: 5px;
		}

		.Avatar {
			vertical-align: top;
		}

		.Text {
			display: inline-block;
			line-height: 20px;
			margin-left: 4px;
		}
	}

	&.name-existing {
		$columSelector: "& > div";

		#{$columSelector} {
			vertical-align: top;
			display: inline-block;
		}

		#{$columSelector}:last-child {
			margin-left: 20px;
		}
	}
}
