@import "~StyleVariables";

.ProjectDetails {
	&__saveButton {
		position: sticky;
		left: calc(50% - 150px);
		bottom: 50px;
		width: 300px;
	}

	&__container {
		width: 100%;
	}

	&__order-relation {
		.RelationRow {
			padding: $space * 3;
			border-radius: $border-radius;
			border: 1px solid $grey-6;
			cursor: pointer;
		}
	}

	&__row {
		display: flex;
		flex-direction: row;
		gap: $space * 3;
	}

	&__containerGrid {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr 1fr;
		grid-gap: $space * 3;
	}

	&__column {
		width: 50%;
	}

	.Input {
		width: 100%;
	}

	.Label {
		font-weight: bold;
	}

	.CustomFields__formGroupTitle {
		margin-bottom: $space * 3;
	}

	/** 
	* @todo  @upsales/components 2.12.3
	* STYLING: Had to modify a modal that should have the same size and appearence as the date input/select dropdown.
	* {@link https://github.com/upsales/ui-components/issues/496}
	*/
	.ModalContent {
		box-shadow: $box-shadow-lg;
		border: $border;
		border-radius: $border-radius;
		background-color: $white;
		z-index: 1;

		.ModalControls {
			text-align: left;
		}
	}

	.AfterSelectPopup {
		position: absolute;
		bottom: 0;
		margin-top: 56px;

		&--StartDate {
			width: 354px;
			height: 260px;
		}

		&--User {
			width: 363px;
			height: 138px;
		}

		&--altConfirm {
			width: 450px;
		}

		&__alt-button {
			margin: 0 3 * $space;
		}

		&__cancel-button {
			float: right;
		}
	}

	&__dateinput-wrapper {
		position: relative;

		.FormComponent:first-of-type {
			min-width: 200px;
		}
		.FormComponent:last-of-type {
			width: -webkit-fill-available;
		}
	}
	&__ContactInput,
	&__AssigneeInput {
		margin-bottom: 60px;
	}

	&__ContactInput {
		// match default margin (same as min height of .FormComponent__message)
		.CreateCallClientContactSelect {
			margin-bottom: 18px;
		}
	}
}
