@import "~StyleVariables";
$width: 202px;
$forecastHeight: 103px;
$headerHeight: 50px;

.SalesboardQuotas {
	width: $width;
	position: fixed;
	right: -$width;
	bottom: 16px;
	box-shadow: 0 3px 6px rgba($black, 0.2);
	transition: right 0.4s;
	top: 98px;

	&--visible {
		right: 8px;
	}

	&__charts {
		background-color: $white;
		height: calc(100% - #{$headerHeight});
		min-width: $width;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		overflow-y: auto;
		overflow-x: hidden;

		&--noForcast {
			height: auto !important;
		}
	}

	&__chart-wrapper {
		white-space: nowrap;
		position: relative;
		margin-left: 8px;
		margin-right: 8px;
		padding-bottom: 8px;
		padding-top: 8px;
		border-bottom: 1px solid $grey-8;

		&:last-of-type {
			border: none;
		}
	}

	&__chart {
		height: 150px;
		width: 184px;
	}

	&__chart-values {
		text-align: center;
		white-space: normal;
	}

	&__chart-info {
		position: absolute;
		width: 110px;
		top: 25%;
		left: 37px;
		text-align: center;
	}

	&__info-label {
		text-transform: uppercase;
	}

	&__header {
		background-color: $white;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 16px;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		.Button {
			height: 50px;
			width: 50px;

			&:hover {
				background-color: $grey-2;
			}

			.Icon {
				font-size: 14px;
				font-weight: 700;
			}
		}
	}

	&--hasForecast {
		.SalesboardQuotas__charts {
			height: calc(100% - #{$forecastHeight});
		}
	}

	// This will override Quota CSS until REMOVE_FRAME is released
	&--remove-frame:is(&__charts) {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	&--remove-frame {
		bottom: 0px;
		top: 82px;
		right: 0px;
	}
}
