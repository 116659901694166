@import "~StyleVariables";

.SignalsNews {
	width: 800px;
	height: 100%;

	.Drawer__children {
		height: 100%;
	}

	&__header {
		&__controls {
			width: calc(100% - 50px);
		}
	}

	.SignalsFeed {
		&__content {
			height: 100%;
			overflow-y: auto;
		}
		&__lottie {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&__itemRow {
			padding: $space * 2 $space * 3;
			margin: 0 !important;
		}
		&__item {
			width: 100%;
			margin: 0 !important;
			> .Card {
				border-radius: $border-radius;
			}
		}
	}
}
