@import "~StyleVariables";

.RequiredProratedCheckbox {
	margin-bottom: 100px;

	&__one-offs {
		--height: 0px;
		cursor: pointer;
		transition: all cubic-bezier(0.46, -0.31, 0.44, 1.32) 300ms;
		height: var(--height);
		overflow: hidden;

		.Icon {
			animation: rotateBackAnimation 1 400ms;
			transform: rotate(0deg);
		}

		&--rotate {
			.Icon {
				animation: rotateAnimation 1 400ms;
				transform: rotate(180deg);
			}
		}
	}

	&__one-offs-header {
		width: stretch;
	}
}
