.OpportunityRow {
	&__row {
		height: 48px;
		.Row {
			align-items: center;
		}

		.Link {
			max-width: 320px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&--isDropDown {
			.TableColumn {
				border-color: white;
				height: 40px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				max-width: 340px;
			}

			.Link {
				max-width: 290px;
			}
		}
	}
}
