@import "~StyleVariables";

.CommentInput {
	&__commentField {
		position: relative;
		background-color: $white;

		.Icon {
			position: absolute;
			top: 10px;
			left: 10px;
			font-size: 16px;
		}

		.Textarea__input {
			padding-left: 40px;
			max-height: 268px;
			min-height: 36px;
			overflow-x: hidden;
			overflow-y: auto;
		}

		.MentionsInput {
			margin-bottom: 4px;
			> .DraftEditor-root {
				border-radius: 4px;
				border: 1px solid $grey-6;
				box-shadow: inset 1px 1px 2px 0 rgba($black, 0.1);
				padding-left: 32px;
			}
		}
	}
}
