@import "~StyleVariables";

.MailTemplatePreviewAddButton {
	z-index: 1002;
	position: absolute;

	&:before {
		content: "";
		position: absolute;
		background-color: $green;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
	}

	&--horizontal {
		height: $space * 2;
		margin-top: $space * -3;
		width: 100%;

		&:before {
			top: 9px;
			left: 0;
			right: 0;
			height: 3px;
		}

		.MailTemplatePreviewAddButton__button {
			left: 50%;
			margin-left: -15px;
			top: -5px;
		}

		&.MailTemplatePreviewAddButton--first .MailTemplatePreviewAddButton__button {
			height: 25px;
			line-height: 25px;
		}

		&.MailTemplatePreviewAddButton--first {
			margin-top: 0;

			&:before {
				top: 0;
			}

			.MailTemplatePreviewAddButton__button {
				top: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}

	&--vertical {
		width: 20px;
		margin-top: 0;
		margin-left: -10px;
		height: 100%;
		left: 0;
		top: 0;

		&:before {
			top: 0;
			left: 9px;
			bottom: 0;
			width: 3px;
		}

		&.MailTemplatePreviewAddButton--last {
			right: 0;
			margin-right: -10px;
			margin-left: 0;
			left: initial;

			&:before {
				left: initial;
				right: 9px;
			}
		}

		.MailTemplatePreviewAddButton__button {
			top: 50%;
			margin-top: -15px;
			left: -5px;
		}
	}

	&:hover {
		.MailTemplatePreviewAddButton__button,
		&:before {
			visibility: visible;
			opacity: 1;
		}
	}

	&__menu {
		$height: 350px;
		position: absolute;
		left: 50%;
		margin-left: -150px;
		top: 50%;
		margin-top: -($height / 2);
		z-index: 10001;
	}

	&__button {
		visibility: hidden;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		position: absolute;
		opacity: 0;
		padding: 0;
		border: none !important;
	}
}
