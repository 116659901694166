@import "~StyleVariables";

.ClientCardContent {
	height: 100%;

    &--construction {
		justify-content: center;
		height: 100%;
		background: repeating-linear-gradient(
            45deg,
            #ffd64033,
            #ffd64033 10px,
            #e8593133 10px,
            #e8593133 20px
        );
    }
}
