@import "~StyleVariables";

.SubAccountStateFrame {
	overflow: unset;

	.inline-confirm-modal .inline-confirm-container {
		max-width: 400px;
		text-align: left;
		margin-top: $space * 3;
		margin-right: $space * 10.5;

		.inline-confirm-control {
			padding-top: $space * 2;
		}
	}

	.InlineConfirm.inline-confirm-modal {
		.inline-confirm-tab.keep-position {
			background-color: $white;
		}
	}

	.btn.up-btn.btn,
	.up-btn.btn {
		background-color: $green;
	}
}
