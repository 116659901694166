.HiddenContactSelect {
	z-index: 1001;

	.Select__arrow {
		display: none;
	}

	.Select__focus-input .Input__input {
		width: 350px;
	}

	&__row {
		height: 60px;
	}

	&__title {
		word-break: break-all;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
		white-space: nowrap;
	}

	&__userInfo {
		min-width: 280px;
		overflow: hidden;
	}

	&__subtitle {
		display: block;
	}
}
