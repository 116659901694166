@import "~StyleVariables";

.calculation-field-select2-drop-down {
	min-height: 320px;

	.select2-results {
		min-height: 320px;
	}
}

.EditCustomField {
	min-height: 250px;

	.ModalHeader {
		display: flex;

		.ModalHeader__icon {
			display: inline-block;
			margin-right: 10px;
		}
	}

	.Flex {
		.Progress {
			width: 20px;
		}
	}

	.editor-error #editor {
		background-color: $super-light-red;
	}

	&__pre-defined-wrap {
		display: inline-block;
		padding: 0 20px;
		margin-top: 10px;

		> p {
			display: inline-block;
			margin-right: 5px;
			margin-bottom: 0;
		}

		.select2-dropdown-open {
			position: absolute;
			width: 285px;
			background: $white;
			border-color: $bright-blue;
			border-radius: 3px 3px 0 0;
		}

		.select2-search-choice-close {
			right: -2px;
			z-index: 10000;
		}

		.select2-dropdown-open {
			.select2-search-choice-close {
				display: none;
			}
		}

		a.select2-choice {
			height: auto !important;
			background: none;
			border: none;
			outline: none;

			.select2-chosen {
				padding: 0;
				padding-right: 12px;
				margin: 0;
				background: none;
				color: $bright-blue;
			}

			span.select2-arrow {
				opacity: 0;
			}
		}
	}

	&__predefined-select {
		padding: 3px 5px;
		border: 1px solid transparent;
	}

	&__tabs {
		height: 100%;
		position: absolute;
		width: 300px;
		margin-left: -150px;
		left: 50%;
		top: 0;

		.ColorSwitcher {
			border-radius: 50%;
			width: 17px;
			height: 17px;
			display: inline-block;
			line-height: 17px;
			font-size: 13px;
			margin-right: 5px;
		}

		.Tab {
			width: 50%;
			text-align: center;
		}
	}

	&__form {
		padding: 20px;
		padding-bottom: 20px;
	}

	&__type-select-container {
		position: relative;
		height: 100%;
		padding: 7px;
	}

	&__type-select {
		height: 110px;
		width: 140px;
		border: 5px solid rgb(246, 249, 251);
		vertical-align: middle;
		text-align: center;

		&--active {
			.EditCustomField__type-wrapper {
				background-color: $green;
				box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
				border-color: $green;
			}
			.EditCustomField__type-select-icon,
			.EditCustomField__type-select-text {
				color: $white;
			}
		}

		&:hover {
			.EditCustomField__type-wrapper {
				background-color: $green;
				box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
				border-color: $green;
			}
			.EditCustomField__type-select-icon,
			.EditCustomField__type-select-text {
				color: $white;
			}
		}
	}

	&__type-select-icon {
		color: $green;
		font-size: 24px;
		line-height: 26px;

		&.--number {
			font-weight: $boldFontWeight;
		}

		&.--yn {
			font-size: 16px;
			font-weight: $boldFontWeight;
		}
	}

	&__type-select-text {
		margin-top: 8px;
		line-height: 1;
		font-size: 14px;
	}

	&__type-wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
		border: 1px solid $grey-4;
		box-shadow: 0 1px 2px 0 rgba(255, 245, 245, 0.2);
		background-color: $white;
		cursor: pointer;
		transition: all ease-in-out 200ms;
	}

	&__controls-link {
		left: 10px;
		position: absolute;
		line-height: 35px;
		text-decoration: none;
	}

	&__toggle-wrap {
		margin-top: 10px;

		.Label {
			display: inline-block;
			margin-left: $space * 2;
		}

		& > div {
			margin-top: 10px;
		}
	}

	.select2-choice {
		box-shadow: inset 1px 1px 2px 0 rgba(30, 37, 46, 0.1);
		border: 1px solid $grey-6;
		border-radius: 2px;

		&.select2-default {
			/* Need important here as select2 has it in their styling */
			color: $grey-8 !important;
			font-style: italic;
		}
	}

	.select2-choices {
		box-shadow: inset 1px 1px 2px 0 rgba(30, 37, 46, 0.1);
		border: 1px solid $grey-6;
		border-radius: 2px;

		.select2-default {
			/* Need important here as select2 has it in their styling */
			color: $grey-8 !important;
			font-style: italic;
		}
	}

	// Convert to BEM?
	.formula-container {
		position: relative;
		background-color: $white;
		box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		margin-top: 15px;
		margin: 15px -15px 0 -15px;
		height: 250px;
		padding-top: 10px;

		.Text {
			padding-left: 15px;
		}

		.toggle-wrap {
			margin-top: $space * 2;

			.Label {
				display: inline-block;
				margin-left: $space * 2;
			}

			.Toggle {
				margin-bottom: $space * 2;
				margin-left: $space * 4;
			}
		}

		.formula-validation {
			position: absolute;
			bottom: 15px;
			left: 15px;
			color: $red;
		}

		.title {
			padding: 0 15px;

			.title-text {
				font-size: 17px;
				font-weight: $boldFontWeight;
			}

			button {
				float: right;
			}
		}

		*::-webkit-scrollbar {
			height: 7px;
		}

		*::-webkit-scrollbar-track {
			background: $white;
			border-radius: 10px;
		}

		::-webkit-scrollbar-thumb {
			background: $grey-7;
			border-radius: 10px;
		}

		.scroll-content {
			padding-left: 15px;
			padding-top: 10px;
			padding-right: 15px;
			width: 100%;
			margin: 0;
			float: left;

			.Editor {
				.mentionSuggestions {
					max-height: 150px;
					transform: scale(1) !important;
					transition: none !important;
				}
			}

			&.invalid {
				.Editor {
					border: 1px solid red;
				}
			}

			.segment {
				margin-top: 10px;

				.open-bracket,
				.close-bracket {
					height: 50px;
					display: flex;
					align-items: center;
					color: $bright-blue;
					font-size: 70px;
					margin: 0 5px;
					z-index: 8;
				}
				.open-bracket {
					margin-left: 0;
				}
			}

			.select-item {
				display: inline-flex;
			}

			.dropdown {
				width: 20px;
				height: 20px;

				.btn {
					width: 100%;
					height: 100%;
					padding: 1px 5px;
				}
			}
		}

		.dropdown-wrapper {
			margin-left: 3px;
			height: 50px;
			display: flex;
			align-items: center;

			.dropup {
				margin-left: 5px;
				width: 20px;
				height: 20px;
				position: relative;
				.dropdown-menu {
					left: -75px;
					width: 180px;
					padding: 3px 6px;
					.dropdown-item {
						cursor: pointer;
						width: 20px;
						height: 20px;
						background: $white;
						border: 1px solid $grey-6;
						color: $grey-11;
						font-weight: $boldFontWeight;
						margin-right: 5px;
						display: inline-block;
						text-align: center;
						border-radius: 3px;
						&.active {
							background: $bright-blue;
							color: white;
						}
						&.brackets {
							width: auto;
							float: right;
							padding: 0 5px;
						}
					}
				}
				.btn {
					position: absolute;
					right: -11px;
					background: white;
					border: 1px solid $grey-8;
					color: $grey-7;
					font-weight: $boldFontWeight;
					z-index: 10;
					width: 100%;
					height: 100%;
					padding: 0px 0px;
					border-radius: 2px;
					&:before {
						content: "\f0d8";
						font-family: FontAwesome;
						position: absolute;
						top: -17px;
						left: 5px;
					}
					&:after {
						content: "\f0d7";
						font-family: FontAwesome;
						position: absolute;
						top: 17px;
						left: 6px;
					}
					&.active {
						color: $bright-blue;
					}
				}
				&:before {
					content: ".........";
					font-size: 26px;
					position: absolute;
					top: -15px;
					left: -10px;
					color: $grey-6;
					width: 28px;
				}
				&.grouped-right {
					position: absolute;
					right: 0;
					&.middle-dropup {
						right: -6px;
						&:before {
							content: ".......";
							left: -13px;
						}
						.btn {
							right: -1px;
						}
					}
					.btn {
						right: -4px;
					}
				}
				&.grouped-left {
					margin-left: 10px;
					position: absolute;
					right: -15px;
					.btn {
						right: 3px;
					}
					&:before {
						content: ".......";
						left: -15px;
					}
				}
				&.group-middle {
					margin-left: 7px;
				}
			}
		}
	}

	.field-select {
		position: relative;
		width: calc(100% - 20px);
		min-width: 210px;
		.add-button {
			width: 100%;
			background-color: $white;
			height: 50px;
			border: 1px solid #d5d5d5;
			color: $bright-blue;
			font-size: 14px;

			.plus-icon {
				border-radius: 50%;
				width: 25px;
				height: 23px;
				color: $white;
				display: inline-block;
				line-height: 20px;
				background-color: $bright-blue;
				font-size: 14px;
				padding-top: 2px;
				margin-right: 10px;
			}

			&.selected-field {
				border-left: 2px solid $bright-blue;
				border-right: 1px solid #d5d5d5;
				border-top: 1px solid #d5d5d5;
				border-bottom: 1px solid #d5d5d5;
				text-align: left;
				padding-left: 20px;
			}
		}
		.sub-field {
			min-width: 210px !important;
		}
		.select-popup {
			background-color: $white;
			border: 1px solid $grey-7;
			border-radius: 2px;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
			width: 315px;
			position: absolute;
			top: 0;
			z-index: 999;
			&.right-elem {
				right: 0;
			}
			.tabs-wrapper {
				width: 100%;
				height: 50px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.fa-trash {
					margin-right: 15px;
					color: $grey-10;
					font-size: 14px;
					cursor: pointer;
				}
			}

			.fields-wrapper {
				background-color: $grey-1;
				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
				padding: 15px;

				.input-select {
					background: $white;
					cursor: pointer;
				}

				label {
					font-weight: $boldFontWeight;
				}

				.fields {
					display: flex;
					width: 100%;
					justify-content: space-between;
					&.fields_number {
						.form-control {
							width: 79%;
						}
					}
					&.fields_select {
						.form-control {
							width: 95%;

							&.select2-dropdown-open {
								width: 285px;
								position: absolute;
							}
						}
					}

					> div {
						width: 100%;
					}
				}
			}
		}
	}

	&__product-category-select {
		margin-top: 12px;
	}
}
