@import "../../../../styles/variables";

.NotificationCenter {
	.CardHeader {
		padding: 0 15px;
	}

	.TableColumn {
		height: 50px;
		font-size: 14px;

		.CancelBtn {
			padding-right: 4px;
		}
	}

	.Card + .Card {
		margin-top: 30px;
	}

	&__notificationTable {
		.TableHeader__column:nth-child(1) {
			width: 50%;
		}
		.TableHeader__column:nth-child(2) {
			width: 25%;
		}
		.TableHeader__column:nth-child(3) {
			width: 25%;
		}
		&--editing {
			.TableHeader__column:nth-child(2) {
				width: 220px;
			}
		}
	}
}

.NotificationCenter .TableColumn.DropdownCol {
	.select2-container {
		width: 90px;
		margin-right: 10px;

		.select2-choice {
			height: 28px;
			font-size: 12px;

			.select2-arrow b {
				background-position: 0 1px;
			}
		}

		.select2-chosen {
			padding: 8px 6px 6px;
			font-size: 12px;
			line-height: 1;
		}
	}
}

.NotificationCenter__reminderUnit--DropdownResults,
.NotificationCenter__reminderTime--DropdownResults {
	.select2-result-label {
		font-size: 12px;
		line-height: 1;
		padding: 8px 6px 6px;
	}
}

.NotificationCenter .TableRow .PreviewLink {
	opacity: 0;
	transition: all 0.2s;
	width: 160px;
	text-align: right;
}

.NotificationCenter .TableRow:hover .PreviewLink,
.NotificationCenter .TableRow.ActiveRow .PreviewLink {
	opacity: 1;
	transition: all 0.2s;
}

.NotificationCenter .TableRow.ActiveRow {
	background: $grey-1;
}

.NotificationCenter .TableRow.DisabledRow {
	box-shadow: inset 4px 0 $grey-8;
	background: $grey-1;
	color: $grey-9;
	font-style: italic;
}

.NotificationCenter .TableRow.DisabledRow .Text,
.NotificationCenter .TableRow.DisabledRow .TableColumn {
	font-style: italic;
	color: $grey-9;
}

#admin {
	#admin-root.NotificationCenter {
		#admin-content {
			padding-bottom: 30px;
		}
	}
}

.NotificationCenter .Checkbox {
	display: inline-block;
}

.NotificationCenter .Table {
	table-layout: fixed;
}

.NotificationCenter .TableHeader__column:last-child {
	text-align: right;
	padding-right: 15px;
}

.NotificationCenter .TableColumn:first-child {
	.Text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		transition: all cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		transition-property: top, opacity;
	}

	.Text:nth-child(1) {
		position: relative;
		top: 0px;
	}

	.TooltipOverflowText {
		opacity: 0;
	}
}

.NotificationCenter .TableColumn:first-child.TableColumn--withSubtitle {
	.Text:nth-child(1) {
		top: 10px;
	}
}

.NotificationCenter .TableColumn:first-child.TableColumn--withSubtitle:hover {
	.Text:nth-child(1) {
		top: 0px;
	}

	.TooltipOverflowText {
		opacity: 1;
	}
}

.NotificationCenter__prospectingTable .CardContent {
	.Text {
		display: inline;
	}

	._elevio_underline {
		// It has transition all from some global styling
		transition: margin 0 ease-in;
		margin-left: 5px;
	}
}

.NotificationCenter__prospectingUpgrade {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	border: 1px solid $grey-5;
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.NotificationCenter .flag-icon {
	font-size: 12px;
	margin-left: 5px;
}
