@import "~StyleVariables";

.BoardColumn {
	$columnBorderRadius: $border-radius * 2;
	$headerHeight: 70px;

	min-width: 200px;
	background-color: $grey-2;
	border-radius: $border-radius * 2;
	min-height: 300px;
	position: relative;

	// This is to makethe column header keep its border radius when sticky
	&:before {
		content: "";
		background-color: $white;
		position: sticky;
		right: 0;
		left: 0;
		top: 0;
		height: $columnBorderRadius;
	}

	&__header {
		position: sticky;
		top: $columnBorderRadius;
		transform: translateY(-$columnBorderRadius);
		border-radius: $columnBorderRadius $columnBorderRadius 0 0;
		border: 2px solid $grey-2;
		z-index: 100;
		box-shadow: 0 4px 6px -4px $grey-2;
		height: $headerHeight;
		padding: 3 * $space 5 * $space;
		background-color: $white;
	}

	&:after {
		content: "";
		position: absolute;
		transition: all ease-out 300ms;
		top: $headerHeight;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $super-light-blue;
		border: 2px solid rgba($medium-blue, 0.2);
		border-radius: 0 0 $columnBorderRadius $columnBorderRadius;
		z-index: 200;
		visibility: hidden;
		opacity: 0;
	}

	&--dragging {
		&:not(.BoardColumn--drag-source) {
			&:after {
				visibility: visible;
				opacity: 1;
			}

			.BoardPagination,
			.BoardCard {
				opacity: 0;
			}
		}
	}
	&--dragging-over:after {
		border-color: $medium-blue;
	}

	&__content {
		flex-grow: 1;
	}

	&__hidden-placeholder {
		display: none;
	}

	@keyframes loading_pulse {
		0% {
			opacity: 0.7;
		}
		50% {
			opacity: 0.4;
		}
		100% {
			opacity: 0.7;
		}
	}

	// TODO: Use a standard animation for loading cards
	&__loading-card {
		background-color: $grey-5;
		height: 100px;
		border-radius: $border-radius;

		animation-name: loading_pulse;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}
