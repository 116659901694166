@import "~StyleVariables";

.EditTicketAutoReplyTemplate {
	$header-height: 51px; // Header 50px + 1px border

	&__location {
		border-radius: $border-radius;
	}

	&--edit {
		--modalContentWidth: 100%;

		.ModalContent {
			padding: 0;
			height: calc(100vh - #{$header-height});

			.EditorWrap__sidebar {
				top: 51px;
			}
		}
	}
	.TextEditor {
		width: 100%;
	}

	.ModalHeader {
		padding: 0;
	}

	.ModalHeader__content {
		padding: 0;
	}

	.Tabs {
		white-space: nowrap;
	}

	&__controls {
		white-space: nowrap;
		margin-left: auto;
	}

	&__modalHeader {
		max-width: 150px;
		margin-left: $space * 2;
	}
}
