@import "~StyleVariables";

.EditOrderSaveButton {
	position: relative;

	&__dropdownWrapper {
		position: relative;
		bottom: $up-btn-height;
		right: 0;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
	}

	&__dropdownMenu {
		right: 0;
		position: absolute;
		bottom: 0;
		padding: 2px 0;
	}

	&__saveButtonWrapper {
		&--open {
			box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		}
	}

	&__saveButton {
		padding-right: 0;
		margin: 0 !important;
	}

	&__toggleIcon {
		padding-left: 0;

		.Icon {
			transition: all ease 200ms;
		}

		&--open {
			.Icon {
				transform: rotate(180deg);
			}
		}
	}

	&__hidingOverflow {
		overflow: hidden;
		height: 3px;
		position: absolute;
		bottom: -3px;
		background-color: #fff;
		width: 100%;
	}
}
