@import "~StyleVariables";

.EasyBookingSettingsForm {
	display: flex;
	align-items: center;

	&__editModal {
		margin-left: 40px;
	}

	&__content {
		flex-grow: 1;
		align-self: flex-start;
	}

	&__triangle {
		width: 50px;
		flex-shrink: 0;
		background: $grey-1;
		align-self: stretch;
		transform: matrix(1, 0, -0.15, 1, 50, 0);
		transform-origin: top;
	}

	&__image {
		align-self: stretch;
		display: flex;
		background: $grey-1;

		img {
			z-index: 1;
			max-height: 100%;
			min-width: 120px;
			width: 300px;
			max-width: 100%;
			padding: 20px;
		}
	}
}
