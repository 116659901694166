@import "~StyleVariables";

.FilesListView {
	background-color: $white;

	&__last-column {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 16px;
	}

	&__folder-icon {
		margin-right: 8px;
	}

	&__delete-button {
		&:hover {
			.Icon {
				color: $grey-10;
			}
		}
	}

	&__folderList {
		.TableHeader__column:nth-of-type(2) {
			width: 16px;
		}
	}

	&__file {
		&__name {
			max-width: 400px;
		}
	}

	.TableHeader__column {
		border-bottom: 1px solid $grey-6;
		border-top: 1px solid $grey-6;
	}
}
