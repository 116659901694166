.CopyableId {
	display: inline-flex;
	align-items: center;

	.Icon {
		opacity: 0;
		visibility: hidden;
		transition: all ease-out 200ms;
		transform: translateX(-20px);
		cursor: pointer;
		font-size: 12px;
	}

	&:hover .Icon {
		opacity: 1;
		visibility: visible;
		transform: translateY(0px);
	}
}
