@import "~StyleVariables";

.TodoCheckboxCard {
	display: flex;
	flex-direction: column;
	box-shadow: 0 1px 2px 0 rgba($black, 0.2);
	&__input-wrap {
		display: flex;
		align-items: center;
		min-height: 45px;
		padding: 0px 10px;
		border-radius: 3px;
		.Text {
			margin-left: 8px;
			position: relative;
			max-width: 290px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.Text::after {
			content: " ";
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 1px;
			background: $black;
			animation: strike 200ms cubic-bezier(0.8, 1.56, 0.8, 1);
			animation-fill-mode: forwards;
		}

		.Input {
			width: 100%;
		}
		.Checkbox {
			height: 24px;
		}
	}
	&__followup {
		box-shadow: 0 0 2px 0 rgba($black, 0.2);
		&--visible {
			padding-top: 10px;
			padding-bottom: 4px;
		}
	}
	&__buttons {
		max-width: 315px;
		> .Tooltip {
			> .Button {
				margin-right: 12px;
				margin-bottom: 8px;
				color: $super-light-green;
			}
		}
	}
	@keyframes strike {
		0% {
			width: 0;
		}
		100% {
			width: 100%;
		}
	}
}
