@import "~StyleVariables";

.EditProductTierRow {
	margin: 0 -10px;
	white-space: nowrap;
	.Column {
		padding: 0 10px;
	}

	&--hasMaxDiscount {
		.Column {
			padding: 0 $space;
		}

		.btn-hover-red {
			padding: 0
		}
	}

	&__tier-discount-input {
		position: relative
	}

	.Tooltip {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: $space * 2;

		.Icon {
			color: $grey-10;
		}

		.Icon:hover {
			cursor: pointer;
		}
	}

	&__max-discount-help {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}
}

.EditProductTierErrorRow {
	$height: 14px;
	display: block;
	margin-bottom: 3px;
	margin-top: -7px;
	height: $height;
	line-height: $height;

	.Icon {
		display: inline-block;
		font-size: 10px;
	}
}
