@import "~StyleVariables";

.FormIntervalSelect {
	width: 100%;

	.RadioList {
		display: flex;

		.RadioListItem {
			margin: 0;
		}

		.Label {
			margin-right: 12px;
			font-weight: 400;
		}
	}

	&__date-group {
		display: flex;
		padding-top: 16px;
		align-items: flex-start;
		justify-content: space-between;

		.input-group {
			.Input {
				width: 100%;
			}
		}
		.input-group:first-of-type {
			margin-right: 10px;
		}
		.input-group:last-of-type {
			margin-left: 10px;
		}
	}
}
