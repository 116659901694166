@import "~StyleVariables";

.FlowEditor:has(#flow-editor.fullscreen) {
    position: static;
}

.FlowEditor {
    #flow-editor:not(.fullscreen) {
        right: 0;
        bottom: 0;
        top: 82px;
        left: 48px;
    }
}