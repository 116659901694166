@import "~StyleVariables";

.ModalWrap {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	overflow: auto;

	&__content {
		z-index: 10001;
		position: absolute;
		width: 100%;
		height: 100%;

		> * {
			transition: all cubic-bezier(0.175, 0.885, 0.32, 1.475) 300ms;
			opacity: 0;
			visibility: hidden;
			transform: scale(0.8);
		}
	}

	&--open &__curtain {
		opacity: 0.2;
		visibility: visible;
	}

	&--open &__content > * {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	&__curtain {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $black;
		opacity: 0;
		visibility: hidden;
		transition: all ease 200ms;
		z-index: 10001;
	}

	// Modal overrides
	.Modal {
		position: relative;
		margin: 20px auto;
		vertical-align: top;
		overflow: visible;
		background-color: $white;
		z-index: 10002;
	}

	.ModalControls,
	.ModalHeader,
	.ModalContent {
		position: relative;
	}

	.ModalContent {
		margin: 0;
	}

	&--fullscreen {
		.Modal {
			margin: 0;
		}
	}
}
