@import "~StyleVariables";

.FileBrowserLabels {
	&__wrapper {
		width: 100%;
	}
	&__modal {
		z-index: 10000;
		width: 300px;
		height: 170px;
	}

	&__modalCard {
		height: 184px;
		padding: 0px 16px;

		.Title {
			padding-top: 16px;
		}

		.Label {
			margin-top: 0 !important;
			padding-top: 16px;
			font-weight: 600;
		}

		.ModalControls {
			border-top: none;
			padding-bottom: 16px;
			padding-top: 0;
		}
	}

	&__progress.Progress {
		width: 15px;
		position: absolute;
		right: 0;
		top: 0;
		margin-top: 20px;
	}

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		margin-left: -15px;
		width: 260px;

		.Button {
			position: absolute;
			right: 0;
		}
	}

	&__label {
		font-weight: $boldFontWeight;
		margin-left: 20px;
		margin-top: 25px;
	}

	&__modalButtonWrapper {
		flex-direction: row;
		margin-top: 15px;
	}

	&__modalContent {
		height: 180px;
		background-color: $white;
	}

	&__icon {
		margin-top: 20px;
		padding-right: 5px;
		background-color: transparent;
	}
}
