@import "~StyleVariables";

#admin-page-dashboard {
    .admin-page-header {
        height: 254px;

        .header-overlay {
            background-color: $green;
            position: relative;
            height: 100%;
        }

        .dashboard-header-logo {
            width: 160px;
            height: 60px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            display: inline-block;
        }

        .dashboard-header-search {
            position: relative;
            display: block;
            background-color: $white;
            border-radius: $border-radius;
            box-shadow: 0 1px 2px 0 rgba($black, 0.2);
            width: 465px;
            height: 49px;
            margin: 15px auto 0 auto;
            overflow: hidden;

            .fa {
                position: absolute;
                top: 0;
                left: 0;
                color: $grey-8;
                font-size: 14px;
                line-height: 49px;
                width: 49px;
                text-align: center;
            }

            input {
                width: 100%;
                height: 100%;
                background: $white;
                padding-left: 40px;
                outline: none;
                border: none;
                transition: all ease 200ms;

                &:focus {
                    background-color: $grey-1;
                }
            }
        }

        #dashboard-header-info {
            position: absolute;
            top: 25px;
            left: 0;
            right: 0;
            text-align: center;
            color: $white;

            h3 {
                margin: 0;
                font-weight: normal;
                font-size: 30px;
                line-height: 32px;
            }

            .license-count {
                color: $super-light-green;
                font-size: 12px;
                font-weight: $regularFontWeight;
                line-height: 24px;
                margin-top: 7px;
                display: block;

                b {
                    text-transform: uppercase;
                }
            }

            #admin-header-logo {
                height: 32px;
                position: relative;
                top: 10px;

                .logo-letter {
                    fill: rgba($white, 0.8);
                }
            }
        }
    }

    #admin-content {
        max-width: 800px;
        padding: 0;
        margin: 0 auto;
    }

    .dashboard-content-inner {
        position: relative;
        margin-top: -20px;
        display: block;
        height: auto;
        overflow: hidden;
        padding: 0 20px;

        .dashboard-content-left {
            float: none;
            width: auto;
            overflow: hidden;
            padding-right: 30px;
        }

        .dashboard-content-right {
            width: 260px;
            float: right;
        }

        .dashboard-widget {
            position: relative;
            background-color: $white;
            border-radius: $border-radius;
            width: 440px;
            width: 100%;
            margin-bottom: 10px;
            overflow: hidden;

            // TODO: This styling should be kept and moved to be used with Card ui-component when refactoring this view
            border: 1px solid $grey-6;

            &.widget-halfnhalf {
                background-position: center right 30px;
                background-repeat: no-repeat;

                .widget-content {
                    padding-right: 50%;
                }
            }

            &.sliced:before {
                content: "";
                background-color: $grey-1;
                position: absolute;
                top: -20px;
                left: -20px;
                right: -20px;
                height: 120px;
                transform: rotate(-5deg);
                transition: all ease 500ms;
            }

            &.sliced:hover:before {
                height: 110px;
            }

            &.centered .widget-title,
            &.centered .widget-content {
                text-align: center;
            }

            .widget-title-img {
                width: 50px;
                position: absolute;
                top: 25px;
                left: 15px;

                & + .widget-title {
                    padding-left: 75px;
                }
            }

            .widget-title {
                position: relative;
                display: block;
                padding: 20px 20px 0 20px;
                color: $grey-11;
                font-size: 18px;
                line-height: 30px;

                .fa {
                    margin-right: 5px;
                }

                .widget-title-subtitle {
                    color: $grey-10;
                    font-size: 14px;
                    font-weight: $regularFontWeight;
                    line-height: 17px;
                }
            }

            .widget-content {
                position: relative;
                padding: 20px;
                padding-top: 15px;
                font-size: 14px;
                color: $grey-10;

                &.no-padding-bottom {
                    padding-bottom: 0;
                }
            }

            .big-link {
                font-size: 18px;
                font-weight: $boldFontWeight;
                line-height: 22px;
                margin-top: 10px;
                display: block;
            }

            .widget-btn {
                display: block;
                outline: none;
                border: none;
                background-color: transparent;
                min-height: 40px;
                line-height: 40px;
                color: $bright-blue;
                font-size: 14px;
                font-weight: $regularFontWeight;
                text-align: center;
                width: 100%;
                transition: all ease 200ms;
                border-top: 1px solid $grey-4;
                text-decoration: none;
                padding: 0;

                &:hover {
                    border-top-color: transparent;
                    background-color: $grey-1;
                    text-decoration: none;
                }
            }

            .widget-img {
                max-height: 170px;
                max-width: 170px;
                position: relative;
                margin: 20px auto 0 auto;
                display: block;
                margin-bottom: 10px;

                & + .widget-title {
                    padding-top: 0;
                }
            }

            .widget-icon {
                font-size: 120px;
                display: flex;
                justify-content: center;
                padding-top: 15px;
                color: $green;
            }
        }
    }

    .support-times {
        display: block;
    }

    .shortcut {
        display: inline-block;
        margin-bottom: 20px;
        width: 50%;
        vertical-align: top;
        padding-right: 5px;

        a {
            display: block;
        }

        span {
            color: $grey-10;
            font-size: 12px;
        }
    }

    .app-widget-content {
        button {
            margin-top: 10px;
        }
    }

    .app-widget-content {
        button {
            margin-top: 10px;
        }
    }

    .dashboard-widget .widget-btn.next-onboarding-btn {
        position: relative;
        text-align: left;
        padding: 10px 15px;
        line-height: 20px;

        .fa-angle-right {
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: -10px;
            width: 10px;
            height: 20px;
            line-height: 20px;
        }

        .btn-title {
            text-transform: uppercase;
            color: $grey-10;
            display: block;
            font-size: 12px;
        }
    }

    #docebo-btn {
        margin-top: 20px;
    }

    .news-row {
        display: block;
        font-size: 16px;
        color: $grey-11;
        transition: all ease 200ms;
        margin-bottom: 20px;
        text-decoration: none;

        &:hover {
            color: $bright-blue;
            text-decoration: none;

            .news-subtitle {
                color: $black;
            }
        }

        .news-subtitle {
            transition: all ease 200ms;
            font-size: 12px;
            color: $grey-10;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}