@import "~StyleVariables";

$pageTop: 82px;
$headerHeight: 50px;

.SalesboardHeader {
	position: sticky;
	background-color: $white;
	align-items: stretch;
	z-index: 1000;
	top: $pageTop;

	& > .Row > .Column {
		height: $headerHeight;
		display: flex;
		align-items: center;

		&:last-of-type {
			justify-content: end;
		}
	}

	&__chart-toggle {
		height: $headerHeight;
		width: $headerHeight;
		border-radius: 0 0 0 0;
	}

	&__filters {
		&--wrapped {
			.DropDownMenu__content {
				left: 0;
			}
		}
	}

	&__tooltip {
		text-align: left;
		width: 260px;
		padding-left: 8px;
		padding-right: 8px;
	}

	&__tooltip-section {
		padding-bottom: 12px;
		padding-top: 12px;

		&:not(.SalesboardHeader__tooltip-section:last-of-type) {
			border-bottom: 1px solid $grey-6;
		}
	}

	&__tooltip-row {
		display: flex;
		justify-content: space-between;
		margin-top: 4px;
	}

	.SharedViews .DropDownButton__text-wrapper {
		.Title {
			max-width: 200px;
		}
	}

	&__toggle-forecast {
		cursor: pointer;

		& > .Block {
			height: 100%;
			padding: 16px 38px 16px 8px;

			.Tooltip {
				display: flex !important;
				height: 100%;
				flex-direction: column;
				justify-content: center;
			}

			.Icon {
				position: absolute;
				right: 16px;
			}
		}
	}
}
