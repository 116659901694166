@import "~StyleVariables";

.AdvancedSearchSection {
	padding: ($space * 6);
	border-bottom: $grey-4 1px solid;

	&__header {
		display: flex;
		align-items: center;
		gap: ($space * 2);

		&--clickable {
			cursor: pointer;
		}
	}

	&__content {
		height: 0;
		overflow: hidden;
		
		&--expanded {
			padding-top: ($space * 4);
			height: auto;
		}
	}
}
