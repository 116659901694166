@import "~StyleVariables";

.ActivityOutcomesStatistic {
	display: flex;
	justify-content: space-between;

	&__side {
		width: 300px;
	}

	&__main {
		flex-grow: 1;
		padding: 8px 30px;
		max-width: 800px;

		.Title {
			text-align: left;
			font-weight: $boldFontWeight;
		}
	}

	&__progress {
		&-block {
			margin: 0 0 10px;
			padding-top: 20px;
			color: $white;
		}

		&-list {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			justify-content: space-between;

			li {
				display: flex;
				align-items: flex-end;
			}
		}

		&-item {
			&--empty {
				color: rgba($white, 0.6);
				font-style: italic;
				font-family: Roboto-Italic, sans-serif;

				.ActivityOutcomesStatistic__progress-item-num {
					font-size: 42px;
					font-weight: $regularFontWeight;

					@media screen and (max-width: 1100px) {
						font-size: 32px;
					}
				}
			}

			&-num {
				display: block;
				font-size: 50px;
				line-height: 1;
				font-weight: $boldFontWeight;

				@media screen and (max-width: 1100px) {
					font-size: 35px;
				}
			}

			&-name {
				display: block;

				@media screen and (max-width: 1100px) {
					font-size: 12px;
				}
			}

			&-total {
				display: flex;
				align-items: center;
			}
		}

		&-info {
			list-style: none;
			padding: 5px 0 0 10px;
			margin: 0;

			li {
				align-items: center;
			}

			&-item {
				margin-bottom: 4px;

				@media screen and (max-width: 1100px) {
					font-size: 12px;
				}
			}
		}
	}

	.top-list-header-row-text {
		color: $white;
		font-weight: $boldFontWeight;
	}
}
