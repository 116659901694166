@import "../../../../styles/variables";

.AccountRelations .TableRow {
	background-color: $white;
}

.AccountRelations .Not-Found {
	background-color: $white;
	text-align: center;
}

.AccountRelations--isRelationsTab .Card {
	margin-top: 0 !important;
	background-color: $grey-1 !important;
}

.AccountRelations .Card {
	margin: 5px 0 25px;

	&.Card--noDuns {
		position: absolute;
		margin: 20px auto;
		left: 0;
		right: 0;
		top: 0;
		max-width: 640px;
		overflow: hidden;
	}
}

.AccountRelations .NoDuns {
	position: relative;
	height: 250px;

	.CardContent {
		> .CardContent--Inner {
			width: 60%;
		}

		.Link {
			margin-left: 5px;
		}

		.Text {
			margin: 10px 0;
			line-height: 1.4em;
		}

		.Title {
			margin-top: 10px;
		}
	}

	img {
		position: absolute;
		right: 10%;
		top: 20%;
		z-index: 10000;
	}
}

.AccountRelations .CardHeader {
	padding: 0 15px;
}

.AccountRelations__GroupTree {
	.accounts-card {
		margin: 0;

		.no-height {
			th {
				padding: 0 15px;
			}
		}

		.result-table {
			width: 100%;
		}

		.unknown-tree thead {
			background-color: $grey-2;
		}

		.unknown-tree-new thead {
			background-color: $grey-1;
		}
	}
}

.AccountRelations__addon-banner {
	width: 100%;
	margin: 16px;
	z-index: 1;
	display: flex;
	justify-content: center;

	.BillingAddonBanner {
		flex: 1;
		max-width: 890px;
	}
}

// Remove when PROSPECTING_CEO_ON_BOARD is released
.AccountRelations .GroupTree--NotEligible {
	position: relative;
	padding: 5px 0;
	background: $white;

	img {
		width: 900px;
	}

	.GroupTree--Shader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: -moz-linear-gradient(
			top,
			rgba(237, 241, 245, 0.4) 0%,
			rgba(237, 241, 245, 0.7) 20%,
			rgba(237, 241, 245, 1) 40%,
			rgba(237, 241, 245, 1) 100%
		); /* FF3.6-15 */
		background: -webkit-linear-gradient(
			top,
			rgba(237, 241, 245, 0.4) 0%,
			rgba(237, 241, 245, 0.7) 20%,
			rgba(237, 241, 245, 1) 40%,
			rgba(237, 241, 245, 1) 100%
		); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to bottom,
			rgba(237, 241, 245, 0.4) 0%,
			rgba(237, 241, 245, 0.7) 20%,
			rgba(237, 241, 245, 1) 40%,
			rgba(237, 241, 245, 1) 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66edf1f5', endColorstr='#edf1f5',GradientType=0 ); /* IE6-9 */
	}

	.Account {
		position: absolute;
		margin: 20px auto;
		left: 0;
		right: 0;
		top: 0;
		max-width: 640px;

		.Card {
			background-color: $green;
			box-shadow: 0 11px 12px rgba($black, 0.2);
			border-radius: $border-radius;
			overflow: hidden;

			.Headline,
			.Title {
				color: $white;
			}

			.Title {
				margin: 15px 0 25px;
				line-height: 1.3em;

				> span {
					opacity: 0.8;
				}

				> b {
					margin: 0 5px;
				}
			}

			.CardContent {
				padding: 30px 0 30px 30px;
				width: 70%;
			}

			.CardContent .tag {
				color: rgba($white, 0.8);
				background: $dark-blue;
				background: $green;
				display: inline-block;
				font-size: 14px;
				padding: 4px 10px;
				border-radius: $border-radius;
				margin-bottom: 10px;
				box-shadow: 0 1px 2px rgba($green, 0.2);
			}

			.CardButtonHolder {
				display: flex;
				align-items: center;
				flex-direction: row;

				.btn-link {
					.Icon {
						margin-left: 10px;
					}
				}
			}

			.upgrade-image {
				position: absolute;
				bottom: 0;
				right: 34px;
				width: 123px;
			}
		}
	}
}

.GenericRelationTable {
	.TableColumn--align-right {
		padding-right: 0;
	}

	.NoProspectingIdHeader {
		padding-top: $space * 4;
		background-color: $white;
	}
}

.group-size-merge {
	.merge {
		max-width: 800px;
		margin: auto;
		margin-top: 10%;

		.title {
			font-size: 26px;
			font-weight: $boldFontWeight;
			text-align: center;
			margin-bottom: 10px;
		}

		.subtitle {
			color: $grey-10;
			font-size: 16px;
			text-align: center;
			height: 22px;
			margin-bottom: 15px;

			& + .subtitle {
				margin-bottom: 40px;
				font-size: 14px;
				font-style: italic;
			}
		}

		.subtitle-name {
			font-weight: $boldFontWeight;
			color: $grey-11;
		}

		.account-select-wrapper {
			display: flex;
			justify-content: center;

			& > *:not(:last-child) {
				margin-right: 15px;
			}

			.account-select {
				width: 100%;
				z-index: 1000;
			}

			.select2-dummy-wrap {
				width: 300px;
				position: relative;
			}

			.select2-dummy {
				top: 0;
				left: 0;
				border: 1px solid;
				border-color: $grey-8;
				border-radius: $border-radius;
				height: 34px;
				width: 100%;
				background-color: $white;
				position: absolute;
				z-index: 0;
				width: 100%;
			}

			.apply-to {
				line-height: 34px;
				vertical-align: middle;
				display: inline-block;
				background: $grey-2;
				color: $grey-10;
				padding: 0 10px;
				border-radius: $border-radius;
			}

			.Icon {
				margin-right: 5px;
			}
		}

		.abort-wrap {
			margin-top: 10px;
			display: flex;
			justify-content: center;
		}
	}
}

.AccountRelations .inline-confirm-modal {
	width: 34.22px;
}

.EmptyResultTable {
	padding-top: $space * 16;
}
