@import "~StyleVariables";

.MoveSubscription {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	position: absolute;
	align-items: center;
	z-index: $modal-z + 2;
	flex-direction: column;
	background-color: $white;

	&__cornerClose {
		z-index: $modal-z + 2;
		align-self: flex-end;
	}

	&__content {
		display: flex;
		max-width: 500px;
		text-align: center;
		align-items: center;
		flex-direction: column;
	}

	&__middleContent {
		width: 300px;
	}

	&__accountSelect,
	&__contactSelect {
		width: 100%;
	}

	&__accountSelect--selected,
	&__contactSelect--selected {
		.select2-choice {
			border-color: $bright-green;

			.select2-arrow {
				border-left-color: $bright-green;
			}
		}
	}

	&__inputContainer {
		text-align: left;
	}
}
