@import "../../../styles/variables";

.EditMailSignature {
	.ModalControls {

		.ModalControls__content {
			display: flex;
			justify-content: space-between;
		}

		.ModalControls__leftSide {
			> div {
				display: inline-block;
				float: left;
				margin: 0 10px 0 0;
				vertical-align: middle;
			}
		}
	}

	.EditMailSignature__progress.Progress {
		width: 15px;
		position: absolute;
		right: 0;
		top: 0;
	}

	.flash input {
		background-color: $super-light-yellow;
	}

	.Column.form-holder {
		.Row {
			margin: 0 0 20px 0;

			.Column + .Column {
				margin-left: 20px;
			}
		}

		& + .Column {
			margin-left: 15px;
		}
	}
}

.EditMailSignature.tags-visible {
	$taglist-width: 280px;
	#tag-list {
		width: $taglist-width;

		#tags-list-ul {
			top: 180px;
		}

		> div,
		ul,
		h2,
		p {
			width: $taglist-width !important;
		}
	}

	.ModalHeader,
	.ModalContent,
	.ModalControls {
		right: $taglist-width;
	}
}

.ckeditor-react-holder > div {
	opacity: 1;
}
