@import "~StyleVariables";

.PriceLists {
	.Label {
		display: inline-block;
		margin-right: 10px;
		margin-left: 4px;
		margin-bottom: 0px;
	}

	.Checkbox {
		margin-right: 12px;
	}

	&__nameColumn {
		width: 65%;
	}

	&__buttonIcon {
		margin-right: 8px;
	}

	&__loaderWrapper {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
	}

	&__inactive {
		background-color: $grey-2;
	}
	#admin-content {
		max-width: 1000px;
		padding: 30px 30px 30px;
	}

	.CardHeader__actions {
		display: flex;
		gap: 4px;
		align-items: center;
		line-height: unset;
	}

	&__search.Input {
		$height: 30px;
		height: $height;
		width: 34px;
		transition: width 250ms ease-out;

		.Input__input {
			padding-left: 26px;
			height: $height;
			border-radius: $border-radius;
		}
		.Input__icon {
			height: $height;
			line-height: $height;
			color: $grey-11;
		}
		&:hover,
		&:focus-within {
			width: 155px;
			.Input__input {
				padding-left: 28px;
			}
		}
	}
}
.WrappedPriceLists#admin {
	#admin-root#admin-root {
		left: 0;
	}
	.admin-root-wrapper {
		left: 0;
	}
}
