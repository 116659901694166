@import "~StyleVariables";

.FullScreenPage {
	background-color: $grey-1;
	left: 0;
	top: 0;
	z-index: 9997;
	bottom: 0;
	right: 0;
	position: fixed;
	overflow: auto;

	visibility: hidden;
	opacity: 0;
	transition: all ease-out 300ms;

	&--open {
		opacity: 1;
		visibility: visible;
	}
}
