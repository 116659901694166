@import "~StyleVariables";

.OrderRows {
	width: 950px;

	.TableRow {
		height: 90px;
	}
	
	.TableHeader__column {
		.Text {
			display: inline;
		}

		&:first-of-type {
			padding-left: 0;
		}
		&::after {
			height: 2px;
			background-color: $grey-6;
		}
	}

	.TableColumn {
		&:first-of-type {
			padding-left: 0;
		}
	}

	&__sumTableHeader {
		float: right;
		.Tooltip {
			margin-right: 4px;
		}
	}

	&__addAndSummary {
		display: flex;
		justify-content: space-between;
		border-top: 1px solid $grey-6;
    	padding-top: 12px;

		.Button {
			.Icon {
				display: inline;
			}

			.Text {
				display: inline;
				margin-left: 4px;
			}
		}
	}
}
