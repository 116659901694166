@import "~StyleVariables";

.EditProjectPlan {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	width: 800px;

	.DrawerHeader {
		position: sticky;
		top: 0;
		z-index: 10001;

		.Link {
			font-size: $modal-font-size;
		}

		.Button {
			height: 50px;
			width: 50px;
		}

		.fa-spinner {
			margin-top: 50%;
		}
	}

	&__link-wrap {
		width: 100%;
	}

	&__clientLink {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 80%;
	}

	&__loaderWrapper {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__content {
		&--whiteBackground {
			min-height: 100vh;
			background-color: $white;
		}
	}

	&--todoDrawerOpen {
		z-index: 9999 !important;
	}
}
