@import "~StyleVariables";

.SaveView__InputGroup--Dropdown {
	li.select2-result-with-children.select2-result-unselectable > .select2-result-label {
		background-color: $white;
		text-transform: initial;
		color: $grey-13;
		height: 30px;
		line-height: 30px;
		font-weight: $boldFontWeight;
		padding: 0 10px;
		font-size: 14px;
	}

	.NumberChildren {
		float: right;
		color: $grey-8;
		font-weight: $regularFontWeight;
		font-size: 12px;
	}

	.select2-results li.select2-result-with-children > .select2-result-label {
		font-size: 14px;
	}

	ul.select2-results .select2-results-dept-1 .select2-result-label {
		padding-left: 20px;
	}

	ul.select2-results .select2-results-dept-2 .select2-result-label {
		padding-left: 30px;
	}

	ul.select2-results .select2-highlighted {
		> .select2-result-label {
			color: $white;
		}

		.NumberChildren {
			color: rgba($white, 0.7);
		}
	}

	ul.select2-results .select2-result-label {
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
		color: $grey-13;
	}
}
