.CustomFields {
	position: relative;
	margin-bottom: 20px;

	.Label {
		font-weight: bold;
	}
	textarea {
		min-height: 110px;
		resize: vertical;
	}

	/**
		* @todo  @upsales/components 2.16.6
		* STYLING: Since NumberInput uses flex and we don't have a prop that can add width: 100% to the components, we need to add manually here.
		* {@link https://github.com/upsales/ui-components/issues/527}
	*/
	.NumberInput {
		.Input {
			width: 100%;
		}
	}

	&__title {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.Icon {
			animation: rotateBackAnimation 400ms;
			font-size: 16px;
			cursor: pointer;
			margin-right: 4px;
		}

		&--rotateIcon {
			.Icon {
				animation: rotateAnimation 400ms;
				transform: rotate(180deg);
			}
		}
	}
}
