.InlineDropDownMenu {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.InlineDropDownMenu .DropDownMenu__content {
	min-width: 200px;
	z-index: 1002;
}

.InlineDropDownMenu__Button-wrapper {
	position: relative;
	height: 100%;
	margin-bottom: 1px;
	overflow: hidden;
}

.InlineDropDownMenu__Button {
	height: 100%;
	width: 40px;
	transition: right 0.2s ease;
	position: absolute;
	right: -100%;
}

.InlineDropDownMenu--expanded .InlineDropDownMenu__Button {
	right: 14px;
}

.TableRow:hover .InlineDropDownMenu__Button {
	right: 14px;
}
