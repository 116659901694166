@import "~StyleVariables";

.DecisionMakersPro {
	&__content {
		width: 850px;
		align-self: center;
	}

	&__buttonBoxes {
		.ButtonBox {
			flex: 1;
		}
	}

	&__stakeholdersCard {
		padding: 0px;
		margin-top: 40px;
	}

	&__titlecategory {
		.TranslateField {
			margin-top: 30px;
			border-radius: 4px;
			box-shadow: 0 1px 2px 0 rgba($black, 0.2);
			border: 1px solid $grey-6;
		}
	}
}
