@import "~StyleVariables";

.FolderDropdownOption {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 8px 12px;

	&--isOver {
		box-shadow: 0 0 0 2px $red;
	}

	.Icon {
		margin-right: 12px;
		font-size: 14px;
	}
	
	&:hover {
		background-color: $grey-6;
	}
}