// AWESOME TRANSITIONS

@include keyframes(enter_animation) {
	0% {
		right: -500px;
		width: 0;
		color: $super-light-blue;
		background-color: $bright-blue;
	}
	50% {
		width: 100%;
		color: $blue;
	}
	100% {
		background-color: $super-light-blue;
	}
}

@include keyframes(leave_animation) {
	0% {
		right: 0;
		color: $blue;
		top: 0;
		width: 100%;
	}
	50% {
		width: 100%;
	}
	100% {
		color: $super-light-blue;
		background-color: $bright-blue;
		width: 100%;
	}
}

@include keyframes(list_item_enter_animation) {
	0% {
		@include opacity(0);
		background-color: $super-light-blue;
	}
	100% {
		@include opacity(1);
		background-color: $white;
	}
}

@include keyframes(badge_enter_animation) {
	0% {
		@include opacity(0);
		@include transform(scale(0) rotate(-45deg));
	}
	50% {
		@include opacity(1);
		@include transform(scale(1.5) rotate(45deg));
	}
	100% {
		@include transform(scale(1) rotate(0deg));
	}
}

@include keyframes(badge_leave_animation) {
	0% {
		@include opacity(1);
	}
	100% {
		@include opacity(0);
	}
}

.notification-dropdown {
	@media (max-width: $responsiveWidht1) {
		#user-name span {
			max-width: 195px !important;
		}

		.notification-progress-wrapper {
			float: right !important;
		}

		&.onboarding-active {
			width: 52px;

			#user-name {
				display: none;
			}
		}
	}

	@media (max-width: 1250px) {
		&.onboarding-active {
			width: 52px;
		}
	}

	@media (max-width: $responsiveWidht5) {
		& {
			width: 52px;
		}

		&.onboarding-active {
			width: 52px;
		}

		#user-name {
			display: none;
		}
	}

	// FLAG SHOWN ON ARRIVING NOTIFICATIONS
	.notification-alert {
		background-color: $super-light-green;
		color: $green;
		position: absolute;
		right: -500px;
		width: 0;
		height: 46px;
		bottom: 0;
		padding: 0 50px 0 14px;
		font-size: 13px;
		line-height: 46px;
		vertical-align: middle;
		word-wrap: break-word;
		overflow: hidden;
		z-index: 2000;

		&.ng-enter,
		&.visible:not(.not-visible) {
			right: 0;
			width: 320px;
			@include animation(enter_animation 700ms);
		}

		&.ng-leave,
		&.not-visible {
			right: -500px;
			@include animation(leave_animation 700ms);
		}

		&:after {
			content: "";
			pointer-events: none;
			position: absolute;
			width: 100px;
			height: 100%;
			top: 0;
			right: 0;

			background-image: -webkit-linear-gradient(right, rgba($super-light-blue, 1), rgba($super-light-blue, 0));
			background-image: -moz-linear-gradient(right, rgba($super-light-blue, 1), rgba($super-light-blue, 0));
			background-image: -ms-linear-gradient(right, rgba($super-light-blue, 1), rgba($super-light-blue, 0));
			background-image: -o-linear-gradient(right, rgba($super-light-blue, 1), rgba($super-light-blue, 0));
			background-image: linear-gradient(to left, rgba($super-light-blue, 1), rgba($super-light-blue, 0));
		}

		.fa {
			font-size: 16px;
			margin-right: 10px;
		}

		b.fa.fa-envelope.failed-notification-mail {
			color: darkred;
		}

		.fa-dollar {
			color: $bright-green;
		}

		.Icon-tiers {
			color: $bright-blue;
		}
		
		.Icon-warning {
			color: $yellow-5;
		}

		.provisioning-notification {
			.Text,
			.Link {
				display: inline;
			}
		}
	}
}

// COUNTING BADGE
.notification-badge {
	background-color: $red;
	color: $white;
	font-weight: $boldFontWeight;
	font-size: 10px;
	padding-top: 1px;
	@include border-radius(50%);
	width: 16px;
	height: 16px;
	text-align: center;
	position: absolute;
	top: 4px;
	right: 6px;

	&.ng-enter {
		@include animation(badge_enter_animation 700ms);
	}

	&.ng-leave {
		@include animation(badge_leave_animation 700ms);
	}

	// &.changed-add {
	// 	@include animation(badge_update_animation 700ms);
	// }
}

.notification-badge-parent {
	position: relative;
	top: 0px;
    right: 0px;
}

.notification-badge-child {
	position: absolute;
	top: -8px;
	right: -8px;
	font-size: 10px;
	padding-top: 1px;
	padding-left: 4px;
	padding-right: 4px;
	padding-bottom: 1px;
	text-align: center;
	text-shadow: none;
	@include border-radius(4px);

	&--blue {
		color: $super-light-blue;
		background-color: $medium-blue;
	}

	&--yellow-5 {
		color: $yellow-2;
		background-color: $yellow-5;
	}	
}

.onboarding-dropdown {
	$onboarding-blue: $bright-blue;
	$dropWidth: 400px;
	display: inline-block;
	// width: 100%; // just for now
	width: $dropWidth;
	height: $navbar-height;
	float: right;
	border-left: 1px solid rgba($onboarding-blue, 0.4);
	border-right: 1px solid rgba($onboarding-blue, 0.4);

	// REVERT WHEN(IF) GAMIFICATIONS IS BACK
	// @media (max-width: $responsiveWidht2) {
	// END OF REVERT
	@media (max-width: $responsiveWidht1) {
		// & {
		// 	width: 250px;
		// }

		#user-name span {
			max-width: 195px !important;
		}

		.notification-progress-wrapper {
			float: right !important;
		}
	}

	@media (max-width: $responsiveWidht5) {
		& {
			width: 52px;
		}

		#user-name {
			display: none;
		}
	}

	.dropdown-clicker {
		cursor: pointer;
		width: 100%;
		height: 100%;

		.generic-avatar {
			margin-right: 7px;
		}
	}

	&.open {
		.dropdown-clicker {
			background: $super-light-blue;
		}

		.phase {
			h5 {
				span {
					color: $blue;
				}
			}

			.shield {
				i {
					color: $bright-blue;
				}
			}

			.indicator {
				background-color: darken($super-light-blue, 15);
			}
		}
	}

	.phase {
		width: calc(100% - 45px);
		height: 100%;
		float: left;

		h5 {
			margin: 8px 0 0 0;

			span {
				color: $white;
				font-size: 14px;
				font-weight: $regularFontWeight;
			}

			.get-started {
				font-weight: $boldFontWeight;
				margin-right: 4px;
			}
		}

		.shield {
			width: 40px;
			float: left;
			height: 100%;
			position: relative;

			i {
				font-size: 24px;
				line-height: 1;
				color: $bright-blue;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -12px;
				margin-top: -12px;

				.shield-number {
					color: $bright-blue;
					font-size: 12px;
					font-weight: $boldFontWeight;
					position: absolute;
					top: 6px;
					font-style: normal;
					width: 100%;
					display: block;
					text-align: center;
				}

				&.up-icon-shield {
					.shield-number {
						color: $white;
					}
				}
			}
		}

		.indicator {
			width: calc(100% - 40px);
			height: 8px;
			background-color: $blue;
			border-radius: 3px;
			border: none;
			padding: 0;

			.current-phase {
				height: 8px;
				background-color: $bright-blue;
				border-radius: 3px;
			}
		}
	}

	.dropdown-menu {
		margin: 0;
		border: none;
		width: 100%;

		> a {
			text-align: center;
			display: block;
			font-size: 12px;
			height: 35px;
			line-height: 35px;
			cursor: pointer;
			text-decoration: none;
		}

		.accordion-header {
			height: 50px;
			border-bottom: 1px solid $super-light-blue;
			cursor: pointer;
			transition: all 0.4s;

			&:hover {
				background: rgba($super-light-blue, 0.6);
			}

			h4 {
				display: table;
				font-size: 14px;
				font-weight: $regularFontWeight;
				color: $blue;

				i.fa.fa-angle-down,
				i.fa.fa-angle-up {
					height: 100%;
					display: table-cell;
					vertical-align: middle;
					width: 10px;
					padding: 0 15px;
					color: $bright-blue;
				}
			}
		}

		.accordion-content {
			max-height: 0;
			padding: 10px 20px 20px;
			transform: translateZ(0);
			transition: all 0.15s;
			border-bottom: 1px solid transparent;
			overflow: hidden;

			&.is-visible {
				max-height: 500px;
				transition: all 0.25s;
			}

			.btn-holder {
				width: 100%;

				.up-btn {
					width: 49%;
					float: left;
					text-align: center;

					i.fa {
						margin-right: 5px;
					}
				}

				&.completed {
					.up-btn {
						width: 100%;
						float: none;
						background-color: $bright-green;
						text-shadow: 1px 1px darken($bright-green, 5%);
						border-color: darken($bright-green, 5%);

						&:hover {
							background-color: darken($bright-green, 8%);
						}
					}

					.btn-lined {
						display: none;
					}
				}
			}
		}

		.specHeight {
			overflow: hidden;
			max-height: 50px;
		}

		.opened {
			.accordion-header {
				border-bottom-color: transparent;
			}
			.accordion-content {
				border-bottom-color: $super-light-blue;
			}
		}

		.list-item {
			height: auto;

			.shield {
				width: 40px;
				float: left;
				height: 100%;
				position: relative;

				> span {
					font-size: 24px;
					line-height: 1;
					color: $bright-blue;
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: -12px;
					margin-top: -12px;

					.shield-number {
						color: $bright-blue;
						font-size: 12px;
						font-weight: $boldFontWeight;
						position: absolute;
						top: 6px;
						font-style: normal;
						width: 100%;
						display: block;
						text-align: center;

						i.fa {
							color: $white;
						}
					}
				}
			}

			h4 {
				height: 100%;
				float: left;
				width: calc(100% - 40px);
				margin: 0;
				line-height: 50px;
			}
		}

		.completed {
			.shield {
				> span {
					color: $bright-green;
				}
			}
		}
	}
}
