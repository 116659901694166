
@import '~StyleVariables';

.SidebarListItem {
	$sidePadding: 20px;

    display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	width: 100%;
	cursor: pointer;
	padding-left: $sidePadding;
	padding-right: $sidePadding;
	transition: all ease-out 200ms;
	position: relative;

	&--icon-only {
		padding-left: 0;
		padding-right: 0;
	}

	&__title {
		align-items: center;
		gap: 8px;
		.Icon {
			font-size: 16px;
		}
	}

	&__drag-icon, &__hide-icon {
		opacity: 0;
		transition: all ease-out 200ms;
	}

	.Tooltip {
		width: 100%;
	}
    .Tooltip > div {
		overflow-x: visible;
	}

    .Title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
        margin-bottom: 0;
	}
    
    &:hover {
		background-color: $grey-3;
		box-shadow: inset -4px 0 darken($grey-3, 25%);

		.SidebarListItem__drag-icon,  .SidebarListItem__hide-icon {
			opacity: 0.5;
		}
	}

	&__drag-icon--dragging {
		opacity: 1;
	}

	&__hide-icon {
		margin-right: 12px;
		&:hover {
			opacity: 1 !important;
		}
	}

    &--selected,
	&--selected:hover {
		background-color: white;
		box-shadow: inset -4px 0 $green;

		.Title {
			font-weight: $boldFontWeight;
		}
    }

	&__icon-wrapper {
		text-align: center;
		font-size: 16px;
	}

	&__right-title {
		position: absolute;
		right: $sidePadding;
		opacity: 1;
		transition: opacity 200ms;
		transition-delay: 200ms;
	}

	&--hidden {
		.SidebarListItem__right-title {
			opacity: 0;
			transition-delay: 0ms;
		}
	}

}