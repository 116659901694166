@mixin sub-page-navigation($height: 50px) {
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 50px;
	background-color: $white;
	box-shadow: 0 1px 2px rgba($black, 0.1);
	clear: both;

	&.tab-bar {
		border-top: none;
		border-bottom: 3px solid rgba($black, 0.1);

		& > li {
			& > a {
				&.current {
					border-bottom: 4px solid $green;
				}
			}
		}
	}

	&.clone {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	&.nav-compact {
		.card-nav.responsive a {
			text-align: center;

			&.regular {
				display: none;
			}

			&.compact {
				display: inline-block !important;
			}
		}
	}

	& > li {
		display: inline-block !important;
		margin: 0;
		padding: 0;
		position: relative;
		vertical-align: top;

		&.responsive a {
			.fa {
				margin-right: 3px;
			}

			&.compact {
				display: none;
			}
		}

		&.pull-right {
			& > a,
			& > .dropdown > a.dropdown-toggle {
				background-color: $grey-1;
			}
		}

		& > a,
		& > .dropdown > a.dropdown-toggle {
			outline: none;
			position: relative;
			top: 0px;
			height: $height;
			line-height: $height;
			display: block;
			color: $grey-11;
			font-size: 14px;
			vertical-align: middle;
			padding: 0 18px;
			cursor: pointer;
			font-weight: $regularFontWeight;
			border-bottom: 4px solid transparent;
			transition: all ease-out 200ms;

			&:hover:not(.current),
			&.hover:not(.current),
			&:focus {
				text-decoration: none;
				border-bottom: 4px solid $grey-6;
				background-color: $grey-1;

				&::before {
					border-color: transparent transparent transparent $grey-6;
				}

				&::after {
					border-color: transparent transparent transparent $grey-1;
				}
			}

			&.current {
				color: $green;
				font-weight: $boldFontWeight;
				background-color: $white;
				border-bottom-color: $green;

				&::before {
					border-color: transparent transparent transparent $green;
				}

				&::after {
					border-color: transparent transparent transparent $white;
				}
			}

			&[disabled] {
				color: $grey-8;
			}

			&.dropdown-toggle .caret {
				border-top-color: $blue;
				border-bottom-color: $blue;
			}

			.counter {
				color: $grey-8;
				font-weight: $regularFontWeight;
			}
			.notification {
				position: absolute;
				top: -3px;
				right: -1px;
				font-size: 11px;

				.bg {
					color: $red;
				}

				.number {
					color: $white;
					font-weight: $boldFontWeight;
				}
			}
		}
	}
}

.up-tabs {
	@include sub-page-navigation();
}
