@import "~StyleVariables";

.EditSocialEventHouse {
	svg#venue-house {
		height: 100%;
		margin: auto;
	}
	svg#venue-house.green .left-wall {
		fill: $super-light-green;
	}

	svg#venue-house.green .right-wall {
		fill: $medium-green;
	}

	svg#venue-house.green .door {
		fill: $bright-green;
	}

	svg#venue-house.green .door-side {
		fill: $bright-green;
	}

	svg#venue-house.green .door-knob {
		fill: $dark-green;
	}

	svg#venue-house.green .window-vertical-left,
	svg#venue-house.green .window-vertical-right,
	svg#venue-house.green .window-horizontal {
		stroke: $medium-green;
	}

	svg#venue-house.green .roof-shadow-left {
		fill: $bright-green;
		opacity: 0.5;
	}

	svg#venue-house.green .roof-shadow-right {
		fill: $dark-green;
		opacity: 0.5;
	}

	svg#venue-house.green .roof-right {
		fill: $bright-green;
	}

	svg#venue-house.green .chimney-right {
		fill: $green;
	}

	svg#venue-house.green .chimney-left {
		fill: $super-light-green;
	}

	svg#venue-house.green .chimney-shadow {
		fill: $green;
	}
}
