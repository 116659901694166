@import "~StyleVariables";

.SubscriptionSidebarActions {
	height: 60px;
	bottom: 0;

	display: flex;
	align-items: center;
	width: 290px;
	justify-content: flex-start;

	border-top: 1px solid $grey-4;

	&__actions {
		padding-left: 12px;

		.Icon {
			padding: 12px 8px 12px 8px;

			&:hover {
				cursor: pointer;
				color: $grey-8;
			}
		}
	}

	&__divider {
		padding-left: 12px;
		border-right: 1px solid $grey-4;
		height: 40px;
	}

	&__id-wrapper {
		padding-left: 12px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}