#flash-sibar-button-wrap {
	padding-top: 12px;
	border-top: 2px solid rgba($black, 0.2);
	position: relative;
	display: none;

	&.visible {
		display: block;
	}

	#flash-sibar-button {
		color: $super-light-green;
		background-color: $green;
		border-radius: 2px;
		height: 32px;
		box-shadow: 0px 1px 2px rgba($black, 0.2);
		/*border: 1px solid rgba($black,.2);*/
		border: 0;
		width: 38px;

		#flash-sidbar-button-tooltip {
			box-shadow: 0px 1px 2px rgba($black, 0.2);
			position: absolute;
			height: 32px;
			width: 130px;
			top: 12px;
			left: 37px;
			background-color: $green;
			display: none;
			font-size: 11px;
			text-align: left;
			padding: 1px;
			padding-left: 5px;
			color: $super-light-green;

			&.visible {
				display: block;
			}
		}
	}
}

#flash-wrap {
	$footer-height: 140px;
	$top-list-width: 300px;

	visibility: hidden;
	@include opacity(0);
	@include transition(all ease-in-out 200ms);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($green, 0.8);
	z-index: $modal-z - 1;

	&.open {
		visibility: visible;
		@include opacity(1);

		#flash-footer-wrap {
			height: $footer-height;
		}
	}

	#flash-tools-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 20px;
		right: 20px;
		z-index: $modal-z;

		#info-icon {
			$bg: rgba($white, 0.2);
			$hover-bg: rgba($white, 0.3);
			background-color: $bg;
			border-color: $bg;
			color: $white;
			border-radius: 2px;
			width: 34px;
			height: 34px;
			display: inline-flex;
			justify-content: center;
			align-items: center;

			.fa {
				padding-left: 1px;
			}
		}

		.up-btn {
			$bg: rgba($white, 0.2);
			$hover-bg: rgba($white, 0.3);
			background-color: $bg;
			border-color: $bg;
			color: $white;
			margin-left: 10px;

			&:hover {
				background-color: $hover-bg;
				border-color: $hover-bg;
			}

			.fa {
				margin-right: 3px;
			}
		}
	}

	.shield-widget {
		position: absolute;
		top: 0;
		height: $footer-height;
		padding: 20px;
		max-width: 230px;

		.shield-widget-shield-wrap {
			text-align: center;

			.shield-widget-shield {
				text-align: center;
				display: inline-block;
				position: relative;

				.shield-widget-icon {
					font-size: 50px;
					color: $bright-blue;
				}

				.shield-widget-count {
					color: $white;
					position: absolute;
					left: 0;
					top: 10px;
					font-size: 25px;
					width: 100%;
				}
			}
		}

		.shield-widget-placeholder {
			color: rgba($white, 0.7);
			font-size: 14px;
			font-style: italic;
			margin-top: 20px;
			margin-bottom: 10px;
		}

		.shield-widget-title {
			color: rgba($white, 0.7);
			font-size: 14px;
		}
	}

	#footer-left-shield-widget {
		left: $top-list-width;
	}

	#footer-right-shield-widget {
		right: $top-list-width;

		.shield-widget-placeholder {
			text-align: right;
		}

		.shield-widget-title {
			text-align: right;
		}
	}

	#flash-result-view {
		position: relative;
		margin: auto;
		width: 620px;
		margin-top: 15%;

		#flash-result-view-icon-row {
			text-align: center;

			.up-icon-shield {
				font-size: 80px;
				text-shadow: 1px 5px 5px rgba($black, 0.4);
				color: $bright-blue;
			}

			.fa {
				position: absolute;
				font-size: 50px;
				color: $white;
				top: 16px;
				left: 286px;
			}
		}

		#flash-result-view-text-row-1 {
			text-align: center;
			font-size: 30px;
			color: $white;
			margin-bottom: 20px;
		}

		#flash-result-view-text-row-2 {
			text-align: center;
			color: $white;
			font-size: 18px;
			margin-bottom: 35px;

			color: rgba($white, 0.7);

			.white-text {
				color: $white;
			}
		}

		#flash-result-view-top-lists-row {
			#right-top-list {
				position: absolute;
				right: 0;
			}
		}
	}

	.top-list {
		display: inline-block;
		height: $footer-height;
		width: $top-list-width;

		.generic-avatar {
			margin-top: 2px;
			margin-left: 5px;
			margin-right: 5px;
		}

		.top-list-row {
			height: $footer-height / 4;
			position: relative;
			line-height: $footer-height / 4;

			&:nth-child(even) {
				background-color: $green;
			}

			&:nth-child(odd) {
				background-color: rgba($green, 0.3);
			}
		}

		.top-list-header-row {
			color: rgba($white, 0.7);

			.top-list-header-row-text {
				margin-left: 10px;
			}

			.top-list-header-row-icon {
				position: absolute;
				right: 0px;
				text-align: center;
				width: 30px;
				cursor: pointer;
			}
		}

		.top-list-value-row {
			color: $white;

			.top-list-value-row-value {
				position: absolute;
				right: 10px;
			}

			&.user-row {
				background-color: rgba($green, 0.6);
			}
		}

		.top-list-placeholder-row {
			color: rgba($white, 0.4);
			padding: 20px 30px 0px 30px;
		}

		.top-list-settings-menu {
			display: none;
			position: absolute;
			color: $white;
			bottom: $footer-height;
			width: $top-list-width;
			padding: 10px;
			background-color: rgba($green, 0.8);
			z-index: 10000;

			&.visible {
				display: block;
			}
		}
	}

	#flash-footer-wrap {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 0;
		transition: ease all 200ms;
		background-color: rgba($green, 0.6);

		#flash-footer {
			position: relative;
			height: 100%;
		}

		#footer-right-top-list {
			position: absolute;
			right: 0;
		}

		#footer-clock-widget {
			position: absolute;
			top: 0px;
			color: $white;
			font-size: 24px;
			left: calc(50% - 150px);
			right: 200px;
			width: 300px;
			height: 200px;
			text-align: center;
			cursor: pointer;

			.title {
				margin-top: 30px;
			}

			.subtitle {
				font-size: 14px;
			}
		}
	}

	#flash-activity-wrap {
		position: relative;

		@include keyframes(flash_animation_r) {
			0% {
				transform: translateX(0);
				@include opacity(1);
			}
			25% {
				transform: translateX(-150px);
				@include opacity(0);
			}
			50% {
				transform: translateX(0px);
				@include opacity(0);
			}
			100% {
				@include opacity(1);
			}
		}

		@include keyframes(arrow_animation_flash) {
			0% {
				@include opacity(1);
			}
			50% {
				@include opacity(0);
			}
			100% {
				@include opacity(1);
			}
		}

		@include keyframes(flash_animation_l) {
			0% {
				transform: translateX(0px);
				@include opacity(1);
			}
			25% {
				transform: translateX(150px);
				@include opacity(0);
			}
			50% {
				transform: translateX(0px);
				@include opacity(0);
			}
			100% {
				@include opacity(1);
			}
		}

		.up-modal {
			display: block;
			top: 70px;
			height: initial;
			bottom: initial;

			&.transition-l {
				@include animation(flash_animation_l 400ms ease-out);
			}
			&.transition-r {
				@include animation(flash_animation_r 400ms ease-out);
			}

			&.full-height {
				bottom: $footer-height + 20px;
			}

			.add-contact-btn {
				height: 20px;
				line-height: 20px;
				padding: 0;
			}

			#date-presets,
			#date-presets-min {
				button {
					padding: 0 0 0 7px;
				}
			}

			.sidebar-list-trigger {
				display: block;
				padding-left: 0;
			}
		}
	}

	.arrow-wrapper {
		position: fixed;
		left: 50%;
		top: 70px;
		width: 1250px;
		height: 800px;
		z-index: $modal-z - 1;

		#nr-of-activity-label {
			position: fixed;
			left: 20px;
			color: $white;
			font-size: 20px;
			top: 27px;
			font-weight: $regularFontWeight;
			z-index: $modal-z + 1;
		}

		#flash-nav-back {
			left: 0px;
			@include border-radius(150px 0 0 150px);

			.fa {
				padding-right: 35px;
				padding-left: 28px;
			}
		}

		#flash-nav-forward {
			@include border-radius(0 150px 150px 0);
			right: 0px;

			.fa {
				padding-left: 35px;
				padding-right: 28px;
			}
		}

		.nav-arrow {
			padding: 0;
			position: absolute;
			color: $white;
			font-size: 35px;
			top: 50%;
			border: none;
			text-align: left;
			margin-top: -75px;
			height: 150px;
			width: 75px;
			background-color: $green;
			z-index: $modal-z + 1;
			@include opacity(0.6);

			&:hover {
				@include opacity(0.8);
			}

			&:active {
				@include opacity(1);
			}

			&[disabled] {
				@include opacity(0.1);
			}

			.fa {
				line-height: 150px;
			}
		}

		@media (max-width: 1265px) {
			#flash-nav-forward {
				background-color: transparent;
				top: -40px;
				font-size: 55px;
				right: 400px;

				&.move-in-right {
					right: 400px !important;
				}
			}
			#flash-nav-back {
				background-color: transparent;
				top: -40px;
				left: 400px;
				font-size: 55px;
				&.move-in-left {
					left: 400px !important;
				}
			}
		}
	}
}
