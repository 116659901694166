@import "~StyleVariables";

.RoleSelect {

	&__input {
		height: 34px;
		line-height: 32px;
		align-content: center;
	}

	.DropDownMenu {
		width: 100%;

		&:hover {
			cursor: pointer;
		}

		&__content {
			margin-top: 2 * $space;
		}
	}

	&--positionRelative {
		.DropDownMenu .DropDownMenu__content {
			position: relative;
		}
	}
}