@import "~StyleVariables";

.DocumentContentEditorProductTable {
	&__info {
		background-color: $super-light-blue;
		border-radius: 4px;
		padding: 8px;
		color: $blue;
		margin-bottom: 12px;
	}

	&__column-type {
		margin: 0 -15px;
		padding: 6px 12px;
		position: relative;
		background-color: $white;
		transition: transform 0.3s ease;
		gap: $space / 2;
		display: flex;
		flex-direction: column;

		&:hover {
			background-color: $grey-1;

			.DocumentContentEditorProductTable__column-move {
				visibility: visible;
				cursor: grab;
			}
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 4px;
			background-color: $grey-6;
			opacity: 0;
		}

		&:hover::before {
			opacity: 1;
		}

		.DocumentContentEditorProductTable__column-move {
			visibility: hidden;
			color: $grey-10;
		}

		&--dragging-active-hover {
			pointer-events: none;
		}

		&--dragging-active {
			background-color: $grey-1;

			.DocumentContentEditorProductTable__column-move {
				visibility: visible;
				cursor: grab;
			}

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 4px;
				background-color: $grey-6;
				opacity: 1;
			}
		}
	}

	&__column-delete {
		width: 30px;
		font-size: 16px; // Size agreed with designer
		display: flex;
		justify-content: center;
		color: $grey-11;

		&:hover {
			cursor: pointer;
		}
	}

	&__add-column-btn {
		margin: $space * 3 0 $space 0;
		width: 100%;

		.Icon {
			margin-right: $space * 2;
		}
	}

	&__column-info {
		margin-top: $space;
	}

	&__column-settings {
		display: flex;
		align-items: center;
		flex: 0;
		gap: $space * 2;
		margin-left: $space * 2;

		.btn.up-btn.btn-xs,
		.up-btn.btn-xs {
			padding: 0 $space * 2;
		}
	}

	&__additional-setting {
		width: 100%;
		margin-top: $space * 3;

		&--text-input {
			display: flex;
			flex-direction: column;
		}
	}

	&__toggle {
		align-items: center;
		display: flex;
		flex: 0;
	}

	&__add-modal-left {
		padding: $padding;
		border-right: 1px solid $grey-6;
	}

	&__add-modal {
		height: 100%;
	}

	&__add-modal-right {
		background-color: $white;
		padding: $padding;
		padding-bottom: 0;
		width: 50%;
		height: 100%;
		overflow: hidden;

		.DocumentTemplateEditorDesign__sidebar-section {
			padding: 0;
			height: 100%;
			border: none;
			display: flex;
			flex-direction: column;
		}

		.DocumentTemplateAvailableTags__tag-row {
			padding: 8px $padding;
			margin: 0 -15px;
			cursor: pointer;

			&:hover {
				background-color: $grey-1;

				.Icon {
					opacity: 1;
				}
			}
		}

		.DocumentTemplateEditorDesign__sidebar-section-input-row {
			margin: 0;
			padding-bottom: $padding;
		}

		.DocumentTemplateAvailableTags__info-text {
			padding: 0;
			display: none;
		}

		.DocumentTemplateAvailableTags__sidebar-section-top {
			margin: -15px;
			margin-bottom: 0;
			padding: 0 $padding;
			border-bottom: 1px solid $grey-6;
		}

		.DocumentTemplateAvailableTags__content {
			margin: 0 -15px;
			padding: 0 $padding;
		}
	}

	&__add-modal-input {
		margin-bottom: $space * 4;

		.Label {
			font-weight: 600;
			width: 100%;
			align-self: stretch;
		}

		.Input {
			width: 100%;
		}
	}

	&__modal-custom-size {
		width: 664px;
		height: 551px;
	}

	&__modal-content {
		height: 100%;
		overflow: hidden;
		padding: 0;

		.ModalContent__content {
			height: 100%;
		}
	}
}
