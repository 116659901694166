.StepIntInput {
	.Input__input {
		text-align: center;
	}

	.Input--sm .Input__input {
		height: 30px;
		line-height: 30px;
	}

	.Button {
		flex: 0 0 34px;
	}
}
