@import "variables.scss";

.BranchOffices {
	div.DataSourceColumn {
		border-top: none;
		padding-left: 0;

        .row-text {
            height: 18px;
            line-height: 18px;
        }

		.additional-info {
			background-color: $grey-4;
			border-radius: 2px;
			padding: 2px 4px 2px 4px;
            font-size: 12px;
            color: $grey-11;
		}
	}
}
