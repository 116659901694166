@import "~StyleVariables";

.ReportcenterViews {
	--filterHeight: 0px;
	--paddingTop: 0px;
	--nbrOfViews: 0;
	padding: 0 15px;
	padding-top: var(--paddingTop);
	padding-bottom: 5px;
	top: var(--filterHeight);
	position: sticky;
	z-index: 1002;
	background-color: $white;
	transition: all ease-in-out 250ms;

	.Label {
		font-size: 12px;
	}

	.ButtonSelect .Text {
		min-width: 70px;
	}

	&--autoWidth .ButtonSelect .Button {
		max-width: unset;
	}
}
