@import "~StyleVariables";

.AddField {
	font-size: 14px;

	.Tabs {
		position: sticky;
		position: -webkit-sticky;
		top: 49px;
		z-index: 1002;
	}

	.TableColumn {
		font-size: 14px;
	}

	&__FieldType {
		span {
			margin-left: 10px;
		}
	}

	&__NoRecords {
		text-align: center;
		margin-top: 100px;
	}

	&__OptIn {
		padding: 5px 20px;

		.Card {
			border: 1px solid $grey-6;
			border-radius: 2px;
			box-shadow: 0 1px 2px 0 rgba($black, 0.1);
			padding: 20px;
			margin: 15px auto;
			cursor: pointer;

			&:hover {
				background-color: $grey-1;
			}
		}
	}

	&__NoData {
		text-align: center;

		&.Headline {
			margin: 30px auto 20px;
		}
	}

	&__DataField {
		// https://upsales.jira.com/projects/MM/issues/MM-250
		// Add margin bottom to the .Table (so that clicking the "Contact fields"-tab scrolls down to the section.
		margin-bottom: 90vh;
	}

	&__FormSpecific {
		padding: 5px 20px;

		&Icon {
			width: 60px;
			font-size: 24px;
			text-align: center;
			flex-grow: 0;
			flex-shrink: 0;
		}

		.Row {
			position: relative;
			top: 9px;
			transition: all cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		}

		.Text--md {
			transition: opacity cubic-bezier(0, 0, 0.08, 1.27) 400ms;
			opacity: 0;
			line-height: 18px;
		}

		.Row--multiline .Text--md {
			height: 18px;
			overflow: hidden;
		}

		.number-type {
			font-size: 24px;
		}

		.Card {
			border: 1px solid $grey-6;
			border-radius: 2px;
			box-shadow: 0 1px 2px 0 rgba($black, 0.1);
			height: 70px;
			margin-top: 11px;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;

			&:hover {
				background-color: $grey-1;

				.Row {
					top: 0;
				}

				.Text--md {
					opacity: 1;
				}

				.Row--multiline .Text--md {
					height: auto;
				}
			}
		}

		&Card--disabled {
			background-color: $grey-5;

			&:hover {
				background-color: $grey-5 !important;
			}
		}
	}

	&__DataType {
		color: $black;
		font-weight: $boldFontWeight;
		background-color: $grey-1;

		&.TableColumn {
			font-size: 18px;
		}
	}

	&__Field {
		&--selected {
			background-color: $grey-2;
			border-color: $grey-4;
			color: $grey-10;

			span {
				font-style: italic;
			}

			.pull-right {
				margin-right: 10px;
			}
		}
	}

	.Tab {
		white-space: nowrap;
	}

	&__NoData {
		margin-top: 50px;
	}
}
