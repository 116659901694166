@import "../../../../styles/variables";

.allow-overflow {
	overflow: initial;
}

#refresh-account {
	&.Modal {
		display: flex;
		flex-direction: column;
		background-color: $white;
	}

	.ModalHeader {
		z-index: 9000;
		border-bottom: none;
		background-color: $grey-2;
	}

	.ModalContent {
		z-index: 4900;
		overflow-x: hidden;
		padding: 20px 0 0 0;
		padding-bottom: 60px;
		background-color: $grey-2;

		.country-select-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 200px;
			margin: auto;
			height: 50px;
		}

		.tab-icon {
			max-height: 26px;
			-webkit-box-shadow: 0 1px 2px rgba(30, 37, 46, 0.2);
			box-shadow: 0 1px 2px rgba(30, 37, 46, 0.2);
			margin-right: 10px;
			border-radius: 2px;
		}

		.total-sum {
			display: inline-block;
			margin: 0 5px;
			padding: 0 10px;
			background: #f5f5f5;
			border-radius: 3px;
		}

		.tabs-wrapper {
			height: 50px;
			background-color: $white;
			box-shadow: 0 1px 2px rgba($black, 0.2);
			margin-bottom: 1px;
		}

		.Table {
			table-layout: fixed;
			background: $white;

			.TableColumn {
				.title {
					display: block;
				}
			}

			.TableRow {
				.DataSourceBuyButton {
					width: 37px;
					overflow: hidden;

					span {
						display: none;
					}
				}

				.DataSourceBuyButton.DataSourceBuyButton-expanded {
					span {
						display: inherit;
					}
				}
			}

			.DataSourceBuyButton.DataSourceBuyButton-expanded {
				width: 120px;
			}

			.TableRow:hover {
				background-color: $grey-1;

				.DataSourceBuyButton {
					width: 120px;

					span {
						display: inherit;
					}
				}
			}
		}

		.TableHeader__column--change-country {
			text-transform: none;
			text-align: right;
			padding-right: 15px;

			.flag-icon {
				margin-right: 5px;
			}
		}
	}

	.ModalControls {
		margin-top: 1px;
		border-radius: 0 0 3px 3px;
		display: flex;
		justify-content: space-between;

		.available-credits {
			float: left;

			> .inner-available-credits {
				display: inline-block;
				line-height: 33px;

				.credits-number {
					font-weight: $boldFontWeight;
				}
			}
		}
	}

	.header-content {
		background-color: $white;
		padding: 46px 15px 15px;
		margin-top: -47px;

		> img {
			position: absolute;
			right: 60px;
			width: 100px;
			top: 10px;
		}

		.main-text {
			color: $grey-10;
			display: block;
		}
	}

	.InUpsales__title-wrap {
		background-color: white;
		padding-left: 15px;
		height: 30px;
		padding-top: 8px;
	}

	.InUpsales .Loader {
		margin: auto;
	}
}
