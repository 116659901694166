@import "~StyleVariables";

.FileStorageApps__app-header {
	position: relative;
	padding: 15px 20px;
	display: flex;
	align-items: center;

	.ButtonGroup {
		display: flex;
		align-items: center;
	}

	.Card {
		width: 28px;
		height: 28px;
		background-position: center center;
		background-size: 80%;
		background-repeat: no-repeat;
		display: inline-block;
		vertical-align: middle;
	}

	.Title {
		display: inline-block;
		vertical-align: middle;
		line-height: 28px;
		margin-left: 10px;
	}

	.ButtonGroup {
		position: absolute;
		right: 15px;
	}

	.disconnect {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-right: 20px;

		.Text {
			margin-left: 10px;
		}
	}
}
