@import "~StyleVariables";

.PercentageInput {
	display: flex;
	border-radius: $space;
	border: 1px solid $grey-6;

	&__input {
		.Input__input {
			width: 15 * $space;
			border: none;
			box-shadow: none;
			border-radius: $space 0 0 $space;
		}
	}

	&__right-side {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 34px;
		min-width: 8 * $space;
		border-radius: 0 $space $space 0;
		background-color: $grey-2;
	}

}