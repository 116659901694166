@import "~StyleVariables";

.AppSyncLogCard {
	margin-bottom: 15px;
	overflow-y: hidden;
	overflow-x: auto;

	.TableHeader__column {
		&:first-child {
			width: 160px;
		}
	}

	.TableRow--sync {
		td:first-child {
			position: relative;
		}

		td:first-child:before {
			content: "";
			position: absolute;
			background-color: none;
			width: 5px;
			top: -1px;
			bottom: -1px;
			left: 0;
		}

		&-yellow td:first-child:before {
			background-color: $yellow;
		}

		&-success-4 td:first-child:before {
			background-color: $success-4;
		}

		&-red td:first-child:before {
			background-color: $red;
		}
	}

	.TableColumn {
		white-space: nowrap;
	}

	.TableColumn--statusColumn {
		padding-right: 10px;
	}

	.AppSyncLogCard--noSync {
		padding: 50px 0;

		> div:first-child {
			margin-bottom: 10px;
		}
	}
}
