.up-widget {
	@include widget-card();

	&.finance {
		vertical-align: top;
		background-color: $bright-blue;
		color: $white;
	}

	&.info {
	}

	&.linked {
		cursor: pointer;
	}

	.loader {
		text-align: center;
		height: 40px;
		line-height: 40px;
		color: $grey-8;
		font-size: 26px;
	}

	&.ad-campaigns {
		position: relative;
		max-width: 240px;
		min-width: 170px;

		.subtitle {
			font-size: 15px;
		}

		.clicks {
			border-left: 1px solid $grey-8;
			padding-left: 8px;
			margin-left: 8px;
			padding-top: 4px;
			padding-bottom: 4px;
		}

		.ad-widget-bottom-row {
			background-color: $grey-1;
			padding: 8px;
			font-size: 12px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding-left: 21px;
		}

		.inactive-campaign {
			color: $grey-8;
		}
		.active-campaign {
			color: $bright-blue;
		}
	}
}