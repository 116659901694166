@import "~StyleVariables";

.CustomerPortfolioRiskTab {
	width: 100%;

	&__widgets {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	&__widgets-column {
		width: 50%;
		display: flex;
		flex-direction: column;
	}
};