@import "~StyleVariables";

.FlowStepBase {
	$left: 30px;
	padding: 8px $left 8px ($left + 8px);

	&--clickable {
		cursor: pointer;
	}

	&__titleRow {
		width: calc(100% + 27px);
		height: 18px;
	}

	&__warning {
		position: absolute;
		z-index: 1;
		height: 22px;
		line-height: 22px;
		text-align: center;
		width: 30px;
		left: -16px;
		top: -16px;
		border-radius: 8px;
		font-size: 12px;
	}

	&__icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: $left;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__title {
		text-transform: uppercase;
		font-size: 8px;
	}

	&__lock {
		position: absolute;
		top: 10px;
		right: 8px;
	}

	&__trash {
		padding: 5px;
		opacity: 0;
		transition: all 200ms ease-in-out;
	}

	&__trash:hover {
		color: $black;
	}

	&:hover {
		.FlowStepBase__trash {
			opacity: 1;
		}
	}
}
