@import "~StyleVariables";

.GoalsDropDown {
	.DropDownButton {
		height: 32px;
		border: none;
		border-bottom: 1px solid $grey-6;
		&__text-wrapper {
			padding-left: 5px;
		}
		&__icon-wrapper {
			margin: auto 5px auto 0;
		}
	}
	.TableColumn {
		background-color: $white;
		color: $black;
		&:hover {
			background-color: $bright-blue;
			color: $white;
		}
	}
}
