.MailTemplatePreviewSocial {
	// One might be tempted to use flex here, but we want to mimic the behaviour in email browsers
	// and we use inline-blocked divs when sending the email
	&__social-icons-preview {
		display: block;

		&--left {
			text-align: left;
		}

		&--center {
			text-align: center;
		}

		&--right {
			text-align: right;
		}

		img {
			vertical-align: unset;
		}
	}
}
