.content-card {
	position: relative;
	width: 100%;
	background-color: $white;
	border: 1px solid $grey-4;
	border-radius: $border-radius;
	margin-bottom: 20px;
	@include transition(all ease-out 200ms);
	padding: 20px;
	@include box-shadow(0 1px 2px rgba($black, 0.1));

	&.no-padding {
		padding: 0;
	}
}
