.editor-tools {
	$tools-width: 350px;
	position: absolute;
	top: 0;
	right: -($tools-width + 10px);
	bottom: 0;
	width: $tools-width;
	@include transition(right ease 200ms);
	background-color: $grey-4;
	border-left: 1px solid $grey-8;
	overflow: hidden;
	overflow-y: scroll;
	font-size: 12px;
	z-index: 1001;
	@include box-shadow(0 0 10px rgba($black, 0.3));

	ul.tool-tabs {
		$size: 36px;
		display: block;
		list-style-type: none;
		padding: 0;
		margin-bottom: 0;
		overflow: hidden;

		li {
			background-color: $grey-4;
			display: inline-block;
			width: 50%;
			height: $size;
			line-height: $size;
			text-align: center;
			color: $grey-10;
			@include transition(all ease 200ms);
			cursor: default;

			&:hover:not(.selected-tab) {
				color: $grey-11;
				background-color: $grey-5;
			}

			&.selected-tab {
				position: relative;
				background-color: $white;
				border-bottom: none;
				z-index: 1000;
				color: $grey-11;
				@include box-shadow(0 0 5px rgba($black, 0.2));
			}
		}
	}

	.tool-main-section {
		position: relative;
		background-color: $white;
		border-bottom: 1px solid $grey-6;
		padding: 5px 0;
		margin-bottom: 5px;

		&.no-border {
			border-bottom: none;
		}

		&.no-margin {
			margin-bottom: 0;
		}

		&.no-padding {
			padding-top: 0;
			padding-bottom: 0;
		}

		&.transparent {
			background-color: transparent;
		}

		.unit-text-wrapper {
			display: flex;
			justify-content: space-between;

			.unit-text {
				display: flex;
				flex-direction: column;
				width: 30%;

				input {
					border: 1px solid #ccc;
					border-radius: 4px;
					padding: 4px;
				}
			}
		}
	}

	.tool-section {
		padding: 5px 15px;

		.up-ios-toggle {
			margin-top: 5px;
		}

		&#change-background {
			.up-ios-toggle {
				margin-top: 1px;
			}
		}
		.image-link-section {
			padding: 10px 0px;
			.Input__input {
				height: 34px;
			}
		}
	}

	label.tool-label {
		font-size: 12px;
		line-height: 28px;
		font-weight: $boldFontWeight;

		&.block-label {
			display: block;
		}
	}

	.tool-header {
		padding: 5px 15px;

		& > .fa {
			color: $green;
			margin-right: 10px;
		}

		& > h3 {
			font-size: 14px;
			display: inline-block;
			line-height: 30px;
			margin-top: 0;
			margin-bottom: 0;
		}

		& > .up-ios-toggle.pull-right {
			margin-top: 6px;
		}
	}

	.color-input {
		@include border-radius(3px);
		width: 80px;
		text-align: center;
	}

	.img-preview {
		position: relative;
		@include border-radius(5px);
		height: 70px;
		background-color: $medium-green;

		.img-element {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.up-btn {
			position: absolute;
			width: 120px;
			left: 50%;
			margin-left: -60px;
			top: 17px;
		}
	}

	.up-step-int {
		width: 120px;
	}
}
