@import "~StyleVariables";

$margin: $space * 5;

.BlacklistTemplate {
	&__inputContainer {
		margin: 0 $margin 0 $margin;
	}

	.TableColumn {
		max-width: 600px;

		&:last-child {
			padding-right: 0;
		}
	}

	.Input {
		width: 300px;
	}

	&__delete {
		font-size: 14px;
	}
}
