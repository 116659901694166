@import "~StyleVariables";

.MailTemplatePreviewColumnTools {
	$height: 30px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 200ms;
	z-index: 1000;

	&__drag-handle,
	&__remove {
		position: absolute;
	}

	&__drag-handle {
		height: $height;
		line-height: $height;
		width: $height;
		text-align: center;
		left: 0;
	}

	&__remove {
		right: 0;
	}
}
