@import "~@upsales/components/Utils/colors";
@import "~@upsales/components/Utils/boxShadow";
// @import '_colors';

/* Dimention variables */
$navbar-height: 46px;
$navbar-profile-img-size: 33px;
$sidebar-width: 65px;
$sidebar-width-expanded: 200px;
$main-menu-height: 31px;
$modal-width: 700px;
$modal-confirm-width: 450px;
$modal-content-margin: 52px 0 56px 0;
$modal-form-sm-width: 600px;
$modal-form-md-width: 790px;
$modal-form-width: 900px;
$modal-info-width: 900px;
$modal-header-height: 46px;
$sub-nav-height: 42px;
$sub-menu-height: $main-menu-height + 5px;
$padding: 15px;
$padding-horizontal: 0 10px;
$header-height: 50px;
$header-height-sm: 48px;
$main-top: ($navbar-height + $main-menu-height) + 20px;
$card-sidebar-width: 300px;
$card-sidebar-width-sm: 200px;
$card-footer-height: 42px;
$modal-sidebar-width: 235px;
$modal-sidebar-width-wide: 335px;
$modal-sidebar-right-width: 270px;
$up-filters-width: 300px;

// Buttons
$up-btn-height-xs: 22px;
$up-btn-height-sm: 30px;
$up-btn-height: 34px;
$up-btn-height-lg: 40px;
$up-btn-height-xl: 46px;

/* Font variables */
/* Franklin Gothic demi for headings and Arial for the rest */
$h-font-family: "Inter", sans-serif;

$main-font-family: "Inter", sans-serif;
$modal-font-size: 12px;
$navbar-font-size: 13px;
$h2-font-size: 19px;
$h3-font-size: 16px;

/* Other */
$up-curtain-z: 1003;
$modal-z: 10000;
$notification-z: 20000;

/* Border radius */
$border-radius-sm: 4px;
$border-radius: 4px;
$border-radius-lg: 6px;

/* Border */
$border: 1px solid $grey-6;

/* Transitions */
$transition-small-time: 300ms;
$transition-small-bounce-tf: cubic-bezier(0.3, 1.35, 0.68, 1.21);
$transition-small-bounce: all $transition-small-bounce-tf $transition-small-time;
$transition-small-ease: all ease-out $transition-small-time;

/* Screen sizes */
$responsiveWidht1: 1420px;
$responsiveWidht2: 1300px;
$responsiveWidht3: 1168px;
$responsiveWidht4: 1024px;
$responsiveWidht5: 890px;
$responsiveWidht6: 700px;

// COLOR INHERITANCES
// MARKETING
$marketing-general: $purple;
$form-color: $purple;
$landing-page-color: $purple;
$email-campaign-color: $medium-purple;
$advertising-color: $medium-purple;
$website-visits-color: $bright-blue;
// SALES
$sales-general: $blue;
$order-color: $green;
$opportunity-color: $blue;
$appointment-color: $medium-blue;
$activity-color: $bright-blue;
// GENERAL
$link-color: $bright-blue;
$headline-color: $grey-13;
$subtitle-color: $grey-10;
// MAIL EVENT ICONS
$read-click-active: $super-light-blue;

// UPDATES
$update-bg: $super-light-green;

/* Box shadows */
$box-shadow-xs: 0px 1px 0px 0px $grey-4;
$box-shadow-above-xs: 0px -1px 0px 0px $grey-4;
$box-shadow-md: 0 1px 2px rgba($black, 0.2);
$box-shadow-above-md: 0 -1px 2px rgba($black, 0.2);
$box-shadow-lg: 0 4px 8px rgba($black, 0.2);
$box-shadow-above-lg: 0 -4px 8px rgba($black, 0.2);
$box-shadow-xl: 0 6px 12px rgba($black, 0.2);
$box-shadow-xxl: 0 12px 24px rgba($black, 0.2);

$box-shadow-links-menu: 0px 8px 16px 0px rgba($green, 0.2);

$regularFontWeight: 400;
$boldFontWeight: 600;
$space: 4px; // This is the base for spacing. Multiply by 2, 3, 4, etc. to get the desired spacing.
