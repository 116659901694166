@import "~StyleVariables";
.TimelineEntityNote {
	margin-bottom: 12px;

	&--angular {
		margin-bottom: 0px;
	}

	.TimelineRow,
	.TimelineCard {
		margin-bottom: 0px;
	}
}
