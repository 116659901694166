@import "~StyleVariables";

.IndustryInsightsAccount {
    height: calc(100vh - #{$header-height} * 2);
    overflow: auto;
    width: 100%;
    padding: $space * 6;

    &__chartWidgetContainer {
        background-color: $white;
    }

    &__chartWidget {
        border: 1px solid $grey-6;
        border-radius: $space * 2;
        width: 100%;
    }

    &__chart {
        width: 100%;
    }

    &__yearSelect {
        max-width: 25%;
    }
}
