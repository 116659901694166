@import "../../../../styles/variables";

#flow-popup .preview-content {
	&.abtesting-preview-content {
		overflow-y: initial;

		.step-type {
			padding: 7px 0 7px 50px;

			img {
				left: 10px;
				width: 30px;
				position: absolute;
				top: 10px;
			}
		}

		.preview-left {
			width: 100%;
		}

		.action-rows {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 0;
			justify-content: center;
			align-items: center;

			.block {
				margin: 10px 0;
				color: $grey-11;
				width: 150px;
				text-align: center;

				.spacing {
					margin: 0 5px;
				}

				.stats {
					display: inline-block;
					width: 65px;

					&.stats-a {
						float: left;
					}

					&.stats-b {
						float: right;
					}

					.percent {
						display: block;
						font-size: 34px;
						font-weight: $boldFontWeight;
						letter-spacing: -1px;
						line-height: 1;
					}
				}
			}
		}
	}
}
