@import "~StyleVariables";

.TodoListOverdueInfo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: $super-light-yellow;
	border-radius: 4px;
	align-items: center;
	height: 60px;
	transition: all 0.3s ease;
	&--dismissed {
		animation: shrink 0.3s ease;
		animation-fill-mode: forwards;
	}
	&:hover {
		background-color: darken($super-light-yellow, 5%);
		cursor: pointer;
	}
	.Button {
		color: $grey-10 !important;
		&:hover {
			color: $grey-11 !important;
		}
	}
	&__info-text {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	@keyframes shrink {
		0% {
			height: 60;
		}

		100% {
			height: 0px;
			opacity: 0;
			display: none;
			padding: 0px;
		}
	}
}
