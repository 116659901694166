$navbar-height: 50px;

.EditorHeaderDropdown {
	display: inline-block;
	position: relative;
	height: $navbar-height;

	.Button {
		height: $navbar-height;
		border-radius: 0;
	}

	&__dropdown {
		position: absolute;
		right: 0;
		top: $navbar-height;
		width: 250px;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
	}

	&--visible &__dropdown {
		visibility: visible;
		opacity: 1;
	}
}
