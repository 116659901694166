@import "../../styles/variables";

.ListSegments {
	&__column {
		&__status {
			.Icon:not(.Icon-plus) {
				color: $black;
				&.Icon-play {
					color: #3d993d;
				}
			}
		}
	}
}
