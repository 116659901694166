.Modals {
	.IntegrationModal {
		// up-modal fullscreen styling
		&--fullscreen {
			top: 20px;
			right: 20px;
			bottom: 20px;
			left: 20px;
			margin-left: 0;
			height: auto;
			width: auto;
		}
	}
}
