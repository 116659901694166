.select2-highlighted .soliditet-matcher-dropdown-format {
	min-height: 30px;

	.org-no {
		color: $white;
	}
	.sni-text {
		color: $white;
	}
	.other-info {
		color: $white;
	}
	.employees {
		color: $white;
	}
}

.soliditet-matcher-dropdown-format {
	line-height: 18px;
	min-height: 30px;

	.org-no {
		font-size: 12px;
		color: $grey-8;
	}

	.sni-text {
		font-size: 11px;
		color: $grey-11;
		width: 230px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.other-info {
		font-size: 11px;
		color: $grey-11;
	}

	.turnover {
		padding-left: 70px;
	}

	.employees {
		float: right;
		color: $grey-11;
	}

	.hide {
		visibility: hidden;
	}

	.indicator-wrapper.indicator-sm {
		height: 13px;

		i {
			position: relative;
			top: -1px;
		}
	}
}

#soliditet-matcher {
	background-color: $grey-2;
	
	#bulk-wash-button {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
	}

	#title {
		width: 100%;

		.Text {
			display: inline-block;
		}
	}

	.spinner-wrapper {
		height: 600px;
		font-size: 50px;
		text-align: center;
		color: $grey-8;

		.spinner {
			top: 300px;
			position: absolute;
		}
	}

	.hidden-toggle {
		width: 100%;
		height: 600px;
		margin-top: -50px;
	}

	.filter {
		height: 44px;
		width: 100px;
		position: absolute;
		right: 0px;
		top: 0px;
		text-align: center;
		color: $bright-blue;
		background-color: $white;

		.toggle {
			cursor: pointer;
		}

		&.show {
			box-shadow: 0px 1px 6px $grey-10;
		}

		&.show .dropdown {
			visibility: visible;
		}

		.dropdown {
			top: 45px;
			background-color: $white;
			right: 0;
			position: absolute;
			height: 100px;
			box-shadow: 0 1px 6px $grey-10;
			width: 400px;
			visibility: hidden;

			.hide-shit {
				background-color: $white;
				position: relative;
				width: 100px;
				height: 5px;
				top: -4px;
				z-index: 1;
				right: -300px;
			}

			.option-text {
				width: 300px;
				font-size: 13px;
				display: inline-table;
				line-height: normal;
				text-align: left;
				color: $grey-11;
			}

			.option-toggle {
				display: inline;
				float: right;
				padding-right: 10px;
			}
		}
	}

	.white-background {
		background-color: $white;
	}

	.blue-background {
		background-color: $grey-1;
	}

	.info-circle {
		color: $bright-blue;
		font-size: 16px;
		cursor: pointer;
	}

	.main-table {
		background-color: $grey-1;
		margin-top: 50px;

		td {
			cursor: default;
		}

		th {
			min-width: 8px;
			background-color: $grey-4;
			color: $grey-11;
			border-bottom: 1px solid $grey-6;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			vertical-align: bottom;
			padding: 5px 10px;

			.last {
				padding-right: 8px;
			}
		}
	}

	.column {
		font-size: 12px;
		height: 50px;
		white-space: nowrap;
		border-bottom: 1px solid $grey-6;
	}

	.name-col {
		min-width: 250px;
		color: $bright-blue;
		padding-left: 9px;

		.position {
			position: relative;
			top: 1px;
		}

		.icons {
			position: relative;
			top: 2px;

			.icon-wrapper {
				display: inline;
				padding-left: 3px;
				font-size: 11px;
				position: relative;
				top: -2px;
				color: $grey-8;

				.flag-icon {
					top: -1px;
				}
			}

			.indicator-wrapper.indicator-sm {
				height: 13px;

				i {
					position: relative;
					top: -1px;
				}
			}
		}
	}

	.city-col {
		min-width: 125px;
		padding-left: 9px;
	}

	td.match-col {
		cursor: pointer;
	}

	.match-col {
		padding-left: 9px;
		min-width: 350px;
		width: 99%;
		border-left: 1px solid $grey-6;
		color: $bright-blue;

		.select-div {
			position: relative;
		}

		.no-matches {
			color: $grey-8;
		}

		.select2-container {
			z-index: 1000;
			visibility: hidden;
			position: absolute;
			left: 0;
			width: 330px;

			&.select2-dropdown-open {
				visibility: visible;
			}
		}

		.upsales-match {
			color: $black;

			i {
				color: $bright-blue;
			}
		}

		.soliditet-match {
			color: $grey-13;

			.bold {
				font-weight: $boldFontWeight;
			}

			.city {
				display: inline-block;
				width: 30%;
			}

			.turnover {
				display: inline-block;
				width: 40%;
			}

			.employees {
				display: inline-block;
				width: 30%;
			}
		}
	}

	.action-col {
		text-align: center;
		border-left: 0;
		border-right: 0;
		padding: 0;

		.inner {
			min-width: 65px;
			background-color: $grey-4;
			cursor: pointer;
			height: 34px;

			&.hide {
				visibility: hidden;
			}

			i {
				color: $grey-10;
				margin-top: 11px;
			}

			&.grey-background {
				background-color: $grey-4;
			}
		}

		.inner.selected i {
			color: $white;
		}
	}

	.match-button {
		.inner {
			@include border-radius(4px 0px 0px 4px);
		}
		.inner.selected {
			background-color: $bright-green;
		}
	}

	.hide-button {
		.inner.selected {
			background-color: $orange;
		}
	}

	.remove-button {
		padding-right: 8px;

		.inner {
			@include border-radius(0px 4px 4px 0px);
		}
		.inner.selected {
			background-color: $red;
		}
	}

	@media (min-width: 992px) {
		.container {
			width: 900px;
		}
	}

	.header-box {
		width: 100%;
		margin: 0 auto;
		margin-top: 65px;
		margin-bottom: 5px;
		background-color: $white;
		@include border-radius(4px);
		@include box-shadow(1px 2px 6px rgba($black, 0.2));

		.text {
			float: left;
			width: 60%;
			margin: 25px 0px 0px 25px;

			.title {
				font-size: 20px;
			}

			.info {
				margin-top: 10px;
				color: $grey-10;
			}
		}
		.icon {
			float: right;
			width: calc(35% - 15px);

			.img {
				height: 160px;
				@include border-radius(0px 3px 3px 0px);
				background: url("../img/account-profile-header.png");
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}
		}
	}

	.placeholder {
		background-color: $grey-2;

		.btn-inner-wrap {
			padding-top: 20px;
		}
	}

	.cross {
		float: right;
		margin-top: -165px;
		margin-right: -17px;
		color: $grey-10;
		font-size: 15px;
		cursor: pointer;
	}

	.footer-space {
		padding-top: 45px;
		background-color: $grey-5;
	}

	.footer {
		position: fixed;
		bottom: 0;
		left: 65px;
		right: 15px;
		height: 45px;
		@include border-radius(0 0 3px 3px);
		padding-top: 7px;
		background-color: $white;
		text-align: center;
		@include box-shadow(0 -1px 6px rgba($black, 0.1));
		z-index: 9997;

		.disable-button {
			cursor: default;
		}

		.LimitSelect {
			position: absolute;
			right: 20px;
			top: 12px;
		}
	}

	.footer--removeFrame {
		left: 47px;
		right: 0px;
	}
}
