@import "~StyleVariables";

.FocusModeFooter {
	box-shadow: 0px -1px 15px 0px rgba(30, 37, 46, 0.2);

	.Icon-chevron-left {
		margin-right: 10px;
	}

	.Icon-chevron-right {
		margin-left: 10px;
	}
}
