@mixin floating-label-shared-props() {
	& {
		position: relative;
		margin-bottom: 10px;
		display: block;
		padding-top: 14px;
		font-size: 14px;
	}

	&.has-value {
		& > label {
			font-size: 12px;
		}
	}

	&.has-error {
		& > input,
		& > .select2-container {
			border-bottom-color: $red !important;
		}

		& > label {
			color: $red;
		}
	}

	&.floating-label-sm {
		font-size: 12px;

		&.has-value {
			& > label {
				font-size: 10px;
			}
		}
	}

	&.floating-label-lg {
		font-size: 20px;

		&.has-value {
			& > label {
				font-size: 14px;
			}
		}
	}

	& > label,
	& > textarea,
	& > input {
		display: block;
		margin: 0;
		padding: 0 3px;
		font-size: 1em;
	}

	& > label {
		position: absolute;
		color: $grey-10;
		font-weight: normal;
		z-index: 1000;
		line-height: 1.6em;
		height: 1.6em;
		font-weight: normal;
		@include transition(all ease 200ms);
	}

	& > textarea,
	& > input,
	& > .select2-container {
		position: relative;
		z-index: 1001;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $grey-8;
		width: 100%;
		line-height: 1.6em;
		color: $grey-13;
		@include transition(border ease 200ms);

		&:focus {
			border-bottom-color: $bright-blue;
			outline: none;
			color: $bright-blue;
		}
	}
}

.floating-label-textarea {
	@include floating-label-shared-props();

	& > label {
		top: 1em;
	}

	&.has-value {
		& > label {
			top: 0;
		}
	}

	& > textarea {
		resize: none;
		overflow: hidden;
		min-height: 1.5em;
	}
}

.floating-label-input {
	@include floating-label-shared-props();

	& > label {
		bottom: 1px;
	}

	&.has-value {
		& > label {
			bottom: 1.7em;
		}
	}

	&.has-value.floating-label-sm {
		& > label {
			bottom: 1.9em;
		}
	}
	&.has-value.floating-label-lg {
		& > label {
			bottom: 2.2em;
		}
	}
}

.floating-label-select2 {
	@include floating-label-shared-props();

	& > label {
		bottom: 0;
		top: initial;
	}

	&.has-value {
		& > label {
			top: 0;
			bottom: initial;
		}
	}

	& > .select2-container {
		background: rgba($black, 0);
		@include box-shadow(none);
		@include border-radius(0);
		min-height: 1.5em;

		.select2-choices {
			background: rgba($black, 0);
			border-color: rgba($black, 0);
			box-shadow: none;
			min-height: 1.5em;

			.select2-search-field input {
				display: block;
				margin: 0;
				padding: 0 3px;
				line-height: 1.5em;
				height: auto;
				font-size: 12px;
				color: $grey-13;

				&:focus {
					border-color: $bright-blue;
					outline: none;
					color: $bright-blue;
				}
			}
		}

		.select2-search-choice {
			background: $bright-blue !important;
			color: $white;
			border-radius: 0;
			margin: 2px 2px 0 0;
		}
	}
}
