@import "~StyleVariables";

.EmailInfo .criteria-row {
	white-space: nowrap;
	width: 500px;

	> div {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.EmailInfo__title {
	font-weight: $boldFontWeight;
	font-size: 13px;
}

.EmailInfo__sub-title {
	font-size: 11px;
	color: $grey-11;
}
