.label {
	text-transform: uppercase;
	font-weight: normal;
	padding: 0.4em 0.8em 0.3em;

	@mixin label-color($bgColor, $textColor: $white) {
		background-color: $bgColor;
		color: $textColor;

		&.lined {
			color: $bgColor;
			background-color: transparent;
			border: 1px solid;
			border-color: $bgColor;
		}
	}

	&.label-default {
		@include label-color($grey-8);
	}

	&.label-success {
		@include label-color(rgba($medium-green, 0.8));
	}

	&.label-warning {
		@include label-color(rgba($orange, 0.8));
	}

	&.label-bright-blue {
		@include label-color($bright-blue);
	}

	&.label-sm {
		padding: 2px 5px;
		font-size: 10px;
	}
}
