@import '~StyleVariables';

.SuggestedClientContact {

    &__suggestedBox {
        width: 390px;
    }

    &__btnSelect {
        flex-basis: 100%;
        .Text.Text {
            line-height: 1.2 !important; // Overrides the '!important' from Button
        }
    }

    &__result {
        max-height: 193px; // To avoid a fourth item showing and making it look off
        overflow-y: auto;
    }

    &__row {
        border-bottom: 1px solid $grey-4;

        .DropDownMenu.DropDownMenu.DropDownMenu {
            width: unset;
        }
    }

    &__confirmDropdown {
        .DropDownMenu__content.DropDownMenu__content.DropDownMenu__content.DropDownMenu__content {
            margin-top: 8px; // to align with the title of the card
            margin-left: -8px; // to align with the right border of the card content
        }

        &--moveClient {
            .DropDownMenu__content.DropDownMenu__content.DropDownMenu__content.DropDownMenu__content {
                margin-top: 13px; // So the 'Move' button is visible for the last suggestion but clipped off for the last suggestion
            }
        }
    }

    &__noPadding.SuggestedClientContact__noPadding {
        padding: 0;
    }
}