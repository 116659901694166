@import "~StyleVariables";

.DesiredOutcomeRoleSelect {
	text-align: center;

	.Headline {
		font-size: 28px;
		white-space: pre-wrap;
	}

	.Text {
		font-size: 18px;
		margin-top: 25px;
	}

	// The elevio link
	span {
		font-size: 18px;
		border-bottom: none;
		color: $bright-blue;
		transition: none;
	}
}

.DesiredOutcomeRoleSelect__grid {
	display: grid;
	align-items: start;
	width: 100%;
	grid-auto-rows: 195px;
	grid-row-gap: 25px;
	grid-column-gap: 25px;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: 50px;

	@media (min-width: 1281px) {
		grid-template-columns: repeat(5, 220px);
	}

	@media (min-width: 1025px) and (max-width: 1280px) {
		grid-template-columns: repeat(4, 220px);
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		grid-template-columns: repeat(3, 220px);
	}

	@media (min-width: 481px) and (max-width: 767px) {
		grid-template-columns: repeat(2, 220px);
	}

	@media (min-width: 320px) and (max-width: 480px) {
		grid-template-columns: repeat(1, 220px);
	}
}

.DesiredOutcomeRoleSelect__select {
	display: flex;
	justify-content: center;

	.Button {
		min-width: 430px;
	}
}
