@import "~StyleVariables";

.ManageAccount {
	background-color: $super-duper-light-green;
	position: fixed;
	top: 0;
	z-index: 6000;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: scroll;

	a.Link {
		font-weight: lighter;
	}
}

.ManageAccount__Backfill {
	height: 80%;
	position: absolute;
	z-index: 50;
	width: 100%;
	background: $super-duper-light-green;
	transform: skew(0deg, -2deg);
	margin-top: -50px;
}

.ManageAccount__Card__Backfill {
	background: $grey-1;
	height: 71%;
	transform: skew(0deg, -6deg);
	margin-top: -5%;
	z-index: 20;
	position: absolute;
	width: 100%;
}

.ManageAccount__wrapper {
	max-width: 1000px;
	margin: 0 auto;
	display: block;
	position: relative;
	z-index: 55;
}
.ManageAccount__plans {
	padding-top: 20px;

	.Column + .Column {
		padding-left: 8px;
	}

	.Card {
		transition: all 0.2s;
		height: 590px;
		border-radius: 2px;
		background-color: $grey-1;
		overflow: hidden;
		border: 1px solid rgba(175, 182, 192, 0);

		.CardHeader,
		.CardContent {
			position: relative;
			z-index: 20;
		}

		.CardContent {
			height: calc(100% - 66px);
		}

		.ManageAccount__content__perks {
			text-align: center;
		}

		.ManageAccount__plan {
			height: 200px;
		}

		.ManageAccount__plan__subTitle {
			font-size: 16px;
			font-weight: $boldFontWeight;
			padding-bottom: 10px;
		}

		.ManageAccount__plan__video {
			.Video {
				border-radius: 4px;
				overflow: hidden;
				box-shadow: 0 8px 16px 0 rgba($black, 0.1);
				margin-bottom: 5px;
			}

			img {
				height: 100px;
			}
		}

		.ManageAccount__plan__video_desc {
			line-height: 22px;
			height: 22px;
		}

		.ManageAccount--Choose {
			position: absolute;
			bottom: 20px;
			left: 20px;
			right: 20px;
			width: calc(100% - 40px);
		}

		.ManageAccount__content__price {
			padding-top: 10px;

			.Text.Text--bold {
				font-size: 16px;
				font-weight: $boldFontWeight;
			}
		}
	}

	.Card:hover {
		box-shadow: 0 11px 24px 0 rgba(30, 37, 46, 0.3);

		.ManageAccount__Card__Backfill {
			opacity: 0;
			z-index: -1;
		}

		.CardHeader__title {
			color: $white;
		}

		.Text {
			color: $white;
		}

		.ManageAccount__plan__description {
			color: rgba($white, 0.7);
		}

		.ManageAccount--color-dark-gray {
			color: rgba($white, 0.7);
		}

		.ManageAccount__plan__video {
			.Text {
				color: rgba($white, 0.7);
			}
		}

		.up-btn.btn-bright-blue.btn-lined {
			background-color: $bright-green;
			color: $white;
		}
	}

	.CardHeader__title {
		font-weight: $boldFontWeight;
		font-size: 28px;
	}

	.ManageAccount__plan__description {
		height: 200px;
		color: $grey-11;
		text-align: left;
	}

	.ManageAccount--color-dark-gray {
		color: $grey-10;
	}

	.ManageAccount__plan__video {
		img {
			width: 100%;
		}

		.Text {
			font-size: 12px;
			color: $grey-10;
			text-align: center;
		}
	}

	.ManageAccount__plan__includes {
		padding-top: 35px;
		padding-bottom: 10px;
	}

	.ManageAccount--hide {
		display: none;
	}
}

.ManageAccount__content {
	padding: 30px 50px;

	.Logo {
		width: 160px;
		padding-bottom: 25px;
	}

	.ManageAccount__description {
		.Text {
			padding-top: 15px;
			color: $grey-11;
		}

		.Headline {
			padding-top: 15px;
		}
	}

	.ManageAccount__admins {
		.Button {
			width: 200px;
		}
	}

	.Card {
		background-color: $white;
		border: 1px solid $grey-6;
		border-radius: 4px;
		box-shadow: none;
	}

	.ManageAccount__description__avatar.CardContent {
		text-align: center;
	}
}

.ManageAccount__administrators {
	padding-top: $padding / 4;
	gap: $space * 2;
}

.ManageAccount__description__avatar {
	max-width: 217px;
	margin-left: auto;
	text-align: center;

	.Button {
		margin-top: 20px;
		background-color: $super-light-green !important;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
	}
}

.Text.ManageAccount__content__account-manager {
	padding-bottom: 15px;
}

.ManageAccount__footer {
	width: 100%;
	padding: 10px 50px;
	text-align: center;
	padding-bottom: 20px;
}
