@import "~StyleVariables";

.ProductBundleDrawer {
	--header-height: 50px;

	width: 900px;
	height: 100%;
	visibility: visible;

	&__wrapper {
		height: 100%;
		position: relative;
		background-color: $white;
	}

	&__save-btn,
	&__cancel-btn {
		height: 100%;
		width: 100px;
		border-radius: 0;
	}

	&__content {
		height: calc(100% - var(--header-height));
		overflow-y: scroll;
		overflow-x: hidden;
		padding: 40px;

		.Row {
			justify-content: space-between;
		}

		.SelectMultiInput {
			min-height: 34px;
		}

		.ProductBundleDrawer__price-adjustment-type {
			width: 100%;
		}
	}

	&__settings {
		& > div {
			margin-bottom: 20px;
		}
	}

	&__type-select {
		.Headline {
			text-align: center;
		}

		.ButtonBox {
			width: 300px;
		}

		button {
			width: fit-content;
		}
	}

	&__type-select > div {
		margin-bottom: 20px;
	}

	&__type-select > &__confirm-btn {
		margin-top: 20px;
	}

	&__price-adjustment-input {
		position: relative;
		.Input {
			width: 112px;
			.Input__input {
				text-align: right;
				padding-right: 20px;
			}
		}

		.ProductBundleDrawer__percent {
			position: absolute;
			top: 30px;
			left: 94px;
		}
	}

	&__products {
		margin-top: 28px;

		.ProductBundleDrawer__product-table-label {
			display: flex;
			gap: $space;
		}

		.Button {
			text-decoration: none;
		}

		.Table {
			border: 1px solid $grey-2;
			border-radius: $border-radius;

			.TableColumn {
				width: 300px;
			}

			.TableColumn:last-of-type {
				width: 100px;
			}

			.select2-container {
				width: 300px;
			}

			.NumberInput {
				width: 100px;
			}
		}
		&__product-select {
			width: 300px !important;
		}
	}

	&__summary {
		width: 285px;

		.Row {
			margin-top: 4px;
			margin-bottom: 4px;
		}
	}

	&__summary-details {
		border-bottom: 2px solid $grey-6;
	}

	.AssistChip {
		width: fit-content;
		margin-top: $space * 5;

		.Row {
			column-gap: 4px;

			.Icon {
				font-size: 10px;
			}
		}
	}

	&__underlined-text {
		text-decoration: underline;
	}

	&__change-type-link {
		align-items: center;

		&:hover {
			cursor: pointer;
		}
	}

	.Progress {
		height: 16px;
		width: 16px;
	}

	.Label {
		font-weight: $boldFontWeight;

		&--has-progress {
			width: 100%;
		}
	}

	&__actions {
		margin-top: 10px;
		.Button {
			text-decoration: none;
			padding: unset;
		}
	}

	&__max-discount {
		width: 28%;
		position: relative;
		.Input {
			.Input__input {
				text-align: right;
				padding-right: 20px;
			}
		}

		.ProductBundleDrawer__percent {
			position: absolute;
			top: 30px;
			left: 94px;
		}
	}

	&__customfields {
		margin-top: 20px;

		.EditProductCustom {
			background-color: $white;
			padding: unset;

			.Title {
				font-size: 22px;
			}
		}
	}

	&__price-settings {
		.Input {
			width: 245px;
		}

		.Select {
			width: 245px;
		}

		.ProductBundleDrawer__price-type {
			.Tooltip {
				margin-left: $space * 2;
			}
		}

		.ProductBundleDrawer__price-adjustment-input {
			position: relative;
			margin-left: $space * 10;
			.Input {
				width: 88px;
				.Input__input {
					padding-left: 24px;
					text-align: left;
				}
			}

			.ProductBundleDrawer__percent {
				position: absolute;
				top: 8px;
				left: 8px;
			}
		}

		.RadioListItem,
		.ButtonSelect {
			width: fit-content;
		}

		.RadioListItem {
			margin: 0;
			.Text {
				margin-left: $space * 2;
			}
		}

		.ProductBundleDrawer__currencies {
			display: none;
		}

		.ProductBundleDrawer__currencies--show {
			display: flex;
		}

		.ProductBundleDrawer__currencies-toggle {
			&:hover {
				cursor: pointer;
			}
		}
	}
}
