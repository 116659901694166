@import "./variables";

.up-modal {
	&.default {
		.appointment-modal {
			.form-control {
				border-color: $grey-6;
			}

			.drag-overlay {
				position: absolute;
				background-color: $grey-1;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 1000000;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				color: $black;
				font-size: 18px;
				line-height: 24px;

				img {
					margin-bottom: 43px;
					width: 136px;
				}
			}

			button {
				i.fa {
					margin-right: 5px;
				}
			}

			.up-modal-header {
				padding: 0 10px;
				position: relative;
				width: 100%;
			}
			.up-modal-content {
				position: relative;
				top: 0 !important;
			}
			.up-modal-controls {
				position: relative;
			}

			.up-modal-controls {
				button {
					i.fa {
						margin-right: 5px;

						&.fa-fw {
							margin: 0;
						}
					}

					&.btn-white-grey {
						color: $grey-10;
					}
				}
			}

			.relate-to {
				z-index: 10000;
				position: absolute;
				top: 45px;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.flex-container {
				height: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-content: stretch;
				align-items: stretch;
				position: sticky;
				top: 0;
				align-self: flex-start;
				flex: 1;
			}

			.required-field-notes {
				position: absolute;
				top: 10px;
				right: 10px;
			}

			.AppointmentTabs {
				scroll-padding-bottom: $space * 2;
				order: 0;
				flex: 3 1 auto;
				align-self: auto;
				background-color: $grey-1;
				overflow: hidden;
				overflow-y: auto;
				position: relative;

				&__TabsContainer {
					background-color: $grey-2;
					height: 50px;

					.Tab {
						height: auto;
						background-color: $grey-2;

						&--selected {
							background-color: $grey-1;
						}
					}
				}

				&__TabsContent {
					display: none;

					&--active {
						display: block;
					}
				}

				.Tabs__selected-slider {
					top: 0;
				}
			}

			.appointment-fields {
				order: 0;
				flex: 3 1 auto;
				align-self: auto;
				min-height: 800px;
				padding: 20px;

				.calendar-integration-settings {
					border-radius: 0px 0px $border-radius $border-radius;
					border: solid $grey-6 1px;
				}

				.TextEditor {
					box-shadow: 0 1px 2px 0 rgba($black, 0.2);
					border-radius: $border-radius;
					border: solid $grey-6 1px;
					overflow: hidden;
				}

				> .Card {
					margin-bottom: 20px;
				}

				*::placeholder,
				.Select-placeholder {
					font-style: italic;
					color: $grey-8;
				}

				.description-field {
					.Input__input {
						box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
					}
				}

				.Select-placeholder {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-right: 25px;
				}

				.Select.is-disabled .Select-control {
					background-color: #e4eaf0;
				}

				.Select.is-disabled:not(.Select--multi) .Select-control {
					.Select-value-label,
					.Select-placeholder {
						color: #6b7c93;
						font-style: italic;
						font-size: 14px;
					}
				}

				.Select.is-disabled.Select--multi .Select-value-icon {
					pointer-events: none;
				}

				#participant-legend {
					font-size: 10px;
					color: $bright-blue;
					margin: 3px 0 0 3px;
					@include opacity(0);
					@include transition(all ease 200ms);
					font-size: 12px;
					line-height: 24px;
					display: flex;

					&.is-visible {
						@include opacity(1);
					}

					.user {
						color: $green;
					}

					span {
						margin-right: 10px;
					}

					.fa {
						font-size: 8px;
						margin-right: 4px;
						display: flex;
						align-items: center;
					}

					.other-companies {
						margin-left: 10px;
					}
				}

				.select-button-group {
					top: 0px;
					padding: 10px;
					margin-bottom: 5px;
					position: sticky;
					background-color: #fff;
					border-left: 1px solid #ccc;
					border-right: 1px solid #ccc;
					width: calc(100% + 2px);
					left: -1px;
				}

				.select-participant-list {
					padding: 0;
					.Expandable {
						-webkit-box-shadow: none;
						box-shadow: none;
						padding: 2 * $space;
						&--expanded {
							background-color: $grey-1;
							border-top: 1px solid $grey-4;
							border-bottom: 1px solid $grey-4;
						}

						&__contactIcon {
							width: 20px;
							text-align: center;
						}

						&__header {
							height: auto;
							padding-left: $space;
							padding-right: $space;
						}
						&__expandIcon {
							position: absolute;
							top: 12px;
							right: 8px;
						}
						&--user,
						&--emailAttendee {
							.Expandable__expandIcon {
								display: none;
							}
							:hover {
								cursor: default;
							}
						}
						&--emailAttendee {
							min-height: 40px;
							display: flex;
						}

						.Avatar {
							height: 30px;
							width: 30px;
							font-size: 30px;
							&--gravatar {
								height: 30px;
								width: 30px;
							}
							&--initials {
								line-height: 30px;
							}
						}
					}
					.generic-avatar {
						margin-right: 0;
					}
				}

				.select-participant-list-expanded {
					list-style: none;
					padding: 0px;
					margin-top: $space * 2;
					margin-bottom: 0px;
					li:not(:first-of-type) {
						margin-top: $space;
					}
				}

				.flex-row {
					display: flex;
					flex-wrap: wrap;
					margin-left: -10px;
					margin-right: -10px;

					& + .flex-row {
						margin-top: 10px;
					}

					.col {
						flex-basis: 0;
						flex-grow: 1;
						max-width: 100%;
						padding: 0 10px;
					}

					label {
						font-weight: $regularFontWeight;
						font-size: 14px;
						line-height: 1;
						margin: 0 0 5px 0;
						color: $grey-13;
						display: block;

						.btn {
							&.label-button {
								padding: 0;
								font-size: 11px;
								float: right;
							}
						}
					}
				}

				.row {
					label {
						margin: 15px 0 5px;
						font-size: 14px;
						font-weight: $regularFontWeight;
						display: block;
						line-height: 1;
						color: $grey-13;

						.label-button {
							padding: 0;
							font-size: 11px;
							float: right;
						}
					}

					.col-md-6 {
						min-height: 68px;
					}
				}

				.custom-fields-row {
					.has-error {
						input,
						textarea {
							background-color: $super-light-red;
							border-color: $dark-red;
							color: $red;
						}
					}
				}
			}

			.appointment-content {
				display: flex;
			}

			.appointment-sidebar {
				flex: 1;
				overflow: inherit;
				background-color: $white;
			}

			.appointment-notes {
				order: 0;
				flex: 0 1 auto;
				align-self: auto;
				min-height: 100%;
				background-color: $white;
			}

			.appointment-notes {
				padding: 12px 12px 12px 4px;
				background-color: $grey-1;
				display: flex;
				flex-direction: column;
			}
			.EditPhonecallNotes {
				flex: 1;
			}
			.QuickCreateTodoCard {
				.Input__input {
					padding-right: 0;
				}
				.OpportunityEditTodoRow__date-col {
					margin-left: 4px;
				}
			}

			.AppointmentTodos,
			.EditPhonecallNotes {
				width: 100%;
			}
			.appointment-sidebar {
				position: relative;
				border-right: 1px solid $grey-6;
				box-shadow: $box-shadow-lg;
				top: 0;
				min-width: $modal-sidebar-width - 1px;
				max-width: $modal-sidebar-width - 1px;

				.section {
					margin: 0 -10px 40px;
					padding: 5px 10px 0;
					border-bottom: 1px solid $grey-4;

					&.company-section {
						padding-bottom: 10px;
					}

					&.edge-to-edge {
						background-color: $grey-1;
					}

					label {
						display: block;
						line-height: 30px;
						height: 30px;
					}

					.placeholder {
						color: $grey-8;
						font-size: 13px;
						line-height: 20px;
					}

					.host-user {
						margin: 0 -10px;
						padding: 10px;
					}

					.participant-emailAttendees {
						height: 52px;
					}

					.participant-users {
						margin: 0 -10px;
						padding: 10px;
						padding-left: $space * 4;
						border-bottom: 1px solid $grey-4;
						cursor: pointer;
						min-height: 58px;

						/** 
							* @todo  @upsales/components 2.15.6
							* STYLING: Had to add my own height and width to Avatar since the current sizes did not support my use case.
							* I could not make the size property work with other values than the predefined strings. Since the gravatar
							* would remain there making it look weird.
							* {@link https://github.com/upsales/ui-components/issues/525}
						*/
						.Avatar--gravatar {
							height: 30px;
							width: 30px;
						}

						&.warning {
							margin-top: -1px;
							position: relative;
							margin-bottom: -1px;
							background-color: $super-light-orange;
							border-top: 1px solid $super-light-orange;
							border-bottom: 1px solid $super-light-orange;

							.participant-icon {
								top: 50%;
								right: 15px;
								margin-top: -6px;
								position: absolute;
								color: $orange;
							}
						}

						&.no-conflict {
							margin-top: -1px;
							position: relative;
							margin-bottom: -1px;
							border-top: 1px solid $grey-4;
							border-bottom: 1px solid $grey-4;

							.participant-icon {
								cursor: pointer;
								top: 50%;
								right: 15px;
								margin-top: -6px;
								position: absolute;
								display: none;
							}
							&:hover {
								background-color: $grey-1;
								.Icon {
									display: block;
								}
							}
						}

						&:last-child {
							border-bottom: none;
						}
					}

					.participants {
						border-bottom: 1px solid $grey-4;
						margin: 0 -10px;
						padding: $space $space * 2;
						line-height: 13px;

						.fa.fa-angle-down {
							transition-delay: 200ms;
							transition: all 0.3s;
						}

						&.participants-open {
							background: $grey-1;

							.fa.fa-angle-down {
								transform: rotate(180deg);
							}

							ul.contact-more-info {
								padding: 0;
								list-style: none;
								margin: 5px 0 0;

								li {
									margin: 0 0 10px 0;

									&:last-child {
										margin: 10px 0 0;
									}

									.inline-edit {
										width: calc(100% - 26px);
										display: inline-block;

										.form-control {
											padding: 2px 5px;
											height: auto;
										}
									}
								}

								i {
									margin-right: 10px;
									width: 16px;
									text-align: center;
									color: $grey-10;

									&.fa-mobile {
										font-size: 1.6666em;
										line-height: 16px;
										vertical-align: 0;
									}
								}
							}
						}

						&:first-child {
							border-top: 1px solid $grey-4;
						}

						.parti-holder {
							display: inline-block;
							flex: 1;
						}
						.parti-arrow {
							width: 10%;
							display: inline-block;
							text-align: center;
							vertical-align: middle;
							line-height: 31px;
						}

						.participant-name {
							display: flex;
							align-items: center;
							gap: $space;
							font-size: 14px;
							line-height: 17px;
							color: $grey-13;
							padding: $space * 2;

							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							width: 170px;

							a {
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								width: 170px;
							}
						}

						.participant-title {
							font-size: 12px;
							line-height: 14px;
							color: $grey-11;
							width: 165px;

							&.grey {
								color: $grey-8;
								font-style: italic;
							}
						}
					}

					&.participants-section,
					&.host-section {
						label {
							margin: 0 -10px;
							padding: 0 10px 5px;
							border-bottom: 1px solid $grey-4;
							height: auto;
						}

						.account-manager {
							line-height: 25px;

							.generic-avatar {
								margin-right: 10px;
								float: none;
								display: inline-block;
								vertical-align: middle;
							}

							.account-manager-name {
								font-size: 14px;
								display: inline-block;
								vertical-align: middle;

								.host {
									font-size: 12px;
									display: block;
									line-height: 12px;
								}
							}

							/** 
								* @todo  @upsales/components 2.15.6
								* STYLING: Had to add my own height and width to Avatar since the current sizes did not support my use case.
								* As I changed the sizes for custom ones and reduced the font-size I had to also change the line-height.
								* {@link https://github.com/upsales/ui-components/issues/525}
							*/
							.Avatar--initials {
								line-height: 30px;
								color: black;
								border-radius: 50%;
								background-color: $grey-4;
							}

							/** 
								* @todo  @upsales/components 2.15.6
								* STYLING: Had to change the background color in the initials element. 
								* But we don't have a way of changing it based on the main class of avatar. So I had to do it like this
								* {@link https://github.com/upsales/ui-components/issues/526}
							*/
							.Avatar--status--confirmed ~ .Avatar--initials {
								background-color: $super-light-green;
							}

							.Avatar--status--declined ~ .Avatar--initials {
								background-color: $super-light-red;
							}
						}
					}

					&.dynamiclinks-section {
						ul {
							list-style: none;
							padding: 0;

							li {
								a {
									.icon {
										margin-right: 5px;
									}
								}
							}
						}
					}
				}
			}
		}

		.fullmodal-container {
			.fullscreen-content {
				display: flex;
				flex-direction: column;
				// flex-wrap: wrap; // is this not needed?
				justify-content: flex-start;
				align-content: stretch;
				align-items: stretch;
			}
		}
	}
}

.TextInput {
	padding: 0;
	position: relative;

	&.has-error {
		.Select {
			.Select-control {
				background-color: $super-light-red;
				border-color: $dark-red;
				color: $red;

				.Select-placeholder {
					color: $red;
				}

				.Select-arrow-zone {
					border-color: $dark-red;
				}
			}
		}
	}

	.Input {
		width: 100%;
		border-radius: 4px;
	}

	&.select2-inside {
		border-right: none;
		height: auto;
	}

	&.height-fix {
		.icon-holder {
			height: 34px;
		}

		.Input__input {
			border-radius: 0 4px 4px 0;
		}
	}

	&.has-error {
		border-color: $red !important;
	}

	.icon-holder {
		float: left;
		width: 34px;
		background: $grey-2;
		height: 34px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		align-items: center;
		text-align: center;
		border-radius: 3px 0 0 3px;
		border-right: 1px solid $grey-6;

		.Icon {
			flex: 1 1 auto;
			align-items: center;
			justify-content: center;
		}
	}

	.icon-holder--error {
		color: $red;
	}

	> input {
		width: calc(100% - 32px);
		border-radius: 3px;
		height: 34px;
		border: 1px solid $grey-6;
		outline: none;
		padding: 6px 8px;
		position: absolute;
		right: -1px;

		&:focus,
		&:active {
			border: 1px solid $bright-blue;
		}
	}

	&.no-border-right:not(.no-icon) {
		.Select-control {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}

	&.no-border-right {
		.icon-holder {
			border-right: none;
		}
	}

	&.no-border {
		border: none;
	}

	.Select--multi,
	.Select--single {
		width: 100%;
		font-size: 13px;
	}

	.Select-control {
		border-color: $grey-6;
	}
	.Select.is-focused:not(.is-open) > .Select-control {
		border-color: $bright-blue;
		box-shadow: none;
	}

	.select2-container,
	.Select-control {
		margin-top: -1px;
		margin-bottom: -1px;
		width: calc(100% - 33px);

		&.select2-container-multi {
			.select2-choices {
				min-height: auto;

				.select2-search-choice {
					.upcontacts-is-user & & {
						background-color: $red;
					}
				}
			}
		}
	}

	.Select-control {
		width: calc(100% + 1px);
		height: 34px;

		.Select-value {
			max-height: 26px;
		}

		.Select-input {
			height: 34px;
		}
	}

	.Select-menu-outer {
		max-height: 305px;

		.Select-menu {
			max-height: 300px;
		}

		.is-focused,
		.is-selected {
			background-color: $bright-blue;
			color: $white;
		}

		#dummy-down-pushing-div {
			height: 55px;
		}
	}

	&.participants-specific {
		.Select-menu-outer {
			margin-top: 50px;
		}
	}

	&.no-icon {
		.select2-container,
		.Select-control {
			left: -1px;
			width: 101%; // is this valid hack?
			margin-right: -2px;
		}
	}

	.Select--multi .Select-value {
		margin-left: 4px;
		margin-top: 4px;
	}
}

.ellipsis-6 {
	.Select-value {
		width: calc(100% - 24px);

		.Select-value-label {
			width: 160px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}

.TextInput {
	border-right: none;
	height: auto;
	flex-direction: row;
	align-items: center;
	background: $grey-2;
	display: flex;
	border-radius: 3px;
	border-right: 1px solid $grey-6;
}

#calendar-component {
	padding: 10px 0 0;

	.CalendarHeader {
		padding: 5px 15px;
		text-align: center;
		height: 34px;

		.circle {
			border: 1px solid $grey-8;
			display: inline-block;
			width: 20px;
			height: 20px;
			line-height: 18px;
			text-align: center;
			border-radius: 50%;
			transition: all ease-out 200ms;

			&:hover {
				background-color: $grey-4;
				color: $grey-8;
			}

			&.left {
				float: left;

				.fa {
					margin-right: 2px;
				}
			}

			&.right {
				float: right;

				.fa {
					margin-left: 1px;
				}
			}
		}

		.month-name {
			font-size: 16px;
			font-weight: $boldFontWeight;
			line-height: 22px;
		}

		i.fa-chevron-left {
			float: left;
		}

		i.fa-chevron-right {
			float: right;
		}

		&:after {
			clear: both;
			content: "";
			display: block;
		}
	}

	.calendar-headers {
		td {
			text-align: center;
			font-size: 10px;
			font-weight: $boldFontWeight;
			color: $grey-10;
			width: 40px;
		}
	}

	td {
		&.day {
			width: 40px;
			height: 40px;
			vertical-align: middle;
			text-align: center;
			color: $grey-8;
			border-top: 1px solid $grey-5;
			border-right: 1px solid $grey-5;

			&.selected-month {
				color: $grey-13;
			}

			.fa-circle {
				color: transparent;
				display: block;
				font-size: 7px;
				line-height: 7px;
				margin-top: -2px;
				color: $bright-blue;
				opacity: 0;
			}

			&.week-number {
				color: $grey-10;
				font-size: 10px;
				font-weight: $boldFontWeight;
			}

			&:hover {
				background-color: $super-light-blue;
				cursor: pointer;

				&.today {
					background-color: $bright-blue;
					border-color: $bright-blue;
					color: $white;
				}

				&.week-number {
					background-color: transparent;
					color: $grey-10;
					cursor: inherit;
				}
			}
		}

		&.day.passed {
			background-color: $grey-4;
			color: $grey-9;

			&:hover {
				background-color: $grey-5;
			}

			&.selected-month {
				background-color: $grey-2;
				color: $grey-10;

				&:hover {
					background-color: $grey-4;
				}
			}

			&.selected-day {
				background-color: $bright-blue;
				color: $white;

				&:hover {
					background-color: $bright-blue;
				}

				.fa-circle {
					color: $white;
				}
			}
		}

		&.day.today {
			box-shadow: inset 0 0 0 1px $bright-blue;
			border-top: none;
			color: $grey-13;

			&.selected-month {
				color: $grey-10;
			}

			&.selected-day {
				color: $white;
			}

			&:hover {
				.fa-circle {
					color: $white;
				}
			}
		}

		&.day.selected-day {
			background-color: $bright-blue;
			box-shadow: inset 0 0 0 1px $bright-blue;
			border-top: none;
			color: $white;

			&:hover {
				background-color: $bright-blue;
				color: $white;
			}

			.fa-circle {
				color: $white;
			}
		}
	}
}

.time-open {
	position: absolute;
	padding-top: 32px;
	width: calc(100% + 2px);
	right: -1px;
	border: 1px solid $bright-blue;
	border-radius: 0 3px 3px 3px;
	top: -1px;
	z-index: 50;
	background: $white;

	&.got-error {
		border-color: $red;
	}

	i.fa {
		display: inline-block;
		margin-left: 16px;
		margin-top: 10px;
	}

	input[type="text"] {
		display: inline-block;
		width: 56%;
		border: none;
		line-height: 32px;
		margin-left: 4px;

		&:focus {
			outline: none;
		}
	}
}

.NotesBlock--error {
	box-shadow: 0 0 5px rgba($red, 0.5) inset;
}

#company-block {
	.company-name {
		font-size: 18px;
		font-weight: $boldFontWeight;
		cursor: pointer;
	}

	#company-history {
		line-height: normal;
		margin: 0 0 10px 0;
		display: inline-block;
	}

	#company-rating {
		display: inline-block;
	}

	.company-util {
		display: flex;
		i {
			margin-right: 10px;
			width: 16px;
			text-align: center;
			color: $grey-10;
			line-height: 24px;

			&.bright-blue {
				color: $bright-blue;
			}
		}
		a {
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.italic {
			font-style: italic;
		}
	}

	.placeholder-text {
		font-size: 13px;
		text-align: center;
		font-style: italic;
		padding: 10px 20px 20px;
		width: 100%;
		display: block;
		line-height: 20px;
		color: $grey-8;
	}
}

#appointment-modal {
	height: 100%;
	display: flex;
	flex-direction: column;
	.up-availability-table {
		&.visible {
			bottom: 0;
		}

		.availability-header {
			.left {
				padding: 0 15px;
				top: 0;

				h2 {
					line-height: 43px;
					height: 100%;
				}

				button {
					position: absolute;
					right: 0;
					top: 0;
					padding: 0 15px;
					line-height: 1;
					height: 100%;
				}
			}
		}
	}
}
