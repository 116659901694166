@import "~StyleVariables";

.AdminTicketTypes {
	&__table-row--editing {
		background-color: $grey-1;
		height: 60px;
	}

	&__table-row {
		.AdminTicketTypes__name-column {
			width: 375px;

			.Tooltip {
				width: inherit;
				
				.Text {
					width: inherit;
				}
			}
		}
	}

	&__input-column {
		.Input {
			flex-grow: 1;
			flex-shrink: 1;
		}
		.Progress {
			margin-left: $space;
			align-self: center;
			height: 20px;
			width: 20px;
		}
	}

	&__merge-content {
		.Select {
			min-width: 240px;
			max-width: 240px;
		}

		.Row {
			column-gap: $space * 2;
			margin-top: $space * 4;
		}

		.AdminTicketTypes__merge-name-input {
			width: 480px + 2 * $space;
		}
	}
}
