@import "~StyleVariables";

.ListToggle {
	display: flex;
	flex-direction: column;
	background-color: $white;

	border-radius: $space;
	border: 1px solid $grey-4;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 4 * $space;
		width: 100%;

		.Toggle {
			margin-right: 4 * $space;
			min-width: 31px;
		}
	}

	&__search-input {
		position: inherit;

		.Icon-search {
			position: absolute;
			top: 10px;
			margin-left: 2 * $space;
			z-index: 1;
		}

		.Input__input {
			min-width: 340px;
			box-shadow: none;
			padding-left: 8 * $space;
		}
	}

	&__toggle-wrapper {
		display: flex;
		align-items: center;
		
		.LockIcon {
			font-size: 4 * $space;
		}
	}

	&__title-wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}