@import "~StyleVariables";

.MarketShareTable {
    width: 100%;
    background-color: white;
    border: 1px solid $grey-6;
    border-radius: $space * 2;

    .DistributionBar {
        margin-bottom: $padding;
    }

    &__header {
        background-color: $grey-1;
        padding: $padding 0;
    }

    &__header-column:first-child {
        width: 40%;
        .Text {
			max-width: 260px;
		}
    }

    &__header-column:not(:first-child) {
        width: 20%;
    }

    &__row {
        background-color: $white;
        
        .TableColumn {
            padding: $padding;
        }
    }

    &__client-col {
        max-width: 300px;
    }
}
