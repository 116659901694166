@import "../../../styles/_variables";

.InactiveLabel {
	background-color: $grey-4;
	border: 1px solid rgba(5, 42, 79, 0.2);
	border-radius: 2px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	padding: 0 5px;
	display: inline-block;
	text-transform: uppercase;
	cursor: pointer;

	.Icon {
		margin-right: 2px;
	}
}
