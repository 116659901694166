#select-creative-template.card {
	background-color: $grey-1;
	border-radius: $border-radius;
	margin-bottom: 10px;
	float: left;

	#card-main-view {
		position: absolute;
		bottom: 0;
		top: 0;
		right: 0;
		overflow: hidden;
		overflow-y: auto;

		&.has-footer {
			bottom: $card-footer-height;
		}

		.card-content {
			background-color: $grey-1;
			padding: 15px;
			padding-top: 40px;
			max-width: 1200px;
			margin: 0 auto;
			text-align: center;

			& > h2,
			& > p {
				color: #7a7a7a;
			}

			& > p {
				margin: 20px auto 40px auto;
				max-width: 600px;
				color: #7a7a7a;
			}

			.frame-wrap {
				margin-bottom: 15px;
				width: 300px;
				height: 250px;
				max-height: 250px;

				iframe {
					border: none;
				}

				.overlay button {
					margin-top: 30px;
				}
			}

			.upload-package {
				display: inline-block;
				color: $bright-blue;
				text-align: center;
				vertical-align: top;
				font-size: 16px;
				padding-top: 60px;
				cursor: pointer;

				&:hover {
					color: darken($bright-blue, 10);
				}

				.fa {
					display: block;
					font-size: 70px;
					margin-bottom: 10px;
				}
			}
		}
	}
}
