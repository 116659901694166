@import "~StyleVariables";

.Folder {
	display: flex;
	gap: 8px;
	flex-direction: column;
	padding: 16px 10px;
	background-color: $white;
	border-radius: 4px;
	margin: 10px;
	box-shadow: 2px 2px 4px 0px $grey-8;
	width: 200px;
	cursor: pointer;
	position: relative;
	visibility: visible;

	&:hover {
		background-color: $grey-3;
	}
	.Text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.Icon {
			margin-right: 8px;
		}
	}

	&--isOver {
		box-shadow: 0 0 0 2px $red;
	}

	&--isDragging {
		visibility: hidden;
	}

	&--isInEditMode {
		animation: scale 0.4s ease;
		&:hover {
			background-color: $white;
			cursor: default;
		}
	}

	&__trash {
		animation: show 0.4s ease;
		transition: all 0.25s ease;
		position: absolute;
		right: -8px;
		top: -6px;
		color: $red;
		font-size: 16px;
		cursor: pointer;
		&:hover {
			scale: 1.12;
		}
	}
}

@keyframes scale {
	0% {
		scale: 1;
		transform: rotate(0deg);
	}
	60% {
		transform: rotate(1deg);
		scale: 1.03;
	}
	100% {
		transform: rotate(0deg);
		scale: 1;
	}
}

@keyframes show {
	0% {
		scale: 0;
		transform: rotate(0deg);
	}
	30% {
		transform: rotate(-4deg);
	}
	60% {
		scale: 1.1;
		transform: rotate(4deg);
	}
	100% {
		transform: rotate(0deg);
		scale: 1;
	}
}
