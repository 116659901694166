@import "~StyleVariables";

.MailBounceLabel {
	display: inline-flex;
	border-radius: $border-radius;
	transition: background-color 200ms ease-in-out;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
	padding: 2px 6px;
}

.MailBounceLabel .Text--sm {
	box-shadow: none;
}

.MailBounceLabel--hard_bounce {
	background-color: $super-light-red;
}

.MailBounceLabel--hard_bounce:hover {
	background-color: darken($super-light-red, 5%);
}

.MailBounceLabel--soft_bounce {
	background-color: $super-light-yellow;
}

.MailBounceLabel--soft_bounce:hover {
	background-color: darken($super-light-yellow, 5%);
}
