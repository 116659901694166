@import "~StyleVariables";

.InlineInput {
	display: flex;
	padding-bottom: 3px;
	padding-top: 3px;
	padding-right: 10px;
	border-radius: $border-radius;
	align-items: center;
	height: 25px;
	position: relative;

	&--default-state {
		width: 100%;

		&:before {
			transition: all ease-out 300ms;
			z-index: -1;
			content: "";
			background-color: $grey-2;
			border-radius: $border-radius;
			position: absolute;
			left: -4px;
			right: -4px;
			top: -1px;
			bottom: -1px;
			visibility: hidden;
			opacity: 0;
		}

		&:hover:before {
			visibility: visible;
			opacity: 1;
		}
		> .Icon {
			text-align: center;
			width: 18px;
		}
	}

	.Text {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&__input-field {
		width: 100%;
		.Input__input {
			color: $bright-blue;
			background-color: $white;
			border-bottom: 1px solid $bright-blue;
			height: 25px;
		}

		&--invalid-input {
			.Input__input {
				background-color: $super-light-red;
			}
		}
	}

	&:hover .InlineInput__tools {
		visibility: visible;
		opacity: 1;
	}

	&__tools {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		margin-left: auto;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 300ms;
		background-color: $grey-2;
		align-items: center;

		&:before {
			content: "";
			position: absolute;
			width: 10px;
			top: 0;
			bottom: 0;
			left: -10px;
			background: linear-gradient(90deg, transparent, $grey-2);
		}

		.Tooltip {
			cursor: pointer;
			color: $grey-10;
			margin-left: 10px;
		}
	}
}
