@import "~StyleVariables";

.FinancialCard {
	max-width: 980px;
	margin: 0 auto;
	&__zebraStripes {
		background-color: $grey-1;
	}
	&__keyFiguresContainer {
		margin: 30px;
	}
	&__tableColumnTitle {
		padding-left: 23px;
		font-weight: $boldFontWeight;
	}
	&__fadeCard {
		opacity: 0.35;
		background-image: linear-gradient(180deg, rgba(246, 249, 251, 0) 0%, $grey-1 100%);
	}
	&__keyFiguresContainerLowZ {
		z-index: -1;
		margin-top: 20px;
	}
	&__proRequiredButton {
		vertical-align: center;
		z-index: 3;
		transform: translateX(-25%);
		position: absolute;
		margin-top: 133px;
	}
}
