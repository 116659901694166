@import "~StyleVariables";

.TemplateCardPreview {
	position: relative;
	background-color: $grey-1;
	overflow: hidden;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	transition: all ease-out 200ms;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: $box-shadow-md;

	.Icon,
	.Text {
		text-align: center;
		z-index: 1000;
	}

	.Icon {
		margin-bottom: 5px;
	}

	.Icon,
	.Text,
	.TemplateCardPreview__overlay {
		visibility: hidden;
		opacity: 0;
		height: 100%;
		width: 100%;
		transition: all ease-out 200ms;
	}
}

.TemplateCardPreview:hover {
	box-shadow: 0 5px 10px 0 rgba($black, 0.2);

	.Icon,
	.Text,
	.TemplateCardPreview__overlay {
		visibility: visible;
		opacity: 1;
	}
}

.TemplateCardPreview__overlay:before {
	content: "";
	width: 100%;
	height: 100%;
	background: linear-gradient($green, $medium-green);
	opacity: 0.8;
	position: absolute;
	top: 0;
	left: 0;
}
