.ReportcenterContentEmpty {
	margin-top: 145px;

	.Headline {
		margin-bottom: 20px;
		text-align: center;
	}

	.Link {
		margin-left: 3px;
	}
}
