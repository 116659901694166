.EditProductCustom {
	.Row {
		margin: 20px -20px 0;

		.Column {
			padding: 0 20px;
		}
	}

	textarea {
		resize: vertical;
		min-height: 34px;
		height: 34px;
	}

	.NumberInput {
		.Input {
			width: 100%;
		}
	}
}
