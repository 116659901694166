@import "~StyleVariables";

.ActivityWrapper {
	padding: 0 12px 0 20px;
	border-top: 1px solid $grey-4;
	display: flex;

	&__contactDetails {
		flex-basis: 50%;
		min-height: 230px;
		padding-top: 15px;
		padding-right: 20px;
		border-right: 1px solid $grey-4;
	}

	&__contactTitles {
		padding-bottom: 5px;
		display: flex;
		color: $grey-10;

		.titleCategory {
			margin-right: 10px;

			.select2-choice {
				height: 25px;
				line-height: 12px;
				border-color: transparent;
				color: $grey-10;
				transition: all ease-out 0.2s;

				&:hover,
				&:focus {
					border-color: transparent;
					background: $grey-2;
				}

				.select2-chosen {
					padding-left: 1px;
				}
			}

			.select2-arrow {
				display: none;
				border-left: none;
				top: -4px;
			}

			.select2-choice:hover .select2-arrow,
			.select2-choice:focus .select2-arrow {
				display: block;
			}
		}

		.select2-dropdown-open {
			.select2-choice {
				border-color: $grey-5;
			}

			.select2-arrow {
				display: block;
			}
		}

		.titleName {
			input {
				border-color: transparent;
				box-shadow: none;
				color: $grey-10;
				padding-left: 1px;

				&:hover {
					background: $grey-2;
				}

				&:focus {
					background: transparent;
					color: $bright-blue;
					border-bottom: solid 1px $bright-blue;
				}
			}
		}

		.Input--error input,
		.titleCategoryError .select2-choice {
			border-color: $red;
		}

		.barBoarder {
			padding-top: 4px;
			margin-right: 10px;
		}
	}

	&__clear {
		clear: both;
	}

	&__contactOptions {
		padding: 5px 0 5px 5px;
	}

	&__contactSelectOptions {
		padding-bottom: 0;
		float: left;
		width: auto;
		min-width: 185px;
		max-width: 65%;
		transition: margin-left ease-out 0.2s;
		margin-left: -10px;
		padding-right: 12px;

		&--empty {
			margin-left: 0;
		}

		.select2-choice {
			border: solid 1px transparent;
		}

		.select2-search-choice-close {
			display: none !important;
		}

		.select2-arrow {
			display: none !important;
		}

		.select2-inner-link {
			color: $grey-13;
			font-size: 16px;
			font-weight: $boldFontWeight;
			line-height: 1.2;
			margin-right: 20px;
		}

		.select2-dropdown-open {
			width: 400px;
			z-index: 1002;

			.select2-search-choice-close {
				display: block !important;
			}

			.select2-arrow {
				display: block !important;
			}

			.select2-inner-link {
				color: $bright-blue;
			}

			.select2-choice {
				border: solid 1px $grey-8;
			}
		}

		.select2-default {
			border: solid 1px $grey-8;

			.select2-search-choice-close {
				display: none !important;
			}

			.select2-arrow {
				display: block !important;
			}

			.select2-inner-link {
				color: $bright-blue;
			}
		}
	}

	.contactOptionWrapper {
		padding-bottom: 5px;
	}

	.contactOptions {
		button {
			font-size: 12px;
			width: auto;
			height: 34px;
		}

		opacity: 0;
		visibility: hidden;
		transition: opacity ease-in-out 200ms;
	}

	.contactOptionWrapper:hover .select2-search-choice-close,
	.contactOptionWrapper:hover .select2-arrow {
		display: block !important;
	}

	.contactOptionWrapper:hover .contactOptions {
		opacity: 1;
		visibility: visible;
	}

	.contactOptionWrapper:hover .select2-default .select2-search-choice-close {
		display: none !important;
	}

	.contactOptionWrapper:hover .select2-choice,
	.select2-choice:hover,
	.select2-choice:focus {
		border: solid 1px $grey-8;
		outline: none;
	}

	.contactOptionWrapper:hover .select2-inner-link,
	.contactOptionWrapper:focus .select2-inner-link {
		color: $bright-blue;
	}

	.contactOptionWrapper:hover {
		.ActivityWrapper__contactSelectOptions:not(.ActivityWrapper__contactSelectOptions--empty) {
			margin-left: 3px;
		}
	}

	&__contactGroup {
		float: left;
		width: 50%;
	}

	&__options {
		b {
			padding-left: 10px;
		}

		b,
		i {
			padding-right: 5px;
		}

		.square-holder.green {
			background-color: $bright-green;
			color: $white;
			float: left;
			width: 16px;
			height: 16px;
			margin-right: 10px;
			border-radius: 2px;
			padding: 0 2px 0 2px;
			margin-left: 8px;
		}

		a {
			cursor: pointer;
		}
	}

	&__subText,
	.subText {
		color: $grey-10;
	}

	&__journeyStep {
		margin-top: -3px;
		padding-bottom: 0;
	}

	&__contactItem {
		display: flex;
		padding-bottom: 3px;
		padding-top: 3px;
		padding-right: 10px;
		border-radius: 2px;
		height: 30px;
		transition: background-color ease-in-out 200ms;

		.icon {
			font-size: 14px;
			width: 20px;
			text-align: center;
			margin-top: 5px;
			margin-right: 5px;
		}

		.iconBlue {
			color: $bright-blue;
		}

		&:hover {
			background-color: $grey-2;
		}

		i {
			position: relative;
			top: 5px;
		}

		.Link {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			position: relative;
			top: 4px;
			margin-left: 3px;
		}
	}

	&__tools {
		display: flex;
		padding-top: 3px;
		margin-left: auto;

		.contactTools {
			display: none;

			.Icon {
				cursor: pointer;
				color: $grey-10;
				margin-left: 10px;

				&:hover {
					color: $bright-blue;
				}
			}
		}
	}

	&__contactInput {
		display: flex;
		padding-bottom: 3px;
		padding-top: 3px;
		padding-right: 10px;
		border-radius: 2px;
		height: 30px;
		width: 100%;

		.icon {
			font-size: 14px;
			width: 20px;
			text-align: center;
			margin-top: 5px;
			margin-right: 5px;
			color: $bright-blue;
		}
	}

	.inputValue {
		flex-basis: 85%;

		input {
			border-color: transparent;
			box-shadow: none;
			color: $bright-blue;
			padding-left: 2px;
			border-bottom: solid 1px $bright-blue;

			&:hover {
				background: transparent;
			}
		}
	}

	&__contactItem:hover .contactTools {
		display: block;
	}

	&__notesWrapper {
		padding-top: 0;
		padding-bottom: 0;

		textarea {
			font-size: 12px;

			min-height: 100px;
		}

		.setMinHeight {
			textarea {
				min-height: 300px;
			}
		}
	}

	&__notesTitle {
		display: flex;

		.title {
			flex: 1;
			padding-top: 10px;
		}

		.addSign {
			.insert-sign-btn {
				padding-right: 0;
			}
		}
	}

	&__notes {
		padding-top: 0;
		width: 100%;
		margin-bottom: 20px;
	}

	&__notesContents {
		padding: 5px 5px;
		white-space: pre;
		border-radius: 2px;
		cursor: pointer;
		position: relative;

		&:hover {
			transition: background-color ease-in-out 200ms;
			background-color: $grey-2;
		}
	}

	.notesTools {
		position: absolute;
		right: 20px;
		top: 4px;
		display: none;
		transition: all ease-out 0.2s;

		.Icon {
			cursor: pointer;
			color: $grey-10;
			margin-left: 10px;

			&:hover {
				color: $bright-blue;
			}
		}
	}

	&__notesEditor {
		margin-bottom: 25px;
		position: relative;
	}

	&__notesEditor:hover .Textarea__input {
		background-color: $grey-2;
	}

	.Textarea__input {
		cursor: pointer;
		width: 100%;
		border-radius: 2px;
		transition: background-color ease-in-out 200ms;

		&:hover {
			background-color: $grey-2;
		}

		&:focus {
			border: solid 1px $bright-blue;
			background-color: $white;
		}
	}

	.flat-input:hover + .notesTools {
		display: block;
	}

	.flat-input + .notesTools:hover {
		display: block;
	}

	&__outcomes {
		flex-basis: 60%;
		position: relative;
		padding: 15px 10px 25px 20px;

		.open-activity-btn {
			margin-top: 25px;
			padding: 0;
		}

		.open-activity-btn-wrap {
			margin-top: 25px;
		}

		.ActivityOutcomes {
			border: none;

			.Block:not(.BorderBox) {
				box-shadow: none;
			}

			.ActivityOutcomes__action-row {
				white-space: initial;

				.Calendar {
					td {
						word-break: initial;
						padding: 10px 0 0 0;
					}
				}
			}

			.ActivityOutcomes__sub-action {
				.Calendar {
					td {
						word-break: initial;
						padding: 10px 0 0 0;
					}
				}
			}

			.padded-section {
				border-radius: $border-radius;
				padding-left: 0px;
				padding-right: 0px;
				margin-left: 0px;
				margin-right: 0px;
			}

			.CardHeader {
				padding: 0;
			}

			.CardContent {
				padding: 0;
			}
		}
	}

	&__outcomes &__notesWrapper {
		padding-bottom: 0;
	}

	@media screen and (max-width: 1300px) {
		flex-direction: column;

		&__contactDetails {
			width: 100%;
			border-right: none;
			max-width: none;
		}

		&__outcomes {
			padding-left: 0;
			padding-bottom: 0;

			.ActivityHistoryLog {
				margin-bottom: 0;
			}
		}
	}
}
