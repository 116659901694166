@import "~StyleVariables";

.CompanyGroupStartPage {
	position: inherit;
	width: 100%;

	&__title {
		max-width: 400px;
	}
	// /**
	// * @todo  @upsales/components 2.19.19
	// * STYLING: Leche requests the Headline with size ‘sm’ to have a font size of 22px.
	// * @link https://github.com/upsales/ui-components/issues/574
	// */
	.Headline--sm {
		font-size: 22px;
	}

	.group-tree.result-table {
		width: 100%;
	}

	table.group-tree {
		border-collapse: separate;
	}

	.group-tree thead {
		position: sticky;
		top: 0;
		z-index: 99;
		background-color: $white;
	}

	.accounts-card .result-table th {
		border-bottom: $border;
	}

	.no-height {
		color: $grey-11;
	}

	#up-list-filters {
		z-index: 1000000;

		.actions > .Tooltip {
			margin-right: $space * 4 !important;
		}
	}
}
