@import "~StyleVariables";

.LeadSource {
	display: flex;
	align-items: center;
	&__source-icon {
		width: 16px;
		margin-right: 8px;
	}
}
