@import "~StyleVariables";

.DuplicatesCard {
	border-radius: $border-radius;
	transition: $transition-small-ease;
	--width: 492.5px;
	--widthDecreased: 420px;

	&--greyBackground {
		background-color: $grey-1;
		.Table {
			background-color: $grey-1;
		}
	}

	&__container {
		width: var(--width);
		border-right: 1px solid $grey-4;
		&--decreaseWidth {
			width: var(--widthDecreased);
		}
	}

	.ExpandedTableValues {
		.Text {
			width: calc(var(--width) - #{$space * 8});
		}
		&--decreasedWith {
			.Text {
				width: calc(var(--widthDecreased) - #{$space * 8});
			}
		}
	}

	&__visibleCard {
		height: 90px;
	}

	&__circle {
		font-size: 4px;
	}

	&__subtitleColumns {
		.Link,
		.Text {
			max-width: 340px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__icon {
		animation: rotateBackAnimation;
		animation-iteration-count: 1;
		animation-duration: 400ms;
		transform: rotate(0deg);
		font-size: $h3-font-size;

		&--rotate {
			animation: rotateAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(180deg);
		}
	}
}
