@import "~StyleVariables";

.EasyBookingSettingsEditorHeader {
	position: sticky;
	top: 0;
	z-index: 100;

	.Tabs--no-flex {
		display: block;
	}

	&__cancelButton {
		margin-right: 10px;
	}

	&__active {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 160px;

		.Toggle {
			margin-right: 12px;
		}

		.Title {
			margin-right: 12px;
		}
	}

	&__row {
		justify-content: space-between;
	}

	&__status {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 82px;
		justify-content: center;

		.Text {
			margin-right: 8px;
		}
	}
}
