@import "~StyleVariables";
.MergeClients {
	.ModalContent {
		padding: 100px;

		.Label {
			font-weight: $boldFontWeight;
		}

		.Text {
			margin: 20px 0 20px;
		}

		.Card {
			padding: 10px;

			.Text {
				margin: 0;
			}
		}
	}
}
