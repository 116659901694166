@import "~StyleVariables";

.ColumnChartWidgetBars {
	flex: 1 0 0;
	position: relative;
	margin-top: 15px;

	.Tooltip {
		position: absolute;
		bottom: 0;
		left: 20px;
		width: 60px;
		height: 100%;
		z-index: 1;
	}

	&--thin-mode {
		.Tooltip {
			width: 30px;
		}
	}

	&--has-data {
		border-bottom: 1px solid $green;
	}

	&--has-negative-values {
		margin-bottom: 15px;

		&.ColumnChartWidgetBars--has-data {
			border-bottom: 0;

			&:after {
				content: "";
				width: 100%;
				bottom: -15px;
				position: absolute;
				border-bottom: 1px solid $green;
			}
		}

		.ColumnChartWidgetBars__sub-row-wrap {
			.ColumnChartWidgetBar {
				border-radius: 0;

			}
		}
	}

	&--loading {
		border-bottom: 1px solid $grey-3;
	}

	&__zero-stripe {
		width: 100%;
		position: absolute;
		border: 1px solid rgba($grey-10, 0.1);
		z-index: 1000;
	}

	&__floating-value {
		position: absolute;
		top: 0px;
		margin-left: 7px;
		bottom: 0;
		display: flex;
		justify-content: center;
		flex-flow: column;

		.Text {
			line-height: 11px;

			&--bright-blue {
				font-size: 10px;
			}
		}
	}

	&__sub-row-wrap {
		position: absolute;
		height: 100%;
		width: 100%;

		.ColumnChartWidgetBar {
			border-radius: 0;

		}
	}
}
