.DetectedDuplicatesDrilldown-exit,
.DetectedDuplicatesDrilldown-exit .DetectedDuplicatesDrilldownContainer,
.DetectedDuplicatesDrilldown-enter,
.DetectedDuplicatesDrilldown-enter .DetectedDuplicatesDrilldownContainer {
	max-height: 0 !important;
}

.DetectedDuplicatesDrilldown-enter-active,
.DetectedDuplicatesDrilldown-enter-active .DetectedDuplicatesDrilldownContainer {
	max-height: 200px !important;
}

.DetectedDuplicatesDrilldown,
.DetectedDuplicatesDrilldown .DetectedDuplicatesDrilldownContainer {
	max-height: 200px;
	overflow-y: scroll;
	transition: all 300ms ease-in-out;
	transition-property: max-height;
}

.DetectedDuplicatesDrilldown-exit-done,
.DetectedDuplicatesDrilldown-exit-done .DetectedDuplicatesDrilldownContainer {
	max-height: 0 !important;
}

.DetectedDuplicatesDrilldownInnerTable th {
	width: 40%;
}

.DetectedDuplicatesDrilldownInnerTable th:last-child {
	width: 20%;
}

.DetectedDuplicatesDrilldownColumn {
	padding: 0;
}

.DetectedDuplicatesDrilldownInnerTable .TableColumn {
	border-top: none;
}

.DetectedDuplicatesDrilldownInnerTable .TableColumn:first-child,
.DetectedDuplicatesDrilldownInnerTable .TableHeader__column:first-child {
	border-right: 1px solid #edf1f5;
}

.DetectedDuplicatesDrilldownInnerTable .TableColumn--grey,
.DetectedDuplicatesDrilldownInnerTable .TableHeader__column:not(:first-child) {
	background-color: #f7fafc;
}
