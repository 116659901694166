@import "~StyleVariables";

.Modals {
	$transitionTime: 300ms;
	$slideStart: 20%;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $modal-z;
	visibility: hidden;

	&--visible {
		visibility: visible;
	}

	&--ascended {
		z-index: $modal-z + 1;
	}

	.FullScreenModal {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: $modal-z;
		background-color: $white;

		&__close {
			position: fixed;
			top: 10px;
			right: 14px;
			color: $grey-10;
			background: rgba($grey-4, 0.6);
			border-radius: 100%;
			width: 34px;
			height: 34px;
			border: none;
			padding: 0;
			z-index: 10000;
			transition: background ease-out 300ms;
			text-shadow: none;
			&:hover {
				background: rgba($grey-4, 1);
			}
		}
	}

	// TODO: This styling needs to be removed or moved to the Modal component
	.Modal {
		position: relative;
		max-height: calc(100% - 48px);
		overflow-y: auto;

		.ModalControls,
		.ModalHeader {
			position: sticky;
		}

		.ModalContent {
			position: relative;
		}

		.ModalContent {
			margin: 0;
			overflow-y: initial;
		}

		&.FullscreenModal {
			max-height: 100%;

			.ModalContent {
				overflow-y: auto;
			}
		}
	}

	&__curtain {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: $modal-z;
		transition: all ease-out $transitionTime;
		background-color: rgba($black, $alpha: 0.2);
		opacity: 0;
		visibility: hidden;

		&--visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&__modal {
		z-index: $modal-z;
		transition-property: all;
		transition-delay: 0ms; //hide-delay
		// transition-timing-function: cubic-bezier(0.8, 1.4, 0.64, 1);
		transition-timing-function: cubic-bezier(0.8, 1.56, 0.8, 1); // bounce
		transition-duration: $transitionTime;
		opacity: 0;
		visibility: hidden;

		&--visible {
			transition-delay: 0ms; // show-delay
		}

		&--active {
			opacity: 1;
			visibility: visible;
		}

		&--drawer {
			position: fixed;
			top: 0;
			box-shadow: -10px 0 20px 0 rgba(47, 57, 71, 0.2);
		}

		&--drawer-right {
			right: 0;
		}

		&--animation-slide-bottom,
		&--animation-fade {
			opacity: 0;
			visibility: hidden;
			position: absolute !important; // Need important here to override .Modals .Modal

			&.Modals__modal--visible {
				opacity: 1;
				visibility: visible;
				z-index: $modal-z - 1;
				position: relative;
			}

			&.Modals__modal--active {
				z-index: $modal-z;
			}
		}

		&--animation-slide-bottom {
			transform: translateY($slideStart);

			&.Modals__modal--visible {
				transform: translateY(0%);
			}
		}

		&--animation-drawer-right {
			transition-timing-function: ease-out;
			transform: translateX(100%);
			visibility: hidden;

			&.Modals__modal--visible {
				transform: translateX(0%);

				&.Modals__modal--active {
					visibility: visible;
				}
			}
		}
	}
}
