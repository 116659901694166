@import "../../../styles/variables";

.BillingAddonTiersModal {
	.ModalHeader,
	.ModalContent {
		background-color: $grey-4;
	}

	&__tier {
		padding: 3px 20px;
	}

	&__current {
		$size: 20px;
		height: $size;
		width: $size;
		line-height: $size;
		border-radius: 50%;
		align-self: center;
		text-align: center;
		font-size: 14px;
		padding-left: 3px;
	}

	&__current-col {
		display: flex;
	}
}
