@import "../../../styles/variables";

.FindHiddenProspects {
	&__inner {
		width: 700px;
		margin: auto;
	}

	&__Description {
		margin: 10px auto 38px;
	}

	&__Types {
		width: 420px;
		margin: 16px auto;
	}

	&__Type {
		background-color: white;
		border: 1px solid $grey-6;
		border-radius: 2px;
		box-shadow: 0 1px 2px 0 rgba(black, 0.1);
		height: 120px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 200px;
		cursor: pointer;

		&--visible-on-hover {
			margin-top: 5px;
			display: none;
			line-height: 20px;
		}

		&:hover {
			padding: 10px 2px;
			background-color: $grey-1;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

			.FindHiddenProspects__Type--visible-on-hover {
				display: block;
			}
		}
	}
}
