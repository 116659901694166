.PlanPhonecallsDrawerCallListSelect {
	.select2-container {
		width: 100%;
		font-size: 14px;
	}

	> .Block:first-of-type {
		display: flex;
		align-items: center;

		> .Help {
			margin-left: 4px;
		}

		.Label {
			font-weight: normal;
			margin-bottom: unset;
		}
	}

	&__content {
		height: 56px;
		position: relative;

		& > .Block {
			position: absolute;
			width: 100%;
		}

		.Column:first-of-type {
			flex-basis: 40%;
		}
	}

	&__saveButtons {
		transition-property: height;
		height: 0px;
	}

	&--withToggle &__content {
		transition-property: height;
		height: 0px;
	}

	&--withToggle#{&}--showInput &__content {
		margin-top: 10px;
		height: 56px;
	}

	&--showCreateNew &__saveButtons {
		height: 30px;
	}

	&--withToggle {
		.Help {
			.Icon {
				margin-top: 4px;
				margin-left: 10px;
			}
		}
	}

	&__progress {
		position: absolute;
		width: 15px;
		top: 0;
		right: 0;
	}
}
