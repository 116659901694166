@import "~StyleVariables";

.SubscriptionIndexingSettings {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	height: 100%;
	padding-bottom: 30 * $space;

	&__content {
		width: 800px;
		align-self: center;
	}

	&__notes-input {
		.Label {
			display: flex;
		}

		&--small {
			width: 49%;
		}
	}

	.DateInput {
		width: 300px;
	}

	&__user-select {
		width: 300px;
	}
}