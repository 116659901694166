@import "~StyleVariables";

.ChildNavigation {

    white-space: nowrap;
    overflow-x: auto;
    width: 100%;

    &--positive span {
        background-color: green;
    }
    &--negative span {
        background-color: red;
    }
    &__positive span {
        color: $success-4;
    }
    &__negative span {
        color: $red;
    }

    .Tab:not(.Tab--selected) {
        .Text {
            color: $grey-10;
        }
    }


}