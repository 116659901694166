@import "~StyleVariables";

$navbar-height: 50px;

.EditorHeader {
	box-shadow: 0 1px 2px 0 rgba($black, 0.2);
	background-color: $white;
	height: $navbar-height;
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 1050;

	&__icon {
		padding: 13px 0px;
		padding-right: 5px;
	}

	&__title {
		padding: 5px 10px;

		.Text,
		.Title > div {
			max-width: 340px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	&__controls {
		position: absolute;
		right: 0;
		top: 0;

		.Row {
			// TODO: remove with MM-12706-add-modal-header-buttons-components 
			height: auto;
		}
	}
}

.EditorHeader .Tab .ColorSwitcher {
	display: inline-block;
	margin-right: 5px;
}
