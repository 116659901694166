@import "~StyleVariables";

.TodoListReport {
	@keyframes showClickable {
		to {
			box-shadow: 4px -4px 12px 0 $grey-6;
		}
	}
	height: 110px;
	padding-top: 12px;
	padding-bottom: 12px;
	box-shadow: 0 -1px 2px 0 $grey-6;
	&:hover {
		animation: showClickable 0.2s ease-in-out;
		animation-fill-mode: forwards;
		cursor: pointer;
	}

	&__left-column {
		border-right: 1px solid $grey-6;
		margin-right: 16px;
		padding-right: 16px;
	}

	&__stats-row {
		display: flex;
		justify-content: space-between;
	}
	&__column {
		&--zero {
			.Text,
			.Title {
				color: $grey-8;
			}
		}
	}
	&__total-outcomes-wrap {
		display: flex;
		.Title {
			margin-right: 10px;
		}
	}
	&__outcome-number {
		display: flex;
		align-items: center;
		margin-left: 6px;
		> .Icon {
			margin-left: 6px;
			margin-right: 4px;
			font-size: 16px;
		}

		&--zero {
			.Text,
			.Icon {
				color: $grey-8;
			}
		}
	}
	&__headline-row {
		margin-bottom: 12px;
		display: flex;
		.Text {
			color: $bright-blue;
			margin-left: 30px;
			margin-top: 2px;
		}
	}
}
