@import "~StyleVariables";

.ChangedRow {
	height: 44px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	padding-left: 16px;
	padding-right: 16px;

	border-bottom: 1px solid $grey-4;

	&__product {
		min-width: 250px;
	}

	p {
		margin: 0;
	}

	&__change {
		.Text--md {
			display: inline-block;
		}
	}

	&__previous {
		text-decoration: line-through;
		margin-right: 4px;
	}

	&__sum {
		min-width: 200px;
		text-align: end;
	}

	&>* {
		flex-basis: 100%;
	}
}