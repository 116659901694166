@import "~StyleVariables";

.GoalsTools {
	max-width: 100%;
	&__filter-elem {
		margin-right: 20px;
		padding: 10px 0;
	}
	&__year-label {
		padding-left: 10px;
	}
	.users {
		width: 250px;
	}
	box-shadow: none;
	border: none;
	.Toggle {
		margin: 10px 0;
	}

	.UserRoleSelect--Select {
		z-index: 3;
		&:before {
			z-index: 1;
			position: absolute;
			content: "";
			bottom: 1px;
			height: 8px;
			width: 100%;
			background: linear-gradient(transparent, $white);
		}
		&:not(.select2-container-active) {
			transition-delay: 100ms;
			.select2-choices {
				height: 36px !important;
				overflow: hidden;
			}
		}
		.select2-choices {
			transition: all ease 0ms;
			transition-delay: 300ms;
			overflow-y: auto;
			height: 100px !important;
			border-radius: 0;
			border: none !important;
			border-bottom: 1px solid $grey-6 !important;
			.select2-input {
				padding: 0;
				min-width: 150px;
			}
			.select2-search-choice {
				margin-bottom: 0px;
				margin-top: 3px;
			}
		}
	}
	&__currency-filter {
		.DropDownMenu__content {
			min-width: stretch;
		}
	}
	&__inactiveTooltip {
		> div {
			display: inline-block;
			margin: 0;
		}
	}

	&__fullscreenTooltip {
		margin-top: 25px;
		right: 12px;
		position: absolute;
	}
}
.AdminGoals-user-select {
	width: max-content !important;
}
