@import "~StyleVariables";

.ConfirmBuyProspects {

    &__up-modal-content {
        height: 100%;
        width: 100%;
        padding: 0;

        .ModalContent__content {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: stretch;

            .ConfirmBuyProspects__left-column {
                background-color: #fff;
                border-right: 1px solid rgba(0, 0, 0, .1);
                display: flex;
                flex-direction: column;
                
                width: 320px;
                min-width: 320px;
                height: 100%;
                overflow-y: auto;

                .section {
                    padding: $space * 5;
                }

                .section-descriptor {
                    color: $grey-10;
                }

                .section-title {
                    margin-top: 10px;
                    margin-bottom: 5px;
                }

                .section.section--border-bottom {
                    border-bottom: 1px solid rgba(0, 0, 0, .1);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .section-action--bottom {
                    margin-top: auto;
                }

                .section-action {
                    margin-top: $space * 2;
                }
            }

            .ConfirmBuyProspects__right-column {
                left: 320px;
                flex-grow: 1;
                overflow-y: auto;

                .section {
                    border-bottom: 1px solid rgba(0, 0, 0, .1);
                    padding: 20px 20px 10px;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                .ConfirmBuyProspects__select-me {
                    margin-top: $space;
                    float: right;
                    font-size: 12px;
                }

                .ConfirmBuyProspects__create-campaign {
                    float: right;
                    font-size: 12px;
                    font-weight: 400;
                }

                .ConfirmBuyProspects__add-company-data {
                    margin-bottom: $space * 4;
                }

                .ConfirmBuyProspects__required-icon {
                    margin-left: $space / 2;
                    line-height: 14px;
                }

                .ConfirmBuyProspects__add-to-campaign-category{
                    margin-bottom: $space * 2;
                }
            }
        }



    }



}