@import "variables";

.up-step-int {
	$height: 36px;

	height: $height;
	padding: 0;

	input {
		height: $height;
		text-align: center;
		border: 1px solid darken($grey-4, 6);
		border-left: none;
		border-right: none;
		font-size: 12px;
		padding-left: 2px;
		padding-right: 2px;

		&.focus,
		&:focus {
			background-color: $bright-blue;
			color: $white;
		}
	}

	button {
		height: $height;
		width: $height;
		line-height: $height;
		text-align: center;
		padding: 0;
	}

	&.input-sm {
		$height: 26px;

		height: $height;
		input {
			height: $height;
		}

		button {
			height: $height;
			width: $height;
			line-height: $height;
		}
	}
}
