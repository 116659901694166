@import "~StyleVariables";

#editor-toolbox {
	.EditFieldRow {
		padding: 0 0 0 25px;

		&.open {
			padding-bottom: 17px;
		}
	}
}

.EditFieldRow {
	transition: all ease 200ms;
	overflow: hidden;
	height: auto;
	max-height: 600px;

	&.closed {
		max-height: 50px;
		height: 50px;

		.btn-remove {
			display: none;
		}

		.EditFieldRow__Header {
			height: 100%;

			.field-row-header {
				display: flex;
				height: 100%;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}
		}
	}

	&__Header {
		cursor: pointer;
	}

	.field-row-header,
	.drag-handle {
		cursor: pointer;
	}

	.form-control {
		max-width: 330px;
	}

	.desc-text {
		cursor: pointer;
		visibility: visible !important;
		opacity: 1 !important;
	}

	label {
		color: $grey-12;
	}

	.btn-remove {
		position: absolute;
		right: 45px;
		top: 15px;
		font-size: 14px !important;
	}

	&.open {
		.field-row-header {
			padding-top: 17px;
			padding-right: 25px;
		}

		.desc-text {
			display: none;
		}

		&:hover {
			background-color: $grey-2 !important;
		}
	}

	&__Settings {
		padding-right: 17px;
	}

	.toggle-field-open {
		font-size: 14px !important;
		top: 16px !important;
		right: 25px !important;
		display: none;
		cursor: pointer !important;
	}

	.drag-handle {
		visibility: hidden;
		background: none !important;
	}

	&:hover,
	&.open {
		background-color: $grey-1 !important;

		.toggle-field-open {
			display: block;
		}

		.EditFieldRow__Headline-wrapper {
			top: 0;
		}

		.EditFieldRow__SubTitle {
			opacity: 1;
		}

		.drag-handle {
			visibility: visible;
		}
	}

	&__Title {
		display: block;
		width: 320px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__Headline-wrapper {
		transition: all cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		position: relative;
		top: 10px;
	}

	&__SubTitle {
		line-height: 18px;
		opacity: 0;
		transition: opacity cubic-bezier(0, 0, 0.08, 1.27) 400ms;

		.Icon {
			position: absolute;
			top: 5px;
			margin-left: 3px;
		}
	}

	&__RequiredIcon {
		font-size: 8px;
		margin-left: 5px;
		position: absolute;
		margin-top: 6px;
	}

	.language-input {
		max-width: 330px;

		.Select__selected {
			margin-top: 12px;
			height: 30px;
			// This color does no longer exist in colors.
			border-color: #ccc;
		}
	}
}
