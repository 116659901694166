@import "~StyleVariables";

.FocusModeTopList {
	width: 100%;
	max-width: 500px;
	height: auto;
	max-height: 200px;
	display: inline-block;
	align-self: center;
	justify-self: center;
	position: relative;
	&__header-row {
		display: flex;
		align-items: center;
	}
	&__title-col {
		margin-left: 15px;
	}
	&__settings {
		width: 100%;
		max-width: 500px;
		position: absolute;
		z-index: 2;
		background-color: $grey-1;
		padding: 15px;

		.select2-container {
			max-width: 450px;
			width: 80%;
		}
	}
}
