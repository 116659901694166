label {
	display: inline-block;
	margin: 0;
	font-weight: $boldFontWeight;
	line-height: 1.8em;
}

.flat-input {
	background-color: transparent;
	border: none;
	outline: none;
	display: block;

	&:focus {
		outline: none;
	}
}

#form-card #title h2 .label {
	vertical-align: bottom;
	margin-bottom: 16px;
	display: inline-block;
}

#form-card #title.has-subtitle h2 {
	line-height: 33px;

	.subtitle {
		display: block;
		font-size: 10px;
		text-transform: uppercase;
		color: $grey-10;
		margin-top: -17px;
	}
}
