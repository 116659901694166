@import "~StyleVariables";

$navbar-height: 50px;

.MailTemplateEditor {
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 300ms;

	&--open {
		opacity: 1;
		visibility: visible;
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -50px;
	}

	&__header {
		.Tab {
			.Text--bold {
				font-weight: $boldFontWeight;
			}
			&#design .Text.Skeleton:before {
				content: "Design";
			}
			&#settingsAndSend .Text.Skeleton:before {
				content: "Settings & send";
			}
		}
		.Text {
			display: inline-block;

			&.Skeleton > * {
				display: none;
			}
		}
	}

	&__navbar-title {
		width: 335px;
		padding: 5px 10px;
		border-right: 1px solid $grey-4;
		
		.Button {
			height: 15px;
			line-height: 15px;
		}

		&--empty {

			.Headline {
				font-style: italic;
			}
		}

		.Text--loading:before {
			content: "Template - All changes saved";
		}

		.Text,
		.Headline {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.Loader {
			display: inline-block;
			width: 18px;
			height: 18px;
			vertical-align: middle;
		}
	}

	&__controls {
		display: inline-flex;
		margin-left: auto;
		border-left: 1px solid $grey-4;
	}
}
