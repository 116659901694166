@import "../../../../../styles/variables";

.AccountListContacts__Controls {
	display: flex;
	.btn-link {
		color: $grey-10;
	}

	.row-tool.fa-edit {
		height: 100%;
		&:hover {
			color: $bright-blue;
			text-decoration: none;
		}
	}

	.inline-confirm-modal {
		width: 34px;
		.row-tool.Icon-Trash {
			line-height: 40px;
			&:hover {
				color: $red;
			}
		}
	}

	.inline-confirm-modal-hidden {
		.inline-confirm-tab {
			.row-tool.Icon-Trash {
				color: $grey-10;
				&:hover {
					color: $red;
				}
			}
		}
	}
}
