@import "~StyleVariables";

.ChooseMailIntegration .ModalHeader {
	background-color: transparent;
}

.ChooseMailIntegration__integration-list {
	display: grid;
	grid-template-columns: repeat(3, 150px);
	grid-auto-rows: 150px;
	justify-content: space-between;
	justify-content: space-around;
	grid-row-gap: 20px;
}

.ChooseMailIntegration__integration {
	cursor: pointer;
	transition: box-shadow 0.3s ease-in-out;
}

.ChooseMailIntegration__integration:hover {
	box-shadow: 0 1px 14px 0 rgba(30, 37, 46, 0.2);
}

.ChooseMailIntegration__integration-placeholder {
	@keyframes background-color-change {
		from {
			background-color: $grey-3;
		}
		to {
			background-color: $grey-2;
		}
	}

	animation-duration: 0.8s;
	animation-iteration-count: infinite;
	animation-name: background-color-change;
	animation-timing-function: ease-in-out;
	animation-direction: alternate;
}

.ChooseMailIntegration__integration-logo {
	display: flex;
	justify-content: center;
	height: 50px;
	margin-top: 40px;
	margin-bottom: 10px;
}

.ChooseMailIntegration__integration-logo img {
	height: 100%;
	width: auto;
}

.ChooseMailIntegration pre {
	font-family: inherit;
	font-size: inherit;
	background: transparent;
	border: 0;
	display: block;
	padding: 0;
	margin: 0;
	line-height: inherit;
	color: inherit;
	border-radius: 0;
}

.ChooseMailIntegration--terms .field-oauth2,
.ChooseMailIntegration--terms .field-oauth2 > div,
.ChooseMailIntegration--terms .field-oauth2 > button {
	vertical-align: top;
	display: inline-flex;
}

.ChooseMailIntegration--terms button:not(.btn-link) {
	min-width: 130px;
}

.ChooseMailIntegration--terms .btn-link {
	margin-left: 10px;
}

.ChooseMailIntegration .app-field-section {
	margin: 0 30px;
}
