@import "../../../styles/variables";

.EmailSyncCard {
	padding-top: 20px;
	padding-bottom: 10px;

	&__fieldset {
		padding-top: 10px;

		.EmailSyncCard__fieldset-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.EmailSyncCard__email-input .select2-search-choice {
			background-color: $blue !important;
			color: $white !important;
		}
	}
}
