@import "~StyleVariables";

.CreateAccountModal {
	overflow: auto;

	#up-logo-wrapper {
		position: fixed;
		top: $space * 3;
		left: 14px;
		width: 110px;
		z-index: 10000001;

		path {
			fill: $grey-6;
		}
	}

	.exit-button {
		all: unset;
		position: fixed;
		top: 10px;
		right: 14px;
		color: $grey-10;
		background-color: $grey-4;
		transition: background-color ease-in-out 200ms;
		border-radius: 100%;
		width: 34px;
		height: 34px;
		cursor: pointer;
		text-align: center;
		z-index: 10000001;

		&:hover {
			background: $grey-4;
		}
	}

	.back-to-search {
		position: fixed;
		top: 10px;
		right: 60px;
		height: 34px;
		color: $grey-10;
		z-index: 10000001;
		background-color: $grey-4;
	}

	.full-screen-modal-content {
		width: 100%;
		max-width: 1600px;
		margin: 0 auto;
	}

	.divider-wrap {
		padding-top: 66px;
		border-bottom: 1px solid $grey-6;

		.header-content {
			padding-left: 14px;
			max-width: 1600px;
			margin: 0 auto;

			.full-screen-small-header {
				all: unset;
				font-size: $h3-font-size;
				color: $green;
				font-weight: $regularFontWeight;
			}

			.huge-input-container {
				width: 100%;
				display: flex;
				align-items: center;
				gap: $space * 3;
				margin-bottom: $space;

				.fa-search {
					color: $grey-8;
					font-size: 26px;
				}

				.huge-input {
					all: unset;
					width: 100%;
					font-size: 26px;
					color: $green;

					&::placeholder {
						color: $grey-8;
					}
				}
			}
		}
	}

	.show-group {
		.header-content {
			height: 177px;
			padding-top: 60px;

			.full-screen-small-header {
				display: flex;
				gap: $space * 2;
				all: unset;
				font-size: $h3-font-size;
				color: $green;
				font-weight: $regularFontWeight;
				padding-top: $space * 3;
				padding-left: 20px;

				.Button {
					font-size: 16px;
					line-height: 1.1;
					padding-left: 0;
					padding-right: 0;
					height: auto;
				}
			}

			.huge-input {
				width: 100%;
				font-size: 26px;
				color: $grey-13;
				padding-left: 20px;
				outline: none;
				border: none;
				margin-top: $space * 2;
			}

			.FindProspectsBranchSubtitle {
				padding-left: 20px;
			}
		}
	}

	.merge {
		margin-top: 200px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			font-size: 26px;
			font-weight: $boldFontWeight;
			text-align: center;
			margin-bottom: $space * 3;
		}

		.subtitle {
			color: $grey-10;
			font-size: $h3-font-size;
			text-align: center;
			height: 22px;
			margin-bottom: $space * 4;

			&-name {
				font-weight: $boldFontWeight;
				color: $black;
			}
		}

		.content-wrapper {
			display: inline-block;

			.account-select-wrapper {
				display: flex;

				.apply-to {
					line-height: 36px;
					padding: 0 $space * 2;
					color: $grey-10;
					background-color: $grey-2;
				}

				.DataSourceBuyButton {
					margin-left: $space * 2;
				}
			}

			.important-message {
				display: flex;
				color: black;
				padding-top: $space * 4;

				div {
					flex-grow: 1;
					width: 0;
					overflow: hidden;
				}
			}
		}
	}

	.required-fields {
		padding: 200px 20px 200px 20px;
		margin: 0 auto;
		max-width: 600px;

		h1 {
			font-size: 26px;
			font-weight: $boldFontWeight;
			color: $grey-11;
		}

		p {
			font-size: $h3-font-size;
			color: $grey-10;
		}

		.half {
			width: 50%;
			padding: 0 10px;
			vertical-align: top;
			display: inline-block;
		}

		.no-padding-left {
			padding-left: 0;
		}
	}

	.box-shadow {
		box-shadow: 0 3px 3px rgba($black, 0.2);
		background-color: $white;
		position: relative;
		z-index: 1000000;
		overflow: hidden;
	}

	.header-content.tab-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.tab-icon {
			margin-right: $space * 2;
		}
	}

	.search-results {
		background-color: $grey-2;
		padding: 0 20px 20px 32px;
		opacity: 1;
		transition: opacity ease-in-out 300ms;
		overflow: auto;

		position: fixed;
		top: 177px;
		bottom: 0;
		left: 0;
		right: 0;

		.accounts-card {
			border-radius: $border-radius;

			.Tab--disabled {
				color: $grey-5;

				.flag-icon-wrap {
					filter: grayscale(100%);
					opacity: 0.5;
				}
			}

			.account-row {
				.additional-info {
					background: $grey-2;
					color: $grey-10;
					font-size: 10px;
					padding: $space $space * 3;
					margin-left: $space * 2;
					border-radius: $border-radius;
				}
			}
		}

		.no-height {
			height: 0 !important;
			padding: 0 !important;
			margin: 0 !important;
			border-collapse: collapse;

			* {
				height: 0 !important;
				padding: 0 !important;
				margin: 0 !important;
				border-collapse: collapse;
			}
		}

		&.no-opacity {
			opacity: 0;
		}
	}

	.result-table {
		width: 100%;
	}

	.total-sum {
		padding: 5px 10px;
		background: $grey-2;
		color: $grey-10;
		font-size: 10px;
		margin-left: 10px;
		border-radius: 2px;
	}

	.flag-icon-wrap {
		margin-right: $space;
	}
}
