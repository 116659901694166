.DesiredOutcomeNoMatch__width-container {
	max-width: 400px;
	margin: auto;
}

.DesiredOutcomeNoMatch .Headline {
	padding-top: 20px;
}

.DesiredOutcomeNoMatch .Text {
	margin-top: 20px;
}

.DesiredOutcomeNoMatch .Label {
	margin-top: 60px;
}

.DesiredOutcomeNoMatch .Button:not(.btn-link) {
	margin-top: 60px;
}

.DesiredOutcomeNoMatch .Button.btn-link {
	margin-top: 15px;
}
