@import "~StyleVariables";

.ListViewActions {
	$width: 400px;
	$header-height: 60px;

	width: $width;
	position: fixed;
	bottom: 0;
	left: 50%;
	margin-left: -$width / 2;
	border-radius: $border-radius $border-radius 0 0;
	transition: all ease 200ms;
	box-shadow: 0 10px 20px rgba($black, 0.4);
	overflow: hidden;
	z-index: 3000;
	transform: translateY(100%);

	&--visible {
		transform: translateY(calc(100% - #{$header-height - 1px}));
	}

	&--open {
		transform: translateY(0);

		.ListViewActions__header .Icon {
			transform: rotate(180deg);
		}
	}

	&__header {
		height: $header-height;
		display: flex;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;

		.Text--sm {
			text-transform: uppercase;
			opacity: 0.8;
		}

		.Icon {
			transition: all ease-out 300ms;
			position: absolute;
			right: 16px;
		}

		&:hover {
			background-color: rgba($white, 0.1);
		}
	}

	&__select-none,
	&__action {
		height: 44px;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: all ease-out 300ms;
		color: $white;
		justify-content: center;

		&:hover:not(.ListViewActions__action--disabled) {
			background-color: rgba($white, 0.1);
		}
	}

	&__action {
		padding: 0 22px;

		&--disabled {
			color: $grey-8;
			cursor: not-allowed;
		}

		&__icon {
			margin-right: 22px;
			font-size: 16px;
		}

		.Text {
			display: inline-block;
		}
	}
}
