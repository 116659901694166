@import "~StyleVariables";

.BigNumberWidget {
	display: flex;
	flex-flow: column;
	justify-content: center;

	&__value {
		text-align: center;
		
		// Without auto margins the element reports the wrong width, and auto resizing doesn't work
		margin-left: auto;
		margin-right: auto;

		.Title {
			line-height: 1em;
			display: inline-block;
			font-size: inherit;
		}

		&--adjustForAverage {
			margin-top: auto;
			margin-bottom: auto;
		}
	}

	.Chip {
		margin-left: 12px;
	}

	&--drilldownEnabled:hover {
		cursor: pointer;
	}
	
	.Row {
		justify-content: center;
		height: auto;
		margin-top: 0.6em;
		margin-bottom: 0.6em; 
	}

	.Text {
		line-height: 1em;
	}

	&--bold {
		font-weight: $boldFontWeight;
	}

	&--sm {
		.Title {
			font-size: 20px;
		}
		.Text {
			font-size: 12px;
		}
	}
	&--md {
		.Title {
			font-size: 32px;
		}
		.Text {
			font-size: 17px;
		}
	}
	&--lg {
		.Title {
			font-size: 42px;
		}
		.Text {
			font-size: 23px;
		}
	}
	&--xl {
		.Title {
			font-size: 64px;
		}
		.Text {
			font-size: 32px;
		}
	}
}
