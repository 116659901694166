@import "~StyleVariables";

.ClientCardSidebar__CompanyGroup {
	cursor: pointer;
	padding: 10px 15px;
	margin: -10px -15px -10px;

	&__parent {
		.Link {
			display: inline-block;
		}

		.Text,
		.Link {
			max-width: 140px;
		}

		.EllipsisTooltip {
			display: flex;
		}

		&__edit {
			height: 20px;
			width: 20px;
			transition: ease-in 0.3s;
			border-radius: $border-radius;

			&:hover {
				background-color: $grey-5;
			}
		}
	}

	&__showCompanyGroup {
		width: stretch;
	}
}
