@import "~StyleVariables";

.ClientIndustry {

    .ClickableItem {
        width: 100%;
        height: 100%;

        &__title-col {
            flex: 1;

            &__subtitle {
                color: $bright-blue;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__item {
        .ClickableItem {
            &__title-col {
                padding: 0;
                flex: 1;
                max-width: 250px;

                &__subtitle {
                    color: $bright-blue;
                }
            }
        }
    }
}