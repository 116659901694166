@import "../../../../../../styles/_variables.scss";
.SharedEasyBookingSettingsDistribution {
	&__caret {
		grid-column-end: -1;
		padding: 0 12px;
	}

	&__data-name {
		margin-left: 12px;
		user-select: none;
		justify-self: start;
	}

	&__distribution-description {
		height: 82px;
		padding: 4px 12px;
		grid-column-end: -1;
		img {
			height: 105%;
		}
	}

	&__distribution-wrap {
		> .CardHeader {
			display: flex;
			justify-content: space-between;
			height: 82px;
			> .CardHeader__actions {
				flex-grow: 1;
				height: 82px;
				display: grid;
				grid-template-columns: min-content auto min-content;

				> .Help {
					display: flex;
					padding: 16px 0 0 16px;
				}
			}
		}
	}

	&__list-header {
		display: grid;
		grid-template-columns: auto max-content min-content;
		column-gap: 16px;
		padding: 20px 16px 5px 16px;
		width: 100%;
	}

	&__picker {
		height: 100%;

		> .Input {
			height: 40px;

			> .Input__input {
				height: 100%;
				border-radius: 0px;
				border: none;
				box-shadow: none;
				color: $grey-10;
				font-size: 12px;
				height: 40px;
				padding-left: 42px;
			}
			> .Input__icon {
				color: $grey-10;
				padding-left: 20px;
				margin: auto;
				top: 0;
				bottom: 0;
			}
		}
	}

	&__role-row {
		display: grid;
		grid-template-columns: 20px auto min-content min-content 42px;
		align-items: center;
		height: 40px;
		padding-left: 16px;
		cursor: pointer;
		border-bottom: 1px solid $grey-2;

		> .Tooltip {
			> div {
				display: flex;
				justify-content: space-around;
				align-items: center;
				height: 22px;
				width: 45px;
				background-color: $super-light-blue;
				color: $blue;
				border-radius: $border-radius;
				padding: 0 4px 0 4px;
			}
		}
	}

	&__table {
		overflow: hidden auto;
		width: 100%;
		max-height: 360px;

		> .Column > .Row {
			> .Row {
				align-items: center;
				padding-left: 20px;
			}
		}

		// Last row in the table looks better without a border
		.Column:last-of-type {
			> .Row:last-of-type {
				border-bottom: none;
			}
		}
	}

	&__account-manager-toggle {
		display: flex;
		align-items: center;
		padding: 0 16px;
	}
}
