@import "variables";
@import "mixins";
@import "animations";

.indicator-wrapper {
	$height: 19px;
	max-width: 70px;
	min-width: 40px;
	height: $height;
	display: inline-block;
	white-space: nowrap;

	&.indicator-sm {
		$height-sm: 16px;
		height: $height-sm;

		.first,
		.second,
		.third {
			line-height: $height-sm;
			font-size: 10px;
			min-width: 23px;
		}
	}

	&.indicator-xs {
		$height-sm: 15px;
		height: $height-sm;

		.first,
		.second,
		.third {
			line-height: $height-sm;
			font-size: 10px;
			min-width: 23px;
		}
	}

	&.indicator-orange {
		.first,
		.second,
		.third {
			&.indicator-active {
				background-color: $purple;
				color: $white;

				&:hover {
					background-color: darken($purple, 10%);
				}

				&.dark {
					background-color: $dark-orange;
				}
			}

			&.indicator-inverted {
				color: $super-light-purple;
				background-color: $purple;

				&:hover {
					background-color: darken($purple, 10%);
					color: $super-light-purple;
				}
			}
		}
	}

	.first,
	.second,
	.third {
		vertical-align: top;
		background-color: $grey-4;
		height: 100%;
		display: inline-block;
		color: $grey-8;
		text-align: center;
		line-height: $height;
		width: 33%;
		min-width: 23px;
		font-size: 13px;
		cursor: default;

		@include transition(all ease-out 200ms);

		&:hover {
			color: darken($grey-8, 10%);
		}

		&.first {
			@include border-radius($border-radius-sm 0 0 $border-radius-sm);
		}

		&.third {
			@include border-radius(0 $border-radius-sm $border-radius-sm 0);
		}

		&.indicator-active {
			background-color: $green;
			color: $super-light-green;

			&:hover {
				background-color: darken($green, 10%);
			}

			&.dark {
				background-color: $dark-blue;
			}
		}

		&.indicator-inverted {
			color: $green;

			&:hover {
				background-color: darken($grey-4, 10%);
				color: $blue;
			}
		}
	}
}
