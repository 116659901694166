#upload-file {
	$animationTime: 300ms;

	// File dropzones
	.file-dropzone {
		$animationSpeed: 300ms;

		background-color: $white;
		height: 300px;
		@include transition(all ease $animationSpeed);

		&.dz-drag-hover {
			background-color: $green;

			.on-passive {
				visibility: hidden;
				@include opacity(0);
			}

			.on-active {
				visibility: visible;
				@include opacity(1);
			}

			#instructions {
				.Title {
					color: $white;
				}
			}
		}

		// Hide dropzone default items
		.dz-preview {
			display: none;
		}

		.modal-close {
			position: absolute;
			top: 3px;
			right: 5px;
			font-size: 15px;
			z-index: 1001;
		}

		#click-zone {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
			z-index: 1000;
		}

		#instructions {
			position: absolute;
			top: 100px;
			left: 0;
			right: 0;
			font-size: 14px;
			text-align: center;

			.Title {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				font-size: 20px;

				&.on-active {
					top: 40px;
					font-size: 30px;
				}

				.Icon {
					margin-right: 7px;
				}
			}

			.Text:not(.extra-info) {
				position: absolute;
				top: 40px;
				left: 0;
				right: 0;
			}

			#upload-btn {
				margin-top: 80px;
			}
		}

		.on-active {
			visibility: hidden;
			@include opacity(0);
		}

		.on-passive {
			visibility: visible;
			@include opacity(1);
		}

		.on-passive,
		.on-active {
			@include transition(all ease $animationSpeed);
		}
	}

	.upload-table {
		tr {
			&.success {
				td {
					&.icon {
						border-left: 3px solid $bright-green !important;
					}

					.upload-progress {
						background-color: $bright-green;
					}
				}
			}

			&.failed {
				td {
					&.icon {
						color: $red;
					}

					.upload-progress {
						background-color: $red;
					}
				}
			}

			td.icon {
				width: 27px;
				text-align: center;
				border-left: 3px solid $white !important;
				color: $bright-green;
				@include transition(all ease $animationTime);
			}

			.upload-progress-wrap {
				$height: 6px;

				width: 100px;
				height: $height;
				overflow: hidden;
				background-color: $grey-4;
				@include border-radius($height);
				@include transition(all ease $animationTime);
				@include opacity(1);
				visibility: visible;

				&.hidden {
					@include opacity(0);
					visibility: hidden;
				}

				.upload-progress {
					@include border-radius($height);
					background-color: $bright-blue;
					width: 0%;
					height: 100%;
					@include transition(width ease 100ms);
				}
			}
		}
	}
}
