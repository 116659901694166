@import "variables";
@import "mixins";

.product-category-outer {
	line-height: 1;
	vertical-align: middle;

	.text-span {
		max-width: 90%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}

	.fa {
		margin-left: 5px;
		vertical-align: top;
	}
}

tr.up-order-row {
	.tooltip {
		pointer-events: none;
	}

	.hidden-field {
		visibility: hidden;
	}

	.edit-purchase-price {
		position: relative;
		padding-left: 20px;

		.pc-edit {
			opacity: 0;
			position: absolute;
			top: 5px;
			left: 0;
			color: $bright-blue;
		}

		&:hover .pc-edit {
			opacity: 1;
		}

		.edit-purchase-price-input {
			text-align: right;
			width: 80px;
			height: 21px;
			line-height: 20px;
			border: none;
			border-bottom: 1px solid $bright-blue;
			outline: none;
			padding: 0;
		}
	}

	.Card {
		padding: 10px 15px;
	}

	td {
		padding: 0px !important;
		position: relative;
		cursor: default !important;

		input.quantity,
		input.discount-percent-input,
		input.discount-input,
		input.price-input {
			padding-left: 5px;
			padding-right: 5px;
			font-size: 13px;
			font-style: normal;

			&[type="number"] {
				-moz-appearance: textfield;
				margin: 0;
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&[disabled] {
				color: $grey-10;

				& + .percent,
				& + .currency {
					color: $grey-10;
				}
			}
		}

		.form-high {
			height: 36px;
		}

		.row-col {
			padding-left: 5px;
			padding-right: 5px;

			&:first-of-type {
				padding-left: 10px;
			}

			&:last-of-type {
				padding-right: 10px;
			}

			& > .input {
				width: 100%;
			}

			&.last-col {
				text-align: right;

				@media (max-width: 1109px) {
					&.break-buttons .order-row-tools {
						$size: 20px;
						flex-direction: column;
						width: $size;

						button {
							height: $size;
							line-height: $size;
							width: $size;
						}
					}
				}
			}

			label {
				padding-top: 5px;
			}
		}

		.real-product-select-wrapper {
			position: relative;
			top: -36px;
			left: 0;
			width: 495px;
			height: 0;

			.select2-container {
				.select2-choice {
					height: 36px;
				}

				&.select2-dropdown-open {
					display: block;
					width: auto;
					z-index: 1003;
				}

				.select2-toggle {
					width: 100%;
					background-color: $white;
					padding: 5px;
					position: absolute;
					top: 30px;

					button {
						width: 50%;
					}
				}
			}

			&.has-toggle {
				.select2-choice {
					height: 70px;
				}
			}
		}

		.product {
			vertical-align: top;
			@include user-select(none);

			&.has-error {
				.form-control {
					background-color: $white;
				}
			}

			.product-name-label,
			.product-category-label {
				line-height: 24px;
				padding-right: 33px;
				position: absolute;
				width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				font-size: 13px;
				font-family: "Roboto", sans-serif;
			}

			.product-category-label {
				color: $grey-10;
				font-size: 9px;
				line-height: 10px;
				text-transform: uppercase;
				letter-spacing: 0.5px;
			}

			.fake-placeholder,
			.product-name-label {
				display: block;
				line-height: 24px;
			}

			.product-category-label + .product-name-label {
				top: 10px;
			}

			.fake-product-select {
				cursor: pointer;

				.product-tier-indicator {
					position: absolute;
					top: 0;
					right: 20px;
					bottom: 0;
					padding: 6px 10px 6px 20px;
					background-color: $white;
					background: linear-gradient(-90deg, $white 80%, transparent);

					&__inner {
						background-color: $super-light-green;
						border: 1px solid $green;
						border-radius: 3px;
						height: 23px;
						line-height: 23px;
						color: $green;
						font-size: 12px;
						padding: 0 10px;
					}
				}

				&[disabled] {
					.product-tier-indicator {
						background: transparent;
					}
				}
			}

			.fake-product-select__icon {
				position: absolute;
				top: 50%;
				left: 8px;
				transform: translateY(-50%);
			}

			.fake-product-select__bundle-chip {
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 20px;
				padding: 0 10px 0 20px;
				background-color: $white;
				background: linear-gradient(-90deg, $white 80%, transparent);
			}

			.form-control[disabled] .fake-product-select__bundle-chip {
				background-color: $grey-4;
				background: linear-gradient(-90deg, $grey-4 80%, transparent);
			}

			.fake-product-select--has-info {
				overflow: visible !important;
			}


			.fake-product-select--has-icon {
				padding-left: 30px;

				.product-name-label, .product-category-label {
					padding-right: 51px;
				}
			}

			.fake-product-select--has-double-icon {
				padding-left: 50px;

				.Icon-bundle {
					left: 26px;
				}

				.product-name-label, .product-category-label {
					padding-right: 71px;
				}
			}

			.form-control {
				position: relative;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-right: 22px;

				.fake-product-select {
					font-size: 14px;
					padding-top: 5px;
					position: relative;
				}

				.fake-placeholder {
					color: $grey-10;
				}

				.fake-select-dropdown {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					color: $grey-10;
					font-size: 12px;
					text-align: center;
					width: 20px;
					border-left: 1px solid $grey-8;
					padding-top: 8px;
				}
			}
		}

		.price {
			input.price-input {
				width: 60%;
				border-left: none;
				text-align: right;

				&--withoutQuantity {
					width: 100%;
					border-left: 1px solid #ccc; // Needs to be changed to color variable once we change the default border on form inputs
					border-radius: 4px;
				}
			}

			.has-error {
				input.quantity.focus,
				input.quantity:focus {
					border-color: $red;
					& ~ .price-input {
						border-color: $red;
					}

					& + .multiply-fix {
						color: $red;
						background-color: $super-light-red;
					}
				}

				input.discount-percent-input:focus {
					& + .percent,
					& + .currency {
						color: $red;
					}
				}
			}

			.multiply-fix {
				width: 10px;
				height: 34px;
				line-height: 34px;
				position: absolute;
				top: 1px;
				left: 40%;
				margin-left: -3px;
				color: $grey-10;
				cursor: default;
				background-color: transparent;

				&--withoutPrice {
					left: auto;
					right: 2px;
				}

				&.focus {
					color: $bright-blue;
					background-color: $white;
				}
			}

			input.quantity {
				width: 40%;
				border-right: none;
				text-align: right;

				&--withoutPrice {
					width: 100%;
					border-right: 1px solid #ccc; // Needs to be changed to color variable once we change the default border on form inputs
					border-radius: 4px;
					padding-right: 15px;

					&:focus {
						border-right: 1px solid $green;
					}
				}

				&.focus {
					@include bootstrap-focus();
				}

				&:focus {
					z-index: 1002;
					& ~ .price-input {
						@include bootstrap-focus();
					}

					& + .multiply-fix {
						color: $bright-blue;
						background-color: $white;
					}
				}
			}
		}

		.discount {
			position: relative;

			input.discount-percent-input {
				width: 40%;
				padding-right: 13px;
				text-align: right;
				border-right: none;

				&:focus {
					& + .percent,
					& + .currency {
						color: $bright-blue;
					}
				}

				&--withoutDiscount {
					width: 100%;
					border-right: 1px solid #ccc; // Needs to be changed to color variable once we change the default border on form inputs
					border-radius: 4px;
					padding-right: 15px;

					&:focus {
						border-right: 1px solid $green;
					}
				}
			}

			input.discount-input {
				width: 60%;
				text-align: right;
				padding-right: 30px;
				border-left: none;

				&--withoutDiscountPercent {
					width: 100%;
					border-left: 1px solid #ccc; // Needs to be changed to color variable once we change the default border on form inputs
					border-radius: 4px;

					&:focus {
						border-left: 1px solid $green;
					}
				}
			}

			.has-error {
				input.discount-percent-input:focus {
					& + .percent,
					& + .currency {
						color: $red;
					}
				}
			}

			.currency,
			.percent {
				z-index: 3;
				width: 10px;
				height: 32px;
				line-height: 32px;
				position: absolute;
				top: 3px;
				color: $grey-10;
				cursor: default;
				background-color: transparent;
			}

			.percent {
				left: 34%;
				margin-left: -3px;

				&--withoutDiscount {
					left: auto;
					right: 5px;
				}
			}

			.currency {
				right: 18px;
			}
		}

		.upOrderRow-total-row {
			display: block;
			text-align: left;
			width: 220px;
			float: left;
			line-height: 20px;
			padding-left: 10px;
		}

		.upOrderRow-total-row .total-row-right {
			display: block;
			float: right;
		}

		.upOrderRow-total-row .total-row-right .Text {
			display: inline;
		}

		.total {
			display: inline-block;
			width: auto;
			vertical-align: top;
			text-align: right;
			line-height: 36px;
			padding-right: 25px;

			b {
				display: block;
			}
		}

		.totalWithCopy {
			display: inline-block;
			width: auto;
			vertical-align: top;
			text-align: right;
			line-height: 36px;
			padding-right: 40px;

			b {
				display: block;
			}
		}

		.order-row-tools {
			$size: 25px;
			position: absolute;
			top: -7px;
			right: 0;
			display: flex;
			width: $size * 2;
			align-items: flex-end;

			&__col {
				display: flex;
				flex-direction: column;

				button {
					height: $size;
					line-height: $size;
					padding: 0;
					width: $size;
				}
			}
		}

		.order-row-tools.with-contribution-margin {
			top: 0;
		}

		.customfields {
			display: block;
			padding-right: 17.3%;
			margin-left: -10px;
			margin-top: $space * 4;

			// This styling is needed to make the input look the same as the other inputs in the row
			// Remove this once all custom fields are using ui-components

			.Input {
				height: 26px;
				width: 100%;
				input {
					height: 26px;
					line-height: 19px;
					font-size: 13px;
					border-radius: 2px;
					box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
					border: 1px solid #ccc; // former border color, we have no variable for this
				}
			}

			.absolute-label {
				position: absolute;
				top: -13px;
				font-weight: $boldFontWeight;
			}

			.row-col:first-of-type,
			.row-col:last-of-type,
			.row-col {
				padding-left: 5px;
				padding-right: 5px;

				.form-group {
					margin-bottom: 10px;
					margin-top: 12px;

					.tooltip {
						text-align: center;
					}
				}
			}

			.input {
				position: relative;
			}

			textarea {
				overflow-x: hidden;
			}

			.select2-container .select2-choice,
			.form-control {
				line-height: 19px;
				font-size: 13px;
				@include border-radius(2px);
				height: 26px;
			}

			.select2-container-multi {
				height: auto;
			}

			.select2-chosen {
				padding: 3px 12px;
			}

			.select2-arrow b {
				margin-top: -2px;
			}
		}

		@media (max-width: 767px) {
			.row-col:first-of-type,
			.row-col:last-of-type,
			.row-col {
				padding-left: 15px;
				padding-right: 15px;

				&.last-col {
					padding-top: 10px;

					.total {
						padding-right: 0;
					}
				}
			}

			.real-product-select-wrapper {
				top: 26px;
				left: 15px;
				right: 15px;
				width: auto;
			}

			.total {
				padding-right: 3px;
			}

			.customfields {
				padding-right: 0;
				margin-left: -15px;

				.row-col:first-of-type,
				.row-col:last-of-type,
				.row-col {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}

		@media (min-width: 1110px) {
			.real-product-select-wrapper {
				width: 561px;
			}
		}
	}
}

tr.up-order-row--hasRecurringInterval:not(.up-order-row--hasContributionMargin) {
	td .upOrderRow-total-row {
		width: 100%;
		text-align: right;
	}

	td .order-row-tools {
		top: -6px;
	}
}

tr.up-order-row--price-locked {
	td .price {
		input.price-input {
			width: 55%;
		}

		input.quantity {
			padding-right: 12px;
			width: 45%;
		}
	}

	td .edit-purchase-price:hover {
		cursor: default;
	}
}

.order-row-select {
	display: flex;
	align-items: center;
}

.order-row-select-product-type-icon {
	color: $green;
	padding-left: 5px;
}

.select2-highlighted .order-row-select-product-type-icon {
	color: $white;
}

.order-row-select-sub-text {
	margin-left: auto;
	color: $grey-10;
}

.select2-highlighted {
	.order-row-select-sub-text {
		color: $grey-7;
	}
}
