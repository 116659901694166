@import "~StyleVariables";

.ProjectPlanTemplateTable {
	max-width: 840px;
	border-radius: $border-radius;

	.AssistChip {
		max-width: 140px;
	}

	.TableColumn {
		max-width: 250px;
	}

	.TableRow--inactive {
		.Icon {
			color: $grey-8;
		}
	}

	.TableRow--clickable .TableColumn:last-child {
		width: 170px;
		padding-right: 0;
	}
}
