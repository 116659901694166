.InvoiceRelatedClient {
	display: flex;
	align-items: center;
	gap: 8px;

	.Text {
		display: inline-block;
	}

	&--orderModal{
		position: absolute;
		right: 18px;
		top: 58px;
		z-index: 1;
	}

	&--isVismaActive {
		top: 56px;
		z-index: 1;
	}
}
