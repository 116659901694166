@import "~StyleVariables";

.FileStorageApps {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__app {
		cursor: pointer;
		margin: 30px 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__header {
		margin-top: 20px;
	}

	&__subHeader {
		margin-top: 18px;
		font-size: 16px;
	}

	&__appTitle {
		margin-top: 10px;
	}

	&__loaderWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10%;
	}

	&__appWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
	}
}

.FileStorageApps__app .Card {
	width: 100px;
	height: 100px;
	background-position: center center;
	background-size: 80%;
	background-repeat: no-repeat;
}
