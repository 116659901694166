@import "~StyleVariables";

.FolderModal {
	width: 250px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 18px;
	gap: 12px;

	&--animate {
		animation: animateIn 0.4s ease;
	}

	.Input {
		width: 100%;
	}

	.Button {
		width: 60%;
	}

	&__add-icon {
		&:hover {
			background-color: $grey-6;
		}

		cursor: pointer;
		transition: all 0.25s ease;
		padding: 6px;
		border-radius: 4px;
		font-size: 16px;
		margin-right: 4px;
	}
}

@keyframes animateIn {
	0% {
		opacity: 0;
		scale: 0.5;
	}

	100% {
		opacity: 1;
		scale: 1;
	}
}
