@import "~StyleVariables";

$navbar-height: 50px;

.GroupMailEditor {
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 300ms;

	&--open {
		opacity: 1;
		visibility: visible;
	}
}

.GroupMailEditor__loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -50px;
}

.GroupMailEditor__scheduled-banner {
	position: sticky;
	top: $navbar-height;
	left: 0;
	right: 0;
	height: 25px;
	line-height: 25px;
	background-color: $medium-purple;
	z-index: 1060;
	text-align: center;
	box-shadow: 0 1px 2px 0 rgba(30, 37, 46, 0.2);

	.Text {
		display: inline-block;
		margin-right: 5px;
	}
}

.GroupMailEditor__header .Text {
	display: inline-block;

	&.Skeleton > * {
		display: none;
	}
}

.GroupMailEditor__header .Tab {
	.Text--bold {
		font-weight: $boldFontWeight;
	}
	&#recipients .Text.Skeleton:before {
		content: "Recipients";
	}
	&#design .Text.Skeleton:before {
		content: "Design";
	}
	&#settingsAndSend .Text.Skeleton:before {
		content: "Settings & send";
	}
}

.GroupMailEditor__navbar-title {
	width: 335px;
	padding: 5px 10px;
	border-right: 1px solid $grey-4;

	&--empty {
		.Button {
			height: 15px;
			line-height: 15px;
		}

		.Headline {
			font-style: italic;
		}
	}
}

.GroupMailEditor__navbar-title .Text--loading:before {
	content: "Draft - All changes saved";
}

.GroupMailEditor__navbar-title .Text,
.GroupMailEditor__navbar-title .Headline {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.GroupMailEditor__controls {
	margin-left: auto;
	border-left: 1px solid $grey-4;
}

.GroupMailEditor__navbar-title .Loader {
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: middle;
}
