.create-appointment-action-modal {
	.time-row {
		.start-time {
			border-right: 0;
			border-bottom-left-radius: 4px !important;
			border-top-left-radius: 4px !important;
			margin-left: 10px;
		}

		.input-text {
			padding: 6px 12px;
			font-size: 14px;
			font-weight: $regularFontWeight;
			line-height: 1;
			color: $grey-11;
			text-align: center;
			background-color: $grey-4;
			border: 1px solid $grey-8;
			border-left: 0;
			height: 34px;
			line-height: 20px;
			display: inline-block;
			vertical-align: top;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
}
