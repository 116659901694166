@import "~StyleVariables";
$width: 760px;
$header-height: 50px;

.TargetCalculator {
	width: $width;
	height: 100vh;

	.DrawerHeader {
		height: $header-height;
		width: 100%;
		.Button:nth-child(3) {
			height: 100%;
			border-radius: 0;
			width: 160px;
			position: relative;
			right: 0;
			.Text {
				text-shadow: none;
			}
			.Loader {
				right: 0;
				position: absolute;
			}
		}
		.TargetCalculator__saveButton {
			&--disabled {
				background-color: $grey-2;
				border: none;
				.Text {
					color: $bright-blue;
				}
				.Icon {
					animation: show 0.5 ease-in;
				}
			}
		}
		z-index: 100;
	}

	&__content {
		height: calc(100% - 50px);
		width: 100%;
		background-color: $white;
		padding: 34px 34px;
		overflow: auto;
		padding-bottom: 60px;

		.Text--xl {
			padding: 10px 0;
		}

		.Title {
			padding: 20px 0;
			min-width: 700px;
		}
	}

	&__placeHolderCalc {
		margin-top: 20px;
	}
	&__targetCard {
		margin-top: 20px;
	}

	@keyframes show {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}
