@import "~StyleVariables";

$pageTop: 82px;
$headerHeight: 50px;

.ListView {
	.inline-confirm-tab {
		b.fa-spinner {
			padding: 0 $space * 2;
			margin-right: 5px;
		}
	}
	margin-top: $space * 4;
	top: $pageTop;

	.Page {
		margin-bottom: $space * 4;
		min-height: 100%;
	}
	.Page_content {
		min-width: 0px;
	}

	&__header {
		position: sticky;
		top: $pageTop;
		background-color: $white;
		height: $headerHeight;
		align-items: stretch;
		z-index: 12;
		border-radius: $border-radius $border-radius 0 0;

		& > .Column {
			display: flex;
			align-items: center;

			&:last-of-type {
				justify-content: end;
			}
		}

		#up-list-filters #toggle-list-filters {
			height: $headerHeight;
		}

		#list-filters-wrap {
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
			top: $pageTop !important;
		}
		.ListView__add-btn-tooltip {
			display: none !important;
		}

		@media (max-width: 1200px) {
			.ListView__add-btn-tooltip {
				display: inline-block !important;
				& + .ListView__add-btn {
					display: none;
				}
			}
		}
	}

	&--no-sticky-header {
		.ListView__header {
			position: relative;
			top: 0;
		}
	}

	.TableHeader {
		position: sticky;
		top: $pageTop + $headerHeight;
		background-color: #fff;
		z-index: 11;
	}

	&--no-sticky-table-header {
		.TableHeader {
			position: static;
		}
	}

	&__footer {
		padding-bottom: 16px;
		gap: 4px;

		&--multiSelectVisible {
			padding-bottom: 80px;
		}

		.Column {
			margin: auto;
		}

		.Row {
			align-items: center;
		}

		&__size-selector {
			.ButtonSelect {
				margin-right: 4px;
				padding: 0px;
				overflow: hidden;
				border: 1px solid transparent;
				border-radius: 6px;

				transition: padding-right 0.3s;
				padding-right: 12px;

				&::after {
					position: relative;
					content: "<";
					transform: scaleY(1.5);
					transition: opacity 0.4s ease-in;

					top: 5px;
					left: 5px;
					color: $grey-9;
					font-family: Upsales;
					font-size: 12px;
					line-height: 16px;
				}

				.btn-light-grey {
					transition: all 0.35s ease-out;
					opacity: 0;
					width: 0px;
					border: 0px solid transparent;
					padding: 0px;
				}
				.btn-white {
					z-index: 1;
				}
			}
			.ButtonSelect:hover {
				padding-right: 0px;
				&::after {
					transition: opacity 0s;
					content: "";

					transform: translateX(-10px);
					opacity: 0;
				}

				.btn-light-grey {
					opacity: 1;
					width: unset;
					border: 1px solid transparent;
					padding: 0 12px;
				}
			}
			justify-content: right;
			white-space: nowrap;
			flex-wrap: nowrap;
			margin-right: 4px;
		}
	}

	.Page__error {
		margin: 16px 0;
	}

	// This will override .Page--position until REMOVE_FRAME is released
	&--remove-frame {
		margin-top: 0;
		left: 48px;
		right: 0;
		bottom: 0;

		.Page {
			border-radius: 0;
			box-shadow: none;
		}

		// This could be implemented outside of flag but i do not want to risk it
		@media (min-width: 996px) {
			&.ListView--filters-visible {
				right: $up-filters-width;

				// Minimize the 'create' button when filter sidebar is open
				& .ListView__add-btn-tooltip {
					display: inline-block !important;
					& + .ListView__add-btn {
						display: none;
					}
				}

				#list-filters-wrap {
					box-shadow: none !important;
				}
			}
		}
	}

	&--fullscreen {
		margin-top: 0;

		.ListView__header {
			top: 0;
		}

		.TableHeader {
			top: $headerHeight;
		}
	}

	.TableHeader__column {
		white-space: nowrap;
	}
}
