@mixin popover() {
	.popover {
		@include border-radius(0);
		z-index: $modal-z + 1;
		//border-top: 3px solid $bright-blue;
		border-radius: 3px;
		border: 0;

		&#mini-account {
			width: 300px;
		}

		#popover-loader {
			font-size: 20px;
			text-align: center;
			display: block;
			line-height: 30px;
		}

		.field {
			display: block;
			padding: 2px 0;

			&.no-info {
				text-align: center;
				color: $grey-10;
			}
		}

		.popover-title {
			background-color: transparent;
			font-size: 12px;
			text-transform: uppercase;
		}

		.popover-content {
			padding: 14px 16px;
		}
	}
}
