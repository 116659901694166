@import "~StyleVariables";

.ClientTooltip {
    &__tooltip {
        display: flex;
        flex-direction: column;
        margin-bottom: 2px;
        text-align: center;
        justify-content: center;
        max-width: 300px;
        margin: 2px -4px;
    }
    
    &__client-name {
        text-align: center;
    }

    &__quick-actions {
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        justify-content: center;
        margin-top: 8px;
    }
}