.RelatedTo {
	line-height: 35px;

	&__description {
		margin-left: 12px;
	}

	.Text {
		display: inline-block;
	}

	.Link {
		margin-left: 8px;

		.Text {
			margin-left: 4px;
			vertical-align: middle;
			max-width: 120px !important;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}
