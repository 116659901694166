.TopContacts {
	box-shadow: none;

	.TableHeader__column:last-child {
		text-align: center;
	}

	.Table {
		table-layout: fixed;
		.TableHeader__column,
		.TableRow .TableColumn {
			padding: 1px 1px 1px 15px;
			vertical-align: middle;

			&:first-child {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&:nth-child(2) {
				width: 70px;
			}
			&:last-child {
				width: 200px;
			}
		}
	}
}
