@import "~StyleVariables";

.NewMailTemplateRow {
	height: 50px;
	display: flex;
	align-items: center;
	background-color: $white;
	transition: all 0.5s ease;
	border-bottom: 1px solid $grey-4;
	visibility: visible;

	.DropDownMenu__content {
		z-index: 10;
	}

	&--hideRow {
		height: 0px;
		border-bottom: 0px;
		visibility: hidden;
		.Text,
		.Icon {
			visibility: hidden;
		}
	}

	&__saveButton {
		margin-right: 16px;
		cursor: pointer;
		color: $grey-10;
		&:hover {
			color: $grey-12;
		}
	}

	& > .Text:first-of-type {
		margin-left: 16px;
	}

	&__contentRow {
		border-radius: $border-radius;
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	&__leftColumn {
		display: flex;
		align-items: center;
		width: 400px;

		.Text:nth-of-type(1) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-left: 8px;
			padding: 4px;
		}
		.Text:nth-of-type(2) {
			opacity: 0;
		}

		&--hasChanged {
			.Text:nth-of-type(2) {
				transition: all 0.25s ease;
				opacity: 1;
				font-style: italic;
				margin-left: 2px;
			}
		}
	}

	&__rightColumn {
		display: flex;
		align-items: center;
		.Text {
			opacity: 0;
			visibility: hidden;
		}
		& > .Icon,
		& > .Text {
			margin: 0 16px;
			cursor: pointer;
			color: $grey-10;
			&:hover {
				color: $grey-12;
			}
		}
		&--hasChanged {
			.Text {
				transition: all 0.25s ease;
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
