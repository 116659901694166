@import "~StyleVariables";

.CreateCallClientContactSelect {
	position: relative;

	&__list {
		position: absolute;
		left: 0;
		right: 0;
		border-radius: $border-radius;
		box-shadow: 0 8px 16px rgba($black, 0.2);
		z-index: 1001;
		max-height: 300px;
		overflow: hidden;
		overflow-y: auto;
		background-color: $white;

		.Loader {
			margin: 4px auto;
		}
	}

	&__row {
		height: 60px;
		border-bottom: 1px solid $grey-4;
		transition: all ease-out 300ms;
		cursor: pointer;

		&--highlighted,
		&:hover {
			background-color: $grey-2;
		}

		& > .Column:last-child {
			overflow: hidden;
		}

		&__title {
			word-break: break-all;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100%;
			white-space: nowrap;
		}

		&__subtitle {
			display: block;
		}
	}

	&__no-results {
		height: 50px;
		line-height: 50px;
	}

	&__header {
		position: sticky;
		z-index: 1;
		top: 0;
	}

	&__selected {
		display: flex;
		border-radius: $border-radius;
		flex-direction: column;
		justify-content: center;

		&--normal {
			height: 60px;
		}

		&--inline {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			gap: 8px;
			align-items: center;
			height: 34px;
			border-bottom: 1px solid $grey-6;
			border-radius: 0px;
		}

		.Column {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.Row .Column:first-of-type {
			padding: 0 16px;
		}
	}
	&__houseIcon {
		width: 30px;
	}
	&__userInfo {
		overflow: hidden;
	}
}
