@import "~StyleVariables";

.PageSidebarItem {
	cursor: pointer;
	height: 30px;
	padding: 0 20px 0 25px;

	.Row {
		height: 100%;
	}

	.Column {
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	&:hover {
		background-color: $grey-3;
		transition: background-color ease-out 200ms;
	}
	&__selector {
		font-size: 8px;
		color: transparent;

		&--selected {
			color: $black;
		}
	}

	.Text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.AssistChip {
		margin-left: 8px;
	}

	&__dashboardIcon {
		width: 25px;
	}
}
