@import "~StyleVariables";

$pageTop: 82px;
$headerHeight: 50px;

.SalesboardColumn {
	display: flex;
	flex-direction: column;
	transition: all ease-out 300ms, margin-bottom ease-out 0ms;
	width: 0;
	margin-bottom: 0px;

	&--sticky {
		margin-bottom: 30px;
	}

	.Paginator {
		margin-bottom: 8px;
		.ButtonGroup {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.Paginator__Button {
				flex: 0;
			}
		}
	}

	&__header {
		position: sticky;
		top: $pageTop + $headerHeight;
		height: 80px;
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		border-left: 1px solid $grey-6;
		border-bottom: 1px solid $grey-6;
		border-top: 1px solid $grey-6;
		overflow: hidden;
		background-color: $white;
		z-index: 100;
		transition: all ease-out 300ms;

		&__inner-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 8px;
			z-index: 1;

			.Loader {
				position: absolute;
				background-color: white;
				border-radius: 50%;
			}
		}

		&__stage-name,
		&__sum {
			position: relative;
			z-index: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			transition: all ease-out 300ms;
		}

		&__overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba($green, 0.5);
			transition: all ease-out 300ms;
			opacity: 1;
		}

		&__item-count {
			position: absolute;
			bottom: 4px;
			right: 12px;
			transition: all ease-out 300ms;
			opacity: 1;
		}

		&__help {
			top: 4px;
			right: 12px;
			color: $white;
			position: absolute;
		}

		&--sticky {
			height: 50px;
			background-image: none !important;

			&:after {
				content: "";
				height: 1px;
				width: 100%;
				background-color: transparent;
				box-shadow: 0 2px 4px rgba($black, 0.5);
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.SalesboardColumn__header__overlay {
				opacity: 0;
			}
			.SalesboardColumn__header__help {
				color: $grey-10;
				font-size: 12px;
			}
			.SalesboardColumn__header__item-count {
				opacity: 0;
			}
		}
	}

	&__data {
		margin: 2px;
		padding: 2px;
		flex: 1;
		display: flex;
		flex-direction: column;
		transition-property: background border;
		transition-timing-function: ease-out;
		transition-duration: 300ms;
		border-radius: 8px;

		&--isDragging {
			background: $super-duper-light-green;
		}

		&--isOver {
			background: $white;
		}

		&--fromStage {
			background: $grey-2;
		}

		.SalesboardCard {
			border-radius: $border-radius;
			margin-bottom: 4px;
		}
	}

	&__StageText {
		margin: auto;
	}

	&:first-child .SalesboardColumn__header {
		border-left: none;
	}
}
