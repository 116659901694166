.AssignModalActivityInfo {
	.Input {
		margin-bottom: 15px;
	}

	&__Prio {
		margin-left: 15px;

		> .Row {
			margin-top: 10px;
		}
	}
	.select2-container {
		max-width: initial !important;
	}
}
