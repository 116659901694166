@import "~StyleVariables";

.PriceListDrawer {
	--header-height: 50px;

	height: 100%;
	width: 900px;

	visibility: visible;

	&__wrapper {
		height: 100%;
		position: relative;
		background-color: $white;
	}

	&__save-btn,
	&__cancel-btn {
		height: 100%;
		width: 100px;
		border-radius: 0;
	}

	&__content {
		height: calc(100% - var(--header-height));
		overflow-y: auto;
		overflow-x: hidden;
		padding: 40px;

		.Row {
			justify-content: space-between;
			align-items: center;
			position: relative;
		}

		.AssistChip {
			position: absolute;
			right: 4px;
		}

		.Input {
			width: 100%;
		}
	}

	&__codeInput {
		width: 33%;
	}

	&__settings > div {
		margin-bottom: 20px;
	}

	&__toggleRow {
		display: flex;
	}

	&__textWithHelp {
		margin-left: 10px;
		margin-right: 5px;
	}

	&__actions {
		margin-top: 40px;
	}

	.Progress {
		height: 16px;
		width: 16px;
	}

	.InlineAction__wrapper--left {
		left: 0;
	}
}
