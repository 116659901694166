.JourneyStatuses__loader {
	text-align: center;
	margin-top: 20px;
}

.JourneyStatuses__loader .Loader {
	display: inline-block;
}

.WrappedPortedJourneyStatuses#admin {
	#admin-root {
		left: 0;
		.admin-root-wrapper {
			left: 0;
		}
	}
}