@import "../../../styles/variables";

.AddOrDeactivate {
	&__loader {
		margin-top: 150px;
		align-self: center;
	}
	.ModalHeader__content {
		height: 48px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
	&__logout-btn {
		color: "$grey-10";
	}

	&__content > * {
		padding-top: 20px;
	}
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 20%;

		.Text {
			font-size: 18px;
			line-height: 1.6;
		}
		.Text--xl {
			font-size: 38px;
			font-weight: $boldFontWeight;
		}
	}
	&__button_block {
		border-radius: 4px;
		margin: 18px;
		height: 172px;
		width: auto;
		position: relative;
		&:hover {
			background-color: $grey-3;
			border-color: $bright-blue;
			cursor: pointer;
		}
	}
	&__button_block > * {
		margin: 5px;
	}
	&__wrapper {
		position: absolute;
		margin: 15px;
		left: 0;
		right: 0;
		text-align: center;
	}
}
