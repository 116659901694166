.SessionExpireWarning {
	z-index: 10001 !important;
	&__inner {
		width: 400px;
		margin: 60px auto 0 auto;
	}

	.Headline,
	.Text {
		text-align: center;
	}

	&__error .Text {
		text-align: left;
	}

	body {
		background-color: #fff;
		text-align: center;
		margin-top: 5%;
	}

	/*** LAUNCH ANIMATION ***/
	svg {
		margin: 0 auto 20px auto;
		display: block;
	}

	svg g #lock-path {
		animation: lock-path-animation 1s ease-out -2s infinite alternate forwards;
		animation-delay: 3.2s;
	}

	svg #lock-animation {
		animation: lock-path-animation 1s ease-out -2s infinite alternate forwards;
		animation-delay: 3.2s;
	}

	svg g #check-icon {
		opacity: 0;
		animation: check-icon 1s ease-out -2s infinite alternate forwards;
		transform: translate(-30px, -8px);
		animation-delay: 2s;
		fill: #fff;
	}

	@keyframes lock-path-animation {
		0% {
			transform: translate(0, 3px);
		}
		50% {
			transform: translate(0, 3px);
		}
		75% {
			transform: translate(0, 0px);
		}
		100% {
			transform: translate(0, 10px);
		}
	}
	@keyframes lock-animation {
		0% {
			transform: translate(0px, 0px);
		}
		50% {
			transform: translate(0px, -6px);
		}
		100% {
			transform: translate(0px, 0px);
		}
	}
	@keyframes check-icon {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
}
