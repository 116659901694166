@import "~StyleVariables";

.NoteRow {
	min-height: 36px;
	width: 100%;
	align-items: baseline;
	padding-bottom: 2px;
	border-top: 1px solid $grey-5;

	&__icon-col {
		display: flex;
		justify-content: flex-end;
	}
	&__edit-col {
		display: flex;
		transition: all ease-out 300ms;
		align-items: center;
		justify-content: center;
		.Icon {
			visibility: hidden;
			opacity: 0;
		}
		.inline-confirm-modal,
		.inline-confirm-modal-hidden {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 38px;
			width: 38px;
			position: relative;
		}
		.Icon:hover {
			color: $grey-11;
		}
	}

	&__textarea {
		.Textarea__input,
		.DraftEditor-root {
			line-height: 24px;
			padding-top: 5px;
			padding-bottom: 5px;
			padding-left: 12px;
		}

		&--notFullsize {
			.Textarea__input,
			.DraftEditor-root {
				max-height: 100px !important;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}

	&__showMore {
		display: flex;
		justify-content: center;
		padding: 4px 0 4px 0px;

		.Link,
		.Icon {
			display: inline;
			cursor: pointer;
		}
	}

	&__text-col {
		position: relative;
		white-space: pre-wrap;

		.Textarea--disabled .Textarea__input {
			background-color: transparent;
			font-style: normal;
		}

		.Textarea__input {
			overflow: hidden;
			font-size: 12px;
			color: $black;
		}
		> .Text {
			font-size: 14px;
			position: absolute;
			top: 0;
			line-height: 24px;
		}
	}

	@keyframes tooltip-bounce {
		0% {
			opacity: 0;
			transform: translateY(16px);
		}
		20% {
			opacity: 1;
			transform: translateY(16px);
		}
		100% {
			transform: translateY(0px);
		}
	}

	.FakeTooltip {
		margin-top: 8px;
		z-index: 2;
	}

	&:hover {
		cursor: pointer;

		.NoteRow__edit-col {
			.Icon {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
