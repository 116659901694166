@import "~StyleVariables";

$height: 50px;

.ProspectingDrawerHeader {
	position: sticky;
	top: 0;
	z-index: 3000;
	height: $height;
	display: flex;
	background-color: white;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.ProspectingDrawerHeader .Tabs {
	white-space: nowrap;
}

.ProspectingDrawerHeader .Tab {
	.ColorSwitcher {
		display: inline-block;
		margin-right: 5px;
	}

	.Text {
		display: inline-block;
	}
}

.ProspectingDrawerHeader__title {
	max-width: 50vw;
	overflow: hidden;
	display: flex;
	text-overflow: ellipsis;
	align-items: center;
	padding: 5px 10px;
}

.ProspectingDrawerHeader__controls {
	white-space: nowrap;
	margin-left: auto;

	.Loader {
		display: inline-block;
		margin-right: 26px;
	}
}
