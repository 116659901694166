@import "~StyleVariables";

.AdvancedSearchFilterInput {
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	padding-bottom: ($space * 3);

	&--half {
		width: 50%;
	}
	
	&--vertical.AdvancedSearchFilterInput--half:nth-child(even) {
		padding-left: ($space * 3);
	}

	&--vertical {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		// padding-top: 0;
		// padding-bottom: ($space * 3);
	}

	&--vertical &__input {
		width: 100%;
		padding-left: 0;
	}
	
	&__title {
		text-align: end;
		padding-left: ($space * 3);
	}
	
	&--vertical &__title {
		padding-left: 0;
	}

	&__active {
		background-color: $green;
		border-radius: 6px;
		height: 6px;
		width: 6px;
		margin-right: $space;
		display: inline-flex;
		margin-bottom: 2px;
	}

	&__input {
		width: 70%;
		padding-left: ($space * 3);
		flex-shrink: 0;

		.ButtonSelect {
			width: 100%;
		}
	}

	&__range {
		display: flex;

		.NumberInput {
			width: 100%;
			
			.Input {
				width: 100%;
			}
		}
	}
	
	&__dateRange {
		display: flex;

		.TimeInput,
		.DateInput {
			width: 100%;
		}
	}

	&__dateRange,
	&__range {
		&__start {
			.Input__input {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		&__end {
			.Input__input {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}

	&__address {
		display: flex;
		align-items: center;

		width: 70%;
		padding-left: ($space * 3);

		.Select {
			width: stretch;
		}

		&__actions {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.Button {
				width: 100%;
				text-align: left;
			}
		}
	}
}
