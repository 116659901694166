.SalesGraph {
	height: 280px;

	display: flex;
	justify-content: center;
	align-items: center;

	.ReportcenterWidget {
		width: 100%;
	}
}
