#mad-board,
#mad-stats {
	@include box-shadow(0 2px 5px rgba($black, 0.1));
	background-color: $white;
	@include border-radius(3px);
	overflow: hidden;

	.header-title {
		height: 100%;
	}
}

#ma-dashboard {
	transition: margin-right ease-in 100ms;
	.up-header {
		right: initial !important;
	}
}

.filters-open {
	#ma-dashboard {
		margin-right: 315px;
	}

	#mad-board .mad-board-sticky-header {
		right: 330px;
	}
}

#mad-stats {
	.up-header .period-btns {
		position: absolute;
		top: 8px;
		width: 590px;
		left: 50%;
		margin-left: -295px;
	}

	.up-header .period-btns button {
		@include transition(background-color ease 200ms);
	}

	.custom-date-input {
		display: inline-block;
		width: 80%;
		vertical-align: top;

		input {
			display: inline-block;
			width: 50%;
			vertical-align: top;
			height: 30px;
			border-radius: 0;
			border-left: none;

			&:last-child {
				border-radius: 0 3px 3px 0;
			}
		}
	}

	.mad-widgets {
		overflow: hidden;
	}

	.mad-widget {
		position: relative;
		width: 25%;
		display: inline-block;
		vertical-align: top;
		height: 117px;
		border-right: 1px solid $grey-4;
		background-color: $white;
		padding: 15px;
		@include transition(background-color ease 200ms);

		&:last-child {
			border-right: none;
		}

		&.linked:hover {
			background-color: $grey-1;
			cursor: pointer;

			.widget-link {
				bottom: 0;
				@include opacity(1);
				visibility: visible;
			}

			.sub-stats {
				@include opacity(0);
				visibility: hidden;
			}
		}

		.widget-icon {
			position: absolute;
			top: 18px;
			display: inline-block;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 30px;
			color: $blue;
		}

		.widget-link {
			position: absolute;
			bottom: -40px;
			left: 0;
			right: 0;
			height: 40px;
			line-height: 40px;
			font-size: 12px;
			color: $bright-blue;
			@include transition(all ease 200ms);
			visibility: hidden;
			@include opacity(0);
			text-align: center;
		}

		.value {
			position: relative;
			font-size: 20px;
			color: $blue;
			padding-left: 40px;
			line-height: 22px;

			.change {
				font-size: 12px;
				position: absolute;
				display: inline-block;
				top: 0px;
				line-height: 14px;
				margin-left: 5px;
			}
		}

		.subtitle {
			color: $subtitle-color;
			padding-left: 40px;
			font-size: 12px;
			line-height: 14px;
		}

		.sub-stats {
			border-top: 2px solid $grey-6;
			margin-top: 20px;
			padding-top: 10px;
			visibility: visible;
			@include transition(all ease 200ms);
			@include opacity(1);

			.sub-stat {
				display: inline-block;
				font-size: 12px;
				color: $blue;
			}
		}
	}
}

#mad-board {
	margin-top: 20px;

	&.dragging {
		.droppable-text {
			top: 100%;
			opacity: 1;
			visibility: visible;
		}

		.mad-board-col.droppable:before {
			opacity: 1;
			visibility: visible;
		}

		.mad-board-card {
			visibility: hidden;
			opacity: 0;
		}
	}

	.up-header .mad-board-header-tools {
		position: absolute;
		top: -2px;
		right: 10px;
	}

	.header-title .upsales-loader {
		width: 30px;
		height: 30px;
		display: inline-block;
		top: 8px;
		position: relative;
	}

	.up-header .mad-info {
		display: inline-block;
		margin-right: 10px;
	}

	.mad-board-sticky-header {
		position: fixed;
		top: $main-top;
		left: $sidebar-width;
		right: $padding;
		height: 35px;
		z-index: 1003;
		display: none;

		&.visible {
			display: block;
		}
	}

	.mad-board-sticky-header-col {
		display: inline-block;
	}

	.mad-board-table {
		table-layout: fixed;
		width: 100%;
	}

	.droppable-text {
		position: absolute;
		top: 0;
		line-height: 20px;
		background-color: inherit;
		padding: 5px 10px;
		overflow: hidden;
		width: 150px;
		text-align: center;
		margin-left: -75px;
		left: 50%;
		font-size: 12px;
		border-radius: 0 0 3px 3px;
		z-index: 1003;
		opacity: 0;
		visibility: hidden;
		transition: all ease 200ms;
	}
	.mad-board-col-header .droppable-text {
		z-index: 1002;
	}

	.mad-board-sticky-header-col,
	.mad-board-col-header {
		position: relative;
		height: 35px;
		line-height: 35px;
		font-size: 12px;
		text-align: center;
		color: $white;
		background-color: $dark-purple;
		padding: 0;
		text-align: right;
		padding-right: 10px;
		vertical-align: top;

		.number {
			position: absolute;
			top: 0;
			left: 10px;
			font-size: 18px;
		}
	}

	.mad-board-col {
		background-color: $grey-2;
		padding: 0;
		vertical-align: top;
		position: relative;

		&.droppable {
			&:before {
				opacity: 0;
				visibility: hidden;
				content: "";
				position: absolute;
				top: 10px;
				bottom: 10px;
				left: 10px;
				right: 10px;
				background-color: $white;
				box-shadow: 0 1px 7px rgba($black, 0.4);
				transition: all ease 200ms;
				z-index: 1000;
				border: 1px solid $white;
				border-radius: 3px;
			}

			&.over:before {
				background-color: rgba($bright-blue, 0.2);
				border-color: $bright-blue;
			}
		}
	}

	.mad-board-col:nth-of-type(odd) {
		background-color: $grey-1;
	}

	.mad-board-col-inner {
		min-height: 200px;
		padding: 5px;
		padding-bottom: 10px;
	}

	#drag-card {
		position: fixed;
		height: 60px;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		background-color: $grey-1;
		line-height: 60px;
		font-size: 14px;
		text-align: center;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $grey-10;
		z-index: 1010;
	}

	.mad-board-card {
		position: relative;
		background-color: $white;
		@include box-shadow(0 1px 2px 0 rgba($black, 0.2));
		padding: 5px;
		border-bottom: 1px solid $grey-8;
		height: 60px;
		@include transition(all ease 200ms);
		overflow: hidden;
		z-index: 1001;

		&:hover {
			background-color: $grey-1;

			.add-external {
				margin-right: 0;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		.card-name {
			color: $black;
			font-size: 12px;
			line-height: 14px;
			height: 14px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-bottom: 2px;

			&.external {
				font-style: italic;
			}

			a {
				color: $black;
				font-weight: $boldFontWeight;
			}
		}

		.add-external {
			background-color: rgba($bright-blue, 0.2);
			border: none;
			@include border-radius(3px);
			color: $bright-blue;
			@include transition(all ease 200ms);
			height: 15px;
			line-height: 15px;
			font-size: 10px;
			padding: 0 5px;
			vertical-align: top;
			margin-right: 5px;
			float: right;
			margin-right: -100%;

			&:hover:not([disabled]) {
				background-color: rgba($bright-blue, 0.3);
			}

			&[disabled] {
				@include opacity(0.4);
				cursor: default;
			}
		}

		.score,
		.assigned {
			position: absolute;
			bottom: 5px;
		}

		.score {
			left: 5px;
			color: $purple;
			height: 16px;
			line-height: 16px;
			font-size: 12px;
			@include border-radius(1px);
		}

		.assigned {
			right: 5px;

			a {
				height: 16px;
				line-height: 16px;
				font-size: 12px;
				cursor: pointer;
			}

			.avatar-wrap {
				float: right;
				width: 20px;
				height: 20px;
			}
		}
	}

	.mad-board-column-showing {
		text-align: center;
		font-size: 12px;
		height: 25px;
		line-height: 25px;
	}

	.mad-board-load-more {
		border: none;
		background-color: $white;
		display: block;
		color: rgba($bright-blue, 0.8);
		text-align: center;
		height: 40px;
		line-height: 40px;
		width: 100%;
		padding: 0;
		@include transition(all ease 200ms);
		border-top: 1px solid $grey-4;

		&:hover:not([disabled]) {
			background-color: $grey-1;
			color: $bright-blue;
		}

		&[disabled] {
			@include opacity(0.4);
			cursor: default;
		}

		.upsales-loader {
			display: inline-block;
			width: 30px;
			height: 30px;
			margin-top: 5px;
		}
	}
}

#client-opportunity-to-order-root {
	.upsales-loader {
		width: 100px;
		height: 100px;
		margin: 30px auto;
	}

	label {
		margin-top: 15px;
		font-weight: normal;
	}
}
