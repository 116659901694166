@import "~StyleVariables";

.TodoListTableRowActionButton {
	&__address {
		margin-top: 3px;
	}
	&__content-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		vertical-align: middle;
	}
}
