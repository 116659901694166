@import "~StyleVariables";

.RelationSelect {
	.Loader {
		width: 100%;
	}

	.Label {
		font-weight: $boldFontWeight;
	}
	& > .Card {
		border-radius: $border-radius;
	}

	&__input:not(.Animate--visible) {
		height: 0px;
	}

	&__notFound {
		height: 30px;
	}

	&__toggleRow {
		margin-bottom: 14px;
		cursor: pointer;

		.Column {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&__content {
		margin-top: $space * 2;
		&--openedFromDropdown {
			& > .Input,
			& > .Label {
				padding-left: 12px;
				padding-right: 16px;
			}

			& > .Input {
				.Input__icon {
					left: 12px;
				}
			}
		}
	}

	&__dropdown {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1001;
		box-shadow: 0 8px 16px rgba($black, 0.2);
		border-radius: 0 0 $border-radius $border-radius;

		.Button {
			border-radius: 0 0 $border-radius $border-radius;
		}
		.btn-green {
			color: $super-light-green !important;
		}
	}
	&__card {
		max-height: 250px;
		overflow-y: auto;
	}
	&__card .Label {
		padding: 0 12px;
		margin-bottom: 0;
		height: 28px;
		line-height: 28px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__card .Label,
	&__relation {
		border-bottom: 1px solid $grey-4;
	}

	&__relation {
		position: relative;
		height: 50px;
		cursor: pointer;
		transition: all ease-out 200ms;

		&:hover {
			background-color: $grey-1;
		}

		.Column {
			display: flex;
			flex-direction: column;
			justify-content: center;
			overflow: hidden;

			&:first-of-type {
				padding-left: 12px;
			}
		}

		.Text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
