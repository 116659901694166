@import "~StyleVariables";
.ReportcenterTVPlay {
	height: 100%; 

	&__loader {
		display: flex;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}