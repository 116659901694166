@import "variables";
@import "mixins";

.react-select {
	position: relative;
	text-align: right;

	.react-select-dropdown {
		position: absolute;
		right: 0;
		width: 150px;
		z-index: 1000;
		padding: 0;
		margin: -2px 0 0;
		list-style: none;
		font-size: 14px;
		text-align: left;
		background-color: $white;
		border: 1px solid rgba($black, 0.15);
		border-radius: 4px;
		box-shadow: 0 6px 12px rgba($black, 0.175);
		background-clip: padding-box;

		.react-select-dropdown-row {
			height: 24px;
			line-height: 24px;
			cursor: default;
			padding: 0 10px 0 10px;

			&:hover {
				background-color: rgba($black, 0.1);
			}
		}
	}

	.react-select-button {
		outline: 0;
		min-width: 150px;
		color: rgba($black, 0.5);

		&.has-value {
			color: $bright-blue;
		}

		&.has-value:hover {
			color: $white;
		}
	}
}

.up-list-view {
	.dropdown {
		display: inline-block;
	}

	.header-info-content {
		display: inline-block;
		padding: 8px 0;
		line-height: 15px;
		font-size: 12px;
		vertical-align: middle;
	}

	.add-external-client {
		color: $bright-blue;
		border-color: rgba($bright-blue, 0.1);
		background-color: rgba($bright-blue, 0.1);
		@include border-radius($border-radius);
		padding: 5px;
		margin-left: 6px;
		font-size: 11px;
		&:hover {
			background-color: rgba($bright-blue, 0.3);
			text-decoration: none;
		}
	}
	.lead-contact {
		.lead-contact-account {
			font-size: 10px;
			text-transform: uppercase;
			a {
				color: $grey-10;
			}
		}
	}

	.company-not-in-upsales-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;

		.no-company-name-wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			margin-right: 9px;

			.no-company-name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				vertical-align: middle;
			}

			.not-in-upsales {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				color: $grey-11;
			}
		}

		.add-external-client {
			flex: 1 1 auto;
			align-items: center;
			margin: 5px 0;
			max-width: 60px;

			span {
				white-space: nowrap;
			}
		}
	}

	.company-in-upsales-wrapper,
	.account-subtitle {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		vertical-align: middle;
	}

	.assigned-salesman {
		.assigned-salesman-icon {
			margin: 0 18px 0 13px;
			color: $grey-10;
		}

		.no-assigned-sales-link {
			display: inline-block;
			line-height: 35px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			vertical-align: middle;
		}
	}

	@media only screen and (max-width: 1260px) {
		.lead-contact-account,
		.overflow-controller {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 120px;
		}

		.overflow-controller {
			padding: 0 10px;
		}

		.company-not-in-upsales-wrapper {
			.no-company-name-wrapper {
				max-width: 80px;
			}

			.add-external-client {
				max-width: 60px;
				flex: 1 1 auto;

				span {
					white-space: nowrap;
				}
			}
		}

		.company-in-upsales-wrapper,
		.account-subtitle {
			max-width: 150px;
		}

		.assigned-salesman {
			.generic-avatar {
				display: none;
			}
		}

		.assigned-salesman {
			.assigned-salesman-icon {
				margin: 0 5px 0 0;
			}
		}
	}
}

.has-up-list-filters {
	&.filters-open {
		@media only screen and (max-width: 1450px) {
			// if adjusted, plz adjust in ui/app/upsales/domain/appointment/controllers/listAppointment.js
			.EasyBookingLinkDropdown__label .DropDownButton {
				display: none;
			}
		}
	}

	&:not(.filters-open) {
		@media only screen and (max-width: 1385px) {
			// if adjusted, plz adjust in ui/app/upsales/domain/appointment/controllers/listAppointment.js
			.EasyBookingLinkDropdown__label .DropDownButton {
				display: none;
			}
		}
	}
}
