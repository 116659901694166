@import "~StyleVariables";

.InlineAction {
	&__wrapper {
		min-width: 188px;
		position: absolute;
		top: -38px;
		box-shadow: 1px 0 0 0 #e4eaf0, 0 0 6px 4px rgba(30, 37, 46, 0.2);
		background-color: $white;
		z-index: 8000;
		text-align: center;
		padding: 14px 14px 14px 0;
		cursor: default;

		.Text {
			margin-bottom: 5px;
			text-align: left;
		}
	}

	&__btn-wrap {
		height: 46px;
		display: flex;
		align-items: center;
	}
}

.InlineAction__wrapper--top {
	top: 0;
	box-shadow: 0px 4px 8px rgba($black, 0.2);
	padding: 0 10px 10px 0px;

	.Text {
		margin-top: 5px;
	}
}

.InlineAction__wrapper--right {
	right: 0;
}

.InlineAction__wrapper--bottom {
	bottom: 0;
}

.InlineAction__wrapper--left {
	left: 0;
}