.cke_editable {
	background-color: #fff;
}

.cke_editable a > img {
	outline: none;
}

.cke_editable span[lang] {
	font-style: inherit;
}

a.cke_button:hover,
a.cke_button:focus,
a.cke_button:active {
	padding: 4px 6px !important;
}

a.cke_button_expandable:hover,
a.cke_button_expandable:focus,
a.cke_button_expandable:active {
	padding: 4px 5px !important;
}

a.cke_button:not(.cke_button_on) {
	border: 1px transparent solid !important;
}

a.cke_button_on {
	padding: 4px 6px !important;
}

a.cke_button_on.cke_button_expandable {
	padding: 4px 5px !important;
}
