.Summary {
	display: flex;
	gap: 28px;
	min-width: 280px;
	justify-content: space-between;

	> div {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}
