@import "~StyleVariables";

.ClientCardSidebar__ContactInfo {
	flex-direction: column;

	.Icon,
	.Sidebar__imageIcon {
		width: 12px;
	}

	.Link {
		color: $grey-11;
		&:hover {
			color: $bright-blue;
			text-decoration: none;
		}
	}
    &__textRow {
        padding-bottom: 3px;
		padding-top: 3px;
		padding-right: 10px;
    }

	&__fields {
		gap: 8px;
	}
}
