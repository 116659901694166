#invite-page {
	#left-area,
	#main-area {
		position: fixed;
		top: 0;
		z-index: 10000;
	}

	#left-area {
		left: 0;
		bottom: 0;
		width: 400px;
		background-color: $bright-blue;

		#up-logo-wrapper {
			fill: $white;
			height: 50px;
			margin: 10px;
			z-index: 1000;
			position: relative;
		}

		#sidebar-background {
			position: absolute;
			z-index: 1000;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	#main-area {
		right: 0;
		bottom: 0;
		left: 400px;
		background-color: $grey-1;
	}

	#invite-inner {
		margin: 50px auto;
		width: 400px;

		h2 {
			font-size: 20px;
			display: block;
			margin-bottom: 10px;
		}

		h3 {
			margin: 0;
			font-weight: $regularFontWeight;
			font-size: 14px;
			margin-bottom: 10px;
			margin-top: 30px;
		}

		#invite-info {
			font-size: 12px;
			color: $grey-10;
			margin-bottom: 20px;
		}

		.form-group {
			margin-bottom: 20px;
		}

		.floating-label-input {
			font-size: 14px;

			label {
				font-size: 0.8em;
			}
			input {
				background-image: none !important;
				font-size: 0.9em;
			}
		}

		button[type="submit"] {
			font-size: 14px;
			margin: 30px 0 10px 0;
		}

		.sub-link {
			font-size: 12px;
		}
	}

	@media (max-width: 1024px) {
		#left-area {
			width: 300px;
		}
		#main-area {
			left: 300px;
		}
	}
}
