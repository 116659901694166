@import "~StyleVariables";

.Salesboard {
	margin-top: 16px;
	top: 82px;

	& > .Page {
		transition: margin-right 0.4s;
		margin-bottom: 16px;
		background-color: $grey-2;
		display: flex;
		flex-direction: column;
		min-height: 100%;
		margin-right: 0px;
	}

	&--quota-charts-active > .Page {
		margin-right: 210px;
	}

	&--has-error {
		min-height: 120px;
	}

	&__no-data {
		$width: 400px;
		position: absolute;
		top: 200px;
		left: 50%;
		margin-left: -($width/2);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: $width;
	}

	&--sticky-header .SalesboardHeader {
		border-radius: 0;
	}

	&__loader {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -50px;
		margin-left: -50px;
	}

	&__quota-charts {
		overflow-x: hidden;
		transition: width 0.4s;
		width: 0px;	

		&--active {
			width: 230px;
		}
	}


	// This will override .Page--position until REMOVE_FRAME is released
	&--remove-frame:is(&--quota-charts-active) > .Page {
		margin-right: 202px;
	}

	&--remove-frame {
		margin-top: 0;
		left: 48px;
		right: 0;
		bottom: 0;

		.Page {
			border-radius: 0;
			box-shadow: none;
			min-height: 100%;
		}

		// This could be implemented outside of flag but i do not want to risk it
		@media (min-width: 1440px) {
			&.ListView--filters-visible {
				right: $up-filters-width + 12px;

				#list-filters-wrap {
					box-shadow: none !important;
				}
			}
		}
	}	
}
