.text-bright-blue {
	color: $bright-blue;
}
.text-bright-blue-i {
	color: $bright-blue !important;
}

.text-blue {
	color: $blue;
}
.text-blue-i {
	color: $blue !important;
}

.text-dark-grey {
	color: $grey-11;
}
.text-dark-grey-i {
	color: $grey-11 !important;
}

.text-grey {
	color: $grey-8;
}
.text-grey-i {
	color: $grey-8 !important;
}

.text-placeholder {
	color: $grey-9;
}
.text-placeholder-i {
	color: $grey-9 !important;
}

.text-red {
	color: $red;
}
.text-red-i {
	color: $red !important;
}

.text-orange {
	color: $orange;
}

.text-rose-4 {
	color: $rose-4;
}
.text-orange-i {
	color: $orange !important;
}

.text-dark-orange {
	color: $dark-orange;
}
.text-dark-orange-i {
	color: $dark-orange !important;
}

.text-brown-orange {
	color: $brown;
}
.text-brown-orange-i {
	color: $brown !important;
}

.text-green {
	color: $bright-green;
}
.text-green-i {
	color: $bright-green !important;
}

.text-super-light-green {
	color: $super-light-green;
}
.text-super-light-green-i {
	color: $super-light-green !important;
}

.text-upsales-grey {
	color: $grey-10;
}
.text-upsales-grey-i {
	color: $grey-10 !important;
}

.text-white {
	color: $white;
}
.text-white-i {
	color: $white !important;
}

.text-black {
	color: $black;
}
.text-black-i {
	color: $black !important;
}

.text-turcose {
	color: $turcose;
}
.text-turcose-i {
	color: $turcose !important;
}

.text-purple {
	color: $purple;
}

.text-purple-i {
	color: $purple !important;
}

.text-subtitle {
	text-transform: uppercase;
	color: $grey-8;
}

.text-sm {
	font-size: 12px;
}

.text-xs {
	font-size: 10px;
}

.text-no-wrap {
	white-space: nowrap;
}

label {
	&.normal {
		font-size: inherit;
		font-weight: normal;
	}
}
