@import "~StyleVariables";

.SubscriptionScheduling {
	.Text {
		display: flex;
		align-items: center;
		line-height: 24px;
	}

	&__mode-select {
		display: inline;
	}

	&__mode-select.Select--disabled {
		.Link {
			cursor: default;
			color: $grey-10
		}

		.Link:hover {
			text-decoration: none;
			color: $grey-10
		}
	}

	&__title-container {
		transition: background-color ease 200ms;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 16px;
		width: 100%;

		.SubscriptionGroupScheduling {
			margin: 16px 0;
		}

		&--grey-background {
			background-color: $grey-1;
		}
	}

	&__title-content {
		width: 950px;
	}

	&__title {
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		flex-direction: row;

		.Link {
			margin-left: 4px;
		}

		.Title {
			padding: 0 4px;
		}

		.BouncingTooltip__tooltip {
			z-index: 1000;
		}
	}
}
