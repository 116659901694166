.ProjectPlanOrderHandler {
	.Card {
		border-radius: 4px;
	}

	.CardStarted__Row {
		.Block {
			width: 25%;
		}
	}

	/** 
	* @todo  @upsales/components 2.12.4
	* STYLING: Had to add a width to the select to make it look good and not changing size depening on the content.
	* @link https://github.com/upsales/ui-components/issues/503
	*/
	.Select {
		width: 212px;
	}

	// /**
	// * @todo  @upsales/components 2.14.0
	// * STYLING: Had to to add a max-width to the card header title with ellipsis because a too long title would break the layout.
	// * @link https://github.com/upsales/ui-components/issues/507
	// */
	.CardHeader {
		.Title {
			max-width: 600px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
