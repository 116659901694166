@import "~StyleVariables";

.CopyButton {
	width: 65px;
	bottom: 10px;

	&--absolute {
		position: absolute;
		right: 10px;
		z-index: 2;
	}
}
