@import "~StyleVariables";

.UpsellWidgetCompanyCard {
	transition: background-color 0.2s ease-in;
	border-top: 1px solid $grey-4;
	border-bottom: 1px solid $grey-4;
	cursor: pointer;

	&:hover {
		background-color: $grey-1;
	}

	&__upsellDot {
		height: 12px;
		width: 12px;
		border-radius: 50%;
	}

	&__bigNumbers {
		font-size: 22px;
	}
}
