@import "~StyleVariables";

.NewsItem {
	&__text {
		flex: 1;
		display: flex;
		gap: 8px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px 20px 0px 20px;

		.content-text {
			cursor: pointer;

			&:hover {
				color: $bright-blue;
			}

			max-width: 480px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			vertical-align: middle;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	&__text-container {
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;

		.NewsItem__title {
			cursor: pointer;
			max-width: 480px;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				color: $bright-blue;
			}
		}
	}

	&__image {
		padding: 20px 20px 0px 20px;
		img {
			max-width: 300px;
			max-height: 120px;
			overflow: hidden;
			border-radius: $border-radius;
		}
	}
}
