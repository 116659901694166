.SidebarList {
    height: 100%;

    &--scrollable {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .tippy-popper {
        max-width: 220px !important;
    }
}