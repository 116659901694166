@import "~StyleVariables";

.DataBox {

    padding: 8px 16px;
    margin-right: 16px;
    width: -webkit-fill-available;
    border: 1px solid $grey-4;
    border-radius: 2px;
    height: fit-content;
    min-height: 112px;
    max-width: 218px;

    &:hover {
        border-color: $bright-blue;
        box-shadow: 0 2px 4px 0 rgba($black, 0.2);
    }

    
    &__value-wrapper {
        display: flex;
        align-items: center;
        padding: 4px 0px;

        .Icon {
            margin-right: 4px;
            font-size: 18px;
        }
    }

    &__subtitle {
        display: inline-block;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: row;
    }
}