@import "~StyleVariables";

.AddOption {
	$side-padding: 15px;

	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-top: 1px solid $grey-2;
	position: relative;

	&__addOptionButton {
		display: flex;
		padding-left: 15px;
		transition: all ease-in-out 0.25s;

		&--hidden {
			opacity: 0;
		}
	}

	&__editContainer {
		width: 100%;
		display: flex;
		position: absolute;
		align-items: center;
		justify-content: space-between;
		transform: translateY(-150%);
		padding-left: $side-padding;
		transition: transform ease-in-out 0.3s, opacity ease-in-out 0.1s;

		&--visible {
			transform: translateY(0);
			opacity: 1;
		}
	}

	&__inputContainer,
	&__buttonContainer {
		display: flex;
		align-items: center;
	}

	&__optionInput {
		width: 100px
	}

	&__saveOption {
		display: flex;
	}

	&__inputErrorContainer {
		height: 0;
		opacity: 0;
		display: flex;
		align-items: center;
		padding-left: $side-padding;
		transition: all ease-in-out 0.2s;

		&--visible {
			height: 34px;
			opacity: 1;
		}
	}
}
