@import "~StyleVariables";

.BillingAddonCard {
	place-self: stretch;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	transition: all ease-in-out 0.2s;

	&:hover {
		box-shadow: 0 4px 8px rgba($black, 0.2);
	}

	.CardContent {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__add-btn {
		overflow: hidden;
		flex-direction: column;
		display: flex;
		justify-content: center;
		padding: 0 10px; // to make the btn shadow not cut off
		margin-right: -10px; // to compensate for the line above

		.Button {
			margin-top: 0; // This is to override some stupid bootstrap style targeting .btn-block + .btn-block
		}
	}

	&__request-addon {
		display: flex;
		align-items: center;
	}

	&__trial_btn {
		margin-bottom: 4px;
	}

	&__type {
		text-transform: uppercase;
	}
	&__bottom_wrapper {
		margin-top: auto;
	}

	.Headline {
		margin-bottom: 15px;
	}

	&__add-card {
		display: flex;
		height: 86px;
		border-radius: 0 0 $border-radius $border-radius;

		& > .Row {
			flex: 1;
		}
	}

	&__price-col {
		display: flex;

		.Text > .Text {
			display: inline-block;
			margin-left: 5px;
		}
	}
	&__trial_tooltip {
		margin: auto;
		margin-left: 5px;
	}

	&__trial-col {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__price {
		align-self: center;
	}

	&__added-icon-col,
	&__added-text-col {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__added-text-col .Text {
		align-self: center;
	}

	&__added-icon {
		$size: 28px;
		height: $size;
		width: $size;
		line-height: $size;
		border-radius: 50%;
		align-self: center;
		text-align: center;
		font-size: 14px;
		padding-left: 3px;
	}

	&__button-row {
		height: 30px;
		.Column:first-child {
			display: flex;
			align-self: center;
		}
	}

	&__next {
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		background-color: $white;
		display: flex;
		flex-direction: column;
	}

	&__desc {
		white-space: break-spaces;
	}

	&__desc-wrapper {
		display: none;
		&--visible {
			display: block;
		}
	}
}
