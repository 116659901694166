@import "~StyleVariables";

.DetectedDuplicatesLandingPage {
	.Card {
		cursor: pointer;
		width: 400px;
		height: 132px;
		border-radius: $border-radius;
		border: 1px solid $grey-6;
		position: relative;
		background-color: $white;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			width: 110%;
			height: 100%;
			background-color: $grey-2;
			clip-path: polygon(100% 100%, 70% 110%, 95% 0,100% 0);
		}
	}

	.Card:hover {
		box-shadow: $box-shadow-lg;
	}

	&__cardBlock {
		width: 330px;
		height: 100%;
	}

	&__card-text {
		width: 270px;
	}

	img {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}
}
