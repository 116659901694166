@import "~StyleVariables";

.FormOverviewActions {
	margin-left: auto;
	display: flex;
	align-items: center;

	.Button {
		margin-right: 20px;
		line-height: 30px;
	}

	.DropDownMenu {
		box-shadow: -1px 0 0 0 $grey-5;
	}

	.DropDownMenu__content {
		z-index: 5;

		.Icon {
			margin-right: 12px;
			font-size: 18px;
			width: 25px;
			text-align: center;
		}

		.Text {
			display: inline-block;
		}

		.TableRow {
			cursor: pointer;
		}
	}

	&__DropdownButton {
		display: flex;
		align-items: center;
		padding: 5px 0 5px 15px;
		height: 47px;
		box-shadow: -1px 0 0 0 $grey-5;

		&--isArchived {
			background-color: $grey-2;
		}

		.Icon {
			display: inline-block;
			margin-right: 7px;
		}

		.Title {
			display: inline-block;
			font-size: 16px;
			font-weight: $boldFontWeight;
			line-height: 19px;
		}

		.Subtitle {
			line-height: 14px;
		}

		.Toggle-icon-wrapper {
			height: 50px;
			display: flex;
			align-items: center;
			padding: 10px 12px;
			box-shadow: -1px 0 0 0 $grey-5;
			cursor: pointer;

			.Icon {
				margin-right: 0;
			}
		}
	}

	&__Action {
		&:hover {
			background-color: $grey-1;
		}
	}
}
