.AccountRelationModal {
	.ModalContent {
		padding: 100px;

		.Text {
			margin: 20px 0 20px;
		}

		.Card {
			padding: 10px;
			margin: 20px 0 20px;

			.Text {
				margin: 0;
			}
		}

		.Input {
			margin: 10px 0 20px;
		}
	}
}
