@import "~StyleVariables";

.OpenSubscription {
	overflow-y: auto;

	.EditorHeader {
		position: fixed;
		width: 100%;
		z-index: 10012;
	}

	.EditorHeader__controls {
		> .EditorHeaderButton {
			padding-right: 0;
			transition: all 0.3s ease;
			opacity: 0 !important;
			display: none;
		}
	}
	&--withoutSubscriptionSplits {
		.Tabs {
			transition: opacity 0.3s ease;
			visibility: visible;
			opacity: 1;
		}
		&.OpenSubscription--hideSteps {
			.Tabs {
				visibility: hidden;
				opacity: 0;
			}
			.EditorHeader__controls {
				> .EditorHeaderButton {
					visibility: visible;
					opacity: 1 !important;
				}
			}
		}
	}

	&.Modals__modal {
		.Select__selected-item {
			visibility: hidden;
		}
	}

	&.Modals__modal--visible {
		.Select__selected-item {
			visibility: unset;
		}
	}

	&__navbar-title {
		display: flex;
		align-items: center;
		z-index: 10003;

		.EditorHeader__icon {
			padding-left: 16px;
		}

		.Help {
			position: fixed;
			right: 19px;
			z-index: 2000;
			top: 16px;
		}
	}

	&__loader {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__controls {
		margin-left: auto;
	}

	&__content {
		height: calc(100% - #{$header-height}); // height of EditorHeader
		margin-top: 90px; // height of EditHeader and BetaBanner

		&--hasRemoveBetaBanner,
		&--labMode {
			margin-top: $header-height;
		}
	}

	.EditorHeaderButton {
		.EditorHeaderButton__title {
			line-height: 24px;
		}
	}

	&__inner {
		margin-top: $header-height; // height of EditorHeader
		position: relative;
	}

	&__promo-splash {
		background-color: $black;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		position: fixed;
		cursor: pointer;
		z-index: 99999;
		display: flex;
		height: 100%;
		opacity: 0.8;
		width: 100%;
		top: 0;
	}
}
