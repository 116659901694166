@import "~StyleVariables";

.FilesGridView {
	display: flex;
	flex-direction: column;

	&__folders-container {
		margin-bottom: 16px;
		> .Text {
			margin-left: 20px;
		}
		display: flex;
		flex-direction: column;
		&__folders {
			display: flex;
			flex-wrap: wrap;
		}
	}
	
	&__files-container {
		> .Text  {
			margin-left: 20px;
		}
		display: flex;
		flex-direction: column;
		&__files {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__edit-button {
		display: flex;
		align-items: center;
		&--active {
			.Icon {
				color: $red;
			}
		}
		.Icon {
			&:hover {
				background-color: $grey-6;
			}
			cursor: pointer;
			transition: all 0.25s ease;
			padding: 4px;
			margin: 4px;
			border-radius: 4px;
			font-size: 12px;

		}
	}
}