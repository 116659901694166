@import "~StyleVariables";

.MarketingBoardHeaders {
	position: sticky;
	top: 152px;
	background-color: $white;
	border-top: 1px solid $grey-4;
	box-shadow: 0 2px 4px rgba($black, 0.2);
	z-index: 1000;
	margin-top: -1px;

	&__col {
		border-left: 1px solid $white;
		border-right: 1px solid $white;
		padding: 5px 10px 0;

		&:first-child {
			border-left: none;
		}
		&:last-child {
			border-right: none;
		}

		.Headline {
			font-size: 14px;
		}
	}
}
