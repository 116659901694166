@mixin filters() {
	// filters

	.new-filters {
		.edit-tools {
			height: 100%;
			padding: 0;

			#view-select-wrap {
				.selected-view {
					position: relative;
					padding-right: 25px;
					max-width: 250px;
					width: auto;

					.caret {
						top: 50%;
						right: 10px;
					}
				}

				.dropdown-toggle {
					position: relative;
					margin: 0;
					padding-top: 3px;
					text-align: left;
					border-right: 1px solid $grey-8;

					&.no-list-views {
						margin-left: 10px;
						border: 0;
					}
				}
			}

			.total-results {
				height: 25px;
			}
		}
	}

	.edit-tools {
		display: inline-block;
		vertical-align: top;
		position: relative;
		height: $header-height;
		padding: 9px 4px;
		border-radius: $border-radius 0 0 0;
		@include transition(all ease-in 100ms);

		// &:hover {
		// 	background-color: $dark-blue;
		// }

		&.new-leads {
			height: 46px;

			#view-select-wrap {
				.dropdown-toggle {
					text-align: left;
				}

				.selected-view {
					max-width: 250px;

					.caret {
						right: 10px;
					}
				}
			}
		}

		& > button.btn {
			margin-left: 10px;
		}

		button .badge {
			position: absolute;
			top: -6px;
			right: -6px;
			background-color: $red;
		}

		.total-results {
			color: $grey-8;
			margin-left: 10px;
			height: 35px;
			display: inline-block;
			vertical-align: top;
			line-height: 35px;
		}

		// view-selector
		#view-select-wrap {
			display: inline-block;

			// #view-select {
			// 	position: relative;
			// 	width: 170px;
			// 	text-align: left;

			&.changed {
				font-style: italic;
			}

			.dropdown-toggle {
				@include h2();
				border: 0;
				background-color: transparent;
				color: $grey-4;
				margin-top: 2px;

				&:hover {
					color: $white;
					cursor: pointer;

					.caret {
						border-top-color: $white;
					}
				}

				.text-blue {
					.caret {
						border-top-color: $blue;
					}
				}
			}

			.selected-view {
				overflow: hidden;
				white-space: nowrap;
				display: block;
				text-overflow: ellipsis;
				padding-right: 20px;

				.caret {
					position: absolute;
					border-top-color: $grey-4;
					margin-left: 5px;
					top: 50%;
					margin-top: -2px;
				}
			}
			// }

			// view-selector list
			.dropdown-menu {
				min-width: 350px;

				li {
					&:active a,
					&.active a {
						background-color: $white;
						color: $black;
						border-left: 5px solid $medium-blue;

						.edit-view,
						.remove-view {
							color: inherit;
						}
					}

					a {
						position: relative;
						border-left: 5px solid $white;
						padding: 5px 80px 5px 12px;
						font-size: 16px;
						color: $grey-11;
						margin: 1px 0;

						&:hover,
						&.hover {
							border-left: 5px solid $medium-blue;
							background-color: $white;
							color: $black;
						}

						.edit-view,
						.remove-view,
						.star-view {
							display: inline-block;
							position: absolute;
							top: 8px;
							cursor: pointer;
							font-size: 16px;
						}

						.edit-view {
							right: 25px;
						}

						.remove-view {
							right: 5px;
						}

						.star-view {
							right: 45px;
						}
					}
				}
			}

			.total-results {
				font-size: 10pt;
				color: $grey-11;
				margin-left: 0px;
				margin-top: -6px;
			}
		}

		#table-loader {
			position: relative; // animation fix
			z-index: 2000; // animation fix
			margin-left: 10px;
			color: $blue;
		}

		#new-view-input {
			display: none;
			width: 150px;
		}

		#new-view-save {
			color: $medium-green;
		}

		#new-view-abort {
			color: $red;
		}

		#new-view-save,
		#new-view-abort,
		#new-view-loader {
			display: none;
		}
	}

	.list-head {
		display: flex;
		align-items: center;
		background-color: $blue;

		#view-tools {
			display: inline-block;
			padding-top: 9px;

			& > button.btn-link {
				color: $white;
			}
		}

		.content {
			display: inline-block;
			padding: 0px !important;
		}

		#left {
			display: inline-block;
			white-space: nowrap;
			// padding: 12px 10px;
			height: 100%;

			&.has-total-results {
				display: inline-block;
				// padding: 5px 10px;

				#view-select-wrap {
					display: block;
				}

				.edit-tools {
					padding: 1px 4px 4px 4px;
				}

				.total-results {
					position: relative;
					color: $grey-8;
					font-size: 12px;
					margin-left: 6px;
					height: 15px;
					display: inline-block;
					vertical-align: top;
					margin-top: -4px;
					text-transform: uppercase;
					letter-spacing: 0.5px;
					line-height: initial;
				}
			}
		}

		#right {
			display: inline-flex;
			margin-left: auto;
			text-align: right;
			white-space: nowrap;
			margin-left: auto;
			height: 100%;

			#list-view-add-btn,
			button#reset-link {
				margin-right: 10px;
				margin-top: 10px;
				height: 35px;
			}

			#reset-link,
			#list-view-add-btn {
				color: $white;
			}
		}
	}

	.filters-wrap {
		display: inline-block;
		vertical-align: top;
		margin-top: 8px;

		#compact-filters {
			display: none;
			margin-right: 1px;

			.dropdown-menu {
				right: 0;
				left: initial;
				text-align: left;
			}

			#active-filters-dropdown {
				border-radius: $border-radius 0 0 $border-radius;
			}
		}

		#add-filter-button {
			margin-right: 10px;
			height: 36px;
			line-height: 36px;
			border: none;

			&.has-filters {
				border-radius: 0 $border-radius $border-radius 0;
				border: none;
			}
		}

		#active-filters {
			display: inline-block;

			#open-advanced-button {
				margin-right: 10px;
				padding-right: 0;
				padding-left: 0;
				width: 35px;
				text-align: center;
			}

			.filter-button {
				position: relative;
				outline: none;
				height: 36px;
				display: inline-block;
				text-align: left;
				padding-right: 20px;
				font-size: 12px;

				.filter-label {
					display: block;
					font-size: 10px;
					text-transform: uppercase;
					color: $grey-13;
					font-weight: $boldFontWeight;
					line-height: 11px;
				}

				.fa.fa-caret-down {
					position: absolute;
					right: 6px;
					top: 50%;
					margin-top: -5px;
				}

				.filter-value {
					display: block;
				}

				&:hover {
					.fa.fa-caret-down,
					.filter-value {
						color: $medium-green;
					}
				}
			}

			.filter {
				display: inline-block;
				margin-right: 1px;
				border-radius: 0;
				padding-left: 6px;

				&:first-of-type {
					border-radius: 3px 0 0 3px;
				}

				.remove-filter {
					display: none;
				}
			}
		}

		&.compact {
			#active-filters {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}

			#compact-filters {
				display: inline-block;
			}
		}
	}

	#filter-popup-wrap {
		position: relative;
		z-index: $modal-z;
	}

	#up-filter-curtain {
		@include curtain();
	}

	.add-filter-list {
		ul {
			margin: 0;
			padding: 0;
		}

		.hasChildren {
			font-weight: $boldFontWeight;
			background-color: $grey-4 !important;
		}
		.hasChildren .fa-chevron-right {
			display: none;
		}

		span {
			display: block;
			padding: 10px;
			border-bottom: 1px solid $grey-2;

			&.no-filters {
				color: $grey-10;
			}

			.fa-chevron-right {
				color: $grey-4;
			}
		}

		span:hover:not(.no-filters) {
			background-color: $grey-1;

			.fa-chevron-right {
				color: $grey-10;
			}
		}

		li li span {
			text-indent: 20px;
		}
	}

	.add-filter-popup {
		border-top: 3px solid $blue;

		#title {
			position: relative;
			display: block;
			padding: 10px;
			border-bottom: 1px solid $grey-4;
			font-size: 16px;

			.tools {
				position: absolute;
				right: 10px;
				top: 9px;
				font-size: 12px;

				.remove {
					position: relative;
					top: 2px;
					margin-right: 5px;
				}
			}
		}

		.search-wrap {
			position: relative;
			padding: 10px;

			.search-info {
				position: absolute;
				right: 15px;
				top: 16px;
				padding: 2px 5px;
				background-color: $grey-4;
				border: 1px solid $grey-8;
				font-size: 12px;
				color: $grey-11;
				@include border-radius(3px);
			}
		}

		.header {
			padding: 10px;
			font-weight: $boldFontWeight;
		}

		#content {
			max-height: 300px;
			overflow: hidden;
			overflow-y: auto;

			ul.filter-list {
				padding-left: 0;
				list-style-type: none;
				width: 100%;

				li {
					position: relative;
					width: 100%;
					background-color: $white;
					border-top: 1px solid $grey-6;
					display: inline-block;
					padding: 10px 0px 10px 0px;

					button {
						padding-left: 10px;
					}
					label {
						font-weight: normal;
						display: inline;
						cursor: pointer;
						width: 100%;
						height: 100%;
						padding-left: 0px;
					}

					.select-only {
						display: none;
						position: absolute;
						right: 13px;
						top: 8px;
					}

					.up-toggle {
						margin-left: 10px;
					}

					&.group-title {
						border-left: 0;
						color: $white;
						font-weight: $boldFontWeight;
						background-color: $grey-3;
						color: $grey-13;
						margin-bottom: 0;
						padding-bottom: 0px;
						padding-top: 3px;

						& > label {
							font-weight: $boldFontWeight;
						}

						&:hover {
							background-color: $grey-3;
						}

						& > .select-only {
							top: 0;
						}
					}

					.check {
						padding-left: 10px;
						color: $bright-blue;
						width: 15px;
						visibility: hidden;
					}

					&.selected {
						.check {
							visibility: visible;
						}
					}

					&.row-selected {
						background-color: $medium-blue;
						& > label {
							color: $white;
						}
					}

					&:hover {
						background-color: $grey-1;

						& > label {
							color: $black;
						}

						& > .select-only {
							display: block;
						}
					}
				}
			}

			table.filter-table {
				tr {
					&:hover,
					&.hover {
						td {
							background-color: $grey-1;
							&.arrow {
								color: $grey-10;
							}

							.select-only {
								display: block;
							}
						}
					}

					&.selected {
						td {
							&.check {
								b {
									display: inline-block;
								}
							}
						}
					}

					&.group-title {
						& > td {
							border-left: 0;
							background-color: $blue;
							color: $white;
							font-size: 12px;
							font-weight: $boldFontWeight;
							height: 29px;
							background-color: $grey-3;
							color: $grey-13;
							border-top: 1px solid $grey-6;
							border-bottom: 1px solid $grey-6;
						}

						& > :hover {
							border-left: 0;
							background-color: $blue;
							color: $white;
							font-size: 12px;
							font-weight: $boldFontWeight;
							height: 29px;
							background-color: $grey-3;
							color: $grey-13;
							border-top: 1px solid $grey-6;
							border-bottom: 1px solid $grey-6;
						}
					}

					td {
						position: relative;
						cursor: pointer;
						padding: 10px 0 10px 10px;
						border-bottom: 1px solid $grey-4;

						&.arrow {
							width: 15px;
							color: $grey-4;
						}

						&.check {
							color: $bright-blue;
							width: 24px;
						}

						&:last-child {
							padding-right: 10px;
						}

						&.group-title {
							order-left: 0;
							background-color: $blue;
							color: $white;
							font-size: 12px;
							font-weight: $boldFontWeight;
							height: 29px;
							background-color: $grey-3;
							color: $grey-13;
							border-top: 1px solid $grey-6;
							border-bottom: 1px solid $grey-6;
						}

						.select-only {
							display: none;
							position: absolute;
							right: 13px;
							top: 8px;
						}
					}
				}
			}

			table.dual-column-table {
				margin: 10px 0;
				text-align: center;

				& > tbody > tr > td {
					padding: 10px;

					&.left {
						text-align: left;
					}
				}
			}
		}

		#toolbar {
			position: relative;
			left: 0px;
			right: 0px;
			bottom: 0px;
			padding: 10px;
			display: block;
			background-color: $grey-2;
			border-top: 1px solid $grey-8;
			color: $grey-11;

			.clear-filter {
				position: absolute;
				top: 3px;
				right: 4px;
				border-left: 1px solid $grey-8;
			}
		}
	}

	.paginator-wrap {
		text-align: center;
		padding: 10px;
		margin-bottom: 70px;
		min-height: 100px;

		.up-paginator {
			margin: 0;
			padding: 0;
		}
	}
}
