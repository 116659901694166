@import "~StyleVariables";

.SubscriptionIndexingPreview {

	&__header {
		background-color: white;
		top: -1px;
		position: sticky;
		z-index: 2;
	}
}
