@import "~StyleVariables";

.FlowTemplateCard {
	position: relative;
	display: flex;
	height: 206px;
	flex-direction: column;
	border: 1px solid $grey-6;
	overflow: hidden;

	&--preview {
		height: 533px;
	}

	&__illustration {
		background-color: $grey-3;
		flex: 1;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
	}

	&__info {
		background-color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 8px 16px;
	}

	&__chip {
		position: absolute;
		top: 8px;
		left: 8px;
	}

	&:hover:not(&--preview) {
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		transition: all ease-out 300ms;
		cursor: pointer;
	}
}
