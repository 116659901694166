@import "~StyleVariables";

.MaxDiscountInfo {
    position: absolute;
    bottom: -$space * 4;
    height: fit-content;

    .Text--xs {
        font-size: 11px;
    }
}