@import "~StyleVariables";

.FunnelWidget {
	height: 100%;

	&__content {
		height: 100%;
		margin-top: 20px;
	}

	&__funnel {
		min-width: 488px;
		max-width: 488px;
		position: relative;
		cursor: pointer;
		.Row {
			height: 48px;
			background-color: transparent;
			margin: auto;
			margin-bottom: 8px;
			position: relative;
			display: block;
			z-index: 1;
			padding-left: 30px;
			padding-top: 4px;
			transition: ease-in-out all 200ms;

			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				width: 50%;
				background: $grey-3;
				transform-origin: top;
				z-index: -1;
				transition: ease-in-out all 200ms;
			}
			&::before {
				border-radius: 8px 0 0 8px;
				border-right: 0;
				left: 0;
				transform: skew(19deg);
			}
			&::after {
				border-radius: 0 8px 8px 0;
				border-left: 0;
				right: 0;
				transform: skew(-19deg);
			}

			&:hover::after,
			&:hover::before {
				background-color: $grey-6;
			}
			.FunnelWidget__value {
				font-weight: $boldFontWeight;
			}
		}

		.Row:nth-of-type(1) {
			width: 448px;
			-webkit-clip-path: polygon(0 0, 100% 0, 96% 100%, 4% 100%);
			clip-path: polygon(0 0, 100% 0, 96% 100%, 4% 100%);
		}

		.Row:nth-of-type(2) {
			width: 406px;
			-webkit-clip-path: polygon(0 0, 100% 0, 95.5% 100%, 4.5% 100%);
			clip-path: polygon(0 0, 100% 0, 95.5% 100%, 4.5% 100%);
		}

		.Row:nth-of-type(3) {
			width: 363px;
			-webkit-clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
			clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
		}

		.Row:nth-of-type(4) {
			width: 321px;
			-webkit-clip-path: polygon(0 0, 100% 0, 94.5% 100%, 5.5% 100%);
			clip-path: polygon(0 0, 100% 0, 94.5% 100%, 5.5% 100%);
		}

		.FunnelWidget__arrow {
			z-index: 2;
			display: inline-block;
			left: 330px;
			right: 0;
			bottom: 0;
			position: absolute;
			width: 54px;
			height: 47px;
			.svg {
				transform: scale(1.15);
			}
			&--first {
				top: 31px;
			}
			&--second {
				top: 92px;
			}
			&--third {
				top: 151px;
			}
			.Text {
				position: relative;
				margin-top: -39px;
				color: $super-light-green;
			}
		}
	}

	&__values {
		.Row {
			display: block;
			padding-top: 10px;
			padding-right: 20px;
			margin-bottom: 25px;
			text-align: center;
		}

		.FunnelWidget__value {
			line-height: 25.2px;
		}
		.Tooltip {
			margin-left: 5px;
		}
	}

	&__label {
		font-weight: $boldFontWeight;
		color: $grey-10;
	}
}
