@import "~StyleVariables";

.TargetCard {
	animation: show 0.5s ease;
	opacity: 1;
	&--disabled {
		opacity: 0.5;
	}

	.Card {
		border: 1px solid $grey-6;
		border-bottom: 0;
		z-index: 99;
		&:not(.TargetCard__calcBox) {
			margin-top: 20px;
		}
	}

	&__input {
		width: 50%;
		&--currencyInput {
			width: calc(50% - 50px);
		}
	}

	&__includeToggle {
		.Toggle {
			margin-right: 10px;
		}
	}

	&__calcBox {
		& > .Row,
		.Column {
			width: 100%;
		}

		.TargetCard__equals {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.TargetCard__denominator {
			border-top: 1px solid $grey-10;
		}

		.Row > .Column {
			& > .Row {
				width: 100%;
				display: flex;
				justify-content: center;
			}
			.TargetCard__separator {
				height: 0px;
			}
			flex-direction: column;
		}

		.TargetCard__resultCol {
			display: flex;
			justify-content: center;
			.Row {
				display: flex;
				justify-content: flex-start !important;
			}
		}

		max-height: 125px;
		background-color: $grey-1;
		border-radius: 0 0 4px 4px;
	}

	&__currencyInput {
		width: calc(40% - 50px);
	}

	&__currencyDisplay {
		width: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid $grey-6;
	}

	@keyframes show {
		0% {
			transform: translateY(-100%);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}
}
