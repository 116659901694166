@import "~StyleVariables";

.ConfirmationDrawer {
	--drawer-header-height: 50px;

	top: calc(var(--drawer-header-height) + 1px);
	width: 100%;
	z-index: 1000;
	position: absolute;
	background-color: $white;
	height: calc(100vh - var(--drawer-header-height));
	pointer-events: none;
	visibility: hidden;

	&--visible {
		pointer-events: auto;
		visibility: visible;
	}

	&__content {
		width: 100%;
		height: 50%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-end;
	}
}
