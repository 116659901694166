@import "../../../styles/variables";

.WistiaVideo {
	height: 100%;
}

img.EmbeddedVideo:hover {
	cursor: pointer;
}

.WistiaVideo__Reject {
	border-radius: 100%;
	color: $grey-10;
	background: $grey-2;
	width: 40px;
	height: 40px;
	position: absolute;
	top: -50px;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
