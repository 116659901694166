@import "~StyleVariables";

.GroupMailEditorReviewSend {
	margin-top: 80px;
	background-color: $green;
	padding-bottom: 100px;

	&--grey {
		background-color: $grey-12;
	}

	&__inner {
		width: 816px;
		margin: 0 auto;
		padding: 100px 0;
		overflow: hidden;

		& > .Row > .Column:first-child {
			z-index: 1000;
		}

		.Headline {
			margin-bottom: 22px;
		}

		.Text {
			margin-bottom: 42px;
		}

		.Row {
			margin: 0 -5px;

			.Column {
				margin: 0 5px;
			}
		}
	}

	&__show-send {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		text-align: center;
		visibility: hidden;
		opacity: 0;
		background-color: $green;
		z-index: 1002;
		transition: all ease-out 200ms;

		&--visible {
			visibility: visible;
			opacity: 1;
		}

		.Icon {
			margin-right: 5px;
		}
	}

	&__confirm-send,
	&__select-type {
		margin-top: 80px;

		&--willGoUpInTier {
			border-radius: $border-radius;
			background-color: $yellow-1;
			padding: 12px;
			.Text {
				display: inline;
				margin-bottom: 0;
			}
		}

		&__tier-upgrade {
			margin-top: 20px;

			&__cost {
				display: flex;
				align-items: center;
				justify-content: right;
			}
		}

		&__loader {
			display: flex;
			justify-content: center;
		}

		&__tierIncrease {
			display: flex;
			flex-direction: column;

			&__summary {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&__tierText {
					display: flex;
					flex-direction: column;
					margin-bottom: 10px;
				}
			}
		}

		&__authorized {
			display: flex;
			align-items: center;

			margin-top: 12px;
			margin-bottom: 12px;

			.Text {
				display: inline;
			}

			.Checkbox {
				display: inline-block;
				margin-right: 12px;
			}
		}
	}

	&__schedule-settings {
		.DateInput__popup,
		.TimeInput__list {
			bottom: 35px;
		}

		.Row {
			margin-bottom: 30px;
		}

		.Label {
			color: $white;
		}
	}

	&__schedule-info {
		padding-top: 80px;

		.Text {
			margin-bottom: 10px;
		}
	}
}
