@import "~StyleVariables";

// TODO: Structure this file
.DocumentTemplateEditorDesign {
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 300ms;
	gap: 24px;

	&--open {
		opacity: 1;
		visibility: visible;
	}

	&__add-first-row {
		margin: 60px auto 0;
		display: block;
	}

	&__preview-loader {
		position: absolute;
		top: 50%;
		margin-left: -25px;
		margin-top: -25px;
		left: 50%;
	}

	&__sidebar {
		overflow: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		&__preview-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 16px 0px;
			position: absolute;
			width: 948px;
			height: 624px;
			left: 0px;
			top: 96px;
			background: $grey-2;
		}

		&__preview-editor {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 10px;
			gap: 10px;
			width: 600px;
			height: 583px;
			flex: none;
			order: 0;
			align-self: stretch;
			flex-grow: 0;
		}

		.ColorSelect {
			width: 80px;
			display: inline-block;
		}

		.ColorSelect__RealColor {
			border: 1px solid $grey-10;
		}

		.ColorSelect__PickerHolder {
			right: 0;
		}

		.StepIntInput {
			width: 130px;
			display: inline-flex;
		}

		.cke {
			opacity: 1;
			margin: 0 -15px;
			border: none;
			box-shadow: none;
		}

		.color-input {
			width: 83px;
			padding: 0 10px;
			border-radius: 3px;
			height: 30px;
			border: 1px solid $grey-10 !important;
		}
	}

	.EditorWrap__sidebar {
		top: 93px;
	}

	&__sidebar-section-flex {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	&__sidebar-inner {
		height: calc(100% - 50px);
		overflow-y: auto;
	}

	&__sidebar-outer {
		height: 100%;
	}
}

.DocumentTemplateEditorDesign__sidebar-section {
	padding: 15px;
	padding-bottom: 10px;
	border-bottom: 1px solid $grey-4;
	height: 100%;
    display: flex;
    flex-direction: column;

	.MailContentEditor__sidebar-section-input-row {
		margin: 10px -5px;

		.Column:first-of-type {
			padding: 0 4px;
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
	}

	.DocumentTemplateEditorDesign__sidebar-section-input-row {
		margin: 10px -5px;

		.Column:first-of-type {
			padding: 0 4px;
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
	}

	.DocumentTemplateEditorDesign__btn-danger {
		background-color: $danger-5;
		border: 1px solid $danger-5;

		&:hover {
			background-color: $danger-6;
			border: 1px solid $danger-6;
		}
	}

	.DocumentTemplateEditorDesign__sidebar-header {
		margin: 0 -5px;

		.Column {
			padding: 0 5px;
		}

		.Headline {
			line-height: 34px;

			.Help {
				display: inline-block;
				margin-left: 5px;
			}
		}
	}
}

.DocumentTemplateEditorDesign__preview {
	margin: 0 auto;
	vertical-align: top;
	margin-bottom: 10px;
	margin-top: 10px;
	position: relative;
	// Measurements for an A4 paper
	width: 21cm;
	&--hidden {
		visibility: hidden;
		.DocumentTemplatePreview__add-first-row {
			visibility: visible;
		}
	}
	
}

.DocumentTemplateEditorDesign__preview-loader {
	position: absolute;
	top: 50%;
	margin-left: -25px;
	margin-top: -25px;
	left: 50%;
}

.DocumentTemplateEditorDesign__border-style .Button > div {
	border-width: 0;
	border-bottom-width: 4px;
}

.DocumentTemplateEditorDesign__empty .Headline {
	height: 50px;
	line-height: 50px;
	padding: 0 15px;
}

.DocumentTemplateEditorDesign__empty-selector {
	padding: 10px;
	background-color: $grey-1;
	border-top: 1px solid $grey-6;
	border-bottom: 1px solid $grey-6;

	& > div {
		display: inline-block;
		width: 50%;
		padding: 5px;
		vertical-align: top;

		.Button {
			width: 100%;
			text-align: center;
			border-radius: 3px;
			padding: 20px 0;
			transition: all ease-out 200ms;
			border: 1px solid $grey-6;
			height: auto;

			.Icon,
			.icon {
				height: 33px;
				font-size: 28px;
				margin-bottom: 5px;
			}

			.Icon {
				margin-bottom: 5px;
			}

			.Icon,
			.Text {
				transition: all ease-out 200ms;
				color: $grey-10;
			}

			.icon-fill {
				fill: $grey-10;
			}

			.Icon-button {
				font-size: 48px;
				line-height: 33px;
			}
		}
	}
}

.DocumentTemplateEditorDesign__code-editor {
	height: 380px;

	&-loader {
		width: 30px;
		height: 30px;
		position: relative;
		bottom: 45px;
		right: 10px;
		float: right;
	}
}
