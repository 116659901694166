@import "~StyleVariables";

.CreateFlowContent {
	position: relative;
	overflow: hidden;

	&__page {
		overflow: hidden;
	}

	.ModalHeader {
		border-top: none !important;
		border-bottom: none !important;
		padding: 0 15px;
	}

	.ModalContent {
		background-color: $white;
		padding-top: 0;
	}

	.ModalControls {
		border-top: 0;
	}
}
