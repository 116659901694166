@import "../../../styles/variables";

.SeatsConfirmed {
	padding: 0px;

	&__loader {
		margin-top: 150px;
		align-self: center;
	}

	&__content > * {
		padding: 20px;
	}
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 15vh;

		.Text {
			font-size: 18px;
		}
		.Text--xl {
			font-size: 38px;
			font-weight: $boldFontWeight;
		}
		.Button {
			padding: 0px;
			margin-top: 20px;
			width: 280px;
		}
		.Icon {
			font-size: 58px;
		}
	}
}
