.ReportcenterFilterExclude {
	&__footer {
		position: sticky;
		// -1 because Select does a transformY() on the container, which can leave a 1px gap on some zoom levels
		bottom: -1px;
	}

	&__toggle-include {
		cursor: pointer;
		display: inline;
	}
}
