@import "../../../../styles/variables";

.AccountListContacts {
	position: relative;

	.AccountListContacts__control {
		position: absolute;
		display: flex;
		top: 0;
		right: 0;
		padding: 12px 10px;

		.search-container {
			margin-right: 10px;

			.Input .Input__input {
				background-color: rgba($grey-4, 0.5);
			}
			.Input__input {
				width: 148px;
				&:focus,
				&[value^=""] {
					width: 200px;
				}
			}
		}

		.only-active-toggle-container {
			margin: auto 20px;
			display: flex;

			.only-active-toggle-container-label {
				margin-right: 10px;
			}

			.only-active-toggle-container-label--hide {
				margin-right: 10px;
				visibility: hidden;
			}
			.Tooltip {
				display: inline-flex !important;
				align-items: center;
			}
		}
	}

	&__loader {
		margin: 104px auto 0;
	}
	.wrapper-header {
		display: inline-block;
		span {
			display: inline-block;
		}
		.AccountListContacts__showDirectorsButton {
			height: auto !important;
			line-height: normal;
			display: inline-block;
			margin-top: -1px;
		}
	}
}

.AccountListContacts .AccountListContacts__List-delimiter-row {
	background: $grey-4;
	color: $grey-12;
	height: 12px;
	font-size: 12px;
	padding: 2px 10px;
	font-weight: $boldFontWeight;
	text-transform: capitalize;
}

.AccountListContacts .AccountListContacts__TableHeader {
	text-transform: uppercase;

	& > div {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	// Hide market events
	@media (max-width: 1365px) {
		div:nth-child(4) {
			display: none;
		}
	}
	// Hide Lead score
	@media (max-width: 1200px) {
		div:nth-child(5) {
			display: none;
		}
	}
	// Hide sakes event
	@media (max-width: 1023px) {
		div:nth-child(6) {
			display: none;
		}
	}
}

.AccountListContacts .AccountListContacts__List-row {
	display: flex;
	align-items: center;
	color: $grey-13;
	background: $white;
	padding: 0 0 0 10px;
	font-size: 12px;
	border-bottom: 1px solid $grey-4;
	cursor: pointer;
	&:focus {
		outline: none;
	}
}

.AccountListContacts .AccountListContacts__List-row-inactive {
	border-left: 4px solid $grey-8;
	padding-left: 5px;
}
