@import "~StyleVariables";

.OpportunityTodoRow {
	height: 55px;
	border: 1px solid transparent;
	border-radius: 2px;
	cursor: pointer;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-right: 8px;

	&__children {
		.OpportunityTodoRowChild:hover {
			background-color: $grey-1;
			cursor: pointer;
		}
	}
	&__check-col {
		display: flex;
		justify-content: center;
	}

	&__actions {
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
	}

	&:hover {
		background-color: $grey-1;
		.OpportunityTodoRow__actions {
			visibility: visible;
			opacity: 1;
			.Button:hover {
				background-color: $grey-3;
			}
		}
	}

	& > .Row, &.Row {
		height: 100%;
		& > .Column {
			align-self: center;
			overflow: hidden;

			&:first-child,
			&:last-child {
				padding-right: 0;
			}

			&:first-child {
				font-size: 14px;
			}

			& > .Text {
				line-height: 18px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	&__description--checked {
		width: fit-content;
		position: relative;
		color: $grey-10;
	}
	&__description--checked::after {
		content: " ";
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		background: $black;
		animation: strike 200ms cubic-bezier(0.8, 1.56, 0.8, 1);
		animation-fill-mode: forwards;
	}
}
