@import "../../../../../styles/_variables";

.inline-edit-controls {
	text-align: right;
	margin-top: 10px;
}

.participants {
	transition: all 0.3s;
	height: 70px;

	.contact-avatar {
		border: none;
		background: transparent;
		height: 30px;
		width: 30px;
		font-size: 30px;
		box-shadow: none;
		color: $grey-10;
	}

	.parti-arrow {
		cursor: pointer;
	}

	.contact-more-info {
		opacity: 0;
		transition: all 0.3s;
		transition-delay: 0.15s;

		li {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.Icon {
				width: 16px;
				text-align: center;
			}

			& > span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				flex-grow: 1;
			}
		}
	}

	.italic {
		font-style: italic;
	}

	&.participants-open {
		transition: all 0.3s;
		height: 180px;

		.contact-more-info {
			opacity: 1;
			transition: all 0.3s;
			transition-delay: 0.15s;
		}
	}

	&.participants-edit {
		min-height: 236px;
		height: auto;
		transition: all 0.3s;
	}

	.PhoneInput {
		&.has-error {
			.form-control {
				border-bottom-color: $red !important;
			}
		}
		.input-group-addon,
		.iti__selected-flag,
		.form-control {
			border: none;
			background: none;
			box-shadow: none;
			border-radius: 0;
		}

		.input-group-addon {
			display: table-cell;
			align-items: center;
			padding: 0;
		}

		.iti__selected-flag {
			outline: none;

			&:hover {
				background: none;
			}
		}

		.form-control {
			font-size: 12px;
			height: 24px;
			border-bottom: 1px solid $grey-6;

			&:focus {
				border-bottom-color: $bright-blue;
			}
		}
	}

	.Input {
		input {
			background: transparent;
		}
	}

	.editing-top {
		.Input {
			margin-bottom: 5px;
		}
	}
}
.appointment-sidebar {
	.contact-participant-link {
		cursor: pointer;

		&.waiting {
			color: $grey-10;
			font-style: italic;
		}
	}
}
