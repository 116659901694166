@import "~StyleVariables";

.EditSocialEventResourceCardContainer__sms-integration-wrapper {
	cursor: pointer;
	margin-top: 20px;
}

.EditSocialEventResourceCardContainer__loader {
	display: flex;
	justify-content: center;
	align-items: center;
}

.EditSocialEventResourceCardContainer__sms-integration {
	display: flex;
	width: 80px;
	height: 80px;
	background-color: $white;
	border-radius: 10px;
	box-shadow: 0 2px 4px 0 rgba($black, 0.2);
	padding: 12px;
}

.EditSocialEventResourceCardContainer__sms-integration img {
	width: 100%;
	margin: auto;
}

.EditSocialEventResourceCardContainer__sms-integration-wrapper .Text {
	margin-top: 10px;
	margin-right: 25px;
}

.EditSocialEventResourceCard__sms-message {
	max-width: 0;
}
.EditSocialEventResourceCard__sms-message span {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.EditSocialEventResourceCardContainer__delete-dialog {
	padding: 14px;
}

.EditSocialEventResourceCardContainer__delete-dialog .Text {
	margin: 10px 0 10px;
}

.EditSocialEventResourceCardContainer__mail-blocker {
	margin: 30px auto 0 auto;
	width: 680px;
}

.EditSocialEventResourceCardContainer__mail-blocker .CardContent {
	padding-right: 280px;
}

.EditSocialEventResourceCardContainer__mail-blocker__text {
	margin-bottom: 15px;
}

.EditSocialEventResourceCardContainer__mail-blocker__img {
	display: flex;
	position: absolute;
	top: -20px;
	right: 120px;
	height: 100%;
}

.EditSocialEventResourceCardContainer__mail-blocker__img img {
	transform: translateX(50%);
	height: 100px;
	margin: auto;
}

.EditSocialEventResourceCard__email-name .Icon {
	margin-right: 5px;
}

.EditSocialEventResourceCard__email-sendtime .up-btn.btn-sm {
	padding: 0;
}

.EditSocialEventResourceCard__error {
	background-color: lighten($red, 41%);

	&:hover {
		background-color: lighten($red, 40%) !important;
	}

	.TableColumn:first-child {
		border-left: 2.5px solid $red;
	}
}

.EditSocialEventResource {
	&__ShareButton {
		.DropDownMenu__content {
			z-index: 10000;
			min-width: 200px;
		}

		.TableColumn {
			padding-left: 10px;
			padding-right: 10px;
		}

		.Icon {
			margin-right: 5px;
		}
	}

	&__Copied {
		margin: 0;
		float: right;
		color: $bright-green;
	}
}
