@import "~StyleVariables";

.CustomerPortfolio.Page {
	$totalNavHeight: $navbar-height + $sub-menu-height;
	display: flex;
	flex-direction: column;
	height: fit-content;
	min-height: calc(100% - #{$totalNavHeight});
	overflow: visible;
}

.CustomerPortfolio {
	$totalNavHeight: $navbar-height + $sub-menu-height;
	/**
	 * @todo @upsales/components 2.12.3
	 * STYLING - Height of header is determined by the tab height, need to export the height from
	 * the Tab component styling.
	 * {@link https://github.com/upsales/ui-components/issues/493}
	 */
	$headerHeight: 50px;

	&__header {
		position: sticky;
		z-index: 2000;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		border-radius: $border-radius $border-radius 0 0;
		display: flex;
		justify-content: space-between;
		top: $totalNavHeight;
		background-color: $white;

		.Tab {
			position: relative;
			min-width: 100px;
			text-align: start;
		}

		.Block {
			display: flex;
		}

		.SalesboardSumSettings__header {
			margin: 0;
		}
	}

	&__widget-title {
		padding: $space * 4;
	}

	&__content {
		margin: $space * 2;
	}

	&__credit-rating-column {
		display: flex;
		align-items: center;
	}

	.TableColumn .Tooltip .Text {
		text-wrap: nowrap;
	}

	.TableColumn .Help .Text {
		text-wrap: nowrap;
	}

	&__company-list-widget.ListView {
		position: relative;
		top: $space;
		left: 0;
		right: $space * 2;

		.TableHeader {
			top: $totalNavHeight + $headerHeight;
			position: sticky;
			z-index: 100;
		}

		.TableColumn:not(:first-child) {
			min-width: 164px;
		}

		.ListView__header {
			height: 0;
		}
	}

	&__info-banner {
		overflow: hidden;
	}

	&__info-banner .Text:nth-child(2){
		position: absolute;
		right: -30px;
		top: 10px;
		font-size: 75px;
		opacity: 25%;
	}

	&__info-row-dot {
		border-radius: 100%;
		width: 10px;
		height: 10px;
		left: 0;
		margin-right: 8px;
	}

	&__addon-banner {
		z-index: -1;
		position: relative;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	&__addon-banner > .Block:first-child {
		position: absolute;
		top: -100px;
		height: 100px;
		width: 100%;
		z-index: -1;
		background: linear-gradient($white, $super-duper-light-green);
	}

	&__addon-banner .BillingAddonBanner {
		padding-right: calc(50% - 380px);
		flex-grow: 1;
	}

	&__addon-banner .BillingAddonCard {
		z-index: 200;
	}

	&__addon-banner img {
		position: absolute;
		height: 310px;
		width: 310px;
		z-index: 100;
		left: calc(50% + 220px);
		top: 55px;
	}

	&__addon-banner .Row {
		z-index: 100;
	}

	@media (min-width: 1700px) {
		&__addon-banner .BillingAddonBanner {
			padding-right: calc(50% + 10px);
		}

		&__addon-banner img {
			left: calc(50% - 170px)
		}
	}
}
