$tooltip-color: $grey-13;

.tooltip {
	z-index: 99999 !important;
	white-space: pre-wrap;
}
.tooltip-inner {
	background: $tooltip-color;
	max-width: 400px;
	@include box-shadow(0 1px 5px rgba($black, 0.2));
}
.tooltip.top .tooltip-arrow {
	border-top-color: $tooltip-color;
}

.tooltip.right .tooltip-arrow {
	border-right-color: $tooltip-color;
}

.tooltip.bottom .tooltip-arrow {
	border-bottom-color: $tooltip-color;
}

.tooltip.left .tooltip-arrow {
	border-left-color: $tooltip-color;
}

.tooltip-inner {
	text-align: left;
}

.tippy-tooltip {
	&.break-all-theme {
		word-break: break-all;
	}

	&.standard-field-theme {
		background-color: $green;
		color: $white;
	}

	&.warning-theme {
		background-color: $orange;
		color: $white;
	}

	.tippy-content {
		font-size: 12px;
		padding: 4px 8px;
		font-weight: $boldFontWeight;
	}

	&.white-theme {
		background-color: $white;
		box-shadow: 0 2px 4px rgba($black, 0.2);

		.tippy-arrow {
			display: none;
		}
	}
}

.tippy-popper .tippy-tooltip.notes-2-theme {
	white-space: normal;
}

.tippy-popper[x-placement^="left"] .standard-field-theme .tippy-arrow {
	border-left: 7px solid $dark-blue;
	transform: translate(0, 135%);
	top: 0 !important;
}

.tippy-popper[x-placement^="right"] .standard-field-theme .tippy-arrow {
	border-right: 7px solid $dark-blue;
}

.tippy-popper[x-placement^="top"] .standard-field-theme .tippy-arrow {
	border-top: 7px solid $dark-blue;
}

.tippy-popper[x-placement^="bottom"] .standard-field-theme .tippy-arrow {
	border-bottom: 7px solid $dark-blue;
}

.tippy-popper[x-placement^="left"] .warning-theme .tippy-arrow {
	border-left: 7px solid $orange;
	transform: translate(0, 135%);
	top: 0 !important;
}

.tippy-popper[x-placement^="right"] .warning-theme .tippy-arrow {
	border-right: 7px solid $orange;
}

.tippy-popper[x-placement^="top"] .warning-theme .tippy-arrow {
	border-top: 7px solid $orange;
}

.tippy-popper[x-placement^="bottom"] .warning-theme .tippy-arrow {
	border-bottom: 7px solid $orange;
}
