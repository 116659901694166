@import "~StyleVariables";

.ReportcenterWidgetCustomFilterSection {
	&__header {
		height: 28px;

		&:hover {
			cursor: pointer;
		}
	}

	&__fields {
		height: 0px;
		display: none;
		&--expanded {
			height: 100%;
			padding-bottom: 14px;
			display: block;
		}
	}

	.PageSidebarGroup__title {
		.Text {
			font-weight: 400 !important;
			color: $grey-10 !important;
		}
	}
}
