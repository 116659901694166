@import "~StyleVariables";

.EditAvailabilityTimeInput {
	$rootElement: &;

	&__outer {
		margin-left: 20px;
	}

	&__inner {
		position: relative;

		&--open input {
			border-radius: $border-radius $border-radius 0 0;
		}
	}

	&__list-wrapper {
		overflow: hidden;
		margin-top: -1px;
		z-index: 1000;
		left: 0;
		right: 0;
		position: absolute;
		background-color: $white;
		border: 1px solid $green;
		border-radius: 0 0 $border-radius $border-radius;
		box-shadow: $box-shadow-md;
		max-height: 0px;
		opacity: 0;
		transition-property: max-height, opacity;
		transition-timing-function: unset, ease-out;
		transition-duration: 0ms, 150ms;
		transition-delay: 150ms, 0ms;
	}

	&__inner--open #{$rootElement} {
		&__list-wrapper {
			transition-delay: 0ms, 150ms;
			max-height: 200px;
			opacity: 1;
		}
	}

	&__list {
		transition: all ease 150ms;
		overflow: hidden auto;
		font-family: $main-font-family;
		white-space: nowrap;
		max-height: 200px;
	}

	&__row {
		text-align: left;
		font-size: 14px;
		color: $grey-13;
		cursor: pointer;
		padding: 3px 5px;
		transition: all ease 200ms;

		&:hover:not(&--selected) {
			background-color: $medium-green;
			color: $white;
		}

		&--selected {
			background-color: $green;
			color: $white;
		}
	}
}
