@import "~StyleVariables";

.HandleDuplicates {
	grid-template-rows: auto 0 1fr 0;
	--modalContentWidth: 1100px;

	.EditorHeader {
		padding:  $space * 5;
		width: 100%;
	}

	&__scroll {
		overflow-x: hidden;
		overflow-y: hidden;

		&--horizontalScroll {
			overflow-x: scroll;
		}
		&--verticalScroll {
			overflow-y: scroll;
		}
	}

	.ExpandedTableTitles {
		.Text {
			width: 95px;
		}
	}

	&__headlineBlock {
		padding: $space * 10 0 $space * 10 0;
	}

	&__cardWrapper {
		padding-bottom: $space * 50;
	}

	.Card {
		border: 1px solid $grey-4;
		border-radius: $border-radius;
	}

	&__controls {
		height: 90px;
		width: 113px;
		background-color: $white !important;
		border-right: 1px solid $grey-4;
	}

	&__icon {
		animation: rotateBackAnimation;
		animation-iteration-count: 1;
		animation-duration: 400ms;
		transform: rotate(0deg);
		font-size: $h3-font-size;

		&--rotate {
			animation: rotateAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(180deg);
		}
	}

	.Table {
		border-top: 1px solid $grey-4;
	}

	.TableColumn {
		border-top: none;
		border-right: 1px solid $grey-4;
	}

	tr > th:first-child,
	tr > td:first-child {
		position: sticky;
		left: 0;
		z-index: 1;
	}

	&__hiddenCard {
		box-shadow: none;
	}

	&__hiddenCardButtonContainer {
		height: 90px;
		border-right: 1px solid $grey-4;
		width: 113px;
	}
}
