@import "~StyleVariables";

.SocialMediaPreview {
	padding: 15px 20px;
	transition: all ease 200ms;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	&:hover {
		background-color: $grey-2;
	}

	h2 {
		font-weight: $regularFontWeight;
		line-height: 17px;
		margin-bottom: 15px;

		b {
			font-size: 14px;
			padding-left: 5px;
		}
	}

	&__Image {
		position: relative;
		width: 65px;
		height: 65px;
		margin-bottom: 10px;
		overflow: hidden;
		border-radius: 3px;
		box-shadow: 0 1px 2px rgba($black, 0.2);

		&:hover {
			.SocialMediaPreview__EditImage {
				display: block;
				background-color: $blue;

				.Icon {
					color: $grey-2;
				}
			}
		}
	}

	&__EditImage {
		position: absolute;
		width: 100%;
		height: 100%;
		display: none;
		border: none;
		background: transparent;
		font-size: 24px;

		&--imageFailed {
			display: block;

			.Icon {
				color: $black;
			}
		}
	}

	&__Description {
		> p {
			max-height: 70px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box !important;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
	}

	.Headline {
		margin-bottom: 15px;
	}

	.Input__input {
		height: auto;
		line-height: 0;
		padding-bottom: 2px;
	}

	.Textarea__input {
		background-color: transparent;
		padding: 0;
	}

	.Textarea__input,
	.Input__input {
		border-bottom: 1px solid $grey-6;

		&:hover {
			border-bottom: 1px solid $grey-10;
		}

		&:focus {
			border-bottom: 1px solid $bright-blue;
		}
	}

	.Headline,
	.Text {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid transparent;
		transition: all ease 200ms;

		.Icon {
			display: none;
			font-size: 14px;
		}

		&:hover {
			border-bottom: 1px solid $grey-10;
			cursor: pointer;

			.Icon {
				display: inline-block;
			}
		}
	}

	&__Link-area {
		display: flex;
		position: absolute;
		bottom: 10px;
		width: 90%;
		transition: bottom cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		will-change: transform;

		&--hidden {
			bottom: -100%;
		}

		.Link {
			margin-right: 5px;
		}

		.Button.last {
			margin-left: auto;

			.Icon {
				margin-right: 5px;
			}
		}
	}
}
