// This ladies and gentlemen, is an excellent example of how not to write css
// Nothing is scoped to the actual component, so it's all global. If we scope this now we don't know what will break
@import "../../../../styles/_variables";
@import "../../../../styles/_mixins";

.col-md-6 .Select-input > input {
	max-width: 166px;
}

// Remove all references to .Select* classes when we get rid of react-select
.Select--multi .Select-value {
	border: none;
	border-radius: 3px;
	font-size: 12px;
}

.appointment-fields {
	.Select-menu-outer {
		z-index: 10000;
	}

	.ai-agenda-toggle button {
		min-width: fit-content;
	}

	.NumberInput {
		.Input {
			width: 100%;
		}
	}
}

.Select-control {
	border-radius: 3px;
}

.Select--multi .Select-value {
	&.user-token,
	&.conflicting-token,
	&.contact-token {
		font-size: 12px;
		border-radius: 3px;
		border: none !important;
		margin-left: 5px;
		margin-top: 5px;

		.Select-value-icon {
			border: none;
			padding: 2px 5px;
		}

		.Select-value-label {
			border: none;
			padding: 2px 5px 2px 0;
		}
	}
}

.account-select-wrapper {
	display: flex;

	.ClientContactSelect {
		width: 100%;
		.Input__input {
			height: 36px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.select2-inside {
		width: 32px;
		border-radius: 3px 0 0 3px;
	}

	.select2-container {
		width: 483px;

		.select2-default,
		.select2-choice {
			height: 36px;
			line-height: 22px;
			border-radius: 0px 3px 3px 0px;
		}
	}
}

.Select-value.user-token > div {
	background-color: $green !important;
	& > div {
		color: $white !important;
	}
}

.Select-value.conflicting-token {
	background-color: $orange !important;
	& > div {
		color: $white !important;
	}
}

.Select-value.contact-token > div {
	background-color: $bright-blue !important;
	& > div {
		color: $white !important;
	}
}

.Select-value.remove-state > div {
	background-color: $red !important;
}

.Select--multi .Select-clear-zone,
.Select-clear-zone,
.Select-loading-zone {
	width: 12px;
	padding: 0 10px;
}

span.Select-arrow-zone {
	border-left: 1px solid $grey-7;
	padding-left: 5px;
}

.Select-clear {
	font-size: 12px;
}

.select-hover-option {
	cursor: pointer;
	transition: all 0.1s;

	&:hover {
		color: #fff;
		background-color: $bright-blue !important;
	}
}

.has-error .Select .Select-control {
	border-color: $red;
}

.react-select-participantsComponent--list {
	overflow-x: hidden;
	.Select-option {
		&.is-focused {
			background-color: $bright-blue;
		}

		&:hover {
			background-color: $grey-1;

			&.is-focused {
				color: $grey-11;
			}
		}
	}
}

.negative-indent-10 {
	margin-right: -10px;
}

.appointment-weblink-toggle {
	height: 36px;
	padding-top: 5px;
}

.appointment-weblink-url {
	height: 36px;
	@include box-shadow(0 1px 3px $grey-8);

	& > .Input input {
		background: $white;
		color: $bright-blue;
		font-style: normal;

		&:hover {
			cursor: pointer;
		}
	}

	& .Icon {
		color: $bright-blue;

		&.Icon-copy {
			float: right;
			position: relative;
			top: -24px;
			right: 10px;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.appointment-rooms {
	max-height: 190px;
	overflow-y: auto;

	.Loader {
		margin: 10px auto;
	}

	.appointment-rooms-error {
		text-align: center;

		.Text:first-child {
			font-size: $h3-font-size;
		}
	}
}

.appointment-room {
	.Checkbox {
		position: relative;
		top: 15px;
	}

	.appointment-room-info {
		position: relative;
		top: -8px;
		left: 30px;

		span {
			display: block;
		}
	}
}

.appointment-room:not(:last-of-type) {
	height: 40px;
}

.appointment-modal-footer-delete-tooltip {
	float: left;
}

.cancel-appointment-button {
	float: left;
	margin-right: 2 * $space;

	.inline-confirm-modal {
		.inline-confirm-container {
			bottom: 0;
			top: unset;
			left: 0;
			right: unset;
		}
	}
}

.appointment-modal-footer-cancellation-message {
	float: left;
	line-height: 34px;
	margin-right: 2 * $space;
}
