@import "~StyleVariables";

.OrderCloseDatePicker {
	opacity: 0;
	width: 250px;
	visibility: hidden;
	transition: all 0.3s;
	transform: translateY(20%);

	&--visible {
		opacity: 1;
		visibility: visible;
		transform: translateY(0%);
	}

	&__buttonContainer {
		display: flex;
		justify-content: flex-end;
		border-top: 1px solid $grey-2;
	}

	&__leaveItButton,
	&__todayButton {
		margin: 0 !important; // To override .up-modal.default .up-modal-controls .up-btn
	}

	&__pastDateHeaderText,
	&__futureDateHeaderText {
		text-align: left;
	}
}
