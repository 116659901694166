@import "~StyleVariables";

.UpdateClientDuns {
	.up-panel {
		background-color: transparent;
		padding: 0px;

		.list-row {
			position: relative;
			background: white;
			padding: 5px 8px;
			margin-bottom: 3px;
			border: 1px solid $grey-4;

			&:hover {
				border-color: $grey-8;
			}

			.btn.up-btn {
				position: absolute;
				right: 5px;
			}
		}
	}
}
