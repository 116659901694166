@import "../../../../../styles/_variables.scss";

.EasyBookingSettingsEmbedded {
	&__infoLink {
		margin-left: 5px;
	}

	&__titleFieldHeader {
		display: inline-flex;
		width: 50%;
		justify-content: space-between;
	}

	&__titleField {
		padding-top: 5px;

		.Input {
			width: 50%;
			input {
				border-radius: 4px;
			}
		}

		.Label {
			font-weight: bold;
		}

		.Progress {
			width: 15px;
			height: 15px;
		}
	}

	&__firstRow {
		display: inline-flex;
		justify-content: flex-start;
	}

	&__inputSizeBox {
		padding-top: 5px;
		margin-right: 20px;
		.Label {
			font-weight: bold;
		}
	}

	&__inputPixel {
		padding-top: 5px;
		min-width: 130px;
		max-width: 130px;

		input {
			width: 70px;
			border-radius: 4px 0px 0px 4px;
		}

		.Label {
			font-weight: bold;
		}

		.input-group-addon {
			width: 40px;
			padding: 9px 12px;
			border: none;
		}
	}

	&__toggleButton {
		display: flex;
		align-items: flex-end;
		margin: 5px 0 8px 0;

		.Label {
			margin-left: 5px;
			margin-bottom: 0;
			font-weight: unset;
		}
	}

	&__toggleButton:last-child {
		margin-left: 16px;
	}

	&__Editor {
		margin-top: 35px;
		height: 120px !important;
	}

	&__fullEditor {
		position: relative;

		.CopyButton {
			position: absolute;
			bottom: 12px;
			right: 13.5px;
			width: fit-content;
		}
	}

	&__embedEditor {
		height: 100%;
		display: flex;
		justify-content: center;
		margin-right: 20px;

		&--company iframe {
			width: 90%;
			height: 420px;
			border: 1px solid $grey-5 !important;
			border-radius: 4px;
		}
	}

	&__previewTabs {
		bottom: -6px;
	}

	&__previewFrame,
	&__companyProfileHeader {
		flex-grow: 1.5;
	}
}
