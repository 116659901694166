@import "~StyleVariables";

.MoveOrCloseActivity {
    .btn-link.btn-link.btn-link.btn-link {
        color: $bright-blue;
    }

    .DateInput {
        width: 70%;
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .TimeInput {
        width: 30%;
        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .Checkbox {
        background: $white;
        border: $border;

        &--checked {
            background: $green;
        }
    }

    .select2-choice {
        border-color: $grey-6;
        box-shadow: inset 1px 1px 2px 0 rgba($black, .1);
    }

    .select2-arrow.select2-arrow {
        border-left: 1px solid $grey-6;
    }
}