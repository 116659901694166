@import "~StyleVariables";

.btn.btn--google {
	$padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #4285f4; // Googles exact color
	padding: 0;
	padding-left: 0 !important;
	padding-right: $padding;
	border: 0;
	text-shadow: none;

	.btn__google-logo {
		height: $up-btn-height - 2px;
		width: $up-btn-height + 2px;
		margin-right: $padding;
		padding: 0;
		border: 0;
	}

	&.btn--google-light {
		background-color: $white;
		.btn__google-logo {
			background: url("img/btn_google_light_normal_ios.svg") transparent -3px 50% no-repeat;
		}

		&:disabled {
			background-color: rgb(206, 206, 206);
		}
		&:disabled .btn__google-logo {
			background: url("img/btn_google_light_disabled_ios.svg") transparent -3px 50% no-repeat;
		}

		&:hover .btn__google-logo {
			background: url("img/btn_google_light_pressed_ios.svg") solid -3px 50% no-repeat;
		}

		&:focus {
			background-color: $white;
		}
		&:focus .btn__google-logo {
			background: url("img/btn_google_light_focus_ios.svg") transparent -3px 50% no-repeat;
		}
	}

	&.btn--google-dark {
		background-color: #4285f4;

		.btn__google-logo {
			background: url("img/btn_google_dark_normal_ios.svg") transparent -3px 50% no-repeat;
		}

		&:disabled {
			background-color: rgb(206, 206, 206);
		}
		&:disabled .btn__google-logo {
			background: url("img/btn_google_dark_disabled_ios.svg") transparent -3px 50% no-repeat;
		}
		&:hover .btn__google-logo {
			background: url("img/btn_google_dark_pressed_ios.svg") transparent -3px 50% no-repeat;
		}

		&:focus .btn__google-logo {
			background: url("img/btn_google_dark_focus_ios.svg") transparent -3px 50% no-repeat;
		}
	}
	&.btn--google-dark:hover {
		background-color: #4285f4; // Googles exact color
	}

	&.btn-sm .btn__google-logo {
		height: $up-btn-height-sm;
		width: $up-btn-height-sm;
	}

	&.btn-lg .btn__google-logo {
		height: $up-btn-height-lg;
		width: $up-btn-height-lg;
	}

	&.btn-xl.btn__google-logo {
		height: $up-btn-height-xl;
		width: $up-btn-height-xl;
	}

	.Button__loader-wrap span {
		visibility: hidden;
	}
}
