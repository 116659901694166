@import "~StyleVariables";

.AppSidebar {
	$width: 48px;
	position: fixed;
	top: 82px;
	left: 0;
	bottom: 0;
	width: $width;
	z-index: 5030;
	background-color: $grey-1;
	border-right: 1px solid $grey-4;
	display: flex;
	flex-direction: column;
	padding-top: $space;

	.Icon-exclamation {
		font-size: 60%;
		padding-top: 3.5px;
	}

	&__button-wrap {
		position: relative;

		&--active {
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: 100%;
				height: 100%;
				background-color: $green;
				border-radius: $border-radius;
				opacity: 1;
				transform: scale(1);
				animation-name: unread-pulse;
				animation-duration: 2s;
				animation-iteration-count: infinite;
			}

			.ClickableItem:hover,
			.ClickableItem {
				position: relative;
				background-color: $green !important;
				color: $super-light-green !important;
			}
		}

		&__active-icon {
			position: absolute;
			color: $super-light-green;
			top: 10px;
			right: 10px;
			font-size: 10px;
		}
	}

	&__add-dropdown {
		overflow-y: auto;
		max-height: calc(100vh - 160px);
	}

	&__dynamiclinks-dropdown {
		overflow-y: auto;
		max-height: calc(100vh - 248px);
	}

	&__dropdown {
		border: 0;
		padding: 0;
		margin: 0;
		position: absolute;
		left: 42px;
		top: -20px;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 300ms;
		display: block;
		z-index: 1000;
		width: 250px;

		&[open] {
			visibility: visible;
			opacity: 1;
			top: 0;
		}

		& > .Card {
			box-shadow: 0 8px 12px rgba($black, 0.2);
			border: 1px solid $grey-3;
			border-radius: $border-radius;
		}
	}

	&__dropdown-item {
		padding: 0 ($space * 3);
		line-height: 32px;
		height: 32px;
		transition: all ease-out 300ms;
		cursor: pointer;

		&:hover {
			background-color: $grey-3;
		}
	}

	&__link-img {
		max-width: 16px;
		max-height: 16px;
		align-self: center;
	}

	@keyframes unread-pulse {
		0% {
			opacity: 0;
			transform: scale(1);
		}
		60% {
			opacity: 0;
			transform: scale(1);
		}
		61% {
			opacity: 1;
			transform: scale(1);
		}
		100% {
			opacity: 0;
			transform: scale(1.5);
		}
	}
}
