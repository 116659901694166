@import "~StyleVariables";

.FlowStepSendMail {
	&__templateName {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__trash {
		padding: 5px;
		opacity: 0;
		transition: all 200ms ease-in-out;
		position: absolute;
		right: 0;
	}

	&__trash:hover {
		color: $black;
	}

	&:hover {
		.FlowStepSendMail__trash {
			opacity: 1;
		}
	}

	&__behaviorBlock {
		width: calc(100% + 38px);
		border-top: 1px solid $grey-4;
		padding: 8px 8px 0px 8px;
		margin-left: -8px;

		.Text {
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	&__deleteTooltip {
		position: absolute;
		right: 0;
		top: 0;
		padding: 8px;
		margin-right: 8px;

		.Icon {
			position: inherit;
			padding: 0px;
		}
	}
}
