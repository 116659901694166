@import "../../../styles/_variables";

.DataSourceBuyButton  {
	transition-property: none;
}

.DataSourceBuyButton .Icon {
	margin: 0 5px 0 0;
}

.DataSourceBuyButton-expanded {
	box-shadow: 1px 0 0 0 $grey-4, 0 3px 6px 0 rgba(30, 37, 46, 0.2) !important;
}

.DataSourceBuyButton__dropdown {
	text-align: left;

	.DropDownMenu__content {
		right: 1px;
		min-width: auto;
		width: 200px;
		min-width: 200px;
		margin-top: -2px;

		.CardContent {
			padding: 0;

			.Row {
				padding: 5px 10px;

				.Text--sm {
					line-height: 16px;
				}
			}

			.Row:last-child {
				padding: 10px;
				background-color: $grey-4;
				display: flex;
				justify-content: center;
			}

			p {
				margin: 0;
			}
		}
	}
}

.DataSourceBuyButton__PriceRow {
	height: 42px;
	cursor: pointer;

	input {
		vertical-align: top;
		margin-top: 1px;
	}

	&:hover {
		background-color: $grey-1;
	}

	.Help {
		display: inline-block;
	}
}
