.InsightsExploreTitle {
	margin: 20px 0 5px 0;
}

.InsightsExploreColumn {
	padding: 5px;
	max-width: 25%;
	height: 300px;
	display: flex;
}

.InsightsExploreColumn--Smaller {
	height: 125px;
}

.InsightsExploreCard {
	flex: 1;
	display: flex;
}

.InsightsExploreColumn--Card__center-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.InsightsExploreCardTitle {
	margin: 10px 0;
	width: 100%;
	text-align: center;
}

.FullscreenModal--scrollable {
	height: 100%;
	overflow-x: scroll;
}

.InsightsExploreCardDescription {
	line-height: 1.4;
}

#create-report-modal .CreateReportModalContent {
	max-width: 1000px;
	margin: 0 auto;
	padding-top: 44px;
}

/* TODO: REMOVE DURING CLEAN-UP */
.up-modal .CreateReportModalContent {
	padding-top: 0px !important;
}
