@import "~StyleVariables";
.DashboardButtonGroup {
	min-width: 725px;
	z-index: 10;
	display: flex;
	flex-direction: column;
	padding: 14px 14px 8px 14px;
	box-shadow: 0 1px 2px 0 rgba($black, 0.2);
	background-color: $white;
	border-radius: 9px;
	position: relative;
	&--gotContent {
		box-shadow: 0 2px 6px 2px rgba($black, 0.2);
	}
	> .ButtonGroup {
		z-index: 2;
		width: 100%;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		margin-bottom: 10px;
		background-color: $grey-4;
		border-radius: $border-radius;

		> div {
			flex: 1;
		}

		.Button {
			width: 100%;
			box-shadow: none;
		}
	}

	.DropDownMenu__content {
		animation: fadeSlide 0.2s ease-in-out;
		.Button {
			width: auto;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
		// this doesn't look aligned if not margin left
		.Icon-opportunity {
			margin-left: -2px;
		}
	}

	&__comment:has(.public-DraftEditorPlaceholder-root) {
		height: 32px;
	}

	&__footer {
		display: none;
		justify-content: space-between;

		&.Animate--visible {
			display: flex;
		}
	}
	&__dropdown-actions {
		display: flex;
		flex-direction: column;
	}

	.Textarea__input,
	.DraftEditor-editorContainer {
		width: 85%;
		height: 100%;
		margin-bottom: 0px;
		overflow-y: hidden;

		&::placeholder {
			line-height: 24px;
		}
	}
	.Progress {
		height: 15px;
		width: 15px;
		position: absolute;
		top: -40px;
		right: 11px;
	}
	.FakeTooltip {
		margin-top: 48px;
		margin-left: -8px;

		&__arrow {
			left: calc(50% - 88px);
		}
	}

	&__end {
		margin-bottom: 115px;
	}
	&__comment-wrapper {
		height: 50px;
		max-height: 50px;
	}
	&__content {
		z-index: 1;
		position: relative;
	}
	> .generic-avatar {
		position: absolute;
		right: 14px;
		bottom: 14px;
	}
	&__confirm {
		z-index: 12;
		position: absolute;
		width: 100%;
		top: 50%;
		display: flex;
		align-items: center;
		flex-direction: column;
		.Checkbox {
			border-radius: 36px;
		}
	}
	.MentionsInput {
		z-index: 2;
	}
	.FakeTooltip {
		z-index: 1;
	}
	@keyframes fadeSlide {
		from {
			opacity: 0;
			transform: translateY(-15%);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
