#admin-page-single-sign-on {
	.input-row .Column {
		padding: 15px;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	.textarea-large {
		height: 172px;
	}
}
