@import "variables";

#find-prospects-card {
	$sidebar-width: 300px;

	#form-wrapper {
		margin-right: $sidebar-width;
		overflow: hidden;
		border-right: 1px solid $grey-8;
		padding-top: $padding;

		h2,
		h3 {
			display: block;
		}

		h2 {
			margin: $padding;
			margin-top: 0;
		}

		h3 {
			@include h3();
			margin-bottom: 5px;
		}

		table.main-table {
			margin-top: 10px;

			tr:last-child td {
				border-bottom: none;
			}
		}

		.form-section {
			border-bottom: 1px solid $grey-2;
			padding: $padding 0;
			padding-top: 0;
			margin-bottom: $padding * 2;

			.slider-wrap {
				padding: 0 50px 0 7px;

				.slider {
					width: 100%;
				}

				.slider-labels {
					width: 100%;
					display: table;

					.slider-label {
						display: table-cell;
						position: relative;

						.label-text {
							position: relative;
							left: -50%;
							text-align: center;
							width: 100%;
							display: block;
						}

						&:last-child .label-text:last-child {
							position: absolute;
							right: -50%;
							top: 0;
							left: initial;
						}
					}
				}
			}
		}

		.input-group.slider-inputs {
			input {
				width: 80px;
				display: inline-block;
			}
		}
	}

	#widget-area {
		position: fixed;
		float: right;
		width: $sidebar-width;
		right: 15px;

		#confirm-container {
			width: $sidebar-width;
			top: 0px;
			right: 0px;
			padding: $padding;

			#get-prospects-btn {
				margin: 10px 0;
				display: block;
			}
		}
	}
}
