@import "~StyleVariables";

.ListViewQuickSearch.Input {
	$height: 30px;
	height: $height;
	width: 34px;
	transition: width 250ms ease-out;

	.Input__input {
		padding-left: 26px;
		height: $height;
		border-radius: $border-radius;
	}
	.Input__icon {
		height: $height;
		line-height: $height;
		color: $grey-11;
	}
	&:hover,
	&:focus-within,
	&:not(:has(input[value=""])) {
		width: 155px !important;
		.Input__input {
			padding-left: 28px;
		}
	}
}
