@import "~StyleVariables";

.Hotspot {
	animation: all ease-in-out 400ms;
	display: flex;

	&:hover {
		cursor: pointer;
	}

	&__modal.Modal.WhiteModal {
		position: absolute;
		z-index: 99999;
		display: flex;
		max-height: 800px;
		width: auto;
	}

	&__modal--top.Modal.WhiteModal {
		transform: translateY(-100%);
	}

	&__modal--left.Modal.WhiteModal {
		transform: translateX(-100%);
		margin-left: -40px;
	}

	&__modal-content {
		height: 100%;
		text-align: left;
	}

	&__modal-footer {
		border-top: 1px solid $grey-6;
	}

	&__dot-wrapper {
		height: 20px;
		width: 20px;
		
		&--hidden {
			display: none;
		}
	}

	&__dot {
		position: absolute;
		width: 8px;
		height: 8px;
		background-color: $medium-blue;
		border-radius: 50%;
	}

	&__first-circle {
		position: absolute;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background-color: $medium-blue;
		animation: hotspotPulse 2s infinite;
		opacity: 0.3;
		transform: scale(0);
	}

	&__second-circle {
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: $medium-blue;
		animation: hotspotPulse 2s infinite;
		opacity: 0.3;
		transform: scale(0);
	}

	@keyframes hotspotPulse {
		50% {
			transform: scale(1);
		}
	}
}
