@import "~StyleVariables";

.AssignModalLeadSelect {
	&__tabs {
		width: 100%;

		// Button with tooltip gets uncentered when stretched. This fixes
		.Tooltip {
			flex-grow: 0.5;
			> .Button {
				width: stretch;
			}
		}
	}
}
.AssignModalAssign__step__user-role-row {
	height: 56px; // Set fixed height to avoid flickering when changing roles
}
