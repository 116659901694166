@import "~StyleVariables";

.OpportunityTodosTimepicker {
	.Input__input {
		border-radius: 0px;
	}
	&--active {
		display: flex;
		justify-content: flex-start;
		gap: 8px;
		align-items: center;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-evenly;

		&--labels {
			.Label {
				height: 34px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;
			}
		}
	}

	&__content-input {
		display: inline-flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 4px;
		height: 34px;
	}

	#trigger:hover,
	#close-btn:hover {
		background-color: $grey-3;
	}

	.TimeInput {
		width: 40px;
	}

	.DateInput {
		width: 90px;

		&__popup {
			margin-top: 8px;
		}
	}

	.DateInput,
	.TimeInput {
		display: inline-block;
		height: 34px;

		.Input__input {
			border: none;
			box-shadow: none;
			border-bottom: 1px solid $grey-6;
			height: 34px;
			line-height: 17px;
			font-size: 14px;
			padding: 0;

			&:focus {
				border-color: $bright-blue;
			}
		}
	}
}
