@import "~StyleVariables";

.AccountPlanSidebar, .AccountPlanSidebarInEditOrder {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;

	&__addPlan {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $space * 2 0;
		gap: $space * 6;

		&__title {
			flex: 1;
		}

		.Icon-plus, .Icon-chevron-up, .Icon-chevron-down {
			cursor: pointer;
		}
	}

	&__plansCollapsible {
		width: 100%;
		overflow: hidden;

		&.Animate--visible {
			overflow: visible;
		}
	}

	&__noPlans {
		display: flex;
		flex-direction: column;
		gap: $space * 3;
		padding: $space * 4;
	}

	&__plan {
		display: flex;
		flex-direction: column;
		width: 100%;
		transition: background-color 200ms ease-in-out;

		&__info {
			padding: $space 23px $space 15px;
			margin-left: -15px;
			margin-right: -15px;

			&:hover {
				cursor: pointer;
				background-color: $grey-1;
	
				.Icon {
					opacity: 1;
				}
			}

			&__name {
				display: flex;
				justify-content: space-between;
				align-items: center;
	
				.Tooltip {
					.Text {
						max-width: 230px;
					}
				}
	
				> .Icon {
					transition: opacity 200ms ease-in-out;
					opacity: 0;
				}
			}
	
			&__valueAndDate {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

	}
}

.AccountPlanSidebarInEditOrder {
	border-bottom: 1px solid $grey-4;

	&__plan {
		&__info {
			&:hover {
				border-right: 1px solid $grey-6;
			}
			&__name {
				.Tooltip {
					.Text {
						max-width: 180px;
					}
				}
			}
		}
	}
}