@import "~StyleVariables";
.EditTodo {
	height: 100%;
	overflow-y: auto;
	background-color: $grey-1;

	&__contentWrapper {
		max-height: fit-content;
		min-width: 400px;
		display: flex;
		flex-direction: column;
	}
	
	form {
		flex-grow: 1;
		display: flex;
	}

	.Loader {
		margin-top: $space * 8;
		align-self: center;
	}

	&__formObserverWrapper {
		height: 100%;
	}

	&__saveButtonWrapper {
		top: auto;
		position: sticky;
		bottom: 0;
		z-index: 4;
		margin-top: auto;

		&--notVisible {
			.Button {
				height: 0;
				margin: 0;
				padding: 0;
				overflow: hidden;
			}
		}
		&--visible {
			box-shadow: 0 0.5px 5px 0 rgba($black, 0.2);
			background-color: $white;
			.Button {
				overflow: hidden;
			}
		}
	}
	.RelationSelect {
		margin-top: 15px;
	}
	.TodoTimePicker {
		margin-top: 10px;
	}
	.DrawerHeader {
		position: sticky;
		top: 0;
		z-index: 100;

		.HeaderUserSelect {
			width: 82px;
			&--open {
				width: 180px;
			}
		}

		.Icon-activity {
			font-size: 18px;
		}
		:not(.inline-confirm-tab) > .Button {
			width: 50px;
			height: 50px;
		}
		.Title {
			font-size: 16px;
		}

		.inline-confirm-tab,
		.inline-modal-hidden {
			vertical-align: top;
			height: $header-height - 1px;
			line-height: $header-height - 1px;
			width: 50px;
			text-align: center;
		}

		.inline-confirm-container {
			top: 49px;
		}
	}
	.Table {
		box-shadow: 0 1px 2px 0 rgba(30, 37, 46, 0.2);
	}
	.TableRow {
		display: block;
		width: 100%;
	}
	.TableColumn {
		width: 100%;
		border: 0px;
		background-color: $white;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		justify-content: center;
	}

	&__enter-to-save {
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 100ms;

		&--visible {
			visibility: visible;
			opacity: 1;
		}
	}

	&__delete {
		height: 48px;
	}

	&__quickLinks {
		border-right: 1px solid $grey-4;
		box-shadow: $box-shadow-xs;
	}

	.CustomFields {
		.Input {
			width: 100%;
		}
	}
}

.HiddenSelect--edit-todo-select {
	width: 178px !important;
}
