@import "variables";
@import "mixins";
@import "animations";

.up-availability-table {
	$height: 170px;
	$header-height: 45px;
	$row-height: 34px;

	position: absolute;
	width: 100%;
	height: $height;
	bottom: -$height;
	left: 0;
	z-index: 1002;
	background-color: $grey-4;
	visibility: hidden;
	cursor: default;
	@include disable-user-select();
	@include opacity(0);
	@include transition(all ease 200ms);

	&.partly-visible,
	&.visible {
		visibility: visible;
		bottom: 57px;
		@include opacity(1);
	}

	&.visible {
		top: $modal-header-height;
		height: auto;

		.availability-header .right {
			border-color: $white;
		}
	}

	&.partly-visible {
		height: $height;
	}

	&:before {
		content: "";
		background-color: $white;
		width: $modal-sidebar-width;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		border-right: 1px solid $grey-8;
		z-index: 1000;
	}

	.availability-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		border-top: 1px solid $grey-8;
		border-bottom: 1px solid $grey-8;
		height: $header-height;
		z-index: 1001;

		.left,
		.right {
			position: absolute;
			top: 0;
			height: $header-height - 2px;
		}

		.left {
			width: $modal-sidebar-width;
			padding: 10px 15px;

			h2 {
				@include h2();
				font-size: 18px;
				display: inline-block;
			}

			button {
				position: absolute;
				right: 0;
				top: 7px;
			}
		}

		.right {
			right: 0;
			left: $modal-sidebar-width;
			background-color: $white;
			text-align: center;

			h2 {
				display: inline-block;
				font-size: 14px;
				padding-top: 7px;
				margin: 0 10px;
			}

			button {
				color: $grey-8;
				background-color: transparent;
				border: none;
				margin: 0;
				padding: 0;
				font-size: 12px;
				width: 20px;
				text-align: center;
			}

			.availability-hours {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 14px;
				font-size: 10px;

				div {
					position: absolute;
					bottom: 0;
					left: 0;
					width: (100% / 24);
					display: inline-block;
					text-align: right;
					height: 2px;
					border-left: 1px solid $grey-8;

					&.faded {
						color: $grey-8;
					}

					span {
						position: absolute;
						bottom: 0;
						left: -5px;
					}

					@for $i from 2 through 25 {
						&:nth-child(#{$i}) {
							left: (60 * ($i - 1)) / 1440 * 100%;
						}
					}
				}
			}
		}
	}

	.availability-hover-area-wrap {
		position: absolute;
		top: $header-height;
		left: $modal-sidebar-width;
		right: 0;
		bottom: 0;
		background-color: transparent;
		z-index: 1002;

		.hover-indicator {
			position: absolute;
			display: none;
			background-color: $bright-blue;
			left: 0;
			top: 0;
			bottom: 0;
			width: 1px;
			z-index: 1001;

			&.visible {
				display: block;
			}

			.appointment-length {
				height: 100%;
				background-color: rgba($bright-blue, 0.5);
			}

			.current-time {
				position: absolute;
				background-color: $bright-blue;
				width: 30px;
				font-size: 10px;
				text-align: center;
				color: $white;
				padding: 1px 0;
				top: -16px;
				left: 0;
				@include transition(left ease 200ms);

				&.left {
					left: -30px;
				}
			}
		}

		.hover-area {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1002;
			background-color: transparent;
		}
	}

	.availability-main {
		position: absolute;
		left: 0;
		right: 0;
		top: $header-height;
		bottom: 0;
		z-index: 1001;
		overflow: hidden;
		overflow-y: scroll;

		&:before {
			content: "";
			background-color: rgba($white, 0.5);
			position: absolute;
			left: 47.6%;
			right: 22.8%;
			top: 0;
			bottom: 0;
		}

		& > table {
			table-layout: fixed;
			position: relative;
			z-index: 1000;

			tr.user-row {
				border-bottom: 1px solid $grey-6;
				font-size: 13px;
				@include transition(all ease 200ms);

				&.warning {
					td {
						&.name-col {
							padding: 2px 10px;
							border-left-color: $orange;
							background-color: $super-light-yellow;

							.fa {
								display: inline-block;
								vertical-align: middle;
							}

							.user-name {
								padding-left: 5px;
								vertical-align: middle;
							}

							.subtitle {
								display: block;
							}
						}
					}
				}

				td {
					height: $row-height;

					&.name-col {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						padding: 8px 10px;
						width: $modal-sidebar-width - 2px;
						border-left: 2px solid $white;
						position: relative;

						.fa {
							display: none;
							color: $orange;
							line-height: 28px;
							vertical-align: top;
						}

						.user-name {
							line-height: 13px;
						}

						.subtitle {
							display: none;
							color: $orange;
							font-size: 11px;
							line-height: 11px;
							position: absolute;
							bottom: 4px;
							left: 31px;
						}
					}

					&.appointments {
						position: relative;
						height: 100%;
						width: 100%;
						overflow: hidden;

						.appointment-block {
							top: 0;
							left: 0;
							position: absolute;
							height: 100%;
							min-width: 2px;
							color: $white;
							text-align: center;
							padding: 10px 0;
							font-size: 11px;
							background-color: rgba($blue, 0.2);
							@include border-radius($border-radius);

							&.current {
								background-color: rgba($bright-blue, 0.8);
								z-index: 1000;
								&.warn {
									background-color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}
}
