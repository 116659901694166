@import "~StyleVariables";

.EditSocialEventDetails__InputRow {
	margin: 0 -10px;
}

.EditSocialEventDetails__InputColumn {
	padding: 10px;
}

.EditSocialEventDetails__label {
	display: inline-block;
}
.EditSocialEventDetails__progress {
	width: 15px;
	height: 15px;
	margin-right: 3px;
	margin-top: 2px;
	float: right;
}

.EditSocialEventDetails__end-date-btn {
	margin-top: 21px;
}

.EditSocialEventDetails__reset-tickets {
	padding: 0;
	float: right;
	height: 16px;
	line-height: 1;
}

.EditSocialEventDetails__waiting-list-toggle {
	margin-top: 17px;
}

.EditSocialEventDetails__On-demand-column {
	margin-top: $space * 2;
	margin-left: $space;
}

.EditSocialEventDetails__On-demand-column-toggle {
	margin-top: $space * 2;
	padding: $space * 2;
	flex: 0 0 40px;
	align-items: center;
}

.EditSocialEventDetails__On-demand-link {
	padding: $space * 2;
	margin-top: $space * 2;

	&--label {
		font-weight: $boldFontWeight;
	}
	&--text {
		font-weight: $regularFontWeight;
		font-size: $modal-font-size;
	}
}
