.FormComponent {
	&__label {
		min-height: 17px;
	}

	&__labels-container {
		display: flex;
		justify-content: space-between;

		&--has-progress {
			.Label {
				width: 100%;
			}
		}
	}

	&__message {
		min-height: 18px;
	}

	.select2-container {
		display: block;
	}
}
