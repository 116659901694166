@import "~StyleVariables";

.EditPhonecallActivityOutcome {
	background-color: $grey-1;
	height: 100%;
	display: flex;
	flex-direction: column;

	&__goalTitle {
		margin-bottom: 4px;
	}

	&__goalInput {
		margin-bottom: 16px;
		align-items: center;

		.Button {
			padding-right: 0px;
			padding-left: 0;
		}
	}

	&__callBlockBottom {
		margin-top: auto;
	}

	&__inner {
		overflow: hidden;
		overflow-y: auto;
	}

	.EditPhonecallCallBlock {
		margin-bottom: -1px;
	}

	.ActivityOutcomes {
		margin-bottom: 8px;
	}

	.ModalHistoryLog {
		padding: 0 20px;
	}
}
