@import "../MultiActionModal.scss";

@include ModalTagList("CreateActivityAction");

.CreateActivityAction {
	.NumberInput {
		.Input {
			width: 100%;
		}
	}

	.TimeInput__listContainer,
	.DateInput__popup {
		display: none;

		&--open {
			display: unset;
			z-index: 1010;
		}
	}
}
