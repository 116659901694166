@import "~StyleVariables";

.MailEditorReviewFileUpload {
	border: 1px solid $white;
	height: 100%;

	&--active {
		min-height: 200px;
		border: 1px solid $bright-blue;

		.MailEditorReviewFileUpload__drop-icon {
			visibility: visible;
			opacity: 1;
		}
	}

	@keyframes animate-width {
		0% {
			rx: 40;
		}
		50% {
			rx: 50;
		}
		100% {
			rx: 40;
		}
	}

	@keyframes animate-bounce {
		0% {
			transform: translateY(0%);
		}
		50% {
			transform: translateY(10%);
		}
		100% {
			transform: translateY(0%);
		}
	}

	&__drop-icon {
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: $white;
		z-index: 1002;
		text-align: center;
		padding-top: 30px;

		svg.file-drop-icon .shadow {
			animation: animate-width 2s ease-out infinite;
		}

		svg.file-drop-icon .card {
			animation: animate-bounce 2s ease-out infinite;
		}
	}
}
