.ModalTagListStyles {
	position: absolute;
	visibility: hidden;
	top: 0;
	right: 0;
	height: 100%;
	width: var(--app-babel-components-modals-modalTagList-width);
	z-index: -1;

	transition: all 300ms cubic-bezier(0.8, 0, 0.8, 1);

	&--translateTagsList {
		visibility: visible;
		transform: translateX(var(--app-babel-components-modals-modalTagList-width));
	}

	#tag-list {
		top: 0;
		height: 100%;
		width: var(--app-babel-components-modals-modalTagList-width);

		#hide-tags-btn {
			position: absolute;
			top: 5px;
			right: 10px;
		}

		> div,
		ul,
		h2,
		p {
			width: var(--app-babel-components-modals-modalTagList-width) !important;
		}
	}
}
