@import "variables";
@import "mixins";

.up-paginator {
	$size: 24px;
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: inline-block;
	@include box-shadow(0px 1px 2px rgba($black, 0.1));

	li {
		display: inline-block;

		&:first-of-type a {
			@include border-radius($border-radius 0 0 $border-radius);
		}

		&:last-of-type a {
			@include border-radius(0 $border-radius $border-radius 0);
		}
		&.active a:hover,
		&.active a {
			background-color: $green;
			border-color: $green;
		}

		a {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			font-size: 10px;
			line-height: $size;
			height: $size;
			font-weight: $boldFontWeight;
			background-color: $white;
			padding: 0 10px;
			border-right: 1px solid $grey-4;
			color: $green;

			&:focus,
			:active {
				text-decoration: none;
			}

			&.current {
				background-color: $green;
				color: $white;
			}

			&.number {
				padding: 0 10px;
			}

			&.disabled {
				&.arrow {
					//	@include opacity(0);
					cursor: default;
					color: $grey-8;
				}
			}

			&.arrow {
				width: $size;
				font-size: 14px;
			}

			&:hover {
				color: $green;
				background-color: $grey-1;
				text-decoration: none;

				&.current,
				&.arrow {
					text-decoration: none;
					background-color: $green;
					color: $white;

					&:hover,
					:focus,
					:active {
						text-decoration: none;
					}
				}
			}
		}
	}
}

.limit-wrap {
	display: block;
	margin: 10px auto;

	.dropdown {
		display: inline-block;
	}

	.dropdown-toggle:active,
	.dropdown-toggle:focus,
	.dropdown-toggle:hover,
	.dropdown-toggle {
		display: inline;
		line-height: inherit;
		height: 20px;
		background-color: transparent !important;
		padding: 0 3px;
	}

	.dropdown-menu {
		$width: 63px;
		text-align: left;
		min-width: $width;
		width: $width;
		left: 50%;
		margin-left: -($width/2);
	}
}

// .paginator-wrap {
// 	background-color: $grey-6;
// }
