@import "~StyleVariables";

.TimelineLoadMore {
	display: flex;
	flex-direction: row;
	background-color: $grey-1;
	height: 50px;
	align-items: center;
	margin: 8px 0;

	&__icon {
		width: 30px;
		height: 50px;
		border-top: 2px solid #a4b3c7;
		border-bottom: 2px solid #a4b3c7;
		z-index: 1;
		background-color: $grey-1;
	}

	&__content {
		width: calc(100% - 30px);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
