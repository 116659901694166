@import "~StyleVariables";

.Legend {

    display: flex;
    align-items: center;

    .Text {
        padding-left: 8px;
        overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
        max-width: 160px;
    }

    &__dot {
        height: 10px;
        min-width: 10px;
        border-radius: 50%;
        display: inline-block; 
        margin-left: 16px;
      }

}