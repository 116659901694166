@import "~StyleVariables";
@import "~StyleAnimations";

.SubaccountSelector {
	/** 
	* @todo  @upsales/components 2.19.3
	* STYLING: $light-blue is missing
	* {@link https://github.com/upsales/ui-components/issues/544}
	*/
	$light-blue: #A3C6F5;

	--IconButton-bg-color: #{$grey-4};
	--bg-color: #{$grey-1};
	--hover-bg-color: #{$grey-2};
	--subtitle-color: #{$black};
	--title-color: #{$grey-11};

	&--isSubaccount:not(#{&}--isInactive) {
		--IconButton-bg-color: #{darken($light-blue, 5%)};
		--bg-color: #{$light-blue};
		--hover-bg-color: #{$light-blue};
		--subtitle-color: #{$dark-blue};
		--title-color: #{$blue};
	}

	@include transition(all 200ms ease);
	background-color: var(--bg-color);
	border-radius: $border-radius;
	cursor: pointer;
	padding: $space * 2 $space * 4;
	width: 100%;

	.Flex {
		flex-shrink: 0;
	}

	// I DO NOT WANT MY FLEX ITEMS TO OVERFLOW, GOD DAMIT!
	& > * {
		min-width: 0;
	}

	&__Title {
		color: var(--title-color);
	}

	&__Subtitle {
		color: var(--subtitle-color);
	}

	&__IconButton {
		@include transition(all 200ms ease);
		background-color: var(--IconButton-bg-color);
		border-radius: $border-radius;
		height: 24px;
		opacity: 0;
		width: 24px;

		.Icon {
			color: var(--subtitle-color);
		}
	}

	&:hover {
		background-color: var(--hover-bg-color);
		border-radius: 0;
		margin-left: $space * -4;
		width: calc(100% + #{$space * 8});
		padding: $space * 2 $space * 8;

		.SubaccountSelector__IconButton {
			opacity: 1;
		}
	}
}
