@import "~StyleVariables";

.DrawerInfoSnooze {
	display: flex;
	align-items: center;
}

.DrawerInfoSnooze__ball {
	position: relative;
	display: inline-block;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	background: $black;
	margin-right: 10px;
}
.DrawerInfoSnooze__ball .Icon {
	font-size: 12px;
	position: absolute;
	top: 3px;
	right: 3px;
}
