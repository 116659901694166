@import "~StyleVariables";

.ColumnChartWidgetScale {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&--has-negative-values {
		margin-bottom: 14px;
	}

	&__tick-text {
		$height: 18px;
		position: absolute;
		width: 100%;
		padding: 0 10px;
		height: $height;
		line-height: $height;
		margin-bottom: ($height / -2);
		text-align: right;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&__tick-line {
		position: absolute;
		height: 1px;
		background-color: $grey-2;
		left: 0;
		right: 0;
	}

	&__tick-line-thick {
		position: absolute;
		height: 1px;
		background-color: $grey-8; //#b0b5bb;
		left: 0;
		right: 0;
	}
}
