@import "~StyleVariables";

.ReportcenterWidgetEditor {
	background-color: $white;
	height: calc(100% - 54px);

	& > .Row {
		height: 100%;
		flex-wrap: nowrap;
	}

	.Label {
		font-weight: $boldFontWeight;
	}

	.select2-container {
		width: 100%;
	}

	&__filters {
		background-color: $grey-1;
		height: 100%;
		overflow-y: auto;
		padding: 30px;
		min-width: 300px;
		max-width: 400px;
	}

	&__settings {
		padding: 30px;
		overflow: hidden;
		overflow-y: auto;
		height: 100%;
	}

	&__settings-inner {
		max-width: 800px;
		margin: 0 auto;

		& > .Row {
			margin-bottom: 20px;
		}
	}

	&__widget {
		border: 1px solid $grey-4;
		border-radius: 3px;
		transform: translate(0, 0);
		overflow: hidden;

		.Block.unavailable-info {
			display: flex;
			align-items: center;
		}
	}

	&__widget-info {
		align-self: center;
		justify-items: center;
		display: flex;
		align-items: center;
		justify-content: center;

		& > .Column {
			max-width: 800px;
			background-color: $super-light-blue;
			color: $bright-blue;
			border-radius: 5px;
			padding: 10px;

			.Text {
				color: $bright-blue;
			}
		}
		.Icon {
			padding: 0 5px 10px 0;
		}
	}

	&__grouping {
		.Column {
			align-self: center;
		}
	}

	&__goal-toggle,
	&__legend-toggle {
		margin-left: 15px;

		.Label {
			margin-bottom: 11px;
		}
	}

	&__add-filter-button {
		.Icon {
			margin-right: 5px;
		}
	}
}
