@import "@upsales/components/Utils/colors";

.AssignModalActivityList {

	&__list-heading {
		z-index: 1000;
		top: 49px; // not 50px to avoid small gap between this header and the main modal header
		position: sticky;
		padding: 15px;
		background-color: $grey-1;
		border-top: 1px solid $grey-2;
	}

	&__AssignModalActivities__list-heading-table-header {
		position: sticky;
		z-index: 900;
		top: 50px;
	}

	&__AssignModalActivities__inactive__list-heading-table-header {
		position: sticky;
		z-index: 900;
		top: 100px;
	}

}