@import "../../../styles/variables";

.UpsalesSupportCard {
	padding-top: 20px;
	line-height: 30px;
}

.UpsalesSupportCard--CardContent {
	padding-bottom: 20px;
}

.UpsaleSupportCard--Link {
	font-weight: $boldFontWeight;
}
