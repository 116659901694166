@import "~StyleVariables";

.SegmentInputWrapper--segment-error .multi-comparitors-wrap {
	width: calc(100% - 30px);

	.up-comparison-type-toggle {
		background-color: $red;
	}

	.select2-choices {
		border-color: $red;
	}
}
