@import "~StyleVariables";

.CustomerSentiment {
	.Tooltip {
		cursor: pointer;
	}

	&__reasoning {
		max-width: 200px;
	}
}