@import "~StyleVariables";

.SubscriptionPromo {
	display: block;
	top: 12%;
	width: 636px;
	
	.ModalHeader {
		justify-content: center;
	}

	&__info-image {
		height: 75%;
		right: 0;
		position: absolute;
		bottom: 0;
	}
}