$search-min: 350px;
$search-max: 800px;

#navbar {
	$icons-break: 1305px;

	background: $blue; /* Old browsers */

	//background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#1a1e47), color-stop(100%,#263366)); /* Chrome,Safari4+ */
	//background: linear-gradient(to bottom,  #1a1e47 0%,#263366 100%); /* W3C */
	//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1e47', endColorstr='#263366',GradientType=0 ); /* IE6-9 */
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: $navbar-height;
	font-size: $navbar-font-size;
	border: 0;
	z-index: 5031;
	border-bottom: 1px solid $grey-8;

	.browser-not-supported,
	.new-version-available {
		$height: 22px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-color: $orange;
		height: $height;
		text-align: center;
		z-index: 10001;
		box-shadow: 0 2px 4px rgba($black, 0.2);

		.Text,
		.Button {
			height: $height;
			line-height: $height;
		}

		& > .Button {
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	.NavbarBrandDropdown {
		float: right;
		height: 46px;

		.NavbarButton {
			width: 160px;
		}
	}

	.new-version-available {
		background-color: $bright-blue;
	}

	.nav-menu {
		height: 46px;
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: inline-flex;
		align-items: center;

		&.menu-right {
			float: right;
		}

		&.menu-quotas {
			float: right;
		}

		& > li {
			vertical-align: top;
			display: inline-block;

			&#logo {
				width: 40px;
				padding: 0;
				margin-left: 4px;
				margin-right: 4px;

				a {
					display: flex;
					align-items: center;
					width: 100%;
				}

				.BadgeLogo {
					display: inline-block;
				}
			}
		}

		@media (max-width: $icons-break) {
			li.menu-item .item-text {
				display: none;
			}
		}

		li.menu-item {
			height: 100%;
			padding: 0;
			cursor: pointer;

			& > a {
				color: rgba($white, 0.7);
				font-size: 13px;
				padding: 0 15px;
				display: block;
				text-transform: uppercase;
				line-height: 46px;
				@include transition(all ease-out 200ms);
				text-decoration: none !important;

				.icon {
					margin-right: 5px;
					font-size: 16px;
					vertical-align: middle;
					height: 18px;
					display: inline-block;
				}

				&:hover,
				&.hover {
					color: $white;
					background-color: rgba($black, 0.2);
					text-decoration: none;

					.caret {
						@include caret-color($white);
					}
				}

				&.current {
					background-color: $white;
					color: $blue;

					&:hover {
						color: $blue;
						background-color: $white;
					}
				}
			}

			&.notifications-btn {
				font-size: 16px;
			}

			.notifications-indicator {
				position: absolute;
				top: 8px;
				right: 3px;
				display: inline-block;
				background-color: rgba($red, 0.8);
				line-height: 10px;
				padding: 2px 4px;
				font-weight: $boldFontWeight;
				font-size: 12px;
				@include border-radius(2px);
			}

			&.open {
				a.dropdown-toggle {
					background-color: rgba($black, 0.2);
					color: $white;
					.caret {
						@include caret-color($white);
					}
				}
			}

			.caret {
				@include caret-color($white);
			}

			.notifications {
				width: 300px;

				li.notification {
					padding: 5px;
					cursor: pointer;
					background-color: $white;
					color: $grey-13;
					border-bottom: 1px solid $grey-4;
					padding: 0;

					&:last-child {
						border: none;
					}

					a {
						white-space: normal;
					}
				}
			}
		}

		.NavbarButton.notification-dropdown {
			transition: 200ms all ease-in-out;
		}
	}

	ul.menu-right:not(#no-notifications) {
		#profile-dropdown {
			& > a {
				.navbar-settings {
					font-size: 15px;
					@include transition(all ease-out 200ms);
				}
			}

			ul.upsales-tools {
				left: initial;
				top: 43px;
				right: 0;
				background-color: $dark-blue;
				color: $white;
				padding: 0;
				border: none;
				/* Denna nedan kan vi gå tillbaka till när vi tar bort länkarna i
					dropdown huvudet, i firefox blir dessa länkar aningen för stora.
					Så de krävs en ökning på 10px för att råda bot på detta. */
				width: 370px;
				/* width: 360px; */
				height: 0;
				overflow: hidden;
				visibility: hidden;
				display: block;
				@include transition(all ease 200ms);

				#content {
					visibility: hidden;
					@include opacity(0);
					@include transition(all ease 200ms);
				}

				#dropdown-header-fire {
					height: 40px;
					line-height: 40px;
					text-align: left;
					font-size: 10px;
					color: rgba($white, 0.6);
					text-transform: uppercase;
					letter-spacing: 2px;
					border-bottom: 1px solid rgba($white, 0.2);
					cursor: default;
					position: relative;
					padding-left: 20px;

					.dropdown-old-links {
						position: absolute;
						top: 5px;
						right: 10px;
						bottom: 0;

						a {
							display: block;
							line-height: 15px;
							height: 15px;
							font-size: 10px;
							letter-spacing: initial;
							text-transform: initial;
							padding: 0 5px;
							text-decoration: none;
							@include transition(color ease 200ms);

							&:hover {
								color: $white;
							}
						}
					}
				}

				.rotate-bulb {
					transform: rotate(40deg);
				}

				.profile-dropdown-btn {
					display: inline-block;
					width: 33.3%;
					height: 100px;
					text-align: center;
					color: $white;
					padding-top: 24px;
					border-right: 1px solid rgba($white, 0.2);
					border-bottom: 1px solid rgba($white, 0.2);
					@include transition(background-color ease-out 200ms);

					&:hover {
						color: $white;
						background-color: rgba($white, 0.1);
						text-decoration: none;
					}

					.fa {
						display: block;
						font-size: 28px;
						margin-bottom: 5px;
					}
				}

				#logout-btn {
					height: 40px;
					line-height: 40px;
					text-align: center;
					font-size: 12px;
					color: rgba($white, 0.6);
					@include transition(color ease 200ms);

					&:hover {
						color: $white;
					}

					.fa {
						margin-right: 10px;
					}
				}
			}

			&.open {
				ul.upsales-tools {
					height: 180px;
					visibility: visible;

					&.two-rows {
						height: 280px;
					}

					#content {
						visibility: visible;
						@include opacity(1);
					}
				}
			}

			ul.dropdown-menu {
				left: initial;
				right: 0;

				a {
					&.user-row {
						font-size: 16px;

						.subtitle {
							display: block;
							color: $grey-11;
							font-size: 10px;
						}
					}

					&:hover,
					&.hover {
						color: $black;

						.subtitle {
							color: $black;
						}
					}
				}
			}
		}
	}

	// No notifications (remove later)
	ul#no-notifications.menu-right {
		#profile-dropdown {
			height: $navbar-height;
			top: 0;
			right: 0;
			position: absolute;

			&.open {
				color: $white;
				background-color: rgba($black, 0.2);
			}

			.dropdown-toggle {
				padding-right: 0;
				display: inline-block;
			}

			& > a {
				@media (max-width: $responsiveWidht5) {
					& {
						width: 49px;
						height: $navbar-height;
					}

					#user-name {
						display: none !important;
					}
				}

				#user-name {
					vertical-align: top;
					line-height: 16px;
					color: rgba($white, 0.9);
					padding-right: 50px;
					text-align: right;
					display: inline-block;
					text-transform: none;

					&.has-role {
						line-height: 11px;
					}

					.role {
						color: rgba($white, 0.6);
						font-size: 8px;
						text-transform: uppercase;
						letter-spacing: 2px;
					}

					span {
						display: block;
						text-overflow: ellipsis;
						word-break: break-all;
						white-space: nowrap;
						max-width: 290px;
						overflow: hidden;
					}
				}

				#profile-img {
					position: absolute;
					top: 0;
					right: 0;
					margin-top: 5px;
					margin-right: 10px;
					@include border-radius(50%);
					overflow: hidden;
				}
			}

			ul.dropdown-menu {
				left: initial;
				right: 0;

				a {
					&.user-row {
						font-size: 16px;

						.subtitle {
							display: block;
							color: $grey-11;
							font-size: 10px;
						}
					}

					&:hover,
					&.hover {
						color: $black;

						.subtitle {
							color: $black;
						}
					}
				}
			}
		}
	}

	// No notifications (remove later)
	ul#no-notifications.navbar-right {
		#profile-dropdown {
			height: $navbar-height;
			top: 0;
			right: 0;
			position: absolute;

			a.navbar-item {
				padding: 0 10px;
				height: 100%;
				position: relative;

				#user-name {
					margin-right: 10px;
					padding-top: 6px;
					line-height: 17px;
					text-align: right;
					height: 100%;
					vertical-align: middle;

					&.no-role {
						padding-top: 0;
						line-height: $navbar-height;
					}

					@media (max-width: $responsiveWidht1) {
						& {
							display: none;
						}
					}
				}

				#profile-img {
					margin: 6px 0 0 0;
					background-color: $grey-4;
					width: $navbar-profile-img-size;
					height: $navbar-profile-img-size;
					display: inline-block;
					vertical-align: baseline;
				}
			}

			ul.dropdown-menu {
				a {
					&.user-row {
						font-size: 16px;

						.subtitle {
							display: block;
							color: $grey-11;
							font-size: 10px;
						}
					}

					&:hover,
					&.hover {
						color: $black;

						.subtitle {
							color: $black;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.navbar-static-top {
		position: relative;
	}
}

#old-search-curtain {
	position: fixed;
	z-index: ($modal-z - 1);
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba($white, 0.5);
	visibility: hidden;
	@include opacity(0);
	@include transition(all ease 200ms);

	&.visible {
		@include opacity(1);
		visibility: visible;
	}
}

#search-curtain {
	position: fixed;
	z-index: ($modal-z - 1);
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba($black, 0.3);
	visibility: hidden;
	@include opacity(0);
	@include transition(all ease 200ms);

	&.visible {
		@include opacity(1);
		visibility: visible;
	}
}

.search-form {
	$formZ: $modal-z;
	position: relative;
	padding: 0;
	width: $search-min;
	z-index: $formZ;
	display: inline-block;
	margin: 0 15px;

	&.md {
		width: 150px;
	}
	.small-search {
		display: none !important;
	}

	@media (max-width: $responsiveWidht6) {
		& {
			width: auto;
		}
		.large-search {
			display: none;
		}
		.medium-search {
			display: none;
		}
		.small-search {
			display: inline-flex !important;

			#search-toggle {
				background-color: rgba($white, 0.2);
			}
		}
	}

	@media (max-width: $search-max) {
		&.focused {
			width: auto !important;
			left: 0 !important;
			right: 0 !important;
			margin-left: 15px !important;
			margin-right: 15px !important;
		}
	}

	.navbar-search {
		.fa-search {
			position: absolute;
			z-index: 10001;
			top: 9px;
			left: 10px;
			color: $grey-6;
		}
		.nav-search-input {
			padding-left: 25px;
		}
		#advanced-toggle {
			& > span {
				margin-left: 4px;
			}
		}
	}

	&:not(.focused) .medium-search {
		#advanced-toggle {
			& > span {
				display: none;
			}
		}
	}
	&.focused {
		position: fixed;
		width: $search-max;
		left: 50%;
		margin-left: -$search-max / 2;
		border-radius: 3px;
		top: -9px;

		input[type="text"] {
			padding-right: 140px;
			height: 40px;
			border: none;
			border-radius: 3px 6px 0 0;
		}

		#shortcut-helper {
			display: block;
		}

		#advanced-toggle {
			right: 0px;
			bottom: 0;
			top: 0;
			padding-right: 25px;
			background-color: $white;
			color: $green;
			border: none;
			border-radius: 0 3px 0 0;
			display: inline-block;
			transition: all ease-out 200ms;

			&:hover {
				background-color: $green;

				> span,
				.Icon {
					color: $super-light-green;
				}
			}

			> span {
				font-size: 14px;
				color: $green;
				font-weight: $regularFontWeight;
				letter-spacing: 0.03em;
				transition: all ease-out 200ms;
			}

			.Icon {
				display: inline;
				margin-right: 8px;
				font-size: 1.4em;
				color: $green;
				transition: all ease-out 200ms;
			}
		}

		#advanced-toggle-icon {
			display: none;
		}

		.navbar-search {
			display: block;
			position: relative;
			margin: 14px 0px 0px 0px;

			.nav-search-input {
				padding-left: 35px;
				color: $bright-blue;
				border-radius: 3px 6px 0px 0px;
			}

			.fa-search {
				left: 10px;
				top: 11px;
				font-size: 16px;
				color: $super-light-blue;
			}
		}

		.large-search {
			height: 40px;
		}

		.medium-search {
			.fa-sliders-h {
				right: 10px;
				top: 11px;
				font-size: 16px;
				color: $super-light-blue;
			}
		}

		.small-search {
			display: none !important;
		}

		#search-results {
			display: block;
			border-radius: 0 0 3px 3px;
			box-shadow: 0 1px 2px rgba($black, 0.3);
			height: auto;
		}
	}

	&.large {
		bottom: 10px;
		top: 0;
		overflow: hidden;
		padding: 0 0 40px 0;

		#search-results {
			overflow: hidden;
			overflow-y: auto;
		}
	}

	input[type="text"] {
		background: $white !important;
		@include border-radius(2px);
		border: 0;
		padding: 7px 10px;
		outline: none;
		width: 100%;
		font-size: $navbar-font-size;
		line-height: 19px;
		position: relative;
		z-index: $formZ;
		height: 30px;
		margin-bottom: 0px;
		border-bottom: 1px solid $grey-4;
		top: 0px;
		@include transition(width ease-out 200ms);
		@include placeholder-color(rgba($blue, 0.5));

		&:focus {
			background: $white !important;
			@include placeholder-color($grey-10);
			border-radius: 2px 2px 0 0;
			@include border-radius(0px);
		}
	}

	#shortcut-helper {
		position: absolute;
		right: 160px;
		top: 10px;
		background-color: $grey-8;
		display: none;
		@include border-radius(3px);
		color: $white;
		height: 20px;
		line-height: 20px;
		padding: 0 5px;
		text-align: center;
		z-index: $formZ;
		font-size: 10px;
	}

	#advanced-toggle {
		position: absolute;
		right: 15px;
		top: 0;
		z-index: 20000;
		border-left: 1px solid $grey-8;
		font-size: $navbar-font-size;
		line-height: 19px;
		background-color: rgba($white, 0.2);
		@include transition(all ease-out 200ms);

		&:hover {
			text-decoration: none;
			background: $grey-1;
		}

		&:focus {
			text-decoration: none;
		}

		i.fa {
			display: none;
		}
	}

	#advanced-toggle-icon {
		position: absolute;
		right: 15px;
		top: 7px;
		z-index: 20000;
		border-left: 1px solid $grey-8;
		font-size: $navbar-font-size;
		line-height: 19px;
		font-size: 16px;
		@include transition(all ease-out 200ms);

		&:hover {
			text-decoration: none;
			background: $grey-1;
		}

		&:focus {
			text-decoration: none;
			display: none;
		}
	}

	#search-results {
		$row-height: 50px;
		display: none;
		background-color: $white;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: $formZ;

		.ui-match {
			font-weight: $boldFontWeight;
			color: $blue;
		}

		&:active,
		&:focus {
			display: block !important;
		}

		.loading {
			background-color: $white;
			border-radius: 0 0 3px 3px;
			height: 260px;
			display: table;
			width: 100%;

			.upsales-loader {
				display: table-cell;
				vertical-align: middle;
			}
		}

		.search-result-header {
			background-color: $blue;
			margin: 0;
			padding: 10px;
			color: $white;
			display: table;
			width: 100%;
			font-size: 12px;
			border-bottom: 1px solid $grey-4;
			text-transform: uppercase;

			> i.fa,
			i[class^="up-icon-"] {
				display: table-cell;
				width: 1px;
				padding-right: 8px;
				font-size: 1.3em;
			}

			i[class^="up-icon-"] {
				display: block;
				padding-right: 4px;
				font-size: 1.2em;
				height: 15px;
			}

			.up-icon-activity {
				padding-right: 3px;
			}

			> span {
				display: table-cell;
				vertical-align: middle;
				line-height: 12px;
				padding-top: 1px;

				> span + span {
					margin-left: 2px;
				}
			}
		}

		.search-tips {
			background-color: $white;
			padding: 20px;
			color: $grey-11;
			border-top: 1px solid $grey-4;

			.search-tips-title {
				margin: 0;
				margin-bottom: 20px;
			}

			.search-tips-column {
				padding-right: 20px;
				width: 50%;
				display: inline-block;
				vertical-align: top;
			}

			.search-tips-column-title {
				font-size: 16px;
				margin-bottom: 10px;
			}

			.search-tips-keycontainer {
				background-color: $grey-13;
				color: $white;
				display: inline-block;
				text-align: center;
				line-height: 21px;
				height: 21px;
				border-radius: 3px;
				margin-top: 10px;
			}
		}

		.search-error,
		.search-loader,
		.search-noresults {
			display: block;
			width: 100%;
			height: 40px;
			line-height: 40px;
			text-align: center;

			background-color: $grey-2;
			height: 80px;
			line-height: 80px;
			font-size: 20px;
		}

		.search-loader {
			font-size: 20px;
		}

		.search-error,
		.search-noresults {
			font-size: 16px;
			color: $grey-10;
		}

		.search-result {
			position: relative;
			cursor: pointer;
			background-color: $white;
			color: $grey-13;
			border-bottom: 1px solid $grey-4;
			min-height: $row-height;

			&:last-child {
				border: none;
			}

			&.clicked {
				background-color: $super-light-blue !important;
			}

			&.inactive-result {
				background-color: $grey-2;
				border-left: 4px solid $grey-8;

				h4,
				.result-subtitle {
					font-style: italic;
				}
			}

			&.selected {
				background-color: $white;

				h3 {
					.underline {
						text-decoration: underline;
					}
					.black {
						text-decoration: underline;
						text-decoration-color: $grey-13;
					}
				}
			}

			.result-section {
				$padding: 7px;
				display: inline-block;
				height: $row-height;
				width: 50%;
				padding: $padding ($padding * 2);
				font-size: 12px;
				vertical-align: top;

				&.section-20 {
					width: 20%;
				}

				&.section-25 {
					width: 25%;
				}

				&.section-33 {
					width: 33.33%;
				}

				&.section-35 {
					width: 35%;
				}

				&.section-40 {
					width: 40%;
				}

				&.section-50 {
					width: 50%;
				}

				&.section-65 {
					width: 65%;
				}

				&.user-section {
					position: relative;
					padding-top: 11px;

					&.blue {
						background-color: rgba($bright-blue, 0.1);
					}

					.and-more,
					.name-label {
						display: inline-block;
						vertical-align: bottom;
						font-size: 12px;
						line-height: 13px;
					}

					.name-wrap {
						float: right;
						width: 147px;
					}

					.no-user {
						display: block;
					}

					.generic-avatar {
						margin-top: 0px !important;
						position: absolute;
						top: 15px;
						left: 10px;
					}

					label {
						font-weight: $regularFontWeight;
						line-height: 11px;
						text-transform: uppercase;
						color: $grey-10;
						font-size: 10px;
						display: block;
					}

					.name-label {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 100%;
					}
				}

				h4 {
					margin: 0 0 3px 0;
					padding: 0;
					font-size: 14px;
					font-weight: $regularFontWeight;
					line-height: 15px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					position: relative;

					.contact-name {
						display: inline-block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						//max-width: 177px;
						max-width: 135px;
						margin-right: 5px;
					}

					.contact-score {
						color: $purple;
						display: inline-block;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 35px;
						margin-left: 5px;
						margin-top: 1px;

						&.zero-score {
							color: $grey-10;
						}
					}

					.indicator-wrapper {
						vertical-align: top;
					}
				}

				& > span {
					font-size: 12px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					display: block;

					&.result-subtitle {
						color: $grey-10;

						&.uppercase {
							text-transform: uppercase;
						}
					}

					&.single-row {
						line-height: $row-height - ($padding * 2);
					}
				}

				.label {
					font-size: 10px;
					margin-top: 7px;
				}

				.address-holder {
					b.flag-icon {
						margin-right: 5px;
					}
				}

				.InactiveLabel {
					margin-top: 4px;
					float: right;
				}
			}

			h3 {
				margin: 0 0 5px 0;
				padding: 0;
				font-size: 13px;
				color: $link-color;

				.black {
					color: $grey-13;
				}
			}
			.active-indicators {
				float: right;
			}

			.text-wrap {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 0px;
				bottom: 0px;
				padding: 5px;

				&.has-img {
					left: 40px;
				}

				table {
					width: 100%;
					height: 100%;

					td {
						width: 50%;
						height: 22px;
					}
				}
			}

			.gravatar {
				position: absolute;
				left: 5px;
				top: 5px;
			}

			.custom-fields {
				display: block;
				padding: 0px 14px 6px;

				.field {
					display: inline-block;
					width: 33%;
					font-size: 12px;
				}
			}
		}

		.more-results {
			text-align: center;
			height: $row-height - 10px;
			line-height: $row-height - 10px;
			width: 100%;
			display: block;
			color: $link-color;
			padding: 0;
			font-size: 12px;
			min-height: initial;

			b {
				font-style: italic;
				&:before,
				&:after {
					content: '"';
				}
			}
		}
	}
}
