@import "~StyleVariables";

.VirtualizedTableWidget {
	height: 100%;

	&__GridRow {
		position: relative;
		display: flex;
		flex-direction: row;
	}

	&__GridColumn {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}

	&__LeftGridContainer {
		left: 0;
		flex: 0 0 150px;
		z-index: 10;
		background-color: $white;
	}

	&__LeftGrid {
		overflow: hidden !important;
	}

	&__HeaderGrid {
		width: 100%;
		overflow: hidden !important;
	}

	&__BodyGrid {
		width: 100%;

		.ReactVirtualized__Grid__innerScrollContainer {
			margin-bottom: 0;
		}
	}

	&__cell {
		width: 100%;
		height: 100%;
		display: flex;
		padding: 0 0.5em;
		border-bottom: 1px solid $grey-2;
		transition: background-color ease-in-out 0.2s;
		font-size: 12px;
		align-items: center;
		&--left {
			background-color: $grey-1;
			color: $black;
			padding-left: 15px;
		}

		&--header {
			text-transform: uppercase;
		}

		&--leftHeader {
			text-transform: uppercase;
			padding-left: 15px;
		}

		&--body {
			justify-content: center;
			border-right: 1px solid $grey-2;
		}

		&--check {
			background-color: $super-duper-light-green;

			&:hover {
				cursor: pointer;
				background-color: $super-light-green;
			}
		}

		&--times {
			background-color: $super-light-red;
		}
	}

	&__ellipsedText {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__loading-row {
		.Skeleton:before {
			content: "";
			display: block;
			height: 12px;
			width: 50%;
		}
		&:nth-child(2n) {
			.TableColumn:first-of-type .Skeleton:before {
				width: 80%;
			}
		}
	}
}
