@import "../../../../styles/variables";

.FontLocationModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
	z-index: 10000;
	background: rgba(30, 37, 46, 0.3);

	.Modal {
		margin: 1.75rem auto;
		position: relative;
		width: 700px;
		background-color: $grey-1;

		&Content {
			position: relative;
			height: 450px;
			display: flex;
			flex-direction: column;
			padding: 0;
			justify-content: center;
			align-items: center;
		}
	}

	.Headline {
		margin-bottom: 15px;
	}

	&__container {
		width: 425px;
		text-align: center;
	}

	&__options {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;

		.Text--lg {
			margin-bottom: 10px;
		}

		.Icon {
			font-size: 32px;
			height: 40px;
			margin-bottom: 10px;
		}

		.Card {
			width: 200px;
			padding: 36px 20px;
			height: 190px;
			cursor: pointer;

			&:hover {
				background-color: $grey-2;
			}
		}
	}

	&__OptionIcon {
		margin-bottom: 10px;
	}
}
