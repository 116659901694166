.UserSelect {
	display: inline;
	position: static;
	overflow-x: hidden;

	.Label {
		display: inline-flex;
		justify-content: flex-start;
		align-items: baseline;
		gap: 4px;
		white-space: nowrap;
		&__required-indicator {
			margin-left: 0px;
		}
	}

	&__link {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;

		.Text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 17px;
		}
	}

	.HiddenSelect__hidden-select {
		height: 34px;
		z-index: 1;
	}
}
