@import "~StyleVariables";

.AdminHeader {
	&__text-wrap {
		padding: 40px 30px;
		width: 100%;
		display: inline-block;
		vertical-align: top;
		overflow: hidden;
		padding-right: 33%;
	}

	&__title {
		font-size: 30px;
		margin-bottom: 10px;
		color: $black;
		line-height: 36px;
		font-weight: $regularFontWeight;
	}

	&__description {
		color: $grey-11;
		font-size: 16px;
		font-weight: $regularFontWeight;
		line-height: 22px;
	}

	.Help {
		display: inline-block;
		margin-left: 4px;
		font-size: 16px;
	}

	&__toggle {
		margin-top: 10px;
		line-height: 50px;

		> div {
			display: inline-block;
		}

		label {
			color: $grey-13;
			font-size: 16px;
			font-weight: $regularFontWeight;
			margin-left: 10px;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 33%;
		text-align: center;

		> div {
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: 60%;
			margin-left: -30%;
			width: 60%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}
	}

	&__tabs {
		vertical-align: bottom;
		margin-left: 30px;

		.Tab {
			vertical-align: top;
		}
	}
}
