@import "~StyleVariables";

.AppLoader {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 300ms;
	background-color: $green;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 10000000;
	display: flex;
	align-items: center;
	justify-content: center;

	&--visible {
		visibility: visible;
		opacity: 1;
	}
}
