@import "~StyleVariables";

.SettingsTab {
	&__Tabs {
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		background-color: $white;
		height: $navbar-height + 4px;
		display: flex;
		position: sticky;
		top: $navbar-height + 7px;
		z-index: 1049;

		.Tabs {
			height: auto;
		}
	}

	&__Wrapper {
		width: 800px;
		margin: 20px auto 0;
	}

	&__Section {
		margin-bottom: 20px;
		padding-bottom: 17px;

		&:last-child {
			margin-bottom: 400px;
		}
	}

	&__RequiredIcon {
		font-size: 6px;
		position: absolute;
	}

	.Label {
		font-weight: $boldFontWeight;
		margin-bottom: 5px;

		&--has-progress {
			padding-right: 0;
		}

		& + .Text {
			margin-bottom: 10px;
		}

		&.Inline {
			display: flex;
			align-items: center;

			.Text {
				margin-left: 9px;
			}
		}
	}

	.Form-control {
		width: 325px;
	}

	.CardHeader__subtitle {
		margin-bottom: 15px;
	}
}
