@import "~StyleVariables";

.ClientSales {
	display: flex;
	gap: 20px;
	max-height: 350px;
	min-height: 260px;
	background-color: $white;
	padding-top: 20px;
	padding-left: 32px;
	padding-bottom: 12px;

	&__leftSide {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&__info {
			width: 180px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			border-radius: $border-radius;
			padding: 8px;

			&__settings {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&--hasClick {
				cursor: pointer;
				transition: all 200ms ease-in-out;

				&:hover {
					background-color: $grey-2;
				}
			}
		}
	}

	&__rightSide {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 1px; // ehhhhhhh I thought I understund css, I clearly don't
		gap: 4px;

		&__buttonSelects {
			display: flex;
			gap: 8px;
		}
	}
}
