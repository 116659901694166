@import "~StyleVariables";

.Select {
	position: relative;

	&__list {
		position: absolute;
		left: 0;
		right: 0;
		border-radius: $border-radius;
		box-shadow: 0 8px 16px rgba($black, 0.2);
		z-index: 1001;
		max-height: 300px;
		overflow: hidden;
		overflow-y: auto;
		background-color: $white;

		.Loader {
			margin: 4px auto;
		}
	}

	&__result {
		display: flex;
		height: 48px;
		border-bottom: 1px solid $grey-4;
		align-items: center;
		transition: all ease-out 300ms;
		cursor: pointer;
		padding: 0 12px;

		&--highlighted,
		&:hover {
			background-color: $grey-2;
		}
	}

	&__no-results {
		height: 48px;
		line-height: 48px;
	}
}
