@import "~StyleVariables";

.MailEditorReviewHeader {
	overflow: hidden;
	margin-bottom: 24px;

	.CardContent {
		position: relative;
		min-height: 310px;
		width: 816px;
		margin: 0 auto;
		padding: 40px 0;
		padding-right: 400px;

		.Headline {
			margin-bottom: 15px;
		}
		.Label {
			margin-top: 25px;
			font-weight: $boldFontWeight;
		}
	}

	.select2-container {
		width: 100%;

		&.error a {
			border-color: $red;
		}
	}

	&__preview-wrap {
		display: none;
		&--visible {
			display: block;
		}
	}

	&__preview-buttons {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1000;
		transition: all ease-out 200ms;
		visibility: hidden;
		opacity: 0;
		padding: 93px 32px 0;
		background-image: linear-gradient(315deg, rgba($blue, 0.5) 0%, rgba($medium-purple, 0.5) 100%);

		.Button {
			margin-bottom: 10px;
		}
	}

	&__preview {
		position: absolute;
		bottom: -300px;
		right: 0;
		width: 200px;
		height: 290px;
		border-radius: 3px 3px 0 0;
		box-shadow: 0 1px 4px 0 rgba(#000, 0.2);
		overflow: hidden;
		background-color: $white;
		transition: bottom cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		z-index: 1001;

		&--loaded {
			bottom: -20px;
		}

		&:hover {
			.MailEditorReviewHeader__preview-buttons {
				visibility: visible;
				opacity: 1;
			}
		}

		iframe {
			border: none;
			width: 680px;
			transform: scale(calc(200 / 680));
			transform-origin: top left;
			height: 1000px;
		}
	}

	&__preview-loader {
		position: absolute;
		right: 75px;
		top: 130px;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
		z-index: 1000;

		&--visible {
			visibility: visible;
			opacity: 1;
		}
	}

	&__toggle-wrap {
		.Text {
			display: inline-block;
			margin-left: 5px;
		}
	}

	&__toggle-inner-wrap,
	&__toggle-inner-wrap > div {
		display: inline-block;
	}
}
