@import "~StyleVariables";


.WidgetEditor {
    $sidebar-width: 52px;
    $filters-hidden-width: 142px;
    $filters-width: 340px;
    $list-width: 260px;

    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .Label {
        font-weight: $boldFontWeight;
    }

    .select2-container {
		width: 100%;
	}

    &__sidebar {
        width: $sidebar-width;
        background-color: $grey-1;
        border-right: 1px solid $grey-2;
    }

    &__content {
        width: calc(100% - #{$sidebar-width});
        display: flex;
        background-color: $grey-1;
        justify-content: flex-end;
        position: relative;
    }

    &__list {
        min-width: 0px;
        width: 0px;
        background-color: $grey-1;
        position: relative;
        transition: width 0.4s, min-width 0.4s;
        z-index: 1;
        .PageSidebarItem {
            padding: unset;
            transition: padding 0.4s;
        }
        
        &--expanded {
            min-width: $list-width;
            width: $list-width;
            border-right: 1px solid $grey-2;
            .PageSidebarItem {
                padding: 0 20px 0 25px;
            }
        }
    }

    &__settings-inner {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 16px 32px;
    }

    &__settings {
        background-color: $white;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        z-index: 2;
        transition: width 0.4s;

        &--filters-expanded:not(.WidgetEditor__settings--list-expanded) {
            width: calc(100% - #{$filters-width});
        }

        &--list-expanded:not(.WidgetEditor__settings--filters-expanded) {
            width: calc(100% - #{$list-width} - #{$filters-hidden-width});
        }

        &--filters-expanded.WidgetEditor__settings--list-expanded {
            width: calc(100% - #{$filters-width} - #{$list-width})
        }

        -ms-overflow-style: none; // IE and Edge
        scrollbar-width: none; // Firefox
        &::-webkit-scrollbar {
            display: none; // Chrome
        }
    }


    &__display-type {
        width: 100%;
        
        .ButtonSelect {
            width: 100%;
        }
    }

    &__toggles {
        display: flex;
        flex-direction: row;
        column-gap: 16px;

        .WidgetEditor__legend-toggle,
        .WidgetEditor__goal-toggle,
		.WidgetEditor__weighted-toggle,
        .WidgetEditor__subAccounts,
		.WidgetEditor__value-type-toggle,
        .WidgetEditor__percent-toggle {
            display: flex;
            flex-direction: row;
            column-gap: 8px;
        }

		.WidgetEditor__value-type-toggle {
			.RadioList {
				display: flex;
		
				.RadioListItem {
					margin: 0;
				}

				.RadioListItem .Text {
					margin: 0 8px;
					font-weight: normal;
				}
			}
		}
    }

    &__widget {
        border: 1px solid $grey-4;
		border-radius: $border-radius;
		transform: translate(0, 0);

		.Block.unavailable-info {
			display: flex;
			align-items: center;
		}
    }

    &__widget-info {
        background-color: $super-light-blue;
        padding: 8px;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
	}

    &__filters-wrapper {
        width: $filters-hidden-width;
        background-color: $grey-1;
        transition: width 0.4s;
        position: relative;
        border-left: 1px solid $grey-2;
        z-index: 3;

        .WidgetEditor__filters {
            transition: padding 0.4s;
            padding-right: 0px;
            padding-left: 0px;
            position: relative;
            min-height: calc(100% - 20px);
        }

        &--expanded {
            width: $filters-width;

            .WidgetEditor__filters {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    &__filters-wrapper-inner {
        height: 100%;
        padding-bottom: 20px;
        overflow-y: auto;
        scrollbar-gutter: stable;
        padding-left: 15px;

        &--hidden {
            overflow: hidden;
        }
    }

    &__expand-toggle {
        all: unset;
        position: absolute;
        left: -17px;
        top: 18px;
        height: 32px;
        width: 32px;
        border: 1px solid $grey-2;
        background-color: $white;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        animation: rotateBackAnimation;
        animation-iteration-count: 1;
        animation-duration: 0.4s;
        transform: rotate(0deg);

        &--rotate {
            animation: rotateAnimation;
            animation-iteration-count: 1;
            animation-duration: 0.4s;
            transform: rotate(180deg);
        }
    }
}
