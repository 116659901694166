@import "~StyleVariables";

$max-width: 720px;
$card-height: 280px;
$block-height: 268px;
$info-text-width: 290px;
$info-text-small-width: 240px;
$padding-left: $space * 10;
$box-shadow-above-and-side: 0 -1px 4px rgba($black, 0.2);

.SubaccountHelp {
	max-width: $max-width;

	.Card {
		height: $card-height;
		position: relative;
		box-shadow: none;
	}

	&__flex {
		height: 100%;
	}

	&__textBlock,
	&__textBlockSearch,
	&__imgBlock {
		width: 50%;
	}

	&__lottieBlock {
		height: $block-height;
		box-shadow: $box-shadow-above-and-side;
		border-bottom-right-radius: $border-radius;
		border-top-left-radius: $border-radius;
	}

	&__textBlock,
	&__textBlockLottie,
	&__textBlockSearch {
		padding-left: $padding-left;
	}

	&__textBlockSearch {
		padding-top: $space * 20;
	}

	&__infoText {
		width: $info-text-width;
	}

	&__infoTextSmall {
		width: $info-text-small-width;
	}

	&__imgBlock,
	&__imgBlockSearch,
	&__lottieBlock {
		align-self: flex-end;
	}

	&__arrowBlock {
		left: 82px;
	}

	&__lottie {
		border-top-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	&__imgCard {
		border-radius: $border-radius;
		margin-bottom: 2px;
	}
}
