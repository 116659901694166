.SocialEventCard {
	margin: 20px auto;
	max-width: 710px;
}

@media (max-width: 880px) {
	.EditSocialEvent:not(.Card--has-sidebar) .SocialEventCard {
		margin: 20px;
	}
}

@media (max-width: 1230px) {
	.EditSocialEvent.Card--has-sidebar .SocialEventCard {
		margin: 20px;
	}
}
