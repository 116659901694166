@import "~StyleVariables";

.ReportcenterSchedulingSelector {
	width: 100%;
	height: 100vh;
	background-color: $white;

	&__info-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__info {
		width: 552px;
		padding-top: 48px;
		.Title {
			padding-bottom: 8px;
		}
		.Text {
			color: $grey-11;
		}
	}

	&__button-group {
		position: relative;
		width: 552px;
		display: inherit;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 32px;
		padding-bottom: 32px;

		.SchedulingButton {
			z-index: 1000;
		}
	}
	&__animationBot {
		z-index: 1;
		position: absolute;
		left: 0px;

		&--schedule {
			left: 280px;
		}
	}
	&__animationLeft {
		position: absolute;

		z-index: 1;
		&--schedule {
			left: 280px;
		}
	}
	&__animationTop {
		position: absolute;
		top: -45px;

		z-index: 1;
		&--schedule {
			left: 280px;
		}
	}
	&__animationBotRight {
		z-index: 1;
		position: absolute;
		top: -30px;
		left: 20px;

		&--schedule {
			left: 280px;
		}
	}

	&__animation {
		position: absolute;
		z-index: 1;
		width: 760px;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
	}
}
