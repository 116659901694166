@import "~StyleVariables";

.ClientRating {
	background-color: $bright-green;
	border-radius: 2px;
	color: $white;
	height: 16px;
	line-height: 17px;
	font-size: 10px;
	margin: 0 7px;
	padding: 0 5px;
	text-align: center;

	&--orange {
		background-color: $orange;
	}

	&--grey-10 {
		background-color: $grey-10;
		width: auto;
		padding: 0 6px;
		text-transform: uppercase;
	}
}
