@import "~StyleVariables";
.TimelineNoteBase {
	&__history-log {
		padding-top: 2px;
		margin-top: 0px;
		&--noTimelineRowMargin {
			.TimelineRow {
				margin-bottom: 0px;
			}
		}
	}
	&.details-table .TimelineRow,
	&.details-table .TimelineCard {
		margin-bottom: 0px;
	}

	&__history-log:has(.TimelineCard__time--late) .Icon {
		color: $red;
	}
}
