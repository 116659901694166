.SubscriptionsDefaultSettings {
	&__label {
		width: 100%;
		display: inline-flex;
		justify-content: space-between;
	}
	&__description {
		margin-bottom: 12px;
	}
	&__input-wrap {
		width: 50%;
	}
}
