@import "~StyleVariables";
.CurrencySelect {
	font-size: 10px;
	&__select {
		transition: all 2s linear 1s;

		width: 35px;
		&--masterCurrency {
			width: auto;
		}
	}
	select {
		color: $bright-blue;
		background: transparent;
		border: none;
		outline: none;
		appearance: none;
		padding-right: 15px;
		position: relative;
	}
	.Icon {
		margin-left: -10px;
	}
	.Text {
		display: inline;
		font-size: 10px;
		margin-left: 10px;
		line-height: 14px;
	}
}
