.appointment-sidebar {
	.ui-elements {
		&.edge-to-edge {
			padding: 0px !important;
		}

		#ui-elements-wrapper {
			.integration-icon {
				height: 15px;
				font-family: "arial black";
				letter-spacing: -1px;
				font-size: 13px;
				text-transform: uppercase;
				margin-bottom: 5px;
				img {
					height: 100%;
					width: auto;
				}
			}

			&.ui-element-widget {
				padding: 0 0 5px;

				.widget-row {
					padding: 5px 10px;

					.widget-dropdown-wrapper {
						ul.dropdown-menu {
							width: 100%;
						}
					}
				}

				.icon-row {
					padding: 10px 10px 0;
				}
			}
		}
	}
}
