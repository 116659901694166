.GroupMailEditorReviewRecipients {
	&__stats-row {
		margin: 25px 0 20px;

		.Column {
			.Title {
				display: inline-block;
			}
		}
	}

	&__stats-row-of-total {
		display: inline-block;
		vertical-align: top;
		line-height: 18px;
		margin-left: 3px;
	}

	&__loader {
		margin: 10px auto 0;
	}

	&__stats-text.Text--loading:before {
		content: "Unsubscribed";
	}

	&__stats-row-of-total.Text--loading:before,
	&__stats-title.Title--loading:before {
		content: "1 234";
	}

	&__form-elem {
		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.Text {
			margin-top: 3px;
		}
	}
}
