@import "../../../../styles/_variables";

.AccountDeactivationModal {
	padding: 0 !important;
	background-color: $white;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $modal-z;
	overflow-y: auto;

	&--old-modal {
		position: relative;
		top: initial;
		left: initial;
		right: initial;
		bottom: initial;
		z-index: initial;
	}

	&__options {
		margin-bottom: $space;
	}

	&__margin, &__info-square, &__toggle-row {
		margin-bottom: $space * 3;
	}

	.Label {
		font-weight: $boldFontWeight;
	}

	.confirmation {
		margin-top: 10px !important;
	}

	.up-select2--inline {
		margin-left: 5px;
		min-width: 150px;
	}

	.up-select2--inline {
		vertical-align: top;
	}

	.up-select2--inline .select2-chosen {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}

.AccountDeactivationModal__header {
	display: flex;
	justify-content: flex-end;
	padding: 20px;
	position: sticky;
	top: 0;

	.Button {
		border-radius: 100%;
	}
}

.AccountDeactivationModal__container {
	width: 700px;
	margin: 0 auto 20px auto;
}

.AccountDeactivationModal__toggle-row {
	.Column {
		display: flex;
		align-items: center;
	}

	.Toggle {
		margin-right: 10px;
	}

	span {
		line-height: 16px;
	}
}

.AccountDeactivationModal__info-square {
	background-color: $grey-1;
	border: 1px solid $grey-4;
	border-radius: $border-radius;
	padding: 15px;

	.Icon {
		margin-right: 10px;
		margin-top: 2px;
	}

	._elevio_underline {
		border: none;
		color: $bright-blue;
		margin-left: 24px;
	}

	.ctxUnderline {
		transition: none;
	}

	.Row {
		margin-top: 10px;
		flex-wrap: nowrap;
	}

	.Text {
		white-space: nowrap;
	}

	.Row:first-child {
		margin-top: 0px;
		margin-bottom: 10px;
	}
}
