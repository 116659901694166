@import '~StyleVariables';

.ReportcenterWidgetEditorFilters {
    margin-bottom: 19px;
    position: relative;
    width: 278px;

    &__header {
        height: 49px;
        margin-bottom: 12px;
        margin-left: 16px;
        transition: margin 0.4s;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $grey-1;
        
        &--expanded {
            margin-left: 0px;
        }

        &--clickable {
            cursor: pointer;
        }

        .Text {
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            column-gap: 8px;
            height: 30px;
        }
    }

    &__filters {
        z-index: 1;
        display: none;
        flex-direction: column;
        row-gap: 8px;
        opacity: 0;
        transition: opacity 0.4s;

        &--visible {
            opacity: 1;
            display: flex;
        }
    }

    .PageSidebarGroup {
        width: 100%;
    }
    .PageSidebarGroup__title {
        padding: 0;

        .Text {
            font-weight: 700;
            font-size: 14px;
            color: $grey-11;
        }

        .Icon {
            right: 0;
            color: $grey-11;
        }
    }

    &__active-filters {
        background-color: $super-light-green;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.4s;

        &--visible {
            opacity: 1;
        }
    }
    .Select {
        background-color: $white;
    }
}