@import "~StyleVariables";

.CreditRatingLabel {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	border-radius: 4px;
	color: $white;
}

.CreditRatingLabel--A {
	background-color: $success-6;
}

.CreditRatingLabel--B {
	background-color: $success-4;
}

.CreditRatingLabel--C {
	background-color: $success-3;
}

.CreditRatingLabel--D {
	background-color: $yellow-4;
}

.CreditRatingLabel--E {
	background-color: $danger-4;
}

.CreditRatingLabel--F {
	background-color: $danger-5;
}

.CreditRatingLabel--other {
	background-color: $grey-8;
}
