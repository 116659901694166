@import "~StyleVariables";

.ReportcenterFilterProduct {
	.Block {
		width: 100%;
	}
	.ButtonSelect {
		display: flex;
	}

	&__list-select {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.DropDownMenu {
		&__content {
			width: 400px;
		}
	}

	&__info {
		position: sticky;
		bottom: 33px;
	}

	&__ommit-inactive {
		cursor: pointer;
		display: inline;
	}
}
