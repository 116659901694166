@import "~StyleVariables";

.MailContentEditorRows {
	&__col {
		.TableColumn {
			padding-left: 25px;
			background-color: $grey-1;

			.Icon {
				margin-right: 5px;
			}
		}
	}
}
