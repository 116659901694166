@import "~StyleVariables";

.MergeWithBoardmember {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.select2-container {
		width: 280px;
		margin-left: -3px;
	}
	&__up-select {
		font-size: 14px;
	}
	&__input {
		margin-top: 37px;
	}
	&__description {
		display: inline;
		background-color: $grey-2;
		border: 1px solid $grey-5;
		border-radius: 2px 0 0 2px;
		white-space: nowrap;
		padding: 8px 17px 10px 15px;
		.Text {
			display: inline;
		}
		.Text--bold {
			text-overflow: ellipsis;
			overflow: hidden;
			margin-bottom: -5px;
			margin-right: 3px;
			max-width: 139px;
			display: inline-block !important;
			white-space: nowrap;
		}
	}
	&__info {
		margin-top: 22px;
		margin-bottom: 56px;
		align-self: baseline;
	}
	&__topLabel {
		align-items: center;
		width: 100%;
		display: inline-flex;
		&__merge {
			margin-left: 10px;
			margin-right: 10px;
			color: $blue;
		}
		&__cancel {
			position: absolute;
			top: 30%;
			right: 10px;
		}
	}
	&__bottomLabel {
		width: 100%;
		padding: 10px;
		text-align: right;
	}
	.select2-choice {
		border-radius: 0 2px 2px 0;
	}
}
