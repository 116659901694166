@import "~@upsales/components/Utils/colors";

.FinancialTab {
	&__finacialInformation {
		padding-top: 30px;
		padding-left: 30px;
	}
	&__bigNumbers {
		display: inline-block;
	}
	&__smallUnits {
		display: inline-block;
		margin-left: 5px;
	}
	&__revenue {
		margin-bottom: 20px;
	}
	&__keyFigures {
		.Icon {
			margin-right: 10px;
		}
		.Text {
			text-transform: uppercase;
		}
	}
	&__tabs {
		&--grey {
			background-color: $grey-1;
		}
		&__sticky {
			position: fixed;
			z-index: 2;
			top: 147px;
		}
		&__normal {
			margin-top: -15px;
			width: 50%;
			top: 0;
		}
		&__placeholder {
			height: 35px;
		}
		&__filler {
			height: 50px;
			position: fixed;
			z-index: 1;
			left: 370px;
			right: 16px;
			top: 147px;
			background-color: $white;
			&--grey {
				background-color: $grey-1;
			}
		}
	}
	&__greyLineNumber {
		letter-spacing: -2px;
		display: inline-block;
	}

	// Remove when PROSPECTING_CEO_ON_BOARD is released
	&__addon {
		.BillingAddonCard {
			top: 14px;
			margin-bottom: 19px;
			max-width: 600px;
		}
		justify-content: center;
		display: flex;
		.Loader {
			margin-top: 30px;
		}
	}
	&__addon-banner {
		width: 100%;
		margin: 16px;
		z-index: 1;
		display: flex;
		justify-content: center;
		
		.BillingAddonBanner {
			flex: 1;
			max-width: 890px;
		}
	}
}
