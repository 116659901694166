@import "~StyleVariables";

.UpdatesItem {
	&__title {
		cursor: pointer;

		&:hover {
			color: $bright-blue;
		}
	}

	&__text {
		flex: 1;
		display: flex;
		gap: 8px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px 20px 0px 20px;

		.content-text {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			vertical-align: middle;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	&__text-container {
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;
	}

	&__image {
		padding: 20px 20px 0px 20px;
		img {
			height: 60px;
			overflow: hidden;
			border-radius: $border-radius;
		}
	}
}
