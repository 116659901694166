.EditPhonecallCallBlock {
	display: flex;
	justify-content: space-between;
	.Row {
		width: 100%;
		justify-content: space-between;
		.Column:nth-child(2) {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.Icon {
				margin-right: 5px;
			}
		}
	}
}
