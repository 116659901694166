@import "~StyleVariables";

.SubscriptionPeriod {
	&__order {
		margin-top: 24px;
		margin-bottom: 18px;

		.Text {
			line-height: 24px;
		}

		.Headline {
			gap: 8px;
			display: flex;
			align-items: center;
		}

		.Select--disabled {
			.Text {
				font-size: 22px;
				color: $grey-10;
			}
		}
	}

	.CustomFields__formGroupTitle{
		margin-bottom: 12px;
	}

	--translate-date: 0px;

	&__dates-container {
		overflow: hidden;
		position: relative;
		width: 380px;
		height: 33px;
		margin-right: -12px;

		&__moving-container {
			transition: all 0.3s cubic-bezier(0.41, -0.39, 0.27, 1.46);
			transform: translateY(var(--translate-date));
			> div {
				position: absolute;
			}
		}
	}

	&__orderRowsInfoBox {
		display: flex;
		align-items: center;
		gap: 12px;
		margin-top: 16px;
		background-color: $super-light-blue;
		padding: 8px 16px;
		border-radius: 4px;

		u {
			cursor: pointer;
		}
	}

	&__customFields {
		margin-top: 40px;

		.CustomFields__title {
			margin-bottom: 12px;
		}

		.CustomFields__content {
			width: 950px;
			gap: 12px;
			display: flex;
			flex-wrap: wrap;

			.FormComponent__message {
				height: 0;
			}

			.Block {
				width: 469px;
			}
		}
	}
}
