#admin {
    #admin-root {
        .document-templates-table {
            .admin-row-tools {
                display: flex;
                justify-content: flex-end;

                //align-items: center;
                .ButtonGroup {
                    .Button {
                        height: 100%;

                        &.row-tool.preview-tool {
                            padding-right: 6px;
                        }
                    }

                    .inline-confirm-modal,
                    .inline-confirm-modal-hidden {
                        height: 40px;
                        width: 37px;

                        .inline-confirm-tab {
                            width: 37px;
                            height: 40px;
                            display: flex;
                            align-items: top;
                        }
                    }
                }

            }

            .TableRow .admin-row-tools-wrap {
                overflow: hidden !important;
                opacity: 0;
                transition: opacity 0.8s;
            }

            .TableRow:hover .admin-row-tools-wrap {
                overflow: initial !important;
                opacity: 1;
            }

            .TableRow:not(:hover) .admin-row-tools-wrap {
                opacity: 0;
                transition-duration: 0.2s;
            }

            #admin-content {
                .admin-table {
                    >.admin-table-top {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
                .table-no-result {
					height: 52px;
				}
            }
        }

        #admin-page-doctemplates {
            .doc-template {
                display: flex;
                align-items: center;

                .download-icon {
                    font-size: 32px;
                }

                .doctemp-dl {
                    text-transform: none;
                    font-size: 14px;
                    color: #4B5562;
                }

                .doctemp-name {
                    font-size: 22px;
                    color: #4B5562
                }
            }
        }
    }
}