@import "~StyleVariables";

.EditPhonecallHeader {
	height: $header-height;
	z-index: 1000;

	.Skeleton:before {
		content: "loading some good data";
	}

	.Column {
		position: relative;
	}

	& > .Block {
		height: $header-height;
		display: inline-block;
		vertical-align: top;
	}

	&__icon,
	&__delete,
	&__quickLinks,
	&__close,
	&__minimize,
	.inline-confirm-tab,
	.inline-modal-hidden {
		vertical-align: top;
		height: $header-height - 1px;
		line-height: $header-height - 1px;
		width: 50px !important;
		text-align: center;
	}

	&__quickLinks{
		border-right: 1px solid $grey-4;
		box-shadow: $box-shadow-xs;
	}

	&__titles {
		.Text {
			display: inline-block;
			max-width: 445px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	&__id {
		margin-left: 10px;
	}

	.inline-confirm-container {
		top: 49px;
	}

	&__icon {
		font-size: 18px;
		border-left: 1px solid $grey-6;
	}

	&__minimize {
		font-size: 18px;
		border-radius: 0;
	}

	&__saved-banner {
		display: inline-block;
		padding: 0 5px;
		border-radius: 2px;
		position: absolute;
		top: 50%;
		margin-top: -12px;
		transition: all ease-out 300ms;
		opacity: 0;
		visibility: hidden;
		right: -100%;

		&--visible {
			opacity: 1;
			visibility: visible;
			right: 10px;
		}
	}
	&__close {
		border-radius: 0;
	}
}
