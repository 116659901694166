@import "../../../styles/variables";
@import "../../../styles/mixins";

.mail-signature-row {
	cursor: pointer;

	.TableColumn {
		height: 40px;
		line-height: 40px;
		font-size: 14px;

		&.TableColumn--has-subtitle {
			line-height: 20px;

			.Icon {
				margin: 0 10px;
				color: $grey-8;
			}
		}

		&.access {
			color: $bright-blue;
		}
	}

	&:hover {
		background-color: $grey-1 !important;
		.admin-row-tools-wrap .admin-row-tools {
			visibility: visible !important;
			opacity: 1 !important;
			right: 0 !important;
		}
	}

	.admin-row-tools-wrap {
		overflow: hidden;

		.admin-row-tools {
			position: relative;
			visibility: hidden;
			transition: all ease 200ms;
			@include opacity(0);
			right: -100%;
		}
	}

	td:first-child  {
		cursor: pointer;
	}

	.admin-row-tools-wrap {
		width: 60px;
		overflow: hidden !important;
		&:hover {
			overflow: initial !important;
		}

		.inline-confirm-modal .inline-confirm-tab {
			width: 54px;
		}
	}

	.is__inactive,
	.is__active {
		font-size: 12px;
		text-transform: uppercase;
	}

	.is__active {
		color: $medium-green;
	}

	.is__inactive {
		font-style: italic;
		color: $grey-8;
	}
}

.mail-signature-table {
	width: 100%;

	.briefcase-holder {
		.briefcase {
			height: 110px;
			background-repeat: no-repeat;
			background-position: center;
			margin: 20px 0;
		}

		.no-fields-copy,
		.no-fields-title {
			text-align: center;
		}

		.no-fields-copy {
			margin-bottom: 20px;
			font-size: 14px;
			color: $bright-blue;
			line-height: 17px;
			cursor: pointer;
		}

		.no-fields-title {
			font-size: 16px;
			color: $grey-10;
			font-weight: $regularFontWeight;
			line-height: 19px;
		}
	}
}
