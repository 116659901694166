@import "~StyleVariables";

.ProspectingResultTable {
	min-height: 325px;
}

.ProspectingResultTable .TableHeader__column {
	white-space: nowrap;
}

.ProspectingResultTable .TableRow {
	background-color: $white;
}

.ProspectingResultTable .TableRow--inactive {
	background-color: $grey-2;
}

.ProspectingResultTable th:last-child {
	width: 120px;
}

.ProspectingResultTable--withInUpsales th:last-child {
	width: 210px;
}

.ProspectingResultTable__scrollToResult {
	position: sticky;
	bottom: 0;
	display: none;
	z-index: 1000;
}

.ProspectingResultTable__scrollToResult--visible {
	display: block;
}

.ProspectingResultTable .Link {
	&:hover,
	&:active,
	&:visited,
	&:focus {
		text-decoration: none;
	}
}

.ProspectingResultTable .Link .Text {
	color: $bright-blue;
}

.ProspectingResultTable .Loader {
	display: inline-block;
	margin-top: 75px;
	margin-bottom: 200px;
	margin-left: calc(50% - 25px);
}

.ProspectingResultTable__placeholder {
	padding-top: 75px;
	padding-bottom: 206px;
	text-align: center;
}

.ProspectingResultTable .TableRow:hover {
	background-color: $grey-1;
}
