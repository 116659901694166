@import "~StyleVariables";

.AdminNotifications {
    #admin-content {
        padding-bottom: 80px;
        max-width: 1000px;
        padding: 30px;

        // TODO: This styling should be kept and moved to be used with Card ui-component when refactoring this view
        .Card {
            border: 1px solid $grey-6;
            border-radius: 4px;
            box-shadow: unset;
        }
    }

}