@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.snooze-ball {
	$size: 35px;
	position: relative;
	background-color: $grey-11;
	width: $size;
	height: $size;
	line-height: $size;
	@include border-radius($size);
	color: $white;
	display: inline-block;
	text-align: center;
	font-size: 14px;

	.up-icon-snooze {
		position: absolute;
		top: -5px;
		right: -10px;
		color: $grey-11;
		font-size: 12px;
	}
}
