@import "~StyleVariables";

.SplitSubscriptionSidebar {
	--subscriptionSidebarWidth: 280px;

	border-right: 1px solid $grey-4;
	position: relative;
	width: var(--subscriptionSidebarWidth);


	&--create-state {
		padding-top: 16px;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 4px;
		text-align: center;
		width: 280px;
		z-index: 1;

		svg {
			padding: 8px 0 16px 0;
		}
	}

	&__fixedSidebar {
		height: stretch;
		width: 280px;
		position: fixed;
		display: flex;
		flex-direction: column;
	}

	&__sidebarContent {
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
	}
}