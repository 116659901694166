@import "~StyleVariables";

.BouncingTooltip {
	--distance: 10px;
	--delay: 1000ms;
	--iteration-count: 5;
	--iteration-duration: 3000ms;
	--direction-multiplier: 1;

	position: relative;
    display: flex;
    align-items: center;

	&__tooltip {
		opacity: 0;
		position: absolute;
		background-color: $green;
		padding: 2px 8px;
		box-shadow: $box-shadow-lg;
		border-radius: $border-radius;
		z-index: 10000;
		white-space: nowrap;

		&--left, &--right {
			animation: bounceX ease-in-out, appear ease forwards;
			animation-duration: var(--iteration-duration), 500ms;
			animation-iteration-count: var(--iteration-count), 1;
			animation-delay: var(--delay);
		}
		
		&--top {
			animation: bounceY ease-in-out, appear ease forwards;
			animation-duration: var(--iteration-duration), 500ms;
			animation-iteration-count: var(--iteration-count), 1;
			animation-delay: var(--delay);
		}

		&--left {
			right: 100%;
			margin-right: var(--distance);
		}
		
		&--right {
			left: 100%;
			margin-left: var(--distance)
		}

		&--top {
			top: 100%;
			margin-top: var(--distance)
		}

		&--hidden {
			animation: disappear 500ms ease;
		}

		&--dissmisable {
			padding-right: 24px;
		}
	}

	&__arrow {
		position: absolute;
		border-top: 6px solid transparent;
		border-bottom: 6px solid transparent;
		top: calc(50% - 6px);

		&--right {
			border-right: 6px solid $green;
			left: -6px;
		}

		&--left {
			border-left: 6px solid $green;
			right: -6px;
		}

		&--top {
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 6px solid $green;
			right: calc(50% - 6px);
			top: -12px;
		}
	}

	&__dismiss-button {
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0.5;

		&.up-btn.btn-green.btn-xs { // Needed to get higher specificity
			padding: 4px;
		}

		&:hover {
			opacity: 1.0;
		}
	}

	@keyframes bounceX {
		0% {
			transform: translateX(0px);
		}
		30% {
			transform: translateX(calc(var(--direction-multiplier) * 20px));
		}
		40% {
			transform: translateX(calc(var(--direction-multiplier) * 18px));
		}
		50% {
			transform: translateX(calc(var(--direction-multiplier) * 20px));
		}
		80% {
			transform: translateX(0px);
		}
		90% {
			transform: translateX(calc(var(--direction-multiplier) * 2px));
		}
		100% {
			transform: translateX(0px);
		}
	}

	@keyframes bounceY {
		0% {
			transform: translateY(0px);
		}
		30% {
			transform: translateY(calc(var(--direction-multiplier) * 20px));
		}
		40% {
			transform: translateY(calc(var(--direction-multiplier) * 18px));
		}
		50% {
			transform: translateY(calc(var(--direction-multiplier) * 20px));
		}
		80% {
			transform: translateY(0px);
		}
		90% {
			transform: translateY(calc(var(--direction-multiplier) * 2px));
		}
		100% {
			transform: translateY(0px);
		}
	}

	@keyframes appear {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes disappear {
		0% {
			opacity: 1;
		}
		99% {
			opacity: 0;
		}
		100% {
			visibility: hidden;
		}
	}
}