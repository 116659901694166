@import "~StyleVariables";

.WidgetCategory {
    width: 313px;
    height: 120px;
    border: 1px solid $grey-6;
    border-radius: $border-radius;
    overflow: hidden;

    &:hover {
        box-shadow: $box-shadow-lg;
        cursor: pointer;
    }

    transition: box-shadow 200ms ease;

    &__category {
        width: 100%;
        height: 100%;
        padding: 16px;


        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .Text {
            z-index: 1;
            display: flex;
            align-items: center;
            .Icon {
                font-size: 16px;
                margin-right: 12px;
            }
        }
    }

    &__lottie {
        --lottieMarginRight: 0px;
        --lottieMarginBottom: 0px;
        --lottieHeight: 108px;

        width: fit-content;
        height: var(--lottieHeight);
        margin-top: calc(-108px - var(--lottieMarginBottom));
        margin-left: auto;
        padding-right: var(--lottieMarginRight);
    }
}