@mixin info-section($bg, $color) {
	background-color: $bg;
	color: $color;
	text-shadow: 1px 1px darken($bg, 5%);

	h2 {
		color: $color !important;
		margin-bottom: 20px !important;
		font-size: 220% !important;
	}

	.up-btn {
		margin-top: 20px;
	}

	.alpha-btn {
		position: absolute;
		margin: 0;
		top: 0;
		right: 0;
		background-color: transparent;
		color: darken($bg, 20%);
		bottom: 0;
		left: auto;
		background-color: transparent;
		height: 100%;
		width: 20%;

		&:hover {
			background-color: darken($bg, 5%);
		}
	}
}

.info-section {
	width: 100%;
	display: block;

	p {
		margin-bottom: 20px;
	}

	.row {
		position: relative;

		.content {
			padding: 40px 0 40px 60px;
		}
	}

	&.white {
		@include info-section($white, $grey-13);
		text-shadow: none;
	}

	&.blue {
		@include info-section($blue, $white);
	}

	&.bright-blue {
		@include info-section($bright-blue, $white);
	}

	&.orange {
		@include info-section($orange, $white);
	}

	&.green {
		@include info-section($bright-green, $white);
	}
}
