.CreateTemplateFlowSettings {
    width: 616px;
    margin-top: 100px;

    &__timeInput {
        margin-bottom: 8px;

        .Title {
            display: inline;
           
            .Select {
                margin-left: 4px;
            }
        }

    }

    &__timezone {
        display: flex;
        flex-direction: row;
        margin-right: 4px;

        .Select {
            margin-left: 4px;
        }
    

        .Title {
            margin-right: 4px;
        }

        .Link {
            font-size: 18px;
            line-height: 22px;
            margin-right: 4px;
        }


    }
    &__select-dropdown {
        width: 200px;

    }
}