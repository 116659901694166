@import "~StyleVariables";

.DocumentTemplatePreviewProductTable {
	&__table-summary {
		display: flex;
		justify-content: flex-end;
		gap: 8px;

		&.Column {
			.Text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 80px;
				position: relative;
			}
		}
	}

	&__discount {
		text-decoration: line-through;
	}
	&__discount-red {
		color: $danger-5;
	}

	&__table-header {
		flex-basis: unset;
		min-width: min-content;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 10px;

		.Text {
			color: $grey-11;
			max-height: 34px;
			max-width: 100%;
		}
	}

	&__table-row {
		gap: 4px;
		word-break: normal;
		max-width: 100%;
	}

	&__table-content {
		padding-right: 10px;
		word-break: break-word;

		.Text {
			max-height: 50px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			position: relative;
		}
	}

	&__table-divider {
		border-top: 1px solid #1e252e;
	}
}
