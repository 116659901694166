@import "~StyleVariables";

.WrappedPortedAdminDomains#admin {
	#admin-root {
		left: 0;
		.admin-root-wrapper {
			left: 0;
		}
	}
}

.AdminDomains {
	position: absolute;
	left: 230px;
	right: 0;
	bottom: 0;
	top: 0;
	overflow: hidden;
	overflow-y: auto;

	&__content {
		padding: 30px 30px 80px;
		max-width: 900px;
	}

	&__section {
		margin-bottom: 30px;
	}

	&__verify-button {
		width: 180px;
		padding: 0 20px;
	}

	&__inline-delete {
		width: 50px;
	}

	&__SystemMail-wrapper {
		margin: -10px 0 10px;
	}

	.SystemMail {
		background-color: $white;
		width: 360px;
		position: absolute;
		right: 0;
		visibility: hidden;
		opacity: 0;
		box-shadow: 0 3px 6px 0 rgba(30, 37, 46, 0.3);
		border-radius: 2px;
		border-top-right-radius: 0;
		padding: 20px 30px;
		transition: all ease-out 200ms;
		z-index: 10;
	}

	&__section {
		&--showMail {
			.SystemMail {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
