.ReportcenterFilterAddress {
	.ButtonSelect {
		display: flex;
	}

	&__list-select {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.DropDownMenu {
		&__content {
			width: 360px;
		}
	}

	.ReportcenterFilter__info {
		z-index: 1;
	}

	&__bottom {
		height: 39px;
	}
}
