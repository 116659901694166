.up-modal {
	/*
		Do not apply styles to the modal element here,
		do that in _modal.scss!!
		The same applies to header, footer and content elements
	*/

	.up-modal-header {
		// USER SELECT
		.HeaderUserSelect {
			display: inline-block;
		}
		.HeaderUserSelect,
		.user-select-wrap {
			position: absolute;
			right: 48px;
			top: 0;
			bottom: 0;
			width: 222px;
		}

		.user-select-wrap {
			display: inline-block;
			border-right: 1px solid $grey-4;
			padding: 8px 10px;
			line-height: 15px;
			cursor: pointer;
			@include transition(all ease 200ms);
			@include disable-user-select();

			&.disabled {
				& > .fa {
					display: none;
				}

				&:hover {
					background-color: initial;
					cursor: default;
				}
			}

			.generic-avatar {
				margin-right: 10px;
			}

			.title {
				text-transform: uppercase;
				color: $grey-10;
				display: block;
				font-size: 10px;
			}

			.user-name {
				width: auto;
				left: 49px;
				right: 20px;
				font-size: 13px;
			}

			& > .fa {
				margin-top: -4px;
				color: $grey-10;
			}

			&:hover {
				background-color: $grey-1;
			}

			.hidden-user-select {
				position: absolute;
				top: 0;
				display: none;
				left: 0;
				right: 0;
				bottom: 0;

				.select2-container {
					height: 100%;

					.select2-chosen,
					.select2-search-choice-close,
					.select2-arrow {
						display: none;
					}

					.select2-choice {
						height: 100%;
						border-top: none;
						@include border-radius(0);
						background: transparent;
					}
				}
			}
		}
		// END OF USER SELECT
	}

	// SIDEBAR STUFF
	.modal-sidebar-item {
		color: $grey-8;
		font-style: italic;
		min-height: 24px;

		&.center {
			text-align: center;
		}

		.fa {
			width: 15px;
			text-align: left;

			&.fa-mobile {
				font-size: 14px;
			}
		}

		.item-value,
		a {
			display: none;
		}

		&.has-input {
			.ellipsis {
				right: 7px;
				left: 31px;
				width: auto;

				&.no-icon {
					left: 0;
				}
			}

			.item-value,
			a {
				display: inline-block;
			}

			a {
				color: $bright-blue;
			}

			& > span:not(.item-value) {
				display: none;
			}

			.select {
				user-select: all;
			}
		}
	}

	.sidebar-contact-select-wrap {
		$btn-width: 34px;
		position: relative;

		&.is-form {
			#contact-display {
				display: none;
			}

			#edit-contact-form {
				display: block;
			}

			#select-contact-init {
				display: none;
			}
		}

		.title-category-wrap {
			height: 70px;

			label {
				color: $grey-10;
				font-size: 12px;
				font-weight: normal;
			}
		}

		#contact-select-hidden {
			display: none;
			position: absolute;
			right: -34px;
			left: 0;
			top: 0;
		}

		#edit-contact-form {
			display: none;
		}

		#contact-display {
			display: block;
		}

		#select-contact-init {
			display: block;
		}

		#sidebar-contact-actions {
			margin-top: 10px;
			border-top: 1px solid $grey-4;
			text-align: center;
			width: 100%;

			button {
				width: 100%;
			}
		}

		.sidebar-account-address {
			display: inline-block;
		}
	}
	// END OF SIDEBAR STUFF
}
