@import "~StyleVariables";


.RiskItem {
	&__title {
		cursor: pointer;

		&:hover {
			color: $bright-blue;
		}
	}
	
	&__title-icon{
		padding-right: 6px;
	}

	&__text {
		flex: 1;
		display: flex;
		gap: 8px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px 20px 0px 20px;
	}

	&__text-container {
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;
	}

	&__elevio-text {
		line-height: 32px;
	}
}
