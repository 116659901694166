@import "@upsales/components/Utils/colors.scss";

.AdminProductList {
	.CardHeader__actions {
		& > .Block,
		& > .Block > div,
		& > .Input,
		& > .Button {
			display: inline-block;
			margin-left: 10px;
		}
	}

	.AdminProductListProductTable {
		table-layout: fixed;
		width: 100%;
		th:first-child {
			width: 30px;
		}
		.TableRow {
			.Checkbox {
				z-index: 10;
			}
		}

		&__tableCol {
			display: flex;
			&__text {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		&__headerLast {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.export-button {
				font-size: 18px;
				cursor: pointer;
				color: $bright-blue;
				margin-right: 12px;
				&.disabled {
					color: $grey-10;
					cursor: auto;
				}
			}
		}
		&__lastRowCol {
			display: flex;
			align-items: center;
			justify-content: space-between;
			&__text {
				width: 80%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.AdminProductListProductCategoryTable__delete-col,
	.AdminProductListProductTable__delete-col {
		width: 30px;
		padding: 0;
	}

	.TableColumn > .AdminProductListProductCategoryTable__actions,
	.TableColumn > .AdminProductListProductTable__actions {
		display: none;
	}

	.TableRow:hover {
		.TableColumn > .AdminProductListProductCategoryTable__actions,
		.TableColumn > .AdminProductListProductTable__actions {
			display: inline-block;
		}
	}
}
