@import "~StyleVariables";

.AdminTicketAutoReplyTemplates {
	&__supportEmail-column {
		max-width: 150px;
	}
	&__listItem {
		max-width: 250px;
	}
}
