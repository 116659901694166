@import "~StyleVariables";

.ColumnChartWidgetBar {
	--cornerRounding: 4px;

	width: 60px;
	position: absolute;
	bottom: 0;

	&--thinMode {
		width: 20px;
	}

	&--goal {
		background-color: $grey-4;
	}

	&--loading {
		background-color: $grey-3;
	}

	&--progress {
		background-color: $green;
	}

	&--negative {
		background-color: $red;
	}

	&__icons {
		position: absolute;
		bottom: 0;
		display: flex;
		justify-content: center;
		left: -2.5px;
	}

	&__iconWrapper {
		display: flex;
		align-content: center;
		justify-content: center;
		align-items: center;
		z-index: 1000;
		border-radius: 50%;
		width: 23px;
		height: 23px;
		text-align: flex;
		text-align: center;
		transform: translatey(50%);
		border: 1px solid white;
		box-sizing: content-box;

		&:not(:last-child) {
			margin-bottom: 2px;
		}
	}

	&__icon {
		font-size: 13px;
	}
}
