@import "~StyleVariables";

.AssignModalActivities__no-assigned {
	padding: 30px 60px;
	text-align: center;
}

.AssignModalActivities__no-assigned .Button {
	margin-top: 20px;
}

.AssignModalActivities__assign-header {
	top: 0;
	height: 50px;
	z-index: 1000;
	position: sticky;
	padding-left: 15px;
	background-color: $white;
	box-shadow: 0 1px 2px 0 rgba($black, 0.2);
}

.AssignModalActivities__assign-header .Title {
	line-height: 50px;
}

.AssignModalActivities__assign-header .Button {
	position: absolute;
	right: 50px;
	top: 7px;
}

.AssignModalActivities__loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
}

.AssignModalActivities__error {
	margin-top: 100px;
	text-align: center;
}
