@import "~StyleVariables";

.AppointmentModal--Outcomes {
	border-radius: 3px;
	margin: 20px 0 20px;
	border: 1px solid $grey-4;

	.OutcomeTypeButton {
		min-width: 48%;
		flex-grow: 1;
		background-color: #fff;
		border: 1px solid #cad2dd;
		border-radius: 4px;
		height: 90px;
		position: relative;
		overflow: hidden;
		padding: 0;

		.Text {
			padding: 3px 0 0 !important;
			display: inline-block;
		}

		&:hover {
			box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
		}
	}

	.header-circle,
	.content-circle {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		left: -25px;

		&:before {
			content: "";
			position: absolute;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			left: 0px;
			border: 2px solid transparent;
			z-index: 1001;
		}

		&:after {
			content: "";
			background-color: $grey-8;
			width: 2px;
			position: absolute;
			left: 7px;
			top: 17px;
			bottom: -32px;
			z-index: 1000;
		}
	}

	.header-circle {
		&:before {
			border-color: $grey-8;
			background-color: $grey-2;
			width: 12px;
			height: 12px;
			left: 2px;
			top: 5px;
		}

		&:after {
			top: 15px;
		}

		&--last:after {
			content: none;
		}
	}

	.content-circle {
		&:before {
			top: 3px;
			background-color: $green;
			border-color: $green;
			color: $super-light-green;
			text-align: center;
			font-size: 10px;
			line-height: 13px;
		}
	}

	.AppointmentModal--Outcomes__waterfall-menu__row {
		margin: 22px 0;

		&:nth-of-type(2) .content-circle:before {
			content: "1";
		}
		&:nth-of-type(3) .content-circle:before {
			content: "2";
		}
		&:nth-of-type(4) .content-circle:before {
			content: "3";
		}
	}

	.AppointmentModal--Outcomes__opportunity-link {
		display: flex;
		padding: 10px 15px 10px 0;

		.Icon {
			padding-top: 6px;
		}

		.Text {
			padding: 0 0 0 10px !important;

			.Link {
				margin: 0 3px;
			}
		}
	}

	.AppointmentModal--Outcomes__waterfall {
		padding-top: 10px;
		height: 178px;
		border-left: 2px solid #ccc;
		position: absolute;
		left: 18px;
		top: 70px;
	}

	& > .Card {
		box-shadow: none;
		margin-bottom: 0 !important;

		& > .Link {
			&:hover {
				text-decoration: none;
			}

			.Text {
				padding: 10px 15px !important;
				border-bottom: 1px solid gainsboro;

				&:hover {
					background-color: $grey-2;
				}

				.Icon {
					margin-right: 8px;

					&:first-of-type {
						margin-right: 10px;
						font-size: 11px;
					}
				}
			}
		}

		& > .CardHeader {
			min-height: 40px;
			padding: 0 15px;

			.Title {
				line-height: 40px;
			}

			.CardHeader__actions {
				height: 40px;
				line-height: 40px;
			}
		}

		& > .CardContent {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 12px;
		}
	}

	.AppointmentModal--Outcomes__actions {
		& > .CardContent {
			padding: 15px 15px;

			&.AppointmentModal--Outcomes__waterfall-menu {
				display: block;
			}

			.AppointmentModal--Outcomes__waterfall-menu__row {
				position: relative;
			}
			.Textarea {
				margin-bottom: 8px;
			}

			.Title {
				padding-bottom: 4px;
			}

			& > .Block {
				.Button {
					flex-grow: 1;
					margin: 4px 4px;
					height: auto;
					min-width: auto;
					padding: 0px 7px;
					line-height: 27px;
					min-width: auto;
					text-shadow: none;

					.OutcomeTypeButton__main-view {
						flex-direction: row;
						align-items: center;
						justify-content: stretch;
						gap: 12px;
					}

					.Icon {
						font-size: 12px;
						margin-right: 10px;
					}

					.Text {
						padding: 3px 0 0 !important;
						display: inline-block;
					}

					&:hover {
						background-color: #b1e1e1;
					}
				}
			}
		}
	}

	.AppointmentModal--Outcomes__add-notes,
	.AppointmentModal--Outcomes__create-todo {
		&--open {
			padding: 10px;
			border: 1px solid $grey-4;
			border-radius: 3px;
			background-color: $grey-1;
		}

		&-label {
			padding: 15px 0 5px !important;
			font-weight: $boldFontWeight;
		}
	}

	.AppointmentModal--Outcomes__add-notes {
		&-label .Link {
			float: right;
			font-weight: normal;
		}

		.Textarea {
			margin-bottom: 10px;
		}

		.Button {
			width: 25%;
		}
	}

	.AppointmentModal--Outcomes__create-todo {
		&-instruction {
			padding: 5px 0 !important;
		}
	}

	.AppointmentModal--Outcomes__create-todo-input-wrapper {
		display: flex;
		justify-content: space-between;

		.Input {
			margin-right: 10px;
			width: 100%;
		}

		.Button {
			width: 25%;
		}
	}

	.AppointmentModal--Outcomes__disqualify-company {
		.Help {
			display: inline-block;
			margin-left: 6px;
			font-size: 12px;
			vertical-align: top;
		}
	}

	&__SendEmail__Link--disabled:hover {
		text-decoration: none;
		color: $grey-10;
	}

	&__DateTimePicker {
		max-width: 292px;
		margin-top: 10px;

		&__clear {
			float: right;
		}

		&__inputs {
			display: flex;
			justify-content: space-between;
			margin-top: 5px;

			.DateInput,
			.TimeInput {
				width: 49%;
			}
		}
	}

	&__add-notes,
	&__create-todo,
	&__SendEmail {
		&__Added {
			float: right;
			padding: 0 !important;
			color: $grey-10;
		}
	}
	&__save-button {
		display: flex;
		align-items: center;
		margin-top: 20px;
		color: $super-light-green !important;
	}
	&__save-button--disabled {
		color: inherit !important;
	}
}
