@import "~StyleAnimations";
@import "~StyleVariables";
@import "~StyleMixins";

.Modals {
	.GenericModal {
		table {
			width: 100%;
		}

		&:not(.FullscreenModal) {
			overflow: visible;
		}

		&.FullscreenModal {
			@keyframes slide-from-bottom {
				from {
					top: 100vh;
				}
				to {
					top: 0;
				}
			}

			> div {
				position: absolute;
				width: 100%;
			}

			@include box-shadow(0 0 0 0);
			animation: 600ms slide-from-bottom;

			&.more-z-index {
				z-index: $modal-z - 1;
			}

			.full-screen-small-header {
				color: $grey-10;
				font-size: 16px;
				font-weight: $regularFontWeight;
				margin-left: 20px;
			}

			.full-screen-modal-content {
				width: 100%;
				max-width: 1600px;
				margin: 0 auto;
			}

			.exit-button {
				position: fixed;
				top: 10px;
				right: 14px;
				color: $grey-10;
				background: rgba($grey-4, 0.6);
				border-radius: 100%;
				width: 34px;
				height: 34px;
				border: none;
				padding: 0;
				z-index: 10000;
				@include transition(background 0.3s);
				text-shadow: none;

				&:hover {
					background: rgba($grey-4, 1);
					// background: rgba(228, 234, 240, 1);
				}

				.fa {
					width: 34px;
					text-align: center;
					// color: #586881;
					font-size: 16px;
				}
			}
		}
	}
}
