@import "~StyleVariables";
/* ta stands for triggerAction */
.popup-content-ta {
	max-height: 400px;
	overflow-y: auto;

	.popup-content-ta__row {
		padding: 15px;
		padding-top: 6px;
		padding-bottom: 6px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.popup-content-ta__row-label {
		font-size: 14px;
		font-weight: $boldFontWeight;
	}

	.popup-content-ta__row-text {
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.popup-content-ta__edit-btn-wrap {
		padding: 15px;
	}
}
