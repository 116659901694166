@import "~StyleVariables";

.ReportcenterWidgetEditorFilterAddress {
	&__inline-dropdown {
		.SelectOption {
			padding: 0 !important;
			min-height: 40px;

			&:has(div.hidden) {
				display: none !important;
			}
		}
	}

	&__item {
		flex: 1;
		&--header {
			background-color: $grey-2;
			
			.Checkbox {
				&:not(.Checkbox--checked) {
					background: $grey-5;
				}

				&:hover:not(.Checkbox--checked) {
					background: $grey-6;
				}
			}
		}
		.Column {
			align-items: center;
			display: flex;

			.Text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
			}
		}
	}

	&__list-select {
		position: sticky;
		top: 0;
		z-index: 1;

		.Block {
			width: 100%;
			height: 100%;
		}

		.ButtonSelect {
			display: flex;
		}
	}

	&__chevron {
		transition: all 0.3s ease;
		&--open {
			transition: all 0.3s ease;
			transform: rotate(180deg);
		}
	}
}

