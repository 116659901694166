@import "~StyleVariables";

.ARRChangeDrawer {
	width: 900px;
	height: 100%;

	background-color: $grey-1;

	&__header {
		width: 850px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		padding-right: 20px;
		padding-left: 20px;

		&__madeBy {
			.Text {
				display: inline;
			}
		}
	}

	&__body {
		height: 100%;
		overflow-y: auto;
		padding-bottom: 100px;
		scrollbar-gutter: stable;
	}

	&__loading {
		height: 100%;
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	&__noResults {
		display: flex;
		align-items: center;
		flex-direction: column;

		margin-top: 50px;

		img {
			width: 130px;
			margin-bottom: 12px;
		}
	}
}
