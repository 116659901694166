@import "~StyleVariables";

.SubscriptionPeriodsTree {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 280px;
	z-index: 99999;

	animation: fadeIn 0.3s ease-in;

	.Hotspot {
		position: fixed;
		top: 74px;
		left: 275px;
	}

	&__plan-button {
		margin-top: 20px;
		padding: 0 46px;
	}
}
