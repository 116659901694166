@import "~StyleVariables";

.FileBrowser {
	$headerHeight: 50px;
	$sidebarWidth: 260px;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $grey-1;

	&__header {
		position: relative;
		z-index: 1001;
		background-color: $white;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		height: $headerHeight;
		padding: 0 20px;

		.Title {
			height: $headerHeight;
			line-height: $headerHeight;
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		height: $headerHeight;
		width: $headerHeight;
		line-height: $headerHeight;
	}

	&__sidebar,
	&__content {
		position: absolute;
		top: $headerHeight;
		bottom: 0;
	}

	&__sidebar {
		left: 0;
		width: $sidebarWidth;
		border-right: 1px solid $grey-4;
		background-color: $white;
		padding: 20px 15px;
		overflow: hidden;
		overflow-y: auto;
	}

	&__content {
		left: $sidebarWidth;
		right: 0;
		overflow: auto;
		padding: 15px 15px 100px;
	}

	&__loader {
		position: absolute;
		right: 10px;
		bottom: 20px;
		z-index: 1000;
	}

	&__standardLabels {
		margin-top: 15px;
		padding-left: 5px;
		margin-bottom: 5px;
	}

	.FileUploadDropzone {
		margin-bottom: 10px;
	}
}
