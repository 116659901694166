@import "~StyleVariables";

.HeaderUserSelect {
	cursor: pointer;

	&,
	& > .Row {
		height: 100%;
	}

	&:hover {
		background-color: $grey-1;
	}

	&__text-col,
	&__avatar-col,
	&__caret-col {
		display: flex;
		justify-content: center;
	}

	&__avatar-col,
	&__caret-col {
		align-items: center;
	}

	&__text-col {
		overflow: hidden;
		flex-direction: column;
	}

	&__title {
		text-transform: uppercase;
		font-size: 10px;
		line-height: 10px;
	}

	&__user-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 16px;
	}

	&__warning-icon {
		position: absolute;
		top: 10px;
		left: 30px;
		visibility: hidden;
	}

	&--disabled &__caret {
		display: none;
	}

	.Avatar {
		$size: 30px;
		width: $size;
		height: $size;
		font-size: $size;

		&--gravatar {
			width: $size;
			height: $size;
		}
		&--initials {
			line-height: $size;
			font-size: 14px;
		}
	}

	.HiddenSelect__hidden-select {
		.select2-container {
			height: 100%;

			.select2-chosen,
			.select2-search-choice-close,
			.select2-arrow {
				display: none;
			}

			.select2-choice {
				height: 100%;
				border-top: none;
				border-radius: 0;
				background: transparent;
			}
		}
	}
}
