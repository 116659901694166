@import "~StyleVariables";

.TreeSelect {
	
	&__options {
		min-width: 200px;
		max-height: 320px;
		overflow-x: hidden;
	}

	.TreeSelectOption {

		&__tooltipFlex {
			min-width: 1px;
		}

		&__child {
			padding-right: 2 * $space;
		}

		/**
		* Maybe Flex should have a background color option
		* See: https://github.com/upsales/ui-components/issues/542
		*/
		&--hasBgColor {
			background-color: $grey-2;
		}

		.Tooltip {
			min-width: 1px;
		}

		/**
		 * Checkbox should have a border option
		 * See: https://github.com/upsales/ui-components/issues/538
		 */
		.Checkbox {
			border: $border;
			flex-shrink: 0;
		}
	}
}