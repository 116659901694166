.InsightsCard {
	position: absolute;
	top: 0;
	right: 15px;
	left: 15px;
	bottom: 15px;
	overflow: hidden;
	display: flex;
	flex-flow: column;
}
