@import "~StyleVariables";

.DesiredOutcome__container {
	max-width: 800px;
	height: 100%;
	margin: auto;
}

.DesiredOutcome__loader-wrap {
	padding-top: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.DesiredOutcome__header {
	display: flex;
	justify-content: space-between;
	height: 50px;
	margin: 10px 10px 40px 10px;

	.Text {
		font-size: 16px;
	}

	.Logo {
		width: 150px;
	}
}

.DesiredOutcome__progressbar-wraper {
	width: 250px;
	position: relative;
	left: -40px;

	.Progressbar {
		margin-bottom: 10px;
		margin-top: 8px;
	}

	.Text {
		text-align: center;
	}
}
