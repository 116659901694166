.BillingHistory {
	&__username {
		margin: auto;
		margin-left: 10px;
	}

	&__icon_question {
		padding-left: 7px;
	}
}
