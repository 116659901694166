@import "~StyleVariables";

.CardChildRow {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid $grey-4;
	padding: 4px 8px;
	border-radius: $border-radius;
	cursor: pointer;
	transition: background-color ease 200ms;

	&:hover {
		background-color: $grey-1;
	}

	&--hasRenewed {
		border-left: 4px solid $success-4;
	}
	&--hasEndDate {
		border-left: 4px solid $red;
	}
	&--hasPassed {
		border-left: 4px solid $grey-6;
		background-color: $grey-2;
		&:hover {
			background-color: $grey-4;
		}
	}

	&__leftSide {
		display: flex;
		align-items: center;
	}

	&__leftColumn {
		display: flex;
		align-items: center;
		width: 250px;

		.Icon {
			margin-right: 8px;
		}
	}

	&__date {
		display: flex;
		flex-direction: column;
	}

	&__boxesIcon {
		padding: 8px;
		border-radius: 4px;
		cursor: pointer;
	}

	&__actions {
		padding-right: 8px;
	}
}
