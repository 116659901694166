.FindProspectsBranchSubtitle {
	.Text {
		display: inline-block;
		margin-right: 2px;
	}

	&__clickable {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
