@import "../../styles/variables";

#newSegment {
	margin-bottom: -25px;

	.modal-close {
		position: absolute;
		z-index: 6000;
	}

	.col-container {
		float: left;
	}

	.col {
		width: 275px;
		float: left;
		padding-right: 25px;
		height: 100%;

		&.dark-background {
			background-color: $grey-11;
			position: relative;
			color: $white;

			h2 {
				color: $white;
				margin-bottom: 20px;
			}

			p {
				font-size: 12px;
				color: $grey-1;
				margin-bottom: 150px;

				> .ingress {
					font-size: 16px;
					color: $grey-1;
					margin-bottom: 20px;
					display: block;
				}
			}

			.text {
				width: calc(100% - 350px);
				padding: 100px 25px 0 0;
				box-sizing: border-box;
				float: left;
			}

			.svg-holder {
				width: 300px;
				float: left;
				margin: 25px;

				svg g .user_lower_level_first {
					animation: animate-drop 0.6s ease-out normal;
				}

				@keyframes animate-drop {
					0% {
						transform: translate(110px, 20px);
					}
					100% {
						transform: translate(110px, 130px);
					}
				}

				svg g .user_lower_level_second {
					animation: animate-drop-second 0.8s ease-out normal;
				}

				@keyframes animate-drop-second {
					0% {
						transform: translate(170px, 100px);
					}
					25% {
						transform: translate(170px, 100px);
					}
					100% {
						transform: translate(170px, 215px);
					}
				}

				.times-first,
				.times-second,
				.times-third,
				.times-fourth {
					opacity: 0.5;
					animation: animate-reveal 0.2s ease-out normal;
				}

				.user_first,
				.user_second,
				.user_third,
				.user_fourth {
					opacity: 0.5;
					animation: decrease-opacity 1s ease-out normal;
				}

				@keyframes decrease-opacity {
					from {
						opacity: 1;
					}
					to {
						opacity: 0.5;
					}
				}

				#check-second-level-left {
					animation: animate-reveal 1s ease-out normal;
				}

				#check-second-level-right {
					animation: animate-reveal 1.4s ease-out normal;
				}

				@keyframes animate-reveal {
					0% {
						fill: $grey-11;
					}
					60% {
						fill: $grey-11;
						transform: translate(0px, 10px);
					}
					70% {
						fill: $medium-purple;
						transform: translate(0px, -6px);
					}
					100% {
						fill: $medium-purple;
						transform: translate(0px, 0px);
					}
				}

				#second-level-floor,
				#second-level-ellipse {
					animation: floor-color 1s ease-out normal;
				}

				@keyframes floor-color {
					0% {
						fill: $grey-3;
					}
					85% {
						fill: $grey-3;
					}
					100% {
						fill: $super-light-purple;
					}
				}

				#second-level-floor-edge {
					animation: floor-edge 1s ease-out normal;
				}

				@keyframes floor-edge {
					0% {
						fill: $grey-8;
					}
					85% {
						fill: $grey-8;
					}
					100% {
						fill: $dark-purple;
					}
				}

				#second-level-floor-shadow {
					animation: floor-edge 1.2s ease-out normal;
				}

				@keyframes floor-edge {
					0% {
						fill: $grey-8;
					}
					75% {
						fill: $grey-8;
					}
					100% {
						fill: $dark-purple;
					}
				}

				@keyframes animate-width {
					0% {
						rx: 40;
					}
					50% {
						rx: 50;
					}
					100% {
						rx: 40;
					}
				}

				#stroke-shadow {
					stroke-dasharray: 1000;
					stroke-dashoffset: 1000;
					animation: flow-stroke 4s 1.4s linear forwards;
				}

				#flow-stroke {
					stroke-dasharray: 1000;
					stroke-dashoffset: 1000;
					animation: flow-stroke 4s 1.4s linear forwards;
				}

				@keyframes flow-stroke {
					to {
						stroke-dashoffset: 0;
					}
				}

				#flow-stroke-dashed {
					stroke-dasharray: 5;
				}

				#box-first {
					animation: box-color 2.2s ease-out normal;
				}

				#box-second {
					animation: box-color 2.6s ease-out normal;
				}

				#circle-first {
					animation: box-color 4.5s ease-out normal;
				}

				@keyframes box-color {
					0% {
						fill: $grey-8;
					}
					75% {
						fill: $grey-8;
					}
					100% {
						fill: $medium-purple;
					}
				}

				#circle-second {
					animation: circle-second 3.8s ease-out normal;
				}

				@keyframes circle-second {
					0% {
						fill: $grey-8;
					}
					95% {
						fill: $grey-8;
					}
					100% {
						fill: $medium-purple;
					}
				}

				#circle-second-icon {
					animation: circle-icon-color 3.8s ease-out normal;
				}

				@keyframes circle-icon-color {
					0% {
						fill: $grey-10;
						transform: translate(0px, 20px);
						opacity: 0;
					}
					90% {
						fill: $grey-10;
						transform: translate(0px, 20px);
						opacity: 0;
					}
					95% {
						fill: $grey-11;
						transform: translate(0px, -6px);
						opacity: 1;
					}
					100% {
						fill: $grey-11;
						transform: translate(0px, 0px);
						opacity: 1;
					}
				}

				.flow-check-top {
					animation: animate-reveal-flow-check 2.2s ease-out normal;
				}

				@keyframes animate-reveal-flow-check {
					0% {
						fill: $grey-11;
					}
					80% {
						fill: $grey-11;
						transform: translate(0px, 10px);
					}
					90% {
						fill: $medium-purple;
						transform: translate(0px, -6px);
					}
					100% {
						fill: $medium-purple;
						transform: translate(0px, 0px);
					}
				}

				.flow-check-bottom {
					animation: animate-reveal-flow-check 2.6s ease-out normal;
				}

				.flow-times-top {
					animation: animate-reveal-times-check 2.2s ease-out normal;
				}

				.flow-times-bottom {
					animation: animate-reveal-times-check 2.6s ease-out normal;
				}

				@keyframes animate-reveal-times-check {
					0% {
						fill: $grey-11;
					}
					80% {
						fill: $grey-11;
						transform: translate(0px, 10px);
					}
					90% {
						fill: $grey-8;
						transform: translate(0px, -6px);
					}
					100% {
						fill: $grey-8;
						transform: translate(0px, 0px);
					}
				}
			}
		}

		h2 {
			margin-bottom: 45px;
		}

		.floating-label-input {
			&.has-value {
				> label {
					bottom: 30px;
				}

				input {
					font-size: 20px;
					border-bottom-color: $bright-blue;
				}

				textarea {
					font-size: 14px;
					border-bottom-color: $bright-blue;
				}

				&.floating-textarea {
					margin-top: 40px;

					> label {
						bottom: 44px;
					}
				}

				&.floating-toggle {
					margin-top: 40px;
				}
			}

			&.error-input {
				input {
					border-bottom-color: rgba($red, 0.8);
					background: rgba($red, 0.05);
					border-radius: 3px 3px 0 0;
				}
			}
		}

		.button-holder {
			width: 250px;
			margin-top: 25px;
			position: absolute;
			bottom: 20px;
		}
	}

	.col + .col {
		width: 670px;
		float: left;
		margin-top: -25px;
		margin-right: -25px;
		margin-bottom: -25px;
		height: 100%;
	}
}