@import "~StyleVariables";


.SimilarCustomers {
    width: 100%;

    &__and {
        margin: 0 $space;
    }

    &__customer {
        font-size: $modal-font-size;
    }

    &__and {
        line-height: 1.4;
    }

    &--editOrder {
        .SimilarCustomers__and {
            line-height: 2.0;
        }
    }
}