@import "~StyleVariables";

.BillingAddonBanner {
	position: relative;
	background-color: $super-duper-light-green;
	border-radius: $border-radius;
	box-shadow: 0 11px 12px rgba($black, 0.1);
	padding: 16px;

	.BillingAddonCard {
		box-shadow: 0 4px 8px rgba($black, 0.2);
	}

	.AssistChip {
		display: inline-flex;
		vertical-align: middle;
	}

	&__breaking-text {
		white-space: break-spaces;
	}
}
