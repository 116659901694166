@import "~StyleVariables";

.MailContentEditorImage__reset-width {
	flex-direction: column;
	padding: 0;
	height: 20px;
	line-height: 20px;
}

.MailContentEditorImage__image-size {
	background-color: $grey-2;
	border: 1px solid $grey-6;
	border-radius: 2px;
	padding: 2px;
}

.MailContentEditorImage__image-size--infoArticle {
	&:hover {
		color: $bright-blue;
	}
}
