.ReportcenterFilterFlow {
	&__list-select {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.Icon-code-fork {
		transform: rotate(180deg);
	}
}
