@import "~StyleVariables";

.ClientCardSidebar {
    display:flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
    box-shadow: $box-shadow-md;
    position: relative;
    z-index: 10;

    > div {
        padding: 16px;
        border-bottom: 1px solid $grey-4;
        .Title {
            margin-bottom: 12px;
        }
    }
    > :last-child {
        border-bottom: none;
    }
    &:before {
        border-right: none;
    }

    &__ToDoBlock {
        justify-content: center;
		align-content: center;
        background: repeating-linear-gradient(
            45deg,
            #ffd64033,
            #ffd64033 10px,
            #e8593133 10px,
            #e8593133 20px
        );
        height: 100px;
    }

    &__content {
        gap: 8px;
    }
}