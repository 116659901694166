@import "~StyleVariables";

.StandardView {
	&__Title {
		padding: 15px 15px 10px;
		color: $black;
		font-weight: $boldFontWeight;
		position: sticky;
		top: 0;
		background-color: $white;
		z-index: 1;

		.Icon {
			margin-right: 10px;
		}

		&--Secondary {
			border-top: 1px solid $grey-4;
		}
	}

	&__SaveAs {
		position: sticky;
		right: 0;
		height: auto;
		line-height: 18px;
		top: 15px;
		z-index: 1000;
		float: right;
	}
}
