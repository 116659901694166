.AccountGrowth {
	&__findProspects {
		margin-right: 8px;
	}

	&__lastContacted {
		.Icon {
			margin-right: 8px;
		}
	}

	.AccountGrowthRowTools {
		white-space: nowrap;
		.Button {
			padding: 8px;
		}
	}	
}