.AccountListDrawer.Page {
	overflow: visible;
}

.AccountListDrawer {
	width: 700px;

	.Page {
		top: 0 !important;
	}

	.ListView__header {
		height: 0;
	}

	.ListView {
		left: 0;
		margin-top: 5px;
		overflow: auto;

		.TableColumn {
			max-width: 250px;
		}

		.Link {
			display: block;
		}
	}

	.TableHeader {
		position: sticky;
		top: 0;
		z-index: 1;
	}
}