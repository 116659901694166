@import "~StyleVariables";

.CriteriaDrilldownWrapper {
	background-color: $grey-1;
}

.CriteriaDrilldownWrapper:not(.CriteriaDrilldownWrapper--open) > td {
	border: 0;
}

.CriteriaDrilldownWrapper > td {
	padding: 0 !important;
}

.CriteriaDrilldownWrapper .drilldown-animation-wrapper {
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	transition: all ease-out 200ms;
	overflow-y: auto;
}

.CriteriaDrilldownWrapper .drilldown-animation-wrapper {
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	-webkit-transition: all ease-out 0.2s;
	transition: all ease-out 0.2s;
	overflow-y: auto;
}

.CriteriaDrilldownWrapper--open .drilldown-animation-wrapper {
	max-height: 200px;
	opacity: 1;
}

.CriteriaDrilldownWrapper--open.order-drilldown .drilldown-animation-wrapper {
	max-height: 310px;
}

.CriteriaDrilldownWrapper--open.agreement-drilldown .drilldown-animation-wrapper {
	max-height: 420px;
}

.CriteriaDrilldownWrapper--open.activity-drilldown .drilldown-animation-wrapper {
	max-height: 270px;
}

.CriteriaDrilldownWrapper table {
	width: 100%;
	margin: 10px 0;
}

.CriteriaDrilldownWrapper .form-control {
	border-radius: 3px;
}

.CriteriaDrilldownWrapper .title-col {
	width: 160px;
	font-size: 14px;
	padding-left: 20px;
	padding-top: 1px;
}

.CriteriaDrilldownWrapper .drilldown-row {
	height: auto;
}

.CriteriaDrilldownWrapper .CriteriaRowWrapper {
	padding: 0;
}

.CriteriaDrilldownWrapper {
	.col-prio {
		.Label {
			margin-left: 5px;
		}
	}
}
