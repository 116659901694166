@import "~StyleVariables";

.SalesboardCard {
	gap: 6px;
	display: flex;
	flex-direction: column;
	> .Row {
		gap: 6px;
		> * {
			overflow: hidden;
			white-space: nowrap;
		}
		justify-content: space-between;
		align-items: baseline;
		.Text {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	%clickable {
		cursor: pointer;

		.Text {
			transition: color 200ms ease;
		}

		&:hover .Text {
			color: $bright-blue;
		}
	}

	&--clickable {
		cursor: pointer;
		transition: background-color 200ms ease-in-out;

		&:hover {
			background-color: $grey-1;
		}
	}

	&--loading {
		background-color: $grey-1;

		.Text {
			color: $grey-8;
		}
	}
	
	.Loader {
		width: 100%;
	}
	&__noAccount {
		&:hover {
			color: $grey-11 !important;
		}
	}

	&__iconsGroup {
		display: flex;
		gap: 8px;
		font-size: 15px;
	}

	&__bottomRight {
		.Avatar {
			margin-bottom: -5px;
		}
	}

	&__top--clickable,
	&__middleLeft--clickable,
	&__middleRight--clickable,
	&__bottomLeft--clickable,
	&__bottomRight--clickable {
		@extend %clickable;
	}

	&__riskTooltipTitle {
		margin-top: 0.25rem;
		margin-bottom: 0.5rem;
	}

	&__riskTooltipEntry {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;

		> * {
			margin-right: 0.75rem;
		}
	}
}
