@import "~StyleVariables";

.SupersearchResultRow {
	position: relative;
	border-bottom: 1px solid $grey-4;
	padding: 5px 15px;
	cursor: pointer;
	transition: background-color ease-in 200ms;

	&--selected,
	&:hover {
		background-color: $grey-1;
	}

	&--inactive {
		background-color: $grey-2;
		&:hover {
			background-color: $grey-3;
		}
		&:before {
			content: "";
			position: absolute;
			background-color: $grey-8;
			width: 3px;
			left: 0;
			top: 0;
			bottom: 0;
		}
	}
	& > .Row > .Column {
		align-self: center;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 10px;

		&:last-of-type {
			padding-right: 0;
		}

		& > .Text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 100%;
		}

		.indicator-wrapper {
			vertical-align: middle;
		}

		.score {
			padding-left: 5px;
		}
	}
	&__user-col {
		flex: 0 0 20%;
		& > .Text {
			display: flex;
		}
		.Avatar {
			vertical-align: bottom;
		}
	}

	&__avatar-wrap {
		display: inline-block;
		flex: 0 0 30px;
	}

	&__user-names-wrap {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__more-users-wrap {
		display: inline-block;
		flex: 0 0 30px;
		padding-left: 3px;
	}

	// Until we have <Block inline={true}> / <InlineBlock> in component-library
	.Text.inline,
	.Block.inline {
		display: inline-block;
	}
}
