@import "../../../../styles/_variables";

.BrokenFiscalYearSelect {
	display: inline-flex;
	flex-direction: column;
}

.BrokenFiscalYearSelect__navigation {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.BrokenFiscalYearSelect__navigation .Text {
	margin-top: 1px;
}

.BrokenFiscalYearSelect__chevron {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border-radius: 100%;
	border: 1px solid $grey-4;
	cursor: pointer;
	color: $grey-10;
	transition: color 200ms ease-in-out;
}

.BrokenFiscalYearSelect__chevron:hover {
	color: $grey-11;
}

.BrokenFiscalYearSelect__chevron--disabled {
	opacity: 0.4;
}

.BrokenFiscalYearSelect__chevron .Icon {
	font-size: 14px;
}

.BrokenFiscalYearSelect__grid {
	display: inline-grid;
	grid-template-rows: repeat(4, 40px);
	grid-template-columns: repeat(3, 94px);
	border-left: 1px solid $grey-2;
	border-top: 1px solid $grey-2;
}

.BrokenFiscalYearSelect__grid-item {
	text-transform: capitalize;
	border-bottom: 1px solid $grey-2;
	border-right: 1px solid $grey-2;
	justify-content: center;
	align-items: center;
	display: flex;
	cursor: pointer;
	transition: all 200ms ease-in-out;
}

.BrokenFiscalYearSelect__grid-item--selected {
	background-color: $bright-blue;
	color: white;
}

.BrokenFiscalYearSelect__grid-item:hover {
	background-color: $grey-2;
	color: $grey-13;
}

.BrokenFiscalYearSelect__grid-item--disabled {
	font-style: italic;
	background-color: $grey-2;
	cursor: not-allowed;
}
