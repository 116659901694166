.MoveContactModal {
	.ModalHeader {
		border-top: none;
	}

	.ModalContent .select2-container {
		width: fill-available;
	}

	.ModalControls.ModalControls.ModalControls {
		text-align: right;
	}
}
