@import "../../../../styles/variables";

.PurchaseCard {
	background-color: $grey-1;
	border: 1px solid $grey-4;
	padding: 10px;
	box-shadow: none;
	position: relative;

	.CardHeader {
		text-align: left;
		padding: 0;
		min-height: 0;
	}

	.CardHeader__title {
		&.Title {
			line-height: 1;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 11px;
			margin-bottom: 15px;
			color: $grey-10;
		}
	}

	.PurchaseCard__close-btn {
		position: absolute;
		top: 0;
		right: 0;

		.Icon {
			padding: 0;
		}
	}

	.PurchaseCard__cost {
		padding-top: 10px;
		display: inline-flex;
	}

	.PurchaseCard__content {
		text-align: right;

		input {
			max-width: 65px;
			padding: 0 8px;
			float: left;
			height: 30px;
		}

		button {
			min-width: 140px;
			margin-left: 12px;

			.Text {
				line-height: initial;
			}
		}

		.Row {
			float: right;
		}
	}
}
