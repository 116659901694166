@import "../../../../../styles/_variables";

.AppointmentTodos {
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	z-index: 1;
	border-radius: 3px;
	box-shadow: none;
	.Card {
		margin-bottom: 0px !important;
	}
}
