@import "~StyleVariables";

.CustomProjectBoard {
    max-width: 840px;
    border: 1px solid $grey-6;
    border-radius: $border-radius;
    margin-bottom: $space * 10;

    &__infoContainer {
        width: 450px;
    }

}