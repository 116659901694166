@import "../../../../../styles/_variables.scss";

.EasyBookingSettingsEditor {
	&__defaultLengths {
		max-width: 90%;
		white-space: nowrap;
		justify-content: space-between;

		.Column {
			display: flex;
			margin-top: 15px;
			cursor: pointer;
			max-width: fit-content;

			.Checkbox {
				margin-right: 7px;
				min-width: 20px;
			}

			.Text {
				text-transform: lowercase;
				min-width: fit-content;
				margin-right: 12px;
			}
		}
	}

	&__addOtherRow {
		> div {
			margin-top: 18px;
			align-items: center;
			max-width: 80%;
		}
	}

	&__removeAll {
		align-items: center;
		cursor: pointer;
		display: flex;
		margin-top: 10px;

		.Icon {
			padding-right: 5px;
		}

		.Text {
			white-space: nowrap;
		}
	}

	&__addOther {
		align-items: center;
		cursor: pointer;

		.Icon {
			margin-right: 5px;
		}
	}

	&__addWrapper {
		display: flex;
	}

	&__dropDown {
		min-width: 250px;
		max-width: fit-content;
		margin: 10px 15px 0px 0px;

		.Select-value {
			margin-top: 7px;
		}
	}
}

@media (max-width: 1100px) {
	.EasyBookingSettingsEditor {
		&__addWrapper {
			flex-direction: column;
		}
	}
}
