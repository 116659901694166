@import "~StyleVariables";
.BillingUpcomingInvoices {
	margin-bottom: 10px;

	.CardHeader {
		padding-left: 15px;
	}
	.CardHeader__title {
		font-weight: $boldFontWeight;
	}
	&__tableHeaderRight {
		text-align: right;
		padding-right: 15px;
	}
	&__tableHeaderNameWidth {
		width: 300px;
	}
	&__tableFooter {
		font-weight: $boldFontWeight;
		font-size: 16px;
	}
}
