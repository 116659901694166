@import "~StyleVariables";

.UpSelect {
	&__icon {
		position: absolute;
		z-index: 1;
		padding-top: 10px;
		padding-left: 10px;
		color: $grey-6;

		& + .select2-container {
			.select2-choice {
				padding-left: 20px;
			}
			.select2-choices {
				padding-left: 25px;
			}
		}
	}
	&__overflowText {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
