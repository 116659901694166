#account-profile-settings,
#admin-page-account-profile {
	.package-box {
		&.cover-img {
			min-height: 200px;
		}

		.overlay {
			padding-top: 0;
		}

		.package-box-footer.lined {
			height: 60px;
		}

		.package-box-title h2 {
			line-height: 1.2;
		}
	}

	.no-logo-text {
		margin-top: 35px !important;
		display: inline-block !important;
		color: $link-color;
		cursor: pointer;
	}
}
