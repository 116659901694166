@mixin card($header-height: 54px) {
	$responsive-width: 1294px;
	position: relative;

	&.full-size {
		position: absolute;
		right: $padding;
		left: $padding;
		width: auto;
		bottom: $padding;
		top: 0;

		&:after {
			content: "";
			position: fixed;
			bottom: -30px;
			left: 0;
			right: 0;
			background-color: $grey-6;
			height: 45px;
		}

		#card-main-view {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		#card-sidebar {
			margin-top: 49px;
		}

		&.has-footer {
			#card-sidebar:before {
				bottom: $card-footer-height;
			}

			#card-main-view {
				bottom: $card-footer-height;
			}
		}
	}

	&.has-navbar-only {
		.card-header {
			background-color: $white !important;
			border: none;
			height: 43px !important;

			#nav {
				margin: 0;
				height: 43px;
				// margin-left: 300px;
				// width: auto;
			}
		}

		#card-main-view {
			margin-top: 43px;
		}

		&#account-card:not(.old),
		&#contact-card:not(.old) {
			.card-header {
				height: 50px !important;
			}

			#nav {
				height: 50px;
			}

			#nav {
				a {
					height: 50px;
					line-height: 50px;
					background-color: transparent;

					.counter {
						color: $bright-blue;
						font-size: 12px;

						.overdue {
							color: $red;
						}

						.closed {
							color: $grey-10;
						}

						.sales {
							color: $bright-green;

							.up-icon-recurring-order {
								float: left;
								margin-right: 5px;
								margin-top: 1px;
							}
						}

						&.market {
							color: $purple;
						}
					}

					&#add-button {
						background-color: $blue;
						color: $white;
					}
				}

				a.regular {
					padding-top: 8px;
					line-height: 17px;
				}

				.dropdown-menu {
					a {
						height: 25px;
						line-height: 25px;
					}
				}
			}

			#card-main-view {
				margin-top: 49px;
				#top-section {
					margin: 0px;
				}
			}
		}
	}

	&.has-navbar-topbar {
		#card-main-view {
			margin-top: 92px;
		}
	}

	&.has-sidebar-sm,
	&.has-sidebar {
		#card-main-view {
			left: -1px;

			#top-section {
				border-bottom: 1px solid $grey-6;
				margin: $padding 0 0 0;
				margin-bottom: $padding;
				background-color: $white;
			}
		}
	}

	&.has-sidebar #card-main-view {
		margin-left: $card-sidebar-width;
		min-height: 800px;

		.Page--position {
			position: relative;
			padding-bottom: 100px;

			&:has(.Loader) {
				padding-bottom: 200px;
			}
		}
	}

	&.has-sidebar-sm {
		#card-sidebar {
			width: $card-sidebar-width-sm;

			&:before {
				width: $card-sidebar-width-sm;
			}
		}

		#card-main-view {
			margin-left: $card-sidebar-width-sm;
		}
	}

	&.has-sidebar-right {
		#card-main-view {
			margin-right: $card-sidebar-width;
			margin-left: -1px;
			right: -1px;
			border-right: 1px solid $grey-6;

			#top-section {
				border-bottom: 1px solid $grey-6;
				margin: $padding 0 0 0;
				margin-bottom: $padding;
				background-color: $white;
			}
		}
	}

	&.has-timeline {
		background-color: $grey-2;

		#card-main-view {
			background-color: transparent;
		}
	}

	&.has-graphs {
		$has-graphs: 500px;

		.up-header {
			margin-top: 20px;
		}

		.page-head {
			top: $has-graphs !important;
		}

		#card-main-view {
			margin-top: 0;
		}
	}

	&.file-dropzone-card {
		#dropzone-progress {
			display: none;
			position: absolute;
			top: -43px;
			left: 0;
			right: 0;
			height: 4px;
			background-color: $grey-4;
			z-index: 1030;

			.prog-indicator {
				position: absolute;
				left: 0;
				bottom: 0;
				top: 0;
				width: 0;
				background-color: $orange;
				@include transition(all ease 100ms);
			}
		}

		#card-dropzone {
			position: absolute;
			top: -43px;
			left: 0;
			right: 0;
			bottom: initial;
			height: 0;
			z-index: 1030;
			background-color: $blue;
			visibility: visible;
			@include opacity(0);
			@include transition(all ease 200ms);

			&.over {
				background-color: $dark-blue;
			}

			// Hide dropzone default items
			.dz-preview {
				display: none;
			}
		}
	}

	.card-header {
		background-color: $white;
		position: absolute;
		z-index: 1001;
		height: $header-height;
		@include border-radius($border-radius $border-radius 0 0);
		display: block;

		#title {
			display: block;
			font-size: 13px;
			padding: ($padding - 3px);

			h2 {
				color: $green;
			}
		}

		.status {
			color: $grey-10;
			font-size: 11px;
			vertical-align: top;
			margin-top: 6px;
			margin-right: 8px;

			&.green {
				color: $medium-green;
			}
		}

		.btn.list-add {
			color: $white;

			&:hover {
				text-decoration: underline !important;
			}
		}

		h2 {
			@include h2();
			font-size: 19px;
			display: inline-block;
			color: $green;
			margin: 5px 0 0 4px;
		}

		.actions {
			display: inline-block;
			position: absolute;
			right: $padding;
			top: 10px;

			.btn-link:hover {
				text-decoration: none;
			}

			.form-control {
				display: inline-block;
				width: initial;
				height: 31px;
				margin-right: 10px;
			}

			#add-button {
				display: inline-block;

				&.responsive {
					display: none;

					@media (max-width: $responsive-width) {
						& {
							display: inline-block;
						}
					}
				}
			}
		}
	}

	ul.nav {
		@include sub-page-navigation();
	}

	#card-sidebar {
		width: $card-sidebar-width;
		padding: $padding;
		float: left;
		font-size: 12px;
		border-right: 1px solid $grey-4;
		@include border-radius($border-radius 0 0 $border-radius);
		background-color: $white;
		margin-right: 1px;

		&::before {
			content: "";
			width: $card-sidebar-width;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background-color: $white;
			border-right: 1px solid rgba($grey-4, 1);
		}

		&.sidebar-right {
			border-right: none;
			border-left: 1px solid $grey-6;
			float: right;
			@include border-radius(0 $border-radius $border-radius 0);

			&::before {
				left: initial;
				right: 0;
				border-left: 1px solid $grey-6;
				border-right: none;
			}
		}

		&.no-padding {
			padding: 0;
		}

		#actions {
			position: relative;
			text-align: right;
			margin-top: -15px;
			margin-right: -15px;
		}

		.inline-input {
			width: 100%;
		}

		.title {
			position: relative;
			margin-bottom: $padding;
			padding-bottom: 10px;
			border-bottom: 1px solid $grey-4;

			h2 {
				display: block;
				font-size: 28px;
				padding-bottom: 5px;
				padding-top: 10px;
				word-wrap: break-word;
				font-weight: $boldFontWeight;
				letter-spacing: -0.5px;
			}

			.label {
				position: absolute;
				top: -10px;
				right: -10px;
				font-size: 85%;
				cursor: pointer;
			}
		}

		h2 {
			font-size: 17px;
		}

		.sidebar-section {
			position: relative;
			padding-bottom: 5px;
			margin-bottom: 25px;
			border-bottom: 1px solid $grey-4;

			&.company-group-card:hover {
				cursor: pointer;
				background-color: $grey-1;
			}

			&.no-border,
			&:last-child {
				border: none;
			}

			&.edge-to-edge {
				margin: 0 -15px 0 -15px;
				padding: 0;

				&:first-child {
					margin: -15px -15px 0 -15px;
				}
			}

			.ui-element-widget {
				padding: 10px 15px;
				background-color: $white;
				&.clickable {
					@include transition(all ease 100ms);
					&:hover {
						background-color: rgba($black, 0.05);
					}
				}
			}

			.sidebar-item {
				display: block;
				margin: 8px 0;
				word-wrap: break-word;

				&.notes {
					overflow: hidden;
					text-overflow: clip;
					height: 34px;
				}

				img {
					height: 14px;
					width: 14px;
					margin-top: -2px;
					margin-left: 3px;
					margin-right: 3px;
					background-color: $grey-13;
				}

				.icon {
					width: 20px;
					text-align: center;
					&.social {
						color: $bright-blue;
					}
					&.google {
						color: $blue;
					}
					&.custom-icon {
						color: $grey-13;
					}
					&.loader {
						color: $grey-8;
					}
				}
			}

			&.budget-progress-wrapper {
				padding-bottom: 40px;

				h2 {
					margin-bottom: 10px;
				}

				&.budget-progress {
					.amount {
						position: absolute;
						bottom: -14px;
					}
				}
			}
		}

		.date-split-horisontal {
			.sidebar-item {
				width: 120px;
				display: inline-block;

				&:last-child {
					border-left: 1px solid $grey-4;
					padding-left: 20px;
				}
			}
		}
	}

	#card-main-view {
		position: relative;
		background-color: $white;

		// Default margin
		margin-top: 48px;

		&.grey {
			background-color: $grey-1;
		}

		.form-section {
			padding: 20px;

			.input-wrap {
				margin-bottom: 20px;
			}
		}

		.card-section {
			.card-section-header {
				display: block;
				height: 50px;
				width: 100%;
				background-color: $white;
				padding: 0 0 0 20px;
				@include border-radius(3px 3px 0 0);

				.title {
					line-height: 50px;
					display: inline-block;
				}
			}

			.card-section-row {
				padding: 20px;
			}
		}

		#top-section {
			$toolbox-width: 240px;
			$widget-width: 200px;

			#info,
			#toolbox {
				ul {
					background-color: $grey-1;
					@include box-shadow(0 0 10px $grey-4);
				}
			}

			#info {
				overflow: hidden;
				padding: 0 $padding;

				#base-info,
				#links {
					padding-bottom: 10px;
				}

				#base-info {
					.info-item {
						width: 150px;
						display: inline-block;
						vertical-align: top;
						margin: 14px 10px 0 0;

						.key {
							display: block;
							font-size: 16px;
						}

						.value,
						.inline-placeholder,
						.inline-value {
							display: block;
							overflow: hidden;
							font-size: 13px;

							a + span,
							a {
								overflow: hidden;
								width: 100%;
								text-overflow: ellipsis;
								display: inline-block;
							}

							> span {
								width: 100%;
							}
						}
					}
				}

				/*#widgets {
					margin-top: $padding;
				}*/

				#links {
					border-top: 1px solid $grey-4;
					padding: $padding 0;
					color: $grey-10;
				}

				#other-info-wrap {
					border-top: 1px solid $grey-4;
				}
				#other-info {
					padding: $padding 0;

					#fields {
						margin-top: 10px;

						.field-item {
							font-size: 12px;
							display: inline-block;
							width: ($widget-width * 1) + 20;
							margin: 4px 10px 5px 0;
							vertical-align: top;

							.key {
								vertical-align: top;
								width: $widget-width;
								font-weight: $boldFontWeight;
							}

							.value {
								display: inline-block;
								width: $widget-width;
								vertical-align: top;
							}
						}
					}
				}

				.notes {
					margin: 14px 0 0 0;
					white-space: pre-wrap;
					word-wrap: break-word;
					font-size: 12px;
				}
			}

			#toolbox {
				float: right;
				width: $toolbox-width;
				margin: $padding;
				margin-left: 0;
				background-color: $white;
				color: $grey-10;

				@media (max-width: $responsive-width) {
					& {
						display: none;
					}
				}

				ul {
					border: 1px solid $grey-6;
					list-style-type: none;
					margin: 5px 0 0 0;
					padding: 5px 0;
					@include border-radius(5px);

					li {
						&.divider {
							height: 1px;
							background-color: $grey-6;
							display: block;
							margin: 5px 0;
						}

						a {
							display: block;
							padding: 5px 20px;
							color: $black;

							.fa {
								width: 20px;
							}

							&.hover,
							&:hover {
								text-decoration: none;
								background-color: $green;
								color: $white;
							}
						}
					}
				}
			}
		}

		#list-section {
			.list-wrap {
				margin-top: $padding;

				.list-header {
					border-top: 1px solid $grey-4;
					margin: $padding $padding 0 $padding;
					padding-top: $padding;

					&.no-border {
						border-top: 0;
					}

					h2 {
						display: inline-block;
					}

					.paginator {
						display: inline-block;
						float: right;
					}

					.list-actions {
						display: inline-block;
						margin-left: 10px;
						font-size: 12px;
						vertical-align: top;
						margin-top: 4px;
					}
				}

				.oldNotes {
					padding: 15px;
					white-space: pre-wrap;
				}

				.tag-cloud {
					padding: $padding;

					.tag {
						display: inline-block;
						font-size: 12px;
						padding: 2px 4px;
						border: 1px solid $grey-4;
						margin: 0 5px 5px 0;
						color: $grey-11;
						@include border-radius(4px);
					}
				}
			}
		}

		.sub-header {
			padding: $padding;

			h2 {
				vertical-align: middle;
			}

			.list-actions {
				display: inline-flex;
				margin-left: $padding;
				font-size: 12px;
				.dropdown {
					margin-left: 10px;
				}
			}
		}
	}

	#card-footer {
		position: fixed;
		bottom: 10px;
		background-color: $white;
		left: 65px;
		right: 15px;
		height: $card-footer-height;
		border-top: 1px solid $grey-4;
		border-radius: 0 0 $border-radius $border-radius;
		z-index: 1000;

		#buttons {
			margin-top: 4px;
			margin-right: 10px;

			button {
				margin-left: 10px;
			}
		}
	}
}

body #main-wrapper .upsales-card-header {
	#title {
		padding: 10px !important;
	}

	.card-header .actions {
		top: 8px !important;
		right: 15px !important;
	}
}

.divider {
	height: 1px;
	background-color: $grey-6;
	display: block;
	margin: 5px 0;
}
