@import "variables";
@import "mixins";

.up-esign-progress {
	$size: 25px;
	$size-sm: 22px;

	font-size: $size;
	display: inline-block;

	&.esign-progress-sm {
		font-size: $size-sm;

		.ball {
			width: $size-sm;
			height: $size-sm;
			line-height: $size-sm;
		}
	}

	.ball {
		position: relative;
		display: inline-block;
		width: 1em;
		height: 1em;
		line-height: 1em;
		text-align: center;
		background-color: $grey-4;
		@include border-radius($size);
		color: $grey-10;
		margin-right: 0.4em;
		cursor: default;

		&:last-of-type {
			margin-right: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			content: "";
			position: absolute;
			background-color: inherit;
			width: 1em;
			height: 2px;
			top: 50%;
			margin-top: -1px;
			left: 100%;
		}

		&.signed {
			background-color: $bright-green;
			color: $white;
		}

		&.rejected {
			background-color: $red;
			color: $white;

			& ~ .ball {
				background-color: $grey-8;

				&:before {
					background-color: $grey-8;
				}
			}
		}

		&.contact {
			cursor: pointer;
		}

		.fa.fa-warning {
			position: absolute;
			font-size: 10px;
			top: -3px;
			right: -3px;
		}

		span {
			font-size: 0.4em;
			vertical-align: top;
		}
	}

	.progress-string {
		font-size: 0.48em;
		vertical-align: middle;

		&.all-signed {
			color: $bright-green;

			.total-involvees {
				color: $bright-green;
			}
		}

		&.rejected {
			color: $red;

			.total-involvees {
				color: $red;
			}
		}

		&.signed {
			color: $bright-green;
		}

		.total-involvees {
			color: $black;
		}
	}
}
