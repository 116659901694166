@import "~StyleVariables";

.SubscriptionPreview {
	display: flex;
	flex-direction: column;
	height: 100%;

	.ModalContent {
		background-color: $white;
		flex-grow: 1;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}

	&__description-row {
		font-size: 12px;
		line-height: 18px;
		color: $grey-11;
		.Text {
			display: inline;
		}
	}
	&__summary-content {
		margin-top: 4px;
		margin-bottom: 64px;

		.Title {
			margin-bottom: 4px;
		}
	}
	&__summary-card {
		display: flex;
		flex-direction: column;
		background-color: $white;
		height: 100%;

		.SubscriptionPreviewGraph {
			height: 100%;
		}
	}

	&__summary-card-graph {
		flex: 1;
		background-color: $grey-4;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.Icon:hover {
			cursor: pointer;
		}
	}

	&__header,
	&__summary-content {
		padding-left: 12px;
		padding-right: 12px;
	}

	&__footer {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
}
