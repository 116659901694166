@import "~StyleVariables";

.SidebarSavedModalButton {
	display: flex;
	justify-content: center;

	.Button {
		border-width: 0;
	}
}
