@import "~StyleVariables";

.LostOpportunity {
	$slideInDuration: 500ms;
	$transition: all cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.3s;
	$slideUpDistance: -125px;
	$slideMarginBottom: 5px;

	position: absolute;
	z-index: $modal-z + 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $white;
	width: 100%;
	height: 100%;
	pointer-events: none;

	&--isModal {
		position: relative;
	}

	&--visible {
		pointer-events: auto;
	}

	&__header {
		margin-top: -5px;
		z-index: 1007; // want it to show above the SlideIn component

		&--isModal {
			position: relative;
			box-sizing: border-box;
			width: 100%;
		}
	}

	&__whyOpportunityWasLostText,
	&__condolencesText {
		font-weight: $boldFontWeight;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 500px;
		text-align: center;
		justify-content: center;
		height: 100%;
		margin-top: $navbar-height;

		&--isModal {
			margin-top: 0;
		}
	}

	&__disabledSaveButtonTooltip {
		width: 100%;
	}

	&__fullWidthButton {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&__loader {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__postSaveView {
		display: flex;
		justify-content: center;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: opacity 400ms ease-in-out;

		&--visible {
			opacity: 1;
		}
	}

	&__reasonForLossText,
	&__lostReasons {
		transition: $transition;
		transform: translateY(0%);
	}

	&__reasonForLossText {
		text-align: left;

		&--slideUp {
			transform: translateY($slideUpDistance - $slideMarginBottom);
		}
	}

	&__lostReasons {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		max-height: 150px;
		overflow: auto;

		&--slideUp {
			transform: translateY($slideUpDistance);
		}
	}

	&__textContainer {
		opacity: 1;
		transform: translateY(0%);
		transition: $transition;

		&--fadeOut {
			opacity: 0;
			transform: translateY(-80px);
		}
	}

	&__buttonContainer {
		width: 100%;
		transition: $transition;
		transform: translateY(0);

		&--hide {
			opacity: 0;
			visibility: hidden;
			transform: translateY(80px);
		}

		&--slideDown {
			transform: translateY(40px);
		}
	}

	&__competitorSelectContainer {
		position: relative;
		width: 100%;
	}

	&__relatedTasksContainer {
		opacity: 1;
		transform: translateY(0%);
		transition: $transition;

		&--slideDown {
			transform: translateY(40px);
		}

		&--fadeOut {
			opacity: 0;
			transform: translateY(-40px);
		}
	}

	&__relatedTasksText {
		max-height: 20px;
		width: 100%;
		transition: $transition;
		transform: translateY(0);
		&--slideUp {
			transform: translateY($slideUpDistance);
		}
		&--fadeOut {
			opacity: 0;
			max-height: 0;
			overflow: hidden;
		}
	}

	&__taskDeleteContainer {
		transition: $transition;
		transform: translateY(0);
		opacity: 1;
		text-align: left;
		width: 100%;
		max-height: 400px;
		&--slideUp {
			transform: translateY($slideUpDistance);
		}
		&--fadeOut {
			max-height: 0;
			opacity: 0;
			padding: 0;
			overflow: hidden;
		}
	}

	&__taskDeleteButton {
		width: 100%;
	}

	&__dropdownButton {
		margin-left: auto;
		&--hide {
			opacity: 0;
		}
	}

	&__taskDeleteInfoContainer {
		mask-image: linear-gradient(180deg, #000 80%, transparent);
		transition: $transition;
		overflow-y: auto;
		max-height: 200px;
		opacity: 1;
		&--hide {
			max-height: 0;
			padding: 0;
			opacity: 0;
			overflow-y: hidden;
		}
		&__todo,
		&__phonecall,
		&__appointment {
			transition: $transition;
			max-height: 200px;
			&--hide {
				max-height: 0;
				opacity: 0;
				overflow-y: hidden;
			}
		}
	}

	&__taskDeleteButton {
		&--hide {
			opacity: 0;
			max-height: 0;
		}
	}

	&__deleteTaskToggleText {
		transition: $transition;
		opacity: 1;
		&--hide {
			div:nth-child(2) {
				display: none;
				opacity: 0;
			}
		}
	}

	&__bottomContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: $transition;
		opacity: 1;
		&--fadeOutDown {
			opacity: 0;
			transform: translateY(40px);
		}
	}

	.CompetitorSelect {
		position: absolute;
		left: 0;
		top: 0;
		transition: all ease-in-out 0.5s;
		opacity: 0;
		transform: translateY(0);
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&--visible {
			transform: translateY($slideUpDistance);
			opacity: 1;
		}
	}
}
