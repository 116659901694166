@import "~StyleVariables";

.CreateActivityModal {
	&__Form-control {
		width: 100%;
		margin-bottom: 10px;
	}

	.Date-area {
		display: flex;
	}

	.Label {
		font-weight: $boldFontWeight;
		line-height: 1.8em;
		margin-bottom: 0;
	}

	.DateInput__popup {
		position: fixed;
		z-index: 1010;
	}

	&__Result-wrapper {
		display: flex;
		justify-content: space-between;
	}

	&__Users-count {
		color: $grey-8;
		font-style: italic;
	}

	.Toggle {
		margin-right: 10px;
	}

	.Row {
		&.column-justify {
			justify-content: space-between;
		}
	}

	&__Priority {
		height: 34px;
		display: flex;
		align-items: center;
	}
	&__user-or-role-label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
}
