@import "~StyleVariables";

.ProgressBarWidget {
	display: flex;
	flex-flow: column;
	justify-content: top;

	.Title {
		font-size: 42px;
		line-height: 36px;
		opacity: 1;
	}

	.Progressbar {
		border-radius: 3px;

		> .Progressbar__progress{
			border-radius: 0px;
		}
	}

	.Row {
		row-gap: 15px;
	}

	&:hover {
		cursor: pointer;
	}

	&__progress-bar-wrapper {
		position: relative;

	}

	&__info-column {
		margin-left: 20px;
		font-family: Roboto;
		position: relative;

		&--right-align > .Row {
			flex-direction: row-reverse;
		}

		&--center-align {
			flex-basis: max-content;
			flex-grow: 0;
		}
	}

	&__info-row-value {
		> .Text {
			font-size: 20px;
			line-height: 20px;
			text-align: left;
		}
	}

	&__info-row-label {
		> .Text {
			color: $grey-13;
			font-size: 13px;
			line-height: 16px;
			text-align: left;
		}
	}

	&__info-row-oval {
		float: left;
		top: 5px;
		left: -18px;
		position: absolute;
		border-radius: 100%;
		width: 10px;
		height: 10px;

		&--success {
			background-color: $success-4;
		}

		&--progress {
			background-color: $green;
		}

		&--sales {
			background-color: $grey-6;
		}
	}
}
