@import "../../../../../styles/variables";

.AccountListContacts__Email {
	font-size: 12px;
	max-width: 14vw;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	a {
		color: $bright-blue;
	}
}
