.Stages {
	border-radius: 8px;
	width: 790px;

	&__name {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.Text {
			margin-left: 8px;
		}
	}

	&__required {
		.Checkbox {
			margin-left: auto;
			margin-right: 8px;
		}
	}

}
