@import "~StyleVariables";

.SubscriptionIndexing {
	display: flex;
	flex-direction: column;
	padding-bottom: 30 * $space;
	height: 100%;

	&__content {
		width: 900px;
		align-self: center;
	}

	&__preview-banner {
		position: sticky;
		top: 0;
		z-index: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 3 * $space;
		min-height: 15 * $space;
		background-color: #E4EDF7;
		box-shadow: 0px 1px 2px 0px #1D3D4833;
	}

	&__preview-link {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.Row {
			align-items: center;
		}

		.Toggle {
			margin-right: $space;
		}

		.Text {
			padding: 0 1px; 
		}

		.Icon {
			padding: 0 $space;
		}
	}

	&__index-raise {
		border-radius: $space;
		padding: 4 * $space;
	}

	&__save-allowed {
		display: flex;
		align-items: center;
	}

	&__how-much {
		display: flex;
		align-items: center;

		.PercentageInput {
			margin-left: 2 * $space;
		}
	}

	&__specific-day {
		.DateInput {
			width: 300px;
		}
	}

	&__user-select {
		width: 300px;
	}
}