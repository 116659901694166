@mixin ModalTagList($modal-name, $tag-list-width: 280px) {
	.Modals .Modal.#{$modal-name} {
		overflow-x: visible;
		overflow-y: visible;
	}

	.#{$modal-name} {
		&__content {
			max-height: 95vh;
			overflow-y: auto;
		}

		&__tags {
			position: absolute;
			visibility: hidden;
			top: 0;
			right: 0;
			height: 100%;
			width: $tag-list-width;
			z-index: -1;

			transition: all 300ms cubic-bezier(0.8, 0, 0.8, 1);

			&--translateTagsList {
				visibility: visible;
				transform: translateX($tag-list-width);
			}

			#tag-list {
				top: 0;
				height: 100%;
				width: $tag-list-width;

				#hide-tags-btn {
					position: absolute;
					top: 5px;
					right: 10px;
				}

				> div,
				ul,
				h2,
				p {
					width: $tag-list-width !important;
				}
			}
		}
	}
}
