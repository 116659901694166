@import "~StyleVariables";

.FolderRow {
	background-color: $white;
	cursor: pointer;
	opacity: 1;
	
	&:hover {
		background-color: $grey-1;
	}

	&--isDragging {
		opacity: 0.1;
	}

	&__last-column {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 16px;
	}
	
	&__folder-icon {
		margin-right: 8px;
	}

	&__delete-button {
		&:hover {
			.Icon {
				color: $grey-10;
			}
		}
	}

	.TableHeader__column {
		border-bottom: 1px solid $grey-6;
		border-top: 1px solid $grey-6;
	}
}