@import "~StyleVariables";

.AddonAdBanner {
    .Text {
        white-space: pre-wrap;
    }

    margin-top: 32px;
    background-color: $super-duper-light-green;
    padding: 20px;
    border-radius: $border-radius;
    box-shadow: $box-shadow-md; //sm

    .Column {
        display: flex;
        flex-direction: column;
    }

    .AssistChip {
        margin-left: 12px;
    }

    &__buttonColumn {
        margin-left: 80px;
        gap: 8px;
        align-items: center;
        margin-top: 10px;
        .up-btn {
            font-size: 14px;
        }

        .Button {
            width: 100%;
        }

        .Icon {
            margin-right: 4px; 
        }

        &--nonBillingAdmin {
            margin-top: 0;
            justify-content: center;
            align-items: flex-end;
        }
    }

    &__textColumn {
        >.Row {
            margin-bottom: 12px;
            align-items: center;
        }
    }
}