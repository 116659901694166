@import "~StyleVariables";

.ReportcenterWidgetCustomFilters {
	&__header {
		margin-top: 14px;
		margin-bottom: 14px;
	}

	.PageSidebarGroup__content {
		padding-bottom: 12px;
	}
}
