.EditSocialEventDetailsNotifyGuestList {
	padding: 20px;
}

.EditSocialEventDetailsNotifyGuestList__first-row {
	.second-column {
		text-align: right;
		.how-does-this-work {
			margin-left: 5px;
		}
	}
}

.EditSocialEventDetailsNotifyGuestList__third-row,
.EditSocialEventDetailsNotifyGuestList__second-row,
.EditSocialEventDetailsNotifyGuestList__fourth-row {
	margin-top: 20px;
}

.EditSocialEventDetailsNotifyGuestList__fourth-row {
	.buttons {
		margin-right: 20px;
	}
}
