@import "~StyleVariables";

.JourneyHistoryDropDown__dropdown .Card {
	padding-top: 3px;
}
.JourneyHistoryDropDown__dropdown {
	display: flex;
	width: 100%;
}

.JourneyHistoryDropDown__table-wrapper {
	display: inline-block;
	overflow-y: auto;
	max-height: 305px;
	width: 100%;
}

.JourneyHistoryDropDown__dropdown .DropDownMenu__content {
	bottom: 0;
	width: 100%;
	min-width: 205px;
	transform: translateY(100%);
	overflow: hidden;
}

.JourneyHistoryDropDown__action-row {
	padding: 5px 7px 5px 0;
}

.JourneyHistoryDropDown__action-col-ball {
	display: flex;
}

.JourneyHistoryDropDown__action-col-button .Button {
	display: flex;
}

.JourneyHistoryDropDown__action-col-button .Icon {
	margin: auto 0;
}
.JourneyHistoryDropDown__action-col-button .Text {
	margin: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 14ch;
}

$ballSize: 12px;
.JourneyHistoryDropDown__ball {
	margin: 0 12px;
	width: $ballSize;
	height: $ballSize;
	border-radius: 50%;
	border: 2px solid;
	position: relative;
	z-index: 1;
	border-color: $grey-10;
	background: $grey-10;
}

.JourneyHistoryDropDown__ball--white {
	border-color: $grey-8;
	background: $white;
}

.JourneyHistoryDropDown__line {
	height: 100%;
	position: absolute;
	left: 17px;
	bottom: -50%;
}

.JourneyHistoryDropDown__line--solid {
	border: 1px solid $grey-8;
}

.JourneyHistoryDropDown__line--hidden {
	border: none;
}

.JourneyHistoryDropDown__action-line {
	position: absolute;
	height: 126px;
	top: 22px;
	left: 17px;
	border: 1px dashed $grey-8;
}

.JourneyHistoryDropDown__history-header {
	margin-top: 10px;
	margin-left: 36px;
}

.JourneyHistoryDropDown__history-row span {
	color: $bright-blue;
	font-style: normal;
}

.JourneyHistoryDropDown__history-row .TableColumn {
	padding-left: 0;
}

.JourneyHistoryDropDown__history-col-ball {
	width: 36px;
	padding-left: 0px !important;
}
