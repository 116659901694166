@import "~StyleVariables";

.AgreementFulfillmentModal {
	// I think this is how you set the content width? 
	--modalContentWidth: 800px;

	/* 
		This is needed to get the content not to be centered in the middle of the screen.
		I have no idea what the fourth row is supposed to be if the other are header, content and footer.
	*/
	grid-template-rows: auto 0 1fr 0;

	// The padding works fine when you use the title prop of the ModalHeader but not so good when you want to add tabs or other things to the header...
	.ModalHeader, .ModalHeader__content {
		padding: 0;	
	}

	/* 
		This is the same z-index as Modals__modal--visible, but lower then Modals__modal--active (z-index 10000).
		Idk if this is a bug or by design but in this case I do not want the modal to be "active" when the drawer is open.
		Only having the Modals__modal--visible puts the modal behind the curtain, which is what I want when the drawer is active.
	*/
	&--editProjectPlanOpen {	
		z-index: 9999 !important;
	}

	.ButtonBox {
		height: 100px;
		width: 256px;
	}

	&__main-flex {
		overflow-x: auto;
		&--isOverflowing {
			.EditorHeaderButton{
				height: 65px;
			}
		}
	}
	&__tabs-container {
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		height: 50px;
		&--isOverflowing {
			height: 65px;
		}
	}

	.Tab {
		max-width: 150px;
	}

	// This could be achived bu using 4 different component instead of the default behavior of the Tab component, but this is less code
	.Tabs .Icon-check-circle, .Tabs .Icon-check-circle + .Tab__subtitle {
		color: $success-5;
	}
	
	// Bug in the ui-library where Tab__icon has position: absolute, but Tab has no position attribute
	.Tab--has-icon {
		position: relative;
	}

	// I hate tables, give me a grid component
	.Table {
		white-space: nowrap;
		table-layout: fixed;

		.TableHeader__column {
			width: 115px;
		}

		.TableHeader__column:nth-child(1) {
			width: 60%;
		}

		.TableHeader__column:nth-child(5) {
			width: 40%;
		}
	}
}

// I Have two blocks I animate between, those still takes spacae when not visible. In my case I want to stack them ontop of each other
.AgreementFulfillmentModal__AnimationContainer .Animate {
	position: absolute;
    width: 100%;
}
