.ColsRenderType {
	margin: 10px 0;

	.ellipsis-col {
		overflow: hidden;

		.widget-link-wrapper,
		.widget-text-wrapper {
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
			overflow: hidden;
		}
	}
}
