@import "~StyleVariables";

.AssignModalAssigned {
	text-align: center;
	padding-top: 25%;
}

.AssignModalAssigned__icon {
	color: $bright-green;
	font-size: 40px;
}

.AssignModalAssigned__text {
	margin-top: 10px;
}
