@import "~StyleVariables";

.OpportunityTodos {
	.Card {
		border-radius: $border-radius;

		.Loader {
			margin: auto;
		}
	}

	&__header {
		margin-bottom: 15px;

		.Headline.Skeleton:before {
			content: "loading a really long headline";
		}
	}

	&__header-subtitle {
		align-self: center;

		.Progressbar,
		.Text {
			display: inline-block;
		}

		.Progressbar {
			width: 300px;
			margin-left: 10px;
		}

		.Text.Skeleton:before {
			content: "loading a longer text than usual, this should be wider";
		}
	}

	@keyframes slide-large {
		0% {
			height: 0px;
			opacity: 0;
		}
		70% {
			height: 100px;
			// opacity: ;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes slide {
		0% {
			height: 0px;
			opacity: 0;
		}
		70% {
			height: 55px;
			opacity: 0.2;
		}
		100% {
			opacity: 1;
		}
	}

	#edit-row-phonecall {
		animation: slide-large 200ms;
		height: 100px;

		#edit-row {
			height: 100px;
		}
	}

	#edit-row-todo {
		animation: slide 200ms;
		height: 55px;
	}

	&__edit-row {
		border-top: 1px solid $grey-3;
		border-bottom: 1px solid $grey-3;
		padding-right: 20px;

		& > form,
		& > form > .Row {
			min-height: 55px;
		}

		& > form > .Row > .Column {
			align-self: center;

			&:first-child {
				font-size: 14px;
			}

			&:last-child {
				flex: 0 1 auto;
			}
		}

		.Input__input {
			padding: 0px !important;
		}

		&-content {
			padding: 4px 0px;
			display: flex;
			align-items: center;
			gap: 12px;
		}

		&-icons {
			.Button {
				&:hover:not(#submit-btn) {
					background-color: $grey-3;
				}
			}
			#submit-btn {
				margin-right: 4px;
			}
		}
	}

	.CardHeader {
		&__actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 8px;

			.Button {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;
				gap: 4px;
			}
		}

		&__icon {
			color: $orange;
		}
	}

	&__taskActions {
		margin-top: 15px;

		.Button {
			margin-right: 15px;
		}
	}
	&__flag-tooltip {
		margin-left: 8px;
	}

	&__error {
		padding: 20px;
	}
}
