.MailBounce__Select {
	height: 34px;
	margin: 10px 5px;

	* {
		max-height: 34px;
	}

	.Select-arrow-zone {
		border-color: transparent;
	}
}
