.compiled > #mail-card,
.compiled#mail-card {
	opacity: 1;
}

#mail-card {
	position: fixed !important;
	top: $main-top !important;
	left: 65px !important;
	right: $padding !important;
	bottom: $padding !important;
	transition: top 200ms, bottom 200ms, left 200ms, right 200ms;
	opacity: 0;

	&.fullscreen {
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		z-index: $modal-z - 1;

		.up-header {
			top: 0;
			left: 0;
			right: 0;
		}
	}

	.up-header {
		transition: top 200ms, bottom 200ms, left 200ms, right 200ms;
	}

	#card-main-view {
		background-color: $grey-1 !important;
	}

	.template-editor-standard-templates {
		position: absolute;
		padding: 20px;
		max-height: 100%;
		overflow-y: scroll;

		#template-gallery {
			padding-right: 0;
			margin-top: 20px;
		}
	}
}

.upload-item {
	position: relative;
	display: inline-block;
	margin-left: 10px;
	padding: 7px;
	background-color: $grey-8;
	@include border-radius(3px);

	&.uploaded {
		background-color: $bright-blue;
	}

	.file-progress {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		background-color: $bright-blue;
		@include border-radius(3px);
	}

	.file-name,
	.remove-file {
		position: relative;
		z-index: 1001;
	}
}

.input-stack {
	$grey-8: $grey-8;
	$input-height: 45px;

	display: inline-block;
	width: 100%;
	margin-bottom: 10px;

	.input-group {
		padding: 0;

		.option-heading {
			color: $grey-13;
			font-size: 14px;
			line-height: 17px;
			text-align: left;
			font-weight: $boldFontWeight;
			display: inline-block;
			margin-bottom: 5px;
		}

		.template-select {
			width: 100%;
			border-radius: 0 0 4px 0 !important;

			a {
				border-radius: inherit;
				border-bottom: 0;
			}
		}

		.from-select {
			width: 100%;
			border-radius: 0 4px 0 0 !important;

			a {
				border-radius: inherit;
				border-bottom: 0;
			}
		}

		.trigger-select {
			.select2-container .select2-choice {
				height: 34px;
				line-height: 1.42857;
				border-radius: 0 5px 5px 0 !important;
				.select2-arrow b {
					background-position: 0 3px;
				}
			}
			.form-control {
				height: 35px;
			}
			.select2-dropdown-open {
				.select2-arrow b {
					background-position: -18px 3px !important;
				}
			}
		}

		.country-region-example {
			font-size: 12px;
			font-style: italic;
			color: $grey-8;
		}

		.form-control {
			height: 34px;

			&.select2-container .select2-choice .select2-chosen {
				height: 34px;
				line-height: 26px;
			}

			&.select2-container-multi {
				height: auto;
				min-height: 34px;

				input {
					width: auto !important;
				}
			}

			&.select2-container-multi .select2-choices {
				min-height: 34px;
				@include border-radius(0);
				border-bottom: none;
			}

			&.has-error {
				background-color: $super-light-red;
				border-color: $dark-red;
			}
		}

		&:first-child {
			.input-group-addon {
				@include border-radius(5px 0 0 0);
			}
			.form-control {
				@include border-radius(0 5px 0 0);

				&.select2-container {
					.select2-choice {
						@include border-radius(0 5px 0 0);
					}

					.select2-choices {
						@include border-radius(0 5px 0 0);
					}
				}
			}
		}

		&:last-child {
			.input-group-addon {
				@include border-radius(0 0 0 5px);
				border-bottom: 1px solid $grey-8;
			}
			.form-control {
				@include border-radius(0 0 5px 0);
				border-bottom: 1px solid $grey-8;

				&.select2-container {
					.select2-choice {
						@include border-radius(0 0 5px 0);
					}
				}
			}
		}

		.input-group-addon {
			width: 130px;
			text-align: right;
			border-bottom: none;
			background-color: $grey-2;
			@include border-radius(0);
		}

		.white-select {
			background-color: $white;
			border: 1px solid $grey-8;
			@include border-radius(0 0 2px 2px);
			margin-left: -1px;
			margin-right: 0px;

			.select-set {
				padding-top: 20px;
				padding-bottom: 15px;
				padding-left: 12px;
			}
		}

		.block-div {
			display: block !important;
			line-height: 30px;
		}

		#upload-btn {
			position: absolute;
			right: 10px;
			top: 2px;
			z-index: 1000;
			cursor: pointer;

			#uploader {
				opacity: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 100%;
			}
		}

		.save-template-btn > i {
			position: relative;
			top: -2px;
			right: -10px;
		}

		.save-template-btn,
		.save-template-btn:focus,
		.save-template-btn:active {
			position: absolute;
			z-index: 1000;
			background: none;
			border: none;
			right: 34px;
			top: 11px !important;
			background-color: transparent !important;
			color: $grey-10;
			box-shadow: none;
			z-index: 99999999;

			.save-template-btn-click {
				position: relative;
				left: 33px;
				background: $grey-4;
				padding: 6px 10px;
				border-radius: 4px;
				bottom: 4px;
			}
			.save-template-btn-cancel {
				left: 18px;
				position: relative;
				bottom: 3px;
			}
			.save-template-btn-upate-cancel {
				// right: 7px;
				position: relative;
			}
			span {
				text-decoration: none;
			}

			#uploader {
				opacity: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 100%;
			}

			.save-template-first {
				position: relative;
				left: 15px;
				bottom: 2px;
			}
		}
		.update-template-btn {
			top: 11px;
		}
		.save-template-btn-blue {
			color: $medium-blue !important;
		}

		.show-template {
			display: block;
		}

		.hide-template {
			display: none;
		}
	}

	.form-control {
		@include border-radius(0);
		border-color: $grey-8;
		border-bottom: none;
		position: relative;
		height: $input-height;
		font-size: 14px;

		&:focus {
			z-index: 20000;
			border-color: $bright-blue !important;
		}

		&.error {
			border: 1px solid $red !important;
			background-color: $super-light-red !important;
		}

		&.select2-container {
			.select2-choice {
				@include border-radius(0);
				height: $input-height;
				border-bottom: none;

				.select2-arrow b {
					background-position: 0 9px;
				}

				.select2-chosen {
					height: $input-height;
					line-height: 33px;
				}
			}
		}
	}
}

.cke.flat {
	@include box-shadow(none);
}

.send-email-action {
	.ck-wrap.error {
		#cke_field_body {
			border: 1px solid $red !important;
		}
	}

	.select2-container.template-input {
		border-bottom: 1px solid #A4B3C7;
	}

	.select2-container.email-select {
		border: 0 !important;
		height: auto !important;

		.select2-choices {
			.select2-search-choice {
				margin-top: 8px;
			}

			.select2-search-field {
				margin-top: 2px;
			}
		}

		&.error:not(.select2-dropdown-open) {
			.select2-choices {
				border: 1px solid $red !important;
				background-color: $super-light-red !important;
			}
		}
	}
}

.template-list-btn-private {
	width: 49%;
	background: $grey-2;
	border: none;
	padding: 7px;
	color: $grey-10;
	margin: 0px 0px 10px 4px;
	border-radius: 4px 0px 0px 4px;
}

.template-list-btn-public {
	width: 49.9%;
	background: $grey-2;
	border: none;
	padding: 7px;
	color: $grey-10;
	margin: 0px 0px 0px 0px;
	border-radius: 4px;
}

.template-list-btn-active {
	background: $bright-blue !important;
	color: $white !important;
}

.sent-mail-content .cc-block {
	height: auto !important;
}

.send-email-sender {
	line-height: 0;
}
