@import "~StyleVariables";

.WebhookInfo .criteria-row {
	white-space: nowrap;
	width: 500px;

	> div {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.WebhookInfo__title {
	font-weight: $boldFontWeight;
	font-size: 13px;
}

.WebhookInfo__sub-title {
	font-size: 11px;
	color: $grey-11;
}
