@import "../../../../../styles/_variables.scss";

.EasyBookingSettingsEditor {
	#admin-content {
		position: relative;
		padding-bottom: 180px !important;
		max-width: 1200px !important;
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	#admin-page-easy-booking-settings-cover {
		background: linear-gradient(180deg, rgba($grey-3, 0.36) 0%, $grey-3 100%);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
	}

	#admin-page-easy-booking-settings-editor {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	&__loaderWrapper {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
	}

	&__bookingPage--columns {
		display: flex;

		#easy-booking-company-logo {
			margin-top: 13px;
			margin-bottom: 16px;

			img {
				max-height: 100px;
				max-width: 100%;
				object-fit: contain;
			}
		}
	}

	&__cardContent {
		padding: 15px 0;

		.Row {
			align-items: center;
		}
	}

	&__contact,
	&__appointments {
		> .Card {
			height: 100%;
			padding-bottom: 20px;
		}

		&__toggleWrap {
			margin-top: 5px;
			display: flex;
			align-items: center;
		}
	}

	&__appointments,
	&__timeSlots,
	&__bufferLimit,
	&__defaultBooking {
		&__label {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			font-weight: bold;
			margin-top: 20px;

			&--first {
				margin-top: 10px;
			}

			.Label {
				font-weight: bold;
			}

			.Text {
				font-size: 12px;
			}
		}
	}

	&__appointments {
		flex-grow: 1;
		flex-basis: 67%;

		&__label {
			&--left {
				justify-content: flex-start;
				align-items: baseline;
			}
		}

		&__toggleLabel {
			margin-left: 10px;
			font-weight: normal !important;
		}

		&__buttonLocation {
			position: relative;
			right: 16px;
		}

		&__inputLocation {
			max-width: 500px;
		}

		.TextEditor {
			border: solid 1px $grey-6;
		}
	}

	&__contact {
		min-width: 250px;
		max-width: 400px;
		flex-grow: 1;
		flex-basis: 33%;

		&__title {
			margin-bottom: 10px;
		}

		&__link {
			display: block;
			font-size: 12px;
			margin-bottom: 4px;
		}

		&__name {
			font-weight: bold;
		}

		&__toggle {
			display: inline-block;
		}

		&__toggleLabel {
			display: inline;
			font-size: 14px;
			margin-left: 8px;
		}

		&__pageUrl {
			margin-top: 22px;
			padding-bottom: 10px;

			&__title {
				display: flex;
				align-items: baseline;

				> .Title {
					font-weight: bold;
				}
			}

			&__buttonWrap {
				margin-top: 14px;
				flex-wrap: nowrap;

				> .Column {
					display: flex;
					flex-wrap: nowrap;
				}
			}

			&__buttonCol {
				flex-grow: 3;

				&--right {
					flex-grow: inherit;
					justify-content: flex-end;
				}
			}
		}
	}

	&__timeSlots {
		margin-top: 30px;
		padding-bottom: 10px;

		.CardContent {
			padding: 0px 20px;
			padding-bottom: 15px;
		}

		&__wrap {
			max-width: 350px;
		}

		.ButtonSelect {
			width: 100%;
		}

		&__timeZoneDesc {
			margin-top: 8px;
			margin-bottom: 8px;
		}

		&__labelDescription {
			font-size: 13px;
			margin-bottom: 10px;
		}

		&__rangeInputWrap {
			display: flex;

			.Input {
				width: 66px;
				margin-right: 8px;

				input {
					text-align: center;
				}
			}
		}
	}

	&__calendarInvitationAgenda {
		max-width: 500px;
	}

	.Calendar {
		font-family: "Roboto", sans-serif;
	}

	&__sharedBookingHeader {
		a {
			margin-left: 5px;
		}

		.CardHeader__title-wrap {
			.CardHeader__subtitle {
				display: flex;
				align-items: center;

				.Help {
					color: $bright-blue;
					margin-left: 5px;
					display: inline-block;
				}
			}
		}
	}

	&__meetingRowsDescriptions {
		font-weight: 500;

		.TableHeader__column {
			padding-left: 20px;
		}
	}

	&__meetingInformation,
	&__noSharedBookings {
		.TableColumn {
			padding-left: 20px;
		}
	}

	&__sharedWith {
		> div {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			width: 25%;

			.Tooltip:nth-child(2) {
				position: relative;
				left: -30%;
			}
			.Tooltip:nth-child(3) {
				position: relative;
				left: -60%;
			}

			.Tooltip:last-child() {
				align-self: center;
			}
		}
	}

	.Calendar__Day--today {
		background-color: $white;
	}

	&__defaultBookingToggle {
		margin-top: 9px;
		display: grid;
		flex-direction: row;
		grid-template-columns: 1fr auto;

		.Text {
			margin-bottom: 20px;
		}

		.Toggle {
			margin-right: 16px;
		}
	}

	.Calendar {
		font-family: "Roboto", sans-serif;
	}

	.Calendar__Day--today {
		background-color: $white;
	}

	&__bufferLimit {
		&__bufferTime,
		&__appointmentLimit {
			margin: 20px 0;
			align-items: center;

			> div {
				font-weight: 400;
				display: inline;
			}
			.Row,
			> .Input {
				display: inline-flex;
			}

			.input-group-addon {
				width: auto;
				line-height: 1.4;
				color: $grey-10;
			}
		}

		&__addOther {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: fit-content;
			cursor: pointer;
			max-width: 50%;

			.Icon {
				margin-right: 7px;
			}
		}

		&__appointmentLimit {
			margin: unset;

			.Input {
				height: 40px;
				width: 50px;
				align-items: center;
			}
		}

		&__remove {
			cursor: pointer;
		}

		&__bufferTime {
			.Input {
				width: 50px;
			}

			input {
				border-radius: 4px 0px 0px 4px;
			}
		}
	}

	&__appointmentDescriptions {
		span {
			.Icon {
				padding-left: 4px;
			}
		}

		td:first-child {
			width: 30%;
		}

		td {
			min-width: 30%;
		}

		.TableHeader__column {
			padding-left: 20px;
		}

		.TableHeader__column:last-child {
			padding-left: 10px;
		}

		.TableHeader__column:nth-child(1) {
			width: 50%;
		}

		.TableHeader__column:nth-child(2) {
			width: 20%;
		}
	}

	&__active {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 40px;
		min-width: 130px;
	}

	&__status {
		display: flex;
		align-items: center;

		.Icon {
			margin-right: 15px;
		}
	}

	&__meetingInformation {
		.TableColumn:first-child {
			padding-left: 20px;
			width: 20%;
		}

		.TableColumn:nth-child(2) {
			padding-left: 20px;

			.Row {
				align-items: center;
			}

			.Text {
				margin-left: 10px;
			}
		}

		.TableColumn:nth-child(3) {
			padding-left: 15px;
			min-width: 102px;
		}

		.TableColumn:nth-child(4) {
			padding-left: 10px;
			min-width: 102px;

			.Row {
				width: fit-content;
			}
		}

		.RadioListItem {
			width: 20px;
		}
	}

	&__noBookingPages {
		position: relative;
		left: 40%;
		font-size: 20px;
		width: fit-content;
		margin: 0 auto;

		.Text {
			text-align: center;
			line-height: 40px;
			height: 20px;
			font-size: 20px;
			color: #a4b3c7;
			position: relative;
			top: -20px;
		}
	}

	&__actions {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
		max-width: 110px;

		.inline-confirm-modal .Icon-trash,
		&--trash:hover .Icon-trash {
			color: $red;
		}

		.Button {
			padding: unset;
		}

		.Button:nth-child(2) {
			padding-left: 5px;
		}
	}

	.Avatar--gravatar {
		box-shadow: unset;
	}

	&__logoButton {
		padding: 0px;
	}
}
