@import "~StyleVariables";

.FormVisits {
	td {
		width: auto;
		padding: 0 0 0 10px;
		height: 38px;
		font-size: 12px;
		border-bottom: 1px solid $grey-4;

		.source-icon {
			display: inline-block;
			vertical-align: top;
			padding: 10px;
			padding-left: 4px;
		}

		.title,
		.subtitle {
			position: absolute;
			top: 3px;
			left: 26px;
			max-width: 77%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			z-index: 1;
		}

		.subtitle {
			top: 16px;
			color: $grey-10;
			font-size: 11px;
		}
	}
}
