@import "~StyleVariables";

.ActivityInfo__title {
	font-weight: $boldFontWeight;
	font-size: 13px;
}

.ActivityInfo__sub-title {
	font-size: 11px;
	color: $grey-11;
}
