@import "_variables.scss";

#voice-wrap {
	$animation-ms: 200ms;
	$toolbar-height: 35px;
	$padding: 30px;
	$fallback-action-height: 60px;

	&.open {
		#voice-device-wrapper {
			bottom: 0;
		}
	}

	.clickable {
		cursor: pointer;
	}

	.hangup-icon {
		padding-right: 3px;
	}

	&.minimized {
		#voice-device-wrapper {
			border: 1px solid $grey-5;
			border-radius: 3px 3px 0 0;
			height: 65px;
			animation: blinkCall 2s infinite;
			#hang-up-btn {
				position: absolute;
				top: 15px;
				left: 10px;
				height: 30px;
				width: 30px;
				line-height: 30px;
				font-size: 15px;
				margin-left: 0;
			}

			.voice-view {
				padding-top: 15px;

				h2 {
					font-size: 12px;
					margin: 0 30px;
					line-height: 15px;
				}

				.subtitle {
					height: 0;
				}
			}

			.empty-top {
				padding-top: 0;
			}

			.dial-info {
				.caller-name {
					overflow: hidden;
					.Text {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
				.caller-options {
					padding: 5px 6px 0 0;
				}
			}

			@keyframes blinkCall {
				0% {
					background-color: $white;
				}
				50% {
					background-color: $super-light-red;
				}
				100% {
					background-color: $white;
				}
			}
		}
	}

	#voice-device-wrapper {
		position: fixed;
		bottom: -100%;
		@include transition(all ease-in $animation-ms);
		right: 100px;
		width: 250px;
		height: 470px - $fallback-action-height;
		background-color: $grey-4;
		@include box-shadow(0 5px 20px rgba($black, 0.2));
		z-index: $modal-z + 3;
		@include border-radius(3px 3px 0 0);
		overflow: hidden;

		#voice-tools {
			.with-text {
				width: 95px;
			}

			.minimize-text {
				display: inline-flex;
				padding-left: 6px;
			}

			height: $toolbar-height;
			display: block;
			position: relative;
			z-index: $modal-z + 5;

			&.light {
				button {
					color: rgba($white, 0.5);

					&:hover {
						color: $white;
					}
				}
			}

			button {
				width: $toolbar-height;
				height: $toolbar-height;
				line-height: $toolbar-height;
				border: none;
				background-color: transparent;
				color: rgba($black, 0.5);
				text-align: center;
				@include transition(color ease-in $animation-ms);

				&:hover {
					color: $black;
				}

				.fa {
					font-size: 16px;
				}
			}
		}

		.voice-view {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: 70px;
			visibility: hidden;
			@include opacity(0);
			@include transition(all ease-in $animation-ms);

			&.view-visible {
				visibility: visible;
				@include opacity(1);
			}

			h2 {
				display: block;
				text-align: center;
				font-size: 18px;
				font-weight: $regularFontWeight;
				line-height: 30px;
				@include transition(all ease-in $animation-ms);
			}

			.subtitle {
				display: block;
				text-transform: uppercase;
				color: $grey-10;
				font-size: 10px;
				text-align: center;
				letter-spacing: 1px;
				line-height: 20px;
				height: 20px;
				@include transition(all ease-in $animation-ms);
			}
		}

		#caller-id {
			.number-row {
				display: block;
				text-align: center;
				font-size: 14px;
				font-weight: $regularFontWeight;
				line-height: 25px;
			}

			h2,
			.subtitle,
			.number-row {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		#followup {
			padding: 0 $padding;
			padding-top: $toolbar-height;

			#followup-icon {
				$size: 42px;
				display: block;
				width: $size;
				height: $size;
				line-height: $size;
				margin: 0 auto;
				font-size: $size;
				text-align: center;
				position: relative;
				color: $grey-10;

				.fa-times {
					position: absolute;
					top: 0;
					right: 0.2em;
					font-size: 0.4em;
				}
			}

			h2 {
				$line-height: 25px;
				line-height: $line-height;
				height: $line-height * 2;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			#call-actions {
				position: absolute;
				top: 190px;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: $white;

				.action {
					width: 50%;
					height: $fallback-action-height;
					display: inline-block;
					color: $bright-blue;
					text-align: center;
					font-size: 12px;
					padding: 10px 0;
					vertical-align: top;
					border-bottom: 1px solid $grey-4;
					border-right: 1px solid $grey-4;
					cursor: pointer;

					&:hover {
						background-color: $grey-1;
					}

					b {
						$size: 20px;
						font-size: $size;
						line-height: $size;
						height: $size;
						display: block;
						margin-bottom: 5px;
					}
				}
			}

			&.minimized {
				padding-top: 15px;
			}
		}

		.phone-btn {
			$size: 40px;

			position: absolute;
			left: 50%;
			top: 155px;
			margin-left: -($size / 2);
			border: none;
			width: $size;
			height: $size;
			@include border-radius($size);
			text-align: center;
			line-height: $size;
			background-color: $red;
			@include box-shadow(0 5px 10px rgba($black, 0.2));
			color: $white;
			font-size: 18px;
			@include transition(all ease-in $animation-ms);
			display: block;
		}

		#in-call {
			background-color: $medium-blue;

			#in-call-bg {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 140px;
				@include opacity(0.1);
				background-position: center -30px;
				position: absolute;
				top: 0;
				width: 100%;
				height: 177px;
				left: 0;
				opacity: 0.1;
				pointer-events: none;
			}

			.meta {
				padding: 0 $padding;

				> span {
					display: block;
					text-align: center;
					color: $white;
				}

				h2 {
					color: $white;
				}
				.subtitle {
					color: $white;
				}

				.number-row {
					display: block;
					text-align: center;
					font-size: 14px;
					font-weight: $regularFontWeight;
					line-height: 25px;
					@include transition(all ease-in $animation-ms);
				}

				h2,
				.subtitle,
				.number-row {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}

			.phone-btn {
				z-index: $modal-z + 15;
			}

			.activity-list {
				position: absolute;
				left: 0;
				right: 0;
				top: 210px;

				ul {
					padding: 0;
					list-style: none;

					li {
						padding: 0 1em;
						color: $white;
						font-size: 16px;
						font-weight: $regularFontWeight;
						line-height: 2em;
						@include transition(all ease-in $animation-ms);
						cursor: pointer;

						&:hover {
							background-color: rgba($medium-blue, 0.5);
						}

						i.fa-fw,
						i[class^="up-icon-"] {
							width: 50px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}

.phonecall-modal {
	width: 400px !important;
	margin-left: -200px !important;
}

.open-phonecall-modal {
	.up-modal-content {
		padding: 0;

		.header {
			h2 {
				line-height: 1.4em;
				display: block;
			}

			small {
				font-size: 12px;
				line-height: 1.4em;
				display: block;
			}

			.modal-close {
				right: 10px;
				top: 20px;
				position: absolute;
			}
		}

		.content {
			.user-called-to {
				font-size: 16px;
				font-weight: $regularFontWeight;
				color: $grey-12;
				display: block;
				margin: 1em 0;

				.user-holder {
					display: inline-block;
					vertical-align: middle;

					div,
					img {
						border-radius: 100%;

						&:after {
							clear: both;
							content: "";
							display: block;
						}
					}

					&:after {
						clear: both;
						content: "";
						display: block;
					}
				}
			}

			audio::-webkit-media-controls-mute-button,
			audio::-webkit-media-controls-volume-slider,
			audio::-internal-media-controls-download-button {
				display: none;
			}

			audio {
				width: 100%;
				margin: 1em 0 0;
			}

			.is-medium-block {
				font-size: 14px;
				line-height: 1.5em;
				margin: 5px 0;
				display: block;

				&.contact-name {
					margin: 5px 0 0;
				}
			}
		}

		.footer {
			margin: 15px -15px -15px;
			background: #f4f4f4;
			position: relative;
			bottom: 0;
			padding: 15px;
			border-top: 1px solid $grey-7;

			label {
				color: $grey-12;
				display: block;
				font-weight: $regularFontWeight;
				text-transform: uppercase;
				letter-spacing: 0.1em;
			}

			.is-link {
				color: $bright-blue;
				line-height: 24px;
				font-size: 13px;
			}
		}
	}
}
