@import "~StyleVariables";

.CardActions {
	padding-right: 4px;
	display: flex;
	align-items: center;
	width: auto !important;

	.inline-confirm-modal {
		.SubscriptionCard__action:hover {
			background-color: inherit;
		}
	}

	.inline-confirm-tab {
		position: inherit;
	}

	.inline-confirm-container {
		top: unset;
		right: 31px;
		margin-top: -2px;
	}

	&--hideActions {
		width: 6% !important;
		.CardActions__action {
			margin-left: 2px;
		}
	}

	&__relation {
		width: 30px;
		display: flex;
		justify-content: center;
	}

	&__action {
		display: flex;
		width: 30px;
		height: 34px;
		align-self: center;
		border-radius: $border-radius;
		align-items: center;
		justify-content: center;

		.Icon {
			align-self: center;
			z-index: 0;
		}

		&:hover::before {
			padding: 16px;
		}
		&::before {
			content: "";
			padding: 0px;
			position: absolute;
			background-color: $grey-5;
			border-radius: $border-radius;
			transition: padding 0.3s cubic-bezier(0.2, 0.18, 0.29, 1.37);
		}

		&--disabled {
			cursor: default;
			&:hover::before {
				padding: 0;
			}

			.Icon {
				color: $grey-6;
			}
		}
	}

	&__dropdownMenuActions {
		.Button {
			z-index: 1;
		}
		.DropDownMenu__content {
			top: 30px;
			z-index: 2;
			border-radius: 4px;

			.Card {
				border-radius: 4px;
			}

			.Button {
				border-bottom: 1px solid $grey-4 !important;
				border-radius: 0;
				transition: background-color ease 200ms;

				&:hover {
					background-color: $grey-1;
				}
			}
		}
	}

	&__dropdownActions {
		.Button {
			height: 40px;
			width: stretch;

			text-align: left;
		}

		.Text {
			display: inline;
		}
	}

	&__dropDown {
		transition: transform 400ms ease;
		transform: rotate(0deg);

		&--rotate {
			transform: rotate(180deg);
		}
	}

	&__divider {
		min-width: 1px;
		height: 38px;
		margin: 0px 4px;
		background: $grey-6;
	}
}
