#configure-integration {
	.integration-content {
		background-color: $grey-1;

		.integration-top-section {
			display: table;
			background-color: $white;
			padding: 20px;
			clear: both;
			width: 100%;

			.integration-header-content {
				padding: 0 20px;

				.integration-headline {
					font-size: 31px !important;
					margin: 0 !important;
					font-weight: $regularFontWeight;
				}

				.publisher-text {
					font-size: 12px;
					color: $grey-10;
				}

				.price-description {
					font-size: 12px;
				}

				.integration-description {
					font-weight: $regularFontWeight;
					font-size: 16px;
					margin-bottom: 0;
				}

				#more-information {
					.info-wrap {
						max-width: 600px;
						margin-bottom: 5px;
					}

					label {
						font-weight: $boldFontWeight;
					}
				}
			}
		}

		.integration-header {
			.nav {
				border-top: 1px solid $grey-4;
				@include sub-page-navigation();
			}
		}

		#integration-logo-wrapper {
			border-right: 1px solid $grey-8;

			.integration-logo-container {
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				width: 100%;
				height: 150px;
			}
		}

		.guide-section {
			.guide-section-header {
				.integration-section-header {
					background-color: transparent;
					padding: 10px 0 0 20px;
					border-top: 1px solid $grey-8;
				}
			}

			.guide-section-row {
				padding: 20px 40px !important;
				margin-bottom: 0 !important;
			}
		}
	}

	.integration-section-header {
		display: block;
		height: 50px;
		width: 100%;
		background-color: $white;
		padding: 0 0 0 20px;
		@include border-radius(3px 3px 0 0);

		.title {
			line-height: 50px;
			display: inline-block;
		}
	}

	.integration-section-row {
		padding: 20px;
	}

	.guide-section.main-section {
		padding: 50px 0;
	}

	#toggle-description {
		padding-left: 0;
	}

	#contract-wrap {
		max-width: 600px;
		padding-bottom: 10px;
	}

	.Select {
		.Select-menu-outer {
			z-index: $modal-z;
		}
	}
}

#admin-page-edit-integration {
	.row {
		margin-bottom: 15px;
	}

	label {
		color: $grey-11;
		font-size: 12px;
	}
}

.integration-box {
	line-height: 1em;
	width: 200px;
	height: 200px;
	display: inline-block;
	margin: 5px;

	.package-box {
		width: 100% !important;
		height: 200px;
		min-height: 200px;
	}

	.package-box:not(.disabled):not(.no-click) {
		cursor: pointer;
	}
	.integration-img {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		width: 100px;
		height: 100px;
		position: absolute;
		top: 30px;
		left: 50%;
		margin-left: -50px;
	}

	.package-box-footer {
		padding-right: 60px;
	}

	.integration-active-indicator {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 50px;
		background-color: $super-light-blue;
		color: $bright-blue;
		font-size: 16px;

		.fa {
			position: absolute;
			width: 50px;
			height: 50px;
			margin-top: -25px;
			margin-left: -25px;
			top: 50%;
			left: 50%;
			display: block;
			line-height: 50px;
			text-align: center;
		}
	}
}

.ui-element-widget-loading {
	text-align: center;
	background-color: rgba($white, 0.9);
	z-index: 1000;
	color: $grey-4;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;

	.fa-spin {
		margin-top: 15px;
		.ui-element-drilldown & {
			margin-top: 5px;
		}
		.ui-element-widget-modal & {
			margin-top: 5px;
		}
	}

	.ui-element-drilldown & {
		color: $grey-8;
	}
	.ui-element-widget-modal & {
		color: $grey-8;
		background-color: transparent;
	}
}

.ui-element-edit-order-sidebar {
	margin-top: 13px;
	border-top: 1px solid $grey-4;
}
.ui-element-widget-wrapper {
	min-height: 60px;
	position: relative;
}

.ui-element-widget-modal {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	margin-right: 0px;
}

.ui-element-widget {
	position: relative;
	padding: 10px 5px;
	color: $grey-13;
	background-color: $grey-1;
	border-bottom: 1px solid $grey-4;

	.ui-element-drilldown & {
		background-color: transparent;
		border-bottom: none;
		padding: 10px 0;
	}

	.ui-element-widget-modal & {
		background-color: transparent;
		border-bottom: none;
	}

	&.clickable {
		@include transition(all ease 100ms);
		&:hover {
			background-color: rgba($black, 0.05);
		}
	}

	.integration-icon {
		height: 15px;
		font-family: "arial black";
		letter-spacing: -1px;
		font-size: 13px;
		text-transform: uppercase;
		margin-bottom: 5px;
		img {
			height: 100%;
			width: auto;
		}
	}

	iframe {
		border: 0;
		width: 100%;
		height: 60px;
	}

	.header {
		display: block;
		font-size: 12px;
		font-weight: $boldFontWeight;
	}

	.widget-text-wrapper {
		font-size: 12px;
		line-height: 15px;
		padding-top: 1px;
		padding-bottom: 1px;
		white-space: pre-line;
		overflow: hidden;
		width: 100%;
	}

	.widget-md-wrapper {
		font-size: 12px;
		line-height: 15px;
		padding-top: 1px;
		padding-bottom: 1px;
		white-space: pre-line;
		img {
			max-width: 100%;
		}
	}

	.widget-iframe-wrapper {
		font-size: 12px;
		line-height: 15px;
		padding-top: 1px;
		padding-bottom: 1px;
		white-space: pre-line;

		&.widget-iframe-wrapper-no-margin {
			margin: -10px -5px;
		}

		iframe {
			max-width: 100%;
			height: 60px;
			.ui-element-drilldown & {
				height: 300px;
			}
			.ui-element-widget-modal & {
				height: 600px;
			}
		}
	}
	.widget-md {
		&.bubbleLeft {
			background-color: $super-light-blue;
			padding: 10px;
			padding-bottom: 1px;
			margin-bottom: 5px;
			border-radius: 8px;
			width: 75%;
			display: block;
		}
		&.bubbleRight {
			background-color: $grey-4;
			padding: 10px;
			padding-bottom: 1px;
			margin-bottom: 5px;
			border-radius: 8px;
			width: 75%;
			display: block;
			margin-left: auto;
			margin-right: 0;
		}
	}

	.widget-buttons-wrapper {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	.widget-text {
		&.clickable {
			@include transition(all ease 100ms);
			&:hover {
				color: $bright-blue;
			}
		}
		&.bubbleLeft {
			background-color: $super-light-blue;
			padding: 10px;
			border-radius: 8px;
			width: 75%;
			display: block;
		}
		&.bubbleRight {
			background-color: $grey-4;
			padding: 10px;
			border-radius: 8px;
			width: 75%;
			display: block;
			margin-left: auto;
			margin-right: 0;
		}
		&.bold {
			font-weight: $boldFontWeight;
		}
	}

	.str-icon {
		margin-right: 5px;
	}

	.widget-image {
		height: 40px;
		position: relative;
		padding-top: 1px;
		padding-bottom: 1px;
		.ui-element-drilldown & {
			height: auto;
		}
		img {
			height: 100%;
		}
		.fa-spin {
			display: block;
			position: absolute;
			color: $grey-1;
			top: 20%;
			left: 50%;
		}
	}

	.image-center {
		text-align: center;
	}

	.btn {
		margin-right: 4px;
		&.multi {
			margin-right: 4px;
		}
		img {
			height: 100%;
			padding-top: 2px;
			padding-bottom: 2px;
			margin-right: 4px;
		}
		.btn-icon {
			margin-right: 4px;
		}
		.fa-spin {
			margin-left: 2px;
		}
		&.btn-left {
			text-align: left;
		}
	}
	.btn-link {
		&.btn-left {
			text-align: left;
			padding-left: 0px;
		}
	}
	.inline {
		.fa {
			margin-right: 4px;
		}
		img {
			height: 100%;
			padding-top: 2px;
			padding-bottom: 2px;
			margin-right: 4px;
		}
	}
	.widget-errorRow {
		color: $red;
	}
}

.ui-element-integration-inactive {
	&.pior {
		padding-top: 32px;
	}
	color: $grey-10;
	img {
		height: 80px;
	}
}

.clickable {
	cursor: pointer;
}
