@import "~StyleVariables";

.DocumentTemplateEditorDetails {
    visibility: hidden;
    opacity: 0;
    transition: all ease-out 300ms;
    display: flex;
    justify-content: center;
    background-color: $white;
    padding-top: 32px;

    width: 100%;
    flex-grow: 1;

    &--open {
        opacity: 1;
        visibility: visible;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -50px;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 404px;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    &__input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 2px;
        width: 404px;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    &__checkboxInput {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        width: 404px;
        flex: none;
        order: 4;
        align-self: stretch;
        flex-grow: 0;
    }

    .Input {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;
        width: 404px;
        border-radius: $border-radius;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }

    .SelectMultiInput {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 3px 50px 3px 8px;
        width: 404px;
        border-radius: $border-radius;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
    .Label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: $black;
        flex: none;
        order: 0;
        flex-grow: 0;
        align-self: stretch;
        margin-top: $space * 6;
    }
}

