@import "~StyleVariables";

.ViewTickets {
	width: 650px;
	background-color: $white;
	height: 100%;
	overflow-y:auto;

	.DrawerHeader {
		position: sticky;
		top: 0;
	}

	&__statusButton {
		margin-right: $space * 3;
	}
}
