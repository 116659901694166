.CreateMailTemplate {
	// Adding important here to overwrite the styles that comes from FeatureIntroModal.
	// Remove when Angular modal is removed.
	// Feature Flag: REACT_CREATE_MAIL_TEMPLATE
	margin-top: 0px !important;
	margin-left: auto;
	margin-right: auto;
	.Label {
		margin-top: 18px;
	}

	&__next-step {
		margin-top: 40px;
	}
}
