.ThirdScreen {
	overflow: hidden;
	height: 100vh;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.Headline {
		margin-bottom: 40px;
	}

	img {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 245px;
	}
}
