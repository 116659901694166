@import "~StyleVariables";

.EditSocialEventLaptop {
	svg#laptop {
		height: 100%;
		margin: auto;
	}
	svg#laptop.green .screen {
		fill: $medium-green;
	}

	svg#laptop.green .seat-back {
		fill: $medium-green;
	}

	svg#laptop.green .seat-front {
		fill: $bright-green;
	}

	svg#laptop.green .face {
		fill: #030004;
	}
}
