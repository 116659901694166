@import "~StyleVariables";

.Overlay {
	background-color: rgba($black, 0.5);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 6000;
}
