@import "../../../../styles/variables";

.Select2FloatingLabel {
	position: relative;
	padding-top: 14px;

	.Label {
		position: absolute;
		margin: 0;
		bottom: 10px;
		transition: all ease 200ms;
		color: $grey-8;
	}

	.Select2FloatingLabel__input {
		width: 100%;

		.select2-choices {
			background: 0 0;
			border: none;
			display: block;
			margin: 0;
			padding: 0;
			border-radius: 0;
			border-bottom: 1px solid $grey-6;
			outline: 0;
		}

		.select2-search-field input {
			padding-left: 0;
		}

		&.select2-dropdown-open {
			.select2-choice {
				border-bottom-color: $bright-blue;
			}
		}
	}
}

.Select2FloatingLabel--has-value {
	.Label {
		bottom: 31px;
		color: $grey-13;
	}
}
