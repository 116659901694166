@import "~StyleVariables";

.ReportcenterFilterRow {
	cursor: pointer;
	transition: all ease-out 200ms;
	border-top: 1px solid $grey-3;

	&:hover {
		background-color: $grey-1;
	}

	.Block {
		width: 100%;
	}

	.Column {
		align-items: center;
		display: flex;

		.Text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}

		.Checkbox {
			flex-shrink: 0;
		}
	}

	/** 
		* @todo  @upsales/components 2.19.13
		* STYLING: Had to add min-width for the checkbox to look good inside a flex container.
		* {@link https://github.com/upsales/ui-components/issues/561}
	*/
	.Checkbox {
		min-width: 20px;
	}
}
