@import "~StyleVariables";

.SubscriptionPreviewGraph {
	--greenBox-height: 135px;

	&__greenBox {
		position: absolute;
		top: 0px;
		height: var(--greenBox-height);
		width: 208px;

		background-color: $super-duper-light-green;
		border-radius: $border-radius;
		border: 1px solid $bright-green;
		transition-property: left, width;
		transition-timing-function: ease-in-out;
		transition-duration: 50ms;

		&--transition {
			transition-duration: 1500ms;
		}

		.Text {
			padding: 4px;
		}
	}

	&__curtain {
		height: var(--greenBox-height);
		width: 100px;
		background-color: $white;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
	}

	.ColumnChartWidget {
		height: 100%;
		z-index: 2;

		&__label {
			border-bottom: unset;
		}

		&__fixed-labels {
			flex: 0 0 7em;
		}
	}
	.ReportcenterWidget {
		border-radius: $border-radius-sm;
		.ReportcenterWidget__header {
			visibility: hidden;
		}
		.ReportcenterWidgetLegend {
			border-radius: $border-radius-sm;
			padding: 12px;
			min-height: unset;
			max-height: unset;
		}
	}
}
