@import "~StyleVariables";

.SubscriptionDetails {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding-top: 3 * $space;
	margin-bottom: 100px;

	&__container {
		width: calc(min(80vw, 900px));
		> .Headline {
			margin-bottom: 20px;
		}
	}

	&__default-fields {
		display: flex;
		flex-direction: row;
		gap: 24px;
		margin-bottom: 8px;
	}

	&__full-width {
		width: 49%;
		.Textarea__input {
			height: 425px;
		}
	}

	&__larger-notes {
		.Textarea__input {
			height: 498px;
		}
	}

	&__description {
		margin-bottom: 10px;
		position: relative;

		.Label {
			font-weight: bold;
		}

		.Column {
			position: absolute;
			height: 20px;
			width: 20px;
			right: 8px;
			top: 29px;
		}

		.Input__input {
			padding-right: 34px;
		}
	}

	&__row {
		.Label {
			font-weight: bold;
		}

		.Link {
			text-decoration: none;
		}

		&--extra-margin {
			margin-bottom: 10px;
		}
	}

	&__custom-fields {
		.CustomFields__content {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;

			.Block {
				width: calc(min(40vw - 12px, 436px));
			}

			.FormComponent__message {
				min-height: 0;
			}
		}
	}
}
