@import "~StyleVariables";

.MailHeader,
.MailFromContact,
.MailStatusHeader {
	height: 50px;
	width: 100%;
	position: relative;
	background-color: $grey-1;
	top: -1px;
	z-index: 1;
	box-shadow: $box-shadow-md;
	border-top: 1px solid $grey-4;

	&__from {
		display: flex;
		justify-content: space-between;
		padding: 4px 12px 4px 12px;

		&__right {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 15px;
		}

		&__left {
			display: flex;
			gap: 12px;
			align-items: center;
		}

		&__icon {
			cursor: pointer;
			animation: rotateBackAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(0deg);

			&--rotate {
				animation: rotateAnimation;
				animation-iteration-count: 1;
				animation-duration: 400ms;
				transform: rotate(180deg);
			}
		}
	}
}

.MailStatusHeader {
	&__events-container {
		display: flex;
		align-items: center;
	}

	&__icons {
		height: 32px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 3px;
		box-shadow: none;
		background-color: $grey-2;

		&--exists {
			background-color: $read-click-active;
		}

		&--read {
			border-radius: 3px 0 0 3px;
		}

		&--click {
			border-radius: 0 3px 3px 0;
		}

		&--attachments {
			background-color: $read-click-active;
			width: 50px;

			.Text {
				margin-left: 3px;
			}
		}
	}

	&__events {
		box-shadow: 0px -1px 2px rgba($black, 0.2);
		border-bottom: 1px solid $grey-4;
		background-color: $white;

		&__header {
			background-color: $grey-1;
		}

		.Row {
			align-items: center;

			.Icon {
				min-width: 20px;
			}
		}
	}
}

.MailFromUpsalesUser {
	&__fromSelf {
		display: flex;
		flex: 1;
		min-width: 0;
		.Avatar {
			flex-shrink: 0;
			z-index: 0;
			margin-right: 12px;
		}

		&__recipientsContainer {
			min-width: 0;
		}

		&__you {
			display: inline;
			text-transform: capitalize;
		}

		&__mail {
			display: inline;
			margin-left: 4px;
		}
	}
}
