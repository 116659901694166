@import "~StyleVariables";

.AssignInfo__title,
.AssignInfo__sub-title {
	font-size: 12px;
}

.AssignInfo__title {
	color: $grey-10;
}

.AssignInfo__sub-title {
	color: $black;
	font-weight: $boldFontWeight;
}
