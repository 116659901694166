@import "~StyleVariables";

.TagsDrawer {
	.Drawer {
		position: fixed;
		z-index: 10001;
	}

	.Drawer__inner {
		width: 300px;
	}

	&__content {
		overflow-x: auto;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 51px;
	}

	.DrawerHeader__title {
		margin-left: 10px;
	}

	&__copy {
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
	}

	&__copied {
		color: $green;
	}

	&__search {
		position: sticky;
		top: 0;
		background-color: #fff;
		padding: 10px;
		z-index: 1000;
		border-bottom: 1px solid $grey-4;
	}

	.TableRow:hover .TagsDrawer__copy {
		visibility: visible;
		opacity: 1;
	}
}
