.SystemMail {
	.select2-container {
		display: block;
		margin-bottom: 10px;
	}

	.select2-choices {
		line-height: initial;
	}

	.Label {
		display: flex;
		justify-content: space-between;
		height: 25px;
		line-height: 32px;
	}

	&__Button--left {
		text-align: left;
	}

	.Button {
		.Icon {
			width: 40%;
			text-align: left;
		}
	}
}
