@import "~StyleVariables";

.HideView {
	position: absolute;
	top: 0;
	left: 100%;
	background: $white;
	width: 100%;
	height: 100%;
	transition: all 0.2s;
	overflow: hidden;

	.Icon {
		cursor: pointer;
	}

	&__Title {
		padding: 15px 15px 10px;
		color: $black;
		font-weight: $boldFontWeight;
		position: sticky;
		top: 0;
		background-color: $white;
		z-index: 1;

		.Icon {
			margin-right: 10px;
		}
	}
}
