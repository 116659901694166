@import "~StyleVariables";

.GenericGoals {
	overflow: hidden !important;
	display: flex;
	flex-direction: column;
	&__header {
		position: sticky;
		top: 0px;
		z-index: 100;
		padding-left: 15px;
		background-color: $white;
		&__title {
			display: flex;
			align-items: center;
		}
		.GoalsTools {
			.Card,
			.Row,
			.Column,
			.Text {
				box-shadow: none;
			}
		}
	}
	&__loader {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
