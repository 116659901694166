@import "~StyleVariables";
.OpportunityTodoRowChild {
	padding-left: 60px;
	height: 40px;
	&__col-1 {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__col-2 {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__description--checked {
		width: fit-content;
		position: relative;
		color: $grey-10;
	}
	&__description--checked::after {
		content: " ";
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		background: $black;
		animation: strike 200ms cubic-bezier(0.8, 1.56, 0.8, 1);
		animation-fill-mode: forwards;
	}
}
