.MailBody {
	flex: 1;
	padding: 8px 8px 0px 8px;
	overflow-x: auto;

	iframe {
		width: 100%;
		height: 100%;
		border: 0px;
	}
}
