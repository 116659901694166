@import "variables";
@import "mixins";

.load-row {
	td {
		color: $black;
		text-align: center;
		font-size: 32px;
	}

	&:hover,
	&.hover {
		td {
			background-color: $white;
			cursor: default;
		}
	}
}
