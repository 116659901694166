@import "~StyleVariables";

.PassedSocialEvent {
	max-width: 700px;
	margin: 20px auto;
}

.PassedSocialEvent__info {
	display: flex;
	margin: 35px;
}
.PassedSocialEvent__info-wrapper {
	padding-top: 30px;
}

.PassedSocialEvent__info-body {
	margin: 15px 0 20px;
}

.PassedSocialEvent__info-statistic .Title {
	display: inline-block;
	margin-bottom: 4px;
	vertical-align: top;
}
.PassedSocialEvent__info-statistic-percentage {
	display: inline-block;
	margin-left: 6px;
}
