@import "../../../../../../styles/_variables.scss";
.BookingLimitInput {
	grid-column-end: -2;

	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 16px;
	height: 20px;

	&::placeholder {
		line-height: 20px;
	}

	&--selected {
		::placeholder {
			color: $black;
			font-style: normal;
		}
	}

	> .Input__input {
		height: 100%;
		padding: 0 0 0 0;
		margin-right: 12px;

		text-align: end;
		&:focus {
			text-align: start;
			border-bottom: 1px solid $black;

			&::placeholder {
				color: $grey-9;
			}
		}
	}

	input[type="number"] {
		-moz-appearance: textfield; /*For FireFox*/

		&::-webkit-inner-spin-button {
			/*For Webkits like Chrome and Safari*/
			-webkit-appearance: none;
			margin: 0;
		}
	}
}
