@import "~StyleVariables";

.Checklist {
	max-width: 1000px;
	margin-bottom: 300px;
	padding-right: 20px;
	padding-left: 20px;

	.Title {
		padding-left: 16px;
		line-height: 55px;
	}

	.Headline {
		margin-bottom: 8px;
	}

	.Help {
		width: fit-content;
		cursor: pointer;
	}

	&__description {
		.Text {
			display: inline;
		}
		.Link {
			margin-left: 4px;
			font-size: 18px; //plz add size to Link
		}
	}

	.Card {
		border-radius: 8px;
	}

	.TableRow {
		cursor: pointer;
		transition: background-color ease-in-out 200ms;

		.Icon-edit {
			transition: opacity ease-in-out 200ms;
			margin-left: 8px;
			display: inline;
			opacity: 0;
		}

		.Text {
			display: inline;
		}
	}

	.TableRow:hover {
		background-color: $grey-1;

		.Icon-edit {
			opacity: 1;
		}
	}

	&__rowStatus {
		display: flex;
		align-items: center;
		padding-right: 50px;

		.Text {
			margin-left: 8px;
			position: absolute;
		}
	}

	&__rowStages {
		.Text {
			display: inline;
		}
		.Icon {
			margin-right: 3px;
		}
	}

	&__middleRow {
		display: flex;
		justify-content: space-between;

		.Button {
			align-self: center;
			margin-right: 8px;

			.Icon {
				vertical-align: text-top;
				display: inline-block;
			}
			.Text {
				vertical-align: text-bottom;
				display: inline-block;
			}
		}
	}

	.inline-confirm-tab {
		position: absolute;
		right: 15px;
		top: 8px
	}

	&__rowHeader {
		.TableHeader__column:first-child {
			width: 40%;
		}
		.TableHeader__column:nth-child(2) {
			width: 40%;
		}
	}

	.TableColumn--md {
		font-size: 12px;
		height: 50px;
	}

	&__ownChecklistAd {
		background-color: lighten($super-light-green, 7%);
		border-radius: 8px;
		margin-top: 18px;
		padding: 12px;
		height: 150px;

		.AdTexts {
			float: left;
			width: 540px;
			margin-left: 10px;
			margin-top: 8px;

			.BoxText {
				float: left;
				background-color: $super-light-green;
				border-radius: 5px;
				font-size: 11px;
				padding: 5px;
				position: relative;
				left: 360px;
				bottom: 80px;
			}
		}

		.Tooltip {
			float: right;
			margin-top: 15px;
			margin-right: 8px;
			.Button {
				color: $super-light-green;
				width: 280px;
				.Icon {
					margin-right: 6px;
				}
			}
		}

		.Help {
			display: flex;
			margin-top: 20px;
			margin-right: 40px;
			float: right;
			.Text {
				font-size: 14px;
			}
			.Icon {
				margin-right: 5px;
				margin-top: 2px;
			}
		}
	}
}
