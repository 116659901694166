@import "~StyleVariables";

.MailTemplateEditorReviewSend {
	margin-top: 80px;
	background-color: $white;
	padding-bottom: 100px;
	position: relative;

	&__info-send {
		margin-top: $space * 10;
		> .Text {
			font-size: $h3-font-size;
		}
	}

	&__inner {
		width: 1100px;
		margin: 0 auto;
		padding: 100px 0;
		overflow: hidden;

		.Headline {
			margin-bottom: $space * 5;
		}

		.Row {
			margin: 0 -5px;

			.Column {
				margin: 0 5px;
			}
		}
	}

	&__date-column {
		display: flex;
		align-items: center;
	}

	&__schedule-settings {

		.Row {
			margin-bottom: $space * 7;
		}

		.Label {
			color: $black;
		}
	}

	&__oneline {
		display: flex;
		align-items: center;

		.Text {
			white-space: nowrap;
		}

		> * {
			margin-right: $space * 2;
		}

		> *:last-child {
			margin-right: 0;
		}
	}

	&__number {
		width: 10%;
	}

	&__status-width {
		width: auto;
	}
}
