@import "../../../styles/variables";

.UserDeactivated {
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 30vh;
		.Text {
			font-size: 38px;
		}
		.Button {
			padding: 10px;
			margin-top: 20px;
		}
		.Icon {
			font-size: 58px;
		}
	}
	&__content > * {
		padding: 20px;
	}
}
