@import "~StyleVariables";

.EditSocialEventReview {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.EditSocialEventReview__banner {
	flex: 1;
	margin-top: 20px;
	padding: 30px 150px 50px;
	transition: background-color 0.5s;
	z-index: 1;
}

.EditSocialEventReview__banner__Title {
	margin-bottom: 10px;
}

.EditSocialEventReview__banner__Text {
	margin: 0 auto 20px;
	width: 600px;
}

.EditSocialEventReview__banner-svg {
	margin: auto;
	width: 345px;
}

.EditSocialEventReview__banner__Task {
	margin-top: 20px;
}

.EditSocialEventReview__elevio {
	margin-left: 20px;
	display: inline-block;
}

.EditSocialEventReview__floating-footer {
	display: flex;
	position: fixed;
	height: 34px;
	bottom: 0;
	right: $padding;
	left: $sidebar-width;
	z-index: 0;
	opacity: 1;
	transition: opacity 0.2s ease;
}

.EditSocialEventReview__floating-footer-hide {
	opacity: 0;
}

.EditSocialEventReview__floating-footer .Button {
	margin: auto;
}

.EditSocialEventReview__floating-footer .Icon {
	margin-right: 15px;
}
