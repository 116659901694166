@import "../../../styles/variables";

.AdminBrandList {
	table-layout: fixed;

	th:nth-child(1) {
		width: 50%;
	}
	th:nth-child(2) {
		width: 50%;
	}
	th:nth-child(3) {
		width: 50px;
	}
	th:nth-child(4) {
		width: 150px;
	}
	td {
		height: 50px;
	}
	td:nth-child(2) {
		padding-top: 8px;
		padding-bottom: 7px;
	}
	.Loader {
		margin: auto;
	}

	&__excludedRoles {
		padding-left: $space * 5;
		padding-right: $space * 5;
	}
	&__info {
		margin-bottom: $space * 2;
	}
	&__bold {
		font-weight: 600;
	}
}

.AdminBrandList__logo-preview {
	height: 45px;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
	cursor: pointer;
}

.AdminBrandList__edit-row {
	background-color: $grey-2;
}

.WrappedPortedBrands#admin {
	#admin-root {
		left: 0;
	}
}