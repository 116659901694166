@import  "~StyleVariables";

.SalesboardFilterButton {
	height: 100%; 

	.DropDownButton {
		height: 100% !important; 
		border-left: 1px solid $grey-6 !important;
		
		.DropDownButton__wrapper {
			height: 100%;
		}
	}

	.datepicker-group {
		width: 100%;
	}

	&__timepicker {
		.Row {
			justify-content: space-between;
		}
	
		.TimeInput {
			width: 49%
		}
	}


	&__filterName {

	}

	&__filterSubtitle {

	}
}