@import "~StyleVariables";

.CleanMyData {
	grid-template-rows: 0.5fr auto auto;
	--modalContentWidth: 800px;

	.Card {
		height: 126px;
		box-shadow: none;
		border-radius: $border-radius;
		margin-top: $space * 10;
	}

	.ButtonBox {
		width: 100%;
        min-width: 390px;
		height: 128px;
	}

	&__imgContaner {
		position: relative;
		top: -40px;
        left: 43px;
	}
}
