@import "~StyleVariables";

.FunnelTableWidget {
	$firstColWidth: 200px;
	overflow: auto;
	height: 100%;

	.Table {
		position: relative;
		width: auto;

		.TableHeader__column {
			background-color: $white;
			position: sticky;
			top: 0;
			z-index: 2;
			line-height: 15px;
			white-space: normal;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 70px;
			overflow-wrap: normal;
			vertical-align: bottom;
			font-size: 10px;

			transform-origin: bottom left;

			.Text {
				margin-bottom: 4px;
			}

			&:first-of-type {
				font-size: 12px;
				width: 1%;
				min-width: $firstColWidth;
				left: 0;
				position: sticky;
				z-index: 3;
			}
			&:last-of-type {
				padding-right: 15px;
			}
		}
	}

	&__noResultTable.Table {
		width: 100%;
	}

	&__loading-row {
		.Skeleton:before {
			content: "";
			display: block;
			height: 12px;
			width: 50%;
		}
		&:nth-child(2n) {
			.TableColumn:first-of-type .Skeleton:before {
				width: 80%;
			}
		}
	}

	&__noResultRow {
		.TableColumn {
			width: 100%;
			background-color: $white;
			.Text {
				text-transform: none;
			}
		}
	}

	.TableRow:not(.FunnelTableWidget__noResultRow) {
		transition: background-color ease-in-out 200ms;
		.TableColumn {
			z-index: 1;
			min-width: 95px;
			&:first-of-type {
				width: 1%;
				min-width: $firstColWidth;
				background-color: $grey-1;
				padding-right: 15px;
				left: 0;
				position: sticky;
				z-index: 2;
				.Text {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-transform: capitalize;
				}
			}
			&:last-of-type {
				padding-right: 15px;
			}

			.Row {
				.Column {
					display: flex;
					align-items: center;
					.Text {
						white-space: nowrap;
					}
				}
			}
		}
		.FunnelTableWidget__column {
			transition: background-color ease-in-out 200ms;

			&--double-grouping {
				transition: background-color ease-in-out 200ms;

				&:hover {
					cursor: pointer;
					background-color: $grey-2;
				}
			}
		}
		.FunnelTableWidget__value-col {
			.Icon-check {
				font-size: 10px;
			}

			.Text {
				display: inline-block;
				white-space: nowrap;
			}
		}
	}
	&__row {
		&--single-grouping:hover {
			cursor: pointer;
			background-color: $grey-1;
		}
	}
	&__row-subtitle {
		.Text {
			display: inline-block;
		}
	}

	.Table &__footer .TableColumn {
		position: sticky;
		bottom: 0;
		background-color: $grey-1;
		font-weight: 700;
		&:nth-of-type(2) {
			background-color: $grey-1;
		}
		&:before {
			content: "";
			height: 1px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			background-color: $grey-2;
		}
	}
	&__footer {
		.FunnelTableWidget__value-col {
			.Text {
				font-weight: 700;
			}
		}
	}

	&__table-header-column-tooltip {
		margin-left: 4px;
	}

	.ConversionRateArrow {
		background-color: transparent;
		width: 52px;
		height: 24px;
		text-align: center;
		position: relative;

		right: 0;
		bottom: 0;
		z-index: 2;

		.Text {
			position: absolute;
			top: 2px;
			bottom: 0;
			left: 0;
			right: 0;
			color: $grey-10;
		}
	}
}
