@import "~StyleVariables";

.multiple-option-selectable-option {
	font-size: 14px;
	padding: $space 10px;
	cursor: pointer;

	&:hover {
		background-color: $grey-1;
	}
}

.multiple-option-subtitle {
	font-size: 12px;
	color: $grey-11;
}
