
@import "~StyleVariables";

.UserRoleSelectPopup {
    position: fixed;
    background-color: $white;
    border: 1px solid $grey-12;
    box-shadow: $box-shadow-lg;
    z-index: 2;
    border-radius: 4px;
    
    .SelectUserGroup__toolbar { // toggle is broken right now, disabled until fixed in other ticket (MM-8902)
        display: none;
    }
}
