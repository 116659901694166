@import "~StyleVariables";

.IncreaseList {
	width: 900px;
	margin: 20px;
	box-shadow: 0px 0.5px 1px 1px rgba(0, 0, 0, 0.075);
	
	&.Block {
		border-radius: $border-radius;
	}
	.ListView {
		position: initial;
		.Page {
			top: 0px !important;
			border-radius: $border-radius;
			margin-bottom: 0px;
		}
		.TableRow--hidden {
			display: none;
		}
		.TableRow--completed {
			background-color: $grey-2;
			.Text {
				color: $grey-10;
			}
			border-left: 4px solid $grey-6;
		}

		.actions-column {
			padding-right: 27px;
		}

		.row-icon-button {
			padding: 0;
		}
	}

	.ListView__footer {
		padding: 0;
	}
}
