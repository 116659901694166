@import "~StyleVariables";

.AdminEditTicketRule {
	&__header {
		position: sticky;
		border-bottom: 1px solid $grey-4;
		padding: $space * 5 $space * 7;

		.Column {
			align-self: center;
		}
	}
	&__content {
		max-width: 720px;
		margin: $space * 5 $space * 7;
		padding-bottom: $space * 35;

		.Label {
			font-weight: 600;
		}
	}

	&__active-col {
		display: flex;
		align-items: center;

		& > .Text {
			display: inline-block;
			cursor: pointer;
		}
	}

	&__page-loader {
		margin: 0 auto;
	}

	&__action-row {
		&:not(:first-of-type) {
			border-top: 1px solid $grey-4;
		}

		& > .Column {
			padding: $space * 4 $space * 3;
			align-self: center;

			&:first-of-type {
				flex: 0 0 140px;
			}
		}

		.Help {
			display: inline-block;
			margin-left: $space * 2;
		}
	}

	.CriteriaWrapper__title {
		max-width: 180px;
	}

	.inline-confirm-container {
		right: 12px;
		top: 32px;
	}

	&__new-action-row {
		margin-left: 70px;
	}

	&__add-action-menu {
		.DropDownButton {
			color: $bright-blue;
		}
		.DropDownMenu__content {
			min-width: 180px;
		}
	}

	&__add-action-row {
		cursor: pointer;
		&:hover {
			background-color: $grey-3;
		}
	}
}
