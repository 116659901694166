@import "~StyleVariables";

.EditPhonecallContact {
	height: 100%;
	padding-top: 25px;
	.Skeleton:before {
		content: "loading some data";
	}

	&__contact-name-col {
		display: flex;
		overflow: hidden;
		white-space: nowrap;

		.Link {
			overflow: hidden;
		}

		.Headline {
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&__tools {
		right: 0;
		top: 0;
		position: absolute;
	}

	&__edit-col {
		overflow: hidden;
	}
	> .Row {
		display: flex;
		align-items: center;
		height: 25px;
	}

	.RelationsSignalWidget {
		margin: 0 -8px -8px;
	}
}
