@import "~StyleVariables";

.SelectFieldModal {
	height: 100%;
	overflow-y: hidden !important;

	.main-table-disabled {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px !important;
		color: $grey-10;
	}

	.ModalContent {
		padding: 0;
		max-height: calc(100% - 52px - 56px);
		overflow-y: auto !important;
	}

	.ModalControls__content {
		text-align: right;
	}

	.ModalHeader__title {
		margin-right: 10px;
	}
}
