@import "mixins";

// All dem opacity classes 1 to 100 (up-opacity-30 = 30% opacity)
$class-thug: up-opacity !default;
@for $i from 0 through 100 {
	.#{$class-thug}-#{$i} {
		@include opacity($i / 100);
	}
	.#{$class-thug}-#{$i}-i {
		@include opacity-i($i / 100);
	}
}
