@import "variables";
@import "mixins";

// card
.deal-card {
	$border-width: 4px;
	display: block;
	// border-bottom: 1px solid $grey-8;
	background-color: $white;
	width: 100%;
	padding: 5px 5px 5px 8px;
	z-index: 1000;
	cursor: pointer;
	font-size: 14px;
	min-height: 70px;
	box-shadow: 0 1px 1px rgba($black, 0.2);
	margin-bottom: 4px;
	border-radius: 4px;

	&:hover {
		transition: background-color ease 200ms;
		background-color: $grey-1;
	}

	&.draggable {
		cursor: move;
	}

	&.dragging {
	}

	&.inactive:not(.dragging):not(.helper) {
		@include opacity(0);
	}

	&.helper {
		z-index: 1002;
		border-top: 1px solid $grey-8;
		border-right: 1px solid $grey-8;
	}

	$line-height: 18px;
	
	.deal-card-risk-chip {
		display: flex;
		margin-bottom: 10px;
	}

	.deal-card-title {
		position: relative;
		display: block;
		overflow: hidden;
		line-height: $line-height;
		height: $line-height;
		word-break: break-all;
		a,
		span {
			transition: color 200ms ease;
			font-size: 14px;
			font-weight: $boldFontWeight;
			color: $black;
		}
	}

	.deal-card-title:hover {
		a {
			text-decoration: none;
			color: $bright-blue;
			span {
				color: $bright-blue;
			}
		}
	}

	.currency {
		font-size: 10px;
		font-weight: normal;
	}
	.deal-card-field3 {
		display: inline-block;
		font-size: 12px;
		float: right;
	}

	.avatar {
		padding-top: 20px;
		margin-right: 0;
	}

	.deal-card-icon {
		border: 1px solid $grey-7;
		margin-right: 2px;
		& > span {
			margin-left: 4px;
			margin-right: 4px;
		}
		.faPadding {
			padding-left: 2px;
			padding-right: 2px;
		}
		.red {
			color: $red;
		}
		.blue {
			color: $blue;
		}
		.gray {
			color: $grey-7;
		}
		&:hover {
			background-color: $grey-8;
		}
	}

	.deal-card-field4 {
		word-break: break-all;

		&.has-avatar {
			display: inline-block;
			width: calc(100% - 25px);
			float: left;
		}
	}

	.deal-card-title,
	.deal-card-field2,
	.deal-card-field3,
	.deal-card-field4 {
		font-size: 12px;
		font-weight: $regularFontWeight;
		color: $grey-12;
		.red {
			color: $red;
		}

		.related {
			.fa {
				padding-right: 5px;
				color: $grey-8;
				font-size: 12px;

				&.active {
					color: $bright-blue;
				}
			}
		}
	}

	.deal-card-field2 {
		margin-top: 4px;
		display: inline-block;
		overflow: hidden;
		word-break: break-all;
		line-height: $line-height;
		height: $line-height;
		min-width: 50%;
	}

	.deal-card-field3 {
		margin-top: 5px;
	}

	.deal-card-field4 {
		height: 22px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.custom-tooltip + .tooltip.in {
		opacity: 1 !important;
	}

	.custom-tooltip + .tooltip.top .tooltip-arrow {
		border-top-color: $green;
	}

	.custom-tooltip--green + .tooltip.top .tooltip-arrow {
		border-top-color: $green;
	}

	.custom-tooltip + .tooltip > .tooltip-inner {
		background-color: $green;
		padding: 8px;
		.Icon-check {
			color: white;
		}
		span {
			margin-left: 8px;
		}
		> .opportunity-ai-row {
			margin-top: 3px;
		}
		.opportunity-ai-row {
			margin-left: 2px;
		}
	}

	.custom-tooltip--green + .tooltip > .tooltip-inner {
		background-color: $green;
	}

	.opportunity-ai-check {
		color: $bright-green;
	}

	.notification-icon {
		text-align: center;
		border-radius: 4px;
		font-size: 15px;
		line-height: 22px;
		vertical-align: text-bottom;

		&--green {
			color: $green;
		}

		&--red {
			color: $red;
		}

		&--grey {
			color: $grey-7;
		}
	}

	.notification-icon:nth-of-type(2) {
		margin-left: 8px;
		margin-right: 8px;
	}

	.completed-steps {
		background-color: $grey-3;
		color: $grey-11;
		margin-left: 8px;
		line-height: 22px;
		border-radius: 4px;
		padding: 4px 6px;
		vertical-align: text-bottom;

		&--green {
			background-color: $super-light-green;
			color: $green;
		}
	}

	.warning {
		color: $greyeen;
	}

	.no-warnings {
		color: $bright-green;
	}
}
// card end
