@import "~StyleVariables";

.OutcomePostponeDate {
	display: flex;
	gap: 8px;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 100%;
	padding-bottom: 5px;

	&__postpone {
		.ButtonSelect .Button {
			padding: 0 8px;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.ButtonSelect {
			max-width: 100%;
		}

		.DropDownMenu__content {
			z-index: 1000;
		}
	}

	&__postponeLaterToday {
		max-height: 200px;
		overflow-y: auto;
	}

	&__date-time-picker {
		display: flex;
	}

	&__date-picker,
	&__time-picker {
		.Input__input {
			border: 1px solid $grey-6;
			border-radius: 4px;
			box-shadow: inset 1px 1px 2px 0 rgba($black, 0.1);
			font-size: 14px;
			padding: 0 5px;
			height: 30px;
		}
	}

	&__date-picker {
		width: 100px;
	}

	&__time-picker {
		width: 70px;

		.TimeInput__list {
			top: initial;
			bottom: 35px;
		}
	}

	.Label {
		font-size: 12px;
		margin: 0;
		font-weight: $boldFontWeight;
		line-height: 1.8em;
	}

	.DateInput__popup {
		z-index: 1010;
		left: 115px;
		bottom: -70px;
	}
	&__date-picker--noComments {
		.DateInput__popup {
			bottom: -5px;
		}
	}
	.DropDownMenu__content {
		min-width: 130px;
		max-height: 180px;
		overflow: auto;

		.TableColumn {
			height: 30px;
			padding: 1px 1px 1px 15px;
			vertical-align: middle;
		}
	}
}
