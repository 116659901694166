@import "~StyleVariables";

.AgreementList {
	.Headline {
		padding: 15px 4px 15px 15px;
		display: inline-block;
	}

	&__totalValue{
		display: inline;
	}

	&__tableRow {
		background-color: $white;
		cursor: pointer;
		transition: ease background-color 200ms;

		&:hover {
			background-color: $grey-1;
		}

		&--inactive {
			background-color: $grey-2;

			> .TableColumn:nth-of-type(-n + 2) {
				font-style: italic;
			}
		}
	}

	&__userRow {
		display: flex;

		.Text {
			margin-left: 4px;
		}
	}

	.Icon-trash {
		transition: ease color 200ms;

		&:hover {
			color: $red;
		}
	}

	.Icon-file {
		transition: ease-out color 200ms;

		&:hover {
			color: $grey-10;
		}
	}

	&__tooltip-icon {
		height: 100%;
		display: inline-flex !important;
		align-items: center;
	}
}
