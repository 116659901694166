@import "~StyleVariables";

.HistoryLog {
	width: 100%;
	margin-bottom: 60px;
	.TimelineRow .TimelineCard,
	&--hasPriorHistory {
		margin-bottom: 0px;
	}

	.TimelineCard {
		border: 1px solid $grey-4;
	}

	&__row {
		display: flex;
		align-items: center;
		height: 60px;
		gap: 4px;
	}

	&__icon-wrapper {
		position: relative;
		height: 100%;
		padding: 22px 0 0 7px;
		width: 40px;
	}

	&__icon {
		width: 16px;
		height: 16px;
		border: 2px solid $grey-6;
		border-radius: 50%;
		text-align: center;
		box-shadow: none;
		background-color: $grey-2;
	}

	&__line {
		border: 1px solid $grey-6;
		width: 1px;
		top: 38px;
		bottom: -16px;
		position: absolute;
		left: 14px;
		&--top {
			top: -38px;
			bottom: 38px;
		}
	}

	&__filler {
		flex-grow: 1;
	}

	.subsection-flex-link {
		display: flex;
		align-items: center;
		padding: 0;
		text-decoration: none;
		width: 100%;
		margin-left: 12px;

		.Link {
			margin-left: 4px;
			max-width: 200px;
		}
	}

	.Text.Skeleton:before {
		content: "Lorem ipsum dolor sit amet";
	}

	&__loader {
		display: flex;
		align-items: center;
		margin-left: 40px;
		gap: 8px;
		overflow: hidden;
	}
}
