@import "~StyleVariables";

.OrderRow {
	&__orderTableRow {
		border-top: 1px solid $grey-6;
		.TableColumn {
			border: none;

			&:last-of-type {
				width: 240px;
			}
		}

		&--isNew {
			border-color: $medium-green;
		}

		.NumberInput {
			.Input__input {
				font-style: normal; // Italic numbers will get cut off
			}
		}

		&--pushFieldsUp {
			.OrderRow {
				&__quantityPrice, &__discount {
					margin-top: -30px;
				}
			}
			.select2-container, .ProductCategorySelect {
				margin-top: -31px; // This select is a little smaller
			}
		}

		.MaxDiscountInfo {
			bottom: $space * 2;
		}
	}

	&__productSelect {
		width: 400px;
		position: relative;

		.select2-container {
			width: 400px;
		}

		&--useDiscount {
			width: 270px;
			.select2-container {
				width: 270px;
			}
		}

		&--tiered, &--bundle {
			.AssistChip {
				display: block !important;
			}
		}

		.AssistChip {
			z-index: 1;
			right: 28px;
			top: 20px;
			position: absolute;
			display: none;

			&::before {
				content: "";
				position: absolute;
				left: -21px;
				background: linear-gradient(-90deg, white 20%, transparent);
				height: 20px;
				width: 20px;
			}
		}
	}

	&__quantityPrice {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-around;
		width: 310px;

		border: 1px solid $grey-6;
		border-radius: $border-radius;

		> .Text--sm {
			width: 12px;
		}

		&--disabled {
			background-color: $grey-4;
		}

		.NumberInput {
			.Input__input {
				padding: 0;
				padding-right: 6px;
				text-align: right;
				border: none;
				box-shadow: none;
				color: $black;
			}

			&:first-of-type {
				width: 52px;
			}

			&:last-of-type {
				width: 240px;
				justify-content: flex-end;
				.Input__input {
					width: 240px;
				}
			}
		}

		&--useDiscount {
			width: 225px;

			.NumberInput {
				&:first-of-type {
					width: 52px;
				}

				&:last-of-type {
					width: 160px;
					justify-content: unset;
					.Input__input {
						width: 100%;
					}
				}
			}
		}
	}

	&__discount {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 185px;

		border: 1px solid $grey-6;
		border-radius: $border-radius;

		padding-right: 4px;

		&--disabled {
			background-color: $grey-4;
		}

		&--excessiveDiscount {
			border-color: $danger-5;
		}

		.NumberInput {
			.Input__input {
				padding-right: 6px;
				text-align: right;
				border: none;
				box-shadow: none;
			}

			&:first-of-type {
				.Input__input {
					width: 52px;
					color: $grey-10;
				}
			}

			&:last-of-type {
				.Input__input {
					width: 115px;
					color: $black;
				}
			}
		}
	}

	&__new-text {
		top: -10px;
		right: 0;
		padding: 0 8px;
		position: absolute;
		background: $white;
	}

	&__sum {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 8px;

		&__row {
			display: flex;
			align-items: flex-end;
			flex-direction: column;

			&--icons {
				gap: 8px;
			}
			.Icon {
				cursor: pointer;
			}
		}
	}

	&__customFields {
		height: auto !important;

		.TableColumn {
			border: none;
			height: auto;

			.CustomFields__content {
				display: flex;
				flex-wrap: wrap;
				gap: 12px;
				margin-bottom: 12px;

				.FormComponent__label {
					font-size: 12px;
					margin-bottom: 2px;
				}

				.Block {
					width: 228px;
				}

				.FormComponent__message {
					min-height: 0;
				}
			}
		}

		.DateInput__popup, .TimeInput__listContainer {
			z-index: 10003;
		}

		&--pushFieldsUp {
			.CustomFields {
				margin-top: -30px;
			}
		}
	}
}
