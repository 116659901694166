@import "~StyleVariables";

.JourneyStepDisplay__dropdown,
.JourneyStepDisplay__button-wrapper {
	display: flex;
	width: 100%;
}

.JourneyStepDisplay .DropDownMenu__content {
	z-index: 1002;
}

.JourneyStepDisplay__button-wrapper .Column {
	margin: auto 0;
}

.JourneyStepDisplay__button {
	height: 100%;
}

.JourneyStepDisplay__button-wrapper {
	transition: all ease-out 200ms;
}

.JourneyStepDisplay__button-wrapper:hover,
.JourneyStepDisplay__button-wrapper--expanded {
	background: $grey-2;
	border-radius: $border-radius;
	padding-left: 8px;
}

.JourneyStepDisplay--isSubaccount:not(.JourneyStepDisplay--isInactive) {
	.JourneyStepDisplay__button-wrapper:hover,
	.JourneyStepDisplay__button-wrapper--expanded {
		background: #{darken($super-light-blue, 5%)};
	}
}
