@import "~StyleVariables";

.BoardMembers {
	&__expandOnRowHover {
		transition: width ease-in 200ms;
	}

	&__icon {
		display: inline-block !important;
	}

	&__addButtonColumn {
		padding: $space 3 * $space;
		text-align: right;

		&:hover {
			.BoardMembers__expandOnRowHover {
				max-width: 300px;
			}
		}

		&__addBoardBuyButton {
			width: 48px;

			span {
				display: none;
			}

			&:hover {
				width: 96px;
				span {
					display: inherit;
					margin-left: $space;
				}
			}
		}
	}
}

.ListView {
	.columnWidth {
		width: 200px;
	}
}
