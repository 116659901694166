.placeholder {
	display: block;
	width: 100%;
	background-color: transparent;

	&.grey {
		background-color: $grey-4;
	}

	&.light-grey {
		background-color: $grey-6;
	}

	.inner-wrap {
		$spacing: 20px;
		margin: 0 auto;
		max-width: 600px;
		text-align: center;
		color: $grey-10;
		padding: 50px 0;

		img,
		h2,
		p {
			margin-bottom: $spacing;
		}

		h2 {
			display: block;
			margin-bottom: $spacing;
			color: $grey-12;
		}

		.btn-wrap {
			padding: 0 10px;
			margin-bottom: 20px;

			&:after {
				content: "";
				display: table;
				clear: both;
			}

			@for $i from 2 through 4 {
				&.btn-wrap-#{$i} {
					.btn-inner-wrap {
						width: 100% / $i;
					}
				}
			}

			.btn-inner-wrap {
				display: inline-block;
				float: left;
				padding: 0 10px;
				width: 100%;
				min-height: 1px;
			}

			button {
				width: 100%;
			}
		}
	}
}
