@import "~StyleVariables";

.ListContacts {
	.FullScreenPage & {
		padding-bottom: 70px;
	}
	
	&__SelectModeConfirm {
		z-index: 1000;
		box-shadow: 0 -2px 8px rgba($black, 0.2);
		width: 450px;
		position: fixed;
		bottom: 0;
		margin: 0 auto;
		left: 0;
		right: 0;
		border-radius: 5px 5px 0 0;
		transition: 0.2s all;
		cursor: pointer;

		&:hover {
			background: darken($green, 5%);
		}

		.Icon {
			position: absolute;
			right: 10px;
			height: 14px;
			bottom: 26px;
		}

		.ListView--filters-visible + & {
			right: $up-filters-width + 15px;
		}
	}
}