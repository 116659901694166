@import "~StyleVariables";

.CriteriaRowWrapper {
	position: relative;
	padding-left: 20px;
}

.CriteriaRowWrapper--max800w {
	max-width: 800px;
}

.CriteriaRowWrapper--rowFlex .criteria-row {
	display: flex;
	align-items: center;
}

.CriteriaRowWrapper,
.CriteriaDrilldownWrapper {
	.multi-comparitors-wrap.active .up-comparison-type-toggle {
		visibility: visible;
	}

	.multi-comparitors-wrap .up-comparison-type-toggle {
		position: absolute;
		top: 0;
		line-height: 32px;
		z-index: 1000;
		width: 55px;
		padding-right: 8px;
		text-align: center;
		background-color: $green;
		color: $white;
		visibility: hidden;
		border-radius: 3px;
		background-clip: padding-box;
		cursor: pointer;
	}

	.multi-comparitors-wrap .up-comparison-type-toggle--locked {
		background-color: $grey-6;
		z-index: 1002;
	}

	.multi-comparitors-wrap .select2-container {
		position: relative;
		z-index: 1001;
	}

	.multi-comparitors-wrap.active .select2-choices {
		padding-right: 0px;
	}

	.multi-comparitors-wrap.active .select2-container-disabled .select2-choices {
		border-color: $grey-6;
	}

	.multi-comparitors-wrap.active .select2-container {
		margin-left: 50px;
		width: calc(100% - 50px);
	}

	.multi-comparitors-wrap .SelectTokens {
		margin-left: 50px;
		width: calc(100% - 50px);
		z-index: 1001;

		.SelectMultiInput {
			z-index: 1001;
			min-height: 34px;

			.Chip__hide {
				height: 10px;
				line-height: 5px;
			}
		}
	}

	.multi-comparitors-wrap .select2-container {
		position: relative;
		z-index: 1001;
	}
}

.CriteriaRowWrapper div[class^="col-"] {
	padding: 0;

	&:not(:first-child) {
		padding-left: 15px;
	}
}
