@import "~StyleVariables";

.FlowLoopSettings {
	width: 420px;
	height: 100%;

	&__content {
		height: calc(100% - 50px);
		overflow: hidden;
		overflow-y: auto;

		.Label {
			font-weight: 500;
		}
	}

	&__loop-inputs {
		align-items: flex-start;
	}

	&__radio-row {
		cursor: pointer;
	}
}
