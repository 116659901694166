@import "~StyleVariables";

.Landing {
	&-header {
		height: 470px;
		box-shadow: none;

		.PublishingOptions__Preview {
			padding-left: 10px;
		}
	}

	&__LinkAddress {
		padding: 20px 60px;

		.Text--xl {
			margin-bottom: 10px;
		}

		.LinkAddress {
			box-shadow: none;
		}

		.CardContent {
			padding: 20px 15px;
		}
	}
}

.LandingPublishingOptions {
	max-width: 1400px;
	margin: 0 auto;

	&__Header {
		&--left-col {
			min-width: 700px;
			flex: 0 0 700px;
			padding-top: 0;
			align-self: center;
			margin-left: 50px;
		}

		.Label {
			font-weight: $boldFontWeight;
		}
	}

	&__LandingPagePreview-wrapper {
		width: 30%;
		max-width: 600px;
		margin-left: auto;
		overflow: hidden;
		padding: 0 20px;
		height: 430px;
	}

	&__Visit-btn {
		padding: 0 27px;
		height: 35px;
		margin-left: 10px;
	}

	&__UntrackedLink {
		margin-bottom: 30px;

		.Column {
			align-self: flex-end;
		}

		.CopyButton {
			bottom: 0px;
		}
	}

	&__ShareButtons {
		.Text {
			margin-bottom: 10px;
		}
	}
}
