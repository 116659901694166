@import "~StyleVariables";

.GoalSummaryDrawer {
	height: 100%;
	background-color: $white;

	&__content-wrapper,
	&__loader-wrapper {
		height: calc(100% - 50px);
		min-width: 380px;
	}

	&__content-wrapper {
		overflow-y: auto;
		padding-bottom: 10px;
		text-align: left;
	}

	.Text#role-title {
		margin-top: 10px;
		margin-bottom: -5px;
		margin-left: 20px;
	}

	.DrawerHeader {
		.Title {
			font-size: 16px;
			color: $black;
		}

		.Button.up-btn.btn-white.btn-md {
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

			.Text {
				font-weight: $boldFontWeight;
			}
		}

		.Column:nth-of-type(2) {
			padding-right: 20px;
			padding-left: 10px;
		}

		.ButtonSelect {
			width: 100%;
			font-family: Roboto;

			.Tooltip {
				width: 50%;
				.Button {
					width: 100%;
				}
			}
		}
	}

	&__no-targets {
		text-align: center;
		padding-top: 50px;
		padding-left: 40px;
		padding-right: 40px;
		width: 100%;

		.Title {
			width: 240px;
			margin: auto;
			margin-top: 10px;
		}
		.Text {
			width: 300px;
			margin: auto;
			margin-top: 10px;
		}
		.Button {
			margin-top: 20px;
			border-radius: 4px;
			box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
			border: 1px solid $bright-blue;
		}
	}
}
