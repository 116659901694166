@import "~StyleVariables";

.FileBrowserLabel {
	&__labelWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 40px;
		margin-left: -15px;
		width: calc(100% + 30px);
		cursor: pointer;
		transition: all ease-out 300ms;
		&--selected {
			background-color: $grey-2;
			border-right: 4px solid $green;

			.FileBrowserLabel__title {
				font-size: 14px;
				font-weight: $boldFontWeight;
			}
			.FileBrowserLabel__num {
				padding-right: 18px;
				font-weight: $boldFontWeight;
			}
		}

		&:hover,
		&--toolsVisible {
			background-color: $grey-4;
			border-right: 4px solid $grey-6;

			.FileBrowserLabel__labelButtonsDiv {
				opacity: 1;
			}

			.FileBrowserLabel__num {
				&--numVisible {
					display: none;
				}
			}
		}
	}

	&__num {
		opacity: 1;
		padding-right: 18px;
		font-size: 12px;
		position: absolute;
		right: 0;
	}

	&__labelButtonsDiv {
		display: flex;
		opacity: 0;
		height: 50px;
		justify-content: flex-end;
		position: absolute;
		right: 0;

		.inline-confirm-modal {
			position: relative;
			opacity: 1;
			padding-left: 34px;
		}

		.inline-confirm-tab:not(.keep-position) {
			background-color: transparent;
			box-shadow: none;
			width: 34px;

			b.fa-spinner {
				margin-top: 12px;
			}
		}
	}

	&__icon {
		margin-top: 18px;
		padding-right: 20px;
		background-color: transparent;

		&:focus {
			background-color: black;
			border-right: 3px solid $grey-6;
		}
	}

	&__deleteModal {
		margin-top: 20px;
	}

	&__title {
		padding-left: 20px;
		padding-top: 6px;
		width: 175px;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&--numVisible {
			width: 200px;
		}
	}
}
