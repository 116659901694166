@import "~StyleVariables";

.ReviewContactsCard__progressbar-wrapper {
	position: relative;
	margin: 15px 0px 30px;
}

.ReviewContactsCard__progressbar-wrapper .Progressbar {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.ReviewContactsCard__details .Title {
	display: inline-block;
}

.ReviewContactsCard__details .Icon {
	font-size: 5px;
	margin-left: 5px;
	vertical-align: top;
	line-height: 14px;
}

.ReviewContactsCard__no-contacts .Title {
	margin: 10px 0;
}

.ReviewContactsCard__no-contacts .Text {
	margin-bottom: 20px;
}
