@import "~StyleVariables";

.ReviewDetailsCard > .Row > .Column {
	padding: 20px;
}

.ReviewDetailsCard > .Row .ReviewDetailsCard__line.Column {
	height: 1px;
	background: $grey-6;
	padding: 0;
}
