@import "~StyleVariables";

.RiskDrawer {
	width: 850px;

	&__drawer-header {
		margin-top: $space;
		margin-bottom: $space;
		margin-left: $space * 4;
	}

	&__drawer-header-row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__drawer-header-tooltip {
		align-self: center;
		justify-self: center;

		&--help-tooltip {
			margin-right: $space * 2;
		}
	}

	&__drawer-signals {
		align-self: center;
	}

	&__loader {
		display: block;
		margin-top: $space * 5;
		margin: auto;
	}

	&__risk-block {
		overflow-y: auto;
		height: 100vh;
	}

	&__risk-block-sticky {
		position: sticky;
		top: 0;
		z-index: 1000;
		padding-bottom: $space * 4;
		padding-top: $space * 4;
	}

	&__title-row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__risk-news-header {
		flex-direction: row;
		align-items: flex-end;
	}

	&__list-title {
		margin-bottom: $space;
		margin-left: $space * 4;
	}

	&__commentary-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: $space * 4;
		padding-bottom: $space * 2;
	}

	&__riskdrawer-row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__riskdrawer-icon {
		font-size: $space * 2;
	}

	&__row-content {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		padding-right: $space * 4;
		padding-top: $space * 4;
		padding-bottom: 10px;
		background-color: $white;

		&--sticky {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			padding-right: $space * 4;
			padding-bottom: $space * 4;
			padding-top: $space * 4;
			position: sticky;
			top: 50px;
			z-index: 1000;
			background-color: $white;
		}
	}

	&__initial-state-block {
		margin-left: $space * 4;
	}

	&__initial-state-content {
		display: flex;
	}

	&__initial-state-text {
		padding-top: $space * 4;
		padding-bottom: $space * 4;
		text-align: justify;
	}

	&__initial-state-buttons {
		align-self: flex-end;
	}

	&__button-icon {
		padding-right: $space * 2;
	}
	&__initial-state-img {
		padding-top: $space * 4;
		height: 100%;
		width: auto;
	}

	&__risk-drawer-table-empty {
		text-align: center;
	}

	.DrawerHeader__hide.Button {
		background: transparent;
	}

	.DrawerHeader.Column.Column:first-child {
		color: black;
	}

	.TableHeader__column:last-of-type {
		text-align: right;
		padding-right: $space * 4;
	}

	.TableColumn:last-of-type {
		text-align: right;
		padding-right: $space * 4;
	}

	.SignalsFeed__item {
		width: 100%;
		margin-bottom: $space * 4;
		margin-left: $space * 4;
		margin-right: $space * 4;
	}

	.SignalsFeed__content {
		background-color: white;
	}

	.SignalsFeed__lottie {
		text-align: center;
	}

	.Row {
		.ButtonSelect {
			height: 34px;
		}
	}
}
