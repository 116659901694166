@import "~StyleVariables";

.MoveSubscriptionProgress {
	$fileIconWidth: 40px;
	$companyCardTextPaddingLeft: 15px;

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	position: absolute;
	align-items: center;
	padding-bottom: 100px;
	flex-direction: column;
	justify-content: center;
	background-color: $white;

	&__cornerClose {
		z-index: 1;
		align-self: flex-end;
	}

	&__companyCards {
		display: flex;
		position: relative;
	}

	&__fromCompanyCard,
	&__toCompanyCard {
		z-index: 1;
		width: 250px;
		overflow: hidden;
		border: 1px solid transparent;
	}

	&__fromCompanyCard {
		padding-left: $fileIconWidth + $companyCardTextPaddingLeft;
		margin-right: 15px;

		&--animate {
			animation: "fromCompanyCard" 3s 1s forwards;

			.Text {
				animation: "fromCompanyCardText" 3s 1s forwards;
			}
		}
	}

	&__toCompanyCard {
		border-radius: 4px;
		padding-left: $companyCardTextPaddingLeft;
		padding-right: 45px;

		&--animate {
			animation: "toCompanyCard" 3s 1s forwards;

			.Text {
				animation: "toCompanyCardText" 3s 1s forwards;
			}
		}
	}

	&__fileIcon {
		top: 50%;
		left: 10px;
		z-index: 1000;
		position: absolute;
		width: $fileIconWidth;
		transform: translateY(-50%);

		&--animate {
			animation: "moveFileIcon" 3s 1s forwards;
		}
	}

	@keyframes moveFileIcon {
		0% {
			transform: translateY(-50%);
		}
		5% {
			transform: translateY(-40%);
		}
		20% {
			transform: translateY(-210%);
		}
		25% {
			transform: translateY(-200%);
		}
		50% {
			transform: translateY(-200%) translateX(260px);
		}
		55% {
			transform: translateY(-190%) translateX(260px);
		}
		70% {
			transform: translateY(-40%) translateX(260px);
		}
		75% {
			transform: translateY(-50%) translateX(260px);
		}
		100% {
			transform: translateY(-50%) translateX(260px);
		}
	}

	@keyframes fromCompanyCardText {
		0% {
			transform: translateX(0);
		}
		15% {
			transform: translateX(0);
		}
		30% {
			transform: translateX(-$fileIconWidth);
		}
		100% {
			transform: translateX(-$fileIconWidth);
		}
	}

	@keyframes fromCompanyCard {
		0% {
			background-color: none;
		}
		25% {
			background-color: none;
		}
		30% {
			background-color: $grey-2;
		}
		100% {
			background-color: $grey-2;
		}
	}

	@keyframes toCompanyCardText {
		0% {
			transform: translateX(0);
		}
		50% {
			transform: translateX(0);
		}
		65% {
			transform: translateX($fileIconWidth);
		}
		100% {
			transform: translateX($fileIconWidth);
		}
	}

	@keyframes toCompanyCard {
		0% {
			border-color: transparent;
		}
		55% {
			border-color: transparent;
		}
		75% {
			border-color: $bright-blue;
		}
		100% {
			border-color: $bright-blue;
		}
	}
}
