@import "~StyleVariables";

.AddParentCompany {
	$xxlPadding: $space * 10;

	position: absolute;
	top: $header-height;
	height: calc(100vh - #{$header-height});
	overflow: auto;
	width: 100%;

	&__content {
		padding: $xxlPadding;
	}

	&__footer {
		position: sticky;
		bottom: 0;
	}

	&__selectCustomRow {
		width: 100%;
	}

	&__backButton {
		cursor: pointer;

		.Title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
