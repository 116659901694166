@import "~StyleVariables";

.ProspectingManageSelection {
	background-color: $white;
	overflow: auto;

	.max600w {
		max-width: 600px;
		margin: auto;
	}

	.TodoTimePicker__content {
		position: relative;
		z-index: 1001;
	}
}

.ProspectingManageSelection__summary {
	margin-top: 80px;
	min-height: 900px;
	background-color: $green;

	& > .Column:first-child {
		padding-top: 80px;
		padding-right: 80px;
		max-width: 500px;
		margin-left: auto;
	}

	& > .Column:last-child {
		padding-top: 80px;
		padding-left: 80px;

		.Text {
			display: inline;
		}

		.Icon {
			padding-right: 10px;
		}
	}
}

.ProspectingManageSelection__drilldown-button {
	padding-left: 0;
	text-decoration: none;

	.Icon {
		margin-left: 10px;
	}
}

.ProspectingManageSelection__phonecall {
	z-index: 1000;
	background-color: white;
}

.ProspectingManageSelection__account-manager {
	z-index: 1000;

	.ProspectingManageSelection__select-me {
		height: 17;
		line-height: "17px";
		margin-bottom: 5;
		padding-right: 0;
	}

	.ProspectingManageSelection__account-manager-select {
		* {
			flex: 1;
		}
		margin-bottom: 16px;
	}

	.ProspectingManageSelection__account-manager-clarification {
		margin-bottom: 32px;
	}
}

.ProspectingManageSelection__fields {
	--height: auto;
	transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	margin-top: calc(-1 * var(--height));
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}

.ProspectingManageSelection__field-column {
	height: 56px;
}

.ProspectingManageSelection__fields--visible {
	margin-top: 0;
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	z-index: 0;
}

.ProspectingManageSelection__fields .Row {
	.select2-choices,
	.select2-choice,
	.form-control:not(.select2-container) {
		border: 1px solid $grey-6;
		box-shadow: inset 1px 1px 2px 0 rgba(30, 37, 46, 0.1);
		border-radius: 2px;
		outline: 0;
		color: $grey-13;
		font-weight: $regularFontWeight;
		box-sizing: border-box;
		transition: all ease-out 0.2s;
	}

	.form-control.select2-container {
		border: unset;
		box-shadow: unset;
		border-radius: unset;
		outline: unset;
		color: unset;
	}

	.ProspectingManageSelection__up-select {
		width: 100%;
	}

	.Column:first-child:not(:last-child) {
		padding-right: 10px;
	}

	.Column:last-child:not(:first-child) {
		padding-left: 10px;
	}

	.input-group {
		margin-top: 22px;

		.select2-choice {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		.input-group-addon {
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
		}
	}
}
