@import "~StyleVariables";
@import "../../../../styles/variables";

@mixin hide-charts {
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	.Row {
		display: none;
	}

	#goals-flag {
		display: inline-block;
		opacity: 0.7;
		font-size: 18px;
	}
}

.QuotaCharts {
	height: 46px;
	position: relative;
	z-index: 1100;
	transition: all ease-in-out 200ms;

	&.NavbarButton {
		padding: 0;
		display: block;
		align-items: initial;
	}

	#goals-flag {
		transition: all ease-in-out 200ms;
	}

	& > .Row {
		height: 100%;
	}

	&__title {
		font-weight: $boldFontWeight;
	}

	&--sm,
	&--md,
	&--lg {
		#goals-flag {
			display: none;
			opacity: 0.7;
		}
	}

	&--sm {
		width: 200px;
		@media (max-width: 875px) {
			@include hide-charts;
		}
	}

	&--md {
		width: 270px;
		@media (max-width: 1000px) {
			@include hide-charts;
		}
	}

	&--lg {
		width: 350px;
		@media (max-width: 1320px) and (min-width: 1250px), (max-width: 1025px) {
			@include hide-charts;
		}
	}

	&--no-widgets {
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;

		#goals-flag {
			opacity: 0.7;
			font-size: 18px;
		}
	}

	&:hover {
		background: rgba(255, 255, 255, 0.2);
		cursor: pointer;
		#goals-flag {
			opacity: 1;
		}
	}

	.Tooltip {
		text-align: center;
		height: 100%;
		width: 100%;
		line-height: 46px;

		#goals-flag {
			vertical-align: middle;
		}
	}
}
