@import "~StyleVariables";

.EasyBookingEditAvailability {
	position: absolute;
	top: 50px;
	left: 10px;
	min-width: 330px;
	box-shadow: 0 3px 10px 0 $grey-4;
	border-radius: 3px;
	z-index: 10;

	.Label {
		font-weight: bold;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0px 8px 12px 20px;
		padding-top: 8px;
	}
	&__inputs {
		&__row {
			margin-bottom: 8px;
			display: grid;
			grid-template-columns: 140px 140px auto;

			.TimeInput,
			.Label {
				margin-left: 20px;
			}

			.TimeInput__inner {
				position: unset;
			}
			.Tooltip {
				margin: 0 8px;
			}
			.Button {
				font-size: 1.5rem;
			}
		}
	}
	&__unavailable {
		border-style: dashed;
		border-radius: 3px;
	}

	&__select {
		margin-bottom: 12px;

		&.is-focused > .Select-control {
			border-color: $green !important;
		}

		.Select-arrow-zone {
			border: none;
		}

		&__option {
			&--is-selected {
				background-color: $grey-2 !important;
				color: $black !important;
			}
			&--is-focused {
				background-color: $super-light-green !important;
				color: $green !important;
			}
		}

		.Select-menu-outer {
			border-color: $green;
			border-top: none;
		}
	}

	&__saveChanges {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: $white;

		.Icon {
			margin-right: 5px;
		}
	}
}
