.UploadFileModal {
	.CloseButton {
		position: absolute;
		right: 5px;
		top: 3px;
	}

	#upload-btn {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.FileUploadDropzone {
		position: relative;
		background-color: inherit;
		border-width: 0;
		height: 300px;
		margin-bottom: 0;

		&__instructions {
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.FileUploadDropzoneActive {
		height: 300px;

		&__instructions {
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
