@import "~StyleVariables";

.PlanPhonecallsDrawer {
	width: 535px;
	height: 100%;
	
	&__content {
		height: calc(100% - 50px);
		overflow: hidden;
		overflow-y: auto;
		background-color: $white;
	}

	.Label {
		font-weight: $boldFontWeight;
	}

	&__title {
		margin-top: 24px;
		margin-bottom: 16px;
	}

	.ButtonSelect {
		display: flex;
	}

	&__toggle-row {
		height: 25px;
		line-height: 25px;
		margin-bottom: 7px;
		align-items: center;
	}

	&__description-row {
		align-items: flex-end;
	}

	.CheckableList {
		margin-bottom: 20px;
	}
	.TriggerDatePicker:not(.TriggerDatePicker--withToggle) {
		margin-top: 8px;
	}
	.TriggerDatePicker__trigger,
	.TriggerDatePicker__input-wrap,
	.TodoTimePicker__trigger,
	.TodoTimePicker .ButtonGroup,
	.TodoTimePicker__labelSection,
	.TextAreaButton__trigger,
	.TextAreaButton__content:not(.TextAreaButton--withToggle >) {
		width: calc(100% - 40px);
	}
	.TodoTimePicker {
		.Icon-trash {
			margin-left: 15px;
		}
	}

	.TextAreaButton {
		margin-top: 10px;
	}

	.TodoTimePicker__content {
		position: relative;
		z-index: 1001;
	}

	.DrawerHeader {
		.Button {
			align-self: center;
			margin-right: 10px;
		}
	}
	#tag-list {
		position: fixed;
		right: -280px;
		top: 0;
		height: 100%;
		width: 280px;
		height: 100%;
		#tags-list-ul {
			top: 180px;
		}

		> div,
		ul,
		h2,
		p {
			width: 280px !important;
		}
	}
}
