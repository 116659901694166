@import "~StyleVariables";

.MailContentEditorHeader {
	&__logo-preview {
		border: 2px $grey-5 solid;
		height: 60px;
		position: relative;
		cursor: pointer;

		&--dark {
			background: $grey-11;
		}

		&--bright {
			background: $grey-1;
		}

		&--selected {
			border: 2px $bright-blue solid;
			position: relative;

			&:before {
				font-family: FontAwesome;
				content: "\f00c";
				position: absolute;
				top: 5px;
				left: 5px;
				width: 15px;
				font-size: 10px;
				height: 15px;
				border-radius: 50%;
				background: $bright-blue;
				color: $white;
				line-height: 15px;
				vertical-align: middle;
				text-align: center;
			}
		}

		img {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			width: auto;
			max-height: 100%;
			height: auto;
			max-width: 100%;
			padding: 10px 10px 10px 25px;
			pointer-events: none;
		}
	}

	&__upload {
		margin-top: 11px;
		font-size: 12px;
		line-height: 1;
		width: 100%;
		text-align: center;
		display: inline-block;
		color: $link-color;

		&:hover {
			text-decoration: underline;
		}
	}
}
