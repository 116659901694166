@import "~StyleVariables";

.ClientCardContent__Contacts {
	background-color: $grey-2;

	.AccountListContacts {
		width: 100%;
	}
	.AccountListBoardOfDirectors {
		width: 100%;

		&__listEntry, &__header  {
			box-shadow: none;
		}
	}
}
