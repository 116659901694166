@import "~StyleVariables";

.RecommendedLeads {
	width: 100%;
	border: 1px solid $grey-4;

	&__table {
		&--scrollable {
			max-height: 230px;
			overflow-y: auto;
		}
	}
}
