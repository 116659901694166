#form-dashboard,
#form-editor {
	#preview-column {
		@include disable-user-select();
		padding-bottom: 15px;
		text-align: center;

		.placeholder {
			color: $grey-10;
			font-style: italic;
		}

		#form {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
			text-align: left;
			background-color: $grey-4;
			border: 1px solid $grey-8;
			width: 400px;
			margin: 10px auto;
			padding: 20px;
			@include border-radius(5px);

			h2 {
				font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
				font-weight: $boldFontWeight;
				margin: 0;
				padding: 0;
				font-size: 30px;
				margin-top: 20px;
				margin-bottom: 10px;
			}

			#sortable-wrapper {
				overflow: auto;

				.form-group {
					background-color: $grey-4;
				}
			}

			.btn.btn-primary {
				border: 0;
			}
		}
	}
}

#select-field-modal {
	.up-modal-header {
		.up-info {
			margin-top: 10px;
		}
	}
	.select-field-datatype {
		vertical-align: top;
		display: inline-block;
		width: calc(25% - 2px);
		height: 50px;
		margin: 1px;
		text-align: center;
		padding-top: 10px;
		font-size: 12px;
		background-color: $white;
		@include transform(all ease 200ms);

		&:hover {
			background-color: $grey-1;
		}

		.fa {
			color: $bright-blue;
			display: block;
			font-size: 14px;
			margin-bottom: 3px;
		}
	}
}

#edit-form-submit {
	.field-wrap {
		.col-md-6,
		.col-md-12 {
			margin-bottom: 15px;

			.checkbox-label {
				font-weight: normal;
			}
		}
	}
}

#missing-thanks-page {
	position: absolute;
	right: 10px;
	top: 8px;
}

#landing-page-editor {
	#card-main-view {
		background-color: $grey-1;
		position: absolute;
		top: 48px;
		right: 15px;
		left: 15px;
		min-height: 768px;
		margin-bottom: 20px;

		.header-box,
		.cards {
			width: 90%;
			margin: 0 auto;
			margin-top: 30px;
		}

		.cards {
			.card-label {
				margin-bottom: 10px;
			}
		}

		.header-box {
			background-color: $white;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;

			.text {
				float: left;
				width: 60%;
				margin: 25px 0px 0px 25px;

				.info {
					margin-top: 10px;
					color: $grey-10;
				}
			}
			.icon {
				float: right;
				width: calc(35% - 15px);

				.img {
					height: 160px;
					-webkit-border-radius: 0px 3px 3px 0px;
					-moz-border-radius: 0px 3px 3px 0px;
					border-radius: 0px 3px 3px 0px;
				}
			}
		}
	}
}

#form-editor {
	.page-content {
		padding-top: $padding;

		#edit-column {
			padding-bottom: 15px;

			#thank-you-page {
				input.form-control {
					border-bottom: 0;
					@include border-radius(5px 5px 0 0);
				}

				textarea.form-control {
					border-top: 1px dashed $grey-8;
					margin-top: -1px;
					@include border-radius(0 0 5px 5px);
				}
			}

			.list-header {
				border-top: none !important;
			}

			.main-table {
				@include disable-user-select();
				margin: 15px 0;
				border: 1px solid $grey-6;

				&#fields-table {
					border-top: none;
				}

				tr {
					& > td {
						label {
							font-weight: normal;
							cursor: pointer;
							margin: 0;
							display: block;
							height: 100%;
							padding-top: 5px;
						}
					}

					&:hover td {
						background-color: $white;
					}

					&.disabled td {
						background-color: $grey-1;
						border-left-color: $grey-1;
						color: $grey-8;
					}

					&:last-child td {
						border-bottom: none;
					}
				}
			}
		}

		#preview-column {
			@include disable-user-select();
			padding-bottom: 15px;
			text-align: center;

			.placeholder {
				color: $grey-10;
				font-style: italic;
			}

			h2 {
				display: block;
			}

			#reset-colors {
				margin-bottom: 10px;
			}

			#form {
				#sortable-wrapper {
					.form-group {
						background-color: $grey-4;
						cursor: move;
					}
				}
			}
		}

		#column-wrapper {
			margin: 0;
		}
	}
}
#form-html-modal {
	ul.tabs {
		@include sub-page-navigation();
		margin-top: -1px;

		& > li {
			width: 197px;
			text-align: center;
		}
	}

	.copy-btn {
		margin-top: -34px;
		z-index: 1000;
		position: relative;
	}

	.ace_editor {
		@include border-radius(3px);

		.ace_scroller {
			left: 10px !important;
			right: 10px !important;
		}
	}

	.hidden-textarea {
		position: absolute;
		opacity: 0;
		top: -9999px;
		left: -9999px;
	}

	.up-modal-content {
		top: $modal-header-height + $sub-nav-height;

		.up-panel {
			padding: 10px;

			.up-panel-content {
			}
			.input-group {
				padding-bottom: 10px;
			}

			.form-control {
				border: 1px solid rgba($grey-8, 1);
			}
		}
	}
}

#valid-domain-modal {
	.up-panel {
		.up-panel-content {
			padding: 0px 20px;
			margin-top: 50px;

			label {
				span {
					margin-left: 5px;
				}
			}

			.input-group {
				.form-control {
					display: table-cell;
					width: 55%;

					&.top-level {
						width: 18%;
					}
				}
			}
			.up-btn {
				display: table-cell;
				width: 22%;
				margin-left: 12px;
			}

			.list-title {
				margin-top: 20px;
				margin-bottom: 5px;

				&.empty-result {
					text-align: center;
					color: $grey-10;
				}
			}

			.list-container {
				margin: 0px -20px;
				max-height: 450px;
				overflow: auto;

				.list {
					tr {
						height: 46px;
						background-color: $white;
						border-bottom: 1px solid $grey-1;

						td:first-child {
							padding-left: 20px;
							font-size: 14px;
						}

						.grey-text {
							color: $grey-10;
						}

						.fa-trash {
							cursor: pointer;
							color: $grey-10;
						}
					}
				}
			}

			.list-spinner {
				width: 35px;
				margin: auto;
			}
		}
	}

	.up-modal-controls {
		.list-spinner {
			height: 100%;
			margin-top: 4px;
			svg {
				width: 20px;
				height: 20px;
			}
		}

		.up-btn {
			width: 120px;
			margin-right: 10px;
		}
	}

	.errorHolder {
		color: $dark-red;
		background-color: $super-light-red;
		border: 1px solid $super-light-red;
		margin: 10px 0;
		padding: 10px;
		border-radius: 3px;

		p {
			margin: 0;
			line-height: 1;
		}

		span:first-child {
			font-weight: $boldFontWeight;
			color: darken($dark-red, 2);
		}
	}
}
