@import "~StyleVariables";

.SubaccountDrawer {
	$xxlPadding: $space * 10;

	height: 100%;
	width: 800px;
	background-color: $white;

	// I DO NOT WANT MY FLEX ITEMS TO OVERFLOW, GOD DAMIT!
	.Flex,
	.Row {
		& > * {
			min-width: 0;
		}
	}

	.DrawerHeader {
		position: sticky;
		top: 0;
	}

	.Drawer__children {
		height: 100%;
	}

	&__List,
	&__Add,
	&__Loading {
		position: absolute;
		top: $header-height;
		overflow: auto;
		height: calc(100vh - #{$header-height});
		width: 100%;

		&__Content {
			height: 100%;
			padding: $xxlPadding;

			.Banner {
				height: auto;
				padding: $space * 6;

				&__content {
					flex-direction: column;
				}
			}
		}
	}

	&__List {
		.AvatarStack {
			// Not pixel perfect cetered without this...
			height: 20px;
		}

		.DropDownMenu__content {
			text-align: left; // It inherits text-align: right from the the tools column...
			min-width: max-content;

			inline-confirm-modal,
			.inline-confirm-modal-hidden {
				width: 100%;
			}
		}
	}

	&__Add {
		&__Content {
			overflow-y: auto;
		}

		&__BackButton {
			cursor: pointer;

			.Title {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.Column:nth-child(2),
		.Column:nth-child(3) {
			.Flex {
				height: 100%;
			}
		}

		&__Footer {
			position: sticky;
			bottom: 0;
		}

		&__selectCustomRow {
			width: 100%;
		}

		.SelectMultiInput__item {
			display: none;
		}
	}

	&__Banner {
		padding: $xxlPadding;

		&__ToggleSection {
			.Text--md {
				// The default line height is nice if you want multi lined text.
				// But if you want to center things pixel perfect, you need to set the line height to the same height as the font size...
				line-height: 14px;
			}
		}

		&__Added {
			& > .Flex {
				width: 100%;
				height: 100%;
			}

			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1000;

			.Block {
				border-radius: 50%;

				.Title {
					// The default line height is nice if you want multi lined text.
					// But if you want to center things pixel perfect, you need to set the line height to the same height as the font size...
					line-height: 24px;
				}
			}
		}

		.Help {
			display: inline;
		}
	}

	&__Label {
		.Text--md {
			// The default line height is nice if you want multi lined text.
			// But if you want to center things pixel perfect, you need to set the line height to the same height as the font size...
			line-height: 14px;
		}
	}

	&__ClientColumnTitle {
		max-width: 350px;
		
		.Tooltip {
			max-width: 220px;
		}
	}

	.DefaultNoDataRow {
		td {
			min-width: 0;
		}
	}
}
