@import "~StyleVariables";

.CompanyGroupActivityList {
	&__TableRow {
		&--overdue td:first-child:before {
			content: "";
			position: absolute;
			background-color: $red;
			width: 4px;
			top: -1px;
			bottom: -1px;
			left: 0;
		}
	}
}
