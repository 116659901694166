.SocialEventRow__row-tool .row-tool {
	height: 100%;
	width: 38px;
}

.ListSocialEventRow__waitinglist-total {
	text-align: right;
	position: absolute;
	top: 3px;
	right: 0;
}

.ListSocialEventRow__stats-wrapper {
	min-width: 135px;
}

.ListSocialEventRow__location-col {
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
}