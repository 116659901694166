#app-overview {
	margin-bottom: 100px; // This is needed so save button does not overlap with the app overview
}

#app-overview-header {
	$innerPadding: 30px;
	$tabsHeight: 50px;
	$infoWidth: 220px;
	position: relative;
	height: 250px;
	background-color: $white;
	padding: $innerPadding;
	@include box-shadow(0 1px 5px rgba($black, 0.2));

	&.has-tabs {
		height: 300px + $tabsHeight;
	}

	&.dark {
		.app-title,
		.app-info,
		.app-tabs,
		.app-info a,
		.app-tab.selected-tab,
		.app-tab:hover {
			color: $white;
		}

		.opacity {
			color: rgba($white, 0.7);
		}

		.app-tab.selected-tab {
			border-bottom-color: rgba($white, 0.7);
		}

		.up-btn.faded {
			@include opacity(0.2);
		}
		.up-btn.faded:hover {
			@include opacity(1);
		}
	}

	&.white {
		.app-info a {
			color: $grey-13;
		}

		.opacity {
			color: $grey-10;
		}
	}

	.opacity {
		color: rgba($black, 0.7);
	}

	.app-logo {
		height: 80px;
		width: 80px;
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.app-title {
		position: absolute;
		top: $innerPadding + 10px;
		left: 110px + 10px;
		right: $infoWidth + 10px;
		color: $black;

		.title {
			font-size: 32px;
			line-height: 40px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.requirements {
			font-size: 12px;
			line-height: 20px;
		}
	}

	.app-description {
		font-size: 18px;
		line-height: 24px;
		margin-top: 15px;
		margin-right: $infoWidth + 10px;
		max-height: 95px;
		overflow: hidden;
		white-space: pre-line;
	}

	.app-info {
		position: absolute;
		top: $innerPadding;
		bottom: $innerPadding + $tabsHeight;
		right: 0;
		width: $infoWidth;
		border-left: 2px solid rgba($white, 0.2);
		color: $black;
		padding: 0 $innerPadding;

		.price {
			font-size: 32px;
			line-height: 40px;
			height: 40px;
			font-weight: $regularFontWeight;

			.currency {
				font-size: 12px;
				line-height: 20px;
				height: 20px;
				vertical-align: top;
				margin: 2px 5px;
				display: inline-block;
			}
		}
		.price-info {
			font-size: 12px;
			line-height: 20px;
			height: 20px;
		}

		.up-btn {
			margin-top: 5px;
		}

		.app-info-header,
		a {
			display: block;
			font-size: 12px;
			line-height: 24px;
		}

		a {
			color: $black;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			cursor: pointer;
		}

		.app-info-header {
			margin-top: 5px;
			text-transform: uppercase;
		}
	}

	.app-tabs {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: $tabsHeight;
		padding-right: 20px;
	}

	.app-tab {
		display: inline-block;
		border-bottom: 3px solid transparent;
		height: 100%;
		min-width: 150px;
		padding: 0 10px;
		font-size: 14px;
		line-height: $tabsHeight;
		text-align: center;
		cursor: pointer;
		@include transition(all ease 200ms);

		&:hover {
			color: $green;
		}

		&.selected-tab {
			color: $green;
			border-bottom-color: $green;
		}

		&.right {
			float: right;
		}
	}
}

.user-config-info-wrapper {
	padding: 20px;
	text-align: center;
}

.app-status-info-wrapper {
	padding: 30px 20px 0px 20px;
	text-align: center;
	.fa-spin {
		color: $grey-8;
	}
}

.showLogLink {
	padding: 30px 20px 20px 20px;
	text-align: right;
	a {
		cursor: pointer;
	}
}

.app-dev-info-wrapper {
	padding: 30px 20px 0px 20px;
	text-align: center;
	&:last-child {
		padding: 30px 20px 20px 20px;
	}
}

.app-dev-msg {
	padding: 20px 40px 20px 20px;
	margin: 0 auto;
	text-align: left;
	color: $grey-10;
	font-size: 16px;
	font-weight: $boldFontWeight;
	line-height: 22px;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	background-color: $white;
	border-radius: 3px;
	max-width: 700px;
	position: relative;

	.text {
		width: 100%;
		padding: 10px;
		.info {
			margin-top: 10px;
		}
		.button {
			cursor: pointer;
			margin-top: 10px;
		}
	}
}

.app-dev-msg-table {
	padding: 0;

	.text {
		padding: 0;
	}

	h2 {
		padding-left: 30px;
		padding-top: 20px;
	}

	td:has(pre) {
		pre {
			margin: 0;
			max-height: 300px;
			overflow: auto;
		}

	}

	#log-table {
		table-layout: fixed;
		overflow-x: auto;
	}
}

.app-status-info {
	padding: 20px 40px 20px 20px;
	margin: 0 auto;
	text-align: left;
	color: $grey-10;
	font-size: 16px;
	font-weight: $boldFontWeight;
	line-height: 22px;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	background-color: $white;
	border-radius: 3px;
	max-width: 700px;
	position: relative;
	.text {
		width: 66%;
		padding: 10px;
		.info {
			margin-top: 10px;
		}
		.button {
			cursor: pointer;
			margin-top: 10px;
		}
	}

	.status-circle {
		font-size: 12px;
		padding-right: 2px;
	}
	.illustration {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 33%;
		background-repeat: no-repeat;
		background-position: center left;
		background-size: cover;
		text-align: center;

		.img {
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: 60%;
			margin-left: -30%;
			width: 60%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			background-image: url(../img/app-delivery.svg);
			&.bad {
				background-image: url(../img/app-delivery-red.svg);
			}
			&.good {
				background-image: url(../img/app-delivery-green.svg);
			}
			&.unknown {
				background-image: url(../img/app-delivery-unknown.svg);
			}
		}

		&.bad {
			color: $red;
			background-image: url(../img/admin-topsection-bg-red.svg);
		}
		&.good {
			color: $bright-green;
			background-image: url(../img/admin-topsection-bg-green.svg);
		}
		&.unknown {
			color: $grey-10;
			background-image: url(../img/admin-topsection-bg.svg);
		}
		.fa {
			font-size: 40px;
			margin-top: 30px;
			margin-left: 40px;
		}
	}
}

.status-circle {
	font-size: 10px;
	padding-right: 3px;
	&.bad {
		color: $red;
	}
	&.good {
		color: $bright-green;
	}
	&.unknown {
		color: $grey-10;
	}
}

.user-config-info {
	padding: 20px 40px 20px 20px;
	margin: 0 auto;
	text-align: left;
	color: $grey-10;
	font-size: 16px;
	font-weight: $boldFontWeight;
	line-height: 22px;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	background-color: $white;
	border-radius: 3px;
	max-width: 700px;
	position: relative;
	.text {
		width: 66%;
		padding: 10px;
		.info {
			margin-top: 10px;
		}
		.button {
			cursor: pointer;
			margin-top: 10px;
		}
	}
	.illustration {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 33%;
		background-image: url(../img/admin-topsection-bg.svg);
		background-repeat: no-repeat;
		background-position: center left;
		background-size: cover;
		text-align: center;
		.img {
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: 60%;
			margin-left: -30%;
			width: 60%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			background-image: url(../img/app-delivery.svg);
		}
	}
}

.app-md-wrapper {
	padding: 20px;
	text-align: center;
}

.app-markdown {
	padding: 40px;
	margin: 0 auto;
	text-align: left;
	color: $grey-10;
	font-size: 16px;
	font-weight: $regularFontWeight;
	line-height: 22px;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	background-color: $white;
	border-radius: 3px;
	max-width: 700px;

	h1,
	h2,
	h3 {
		color: $grey-13;
		font-weight: $boldFontWeight;
		margin: 0;
	}

	h1 {
		font-size: 26px;
		line-height: 36px;
		margin-bottom: 24px;
	}

	h2 {
		font-size: 22px;
		line-height: 32px;
		margin-bottom: 20px;
	}

	h3 {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 16px;
	}
}

.app-fields-info {
	text-align: center;
	font-size: 20px;
	color: $grey-10;
	height: 40px;
	line-height: 40px;
	margin: 20px 0;
}

.app-fields {
	padding: 30px 20px 0px 20px;
}

@keyframes bg-spin {
	to {
	  --border-angle: 1turn;
	}
  }
  
  // Define property
  @property --border-angle {
	syntax: '<angle>';
	inherits: true;
	initial-value: 0turn;
  }
  
  // Updated mixin with proper z-indexing and animation
  @mixin animated-border($color: #C992F3, $duration: 4s) {
	--border-size: 2px;
  
	position: relative;
	background: white;
	border-radius: 4px;
	isolation: isolate;
  
	&::before {
	  content: "";
	  position: absolute;
	  inset: 0;
	  border-radius: inherit;
	  padding: var(--border-size);
	  background: conic-gradient(
		from var(--border-angle),
		transparent 20%,
		$color 40%,
		$color 60%,
		transparent 80%);
	  -webkit-mask: 
		linear-gradient(white 0 0) content-box, 
		linear-gradient(white 0 0);
	  mask: 
		linear-gradient(white 0 0) content-box, 
		linear-gradient(white 0 0);
	  mask-composite: exclude;
	  animation: bg-spin $duration linear infinite;
	}
  
	& > * {
	  position: relative;
	  z-index: 1;
	}
  }
  
.app-field-section {
	background-color: $white;
	@include box-shadow(0 1px 2px rgba($black, 0.2));
	max-width: 700px;
	margin: 0 auto 20px auto;
	padding: 20px 20px 10px 20px;
	@include border-radius(3px);

	&:has(.field-aiEmailAnalysis) {
		@include animated-border(#c992f3);
	}

	&:has(.field-title) {
		background: none;
		box-shadow: none;
		border-radius: 0;
		padding: 0;
		margin-top: 28px;
		margin-bottom: -10px;
	}

	&:has(.IntegrationArrayField) {
		padding: 0;  // Remove padding when containing IntegrationArrayField
	}
}

.ai-border-animation {
	@include animated-border(#c992f3);
}

.app-field-section-title {
	font-size: 20px;
	line-height: 20px;
	color: $grey-13;
	margin-bottom: 10px;
	font-weight: normal;
}

.app-field-section-title--lg {
	font-size: 28px;
	line-height: 28px;
}

.app-field-section-info {
	margin-bottom: 10px;
	color: $grey-11;
}

.app-field-label {
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

.app-field-row {
	margin-bottom: 15px;
	display: flex;
}

.app-field-info {
	border-left: 5px solid $grey-4;
	padding: 10px 20px;
	color: $grey-10;
	line-height: 20px;
	font-size: 14px;
}

.field-checkbox,
.field-checkboxLarge {
	line-height: 26px;
	display: flex;
	align-items: center;

	.Toggle {
		min-width: 1.7em;
	}

	.app-field-label {
		margin-left: 5px;
	}

	.app-field-section-title {
		margin-left: 10px;
		margin-bottom: 0;
		vertical-align: middle;
		line-height: 26px;
	}

	.up-ios-toggle {
		display: inline-block;
		vertical-align: middle;
	}
}

.field-oauth {
	.oauth-connected {
		line-height: 26px;
		line-height: 34px;

		.inner-text {
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			margin-left: 10px;
		}
	}
	.fa-check-circle {
		font-size: 26px;
		line-height: 34px;
	}
}

.app-field-hero {
	position: relative;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	max-width: 700px;
	margin: 20px auto 20px auto;
	padding: 50px 80px;
	border-radius: 3px;
	color: rgba($white, 0.8);
	font-size: 20px;
	font-weight: $regularFontWeight;
	line-height: 24px;
	overflow: hidden;
	color: $grey-11;

	& > * {
		position: relative;
		z-index: 1001;
	}

	h2 {
		color: $grey-11;
		font-size: 36px;
		font-weight: $boldFontWeight;
		line-height: 43px;
		margin-bottom: 15px;
	}

	.hero-slice {
		content: "";
		position: absolute;
		top: -40px;
		right: -40px;
		bottom: -60px;
		background-color: darken($green, 0.4);
		width: 220px;
		z-index: 1000;
		transform: rotate(20deg);
	}
}

.ai-field-bullet-points-list {
    display: block;
    white-space: pre-wrap;
    margin: 0;
    padding: 0;

    &:first-of-type {
        margin-bottom: 16px; // Space after the description text
    }
}
