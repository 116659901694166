@import "~StyleVariables";

.FunnelColumnChartWidget {
	overflow: hidden;
	overflow-x: auto;
	display: flex;
	position: relative;

	&__labels {
		position: absolute;
		bottom: 0;
		background-color: $grey-1;
		width: 100%;
		border-top: 2px solid $grey-6;
		min-height: 56px;
		max-height: 56px;
		.Column {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__bars {
		width: 75%;
		height: calc(100% - 57px);
		position: absolute;
		bottom: 56px;

		.FunnelColumnChartWidget__bar-wrapper {
			position: relative;
			width: 25%;

			.FunnelColumnChartWidget__bar {
				height: 25%;
				background-color: $grey-3;
				position: absolute;
				bottom: 0;
				left: 10%;
				right: 10%;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				text-align: center;
				transition: ease-in-out all 200ms;

				.Text {
					margin-top: 20px;
				}
				&:hover {
					cursor: pointer;
					background-color: $grey-6;
				}
			}
		}
		.Row {
			position: relative;
			height: 100%;
		}

		.FunnelColumnChartWidget__arrow {
			z-index: 2;
			display: inline-block;
			left: 75%;
			top: 75%;
			position: relative;
			.Text {
				position: absolute;
				top: 10px;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
	}
	&__totals {
		width: 25%;
		height: calc(100% - 56px);
		position: absolute;
		bottom: 56px;
		right: 0;

		.Row {
			padding: 10px;
			display: flex;
			align-items: left;
			justify-content: left;

			.Tooltip {
				margin-left: 5px;
			}
		}
	}
}
