#file-browser {
	.package-box.type-img {
		background-image: url("../img/transparent.png");
		background-repeat: repeat;
		background-color: transparent;
	}

	.image-delete {
		margin-top: -4px;
		font-size: 14px;
	}
}

.up-modal.default {
	.filebrowser-modal {
		.up-modal-header {
			background-color: $white;
			box-shadow: 0 1px 1px rgba($black, 0.1);
		}

		.up-modal-controls {
			.Paginator {
				margin-top: 8px;
			}
			.Paginator__Button {
				margin: 0;
			}
		}
	}
}

.filebrowser-modal {
	/* Fade in loaded elements */
	.lazy-load {
		opacity: 1;
		-webkit-transition: all 2s ease-in-out;
		-moz-transition: all 2s ease-in-out;
		transition: all 2s ease-in-out;

		.upsales-loader {
			width: 100%;
			height: 60%;
			margin-top: 20px;
			position: relative;
			z-index: 1;
		}
	}

	.lazy-load-visible {
		opacity: 1;
	}

	.up-modal-content.pior {
		padding: 15px 0px;
	}

	svg.file-drop-icon .shadow {
		animation: animate-width 2s ease-out infinite;
	}

	svg.file-drop-icon .card {
		animation: animate-bounce 2s ease-out infinite;
	}

	@keyframes animate-width {
		0% {
			rx: 40;
		}
		50% {
			rx: 50;
		}
		100% {
			rx: 40;
		}
	}

	@keyframes animate-bounce {
		0% {
			transform: translateY(0%);
		}
		50% {
			transform: translateY(10%);
		}
		100% {
			transform: translateY(0%);
		}
	}

	.up-paginator-wrap {
		margin: 5px auto 15px;
		text-align: center;

		ul {
			margin: 0 auto;
		}
	}

	.modal-title {
		font-size: 16px;
		color: $blue;
	}

	.file-count {
		color: $grey-10;
		font-weight: $regularFontWeight;
		font-size: 12px;
		margin-left: 10px;
	}

	.search-holder {
		width: 300px;
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -150px;
		height: 30px;
		margin-top: 8px;
		background: $grey-4;
		border: none;

		i.fa {
			color: $grey-8;
			top: 6px;
			position: absolute;
			left: 6px;
			z-index: 500;
			font-size: 16px;
		}

		#upload-files-search {
			font-size: 14px;
			width: 100%;
			height: 30px;
			background-color: $grey-4;
			border: none;
			top: 0;
			position: absolute;
			padding-left: 27px;
		}
	}

	.filebrowser-padding-wrapper {
		padding-left: 30px;
		padding-right: 30px;
	}

	.files {
		&:after {
			clear: both;
			content: "";
			display: block;
		}

		&.files-wrapper {
			min-height: 508px;
		}

		.file {
			margin: 0 5px 10px;
			width: calc(25% - 10px);
			height: 215px;
			float: left;
			position: relative;
			box-shadow: 0 1px 3px rgba($black, 0.05);
			border-radius: 2px;
			transition: all 0.4s;
			background-image: linear-gradient(rgba($black, 0.1), rgba($black, 0.1)), url(../img/transparent.png);

			.preview {
				width: 100%;
				height: 100%;
				text-align: center;

				.fa {
					font-size: 40px;
					line-height: calc(215px - 40px);
					vertical-align: middle;
				}
			}

			&.loading {
				background: $white;
				vertical-align: middle;
				display: table;

				.upsales-loader-holder {
					margin: 0 auto;
					display: table-cell;
					width: 100%;
					vertical-align: middle;
					text-align: center;

					.upsales-loader {
						margin: 0 auto;
					}

					h3 {
						font-size: 16px;
						font-weight: $regularFontWeight;
						color: $grey-11;
						margin: 5px 0;
					}
				}
			}

			.file-bottom-info {
				display: block;
				bottom: 0;
				left: 0;
				right: 0;
				background: $white;
				padding: 7px 10px;
				font-size: 12px;
				position: absolute;
				height: 40px;
				line-height: 20px;
				border-radius: 0 0 2px 2px;
				transition: all 0.4s;
				overflow: hidden;
				z-index: 12;

				.generic-avatar {
					float: right;
					margin: 0;
					margin-top: 1px !important;
				}

				span {
					display: inline-block;
					line-height: 28px;
					max-width: 150px;
					text-overflow: ellipsis;
					overflow: hidden;
					height: 28px;
				}

				.extra-info {
					transition: all 0.3s;
					opacity: 0;
					visibility: hidden;
					position: absolute;
					bottom: -110px;
					width: calc(100% - 20px);

					span {
						display: block;
						width: 50%;
						float: left;
					}
				}
			}

			&:hover {
				box-shadow: 0 0 5px rgba($blue, 1);

				.file-bottom-info {
					.extra-info {
						opacity: 1;
						visibility: visible;
						bottom: 3px;
					}
				}
			}
		}

		.files-options {
			display: flex;
			margin: 10px 5px;
			font-weight: $regularFontWeight;
			font-size: 14px;
			color: $grey-11;

			.headline {
				flex: 1;
			}

			.sort-options {
				display: flex;
				margin-top: auto;

				.ButtonGroup .Icon {
					margin-right: 0px;
				}
			}

			.select2-container {
				margin-left: 10px;
			}

			.fa {
				cursor: pointer;
				margin: auto 20px auto 4px;
				color: $bright-blue;
			}
		}

		&.added-files .Headline {
			margin-bottom: 10px;
		}

		.TableRow {
			background: $white;

			.TableColumn.filename-column {
				width: 400px;
			}
			.TableColumn.align-middle {
				line-height: 28px;
			}
			.ellipsis {
				width: 90%;
				top: 12px;
			}
		}
	}

	.up-panel,
	.up-panel-content {
		height: 100%;
	}

	.drag {
		transition: all 0.4s ease-in-out;
		position: absolute;
		z-index: 5000;
		opacity: 0;
		visibility: hidden;
		height: auto;
		width: auto;

		.valign-middle {
			width: 100%;
			text-align: center;
			display: block;
			top: 50%;
			position: relative;
			margin-top: -100px;

			h3,
			p {
				line-height: 1;
				margin: 10px 0;
			}

			h3 {
				font-size: 18px;
				font-weight: $regularFontWeight;
				color: $bright-blue;
			}

			p {
				font-size: 14px;
				color: $grey-10;
			}
		}
	}

	.drag.dragging {
		position: absolute;
		z-index: 5000;
		visibility: visible;

		top: -19px;
		bottom: -14px;
		right: -14px;
		left: -14px;

		height: auto;
		width: auto;

		background: rgba($white, 0.97);
		display: block;
		border: none;
		margin: 0;
		border-radius: 0;

		transition: all 0.4s ease-in-out;
		opacity: 1;
	}

	#dropzone {
		background: $grey-6;
		border: 2px dashed $grey-8;
		height: 120px;
		display: table;
		border-radius: 5px;
		width: 100%;
		text-align: center;
		transition: all 0.4s;
		position: relative;

		.valign-middle {
			display: table-cell;
			vertical-align: middle;
			width: 100%;

			h3,
			p {
				line-height: 1;
				margin: 10px 0;
			}

			h3 {
				font-size: 18px;
				font-weight: $regularFontWeight;
				color: $grey-10;
			}

			p {
				font-size: 14px;
				color: $grey-10;
			}

			input[type="file"] {
				visibility: hidden;
				position: absolute;
			}

			.upload-linktext {
				color: $bright-blue;
				font-weight: $regularFontWeight;

				label {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 9000;
					width: 100%;
					height: 100%;
					cursor: pointer;
				}
			}
		}
	}

	.dragging + #dropzone {
		z-index: 4000;
	}
}

.data-on-hover {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	transition: all 0.4s;
	z-index: 5;
}

.file:hover > .data-on-hover {
	opacity: 1;
	visibility: visible;
	background-color: rgba($blue, 0.5);
}

.filebrowser-modal .files .file:hover > .file-bottom-info {
	height: 60px;
}

.data-on-hover > i.fa {
	position: absolute;
	top: 10px;
	right: 10px;
	color: $white;
	font-size: 16px;
	cursor: pointer;
}

.data-on-hover button {
	width: 120px;
	margin: 25% auto;
	display: block;
}

.data-on-hover button > i.fa {
	margin-right: 5px;
}
