#sidebar[open] + #main-view .fixed-header-container .header-background {
	left: 215px;
}
$date-cell-width: 128px;
$table-cell-padding-left-right: 10px;
$header-top: $main-top + $header-height;
$th-height: 31px;

@mixin th-style() {
	font-weight: $boldFontWeight;
	text-transform: uppercase;
	padding: 5px $table-cell-padding-left-right !important;
	border-top: 1px solid $grey-6 !important;
	border-bottom: 1px solid $grey-6 !important;
	background-color: $grey-3 !important;
	border-left: 1px solid $grey-3 !important;
	font-size: 12px;
	height: $th-height !important;

	&.column-tools {
		width: 130px;

		.th-inner {
			right: 15px;
			width: 130px;
		}

		.dropdown-menu.typeahead {
			right: 0 !important;
			left: initial !important;
		}

		.edit-cog {
			position: absolute;
			right: 7px;
			top: 8px;
			width: 20px;
			color: $grey-10;
			font-size: 14px;
			vertical-align: baseline;
			cursor: pointer;

			&:hover {
				color: $grey-13;
			}

			&.active {
				color: $bright-blue;
			}
		}
	}

	&.column-tools-sm {
		width: 60px;

		.th-inner {
			width: 60px;
		}
	}

	.sortable-header {
		cursor: pointer;
	}

	&.sortable {
		padding-right: 20px;

		&.dragging {
			border-color: $grey-4 $grey-8;
		}

		.remove-header {
			position: absolute;
			right: -15px;
			top: 3px;
			cursor: pointer !important;
			color: $red;

			&:hover {
				color: $grey-13;
			}
		}
	}

	.up,
	.down {
		display: none;
	}
}

$date-cell-width: 128px;
$table-cell-padding-left-right: 10px;
$header-top: $main-top + $header-height;
$th-height: 31px;

@mixin th-style($notFixed) {
	font-weight: normal;
	color: #5c5c5c;
	text-transform: uppercase;
	@if $notFixed {
		padding: 5px $table-cell-padding-left-right;
		border-top: 1px solid $grey-6;
		border-bottom: 1px solid $grey-6;
		background-color: $grey-3;
		border-left: 1px solid $grey-3;
		height: $th-height;
	}

	font-size: 12px;

	// column types
	&.checkbox-column {
		width: 40px;

		.checkbox {
			display: inline-block;
			position: relative;
			left: 1px;
			margin: 0;
			padding: 0;
		}

		.TableHeaderDropdown {
			&__trigger-button {
				padding: 0 0 0 8px;
				
				.Icon:first-of-type {
					font-size: 14px;
				}
			}
			
			&__trigger-button,
			.DropDownMenu__content {
				transform: translateX(-10px);
			}
		}

		#check-btn {
			padding: 0 5px;
			margin-left: -4px;
			background-color: transparent !important;
			background-image: none !important;
			border: none;
			border-radius: 2px;
			@include transition(all ease-out 200ms);

			&:hover {
				border-color: $grey-8;
				background-color: $white !important;
			}

			.fa.fa-check-square,
			.fa-minus-square-o {
				color: $bright-blue;
			}
		}

		.dropdown {
			text-transform: none;

			#check-btn:focus {
				background-color: $bright-blue !important;
				border-color: $bright-blue !important;
				box-shadow: none;
				color: $white;
			}

			&.open {
				#check-btn {
					background-color: $bright-blue !important;
					border-color: $bright-blue !important;
					box-shadow: none;

					.fa.fa-check-square,
					.fa-minus-square-o {
						color: $white;
					}
				}
			}

			.dropdown-menu {
				a.active {
					background-color: $medium-blue;
					color: $white;
				}
			}
		}
	}

	&.column-tools {
		width: 130px;

		&.responsive {
			@media (max-width: 1140px) {
				& {
					width: 30px;
				}
			}
		}

		.th-inner {
			width: 130px;
			right: 15px;
			width: 35px;
		}

		.dropdown-menu.typeahead {
			right: 0 !important;
			left: initial !important;
		}

		.edit-cog {
			position: absolute;
			right: 0;
			top: 0;
			width: 35px;
			line-height: $th-height;
			height: 100%;
			color: $grey-10;
			font-size: 14px;
			vertical-align: baseline;
			cursor: pointer;
			text-align: center;
			background-color: $grey-6;

			&:hover {
				color: $grey-13;
				background-color: $grey-8;
			}

			&.active {
				color: $bright-blue;
			}
		}
	}

	&.column-tools-sm {
		width: 60px;
	}

	.sortable-header {
		cursor: pointer;
	}

	&.sortable {
		padding-right: 20px;

		&.dragging {
			border-color: $grey-4 $grey-8;
		}

		.remove-header {
			position: absolute;
			right: -15px;
			top: 3px;
			cursor: pointer !important;
			color: $red;

			&:hover {
				color: $grey-13;
			}
		}
	}

	.up,
	.down {
		display: none;
	}
}

@mixin table-components() {
	/* FIXED HEADER */
	.fixed-header-container {
		position: relative;

		.header-background {
			position: fixed;
			top: $header-top;
			right: $padding;
			left: 65px;
			height: $th-height;
			// border-top: 1px solid $grey-4;
			// border-bottom: 1px solid $grey-4;
			// background-color: $grey-2;
			border-top: none;
			border-bottom: 1px solid $grey-6;
			background-color: $grey-3;
			z-index: 1000;
		}
		.embedded {
			top: 0;
			right: 0;
			left: 0;
			position: relative;
			height: $th-height;
			border-top: 1px solid $grey-6;
			border-bottom: 1px solid $grey-6;
			background-color: $grey-3;
			z-index: 1000;
		}
	}

	/* MAIN TABLE */
	table.main-table {
		margin: 0;
		width: 100%;
		padding: 0;
		color: $grey-13;
		background-color: $white;
		//		overflow-x: hidden;
		//		overflow-y: auto;
		border: none;
		border-collapse: separate;

		thead {
			th {
				@include th-style(true);
			}

			&.white-header {
				th {
					background-color: $white;
					border-color: $white;
				}
			}
		}

		&.boxed {
			margin: 15px;
			border-left: 1px solid $grey-6;
			border-right: 1px solid $grey-6;
		}

		.column-tools {
			position: relative;
		}

		&.fixed-header {
			margin-top: 82px;
			// overflow-x: hidden;
			//   		overflow-y: auto;
			&.editmode {
				th .th-inner {
					border-left: 1px dashed $black;

					.sorting-indicator {
						display: none;
					}

					&.sorted {
						color: $grey-13;
					}
				}

				.column-tools {
					width: 300px;

					.th-inner {
						width: 300px;
					}
				}

				.checkbox-column {
					display: none;
				}

				th:first-child .th-inner {
					border-left: 0;
				}

				tr {
					display: none;

					&.edit-mode-column {
						display: table-row;
					}
				}
			}

			thead th {
				@include th-style(false);
				border: 0;
				height: 0;
				margin: 0 11px;
				padding: 0;

				.th-inner {
					position: fixed;
					top: $header-top;
					line-height: $th-height;
					padding-left: $table-cell-padding-left-right;
					height: $th-height;
					z-index: 1001;

					.remove-header {
						top: 9px;
					}
				}

				&.column-date {
					width: 120px;
				}
			}
		}

		& > tbody > tr {
			transition: all 200ms ease-out;
			height: 40px;

			&.extended-height {
				height: 48px;

				td {
					height: 48px !important;
				}
			}

			&.form-submit-table-row {
				td {
					padding: 2px 0 2px $table-cell-padding-left-right;
				}
			}

			&.edit-mode-column {
				display: none;
				text-align: center;

				td {
					padding: 50px 0;
					font-size: 16px;
				}
			}
			&.won {
				& > td:first-of-type {
					border-left: 2px solid $bright-green;
				}
			}
			&.lost {
				& > td:first-of-type {
					border-left: 2px solid $red;
				}
			}

			&.stage-title {
				& > td {
					border-left: 0;
					background-color: $blue;
					color: $white;
					font-size: 12px;
					font-weight: $boldFontWeight;
					height: 29px;
					background-color: $grey-3;
					color: $grey-13;
					border-top: 1px solid $grey-6;
					border-bottom: 1px solid $grey-6;
				}

				& > :hover {
					border-left: 0;
					background-color: $blue;
					color: $white;
					font-size: 12px;
					font-weight: $boldFontWeight;
					height: 29px;
					background-color: $grey-3;
					color: $grey-13;
					border-top: 1px solid $grey-6;
					border-bottom: 1px solid $grey-6;
				}
			}
			&.selected {
				& > td {
					background-color: $white;
					border-left: 1px solid $white;
				}

				&:hover {
					& > td {
						background-color: $super-light-yellow;
						border-left: 1px solid $super-light-yellow;
					}
				}
			}

			&.selected-grey {
				& > td {
					background-color: $grey-2;
					color: $black;
					font-weight: $boldFontWeight;
				}

				&:hover {
					& > td {
						background-color: $grey-2;
						color: $black;
						font-weight: $boldFontWeight;
					}
				}
			}
			&.th-style td {
				@include th-style(true);
			}
			&.no-result-row,
			&.error-row,
			&.load-row {
				&:hover,
				&.hover {
					td {
						background-color: $white;
						cursor: default;
					}
				}
			}

			&.no-border td {
				border-color: transparent;
			}

			&.table-section {
				background-color: $white;

				td {
					padding: 15px;
					cursor: default;

					h2 {
						vertical-align: middle;
					}

					&:hover,
					&.hover {
						background-color: $white;
						cursor: default;
					}
				}
			}

			&.no-result-row,
			&.error-row {
				td {
					color: $grey-10;
					text-align: center;
					font-size: 18px;
				}
			}

			&.no-result-row.new {
				td {
					background-color: $grey-4;
					padding: 70px 0;

					&:hover {
						cursor: default;
					}

					h3 {
						font-size: 16px;
						font-weight: normal;
					}

					p {
						font-size: 14px;
						margin: 0;
						margin-top: 20px;
					}

					#no-result-script-box {
						margin: 10px auto 0 auto;
						width: 560px;

						& > span {
							font-size: 12px;
							margin-bottom: 5px;
						}

						textarea {
							width: 100%;
							@include border-radius(3px);
							@include box-shadow(0 1px 2px rgba($black, 0.1));
							border: 1px solid $grey-6;
							background-color: $white;
							font-size: 12px;
							padding: 20px;
							color: $blue;
							height: 60px;
							outline: none;
							text-align: center;
						}
					}
				}
			}

			&.error-row {
				td {
					.icon {
						margin-right: 5px;
						color: $orange;
					}
				}
			}

			&.load-row {
				td {
					color: $black;
					text-align: center;
					font-size: 32px;
				}
			}

			&.inactive {
				td {
					background-color: $grey-2;
					border-left-color: $grey-2;

					&:first-child {
						border-left: 4px solid $grey-8;
						padding-left: 8px;
					}

					&.column-date,
					&.column-date span {
						color: $grey-10;
						font-style: italic;
					}
				}
			}

			&.passed {
				td.column-date {
					color: $red;
				}

				& > td:first-child {
					border-left: 5px solid $red;
					padding-left: 8px;
				}
			}

			&.passed:hover {
				& > td:first-child {
					border-left: 5px solid $red;
					padding-left: 8px;
				}
			}

			&.has-priority {
				.column-tools {
					width: 135px;

					.activity-priority {
						display: inline-block;
					}
				}
			}

			&.paginator {
				&:hover td,
				&.hover td {
					background-color: $white;
				}

				td {
					border-bottom: 0;
					text-align: center;

					ul.pagination {
						li.active a {
							background-color: $green;
							border-color: $green;
						}
					}
				}
			}

			&.has-open-drilldown td {
				background-color: $white !important;
				font-weight: $boldFontWeight;
				// padding-left: 11px; // to compensate for removed border
				border-bottom: 1px solid $grey-8;

				position: relative;

				&:before {
					content: "";
					display: block;
					height: 3px;
					position: absolute;
					top: 0;
					left: -1px;
					right: -1px;
					background-color: $blue;
					z-index: 1;
				}

				a {
					font-weight: normal;
				}
			}

			// row hover
			&:hover:not(.no-click):not(.th-style):not(.external-client) {
				&.inactive > td {
					&:first-child {
						border-left: 5px solid $grey-8;
					}
				}

				&.passed > td {
					&:first-child {
						border-left: 5px solid $red;
					}
				}

				&.won > td {
					&:first-of-type {
						border-left: 2px solid $bright-green;
					}
				}

				&.lost > td {
					&:first-of-type {
						border-left: 2px solid $red;
					}
				}

				&.has-open-drilldown > td {
					//border-left: none;
				}

				&.table-section > {
					background-color: $white;
					cursor: default !important;
				}

				// &:nth-child(odd) > td,
				& > td:not(.drilldown-td) {
					cursor: pointer;
					background-color: $grey-1;
				}
			}

			&.external-client {
				background-color: $super-light-blue;

				&:hover {
					background-color: darken($color: $super-light-blue, $amount: 5);
				}
			}

			& > td {
				width: auto;
				padding: 0px 0px 0px $table-cell-padding-left-right;
				height: inherit;

				font-size: 12px;
				border-bottom: 1px solid $grey-4;

				&.ellipsis-wrap .ellipsis {
					height: 100%;
					line-height: 100%;
					top: 0;
				}

				// tale gravatar
				.table-gravatar {
					$size: 33px;
					width: $size;
					height: $size;
					border: 1px solid $grey-4;
					@include border-radius($size/2);
				}

				&.inactive {
					border-left: 5px solid $grey-8;
					padding-left: 8px;
					color: $grey-10;
					font-style: italic;

					span,
					.column-date {
					}
				}

				&.checkbox-column {
					width: 20px;

					.checkbox {
						margin: 0;
					}
				}

				// column types
				&.column-value,
				&.column-date {
					white-space: nowrap;
				}

				&.column-value.green {
					color: $medium-green;
				}

				&.column-value.red {
					color: $red;
				}

				&.column-form {
					.form-name {
						display: block;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						max-width: 200px;
					}

					.lead-source {
						display: block;
						font-size: 10px;
						color: $grey-10;

						img {
							width: 15px;
							margin-right: 3px;
						}

						.fa {
							margin-right: 3px;
						}
					}
				}

				&.account-contact-col {
					.account-subtitle {
						display: block;
						font-size: 10px;
						text-transform: uppercase;
						a {
							color: $grey-10;
						}
					}
				}

				&.column-prio {
					.important {
						display: flex;
						align-items: center;
					}

					.Icon {
						font-size: 6px;
						margin-right: 6px;
					}
				}

				.column-subtitle {
					display: block;
					font-size: 10px;
					text-transform: uppercase;
					color: $grey-10;

					a {
						color: $grey-10;
					}
				}

				&.column-assigned {
					.assigned-wrap {
						display: flex;
					}

					.inactive-user-icon-wrap {
						display: inline-block;
						font-size: 16px;
						width: 25px;
						text-align: center;
						height: 25px;
						line-height: 25px;
					}

					&.inactive-user {
						color: $grey-8;
					}

					.fa-user-circle-o {
						position: relative;
						margin-right: 5px;
					}

					.fa-plus {
						position: absolute;
						font-size: 8px;
						left: 8px;
						top: -2px;
					}

					.assigned-info {
						display: inline-block;
						font-size: 11px;
						vertical-align: top;
						line-height: 13px;

						&.no-date {
							line-height: 23px;
						}

						.user-name {
							max-width: 90px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
				&.column-leadSource {
					.source-icon {
						display: inline-block;
						vertical-align: top;
						padding: 10px;
						padding-left: 4px;

						.fa {
							font-size: 14px;
							margin-left: 2px;
						}
					}

					.title,
					.subtitle {
						position: absolute;
						top: 3px;
						left: 26px;
						max-width: 77%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						z-index: 1;
					}

					.no-value {
						top: 10px;
					}

					.subtitle {
						top: 16px;
						color: $grey-10;
						font-size: 11px;
					}

					.no-source {
						top: -7px;
						left: 8px;
						color: $grey-10;
						font-style: italic;
					}
				}

				// add column column
				&.add-column {
					width: 149px;
				}

				&.row-actions {
					text-align: right;
					.btn-group {
						text-align: left;
						.dropdown-toggle {
							color: $grey-8;

							&:hover,
							&:active {
								color: $black;
								text-decoration: none;
							}
						}

						&.open .dropdown-toggle {
							color: $black;
							text-decoration: none;
						}
					}
				}

				// column tools
				&.column-tools {
					text-align: right;
					width: 130px;
					padding-right: $table-cell-padding-left-right;

					&.has-priority {
						min-width: 145px;
					}

					button:not(.DisqualifyDropDown__disq-confirm):not(.DisqualifyDropDown__disq) {
						padding: 6px;
						color: $grey-8;

						&:hover,
						&:focus {
							text-decoration: none;
							color: $grey-10;

							&.delete-trigger {
								color: $red;
							}
						}

						&.priority-active {
							color: $red;
						}
					}

					.row-tool-loading {
						padding: 6px;
					}

					.row-tool .Icon {
						vertical-align: super;
					}

					.dropdown {
						&:not(.DisqualifyDropDown__disq-confirm):not(.DisqualifyDropDown__disq) {
							padding: 0 4px;
						}

						&.open {
							.dropdown-toggle {
								background-color: $white !important;
								color: $black;
								border-color: $grey-8;
								box-shadow: 0 2px 5px rgba($black, 0.2);
							}

							.dropdown-menu {
								&:after {
									display: block;
								}
							}
						}
						.dropdown-toggle {
							height: 24px;
							padding: 0;
							width: 24px;
							line-height: 24px;
							text-align: center;
							border: 1px solid transparent;

							&:active,
							&:focus,
							&:hover {
								background-color: transparent !important;
								color: $black;
							}
						}

						.dropdown-menu {
							margin-top: -1px;

							&:after {
								content: "";
								display: none;
								background-color: $white;
								position: absolute;
								right: 0;
								top: -1px;
								height: 1px;
								width: 22px;
								z-index: 1003;
							}
						}
					}

					.dropdown-menu.dropdown-menu-right {
						left: initial;
						right: 0;
					}

					&.responsive {
						.Icon-bars,
						.dropdown {
							display: none;
						}

						@media (max-width: 1140px) {
							& {
								width: 30px;
							}

							.row-tool {
								display: none;
							}

							.Icon-bars,
							.dropdown {
								display: inline-block;
							}
						}
					}
				}

				&.account-tools {
					display: flex;
					justify-content: flex-end;
				}

				&.column-tools-sm {
					width: 60px;
				}
			}
			span {
				&.column-users-text {
					display: flex;
					align-items: center;
				}
			}
			.column-goal {
				display: flex;
				align-items: center;
				.Checkbox {
					border-radius: 26px;
					margin-right: 5px;
					border: solid 1px $grey-6;
				}
			}
		}

		.sorted {
			color: $bright-blue;
		}

		&.editmode {
			th {
				border-left: 1px dashed $grey-10;

				&:first-child {
					border-left-color: $grey-2;
				}

				&.sortable {
					cursor: move;

					&:hover,
					&.hover {
						background-color: $grey-1;
					}
				}
			}
			& > tbody > tr {
				& > td {
					border-left: 1px dashed $grey-10;

					&:first-child {
						border-left-color: transparent;
					}

					&.inactive {
						border-left: 5px solid $grey-8 !important;
					}
				}

				&.inactive {
					& > td {
						&:first-child {
							border-left: 5px solid $grey-8 !important;
						}
					}
				}

				&:hover,
				&.hover {
					&.inactive {
						& > td {
							border-left: 1px dashed $black;
						}
					}

					& > td {
						border-left: 1px dashed $black;
					}

					& > td:first-child {
						border-left: 1px solid $white;
					}
				}
			}
		}
	}

	#dragger {
		position: fixed;
		top: 156px;
		display: inline-block;
		min-width: 2px;
		height: 31px;
		background-color: transparent;
		border-left: 2px solid $grey-11;
		z-index: 60000;

		.dragger-arrow {
			position: absolute;
			left: -5px;
			height: 10px;
			padding: 0;
			margin: 0;

			&#top {
				top: -7px;
			}
			&#bottom {
				top: 21px;
			}
		}

		.dragger-title {
			position: relative;
			padding: 3px;
			height: 20px;
			background-color: $grey-11;
			color: $white;
			margin-top: 45px;
			border-radius: 4px;
			font-size: 12px;
		}
	}
}

.list-tools {
	padding: 15px;

	.list-tools-right {
		float: right;
	}

	.icon-search {
		max-width: 160px;
		display: inline-block;
		height: 28px;
		font-size: 12px;
		margin-right: 10px;
		@include border-radius(2px);
		background-color: rgba($black, 0.05);
		border: none;
		color: $grey-10;
		@include box-shadow(none);
		@include transition(all ease-in 200ms);

		&:focus,
		&:active {
			max-width: 200px;
			background-color: $white;
			border: 1px solid $grey-8;
		}
	}
}

[table-type="scoreboard"] .column-status {
	height: 45px;
}

[table-type="scoreboard"] .up-inline-edit:hover .inline-activator {
	display: none !important;
}

table > tbody > tr > td.mid-mid {
	text-align: center;
	vertical-align: middle !important;
}

/** New react paginator **/
.react-paginator-wrap {
	text-align: center;
	display: block;
}

.react-paginator {
	a {
		cursor: pointer;
	}
}

.list-navigation-btn-wrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	transition: 0.2s right;
	box-shadow: 0 -2px 8px rgba($black, 0.2);
	width: 450px;
	margin: 0 auto;
	border-radius: 5px 5px 0 0;

	.abort-btn {
		margin: 0;
		display: inline-block;
		width: 35%;
		height: 50px;
		line-height: 50px;
		padding-left: 15px;
		background-color: $white;
		text-align: center;
		color: $bright-blue;
		vertical-align: middle;

		&:hover {
			background-color: $grey-4;
		}
	}

	.continue-btn {
		margin: 0;
		display: inline-block;
		width: 100%;
		height: 50px;
		line-height: 70px;
		padding-left: 15px;
		background-color: $bright-blue;
		text-align: center;
		color: $white;
		height: 70px;
		border-radius: 5px 5px 0 0;
		vertical-align: middle;

		.title {
			margin-top: 10px;
			margin-bottom: 5px;
			font-size: 22px;
			line-height: 1.2em;
		}

		.subtitle-text {
			font-size: 14px;
			line-height: 1.2em;
			margin-bottom: 5px;
		}

		.fa {
			position: absolute;
			right: 10px;
			height: 14px;
			bottom: 26px;
		}

		&.disabled {
			background-color: $grey-3;
			color: $grey-10;
			cursor: not-allowed;
		}

		&:not(.disabled):hover {
			background-color: $bright-blue;
		}
	}
}

.filters-open {
	.list-navigation-btn-wrapper {
		right: 315px;
	}
}
