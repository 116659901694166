@import "~StyleVariables";

.NavbarCustomLinks {
	a {
		text-decoration: none;
	}

	&__item {
		transition: all ease-out 200ms;
		cursor: pointer;

		.Icon,
		.Text {
			color: $grey-12;
			transition: color ease-out 200ms;
		}

		&:hover {
			background-color: $grey-1;

			.Icon,
			.Text {
				color: $medium-blue;
			}
		}

		.Column {
			$height: 40px;
			height: $height;
			line-height: $height;
			overflow: hidden;
			&:last-of-type {
				padding-right: 15px;
			}

			.Icon,
			.Text {
				height: $height;
				line-height: $height;
			}
			.Text {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.Icon {
				font-size: 16px;
			}
		}
	}
}
