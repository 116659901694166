@import "~StyleVariables";

$stepTitleHeight: 26px;

.AssignModalAssign {
	margin: 40px 0;
}

.AssignModalAssign__step {
	margin: 15px 0;
}

.AssignModalAssign__step__indicator {
	position: relative;
}

.AssignModalAssign__step__indicator:before {
	content: "";
	position: absolute;
	top: 12px;
	left: calc(50% - 1px);
	bottom: -35px;
	background-color: $grey-4;
	width: 2px;
}

.AssignModalAssign__step--last .AssignModalAssign__step__indicator:before {
	width: 0;
}

.AssignModalAssign__step__nr {
	$size: 20px;
	font-size: 14px;
	margin: 3px auto;
	text-align: center;
	border-radius: 50%;
	width: $size;
	height: $size;
	line-height: $size;
	color: $grey-10;
	background-color: $grey-4;
	z-index: 1001;
	position: relative;
}

.AssignModalAssign__step--last .AssignModalAssign__step__nr {
	margin-top: 10px;
}

.AssignModalAssign__step--active .AssignModalAssign__step__nr,
.AssignModalAssign__step--passed .AssignModalAssign__step__nr {
	background-color: $green;
	color: $super-light-green;
}

.AssignModalAssign__step__title {
	line-height: $stepTitleHeight;
}

.AssignModalAssign__step__content {
	padding-right: 50px;
}

.AssignModalAssign__step__desc {
	margin-bottom: 15px;
}

.AssignModalAssign__step__next {
	margin-top: 15px;
}

.AssignModalAssign__step__user-select {
	display: block;
	max-width: 500px;
}

.AssignModalAssign__step__manager-toggle-row,
.AssignModalAssign__step__user-role-row {
	margin-top: 15px;
}

.AssignModalAssign__step__manager-toggle-row > div {
	display: inline-block;
	margin-right: 5px;
}

.AssignModalAssign__step__passed-title,
.AssignModalAssign__step__title--clickable {
	cursor: pointer;
}

.AssignModalAssign__step__passed-title .Text {
	display: inline-block;
	line-height: $stepTitleHeight;
}

.AssignModalAssign__step__title-btn {
	padding: 0 !important;
	height: $stepTitleHeight !important;
	line-height: $stepTitleHeight !important;
}

.AssignModalAssign__step__assign-btn > .Text,
.AssignModalAssign__step__assign-btn > .Button {
	display: inline-block;
}

.AssignModalAssign__step__assign-btn > .Text {
	margin: 0 5px 0 20px;
}
