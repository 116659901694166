@import "~StyleVariables";

#mail-campaign {
	$faq-blue-color: $super-light-blue;
	@include card(97px);
	$tabs-height: 40px;

	.cursor-default {
		cursor: default;
	}

	.mailcampaign-visit-drilldown {
		.visit-drilldown-client {
			line-height: 14px;
			margin-top: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.col1,
		.col2,
		.col3,
		.col4 {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.col1,
		.col4 {
			width: 70px;
		}
	}

	&.full-size {
		.card-header {
			margin-left: 250px;
			background-color: $white;
			left: 0;
			right: 0;
			height: $tabs-height;

			ul.nav {
				border-top: none;
				height: $tabs-height;

				li {
					height: $tabs-height;

					&.tool-link {
						float: right;
						a {
							min-width: initial;
						}
					}

					a {
						position: relative;
						height: $tabs-height;
						line-height: $tabs-height;
						min-width: 140px;
						text-align: center;
						color: $grey-10;
						@include transition(all ease 300ms);
						border-bottom: none;

						&.current {
							color: $green;

							&:before {
								content: "";
								background-color: $green;
								height: 4px;
								width: 100%;
								position: absolute;
								left: 0;
								bottom: -1px;
							}
						}
					}
				}
			}
		}

		#card-sidebar {
			color: #4a4a4a;
			width: 250px;
			padding: 0;
			margin-top: 0;

			&:before {
				width: 250px;
			}

			& > * {
				position: relative;
			}

			h2#title {
				color: #4a4a4a;
				font-size: 20px;
				margin: 20px;
			}

			#preview-img {
				position: relative;
				visibility: hidden;
				@include opacity(0);
				@include transition(all ease 300ms);
				margin-top: 10px;

				&:hover {
					.preview-img-gradient {
						visibility: visible;
						@include opacity(0.8);
					}

					button {
						visibility: visible;
						@include opacity(1);
					}
				}

				&.failed,
				&.loaded {
					visibility: visible;
					@include opacity(1);
				}

				&.failed {
					img {
						display: none;
					}

					button {
						visibility: visible;
						@include opacity(1);
					}

					.preview-img-button-wrap {
						top: 0;
					}
				}

				img {
					width: 100%;
				}

				.preview-img-button-wrap {
					position: absolute;
					left: 50%;
					width: 150px;
					margin-left: -75px;
					top: 90px;
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.preview-img-gradient {
					visibility: hidden;
					@include transition(all ease 300ms);
					text-align: center;
					color: $white;

					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					background: linear-gradient($green, $medium-green);
					@include opacity(0);
				}

				button {
					visibility: hidden;
					margin-top: 10px;
					@include opacity(0);
				}
			}

			#error-reasons {
				margin-top: 10px;
				font-size: 12px;
				line-height: 20px;

				& > div {
					margin-bottom: 5px;

					.fa {
						margin-right: 5px;
					}
				}

				a:hover,
				a:active,
				a:focus {
					text-decoration: none;
				}
			}

			.sidebar-section {
				padding: 0 20px 10px 20px;
				margin-bottom: 10px;
				font-size: 14px;
				line-height: 20px;

				label {
					color: $grey-10;
					text-transform: uppercase;
					display: block;
					font-weight: $regularFontWeight;
					font-size: 12px;
					letter-spacing: 0.5px;
				}

				.subtitle {
					color: $grey-10;
					font-size: 10px;
					font-style: italic;
					display: block;
				}

				.ellipsis-line {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: block;
				}

				.progress {
					height: 10px;
					position: relative;

					.Progressbar {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						z-index: 1000;

						&.Progressbar--green {
							z-index: 1001;
						}
					}
				}
			}
		}

		#card-main-view {
			background-color: $grey-1;
			margin-top: $tabs-height;
			margin-left: 250px;
			overflow: hidden;
			overflow-y: auto;

			.send-to-section {
				padding: 25px;

				.Column {
					position: relative;
					z-index: 1001;
				}

				.send-to-section__info {
					padding-right: 20px;

					.Title {
						margin-bottom: 10px;
					}
				}

				.send-to-section__btns {
					text-align: right;
					padding-left: 10px;

					.Button {
						padding: 0;
					}
				}

				.Card__slice:before {
					width: 360px;
				}
			}

			#page-tools {
				display: block;
				padding: 20px;

				h2 {
					line-height: 30px;
				}

				.button-toggle {
					display: inline-block;
					margin-left: 1em;

					.button-toggle-wrapper {
						div {
							&:first-child {
								border-radius: 3px 0 0 3px;
							}

							&:last-child {
								border-radius: 0 3px 3px 0;
							}

							border: none;
							background-color: $grey-4;
							height: 30px;
							line-height: 30px;
							padding: 0 10px;
							font-size: 12px;
							box-shadow: inset 0 1px 2px rgba(153, 153, 153, 0.2);
							transition: all 200ms ease;
							outline: none;
							color: $grey-13;
							display: inline-block;
							margin: 0;
							width: 104px !important;
							text-align: center;
							cursor: pointer;

							&.active {
								background-color: $white;
								box-shadow: 0 1px 2px rgba(153, 153, 153, 0.2);
								color: $bright-blue;
							}
						}
					}

					.dd-filter {
						display: none;
						transition: all 0.4s;
					}

					@media only screen and (max-width: 1260px) {
						width: 185px;

						.dd-filter {
							display: flex;
							text-align: center;
							justify-content: center;
							align-items: center;
						}

						.button-toggle-wrapper {
							display: none;
							height: auto;
							position: absolute;
							z-index: 50;
							width: 185px;
							padding: 0;
							border-radius: 3px;

							> div {
								display: block;
								width: 100%;

								&:first-child {
									border-radius: 3px 3px 0 0;
								}
								&:last-child {
									border-radius: 0 0 3px 3px;
								}
							}
						}
					}
				}

				.btn-group {
					&.active-group {
						.group-trigger {
							display: none;
						}

						button:not(.group-trigger) {
							display: inline-block;
						}

						button.btn-white-blue {
							box-shadow: 1px 1px 2px rgba($black, 0.25);
						}
					}

					button:not(.group-trigger) {
						display: none;
					}

					.group-trigger {
						display: inline-block;
						border-radius: $border-radius;
					}

					&.undelivered {
						margin-left: 20px;
					}
					@media only screen and (max-width: 1060px) {
						&.undelivered {
							margin-left: 0px;
						}
					}
				}
			}

			.table-search {
				$height: 30px;
				position: relative;

				&.has-value {
					input + .fa {
						color: $bright-blue;
					}

					button {
						display: block;
					}
				}

				input {
					border: none;
					@include border-radius(3px);
					background-color: $grey-4;
					height: $height;
					line-height: $height;
					padding: 0 $height;
					font-size: 12px;
					@include box-shadow(inset 0 1px 2px rgba($grey-10, 0.2));
					@include transition(all ease 200ms);
					outline: none;
					width: 150px !important;
					color: $grey-13;

					&:focus {
						width: 200px;
						@include box-shadow(inset 0 1px 2px rgba($grey-10, 0.3));
						background-color: $grey-4;
						color: $bright-blue;
					}

					& + .fa {
						position: absolute;
						top: 0;
						left: 0;
						width: $height;
						height: $height;
						line-height: $height;
						text-align: center;
						font-size: 14px;
						color: $grey-10;
						@include transition(all ease 200ms);
					}
					@media only screen and (max-width: 1260px) {
						width: 185px !important;
					}
				}

				button {
					border: none;
					background-color: transparent;
					height: $height;
					line-height: $height;
					width: $height;
					text-align: center;
					outline: none;
					display: none;
					position: absolute;
					top: 0;
					right: 0;
					padding: 0;
					margin: 0;
					font-size: 12px;
					color: $grey-10;
					@include transition(color ease 200ms);

					&:hover {
						color: $black;
					}
				}
			}

			.no-result {
				display: block;
				text-align: center;
				color: $grey-10;
				line-height: 40px;
			}

			table {
				table-layout: fixed;
				width: 100%;

				.TableHeaderDropdown__trigger-button {
					padding: 0 0 0 10px;

					.Icon:first-of-type {
						font-size: 14px;
					}
				}

				thead {
					border-bottom: 1px solid $grey-4;

					th {
						$height: 25px;
						height: $height;
						color: $grey-11;
						padding: 0 10px 0 0;

						& > span {
							line-height: $height;
							text-transform: uppercase;
							font-weight: normal;
							font-size: 12px;
						}

						.fa-question-circle {
							margin-left: 5px;
						}

						.sorting-indicator {
							margin-left: 4px;
							color: $grey-10;

							&.active-sort {
								color: $bright-blue;
							}
						}

						#check-btn {
							background-color: transparent;
							border: none;
							padding: 0 5px;
							@include transition(all ease 200ms);
							margin-left: 10px;

							&:hover {
								background-color: rgba($grey-10, 0.5) !important;
							}
						}
					}
				}

				tbody > tr {
					background-color: $white;
					height: 35px;
					line-height: 35px;
					border-bottom: 1px solid $grey-4;
					font-size: 12px;
					padding: 0;

					&:hover {
						background-color: $grey-1;
						cursor: pointer;
						@include transition(background-color ease 200ms);
					}

					&#load-row {
						background-color: transparent;

						.upsales-loader {
							width: 30px;
							height: 50px;
							display: block;
							margin: 0 auto;
						}
					}

					.column-checkbox {
						padding-left: 15px;
					}

					.account-contact-col {
						line-height: 17px;

						.account-subtitle {
							display: block;
							font-size: 10px;
							text-transform: uppercase;
							a {
								color: $grey-10;
							}
						}
					}

					.indicator-wrapper {
						vertical-align: middle;
					}

					.delivery-status {
						& > span {
							display: block;
							line-height: 15px;
							font-size: 12px;
							color: $grey-11;

							&.subtitle {
								color: $grey-10;
								text-transform: uppercase;
								font-size: 10px;
								letter-spacing: 1px;
							}
						}
					}

					.ellipsis-content {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						display: block;
					}

					.unsub-icon {
						position: relative;
						margin-right: 5px;

						.fa-times-circle {
							position: absolute;
							top: -4px;
							right: -3px;
							font-size: 10px;
						}
					}

					.invalid-email {
						color: $orange;
						text-decoration: underline;
					}

					&.drilldowned-row td {
						border-top: 2px solid $blue;
					}

					.drilldown-td {
						padding: 20px;
						background-color: $grey-1;
						border-bottom: 2px solid $blue;

						.upsales-loader {
							display: block;
							margin: 0 auto;
							width: 50px;
						}

						h3 {
							font-size: 14px;
							line-height: 22px;
							margin: 0;
							padding: 0;
							font-weight: $regularFontWeight;
							margin-bottom: 5px;
						}

						table {
							width: 570px;
							table-layout: fixed;
							background-color: $white;
							box-shadow: 0px 0.5px 1px 1px rgba($black, 0.075);
							border-radius: 2px;

							tr:hover {
								background-color: $white;
								cursor: default;
							}

							td {
								padding-right: 10px;

								.fa {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}

		.pagination {
			display: block;
			text-align: center;
			font-size: 10px;
			margin-top: 10px;
		}

		#dashboard {
			$white: $white;

			.topbar {
				display: table;
				width: 100%;
				table-layout: fixed;
				background: $white;

				.topbar-item {
					height: 110px;
					text-align: center;
					display: table-cell;
					vertical-align: middle;

					h2 {
						display: block;
						font-size: 30px;

						.percent {
							font-weight: normal;
							font-size: 14px;
							vertical-align: top;
							line-height: 20px;
							display: inline-block;
							margin-left: 5px;

							&.green {
								color: $bright-green;
							}

							&.orange {
								color: $orange;
							}

							&.purple {
								color: $medium-purple;
							}
						}
					}

					.small {
						text-transform: uppercase;
						color: $grey-10;
					}
				}
			}

			.elevio-btn {
				position: absolute;
				right: 15px;
				top: 6px;
				color: $grey-10;
				font-size: 12px;
			}

			.more-stats-toggler {
				padding: 10px 0;
				background: $white;

				> span {
					text-align: center;
					display: block;
					color: $bright-blue;
					cursor: pointer;

					i {
						margin-left: 5px;
					}
				}
			}

			.more-stats {
				display: none;
				transition: all 5s;
				border-bottom: 1px solid $grey-4;
				box-shadow: 0 1px 2px rgba($black, 0.2);

				&.is-visible {
					display: block;
				}

				&:after {
					clear: both;
					content: "";
					display: block;
				}

				.more-stats-item {
					background-color: $white;
					box-sizing: border-box;
					float: left;
					height: 160px;
					position: relative;
					border-top: 1px solid $grey-4;
					border-right: 1px solid $grey-4;

					&.no-padding-right {
						padding-right: 0;
					}

					&.no-border-right {
						border-right: transparent;
					}

					h3 {
						font-size: 16px;
						font-weight: $regularFontWeight;
						margin: 0;
						position: absolute;
						z-index: 999;
					}

					span.name {
						font-size: 11px;
						display: inline-block;
						padding: 0;
						line-height: 1;

						span.circle {
							width: 9px;
							height: 9px;
							display: block;
							margin-right: 5px;
							border-radius: 100%;
							margin-top: 1px;
							float: left;
						}

						span.opt-text {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							max-width: 100px;
							width: auto;
							float: left;
						}
					}

					.graph {
						width: 50%;
						float: right;
						display: block;
						.highcharts-container {
							// right: -10px;
							tspan {
								font-size: 10px;
								font-weight: $regularFontWeight;
							}
						}
					}

					@media only screen and (max-width: 1330px) {
						.opt-text {
							max-width: 140px !important;
						}
						.highcharts-container {
							display: none !important;
						}
					}

					.column-rows {
						&.align-middle {
							display: flex;
							height: 100%;

							.large-number {
								display: flex;
								flex: 1 0 auto;
								text-align: center;
								flex-direction: column;
								justify-content: center;

								> span {
									font-size: 28px;
									font-weight: $boldFontWeight;
									padding-top: 0.75em;

									&.sub-lang {
										font-size: 12px;
										font-weight: $regularFontWeight;
										padding: 0;
									}
								}
							}
						}
					}

					.show-all {
						display: block;
						text-align: center;
						margin-top: -1em;
						font-size: 12px;
					}

					.no-data {
						background-color: $grey-1;
						padding: 14px 18px;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						text-align: center;

						.no-data-msg {
							position: absolute;
							bottom: 10px;
							left: 20px;
							right: 20px;
							font-size: 14px;
							font-weight: $regularFontWeight;
							text-align: center;
							color: $grey-10;
						}

						.no-data-img {
							margin-top: 25px;
							height: 50px;
						}
					}
				}

				.follow-up {
					background-color: $faq-blue-color;
					padding: 2em;
				}
			}

			.timeline {
				$timeline-td-height: 55px;
				width: 100%;
				margin: 0;
				table-layout: auto;

				.custom-loader-holder {
					position: absolute;
					padding-top: 2.5em;
					width: 83%;
					left: 20%;
					margin-left: -13%;

					.loader {
						height: 4px;
						width: 100%;
						position: relative;
						overflow: hidden;
						background-color: transparent;
					}
					.loader:before {
						display: block;
						position: absolute;
						content: "";
						left: -200px;
						width: 200px;
						height: 4px;
						background-color: $bright-blue;
						animation: loading 2s linear infinite;
					}

					@keyframes loading {
						from {
							left: -200px;
							width: 30%;
						}
						50% {
							width: 30%;
						}
						70% {
							width: 70%;
						}
						80% {
							left: 50%;
						}
						95% {
							left: 120%;
						}
						to {
							left: 100%;
						}
					}
				}

				tr {
					height: 60px;
					border: none;
					line-height: 1.4em;
					background-color: transparent;

					&.no-result-row {
						line-height: 40px;
						text-align: center;

						h2 {
							color: $grey-10;
						}
					}

					td {
						vertical-align: middle;
						padding: 0;

						&.cursor-default {
							cursor: default;
						}

						.text-ellipsis {
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}

						.time {
							padding-left: 8px;
							width: 120px;
							display: inline-block;
							height: $timeline-td-height;
							vertical-align: middle;
							background-color: $white;
							border: 1px solid $grey-4;
							border-radius: 3px 0 0 3px;
							border-right: none;
						}

						.no-result-column {
							margin-left: 50px;
							background-color: $white;
							display: inline-block;
							vertical-align: middle;
							background: $white;
							height: 55px;
							width: calc(100% - 120px - 25px);
							border: 1px solid $grey-4;
							border-radius: 0 3px 3px 0;
							line-height: 55px;
							padding-left: 20px;
							cursor: default;
							font-size: 14px;
						}

						.main-column {
							display: inline-block;
							vertical-align: middle;
							background: $white;
							height: $timeline-td-height;
							width: calc(100% - 120px);
							border: 1px solid $grey-4;
							border-radius: 0 3px 3px 0;
							border-left: none;
							border-right: none;
							padding-right: 20px;

							> div {
								background: $white;
							}

							.timeline-account-and-title {
								font-size: 11px;
								opacity: 0.9;

								.indicator-wrapper {
									height: 12px;
									margin: 0 0 0px 1px;

									div {
										line-height: 12px;
										font-size: 9px;
										min-width: 20px;
									}
								}
							}
						}

						.timeline-column {
							display: inline-block;
							vertical-align: middle;
							background: $white;
							height: $timeline-td-height;
							width: 100%;
							border: 1px solid $grey-4;
							border-radius: 0 3px 3px 0;
							border-left: none;
							border-right: none;
							padding-right: 20px;
							padding: 2px 0px;

							.MailBounceLabel {
								position: relative;
								top: -1px;
							}
						}

						.last-column {
							width: 90%;
							border-right: 1px solid $grey-4;
							line-height: 50px;
						}

						.valign-middle {
							position: relative;
							top: 50%;
							transform: translateY(-50%);
							line-height: 18px;
						}
					}
				}

				thead {
					border: none;

					tr {
						height: 30px;
					}

					th {
						position: relative;
						vertical-align: bottom;

						&.col1 {
							width: 50px;
						}

						&.col2 {
							width: 120px;
						}

						&.col5 {
							width: 150px;
						}

						.time {
							width: 120px;
							display: inline-block;
							text-transform: uppercase;
							font-weight: normal;
							font-size: 12px;
						}

						.uppercase-fontwight400 {
							text-transform: uppercase;
							font-weight: $regularFontWeight;
							font-size: 12px;
						}

						span {
							display: inline;
							vertical-align: bottom;
							line-height: initial;
							position: absolute;
							left: 120px;
							height: auto;
							bottom: 2px;
						}
					}
				}

				tfoot {
					tr {
						td {
							z-index: 1 !important;
							padding: 10px;
							position: relative;
							vertical-align: middle;
							color: $grey-13;
						}
					}
				}

				.icon-td {
					background-color: transparent;
					padding: 10px;
					width: 50px;

					.icon {
						position: relative;
						color: $grey-8;
						border-radius: 30px;
						background-clip: padding-box;
						box-shadow: 1px 1px 2px rgba($black, 0.1);
						background-color: $white;
						width: 30px;
						height: 30px;
						text-align: center;
						font-size: 20px;
						margin-bottom: 0;

						i.fa {
							margin: 0 auto;
							display: block;
							line-height: 30px;
							color: $grey-10;
							font-size: 17px;

							&.fa-mouse-pointer {
								font-size: 14px;
								margin-left: 8px;
							}
						}
					}
				}
			}
		}

		.ListViewActions {
			&--open {
				bottom: 15px;
			}
		}
	}
}

#start-mail-campaign-modal {
	#planes {
		position: absolute;
		top: -60px;
		width: 130px;
		left: 50%;
		margin-left: -65px;
	}

	.up-modal-header {
		border-top: 5px solid $bright-blue;
		@include border-radius(0);
	}

	.up-modal-content {
		padding: 70px 70px 20px;

		h2 {
			font-size: 22px;
			margin: 0;
			padding: 0;
			font-weight: $regularFontWeight;
			margin-bottom: 10px;
		}

		p {
			margin: 0 0 25px;
			color: $grey-11;
		}

		.up-btn {
			display: block;
		}
	}
}
