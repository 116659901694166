@import "~StyleVariables";

.Header {
	margin: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__folder-tree {
		display: flex;
		align-items: center;
		white-space: nowrap;
		cursor: pointer;

		.Icon {
			font-size: 12px;
		}
	}

	&__right-container {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	&__folders-dropdown-button {
		margin: 0px 8px;
		padding: 10px;
		border-radius: 50%;
		&:hover {
			background-color: $grey-6;
		}
	}

	&__dropdown {
		padding: 8px 0;
		width: 300px;
	}
}
