@import "~StyleVariables";

.ProjectConversation {
	&__mention-input {
		border: $border;
		border-radius: $border-radius;

		.public-DraftStyleDefault-block {
			min-height: 60px;
		}

		.public-DraftEditorPlaceholder-root {
			z-index: 0;
		}
	}


	&__comment {
		border-bottom: 1px solid $grey-4;

		&__header {
			& > .Text {
				display: inline-block;
			}
		}

		.MentionsInput__mention {
			background-color: $grey-4;
		}
	}
}
