@import "~StyleVariables";

.AppOnboardingCompanyConfirm {
	position: relative;
	margin-top: 60px;

	&__company-info {
		border-radius: $border-radius;
		box-shadow: 0 4px 8px rgba($black, 0.2);
	}

	&__legend {
		position: relative;
		padding-left: 26px;
	}

	&__legend-block {
		height: 14px;
		width: 14px;
		border-radius: $border-radius;
		position: absolute;
		left: 0;
		top: 14px;
	}

	&__chart {
		position: relative;
		height: 82px;
	}

	&__baseline {
		position: absolute;
		left: 0;
		right: 0;
		background-color: $green;
		height: 1px;
		z-index: 1;
	}

	&__result-bar {
		position: absolute;
		width: 16px;
	}

	&__arrow {
		position: absolute;
		right: 20px;
		top: -60px;
		width: 200px;

		img {
			position: absolute;
			left: -46px;
			top: 12px;
			font-size: 20px;
		}
	}
}
