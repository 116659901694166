@import "~StyleVariables";

.UpsellCompanyGroup {
	width: 1100px;
	height: 100%;
	overflow-y: auto;

	.Table {
		background-color: $white;
	}

	&__header {
		&__controls {
			width: calc(100% - 50px);
		}
	}

	&__firstColumn {
		width: 60%;
	}
}
