@import "~StyleVariables";

.EditProjectPlanTemplate__TaskSettings {
	.TableColumn:last-child {
		padding-right: 0;
	}

	.TaskSettings__add-task-buttons {
		height: 100%;
	}

	.TaskSettings__list-name-wrapper {
		.Text {
			max-width: 400px;
		}

		.EllipsisTooltip {
			flex-grow: 1;
		}
	}
}
