@import "~StyleVariables";

.ComparisonToggle {
	display: inline-block;
	margin: 0 5px;
	color: $bright-blue;
	cursor: pointer;
}

.ComparisonToggle--locked {
	color: inherit;
	cursor: inherit;
}

.ComparisonToggle .Icon {
	margin-left: 3px;
}
