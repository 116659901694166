@mixin main-menu() {
	// SUB MENU
	#sub-menu {
		$sub-menu-height: $main-menu-height + 5px;
		width: 100%;
		height: $sub-menu-height;
		position: fixed;
		top: $navbar-height;
		left: 0;
		width: 100%;
		box-shadow: none;

		.indicator {
			font-size: 11px;
			margin-left: 10px;
		}

		.offline {
			font-style: italic;
			color: $grey-11;
			line-height: $sub-menu-height - 1px;
			vertical-align: middle;
			padding-right: 5px;

			.fa {
				color: $orange;
			}
		}

		nav {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			display: block;
			height: 100%;
			margin-top: 0px;
			visibility: hidden;
			@include opacity(0);
			@include transition(all ease 200ms);
			z-index: 1000;

			&.visible-force,
			&.visible {
				@include opacity(1);
				visibility: visible;
			}

			&.visible {
				z-index: 1001;
			}

			& > span:first-child > a.sub-menu-item {
				border-left: 0;
			}

			& > a,
			a.sub-menu-item {
				outline: none;
				font-size: 13px;
				display: inline-block;
				height: $sub-menu-height;
				cursor: pointer;
				color: $grey-10;
				padding: 9px 12px;
				border-left: 1px solid $grey-4;
				vertical-align: top;
				transition: all ease 200ms;
				border-bottom: 2px solid $white;

				&:hover,
				&.hover,
				&.active {
					color: $green;
					text-decoration: none;
				}
				&:hover,
				&.hover {
					color: $green;
					border-bottom: 2px solid $grey-10;
					background-color: $grey-1;
				}
				&.active {
					color: $green;
					border-bottom: 2px solid $green;
				}
				&.has-dd {
					padding-right: 25px;
				}
			}

			@media (max-width: 1120px) {
				& > a,
				a.sub-menu-item {
					font-size: 12px;
				}
			}
		}

		.dropdown {
			display: inline-block;

			&.open .dropdown-toggle {
				border-bottom: 1px solid $green;
				@include box-shadow(none);

				.fa-angle-down,
				.fa-caret-down {
					display: none;
				}

				.fa-angle-up,
				.fa-caret-up {
					display: inline-block;
				}
			}

			.dropdown-menu {
				top: 33px;
				margin-left: -25px;
				height: auto;
				max-height: 60vh;
				overflow: auto;
			}

			&.right .dropdown-menu {
				left: initial;
				right: 0;
			}

			.dropdown-toggle {
				position: relative;
				display: inline-block;
				height: $sub-menu-height;
				width: 25px;
				border: 0;
				background-color: transparent;
				border-bottom: 1px solid transparent;
				color: $grey-11;
				margin-left: -25px;

				&:hover {
					color: $green;
					border-bottom: 2px solid $grey-10;
					background-color: $grey-1;
				}

				.fa-angle-up,
				.fa-caret-up {
					display: none;
				}

				.vertical-divider {
					position: absolute;
					left: -2px;
					background-color: $grey-6;
					width: 1px;
					height: 17px;
				}
			}
		}
	}

	.white-navbar {
		background-color: $white !important;

		nav.visible-force {
			background-color: $white !important;
		}
	}
	// END OF SUB MENU

	#navbar.old-branding.future-nav {
		height: 82px;
		background: $green !important;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		border-bottom: none;

		.search-form {
			.nav-search-input {
				transition: all ease-out 200ms;
				transition-property: background-color, color;
			}

			#advanced-toggle {
				transition: all ease-out 200ms;
				transition-property: background-color, color;
			}
		}

		#sub-menu {
			box-shadow: none;

			nav > a,
			nav a.sub-menu-item,
			.dropdown .dropdown-toggle {
				border-bottom: none;
				border-left: none;
				color: rgba($white, 0.8);
				border-bottom: 2px solid transparent;
			}

			nav a.sub-menu-item.active + .dropdown .dropdown-toggle,
			nav a.sub-menu-item.active {
				background-color: $white;
				border-bottom-color: $white;
				color: $green;
				border-radius: 3px 3px 0 0;
				font-weight: $boldFontWeight;
			}

			.dropdown .dropdown-toggle:hover,
			nav a.sub-menu-item:not(.active):hover {
				border-radius: 3px 3px 0 0;
				background-color: rgba($white, 0.1);
				color: $white;
			}
		}

		#profile-dropdown.open a.dropdown-toggle {
			background-color: $white;
			color: $green;
		}

		.dropdown-toggle {
			&:hover:not([aria-expanded="true"]),
			&.hover:not([aria-expanded="true"]) {
				background-color: rgba($white, 0.2);
			}
		}

		#profile-dropdown {
			// &:hover {
			// 	background-color: rgba($white, .2);
			// }

			.profile-dropdown-btn {
				border: none !important;
			}

			#logout-btn {
				border-top: 1px solid $grey-4;
				transition: background-color ease 200ms;
			}

			.profile-dropdown-btn,
			#logout-btn {
				color: $grey-12 !important;

				&:hover {
					background-color: $grey-2 !important;
					color: $black !important;
				}
			}
		}

		#profile-dropdown .dropdown-toggle {
			transition: all ease-out 200ms;
			height: 100%;
		}

		.nav-menu.menu-right {
			height: 46px;
		}

		.nav-menu:first-child li.menu-item {
			height: 30px;
			margin-left: 5px;

			a {
				height: 30px;
				line-height: 30px;
				border-radius: 3px;
				text-transform: none;
				font-weight: $boldFontWeight;

				&#search-toggle:hover {
					background-color: rgba($white, 0.5);
				}
				&.current {
					background-color: $white;
					color: $green;
					font-weight: $boldFontWeight;
				}

				&:hover:not(.current) {
					color: $white;
					background-color: rgba($white, 0.1);
					text-decoration: none;
				}

				.item-text {
					margin-left: 5px;
				}

				.icon {
					margin-right: 0;
				}
			}
		}

		.search-form:not(.focused) {
			input[type="text"] {
				font-style: italic;
				@include placeholder-color($grey-6);
			}

			.nav-search-input {
				background: $greyeen !important;
				border-radius: 6px;
				border-bottom: none;
				color: $green;
				padding-left: 30px;

				&:hover {
					box-shadow: 0 2px 4px rgba($black, 0.2);
					background-color: rgba($white, 1) !important;
				}
			}

			#advanced-toggle {
				color: $white;
				background-color: $green;
				border-radius: 3px;
				top: 3px;
				right: 3px;
				border: none;
				height: 24px;
				line-height: 12px;
				border-left: 1px solid $green;

				&:hover {
					background-color: $dark-green;
				}
			}

			#advanced-toggle-icon {
				background-color: transparent;
				border-radius: 0 3px 3px 0;
				border: none;
				height: 30px;
			}
		}

		ul.upsales-tools {
			border-bottom-left-radius: 5px;
			border-top-left-radius: 5px;
			background-color: $white !important;
			height: 140px !important;

			&.two-rows {
				height: 240px !important;
			}
		}

		.upsales-tools #dropdown-header-fire {
			display: none;
		}
	}

	#navbar.new-branding {
		$menu-item-height: 30px;
		height: 82px;
		background: $green !important;
		border-bottom: none;
		transition: background-color ease-out 300ms;

		.search-form {
			border-radius: $border-radius;

			&:not(.focused) {
				margin-left: 8px;
				height: $menu-item-height;
			}

			&.focused,
			&:hover {
				.nav-search-input {
					background-color: $white !important;
					@include placeholder-color($grey-6);
				}

				.medium-search .fa-search,
				.large-search .fa-search {
					color: $grey-6;
				}
			}

			&.focused {
				top: 8px;

				#advanced-toggle {
					background-color: transparent;
					height: 34px;
				}

				.nav-search-input {
					background-color: $white !important;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

				.navbar-search .fa-search {
					left: 12px;
				}
			}

			.nav-search-input {
				transition: all ease-out 200ms;
				transition-property: background-color, color;
				background-color: rgba($white, 0.2) !important;
				border-radius: $border-radius;
				border: none;
				height: 100%;
				font-size: 12px;
				@include placeholder-color($grey-6);
			}

			#advanced-toggle {
				$space: 3px;
				transition: all ease-out 200ms;
				transition-property: background-color, color, box-shadow;
				top: 0;
				right: 0;
				padding: 0 12px;
				background-color: $green;
				border-radius: $border-radius;
				margin: $space;
				height: $menu-item-height - ($space * 2);
				line-height: $menu-item-height - ($space * 2);
				border: 0;
				font-size: 12px;
				color: $white;

				&:hover {
					background-color: $green;
					color: $white;
					box-shadow: 0 8px 12px rgba($black, 0.2);
				}
			}

			.navbar-search {
				margin: 0;

				.fa-search {
					color: rgba($white, 0.7);
					left: 8px;
					top: 50%;
					margin-top: -8px;
				}
			}
		}

		.nav-menu {
			display: inline-flex;
			align-items: center;
			li.menu-item:not(#profile-dropdown) {
				height: $menu-item-height;
				margin-left: 8px;
				& > a {
					line-height: $menu-item-height;
					color: rgba($white, 0.8);
					text-transform: none;
					border-radius: $border-radius;
					font-size: 12px;

					&.current {
						color: $green;
						background-color: $white;
					}

					&:hover:not(.current) {
						background-color: rgba($white, 0.2);
						color: $white;
					}
				}
			}
			li#logo {
				width: 90px;
				padding: 0;
				margin-left: 8px;

				a {
					display: flex;
					align-items: center;
					width: 100%;
				}

				.Logo {
					width: 100%;
					height: 22px;
				}

				.BadgeLogo {
					display: none;
				}
			}
		}

		@media (max-width: 1250px) {
			.nav-menu li.menu-item .icon {
				margin: 0;
			}
			.nav-menu li#logo {
				width: 30px;
				.Logo {
					display: none;
				}
				.BadgeLogo {
					display: inline-block;
				}
			}
		}

		#sub-menu {
			box-shadow: none;
			background-color: $white;
			border-bottom: 4px solid $grey-2;
			height: 38px;

			nav > a,
			nav a.sub-menu-item,
			.dropdown .dropdown-toggle {
				border-bottom: none;
				border-left: none;
				color: $grey-11;
				border-bottom: 4px solid transparent;
				padding: 0 8px;
				line-height: 34px;
				height: 38px;
			}

			nav a.sub-menu-item.has-dd {
				padding-right: 25px;
			}

			nav a.sub-menu-item.active + .dropdown .dropdown-toggle,
			nav a.sub-menu-item.active {
				background-color: transparent;
				border-bottom-color: $green;
				color: $green;
				font-weight: $boldFontWeight;
			}

			.dropdown .dropdown-toggle:hover,
			nav a.sub-menu-item:not(.active):hover {
				background-color: $grey-2;
				border-bottom-color: $grey-6;
			}
		}

		#profile-dropdown {
			.generic-avatar {
				margin-right: 0;
			}

			#logout-btn {
				border-top: 1px solid $grey-4;
				transition: background-color ease 200ms;
			}

			.profile-dropdown-btn,
			#logout-btn {
				color: $grey-12 !important;

				&:hover {
					background-color: $grey-2 !important;
					color: $black !important;
				}
			}
		}

		ul.upsales-tools {
			border-bottom-left-radius: $border-radius;
			border-top-left-radius: $border-radius;
			background-color: $white !important;
			height: 140px !important;

			&.two-rows {
				height: 240px !important;
			}
		}

		.upsales-tools #dropdown-header-fire {
			display: none;
		}

		#advanced-toggle-icon {
			position: absolute;
			right: 15px;
			top: 7px;
			z-index: 20000;
			border-left: 1px solid $grey-8;
			font-size: $navbar-font-size;
			line-height: 19px;
			font-size: 16px;
			@include transition(all ease-out 200ms);

			&:hover {
				text-decoration: none;
				background: $grey-1;
			}

			&:focus {
				text-decoration: none;
				display: none;
			}
		}
	}
}
