@import "../../../styles/variables";


.Billing{
	.TableColumn{
		white-space: nowrap;
	}
}

.Billing--version .Icon {
	margin: auto 0;
	padding-right: 10px;
}

.Billing--amount {
	display: inline-block;
}

.Billing--hide {
	display: none;
}

.Billing--currency {
	vertical-align: top;
	color: $grey-9;
	margin-left: 3px;
	font-weight: $regularFontWeight;
	font-size: 12px;
}

.Billing--statement-selected {
	background-color: $grey-1;
	border-left: 4px solid $green;

	&:hover {
		background-color: $grey-2 !important;
	}
}

.Billing--statement-faded {
	opacity: 0.6;
}

.Billing__credits {
	margin-top: 15px;
}

.Billing__credits .PurchaseCard {
	margin: 15px 0;
	float: right;
	clear: right;
}
.Billing__cancel {
	margin-top: 15px;
}

.Billing--transaction-table {
	border-bottom: 3px solid $green;
}

.Billing--transaction-table .Billing--currency {
	font-size: 10px;
}

.Billing--transaction-table .TableHeader {
	background-color: $grey-1;
}

.Billing--version {
	padding-bottom: 15px;
}

.Billing--used, .Billing--used--uneditable {
	border-left: 1px solid lightgray;
	padding-left: 10px;
	margin-left: 20px;

	.Column {
		padding: 0 10px;
	}

	button {
		padding: 0;
	}
}
.Billing--used_editSeats {
	margin-right: 0px;
	margin-left: auto;
}

.Billing--used:hover {
	cursor: pointer;
}

.Billing--used--uneditable:hover {
	cursor: unset;
}

.Billing__seats-column {
	max-width: 240px;
}

.Billing--addon-label {
	padding-top: 10px;
}

.Billing--addons:hover {
	cursor: pointer;
}

.Billing--addons {
	background-color: #e4eaf0;
	color: #6b7c93;
	margin: 0 5px;
	padding: 0 5px;
	border: 1px solid #c9d2dd;
	border-radius: $border-radius;

	.Text {
		color: #6b7c93;
	}
}

.Billing--total-cost {
	.Billing--cost-breakdown {
		visibility: hidden;
		position: absolute;
		width: 200px;
		padding: 7px;
		z-index: 200;
		border-top: 2px solid $green;
		border-radius: $border-radius;
		box-shadow: 0px 5px 10px 5px rgba(30, 37, 46, 0.2);
	}
}

.Billing--total-cost:hover {
	cursor: pointer;

	.Billing--cost-breakdown {
		visibility: visible;
	}
}

.Billing--avatar {
	text-align: center;
	font-size: 12px;
	font-weight: $regularFontWeight;
}

.Billing--statement-table {
	margin-top: "15px";

	.TableRow {
		height: 44px;
	}

	td.TableColumn {
		max-width: 230px;
	}

	td.TableColumn:first-child {
		width: 100px;
	}

	.Text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.TableRow--credits .Billing--purchase-card {
	margin: 12px 0;
}

.Billing--used .Progressbar {
	margin: 10px 0;
}

.Billing--billing-method {
	.Card {
		background-color: $grey-1;
		border: 1px solid $grey-4;
		border-radius: $border-radius;
		box-shadow: none;
		padding: 15px;
		height: 150px;

		.Card {
			background-color: transparent;
			border: none;
			border-radius: 0;
			padding: 0;
			height: auto;
			width: 50%;
			display: inline-block;
			vertical-align: top;
		}
	}
}

.Billing__uc-label {
	text-transform: uppercase;
	letter-spacing: 1px;
}

.Billing__buyUpsalesBtn {
	border-radius: 0px !important;
	box-shadow: none;
	padding-right: 7px;
	margin-right: 7px;
	margin-left: 0px;
	border-right: 1px solid rgba(0, 0, 0, 0.3) !important;
	height: 33px;
	border-top-left-radius: 2px !important;
}

.Billing__buyUpsalesDropdown {
	.DropDownMenu__content {
		min-width: auto;
		margin-top: -4px !important;
		.TableColumn {
			border-top: none;
		}
	}
	.DropDownButton__wrapper {
		.DropDownButton__icon-wrapper {
			margin: auto 12px 16px 0px;
		}
	}
	.DropDownButton__text-wrapper {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	background-color: $green;
	height: 40px;
	margin-top: 5px;
	border-radius: $border-radius;
	transition: background-color ease-out 0.2s;

	&:hover {
		background-color: darken($green, 5%);
		border-color: darken($green, 5%);
	}

	.TableRow {
		background-color: $green;
		cursor: pointer;
		transition: background-color ease-in 0.2s;

		&:hover {
			background-color: darken($green, 5%);
		}

		.TableColumn {
			padding-right: 15px;
		}
	}
	.Icon {
		color: $white;
	}
}

.BillingInvoiceRowWithAddons {
	&--hover:hover {
		background-color: $grey-1;
	}

	&__AddonProgressbar {
		background: linear-gradient(-45deg, #E9EEF3 25%, #F6F9FB 25%, #F6F9FB 50%, #E9EEF3 50%, #E9EEF3 75%, #F6F9FB 75%, #F6F9FB);
		background-size: 20px 20px;
	}

	&__edit_column {
		padding: 0;
		min-width: 50px;
		height: 50px;
		padding-right: 0;
	}

	&__tier-col .Text {
		padding-bottom: 6px;
	}
	&__cancel_dropdown_card {
		padding: 14px;
		border-radius: 2px 0 2px 2px;
		background-size: cover;
	}

	&__icon_pencil__true {
		background-color: $white;
		padding: 18px;
	}
	&__icon_pencil__false {
		min-height: 40px;
		padding: 18px;
	}
	&__icon_pencil {
		min-height: 40px;
		padding: 18px;
	}
	&__icon_question {
		padding-left: 4px;
		padding-right: 4px;
		color: $black;
		margin-top: auto;
		margin-bottom: auto;
	}
	&__cancel_dropdown_card .Text {
		padding-bottom: 10px;
	}
	&__trial_subtitle {
		font-size: 12px;
	}
}

.CreditRow {
	&__icon_question {
		padding-left: 4px;
		color: $black;
		margin-top: 25%;
		margin-bottom: auto;
	}
}

.renderInvoiceSubscriptions {
	&__enable_button_container {
		text-align: right;
	}
	&__enable_button {
		max-width: 200px;
	}
	&__question_circle {
		padding-left: 4px;
		color: $black;
	}
}
