@import "../../../../styles/variables";

.EditSalesboard .Breadcrumbs {
	padding: 0 !important;
	margin-bottom: 20px;
}

.EditSalesboard .admin-page-header {
	height: 150px;
	padding: 30px 40px 30px 40px;
}

.EditSalesboard #admin-content {
	max-width: initial !important;
	overflow-x: auto;
	padding-bottom: 50px;
	height: calc(100% - 150px);
}

.EditSalesboard #admin-content > .Row {
	flex-wrap: nowrap;
}

.EditSalesboard__dragContainer {
	display: flex;
	flex-wrap: nowrap;
	position: relative;
}

.EditSalesboard__dragContainer > div {
	margin-right: 10px;
}

.EditSalesboard #admin-content > .Row > .Column:last-child {
	min-width: initial;
	max-width: initial;
}

.EditSalesboard .admin-page-header .Column:nth-child(1) {
	padding-right: 30px;
}

.EditSalesboard__createNewButton {
	position: absolute;
	right: 30px;
	top: 22px;
	font-size: 14px;
}

.EditSalesboard__typeSelect-wrap {
	background-color: white;
	border-radius: 2px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	padding: 15px;
	max-width: 250px;
}

.EditSalesboard__typeSelect {
	width: 100%;
}

.EditSalesboard__help-text {
	padding-left: 50px;
}

.WrappedPortedAdminEditSalesboard#admin {
	#admin-root#admin-root {
		left: 0;
	}
}
