@import "~StyleVariables";

.MailEditorReviewSender {
	&__fake-rows-wrap {
		border-left: 1px solid $grey-4;
		position: relative;
		overflow-y: clip;
		padding: 10px 15px;
	}

	&__fake-rows {
		position: absolute;
		margin-top: 12px;
		left: 0;
		right: 0;
	}

	&__label-tools {
		float: right;

		.Button {
			height: 15px;
			line-height: 15px;
		}
	}

	&__form-elem {
		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.Text {
			margin-top: 3px;
		}
	}
	
	&__sender {
		display: inline-block;
	}

	.select2-container.error a {
		border-color: $red;
	}
}

.MailEditorReviewSender__send-test-modal {
	width: 400px;
	top: 50%;
	left: 50%;
	z-index: 10001;
	margin-left: -200px;
	margin-top: -100px;
}
