@import "~StyleVariables";
.ReportcenterTVList {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	flex-direction: column;
	margin-top: 47px;
	padding-top: 30px;

	&__content {
		width: 60%;
		min-width: 800px;
	}
	
	&__table {
		border: 1px solid $grey-6;
		border-radius: 4px;
		margin-top: 12px;
	}

	.TableHeader__column:first {
		border-top-left-radius: 4px;
	}

	.TableHeader__column {
		background-color: transparent;
	}

	.TableColumn {
		max-width: 200px;
	}

	.TableColumn--align-right {
		padding-left: 8px;
		padding-right: 0px;
		width: 20px;
	}

	.TableColumn--align-right:last-child {
		padding-left: 0px;
		padding-right: 8px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
	}

	&__dashboard-chip {
		border-radius: 8px;
		border: 1px solid $grey-8;
		width: fit-content;
		height: fit-content;
		padding: 2px 8px;
	}

	&__dashboard-column {
		display: flex;
		align-items: center;
		column-gap: 4px;
	}

	&__empty {
		img {
			height: 100px;
		}
		background-color: $grey-1;
		text-align: center;
		height: 276px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}