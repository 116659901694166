@import "~StyleVariables";

.AssignButton {
	border: none !important;
	text-shadow: none !important;
}

.AssignButton:hover {
	border: none !important;
}
