@import "../../../../../styles/variables";

.AccountListContacts__Name {
	max-width: 14vw;
	white-space: nowrap;
	display: flex;
	align-self: center;

	&--hasTitle {
		flex-direction: column;
	}

	span {
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.AccountListContacts__Name {
	.Name__subtitle {
		font-size: 11px;
		max-width: 10vw;
		a {
			color: $grey-13;
		}

		&--category {
			margin-left: 2px;
			color: $grey-10;
		}
	}
}

.Name__titles {
	display: inline-flex;
}

.AccountListContacts__Name .Icon {
	margin-right: 4px;
}
