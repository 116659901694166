@import "~StyleVariables";

.LoginForm {
	$input-height: 50px;
	&__remember-me {
		position: absolute;
		right: 0;
		top: 0;
		background-color: $super-light-green;
		height: $input-height;
		line-height: $input-height;
		border-radius: 0 $border-radius $border-radius 0;
		padding: 0 12px;
		cursor: pointer;
	}

	&__saved-user {
		background-color: $dark-green;
		border-radius: $border-radius;
		cursor: pointer;
		transition: all ease-in 300ms;
		align-items: center;
		display: flex;
		height: $input-height;

		& > .Text {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-left: 10px;
		}

		& > .Icon {
			color: $super-light-green;
			opacity: 0.5;
			font-weight: 500;
			flex: 0 0 $input-height;
			text-align: center;
		}

		&:hover {
			background-color: $greyeen;
		}
	}
}
