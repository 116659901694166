@import "~StyleVariables";

.BillingCancelModal {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: auto;
	min-height: 100%;
	overflow: hidden;
	z-index: 10002;

	.ModalContent {
		width: 600px;
		background-color: $white;
	}
	.ModalContent > * {
		margin: 16px 0;
	}
	.Textarea > * {
		height: 80px;
	}
}
