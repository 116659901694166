#mail-campaign.MailCampaign {
	&.full-size {
		left: 0;
		right: 0;
	}

	&:after {
		content: none;
	}
}
