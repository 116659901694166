.MailTemplatePreviewText {
	height: 100%;

	.TextEditor {
		.cke_editable {
			background-color: transparent;

			&:focus {
				outline: none;
			}
		}
	}
}
