@import "../../../styles/_variables";

.DesiredOutcomeGridItem {
	padding: 10px 10px;
	max-height: 195px;
	z-index: 100;
	cursor: pointer;
	transition: all ease 200ms;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.DesiredOutcomeGridItem:not(.DesiredOutcomeGridItem--no-fit):hover {
	box-shadow: 0 5px 5px 1px rgba(200, 200, 200, 1);
}

.DesiredOutcomeGridItem__title {
	min-height: 195px;
}

.DesiredOutcomeGridItem--expand-on-hover:hover {
	max-height: 400px;
	z-index: 200;
}

.DesiredOutcomeGridItem--selected {
	background-color: $bright-blue;

	.Text {
		color: $white;
	}
}

.DesiredOutcomeGridItem img {
	display: block;
	margin: auto;
	height: 70px;
	margin-bottom: 15px;
}

.DesiredOutcomeGridItem__text {
	font-size: 16px;
}

.DesiredOutcomeGridItem__description {
	flex: 1;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 8px;
}

.DesiredOutcomeGridItem--no-fit {
	height: 100%;
	width: 100%;
}

.DesiredOutcomeGridItem--no-fit .Button {
	font-size: 16px;
}

.DesiredOutcomeGridItem--center-text {
	display: flex;
	justify-content: center;
	align-items: center;
}
