@import "~StyleVariables";

.LandingpageEditor {
	&__modal {
		height: 100%;
		width: 100%;
		max-height: none !important;
		#card-main-view {
			padding-bottom: $space * 2;
			margin-bottom: 15px;
		}
	}
	&__route {
		.up-header {
			top: 82px;
		}
		#card-main-view {
			padding-bottom: $space * 2;
		}
		.card.full-size {
			bottom: 0px;
		}
	}
}
