@import "~StyleVariables";

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}

.AllRecommendationsApproved {
	.OpportunityRow:last-of-type {
		border-radius: 0px 0px 8px 8px;
	}

	&__header {
		z-index: 1;
		height: 53px;
		overflow: hidden;
		border-radius: 4px;
		transition: border-radius ease 400ms;

		&--open {
			border-radius: 4px 4px 0 0;
		}

		img {
			position: absolute;
			height: 54px;
			width: 93px;
		}

		.Button {
			position: absolute;
			right: 10px;
			top: 0px;

			height: 54px;
			width: -webkit-fill-available;
			text-align: end;
			animation: fadeIn 400ms;
			text-decoration: none;

			.Text {
				display: inline;
			}
		}

		&__rotatingIcon {
			animation: rotateBackAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(0deg);

			&--rotate {
				animation: rotateAnimation;
				animation-iteration-count: 1;
				animation-duration: 400ms;
				transform: rotate(180deg);
			}
		}

		&__greenShape {
			clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
			position: absolute;
			left: 0;
			top: 0;
			width: 70px;
			animation: slideInFromLeft 400ms;
			height: 53px;
			background: #5cb85c;
		}
		&__text {
			position: absolute;
			z-index: 1;
			animation: fadeIn 400ms;
			margin-left: 16px;
			padding-top: 3px;

			&--left-margin {
				margin-left: 80px;
			}

			.Text {
				padding-top: 15px; // Remove when article is added
			}
		}
	}
}
