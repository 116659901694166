@import "../../../styles/variables";

$avatarHeight: 25px;
.BillingInvoicePlan {
	&__billingInfo, .BillingInvoicePlanBillingAdmins {
		margin: -10px;
		padding: 10px;
		&:hover {
			background-color: $grey-2;
			border-radius: $border-radius;
			cursor: pointer;

			transition: background 250ms;

			.Icon {
				display: initial;
			}
		} 
	}

	.CardContent {
		padding: 15px;
	}
	
	.Tooltip, &__editIcon {
		float: right;
		display: none;
	}

	&__billingInfo {
		margin-right: 10px;
	}

	&__grid-base {
		display: grid;
		grid-template-columns: 66.6667% auto;
		grid-template-rows: auto;
		column-gap: 15px;
		row-gap: 15px;
	}

	&__saveCancel {
		margin-top: 16px;
	}
	&__saveButton {
		.Icon {
			margin-right: 4px;
		}
	}

	&__card, &__accountManagerCard {
		border-radius: $border-radius;
		box-shadow: none;
	}

	&__accountManagerCard {
		height: min-content;
		min-height: 160px;
	}

	&__save-address {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
	&__change-address {
		position: absolute;
		right: 10px;
		bottom: 0;
	}

	&__country-select .Select__selected {
		background-color: transparent;
		border-left: none;
		border-right: none;
		border-top: none;
		border-radius: 0;
	}

	.Input--error.Input--inline .Input__input {
		border-color: $red;
	}
}
