@import "~StyleVariables";

.SignalsToggle {
	position: relative;
	&:hover {
		cursor: pointer;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 18px;
	transition: border-bottom 200ms ease-out, width 200ms ease-out, height 200ms ease-out;

	* {
		transform: translateY(2px);
	}

	&--enabled {
		padding: 0px 11px;
		.SignalsToggle__icon-background {
			transition: background-color 200ms ease-out;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 28px;
			height: 28px;
			background-color: $super-light-green;
			border-radius: $border-radius;
			.Icon {
				transform: none;
			}
		}
	}

	#info-box {
		position: absolute;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-direction: column;
		border-radius: $border-radius;
		background-color: $super-light-blue;
		z-index: 1000;
		top: 65px;
		box-shadow: $box-shadow-lg;
		width: 200px;
		height: 180px;
		padding: 16px;
		color: $bright-blue;

		.Icon {
			font-size: 18px;
		}

		.Text:first-child {
			margin-bottom: 8px;
		}
	}
}
