@import "~StyleVariables";

.EasyBookingSettingsActivationGuide {
	border-radius: 4px;

	&__subtitle {
		margin: 16px 16px 16px 0;
		line-height: 24px;
	}

	&__suggestions {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
		margin-top: 16px;

		.Button {
			padding: 0 16px;
			display: flex;
			gap: 10px;
			justify-content: center;
			align-items: center;
			height: 42px;
		}
	}

	&__appLogo {
		width: 24px;
		height: 24px;
		transition: opacity 0.2s ease-out;
		background-origin: content-box;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
}
