@import "~StyleVariables";

.CompetitorRow {
	cursor: pointer;
	border-top: 1px solid $grey-2;
	transition: all ease 100ms;

	&:hover {
		background-color: $grey-1;
	}

	&__textColumn {
		max-width: 91.6667%;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__name {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__willBeMergedOrKeptText {
		opacity: 0;
		width: auto;
		flex-shrink: 0;
		text-align: right;
		transition: $transition-small-bounce;

		&--visible {
			opacity: 1;
		}
	}

	&__orderCountColumn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__orderCount {
		text-align: right;
	}
}
