.FlowStepParamList .Row {
	flex-wrap: nowrap;
}

.FlowStepParamList .Row .Text {
	white-space: nowrap;
}

.FlowStepParamList .Row .Text:last-child {
	text-overflow: ellipsis;
	overflow: hidden;
	padding-left: 5px;
}
