.AccountList .ReactVirtualized__Grid {
	&:focus {
		outline: none;
	}
}

.ReactVirtualized__Grid__innerScrollContainer {
	margin-bottom: 120px;
	overflow: initial !important;
}
