@import "~StyleVariables";

.MailReplyButton {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.Tooltip {
		.Button {
			border-radius: $border-radius-sm 0 0 $border-radius-sm;
			padding: 0 4px 0 8px;
			.Text {
				display: inline;
			}
			.Icon {
				margin-right: 8px;
			}
		}
	}

	.DropDownMenu {
		transition: opacity 0.2s ease-out;
		background-color: $green;
		border: 1px solid $green;
		height: 34px;
		border-radius: 0 $border-radius-sm $border-radius-sm 0;

		.DropDownMenu__content {
			border-radius: 4px;
			min-width: 120px;
			cursor: pointer;
		}
	}

	.DropDownButton {
		height: 28px;
		.DropDownButton__wrapper {
			height: auto;
		}
		.DropDownButton__text-wrapper {
			padding: 0;
		}
		.DropDownButton__icon-wrapper {
			margin: 4px 4px 0 0;
			opacity: 1;
			color: $white;
			align-items: center;
		}
	}

	&--disabled {
		.DropDownMenu {
			opacity: 0.6;
		}
		.DropDownButton {
			cursor: default !important;

			.DropDownButton__icon-wrapper {
				.Icon {
					cursor: default !important;
				}
			}
		}
	}

	&__item-card {
		display: flex;
		align-items: center;
		padding: 8px 16px;
		margin-top: 1px;
		gap: 10px;
		white-space: nowrap;
		&:hover {
			background-color: $grey-1;
		}
	}
}
