.BillingAccountManager {
	&__name {
		margin-top: 30px;
	}

	.Avatar {
		left: 50%;
		top: -40px;
		position: absolute;
		transform: translateX(-50%);
	}
}
