.widget-table-loader {
	position: absolute;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $grey-1;
	color: rgba($black, 0.2);
	text-align: center;
	height: 100%;

	.widget-table-loader-icon {
		font-size: 30px;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
}

.table-widget-note {
	color: rgba($black, 0.4);
	text-align: center;

	.fa {
		font-size: 20px;
		color: rgba($black, 0.4);
	}
}

table.widget-table {
	background-color: $grey-1;
	margin: 0;

	tr {
		&.header {
			height: 42px;
			border-top: 1px solid $grey-4;
		}
	}

	a {
		color: $bright-blue;
	}

	h2 {
		font-size: 18px !important;
		color: $grey-11;
	}

	tr {
		border: none !important;

		&.header {
			color: $grey-11;
			background-color: $white;

			th {
				padding-top: 10px;
			}

			.widget-table-option {
				color: rgba($black, 0.3);
			}
		}

		&.shaded {
			background-color: $grey-2;
		}

		&.widget-table-sub-header {
			border: none;
			text-transform: uppercase;
			font-weight: normal;
			font-size: 12px;
			background-color: $white;

			th {
				border: none !important;
				padding: 0 8px 4px 8px;
				font-weight: normal;
				border-bottom: 1px solid rgba($black, 0.02) !important;
			}
		}
	}

	td {
		border: none !important;
		padding: 10px !important;
	}

	&.bright-blue {
		background-color: darken($bright-blue, 10);
		color: $white;

		a {
			color: $white;
		}

		h2 {
			color: $white !important;
		}

		tr {
			&.header {
				color: $white;
				background-color: $bright-blue;

				th {
					padding-top: 10px;
				}
			}

			&.shaded {
				background-color: darken($bright-blue, 12);
			}

			&.widget-table-sub-header {
				background-color: $bright-blue;
			}
		}

		td {
			border: none;
		}

		// .widget-table-loader {
		//     background-color: $bright-blue;
		//     color: $white;

		//     .widget-table-loader-icon {
		//     	color: $white;
		//     }
		// }
	}

	&.blue {
		background-color: $dark-blue;
		color: $white;

		a {
			color: $white;
		}

		h2 {
			color: $white !important;
		}

		tr {
			&.header {
				color: $white;
				background-color: $blue;

				th {
					padding-top: 10px;
				}
			}

			&.shaded {
				background-color: darken($dark-blue, 2);
			}

			&.widget-table-sub-header {
				background-color: $blue;
			}
		}

		td {
			border: none;
		}

		// .widget-table-loader {
		//     background-color: $blue;
		//     color: $white;

		//     .widget-table-loader-icon {
		//     	color: $white;
		//     }
		// }
	}

	&.orange {
		background-color: darken($orange, 6);
		color: $white;

		a {
			color: $white;
		}

		h2 {
			color: $white !important;
		}

		tr {
			&.header {
				color: $white;
				background-color: $orange;
			}

			&.shaded {
				background-color: rgba($red, 0.1);
			}

			&.widget-table-sub-header {
				background-color: $orange;
			}
		}

		td {
			border: none;
		}

		.widget-table-loader {
			background-color: $orange;
			color: $white;

			.widget-table-loader-icon {
				color: $white;
			}
		}
	}

	&.purple {
		background-color: darken($medium-purple, 6);
		color: $white;

		a {
			color: $white;
		}

		h2 {
			color: $white !important;
		}

		tr {
			&.header {
				color: $white;
				background-color: $medium-purple;
			}

			&.shaded {
				background-color: rgba($purple, 0.1);
			}

			&.widget-table-sub-header {
				background-color: $medium-purple;
			}
		}

		td {
			border: none;
		}

		.widget-table-loader {
			background-color: $medium-purple;
			color: $white;

			.widget-table-loader-icon {
				color: $white;
			}
		}
	}
}
