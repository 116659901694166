@import "~StyleVariables";

.InlineChangeHistory {
	position: relative;
	width: 14px;

	&__button {
		opacity: 0;
		height: 39px;
		visibility: hidden;
		transition: all ease-out 200ms;
		position: absolute;
		cursor: pointer;
		right: 0;
		top: -19px;

		&--editing {
			visibility: visible;
			opacity: 1;
			box-shadow: -7px 0px 6px -2px rgba(30, 37, 46, 0.2);
			background-color: white;
			z-index: 1001;
		}

		&--specificChange {
			display: flex;
			align-items: center;
			background-color: inherit;
			box-shadow: none;
			width: 12px;
		}
	}

	&__edit {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: all ease-out 200ms;
		right: 0;
		top: 20px;
		width: 340px;
		background-color: #fff;
		z-index: 1;
		box-shadow: -2px 0px 10px 3px rgba(30, 37, 46, 0.2);
		cursor: default;
		z-index: 1000;

		&--editing {
			visibility: visible;
			opacity: 1;
		}
	}

	&__header {
		align-items: center;
	}

	&__action-buttons .Button {
		width: 100px;
	}

	&__multi-user {
		height: 34px;

		.Column {
			align-self: center;

			&:first-child {
				flex-grow: 0;
				padding-right: 15px;
			}

			.Text {
				line-height: 1em;
			}
		}

		.Avatar {
			display: block;
		}
	}
}

tr:hover {
	.InlineChangeHistory__button {
		opacity: 1;
		visibility: visible;
	}
}
