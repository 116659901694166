.ReportcenterDevTools {
	padding: 15px;
	display: block;

	.Row {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	.Text {
		margin-top: 10px;
	}

	.Progressbar {
		margin-top: 10px;
		width: 75%;
	}
}
