@import "~StyleVariables";

$cardHeight: 330px;
$cardWeight: 200px;
$gap: 20px;

.MailTemplateGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, $cardWeight);
	grid-auto-rows: $cardHeight;
	column-gap: $gap;
	row-gap: $gap;
	border-top: 1px solid $grey-6;
	margin: 0;
	padding: 20px;

	&__noResults {
		text-align: center;
		padding: 12px 0px;
		color: $grey-10;
	}
}

.MailTemplateGrid .TemplateCard {
	margin: 0;
	width: auto;
	height: auto;
}
