@import "~StyleVariables";

.IndustryInsightsDrawer {
    width: 800px;
    height: 100vh;
    background-color: $white;
    display: flex;
    flex-direction: column;

    /**
    * @todo  @upsales/components 2.19.29
    * STYLING: Tabs should have a min-height because sometimes the 50px height is not applied when using DrawerHeader
    * @link https://github.com/upsales/ui-components/issues/593
    */
    .DrawerHeader {
        position: sticky;
        top: 0;
    }

    .Tabs {
        min-height: 50px;
        border-bottom: 1px solid $grey-4;
    }

    .Drawer__children {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__contentWrapper {
        height: 100%;
    }
}
