@import "~StyleVariables";

.CreateTicket {
	width: 500px;

	.Drawer__children {
		height: 100%;
	}

	&__content {
		height: calc(100% - 50px);
		overflow-y: auto;

		.TextEditor {
			border: 1px solid $grey-4;
			border-radius: $border-radius;
			overflow: hidden;
		}
	}

	&__loader {
		display: flex;
		justify-content: center;
		padding-top: $space * 35;
	}

	form {
		height: 100%;
	}

	.FileUploadButtonWrapper {
		width: 20%;
		border-color: $grey-4;
	}

	.MailEditor__template-placeholder {
		width: 80%;
		border-color: $grey-4;
	}

	.CreateCallClientContactSelect {
		&:first-of-type:not(:last-of-type) {
			margin-bottom: $space * 4;
		}

		&:last-of-type {
			margin-bottom: unset;
		}
	}

	.CustomFields {
		margin-bottom: unset;

		&__content {
			display: flex;
			flex-direction: column;
			gap: $space * 4;
		}

		.FormComponent__message {
			display: none;
		}
	}

	hr {
		margin: 0px;
		border-color: $grey-6;
	}

	&__createTicket {
		z-index: 10;
		top: auto;
		position: sticky;
		bottom: 0;
		box-shadow: 0 0.5px 5px 0 rgba($black, 0.2);
	}

	/**
 		* @todo  @upsales/components 2.19.23
 		* STYLING: we should have a prop for 100% width
 		* @link https://github.com/upsales/ui-components/issues/580
 	*/
	.SplitButtonWithActions {
		width: 100%;

		.SplitButton {
			width: 100%;

			.Button:first-child {
				flex-grow: 2;
			}
		}
	}
}