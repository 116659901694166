@import "~StyleVariables";

.GroupTreeColumn {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 120px;

	.connect-btn {
		.Icon {
			margin-right: 5px;
		}
	}

	.loader {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		span {
			margin-left: 4px;
		}

		.Loader {
			margin: 0px;
		}
	}

	.fa {
		color: $grey-10;
	}

	.grey-text {
		color: $grey-10;
	}

	.show-on-row-hover {
		display: none;
	}

	.hide-on-row-hover {
		display: initial;
	}

	&:hover {
		.show-on-row-hover {
			display: initial;
		}

		.hide-on-row-hover {
			display: none;
		}
	}
}
