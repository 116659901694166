.DashboardPinnedComments {
	.HistoryLog__line {
		height: 47px;
	}

	.TimelineCard {
		margin-bottom: 0px;
	}
	&__first-td, .icon-td {
		width: 50px;
	}
	&__title {
		padding-top: 15px;
		font-size: 20px;
	}
	table td .icon.comment {
		border: none !important;
	}

	table {
		width: stretch;
	}

}
