@import "~StyleVariables";

.CriteriaTable {
	border-radius: 2px;
}
.CriteriaTable > table {
	margin-bottom: 0px;
}

.CriteriaTable__header {
	padding: 16px;
	font-size: 18px;

	.fa-plus-circle {
		color: $medium-green;
		margin-right: 10px;
	}

	.fa-minus-circle {
		color: $red;
		margin-right: 10px;
	}
}
