@import "~StyleVariables";
.QuickCreateTodoCard {
	padding-bottom: 12px;

	&__related-todos .OpportunityTodoRow {
		height: 45px;
	}
	&--create-row {
		display: flex;
		align-items: center;
		height: 40px;
		margin-bottom: 0px;
		.OpportunityEditTodoRow__text {
			padding-left: 0;
		}
	}

	> .Title {
		margin-bottom: 8px;
		margin-left: 12px;
	}
	.FakeTooltip {
		top: 40px !important;
		box-shadow: none !important;
	}
}
