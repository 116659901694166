@import "~StyleVariables";

.TopList {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&__content {
		margin-top: -100px;
		width: 600px;
		position: relative;
	}

	&__item {
		height: 64px;

		.Column:first-of-type {
			display: flex;
			align-items: center;
			justify-content: center;
			.Icon {
				font-size: 30px;
			}
		}
	}

	&__trophies {
		position: absolute;
		left: 0;
		bottom: 0;
	}
}
