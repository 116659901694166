.ScheduleMail {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__schedule {
		width: 100%;
		.Text {
			margin-bottom: 20px;
		}
		.Button {
			width: 100%;
		}
	}

	&__dateAndTimeInput {
		display: inline-flex;
		width: 100%;
		margin-bottom: 10px;
		.TimeInput {
			flex: 1;
		}
		.DateInput {
			flex: 2;
			.DateInput__popup {
				z-index: 1001;
			}
		}
	}

	&__cancelButton {
		height: 28px;
		margin-top: 4px;
	}
}
