@import "~StyleVariables";

$innerMaxWith: 600px;
$innerPadding: 15px;

.Notes {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: $white;
	font-family: "Roboto", sans-serif;
}

.Notes__messages {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 66px;
	overflow: hidden;
	overflow-y: auto;
	padding: 15px 0;
}

.Notes__compose {
	left: 0;
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: $grey-1;
	padding: $innerPadding;
	box-shadow: 0 -1px 2px 0 rgba($black, 0.2);
}

.Notes__inner-wrapper {
	max-width: $innerMaxWith;
	margin: 0 auto;
	height: 100%;
}

.Notes__inner-wrapper > .Row {
	height: 100%;
}

.Notes__inner-wrapper > .Row > .Column {
	position: relative;
}

.Notes__send {
	width: 34px;
	height: 34px;
	border-radius: 50%;
	padding: 0;
}

.Notes__separator {
	position: relative;
	text-align: center;
	color: $grey-13;
	font-size: 14px;
	font-weight: $boldFontWeight;
	line-height: 17px;
	margin-bottom: 15px;
}

.Notes__separator-inner {
	position: relative;
	display: inline-block;
	margin: 0 auto;
	background-color: $white;
	padding: 0 10px;
	line-height: 20px;
	z-index: 1001;
}

.Notes__note {
	padding: 0 15px;
}

.Notes__separator:before {
	content: "";
	z-index: 1000;
	background-color: $grey-4;
	position: absolute;
	height: 1px;
	left: 0;
	right: 0;
	top: 50%;
}

.Notes__day-group {
	margin-bottom: 15px;
}

.Notes__sender {
	height: 30px;
	line-height: 30px;
	position: relative;
}

.Notes__sender-time {
	color: $grey-11;
	display: inline-block;
	font-size: 12px;
	font-weight: $regularFontWeight;
	margin-left: 10px;
}

.Notes__sender-name {
	display: inline-block;
	color: $grey-13;
	font-size: 14px;
	font-weight: $boldFontWeight;
	line-height: 17px;
	margin-left: 30px;
}

.Notes__avatar {
	display: inline-block;
	position: absolute;
	top: 5px;
}

.Notes__message {
	word-break: break-all;
	word-wrap: break-word;
	white-space: pre-wrap;
}

.Notes__no-notes {
	position: absolute;
	top: 50%;
	text-align: center;
	width: 100%;
}

.Notes__loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -50px;
	margin-top: -50px;
	text-align: center;
}

.Notes__compose--fixed-height {
	height: 309px;
}

.Notes__compose--fixed-height .Notes__textarea .Textarea__input {
	height: 100% !important;
	overflow: initial;
}

.Notes__compose--fixed-height .Notes__textarea {
	height: initial;
	top: 0;
	bottom: 0;
	position: absolute;
	right: 0;
	left: 0;
}

.Notes__textarea-hidden {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: -9999px;
	left: -9999px;
}
