@import "~StyleVariables";

.AdminAnonymization #admin-page-anonymization {
	background-color: $grey-2;

	#list {
		padding-bottom: 20px;

		.header-button {
			float: right;
			margin-top: -4px;
			margin-right: 16px;
		}

		.up-paginator-wrap {
			display: block;
			margin-top: 10px;
			text-align: center;
		}
	}

	.no-padding {
		padding: 0 !important;
	}

	.card {
		margin: 20px 40px;
		padding: 20px 0;
		background: $white;
		box-shadow: 0 1px 2px rgba($black, 0.2);
		border-radius: 4px;

		&.fields {
			padding: 20px 0 0;

			.Help {
				margin-left: 4px;
				display: inline-block;
			}
		}

		h2,
		h3 {
			margin: 0;
			line-height: 1.3em;

			b {
				padding-left: 5px;
			}
		}

		h2 {
			margin-bottom: 15px;
			padding: 0 20px;
			line-height: 32px;
		}

		.list-of-checks {
			margin-top: 20px;
			padding: 0 20px;
		}

		.checker-item {
			margin-bottom: 20px;

			.text-content {
				p {
					color: $grey-11;
					font-size: 14px;
					font-weight: $regularFontWeight;
					letter-spacing: -0.1px;
					line-height: 22px;
				}
			}
		}

		.main-paragraph {
			padding: 0 20px;
			margin-bottom: 8px;

			span.important {
				font-weight: $boldFontWeight;
				text-decoration: underline;
				color: $grey-13;
				margin: 0 4px;
			}
		}

		.subheader {
			padding: 0 20px;
		}
	}

	.regular-table-title {
		color: $grey-11;
		font-size: 18px;
		font-weight: $regularFontWeight;

		b.fa {
			font-size: 14px;
		}
	}

	.checkbox-holder {
		float: left;
		margin-right: 15px;
		padding-bottom: 2.3em;
		margin-top: 4px;
	}

	.huge-button-full-width {
		margin-bottom: -20px;
		font-size: 18px;
		border-radius: 0;
	}

	table {
		width: 100%;

		tr {
			border-bottom: 1px solid rgba($black, 0.05);

			.up-toggle {
				width: 16px;
				font-size: 16px;
				height: 16px;
			}

			th {
				height: 30px;
				font-size: 12px;
				text-transform: uppercase;
				font-weight: $regularFontWeight;
				color: $grey-11;

				&.sortable-th {
					cursor: pointer;

					&.sorting-active {
						color: $bright-blue;
					}
				}

				.sorting-indicator {
					margin-left: 5px;
				}

				&:first-child {
					padding-left: 20px;
					min-width: 50px;
				}

				&:last-child {
					padding-right: 20px;
				}

				&.dropdown-checkmark {
					.dropdown-menu {
						li {
							text-transform: initial;
							display: block;
							padding: 3px 20px;
							clear: both;
							font-weight: normal;
							line-height: 1.42857;
							color: $grey-13;
							white-space: nowrap;
							cursor: pointer;
							background-color: $white;
							transition: all 0.3s;

							&:hover {
								background-color: $medium-blue;
							}
						}
					}
				}
			}

			td {
				height: 50px;

				&:first-child {
					padding-left: 20px;
					min-width: 50px;
				}

				&:last-child {
					padding-right: 20px;
				}

				&:nth-child(4) {
					min-width: 100px;
				}

				&:nth-child(3) {
					padding-right: 20px;
				}

				i.grey {
					font-size: 13px;
				}

				.lead-contact {
					a {
						color: $grey-13;
					}

					.lead-contact-account {
						a {
							font-size: 12px;
							color: $grey-11;
						}
					}
				}

				.account-manager {
					.generic-avatar {
						margin-top: 1px;
						margin-right: 10px;
					}

					> span {
						display: block;
						line-height: 1.1em;
					}
				}
			}
		}
	}
}
