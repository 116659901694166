@import "~StyleVariables";

.RiskWidgetCompanyCard {
	transition: background-color 0.2s ease-in;
	border-top: 1px solid $grey-4;
	border-bottom: 1px solid $grey-4;
	cursor: pointer;

	.DistributionBarWidget {
		transition: background-color 0.2s ease-in;
	}

	&:hover {
		background-color: $grey-1;

		.DistributionBarWidget {
			background-color: $grey-1;
		}
	}

	.ReportcenterWidget {
		width: 100%;
		&__content {
			border: none !important;
		}
	}

	.DistributionBarWidget {
		&__infoColumn {
			margin-left: $space * 4 !important;
			margin-right: $space * 4 !important;

			.Text {
				white-space: normal;
			}
		}
	}
}
