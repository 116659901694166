.attachment-library-section {
	position: relative;
	padding: 0;
	// background-color: $grey-4;
	min-height: 500px;

	.guide-section-header {
		margin-bottom: 0 !important;
	}
}

.attachment-library {
	position: relative;
	background-color: $grey-4;
	min-height: 500px;

	.att-lib-top {
		display: block;
		padding-top: 10px;
		margin-bottom: 30px;
		padding: 10px 0;
		border-bottom: 1px solid $grey-8;
	}

	.att-lib-main {
		padding: 20px;

		.att-lib-row {
			margin-top: 10px;

			p {
				font-size: 10px;
				margin-bottom: 0;
			}
		}

		.creative-wrapper {
			position: relative;
			display: inline-block;
			margin: 4px 2px;
			background-color: $grey-11;
			@include box-shadow(1px 1px 2px rgba($black, 0.1));
			@include transition(all ease-out 200ms);

			&:hover {
				@include box-shadow(1px 1px 3px rgba($black, 0.2));
			}

			.cw-overlay {
				@include transition(all ease 200ms);

				.cw-overlay-icon {
					display: none;
					color: $white;
					margin-left: auto;
					margin-right: auto;
					text-align: center;
					font-size: 32px;
				}

				.cw-creative-title {
					color: $white;
					text-align: center;
				}

				&:hover {
					background-color: rgba($black, 0.4);

					.cw-overlay-icon {
						display: block;
					}

					.cw-creative-title {
						display: block;
					}
				}
			}

			.cw-content {
				position: absolute;
				top: 0;
				left: 0;
			}

			.cw-loader {
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				bottom: 0;
				text-align: center;
				top: 0;
				font-size: 20px;
				color: $grey-13;
			}
		}

		.no-content-placeholder {
			color: $grey-8;
			text-align: center;
			font-size: 16px;
			background-color: $grey-4;
		}

		// THIS THIS THIS
		&.creative-details-open {
			.cw-overlay {
				background-color: rgba($grey-4, 0.6);
				@include transition(all ease-out 200ms);

				&:hover {
					background-color: rgba($grey-4, 0.5);

					.cw-overlay-icon {
						display: none;
					}
				}

				&.selected-creative {
					background-color: transparent;
				}
			}
		}
	}

	.att-lib-loader {
		text-align: center;
		padding-top: 200px;
		color: $grey-8;

		.fa {
			margin-bottom: 20px;
			font-size: 20px;
		}
	}
}

.attachment-lib-info {
	position: absolute;
	background-color: $grey-1;
	width: 33%;
	min-height: 300px;
	padding: 20px 20px 20px 30px;
	top: 0;
	right: 0;
	bottom: 0;
	@include box-shadow(-8px 0px 8px -4px rgba($black, 0.15));

	h2 {
		margin-bottom: 20px;
		margin-top: 32px;
	}

	h4 {
		margin-bottom: 20px;
	}

	.att-lib-script-check {
		color: $grey-10;
		margin-left: 4px;

		.fa.fa-link {
			color: $bright-blue;
		}

		.fa.fa-chain-broken {
			color: $grey-11;
		}

		.fa.fa-times,
		.fa-exclamation-triangle {
			color: $orange;
		}
	}

	.att-file-info {
		p {
			color: $grey-11;
			font-size: 12px;
			margin-bottom: 5px;

			b {
				color: $grey-13;
			}
		}
	}

	.up-btn.att-lib-close-btn {
		background-color: transparent;
		float: right;
		color: $grey-8;
		box-shadow: none;
		border: 0;
	}

	.attachment-link-url {
		display: block;
		overflow: hidden;
		padding-right: 10px;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}
}
