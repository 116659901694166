.OptOutLangSection {
	padding: 14px;
	background-color: #ffffff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid #eee;
}

.OptOutLangSection__title.Title {
	display: inline-block;
	line-height: 30px;
	margin-bottom: 10px;
}

.OptOutLangSection__default-lang {
	display: inline-block;
	margin-left: 10px;
	color: #6b7c93;
	font-size: 12px;
	line-height: 14px;
}

.OptOutLangSection__input-row {
	margin: 0 -7px;
	padding-right: 60px;
	position: relative;
}

.OptOutLangSection__input-row:after {
	content: '';
	display: table;
	clear: both;
}

.OptOutLangSection__input-wrap {
	display: inline-block;
	width: 50%;
	padding: 0 7px;
}

.OptOutLangSection__input-title {
	color: #2f3947;
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 5px;
	position: relative;
}

.OptOutLangSection__input-remove {
	position: absolute;
	right: 0;
	width: 60px;
	bottom: 0;
}

.OptOutLangSection__progress.Progress {
	width: 15px;
	position: absolute;
	right: 0;
	top: 0;
}
