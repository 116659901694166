@import "~StyleVariables";

.BarchartWidgetBars {
	position: relative;
	height: 40px;

	&__small {
		height: 25px;
	}

	&__goal {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 3px;
		border-radius: 2px;
		background-color: $grey-8;
		border: 1px solid $white;
		box-sizing: content-box;
		margin-left: -1px;
		z-index: 1000;

		&--reached {
			background-color: $medium-green;
			border-left: 0;
			border-right: 0;
		}
	}

	.Tooltip {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1001;
		bottom: 0;
		min-width: 0.2%;
	}

	&__floating-value {
		position: absolute;
		top: 0px;
		margin-left: 7px;
		bottom: 0;
		display: flex;
		justify-content: center;
		flex-flow: column;

		.Text {
			line-height: 11px;

			&--bright-blue {
				font-size: 10px;
			}
		}
	}

	&__sub-row-wrap {
		height: 100%;
		width: 100%;
	}

	&__zero-stripe {
		top: 0;
		width: 1px;
		height: 100%;
		z-index: 1000;
		position: absolute;
		border: 1px solid rgba($grey-10, 0.1);
		transform: translateX(-1px);
	}

	&__tooltip {
		text-align: left;
		
		.Text {
			&.tooltip-row {
				display: flex;
				align-items: baseline;
			}

			&.tooltip-row--center {
				align-items: center;
			}
		}
		.Icon {
			display: inline-block;
			margin-right: 4px;
		}
		.no-icon {
			margin-left: 17px;
		}
	}
}
