@import "~StyleVariables";

.ListViewSidebar {
	position: fixed;
	overflow-y: auto;
	width: 240px;
	height: calc(100% - #{$navbar-height} - #{$sub-menu-height});
	background-color: $grey-1;
	z-index: 13;

	.no-submenu & {
		height: calc(100% - #{$navbar-height});
	}

	hr {
		margin: 0;
	}

	&__header {
		cursor: pointer;
	}

	&__icon {
		animation: rotateBackAnimation;
		animation-iteration-count: 1;
		animation-duration: 400ms;
		transform: rotate(0deg);

		&--rotate {
			animation: rotateAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(180deg);
		}
	}
	&__titleColumn {
		width: 210px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__slideBlock {
		overflow: hidden;
	}

	&__listViewSidebarSection {
		&:nth-of-type(1) {
			z-index: 3;
		}
		&:nth-of-type(2) {
			z-index: 2;
		}
		&:nth-of-type(3) {
			z-index: 1;
		}
		position: relative;
	}
	.ClickableItem__right-col {
		padding-left: 0px;
	}

	.DropDownMenu {
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 300ms;
	}

	.DropDownMenu--expanded,
	.ClickableItem:hover .DropDownMenu {
		visibility: visible;
		opacity: 1;
	}

	.ClickableItem:hover:not(.ClickableItem--active) {
		.ClickableItem__right-col {
			box-shadow: inset -4px 0 darken($grey-3, 25%);
		}
	}

	.ClickableItem--active:hover {
		background-color: $white !important;
	}

	.ClickableItem--active:not(.ListViewSidebarDropDown__options) {
		.ClickableItem__right-col {
			box-shadow: inset -4px 0 $green;
		}
	}

	.Animate--visible {
		overflow: visible;
	}

	.DropDownMenu__content--addMinWidth {
		min-width: max-content;
	}
}
