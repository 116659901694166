@import "~StyleVariables";

.OpportunityRow {
	cursor: default;
	height: 50px;
	transition: none;
	&--updated {
		background-color: $super-light-blue;
	}

	&--clickable {
		cursor: pointer;
		transition: background-color ease-in-out 200ms;

		&:hover {
			background-color: $grey-1;
		}
	}

	&__iconTitle {
		position: absolute;
		top: 10px;

		font-size: 18px;
		line-height: 30px;
		width: 42px;
		text-align: center;
	}

	&__appointmentLink {
		margin-top: 7px;
		margin-left: 3px;
		margin-right: 3px;
		font-size: 14px !important;
	}

	.Text {
		display: inline-block;
		padding-top: 7px;
	}

	&__description {
		margin-left: 40px;
	}

	&__con {
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		transition: padding cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		padding-top: 8px;
	}

	&__CEOLink {
		margin-top: 7px;
		margin-right: 3px;
		font-size: 14px !important;
		margin-left: 40px;
	}

	&__decisionMakerName {
		margin-left: auto;
		margin-right: 16px;
	}

	&__descriptionDate {
		overflow: hidden;
		margin-left: auto;
		display: flex;
		flex-direction: column;

		&__date {
			margin-left: auto;
			margin-right: 16px;
			padding-top: 0 !important;
		}

		&__description {
			margin-top: 0px;
			margin-right: 16px;

			&--moveUp {
				margin-top: -8px !important;
			}
		}
	}

	&__noBtn {
		opacity: 0;
		position: absolute;
		right: 4px;
		top: 8px;

		.Icon {
			margin-right: 4px;
		}

		&--popUp {
			opacity: 1;
			pointer-events: none;
		}
	}

	.Checkbox {
		position: absolute;
		margin-top: 15px;
	}

	&__viewOptions {
		position: absolute;
		right: 10px;
		top: 8px;
		&--popUp {
			pointer-events: none;
		}
	}

	&__updated {
		position: absolute;
		right: 18px;
		top: 8px;
	}

	&__actions {
		opacity: 0;
		position: absolute;
		right: 10px;
		top: 8px;
		transition: opacity cubic-bezier(0, 0, 0.08, 1.27) 400ms;

		.Button:nth-of-type(2) {
			margin-right: 15px;
			margin-left: 15px;
		}
	}
}
.OpportunityRow:hover {
	.OpportunityRow__noBtn {
		opacity: 1;
	}

	.OpportunityRow__actions {
		opacity: 1;
	}
}
