@import "~StyleVariables";
@import "~StyleMixins";

.AdminEmailSignatures {
    #admin-content {
        padding: 30px;
        padding-bottom: 80px;
        max-width: 1000px;

        .Card {
            // TODO: This styling should be kept and moved to be used with Card ui-component when refactoring this view
            border: 1px solid $grey-6;
            border-radius: 4px;
            box-shadow: unset;
        }
    }
}