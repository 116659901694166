@import "~StyleVariables";
.CreateCall {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: $grey-1;
	overflow-y: auto;

	&__contentWrapper {
		width: 500px;
		display: flex;
		flex-direction: column;

		& > .Animate {
			z-index: 1000;
		}

		.ClientContactFollowupSelect {
			z-index: 2;
		}
	}

	&__toggleLabel {
		margin-bottom: 3 * $space;
		display: flex;
		align-items: center;
	}

	&__buttonWrapper {
		display: flex;
		flex-direction: column;
		top: auto;
		position: sticky;
		bottom: 0;
		margin-top: auto;
		z-index: 4;
		box-shadow: 0 0.5px 5px 0 rgba($black, 0.2);

		&--cannotSave {
			.Button:nth-child(2),
			.Tooltip:nth-child(2) .Button {
				height: 0;
				margin: 0;
				padding: 0;
				overflow: hidden;
			}
		}

		&--canSave {
			.Button:nth-child(2),
			.Tooltip:nth-child(2) .Button {
				margin-top: $space * 4;
				overflow: hidden;
			}
		}

		.Tooltip:nth-child(2) {
			line-height: 0; // makes the show-hide animation smoother
		}

		.Button {
			width: 100%;
		}
	}

	.DrawerHeader {
		position: sticky;
		top: 0;
		z-index: 4;
	}

	.TodoTimePicker {
		margin-bottom: 14px;
	}

	.Label {
		font-weight: $boldFontWeight;
	}
	.RelationSelect {
		margin-bottom: 15px;

		&__dropdown {
			z-index: 2;
		}
	}

	.NumberInput {
		.Input {
			width: 100%;
		}
	}
}
