@import "~StyleVariables";

.ListTab {
	&__Header {
		position: absolute;
		padding-top: 20px;
		z-index: 1001;
		width: fit-content;
	}
}

// This is for increased specificity
.ListTab.ListTab.ListTab {
	.Page__content-loader {
		margin-top: 70px;
	}

	.ListView {
		top: 0;
		left: 0;
		overflow-y: auto;
		overflow-x: hidden;

		.ListView__header {
			top: 0;
			border-radius: 0;
			height: 70px;
		}

		.ListView__footer {
			padding: 0;
		}

		.ListView__header > .Column:last-of-type {
			display: flex;
			flex-direction: row-reverse;
			margin-right: 2 * $space;
		}

		.TableHeader {
			top: 70px;
		}
	}
}
