@import "~StyleVariables";

.MailTemplateBrowserModal {
	background-color: $grey-4;
	width: 900px;
	top: 20px;

	#mail-template-chooser {
		.up-modal-header {
			height: 46px;
			background: $white;
			border-bottom: 1px solid $grey-7;

			h2 {
				height: 22px;
				line-height: 22px;
			}
			.template-total {
				position: absolute;
				top: 26px;
				left: 0;
				font-size: 12px;
				color: $grey-10;
			}

			.fa-times {
				cursor: pointer;
			}
		}
		.up-modal-controls {
			background-color: $white;
			border-top: 1px solid $grey-6;

			height: 57px;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.up-paginator-wrap {
			height: 24px;
		}

		.pagination {
			height: 24px;
			cursor: pointer;
		}

		.preview-image {
			background-size: cover !important;
		}
		.package-box.cover-img:hover .overlay {
			background: linear-gradient($green, $medium-green);
			opacity: 0.8;
		}

		.template-preview {
			display: inline-block;
			width: 23%;
			margin: 20px 1%;
			box-shadow: rgba($black, 0.1) 1px 1px 1px;
			height: 250px;
			margin-bottom: 20px;
			border: $grey-1 solid 1px;
			position: relative;
			background: $white;

			.template-preview-info {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($bright-blue, 0.8);

				.up-btn {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					width: 40%;
				}
			}
		}

		.package-box-title {
			max-width: 75%;
		}

		.overlay-white {
			background-color: rgba($white, 0.8);

			color: $grey-10;
			font-size: 14px;
			padding: 40px 10px;

			.fa {
				color: $grey-10;
				font-size: 40px;
				display: block;
				margin-bottom: 10px;
			}
		}

		.avatar {
			top: 0 !important;
			bottom: 0;
			margin: auto;
		}

		.generic-avatar {
			top: 0;
			bottom: 0;
			margin-top: auto !important;
			margin-bottom: auto;
		}

		.search-container {
			position: relative;
			top: 6px;
			width: 200px;
			height: 32px;
			margin: auto;
			display: inline-block;
			color: $grey-7;
		}

		.header-select {
			display: inline-block;
			width: 200px;

			.select2-choice {
				height: 31px !important;
			}
		}

		.search-bar {
			display: block;
			width: 100%;
			height: 100%;
			padding-left: 36px;
			line-height: 16px;
			color: $grey-7;
			outline: none;
			border-radius: 4px;
			background: $grey-4;
			border: none;
			font-size: 12px;
		}

		.search-container > .fa-search {
			position: absolute;
			left: 15px;
			top: 9px;
		}

		.right-tools {
			position: absolute;
			top: 6px;
			right: 15px;
		}

		.left-tools {
			position: absolute;
			top: 0;
			left: 15px;
			line-height: initial;

			> .btn-group {
				top: 5px;
				position: relative;
			}

			h2 {
				line-height: 30px;
			}
		}

		.sort-select {
			cursor: pointer;
			background: transparent;
			border: none;
			outline: none;
			margin-left: 10px;
		}

		.btn-group {
			margin-left: 15px;
			margin-right: 15px;
		}

		.up-modal-content {
			min-height: 200px;
			padding: 15px;
			&::after {
				content: "";
				display: table;
				clear: both;
			}
			&.no-padding {
				padding: 0;
			}
		}

		.no-templates {
			color: $grey-10;
			font-size: 20px;
			text-align: center;
			position: absolute;
			top: 90px;
			left: 0;
			right: 0;
		}
	}

	#mail-template-chooser .main-table tr > td:last-child > div {
		padding-right: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
