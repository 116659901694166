@import "../../../styles/variables";

.SocialEventSideBar__Title {
	margin-bottom: 26px;
}

.SocialEventSideBar__check-in-info {
	padding-right: 30px;
	border-right: $grey-6 solid 1px;
}

.SocialEventSideBar__check-in-pin {
	flex-grow: 0;
	margin: auto;
	padding: 0 5px 0 15px;
}

.SocialEventSideBar__location-image {
	border-radius: 5px;
	border: 1px solid #cad2dd;
}

.SocialEventSideBar__contactStats {
	cursor: pointer;
}

.EditSocialEvent__Sidebar__social-media {
	cursor: pointer;
}

.SocialEventSideBar__location-text {
	overflow: hidden;
}
