@import "~StyleVariables";

.CreateTemplateFlowSelection {
	margin-top: 100px;
	width: 616px;

	&__inline-text {
		display: inline;
	}

	.Select {
		display: inline;
		margin-left: 8px;
		margin-right: 4px;
	}

	&__select-dropdown {
		width: 300px;
	}

	.Select__focus-input--visible {
		width: 200px;
	}

	&__inline-date {
		display: inline-block;
		width: 120px;
		margin-left: 8px;
		vertical-align: bottom;

		& > div,
		.Input,
		.DateInput__inner,
		.Input__input {
			display: inline;
		}

		.Input__input {
			padding: 0;
			height: auto;
			cursor: pointer;
			color: $bright-blue;
			outline: 0;
			box-shadow: none;
			border-radius: 0;
			border: none;
			font-size: 18px;
			line-height: 22px;
		}

		input:hover {
			text-decoration: underline;
		}
	}
}
