@import "~StyleVariables";
.SubscriptionsOrderSettings {
	&__earlyCreation .ButtonSelect {
		margin-top: 12px;
	}
	&__period-dates .Row {
		gap: 18px;
	}
	&__setup-fields-text {
		.Text,
		.Link {
			display: inline;
		}
	}
}
