@import "~StyleVariables";

.EditSocialEventType__Radio {
	display: flex;
}

.EditSocialEventType__Radio-item {
	flex: 1;
	position: relative;
	margin-bottom: 30px;
	padding: 10px 0;
	background-color: $white;
	border: 1px solid $grey-6;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba($black, 0.1);
	cursor: pointer;
}
.EditSocialEventType__Radio-item--active {
	border: 1px solid $green;
}
.EditSocialEventType__Radio-item--disabled {
	cursor: not-allowed;
	font-style: italic;
}

.EditSocialEventType__Radio-item .EditSocialEventType__Check-circle {
	position: absolute;
	top: 10px;
	right: 10px;
}

$wrapperWidth: 28px;
.EditSocialEventType__Check-circle {
	width: $wrapperWidth;
	height: $wrapperWidth;
	line-height: $wrapperWidth - 2px;
	border-radius: 100%;
	border: 1px solid $grey-6;
}

.EditSocialEventType__Check-circle .Icon {
	width: 100%;
	text-align: center;
}

.EditSocialEventType__Check-circle--blue {
	background-color: $green;
}

.EditSocialEventType__Check-circle--success {
	background-color: $bright-green;
}

.EditSocialEventType__Check-circle--warning {
	background-color: $bright-orange;
}

.EditSocialEventType__Check-circle--error {
	background-color: $red;
}

.EditSocialEventType__Radio-item .EditSocialEventHouse,
.EditSocialEventLaptop {
	display: flex;
	height: 80px;
	margin-bottom: 10px;
}

.EditSocialEventType__Info-wrapper {
	height: 100%;
	margin-left: 20px;
	padding-left: 16px;
	border-left: 4px solid $grey-6;
}

.EditSocialEventType__Info-list {
	color: $grey-10;
	padding-inline-start: 18px;

	li {
		margin-top: 6px;
	}
}

.EditSocialEventType__Copy-link {
	cursor: pointer;

	&:hover {
		color: $bright-blue;
	}
}

.EditSocialEventDetails__Webinar-provider {
	display: flex;
	height: 80px;
	background-color: $white;
	border: 1px solid $grey-6;
	border-radius: 10px;
	box-shadow: 0 2px 4px 0 rgba($black, 0.2);
	margin-left: 10px;
	cursor: pointer;

	img {
		width: 100%;
	}

	.Text,
	img {
		margin: auto;
	}
}

.EditSocialEventDetails__Webinar-provider--active {
	border: 1px solid $green;
}

.EditSocialEventDetails__Webinar-provider-image {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin: 10px;
	width: 100%;
}

.EditSocialEventDetails__Webinar-provider-name {
	width: 80px;
	margin-top: 8px;
	margin-left: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
