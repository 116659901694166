@import "../MultiActionModal.scss";

@include ModalTagList("MultiUpdateActivity");

.MultiUpdateActivity {
	.ModalHeader {
		border-top: none;
	}

	.ModalContent__content, .CustomFields__content {
		display: grid;
		grid-template-columns: 49% 1fr;
		column-gap: 12px;
		row-gap: 4px;

		& > .col-span2 {
			grid-column: span 2;
		}

		.PlanPhonecallsDrawerCallListSelect {
			.Row .Column {
				.Text {
					font-weight: 400;
				}

				flex-basis: unset;
			}
		}
	}

	&__trigger-date {
		gap: 4px;

		.Block {
			flex-grow: 1;

			&:first-of-type {
				width: 60%;
			}

			&:last-of-type {
				width: 30%;
			}
		}
	}

	.ModalControls {
		z-index: 1;
	}

	.CustomFields .Label {
		font-weight: unset;
	}

	.CustomFields__formGroupTitle {
		margin-bottom: 8px;
	}

	&__notes .Textarea__input {
		height: 148px;
	}
}