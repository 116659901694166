@import "~StyleAnimations";
@import "~StyleVariables";
@import "~StyleMixins";

.AdminTriggers {

    #admin-content {
        padding: 30px;
        padding-bottom: 80px;
        max-width: 1000px;

        h2 {
            color: $grey-11;
            margin: 0;
            margin-bottom: 10px;
            font-weight: normal;
            font-size: 18px;
            display: block;
        }

        label {
            display: block;
            font-weight: $boldFontWeight;

            &.inline {
                display: inline-block;
            }
        }

        .admin-info-row {
            position: relative;
            display: block;
            height: auto;
            overflow: hidden;
            margin: 0 20px;

            .info-row-content {
                float: none;
                width: auto;
                overflow: hidden;
                margin-bottom: 20px;

                &.no-info {
                    padding-right: 20px;
                }
                .Block {
                    &.profile-block {
                        display: flex;
                        align-items: center;
                        max-width: 80%;
                    }
                }
                .Avatar {
                    &.image-margin {
                        margin-right: 16px;
                    }
                }
            }

            .info-row-info {
                width: 200px;
                float: right;
                border-left: 4px solid $grey-4;
                padding: 10px;
                margin-bottom: 10px;
                margin-left: 20px;
                color: $grey-10;
            }

            &.admin-info-row-flex {
                display: flex;
                overflow: initial;

                .info-row-content {
                    flex: 1;
                    margin-right: 20px;
                    overflow: initial;
                }

                .info-row-info {
                    width: 198px;
                    float: initial;
                    margin-left: 0;
                }
            }
        }

        .admin-table-token {
            background: rgba($bright-blue, 0.2);
            padding: 3px 5px;
            color: $bright-blue;
            font-size: 12px;
            line-height: 16px;
            border-radius: $border-radius;
        }

        .admin-table-token-more {
            line-height: 20px;
            display: inline-block;
            font-size: 12px;
            margin-left: 4px;
            color: $bright-blue;
        }

        .admin-table-token + .admin-table-token {
            margin-left: 5px;
        }

        .admin-section {
            margin-bottom: 30px;
        }

        .admin-table,
        .admin-card {
            background-color: $white;
            position: relative;
            border-radius: $border-radius;
            display: block;
            margin-bottom: 20px;

            // TODO: This styling should be kept and moved to be used with Card ui-component when refactoring this view
            border: 1px solid $grey-6;

            &.has-info:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 218px;
                background-color: $grey-1;
            }

            .admin-card-top,
            .admin-table-top {
                vertical-align: middle;
                padding: 10px 20px;
                line-height: 50px;

                .no-line-height {
                    line-height: 1.5;
                }
                .invalid {
                    border: 1px solid $red;
                }

                &.table-top-flex {
                    display: flex;
                    flex-wrap: wrap;
                }

                .admin-card-title,
                .admin-table-title {
                    font-size: 18px;
                    display: inline-flex;
                    align-items: baseline;

                    &.table-title-flex {
                        flex: 2 0 auto;
                    }

                    &.admin-card-title-lg,
                    &.admin-table-title-lg {
                        font-size: 28px;
                    }
                    .admin-card-title-margin {
                        margin-right: 16px;
                    }
                }

                .admin-card-top-description,
                .admin-table-top-description {
                    color: $grey-11;
                    font-size: 14px;
                    font-weight: $boldFontWeight;
                    line-height: 17px;
                    display: block;
                }

                .table-search {
                    position: relative;

                    input {
                        height: 32px;
                        background-color: $grey-2;
                        border: none;
                        outline: none;
                        padding-left: 30px;
                        @include border-radius(3px);
                        @include transition(all ease 200ms);
                        width: 150px;

                        &:focus {
                            @media (min-width: 1200px) {
                                width: 200px;
                            }
                            background-color: $grey-4;
                        }
                    }

                    .fa-search {
                        position: absolute;
                        left: 10px;
                        top: 18px;
                        color: $grey-10;
                    }
                }
            }
        }

        .admin-card .admin-card-content {
            padding: 0 20px 20px 20px;
        }

        .admin-card .email-tracking {
            .admin-card-title {
                padding-bottom: 15px;
            }

            .description {
                padding-bottom: 20px;
                max-width: 60%;
            }

            .toggle-label-container {
                display: flex;
                align-items: center;
            }

            padding: 20px 30px;
        }

        .admin-table {
            &.has-info:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 218px;
                background-color: $grey-1;
            }

            .admin-card-top,
            .admin-table-top {
                vertical-align: middle;
                padding: 10px 20px;
                line-height: 50px;

                .admin-card-title,
                .admin-table-title {
                    font-size: 18px;
                }

                .table-search {
                    position: relative;

                    input {
                        height: 32px;
                        background-color: $grey-2;
                        border: none;
                        outline: none;
                        padding-left: 30px;
                        @include border-radius(3px);
                        @include transition(all ease 200ms);
                        width: 150px;

                        &:focus {
                            @media (min-width: 1200px) {
                                width: 200px;
                            }
                            background-color: $grey-4;
                        }
                    }

                    .fa-search {
                        position: absolute;
                        left: 10px;
                        top: 18px;
                        color: $grey-10;
                    }
                }
            }
        }

        .admin-card .admin-card-content {
            padding: 0 20px 20px 20px;
        }

        .table-no-result {
            text-align: center;
            line-height: 40px;
            height: 20px;
            font-size: 20px;
            color: $grey-8;
            position: relative;
            top: -20px;
        }

        .admin-table {
            div.table-wrapper > table,
            & > table {
                width: 100%;
                table-layout: fixed; // DO NOT CHANGE THIS
            }

            div.table-wrapper > table > thead > tr,
            & > table > thead > tr,
            .admin-table-header {
                border-bottom: 1px solid rgba($black, 0.05);

                & > th,
                .admin-table-cell {
                    height: 25px;
                    line-height: 25px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-transform: uppercase;
                    color: $grey-11;
                    font-weight: normal;
                    font-size: 12px;
                    padding-left: 20px;

                    &.sortable {
                        cursor: pointer;
                    }

                    .fa-caret-down {
                        visibility: hidden;
                        @include opacity(0);
                        @include transition(all ease 200ms);
                    }

                    &.active {
                        color: $bright-blue;

                        .fa-caret-down {
                            visibility: visible;
                            @include opacity(1);
                            @include transform(rotate(0deg));
                        }

                        &.desc .fa-caret-down {
                            @include transform(rotate(180deg));
                        }
                    }
                }
            }

            div.table-wrapper > table > tbody > tr,
            & > table > tbody > tr,
            .admin-table-row {
                cursor: default;
                border-bottom: 1px solid rgba($black, 0.05);
                background-color: $white;
                @include transition(height 0.5s);
                @include transition(background-color 0.2s);

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: $grey-3;
                    }
                }

                &.inactive-row {
                    background-color: $grey-2;
                    border-bottom: none;

                    & > td:first-child:before,
                    & > .admin-table-cell:first-child:before {
                        content: "";
                        border-left: 4px solid $grey-10;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                    }
                }

                &:hover {
                    .admin-row-tools-wrap .admin-row-tools {
                        visibility: visible;
                        @include opacity(1);
                        right: 0;
                    }
                }

                &.admin-table-standard-field-row {
                    background-color: $grey-1;

                    .Help {
                        display: inline;
                        margin-left: 4px;
                    }

                    &.clickable {
                        background-color: $white;

                        &:hover {
                            background-color: $grey-3;
                        }
                    }

                    &.active {
                        background-color: $white;
                    }
                }
            }

            .admin-table-group {
                background-color: white;

                .cheveron {
                    transition: transform 0.5s ease-in-out;
                    margin-left: 20px;
                }

                &[open] .cheveron {
                    transform: rotate(180deg);
                }

                .is-draggable-parent {
                    cursor: pointer;
                    display: inline;
                }

                .admin-table-group-title {
                    font-weight: $boldFontWeight;
                    align-self: center;
                    padding-left: 10px;
                    display: inline;
                }
            }

            .required-header {
                grid-column: 5 /7;
            }

            .table-btn {
                border: none;
                display: block;
                width: 100%;
                height: 45px;
                line-height: 45px;
                padding: 0;
                color: $grey-8;
                background-color: $grey-1;
                outline: none;
                text-align: center;
                @include transition(all ease 200ms);

                &:hover {
                    background-color: $grey-4;
                    color: $bright-blue;
                }
            }

            & > table > tbody > tr.two-lines > td,
            .admin-table-row.two-lines .admin-table-cell {
                line-height: 25px;
                height: 50px;
            }

            div.table-wrapper > table > tbody > tr > td,
            & > table > tbody > tr > td,
            .admin-table-cell {
                padding: 0 0 0 20px;
                line-height: 40px;
                font-size: 14px;
                line-height: 40px;
                height: 40px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                position: relative;

                .subtitle {
                    color: $grey-10;
                    font-size: 12px;
                }

                &.no-padding-right {
                    padding-right: 0;
                }

                &.delete-btn {
                    visibility: hidden;
                    color: $grey-10;
                    float: right;
                    margin-right: 20px;
                }

                &.delete-btn:hover {
                    color: $red;
                }
            }

            .admin-table-body {
                .ui-sortable-helper {
                    border-top: 1px solid rgba($black, 0.05);
                    box-shadow: 0 0 4px rgba($black, 0.2);
                }

                .edit {
                    line-height: 70px;
                    height: 70px;

                    .floating-label-input {
                        padding-top: 20px;

                        > label {
                            font-weight: $regularFontWeight !important;
                        }

                        &.has-value {
                            > label {
                                padding: 0;
                                bottom: 20px;
                            }

                            > input {
                                padding: 0;
                                border-bottom-color: $bright-blue;
                            }
                        }
                    }

                    .floating-label-select {
                        .select2-container {
                            box-shadow: none;
                            border-bottom: 1px solid $bright-blue;
                            border-radius: 0;

                            .select2-choice {
                                background: rgba($black, 0);
                                border: none;

                                .select2-arrow {
                                    border: none;
                                    height: 15px;

                                    > b {
                                        background-position: 0 -5px;
                                        background: rgba($black, 0);
                                        border: 4px solid rgba($black, 0);
                                        border-top-color: $bright-blue;
                                        width: 1px;
                                        height: 1px;
                                        margin-top: -2px;
                                        top: 50%;
                                        position: absolute;
                                        left: 50%;
                                        margin-left: -4px;
                                    }
                                }
                            }
                        }

                        &.has-value {
                            .select2-choice {
                                border-bottom-color: $bright-blue;
                            }
                        }
                    }
                }

                .admin-table-cell {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 0 0 20px;

                    &.edit-controls {
                        float: right;
                        padding-right: 20px;
                        height: 70px;

                        .btn {
                            float: right;
                            margin: 20px 0;
                        }

                        &:after {
                            clear: both;
                            content: "";
                            display: block;
                        }
                    }

                    & > .Help {
                        display: inline;
                        margin-right: 5px;
                    }
                }

                .admin-table-header {
                    .admin-table-cell {
                        height: 25px;
                        line-height: 25px;
                        padding: 0 0 0 20px;
                    }
                }

                .admin-table-row {
                    width: 100%;

                    .delete-btn:hover {
                        visibility: visible;
                    }

                    .admin-table-cell {
                        &.color-grey {
                            color: $grey-8;
                        }

                        &.hover-red:hover {
                            color: $red;
                        }
                    }
                }
            }

            .floating-label-select {
                > label {
                    top: 1px;
                    padding: 0;
                    font-weight: $regularFontWeight !important;
                    font-size: 0.8em !important;
                }

                .select2-container {
                    position: relative;
                    line-height: 40px;
                    height: 19px;

                    .select2-choice {
                        border: none;
                        height: 19px;
                        border-bottom: 1px solid $grey-8;
                        border-radius: 0;
                        margin: 0;
                        padding: 0;
                        line-height: 0;

                        .select2-chosen {
                            padding: 0;
                            position: relative;
                            z-index: 1001;
                            background-color: rgba($black, 0);
                            border: none;
                            width: 100%;
                            line-height: 1.5em;
                            color: $grey-13;
                            font-size: 12px;
                            text-overflow: ellipsis;
                            width: 80%;
                        }
                    }
                }
            }
        }

        .admin-table + .admin-table {
            margin-top: 30px;
        }

        .error-msg {
            color: $red;
        }
    }
}