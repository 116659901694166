@import "~StyleVariables";

.CalendarView {
	transition: right ease-out 100ms;

	&--filters-open {
		right: 315px !important;
	}

	&--no-sidebar {
		left: 15px !important;
	}

	&.Page--position {
		top: 82px;
		left: 48px;
		right: 0px;
	}

	.CalendarWeekday {
		border-bottom: 1px solid $grey-6;
	}

	.EasyBookingLinkDropdown {
		margin-top: 0;
	}

	&__SubHeaderWrapper {
		position: sticky;
		top: 82px;
		z-index: 5;
		background-color: $white;

		.CardHeader {
			padding: 0;
			display: flex;
			&__title {
				margin-left: 10px;
			}
		}
	}

	&__date-picker {
		&:hover {
			cursor: pointer;
			background-color: $grey-1;
		}
		width: 290px;
	}

	&__TodayButton {
		margin-left: 10px;
	}

	&__header-top {
		min-height: 45px;
	}

	&__DateWrapper {
		min-width: 180px;
	}

	&__CalendarWrapper {
		background-color: $white;
		max-height: calc(100vh - 97px - 56px - 112px);
		overflow-y: auto;
		overflow-x: hidden;

		.Calendar__Day {
			height: 135px;

			span {
				margin-left: 10px;
				display: block;
				text-align: left;
			}
		}
	}

	&__AddAppointment,
	&__CopyCalendarFeedHelp {
		margin-right: 16px;
	}

	.has-up-list-filters.filters-open & {
		margin-right: 300px;
	}

	#toggle-list-filters {
		line-height: 58px;
	}
}
