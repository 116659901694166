@import "~StyleVariables";

.AccountListBoardOfDirectorsCompanyCard {
	@media (min-width: 1550px) {
		padding: 5px;
		width: 570px;
		display: inline-block;
	}
	width: 100%;
	padding: 5px;
	.Flex {
		padding-left: 0 !important;
	}
	&__companyCard {
		margin-top: 1px;
	}
	&__topRight {
		.Icon {
			color: $grey-11;
			margin-right: 6px;
		}
		.Text {
			display: contents;
		}
		position: absolute;
		right: 35px;
		top: 16px;
	}
	&__topRightButton {
		.Icon {
			margin-right: 3px;
		}
		position: absolute;
		right: 10px;
		top: 10px;
	}
	&__informationRow {
		margin-top: 13px;
		padding-left: 0px !important;
		&__roles {
			white-space: nowrap;
			width: 175px;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	&__industry {
		padding-top: 10px;
		.Text--md {
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 400px;
			white-space: nowrap;

			@media (min-width: 1550px) {
				width: 200px;
			}
		}
	}
	&__innerCon {
		padding: 15px;
	}
	&__postTown {
		display: inline;
		margin-left: 5px;
	}
	.Title {
		text-overflow: ellipsis;
		max-width: 370px;
		overflow: hidden;
		white-space: nowrap;
	}
	&__orgNumber {
		display: block;
	}

	.Link:hover .Title {
		color: $bright-blue;
	}
}
