@import "../../../../styles/variables.scss";

.ContactSignals {
	&__mailRowText {
		max-width: 500px;
	}
	
	&__numericalIndicator {
		&__positive {
			background-color: $success-1;
			border-radius: $border-radius-sm;
		}
		&__negative {
			background-color: $danger-1;
			border-radius: $border-radius-sm;
		}
	}

	&__mailRow {
		height: 50px;
	}

	&__at {
		text-transform: lowercase;
	}

	.TableColumn {
		&--icon {
			width: 32px;
		}
	}
}
