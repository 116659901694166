@import "~StyleVariables";

.MailTemplateSelect {
	position: relative;
	z-index: 10;
	&__list {
		max-height: 390px;
		overflow: hidden;
		overflow-y: auto;
		height: 0px;

		.Loader {
			margin: 4px auto;
		}

		&--open {
			height: auto;
		}
	}
	&__dialog {
		box-shadow: inset 0 1px $grey-3;
		padding: 8px 12px;
		font-size: 12px;
		font-weight: bold;
		background-color: $grey-1;
	}
	&__result-item {
		transition: all 0.2s ease-out;
		display: flex;
		flex-direction: column;
		border-radius: 0;
		padding: 4px 16px;
		cursor: pointer;
		box-shadow: inset 0 1px $grey-3;
	}
	&__result-item:hover {
		background-color: $grey-3;
	}
	&__filters {
		padding: 12px;
		width: 100%;
		& > .ButtonSelect,
		& > .Input {
			width: 100%;
		}
		& .Input {
			margin-top: 8px;
			background-color: $grey-3;
			border-radius: $border-radius-lg;
		}

		input::placeholder,
		.Input__input,
		.Input__icon {
			color: $grey-13;
		}

		.Input__input {
			background-color: transparent;
		}
	}
	&__load-more-btn {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&__load-more-btn:hover {
		background-color: $grey-3 !important;
		box-shadow: $box-shadow-above-md;
	}
}
