@import "~StyleVariables";

.ConfirmBuyProspectModal {
    min-height: 571px;
    position: relative !important; // To override !important attrbute for positioning in animation fade for modals

    form {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow:1;
        
        .ModalContent {
            // To override initial overflow attribute for this specific modal
            // Made it in this scss file to avoid affecting if someone reuses 
            // ConfirmBuyProspect specifically.
            overflow-y: auto !important; 
        }

        .ModalControls {
            display: flex;
            background-color: $grey-2;
            justify-content: flex-end;
        }
    }
}