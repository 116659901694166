@import "~StyleVariables";

.SubscriptionHotspot {
	width: 400px;

	&--scheduling {
		width: 600px;
	}
}

.SubscriptionSchedulingHotspot {
	&__img-column {
		background-color: $grey-1;
		
		.Row {
			height: 100%;
		}
	}
}