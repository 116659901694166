@import "~StyleVariables";

.MailEditorShareActions {
	display: inline-block;
	position: relative;
    width: 100%;

    .Button {
        width: 100%;
    }
	&__dropdown {
		position: absolute;
		right: 0;
		width: 300px;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
        box-shadow: $box-shadow-xl;
	}

	&--visible &__dropdown {
		visibility: visible;
		opacity: 1;
        z-index: 1;
	}

    &__table-column {
        display: flex;
        align-items: center;
    }

	&__table-column--disabled {
		cursor: default;
		opacity: 0.6;
	}
}