@import "../../../../../styles/_variables.scss";

.EasyBookingSettings {
	.AdminHeader__text-wrap {
		padding-right: 40%;
	}
	#admin-page-easy-booking-settings-cover {
		background: linear-gradient(180deg, rgba($grey-3, 0.36) 0%, $grey-3 100%);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
	}

	#admin-content {
		position: relative;
		padding-bottom: 180px !important;
		margin-bottom: 50px;
		max-width: 1200px !important;
		padding: 30px 30px 80px;
	}

	.AdminHeader__image > div {
		bottom: -190px;
		top: -5%;
		width: 190%;
		left: 5%;
	}
	.admin-page-header {
		overflow: hidden;
	}

	&__loaderWrapper {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
	}

	&__addOn {
		.Title {
			font-weight: 600;
		}

		.Text {
			font-weight: 500;
		}

		.Row {
			align-items: center;
			margin-top: 15px;

			.link {
				margin-left: 18px;
			}
		}

		.add-shared-header {
			.Title {
				color: $blue;
			}
		}
	}
	&__inactiveInfoCard {
		padding: 20px;
		border-radius: 4px;

		.Title {
			font-weight: bold;
			margin: 24px 0 12px 0;

			&:first-child {
				margin-top: 0;
			}
		}
		.Text {
			white-space: pre-wrap;
		}
	}
}
