@import "~StyleVariables";

.GroupSubscriptionList {
	.ListView {
		&__footer,
		&__header {
			display: none;
		}
	}
}
