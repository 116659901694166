@import "~StyleVariables";
.TodoListSidebarItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	cursor: pointer;

	.Tooltip > div {
		max-width: 46px;
		overflow-x: visible;
	}
	.Title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&__title {
		p {
			margin-bottom: 0;
			width: 172px;
		}
		
		&--shared p {
			width: 148px;
		}
	}
	
	&__right-title {
		width: 35px;
		display: flex;
		justify-content: flex-end;
	}
	&:hover {
		background-color: $grey-3;
		transition: all ease-out 300ms;
		box-shadow: inset -4px 0 darken($grey-3, 25%);
	}
	&--selected,
	&--selected:hover {
		transition: all ease-out 300ms;
		background-color: white;
		box-shadow: inset -4px 0 $green !important;
		.Title {
			font-weight: $boldFontWeight;
		}
		.Title,
		.Text {
			color: $green;
		}
	}
}
