@import "~StyleVariables";

.AboutDropDown {
	cursor: pointer;
	padding: 4px 15px 10px 15px;
	margin-bottom: -10px;
	overflow: hidden;
	transition: all 200ms ease-in-out;
	border-radius: $border-radius;

	&--expanded {
		overflow-y: auto;
		max-height: 300px;
	}

	&__aboutText {
		padding-right: 4px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		line-clamp: 3;
		-webkit-line-clamp: 3;
		.Text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 200px;
		}
		&--expanded {
			line-clamp: unset;
			-webkit-line-clamp: unset;
		}
	}

	&__menu {
		display: inline-block;
	}
}

.AboutDropDown:hover {
	background-color: $grey-1;
}

.AboutDropDown--isSubaccount:hover:not(.AboutDropDown--isInactive) {
	background-color: #{darken($super-light-blue, 5%)};
}
