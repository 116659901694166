.ProspectingDrawer .CriteriaDrilldownWrapper .table-col div[class^="col-"] {
	padding-left: 15px;
	padding-right: 0;
}

.ProspectingDrilldown {
	.flex-row {
		display: flex;
		align-items: center;

		.drilldown-row {
			flex-grow: 1;
		}
	}

	.drilldown-row {
		display: inline-flex;

		.CriteriaRowWrapper {
			flex-grow: 1;
		}

		.CriteriaRowWrapper + .select2-container {
			margin-left: 15px;
			max-width: 160px;
		}
	}

	.table-col .drilldown-row {
		// Stupid SASS... https://github.com/sass/libsass/issues/2701
		max-width: unquote("min(700px, 100%)");
	}

	.col-lg-4 .Text {
		padding-top: 5px;
	}
}

.ProspectingDrilldown__pro-filter {
	display: inline-flex;
	margin-left: 15px;
}

@media (max-width: 1250px) {
	.ProspectingDrilldown__pro-filter {
		display: none;
	}
}

@media (min-width: 1950px) {
	.ProspectingDrilldown {
		.col-lg-4 .Text {
			padding-top: 0px;
			line-height: 17px;
		}
	}

	.ProspectingDrilldown__pro-filter {
		display: none;
	}
}
