.SubscriptionDifforder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	margin-top: 110px;

	&__container {
		animation: flyIn 0.45s cubic-bezier(0.53, 1.34, 0.79, 1.34);
		width: 776px;
	}
}

@keyframes flyIn {
	0% {
		opacity: 0;
		transform: translateY(-100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}
