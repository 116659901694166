@import "~StyleVariables";

.GoalsChangePeriod {
	left: 300px;
	z-index: 1700;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	background-color: $white;
	height: calc(100% - 15px);
	width: calc(100% - 315px);
	min-width: 600px;
	position: absolute;
	visibility: hidden;

	&--open {
		visibility: visible;
	}

	.GoalsChangePeriod__confirm-button {
		transition: all ease-in-out 400ms;
		transition-timing-function: cubic-bezier(0.8, 1.4, 0.8, 1);
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-width: 600px;
		max-height: 500px;

		.Headline,
		.Title {
			text-align: center;
		}

		.Button {
			margin-top: 20px;
			&:first-of-type {
				width: 100%;
				margin-top: 40px;
			}
		}
	}

	&__selection-cards {
		margin-top: 20px;
		.Column:first-of-type {
			margin-right: 20px;
		}

		.GoalsChangePeriod__period-card {
			position: relative;

			.GoalsChangePeriod__check {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $bright-blue;
				height: 20px;
				width: 20px;
				border-radius: 10px;
				position: absolute;
				top: 5px;
				right: 5px;
			}
		}

		.Card {
			border-radius: 6px;
			width: 290px;
			height: 220px;
			.Row {
				height: calc(100% - 130px);
				display: flex;
				align-items: center;
				justify-content: center;
			}
			img {
				pointer-events: none;
				width: 100%;
			}
			&:hover {
				border: 1px solid $bright-blue;
				box-shadow: 5px 10px 10px rgba($black, 0.1);
			}
		}
	}
}
