@import "~StyleVariables";

.MentionsInput {
	position: relative;

	.DraftEditor-root {
		padding: 5px 10px;
		font-size: 12px;
		line-height: 24px;

		.DraftEditor-editorContainer {
			z-index: 0;
		}
	}
	&__mention {
		background-color: $super-light-green;
		padding: 4px 4px;
		border-radius: $border-radius-sm;
	}

	&__mention-suggestions {
		background-color: $white;
		box-shadow: $box-shadow-lg;
		max-height: 200px;
		overflow: auto;
		z-index: 10001;

		&-entry,
		&-entry-focused {
			padding: 5px 15px;
			border-bottom: 1px solid rgba($black, 0.15);
			cursor: pointer;
		}
		&-entry-focused {
			background-color: $grey-3;
		}
	}
	.public-DraftEditor-content {
		overflow-wrap: anywhere !important;
	}
	.public-DraftEditorPlaceholder-root {
		pointer-events: none;
	}
}
