@import "~StyleVariables";
@mixin ontop {
	content:'';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

$firstColWidth: 200px;
$doubleGroupingColWidth: 130px;

.TableWidget {
	height: 100%;
	overflow: auto;

	&__tableHeader {
		position: sticky;
		// This, instead of just 'top: 0', eliminates jitter when a scroll bar is present
		top: 1px;
		transform: translateY(-2px);
		z-index: 4;
	}

	&__headerColumn {
		&:nth-of-type(1) {
			position: sticky;
			left: 0;
			z-index: 5;
		}

		&:nth-of-type(2) {
			position: sticky;
			left: $firstColWidth;
			z-index: 5;
		}
	}

	&__table {
		box-shadow: 0 0 2px rgba($black, 0.2);
	}

	&__info-row-dot {
		border-radius: 100%;
		width: 10px;
		height: 10px;
		margin-right: 8px;
	}

	&__cell {
		min-width: $doubleGroupingColWidth;
		background-color: $white;
		z-index: 2;

		.Text {
			display: flex;
			align-items: center;
		}

		&--selectable:hover {
			cursor: pointer;
			background-color: $grey-1;
		}

		&:nth-of-type(1) {
			background-color: $grey-1;
			min-width: $firstColWidth;
			width: $firstColWidth;
			max-width: $firstColWidth + $doubleGroupingColWidth;
			overflow: hidden;
			left: 0;
			position: sticky;
			z-index: 3;
		}

		&:nth-of-type(2) {
			position: sticky;
			left: $firstColWidth;
			z-index: 3;
		}

		&:last-of-type {
			padding-right: 15px;
		}

		&--emptyData{
			color: $grey-12;
		}

		&--distribution {
			width: 100%;
		}
	}

	&__cell--hasBarchart {
		width: 100%;
		padding-right: 16px;

		.Text {
			font-weight: normal;
		}
	}

	&--hasTotal {
		&__row:nth-last-of-type(2) .TableWidget__cell::before {
			@include ontop;
			height: 1px;
			top: auto;
			bottom: 0;
			background-color: $grey-8;
		}
	
		&__totalRow {
			position: sticky;
			bottom: 0px;
			border-top: 3px rgba($black, 0.3);
			z-index: 3;
	
			.TableWidget__cell {
				background-color: $white;
			}
		}
	}

	.TableHeader__column:after {
		bottom: -1px;
	}

	&--scrolledLeft {
		// Hack to get around shadows not showing over table elements
		.TableWidget__row .TableWidget__cell:nth-of-type(2)::after {
			@include ontop;
			box-shadow: 0 0 4px rgba($black, 0.2);
			clip-path: inset(0px -15px 0px 1px); // only show the shadow on the right side
		}

		// Shadows on header and totals are less prominent
		.TableHeader__column:nth-of-type(2)::before {
			@include ontop;
			box-shadow: 0 0 2px 0px rgba($black, 0.15);
			clip-path: inset(0px -15px 0px 1px);
		}
		.TableWidget__totalRow .TableWidget__cell:nth-of-type(2)::after {
			@include ontop;
			box-shadow: 0 0 2px rgba($black, 0.2);
			clip-path: inset(0px -15px 0px 1px);
		}
	}

	&--scrolledTop .TableHeader {
		box-shadow: 0 0 6px rgba($black, 0.2);
	}

	&--scrolledBot .TableWidget__totalRow {
		box-shadow: 0 0 4px -1px rgba($black, 0.2);
	}
}