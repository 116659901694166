@import "~StyleVariables";

.ReportcenterCreateDashboardDropdown {
	.DropDownMenu__content {
		top: -5px;
		z-index: 1005;
		text-align: left;
		padding: 10px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		border: 1px solid $grey-4;

		.Label {
			font-weight: $boldFontWeight;
			margin-top: 15px;
		}

		.Row {
			margin-top: 15px;
		}
	}
}
