@import "~StyleVariables";

.UtmLinkGenerator {
	width: 500px;
	height: 100%;

	.Help {
		color: $grey-11;
	}

	.Select.is-disabled .Select-placeholder {
		background-color: $grey-4;
	}

	.Textarea__input {
		line-height: 17px;
		margin-bottom: 10px;
		padding: 10px;
	}

	&__Content {
		width: 500px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: calc(100% - 50px);
		overflow-y: auto;
		background-color: $white;

		.Column {
			padding: 0 5px;
		}

		.Title {
			font-size: 16px;
			font-weight: $boldFontWeight;
			margin-left: 10px;
		}

		.Label {
			font-weight: $boldFontWeight;
		}
	}

	&__Fields {
		padding: 15px 20px;

		> .Row {
			padding-bottom: 15px;
		}
	}

	&__UtmCode {
		.Icon {
			margin-top: 10px;
		}
	}
}
