.CopySocialEvent {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.CopySocialEvent__inner {
	width: 400px;
	text-align: center;
	margin: auto 0;
}

.CopySocialEvent__title {
	margin-bottom: 20px;
}

.CopySocialEvent__text {
	margin-bottom: 30px;
}

.CopySocialEvent .EditSocialEventCopySvg {
	margin-bottom: 20px;
}
