@import "~StyleVariables";

.LandingPagePreview-area {
	position: relative;
	width: 100%;
	height: 100%;

	&--isLoading {
		.LandingPagePreview-area__Device-wrapper {
			top: 100%;
		}
	}

	&__Device-wrapper {
		position: relative;
		top: 50px;
		transition: top cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		will-change: transform;
	}

	.device {
		position: absolute;
		transform: scale(1);
		z-index: 1;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		top: 0;
	}

	.device-google-pixel {
		height: 904px;
		width: 470px;
		transform: scale(0.31) !important;
		top: 50px;
		z-index: 10;
		box-shadow: 0 20px 20px rgba($black, 0.2);
		border-radius: 54px;

		.device-frame {
			background-image: linear-gradient(225deg, $grey-4 0%, $grey-6 100%);
			border-radius: 54px;
			box-shadow: inset 0 0 0 2px $grey-6, inset 0 0 0 6px $grey-4, inset 0 0 0 10px white;
			padding: 82px 18px 86px 18px;
			height: 904px;
			width: 470px;

			iframe {
				height: 100%;
				width: 100%;
			}
		}

		.device-stripe {
			border-top: 6px solid rgba($grey-13, 0.15);
			bottom: 0;
			left: 254px;
			position: absolute;
			top: 0;
			width: 8px;

			&:before {
				border: solid rgba($grey-13, 0.15);
				border-width: 0 6px;
				content: "";
				height: 10px;
				left: -254px;
				position: absolute;
				width: 470px;
				z-index: 9;
				bottom: 46px;
			}

			&:after {
				border: solid rgba($grey-13, 0.15);
				border-width: 0 6px;
				content: "";
				height: 10px;
				left: -254px;
				position: absolute;
				width: 470px;
				z-index: 9;
				bottom: 46px;
				top: 60px;
			}
		}

		.device-sensors {
			background: $grey-7;
			border-radius: 2.5px;
			height: 5px;
			left: 50%;
			margin-left: -39px;
			margin-top: -2.5px;
			position: absolute;
			top: 41px;
			width: 78px;

			&:before {
				height: 10px;
				left: -81px;
				margin-top: -5px;
				top: 50%;
				width: 10px;

				background: $grey-13;
				border-radius: 6px;
				content: "";
				position: absolute;
			}

			&:after {
				height: 12px;
				left: 50%;
				margin-left: -14px;
				top: 21.5px;
				width: 28px;

				background: $grey-13;
				border-radius: 6px;
				content: "";
				position: absolute;
			}
		}

		.device-btns {
			background: $grey-6;
			height: 102px;
			position: absolute;
			right: -2px;
			top: 298px;
			width: 3px;
		}

		.device-power {
			background: $grey-6;
			height: 50px;
			position: absolute;
			right: -2px;
			top: 184px;
			width: 3px;
		}
	}

	.device-macbook {
		height: 920px;
		width: 1480px;
		transform: scale(0.4) !important;

		.device-frame {
			iframe {
				height: 100%;
				width: 100%;
			}
		}

		.device-frame {
			background-image: linear-gradient(225deg, $grey-4 0%, $grey-6 100%);
			border-radius: 20px;
			box-shadow: inset 0 0 0 2px $grey-6;
			height: 920px;
			margin: 0 auto;
			padding: 58px 38px 78px 38px;
			position: relative;
			width: 1228px;
			box-shadow: 0 20px 20px rgba($black, 0.2);

			&:before {
				bottom: 10px;
				color: $grey-11;
				content: "Upsales Air";
				font-size: 24px;
				height: 32px;
				left: 50%;
				line-height: 16px;
				margin-left: -200px;
				position: absolute;
				text-align: center;
				width: 400px;
				z-index: 1;
			}

			&:after {
				background-image: linear-gradient(225deg, $grey-4 0%, $grey-6 100%);
				border-radius: 0 0 20px 20px;
				bottom: 4px;
				content: "";
				height: 52px;
				left: 4px;
				position: absolute;
				width: 1220px;
			}
		}

		.device-power {
			background: $grey-4;
			border: solid $grey-5;
			border-radius: 2px 2px 0 0;
			border-width: 0 8px;
			height: 8px;
			margin-top: -20px;
			position: relative;
			width: 1480px;
			z-index: 9;
			box-shadow: 0 20px 20px rgba($black, 0.2);

			&:before,
			&:after {
				content: "";
				position: absolute;
			}

			&:before {
				background: $grey-8;
				border-radius: 0 0 360px 360px/ 0 0 20px 20px;
				box-shadow: inset 0 -4px 12px 0 $grey-12;
				height: 20px;
				left: -8px;
				margin: 0 auto;
				top: 8px;
				width: 1480px;
			}

			&:after {
				background: radial-gradient(circle at center, $grey-4 0, $grey-4 85%, $grey-8 100%);
				border: solid $grey-7;
				border-width: 0 4px;
				height: 8px;
				left: 50%;
				margin-left: -120px;
				width: 240px;
			}
		}
	}

	.Loader {
		position: absolute;
		z-index: 5;
		left: calc(50% - 25px);
		top: calc(50% - 25px);
	}
}
