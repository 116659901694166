@import "~StyleVariables";

.TimelineIcon {
	width: 40px;
	position: relative;
	padding-top: 15px;

	.Icon {
		text-align: center;
		margin-bottom: 0;
		display: block;
		line-height: 30px;
		font-size: 18px;
	}

	&__iconCard {
		border-radius: 50%;
		background-clip: padding-box;
		width: 30px;
		height: 30px;
		z-index: 50;
	}

	&__line {
		border: 1px solid $grey-6;
		width: 1px;
		top: 44px;
		bottom: -27px;
		position: absolute;
		left: 14px;
		z-index: 0;
	}
}
