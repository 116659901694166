@import "~StyleVariables";
// AD-STUFF
.card {
	@include card();

	$guide-sidebar-width: 100%;
	$guide-sidebar-width1: 400px;
	$guide-sidebar-width2: 320px;
	$guide-sidebar-width3: 220px;

	$tools-width: 90px;

	#dashboard-header {
		height: 40px;

		.actions {
			.btn-group {
				padding: 6px 10px 0 0;
			}
		}
	}

	#card-main-view {
		#pre-table-tools-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 40px;
			background-color: $grey-3;
			padding: 7px 10px;

			label {
				font-weight: normal;
				color: $grey-10;

				.up-ios-toggle {
					margin-right: 5px;
				}
			}

			#qicksearch-wrap {
				margin-left: auto;
				width: 200px;
			}
		}

		table.main-table {
			width: 100%;
			table-layout: fixed;

			th [ng-click] {
				cursor: inherit;
			}

			th.sortable {
				cursor: pointer;
			}

			th .th-inner {
				white-space: nowrap;
			}

			.column-tools {
				width: $tools-width !important;
			}

			.column-tools > div {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			.column-tools .upsales-loader {
				margin-right: 8px;
			}

			tbody tr td {
				position: relative;

				&.overflow {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
				.ellipsis {
					top: 0;
					height: 34px;
					line-height: 34px;
				}
				.banner-preview-img + span {
					margin-left: 30px;
				}

				.banner-preview-img {
					$size: 20px;
					position: absolute;
					width: $size;
					height: $size;
					top: 50%;
					display: inline-block;
					margin-top: -($size / 2);
					border: 1px solid $grey-8;
					@include border-radius(3px);
					overflow: hidden;
					background-repeat: no-repeat;
					background-origin: center center;
					background-size: cover;
				}

				.adcampaign-list-button {
					display: inline-block;
					font-size: 14px;
				}
			}
		}

		.banner-table {
			th:nth-child(1) {
				width: 50%;
			}

			th:nth-child(2) {
				width: 110px;
			}

			th:nth-child(3) {
				width: 70px;
			}

			th:nth-child(4) {
				width: 110px;
			}

			th:nth-child(5) {
				width: 50%;
			}

			th:nth-child(6) {
				width: 130px;
			}

			th:nth-child(7) {
				width: $tools-width;
			}
		}

		.adcampaign-table {
			td:first-child {
				word-break: break-word;
			}

			th:nth-child(1) {
				width: 100%;
			}

			th:nth-child(2) {
				width: 140px;
			}

			th:nth-child(3) {
				width: 110px;
			}

			th:nth-child(4) {
				width: 130px;
			}

			th:nth-child(5) {
				width: 100px;
			}

			th:nth-child(6) {
				width: 110px;
			}

			th:nth-child(7) {
				width: 110px;
			}

			th:nth-child(8) {
				width: 100px;
			}

			th:nth-child(9) {
				width: 90px;
			}

			th:nth-child(10) {
				width: $tools-width;
			}
		}
	}

	&.has-guide {
		&.has-guide-sidebar {
			background-color: transparent;
			.up-header {
				right: $padding + $guide-sidebar-width1 !important;
				color: $white;
				line-height: $header-height;

				@media (max-width: $responsiveWidht1) {
					// 1420 px

					& {
						right: $padding + $guide-sidebar-width1 !important;
					}
				}

				@media (max-width: $responsiveWidht2) {
					// 1300 px

					& {
						right: $padding + $guide-sidebar-width2 !important;
					}
				}

				@media (max-width: $responsiveWidht3) {
					// 1168 px

					& {
						right: $padding + $guide-sidebar-width3 !important;
					}
				}

				@media (max-width: $responsiveWidht4) {
					// 1024 px

					& {
						right: $padding !important;
					}
				}
			}

			.guide-section {
				@include box-shadow(1px 1px 2px rgba($black, 0.05));
				margin-right: $guide-sidebar-width1;

				@media (max-width: $responsiveWidht1) {
					// 1420 px

					& {
						margin-right: $guide-sidebar-width1;
					}
				}

				@media (max-width: $responsiveWidht2) {
					// 1300 px

					& {
						margin-right: $guide-sidebar-width2;
					}
				}

				@media (max-width: $responsiveWidht3) {
					// 1168 px

					& {
						margin-right: $guide-sidebar-width3;
					}
				}

				@media (max-width: $responsiveWidht4) {
					// 1024 px

					& {
						margin-right: 0;
					}
				}

				// &:first-of-type {
				// 	padding-top: 40px;
				// }

				&:last-of-type {
					margin-bottom: 40px;
				}
			}

			#card-main-view {
				background-color: transparent !important;
			}

			.guide-summary {
				position: fixed;
				right: 0;
				top: $main-top;
				width: 100%;
				background-color: $grey-1;
				z-index: 1;
				width: $guide-sidebar-width1;
				@include border-radius($border-radius 0 0 $border-radius);
				@include box-shadow(1px 1px 2px rgba($black, 0.05));

				.up-header.guide-summary-header {
					right: 0 !important;
					@include border-radius($border-radius 0 0 0);
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					#title {
						line-height: 38px;
						padding-left: 10px;

						h2 {
							font-size: 16px;
							line-height: 40px;
						}
					}

					.label {
						position: absolute;
						left: 8px;
						bottom: 8px;
					}
				}

				.guide-summary-section {
					border-bottom: 1px solid $grey-4;
					font-size: 12px;

					.guide-sum-row {
						padding: 10px;

						&.valid {
							background-color: $white;
						}

						&.dates {
							width: 49%;
							display: inline-block;
							border-right: 1px solid $grey-4;

							&:last-of-type {
								border: none;
								padding-left: 10px;
							}
						}
					}

					.guide-summary-alert {
						display: inline-block;

						.red {
							color: $red;
						}
						.green {
							color: $bright-green;
						}
						.orange {
							color: $orange;
						}
					}

					&.last {
						background-color: $white;
						@include border-radius(0 0 0 $border-radius);
					}

					&.sum {
						font-size: 10px;
					}
				}

				.guide-summary-section.last {
					display: block;
					padding: 10px;
				}

				@media (max-width: $responsiveWidht1) {
					// 1420 px

					& {
						width: $guide-sidebar-width1;
					}
				}

				@media (max-width: $responsiveWidht2) {
					// 1300 px

					& {
						width: $guide-sidebar-width2;
					}
				}

				@media (max-width: $responsiveWidht3) {
					// 1168 px

					& {
						width: $guide-sidebar-width3;
					}
				}

				@media (max-width: $responsiveWidht4) {
					// 1024 px

					@include box-shadow(-2px -2px 3px rgba($black, 0.1));
					background-color: $white;

					& {
						width: $guide-sidebar-width;
						bottom: 0;
						top: auto;
						z-index: 1001;
						border-top: 1px solid $grey-4;
						text-align: center;
						padding: 12px;

						.guide-summary-section,
						.guide-summary-header {
							display: none;
						}

						.guide-summary-section.last {
							display: block;
							border: 0;
							padding: 0;

							.btn {
								max-width: 45%;
								display: inline-block;
								height: 40px;
								margin: 0;
								font-size: 16px;
								box-shadow: none;
							}
						}
					}
				}
			}
		}

		#card-main-view {
			.guide-section {
				background-color: $grey-1;
				margin-bottom: 6px;
				@include border-radius($border-radius);

				.guide-section-header {
					display: block;
					height: $header-height;
					width: 100%;
					background-color: $white;
					margin-bottom: 10px;
					padding: 0 0 0 20px;
					@include border-radius($border-radius $border-radius 0 0);

					.title {
						line-height: $header-height;
						display: inline-block;
						float: left;
					}

					.main-action-btn {
						height: $header-height;
						@include border-radius(0 $border-radius 0 0);
						min-width: 140px;
					}

					.btn-group {
						margin-top: 7px;
						margin-right: 10px;
					}
				}

				.guide-section-row {
					padding: 20px;
				}

				.card-header #title {
					h2 {
						color: $white;
						padding: 0 15px;
						font-size: 18px;
						line-height: $header-height;
					}
				}

				.guide-section-aside {
					padding: 0 30px;
					background-color: $grey-1;

					p {
						color: $grey-10;
					}
				}

				.custom-bundle-card {
					overflow: hidden;
					margin-top: 28px;
					border-radius: 2px;

					&:before {
						content: "";
						position: absolute;
						top: -32px;
						left: -10px;
						right: -10px;
						height: 114px;
						z-index: 1001;
						background-image: linear-gradient(90deg, $purple 0%, $blue 100%);
						transform: rotate(-5deg);
					}

					.CardContent {
						z-index: 1001;
						position: relative;
						text-align: center;
					}

					.Headline {
						text-align: center;
						margin: 15px 0;
					}

					.Text {
						margin-bottom: 20px;
					}
				}

				.guide-ad-credit-preview {
					position: relative;
					background-color: $green;
					font-size: 12px;
					padding: 0 10px;
					text-align: left;
					color: $super-light-green;
					@include border-radius($border-radius);
					@include box-shadow(1px 1px 2px rgba($black, 0.1));
					margin: 10px 0px 10px -12px;
					line-height: 26px;
					height: 26px;
					margin-top: 28px;
					text-transform: uppercase;
					display: inline-block;

					.arrow {
						position: absolute;
						margin-left: -14px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 6px 5px 6px 0;
						border-color: transparent $blue transparent transparent;
						top: 8px;
					}

					&.red {
						background-color: $red;

						.arrow {
							border-color: transparent $red transparent transparent;
						}
					}
				}

				// STYLING FOR AD PACKAGES IN GUIDE
				.ad-package-box {
					position: relative;
					background-color: $white;
					border: 1px solid $grey-8;
					@include border-radius($border-radius);
					text-align: center;
					margin-bottom: 20px;
					@include transition(all ease-out 200ms);
					height: 220px;
					overflow: hidden;

					&.ad-package-selected,
					&:hover {
						border-color: $medium-green;
						@include box-shadow(1px 1px 2px rgba($black, 0.1));
					}

					&.ad-package-selected {
						border-color: $medium-green;

						.ad-package-button {
							background-color: $medium-green;
							color: $white;
							border-top: 1px solid $medium-green;

							.select-text {
								display: none;
							}

							.fa {
								display: inline-block;
							}
						}
					}
					.price-tag {
						position: absolute;
						top: 0px;
						left: 7px;
						padding-top: 6px;
						padding-bottom: 4px;
						background-color: $grey-1;
						color: $grey-8;
						padding-left: 5px;
						padding-right: 5px;
						@include border-radius(0 0 $border-radius $border-radius);
						border-bottom: 1px solid $grey-8;
						border-left: 1px solid $grey-8;
						border-right: 1px solid $grey-8;
						&.active {
							background-color: $medium-green;
							color: $white;
						}
					}
					.ad-package-inner {
						padding: 30px;
						font-size: 12px;

						h2 {
							margin: 12px 0;
						}

						i.flag-icon {
							font-size: 20px;
							margin-right: 4px;
						}

						i.fa.fa-globe {
							font-size: 22px;
							color: $grey-10;
							margin-right: 4px;
						}

						.sub-title {
							color: $grey-10;
							margin-bottom: 0;
							text-transform: uppercase;
						}

						.ad-package-description {
							max-width: 250px;
							margin: 0 auto;
							display: block;
						}
					}

					.ad-package-button {
						position: absolute;
						bottom: 0;
						width: 100%;
						left: 0;
						right: 0;
						@include transition(all ease-out 200ms);
						@include border-radius(0 0 $border-radius $border-radius);
						padding: 10px 16px;
						font-size: 18px;
						line-height: 1.33;
						font-weight: normal;
						text-align: center;
						vertical-align: middle;
						background-color: $grey-1;
						border-top: 1px solid $grey-8;
						color: $grey-10;

						.select-text {
							display: block;
						}

						.fa {
							display: none;
						}
					}
				}
			}
		}
	}
}

#account-card {
	@include border-radius($border-radius $border-radius 0 0);

	&.has-ad-graph {
		top: 58px;

		#card-main-view {
			margin-top: 0 !important;
			background-color: $grey-1 !important;

			.alert {
				margin: 0;
				border-radius: 0;
			}
		}

		.top-status-wrapper {
			background-color: $grey-1;
			padding: 60px 40px;
			display: block;
			width: 100%;
			color: $grey-11;
			text-align: center;

			h2 {
				margin: 10px 0;
			}

			.error {
				color: $orange;

				h2 {
					color: $orange;
				}
			}
		}

		ul.nav {
			clear: none !important;
			margin: 0 !important;
			border: none !important;
		}

		.clearfix {
			background-color: $grey-1;
		}

		#main-content {
			.page-content {
				margin-top: 0 !important;
			}
		}

		.up-paginator {
			margin-bottom: 0;
		}
	}
}

@include keyframes(all-in) {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.allin {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	font-size: 200px;
	color: $bright-green;
	z-index: 99999999;
	top: 0;
	margin: 0 auto;

	&.visible {
		display: block;
		visibility: visible;
		@include animation(all-in 2000ms);
	}
}

.ad-targets-icons {
	div {
		color: $grey-8;
		background-color: $grey-4;
		margin: 0;
		display: inline-block;
		padding-top: 2px;
		margin-right: -3px;
		padding-right: 5px;
		padding-left: 5px;
		padding-bottom: 2px;
		font-size: 10px;
		&.last {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
		&.first {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}
		&.active {
			color: $blue;
		}
		&.big {
			font-size: 16px;
		}
		&.no-background {
			background-color: $white;
			padding-left: 0;
			padding-right: 10px;
		}
	}
}

.list-ad-status {
	.fa {
		margin-right: 5px;
	}

	.error {
		color: $orange;

		.fa.fa-exclamation-triangle {
			display: inline-block;
		}
	}

	.draft {
		color: $grey-8;
		font-style: italic;
	}

	.activating {
		color: $bright-green;
		font-style: italic;
		.fa.fa-play {
			display: inline-block;
		}
	}

	.active {
		color: $bright-green;

		.fa.fa-play {
			display: inline-block;
		}
	}

	.paused {
		color: $grey-11;

		.fa-pause {
			display: inline-block;
		}
	}

	.finished {
		color: $grey-11;
		font-style: italic;

		.fa-check {
			display: inline-block;
		}
	}

	.done {
		color: $grey-11;

		.fa-check {
			display: inline-block;
		}
	}

	.archived {
		color: $grey-11;

		.fa-archive {
			display: inline-block;
		}
	}
}

.no-content-placeholder {
	text-align: center;
	color: $grey-8;
	padding: 40px 0;
	font-size: 16px;
}

.ad-status-label {
	color: $grey-11;
	padding-right: 2px;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0.2px;
	width: 18px;
	cursor: default;

	.ad-status-inner {
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 2px;
		margin-right: 4px;
		text-align: center;
		padding-top: 2px;
	}

	&.has-ip {
		.ad-status-inner {
			background-color: rgba($bright-green, 0.3);
			color: $green;
		}
	}

	&.no-ip {
		.ad-status-inner {
			background-color: rgba($orange, 0.4);
			color: rgba($red, 0.6);
		}
	}

	&.no-duns {
		.ad-status-inner {
			background-color: rgba($red, 0.2);
			color: darken($red, 10);
			cursor: pointer;
		}
	}

	&.not-active-yet {
		.ad-status-inner {
			background-color: rgba($grey-8, 0.4);
			color: rgba($blue, 0.6);
		}
	}
}

.dropdown-menu {
	.ad-status-label {
		display: inline;
	}
	.counter {
		font-size: 12px;
		color: $grey-8;
	}
}

.ad-dash-footnote {
	color: $grey-11;
	font-size: 12px;
	font-style: italic;
	margin-bottom: 12px;
	& i {
		float: right;
	}
}
.graph-main.ads {
	height: 290px;
}

.select-upload-type-box {
	position: relative;
	background-color: $white;
	border: 1px solid $grey-6;
	-khtml-border-radius: 3px;
	border-radius: 3px;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	text-align: center;
	margin-bottom: 20px;
	-webkit-transition: all ease-out 200ms;
	transition: all ease-out 200ms;
	width: 100%;
	height: 100px;
	padding: 5px;
	&:hover {
		border-color: $bright-blue;
		@include box-shadow(1px 1px 2px rgba($black, 0.1));
	}
}

.ip-grade-on {
	color: $bright-blue;
}

.ip-grade-off {
	color: $grey-4;
}

.select-target {
	border-left: 4px solid $grey-8;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	padding: 8px;
	margin-bottom: 4px;
	.pull-left .fa {
		font-size: 18px;
		color: $grey-8;
		padding-right: 8px;
		margin-top: 6px;
		margin-bottom: 100%;
	}
	div {
		font-size: 11px;
		color: $grey-10;
	}
	&.active {
		border-color: $bright-blue;
		.pull-left .fa {
			color: $bright-blue;
		}
	}
	&:hover {
		border-color: $bright-blue;
		background-color: $white;
		.pull-right .fa {
			display: inline;
		}
	}
	.pull-right .fa {
		display: none;
		font-size: 13px;
		color: $grey-10;
	}
}

.edit-target {
	.select-page {
		border-bottom: 1px solid $grey-8;
		margin-top: 4px;
		margin-bottom: 15px;
		padding-bottom: 13px;
	}
	.add-page {
		border-top: 1px solid $grey-8;
		border-bottom: 1px solid $grey-8;
		border-left: 0;
		border-right: 0;
		margin-left: 0;
		margin-right: 0;
		border-radius: 0;
		font-size: 10px;
		&.web-page {
			border-top-left-radius: 0.25em;
			border-bottom-left-radius: 0.25em;
			border-left: 1px solid;
			border-color: $bright-green;
			color: $bright-green;
			&:hover {
				background-color: $bright-green;
				color: $white;
			}
		}
		&.web-page-wild-card {
			border-color: $bright-green;
			color: $bright-green;
			&:hover {
				background-color: $bright-green;
				color: $white;
			}
		}
		&.web-page-ignore {
			border-color: $red;
			color: $red;
			&:hover {
				background-color: $red;
				color: $white;
			}
		}
		&.web-page-ignore-wild-card {
			border-right: 1px solid;
			border-color: $red;
			border-top-right-radius: 0.25em;
			border-bottom-right-radius: 0.25em;
			color: $red;
			&:hover {
				background-color: $red;
				color: $white;
			}
		}
	}
	.selected-page {
		margin-top: 4px;
		margin-bottom: 15px;
		padding-bottom: 13px;
		border-bottom: 1px solid $grey-6;
	}

	.icon-search {
		max-width: 250px;
		display: inline-block;
		height: 28px;
		font-size: 12px;
		margin-right: 10px;
		@include border-radius(2px);
		background-color: rgba($black, 0.05);
		border: none;
		color: $grey-10;
		@include box-shadow(none);
		@include transition(all ease-in 200ms);

		&:focus,
		&:active {
			max-width: 200px;
			background-color: $white;
			border: 1px solid $grey-6;
		}
	}
}

#select-creatives-modal {
	.up-modal-content {
		bottom: 56px;
		display: flex;
		flex-direction: column;

		.up-modal-loader {
			width: 100%;
			height: calc(100% - 46px);
			position: absolute;
			top: 46px;
			left: 0;
			z-index: 1000;
		}

		.top-toolbar {
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			border-top: 1px solid rgba(0, 0, 0, 0.1);

			#qicksearch-wrap {
				width: 250px;
			}

			label {
				font-weight: normal;

				span {
					padding-left: 5px;
				}
			}
		}

		.bottom-toolbar {
			display: none;
			margin-top: auto;
			padding: 10px;
			justify-content: center;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}

		&.with-paginator {
			.bottom-toolbar {
				display: flex;
			}

			.up-modal-loader {
				height: calc(100% - 91px);
			}
		}

		.table-wrap {
			overflow-x: hidden;
			overflow-y: auto;
			height: calc(100% - 46px);
		}

		.att-preview-popover {
			position: absolute;
			background-color: $grey-13;
			@include box-shadow(2px 2px 4px rgba($black, 0.2));
			@include border-radius(2px);
			// border: 1px solid $grey-4;
			color: $white;
			text-align: center;
		}

		.script-destination-wrapper {
			display: inline;
			padding: 2px 6px;
			@include border-radius(3px);
			background-color: $white;
			box-shadow: 1px 1px 1px rgba($black, 0.1);
		}

		.script-validation-wrapper {
			position: relative;
			display: inline-block;
			color: $grey-10;
			margin-left: 3px;
			border-left: 1px solid $grey-4;
			padding-left: 5px;
		}
	}

	.modal-table {
		tr {
			&.list-modal-row {
				.icon .fa.fa-check {
					color: $bright-blue;
					top: 50%;
					left: 13px;
					margin-top: -7px;
				}
			}

			&.row-selected {
				.fa {
					opacity: 1;
				}

				td.blue-on-selected {
					color: $bright-blue;
				}
			}

			th:last-child {
				max-width: 90px;
			}

			td {
				&.small-text {
					font-size: 12px;
				}

				&.icon {
					padding-left: 20px;
				}

				svg {
					vertical-align: middle;
				}
			}
		}
	}
}
