@import "../../../../styles/variables";

.behavior-diamond {
	background-color: #2eaa9b;
	color: white;
	width: 35px;
	height: 35px;
	border-radius: 3px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transform: rotate(45deg);
	//box-shadow: 2px 2px 0px rgba(0,0,0,0.2);

	b {
		transform: rotate(-45deg);
		font-size: 16px;
	}
}
