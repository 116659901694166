@import '~StyleVariables';

.Alerts {
    &--fullScreen {
        // AlertBody styling
        .account-items {
            width: 100%;
            margin-top: 12px;
            background-color: $grey-1;
            border: 1px solid $grey-4;

            display: grid;

            padding: 14px;
            margin-bottom: 24px;

            .upsales-loader {
                height: 45px;
                width: 45px;
                margin: 0 auto;
            }

            .account-item {
                margin: 4px 0;
            }
        }
    }


    &.FullscreenModal {
        .ModalControls {
            .Button {
                width: 100%;
            }
        }
    }

    p {
        white-space: pre-wrap;
    }

    // AlertBody styling
    .confirm-warning {
        color: $danger-5;
    }

    .confirm-body {
        color: $grey-11;
    }

    .confirmation {
        margin-top: 16px;
    }
}
