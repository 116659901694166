.InfoText {
	&--list {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		flex-wrap: wrap;
	}

	&--single {
		text-align: -webkit-center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Text {
		max-width: 600px;
		padding: 4px;
	}

	&__icon {
		margin-right: 16px;
	}
	padding: 8px 0px;
}
