@import "~StyleVariables";

.EasyBookingSettingsAvailability {
	&__calendarHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 10px 0;

		&__buttonWrap {
			margin-left: 12px;
			display: flex;
			gap: 12px;
		}
	}

	&__renderDay {
		height: 140px;
		text-align: left;
		user-select: none;
		display: flex;
		flex-direction: column;

		&__header {
			font-size: 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__date {
				padding: 8px 4px;
			}

			&__icons {
				display: flex;
				align-items: center;

				.Button {
					padding: 4px;
					height: auto;
					.Icon {
						display: block;
					}
				}

				&__repeat {
					pointer-events: none;
					color: $grey-7;
					padding: 4px;
				}
			}

			.DropDownMenu__content {
				z-index: 2;
			}
		}

		&__dropdown {
			&__header {
				background-color: $grey-2;
				cursor: default;

				.TableHeader__column {
					text-transform: none;
					font-weight: bold;
				}
			}
		}

		&--full {
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 50px;
				background-image: linear-gradient(transparent, $white);
			}
		}
		&--greyDate {
			color: $grey-7;
			&:after {
				background-image: linear-gradient(transparent, $grey-3);
			}
		}
		&--full & {
			&__content {
				:hover > & {
					background: $grey-2;
					box-shadow: 0 2px 2px #ccc;
				}
			}
		}

		&:hover > &__content {
			z-index: 1;
			overflow: visible;
			&:after {
				display: none;
			}
		}
		&__content {
			overflow: hidden;
			position: relative;

			&__unavailable {
				color: $grey-4;
				font-size: 12px; // if clamp() is not supported
				font-size: clamp(10px, 0.9vw, 12px);
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 100%;
				padding-top: 10px;
				padding-bottom: 30px;

				span {
					padding: 0 10px;
					display: none;
				}

				.Icon {
					font-size: 2.5em;
					margin-bottom: 10px;
				}
				&--deviation {
					color: rgba($red, 0.8);

					span {
						display: block;
					}
				}
			}
			&__timeWrap {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: 4px;
				padding: 0 4px 16px 4px;
			}

			&__time {
				font-size: 11px; // if clamp() is not supported
				font-size: clamp(9px, 0.8vw, 11px);
				background-color: $super-light-green;
				text-align: center;
				padding: 2px;
				border-radius: 4px;
				border: solid 1px $medium-green;
				box-shadow: 0 1px 3px 0 $grey-4;
				color: $green;

				:not(:first-child) {
					margin-top: 5px;
				}
				&:hover {
					box-shadow: 0 1px 3px 0 $grey-6;
				}
			}
		}
	}

	.Calendar {
		font-family: "Roboto", sans-serif;
	}
	.Calendar__Day--today {
		background-color: $white;
	}
}
