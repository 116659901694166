@import "~StyleVariables";

.CardsHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $space * 6 $space * 4 $space $space * 6;

	&__leftSide {
		.Text {
			gap: 8px;
			display: flex;
			align-items: center;
		}
	}

	&__rightSide {
		display: flex;
		align-items: center;
		.Toggle {
			margin-right: 8px;
		}
		.Button {
			margin-left: 16px;
		}
	}

	&__buttonTrigger {
		color: $super-light-green !important;
	}

	&__buttonDropDown {
		white-space: nowrap;
		.Text {
			.Icon {
				margin-right: 12px;
			}
			padding: 4px 24px;
			cursor: pointer;
			&:hover {
				background-color: $grey-4;
			}
		}
	}

	.Animate {
		z-index: 2;
	}
}
