@import "~StyleVariables";
@import "~StyleMixins";
@import "~StyleAnimations";

$admin-sidebar-width: 230px;

.admin-sidebar {
	width: $admin-sidebar-width;
	flex-direction: column;
	display: flex;

	#admin-sidebar-noresults {
		display: block;
		text-align: center;
		color: $grey-10;
		font-size: 16px;
		height: 30px;
		line-height: 30px;
	}

	#admin-sidebar-search {
		$height: 50px;
		position: relative;
		height: $height;

		.fa-search {
			position: absolute;
			left: 0;
			top: 0;
			font-size: 14px;
			color: $grey-8;
			width: $height;
			text-align: center;
			height: $height;
			line-height: $height;
		}

		input {
			border: none;
			margin: 10px;
			height: $height - 20px;
			width: $admin-sidebar-width - 20px;
			line-height: $height;
			padding: 0 22px;
			padding-left: $height - 20px;
			outline: none;
			background-color: $grey-2;
			border-radius: $border-radius;
			@include placeholder-color($grey-8);
		}

		.clear-search {
			$size: 14px;
			position: absolute;
			right: 20px;
			top: 18px;
			text-align: center;
			width: $size;
			height: $size;
			line-height: $size;
			background-color: transparent;
			font-size: 12px;
			cursor: pointer;
			@include border-radius(3px);
			color: rgba($bright-blue, 0.4);
			@include transition(all 200ms ease);

			&:hover {
				color: $bright-blue;
			}
		}
	}

	#sidebar-list-wrap {
		overflow-x: auto;
	}

	.highlighted {
		background-color: $green;
		color: $super-light-green;
		padding: 4px 0;
		border-radius: $border-radius;
	}

	.sidebar-main-section {
		position: relative;

		&.expanded {
			.title .fa {
				transform: rotate(180deg);
			}

			.sidebar-items {
				visibility: visible;
				height: auto;
				@include opacity(1);
			}
		}

		.title {
			$height: 40px;
			padding: 0 15px;
			color: $grey-13;
			font-size: 16px;
			@include transition(color 200ms ease);
			height: $height;
			line-height: $height;
			border-top: 1px solid $grey-4;

			&:hover:not(.no-click) {
				color: $bright-blue;
				cursor: pointer;
			}

			.fa {
				padding: 0 8px;
				position: absolute;
				right: 8px;
				@include transition(all 200ms ease);
				font-size: 16px;
				transform: rotate(0deg);
				height: $height;
				line-height: $height;
			}
		}

		.sidebar-items {
			visibility: hidden;
			overflow: hidden;
			height: 0;
			@include opacity(0);
			@include transition(all 200ms ease);
		}
	}

	#admin-sidebar-profile-item {
		.item-title {
			font-size: 16px;
			color: $grey-13;
			padding-left: 15px;
			height: 35px;
			line-height: 35px;
		}
	}

	.highlighted-item {
		background-color: rgba($super-light-green, 0.5);
		color: $green;
	}

	.sidebar-item {
		display: block;
		color: $grey-11;
		@include transition(color 200ms ease);

		&:hover,
		&.active {
			.item-title {
				color: $green;
				background-color: $white;

				.fa {
					color: $green;
				}
			}
		}
		&.active .item-title {
			color: $black;
			font-weight: $boldFontWeight;
			border-right: 3px solid $green;
		}

		&.expanded {
			.item-title .fa-angle-down {
				transform: rotate(180deg);
			}

			.sub-items {
				visibility: visible;
				height: auto;
				@include opacity(1);
			}
		}

		.item-title {
			$height: 30px;
			font-size: 14px;
			padding: 0 15px;
			padding-left: 25px;
			height: $height;
			line-height: $height;
			cursor: pointer;
			@include transition(color 200ms ease);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				color: $green;
			}

			.fa-angle-down {
				padding: 0 8px;
				position: absolute;
				right: 8px;
				@include transition(all 200ms ease);
				font-size: 12px;
				transform: rotate(0deg);
				height: $height;
				line-height: $height;
			}
		}

		.sub-items {
			visibility: hidden;
			overflow: hidden;
			height: 0;
			@include opacity(0);
			@include transition(all 200ms ease);

			.sub-item {
				font-size: 12px;
				padding-left: 35px;
				height: 30px;
				line-height: 30px;
				cursor: pointer;
				@include transition(all 200ms ease);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&:hover,
				&.active {
					color: $green;
					background-color: $white;
				}
				&.active {
					color: $black;
					font-weight: $boldFontWeight;
				}
			}
		}
	}

	.sidebar-app-item {
		.app-img-wrapper {
			height: 20px;
			width: 20px;
			margin-right: 5px;
			display: inline-block;
			overflow: hidden;
			border-radius: $border-radius;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 80%;
			vertical-align: middle;
		}
	}
}
