@import "../../../styles/variables";

.InsightsNavbar {
	flex: 0 1 auto;
}

.InsightsNavbar--Wrapper {
	flex: 0 1 auto;
	display: flex;
	background-color: $white;
	height: 50px;
	border-bottom: 1px solid $grey-8;
	box-sizing: content-box;
}

.InsightsNavbar--Tabs__nope > .Tabs__selected-slider {
	display: none;
}

.InsightsNavbar--Tabs {
	align-items: center;
	overflow: visible;
}

.InsightsNavbar--Tabs--right {
	margin-left: auto;
	margin-right: 20px;
}

.InsightsNavbar--Tab {
	height: 100%;
	line-height: initial;
	padding: initial;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 200px;
	position: relative;
}

.InsightsNavbarDropdown {
	list-style: none;
	position: relative;
	top: 23px;
	left: -100%;
	z-index: 1000;

	.dropdown-menu {
		// looks ugly in firefox with this
		overflow-x: hidden;
	}
}

.InsightsNavbarDropdown ul {
	border-color: $bright-blue;
	min-width: 250px;
}

.InsightsNavbarDropdown--Title {
	margin-bottom: 15px;
}

.InsightsNavbarDropdown--Icon {
	margin-right: 10px;
}

.InsightsNavbarDropdown--Button {
	text-align: left;
}

.InsightsNavbarDropdown--Search {
	padding: 6px 6px 10px 6px;

	/* md to lage, sm to smal :/ */
	.Input,
	.Input__input,
	.Input__icon {
		height: 28px;
		line-height: 28px;
	}

	.Input__input::-moz-placeholder,
	.Input__input:-moz-placeholder {
		line-height: 28px;
	}

	.Input__icon {
		width: 28px;
	}
}

.InsightsNavbarDropdown--Header {
	color: $black;
	background-color: $grey-2;
	text-transform: uppercase;
	font-size: 12px;
	padding: 6px 10px;
	font-weight: normal;

	.Icon {
		margin-right: 8px;
	}
}

.InsightsNavbarDropdown--Item {
	background-color: $white;
	color: $black;
}

.InsightsNavbarDropdown--Link {
	/* disable some of the default bootstrap dropdown css to make it look more like an select2 */
	display: flex !important;
	padding: 0px 20px 0px 10px !important;
	color: inherit !important;
	background-color: inherit !important;

	align-items: center;
	height: 32px;
}

.InsightsNavbarDropdown--Item:hover,
.InsightsNavbarDropdown--Item:active {
	background-color: $bright-blue;
	color: $white;
}
