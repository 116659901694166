@import "~StyleVariables";

.BetaBanner {
	height: 40px;
	background-color: $super-light-blue;
	position: fixed;
	z-index: 10011;
	width: 100%;
	top: 50px;

	&__wrapper {
		height: 100%;
	}

	&__leftSide {
		.AssistChip {
			width: fit-content;
			border-color: rgba($dark-purple, 0.2);
			background-color: $super-light-purple;

			.Text {
				color: $dark-purple;
				padding-right: 0px;
			}
		}
	}

	&__popup {
		display: flex;
		flex-direction: column;

		z-index: 1;
		width: 370px;
		position: absolute;
		top: 90px;
		right: 28px;
		padding: $space * 4;
		border-radius: $border-radius;
		box-shadow: $box-shadow-xl;

		.Text--bold {
			margin-bottom: $space * 2;
		}

		.Textarea {
			margin-top: $space * 3;
		}
	}
}
