@import "~StyleVariables";

.Modal.ExportDataReact {
	width: 900px;
}

.ExportDataReact {
	a {
		cursor: pointer;
	}

	&__row-toggle {
		float: left;
	}

	.ModalContent {
		font-size: 12px;
	}

	.TemplateHeaderSelector__options__option {
		.Text {
			width: fit-content;
		}
	}

	// Directly copied from old export-columns.scss
	.addable {
		display: block;
	}
	.header-entity {
		h4 {
			display: inline-block;
		}
	}
	.drag-placeholder {
		height: 40px;
		margin-bottom: 10px;
		border: 1px dashed $grey-5;
	}

	.dropzone {
		background-color: $grey-1;
		border: 1px solid $grey-8;
		padding: 10px 10px 0 10px;

		.draggable {
			min-height: 40px;
			background-color: $white;
			border: 1px solid $grey-5;
			margin-bottom: 10px;

			.inner {
				display: flex;
				justify-content: space-between;
				gap: 4px;
				padding: 10px;

				.textContent {
					display: flex;
					gap: 4px;
					align-items: baseline;
				}

				.handle {
					cursor: move;
				}

				.remove {
					float: right;
					color: $grey-8;
					cursor: pointer;
					padding-top: 1px;

					&:hover {
						color: $black;
					}
				}
			}
		}
	}
}
