#groupmail-select-group.card {
	position: fixed !important;
	top: $main-top !important;
	left: 65px !important;
	right: $padding !important;
	bottom: $padding !important;
	background-color: $grey-3;
	width: 100%;
	border-radius: $border-radius;
	margin-bottom: 10px;
	float: left;
	transition: top 200ms, bottom 200ms, left 200ms, right 200ms;

	&.fullscreen {
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		z-index: $modal-z - 1;

		.up-header {
			top: 0;
			left: 0;
			right: 0;
		}
	}

	.recipient-type-select {
		margin-bottom: 10px;
	}

	.up-header {
		transition: top 200ms, bottom 200ms, left 200ms, right 200ms;
	}

	#card-sidebar {
		position: absolute;
		overflow-y: scroll;
		top: 0;
		bottom: 0;

		.sidebar-header {
			margin: 15px -15px 10px -15px;
			padding: 16px 15px 6px 15px;
			border-top: 1px solid $grey-4;
			position: relative;

			&:first-of-type {
				border-top: none;
				margin-top: -15px;
			}

			h2 {
				font-size: 16px !important;
				color: $black;
			}

			.right-btn {
				position: absolute;
				top: 10px;
				right: 15px;
				transition: none !important;
			}

			.up-ios-toggle {
				position: absolute;
				top: 16px;
				right: 15px;
			}
		}

		.warning-box {
			position: relative;
			margin: 10px 0;
			padding: 5px;
			padding-left: 25px;
			background-color: rgba($orange, 0.2);
			border: 1px solid rgba($orange, 0.2);
			@include border-radius(3px);

			.fa {
				color: $orange;
				position: absolute;
				top: 8px;
				left: 7px;
			}
		}

		label {
			font-weight: normal;
			font-size: 14px;
		}

		.selection-status {
			margin: 6px 0;
		}

		.attachment-list {
			margin-top: 12px;

			.file-name {
				font-weight: $boldFontWeight;
			}

			.att-file-size {
				float: right;
			}
		}

		#recipients {
			margin-top: 10px;

			h2 {
				display: block;
			}

			.progress-bar {
				@include border-radius(8px);
			}
		}

		#or {
			text-align: center;
			color: $grey-10;
			line-height: 40px;
			font-style: italic;
			font-size: 12px;
		}
	}

	#template-indicator {
		position: relative;

		#change-template {
			position: absolute;
			top: -10px;
			right: 0;
		}
	}

	#card-main-view {
		position: absolute;
		bottom: 0;
		top: 0;
		right: 0;

		.select-target-type {
			padding: 60px 40px;
		}

		&.has-sidebar {
			padding: 0;
			margin-top: $header-height-sm;

			&.sidebar-hidden {
				overflow-y: hidden;

				#card-sidebar {
					left: -$card-sidebar-width;
				}

				.card-content {
					margin-left: 0;
				}

				#dd-editor-wrap {
					display: inline-block;
					// visibility: visible;
					// @include opacity(1);
				}
			}
		}

		#dd-editor-wrap {
			// visibility: hidden;
			display: none;
			// @include opacity(0);
			// @include transition(all ease 200ms);
		}

		.card-content {
			background-color: $grey-2;
			overflow: hidden;
			overflow-y: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			#no-account-profile {
				margin: 50px auto;
				width: 400px;
				text-align: center;
				color: $grey-10;

				h2 {
					color: $blue;
					font-size: 24px;
					line-height: 40px;
				}

				p {
					margin: 20px 0;
					line-height: 22px;
				}

				#text-edit-btn-wrap {
					margin-top: 10px;
				}
			}

			.template-tabs {
				$tab-height: 60px;
				background-color: $white;
				display: block;
				margin: 0;
				padding: 0;
				@include box-shadow(0px 1px 2px rgba($black, 0.2));

				li.tab {
					display: inline-block;
					position: relative;
					height: $tab-height;
					line-height: $tab-height;
					text-align: center;
					width: 33.33%;
					color: $grey-10;
					font-size: 15px;
					@include transition(all ease-out 200ms);

					&:before {
						$size: 24px;
						content: $fa-var-caret-down;
						@include fa-icon();
						position: absolute;
						bottom: 0;
						visibility: hidden;
						@include transition(all ease-out 200ms);
						left: 50%;
						width: $size;
						height: $size;
						font-size: $size;
						margin-left: -($size/2);
						line-height: 10px;
						color: $white;
					}

					&:hover {
						&:before {
							color: $grey-1;
							visibility: visible;
							bottom: -21px;
						}

						&:not(.selected-tab) {
							background-color: $grey-1;
						}
					}

					&.selected-tab {
						background-color: $bright-blue;
						color: $white;

						&:before {
							color: $bright-blue;
							visibility: visible;
							bottom: -21px;
						}
					}
				}
			}

			.template-iframe {
				margin: 0;
				padding: 0;
				background-color: $white;
				border: none;
				min-height: 600px;
				width: 100%;
				display: block;

				&.phone {
					display: block;
					// width: 22em;
					height: 497px;
					min-height: auto;
				}

				.desktop {
					display: block;
					width: 100%;
					height: auto;
				}
			}

			.md-iphone-5 {
				text-align: center;
				left: auto;
				right: auto;
				display: inherit;
				margin: 40px auto;
			}

			.preview-phone {
				display: none;

				&.visible {
					display: block;
				}
			}

			.preview-desktop {
				display: block;
				margin: 20px auto 40px auto;
				border-radius: 4px;
				@include box-shadow(0px 5px 15px rgba($black, 0.2));
				max-width: 800px;
				overflow: hidden;

				.desktop-bar {
					width: 100%;
					height: 24px;
					background-color: $white;
					padding: 3px 10px;
					border-radius: 6px 6px 0 0;
					border-bottom: 1px solid $grey-4;

					.browser-dot {
						background-color: $grey-4;
						border-radius: 10px;
						height: 10px;
						width: 10px;
						display: inline-block;
					}
				}

				&.hidden {
					display: none;
				}
			}
		}

		.card-content-gallery {
			position: relative;
			float: left;
			width: 100%;

			.content-gallery-top {
				padding: 20px;

				.dropdown {
					display: inline-block;
				}

				.content-gallery-info {
					margin-bottom: 20px;
					color: $grey-10;
				}
			}

			.gallery-title {
				text-transform: uppercase;
				color: $grey-11;
				margin: 0;
				letter-spacing: 1px;
				font-size: 12px;
			}

			.all-templates {
				display: block;
				width: 100%;
				float: left;
			}

			#content-gallery-search {
				display: inline-block;
				height: 34px;
				background-color: rgba($black, 0.1);
				border: none;
				box-shadow: none;
				margin-right: 10px;
				padding-left: 30px;
				width: 150px;
				@include transition(all ease-out 200ms);

				& + .fa.fa-search {
					position: absolute;
					left: 24px;
					top: 9px;
					color: $grey-10;
				}

				&:focus {
					background-color: $grey-1;
					width: 250px;
				}

				&:disabled {
					@include opacity(0.3);

					& + .fa.fa-search {
						@include opacity(0.3);
					}
				}
			}

			.card-content-gallery-loader {
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: $white;
				background-color: $grey-2;
				@include transition(all ease-out 200ms);
				color: $grey-8;
				text-align: center;
				z-index: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				&.has-gallery-top {
					top: 77px;
				}

				.fa {
					font-size: 30px;
					margin-top: 100px;
					display: block;
					margin-bottom: 10px;
				}
			}
		}
	}

	.schedule-group-mail {
		display: none;
		margin-top: 10px;

		&.visible {
			display: block;
		}
	}

	.card-footer {
		background-color: $white;
		border-radius: 0 0 $border-radius $border-radius;

		.card-footer-inner {
			padding: 30px;
		}
	}

	.toggle-device {
		margin: auto;
		right: auto;
		text-align: center;
		z-index: 1029;

		.up-btn {
			font-size: 18px;
			min-width: 60px;
		}
	}

	.dd-editor {
		position: absolute;
		background-color: $blue;
		@include transition(all ease-out 200ms);
		top: 0;
		right: 15px;
		bottom: 0px;
		left: 90%;
		z-index: 1029;
		opacity: 0;
		display: none;

		&.visible {
			display: block;
			opacity: 1;
			left: 15px;
		}
	}

	.up-paginator-wrap {
		width: 100%;
		height: 30px;
		text-align: center;

		.up-paginator {
			padding: 0;
		}
	}
}

#template-gallery {
	.package-box {
		min-height: 350px;
		max-width: 220px;

		a {
			padding-top: 6px;
		}
	}
}
