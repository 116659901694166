@import "~StyleVariables";

.EditSocialEventAddContacts__select-segment-wrapper {
	width: 680px;
	margin: 30px auto;
}

.EditSocialEventAddContacts__select-contact-card,
.EditSocialEventAddContacts__select-segment-card {
	position: relative;
	border: 1px solid $grey-6;
	padding: 20px;
	min-height: 181px;
	width: 50%;
}

.EditSocialEventAddContacts__select-contact-card .Title,
.EditSocialEventAddContacts__select-segment-card .Title {
	margin-bottom: 12px;
	text-align: center;
}

.EditSocialEventAddContacts__select-segment-card {
	border-right: none;
}

.EditSocialEventAddContacts__selectedSegment {
	width: 100%;
}

.EditSocialEventAddContacts__manual-select-contact {
	text-align: center;
	margin-top: 30px;
}

.EditSocialEventAddContacts__confirm-segment {
	margin-top: 15px;
}

.EditSocialEventAddContacts__after-launch-settings {
	padding: 30px 0;
}

.EditSocialEventAddContacts__selected-contact {
	text-align: left;
	font-size: 16px;
	position: relative;
	padding-right: 40px;
	line-height: 35px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 295px;
}

.EditSocialEventAddContacts__selected-contact .Button {
	position: absolute;
	right: 0;
}

.EditSocialEventAddContacts__card-cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $white;
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 200ms;
}
.EditSocialEventAddContacts__card-cover--visible {
	visibility: visible;
	opacity: 0.9;
}

.EditSocialEventAddContacts__after-launch-settings-toggle {
	position: relative;
	padding-left: 50px;
	margin-bottom: 30px;
}

.EditSocialEventAddContacts__after-launch-settings-toggle .Toggle {
	position: absolute;
	top: 8px;
	left: 0;
}

@media (max-width: 1110px) {
	.EditSocialEventAddContacts__select-segment-wrapper {
		width: initial;
		margin: 30px 20px;
	}
}
