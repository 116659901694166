#create-new-form {
	.cancel-btn {
		margin-right: 15px;
		color: $grey-10;
		cursor: pointer;
	}

	.card-main-view {
		margin-top: 45px;
		height: 768px;
		background-color: $grey-1;

		.row {
			margin-left: 0px;
			margin-right: 0px;
		}

		.title {
			text-align: center;
			width: 100%;
			font-size: 20px;
			margin-top: 40px;
			margin-bottom: 10px;
		}

		.content {
			position: relative;
			width: 100%;
			height: 300px;
			margin: auto;
			margin-top: 20px;
			border: 0.5px solid $grey-4;
			background-color: $white;
			max-width: 500px;
			cursor: pointer;
			float: left;
			text-align: center;
			padding: 40px 0;
			overflow: hidden;
			@include transition(all ease 200ms);

			&.right {
				float: right;
			}

			.icon {
				text-align: center;
				bottom: 120px;
			}
			.text {
				width: 100%;
				position: absolute;
				bottom: 30px;
				.info {
					color: $grey-10;
					margin: 20px 15px 0px 15px;
				}
			}

			&:hover {
				background-color: $grey-4;
			}

			.beta-ribbon {
				background-color: $purple;
				position: absolute;
				text-align: center;
				color: $white;
				transform: rotate(45deg);
				top: 43px;
				right: -33px;
				width: 170px;
				font-size: 14px;
				text-transform: uppercase;
				height: 20px;
				line-height: 20px;
				font-weight: $boldFontWeight;

				&.se {
					background-color: $green;
					border: 2px solid $white;
					height: 30px;
					line-height: 27px;
					top: 34px;
					right: -36px;
				}
			}
		}
	}
}

#form-card {
	.up-header {
		#title {
			float: initial;
			margin-right: 350px;

			h2 {
				word-wrap: normal;
				height: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				position: relative;
				display: inline-block;
				width: 100%;
			}
		}
	}
}

#form-dashboard:not(.old) {
	.top-bar,
	.preview {
		height: auto;
		min-height: 390px;

		&.has-loading {
			height: 400px;
		}
	}

	.old-form-preview {
		max-height: 390px;
	}

	.top-bar {
		vertical-align: top;
		display: inline-block;
		width: 70%;
		position: relative;
		background-color: $white;
		border-bottom: 1px solid $grey-4;

		.key {
			height: 110px;
			width: 33%;
			float: left;
			text-align: center;

			&.no-visit {
				width: 50%;
			}

			.title {
				font-size: 40px;
				margin-top: 20px;

				.fa {
					font-size: 15px;
					vertical-align: top;
					padding: 10px 5px 0 0;
					color: $grey-10;

					&.blue {
						color: $bright-blue;
					}
				}
			}

			.subtitle {
				font-size: 15px;
				color: $grey-10;
				text-transform: uppercase;
			}
		}

		.graph-spinner {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: $grey-1;
			opacity: 0.6;
			text-align: center;

			.fa {
				margin-top: 130px;
				font-size: 25px;
			}
		}

		.graph {
			margin-top: 10px;

			.x-axis-line {
				position: absolute;
				top: 224px;
				left: 0;
				right: 0;
				height: 2px;
				background-color: $bright-blue;
			}

			.right-icon {
				position: absolute;
				right: 25px;
				top: 200px;
				color: $grey-10;
			}

			.no-data {
				width: 100%;
				height: 273px;
				text-align: center;
				color: $grey-10;
				font-size: 16px;
				font-style: italic;
				position: relative;

				.no-data-inner {
					position: absolute;
					left: 0;
					right: 0;
					top: 150px;
				}
			}

			.highcharts-container {
				top: 0px;
			}
		}

		.loading-link {
			.title {
				font-size: 16px;
			}

			.info-text {
				color: $grey-10;
			}

			input {
				color: $bright-blue;
				background-color: $white;
			}

			.up-btn {
				border-radius: 0px 2px 2px 0px;
			}
		}
	}
	.preview {
		position: relative;
		width: 30%;
		top: 0px;
		display: inline-block;
		overflow: hidden;
		border-left: 1px solid $grey-4;
		border-bottom: 1px solid $grey-4;

		.curtain {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: 1;
			opacity: 0;
		}

		iframe {
			height: 1768px;
			width: 1024px;
			border: none;
			transform-origin: 0 0;
			will-change: transform;
		}
	}

	.lists {
		position: relative;
		margin-top: -6px;
		.tab {
			display: inline-block;
			height: 50px;
			line-height: 50px;
			padding-left: 20px;
			padding-right: 20px;
			color: $blue;
			cursor: pointer;

			&:hover {
				background-color: $grey-1;
			}

			&.current {
				border-bottom: 1px solid $blue;
				top: 1;
				font-weight: $boldFontWeight;
			}

			// &.disabled {
			// 	pointer-events: none;
			// 	color: $grey-3;
			// }
		}

		.export-btn {
			position: absolute;
			right: 15px;
			top: 20px;
			color: $bright-blue;
			cursor: pointer;

			.fa {
				margin-right: 5px;
			}

			&.disabled {
				color: $grey-10 !important;
				cursor: default !important;
			}
		}

		.list {
			.light-version {
				padding-top: 8px;
				background-color: $grey-1;
				border-top: 1px solid $grey-3;

				thead {
					th {
						background-color: $grey-1;
						border-bottom: 1px solid $grey-3;
						border-top: 0;
					}
				}

				td {
					background-color: $white;
				}

				.loading-bar {
					text-align: center;
					.fa {
						font-size: 25px;
					}
				}
			}

			nav {
				text-align: center;
			}
		}
	}

	#chart-wrap {
		margin-top: 20px;
	}

	#submit-table {
		td.tools {
			text-align: right;
			width: 78px;

			button {
				padding: 6px;
				color: $grey-10;

				&:hover,
				&:focus {
					text-decoration: none;
					color: $bright-blue;

					&.delete-trigger {
						color: $red;
					}
				}
			}
		}
	}
}

.social-med-holder {
	margin: 32px 0;

	h2 {
		display: block;
		width: 380px;
		margin: 0 auto;

		b {
			margin-left: 5px;
		}
	}

	.social-media-preview {
		width: 380px;
		margin: 15px auto 30px auto;
		background: $grey-1;
		box-shadow: 0 2px 4px 0 rgba($black, 0.2);
		border-radius: 3px;

		.image {
			overflow: hidden;
			height: 140px;
			position: relative;
			border-radius: 3px 3px 0 0;

			img {
				max-width: 100%;
			}

			.show-on-hover {
				position: absolute;
				height: 100%;
				width: 100%;
				opacity: 0;
				transition: all 0.5s;

				&:hover {
					opacity: 1;
				}

				.curtain {
					background: rgba($blue, 0.6);
					opacity: 1 !important;
				}

				.up-btn {
					margin: 0 auto;
					float: none;
					display: block;
					top: 50%;
					position: relative;
					transform: translateY(-50%);
					z-index: 5000;
				}
			}
		}

		.text-bar {
			background: $white;
			padding: 10px;
			border-radius: 0 0 3px 3px;

			.share-title {
				color: $grey-12;
				font-size: 14px;
				font-weight: $boldFontWeight;
				line-height: 17px;
				display: block;
				position: relative;

				&.editing {
					background: $grey-2;
					outline: 1px solid $grey-4;
					border-radius: 3px;
					padding: 3px;
					border: none;
					margin: 1px;
				}
			}

			.share-desc {
				color: $grey-13;
				font-size: 12px;
				font-weight: $regularFontWeight;
				line-height: 16px;
				display: block;
				margin: 6px 0;
				min-height: 32px;

				&.editing {
					background: $grey-2;
					outline: 1px solid $grey-4;
					border-radius: 3px;
					padding: 3px;
				}
			}

			.share-url {
				color: $grey-10;
				font-size: 10px;
				font-weight: $regularFontWeight;
				line-height: 12px;
				display: block;
				word-break: break-all;
			}

			.inline-inputs {
				display: block;
				width: 100%;
				border: none;
				padding: 0;
				background: transparent;
			}

			.editing-text {
				color: $bright-blue;
				font-size: 12px;
				position: absolute;
				right: 0;

				* {
					margin-left: 10px;
				}
			}
		}
	}

	.button-wrapper {
		width: 380px;
		margin: 0 auto;

		.hidden-input {
			@include opacity(0);
			position: absolute;
			left: -9999;
			top: -9999;
		}

		> div {
			margin: 0 -10px;

			button,
			a {
				margin: 0 10px;

				&.done-copy,
				&.done-copy:hover {
					background: $bright-green;
					border-color: $green;
					color: $white;
					position: relative;

					i.fa {
						position: absolute;
						right: 12px;
						color: $white;
						line-height: 34px;
					}
				}
			}
		}
	}
}
