@import "../../../../styles/variables";

.AdminSalesboardColumn {
	position: relative;
	width: 250px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	transition: box-shadow ease 200ms;
}

.AdminSalesboardColumn__drag-handler {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	line-height: 5px;
	font-size: 28px;
	top: 0;
	left: 105px;
	color: $grey-6;
	transition: opacity ease 200ms;
	z-index: 100;
}

.AdminSalesboardColumn:hover,
.AdminSalesboardColumn--isDragging {
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);

	.AdminSalesboardColumn__drag-handler {
		opacity: 1;
		visibility: visible;
	}
}
