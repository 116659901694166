.InsightsContent {
	flex: 1 1 auto;
	display: flex;
	overflow: auto;
}

.InsightsContent--CardContent {
	flex: 1;
	display: flex;
	max-width: 1200px;
	margin: 0 auto;
}

.InsightsContent--Column_center-everything {
	display: flex;
	align-items: center;
	justify-content: center;
}

.InsightsContent--Row {
	flex: 1;
}

.InsightsContent--UpsalesSupportCard {
	margin-bottom: 20px;
	margin-top: 20px;
}

.InsightsContent--Column {
	margin: 0 20px;
	min-width: 0;
}

.InsightsContent--Column:first-child {
	margin-left: 0;
}

.InsightsContent--Column:last-child {
	margin-right: 0;
}
