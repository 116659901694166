@import "~StyleVariables";

.WonOpportunity {
	$slideInDuration: 500ms;
	$contentFadeInDuration: 600ms;
	$contentFadeInDelay: 600ms;

	z-index: $modal-z + 2;
	display: flex;
	flex-direction: column;
	background-color: $white;
	overflow: auto;
	pointer-events: none;

	&--animate {
		position: absolute;
		width: 100%;
		height: 100%;
		transform: translateY(100%);
		opacity: 0;
		visibility: hidden;
		transition: all ease-in-out $slideInDuration;
	}

	&--visible {
		transform: translateY(0%);
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	&__header {
		margin-top: -5px;

		&--isModal {
			position: relative;
		}
	}

	&__confetti {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		margin: $modal-content-margin;

		@media (min-width: $responsiveWidht6) {
			padding-right: 100px;
			padding-left: 100px;
		}

		&--isModal {
			margin-top: 0;
		}
	}

	&__contentText {
		display: flex;
		flex-direction: column;
		align-items: center;
		opacity: 0;
		transition: opacity ease-in-out $contentFadeInDuration $contentFadeInDelay;
		width: 100%;
		height: 100%;

		&--fadeIn {
			opacity: 1;
		}

		&--pushUp {
			margin-top: -50px;
		}

		&--small {
			max-width: 500px;
		}
	}

	&__congratulationsText {
		color: $bright-green;
	}

	&__targetStatusText {
		font-weight: $boldFontWeight;
		text-align: center;
	}

	&__changeCloseDateBlock {
		display: flex;
		align-items: center;
	}

	&__changeCloseDateButton {
		font-size: 14px;
		padding-left: 0;
		margin-bottom: 2px;
	}

	&__changeCloseDateButtonContainer {
		display: inline;
	}

	.OrderCloseDatePicker {
		position: absolute;
		bottom: $up-btn-height;
		right: 0;
	}
}
