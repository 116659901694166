@import "~StyleVariables";

.ReportcenterDrilldownDrawer {
	height: 100%;
	min-width: 600px;
	max-width: 100%;
	background-color: $grey-1;
	transition: 300ms ease-out 300ms;

	&__content-wrapper {
		height: calc(100% - 50px);
		overflow-y: auto;
		padding-bottom: 10px;
		text-align: left;
	}

	&__export-btn {
		position: absolute;
		right: 0;
		bottom: 0px;
	}
	&__export-tooltip {
		position: absolute;
		right: 0;
		bottom: 0px;
	}

	.Text#role-title {
		margin-top: 10px;
		margin-bottom: -5px;
		margin-left: 20px;
	}

	.DrawerHeader {
		.Title {
			font-size: 16px;
			color: $black;
		}

		.Button.up-btn.btn-white.btn-md {
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

			.Text {
				font-weight: $boldFontWeight;
			}
		}

		&__subtitle {
			line-height: 14px;
		}
	}

	.DrawerHeader__hide.Button {
		background: transparent;
	}

	.Widget {
		width: auto;
	}
	.Paginator {
		position: absolute;
		left: 0;
		right: 0;
		margin-top: 10px;
	}
}
