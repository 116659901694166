.FlowTemplatePreview {
	overflow: hidden;
}

.FlowTemplatePreview__new-flow-button {
	display: block;
	margin-left: auto;
	margin-left: 8px;
}

.FlowTemplatePreview__title-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}