@import "~StyleVariables";

.LocationPicker {
	width: inherit;
	white-space: nowrap;
	position: relative;

	&--disabled {
		.Icon {
			background-color: $grey-4 !important;
			&:hover {
				cursor: default;
			}
		}
	}
	&__input {
		width: calc(100% - 25px) !important;
		display: inline-block;

		.Input {
			border-radius: $border-radius, $border-radius, 0, 0;

			&__input {
				font-size: 13px; //lord have mercy
				box-shadow: none;
				padding-right: 20px;
				border-radius: $border-radius 0px 0px $border-radius;
			}
		}

		&--noAddresses {
			border-radius: $border-radius;
			width: 100% !important;

			.Input__input {
				border-radius: $border-radius;
			}
		}
	}

	&__times {
		line-height: 35px;
		position: absolute;
		right: 27px;
		display: inline;
		cursor: pointer;
		font-size: 7px;

		&:hover {
			color: $dark-red;
		}

		&--noAddresses {
			right: 8px !important;
		}

		&::before {
			position: relative;
			background-color: $white;
			padding: 8px;
		}
	}

	.Icon-chevron-down {
		position: absolute;
		right: 0;
		top: 0;
		background-color: $white;
		line-height: 32px;
		width: 25px;
		padding-left: 7px;
		font-size: 10px;
		border: 1px solid $grey-6;
		border-left: none;
		border-radius: 0px $border-radius $border-radius 0px;
		cursor: pointer;
	}

	.ButtonSelect {
		width: -webkit-fill-available !important;
		margin: 4px !important;

		.Text {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: -webkit-fill-available;
		}
	}

	.Animate {
		position: absolute;
		z-index: 1;
		width: inherit;
		width: 100%;
	}

	&__loader {
		display: flex;
		justify-content: center;
	}

	&__location {
		padding: 8px;
		cursor: pointer;
		transition: background-color ease 100ms;
		height: 50px;

		.Text {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&--withoutType {
			line-height: 34px;
		}

		&:hover {
			background-color: $grey-1;
		}
	}

	&__companyName {
		width: inherit;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		background-color: $grey-1;
		height: 30px;
		line-height: 30px;
		padding-left: 16px;
	}

	&__noAddresses {
		white-space: pre-line;
		padding: 8px;
	}
}
