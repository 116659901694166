@import "~StyleVariables";

.CardParent {
	border-radius: $border-radius;
	box-shadow: $box-shadow-md;
	background-color: $white;
	min-height: 70px;
	margin: 16px 0;

	&--hideActions {
		// We want this for the UpdateSplitSubs fade animation
		overflow: hidden;
	}

	&--showClientName {
		border: 1px solid $grey-6;
	}

	&__clientName {
		border-radius: $border-radius;
		background-color: $grey-1;
		height: 31px; // ask leche
	}

	&__row {
		cursor: pointer;
		padding: 16px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		> div {
			width: 14%;
		}
	}

	&__start-date {
		padding-left: 24px;
	}

	&--closed {
		transition: background-color, box-shadow 200ms ease;

		&:hover {
			background-color: $grey-1;
			box-shadow: $box-shadow-lg;
		}
	}

	&--isInactive {
		background-color: $grey-2;
		border-left: 4px solid $grey-6;
		&:hover {
			background-color: $grey-3;
		}

		.CardChildRow--hasEndDate {
			margin-left: -4px;
		}
	}

	&__sales-description {
		width: 16% !important;
		padding-right: 4px;
		margin-left: 16px;
		border-right: 1px solid lightgrey;
	}

	&__user {
		display: flex;
		gap: 8px;

		.Avatar {
			top: 10px;
			background-color: $super-light-green;
			color: $black;
		}

		.Avatar--initials {
			color: $green;
		}
	}

	&__contact .Link {
		display: block;
		width: stretch;
	}

	&__buttonGroup {
		display: flex;
		align-items: center;
		margin-left: 16px;
		margin-bottom: 8px;
		.Help {
			margin-left: 8px;
		}
	}

	&__emptyRow {
		padding: 11px 16px 16px 16px;
	}
}
