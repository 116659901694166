@import "~StyleVariables";

.NotificationCard {
	border-radius: 2px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;

	&--unread {
		.NotificationCard__heading,
		.NotificationCard__content {
			background-color: $white;
		}
	}

	&--link {
		&:hover {
			cursor: pointer;
		}
	}

	&__heading {
		background-color: $grey-1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
		height: 25px;

		.Icon {
			padding-right: 10px;
		}
	}

	&__content {
		background-color: $grey-1;
		display: flex;
		align-items: center;
		padding: 10px;
		color: $grey-11;
	}

	&__text {
		width: 100%;
	}

	&__removed-content {
		color: $grey-11;
		font-style: italic;
	}

	.Progressbar {
		margin-top: 3px;
	}
}
