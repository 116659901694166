@import "~StyleVariables";

.ClientCardOverview {
	&__comment {
		margin: 20px;
	}

	&__timeline {
		height: 100%;
		padding: 20px;
	}

	.TimelineLoadMore {
		background-color: $grey-2;

		&__icon {
			background-color: $grey-2;
		}
	}

	&__widgets {
		border-bottom: 1px solid $grey-6;

		#ui-elements-wrapper {
			display: flex;
			padding: 0;

			> div {
				padding: 12px 16px;
				flex: 1;

				&:not(:last-child) {
					border-right: 2px solid $grey-2;
				}
			}
		}

		iframe {
			height: 100%;
		}

		.ui-element-widget {
			background-color: $white;
			border-bottom: 0;
		}
	}
}
