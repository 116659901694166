@import "~StyleVariables";

.SwooshScreen {
	background-color: $super-light-blue;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.Icon {
		font-size: 24px;
	}
}
