@import "~StyleVariables";

.ChangesOnAgreement {
	position: relative;
	margin: 12px;

	&__card {
		height: 75px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		border-radius: $border-radius;
		padding-right: 16px;
		padding-left: 16px;

		transition: border-radius 0.2s ease-in-out;

		&--clickable {
			cursor: pointer;
		}

		&--open {
			border-radius: $border-radius $border-radius 0 0;
		}

		&__name {
			display: flex;
			flex-direction: column;
		}

		&__value {
			gap: 12px;
			display: flex;
			align-items: center;
		}
	}

	&__header {
		height: 55px;

		display: flex;
		align-items: center;
		justify-content: space-between;

		padding-left: 16px;
		padding-right: 16px;

		background-color: $success-1;

		&--negative {
			background-color: $danger-1;
		}

		&--hidden {
			background-color: $grey-2;
		}

		&__leftSide {
			gap: 12px;
			display: flex;
			align-items: center;

			&__nameAndType {
				display: flex;
			}
		}

		&__rightSide {
			gap: 12px;
			display: flex;
			align-items: center;
		}
	}

	&__rows {
		background-color: $white;

		&--notLast {
			padding-bottom: 12px;
		}
	}
}
