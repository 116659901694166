@import "~StyleVariables";

.SubscriptionPeriodScheduling {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	.Title {
		padding: 0 5px;
	}

	&__row {
		display: flex;
		gap: $space;
		line-height: 28px;
		white-space: nowrap;
		align-items: center;
	}

	&__inline-select {
		text-transform: lowercase;
	}

	.Select--disabled {
		margin: 0 4px;
	}

	&__inline-dropdown {
		.Select__dropdown-inner {
			max-height: none;

			.SelectOption {
				min-height: 32px;
			}
		}
	}

	&__renewal-toggle.Link {
		margin-right: 4px;
	}

	.DateInput--disabled .Text {
		margin-left: 4px;
	}

	.Link {
		margin-left: 4px;
	}

	ul {
		margin: 0;
	}
}
