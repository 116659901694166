@import "~StyleVariables";
@import "./shared";

.PeriodizationList {
	$heightToSubtract: $header-height + $main-top + 20px;
	height: calc(100vh - #{$heightToSubtract});
	display: grid;
	grid-template-columns: $sideBarWith auto;
	grid-template-rows: auto fit-content(100%);
	overflow: auto;
	position: relative;
}

.PeriodizationListTable {
	display: grid;
	grid-template-rows: 25px; // Header
	grid-auto-rows: $rowHeight; // Rows
}

.PeriodizationListTable > div {
	display: inline-grid;
	cursor: pointer;
	background-color: $white;
	box-shadow: inset 0 -1px 0 0 $grey-2;
}

.PeriodizationListTable > div:not(:first-child):hover {
	background-color: $grey-2;
}

.PeriodizationListTable > div:nth-child(odd) {
	background-color: $grey-1;
}

.PeriodizationListTable > div:first-child {
	background-color: transparent;
}

.PeriodizationListTable > div:first-child > div {
	text-transform: uppercase;
}

.PeriodizationListTable > div > div {
	padding: 0 0 0 $columnPadding;
	min-width: 0;
	grid-row: 1;
}

.PeriodizationListTable > div > div:last-child {
	padding-right: $columnPadding;
}
