@import "~StyleVariables";

.TimelineCard {
	border-radius: $border-radius;
	width: 100%;
	height: 100%;
	margin-bottom: 8px;
	min-width: 1px;

	&:not(.Card--transparent) {
		transition: all linear 100ms;
	}

	&__avatar {
		padding: 0 8px 0 0;

		.Avatar {
			height: 30px;
			width: 30px;
			font-size: 30px;
			vertical-align: middle;
		}
		.Avatar--initials {
			line-height: 30px;
		}
		.Avatar--gravatar {
			height: 30px;
			width: 30px;
		}
	}

	&__cardHeader {
		display: flex;
		align-items: center;
		min-height: 60px;
		padding-left: 3 * $space;
		padding-top: 2 * $space;
		padding-bottom: 2 * $space;

		&--hasSubSection {
			min-height: 50px;
			border-bottom: 1px solid $grey-4;
		}

		&--clickable {
			cursor: pointer;
		}
	}

	&__title-wrapper {
		padding-right: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	&__title-section {
		width: 100%;
		flex: 1;
		min-width: 1px;
	}

	&__cardContent {
		opacity: 0;
		overflow: hidden;
		max-height: 0;
		height: 0;
		transition: opacity 0.25s, max-height 0.15s ease-out;
		padding: 0px;

		&--open {
			max-height: fit-content;
			overflow: initial;
			height: 100%;
			opacity: 1;
			transition: opacity 0.25s, max-height 0.25s ease-in;
		}
	}

	&__subSection {
		display: flex;
		align-items: center;
		padding-left: 2px;
		.Text,
		.Link {
			font-size: 12px;
		}

		.btn-link {
			padding: 0 5px;
			line-height: 1px;
		}
	}

	&__subtitle {
		.Text {
			display: inline-block;
		}
	}
	&__comment-row {
		padding: 4px 0px;
	}
	&__actions {
		display: flex;
		align-items: stretch;
	}
}
