.CreateFlowForm {
	.Toggle {
		margin-left: 1px;
		& + .Label {
			display: inline-block;
		}
	}
	&__hide-label .Label {
		visibility: hidden;
	}
}