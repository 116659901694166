.VerifyTimeframe {
	&__content {
		width: 790px;
		align-self: center;
	}

	&__buttonBoxes {
		.ButtonBox {
			flex: 1;
		}
	}
}

.PortedVerifyTimeframe {
	display: block;
}
