@import "~StyleVariables";

.SubscriptionIndexingSummary {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__content {
		width: 1100px;
		padding-bottom: 40px;
	}
}
