@import "~StyleVariables";

.EditPhonecallNotes {
	padding: 8px 0px 0px 12px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	&__header {
		display: flex;
		justify-content: space-between;
		background-color: $white;
		margin-right: 12px;
	}

	&__add-signature {
		display: flex;
		justify-content: flex-end;
		flex-direction: row;
		align-items: center;
		.Button {
			line-height: 16px;
			height: 16px;
			padding-right: 0;
		}
		.Icon {
			margin-right: 5px;
		}
	}

	&__title {
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		align-items: center;
		gap: 8px;
	}

	&__textarea-row {
		flex: 1;
		width: 100%;
		overflow-y: auto;
		.Textarea {
			width: 100%;
			.Textarea__input {
				height: 100%;
				line-height: 17px;
				font-size: 14px;
				padding-left: 0px;
				padding-right: 12px;
			}
		}
	}
}
.EditPhonecallNotes--has-error {
	box-shadow: 0 0 5px rgba($red, 0.5) inset;
}
