@import "~StyleVariables";

.QuotaWidget {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0 $space * 2;
	height: 100%;

	&__progressbar {
		width: 100%;
		margin-top: $space;
	}
}
