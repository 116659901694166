.EditCampaign {
	overflow-y: hidden !important;
	height: 902px;
	width: 900px;
	background-color: #f6f9fb;

	.ModalHeader {
		border-top: none;
	}

	.ModalContent {
		height: calc(100% - 47px - 56px);
		overflow-y: auto !important;
	}

	&__customFieldContainer {
		min-height: 80px;
	}

	input:user-invalid,
	textarea:user-invalid,
	select:user-invalid {
		background-color: #f6e8e8;
		border-color: #b94a48;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		scroll-margin-top: 28px;
	}
	.NotesWithSignature {
		margin-top: -8px;
		&__label {
			.Label {
				opacity: 0;
			}
		}
	}
}
