@import "~StyleVariables";

.criteria-row--prospecting-industry .ButtonSelect button:last-child {
	display: flex;
	align-items: center;

	/* Hax */
	.Icon {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		background-color: $bright-green;
		border-radius: 100%;
		font-size: 10px;
		height: 13px;
		width: 13px;
	}
}

.CriteriaDrilldownWrapper--open.ProspectingDrilldown--industry .drilldown-animation-wrapper {
	max-height: 500px;
}

.ProspectingDrilldown--industry .ProspectingDrilldown__table {
	width: 100%;
}

.ProspectingDrilldown--industry.ProspectingDrilldown--no-sales {
	table {
		max-width: 100%;
	}

	.Row .Column:first-child {
		max-width: 600px;
	}

	.ctxUnderline {
		color: $bright-blue;
	}
}

.SniCodeRow {
	height: 30px;
	background-color: $white;
	border: 1px solid $grey-4;
	border-bottom: none;
}

.SniCodeRow:last-child {
	border-bottom: 1px solid $grey-4;
}

.SniCodeRow .Column:not(:last-child) {
	padding-left: 10px;
}

.SniCodeRow--child {
	background-color: $grey-1;
}

.SniCodeRow--child .Column:first-child {
	padding-left: 35px;
}
