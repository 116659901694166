#edit-columns {
	.addable {
		display: block;
	}

	.not-addable {
		display: block;
		text-decoration: none;
		color: $grey-10;
	}

	.drag-placeholder {
		height: 40px;
		margin-bottom: 10px;
		border: 1px dashed $grey-5;
	}

	.dropzone {
		background-color: $grey-1;
		border: 1px solid $grey-8;
		padding: 10px 10px 0 10px;

		.not-draggable {
			background-color: $grey-6;
			border: 1px solid $grey-5;
			height: 40px;
			margin-bottom: 10px;

			.inner {
				padding: 10px;
			}
		}

		.draggable {
			height: 40px;
			background-color: $white;
			border: 1px solid $grey-5;
			margin-bottom: 10px;
			cursor: move;

			.inner {
				padding: 10px;

				.remove {
					float: right;
					color: $grey-8;
					cursor: pointer;
					padding-top: 1px;

					&:hover {
						color: $black;
					}
				}
			}
		}
	}
}
