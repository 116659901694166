#form-editor,
#landingpage-editor {
	&.tools-visible {
		#card-main-view .preview {
			right: 350px;
			overflow: scroll;
		}

		#editor-toolbox {
			right: 0;
		}

		#editor-hide-tools {
			right: 350px + 10px;
		}
	}

	#fake-bottom-row {
		height: 50px;
	}

	#no-content-add-row {
		text-align: center;
	}

	#form-preview-toggle {
		position: absolute;
		z-index: 1003;
		left: 50%;
		width: 338px;
		margin-left: -168px;
		top: 30px;

		button {
			width: 50%;
		}
	}

	#preview-toggles {
		margin: 10px auto 0 auto;
		display: block;
		width: 400px;
		height: 30px;

		button {
			width: 50%;
		}
	}

	#save-curtain {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($black, 0.5);
		z-index: 1200;

		.upsales-loader {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 100px;
			width: 100px;
			margin: -50px 0 0 -50px;
		}
	}

	#editor-hide-tools {
		$size: 35px;
		position: absolute;
		right: -$size;
		top: 10px;
		height: $size;
		width: $size;
		@include border-radius(50%);
		@include box-shadow(0 1px 5px rgba($black, 0.3));
		background-color: rgba($white, 0.8);
		z-index: 1010;
		border: none;
		@include transition(all ease 200ms);

		&:hover {
			background-color: $white;
		}
	}

	.content-type-card-wrapper {
		padding: 10px;

		.content-type-card {
			display: inline-block;
			width: 50%;
			padding: 5px;

			.content-type-card-inner {
				position: relative;
				height: 50px;
				line-height: 50px;
				border-radius: 2px;
				padding: 0 10px;
				padding-left: 40px;
				color: $grey-11;
				font-size: 14px;
				width: 100%;
				cursor: pointer;
				background-color: $white;
				white-space: nowrap;
				@include transition(all ease 200ms);
				@include box-shadow(0 1px 2px rgba($black, 0.2));

				&.disabled {
					background-color: $grey-8;
					cursor: default;
				}

				&:hover:not(.disabled) {
					@include box-shadow(0 2px 4px rgba($black, 0.2));
					color: $bright-blue;
				}

				.fa {
					position: absolute;
					left: 0;
					width: 40px;
					line-height: 50px;
					text-align: center;
					font-size: 20px;
				}
			}
		}
	}

	.thanku-element-info {
		color: $grey-10;
		padding: 10px 15px;

		h3 {
			font-size: 18px;
			margin: 0;
			font-weight: normal;
			margin-bottom: 10px;
		}
	}

	#card-main-view {
		overflow: hidden;
		background-color: $grey-2;

		&.scrollable {
			overflow-y: auto;
		}

		.preview {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			overflow: hidden;
			overflow-y: scroll;
			overflow-x: scroll;
			@include transition(right ease 200ms);

			&.static {
				background-color: transparent;
				right: 350px;
			}

			&:not(.static) {
				.preview-inner {
					min-width: 1000px;
				}
			}

			.no-content-element {
				min-height: 200px;
				border: 1px dashed $bright-blue;
				margin: 0 -15px;
				text-align: center;

				.fa {
					position: absolute;
					left: 50%;
					top: 50%;
					margin-top: -15px;
					margin-left: -15px;
					background-color: $grey-8;
					width: 30px;
					height: 30px;
					@include border-radius(50%);
					line-height: 30px;
				}
			}

			.up-editor-placeholder {
				padding: 25px;
				background-color: $grey-4;
				color: $grey-10;
				text-align: center;
				@include border-radius(3px);

				b {
					font-size: 42px;
					margin-bottom: 20px;
				}

				.up-editor-placeholder-img {
					height: 72px;
					margin-bottom: 20px;
				}

				.editor-placeholder-text {
					font-size: 18px;
				}

				.editor-placeholder-body {
					font-size: 14px;
				}
			}

			#landingpage-move-tools {
				$size: 30px;
				position: absolute;
				left: 0;
				visibility: hidden;
				@include opacity(0);
				@include transition(all ease 200ms);
				z-index: 1003;
				background-color: $green;
				color: $white;
				width: $size;

				&.visible {
					@include opacity(1);
					visibility: visible;
				}

				button.move-row-tools {
					display: block;
					background-color: transparent;
					color: $white;
					width: 100%;
					height: $size;
					border: none;
					outline: none;
					@include transition(all ease 200ms);

					&:hover {
						background-color: rgba($white, 0.1);
					}
				}
			}

			#add-row-tools {
				$listWidth: 300px;
				$btnSize: 35px;
				position: absolute;
				visibility: hidden;
				@include opacity(0);
				@include transition(all ease 200ms);
				left: 0;
				border-top: 3px solid $medium-green;
				z-index: 1004;

				&.visible {
					visibility: visible;
					@include opacity(1);
				}

				&.open {
					#add-row-curtain {
						display: block;
					}

					#column-size-menu {
						visibility: visible;
						@include opacity(1);
						height: auto;
						padding: 20px 0;
					}

					#add-row-btn .fa {
						transform: rotate(45deg);
					}
				}

				&.above {
					#column-size-menu {
						top: initial;
						bottom: 0;
					}
				}

				&.center {
					#column-size-menu {
						top: initial;
					}

					#add-row-btn {
						margin-left: -($btnSize/2) - ($listWidth/2);
					}
				}

				#add-row-curtain {
					position: fixed;
					display: none;
					z-index: 1000;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}

				#add-row-btn {
					background-color: $medium-green;
					height: $btnSize;
					line-height: 33px;
					width: $btnSize;
					@include border-radius(50%);
					border: none;
					color: $white;
					position: absolute;
					left: 50%;
					margin-left: -($btnSize/2);
					top: -($btnSize/2);
					z-index: 1002;
					@include transition(all ease 200ms);

					.fa {
						@include transition(all ease 200ms);
					}
				}

				#column-size-menu {
					visibility: hidden;
					@include opacity(0);
					@include transition(all ease 200ms);
					position: absolute;
					background-color: $medium-green;
					@include border-radius(3px);
					width: $listWidth;
					height: 0;
					left: 50%;
					margin-left: -($listWidth/2);
					text-align: center;
					z-index: 1001;
					padding: 0;
					color: $white;
					overflow: hidden;

					.columns-header {
						letter-spacing: 0.5px;
						font-size: 14px;
					}

					.columns-row {
						padding: 7px 0;
						border-bottom: 2px solid rgba($black, 0.2);

						&:last-child {
							border: none;
						}

						&:hover {
							.col-preview-table td {
								background-color: rgba($black, 0.5);
							}
						}
					}

					.col-preview-table {
						table-layout: fixed;
						width: 100%;
						border-spacing: 7px;
						border-collapse: separate;

						td {
							background-color: rgba($black, 0.2);
							text-align: center;
							height: 30px;
							font-size: 11px;
							letter-spacing: 0.5px;
							@include border-radius(3px);
							@include transition(background-color ease 200ms);
						}
					}
				}
			}
		}

		#choose-template {
			.header-box,
			.cards {
				width: 90%;
				margin: 0 auto;
				margin-top: 30px;
			}

			.cards {
				.card-label {
					margin-bottom: 10px;
				}

				.landing-img {
					iframe {
						height: 768px;
						width: 1024px;
						transform-origin: 0 0;
					}
				}
			}

			.header-box {
				background-color: $grey-2;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;

				.text {
					float: left;
					width: 60%;
					margin: 25px 0px 0px 25px;

					.title {
						font-size: 20px;
					}

					.info {
						margin-top: 10px;
						color: $grey-10;
					}
				}
				.icon {
					float: right;
					width: calc(35% - 15px);

					.img {
						height: 160px;
						-webkit-border-radius: 0px 3px 3px 0px;
						-moz-border-radius: 0px 3px 3px 0px;
						border-radius: 0px 3px 3px 0px;
					}
				}

				.abort-icon {
					position: absolute;
					right: 35px;
					color: $grey-10;
					cursor: pointer;
				}
			}
		}
	}

	.selected-row:before,
	.selected-column:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
	}

	.selected-row:before {
		border-top: 1px solid $bright-blue;
		border-bottom: 1px solid $bright-blue;
		background-color: rgba($bright-blue, 0.4);
	}

	.selected-column:before {
		background-color: rgba($bright-blue, 0.4);
		z-index: 1001;
	}

	.up-column:hover:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		z-index: 1001;
		@include transition(background-color ease 200ms);
		background-color: rgba($bright-blue, 0.4);
	}

	.up-row.up-absolute-row:hover:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		z-index: 1001;
		@include transition(background-color ease 200ms);
		background-color: rgba($bright-blue, 0.4);
	}
}

#editor-toolbox {
	position: absolute;

	.cke_toolgroup,
	.cke_combo_button {
		margin-right: 2px;
	}

	.cke_top {
		padding: 5px 3px;
	}

	.tool-main-section {
		.element-tools {
			.link-href-input {
				margin-right: 20px;
			}

			.remove-link-btn {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	.inline-select {
		display: inline-block;
		width: 100px;
	}

	.cke {
		@include opacity(1);
		margin: 0px;
		width: auto !important;
		@include box-shadow(none);
		border-top: none;
	}

	.up-step-int {
		width: 100px;
	}

	.video-input {
		color: $bright-blue;

		&.input-warning {
			color: $orange;
		}
	}

	.video-warning {
		background-color: $orange;
		color: $white;
		padding: 5px;
		margin-top: -2px;
		border-radius: 0 0 3px 3px;
	}

	.field-row,
	.action-row {
		position: relative;
		padding: 10px;
		border-bottom: 1px solid $grey-6;
		background-color: $white;

		&:hover:not(.no-hover) {
			background-color: $grey-1;
		}

		.field-tools,
		& > button {
			position: absolute;
			right: 0;
			top: 3px;
		}
	}

	.action-row {
		.number {
			color: $grey-8;
			margin-right: 5px;
		}

		#score-input {
			width: 50px;
			margin-top: -6px;
			text-align: center;
		}
	}

	.tool-header {
		.up-ios-toggle {
			margin-top: 5px;
			margin-right: 5px;
		}
	}

	.field-row {
		$handle-size: 24px;

		padding-left: $handle-size + 10px;

		&:hover {
			.toggle-field-open .desc-text {
				@include opacity(1);
				visibility: visible;
			}
		}

		.drag-handle {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: $handle-size;
			background-color: $grey-1;

			.fa {
				position: absolute;
				top: 50%;
				left: 0;
				width: $handle-size;
				height: $handle-size;
				line-height: $handle-size;
				margin-top: -($handle-size / 2);
				text-align: center;
			}
		}

		.field-row-header {
			.header-title {
				text-transform: uppercase;
				color: $grey-11;
				font-size: 10px;
				display: block;
				line-height: 10px;

				.fa {
					font-size: 10px;
					color: $red;
					font-size: 8px;
					position: absolute;
					top: 10px;
					margin-left: 3px;
				}

				&.has-error {
					color: $red;
				}
			}

			.header-subtitle {
				display: block;
				color: $black;
				font-size: 14px;
				min-height: 19px;
				width: 260px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.field-row-section {
			margin-bottom: 10px;

			&.field-toggle-row {
				.up-ios-toggle {
					margin-top: 0;
					margin-right: 10px;
				}

				label {
					line-height: 14px;
					font-weight: inherit;
				}

				.edit-company-addresses {
					float: right;
					cursor: pointer;
					color: $bright-blue;
				}

				.edit-company-addresses-inactive {
					float: right;
					color: $grey-10;
					cursor: default;

					&:hover {
						text-decoration: none;
					}
				}
			}

			&.field-title-row {
				margin-top: 5px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&.disabled {
				color: $grey-8;

				div {
					pointer-events: none;
				}
			}

			.language-input {
				max-width: 330px;

				.Select__selected {
					margin-top: 12px;
					height: 30px;
					// This color does no longer exist in colors.
					border-color: #ccc;
				}
			}
		}

		.text-grey {
			cursor: move;
		}

		.toggle-field-open {
			position: absolute;
			cursor: default;
			top: 16px;
			right: 10px;
			color: $grey-10;
			font-size: 10px;

			.desc-text {
				visibility: hidden;
				@include opacity(0);
				@include transition(all ease 200ms);
			}
		}

		.datatype-selector {
			padding: 0;
		}

		.option-input {
			width: 90px;
			display: inline-block;
			margin-right: 5px;
			margin-bottom: 5px;
		}

		.add-option-btn {
			margin-bottom: 5px;
		}
	}

	#s2id_add-field-input {
		position: absolute;
		bottom: -15px;
		left: 0;
		right: 0;
		display: none !important;

		&.select2-dropdown-open {
			display: block !important;
		}
	}

	.replace-form-overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		background: rgba($white, 0.8);
		z-index: 100000;

		@include transition(background ease 1s);

		div {
			text-align: center;
			width: 70%;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			button {
				display: block;
				margin: 0 auto;
				margin-top: 5px;
			}
		}
	}

	.cke_combo_button {
		.cke_combo_text {
			width: 40px;
			padding-left: 6px;

			&#cke_10_text {
				width: 24px;
				padding-left: 5px;
			}
		}

		.cke_combo_open {
			margin: 1px 6px;
		}
	}

	.cke_button {
		&.cke_button__removeformat {
			padding: 4px 6px 4px 0px !important;
			.cke_button__removeFormat_icon {
				display: none;
			}

			> .cke_button__removeformat_label {
				display: block;
			}
		}
	}

	span.cke_button_icon.cke_button__removeformat_icon {
		display: none;
	}
}
