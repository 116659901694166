@import "~StyleVariables";

.InlineAbortConfirm {
	&__wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		border-radius: $border-radius;
		padding: 12px;

		.Text {
			margin-bottom: 4px;
			text-align: left;

			.Icon {
				margin-right: 4px;
			}
		}
	}

	&__btn-wrap {		
		.Row {
			flex-wrap: nowrap;
		}
	}
}