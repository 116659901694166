@import "~StyleVariables";

.ShareButtons {
	.Text--md {
		margin-bottom: 13px;
	}

	.Row {
		margin: 0 -5px;
	}

	.Column {
		padding: 0 3px;
	}

	&__Copy,
	&__Copied {
		transition: opacity cubic-bezier(0, 0, 0.08, 1.27) 400ms;
		font-size: 12px;
		opacity: 0;
	}

	&__Text {
		margin-left: 14px;
		text-align: left;
		position: relative;
		top: 10px;
		transition: all cubic-bezier(0, 0, 0.08, 1.27) 400ms;

		.Button:hover & {
			top: 0;
		}
	}

	&__Copy,
	&__Copied {
		.Button:hover & {
			opacity: 1;
		}
	}

	&__List {
		align-items: center;
	}

	&__Button.up-btn.btn-block {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 14px;
		text-shadow: none;
		font-size: 14px;
		line-height: 20px;
		border: none;
		transition: all 200ms ease;
		overflow: hidden;

		&--copied {
			background-color: $medium-green !important;
		}
	}
}
