@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.WrappedPortedSalesProcess#admin {
	#admin-root {
		left: 0;
		.SalesProcess {
			left: 0;
		}
	}
}

.SalesProcess {
	.Table {
		table-layout: initial !important;
	}

	.TableRow--clickable {
		cursor: pointer !important;
	}

	.TableRow {
		&:hover {
			background-color: $grey-1 !important;
			.admin-row-tools-wrap .admin-row-tools {
				visibility: visible !important;
				opacity: 1 !important;
				right: 0 !important;
			}
		}

		.admin-row-tools-wrap {
			overflow: hidden;

			.admin-row-tools {
				position: relative;
				visibility: hidden;
				transition: all ease 200ms;
				@include opacity(0);
				right: -100%;
			}
		}

		td:first-child  {
			cursor: pointer;
		}

		.admin-row-tools-wrap {
			width: 60px;
			overflow: hidden !important;
			&:hover {
				overflow: initial !important;
			}

			.inline-confirm-modal .inline-confirm-tab {
				width: 54px;
			}
		}
	}
}
