@import "~StyleVariables";

.EditProductBundleOrder {
	// TODO: All this Modal specific styling should be removed once it has been fixed in the ui-components
	&.FullscreenModal--headerAtTop {
		overflow: hidden;
		grid-template-rows: auto auto auto 1fr;
		grid-template-columns: minmax(auto, 421px) auto auto;

		.ModalHeader__close {
			display: none;
		}

		.ModalContent__content {
			width: 100%;
			min-height: calc(50vh);
		}

		.ModalHeader,
		.ModalHeader__content {
			padding-right: 0;
		}

		.ModalSidebar {
			flex-direction: column;

			> .Block {
				width: 100%;
			}
		}
	}

	&__actionButtons {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: $space;
	}

	.BundleRow {
		&__unused {
			background-color: $grey-2;
		}

		&__product {
			max-width: 280px;
			.ClickableItem__title-col {
				width: 250px;
			}
		}
	}
}

.EditProductBundleOrder .ModalContent {
	padding: 0px;
}

.EditProductBundleOrder__Content {
	.NumberInput {
		width: 110px;
	}

	.TableRow:last-child {
		border-bottom: 1px solid $grey-2;
	}

	.TableHeader__column:last-child {
		padding-right: 16px;
		text-align: right;
	}

	.TableColumn--align-right {
		font-size: 12px;
		font-weight: bold;
	}

	.TableColumn .EditProductBundleOrder__sum-column {
		width: 250px;
	}

	.EditProductBundleOrder__add-product-btn {
		margin: $space * 2 $space * 4;
	}

	.DropDownMenu__content {
		margin-top: $space;
	}
}

.EditProductBundleOrder__ContentHeadline {
	.Icon {
		margin-right: 16px;
		font-size: 24px;
		line-height: 24px;
	}
}
