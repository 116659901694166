@import "~StyleVariables";

.AssignModalLead {
	.ModalHeader {
		border-top: none;
	}

	.ModalContent {
		margin: 46px 0 0 0;
		padding: 0;
	}

	.AssignModal__content {
		position: relative;
		left: 0;
	}

	.AssignModalAssign {
		margin-top: 0;

		&__step__title--locked {
			cursor: not-allowed;
		}
	}

	.select2-container {
		max-width: 300px;
	}

	&__Tabs {
		width: 250px;
		margin-bottom: 10px;
	}
}
