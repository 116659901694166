@import "../../../../styles/variables";

.ProspectingSignals {
	&__content {
		padding: 30px 30px 80px;
		max-width: 900px;
		.Card {
			&:first-child {
				margin-bottom: 0px;
			}

			margin-bottom: 30px;
		}
	}

	&__journey {
		padding: 20px;
		align-items: center;
	}

	&__journey-select {
		&.is-focused > .Select-control {
			border-color: $green !important;
		}

		.Select-value {
			background-color: $super-light-green;
			color: $green;
			&-icon {
				border-right: none;
			}
			&-icon:focus {
				color: $green;
			}
			&-icon:hover {
				color: $green;
			}
		}

		.Select-arrow-zone {
			border: none;
		}

		&__option {
			&.is-selected {
				background-color: $grey-2;
			}
			&.is-focused {
				background-color: $super-light-green;
			}
		}

		.Select-menu-outer {
			border-color: $green;
			border-top: none;
		}
	}

	&__following {
		background-color: $super-light-blue;
		border-radius: 6px;
		padding: 20px 20px;
		margin: 0px 20px;

		.Icon {
			margin-right: 8px;
		}
	}

	&__auto-update {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0px 20px 20px 20px;

		&-toggle {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
		}

		&-desc {
			color: $grey-10;
			margin-bottom: 20px;
		}
	}

	&__toggle-image {
		padding: 0px 0px 0px 20px;
		img {
			width: 360px;
			right: 0px;
			bottom: 0px;
		}

		.CardHeader {
			padding: 0px;
		}

		&--blue {
			.Title {
				color: $blue;
			}
		}

		&--padded {
			padding: 0px 20px 20px 20px;
		}
	}

	&__toggle-image > div:first-child {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.Icon {
			margin-right: 8px;
		}
	}
}
