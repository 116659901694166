#main-wrapper {
	#main-view {
		// position: absolute;
		// @include box-shadow(0 0 10px #ccc);
		margin: 0 $padding ($padding + 30px) $padding; // the + 30px is for the chat-box
		// padding-top: 31px;
		// background-color: $white;
		// right: 0;
		// left: 0;
		margin-left: 15px;

		#main-content {
			// margin-top: 58px;
			min-height: 100%;
			height: 1px; // To let children fill up space with height: 100%
			.page-head {
				display: flex;
				margin: 0;
				padding: 0;
				position: fixed;
				right: $padding;
				top: $main-top;
				left: 65px;
				height: $header-height;
				z-index: 1029;
				// min-width: 1024px;

				.content {
					display: block;
					padding: 12px 10px;

					h2 {
						@include h2();
						vertical-align: middle;
						margin: 15px 0 0 12px;
					}
				}

				.note {
					color: $grey-11;
				}
			}

			.page-content {
				margin-top: $header-height;

				&.is-list {
					margin-top: 63px + 31px;
				}
			}
		}
	}
}

.up-header {
	position: relative;
	display: flex;
	align-items: center;
	color: $grey-8;
	height: $header-height;
	color: $black;
	z-index: 1001;
	background-color: $white;
	line-height: $header-height;
	@include border-radius($border-radius $border-radius 0 0);

	&.up-header-sm {
		height: $header-height-sm;
		line-height: $header-height-sm;

		ul.nav {
			@include sub-page-navigation($header-height-sm - 3px);
			border: none;
			background-color: transparent;
		}

		.header-title,
		#title h2 {
			font-size: 16px;
		}

		.actions {
			button.up-btn.card-header-btn {
				height: $header-height-sm;
				line-height: $header-height-sm;
			}

			.up-btn.header-btn,
			& > button.up-btn {
				margin-top: 4px;
				margin-right: 4px;
				vertical-align: initial;
			}

			.btn-group.header-btn-group,
			& > .btn-group {
				margin-right: 10px;
				.up-btn {
					margin-right: 0;
				}
			}
		}
	}

	&.fixed {
		position: fixed;
		right: $padding;
		top: $main-top;
		left: 65px;
		z-index: 1029;
	}
	// styles
	&.blue {
	}

	&.border-top {
		&.up-header-sm {
			.actions {
				button.up-btn.card-header-btn {
					height: $header-height-sm - 4px;
					line-height: $header-height-sm - 4px;
				}
			}
		}
	}

	& > .btn-group {
		margin-left: 10px;
	}

	&.new-filters {
		line-height: 20px;

		& > .btn-group {
			margin-top: 6px;

			.title {
				float: left;
				margin-top: 10px;
				margin-right: 5px;
			}
		}

		.list-change-button {
			height: 100%;
			border-radius: 0;
			transition: all ease-out 200ms;
			white-space: nowrap;
			color: rgba($black, 0.6);
			background-color: $white;
			position: relative;
			font-weight: $boldFontWeight;
			outline: none;

			&.current {
				color: $green;
				border-bottom: 4px solid $green;
				top: 1px;
			}

			&:active {
				box-shadow: none;
			}
		}
	}

	.subtitle {
		text-transform: uppercase;
		font-size: 10px;
	}

	// .header-title,
	// #title {
	// 	display: inline-block !important;
	// 	color: $white;
	// }

	.header-title,
	#title {
		padding: 0 $padding;
		float: left;
		line-height: inherit;

		h2 {
			color: $green;
		}
	}

	ul.nav {
		@include sub-page-navigation($header-height);
		background-color: transparent;
		margin: 0;
		border: none;
		height: 100%;
		border-top: none;
		margin-top: -1px;
	}

	.actions {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		line-height: initial;

		& > * {
			vertical-align: middle;
		}

		.ButtonSelect {
			margin-right: 10px;
		}

		& > button.up-btn {
			margin-right: 10px;
			margin-top: 12px;
		}

		button.up-btn {
			&.back-button {
				position: relative;
				right: 8px;
				color: $grey-10;
				font-size: 14px;
				height: 100%;
				margin: 0;
			}

			&.card-header-btn {
				height: $header-height;
				line-height: $header-height;
				@include border-radius(0);
				box-shadow: none;
				border: none;
				padding: 0 20px;
				margin: 0;
				border-left: 1px solid $grey-4;
				// border-bottom: 1px solid $grey-8;

				&:last-of-type {
					@include border-radius(0 $border-radius 0 0);
				}

				&.ad-status-btn {
					position: relative;
					line-height: initial;
					text-align: left;
					padding: 0 40px 0 30px;
					background-color: $grey-4;
					border-bottom: 1px solid $grey-8;

					.subtitle {
						color: $grey-10;
						display: block;
					}

					.caret-wrapper {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						height: 100%;
						width: 30px;
						text-align: center;

						.fa {
							position: relative;
							text-align: center;
							margin: 22px auto;
							border-top-color: rgba($black, 0.3);
						}

						.caret {
							position: relative;
							text-align: center;
							margin: 28px auto;
							border-top-color: rgba($black, 0.3);
						}
					}

					&.active {
						background-color: rgba($bright-green, 0.1);
						color: $bright-green;

						&:hover {
							background-color: rgba($bright-green, 0.2);
						}
					}

					&.passive {
						background-color: $grey-4;
						color: $grey-13;

						&:hover {
							background-color: darken($grey-4, 10);
						}
					}

					&.error {
						background-color: rgba($orange, 0.1);
						color: $orange;

						&:hover {
							background-color: rgba($orange, 0.2);
						}
					}

					.delete-btn {
						&:hover {
							background-color: $red !important;
						}
					}
				}
			}
		}

		.dropdown.card-header-dropdown {
			display: inline-block !important;

			.dropdown-menu {
				border-top: 0;
				border-color: $grey-4;
				box-shadow: none;
				margin-top: 0;
				padding: 0;
				left: 0;
				right: auto;
				width: 100%;
				@include box-shadow(1px 1px 2px rgba($black, 0.1));
				@include border-radius(0 0 $border-radius $border-radius);
				@include transition(all ease 200ms);

				li a {
					padding: 10px 10px;
					@include transition(all ease 200ms);

					&:hover {
						background-color: $grey-1;
					}

					i {
						color: $grey-8;
					}
				}

				&.ad-status-dropdown {
					a.launch {
						color: $bright-green;
						background-color: $white;

						.fa.fa-play {
							color: $bright-green;
						}

						&:hover {
							color: $green;
							background-color: rgba($bright-green, 0.2);
						}
					}

					a.finish {
						color: $orange;
						background-color: $white;

						.fa.fa-stop {
							color: $orange;
						}

						&:hover {
							background-color: rgba($orange, 0.1);
						}
					}
				}
			}
		}
	}

	.up-header-pagination {
		position: relative;
		padding-left: $padding;
		display: table;
		text-align: center;
		display: inline-block;

		.inner {
			display: table-row;
		}

		li {
			position: relative;
			display: inline-block;
			display: table-cell;
			text-align: center;
			padding: 0 $padding;
			line-height: 57px;
			@include transition(all ease 200ms);

			&:hover {
				background-color: rgba($grey-13, 0.05);
				color: rgba($blue, 0.8);
				text-decoration: none;
			}

			&.active {
				border-bottom: 3px solid $blue;
				line-height: 50px;

				a {
					color: $blue;
				}

				&:hover {
					background-color: transparent;
				}
			}

			a {
				color: rgba($blue, 0.6);
				padding: 5px 6px;
				text-align: center;
				text-decoration: none;
			}
		}
	}

	#view-tools {
		display: inline-block;
		margin-left: 10px;
	}
}

#header-extension-container {
	display: inline-block;
}
