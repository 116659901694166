@import "../MultiActionModal.scss";

@include ModalTagList("MultiUpdateClient");

.MultiUpdateClient {
	.NumberInput {
		.Input {
			width: 100%;
		}
	}

	.TimeInput__listContainer,
	.DateInput__popup {
		display: none;

		&--open {
			display: unset;
			z-index: 1010;
		}
	}

	&__create-campaign {
		float: right;
		font-weight: 400;
	}
	&__custom-field-select {
		margin-top: 30px;
	}
	&__custom-title {
		margin-top: 4px;
	}
	&__custom-field-input {
		width: 100%;
	}
	.NumberInput {
		.Input {
			width: 100%;
		}
	}
	.TimeInput__listContainer,
	.DateInput__popup {
		display: none;

		&--open {
			position: fixed;
			display: unset;
			overflow-y: auto;
			height: 150px;
			z-index: 1006;
		}
	}
}
