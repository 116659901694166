@import "~StyleVariables";

.MatchCompaniesBeforeMerge {
	grid-template-rows: 0.5fr auto 1fr;
	--modalContentWidth: 600px;

	.StateFrame__title-column > .StateFrame__subtitle {
		font-size: 14px;
	}
}
