@import "~StyleVariables";

.Widget {
	margin: 10px 20px 0 20px;

	&--noMargin {
		margin: 0;
	}

	.Widget__card {
		height: 150px;
		border-radius: 3px;
		border: 1px solid $grey-4;
		transition: all ease 200ms;

		&--no-goal {
			height: 120px;
		}

		&--no-box-shadow {
			box-shadow: none;
		}

		&--has-prognosis-label {
			height: 180px;
		}
	}

	.Widget__icon-background {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 28px;
		width: 28px;
		margin-top: 21px;
		margin-right: -8px;
		border-radius: 50%;

		&:hover {
			cursor: pointer;
		}

		&--pinned {
			background-color: $grey-4;
		}

		.helper {
			height: 100%;
			display: inline-block;
			vertical-align: middle;
		}

		.Icon {
			display: inline-block;
			vertical-align: middle;
		}
	}

	.CardHeader {
		text-align: left;
	}
	.CardHeader__title {
		color: $black;
	}

	.Widget__progress-bar {
		height: 10px;
		margin-top: 10px;
		position: relative;
	}

	.Widget__grid {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		margin-top: 15px;

		&--no-goal {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}

	.Widget__grid-item {
		display: grid;
		grid-template-columns: auto;
		font-family: Roboto;
		padding-right: 2 * $space;

		&--no-goal {
			text-align: center;
		}
	}

	.Widget__grid-item-value {
		color: $grey-13;
		font-size: 16px;
		line-height: 19px;
		text-align: left;

		&--reachedGoal {
			color: $medium-green;
		}
	}

	.Widget__grid-item-value-currency {
		font-size: 60%;
		vertical-align: top;
		margin-top: -3px;
		margin-left: 1px;
		display: inline-block;

		&--prognosis {
			margin-right: 4px;
		}
	}

	.Widget__grid-item-oval {
		float: left;
		top: 5px;
		position: relative;
		margin-right: 5px;
		border-radius: 100%;
		width: 7px;
		height: 8px;
		background-color: $grey-6;
		&--progress {
			background-color: $bright-green;
		}

		&--progress-with-prognosis {
			background-color: $medium-green;
		}

		&--prognosis {
			background-color: $bright-green;
			top: 0;
		}
	}

	.Widget__grid-item-label {
		color: $grey-13;
		font-family: Roboto;
		font-size: 13px;
		line-height: 16px;
		text-align: left;
	}

	.DropDownButton__wrapper {
		height: auto !important;
		transition: all ease 200ms;

		.Icon {
			opacity: 0;
		}
		&:hover {
			color: $bright-blue;

			.Icon {
				opacity: 1;
				color: $bright-blue;
				margin-left: 5px;
			}
			.Icon-caret-down {
				display: none;
			}
		}
	}

	.DropDownButton__text-wrapper {
		padding-left: 0px;
		padding-right: 5px;
		display: flex;
		align-items: center;
	}

	.DropDownButton--sm {
		height: auto !important;
	}

	.DropDownButton--expanded {
		color: $bright-blue;
		.Icon {
			opacity: 1;
			color: $bright-blue;
			margin-left: 5px;
		}
		.Icon-caret-down {
			display: none;
		}
	}
	.DropDownMenu__content {
		min-width: 200px;
	}

	.TableColumn {
		border: none;

		.Text {
			&:hover {
				cursor: pointer;
			}
		}

		.Icon {
			opacity: 0;
		}

		&--selected {
			.Icon {
				opacity: 1;
			}
		}
	}

	&:hover {
		.Widget__card {
			box-shadow: 0 2px 4px rgba($black, 0.2);
			border: 1px solid $bright-blue;
		}
	}

	&__prognosis-bar {
		.Progressbar__progress {
			transition: all 500ms;
		}
	}

	&__prognosis-label {
		border-top: 1px solid $grey-4;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		height: 32px;
		animation: "prognosisLabel" 500ms forwards;
	}

	@keyframes prognosisLabel {
		from {
			opacity: 0;
			transform: translateY(-50%);
		}
		to {
			opacity: 1;
			transform: translateY(0%);
		}
	}
}
