@import "~StyleVariables";

.GenericSelectEntityModal {
	.Loader {
		margin: 0 auto;
	}

	.ModalContent {
		padding: 0;
	}

	.Table {
		background-color: $white;
	}

	&__row--selected {
		background-color: $grey-2;
	}

	&__column--child {
		padding-left: 6 * $space;
	}
}
