@import "~StyleVariables";

.ProcessedBy {
    &__container {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-direction: row;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &--red {
            color: $red;
        }

        &--last-assigned-text {
            margin-left: $space;
        }
    }

    &__subtitle {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 4px;
    }
}