@import "~StyleVariables";

.TemplateCard {
	position: relative;
	background-color: $grey-1;
	border-radius: 2px;
	box-shadow: 0 1px 4px 0 rgba($black, 0.2);
	width: 200px;
	height: 330px;
	overflow: hidden;
	transition: all ease-out 200ms;
	display: inline-block;
	margin: 0 10px 15px;
	vertical-align: top;
	background-size: contain;
	background-image: none;
	background-repeat: no-repeat;
	z-index: 0;
}

.TemplateCard--delete .TemplateCard__delete-confirm {
	.TemplateCard__delete-confirm-inner {
		opacity: 1;
		visibility: visible;
		transition-delay: 250ms;
		top: 0;
	}

	&:before {
		transform: scale(630);
		transition-delay: 0ms;
	}
}

.TemplateCard__overlay {
	visibility: hidden;
	opacity: 0;
	padding: 80px 24px 0 24px;
	height: 100%;
	transition: all ease-out 200ms;
}

.TemplateCard__overlay:before {
	content: "";
	width: 100%;
	height: 100%;
	background: linear-gradient($green, $medium-green);
	opacity: 0.8;
	position: absolute;
	top: 0;
	left: 0;
}

.TemplateCard__overlay .Button {
	margin-bottom: 10px;
	position: relative;
}

.TemplateCard__delete {
	position: absolute !important;
	top: 10px;
	right: 10px;
}

.TemplateCard__multiSelect {
	position: absolute !important;
	top: 20px;
	left: 24px;
	visibility: hidden;
	opacity: 0;
	transition: opacity ease-out 200ms;
	z-index: 1000;
}

.TemplateCard__multiSelect--checked {
	visibility: visible;
	opacity: 1;
}

.TemplateCard__text-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	padding: 0 15px;
	background-color: $white;
	border-top: 1px solid $grey-6;
	z-index: 1000;
}

.TemplateCard__text-wrap .Text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: all ease-out 200ms;
}

$textHeight: 44px;
$textHeightWithLabel: 33px;

.TemplateCard__name-wrap {
	height: $textHeight;
	transition: all ease-out 200ms;

	.Text {
		line-height: $textHeight;
	}
}

.TemplateCard__text-wrap--hasLabels {
	.TemplateCard__name-wrap {
		height: $textHeightWithLabel;

		.Text {
			line-height: $textHeightWithLabel;
		}
	}
}

.TemplateCard__label-wrap {
	height: 24px;
}

.TemplateCard__subtitle {
	visibility: hidden;
	opacity: 0;
	line-height: 16px !important;
	margin-top: -5px;
}

.TemplateCard__delete-confirm-inner {
	position: absolute;
	top: -10px;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	padding: 100px 15px 64px;
	text-align: center;
	transition: all ease 200ms;
	transition-delay: 0ms;
}

.TemplateCard__delete-confirm-inner .Title {
	margin-bottom: 5px;
}

.TemplateCard__delete-confirm-inner .Text {
	margin-bottom: 30px;
}

.TemplateCard__delete-confirm:before {
	content: "";
	background-color: $white;
	border-radius: 50%;
	position: absolute;
	height: 1px;
	width: 1px;
	top: 25px;
	right: 24px;
	transform: scale(1);
	transition: all ease-in 200ms;
	transition-delay: 250ms;
}

.TemplateCard--delete,
.TemplateCard:hover {
	box-shadow: 0 5px 10px 0 rgba($black, 0.2);

	.TemplateCard__overlay {
		visibility: visible;
		opacity: 1;
	}

	.TemplateCard__name-wrap {
		height: 64px;
	}

	.TemplateCard__name-wrap .Text {
		line-height: 38px;
	}

	.TemplateCard__subtitle {
		visibility: visible;
		opacity: 1;
	}

	.TemplateCard__multiSelect {
		visibility: visible;
		opacity: 1;
	}
}
