@import "~StyleVariables";

$toggle-line-height: 40px;

.SalesboardCard__card {
	margin-bottom: 20px;
}

.SalesboardCard__content {
	padding: 30px 30px 80px;
	max-width: 900px;
}

@keyframes slide-up {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.SalesboardCard__zone-toggle-row {
	line-height: $toggle-line-height;
	height: $toggle-line-height;
	border-bottom: 1px solid $grey-4;

	&__opportunityAI {
		animation: slide-up 0.4s ease;
		.Text {
			display: inline-block;
		}
	}

	._elevio_underline {
		margin-left: 4px;
	}
}

.SalesboardCard__zone-toggle-row .Label {
	display: inline-block;
	line-height: $toggle-line-height;
	height: $toggle-line-height;
}

.SalesboardCard__zone-toggle-row .Toggle {
	z-index: 1000;
	float: right;
	margin-top: 8px;
}

.SalesboardCard__ghost-card {
	background-color: $grey-1;
	border-radius: 3px;
	height: 70px;
	margin: 15px auto;
	width: 300px;
}

.SalesboardCard__fake-card {
	overflow: hidden;
	width: 300px;
	margin: 0 auto;
	cursor: default;
}

.SalesboardCard__fake-card:hover {
	background-color: $white;
}

.SalesboardCard__fake-card__recommendations {
	.Text {
		display: inline;
		background-color: $grey-3;
		margin-left: 4px;
		border-radius: 4px;
		padding: 4px;
	}
}

.SalesboardCard__fake-card--editable-zone {
	position: relative;
	cursor: pointer;
}

.SalesboardCard__fake-card--zone-content {
	position: relative;
	z-index: 1001;
}

.SalesboardCard__fake-card--selected-zone {
	visibility: visible;
	background-color: rgba($bright-blue, 0.3);
	opacity: 1;
}

.SalesboardCard__fake-card--editable-zone:before {
	$offset: 0;
	content: "";
	visibility: hidden;
	position: absolute;
	top: $offset;
	bottom: $offset;
	left: $offset;
	right: $offset;
	border: 1px solid transparent;
	background-color: rgba($grey-8, 0.3);
	border-radius: $border-radius-sm;
	opacity: 0;
	transition: all ease 200ms;
	z-index: 1000;
}

.SalesboardCard__fake-card--editable-zone:hover:before {
	opacity: 1;
	visibility: visible;
	border: 1px solid rgba($black, 0.5);
}

.SalesboardCard__zone-settings {
	padding: 10px 0;
}

.SalesboardCard__none-selected {
	text-align: center;
	margin-top: 120px;
}

.SalesboardCard__separator {
	text-align: center;
}
