@import "~StyleVariables";

.PageSidebarGroup {
	&__title {
		position: relative;
		padding: 0 20px;
		height: 30px;
		display: flex;
		align-items: center;

		.Icon-code-fork {
			transform: rotate(180deg);
		}

		.Text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&--expandable {
			cursor: pointer;
		}
	}

	&__expander {
		position: absolute;
		right: 20px;
		color: $grey-7;
		transition: all ease-out 200ms;

		&:hover {
			color: $grey-10;
		}
	}

	&__sectionIcon {
		font-size: 14px;
	}

	&--open {
		.PageSidebarGroup__expander {
			transform: rotate(180deg);
			position: absolute;
			right: 20px;
		}

		.PageSidebarGroup--closed {
			.PageSidebarGroup__expander {
				transform: rotate(0deg);
			}
		}
	}

	&--closed {
		.PageSidebarGroup__expander {
			position: absolute;
			right: 20px;
			color: $grey-7;
			transition: all ease-out 200ms;

			&:hover {
				color: $grey-10;
			}
		}
	}
}

.PageSidebarGroup__content-enter {
	opacity: 0;
	height: 0%;
}

.PageSidebarGroup__content-enter-active {
	opacity: 1;
	height: 100%;
	transition: all 200ms;
}

.PageSidebarGroup__content-exit {
	opacity: 1;
	height: 100%;
}

.PageSidebarGroup__content-exit-active {
	opacity: 0;
	height: 0%;
	transition: all 200ms;
}
