@import "~StyleVariables";

.ConfirmBuyProspect {

    &__up-modal-header {
        border-bottom: 0;
        height: 0;
    }

    &__up-modal-content {
        height: 100%;
        width: 100%;
        padding: 0;

        .ModalContent__content {
            height: 100%;
            width: 100%;
            

            .section {
                border-bottom: 1px solid rgba(0, 0, 0, .1);
                padding: 20px 40px 20px;

                &-white-bg {
                    @extend .section;
                    background-color: white;
                    padding: 40px 40px 20px;

                    .Title {
                        margin-bottom: $padding;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            .ConfirmBuyProspect__select-me {
                margin-top: $space;
                float: right;
                font-size: 12px;
            }

            .ConfirmBuyProspect__create-campaign {
                float: right;
                font-size: 12px;
                font-weight: 400;
            }

            .ConfirmBuyProspect__add-company-data {
                margin-bottom: $space * 4;
            }

            .ConfirmBuyProspect__required-icon {
                margin-left: $space / 2;
                line-height: 14px;
            }

            .ConfirmBuyProspect__add-to-campaign-category {
                margin-bottom: $space * 2;
            }
        }
    }

    &__up-modal-controls {
        .ModalControls__content {
            display: flex;
            align-items: center;

            button.btn.btn-block.up-btn.btn-link.btn-grey {
                margin: 0;
                font-size: 12px;
            }
        }
    }
}