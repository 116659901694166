@import "~StyleVariables";

.CreateTemplateFlowParticipants {
	width: 616px;
}

.CreateTemplateFlowParticipants .Loader {
	margin-bottom: 10px;
}

.CreateTemplateFlowParticipants__info {
	padding: 16px;
	border-radius: $border-radius;
	border: 1px solid rgba($medium-blue, 0.2);
}
