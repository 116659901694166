.ImageUrl {
	.ModalContent > div {
		height: 60px;

		.Loader {
			float: right;
		}
	}

	.ModalControls > button {
		margin-right: 10px;
	}
}
