@import "~StyleVariables";

.NewSingleMail {
	height: 100%;
	background-color: $white;
	width: 750px;
	overflow-x: hidden;

	.DrawerHeader {
		.NewSingleMail__rightSide {
			display: flex;
			align-items: center;
			gap: 16px;
			margin-right: 16px;
		}
	}
}
