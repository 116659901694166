@import "../../../styles/variables";

.AdminBrokenFiscalYear {
	.pull-right {
		display: flex;
		align-items: center;
	}
}

.AccountSettings__elevio-wrap {
	display: flex;
	margin-top: 18px;
	display: inline-block;
	border-bottom: none;
	color: $bright-blue;
}

.AdminBrokenFiscalYear__question-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 283px;
	margin-bottom: 20px;

	.Text {
		font-weight: $boldFontWeight;
	}
}

.AdminBrokenFiscalYear__display-card {
	border: 1px solid $grey-6;
	border-radius: 2px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	width: 310px;
	height: 74px;
	padding: 15px;
	margin-bottom: 2px;
}

.AdminBrokenFiscalYear__display-card .Button {
	height: 22px;
	line-height: 22px;
	font-size: 14px;
	margin-left: auto;
	padding: 0;
}

#admin-content {
	position: relative;
}

#admin-page-languagesettings {
	.admin-table {
		.label-elevio-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.select2-container-disabled {
			.select2-arrow {
				display: none;
			}
		}

		.tandard-language-wrap {
			width: 300px;
			padding-top: 20px;
		}

		.title {
			font-size: 18px;
		}

		.inline-edit-select2--wrap {
			position: relative;
			padding-top: 6px;
		}

		.inline-edit-select2--label {
			position: absolute;
			font-size: 1em;
			color: $grey-10;
			transition: all ease 0.2s;
			top: 16px;
			left: 2px;

			&.has-value {
				font-size: 12px;
				top: 0;
				left: 2px;
			}
		}

		.inline-edit-select2 {
			width: 100%;
			top: -2px;
			box-shadow: none;

			.select2-choice {
				background: 0 0;
				border: none;
				display: block;
				margin: 0;
				padding: 0;
				border-radius: 0;
				border-bottom: 1px solid $grey-9;
				outline: 0;
			}

			.select2-chosen {
				position: relative;
				top: 8px;
				padding-left: 3px;
				display: block;
			}

			.select2-default {
				.select2-chosen {
					display: none;
				}
			}

			.select2-search-choice-close {
				margin-top: 0px;
			}

			.select2-arrow {
				border-left: 0;
				top: 7px;
			}

			button {
				visibility: visible;
				width: 120px;
				overflow: hidden;
				transition: all ease 200ms;
				margin-left: 15px;
				margin-top: 4px;

				&.btn-hidden {
					visibility: hidden;
					width: 0;
					margin-left: 0;
					padding: 0;
				}
			}

			&.select2-dropdown-open {
				.select2-choice {
					border-bottom-color: $bright-blue;
				}
			}
		}

		&.language__no-master-language {
			overflow: auto;

			.admin-table-content {
				margin: 0px 20px 20px 20px;
				display: flex;
			}

			.language__no-master-language__left {
				display: inline-block;
				width: 50%;

				& > div:nth-child(1) {
					font-size: 16px;
					font-weight: $boldFontWeight;
					margin-bottom: 10px;
					margin-top: 10px;
				}

				& > div:nth-child(2) {
					margin-bottom: 25px;
					color: $grey-10;
				}
			}

			.language__no-master-language__right {
				justify-content: center;
				align-items: center;
				display: inline-flex;
				width: 50%;
			}
		}

		.language__row--delete-btn {
			padding: 0;
			height: 40px;
			width: 0;
			overflow: hidden;
			visibility: hidden;
			vertical-align: top;
			opacity: 0;
			border-left: 1px solid transparent;
			transition: all ease 200ms;

			&.is_expanded {
				border-left: 1px solid rgba($black, 0.05);
				color: $red;
				width: 100%;
				opacity: 1;
				visibility: visible;
			}

			&:hover {
				color: $red;
			}
		}

		.language__row--delete-confirm {
			display: flex;
			justify-content: center;
		}

		.DropDownMenu__content {
			max-width: 250px;
			min-width: 250px;
			background: inherit;
			white-space: normal;
			top: 40px;

			.Text {
				margin-bottom: 10px;
			}
		}

		.Card,
		.DropDownMenu__content {
			border-radius: 3px;
			border-top-right-radius: 0;
		}

		.language__add-row {
			height: 62px;

			td {
				background-color: #f5f5f5;
			}

			.form-control {
				width: 300px;
			}

			.language__add-row--content-wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}

	.language__row-tools-dropdown {
		display: flex;
		justify-content: flex-end;
	}

	table {
		tr {
			.language-col {
				width: 200px;
			}

			.button-col {
				width: 60px;
			}

			.admin-table-token {
				cursor: pointer;
				color: $bright-blue;

				&:hover {
					opacity: 0.8;
				}
			}

			.fa-spinner {
				margin-left: 14px;
				color: $red;
			}

			td {
				overflow: visible !important;
			}

			&:hover {
				.language__row--delete-btn {
					width: 100%;
					opacity: 1;
					visibility: visible;
				}
			}
		}

		td.no-missing {
			font-style: italic;
			color: $grey-10;
		}
	}

	.AccountSettings--regionSelect {
		width: 160px;
	}
}

.AccountSettings__AutoAddProspectingData .CardHeader__actions {
	display: flex;
	align-items: center;
}
