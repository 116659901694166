@import "~StyleVariables";

.GroupSelector {
	$light-blue: #a3c6f5;

	--iconButton-bg-color: #{$grey-4};
	--subtitle-color: #{$black};

	z-index: 1001;
	margin-right: 1px;
	&__subaccount {
		transition: all 0.3s ease-in-out;
		padding: $space * 2 $space * 4;
		border-radius: $border-radius;
		width: 100%;
		background-color: $light-blue;

		.GroupSelector__iconButton {
			transition: background-color 0.3s ease-in-out;
			cursor: pointer;
			opacity: 1;
			background-color: unset;
		}

		&__city {
			max-width: 180px;
			color: var(--subtitle-color);
		}

		&__name {
			max-width: 200px;
			color: var(--subtitle-color);
		}

		&:hover {
			border-radius: 0;
			margin-left: $space * -4;
			width: calc(100% + #{$space * 8});
			padding: $space * 2 $space * 8;

			.GroupSelector__iconButton {
				background-color: var(--iconButton-bg-color);
			}
		}

		&--isSubaccount:not(#{&}--isInactive) {
			--iconButton-bg-color: #{darken($light-blue, 5%)};
			--subtitle-color: #{$dark-blue};
		}
	}

	&__renderTrigger {
		transition: all 0.3s ease-in-out;
		padding: $space * 2 $space * 4;
		border-radius: $border-radius;
		width: 100%;
		background-color: $grey-1;
		cursor: pointer;

		&:hover {
			background-color: $grey-2;
			border-radius: 0;
			margin-left: $space * -4;
			width: calc(100% + #{$space * 8});
			padding: $space * 2 $space * 8;

			.GroupSelector__iconButton {
				opacity: 1;
			}
		}
	}

	.DropDownMenu {
		width: 100%;
		&__content {
			top: 0px;
		}
	}

	&__iconButton {
		transition: opacity 0.3s ease-in-out;
		background-color: var(--iconButton-bg-color);
		border-radius: $border-radius;
		height: 24px;
		opacity: 0;
		width: 24px;

		.Icon {
			color: var(--subtitle-color);
		}
	}

	&__content {
		border: 1px solid $grey-6;
		width: 600px;

		&__headline {
			padding-right: $space * 3;
			.Icon {
				cursor: pointer;
			}
		}

		.Input {
			width: stretch;

			&__input {
				border-radius: $border-radius;
			}
		}

		hr {
			margin: 0 $space * 3;
		}

		&__empty {
			height: 240px;
			background-color: $grey-1;
			border-radius: $border-radius;

			img {
				height: 100px;
			}
		}

		&__companies {
			max-height: 400px;
			overflow-y: auto;

			&__company {
				height: 61px;
				transition: background-color 0.3s ease-in-out;
				cursor: pointer;

				> .Toggle {
					padding-right: 30px;
				}

				> .Flex {
					height: 100%;
					width: 100%;
				}

				&__name,
				&__city {
					width: 300px;
				}

				&__visit {
					opacity: 0;
					transition: opacity 0.3s ease-in;
				}

				&:hover {
					background-color: $grey-2;

					.GroupSelector__content__companies__company__visit {
						opacity: 1;
					}
				}
			}
		}

		&__noCompanies {
			height: 100px;
		}
	}

	&__toggles {
		margin-top: $space * 6;
		margin-bottom: $space * 6;
		margin-left: $space * 4;
		margin-right: $space * 4;
	}

	&__includeAllSubaccountsToggle {
		margin-right: $space * 4;
	}

	.flag-icon {
		border-radius: $space * 2;
	}
}
