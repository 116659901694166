@import "~StyleVariables";
.MailEditorReviewSection {
	width: 816px;
	margin: 15px auto 0;

	.CardContent {
		padding-bottom: 25px;
	}

	.Label {
		position: relative;
		font-weight: $boldFontWeight;
	}

	.select2-container {
		width: 100%;
	}
}
