.TicketRelateTo {
	&__headline {
		height: 30px;
	}

	.Label {
		// /**
		// * @todo  @upsales/components 2.19.19
		// * STYLING: label has a hard coded margin-bottom that is not needed.
		// * @link https://github.com/upsales/ui-components/issues/570
		// */
		margin-bottom: 0px;
	}
}
