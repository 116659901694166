@import "~StyleVariables";

.ReportcenterFilterHeader {
	cursor: pointer;
	transition: all ease-out 200ms;

	&:hover {
		background-color: $grey-1;
	}

	.Block {
		width: 100%;
	}
	
	.Column {
		align-items: center;
		display: flex;

		.Text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}

		.Checkbox {
			flex-shrink: 0;
			background: $grey-5;

			&:hover:not(.Checkbox--disabled):not(.Checkbox--checked) {
				background: $grey-6;
			}

			&--checked {
				background: $green;
			}
		}
	}	

	&__chevron {
		transition: all 0.3s ease;
		&--open {
			transition: all 0.3s ease;
			transform: rotate(180deg);
		}
	}
}
