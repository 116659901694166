@import "~StyleVariables";

.ShownInStages {
	&__selectedStages {
		display: inline-block;
		width: fit-content;
		white-space: nowrap;
		background-color: $super-light-green;
		margin-right: 4px;
		padding: 4px 8px;
		border-radius: $border-radius;
		cursor: pointer;
	}

	&__hiddenStages {
		cursor: pointer;
		display: inline-block;
	}
}
