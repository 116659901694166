@import "~StyleVariables";

.ProjectBoard {
	$headerHeight: 50px;

	&__board-scroll-area,
	&__header {
		transition: margin-right 100ms ease-in; // This will have to match filters animation
	}

	&--filters-visible &__board-scroll-area,
	&--filters-visible &__header {
		margin-right: 300px;
	}

	&__header {
		height: $headerHeight;
		position: sticky;
		top: 0;
		z-index: 60;
		background-color: $white;
	}

	&__board-scroll-area {
		overflow: auto;
		height: calc(100vh - #{$headerHeight});
	}
}
