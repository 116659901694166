@import "~StyleVariables";

.DistributionBarWidget {
	display: flex;
	flex-flow: column;

	.Row {
		row-gap: 15px;
		flex-wrap: nowrap;
	}

	&__progress-bar-wrapper:hover {
		cursor: pointer;
	}

	&__tooltip {
		.Row {
			margin-top: 10px;
		}
	}

	&__info-column {
		margin-left: 20px;
		font-family: Roboto;
		position: relative;

		&--right-align>.Row {
			flex-direction: row-reverse;
		}

		&--center-align {
			flex-basis: max-content;
			flex-grow: 0;
		}
	}

	&__info-row-label {
		>.Text {
			line-height: 16px;
		}

		.Icon {
			font-size: 12px;
			
			&:hover {
				cursor: pointer;
			}
		}
	}

	&__info-row-dot {
		float: left;
		top: 5px;
		left: -18px;
		position: absolute;
		border-radius: 100%;
		width: 10px;
		height: 10px;
	}
}