.up-modal.CloseOpportunityModal--root {
	width: 550px;
	margin-left: -250px;
	height: 200px;

	.Modal {
		position: relative;
		margin: 0 auto;
		padding: 0;
		width: 550px;
	}
}

.CloseOpportunityModal {
	.ModalHeader {
		box-shadow: 0 0px 2px 0px rgba(30, 37, 46, 0.2);

		.Title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 20px;
		}
	}

	.ModalContent {
		background-color: white;
		text-align: center;
		padding: 0;

		.CloseOpportunityModal--Card-Header {
			background-color: #f6f9fb;
			box-shadow: none;
			text-align: left;
			min-height: 125px;

			.CardContent {
				width: 65%;

				.Text {
					color: #6b7c93;
				}
			}

			.Card__slice:before {
				width: 240px;
			}

			img {
				position: absolute;
				width: 105px;
				top: 22px;
				right: 35px;
				z-index: 500;
			}

			.Card__slice {
				z-index: 100;
			}
		}

		.CloseOpportunityModal--Actual-content {
			--gap: 10px;

			padding: 15px;
			text-align: left;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			overflow-y: auto;
			gap: var(--gap);

			max-height: 500px;

			label {
				font-size: smaller;
			}

			.half {
				padding: 0;
				width: calc(50% - var(--gap));
			}

			.CloseOpportunityModal--Set-today {
				width: 260px;
			}

			.ButtonGroup {
				margin-top: 5px;
				width: 260px;
			}

			.Button {
				height: 30px;
				line-height: 0;
			}
		}
	}

	.ModalControls {
		.Toggle-Wrapper {
			width: 300px;
			float: left;

			.Toggle {
				float: left;
				margin-top: 6px;
			}

			.Text {
				float: left;
				padding-left: 10px;
				line-height: 18px;
				text-align: left;
				width: 158px;
			}
		}
	}
}
