@import "~StyleVariables";
@import "~StyleMixins";
@import "~StyleAnimations";

.ConfirmEsign {
	#esign-version-warning {
		color: $orange;
		text-align: center;
	}

	#esign-sending-curtain {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $grey-1;
		visibility: hidden;
		z-index: 10000;
		@include opacity(0);
		@include transition(all ease-in 100ms);

		&.visible {
			@include opacity(1);
			visibility: visible;

			#progress-bar-wrap #progress-bar {
				width: 100%;
			}
		}

		span {
			position: absolute;
			display: block;
			width: 200px;
			text-align: center;
			left: 50%;
			margin-left: -100px;
			top: 50%;
		}

		#progress-bar-wrap {
			background-color: $grey-4;
			height: 4px;
			width: 200px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -100px;
			margin-top: -20px;
			@include border-radius(3px);

			#progress-bar {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 0%;
				background-color: $bright-blue;
				@include border-radius(3px);
				@include transition(width ease-in 1000ms);
			}
		}
	}

	&.view {
		.ModalContent {
			#involvee-list-wrap {
				.involvee-row {
					padding: 18px 0;

					&:hover {
						padding: 12px 0;

						.involvee-info {
							visibility: visible;
							@include opacity(1);
						}
					}

					.involvee-info {
						visibility: visible;
						@include opacity(0);
						@include transition(all ease-in 100ms);
					}
				}
			}
		}
	}

	color: $grey-10;

	p {
		font-size: $modal-font-size;
		margin: 15px;
	}

	#involvee-list-wrap {
		position: relative;
		width: 400px;
		margin: 60px auto 39px auto;
		background-color: $white;
		border: 1px solid $grey-4;
		@include border-radius(3px);
		@include box-shadow(0 1px 2px rgba($black, 0.2));

		&:before,
		&:after {
			content: "";
			position: absolute;
			background-color: $grey-4;
			width: 2px;
			margin-left: -1px;
			left: 50%;
			height: 40px;
		}

		&:before {
			top: -40px;
			bottom: 0px;
		}

		&:after {
			bottom: -40px;
		}

		.involvee-row {
			position: relative;
			border-bottom: 1px solid $grey-4;
			height: 75px;
			padding: 12px 0;
			@include transition(padding ease-in 100ms);

			&:last-of-type {
				border-bottom: none;
			}

			&.signed {
				background-color: rgba($bright-green, 0.07);

				.signed-info {
					color: $green;
				}
			}

			&.rejected {
				background-color: rgba($red, 0.07);

				.signed-info {
					color: $red;
				}
			}

			.row-info {
				position: absolute;
				right: 15px;
				margin: 0;
				padding: 0;
				top: 27px;
			}

			.Avatar {
				position: absolute;
				left: 12px;
				top: 20px;
			}

			h3,
			.subtitle {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			h3 {
				position: absolute;
				left: 12px;
				right: 12px;
				margin: 0;
				font-size: 16px;
				margin-left: 50px;
				color: $black;
				font-weight: normal;
			}

			.subtitle {
				margin: 20px 0 0 62px;
				font-size: 12px;
				line-height: 16px;
			}
		}
	}

	.signed-count {
		background-color: $grey-4;
		padding: 5px 10px;
		font-size: 12px;
		@include border-radius(3px);
		margin: 0 auto;
		width: 120px;
		text-align: center;
	}

	.ModalControls {
		button {
			margin-left: 10px;
		}
	}
}
