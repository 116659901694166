@import "~StyleVariables";
.MailTemplates__list-type-toggle {
	margin-left: 12px;
	margin-right: 12px;

	.Button {
		padding-left: 16px;
		padding-right: 16px;
	}
}
