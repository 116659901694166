@import "~StyleVariables";

$width: 580px;

.ck-inline-toolbar {
	position: sticky;
	top: 135px;
	z-index: 1003;
	display: none;
	visibility: hidden;
	align-items: center;
	flex-direction: column;
	height: 0px; // so it wont take any place in the dom and push content

	&--visible {
		display: flex;
		visibility: visible;
	}

	.cke {
		position: absolute;
		top: -45px;
	}
}

.MailTemplatePreview {
	min-height: 100px;

	&__add-first-row {
		margin: 60px auto 0;
		display: block;
	}
}

// inky props
.MailTemplatePreview p,
.MailTemplatePreview h3,
.MailTemplatePreview h2 {
	font-style: normal;
	font-weight: normal;
	hyphens: none;
	line-height: 1.3;
	margin: 0;
	margin-bottom: 10px;
	padding: 0;
	text-decoration: none;
	white-space: initial;
}

.MailTemplatePreview .text-left {
	text-align: left;
}

.MailTemplatePreview .text-right {
	text-align: right;
}

.MailTemplatePreview .text-center {
	text-align: center;
}

.MailTemplatePreview__row {
	position: relative;
	margin: 0 auto;

	&:after {
		content: "";
		position: absolute;
		width: 580px;
		left: 50%;
		top: 0;
		bottom: 0;
		margin-left: -290px;
		z-index: 999;
	}

	&--full-width {
		width: 100%;
		margin: 0;

		&:after {
			left: 0;
			margin-left: 0;
			right: 0;
			width: auto;
		}
	}

	.inline-confirm-container {
		top: 0;
		left: 0;
		right: auto;
	}

	.MailTemplatePreview__row-drag-handle {
		display: inline-block;
		width: 34px;
		height: 34px;
		vertical-align: top;
		line-height: 34px;
		text-align: center;
	}

	.MailTemplatePreview__edit-options-row {
		visibility: hidden;
		position: absolute;
		top: 5px;
		left: 5px;
		opacity: 0;
		transition: visibility 0s, opacity 200ms linear;
		z-index: 1003;

		.MailTemplatePreview__edit-options-row-btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;

			&--first {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}

	.MailTemplatePreview__row-inner {
		width: 580px;
		margin: 0 auto;
		position: relative;
		z-index: 1000;
		word-break: break-word;
		flex-wrap: nowrap;

		.inline-confirm-modal .inline-confirm-tab {
			left: 0;
			right: auto;
			display: none;
		}
	}
}

// Outer column style that can not be applied inside the component css
.MailTemplatePreview__column--hr {
	border: none !important;
}

.MailTemplatePreview__column--image {
	text-align: center;
}

.MailTemplatePreview__column--producttable {
	max-width: 100%;
}

.MailTemplatePreview__empty-content {
	min-height: 50px;
	border: 1px dashed $green;

	.Text {
		line-height: 48px;
	}
}

.MailTemplatePreview__column {
	position: relative;

	&--dragging {
		box-shadow: 0 4px 8px rgba($black, 0.2);
	}
}

// Hovering columns
.MailTemplatePreview__column--selected,
.MailTemplatePreviewHeader--selected .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter--selected .MailTemplatePreviewFooter__inner,
.MailTemplatePreview__column--hovered,
.MailTemplatePreview__column:not(.MailTemplatePreview__column--read-only):hover,
.MailTemplatePreviewHeader:not(.MailTemplatePreviewHeader--read-only):hover .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter:not(.MailTemplatePreviewFooter--read-only):hover .MailTemplatePreviewFooter__inner {
	&:before {
		content: "";
		position: absolute;
		border: 1px solid $green;
		border-top: 0;
		border-bottom: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.MailTemplatePreviewHeader--selected .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter--selected .MailTemplatePreviewFooter__inner,
.MailTemplatePreviewHeader:not(.MailTemplatePreviewHeader--read-only):hover .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter:not(.MailTemplatePreviewFooter--read-only):hover .MailTemplatePreviewFooter__inner {
	&:before {
		border: 1px solid $green;
	}
}

// selecting columns
.MailTemplatePreview__column--selected,
.MailTemplatePreviewHeader--selected .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter--selected .MailTemplatePreviewFooter__inner {
	&:before {
		box-shadow: 0 0 2px 2px $medium-green inset;
	}
}

//Hovering trash Icon
.MailTemplatePreview__row--dragging .MailTemplatePreview__edit-options-row,
.MailTemplatePreview__row:hover .MailTemplatePreview__edit-options-row {
	visibility: visible;
	opacity: 1;
}

.MailTemplatePreview__row:not(.MailTemplatePreview__row--read-only):hover:before,
.MailTemplatePreview__row--dragging:before,
.MailTemplatePreview__row--hovered:before,
.MailTemplatePreview__row--selected:before {
	content: "";
	position: absolute;
	border: 1px solid $green;
	border-left: none;
	border-right: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
}

.MailTemplatePreview__row--dragging {
	box-shadow: 0 0 8px 0 rgba($black, 0.2);
	z-index: 1000;
}

.MailTemplatePreview__row--row-is-full {
	opacity: 0.5;
}

.MailTemplatePreviewPadding {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 0px solid rgba($green, 0.2);
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 200ms;
}

.MailTemplatePreview__column--hovered,
.MailTemplatePreview__column:hover:not(.MailTemplatePreview__column--selected) {
	.MailTemplatePreviewPadding {
		visibility: visible;
		opacity: 1;
	}
}

.MailTemplatePreview__column--hovered,
.MailTemplatePreview__column:hover {
	.MailTemplatePreviewColumnTools {
		visibility: visible;
		opacity: 1;
	}
}

.MailTemplatePreview__browser-link {
	text-align: center;
	padding: 10px;
}