@import "~StyleVariables";

.SubscriptionConflictModal {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: 50px;

	.EditorHeader {
		width: 100%;

		.EditorHeader__title {
			min-width: 320px;
		}
	}

	.EditorHeaderButton--next {
		min-width: 105px !important;
	}

	&__wrapper {
		height: 100%;
		overflow-y: auto;
		width: 100%;
	}

	&__content {
		width: 1100px;
		padding-bottom: 40px;
	}

	&__title {
		margin-bottom: $space * 3;
	}
}
