.AdminLooker {
	td {
		overflow: unset !important;
	}
}

.WrappedLooker {
	&__loader {
		margin: auto;
		height: 100%;
	}
	#admin-root#admin-root {
		left: 0;
	}
}
