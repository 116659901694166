@import "../../../../styles/variables";

.PreviewSalesboardColumn {
	background-color: $grey-1;
}

.PreviewSalesboardColumn__start-edit-button {
	width: 100%;
	position: relative;
	top: -4px;
}

.PreviewSalesboardColumn__overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba($blue, 0.5);
}

.PreviewSalesboardColumn__image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100px;
	color: white;
	font-size: 14px;
	background-color: aliceblue;
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	position: relative;
	overflow: hidden;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	background-size: cover;
}

.PreviewSalesboardColumn__image .Text {
	z-index: 100;
}

.PreviewSalesboardColumn__config-container {
	width: calc(100% - 20px);
	height: 215px;
	background-color: white;
	border-radius: 2px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	position: relative;
	top: -10px;
	left: 10px;
	padding: 10px;
}

.PreviewSalesboardColumn__locked-container {
	height: 34px;
	padding-top: 2px;
	padding-left: 12px;
	padding-right: 12px;
	display: flex;
	justify-content: space-between;

	.Tooltip:last-child {
		width: 30px;
		height: 20px;
		margin-right: -10px;
		cursor: pointer;
		padding-top: 2px;

		.Text {
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
			font-size: 16px;
		}
	}

	.Tooltip:last-child:hover .Text {
		color: $grey-13;
	}
}

.PreviewSalesboardColumn__config-container .Row:nth-child(even) {
	margin-bottom: 5px;
}

.PreviewSalesboardColumn__config-container .Text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.PreviewSalesboardColumn__config-container .Text--bold {
	font-weight: $boldFontWeight;
}
