@import "~StyleVariables";

$navbar-height: 50px;

.DocumentTemplateEditor {
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 300ms;
	background-color: $grey-2;
	display: flex;
	flex-direction: column;

	&.DocumentTemplateEditor--open {
		opacity: 1;
		visibility: visible;
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -50px;
	}

	.up-btn.btn-grey.btn-link {
		color: $green;
	}

	&__header {
		.Tab {
			.Text--bold {
				font-weight: $boldFontWeight;
			}

			&#details .Text.Skeleton:before {
				content: "Details";
			}

			&#design .Text.Skeleton:before {
				content: "Design";
			}
		}

		.Text {
			display: inline-block;

			&.Skeleton>* {
				display: none;
			}
		}

		.EditorHeader {
			&__title {
				padding-right: 40px;
			}
		}
	}

	&__controls {
		margin-left: auto;

		.EditorHeaderButton__super {
			color: $super-light-green;

			&--disabled {
				color: $grey-10;
			}
		}
	}

}