@import "~StyleVariables";

.SignalsFeed {
	$height: 50px;
	$card-width: 900px;

	background-color: $white;
	

	&__loader {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	&__lottie {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	&__paginator {
		display: flex;
		justify-content: center;
		align-items: center;
		width: $card-width;
		margin-left: 32px;
	}

	&__header {
		position: sticky;
		top: 0;
		z-index: 3000;
		height: $height;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		background-color: white;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

		.header-title {
			height: 100%;
			display: inline-flex;
			align-items: center;
		}

		.header-select {
			padding: 0px 16px 0px 16px;
			.ButtonSelect {
				margin-right: 16px;
				.Button {
					padding: 0px 20px;
				}
			}
		}
	}
	&__content {
		overflow-y: auto;
		height: 100%;
		padding-bottom: 200px;
	}
	&__itemRow {
		width: 100%;
		display: flex;
		margin-left: 32px;
		justify-content: flex-start;
		&:first-child {
			margin-top: 16px;
		}

		.SignalsFeed__item {
			width: $card-width;
			margin-bottom: 16px;
		}
	}

	&__item {
		.Card {
			border-radius: $border-radius;
		}
	}
}
