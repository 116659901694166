@import "../../../../styles/variables";

.AccountListWrapper {
	background-color: $white;
	position: fixed;
	right: 16px;
	left: 365px;
	top: 147px;
	z-index: 1;

	.wrapper-header {
		display: block;
		padding: 8px 15px;
		h2 {
			margin: 0;
			font-weight: $regularFontWeight;
			color: $grey-13;
			font-size: 20px;
			display: block;
		}
		span {
			color: $grey-8;
			display: block;
		}
	}
}

.AccountListWrapper .AccountListContacts__TableHeader {
	display: flex;
	padding: 6px 0 6px 10px;
	color: $grey-11;
	background-color: $grey-4;
	font-size: 12px;
	font-weight: $regularFontWeight;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
