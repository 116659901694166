@import "~StyleVariables";
.SelectUserGroup {
	&__title {
		position: relative;
		display: block;
		padding: 10px;
		border-bottom: 1px solid $grey-4;
		font-size: 16px;
	}
	&__search-wrap {
		position: relative;
		padding: 10px;
		height: 54px;
	}
	&__search-info {
		position: absolute;
		right: 15px;
		top: 16px;
		padding: 2px 5px;
		background-color: $grey-4;
		border: 1px solid $grey-8;
		font-size: 12px;
		color: $grey-11;
		border-radius: 3px;
		background-clip: padding-box;
	}
	&__content {
		@media screen and (min-height: 600px) {
			--select-user-group-content-max-height: 400px;
		}
		
		@media screen and (min-height: 400px) and (max-height: 600px) {
			--select-user-group-content-max-height: 250px;
		}

		@media screen and (min-height: 200px) and (max-height: 450px) {
			--select-user-group-content-max-height: 150px;
		}

		max-height: var(--select-user-group-content-max-height);

		&--overflowY {
			overflow-y: auto;
		}
	}
	&__toolbar {
		position: relative;
		left: 0px;
		right: 0px;
		bottom: 0px;
		padding: 10px;
		display: block;
		background-color: $grey-2;
		border-top: 1px solid $grey-8;
		color: $grey-11;
		a {
			cursor: pointer;
		}
	}
	.ReactVirtualized__Grid__innerScrollContainer {
		margin-bottom: 0;
	}
}
