@import "~StyleVariables";

.MailEditorReviewSenderFakeRow {
	position: relative;
	padding: 8px 28px;
	border-bottom: 1px solid $grey-4;
	background-color: $grey-1;

	&--active {
		background-color: $white;

		&:before {
			content: "";
			background-color: $bright-blue;
			border-radius: 100%;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 21px;
			left: 11px;
		}
	}

	&__title.Skeleton:before {
		content: "Upsales Feature updates";
		font-size: 12px;
		background-color: $grey-5;
		color: $grey-5;
	}
	&__subject.Skeleton:before {
		content: "News from Upsales";
		font-size: 10px;
		background-color: $grey-5;
		color: $grey-5;
	}
	&__email.Skeleton:before {
		content: "info@upsales.com";
		font-size: 8px;
		background-color: $grey-4;
		color: $grey-4;
	}
	&__preview-text.Skeleton:before {
		content: "Check out our new email campaign features!";
		font-size: 8px;
		background-color: $grey-4;
		color: $grey-4;
	}

	&__title,
	&__subject,
	&__email,
	&__preview-text {
		&.Skeleton:before {
			animation-name: none;
		}
	}

	&__title {
		font-size: 13px;
	}

	&__subject {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
	}

	&__email {
		font-size: 10px;
	}

	&__preview-text {
		font-size: 12px;
	}
}
