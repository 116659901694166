@import "~StyleVariables";

.EditSocialEventAddResource {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-x: auto;

	.ModalContent {
		> .ModalContent__content {
			width: 100%;
		}
	}

	.ModalHeader {
		> .ModalHeader__content {
			width: auto;
		}
	}

}

.EditSocialEventAddResource--container {
	display: flex;
}

.EditSocialEventAddResource--Card-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 30px auto 0;
	max-width: 1200px;
	text-align: center;
}

@media (max-width: 1600px) {
	.EditSocialEventAddResource--Card-wrapper {
		max-width: 720px;
	}
}

.EditSocialEventAddResource--Card {
	color: $black;
	position: relative;
	display: inline-block;
	margin: 0 15px;
	padding: 10px 15px 35px;
	border: 1px solid $grey-6;
	border-radius: 2px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	flex: 1 0 210px;
	max-width: 210px;
	cursor: pointer;
	margin-bottom: 30px;
	transition: all ease 200ms;
}

.EditSocialEventAddResource--Card:hover {
	background-color: $grey-1;
}

.EditSocialEventAddResource--Card-wrapper {
	.Block--grey-3{
		cursor: default;
	}
	.Block--grey-3:hover{
		background-color: $grey-3;
	}
	.Block--success-1{
		cursor: default;
	}
	.Block--success-1:hover{
		background-color: $success-1;
	}
}

.EditSocialEventAddResource--Card.Card--light-grey .EditSocialEventAddResource--Card-Icon {
}

.EditSocialEventAddResource--Card.Card--light-grey .EditSocialEventAddResource--Text {
	font-style: italic;
}

.EditSocialEventAddResource--Card-Icon {
	height: 70px;
}

.EditSocialEventAddResource--Card-check {
	background-color: $grey-8;
	position: absolute;
	top: 10px;
	right: 10px;
	height: 28px;
	width: 28px;
	border-radius: 100%;
	line-height: 28px;
}

.EditSocialEventAddResource--Title {
	margin-top: 10px;
}

.EditSocialEventAddResource--Text {
	margin-top: 10px;
}
