.BooleanInput__btn-group {
	min-width: 85px;
}

.BooleanInput__btn-group {
	display: flex;

	button {
		flex: 1;
		border: 0 !important;

		&:not(:last-child):not(:first-child) {
			border-radius: 0;
		}

		&:last-child {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}

		&:first-child {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}
}
