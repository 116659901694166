@import "~StyleVariables";

.AlertIcon {
	height: 50px;
	// Compensate button padding for child width increase
	margin: 0 -7px;

	&__icon-background {
		width: 28px;
		height: 28px;
		background-color: $yellow-2;
		border-radius: $border-radius;
	}
}
