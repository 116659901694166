@import "../../../../styles/variables";

.TranslateField {
	.disabled {
		width: 100%;
		background-color: #f6f9fb;
		position: absolute;
		top: 0;
		bottom: 0;
		opacity: 50%;
		z-index: 1;
	}

	table {
		table-layout: fixed;
	}

	.TableRow:not(:hover) button.visibleOnHover {
		display: none;
	}

	.TableColumn button.Button.up-btn.btn-lg.btn-link {
		padding: 0px;
		padding-left: 12px;
		font-size: 15px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.TableColumn button.Button.up-btn.btn-lg.btn-link {
		&:hover {
			text-decoration: underline;
		}
	}

	tr.TableRow {
		height: 43px;

		td.defaultValue {
			min-width: 130px;
			max-width: 200px;
		}
		td.language {
			width: 200px;

			.Text--md {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		td.translation {
			width: 190px;
		}
		td.actions {
			min-width: 190px;
			padding-right: 0px;
		}
	}

	tr.TableRow.selected {
		background-color: $grey-1;
	}

	tr.TableRow:hover {
		background-color: $grey-1;
	}

	td.TableColumn.TableColumn--black.TableColumn--align-left.TableColumn--md {
		font-size: 15px;
	}

	th:first-child {
		padding-left: 20px;
	}

	th {
		white-space: nowrap;
	}

	td:first-child {
		padding-left: 20px;
	}

	i.flag-icon {
		margin-right: 5px;
	}

	.Input.Input--md.Input--inline {
		width: 80%;
		float: left;
	}

	&__deleteTranslationButton {
		&--hideMe {
			display: none;
		}
	}

	&__flexContainer {
		display: flex;
		align-items: center;
	}

	.hideMe {
		display: none;
	}

	.no-default-language-wrapper {
		text-align: center;

		.header {
			font-size: 16px;
			font-weight: $boldFontWeight;
			padding-bottom: 20px;
			padding-top: 40px;
		}

		.select-wrapper {
			width: 300px;
			display: inline-block;
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}

	.briefcase-holder {
		margin-top: 20px;
		text-align: center;

		.briefcase {
			background-image: url("../../../../img/empty-briefcase.svg");
			height: 105px;
			width: 105px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			margin: 0 auto 20px auto;
		}

		.title {
			font-size: 16px;
			color: $grey-10;
			line-height: 19px;
			margin: 10px 0;
			font-weight: $regularFontWeight;
		}

		.add-value {
			font-size: 14px;
			color: $grey-10;
			line-height: 17px;
			font-weight: $regularFontWeight;
		}
	}

	.pretty-select.select2-container {
		width: 100%;

		.select2-choice {
			background: 0 0;
			display: block;
			margin: 0;
			padding: 0;
			border: none;
			border-radius: 0;
			border-bottom: 1px solid $grey-6;
			outline: 0;

			.select2-arrow {
				border-left: 0;
				top: 2px;
			}
		}

		.select2-default .select2-chosen {
			float: left;
			position: relative;
			top: 8px;
			padding-left: 3px;
			display: block;
			font-style: italic;
			line-height: 9px;
			color: #a4b3c7;
			font-size: 14px;
		}

		.select2-search-choice-close {
			top: 18px;
		}

		button {
			visibility: visible;
			width: 120px;
			overflow: hidden;
			margin-left: 15px;
			margin-top: 4px;

			&.btn-hidden {
				visibility: hidden;
				width: 0;
				margin-left: 0;
				padding: 0;
			}
		}

		&.select2-dropdown-open {
			.select2-choice {
				border-bottom-color: $bright-blue;
			}
		}
	}

	.field__row--delete-btn.Button.up-btn.btn-grey.btn-link.no-shadow {
		height: 40px;
		border-left: 1px solid transparent;

		&.is_expanded {
			background-color: $white;
			border-left: 1px solid rgba($black, 0.05);
			color: $red;
		}

		&:hover {
			color: $red;
		}
	}

	.field__row--delete-confirm {
		display: flex;
		justify-content: center;
	}

	button.confirmDelete.Button.up-btn.btn-red.no-shadow {
		min-width: 180px;
		margin-top: 5px;
	}

	.DropDownMenu.DropDownMenu--expanded {
		.DropDownMenu__content {
			left: unset;
			right: 0;
		}
	}

	.full-cell-width {
		width: 100%;
		text-align: left;
	}

	&__activeStages {
		cursor: pointer;
		width: 195px;
	}

	&__selectedStages {
		display: inline-block;
		width: fit-content;
		white-space: nowrap;
		background-color: $super-light-green;
		margin-right: 4px;
		padding: 4px;
		border-radius: 4px;
		cursor: pointer;
	}

	&__hiddenStages {
		cursor: pointer;
		display: inline-block;
	}

	&__shownInStage {
		position: absolute;
		background-color: $white;
		padding: 12px 0px 4px 0px;
		z-index: 1;
		border-radius: 4px;
		border: 1px solid $grey-6;

		.Text--bold {
			white-space: nowrap;
			margin-bottom: 8px;
			margin-left: 12px;
		}

		&__wrapper {
			border-top: 1px solid $grey-2;
		}

		&__row {
			white-space: nowrap;
			padding-left: 8px;
			padding-right: 8px;
			padding-top: 4px;
			transition: background-color ease 200ms;

			.Checkbox {
				display: inline-block;
			}

			.Text {
				display: inline-block;
				margin-left: 4px;
				vertical-align: top;
				line-height: 27px;
			}
		}

		&__row:hover {
			background-color: $grey-1;
		}
	}
}
