@import "~StyleVariables";

.FileUploadDropzone {
	background-color: $grey-4;
	border: 2px dashed $grey-6;
	border-radius: 3px;
	text-align: center;
	padding: 30px 0;
	margin-bottom: 20px;

	&__icon {
		margin-right: $space;
	}
}
