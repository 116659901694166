@import "~StyleVariables";

.RelationDropdownMenu {
	position: relative;

	& > .DropDownMenu > .Tooltip > .Button:hover {
		background-color: $grey-2;
	}

	.RelationSelect {
		&__content > .Label {
			margin-left: 24px;
			margin-top: 4px;
		}

		.Input {
			padding: 0 24px;
			margin-bottom: 8px;
			> .Icon {
				left: 24px;
			}
		}
		.Button {
			height: 40px;
		}
	}

	&__dropdown {
		transition: width 0.3s ease-out;
		width: 320px;

		.Text {
			box-shadow: none;
		}
	}

	&__dropdown--show-select {
		width: 372px;
	}
	&__dropdown-options {
		animation: fade 0.3s ease-out;
		display: flex;
		flex-direction: column;

		& > .Text {
			padding-left: 8px;
		}
	}

	&__hide-select-button,
	&__hide-select-button:hover,
	&__dropdown-options .Button:first-child,
	&__dropdown-options .Button:first-child:hover,
	&__dropdown-options .Text {
		box-shadow: none !important;
	}
	&__hide-select-button,
	&__dropdown-options .Button {
		border: none;
	}

	&__hide-select-button,
	&__dropdown-options > .Button {
		padding-left: 0;
		padding-right: 0;
		border-radius: 0;
		& > .Icon {
			width: 32px;
		}
	}
	&__hide-select-button,
	&__dropdown-options .Button,
	&__dropdown-options .Text {
		width: 100%;
		display: flex;
		align-items: center;
		height: 36px;
		box-shadow: inset 0 1px 1px rgba($black, 0.075);
	}
	&__hide-select-button {
		margin-bottom: 8px;
		box-shadow: inset 0 -1px 1px rgba($black, 0.075) !important;
	}

	@keyframes fade {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}
