@import "~StyleVariables";

@keyframes skeleton_animation {
	0% {
		opacity: 0.7;
	}
	50% {
		opacity: 0.4;
	}
	100% {
		opacity: 0.7;
	}
}

#SharedViews {
	display: inline-flex;
	height: 100%;
	user-select: none;
}

.SharedViews {
	display: flex;
	align-content: center;
	line-height: 1;
	box-shadow: 1px 0 0 0 $grey-6;
	height: 100%;

	&--hasRemoveFrame {
		box-shadow: 0 0 0 0;
		border-bottom: 0 !important;

		.DropDownButton__icon-wrapper {
			opacity: 1;
		}
		.DropDownButton__text-wrapper > .Text--sm {
			color: $grey-11 !important;
		}
		&.SharedViews--isChanged {
			.DropDownButton__text-wrapper > .Title--md {
				color: $blue !important;
			}
			.DropDownButton__text-wrapper > .Text--sm {
				color: $blue !important;
			}
		}
	}

	&.SharedViews--isDisabled {
		box-shadow: none;
	}

	.DropDownMenu .DropDownButton {
		transition: all ease-out 300ms;
		border-top-left-radius: $border-radius;
		&:hover {
			background-color: $grey-2;
		}
		.Title {
			line-height: initial;
		}
	}
}

#report-picker.SharedViews {
	justify-content: left;

	&.SharedViews--isChanged {
		padding-bottom: 35px !important;
		width: 100%;
		position: relative;
	}

	.DropDownMenu {
		display: block;
		width: 100%;
		position: initial;

		.DropDownButton__text-wrapper {
			width: 100%;
			padding: 0;

			.Title {
				white-space: normal;
				line-height: 1.4;
				width: 100%;
				overflow: initial;
				text-overflow: initial;
			}
		}

		.FloatingButton {
			right: 5px;
			bottom: 0;
			top: calc(100% - 33px);
		}
	}
}

#find-prospects-picker.SharedViews {
	.DropDownMenu__content {
		padding-top: 10px;
	}
}

.SharedViews .DropDownMenu__content,
.SharedViews .DropDownMenu__content .SharedViews__Expanded,
.SharedViews .DropDownMenu__content .SharedViews__Expanded > div {
	border-radius: 4px;
}

.SharedViews--isChanged {
	justify-content: start;
	border-bottom: 4px solid $bright-green;
	margin-bottom: -1px;

	.DropDownMenu {
		width: 100%;

		.FloatingButton {
			margin: 5px;
			padding: 0 6px;
		}

		.DropDownButton {
			background-color: rgba($super-light-green, 0.2);
		}
	}

	.DropDownButton__text-wrapper {
		margin-left: 0;
	}
}
.SharedViews {
	&--isNewListView {
		.DropDownButton:hover {
			background-color: transparent !important;
			cursor: unset;
		}
	}
}

.SharedViews--isChanged.SharedViews--isNewListView {
	.DropDownButton {
		background-color: transparent !important;
		&:hover {
			background-color: transparent !important;
			cursor: unset;
		}
	}
	.Tooltip {
		margin-bottom: 12px;
	}
}

.SharedViews--loading {
	flex-direction: column;
	padding: 0 10px;
	width: 140px;
}

.SkeletonLoading__Subtitle {
	margin-top: 5px;
	height: 8px;
}

.SkeletonLoading__Main {
	height: 15px;
}

.SharedViews--loading > div {
	background: $grey-6;
	color: $grey-6;
	border-radius: 1px;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-animation-name: skeleton_animation;
	animation-name: skeleton_animation;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

.SharedViews .DropDownButton,
.SharedViews .DropDownButton__wrapper {
	height: 100%;
	width: 100%;
}

.SharedViews .DropDownButton__wrapper {
	display: flex;
	align-items: center;
}

.SharedViews .DropDownButton__text-wrapper {
	user-select: none;
	padding-left: 10px;

	.Title {
		max-width: 280px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: $boldFontWeight;
	}
}

.SharedViews .DropDownButton__text-wrapper > .Text--sm {
	margin-top: 3px;
	line-height: 1;
	color: $grey-10;
}

.SharedViews__Expanded {
	width: 300px;
	overflow: hidden;
	transition: all 0.3s;
	box-shadow: 0 15px 15px 0 rgba($black, 0.2);
	overflow: hidden;
	overflow-y: auto;
	position: relative;

	.StandardView {
		transition: all 0.2s;
		transition-delay: 0.1s;
	}
}

.SearchBar {
	padding: 0;
	border: none;
	margin: 0;
	height: 30px;
	padding-bottom: 10px;

	.Input {
		margin: 0 15px;
		height: 30px;

		* {
			height: 30px;
			line-height: 30px;
		}

		.Icon {
			height: 30px;
			line-height: 32px;
			width: 30px;
		}
	}
}

.SharedViews__Expanded--isHiddenView {
	.HideView {
		top: 0;
		left: 0;
		position: relative;
		transition-delay: 0.1s;
		overflow: initial;
	}

	.StandardView {
		margin-left: -100%;
		position: absolute;
		width: 100%;
	}
}

.SharedViews__Expanded--isSaveView {
	.SaveView {
		top: 0;
		right: 0;
		position: relative;
		transition-delay: 0.1s;
	}

	.StandardView {
		width: 100%;
		position: absolute;
		margin-left: -100%;
	}
}

.SharedViews__Expanded .RowView.TableRow--clickable {
	transition: all 0.3s;

	&:hover {
		background-color: #f5faff;
	}
}

.SharedViews__Expanded .RowView:hover {
	background-color: $grey-1;
}

.SharedViews__Expanded .RowView .TableColumn {
	height: 30px;
	color: $grey-10;
	border: none;
	cursor: pointer;

	.Avatar {
		margin: 0 5px 0 0;
		float: left;
		font-size: 18px;
		margin-bottom: -5px;

		.Avatar--initials {
			line-height: 2.15em;
		}
	}

	.Tooltip + .Tooltip {
		margin-left: 10px;
	}

	.Icon-chevron-right {
		margin-right: 2px;
	}

	.Icon-eye-slash {
		font-size: 13px;
	}

	.Icon-star-o {
		font-size: 14px;
	}

	.no-tooltip {
		display: inline-block;
	}
}

.SharedViews__Expanded .RowView .TableColumn .Tooltip > .Text {
	display: inline;
}

.SharedViews__Expanded .RowView .RowView__RightColumnIcons {
	opacity: 0;
	width: 80px;
	padding-left: 0;
	transition: all 0.3s;
}

.SharedViews__Expanded .RowView:hover .RowView__RightColumnIcons {
	opacity: 1;
}

.SharedViews__Expanded .RowView--Current {
	background-color: $grey-1;
	box-shadow: inset 4px 0 0 0 $green;
}

.SharedViews__Expanded .RowView--Active .TableColumn,
.SharedViews__Expanded .RowView--Current .TableColumn {
	color: $grey-13;
}

.SharedViews__Expanded .RowView--Current .Text {
	color: $black;
}

.SharedViews--isDisabled .DropDownButton__icon-wrapper {
	display: none;
}
