@import "~StyleVariables";

.TodoListTable {
	.Table {
		table-layout: fixed;
	}
	.TableHeader {
		th:nth-child(3) {
			padding-left: 4px;
		}

		&__column {
			position: sticky;
			top: 0;
			z-index: 1;
			background-color: $white;
			white-space: nowrap;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				border-bottom: 1px solid $grey-2;
			}
		}
	}

	&__iconCol {
		width: 60px;
	}

	&__actions--small {
		width: 116px;
	}

	.TableRow .TableColumn:first-of-type,
	.TableHeader__column:first-of-type {
		width: 0;
		transition: all ease-out 300ms;
		padding: 0;
		text-align: center;
		transition-delay: 300ms;
	}

	.TableRow .TableColumn:first-of-type {
		.Checkbox {
			margin: 0 auto;
		}
	}

	.TableRow .TableColumn:last-of-type,
	.TableHeader__column:last-of-type {
		width: 120px;
	}

	&--multiselect-enabled {
		.TableRow .TableColumn:first-of-type,
		.TableHeader__column:first-of-type {
			width: 46px;
			transition-delay: 0ms;
		}
	}
}

@media (max-width: $responsiveWidht2) {
	.TodoListTable {
		.TableHeader th:last-child {
			padding-left: 25px;
		}
	}
}
