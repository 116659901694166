@import "~StyleVariables";

.SignalsWidget {
	transition: background-color 0.2s ease-in;
	border-bottom: 1px solid $grey-4;
	cursor: pointer;

	&:hover {
		background-color: $grey-1;

		.SignalsWidget__imgPlaceholder {
			background-color: $grey-2;
		}
	}

	&__title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&__img {
		img {
			width: 272px;
			border: 1px solid $grey-4;
			border-radius: $border-radius;
		}
	}

	&__imgPlaceholder {
		width: 272px;
		height: 100px;
		background-color: $grey-1;
		border-radius: $border-radius;
		transition: background-color 0.2s ease-in;

		img {
			display: block;
			max-width: 272px;
			max-height: 100px;
			width: auto;
			height: auto;
		}
	}
}
