.DisqualifyDropDown__dropdown.DropDownMenu--alignment-right .DropDownMenu__content {
	right: -10px;
}

.DisqualifyDropDown__dropdown .DropDownMenu__content {
	text-align: left;
	padding: 15px;
	min-width: 180px;
	max-width: 180px;
	z-index: 1000;
}

.DisqualifyDropDown__dropdown-title {
	margin-bottom: 15px;
}
