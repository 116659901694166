.TimelineGeneric {
	.Card .Text {
		display: inline;
	}

	.TimelineRow,
	.TimelineCard {
		margin-bottom: 0px;
	}
}
