@import "../../../../../styles/variables";

.TemplateEditor {
	position: initial;
	.TemplateEditor__editor-header {
		background-color: $grey-11;
		padding: 15px 15px 1px 15px;

		.TemplateEditor__label {
			color: $grey-9;
			font-size: 12px;
		}

		.Input__input {
			background-color: $black;
			border-color: $black;
			color: $grey-10;
		}

		.template-editor-roles {
			width: 100%;

			.select2-default {
				color: $grey-12 !important;
			}

			.select2-choices {
				background-color: $black;
				border: none;
				min-height: 34px;
			}

			.select2-search-choice {
				color: $bright-blue;
			}
		}

		::placeholder {
			color: $grey-12;
			opacity: 1; /* Firefox */
		}

		.Editor-tab-buttons {
			.btn-link {
				color: $grey-9;
				text-decoration: none;
			}
		}

		.TemplateEditor__templates {
			color: $grey-9;
			font-size: 12px;
			box-shadow: 1px 0 0 0 $grey-11;
			&:hover {
				background-color: $grey-11;
				color: $white;
			}

			.DropDownButton__wrapper {
				background: $grey-11;
				.DropDownButton__text-wrapper .Title {
					font-size: 13px;
				}
			}
		}

		.TemplateEditor__tags {
			font-size: 13px;
		}

		.DropDownMenu__content {
			margin-top: 5px;
			.template-row {
				&:hover {
					background-color: $bright-blue;
					cursor: pointer;
				}
				.TableColumn {
					&:hover {
						color: $white;
					}
				}
			}
		}
	}

	.TemplateEditor__preview-header {
		background-color: $white;
		padding: 15px 15px 1px 15px;
		.InlineAction__wrapper--top {
			padding-top: 8px;
		}
	}

	.TemplateEditor__preview-pdf {
		background-color: $white;
		padding: 15px 15px 1px 15px;

		.pdf-loader {
			margin: 30% 45%;
		}

		.error-card {
			top: 200px;
		}

		.iframe {
			overflow: hidden;
			height: 91.99vh;
			width: 100%;
			border: 1px solid $grey-6;
			border-radius: 3px;
		}

		.helper-message {
			height: 100vh;

			.CardContent {
				padding-top: 200px;
			}
		}
	}

	.TemplateEditor__tabs {
		.Tab {
			background-color: $grey-11;
		}
		.Tab--blue {
			color: $grey-9;
		}
		.Tab--blue.Tab--selected {
			color: $white;
		}
	}

	.CodeEditor {
		width: 100%;
		position: relative;
	}

	.ace_editor {
		background-color: $grey-13;
		height: 70.8vh;
	}

	.ace_scrollbar-v {
		display: block !important;
	}

	.ace_editor .ace_gutter {
		background-color: $grey-13;
		color: $grey-10;
	}

	.ace_editor .ace_marker-layer .ace_active-line,
	.ace_editor .ace_gutter-active-line {
		background-color: rgba($black, 0.5);
	}

	.TemplateEditor__footer {
		height: 50px;
		line-height: 50px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 15px;
		box-shadow: 0 0 20px #1e252e;
		z-index: 1001;
		background-color: $grey-13;

		.up-btn.btn-grey.btn-link:hover {
			color: $white;
		}

		.InlineAction__wrapper--left {
			top: -60px;
			left: 0;
		}
	}

	.TemplateEditor__left {
		background-color: $grey-13;
		height: 100%;
		width: 50%;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
	}

	.TemplateEditor__right {
		height: 100%;
		width: 50%;
		position: fixed;
		z-index: 1;
		top: 0;
		right: 0;
		background-color: $white;
	}

	.TemplateEditor__tagList--visible {
		$taglist-width: 280px;
		#tag-list {
			width: $taglist-width;

			#tags-list-ul {
				top: 180px;
			}

			> div,
			ul,
			h2,
			p {
				width: $taglist-width !important;
			}
		}
	}
}

@media (min-height: 790px) {
	.TemplateEditor .ace_editor {
		height: 77.1vh !important;
	}
}
