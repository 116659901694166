.ListDrawer.Page {
	overflow: visible;
}

.ListDrawer {
	width: 1200px;

	.Page {
		top: 0 !important;
	}

	.ListView__header {
		top: 0;
	}

	.ListView {
		left: 0;
		top: 51px;
		overflow-y: auto;

		.TableColumn {
			max-width: 200px;
		}

		.Link {
			display: block;
		}
	}

	.TableHeader {
		position: sticky;
		top: 0;
		z-index: 1;
	}
}
