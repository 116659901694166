.StepContactTable {
	&__loop-indicator {
		position: absolute;
		right: 16px;
		top: 0;
		height: 100%;
		display: flex;
		align-items: center;

		.Text {
			display: inline-block;
		}
	}
}