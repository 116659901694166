.GroupMailEditorRobot {
	height: 400px;
	position: relative;
	z-index: 999;

	& > div {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -300px !important;
		pointer-events: none;
	}
}
