@import "~StyleVariables";

.ReportcenterShareDashboardDropdown {
	display: inline-block;
	.DropDownMenu__content {
		z-index: 10001;
		text-align: left;
		padding: 10px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		border: 1px solid $grey-4;
		overflow: hidden;
		overflow-y: auto;

		.Label {
			font-weight: $boldFontWeight;
			margin-top: 15px;
		}

		.Row {
			margin-top: 15px;

			&#share-dashboard-header {
				margin-top: 0px;
			}
		}
	}

	&__share-dashboard-header {
		position: relative;
		text-align: right;
		display: flex;
		align-items: center;
		background-color: transparent;
		height: 34px;
		line-height: 34px;
		border-radius: 3px;
		border: 1px solid transparent;
		padding-left: 10px;

		&--expanded {
			border-color: $grey-4;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

			&:before {
				background-color: $white;
				height: 6px;
				left: 0;
				right: 0;
				content: "";
				position: absolute;
				bottom: -3px;
				z-index: 10002;
			}
		}
	}

	&__select-wrapper {
		min-height: 34px;
	}
}
