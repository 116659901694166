@import "~StyleVariables";

$navbar-height: 50px;

.EditorHeaderButton {
	height: $navbar-height;
	border-radius: 0;
	min-width: 80px;
	opacity: 1 !important;
}

.EditorHeaderButton--next:not(.EditorHeaderButton--no-icon) {
	min-width: 160px !important;
	padding-right: 35px;
}
.EditorHeaderButton--next {
	position: relative;
	text-align: left;
}

.EditorHeaderButton .Icon {
	position: absolute;
	right: 15px;
	top: 0;
	line-height: $navbar-height;
	height: $navbar-height;
	font-size: 12px;
}

.EditorHeaderButton__super {
	font-size: 12px;
	font-weight: $regularFontWeight;
	line-height: 14px;

	&--disabled {
		color: $grey-10;
	}
}

.EditorHeaderButton__title {
	font-size: 16px;
	font-weight: $boldFontWeight;
	line-height: 19px;

	&--disabled {
		color: $grey-10;
	}
}
