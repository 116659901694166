@import "~StyleVariables";

.MailEditorReviewLinks {
	&__error-link {
		border-left: 2.5px solid $red;
		background-color: $super-light-red;

		.Tooltip {
			margin-left: 8px
		}
	}
	.load-links {
		background-color: $grey-2;
	}
	.CardContent {
		padding: 0;

		.Table .TableColumn {
			max-width: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.CardHeader__actions {
		display: -webkit-inline-box;
		.Loader,
		.Text {
			display: inline-flex;
			margin-right: 10px;
		}
	}
}
