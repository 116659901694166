@import "~StyleVariables";
.ListViewLabels {
	width: stretch;
	.FileBrowserLabels__wrapper {
		position: sticky;
		top: 82px;
		height: calc(100vh - 82px);
		overflow: hidden auto;
		z-index: 15;
	}

	.FileBrowserLabels__header {
		align-items: baseline;
		margin-left: 0;
		height: 40px;
		position: relative;
		width: 100%;

		.FileBrowserLabels__label {
			margin-top: 12px;
		}
		.FileBrowserLabels__icon {
			margin-top: 14px;
		}
	}

	.FileBrowserLabel__labelWrapper {
		margin-left: 0;
		height: 40px;
		position: relative;
		width: 100%;

		&--selected {
			background-color: $white;
		}

		.FileBrowserLabel__labelButtonsDiv {
			margin-right: -6px;
		}

		&:hover {
			background-color: $grey-3;
		}
	}
}
