// Large
.responsive-show-l {
	display: none !important;
}

@media (max-width: $responsiveWidht1) {
	.responsive-hide-l {
		display: none !important;
	}
	.responsive-show-l {
		display: initial !important;
	}
}

// Medium
.responsive-show-m {
	display: none !important;
}

@media (max-width: $responsiveWidht2) {
	.responsive-hide-m {
		display: none !important;
	}
	.responsive-show-m {
		display: initial !important;
	}
}

// Small
.responsive-show-s {
	display: none !important;
}

@media (max-width: $responsiveWidht3) {
	.responsive-hide-s {
		display: none !important;
	}
	.responsive-show-s {
		display: block !important;
	}
}

// X-Small
.responsive-show-xs {
	display: none !important;
}

@media (max-width: $responsiveWidht4) {
	.responsive-hide-xs {
		display: none !important;
	}
	.responsive-show-xs {
		display: block !important;
	}
}
