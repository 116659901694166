@import "~StyleVariables";

$navbar-height: 50px;

.FormEditor {
	&__navbar-title {
		width: 235px;
		padding: 5px 10px;
		border-right: 1px solid $grey-4;
	}

	&__navbar-title {
		.Text,
		.Title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.Loader {
			display: inline-block;
			width: 18px;
			height: 18px;
			vertical-align: middle;
		}
	}

	&__controls {
		margin-left: auto;
		border-left: 1px solid $grey-4;
	}

	&__SetupProfile {
		display: flex;
		align-items: center;
		margin-top: 100px;
		flex-direction: column;

		.Button {
			margin-top: 20px;
		}
	}

	.preview {
		.template-scoped-style {
			padding: 0;
		}
	}
}
