@import "~StyleVariables";

.LoginStatsChart {
	max-width: 900px;
	padding-bottom: 20px;
}

.LoginStatsChart__date-label {
	position: absolute;
	left: 0;
	margin-left: -20px;
	width: 40px;
	top: 20px;
}

.LoginStatsChart__date-label .Text {
	line-height: 12px;
}

.LoginStatsChart__tooltip {
	background-color: $black;
	text-align: center;
	padding: 5px;
}

.LoginStatsChart__tooltip .Text {
	line-height: 12px;
}
