@import "~StyleVariables";

.InvitePage {
	display: flex;
}

.InvitePage__LeftArea {
	transition: all 0.3s;
	width: 50%;
	position: fixed;
	z-index: 10000;
	left: 0;
	top: 0;
	bottom: 0;
}

.InvitePage__LeftArea .Logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.InvitePage__LeftArea .Logo svg {
	height: 50px;
	margin: 20px;
	z-index: 1000;
	position: relative;
}

.InvitePage__MainArea {
	transition: all 0.3s;
	background: $green;
	position: fixed;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	width: 50%;
}

.InvitePage__Background {
	position: absolute;
	z-index: 1000;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.InvitePage__InnerArea {
	padding: 60px;
	max-width: 800px;
}

.InvitePage__InnerArea .Card {
	margin: 30px 0;
	border-radius: $border-radius;
}

.InvitePage__InnerArea .Card .CardContent {
	border-radius: $border-radius;
	padding: 20px;
}

.InvitePage__InnerArea .Input + .Title {
	margin-top: 20px;
	margin-bottom: 10px;
}

.InvitePage__InnerArea .Input + .Label {
	margin-top: 20px;
}

.InvitePage__InnerArea .Label {
	font-weight: $boldFontWeight;
}

.InvitePage__LinkHolder {
	display: flex;
	margin: -15px 0 25px 0;
	justify-content: space-between;

	a {
		cursor: pointer;
	}
}

.InvitePage__InnerArea .Card.InvitePage__HasError {
	border-radius: $border-radius;
	margin-top: -10px;
	background-color: #fad668;

	.Title {
		color: #664c00;
		font-weight: $boldFontWeight;
	}
}

@media (max-width: $responsiveWidht5) {
	.InvitePage__LeftArea {
		width: 100%;
		height: 30vh;
	}

	.InvitePage__MainArea {
		width: 100%;
		top: 30vh;
	}

	.InvitePage__InnerArea {
		padding: 40px 60px;
	}
}
