@import "~StyleVariables";

.MergeTickets {
	$header-height: 51px; // Header 50px + 1px border
	--modalContentWidth: 50%;

	.ModalHeader {
		padding: 0;

		&__content {
			padding-right: 0;
		}
	}

	&__controls {
		white-space: nowrap;
		margin-left: auto;
	}

	.ModalHeader__content {
		padding-left: $space * 5;
	}

	.ModalContent {
		padding: $space * 10;
		height: calc(100vh - #{$header-height});
	}

	&__info {
		padding: $space * 3 $space * 4;
	}

	&__select {
		padding: $space * 4;
	}
}
