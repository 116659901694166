.edit-activity-modal {
	@media (min-width: ($modal-form-width + 110px)) {
		.up-modal-header {
			&>h2 {
				right: 370px !important;
			}
		}
	}

	&.is-flash {
		.up-modal-header {
			.HeaderUserSelect {
				right: 0;
				width: 370px;

				&--error {
					border-top: 0;
					bottom: -1px;
				}
			}
		}

		.up-modal-content.hidden-footer,
		.up-modal-content.hidden-footer~.sidebar-right {
			bottom: unset;
			height: 100%;
		}
	}

	.up-modal-header.up-modal-header.up-modal-header {
		z-index: 10008;

		&>h2 {
			position: absolute;
			left: 15px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			right: 270px;
		}

		.up-modal-header-title {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.up-modal-header-title-holder {
			display: flex;
			flex-direction: column;

			h2 {
				height: auto;
				line-height: 1.3;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 740px;
			}
		}

		.up-modal-header-date {
			display: flex;
			height: auto;
			line-height: 1.3;

			.id {
				margin-left: $space * 3;
				color: $grey-10;
			}
		}
	}

	&.availability-visible {
		.up-modal-content {
			margin-bottom: 170px !important;
		}

		.modal-sidebar.sidebar-right {
			bottom: 227px !important;
		}
	}

	.up-modal-content {
		.hide-when-account {
			display: block !important;
		}

		.show-when-account {
			display: none !important;
		}

		&.account-selected {
			.hide-when-account {
				display: none !important;
			}

			.show-when-account {
				display: block !important;
			}
		}

		#select-account-cover {
			position: absolute;
			top: 0;
			bottom: 0;
			left: $modal-sidebar-width;
			right: 0;
			background-color: $grey-1;
			z-index: 1005;
			padding: 41px 20px 0 193px;
			color: $grey-10;
			font-size: 20px;
		}

		#participant-legend {
			font-size: 10px;
			color: $bright-blue;
			margin: 3px 0 0 3px;
			@include opacity(0);
			@include transition(all ease 200ms);

			&.is-visible {
				@include opacity(1);
			}

			.user {
				color: $blue;
			}

			span {
				margin-right: 10px;
			}

			.fa {
				font-size: 8px;
			}
		}

		.modal-sidebar {
			.pointer {
				cursor: pointer;
			}

			.edit-account {
				position: absolute;
				top: 0;
				right: 0;
			}

			.sidebar-account-title {
				margin-top: 3px;
				margin-right: 20px;
			}

			.sidebar-section {
				.up-rating-label {
					display: inline-block;
					vertical-align: top;
					margin-top: 3px;
				}

				.indicator-wrapper {
					display: inline-block;
					margin-right: 10px;
					margin-top: 3px;
				}

				.select2-dropdown-open {
					width: 400px;
					z-index: 1002;
				}
			}

			#links-section {
				ul {
					list-style-type: none;
					padding-left: 0;
					margin-left: 0;
				}
			}

			.sidebar-contact-select-wrap {
				padding-bottom: 40px;

				.floating-label-input {
					&.has-value {
						padding-top: 18px;

						>label {
							top: 0;
						}
					}

					.select2-container.form-control {
						box-shadow: none;

						.select2-choices {
							position: relative;
							z-index: 1001;
							background-color: transparent;
							border-bottom: 1px solid $grey-8;
							border-radius: 0;
							width: 100%;
							line-height: 1.6em;
							color: $grey-13;
							transition: border ease 200ms;

							input {
								padding: 0 0 0 8px;
							}
						}
					}
				}
			}

			.contact-sidebar-save {
				position: sticky;
				bottom: 0;
				background-color: #fff;
				z-index: 1001;
				padding: 0 0 10px 0;

				&:before {
					content: "";
					position: absolute;
					top: -20px;
					right: 0;
					left: 0;
					height: 20px;
					background: rgb(255, 255, 255);
					background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
				}

				&:after {
					content: "";
					clear: both;
					display: block;
				}

				.btn-wrap-save {
					padding-top: 9px;
				}
			}
		}
	}

	.insert-sign-btn {
		position: absolute;
		left: 1px;
		top: 0;
		height: 35px;
		width: calc(100% - 16px);
		text-align: left;
		z-index: 1000;
		background-color: white;
		border-radius: 0 !important; // to override styling from .up-btn for this specific case
		border-bottom: 1px solid $grey-2 !important;
	}

	#notes-area {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px;
		padding-top: 45px;
		min-width: 100%;
		min-height: 100%;
		line-height: 17px;
	}

	.date-time {
		$date-width: 115px;
		$icon-width: 22px;
		position: relative;
		display: inline-block;
		width: 100%;

		.input-group {
			width: 100%;
		}

		.time-icon,
		.clear-date,
		.date-icon {
			position: absolute;
			top: 0;
			z-index: 1000;
			color: $grey-8;
			left: 0;
			width: $icon-width;
			text-align: center;
			line-height: 34px;
			font-size: 13px;
		}

		.clear-date {
			left: $date-width - $icon-width;
			font-size: 12px;
			z-index: 1001;

			&:hover {
				color: $grey-10;
			}
		}

		.time-icon {
			left: $date-width;
		}

		.date-input {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1000;
			width: 115px;
			height: 100%;
			@include opacity(0);
		}

		.fake-date-input {
			width: $date-width;
		}

		.fake-date-input,
		.time-input {
			padding: 0 5px 0 $icon-width;
			font-size: 13px;
		}
	}

	.modal-center {
		min-height: 500px;

		.form-group,
		.form-flex-group {
			.label-add {
				position: absolute;
				top: -5px;
				right: 5px;
				padding-right: 0;
				text-align: right;
			}

			.has-error .select2-container .select2-choice {
				background-color: $super-light-red;
			}

			.select2-dropdown-open.expand {
				width: 315px;
				z-index: 1010;
				position: absolute;

				&.align-right {
					right: 0;
				}
			}
		}

		.form-flex-group {
			display: flex;
			flex-wrap: wrap;

			&.align-end {
				align-items: flex-end;
			}

			.flex-col {
				width: 50%;
				margin-bottom: 15px;
				position: relative;

				&:nth-child(odd) {
					padding-right: 5px;
				}

				&:nth-child(even) {
					padding-left: 5px;
				}

				@media (min-width: 992px) {
					#date-presets {
						display: block;
					}

					#date-presets-min {
						display: none;
					}

					@media (max-width: 1110px) {
						& {
							#date-presets {
								display: none;
							}

							#date-presets-min {
								display: block;
							}
						}
					}
				}

				@media (max-width: 992px) {
					width: 100%;
					padding: 0 !important;
				}
			}

			.description-wrapper {
				flex: 1;
			}

			.activity-priority-wrapper {
				margin-left: 10px;
			}
		}

		.input-group {
			&>input:last-child {
				border-left: none;
			}
		}

		.phone-calls {
			h2 {
				margin-bottom: 10px;
				margin-top: 10px !important;
			}

			.phone-content {
				box-shadow: 1px 2px 3px rgba($black, 0.1);
				background-color: $white;
				max-height: 800px;
				overflow: auto;
				transition: max-height 0.3s ease;

				audio::-internal-media-controls-download-button,
				audio::-webkit-media-controls-download-button {
					display: none;
				}

				.phone-card {
					cursor: pointer;
					//height:75px;
					height: 45px;
					position: relative;
					padding-left: 10px;
					border-top: 1px solid $grey-4;

					.recipent {
						padding-top: 5px;
					}

					.phone-number {
						margin-left: 5px;
						color: $grey-10;
					}

					.play-btn {
						color: $bright-blue;
						position: absolute;
						right: 60px;
						top: 14px;

						.outer {
							margin-left: 3px;
						}
					}

					.user {
						position: absolute;
						right: 10px;
						top: 8px;
					}

					.hidden-stuff {
						display: none;
						margin-left: 10px;
						margin-right: 10px;
					}

					&.no-audio {
						.play-btn {
							color: $grey-3;
						}
					}

					&.open {
						height: 72px;

						.hidden-stuff {
							display: block;
						}

						.play-btn {
							visibility: hidden;
						}
					}
				}

				&.phone-content__collapsed {
					max-height: 135px;
					overflow: hidden;
				}
			}

			.show-all-calls {
				border-top: 1px solid $grey-4;
				background: $white;
				box-shadow: 1px 2px 3px rgba($black, 0.1);
			}
		}
	}

	.no-transition {
		transition: none !important;
	}

	.activity-priority-field {
		display: flex;
		height: 34px;
		align-items: center;
	}

	.PhoneInput {
		&.has-error {
			.form-control {
				border-bottom-color: $red !important;
			}

			.iti__selected-flag {
				border: none;
				background: none;
			}
		}

		.input-group-addon {
			display: none;
		}

		.iti__selected-flag,
		.form-control {
			border: none;
			background: none;
			box-shadow: none;
			border-radius: 0;
		}

		.iti__selected-flag {
			outline: none;

			&:hover {
				background: none;
			}
		}

		.form-control {
			font-size: 12px;
			height: 24px;
			border-bottom: 1px solid $grey-6;

			&:focus {
				border-bottom-color: $bright-blue;
			}
		}
	}

	&.has-outcome {
		@media (min-width: ($modal-form-width + 110px)) {
			.modal-center {
				padding-right: 360px !important;
			}

			.sidebar-bg.right,
			.modal-sidebar.sidebar-right {
				width: 350px !important;
				padding: 0;
			}

			.HeaderUserSelect,
			.user-select-wrap {
				width: 302px !important;
			}
		}
	}
}

#edit-activity-modal {
	.field-icon {
		position: relative;

		.fa {
			position: absolute;
			top: 0;
			line-height: 34px;
			width: 30px;
			text-align: center;
			color: $grey-8;
		}

		.form-control {
			padding-left: 25px;

			&:focus+.fa {
				color: $bright-blue;
			}
		}
	}

	#type-select {
		.btn-group.dropdown {
			display: block;

			.dropdown-toggle {
				position: relative;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				padding-right: 20px;

				.caret {
					position: absolute;
					right: 7px;
					top: 14px;
				}
			}
		}
	}

	#single-user-select {
		margin-top: 14px;
	}

	#notes {
		position: relative;

		.btn {
			position: absolute;
			top: -8px;
			right: -10px;
		}
	}

	#date-presets {
		margin-bottom: 5px;
	}

	#date-presets-min {
		display: none;
	}

	.date-input {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
	}

	.up-modal-controls {
		.tools-message {
			color: $grey-10;
			float: left;
			padding-top: 3px;
		}

		.dropdown {
			display: inline-block;
		}
	}

	.related-row {
		position: relative;
		margin-top: 15px;

		.related-icon {
			width: 16px;
			color: $black;
		}

		.select2-wrapper {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		.clear {
			position: absolute;
			right: 0;
		}
	}

	.select2-img {
		position: relative;
		top: -2px;
		left: -3px;
		@include border-radius(25px);

		&.multi {
			top: 0;
			left: 0;
		}
	}
}