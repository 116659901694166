@import "../../../styles/variables";

.EditSocialEventContacts .Button .Button--inline {
	padding: 0;
}

.EditSocialEventContacts__Tab-wrapper {
	display: flex;
	background-color: $white;
	position: relative;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	z-index: 1002;
}

.EditSocialEventContacts__Status-column-status {
	color: $grey-8;
}

.EditSocialEventContacts__Status-column-do-checkin {
	color: $bright-blue;
	cursor: pointer;
}

.EditSocialEventContacts__Contact-row:hover .EditSocialEventContacts__contact-control .DropDownMenu .Button {
	opacity: 1;
}

.EditSocialEventContacts__Contact-row .Avatar {
	margin-right: 4px;
	z-index: 0;
}

.EditSocialEventContacts__Contact-row .EditSocialEventContacts__Account-manager {
	display: flex;
}

.EditSocialEventContacts__contact-control .DropDownMenu {
	float: right;
}

.EditSocialEventContacts__contact-control .DropDownMenu__table-wrapper {
	top: 34px;
}

.EditSocialEventContacts__contact-control .DropDownMenu .Button {
	height: 100%;
	padding: 12px;
	opacity: 0;
	transition: opacity 0.1s ease-out;
}

.EditSocialEventContacts__contact-control .DropDownMenu .Button--expanded {
	opacity: 1;
}

.EditSocialEventContacts__contact-control .Button--expanded::after {
	content: " ";
	width: 40px;
	height: 40px;
	position: absolute;
	right: 0;
	top: 0;
	box-shadow: 1px 0 0 0 $grey-4, 0 3px 6px 0 rgba($black, 0.2);
}

.EditSocialEventContacts__Contact-multi-select {
	padding-left: 11px;
}

.EditSocialEventContacts__status-filter-btn {
	border-right: 1px solid $grey-6;
}

.EditSocialEventContacts__status-filter-btn:hover {
	background-color: $grey-1 !important;
}

.EditSocialEventContacts__waiting-list-info {
	min-height: 150px;
}

.EditSocialEventContacts__waiting-list-info .CardContent {
	padding-right: 280px;
}

.EditSocialEventContacts__waiting-list-info-img {
	position: absolute;
	top: -30px;
	right: 60px;
	width: 110px;
}
