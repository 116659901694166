@import "~StyleVariables";
.EditPhonecallNoContact {
	.Card__slice {
		&:before {
			width: 150px;
		}
		&:after {
			width: 100px;
		}
	}

	& > .Row {
		position: relative;
		z-index: 1001;
	}

	.Label {
		font-weight: $boldFontWeight;
		margin-bottom: 20px;
	}
}

.EditPhonecallNoContact__button-wrapper {
	position: absolute;
	top: -9999;
	left: -9999;
	visibility: hidden;
}
