@import "~StyleVariables";

.ClientCardSidebar__Summary {
    &__summary {
        .InactiveLabel {
            float: right;
        }

        .Headline {
            word-wrap: anywhere;
        }
        .JourneyStepDisplay {
            width: 100%;
        }

        >.Row >.Text {
            margin-bottom: 10px;
        }

        &--isSubaccount:not(#{&}--inactive) {
            background-color: $super-light-blue;
            
            .ClientCardSidebar__Summary__noNotes:hover {
                background-color: #{darken($super-light-blue, 5%)};
            }
        }

        &--inactive {
            background-color: $grey-1;
            .Headline {
                font-style: italic;
                color: $grey-10;
            }
        }
    }

    &__notes, &__noNotes {
        margin-top: 8px;
    }

    &__noNotes {
        padding: 8px;
        border-radius: $border-radius;
        width: 100%;
        &:hover {
            background-color: $grey-2;
            color: $grey-10;
            padding-left: 20px;
        }

        transition: color 0.25s, background-color 0.25s, padding-left 0.25s;
    }
}
