@import "~StyleVariables";

.EditorSidebarToggle {
	height: 50px;
	line-height: 50px;
	width: 50px;
	background-color: $green;
	color: $grey-10;
	cursor: pointer;
	text-align: center;
	color: $white;
	box-shadow: 0px 0 10px 0 rgba($black, 0.4);
	border-radius: 50%;

	&:hover {
		background-color: darken($green, 5%);
	}
}
