@import "~StyleVariables";

.ReportcenterWidgetActionMenu {
	position: absolute;
	top: 0;
	right: 0;

	&__item {
		height: 40px;
		cursor: pointer;

		&:hover {
			background-color: $grey-1;
		}

		.Column {
			align-self: center;

			&:first-of-type:not(.ReportcenterWidgetActionMenu__item-icon) {
				padding-left: 15px;
			}

			&.ReportcenterWidgetActionMenu__item-icon {
				text-align: center;
				flex: 0 0 40px;
				padding-left: 8px;
			}
		}
	}

	.DropDownMenu__content {
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		overflow: hidden;
		z-index: 1001;
	}

	&__actions {
		& > .Text {
			padding: 0 15px;
		}
	}

	&__btn {
		color: $grey-10 !important;
		width: 50px;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		padding: 0;

		&:hover {
			color: $black !important;
		}
	}
}
