@import "~StyleVariables";

.ContentWidget {

	&__container {
		height: 300px;
		overflow: auto;
	}

	&__spacer {
		height: 200px;
	}
}