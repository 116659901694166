@import "~StyleVariables";

.CriteriaTableFooter {
	height: $space * 11;
	align-items: center;
	border-top: 1px solid $grey-6;
	padding: 0 $space * 3;

	& > .Column:first-child {
		flex: 0 0 auto;
	}

	&__select-wrap {
		position: relative;

		& > .Button {
			margin-left: $space * 3;
		}
	}

	&__select {
		display: none;
		position: absolute;
		top: -2px;
		left: $space * 3;
		width: 400px;

		&--visible {
			display: block;
		}

	}

	// Remove when TICKET_RULES is released
	.CriteriaTableFooter__select .select2-container {
		min-width: 350px;
	}
}
