@import "~StyleVariables";

.ReportcenterFilterSection {
	&__header {
		height: 30px;
		padding: 0 30px;
		align-items: center;
		display: flex;
		width: 100%;
		background-color: $grey-1;
		border-top: 1px solid $grey-2;
	}
}