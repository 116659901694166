.UsersAndRoles {
	width: 400px;

	&__name {
		display: inline;
	}

	&__asterisk {
		display: inline;
		margin-left: 4px;
	}

	&__whoIsItFor {
		margin-top: 8px;
	}

	.ButtonSelect {
		width: 400px;
	}
}
