@import "~StyleVariables";

.ReportcenterWidgetLegend {
	min-height: 45px;
	max-height: 45px;
	position: relative;
	overflow-y: auto;
	background-color: $white;
	display: flex;
	align-items: center;
	padding: 5px 15px;
	flex-wrap: wrap;

	&__label,
	.Icon,
	.Text {
		display: inline-block;
		margin-bottom: 1px;
	}

	.Icon {
		vertical-align: middle;
		font-size: 10px;
	}

	.ReportcenterWidgetLegendIcon {
		margin-right: 15px;
		display: flex;
		align-items: center;

		&--lg {
			line-height: 22px;
			.Icon,
			.Text {
				font-size: 14px;
			}
		}

		&__iconBackground {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			text-align: center;
			display: inline-flex;
			align-content: center;
			justify-content: center;
			align-items: center;

			.Icon {
				margin: 0;
				font-size: 12px;
			}
		}
	}
}
