@import "~StyleVariables";

.FormFilterSelect {
	display: flex;
	flex-direction: column;

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.DropDownMenu {
		display: inherit;
	}
	.ReportcenterFilter {
		padding-top: 4px;
	}

	.DropDownMenu__content {
		top: 36px !important;
		max-height: 300px;
		overflow: hidden;
		overflow-y: auto;
		max-width: 716px;
	}

	.DateFilterSelect__column {
		.Label {
			font-weight: $regularFontWeight;
		}
	}
	&__add-wrapper {
		display: flex;
		flex-direction: column;

		.DropDownMenu__content {
			padding: 0 4px;
			box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);

			.ReportcenterFilter {
				margin: 4px 0;
			}
			.ReportcenterFilter:first-of-type {
				margin-top: 6px;
			}
		}
	}

	&__add-filter {
		display: inherit;
		align-items: center;
		padding: 8px;
		transition: all ease-out 200ms;
		border: 1px solid transparent;
		border-radius: 4px;

		.Icon {
			margin-right: 4px;
			color: $green;
		}

		&:hover {
			cursor: pointer;
		}

		&--active {
			border-color: $grey-4;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

			background-color: transparent;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: -2px;
				height: 4px;
				background-color: $white;
				z-index: 1000;
			}
		}
	}
}
