@import "~StyleVariables";

.TodoListHeader {
	position: relative;
	z-index: 1000;
	background-color: $white;
	&__header-buttons {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__header-buttons-left {
		margin-left: 10px;
	}

	&__header-buttons-right {
		.ButtonSelect,
		.Input,
		.Tooltip,
		> .Button {
			display: inline-block !important; // must be important because of Tooltip
			margin-right: 10px;
		}

		.Input,
		.Input__icon,
		.Input__input {
			height: 30px !important;
			line-height: 30px !important;
		}

		.Input__input {
			font-size: 12px;
			width: 85px;

			&:focus,
			&[value^=""] {
				width: 170px;
			}
		}
	}

	&__focus-mode-button .Icon {
		margin-right: 4px;
	}

	.TodoListEntityFilter {
		display: inline;
	}

	.Title {
		display: inline;
		margin-right: 10px;
	}

	&__only-wide {
		margin-left: 4px;
	}

	@media (max-width: 1250px) {
		&__only-wide {
			display: none;
		}
	}
}
