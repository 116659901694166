@import "~StyleVariables";

.FormDashboard {
	&__FormContainer {
		display: flex;
		box-shadow: 0 0 1px 0 rgba($grey-11, 0.2);
		z-index: 4;
		height: 300px;
	}

	&__Graph-wrapper {
		width: 70%;

		&.CardContent {
			padding: 22px 30px;
		}
	}

	&__FormPreview-wrapper {
		box-shadow: 0 0 1px 0 rgba($black, 0.2);
		width: 30%;
		background-color: $grey-1;
		overflow: hidden;
	}

	&__Graph-heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		color: $grey-10;
		font-size: 16px;

		.Headline {
			font-style: italic;
			color: $black;
		}
	}

	&__Graph-heading--NoData {
		.Headline {
			color: $grey-10;
		}
	}

	&__Graph-message {
		display: flex;
		width: 500px;
		margin: 72px auto 20px;

		img {
			margin-right: 20px;
		}

		.NoData {
			margin: 0 auto;
		}
	}

	&__Table-toolbar {
		border-radius: 2px;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		margin-bottom: 2px;
		display: flex;
		align-items: center;
	}

	&__Table-tabs {
		width: 396px;
	}

	&__LandingPagePreview-wrapper {
		min-width: 300px;
		width: 30%;
		box-shadow: -1px 0 0 0 $grey-5;
		margin-left: auto;
		padding: 0;

		> div:first-of-type {
			width: 100%;
			height: 100%;
		}
	}

	&__FormSubmits-empty {
		height: 400px;
		text-align: center;
		padding: 50px 0;
		background-color: $grey-1;

		.Text {
			margin-bottom: 10px;
		}

		.Loader {
			margin: 50px auto;
		}
	}

	&__Export-button {
		margin-left: auto;

		.Icon {
			margin-right: 5px;
		}
	}

	.TableColumn {
		&:last-child {
			padding-right: 0;
		}
	}

	.inline-confirm-tab {
		padding-top: 10px;
		height: 47px;
	}

	.inline-confirm-container {
		top: 47px;
	}
}
