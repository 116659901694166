@import "~StyleVariables";
.CreateExternalContact {
	width: 420px;
	height: 100%;
	&__content-wrapper {
		height: calc(100% - 50px);
	}

	&__content {
		height: 100%;
		background-color: $grey-1;
		overflow-y: auto;
	}

	&__description {
		background-color: $white;
		border-radius: $border-radius;
		border: 1px solid $grey-6;
	}

	&__save {
		z-index: 10;
		top: auto;
		position: sticky;
		bottom: 0;
		box-shadow: 0 0.5px 5px 0 rgba($black, 0.2);
	}

	.CreateCallClientContactSelect {
		&:first-of-type:not(:last-of-type) {
			margin-bottom: $space * 4;
		}
		&:last-of-type {
			margin-bottom: unset;
		}
	}
}
