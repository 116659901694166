@import "../../../../styles/variables.scss";

.RelationsSignalDrawer {
	height: 100%;
	background-color: white;
	--drawerWidth: 700px;
	z-index: 10000;
	visibility: visible;
	width: var(--drawerWidth);

	.DrawerHeader {
		position: sticky;
		top: 0;
	}

	.Drawer__children {
		height: 100%;
		overflow: auto;
	}
}
