@import "~StyleVariables";
@import "../shared";

.PeriodizationListSideBar {
	background-color: $white;
	z-index: 1000;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	position: sticky;
	left: 0;
}

.PeriodizationListSideBar .PeriodizationListTable {
	width: 100%;
}

.PeriodizationListSideBar .PeriodizationListTable {
	grid-auto-columns: 100%;
}

.PeriodizationListSideBar__header {
	height: $headerHeight;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: $columnPadding;
	padding-bottom: $headerBottomPadding;
}

.PeriodizationListSideBar__year-select {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 20px);
}

.PeriodizationListSideBar__year-select .Text {
	cursor: pointer;
	transition: color ease 200ms;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: $boldFontWeight;
}

.PeriodizationListSideBar__year-select .Text:hover {
	color: $black;
}

.PeriodizationListSideBarColumn {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.PeriodizationListSideBarColumn__bottom-row {
	display: flex;
	justify-content: space-between;
}
