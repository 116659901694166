.VerifyBudget {
	&__content {
		width: 790px;
		align-self: center;
	}

	&__buttonBoxes {
		.ButtonBox {
			flex: 1;
		}
	}
}

.FullscreenModal > .VerifyBudget {
	position: absolute;
	width: 100%;
}
