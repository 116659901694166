.ClickableCard {
	cursor: pointer;
	transition: background ease-in 0.2s;
}

.ClickableCard:hover {
	background: #fafafa;
}

.ClickableCard .ClickableCardImg {
	height: 40%;
	width: 100%;
	text-align: center;
	transition: transform ease-in 0.2s;
	transform: translateY(0);
}

.ClickableCard .ClickableCardImg > img {
	width: auto;
	height: 100%;
}

.ClickableCard:hover .ClickableCardImg {
	transform: translateY(-10px);
}
