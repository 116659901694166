@import "~StyleVariables";

.ListFormSubmits {
	// Temporary styling (copied from old view) until the new LeadSourceColumn component is done
	.LeadSource {
		.source-icon {
			display: inline-block;
			vertical-align: top;
			padding: 10px;
			padding-left: 4px;

			.fa {
				font-size: 14px;
				margin-left: 2px;
			}
		}

		.title,
		.subtitle {
			position: absolute;
			top: 3px;
			left: 26px;
			max-width: 77%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.no-value {
			top: 10px;
		}

		.subtitle {
			top: 16px;
			color: $grey-10;
			font-size: 11px;
		}

		.no-source {
			top: -7px;
			left: 8px;
			color: $grey-10;
			font-style: italic;
		}
	}

	// Temporary styling (copied from old view) until the new ReactTemplates.processedByColumn component is done
	.column-assigned {
		.assigned-wrap {
			display: flex;
		}

		.inactive-user-icon-wrap {
			display: inline-block;
			font-size: 16px;
			width: 25px;
			text-align: center;
			height: 25px;
			line-height: 25px;
		}

		&.inactive-user {
			color: $grey-8;
		}

		.fa-user-circle-o {
			position: relative;
			margin-right: 5px;
		}

		.fa-plus {
			position: absolute;
			font-size: 8px;
			left: 8px;
			top: -2px;
		}

		.assigned-info {
			display: inline-block;
			font-size: 11px;
			vertical-align: top;
			line-height: 13px;

			&.no-date {
				line-height: 23px;
			}

			.user-name {
				max-width: 90px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
}
