@import "~StyleVariables";

.CustomScripts {
	&__Editor {
		height: 200px;
	}
	&__SubTitleWrapper {
		padding: 0 20px;
		position: relative;
		bottom: 15px;
		color: $grey-10;
		span {
			color: $bright-blue;
			margin-left: 5px;
		}
	}
}
