@import "~StyleVariables";

.AcceptTerms {
	width: 1038px;
	background-color: $white;

	& > .Row {
		min-height: 470px;
		align-items: stretch;

		& > .Column {
			padding: 50px;
		}
	}

	&__illustration-col {
		display: flex;
		background-color: $dark-green;
		position: relative;

		&:before {
			content: "";
		}

		img {
			position: absolute;
			bottom: 0;
			right: 16px;
		}
	}

	&__terms-link-block {
		.Text,
		.Link {
			display: inline;
		}
	}
}
