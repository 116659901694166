@import "~StyleVariables";

.ColumnChartWidget--multi-columns {
	.ColumnChartWidget__column-wrap {
		display: flex;
		flex: 1 0 0;
		position: relative;
		margin-top: 15px;
		border-bottom: 2px solid $green;
	}

	.ColumnChartWidgetBars--has-data {
		border-bottom: none;
	}

	.ColumnChartWidgetBar {
		left: 0;
		width: 30px;
	}

	.ColumnChartWidget__column-wrap > div:last-child {
		flex: 1 0 0;
	}

	.ColumnChartWidgetBars {
		flex: none;
		width: 30px;
	}

	.ColumnChartWidgetBars .Tooltip {
		left: 0;
		width: 30px;
	}

	.ColumnChartWidget__bar {
		width: 30px;
	}

	.ColumnChartWidget__bar--loading:nth-child(3) {
		left: 40px;
	}

	.ColumnChartWidget__bar--loading:nth-child(4) {
		left: 70px;
	}
}
