@import "~@upsales/components/Utils/colors";

.LetsCrunchSomeNumbers {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -150px;

	&__headline {
		width: 100%;
		justify-content: center;
	}
	&__data {
		width: 100%;
		margin-top: 50px;
		justify-content: center;

		.FunnelWidget {
			width: 800px;
			height: 240px;
			&__values {
				.Row:nth-of-type(2) {
					display: none;
				}
			}
		}
		.LetsCrunchSomeNumbers__list {
			position: absolute;
			width: 300px;
		}
		.LetsCrunchSomeNumbers__funnel {
			position: absolute;
		}
	}
	&__item {
		height: 64px;

		.Row {
			width: 470px;
		}

		.Column:first-of-type {
			display: flex;
			align-items: center;
			justify-content: center;
			.Icon {
				font-size: 30px;
			}
		}

		.Column:nth-of-type(2) {
			width: 100px;

			.Headline {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	img {
		position: absolute;
		left: 0;
		bottom: 0;
	}
}
