@import "~StyleVariables";

@keyframes burn {
	0% {
		color: $red;
	}
	50% {
		color: $orange;
	}
	100% {
		color: $red;
	}
}

.TodoListTableRow {
	height: 50px;

	&__descriptionCol {
		.Text {
			line-height: 12px;
		}
		&__subtitle.Text {
			font-size: 10px;
			line-height: 15px;
		}
	}

	&:hover {
		.TodoListTableRow__hotIcon .Icon {
			animation-name: burn;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}
	&__quickactions {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.Button {
			padding: 0 8px;
		}

		.Button.btn-grey > .Icon {
			color: $grey-8;
			&:hover {
				color: $grey-10;
			}
		}
	}

	.TableColumn__subtitle,
	&__ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.outcomeButtonNo {
		margin-right: 8px;
	}
}

.TodoListTableRow__flagCol {
	min-width: 30px;
}

.TableRow.TodoListTableRow--selected {
	background-color: $grey-2;
	box-shadow: inset 4px 0 $green;
}

.TableRow.TodoListTableRow--selectedHighlight {
	background-color: darken($super-light-yellow, 9%);
	box-shadow: inset 4px 0 $green;
}

.TableRow.TodoListTableRow--isCanceled {
	text-decoration: line-through;
	color: $grey-10;

	.TodoListTableRow__titleCol-wrap {
		.Text {
			color: $grey-10;
			font-style: italic;
		}
	}
}

.TodoListTableRow__titleCol {
	padding-left: 4px;
	width: 10%;
	white-space: nowrap;
	max-width: 200px;
	min-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	.Text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}
	&-wrap {
		display: flex;
		flex-direction: column;
		min-width: 0;
	}
	&-title {
		width: fit-content;
		&--strike {
			position: relative;
			color: $grey-10;
			&::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background: $black;
				animation: strike 200ms cubic-bezier(0.8, 1.56, 0.8, 1);
				animation-fill-mode: forwards;
			}
		}
	}

	&-subtitle .Text {
		display: inline;
	}

	@keyframes strike {
		0% {
			width: 0;
		}
		100% {
			width: 100%;
		}
	}
}

.TodoListTableRow__buttonCol {
	max-width: 200px;
	white-space: nowrap;

	&--small {
		.Button {
			padding: 0 4px;
			width: 84px;
			.Text {
				margin: auto;
			}
		}
	}

	.Text {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.TodoListTableRow__buttonCol-wrap {
	width: 100%;

	.Button {
		max-width: 100%;
	}
}

.TodoListTableRow__iconCol .Checkbox {
	border: solid 1px $grey-6;
	display: inline-block;
}

.TodoListTableRow__withWhomCol {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 1%;
	max-width: 350px;

	.Text {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.TodoListTableRow__iconCol {
	padding-right: 0px;
	text-align: center;
	> .Icon {
		transition: all ease-out 300ms;
		font-size: 20px;
	}
}

.TodoListTableRow--highlight {
	background-color: $super-light-yellow;
	&:hover {
		background-color: darken(
			$super-light-yellow,
			5%
		) !important; // To override the default hover color when it's a highlighted row
	}
}

.TodoListTableRow--redDate {
	.TodoListTableRow__titleCol-subtitle {
		color: $red;
	}
}

.TodoListTableRowDescriptionCol {
	&__subtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.TodoListTableRow__hotIcon {
	font-size: 12px;
	width: 15px;
	height: 15px;
	margin-left: -22px;
	padding-right: 4px;
	line-height: 15px;

	.Icon {
		color: $red;
		padding-top: 1px;
		width: 21px;
		height: 21px;
		border-radius: 40px;
		background-color: $super-light-yellow;
	}
}
@media (max-width: $responsiveWidht2) {
	.TodoListTableRowDescriptionCol {
		padding-left: 25px;
	}
	.TodoListTableRow__titleCol {
		max-width: 150px;
	}
}
