@import "../../../../styles/variables";
/* 
	This is used filters/history/ i just moved it here so i did not 
	had to duplicate the code. But filters/history/ does not use this
	component. 
*/
.datepicker-group {
	display: flex;
	position: relative;

	.datepicker-select-wrap {
		width: 100%;
	}

	.datepicker-wrap {
		position: absolute;
		right: 18px;
		width: calc(100% - 18px);

		input:not(.Input__input) {
			display: inline-block;
			position: relative;
			border-radius: 3px;

			&:first-of-type {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				width: calc(50% - 1px);
				border: 0;
				margin-top: 1px;
				margin-left: 1px;
				height: 32px;
			}

			&:last-of-type {
				border-radius: 0;
				width: 50%;
				margin-left: -1px;
				border: 0;
				border-left: 1px solid $grey-8;
				border-right: 1px solid $grey-8;
				margin-top: 1px;
				height: 32px;
			}

			&:focus {
				box-shadow: inset 0 1px 1px rgba($black, 0.05);
			}

			&:disabled.form-control {
				background-color: $grey-4 !important;
			}
		}
	}

	&.datepicker-custom-visible {
		.select2-arrow {
			border-left: none;
			filter: none;
		}
	}

	.datepicker-select {
		width: 100%;
	}

	#datepicker-container {
		position: relative;
	}

	#datepicker-container .dropdown-menu {
		left: auto !important;
		right: 0px;
	}

	.datepicker-wrap .Input {
		flex-grow: 1;
	}

	.datepicker-wrap .Input input {
		border-radius: 0;
		width: 100%;
		margin: 1px 0px 0px 0px;
		height: 32px;
		border: 0;
		border-right: 1px solid $grey-8;
	}

	.datepicker-wrap .Row {
		flex-wrap: nowrap;
	}

	.datepicker-wrap .Block {
		font-size: 13px;
		white-space: nowrap;
		flex-grow: 0;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
		background-color: $green;
		color: $white;
		display: flex;
		align-items: center;
	}
}

.StackedDateInput__select2-dropdown {
	.select2-search {
		padding: 4px;
		border-top: 1px solid $green;
	}
}
