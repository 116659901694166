@import "~StyleVariables";

.LeadDrawer {
	height: 100%;
	min-width: 550px;
	background-color: $white;
	&__wrapper {
		min-width: 550px;
	}

	&__info {
		margin: 28px 15px 24px 15px;
	}
	&__info-col {
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		width: 100%;

		.Tooltip {
			width: 110px;
		}
	}

	&__event {
		display: -webkit-inline-box;

		.Icon {
			margin-top: 3px;
		}
	}

	&__date {
		padding-right: 8px;
	}

	&__show-more {
		width: 500px;
		font-size: 12px;
		display: flex;
		justify-content: center;
		margin-top: 8px;
	}
}
