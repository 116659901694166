@import "~StyleVariables";

$xxlPadding: $space * 10;

.Add {
	position: absolute;
	top: $header-height;
	overflow: auto;
	height: calc(100vh - #{$header-height});
	width: 100%;

	&__content {
		height: 100%;
		padding: $xxlPadding;
		overflow-y: auto;
	}

	&__backButton {
		cursor: pointer;

		.Title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	
	.SelectOptionHeader {
		background-color: $grey-1;

		.Text {
			font-size: 12px;
		}
	}

	.Column:nth-child(2),
	.Column:nth-child(3) {
		.Flex {
			height: 100%;
		}
	}

	&__footer {
		position: sticky;
		bottom: 0;
	}

	&__selectCustomRow {
		width: 100%;
	}

	.SelectMultiInput__item {
		display: none;
	}
}
