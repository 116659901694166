@import "~StyleVariables";

.PhonecallCustomFields {
	&__title {
		height: 47px;
		position: relative;
		display: flex;
		align-items: center;
		background-color: $grey-4;
		padding-left: 15px;
	}

	&__custom-field {
		margin-top: 15px;
		margin-bottom: 15px;
		.Label {
			font-weight: $boldFontWeight;
		}
		&-label {
			display: flex;
			.Icon {
				align-self: center;
				font-size: 5px;
				margin-left: 5px;
			}
		}

		&-input {
			width: 100%;
		}
	}

	&__show-more-button {
		margin-bottom: 15px;
		margin-left: 3px;
		&:focus {
			text-decoration: none;
		}
		.Icon {
			margin-left: 5px;
		}
	}

	.NumberInput {
		.Input {
			width: 100%;
		}
	}
}
