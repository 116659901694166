.AssignModalCloseActivity {
	padding: 50px 120px;
}

.AssignModalCloseActivity__label {
	margin-top: 30px;
}

.AssignModalCloseActivity__submit {
	margin-top: 30px;
}
