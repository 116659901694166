@import "../../../styles/variables";

.UpsalesKnowledgebaseCard {
	text-align: left;
}

.UpsalesKnowledgebaseCard .Row .Link {
	margin-top: 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.UpsalesKnowledgebaseCard .CardHeader {
	padding-top: 20px;
	margin-bottom: 10px;
	text-align: center;
}

.UpsalesKnowledgebaseCard .Button {
	border-top: 1px solid $grey-3;
}

.UpsalesKnowledgebaseCard .CardHeader__title-wrap {
	margin-top: 10px;
}
