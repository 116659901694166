@import "~StyleVariables";
.SelectItem {
	&__item {
		cursor: pointer;
		position: relative;
		width: 100%;
		background-color: #fff;
		border-top: 1px solid $grey-6;
		padding: 10px 0px 10px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 70px;

		label {
			cursor: pointer;
			padding-left: 10px;
			font-weight: normal;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		.Checkbox {
			min-width: 20px;
			border: solid 1px $grey-8;
		}
		.up-btn {
			display: none;
			position: absolute;
			right: 13px;
			top: 8px;
		}

		&:hover {
			background-color: $grey-1;

			.up-btn {
				display: block;
			}
		}
	}
	&__group-title {
		border-left: 0;
		color: #fff;
		font-weight: $boldFontWeight;
		background-color: $grey-3;
		color: $grey-13;
		margin-bottom: 0;
		padding-bottom: 0px;
		padding-top: 3px;
		&:hover {
			background-color: $grey-3;
		}
		label {
			font-weight: $boldFontWeight;
		}
	}
}
