@import "~StyleVariables";

.MailContentEditor {
	&__sidebar {
		overflow: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		.ColorSelect {
			width: 80px;
			display: inline-block;
		}
		.ColorSelect__RealColor {
			border: 1px solid $grey-10;
		}

		.ColorSelect__PickerHolder {
			right: 0;
		}

		.StepIntInput {
			width: 130px;
			display: inline-flex;
		}

		.cke {
			opacity: 1;
			margin: 0 -15px;
			border: none;
			box-shadow: none;
		}

		.color-input {
			width: 83px;
			padding: 0 10px;
			border-radius: 3px;
			height: 30px;
			border: 1px solid $grey-10 !important;
		}
	}

	.EditorWrap__sidebar {
		top: 93px;
	}

	&__sidebar-section-flex {
		display: flex;
		gap: 8px;
		align-items: center;
	}
}

.MailContentEditor__sidebar-section {
	padding: 15px;
	padding-bottom: 10px;
	border-bottom: 1px solid $grey-4;

	.MailContentEditor__sidebar-section-input-row {
		margin: 10px -5px;

		.Column:first-of-type {
			padding: 0 4px;
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
	}

	.MailContentEditor__sidebar-section-no-collapse {
		.ButtonBox {
			width: 164px;
			align-items: center;
			flex-direction: column-reverse;
			flex: 1;
		}
	}

	.MailContentEditor__sidebar-header {
		margin: 0 -5px;

		.Column {
			padding: 0 5px;
		}

		.Headline {
			line-height: 34px;

			.Help {
				display: inline-block;
				margin-left: 5px;
			}
		}
	}
}

.MailContentEditor__preview {
	margin: 0 auto;
	vertical-align: top;
	margin-bottom: 10px;
	position: relative;
}

.MailContentEditor__preview-loader {
	position: absolute;
	top: 50%;
	margin-left: -25px;
	margin-top: -25px;
	left: 50%;
}

.MailContentEditor__border-style .Button > div {
	border-width: 0;
	border-bottom-width: 4px;
}

.MailContentEditor__empty .Headline {
	height: 50px;
	line-height: 50px;
	padding: 0 15px;
}

.MailContentEditor__empty-selector {
	padding: 10px;
	background-color: $grey-1;
	border-top: 1px solid $grey-6;
	border-bottom: 1px solid $grey-6;

	& > div {
		display: inline-block;
		width: 50%;
		padding: 5px;
		vertical-align: top;

		&:hover {
			button {
				box-shadow: 0 1px 2px rgba($black, 0.4);
			}
			.Icon,
			.Text {
				color: $bright-blue;
			}

			.icon-fill {
				fill: $bright-blue;
			}
		}

		button {
			background-color: $white;
			width: 100%;
			text-align: center;
			border-radius: 3px;
			padding: 20px 0;
			transition: all ease-out 200ms;
			box-shadow: 0 1px 2px rgba($black, 0.2);
			border: 1px solid $grey-6;

			.Icon,
			.icon {
				height: 33px;
				font-size: 28px;
				margin-bottom: 5px;
			}

			.Icon {
				margin-bottom: 5px;
			}

			.Icon,
			.Text {
				transition: all ease-out 200ms;
				color: $grey-10;
			}

			.icon-fill {
				fill: $grey-10;
			}
			.Icon-button {
				font-size: 48px;
				line-height: 33px;
			}
		}
	}
}

.MailContentEditor__code-editor {
	height: 380px;

	&-loader {
		width: 30px;
		height: 30px;
		position: relative;
		bottom: 45px;
		right: 10px;
		float: right;
	}
}
