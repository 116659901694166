@import "~StyleVariables";

.RangeInput {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	div {
		flex-grow: 1;
	}
	&__start {
		margin-right: -1px;
		input {
			border-radius: $border-radius 0px 0px $border-radius;
		}
	}

	&__end input {
		border-radius: 0px $border-radius $border-radius 0px;
	}
}
