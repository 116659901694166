.ReportcenterFilterDateSelectOptions {
	display: flex;

	.Column {
		padding: 10px 8px;
		flex-basis: unset;

		.RadioListItem {
			.RadioItem {
				min-width: 20px;
			}
		}
	}
}
