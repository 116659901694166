@import "~StyleVariables";

.FileBrowserFile {
	$infoMinHeight: 50px;
	border-radius: 2px;
	box-shadow: 0 1px 4px 0 rgba($black, 0.2);
	width: 200px;
	height: 220px;
	margin: 10px;
	display: inline-block;
	transition: all ease-out 200ms;
	overflow: hidden;

	&__info {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		min-height: $infoMinHeight;
		border-top: 1px solid $grey-4;
		background-color: $white;
		padding: 8px;

		.Text {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__checked {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		bottom: $infoMinHeight;
		visibility: visible;
		opacity: 1;
		padding: 20px;
		padding-top: 50px;

		.Button {
			position: relative;
		}

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(315deg, $blue 0%, $medium-purple 100%);
			opacity: 0.5;
			top: 0;
			left: 0;
		}
	}

	&__hover-tools {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		bottom: $infoMinHeight;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
		padding: 20px;
		padding-top: 50px;

		.Button {
			position: relative;
		}

		.Checkbox {
			position: relative;
		}

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(315deg, $blue 0%, $medium-purple 100%);
			opacity: 0.5;
			top: 0;
			left: 0;
		}
	}

	&__delete.Button {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	&__select.Checkbox {
		position: absolute;
		top: 20px;
		right: 160px;
	}

	&__select.Button {
		margin-top: 30px;
	}

	&__label {
		display: flex;
		align-items: baseline;
	}

	&__extra-info {
		display: flex;
		flex-direction: column;

		.Icon {
			color: $grey-11;
			font-size: 10px;
		}

		.Text {
			margin-left: 8px;
			max-width: 165px;
		}
	}

	&__preview {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		bottom: $infoMinHeight;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% auto;
		background-color: $grey-1;
	}

	&__loader {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 50px;
		right: 0;
		z-index: 1000;

		.Loader {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -12.5px;
			margin-left: -12.5px;
		}
	}

	&:hover {
		box-shadow: 0 5px 10px 0 rgba($black, 0.2);

		.FileBrowserFile__hover-tools {
			visibility: visible;
			opacity: 1;
		}
	}

	&__dimensions {
		padding: 3px 5px;
		background: rgba(255, 255, 255, 0.7);
	}
}

img.FileBrowserFile__preview {
	top: 30px;
}
