@import "~StyleVariables";

.TodoTimePicker {
	.Icon-trash {
		align-self: center;
		margin-left: 11px;
		cursor: pointer;
	}

	&__labelSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 30px;
		.Title {
			display: inline-block;
		}
		.Button {
			padding-left: 3px;
			padding-right: 3px;
		}
	}

	&__dueDateInputWrapper {
		display: flex;
		.ButtonGroup {
			width: 100%;
			.TimeInput {
				flex: 1;

				.Input__input {
					border-left: 0;
				}
			}
			.DateInput {
				flex: 2;

				.DateInput__popup {
					z-index: 1001;
				}

				.Input__input {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
	&__content {
		z-index: 1;
	}

	&__toggleLabel {
		display: flex;
		align-items: center;

		.Link {
			margin-left: auto;
		}
	}

	&--withToggle &__content {
		transition-property: height;
		height: 0px;
	}

	&--withToggle#{&}--showInput &__content {
		margin-top: 10px;
		height: 64px;
	}
	.Column:last-of-type {
		flex-direction: row;
		justify-content: space-between;
		.Link {
			text-decoration: none;
		}
	}
}
