@import "~StyleVariables";

.PeriodTree {
	--solid-line-height: 0px;
	position: relative;
	width: 100%;
	
	&__circle-icon {
		position: relative;
		z-index: 2;
		font-size: 10px;
		margin-right: 8px;
	}

	&__solid-line {
		transition: all 0.3s cubic-bezier(0.41, -0.39, 0.27, 1.46);
		transition-delay: 100ms;
		z-index: 2;
		position: absolute;
		height: var(--solid-line-height);
		width: 20px;
		top: 27px;
		left: 26px;
		bottom: 23px;
		border: 1px solid $green;
		border-top: transparent;
		border-right: transparent;
		opacity: 0;
		transform: translateY(5px);
		
		&--isActive {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&__headline {
		transition: all 0.2s ease-in;
		.Icon {
			transition: all 0.2s ease-in;
		}

		> div {
			display: flex;
			align-items: center;
		}

		height: 52px;
		cursor: pointer;
		padding: 0 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-right: 4px solid $grey-4;

		&__trash {
			transition: opacity 0.15s ease-in;
			opacity: 0;
			.Icon:hover {
				color: $black;
			}
		}

		&:hover {
			border-color: $grey-4;
			background-color: $grey-1;
			.PeriodTree__headline__trash {
				opacity: 1;
			}
		}
		&--isActive {
			.Text:first-child {
				color: $green;
			}
			.Icon {
				color: $green;
			}
		}
		&--isDisabled {
			cursor: default;
			border-color: $grey-4 !important;
			background-color: $white !important;
		}
		&--isActiveNoChildren {
			border-right: 4px solid $green !important;
		}
	}

	&__child-period {
		transition: all 0.2s ease-in;
		position: relative;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px;
		padding-left: 46px;
		border-right: 4px solid $grey-4;

		&:hover {
			background-color: $grey-1;
			border-color: $grey-6;
			.PeriodTree__child-period__trash {
				opacity: 1;
			}
		}

		&__dates {
			display: flex;
			align-items: center;
		}

		&__line {
			z-index: 0;
			position: absolute;
			height: 55px;
			width: 26px;
			left: 26px;
			bottom: 22px;
			border: 1px dotted $grey-6;
			border-top: transparent;
			border-right: transparent;
		}

		&__trash {
			transition: opacity 0.15s ease-in;
			opacity: 0;
			margin-right: 8px;
			.Icon:hover {
				color: $black;
			}
		}

		&__focused-item {
			transition: all 0.2s ease-in;
			&--activeColor {
				font-weight: bold;
				color: $green;
			}
		}

		&--solidBorder {
			border-right: 4px solid $green !important;
		}
		
		&--isDisabled {
			cursor: default;
			border-color: $grey-4 !important;
			background-color: $white !important;
		}
	}
}