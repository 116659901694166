@import "~StyleVariables";

.AgreementHistory {
	margin-left: -15px; // .up-modal-content padding
	margin-right: -15px; // .up-modal-content padding
	min-height: 400px;

	&__description {
		padding-left: 15px;
	}

	&__companyLink {
		padding: 0;
		font-size: inherit;
	}

	&__typeColumn {
		display: flex;
		align-items: center;
	}

	&__historyRow {
		background-color: $white;

		&:hover {
			background-color: $grey-1;
		}
	}

	&__historyRow--hidden {
		background-color: $grey-1;
		font-style: italic;
		color: $grey-10;
	}
}
