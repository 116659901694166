.form-group {
	&.has-icon {
		position: relative;

		&.left {
			.form-control {
				padding-left: 30px;
			}

			.fa {
				top: 33px;
				left: 10px;
				position: absolute;
			}
		}

		&.right {
			.form-control {
				padding-right: 30px;
			}

			.fa {
				top: 33px;
				right: 10px;
				position: absolute;
			}
		}
	}
}

.form-control {
	&.combined-fields {
		width: 50%;

		&:first-of-type {
			@include border-radius($border-radius 0 0 $border-radius);
		}

		&:last-of-type {
			margin-left: -1px;
			@include border-radius(0 $border-radius $border-radius 0);
		}
	}
}
