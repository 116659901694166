@import "~StyleVariables";

.MailAttachment {
	&__card {
		height: 50px;
		width: 220px;
		border: 1px solid $grey-6;
		box-shadow: $box-shadow-md;
		border-radius: $border-radius;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: $space;
		margin: $space * 2;
		transition: all ease-in-out 200ms;

		&--clickable {
			cursor: pointer;
		}

		&--clickable:hover {
			background-color: $grey-2;
			box-shadow: $box-shadow-lg;
		}

		.Icon:first-child {
			margin: 10px;
			font-size: 20px;
		}
		.Icon-times {
			cursor: pointer;
			padding: $space;
		}
	}
}
