@import "~StyleVariables";

.MarketingBoard {
	transition: right ease-out 200ms;
	&__stats {
		display: flex;
		flex-direction: column;
	}

	&--filters-open {
		right: 315px !important;
	}

	&--no-sidebar {
		left: 15px !important;
	}

	.CardHeader {
		position: sticky;
		top: 97px;
		background-color: $white;
		z-index: 1001;
	}

	& > .CardHeader {
		z-index: 1002;
	}

	.DateInput .DateInput__popup {
		line-height: initial;
		right: 0;
	}
}
