.AllowAccess {
	.ModalContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.Text {
		width: 290px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
}
