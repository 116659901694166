@import "~StyleVariables";

.SubscriptionPeriodButtonSelect {
	&.ButtonSelect {
		padding: 2px;
	}
	
	.Button {
		padding: 10px 10px;

		.Icon {
			opacity: 0;
			width: 1px;
			transition: $transition-small-bounce;
		}
	}

	.Button:has(.SubscriptionPeriodButton--missing) {
		background-color: $yellow-1;

		.Text {
			color: $yellow-6;
		}

		.Icon {
			color: $yellow-4;
			width: 22px;
			padding-right: 8px;
			opacity: 1;
		}
	}
}