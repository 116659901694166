.TodoList {
	bottom: 15px;

	.Page__content {
		display: flex;
		flex-direction: column;
	}

	& > .Row {
		height: 100%;
	}

	.TodoListHeader {
		flex-shrink: 0;
	}

	.TodoListTable {
		overflow-y: auto;
		height: 100%;
	}

	.TodoListReport {
		$transitionAll: all ease-out 300ms;
		margin-top: auto;
	}
}
