#opt-in-modal {
	.opt-in-info {
		.opt-in-info-left {
			width: 340px;
			background-color: $white;
			padding: 30px 20px 10px 20px;

			h2 {
				color: $grey-13;
				font-size: 22px;
				font-weight: normal;
				line-height: 30px;
				margin-bottom: 18px;
			}

			h3 {
				color: $grey-13;
				font-size: 16px;
				font-weight: normal;
				line-height: 19px;
				margin-bottom: 10px;
			}

			.opt-in-info-item {
				height: 24px;
				line-height: 24px;
				color: $grey-10;
				font-size: 14px;

				.fa {
					color: $bright-green;
					margin-right: 5px;
				}

				.fa-times-circle {
					color: $red;
				}
			}

			.opt-in-info-btns {
				margin-top: 30px;
			}
		}

		.opt-in-info-right {
			position: absolute;
			top: 0;
			left: 340px;
			right: 0;
			bottom: 0;
			background-color: $dark-purple;
			text-align: center;
			padding: 30px 0;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 60%;
				background-color: $purple;
			}

			img {
				position: relative;
				z-index: 1000;
				width: 450px;
			}
		}

		.opt-in-info-read-more {
			text-align: center;
			margin-top: 20px;
		}
	}

	.up-modal-header {
		height: 86px;
		border-top: 0px solid $blue;

		.fa-times {
			position: absolute;
			top: 20px;
			right: 20px;
			color: $grey-10;
			font-size: 14px;
			font-weight: $regularFontWeight;
			cursor: pointer;
		}
	}

	.up-modal-content {
		top: 86px;
		padding: 25px;
		background-color: $grey-1;

		button {
			margin-top: 8px;
		}

		.terms-textarea {
			height: 75px;
		}

		.cke {
			@include opacity(1);
		}

		.optin-respons-language-picker {
			display: block;
			margin-bottom: 15px;

			button {
				position: relative;
				margin: 0 10px 0 0;
				height: 18px;
				width: 20px;
				background: none;
				border: none;

				&.disabled {
					opacity: 0.3;
					cursor: default;

					b {
						&:hover {
							opacity: 1;
						}
					}
				}

				b {
					position: absolute;
					height: 100%;
					width: 100%;
					border-radius: 2px;
					left: 0;
					bottom: 0;
					transition: all ease-out 200ms;

					&:hover {
						opacity: 0.6;
					}
				}
			}
		}
	}

	.opt-in-terms {
		height: 396px;
	}

	.opt-in-require-terms {
		display: grid;
		img {
			margin: 0 auto;
		}

		h2 {
			margin: 16px auto 13px auto;
			color: $grey-13;
			font-size: 22px;
			font-weight: normal;
			line-height: 30px;
		}

		.opt-in-terms-body {
			margin: 0 auto 25px auto;
			text-align: center;

			h4 {
				width: 460px;
				color: $grey-10;
				font-size: 16px;
				font-weight: $regularFontWeight;
				line-height: 24px;
			}

			span {
				margin-top: 0px;
				font-size: 16px;
				font-weight: $regularFontWeight;
				line-height: 24px;
			}
		}
	}

	.opt-in-terms-container {
		.require-terms-switch-holder {
			margin: 0 auto;
			padding-bottom: 30px;
			label {
				color: $grey-13;
				font-weight: $boldFontWeight;
				line-height: 17px;
				margin-right: 30px;
				display: inline-block;
			}
		}

		.terms-slice-container {
			position: absolute;
			overflow: hidden;
			top: 86px;
			height: 80%;
			width: 100%;
			right: 0;
			z-index: -1;

			.terms-slice {
				background: $white;
				height: 100%;
				width: 130%;
				margin-top: 30px;
				transform: rotateZ(-4deg);
			}
		}
	}

	.content-max-w {
		max-width: 360px;
	}

	.opt-in-form-tabs {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 40px;

		.form-tab {
			height: 36px;
			line-height: 36px;
			display: inline-block;
			color: $grey-10;
			font-size: 14px;
			text-align: center;
			padding: 0 25px;
			border-bottom: 4px solid $white;
			cursor: pointer;

			&.form-tab--active {
				color: $blue;
				border-bottom-color: $blue;
			}
		}
	}

	.opt-in-form {
		label {
			display: inline-block;
			color: $grey-11;
			font-size: 14px;
			font-weight: normal;
			line-height: 17px;
			margin-bottom: 5px;
		}

		.form-control {
			margin-bottom: 15px;
		}

		.opt-in-type-wrap {
			overflow: hidden;
			@include box-shadow(0 2px 5px rgba($black, 0.2));
			@include border-radius(3px);
			margin-bottom: 30px;

			&.opt-in-type-clickable {
				cursor: pointer;
			}

			.opt-in-type {
				display: inline-block;
				width: 50%;
				text-align: center;
				padding: 20px;
				background-color: $white;
				@include transition(all ease 200ms);

				&:hover {
					background-color: $grey-1;
				}

				&.opt-in-type--selected {
					background-color: $bright-blue;
					color: $white;
				}

				h4 {
					font-size: 16px;
					font-weight: $regularFontWeight;
					margin-top: 2px;
					margin-bottom: 0px;
				}

				.opt-in-type-icon {
					display: inline-block;
					font-size: 22px;

					.fa {
						margin-right: 5px;
						height: 22px;
						line-height: 22px;
						vertical-align: top;
					}

					.fa-chevron-right {
						font-size: 14px;
						position: relative;
						top: 1px;
					}
				}
			}
		}

		.optin-info-wrap {
			.optin-text-form {
				input {
					display: inline-block;
					width: 85%;
				}
				button {
					display: inline-block;
					width: 13%;
					display: inline-block;
					margin: 0 0 15px 2%;
				}
			}
			.optin-text-preview {
				padding-left: 6px;
			}
		}
	}

	.up-modal-controls {
		height: 60px;

		.opt-in-stats {
			display: inline-block;
			text-align: left;
			float: left;
			margin-left: 10px;

			.opt-in-stats-heading {
				color: $grey-10;
				font-size: 14px;
				font-weight: $regularFontWeight;
				line-height: 20px;
				margin: 0;
			}

			.opt-in-stats-approved-by {
				margin: 0;
			}
		}

		.opt-in-info-btns {
			display: inline-block;
		}
	}
}

.opt-in-inline-edit-row {
	width: 100%;
	box-shadow: 0px 0px 1px 1px rgba($black, 0.075);
	border-radius: 2px;
	background-color: $grey-2 !important;

	.optin-input-container {
		display: inline-block;
		padding-top: 16px;
		max-width: calc(100% - 180px);
		min-width: 240px;

		h4 {
			color: $grey-10;
			font-size: 10px;
			font-weight: $regularFontWeight;
			margin: 0;
			margin-bottom: -4px;
		}
		input {
			color: $grey-13;
			width: 101%;
			font-size: 14px;
			font-weight: $regularFontWeight;
			line-height: 17px;

			background: transparent;
			outline: none !important;
			border: 0;
			box-shadow: none;
			border-bottom: 2px solid $bright-blue;

			&.error {
				border-bottom: 2px solid $red !important;
			}

			&:focus {
				outline: none !important;
				border: 0;
				box-shadow: none;
				border-bottom: 2px solid $bright-blue;
			}
		}
		p {
			line-height: 0;
			height: 0;
			visibility: hidden;
			margin: 0;
		}
	}

	.optin-control-container {
		float: right;
		padding: 15px;
		margin: auto;
	}
}

.WrappedOptIns {
	#admin-root#admin-root {
		left: 0;
	}
}
