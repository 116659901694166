@import "~StyleVariables";

.DateRangeInput {
	display: flex;
	flex-direction: column;

	&__appointment-indicator {
		position: absolute;
		font-size: 6px;
		top: 28px;
		left: 50%;
		margin-left: -3px;
		color: $green;

		&--selected {
			color: $white;
		}
	}
	.Row {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		gap: 16px;
		height: 56px;

		.Button {
			height: 34px;
			&:hover {
				background-color: $grey-3;
			}
		}

		&:first-child {
			margin-bottom: 8px;
		}
	}

	.Label {
		white-space: nowrap;
		overflow: hidden;
	}

	&__section {
		display: flex;
		flex-direction: row;
	}

	&__time {
		width: 85px;
		margin-right: 8px;
	}

	&__date {
		flex: 1;
		margin-right: 8px;
		transition: width 200ms ease-out;
	}

	&__icon-btn {
		padding-top: 22px;
	}

	&__date-toggle {
		display: flex;
		flex-direction: row;
		width: 0px;

		&.Animate--visible {
			width: 132px;
		}
	}
}
