@import "~StyleVariables";

.SplitDateRow {
	display: flex;
	width: 100%;
	border-bottom: 1px solid $grey-4;
	align-items: flex-end;
	gap: 24px;
	padding: 16px 0;

	&__date-input-text {
		padding-left: 4px;
	}

	.Button .Icon {
		margin-right: 8px;
		margin-left: 12px;
	}
	.DateInput {
		width: 360px;
		margin-top: 2px;

		&__popup {
			width: 360px;
		}
	}
}