#looker {
	position: fixed;
	top: 100px;
	right: 15px;
	left: 65px;
	bottom: 15px;
	overflow: hidden;
	background: $white;
	background: -webkit-linear-gradient($white, $grey-4);
	background: -o-linear-gradient($white, $grey-4);
	background: -moz-linear-gradient($white, $grey-4);
	background: linear-gradient($white, $grey-4);

	.looker-container {
		position: absolute;
		height: 100%;
		width: 100%;
	}

	.white-background {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -10;
		background-color: $white;
	}

	.dropdown-container {
		height: 45px;
		width: 100%;
		border-bottom: 1px solid $grey-6;
		position: relative;
	}

	.dashboard-dropdown {
		float: left;
		top: 5px;
		left: 7px;
	}

	.explore-dropdown {
		float: left;
		top: 5px;
		left: 10px;
	}

	.look-dropdown {
		float: left;
		top: 5px;
		left: 13px;
	}

	.dropdown-menu {
		overflow: auto;
		height: auto;
		max-height: 40vh;
	}

	.dropdown-menu li a {
		cursor: pointer;
		display: block;
		padding: 3px 20px;
		clear: both;
		font-weight: normal;
		line-height: 1.42857;
		color: $grey-13;
		white-space: nowrap;
	}

	.looker-failed-div {
		text-align: center;
		color: $grey-8;
		height: 100%;
	}

	.looker-inactive-header {
		color: $grey-11;
	}

	#looker-wrapper {
		position: absolute;
		top: 45px;
		left: 0;
		bottom: 0;
		width: 100%;
	}

	#looker-frame {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}

	#looker-promotional-wrapper {
		padding-top: 10px;
		width: 100%;
		height: 100%;
	}

	#looker-promotional-iframe {
		width: 80%;
		height: 100%;
		border: none;
		box-shadow: 0 3px 6px rgba($black, 0.3);
		border-radius: 3px;
		padding-bottom: 100px;
	}
}
