@import "variables";
@import "mixins";

.up-color-list-trigger,
.up-color-picker-wrap {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 22px;

	input.up-color-picker {
		width: 100%;
		text-align: center;
		@include border-radius(3px);
		@include box-shadow(inset 0px 0px 0px 2px rgba($black, 0.2));
		border: none;
		outline: none;
		color: $black;
		background-color: transparent;
		background-image: url("../../img/transparent.png");
		background-repeat: repeat;
		height: 100%;

		&:focus {
		}

		&:disabled,
		&.disabled {
			@include opacity(0.6);
		}
	}

	.up-color-picker-taglabel {
		position: absolute;
		top: 1px;
		left: 1px;
		right: 1px;
		bottom: 1px;
		display: none;
		font-size: 12px;
		padding: 2px;
		text-align: center;
	}
}

.colorpicker.dropdown-menu {
	z-index: $modal-z + 1;
	width: 171px;
	padding: 5px;

	.colorpicker-saturation {
		width: 130px;
		height: 130px;
		margin-bottom: 5px;
	}

	.colorpicker-hue {
		width: 25px;
		height: 130px;
	}

	.colorpicker-selectors {
		padding-top: 5px;

		.colors-title {
			display: block;
			font-size: 10px;
			position: initial;
			font-weight: $boldFontWeight;
			clear: both;
		}

		i {
			margin-right: 5px;
			margin-bottom: 5px;
			height: 20px;
			width: 20px;
			border: 1px solid rgba($black, 0.5);
			@include border-radius(3px);

			& + i {
				margin-left: 0;
			}
		}
	}
}
