@import "~StyleVariables";

.InfoBox {
	display: flex;
	align-items: center;
	gap: 12px;

	margin: 12px;
	padding: 16px;

	background-color: $super-light-blue;
	border-radius: $border-radius;
}
