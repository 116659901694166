.BillingAddons {
	&__search {
		float: right;
		width: 160px;
	}

	&__grid {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto;
		column-gap: 15px;
		row-gap: 15px;
	}
}
