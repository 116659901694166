@import '../../styles/variables.scss';

.RelationsSignalWidget {
	position: relative;
	transition: all 0.3s ease-in-out;
	border-radius: $border-radius;
	width: 100%;
	cursor: pointer;

	&__relationText{
		white-space: nowrap;
	}

	&:hover {
		background-color: $grey-2;
		padding: $space * 2 $space * 2;
	}

	&__signals {
		width: 25%;
	}
}
