.JourneyEditor {
}

.WrappedPortedJourneyEditor#admin {
	#admin-root {
		left: 0;
		.admin-root-wrapper {
			left: 0;
		}
	}
}

.JourneyEditor__Breadcrum {
	display: flex;
}

.JourneyEditor__Breadcrum .Link {
	line-height: 22px;
	margin-right: 4px;
}

.JourneyEditor__title {
	margin-bottom: 10px;
}

.JourneyEditor__StatusName {
	display: flex;
	margin: 22px 0 25px;
	align-items: flex-end;
}

.JourneyEditor__StatusNameInputWrapper {
	width: 100%;
}
