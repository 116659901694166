.WidgetBarchart {
	margin: 0 -15px;

	.WidgetBarchart__label {
		position: absolute;
		left: 0;
		margin-left: -20px;
		width: 40px;
		top: 20px;
	}

	.WidgetBarchart__value-label .Text {
		font-size: 10px;
	}
}
