@import "~StyleVariables";

.ListViewColumn {
	& > .Row {
		align-items: center;
	}
	&__IconCircle {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 18px;
		height: 18px;
		border-radius: 50%;

		&--success {
			background: $success-1;
		}
		&--danger {
			background: $danger-1;
		}
	}
}
