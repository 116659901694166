@import "~StyleVariables";

.SubscriptionPlan {
    padding-top: 40px;
    background-color: $white;
    &:last-of-type {
        padding-bottom: 24px;
    }
}

.SubscriptionPlanChildNavigation {
    .Tab--white.Tab--selected .Tab__subtitle {
        color: $black;
    }
}

.SubscriptionPlanMotherNavigation {
    background-color: $white;
    align-items: center;
    .Icon {
        font-size: 16px;
        padding: 8px;
        margin: 4px;
        &:hover {
            cursor: pointer;
            background-color: $grey-3;
            border-radius: $border-radius-lg;
        }
    }

    .Block {
        &--mlxsmall {
            margin-left: 4px;
        }
        &--margin-left-small {
            margin-left: 8px;
        }
        &--margin-left-medium {
            margin-left: 12px;
        }
        &--margin-left-large {
            margin-left: 16px;
        }
        &--margin-left-xlarge {
            margin-left: 32px;
        }

        &--mrxsmall {
            margin-right: 4px;
        }
        &--margin-right-small {
            margin-right: 8px;
        }
        &--margin-right-medium {
            margin-right: 12px;
        }
        &--margin-right-large {
            margin-right: 16px;
        }
        &--margin-right-xlarge {
            margin-right: 32px;
        }

        &--plxsmall {
            padding-left: 4px;
        }
        &--padding-left-small {
            padding-left: 8px;
        }
        &--padding-left-medium {
            padding-left: 12px;
        }
        &--padding-left-large {
            padding-left: 16px;
        }
        &--padding-left-xlarge {
            padding-left: 32px;
        }

        &--prxsmall {
            padding-right: 4px;
        }
        &--padding-right-small {
            padding-right: 8px;
        }
        &--padding-right-medium {
            padding-right: 12px;
        }
        &--padding-right-large {
            padding-right: 16px;
        }
        &--padding-right-xlarge {
            padding-right: 32px;
        }
    }
}
