@import "~StyleVariables";

.LicenseTypeSettings {
	margin-bottom: 5 * $space;
	padding-bottom: 5 * $space;
	box-shadow: 0px 0.5px 1px 1px rgba(0, 0, 0, 0.075);
	border-radius: $border-radius;

	&__header-row {
		display: flex;
		align-items: center;
		padding: 5 * $space 60px 4 * $space 4 * $space;
		column-gap: $space;
	}

	&__option-row {
		padding: 0px 60px 4 * $space 4 * $space;
	}

	&__option-toggle-column {
		display: flex;
		align-items: center;
	}

	&__option-info-column {
		display: flex;
		flex-direction: column;
		row-gap: 2px;
	}
}
