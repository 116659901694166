@import "~StyleVariables";

.TriggerDatePicker {
	display: flex;

	> .Icon {
		margin-left: 15px;
		margin-top: 27px;
	}

	&__content {
		display: flex;

		> .Block:first-child {
			flex: 1;
			> .select2-container {
				width: 100%;
			}
		}

		> .Block:not(:first-child) {
			.Input {
				width: 90px;
			}
		}

		> .Block:last-child {
			margin-left: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}

	&__trigger {
		text-align: left;
		border: 1px solid transparent !important;

		&:hover {
			background-color: $white !important;
			border-color: $grey-6 !important;
		}
	}

	&--withToggle {
		display: block;
		position: relative;
		z-index: 2;
	}

	&--withToggle &__content {
		transition-property: height;
		height: 0;
	}

	&--withToggle#{&}--showInput &__content {
		margin-top: 10px;
		height: 52px;
	}
}
