@import "~@upsales/components/Utils/colors";

.ThankYou {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	cursor: pointer;

	.Text--white {
		margin-top: 12px;
	}

	&__upsalesHandShake {
		margin-top: 80px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		.Logo {
			width: 120px;
			margin-bottom: 8px;
		}

		.Icon {
			font-size: 30px;
		}

		.Text {
			margin-top: 16px;
		}
	}

	img {
		position: absolute;
		left: 0;
		bottom: 0;
	}
}
