@import "~StyleVariables";

.CompetitorSelect {
	width: 100%;
	$transition: all cubic-bezier(0.17, 0.67, 0.83, 0.67) 200ms;
	$selectedIconWidth: 20px;

	&__inputContainer {
		visibility: hidden;
		opacity: 0;
		transition: $transition;
		transform: translateY(-50px);

		&--visible {
			visibility: visible;
			opacity: 1;
			transform: translateY(0px);

			&.CompetitorSelect__inputContainer--noCompetitors {
				transform: translateY(-$up-btn-height);
			}
		}
	}

	&__headerText {
		text-align: left;
	}

	&__nameInputText {
		height: 20px;
	}

	&__nameInputError,
	&__nameInputDescription {
		opacity: 0;
		text-align: left;
		transition: $transition;
		transform: translateY(-100%);

		&--visible {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	&__clearInputButton {
		top: 0;
		right: 0;
		opacity: 0;
		position: absolute;
		transition: $transition;

		&--visible {
			opacity: 1;
		}
	}

	&__buttonContainer {
		display: flex;
		justify-content: flex-start;
	}

	&__competitors {
		display: flex;
	}

	&__competitorOptions {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		max-height: 150px;
		overflow: auto;
	}

	&__competitorWrapper {
		max-width: 100%;
	}

	&__competitor {
		display: flex;
		align-items: center;
		overflow: hidden;
		max-width: 100%;
	}

	&__selectedIcon {
		width: 0;
		opacity: 0;
		display: flex;
		align-items: center;
		height: $up-btn-height;
		justify-content: center;
		transition: $transition;
		transform: translateY(-$up-btn-height);

		&--visible {
			opacity: 1;
			transform: translateY(0);
			width: $selectedIconWidth;
		}
	}

	&__addOptionButton {
		transition: $transition;
		opacity: 0;
		visibility: hidden;

		&--visible {
			opacity: 1;
			visibility: visible;
		}
	}
}
