.ReportcenterFilterClient {
	.DropDownMenu__content {
		max-width: 260px;
	}

	.ButtonSelect {
		display: flex;
	}

	&__list-select {
		position: sticky;
		top: 0;
		z-index: 1;
	}
}
