@import "variables";
@import "mixins";

.generic-avatar {
	float: left;

	background: transparent;
	box-shadow: none;
	position: relative;
	margin-right: 4px;
	.color {
		position: absolute;
		top: 0;
		display: block;
		background: transparent;
		background-color: $super-light-green;
		width: 100%;
		height: 100%;

		.mm {
			font-size: 220%;
			top: 25%;
			min-width: 35px;
			position: absolute;
			text-align: center;
			width: 100%;
		}

		.name {
			// position: absolute;
			color: $green;
			// font-weight: $boldFontWeight;
			// font-size: 100%;
			// top: 24%;
			text-transform: uppercase;
			text-align: center;
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.img {
		background-size: contain;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&.round {
		@include border-radius(50%);
		.color,
		.mm,
		.name,
		.img {
			@include border-radius(50%);
		}
	}
}
