@import "~StyleVariables";

.Onboarding {
	background-color: $white;
	border-radius: $border-radius;
	display: flex;
	flex-direction: row;
	position: relative;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	$heightToSubtract: $main-top + 20px; /* the +20px is for the margin at the bottom */
	min-height: calc(100vh - #{$heightToSubtract});
}

.Onboarding--isFullscreen {
	right: 0;
	bottom: 0;
	top: -97px;
	left: -50px;
	z-index: 9998;
	position: absolute;
	height: calc(100% + 97px);
}

.Onboarding--startView {
	background-color: $green;
	align-items: center;
	justify-content: center;
}

.Onboarding__StartPageLeft {
	margin-right: 30px;
}

.Onboarding__StartPageLeft .Button {
	width: 300px;
	box-shadow: 0 10px 20px rgba($black, 0.2);
	margin-bottom: $padding;
}

.Onboarding__StartPageRight {
	img {
		max-width: 370px;
		max-height: 210px;
	}
	box-shadow: 0 10px 20px rgba($black, 0.2);
	border-radius: $border-radius;
}

// remove this when we have a proper video
.Onboarding__StartPageRight img {
	border-radius: $border-radius;
}

.Onboarding--startView .Title {
	font-size: 46px;
	font-weight: $boldFontWeight;
	line-height: 55px;
	width: 400px;
}

.Onboarding--startView .Text {
	font-size: 16px;
	font-weight: $regularFontWeight;
	line-height: 24px;
	margin: 20px 50px 40px 0;
	width: 370px;
}

.Onboarding__RightNavigation {
	position: absolute;
	top: $padding;
	right: $padding + 10px; /* The +10px is to make it look good witht a scrollbar visible */
}

.Onboarding__RightNavigation #expand-button {
	background-color: $grey-2;
	border-radius: 100%;
}

.Onboarding__Sidebar {
	width: 100%;
	flex: 1;
	overflow: auto;
	border-radius: $border-radius 0 0 $border-radius;
	max-width: 380px;
	padding: $padding * 4 $padding * 2;
	background-color: $green;
}

.Onboarding__Sidebar--Substep {
	background-color: $white;
	background-image: none;
	padding: $padding * 2;
	box-shadow: 0 1px 2px 0 rgba($black, 0.2);
}

.Onboarding__MainArea {
	flex: 1;
	background-color: $grey-1;
	padding-left: $padding;
}

.Onboarding__MainArea--Substep {
	background-color: $grey-1;
}

.Onboarding__HelperText {
	margin-bottom: 30px;
}

.Onboarding__HelperText .Title {
	font-weight: $boldFontWeight;
}

.Onboarding__HelperText .Button {
	margin-left: -16px;
	margin-bottom: 5px;
	margin-top: -10px;
}

.Onboarding__HelperText .Text {
	margin: $padding 0;
}

.Onboarding__HelperText .ImportApp {
	margin: 0;
	margin-right: 20px;
}

.Onboarding__HelperText--import-step .Row {
	align-items: center;
}

.Onboarding__HelperText--import-step .Title {
	font-size: 26px;
}

.Track {
	opacity: 0.6;
	margin: 0 0 10px 0;
}

.Track:not(.Track--Active) {
	cursor: pointer;
}

.Track__Substep {
	margin: 0 0 10px 0;
}

.Track__Substep:not(.Track__Substep--Active) {
	cursor: pointer;
}

.Track .Title {
	color: $white;
	display: flex;
	font-size: 14px;
	font-weight: $regularFontWeight;
	line-height: 17px;
	align-items: center;
}

.Track__Substep .Title {
	display: flex;
	font-size: 14px;
	line-height: 17px;
	align-items: center;
	color: $grey-10;
}

.Track--Active,
.Track--Completed {
	opacity: 1;
}

.Track--Active .Title {
	font-size: 26px;
	font-weight: $boldFontWeight;
	line-height: 31px;
	align-items: flex-start;
}

.Track--Active .Text {
	font-size: 16px;
	font-weight: $regularFontWeight;
	line-height: 24px;
	color: $grey-2;
	margin: 20px 0;
}

.Track--Completed .Title span:not(.Track__Number) {
	opacity: 0.6;
}

.Track--Active .Title span:not(.Track__Number) {
	display: flex;
	flex: 1;
}

.Track__Number,
.Track__Substep__Number {
	border-radius: 100%;
	background-color: $super-light-green;
	color: $green;
	width: 28px;
	height: 28px;
	display: flex;
	text-align: center;
	font-size: 14px;
	flex: 0 0 28px;
	justify-content: center;
	margin-right: 15px;
	font-family: "Roboto", sans-serif;
	line-height: 17px;
	align-items: center;
	font-weight: $boldFontWeight;
}

.Track__Substep__Number {
	color: $grey-10;
	background: $grey-2;
}

.Track__Number.Track__Number--Completed,
.Track--Active .Track__Number.Track__Number--Completed,
.Track__Substep__Number--Completed,
.Track__Substep--Active .Track__Substep__Number--Completed {
	background-color: $bright-green;
}

.Track__Number--Completed .Icon,
.Track__Substep__Number--Completed .Icon {
	color: $white;
}

.Track__Substep--Active .Title {
	color: $black;
}

.Track__Substep--Active .Track__Substep__Number {
	background-color: $super-light-green;
	color: $green;
}

.StepWrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.StepWrap__Holder {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	padding-top: 50px;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.StepWrap__Component {
	width: 480px;
	margin: 0 auto;
}

.StepWrap__Button.Button.btn-block.up-btn {
	width: 480px;
	margin: 60px auto;
	font-size: 16px;
	line-height: 19px;
	font-weight: $regularFontWeight;
	margin-top: 60px;
	box-shadow: 0 10px 20px rgba($black, 0.2);
}

.ImportStep .Title {
	font-weight: $boldFontWeight;
}

.ImportStep__Container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -15px 40px;
}

.ImportStep__ContainerStarted {
	display: flex;
	flex-direction: column;
	margin: 0 -15px;
}

.OnboardingImportCard {
	margin: 0 15px;
	box-shadow: 0 4px 8px rgba(30, 37, 46, 0.2);
	border-radius: $border-radius;
	overflow: hidden;
	display: flex;
	width: 480px;
	margin-bottom: 30px;

	.Icon-file-excel {
		color: #d3dae4;
		font-size: 3.5em;
		display: none;
	}

	.Icon-check-circle {
		margin-right: 5px;
	}
}

.OnboardingImportCard:last-child {
	margin-bottom: 50px;
}

.OnboardingImportCard--powerimport {
	.Icon-file-excel {
		display: inline-block;
	}
}

.OnboardingImportCard__AppLogo {
	background-repeat: no-repeat;
	background-size: 90%;
	background-position: center center;
	background-color: $grey-1;
	width: 104px;
	height: 104px;
	flex: 0 1 auto;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.OnboardingImportCard__ImportInfo {
	flex: 1;
	padding: 15px;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/* (stepType) Import.js */
.ImportApp,
.ImportApp--manual {
	width: 104px;
	height: 104px;
	margin: 15px;
	margin-bottom: 30px;
	flex: 0 1 auto;
	text-align: center;
	cursor: pointer;
	transition: all 0.2s;
}

.ImportApp:hover,
.ImportApp--manual:hover {
	transform: scale(1.1);

	.Card {
		box-shadow: 0 6px 14px rgba($black, 0.2);
	}
}

.ImportApp--disabled {
	opacity: 0.5;
	pointer-events: none;
	cursor: default;
}

.ImportApp--manual .Card {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.ImportApp--manual .Icon {
	color: $grey-5;
	font-size: 3.5em;
}

.ImportApp .Title,
.ImportApp--manual .Title {
	color: $grey-10;
	margin-top: 10px;
	font-weight: $regularFontWeight;
}

.ImportApp .Card,
.ImportApp--manual .Card {
	box-shadow: 0 4px 8px rgba($black, 0.2);
	border-radius: $border-radius;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center center;
	transition: all 0.2s;
}

.ImportApp__ImageLink {
	width: 100%;
}

.ScriptStep .Card {
	margin-top: 60px;
}

.ScriptStep .Loader {
	margin: auto;
}

.ScriptStep .TableHeader__column:nth-child(1) {
	width: 80px;
}

.ScriptStep .TableHeader__column:nth-child(3) {
	width: 90px;
}

.ScriptStep .TableHeader__column:nth-child(4) {
	padding-right: 15px;
	width: 100px;
}

.ScriptStep .Title {
	font-size: 26px;
	font-weight: $boldFontWeight;
	line-height: 31px;
}

.ScriptStep .Text {
	margin: 20px 0;
	font-size: 16px;
	font-weight: $regularFontWeight;
	line-height: 23px;
	color: $grey-10;
}

.ScriptStep .Title.ScriptStep__Subtitle {
	font-weight: $boldFontWeight;
	font-size: 18px;
	line-height: 22px;
}

.ScriptStep__CodeEditor {
	margin: 30px 0;
	border-radius: $border-radius;
	background: #002240;
	position: relative;
	padding: 15px;
	box-shadow: 0 4px 8px 0 rgba($black, 0.2);
}

.ScriptStep .ButtonGroup .Icon {
	margin-right: 8px;
}

.ScriptStep .ButtonGroup {
	margin: 0 -12px;
}

.ScriptStep .hidden-input {
	opacity: 0;
	position: absolute;
	left: -9999;
	top: -9999;
}

.ScriptStep__CodeEditor .Button {
	position: absolute;
	bottom: 5px;
	right: 5px;
	transition: all 0.5s;
}

.ScriptStep__CodeEditor .Button .Icon {
	margin-right: 8px;
}

.CodeEditor {
	border-radius: $border-radius;
}

.OnboardingNotification.NavbarButton {
	display: flex;
	width: 200px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: left;
}

.OnboardingNotification {
	&__title {
		position: relative;
		width: 100%;
	}

	&__percent {
		position: absolute;
		right: 0;
	}

	.Progressbar {
		height: 8px;
		width: 100%;
	}
}

.Track__Article {
	height: 80px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 30px;
}

.Track__Article img {
	flex: 0 1 130px;
	margin-right: 20px;
	height: 80px;
	cursor: pointer;
	border-radius: $border-radius;
	transition: all 0.4s;
	box-shadow: 0 10px 20px 0 rgba($black, 0.2);
}

.Track__Article img:hover {
	box-shadow: 0 20px 40px 0 rgba(30, 37, 46, 0.4);
}

.Track__Article__RightSide a {
	color: rgba($white, 0.9);
	text-decoration: none;
	transition: all 0.3s;
	cursor: pointer;
}

.Track__Article__RightSide a:hover {
	color: rgba($white, 0.7);
}

.Track__Article__RightSide .Icon {
	margin-left: 10px;
}

.Track__Article__RightSide .Title {
	margin: 0 0 10px 0;
	font-size: 14px;
	line-height: 1.3em;
	font-weight: $boldFontWeight;
	cursor: pointer;
	transition: all 0.3s;
}

.Track__Article__RightSide .Title:hover {
	color: rgba($white, 0.7);
}

.Track__Article__RightSide {
	display: flex;
	flex-direction: column;
	height: 80px;
	justify-content: center;
	max-width: 140px;
}

.WistiaVideo__Reject {
	border-radius: 100%;
	color: $grey-10;
	background: $grey-2;
	width: 40px;
	height: 40px;
	position: absolute;
	top: -50px;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.CompanyProfile__SocialTitle {
	margin: 30px 0 0;
}

.CompanyProfile .Title,
.CompanyProfileLogo .Title,
.DomainTerms .Title,
.DomainContent .Title {
	font-weight: $boldFontWeight;
	font-size: 26px;
	line-height: 36px;
}

.CompanyProfile .Label {
	font-size: 14px;
	font-weight: $boldFontWeight;
	line-height: 17px;
}

.CompanyProfile .Row {
	margin: 15px 0 0;
}

.CompanyProfile .Row .Column + .Column {
	margin-left: 30px;
}

.CompanyProfile .Row .Column .select2-container {
	width: 100%;
}

.CompanyProfileLogo__LogoTypes {
	display: flex;
	margin: 15px -15px;
}

.CompanyProfileLogo__LogoTypes .Logotype__dark,
.CompanyProfileLogo__LogoTypes .Logotype__light {
	width: 100%;
	height: 120px;
	display: flex;
	margin: 0 15px;
	flex: 0 1 auto;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
}

.Logotype__Image {
	border-radius: $border-radius;
	box-shadow: 1px 1px 2px rgba($black, 0.1);
	margin: 0 10px;
	width: 100%;
	height: 90px;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 0;
}

.Logotype__BackGroundImage {
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
}

.Logotype__Image--dark {
	background: $black;
}

.Logotype__Image--light {
	background: $white;
}

.Logotype__Overlay {
	display: flex;
	flex-direction: column;
	flex: 0 1 auto;
	background: rgba($black, 0.2);
	width: 100%;
	height: 90px;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	cursor: pointer;
}

.Logotype__IconHolder {
	width: 24px;
	height: 24px;
	margin-bottom: 5px;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.Logotype__dark .Logotype__IconHolder {
	background-color: $white;
}

.Logotype__dark .Logotype__Overlay {
	background-color: $black;
}

.Logotype__light .Logotype__Overlay {
	background-color: $white;
}

.Logotype__dark .Logotype__Overlay .Text {
	color: $white;
}

.Logotype__light .Logotype__Overlay .Text {
	color: $black;
}

.Logotype__light .Logotype__IconHolder {
	background-color: $black;
}

.Logotype__light .Logotype__IconHolder .Icon {
	color: $white;
}

.CardContent__Holder {
	display: flex;
	justify-content: space-between;

	.select2-container:first-child {
		width: 175px;
	}
}

.CardContent__Extra__Color {
	position: relative;
}

.CompanyProfile .CardContent__Extra {
	display: flex;
	flex-direction: row;
}

.CardContent__Extra__Card {
	flex: 1;
}

.CompanyProfile__Card {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
}

.CompanyProfile__Card .Button {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;
}

.CompanyProfile__Card .Button .Icon {
	margin-right: 10px;
}

.CompanyProfile__Card .ButtonGroup .Button {
	height: 34px;
	padding: 0 10px;
}

.CompanyProfile__Card .ButtonGroup .Button .Icon {
	margin-right: 0;
	font-size: 14px;
}

.CompanyProfile__Card .CardContent {
	border-bottom: 1px solid rgba($grey-11, 0.1);
	padding: 15px;
}

.CompanyProfile__Card .CardContent:last-child {
	border-bottom: none;
}

.ColorSelect__Input {
	width: 90px;
}

.Domain__Loading {
	text-align: center;
}

.Domain__Loading .Loader {
	margin: 0 auto;
}

.DomainTerms .DomainTerms__Subtitle {
	font-size: 18px;
	margin: 30px 0 15px;
}

.DomainTerms .Text {
	font-size: 16px;
	line-height: 23px;
	margin: 20px 0;
	color: $grey-10;
}

.DomainTerms ol {
	padding: 0;
	counter-reset: item;
}

.DomainTerms__List li {
	display: block;
	color: $grey-10;
	margin-top: 15px;
	font-weight: normal;
}

.DomainTerms__List > li > span {
	font-weight: $boldFontWeight;
	color: $grey-13;
}

.DomainTerms__List > li:before {
	font-weight: $boldFontWeight;
	color: $grey-13;
}

.DomainTerms__List li:before {
	content: counters(item, ".") " ";
	counter-increment: item;
	min-width: 30px;
	display: inline-block;
	margin-bottom: 15px;
	float: left;
}

.DomainContent > .Text,
.DomainContent > .Row {
	margin: 15px 0;
}

.DomainContent > .Text {
	color: $grey-10;
}

.DomainContent > .Row .Column {
	margin-right: 15px;
}

.DomainContent .Label {
	font-weight: $boldFontWeight;
}

.DomainContent__DomainInput {
	width: 70%;
	display: inline-block;
	margin-right: 15px;
}

.DomainContent__AddButton {
	width: calc(30% - 15px);
}

.DomainContent__Domains {
	margin-top: 60px;
}

.DomainContent__Domains--have-domain .Column {
	display: flex;
	justify-content: center;

	button:first-child {
		margin-right: 20px;
	}
}

.DomainContent .CardHeader .CardHeader__title {
	font-size: 18px;
	font-weight: $regularFontWeight;
}
