@import "~StyleVariables";

.ChecklistItemSelect {
	position: relative;
}

.ChecklistItemSelect .Input {
	width: 100%;
}

.ChecklistItemSelect__dropdown {
	position: absolute;
	margin-top: 1px;
	max-height: 350px;
	overflow: auto;
	z-index: 1000;
	width: 100%;
	border-radius: $border-radius;
	background-color: $white;
	border: 1px solid $grey-6;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.ChecklistItemSelect__dropdown-item {
	height: 50px;
	padding: 6px 15px;
	cursor: pointer;

	&:not(:last-child) {
		border-bottom: 1px solid $grey-6;
	}
}

.ChecklistItemSelect__dropdown-item:hover {
	background-color: $super-light-green;

	&:not(:last-child) {
		border-color: $super-light-green;
	}

	.Text {
		color: $black;
	}
}

.ChecklistItemSelect__dropdown-item--highlighted {
	background-color: $green;

	&:not(:last-child) {
		border-color: $green;
	}
}
