.PageDomainInput__row {
	margin: 0 -5px 0px -5px;
}

.PageDomainInput__col {
	position: relative;
	padding: 0 5px;
}

.PageDomainInput__form-link {
	display: block;
	height: 30px;
	line-height: 30px;
}

.PageDomainInput__invalid {
	position: absolute;
	top: 8px;
	right: 10px;
}

.PageDomainInput__loader {
	position: absolute;
	top: 5px;
	right: 8px;
}

.PageDomainInput__input .Input__input {
	padding-right: 30px;
}