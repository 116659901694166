.StepDrawer {
	min-width: 500px;
	overflow-y: auto;

	&__mail-content {
		padding-left: 8px;
		padding-bottom: 0px;

		.StepEmailSubject {
			padding: 8px 0px;
		}

		.StepDrawer__drawer-info-row {
			display: flex;
			flex-direction: column;
		}
		.Column:nth-child(2) {
			padding-left: 8px;
		}

		.Row:nth-child(2) {
			padding: 8px 0px;

			.Title:nth-child(1) {
				padding-left: 0px;
				width: 100%;
			}
		}

		.TemplateCard {
			width: 178px;
			height: 234px;
			background-size: cover;
			margin: 0px 0px 15px 0px;
		}

		.DrawerInfoProgress {
			width: 90px;
		}

		.Column .Link {
			display: block;
			text-align: center;
			position: absolute;
			top: 26%;
		}
	}

	.StepDrawer__content .Row {
		margin: 15px 0;

		.Column:nth-child(2) {
			padding-left: 15px;
		}

		.Column .Link {
			display: block;
			text-align: center;
		}
	}
}
