@import "~StyleVariables";

.SidebarHeader {
	box-shadow: 0 1px 2px 0 rgba($black, 0.2);
	height: 49px;
	align-items: center;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 1003;
	background-color: white;

	&__SubTitle {
		font-size: 12px;
	}

	.Row {
		height: 100%;
		align-items: center;
		padding-right: 20px;
	}

	&__BackCol {
		flex: 0 0 80px;
	}

	&__BackBtn.up-btn {
		color: $grey-10;
		height: 49px;
		width: 100%;
		box-shadow: none;
		border-right: 1px solid $grey-5;
		border-radius: 0;
		border-left: none;
		border-bottom: none;
		border-top: 0;

		.Icon {
			margin-right: 5px;
		}
	}

	.Headline--xs {
		margin-left: 10px;
	}

	.Text {
		font-weight: $boldFontWeight;
		margin: 0;
	}

	input {
		width: 100%;
	}
}
