.AccountSelect {
	.select2-highlighted & {
		.Title {
			color: white;
		}
	}

	.Title {
		margin-bottom: 3px;
	}

	.Column {
		font-size: 1.2rem;

		.icon {
			margin-right: 4px;
		}

		.Avatar {
			vertical-align: middle;
			margin-right: 4px;
		}
	}
}
