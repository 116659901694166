@import "~StyleVariables";

@font-face {
	font-family: "upicons";
	src: url("./upicons.eot");
	src: url("./upicons.eot") format("embedded-opentype"),
		url("./upicons.woff") format("woff"),
		url("./upicons.ttf") format("truetype"),
		url("./upicons.svg#upicons") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="up-icon-"],
[class*=" up-icon-"] {
	display: inline-block;
}

[class^="up-icon-"],
[class*=" up-icon-"] {
	font-family: "upicons" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: text-top;

	*:not([class^="up-icon-"]):not([class*=" up-icon-"]):not(.fa) {
		font-family: $main-font-family !important;
		font-weight: $regularFontWeight;
	}
}

.up-icon-assign-user:before {
	content: "\72";
}
.up-icon-lead:before {
	content: "\62";
}
.up-icon-lead-question:before {
	content: "\63";
}
.up-icon-shield:before {
	content: "\64";
}
.up-icon-shield-o:before {
	content: "\65";
}
.up-icon-company-contacts:before {
	content: "\66";
}
.up-icon-activity:before {
	content: "\61";
}
.up-icon-missing-preview:before {
	content: "\68";
}
.up-icon-recurring-order:before {
	content: "\69";
}
.up-icon-pipeline:before {
	content: "\70";
}
.up-icon-search-account:before {
	content: "\6b";
}
.up-icon-unassigned-user:before {
	content: "\6c";
}
.up-icon-form:before {
	content: "\6d";
}
.up-icon-form-submit:before {
	content: "\6e";
}
.up-icon-edit-mail:before {
	content: "\6f";
}
.up-icon-email-template:before {
	content: "\78";
}
.up-icon-group-mail:before {
	content: "\71";
}
.up-icon-snooze:before {
	content: "\7a";
}
