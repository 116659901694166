@import "../../../styles/variables";

$avatarHeight: 25px;
.BillingInvoicePlanBillingAdmins {
	.Tooltip, &__editIcon {
		float: right;
		display: none;
	}

    .Avatar, .Avatar--gravatar {
        height: $avatarHeight;
        width: $avatarHeight;
    }
    .Avatar--initials {
        line-height: $avatarHeight;
    }

	&__billingAdmin {
		margin-top: 8px;
		gap: 12px;
		align-items: center;

		.Text {
			width: 200px;
		}
	}
	.UserStack {
		margin-top: 8px;
	}
}
