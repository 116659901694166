@import "~StyleVariables";

.MailEditorTemplates__heading {
	background-color: $white;
	padding: 20px 25px 20px 0;

	.Headline {
		margin-bottom: 15px;
	}

	.Headline,
	.Text {
		margin-left: 25px;
	}

	.Tabs {
		vertical-align: bottom;
	}
}

.MailEditorTemplates__content {
	margin: 25px;
}

.MailEditorTemplates__templates {
	margin: 0 -20px;

	.Column {
		padding: 0 20px;
	}

	.Loader {
		margin: 0 auto;
	}
}

.MailEditorTemplates__filters {
	position: sticky;
	top: 128px;

	.Label {
		font-weight: $boldFontWeight;
		margin-top: 15px;
	}

	.select2-container {
		width: 100%;
	}
}

.MailEditorTemplates__from-scratch {
	margin: 25px auto;
	max-width: 600px;
}

.MailEditorTemplates__from-scratch-inner {
	width: 490px;
	margin: 0 auto;
	padding: 40px 0;
	text-align: center;

	.Headline {
		margin-bottom: 30px;
	}

	.Text {
		margin-bottom: 40px;
	}
}

.MailEditorTemplates__tabs-wrap {
	position: sticky;
	top: 53px;
	height: 50px;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba($black, 0.2);
	z-index: 1001;

	.Button {
		position: relative;
		float: right;
		top: 10px;
		right: 10px;
	}
}

.MailEditorTemplates__paginator {
	margin-top: 15px;
}

.MailEditorTemplates__no-result {
	.Title {
		margin-bottom: 15px;
	}
}
