@import "variables";

.error-row {
	td {
		color: $grey-10;
		text-align: center;
		font-size: 18px;
		line-height: 40px;

		.icon {
			margin-right: 5px;
			color: $orange;
		}
	}

	&:hover,
	&.hover {
		td {
			background-color: $white;
			cursor: default;
		}
	}
}
