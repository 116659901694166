@import "../../../../styles/variables";

.GoogleFontsModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
	z-index: 10000;
	background: rgba(30, 37, 46, 0.3);

	.Select {
		margin-bottom: 80px;
	}

	.Modal {
		margin: 1.75rem auto;
		position: relative;
		width: 700px;

		&Content {
			position: relative;
			height: 450px;
			display: flex;
			flex-direction: row;
			padding: 0;
			justify-content: space-between;
		}

		&Header__title {
			color: $blue;
		}
	}

	&__FontSelect {
		padding: 15px;
		flex: 1 1 auto;
	}

	&__FontPreview {
		padding: 15px;
		font-size: 24px;
		background-color: white;
		width: 340px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
		color: $grey-13;
		line-height: 24px;
		overflow-y: auto;
	}

	&__NoFont {
		color: $grey-10;
		display: flex;
		align-items: center;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		text-align: center;

		.Text {
			width: 150px;
			margin-top: 15px;
		}
	}

	&__PreviewText {
		margin-bottom: 13px;
	}
}
