@import "~StyleVariables";

.SubscriptionPlanSubscriptionProducts {
    background-color: $white;
    .TableColumn {
        .TableColumn__subtitle {
            font-size: 12px;
        }
    }

    &__totalRow {
        .TableColumn {
            font-weight: $boldFontWeight;
        }
    }

    &__nameColumn {
        width: 300px;
    }

    &__priceColumn {
        text-align: right;
    }
    &__totalColumn {
        text-align: right;
    }

    &__productRow {
        height: 48px;
        &:hover {
            .TableColumn__subtitle, .Table__productRow__specialColumn__subtitle {
                color: $blue;
            }
        }

        .TableColumn__subtitle {
            margin-top: 4px;
        }
    
        &__nameCell {
            max-width: 300px;
            width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        &__quantityCell {
            max-width: 0px;

            .Progressbar {
                margin-top: 2px;
            }
        }

        &__priceCell, &__totalCell {
            text-align: right;
            min-width: 125px;
            width: 125px;
        }

        &__editCell {
            padding-right: 16px;
            text-align: right;
            width: 0px;
        }

        .DropDownMenu {
            .Card {
                padding: 12px;
                border-radius: 2px 0 2px 2px;
            }
        }

        &__specialColumn {
            width: 300px;
            .Tooltip {
                display: flex !important;
                align-items: center;

                .Icon {
                    margin-right: 12px;
                }

                .TooltipOverflowText {
                    .Text {
                        width: 250px;
                    }
                }
            }
        }

        &--trialRow {
            &:hover {
                .Table__productRow__specialColumn__subtitle {
                    color: $grey-11;
                }
            }

            .Table__productRow__specialColumn__subtitle .Icon {
                width: 16px;
                margin: 0;
                padding: 0;
                text-align: center;
            }
        }

    }

    .TooltipOverflowText {
        p {
            margin-bottom: 0;
        }
    }
}