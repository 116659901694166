@import "../../../styles/variables";

// Use on TableHeader for column sizing
.TableHeader--multi-select tr th:first-of-type {
	padding: 0;
}

.TableHeaderDropdown {
	&__trigger-button {
		height: 100%;
		background-color: transparent;
		transition: all ease-out 200ms;
		border: none;
		outline: none;
		padding: 0 8px;

		&--open,
		&:hover {
			background-color: $grey-1;
		}

		.Icon:first-of-type {
			font-size: 16px;
			vertical-align: text-bottom;
		}

		.Icon-angle-down {
			transition: all ease-out 200ms;
			font-size: 12px;
		}

		&--open .Icon-angle-down {
			transform: rotate(180deg);
		}

		.Icon-angle-down {
			margin-left: 4px;
		}
	}

	&__item {
		transition: all ease-out 200ms;
		cursor: pointer;
		text-transform: initial;
		white-space: nowrap;

		.Text {
			color: $grey-12;
			transition: color ease-out 200ms;
		}

		&:hover {
			background-color: $grey-1;

			.Text {
				color: $medium-blue;
			}
		}

		.Column {
			$height: 35px;
			height: $height;
			line-height: $height;
			padding: 0 12px;

			.Text {
				height: $height;
				line-height: $height;
			}
		}
	}

	.DropDownMenu__content {
		width: auto;
		min-width: initial;
	}
}
