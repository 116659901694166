@import '~StyleVariables';

.ProBanner {
    background-color: $super-light-blue;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 32px;

    .Help {
        display: flex; 
        align-items: center;
    }

    .ctxUnderline {
        &:hover {
            color: unset;
        }
    }
}