@import "~StyleVariables";

.ForecastingInfo {
	position: relative;
	background-repeat: no-repeat;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	min-width: 202px;

	.tippy-popper {
		&[x-placement="bottom"] {
			transform: translate3d(56px, 42px, 0px) !important;
		}
		&[x-placement="left"] {
			transform: translate3d(56px, 12px, 0px) !important;
		}
	}
	
	&__wrapper {
		height: 103px;
		cursor: pointer;
		padding: 8px 40px 8px 16px;

		.Link {
			font-size: 12px;

			&--success-5:hover {
				color: $success-5;
			}

			&--dark-red:hover {
				color: $dark-red;
			}
		}
	}

	&__text-wrapper {
		display: flex;
		flex-wrap: wrap;
		column-gap: 4px;
		padding-bottom: 8px;
		height: 68px;
	}

	// This will override border radius until REMOVE_FRAME is released
	&--remove-frame {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;		
	}

	&__hide-sidebar {
		position: absolute;
		top: 8px;
		right: 8px;
		display: flex;
		align-items: center;
		text-align: center;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
	}

	&:hover &__hide-sidebar {
		visibility: visible;
		opacity: 1;
	}
}
