@import "~StyleVariables";
.ClientCardSplitButton {
	// /**
	// * @todo  @upsales/components 2.19.19
	// * STYLING: Be able to change the dropdown size in the split button
	// * @link https://github.com/upsales/ui-components/issues/575
	// */
	.DropdownRow {
		height: 36px;
	}

	.DropdownRow:not(:last-child) {
		border-bottom: none !important;
	}

	.DropDownMenu {
		&__content {
			min-width: 190px;
		}
	}
}
