@import "~StyleVariables";
.TodoListTableRowQuickActions {
	.DropDownMenu > .Button:hover {
		background-color: $grey-2;
		color: $grey-10;
	}
	.DeleteConfirm {
		position: absolute;
		padding: 12px 12px;
		box-shadow: 0 1px 2px 0 rgba(30, 37, 46, 20%);
		background-color: $white;
		right: 0;
		height: 135px;
		z-index: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		.Text,
		.Title {
			white-space: nowrap;
		}
		.Title {
			line-height: 19px;
		}
		.Button {
			width: 100%;
		}
	}
	&__dropdown-actions {
		> .Card {
			display: flex;
			align-items: center;
			padding: 5px 10px;
			> .Icon {
				margin-right: 8px;
			}
			&:hover {
				transition: all 0.1s linear;
				background-color: $grey-1;
			}
		}
	}
	.DropDownMenu__content {
		width: max-content;
		animation: fadeIn 0.3s;
		min-width: initial;
		right: -14px !important;
	}
}
