.CriteriaPlaceholder {
	padding: 20px 0 20px 160px;
}

.CriteriaPlaceholder .Title,
.CriteriaPlaceholder .Icon {
	display: inline-block;
}

.CriteriaPlaceholder .Icon {
	font-size: 20px;
}
