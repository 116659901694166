@import "~StyleVariables";

.SignalsFeedViewSelector {
	&__title {
		padding: 15px 15px 10px;
		color: $black;
		font-weight: $boldFontWeight;

		.Icon {
			margin-right: 10px;
		}

		&--secondary {
			border-top: 1px solid $grey-4;
		}
	}
	&__table {
		line-height: 1;

		.TableColumn {
			height: 30px;
			border: none;
		}
	}

	&__table-row {
		&--current {
			background-color: #f6f9fb;
			box-shadow: inset 4px 0 0 0 #1d3d48;
		}
	}
}
