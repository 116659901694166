@import "../../../styles/variables";

.TranslateFieldModal {
	.ModalContent {
		overflow-y: auto;

		&.padding-bottom {
			padding-bottom: 100px;
		}
	}
}
