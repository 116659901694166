@import "~StyleVariables";

.LinkButtonGroup {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.LinkButton:first-child {
		margin-left: 0;
	}

	.LinkButton {
		width: 24px;
		height: 24px;

		border-radius: $border-radius;

		display: flex;
		justify-content: center;
		align-items: center;

		margin: 2px;

		transition: all ease-out 200ms;

		&:hover,
		&:active,
		&:visited,
		&:focus {
			text-decoration: none;
		}

		&--active {
			color: $green !important;
		}

		&--inactive {
			color: $grey-6 !important;
		}

		&--active:hover {
			background-color: $green;
			color: $white !important;
			box-shadow: 0 4px 8px 20%;
		}

		&--inactive:hover {
			background-color: $grey-2;
			color: $grey-8 !important;
		}
	}
}
