@import "~StyleVariables";

.OutcomeTypeButton {
	background-color: $white;
	border: 1px solid $grey-6;
	border-radius: $border-radius;
	height: 90px;
	position: relative;
	overflow: hidden;
	padding: 0;
	transition: box-shadow ease-in-out 0.2s;
	cursor: pointer;

	& > .Tooltip {
		width: 100%;
		height: 100%;
		display: inline-block !important;
	}

	&__main-view,
	&__hover-view,
	&__selected-view {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
		overflow: hidden;
		transition: top ease-out 200ms;
		position: absolute;
		z-index: 9;

		.Icon {
			font-size: 20px;
			font-weight: $regularFontWeight;
			line-height: 22px;
		}

		.Text {
			width: 100%;

			.text-ellipses {
				display: inline-block;
				max-width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				vertical-align: bottom;
			}
		}
	}

	&__main-view {
		top: 0;
	}

	&__selected-view,
	&__hover-view {
		top: 100%;
	}

	&__hover-view {
		background: $grey-1;

		.Text {
			line-height: 15px;
		}
	}

	&__selected-view {
		top: 0;
		opacity: 0;
		background: $green;
		z-index: 0;
		transition: opacity ease-out 200ms;

		.Text--bold {
			font-weight: $boldFontWeight;
		}

		& > div {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&:hover {
			background-color: darken($green, 5%);
			border-color: darken($green, 5%);
		}

		.OutcomeTypeButton__main-view {
			top: -100%;
		}

		.OutcomeTypeButton__hover-view {
			top: 0;
		}
	}

	&:active,
	&:focus {
		outline: none;
	}

	&--selected {
		box-shadow: 0 2px 4px 0 rgba($black, 0.2);

		.OutcomeTypeButton__selected-view {
			z-index: 11;
			opacity: 1;
		}
	}

	&--disabled {
		background-color: $grey-1;
		opacity: 0.7;
	}
}

.OutcomeTypeButton--selected.OutcomeTypeButton--pendingOutcome {
	animation: pulsate 2s ease-out infinite;
}

@keyframes pulsate {
	0% {
		box-shadow: 0 0 0 rgba($bright-green, 0);
	}
	50% {
		box-shadow: 0 0 0 10px rgba($bright-green, 0.5);
	}
	100% {
		box-shadow: 0 0 0 rgba($bright-green, 0);
	}
}
