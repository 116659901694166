@import "~StyleVariables";

.ForecastingPipeline {
    display: block;
    padding-top: 40px;
    &--hidden {
        display: none;
    }

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
    }

    &__label {
        margin-bottom: 8px;
    }

    &__groupings {
        margin-left: 8px;
    }

    &__chart {
        height: 80px;

        &--oneBar {
            height: 54px;
        }
    }

    &__legend {
        display: flex;
        flex-direction: row;
        column-gap: 16px;

        .Legend__dot {
            margin-left: unset;
        }
    }
}