@import "../../../../../../styles/_variables.scss";

.EasyBookingSettingsEmbeddedCompanyEditor {
	&__editColor,
	&__editTypography {
		.Label {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}

		.Label:not(:first-child) {
			padding-top: 17px;
		}

		.Block:not(:first-child) > .Label {
			padding-top: 18px;
		}
	}

	&__editColorRow {
		display: flex;
		padding: 16px;
		padding-left: 0px;

		.Text {
			flex-grow: 2;
		}

		.Input {
			width: 80px;
			height: 22px;
		}

		.ColorSelect__PickerHolder {
			left: -200px;
		}
	}

	&__editTypographyRow {
		display: flex;
		place-content: space-between;

		.select2-container {
			flex-grow: 0.8;
			max-width: 200px;
			min-width: 200px;
		}

		.ButtonSelect {
			width: fit-content;

			.Button:last-of-type {
				.Icon {
					color: $red;
				}
			}
		}
	}
}
