@import "../../../../../styles/_variables";

.OnboardingImport,
.IntegrationConfig {
	width: 100%;

	/* 
        This styling is to make ReactTemplates.admin.standardIntegrations.fields 
        look like Henriks mock
	*/

	.app-field-hero {
		box-shadow: none;
		margin: 0;
		margin-bottom: 40px;
		padding: 0;

		h2 {
			color: $black;
			font-size: 28px;
			font-weight: $boldFontWeight;
			line-height: 36px;
			text-align: left;
			display: block;
		}

		span {
			color: $grey-11;
			font-size: 18px;
			font-weight: $regularFontWeight;
			line-height: 24px;
			width: 480px;
			text-align: left;
		}
	}

	.app-field-section {
		padding: 0;
		margin: 0;
		margin-bottom: 40px;
		box-shadow: none;
		background-color: transparent;
	}

	.app-field-section-info {
		color: $grey-11;
		font-size: 16px;
		font-weight: $regularFontWeight;
		line-height: 22px;
		text-align: left;
	}

	.app-field-section-title {
		font-weight: $boldFontWeight;
		text-align: left;
		color: $black;
	}

	.app-field-label {
		color: $grey-13;
		font-size: 14px;
		font-weight: $boldFontWeight;
		line-height: 17px;
		text-align: left;
	}

	.app-field-column {
		width: 290px;
	}

	.app-field-info {
		color: $grey-10;
		font-size: 14px;
		font-weight: $regularFontWeight;
		line-height: 20px;
		text-align: left;
		width: calc(100% - 290px);
	}

	.app-field-section-title ~ .app-field-row {
		margin-top: 20px;
	}

	.app-field-section-info ~ .app-field-row {
		margin-top: 20px;
	}

	.app-field-row:not(.app-field-row--has-info) {
		.app-field-column {
			width: 100%;
		}
	}

	.field-checkbox,
	.field-checkboxLarge {
		label {
			font-size: 14px;
			font-weight: $regularFontWeight;
		}
	}

	.field-checkboxLarge {
		.up-ios-toggle {
			font-size: 27px;
			height: 27px;
		}

		.toggle-handle {
			height: 27px;
			width: 27px;
			border-radius: 27px;
		}
	}

	.form-control:not(.select2-container) {
		border-radius: 2px;
		box-shadow: inset 1px 1px 2px 0 rgba(30, 37, 46, 0.1);
		border: 1px solid $grey-6;
	}
}

.OnboardingImport__Title {
	font-size: 26px;
	line-height: 26px;
	margin-bottom: 40px;
	font-weight: $boldFontWeight;
}
