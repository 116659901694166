@import "~StyleVariables";

.ReactSelectDropdownIcon {
    line-height: 32px;
    width: 25px;
    padding-left: 7px;
    font-size: 10px;
    border-style: solid;
    border-width: 0;
    border-left-width: 1px;
    border-color: $grey-6;
}

.ReactSelectClearIcon {
	color: $grey-11;
	cursor: pointer;
	transition: all ease-out 300ms;
	font-size: 10px;

	&:hover {
		color: $grey-12;
	}
}
