@import "~StyleVariables";

.SubscriptionPeriodSelector {
	&__multiple-periods-toggle {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		margin: 16px;
	}

	.ButtonSelect {
		.Text {
			display: block;
			text-align: left;

			&:first-of-type{
				margin-bottom: 4px;
			}
		}

		.Button{
			display: flex;
			align-items: center;
		}
	}

	.RadioList {
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}

	&__selection-container {
		margin-bottom: 24px;
		height: 36px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		.DropDownMenu {
			.Button {
				transition: 200ms ease-in;
				height: 34px;
			}
		}
	}

	&__expand-container {
		position: absolute;
		margin-top: -20px;
		width: 30px;
		height: 30px;
		z-index: -1;
		background-color: $grey-4;
		border-radius: $border-radius;
	}

	.inline-confirm-modal {
		position: relative;

		.inline-confirm-tab {
			position: relative;
			padding-top: 4px;
			width: 36px;
		}
	}
}
