.TimelineHistoryUsers {
	&__combined-title {
		.Link {
			display: inline-flex;
			max-width: 100%;
			vertical-align: unset;
		}

		.Text {
			display: inline;
		}
	}
}
