.CriteriaSelectSection,
#segment-filter-v2 {
	position: relative;
	border-bottom: 1px solid $grey-6;
	transition: background-color 200ms linear;
	min-height: 90px;

	.placeholder-wrapper {
		background-color: $white;
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 0;
		height: 0;
		opacity: 0;
		transition: opacity 200ms linear;
		overflow: hidden;

		.left-side,
		.right-side {
			display: inline-flex;
			width: 50%;
			flex-direction: column;
		}

		.left-side {
			align-items: center;

			.title {
				margin-top: 50px;
				font-size: 22px;
			}

			.sub-title {
				margin-top: 20px;
				font-size: 16px;
				color: rgba($black, 0.6);
			}

			button {
				margin-top: 30px;
			}
		}

		.right-side {
			justify-content: center;
			align-items: center;
		}
	}

	.collapsed-wrapper {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 90px;
		opacity: 0;
		visibility: hidden;
		transition: visibility 0s, opacity 200ms ease-out 400ms;
		z-index: 0;
		cursor: pointer;

		.left-side {
			display: flex;
			align-items: center;
			padding-left: 20px;
			flex: 1;
			height: 100%;

			.text {
				font-size: 18px;
				position: relative;
				top: 0;
				transition: all 200ms ease;
			}

			.expand-button-wrap {
				visibility: hidden;
				position: absolute;
				top: 50px;
				opacity: 0;
				transition: all 200ms ease;

				button {
					margin-right: 10px;
				}
			}
		}

		&:hover {
			.expand-button-wrap {
				opacity: 1;
				visibility: visible;
			}

			.text {
				top: -15px;
			}
		}

		.right-side {
			width: 340px;
			height: 100%;
			background-image: url("../img/admin-topsection-bg.svg");
			background-repeat: no-repeat;
			background-position: center left;
			background-size: cover;

			img {
				height: 80%;
				margin-top: 8px;
				margin-left: 160px;
			}
		}
	}

	.expanded-wrapper {
		display: flex;
		flex-direction: column;
		padding: 16px;
		z-index: 0;
		max-height: 0;
		opacity: 0;
		transition: max-height 400ms linear, opacity 400ms ease-out;
		overflow: hidden;
		background-color: $grey-4;
		position: relative;

		.header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			min-height: 34px;

			.title {
				font-size: 22px;

				.fa {
					margin-left: 6px;
				}
			}

			.toggle-button-wrap {
				cursor: pointer;

				&:hover {
					.btn-round {
						background-color: $grey-6;
					}

					.btn-link {
						color: $grey-11;
					}
				}
			}
		}

		.footer {
			display: flex;
			cursor: pointer;
			justify-content: center;

			.collapse-segment-filter {
				.btn-round {
					height: 34px;
					width: 34px;
				}

				span {
					position: absolute;
					line-height: 34px;
					visibility: hidden;
					opacity: 0;
					color: $grey-11;
					font-size: 14px;
					padding-left: 14px;
					transition: all ease-out 200ms;
				}
			}

			&:hover {
				.btn-round {
					color: $grey-10;
					background-color: $grey-6;
				}
				span {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}

	&.placeholder,
	&--placeholder {
		height: 465px;

		.placeholder-wrapper {
			opacity: 1;
			visibility: visible;
			height: 464px;
			padding: 50px;
			z-index: 1000;
		}

		.expanded-wrapper {
			visibility: hidden;
			filter: opacity(0);
		}
	}

	&.collapsed,
	&--collapsed {
		background-color: $white;

		.collapsed-wrapper {
			visibility: visible;
			opacity: 1;
			z-index: 1000;
		}
	}

	&.expanded,
	&--expanded {
		background-color: $grey-1;

		.collapsed-wrapper {
			visibility: hidden;
			filter: opacity(0);
		}

		.expanded-wrapper {
			max-height: 3000px;
			opacity: 1;
			z-index: 1000;
		}
	}

	.btn-round {
		background: transparent;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		border: 1px solid $grey-8;
		color: $grey-10;
		font-size: 10px;
		padding: 0;
		transition: all ease-out 200ms;

		.fa {
			position: relative;
			top: -1px;
		}
	}

	.flex-input-group {
		display: flex;

		input {
			border-radius: 3px;
		}

		.Block {
			display: inline-flex;
			flex: 1;
		}

		.Block:first-child {
			margin-right: -1px;
		}

		.Block:first-child input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			&:focus {
				z-index: 1;
			}
		}

		.Block:last-child input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&.flex-input-group--with-space .Block input {
			border-radius: 3px;
		}

		&.flex-input-group--with-space .Block:first-child {
			margin-right: 15px;
		}

		&.flex-input-group--with-addon {
			input {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			.flex-input-group__addon {
				display: flex;
				justify-content: center;
				align-items: center;
				color: $grey-12;
				background-color: $grey-4;
				border: 1px solid $grey-6;
				padding: 0 15px;
				border-radius: 3px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				margin-left: -1px;
			}
		}

		&.flex-input-group--invalid {
			input,
			.flex-input-group__addon {
				border-color: $red;
			}
		}
	}

	.flex-btn-group {
		display: flex;

		button {
			flex: 1;
			border: 0 !important;

			&:not(:last-child):not(:first-child) {
				border-radius: 0;
			}

			&:last-child {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}

			&:first-child {
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}
		}
	}
}

#segment-filter-v2 {
	margin-top: 50px;
}

.segment-row {
	.column-tools button {
		display: inline-flex;
		align-items: center;
	}

	.column-tools .Icon {
		margin-right: 5px;
	}

	&.excluded {
		td {
			background-color: $grey-1;
			color: $grey-10;

			a {
				color: $grey-10;
			}
		}

		.segment-row-excludetext {
			width: 64px;
		}

		.segment-row-excludetext,
		.column-tools .Icon {
			color: $grey-10;
		}
	}

	&:hover {
		.segment-row-excludetext {
			width: 64px;
		}

		.segment-row-excludetext,
		.column-tools .Icon {
			color: $bright-blue;
		}
	}

	.segment-row-excludetext {
		width: 0;
		transition: 200ms width;
		display: inline-block;
		overflow: hidden;
		color: $bright-blue;
		font-family: Roboto, sans-serif;
	}
}

.inactive-segment {
	#edit-segment-sidebar .section:first-child {
		background-color: $grey-4;
	}
}

#edit-segment-root .CriteriaTable,
.CriteriaSelectSection .CriteriaTable {
	background-color: $white;
}

#edit-segment-root {
	$sidebar-width: 250px;
	$grey-text: $grey-10;

	@include keyframes(tooltipBounce) {
		0% {
			margin-top: 0;
		}
		15% {
			margin-top: 5px;
		}
		50% {
			margin-top: 0;
		}
		100% {
			margin-top: 0;
		}
	}

	.segment-ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.segment-hover-cursor-pointer:hover {
		cursor: pointer;
	}

	#edit-segment-sidebar {
		position: fixed;
		top: 97px;
		bottom: 15px;
		width: $sidebar-width;
		background-color: $white;
		border-top-left-radius: 2px;
		@include box-shadow(1px 2px 3px rgba($black, 0.1));
		z-index: 1001;

		.section {
			padding: 15px;
			border-bottom: 1px solid rgba($black, 0.1);
			@include transition(background-color ease 200ms);
		}

		.section-selection {
			margin-bottom: 10px;

			span {
				font-size: 16px;
			}

			button {
				padding: 0;
				float: right;
			}
		}

		.section-description {
			min-height: 20px;

			.hoverable-placeholder {
				width: 90%;
				background-color: rgba($black, 0.1);
				height: 14px;
				margin-top: 3px;
				float: left;
				transition: all 0.4s;
				opacity: 0;
				border-radius: 3px;
			}

			&:hover {
				.hoverable-placeholder {
					opacity: 1;
				}
			}
		}

		.section-name,
		.section-description {
			color: $grey-13;
			font-size: 20px;
			position: relative;
			word-break: break-word;
		}

		.section-description {
			margin-bottom: 20px;
			font-size: 12px;
			color: $grey-11;
		}

		.section-nocontacts {
			text-align: center;
			font-size: 14px;
			color: $grey-10;

			button {
				padding: 0;
				margin: 20px 0 0 0;
			}
		}

		.section-contacts {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-template-rows: 26px 16px;
		}

		.section-created {
			span.title {
				text-transform: uppercase;
				font-size: 12px;
				color: $grey-10;
			}

			.user-holder {
				margin: 5px 0 0;

				&:after {
					clear: both;
					content: "";
					display: block;
				}

				.user-image {
					margin-right: 10px;
					display: table-cell;
					padding-right: 5px;
				}

				.user-user {
					vertical-align: middle;
					display: table-cell;

					span {
						text-align: left;
						float: none;
						display: block;

						&.creation-date {
							font-size: 12px;
							color: $grey-10;
						}

						&.user-name {
							color: $grey-13;
						}
					}
				}
			}
		}

		.section-status {
			margin: 20px 0 0;

			> span:first-child {
				text-transform: uppercase;
				font-weight: normal;
				font-size: 12px;
				color: $grey-10;
			}

			.status-holder > span {
				font-size: 12px;

				&.link {
					float: right;
					color: $bright-blue;
					font-weight: $regularFontWeight;
					cursor: pointer;

					&:hover {
						color: $blue;
						text-decoration: underline;
					}
				}
			}
		}

		.section-mail-settings {
			b {
				float: right;
			}
		}
	}

	.segment-list {
		.up-paginator-wrap {
			text-align: center;
			display: block;
			margin: 10px 0 20px 0;
			z-index: 1000;
			position: relative;
			padding-bottom: 100px;
			min-height: 1px;

			li,
			a {
				cursor: pointer;
			}
		}

		.upsales-loader {
			margin: auto;
			height: 45px;
		}

		.pagination > li {
			display: inline;
		}
	}

	.segment-list-title {
		padding: 15px;
		font-size: 18px;
	}

	#edit-segment-body.version-2 {
		#segment-contacts {
			background-color: $grey-1;

			.segment-list-title {
				height: 48px;
				background: $white;
				font-size: 18px;
				display: flex;
				padding: 0;

				.segment-status-bar {
					display: flex;
					width: 100%;
					box-shadow: -1px 2px 3px rgba($black, 0.2);
					cursor: pointer;

					.segment-result-filter-container {
						flex: auto;

						.segment-result-filters {
							display: flex;
							max-width: 900px;
							height: 100%;

							.segment-result-filter {
								flex: 1;
								padding: 10px;
								transition: all ease-out 200ms;
								border-bottom: 2px solid $white;

								.filter-title {
									color: $grey-10;
									font-weight: $regularFontWeight;
									font-size: 14px;
									margin: 0;
								}

								.filter-title.filter-active {
									color: $grey-11;
									font-size: 14px;
									margin: 0;
								}

								.filter-subtitle {
									color: $grey-10;
									font-size: 12px;
									margin: 2px 0;
								}

								&:hover:not(.filter-active) {
									border-bottom: 2px solid $grey-6;

									.filter-title {
										color: $grey-11;
									}
								}

								&.filter-active {
									border-bottom: 2px solid $green;

									.filter-title {
										color: $green;
										font-size: 14px;
										font-weight: $boldFontWeight;
									}
									.filter-subtitle {
										color: $bright-green;
										font-size: 12px;
									}
								}

								&.blink {
									background-color: $super-light-green;
									border-bottom: 2px solid $super-light-green;
									h4,
									h5 {
										color: $bright-green;
									}
								}
							}
						}
					}

					.segment-list-search {
						margin: 10px;
					}
				}

				.email-campaigns {
					width: 100%;
					padding: 8px;
				}
			}
		}
	}

	.segment-list-table {
		width: 100%;
		table-layout: fixed;
	}

	.segment-list-thead-tr {
		height: 30px;
	}

	.segment-list-thead-th {
		text-transform: uppercase;
		font-weight: normal;
		font-size: 12px;
		color: $grey-11;
	}

	.segment-list-thead-th.sortable-th {
		cursor: pointer;
	}

	.segment-list-thead-th.sorted {
		color: $bright-blue;
	}

	.segment-list-thead-th .fa {
		font-size: 12px;
		margin-left: 5px;
	}

	.segment-list-tbody-tr {
		background-color: $white;
		height: 45px;
		&:hover td {
			background-color: $grey-1;
		}
	}

	.segment-list-tbody-td,
	.segment-list-thead-th {
		padding-left: 15px;
	}

	.segment-list-thead-tr,
	.segment-list-tbody-tr {
		border-bottom: 1px solid rgba($black, 0.05);
	}

	.segment-list-tbody-td {
		background-color: $white;
		height: 45px;
		font-size: 12px;
	}

	.segment-list-tools {
		padding-right: 15px;
		color: $grey-text;
		text-align: right;

		.Tooltip:first-child i {
			margin-right: 15px;
		}

		i:hover {
			cursor: pointer;
			color: $grey-13;
		}
	}

	.segment-list-search {
		float: right;
		position: relative;

		.fa-search {
			position: absolute;
			top: 8px;
			font-size: 14px;
			left: 10px;
			color: $grey-text;
			cursor: default;
		}

		.fa-times-circle {
			display: none;
			color: rgba($bright-blue, 0.4);
			position: absolute;
			right: 10px;
			left: unset;
			top: 8px;
			font-size: 14px;
			@include transition(color ease 200ms);

			&:hover {
				color: $bright-blue;
			}
		}

		input {
			border: none;
			background-color: rgba($black, 0.05);
			@include border-radius(3px);
			@include box-shadow(0 1px 2px rgba($black, 0.1) inset);
			height: 30px;
			line-height: 30px;
			outline: none;
			padding: 0 28px 0 30px;
			width: 150px;
			font-size: 12px;
		}

		&.active {
			.fa-search {
				color: $bright-blue;
			}

			.fa-times-circle {
				display: block;
			}

			input {
				background-color: rgba($bright-blue, 0.2);
			}
		}
	}

	.segment-list-error {
		cursor: initial !important;
	}

	.segment-list-error .Help {
		display: inline-block;
		margin-left: 5px;
	}

	#segment-contacts .segment-list-noresult-placeholder {
		padding-top: 50px;

		h4 {
			color: $grey-10;
			margin-bottom: 15px;
		}

		p {
			color: $grey-10;
			opacity: 0.9;
		}
	}

	#segment-body {
		margin-top: 50px;
		margin-bottom: 15px;
	}

	#segment-events {
		background-color: $grey-1;

		.upsales-loader {
			height: 50px;
			width: 50px;
			margin: 0 auto;
		}

		.no-events-msg {
			font-size: 20px;
			color: $grey-10;
			text-align: center;
			display: block;
		}

		#header {
			padding: 30px 20px;

			h2 {
				margin: 0;
				padding: 0;
				font-size: 20px;
				color: $grey-11;
				font-weight: normal;
				height: 30px;
				line-height: 30px;
			}

			#filters {
				float: right;
			}
		}

		#event-table-wrap {
			display: block;
			margin: 0 20px;
		}

		#event-table {
			width: 100%;
			$table-padding: 10px;

			th {
				font-weight: normal;
				text-transform: uppercase;
				font-size: 14px;
				padding-left: 10px;
				padding-bottom: 5px;
			}

			.expand-row-contents {
				visibility: hidden;
				@include opacity(0);
				@include transition(all ease 200ms);
				height: 0;
				line-height: 20px;
				font-size: 14px;
				color: $grey-11;
				padding: 0 $padding;
				overflow: hidden;
			}
			.expand-row {
				border-top: none;
				padding: 0;
			}

			tr.no-data {
				text-align: center;
				font-size: 14px;
				color: $grey-10;
			}

			tr.expanded {
				.expand-row-contents {
					visibility: visible;
					@include opacity(1);
					height: 68px;
					min-width: 125px;
					padding: $padding;
				}
				.expand-row {
					border-top: 1px solid $grey-4;
				}

				.no-data .expand-row-contents {
					height: 50px;
				}
			}

			.table-group {
				background-color: $white;
				margin-bottom: $table-padding;
				@include box-shadow(0 1px 1px rgba($black, 0.1));

				.inner-table {
					width: 100%;

					.group-header td {
						padding: 20px $table-padding;
						@include transition(background-color ease 200ms);
						cursor: pointer;
					}

					.group-header:hover td {
						background-color: $grey-1;
					}

					.event-icon {
						$size: 30px;
						width: $size;
						height: $size;
						line-height: $size;
						@include border-radius($size);
						@include box-shadow(0 1px 2px rgba($black, 0.3));
						text-align: center;
						color: $white;
						font-size: 14px;
						margin-right: 10px;
						display: inline-block;
						margin-top: 4px;

						&.red {
							background-color: $red;
						}

						&.bright-blue {
							background-color: $bright-blue;
						}

						&.orange {
							background-color: $purple;
						}
					}

					.date {
						font-size: 12px;
						color: $grey-11;
					}

					.subtitle {
						display: block;
						color: $grey-10;
						font-size: 12px;
					}

					.group-date {
						color: $grey-10;
						font-size: 12px;

						h2 {
							font-size: 18px;
							color: $grey-13;
							margin: 0;
							padding: 0;
							display: block;
							margin-bottom: 5px;
						}
					}

					.group-mailcampaigns {
						$icon-size: 20px;
						.stats-row {
							line-height: $icon-size;
							margin-bottom: 5px;
							display: block;
							color: $bright-blue;

							&:last-of-type {
								margin-bottom: 0;
							}
						}
						.icon {
							width: $icon-size;
							height: $icon-size;
							background-color: $purple;
							@include border-radius(2px);
							@include box-shadow(0 1px 2px rgba($black, 0.3));
							margin-right: 10px;
							text-align: center;
							line-height: $icon-size;
							font-size: 10px;
							color: $white;
							display: inline-block;
						}
					}

					.group-sum {
						color: $grey-13;

						.total {
							display: block;
							font-size: 18px;
							line-height: 20px;
							margin-bottom: 5px;

							.fa {
								margin-right: 5px;
								font-size: 16px;
							}
						}

						.added,
						.removed {
							display: inline-block;
							width: 50%;
							font-size: 14px;
							line-height: 20px;

							.fa {
								margin-right: 5px;
							}
						}

						.added {
							.fa {
								color: $bright-blue;
							}
						}

						.removed {
							.fa {
								color: $red;
							}
						}
					}
				}
			}
		}
	}

	#contact-stats {
		background-color: $white;
	}

	#chart-wrap {
		position: relative;
		height: 170px;
		border-bottom: 1px solid $grey-8;

		&.loading {
			.chart {
				visibility: hidden;
				@include opacity(0);
			}
			.upsales-loader {
				visibility: visible;
				@include opacity(1);
			}
		}

		h2 {
			display: block;
			font-size: 18px;
			padding: 20px 0 0 20px;
			font-weight: normal;
			color: $grey-13;
			.fa {
				font-size: 14px;
			}
		}

		.subtitle {
			padding: 5px 0 0 20px;
			color: $grey-10;
		}

		.chart {
			position: absolute;
			top: 60px;
			bottom: 0;
			left: 0;
			right: 0;
			visibility: visible;
			@include opacity(1);
			@include transition(all ease 200ms);
		}

		.upsales-loader {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 50px;
			width: 50px;
			margin: -25px 0 0 -25px;
			visibility: hidden;
			@include opacity(0);
			@include transition(all ease 200ms);
		}
	}

	#widget-wrap {
		border-bottom: 1px solid $grey-8;

		.widget {
			display: inline-block;
			border-right: 1px solid $grey-8;
			width: 25%;
			color: $grey-13;
			font-size: 30px;
			font-weight: $regularFontWeight;
			padding: 10px;

			&:last-of-type {
				border-right: none;
			}

			.title {
				color: $grey-10;
				text-transform: uppercase;
				font-size: 12px;
				display: block;
				font-weight: normal;
			}
		}
	}

	.segment-list-mailcampaign-archived {
		.segment-list-tbody-td {
			background-color: $grey-1;
		}
	}

	#segment-mailcampaign {
		margin-top: 50px;

		.segment-list-title {
			&:after {
				content: "";
				clear: both;
				display: block;
			}
			.email-campaigns > .Tooltip {
				display: block;
				float: right;
			}
		}

		.segment-list-table {
			table-layout: fixed;
		}

		.segment-list-tbody-tr {
			cursor: pointer;
		}
	}

	#segment-contacts {
		.segments-contacts-client {
			font-size: 10px;
			text-transform: uppercase;

			a {
				color: $grey-text;
			}
		}

		.segment-contacts-added {
			font-size: 12px;
		}
	}

	#segment-bouncy-tooltip {
		position: absolute;
		background-color: $dark-blue;
		color: $white;
		max-width: 180px;
		right: -15px;
		top: 55px;
		@include border-radius(3px);
		padding: 10px;
		@include animation(tooltipBounce 2s ease infinite);
		@include box-shadow(0 10px 20px rgba($black, 0.1));
		font-size: 14px;

		&:after {
			content: "";
			position: absolute;
			left: 50%;
			margin-left: 5px;
			top: -14px;
			border: 7px solid transparent;
			border-bottom-color: $blue;
			width: 0;
			height: 0;
		}
	}

	.segment-filters-list {
		background-color: $white;
		border-radius: 2px;
		@include box-shadow(0px 1px 3px rgba($black, 0.2));
		margin-bottom: 20px;

		.segment-fl-header {
			position: relative;
			padding: 15px;
		}

		.segment-fl-addbutton {
			float: right;
		}

		.segment-fl-titlerow {
			font-size: 18px;
			margin-bottom: 4px;
		}

		.segment-fl-icon {
			color: $medium-green;
			margin-right: 10px;
		}

		.segment-fl-subtitle {
			color: $grey-text;
		}

		.segment-fl-body {
			min-height: 110px;
		}

		.segment-fl-table {
			width: 100%;
		}

		.segment-fl-th,
		.segment-fl-td {
			padding-left: 15px;
		}

		.segment-fl-tr {
			border-bottom: 1px solid rgba($black, 0.1);
			height: 40px;
		}

		.segment-fl-tbody {
			.segment-fl-td:last-child {
				text-align: right;
				padding-right: 15px;
				color: $grey-text;
			}
		}

		.segment-fl-thead {
			.segment-fl-tr {
				height: 30px;
			}

			.segment-fl-th {
				font-weight: normal;
				text-transform: uppercase;
			}
		}

		.segment-fl-tfoot {
			.segment-fl-td {
				text-align: right;
				padding-right: 15px;
				text-transform: uppercase;
			}
		}

		.segment-fl-total {
			font-weight: $boldFontWeight;
			font-size: 16px;
		}

		.segment-fl-noMatch {
			text-align: center;
			font-size: 18px;
			color: $grey-text;
			margin: 20px;
		}

		.segment-fl-noMatchExplanation {
			width: 400px;
			margin: auto;
			text-align: center;
			color: $grey-text;
			padding-bottom: 15px;
		}

		&.has-filters {
			.segment-fl-body {
				min-height: 0px;
			}
		}

		&.exclude {
			.segment-fl-icon {
				color: $red;
			}
		}

		&:last-child {
			margin-bottom: 10px;
		}
	}

	.filter-onboarding {
		height: 420px;
		background: $white;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 499;
		margin: 80px auto;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		overflow: hidden;
		max-width: 700px;

		.svg-block {
			margin-left: 30px;
			margin-top: 30px;
			float: left;
		}

		.text-block {
			float: left;
			width: 280px;
			position: absolute;
			right: 40px;
			top: 50px;

			h2 {
				margin-bottom: 10px;
			}

			p {
				margin: 10px 0 20px 0;
				color: $grey-13;

				span.ingress {
					margin: 0 0 20px 0;
					display: block;
					font-size: 16px;
					color: $grey-11;
				}
			}
		}
	}

	.btn-round {
		background: transparent;
		border-radius: 50%;
		height: 24px;
		width: 24px;
		border: 1px solid $grey-8;
		color: $grey-10;
		font-size: 10px;
		padding: 0;
		transition: all ease-out 200ms;

		.fa {
			position: relative;
			top: -1px;
		}
	}

	#segment-save-btn {
		position: fixed;
		visibility: hidden;
		width: 300px;
		z-index: $modal-z - 1;
		left: 50%;
		bottom: -50px;
		margin-left: -10px; //-150px;
		@include transition(all cubic-bezier(0.68, -0.55, 0.37, 2) 300ms);

		&.visible {
			visibility: visible;
			bottom: 30px;
		}

		&:disabled {
			@include opacity(0.5);
		}
	}

	@media (min-width: 1900px) {
		.segment-v2 {
			height: 100%;

			#segment-filter-v2 {
				width: 50%;
				display: inline-block;
				vertical-align: top;
				border-bottom: 0;
				height: calc(100% - 44px);
				overflow-y: auto;

				.toggle-button-wrap {
					display: none;
				}

				.footer {
					pointer-events: none;

					.collapse-segment-filter {
						display: none;
					}
				}

				&.placeholder .placeholder-wrapper {
					height: 100%;
				}

				&.expanded,
				&--expanded {
					background-color: $grey-4;
				}
			}

			#segment-contacts {
				width: 50%;
				display: inline-block;
				vertical-align: top;
				margin-top: 92px;
				border-left: 1px solid rgba($black, 0.1);
				height: calc(100% - 92px);
				overflow-y: auto;
			}
		}

		.segment-v2--noFrame {
			#segment-filter-v2 {
				height: stretch;
			}

			#segment-contacts {
				margin-top: 50px;
				height: stretch;

				.segment-list-title {
					top: 132px;
				}
			}
		}
	}

	#segment-filters {
		margin-top: 50px;
		background-color: $super-light-blue;
		position: relative;
		@include box-shadow(1px 2px 3px rgba($black, 0.15));
		margin-bottom: 30px;
		padding-bottom: 0;
		height: 120px;

		.child-holder {
			overflow: hidden;
			width: 100%;
			height: 100%;

			> div {
				opacity: 0;
				visibility: hidden;
				position: absolute;
				width: 100%;
				padding: 20px;
				overflow: hidden;

				&.active {
					opacity: 1;
					visibility: visible;
					position: relative;
				}
			}
		}

		&.expanded {
			height: auto;
			padding-bottom: 20px;
		}

		.segment-filters-titlerow {
			height: 40px;
		}

		.segment-filters-title {
			color: $black;
			font-size: 18px;
		}

		.segment-filters-titlebutton {
			float: right;
		}

		.segment-filters-unexpanded-text {
			height: 80px;
			line-height: 80px;
			font-size: 18px;
			color: $bright-blue;
			text-align: center;
		}

		.segment-filters-button {
			$height: 40px;

			background-color: $super-light-blue;
			border: 0;
			border-bottom-left-radius: 2px;
			border-bottom-right-radius: 2px;
			line-height: $height;
			position: absolute;
			height: $height;
			bottom: -$height / 2;
			width: 200px;
			margin-left: calc(50% - 100px);
			color: $bright-blue;

			.segments-filters-button-shadow-element {
				height: $height / 2;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				@include box-shadow(1px 2px 3px rgba($black, 0.15));
			}
		}
	}

	#edit-segment-body {
		position: absolute;
		top: 0;
		bottom: 0;
		left: $sidebar-width;
		right: 0;
		background-color: $grey-1;
		border-top-right-radius: 2px;

		.BouncingTooltip {
			display: inline-flex;
		}

		.navigation-bar {
			position: fixed;
			right: 16px;
			left: 314px;
			z-index: 1100;

			.BouncingTooltip__tooltip {
				left: -80px;
			}

			.nav-a {
				position: relative;
				height: 51px;
				line-height: 51px;
				min-width: 140px;
				text-align: center;
				color: $grey-10;
			}

			.nav {
				border-top: 0;
				height: 51px;
				border-bottom: 1px solid $grey-6;
			}

			.nav-tools {
				float: right;
				.nav-a {
					min-width: 40px;
				}
			}

			.current {
				border-bottom: 2px solid $blue;
				color: $blue;
			}

			.Tabs--no-flex {
				display: inline-flex;
			}

			.special-subtitle-class {
				text-transform: capitalize;

				&.special-subtitle-active {
					.Tab__subtitle {
						color: $medium-green;
					}
				}
			}

			.settings-dropdown {
				opacity: 0;
				right: 0;
				position: absolute;
				z-index: -1;
				width: 320px;
				box-shadow: 0 2px 4px 0 rgba($black, 0.2);
				background-color: $white;
				margin-top: -1px;
				transition: all 0.4s;
				visibility: hidden;

				&.open {
					opacity: 1;
					z-index: 5000;
					visibility: visible;
				}

				ul {
					padding: 0;
					list-style: none;

					&.flowVisible {
						padding: 15px 0;

						.segment-dropdown-subtitle {
							padding: 5px 15px;
							text-transform: uppercase;
							font-size: 12px;
							color: $black;
						}

						@media screen and (max-height: 670px) {
							overflow-y: scroll;
							height: 70vh;
						}
					}

					.item {
						transition: all 0.3s;
						display: table;
						width: 100%;

						&:hover {
							cursor: pointer;
						}

						.icon {
							display: table-cell;
							padding: 15px;
							vertical-align: middle;
							width: 50px;
							text-align: center;
							color: $grey-13;
							font-size: 18px;
						}

						.text {
							padding: 10px 0;

							> span {
								display: block;
								font-size: 12px;
								color: $grey-10;

								&:first-child {
									font-size: 14px;
									color: $grey-13;
								}
							}
						}

						&:hover {
							background-color: $grey-1;
						}

						&.disabled {
							cursor: not-allowed;
							background-color: $grey-3;

							&hover {
								background-color: $grey-3;
							}
						}

						&.green {
							background-color: $super-light-green;

							.icon,
							.text > span:first-child {
								color: $bright-green;
							}

							&:hover {
								background-color: darken($super-light-green, 3);

								.icon,
								.text > span:first-child {
									color: darken($bright-green, 2);
								}
							}
						}
					}
				}
			}
		}

		.nothing-to-see-wrapper {
			position: relative;
			top: 50px;
			padding: 80px 120px;
			text-align: center;

			h2 {
				font-size: 24px;
			}

			p {
				margin: 20px 0;
			}

			button {
				width: 280px;
				height: 45px;
				font-size: 15px;
				font-weight: normal;
				margin-top: 20px;
			}
		}
	}

	.flex-input-group {
		display: flex;

		input {
			flex: 1;
			border-radius: 3px;

			&:first-child {
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
				margin-right: -1px;

				&:focus {
					z-index: 1;
				}
			}

			&:last-child {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}
		}

		&.invalid input {
			border-color: $red;
		}
	}

	.flex-btn-group {
		display: flex;

		button {
			flex: 1;
			border: 0 !important;

			&:not(:last-child):not(:first-child) {
				border-radius: 0;
			}

			&:last-child {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}

			&:first-child {
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}
		}
	}
}

#new-segment {
	.segment-icon {
		text-align: center;
		margin-top: 30px;
	}

	.up-panel-content {
		.segment-info-text {
			color: $grey-10;
			font-size: 12px;
		}
	}

	.floating-label-input {
		input {
			font-size: 14px !important;
		}

		&.has-value {
			label {
				bottom: 20px;
			}
		}
	}

	.modal-close {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 1000;
	}

	.active-row {
		label {
			font-weight: normal;
			display: block;
			color: $grey-10;
			font-size: 12px;
		}
	}
}

#segment-info-row {
	height: 200px;
	td {
		position: relative;
		border-bottom: 2px solid $grey-8;
		padding-left: 0;

		.title-text {
			font-size: 20px;
			margin-bottom: 15px;
			text-align: center;
		}

		.info-content {
			width: 100%;
			height: 110px;

			.tab-style {
				font-size: 12px;
				width: 33%;
				float: left;

				.icon {
					width: 30px;
					margin: auto;
					height: 30px;
					line-height: 30px;
					font-size: 14px;
					color: $white;
					border-radius: 30px;
					background-color: $bright-blue;
					text-align: center;
				}

				.info-text {
					width: 80%;
					margin: auto;
					color: $grey-13;
					margin-top: 10px;
					text-align: center;
				}
			}
		}

		.close-btn {
			position: absolute;
			bottom: 10px;
			text-align: center;
			width: 100%;
			cursor: pointer;
			color: $bright-blue;

			.fa {
				margin-right: 5px;
			}
		}
	}
}

#segment-flow {
	$header-height: 45px;
	position: absolute;
	top: 50px;
	bottom: 0;
	left: 0;
	right: 0;

	#flow-header {
		position: relative;
		padding: 50px 40px;
		background-color: $white;
		border-bottom: 1px solid lightgrey;
		@include box-shadow(1px 2px 3px rgba($black, 0.1));

		.BouncingTooltip {
			display: inline-flex;
		}

		#flow-status-dropdown > button,
		#edit-flow-btn {
			border: none;
			background-color: transparent;
			height: $header-height;
			padding: 10px 20px 10px 15px;
			outline: none;
			vertical-align: top;
			min-width: 150px;
			@include transition(all ease 200ms);

			&:hover {
				background-color: $grey-1;
			}
		}

		#edit-flow-btn {
			position: relative;
			float: right;
			text-align: center;
			border-left: 1px solid $grey-4;
			color: $green;
			font-size: 14px;
			line-height: $header-height;
			padding-top: 0;
			padding-bottom: 0;
		}

		#edit-flow-right-btn {
			float: right;
			border-radius: 0;
			font-size: 14px;
		}

		.nav-tools {
			float: right;

			.nav-a {
				vertical-align: middle;
				line-height: 45px;
				border-left: 1px solid $grey-4;
				position: relative;
				float: right;
				min-width: 40px;
				text-align: center;
				color: $grey-10;

				&:hover {
					background-color: $grey-1;
				}
			}
		}

		.settings-dropdown {
			opacity: 0;
			right: 0;
			position: absolute;
			z-index: -1;
			width: 320px;
			box-shadow: 0 2px 4px 0 rgba($black, 0.2);
			background-color: $white;
			margin-top: -1px;
			transition: all 0.4s;
			visibility: hidden;
			top: 100%;

			&.open {
				opacity: 1;
				z-index: 5000;
				visibility: visible;
			}

			ul {
				padding: 0;
				margin: 0;
				list-style: none;

				.item {
					transition: all 0.3s;
					display: table;
					width: 100%;

					&:hover {
						cursor: pointer;
					}

					.icon {
						display: table-cell;
						padding: 15px;
						vertical-align: middle;
						width: 50px;
						text-align: center;
						color: $grey-13;
						font-size: 18px;
					}

					.text {
						padding: 10px 0;

						> span {
							display: block;
							font-size: 12px;
							color: $grey-10;

							&:first-child {
								font-size: 14px;
								color: $grey-13;
							}
						}
					}

					&:hover {
						background-color: $grey-1;
					}

					&.green {
						background-color: $super-light-green;

						.icon,
						.text > span:first-child {
							color: $bright-green;
						}

						&:hover {
							background-color: darken($super-light-green, 3);

							.icon,
							.text > span:first-child {
								color: darken($bright-green, 2);
							}
						}
					}
				}
			}
		}

		.BouncingTooltip__tooltip {
			left: -30px;
		}

		#flow-status-dropdown {
			position: relative;
			display: inline-block;
			vertical-align: top;
			outline: none;
			@include user-select(none);

			&.open {
				.flow-status-dropdown-popup {
					display: block;
				}
				& > button .fa-caret-down {
					@include transform(rotate(180deg));
				}
			}

			&.active {
				& > button {
					background-color: rgba($bright-green, 0.1);

					.status {
						color: $bright-green;
					}
				}
			}

			&.disabled {
				& > button {
					background-color: $grey-2;
					cursor: default;
				}
			}

			& > button {
				position: relative;
				text-align: left;
				border-right: 1px solid $grey-4;

				.header {
					font-size: 10px;
					line-height: 10px;
					text-transform: uppercase;
					display: block;
					color: $grey-10;
					letter-spacing: 1px;
				}

				.status {
					font-size: 14px;

					.fa {
						margin-right: 3px;
					}
				}

				.fa-caret-down {
					@include transition(all ease 200ms);
					position: absolute;
					top: 50%;
					right: 0;
					width: 20px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					margin-top: -(20px/2);
					color: $grey-10;
				}
			}

			.flow-status-dropdown-popup {
				position: absolute;
				display: none;
				background-color: $white;
				z-index: 1000;
				width: 310px;
				height: 230px;
				border-right: 1px solid $grey-4;
				border-top: none;
				overflow: hidden;
				@include box-shadow(0 2px 5px rgba($black, 0.1));

				.activation-activated,
				.activation-loading,
				.option-large {
					position: absolute;
					display: block;
					height: 230px;
					width: 100%;
					text-align: center;
					padding: 40px;
					@include transition(all ease 200ms);
					visibility: hidden;
					@include opacity(0);
					@include transition-delay(0ms);
					margin-top: -30px;

					&.option-visible {
						visibility: visible;
						@include opacity(1);
						@include transition-delay(200ms);
						margin-top: 0;
					}
				}

				.activation-activated,
				.activation-loading {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}

				.activation-loading {
					.Loader {
						width: 70px;
						height: 70px;
					}
				}

				.activation-activated {
					.Icon-check {
						font-size: 36px;
					}
				}
			}
		}

		.header-left {
			width: 285px;

			h2 {
				display: block;
				color: $grey-12;
				font-weight: normal;
				font-size: 24px;
				margin-bottom: 10px;
			}

			p {
				font-size: 16px;
				color: $grey-10;
				margin: 20px 0 40px;
			}

			a {
				display: block;
				color: $bright-blue;
				margin-bottom: 5px;
				cursor: pointer;
			}

			button {
				margin-bottom: 15px;
				width: 100%;
				position: relative;

				> .Icon {
					position: absolute;
					left: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.select-wrap {
				position: relative;
				height: 100%;
				flex: 1;
				min-width: 285px;

				.Loader {
					margin: 0 auto;
				}

				.flow-select {
					display: none;
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
				}
			}

			.flow {
				.flow-select {
					display: block;
				}
			}
		}
		.header-right {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 40px;
			width: auto;
			overflow: hidden;

			button {
				position: absolute;
				top: 50%;
				margin-top: -22.5px;
			}
		}
	}

	#flow-preview {
		position: absolute;
		left: 0;
		right: 0;
		top: $header-height + 1;
		bottom: 0;
	}

	#flow-info {
		text-align: center;
		padding: 30px;
		margin: 0 auto;
		max-width: 500px;

		h2 {
			margin-bottom: 20px;
			font-size: 20px;
			color: $grey-11;
			line-height: 30px;
		}

		p {
			color: $grey-10;
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 20px;
		}
	}

	#flow-loader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $grey-1;
		visibility: hidden;
		@include opacity(0);
		@include transition(all ease 200ms);

		&.visible {
			visibility: visible;
			@include opacity(1);
		}

		.upsales-loader {
			$size: 100px;
			position: absolute;
			top: 50%;
			left: 50%;
			width: $size;
			height: $size;
			margin-left: -($size/2);
			margin-top: -($size/2);
		}
	}
}

// Remove when TICKET_RULES is released
.select2-criteria-select {
	.select2-result-unselectable.primary-criterias > .select2-result-label {
		background-color: $white;
		color: $black;
		font-size: 18px;
		text-transform: none;
	}

	.criteria-select-result {
		.fa {
			margin-right: 10px;
		}
	}
}
// Everything under the #newSegment__root can eventually be removed when we are getting rid of angular modals. 
// This styling has been added and modified for the react modal and can be find in the folder "upsales-crm/ui/app/components/NewSegment/NewSegmentModal.scss"
// Feature_flag: REACT_NEW_SEGMENT_MODAL
#newSegment__root {
	min-height: 100%;
	margin-bottom: -25px;

	.modal-close {
		position: absolute;
		z-index: 6000;
	}

	.col-container {
		height: 100%;
		float: left;
	}

	.col {
		width: 275px;
		float: left;
		padding-right: 25px;
		height: 100%;

		&.dark-background {
			background-color: $grey-11;
			position: relative;
			color: $white;

			h2 {
				color: $white;
				margin-bottom: 20px;
			}

			p {
				font-size: 12px;
				color: $grey-1;
				margin-bottom: 150px;

				> .ingress {
					font-size: 16px;
					color: $grey-1;
					margin-bottom: 20px;
					display: block;
				}
			}

			.text {
				width: calc(100% - 350px);
				padding: 100px 25px 0 0;
				box-sizing: border-box;
				float: left;
			}

			.svg-holder {
				width: 300px;
				float: left;
				margin: 25px;

				svg g .user_lower_level_first {
					animation: animate-drop 0.6s ease-out normal;
				}

				@keyframes animate-drop {
					0% {
						transform: translate(110px, 20px);
					}
					100% {
						transform: translate(110px, 130px);
					}
				}

				svg g .user_lower_level_second {
					animation: animate-drop-second 0.8s ease-out normal;
				}

				@keyframes animate-drop-second {
					0% {
						transform: translate(170px, 100px);
					}
					25% {
						transform: translate(170px, 100px);
					}
					100% {
						transform: translate(170px, 215px);
					}
				}

				.times-first,
				.times-second,
				.times-third,
				.times-fourth {
					opacity: 0.5;
					animation: animate-reveal 0.2s ease-out normal;
				}

				.user_first,
				.user_second,
				.user_third,
				.user_fourth {
					opacity: 0.5;
					animation: decrease-opacity 1s ease-out normal;
				}

				@keyframes decrease-opacity {
					from {
						opacity: 1;
					}
					to {
						opacity: 0.5;
					}
				}

				#check-second-level-left {
					animation: animate-reveal 1s ease-out normal;
				}

				#check-second-level-right {
					animation: animate-reveal 1.4s ease-out normal;
				}

				@keyframes animate-reveal {
					0% {
						fill: $grey-11;
					}
					60% {
						fill: $grey-11;
						transform: translate(0px, 10px);
					}
					70% {
						fill: $medium-purple;
						transform: translate(0px, -6px);
					}
					100% {
						fill: $medium-purple;
						transform: translate(0px, 0px);
					}
				}

				#second-level-floor,
				#second-level-ellipse {
					animation: floor-color 1s ease-out normal;
				}

				@keyframes floor-color {
					0% {
						fill: $grey-3;
					}
					85% {
						fill: $grey-3;
					}
					100% {
						fill: $super-light-purple;
					}
				}

				#second-level-floor-edge {
					animation: floor-edge 1s ease-out normal;
				}

				@keyframes floor-edge {
					0% {
						fill: $grey-8;
					}
					85% {
						fill: $grey-8;
					}
					100% {
						fill: $dark-purple;
					}
				}

				#second-level-floor-shadow {
					animation: floor-edge 1.2s ease-out normal;
				}

				@keyframes floor-edge {
					0% {
						fill: $grey-8;
					}
					75% {
						fill: $grey-8;
					}
					100% {
						fill: $dark-purple;
					}
				}

				@keyframes animate-width {
					0% {
						rx: 40;
					}
					50% {
						rx: 50;
					}
					100% {
						rx: 40;
					}
				}

				#stroke-shadow {
					stroke-dasharray: 1000;
					stroke-dashoffset: 1000;
					animation: flow-stroke 4s 1.4s linear forwards;
				}

				#flow-stroke {
					stroke-dasharray: 1000;
					stroke-dashoffset: 1000;
					animation: flow-stroke 4s 1.4s linear forwards;
				}

				@keyframes flow-stroke {
					to {
						stroke-dashoffset: 0;
					}
				}

				#flow-stroke-dashed {
					stroke-dasharray: 5;
				}

				#box-first {
					animation: box-color 2.2s ease-out normal;
				}

				#box-second {
					animation: box-color 2.6s ease-out normal;
				}

				#circle-first {
					animation: box-color 4.5s ease-out normal;
				}

				@keyframes box-color {
					0% {
						fill: $grey-8;
					}
					75% {
						fill: $grey-8;
					}
					100% {
						fill: $medium-purple;
					}
				}

				#circle-second {
					animation: circle-second 3.8s ease-out normal;
				}

				@keyframes circle-second {
					0% {
						fill: $grey-8;
					}
					95% {
						fill: $grey-8;
					}
					100% {
						fill: $medium-purple;
					}
				}

				#circle-second-icon {
					animation: circle-icon-color 3.8s ease-out normal;
				}

				@keyframes circle-icon-color {
					0% {
						fill: $grey-10;
						transform: translate(0px, 20px);
						opacity: 0;
					}
					90% {
						fill: $grey-10;
						transform: translate(0px, 20px);
						opacity: 0;
					}
					95% {
						fill: $grey-11;
						transform: translate(0px, -6px);
						opacity: 1;
					}
					100% {
						fill: $grey-11;
						transform: translate(0px, 0px);
						opacity: 1;
					}
				}

				.flow-check-top {
					animation: animate-reveal-flow-check 2.2s ease-out normal;
				}

				@keyframes animate-reveal-flow-check {
					0% {
						fill: $grey-11;
					}
					80% {
						fill: $grey-11;
						transform: translate(0px, 10px);
					}
					90% {
						fill: $medium-purple;
						transform: translate(0px, -6px);
					}
					100% {
						fill: $medium-purple;
						transform: translate(0px, 0px);
					}
				}

				.flow-check-bottom {
					animation: animate-reveal-flow-check 2.6s ease-out normal;
				}

				.flow-times-top {
					animation: animate-reveal-times-check 2.2s ease-out normal;
				}

				.flow-times-bottom {
					animation: animate-reveal-times-check 2.6s ease-out normal;
				}

				@keyframes animate-reveal-times-check {
					0% {
						fill: $grey-11;
					}
					80% {
						fill: $grey-11;
						transform: translate(0px, 10px);
					}
					90% {
						fill: $grey-8;
						transform: translate(0px, -6px);
					}
					100% {
						fill: $grey-8;
						transform: translate(0px, 0px);
					}
				}
			}
		}

		h2 {
			margin-bottom: 45px;
		}

		.floating-label-input {
			&.has-value {
				> label {
					bottom: 30px;
				}

				input {
					font-size: 20px;
					border-bottom-color: $bright-blue;
				}

				textarea {
					font-size: 14px;
					border-bottom-color: $bright-blue;
				}

				&.floating-textarea {
					margin-top: 40px;

					> label {
						bottom: 44px;
					}
				}

				&.floating-toggle {
					margin-top: 40px;
				}
			}

			&.error-input {
				input {
					border-bottom-color: rgba($red, 0.8);
					background: rgba($red, 0.05);
					border-radius: 3px 3px 0 0;
				}
			}
		}

		.button-holder {
			width: 250px;
			margin-top: 25px;
			position: absolute;
			bottom: 20px;
		}
	}

	.col + .col {
		width: 670px;
		float: left;
		margin-top: -25px;
		margin-right: -25px;
		margin-bottom: -25px;
		height: 100%;
	}
}
