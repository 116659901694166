@import "~StyleVariables";

.TemplateHeaderSelector {
	position: absolute;
	right: 50px;
	top: 0;
	height: 46px;

	> .DropDownMenu {
		text-align: right;
		width: 300px;
	}

	&__saveButton {
		.SplitButton .Button {
			background-color: $super-light-green;
			color: $black;

			&[disabled] {
				color: $grey-10;
			}
		}
	}

	.Modal.Modal {
		overflow-y: visible;
	}

	.TemplateHeaderSelector__modal.TemplateHeaderSelector__modal {
		position: absolute;
		// Should come from WhiteModal but was overridden and didn't dare to change there
		max-height: 650px;  
	}

	&__modal {
		top: 40px;
		right: 0;
		position: absolute;
		width: 270px;
		overflow: visible;

		&__controls {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			&__deleteConfirm {
				width: 100%;
				margin-top: $space * 4;
			}

			&__delete {
				position: absolute;
				left: $space * 2;
				width: auto;
				top: $space;

				/**
                * Dropdown with icon as button and content stick to it
                * See: https://github.com/upsales/ui-components/issues/554
                */
				> .DropDownButton--expanded > .DropDownButton__wrapper {
					position: absolute;
					background: $white;
					border-top-left-radius: $border-radius;
					border-top-right-radius: $border-radius;
					height: 46px;
				}

				> .DropDownButton--expanded.DropDownButton {
					position: absolute;
					z-index: 2;
					background: $white;
					&::before {
						box-shadow: 1px 0 0 0 #e4eaf0, 0 -2px 6px 0 rgba(30, 37, 46, 0.2); // delete button shadow when active. It is slightly different from the default one
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						height: 42px;
						width: 38px;
						border-radius: $border-radius;
					}
				}

				.DropDownMenu__content {
					width: 250px;
					top: 40px;

					&--addMinWidth {
						min-width: 250px;
					}
				}
				.DropDownButton__icon-wrapper {
					display: none;
				}
			}
		}
	}

	&__options {
		.DropDownMenu__content {
			max-height: 400px;
			overflow-y: auto;
			width: 300;
			right: 0;
			left: unset;
		}

		&__selectedValue {
			.Title {
				max-width: 250px;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: end;
			}
		}

		&__option {
			cursor: pointer;
			max-width: 300px;
			overflow: hidden;
			text-overflow: ellipsis;
			text-wrap: nowrap;
			height: 40px;

			.Text {
				max-width: 230px;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&--selected {
				border-left: solid 3px $green;
			}
			.TemplateHeaderSelector__editButton {
				display: none;
			}
			&:hover {
				background-color: $grey-4;
				.TemplateHeaderSelector__editButton {
					display: block;
				}
			}
		}
	}
}
