@import "~StyleVariables";

.NavbarDropdown {
	position: relative;
	display: inline-block;
	$width: 300px;

	&__menu {
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
		position: absolute;
		border-radius: 4px;
		width: $width;
		top: 30px;
		box-shadow: 0 3px 6px rgba($black, 0.3);
		z-index: 9000;
		overflow: hidden;
		max-height: 80vh;
		overflow-y: auto;

		&--open {
			visibility: visible;
			opacity: 1;
			top: 40px;
		}
	}

	&--center &__menu {
		margin-left: -($width / 2);
		left: 50%;
	}
	&--left &__menu {
		left: 0;
	}
	&--right &__menu {
		right: 0;
	}
}
