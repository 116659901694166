@import "~StyleVariables";

.RelateTo {
	background-color: $white;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 56px;
	gap: 16px;

	.RelationSelect {
		margin: 16px;
		min-width: 460px;
	}
}
