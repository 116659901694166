.TimelineSubSection {
	flex-wrap: nowrap;
	width: 100%;
	height: 36px;
	padding-right: 12px;

	&__iconColumn {
		display: flex;
		justify-content: flex-end;
	}

	&__contentColumn {
		display: flex;
		align-items: center;
		line-height: 24px;
		padding-left: 12px;
		flex: 1;
		min-width: 1px;

		&--hasLink {
			display: flex;
			align-items: center;
			padding: 0;
			text-decoration: none;
			width: 100%;
			margin-left: 12px;

			.Link {
				margin-left: 4px;
			}
		}
	}
}
