.DateTimeSelect {
	.DateInput {
		width: 65%;
		.Input__input { 
			border-radius: 4px 0 0 4px;
		}
	}
	.TimeInput {
		width: 30%;
		flex-grow: 1;
		.Input__input { 
			border-radius: 0 4px 4px 0;
		}
	}
}