@import "~StyleVariables";

.SubscriptionHistory {
	display: flex;
	justify-content: center;
	padding-bottom: 60px;

	&__content {
		margin-top: 32px;
		width: 950px;
	}

	.Table {
		margin-top: 24px;

		.btn-link {
			padding: 0;
		}
	}

	.TableRow--disabled {
		background-color: $grey-1;
	}

	.TableHeader__column {
		&:after {
			background-color: $grey-6;
		}

		&:nth-child(1) {
			width: 180px;
		}

		&:nth-child(2) {
			width: 330px;
		}

		&:nth-child(3) {
			width: 220px;
		}

		&:nth-child(4) {
			width: 200px;
		}

		&:nth-child(5) {
			width: 20px;
		}
	}

	&__year {
		background-color: $grey-2;

		&__icon {
			animation: rotateBackAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(0deg);

			&--rotate {
				animation: rotateAnimation;
				animation-iteration-count: 1;
				animation-duration: 400ms;
				transform: rotate(180deg);
			}
		}
	}

	&__type {
		.Text {
			display: inline;
		}
		.Link {
			padding-left: 4px;
		}
	}

	&__user {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}
