@import "../../../styles/variables";

.FullTermsModal {
	.html-wrapper {
		overflow-x: hidden;
		overflow-y: auto;
		height: auto;
	}
}

.html-wrapper {
	margin: 0 auto;
	padding-top: 50px;
	overflow-y: scroll;
	height: calc(100vh - 205px);
}

.html-content,
.html-content-price {
	margin: 0 auto;
	width: 500px;
}

.html-content-price {
	margin: 25px auto;
}

.html-content h1 {
	font-weight: $boldFontWeight;
}

.html-content h5 {
	font-size: 14px;
}

.html-content p {
	margin: 1em 0 1.5em;
	font-size: 16px;
	line-height: 1.5em;
}

.html-content ul {
	margin: 20px 0;
}

.accept-row {
	text-align: center;
	padding: 60px;
	border-top: 1px solid #b2becf;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}
