@import "~StyleVariables";

.PeriodizationChart {
	width: 205px;

	.highcharts-container {
		width: 100%;
	}
}

.PeriodizationChart__header {
	height: 30px;
	width: 205px;

	.PeriodizationChart__title {
		margin-top: 8px;
		margin-left: 2px;
		font-weight: $boldFontWeight;
	}

	.PeriodizationChart__edit-button {
		height: 25px;
	}
}

.PeriodizationChart__legend {
	text-align: center;
	text-transform: uppercase;
	font-size: 10px;
	color: #4b5562;
}

.PeriodizationChart__date-label {
	position: absolute;
	left: 0;
	margin-left: -20px;
	width: 40px;
	top: 20px;
}

.PeriodizationChart__date-label .Text {
	line-height: 12px;
}

.PeriodizationChart__value-label .Text {
	font-size: 10px;
}

.PeriodizationChart__tooltip {
	background-color: $black;
	text-align: center;
	padding: 5px;
}

.PeriodizationChart__tooltip .Text {
	line-height: 12px;
}
