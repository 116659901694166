@import "~StyleVariables";

.SplitSubscriptionPage {
	padding: 24px;
	position: absolute;
	width: 100%;
	opacity: 0;
	animation: showPage 0.5s cubic-bezier(0.45, 0.71, 0.56, 1.25) forwards;

	&__headline {
		position: relative;

		.Icon {
			transition: all 0.4s ease;
			position: absolute;
			font-size: 24px;
			top: 16px;
			opacity: 0;
		}

		&__text {
			transition: all 0.4s ease;
			> .Headline {
				position: absolute !important;
				top: 8px;
				opacity: 1;
			}
			> div {
				transition: all 0.4s ease;
				opacity: 0;
			}

			&--lastStep {
				transform: translateX(36px);
				> .Headline {
					opacity: 0;
				}
				> div {
					opacity: 1;
				}
			}
		}

		&--backButton {
			cursor: pointer;
			.Icon {
				opacity: 1;
			}
		}
	}

	&__boxes {
		transition: opacity 0.3s ease;
		position: absolute;
		margin-top: 12px;
		max-width: 930px;
		width: stretch;

		&--hide {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}

	&__boxes-grid {
		width: stretch;
		display: grid;
		grid-gap: $space * 3;
		grid-template-columns: repeat(auto-fit, 302px);

		.Hotspot {
			align-self: center;
			& > .Block {
				margin: 0;
			}
		}
	}

	&__bottom {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
		margin-top: 24px;
	}

	@keyframes showPage {
		from {
			transform: translateY(-300px);
			opacity: 0;
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}
}
