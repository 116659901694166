@import "~StyleVariables";

.MailTemplatePreviewImage {
	line-height: 1;

	&--broken {
		background-color: rgba($red, 0.1);

		img {
			display: none;
		}
	}

	&__broken-info {
		text-align: center;

		.Icon {
			color: $red;
			font-size: 16px;
		}
	}

	.TextEditor {
		.cke_editable {
			background-color: transparent;

			&:focus {
				outline: none;
			}
		}
	}

	img {
		display: inline-block;
	}
}
