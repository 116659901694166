@import "~StyleVariables";

.EditSocialEventShareForm {
	.SidebarWidgetTitle {
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		overflow: hidden;
		max-width: 150px;
	}

	&__ShareButton {
		.DropDownMenu__content {
			min-width: 200px;
		}

		.TableRow {
			&.facebook {
				.TableColumn {
					border-top: none;
				}
			}
		}

		.TableColumn {
			padding-left: 10px;
			padding-right: 10px;
		}

		.Icon {
			margin-right: 5px;
		}
	}

	&__Copy {
		&--copied {
			margin: 0;
			float: right;
			color: $bright-green;
		}
	}
}
