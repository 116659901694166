@import "~StyleVariables";

.EditClientTarget {
	width: 800px;
	height: 100%;

	overflow-y: auto;

	background-color: $white;

	&__body {
		display: flex;
		flex-direction: column;
		gap: 20px;

		padding: 40px;
	}

	&__budegetInputs {
		z-index: 0;

		.NumberInput {
			display: inline-block;
		}
		.Tooltip {
			margin-bottom: 4px;
		}
	}

	.Select__dropdown {
		width: 100px !important;
	}

	&__help {
		.Text {
			display: inline;
		}

		.Help {
			display: inline;
			margin-left: 4px;
			color: $black;
		}
	}

	&__options {
		display: flex;
		justify-content: space-between;

		&__buttonSelects {
			display: flex;
			gap: 8px;
		}
	}

	.Animate {
		position: absolute;
		width: 100%;
		z-index: 2;
	}

	&__warning {
		padding: 16px;
		position: absolute;
		z-index: 1;
		border-radius: $border-radius;
		box-shadow: $box-shadow-xl;

		&__text {
			margin-bottom: 8px;

			> .Text {
				display: inline;
				padding-left: 4px;
			}
		}
	}
}
