@import "~StyleVariables";

.ReportcenterWidgetInfo {
	margin-right: $space * 4;

	.Icon {
		height: 50px;
		display: flex;
		align-items: center;
	}
}
