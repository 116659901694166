@import "~StyleVariables";

.CriteriaDrilldownWrapper--open.ProspectingDrilldown--cpvcode .drilldown-animation-wrapper {
    max-height: 500px;
}

.ProspectingDrilldown--cpvcode {
    
    table {
        max-width: 100%;
    }

    .ProspectingDrilldown__table {
        width: 100%;
        table-layout: fixed;
    }
}

.ProspectingDrilldown--cpvcode .ProspectingDrilldown__table {
    width: 100%;
}

.IndustryCPVInput {
    &__alternative-button {
        font-size: 14px;
        padding: 10px $space * 4;
        line-height: 1;
    }
}

.IndustryCPVRow {
    height: 30px;
    background-color: $white;
    border: 1px solid $grey-4;
    border-bottom: none;

    &:last-child {
        border-bottom: 1px solid $grey-4;
    }

    .Column:not(:last-child) {
        padding-left: 10px;
    }

    &--child {
        background-color: $grey-1;
    }

    &--level-0 {
        .Column:first-child { padding-left: 8px; }
        .text-ellipsis { padding-left: 4px; }
    }
    
    &--level-1 {
        .Column:first-child { padding-left: 36px; }
        .text-ellipsis { padding-left: 32px; }
    }
    
    &--level-2 {
        .Column:first-child { padding-left: 64px; }
        .text-ellipsis { padding-left: 60px; }
    }
    
    &--level-3 {
        .Column:first-child { padding-left: 92px; }
        .text-ellipsis { padding-left: 88px; }
    }
    
    &--level-4 {
        .Column:first-child { padding-left: 120px; }
        .text-ellipsis { padding-left: 116px; }
    }
}
