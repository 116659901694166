@import "~StyleVariables";

.TodoListSidebar {
	height: 100%;
	width: 240px;
	padding-bottom: 20px;
	overflow-y: auto;
	background-color: $grey-1;

	&__red-item.TodoListSidebarItem {
		.Text,
		.Title {
			color: $red !important;
		}
	}
	&__leads-section,
	&__no-overdue,
	&__call-list-section {
		> .Text {
			padding-left: 20px;
			line-height: 24px;
			color: $grey-11;
		}
		box-shadow: inset 0px 1px $grey-4;
	}

	.Row > .Icon {
		line-height: 18px;
	}
}
