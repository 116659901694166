@import "~StyleVariables";

.FileStorageView {
	width: 100%;

	iframe {
		background-color: $white;
		height: 400px;
		width: 100%;
		border: none;
	}

	&__noPermission {
		display: flex;
		padding: 30px;
		background-color: $white;

		.messageWrapper {
			margin-left: 35px;
			.text {
				margin-top: 10px;
				max-width: 500px;
			}
		}
	}
}
