.WebLinks {
	.Link {
		margin: 0 10px 10px 0;
		display: inline-block;

		.Icon {
			margin-right: 10px;
		}
	}
}
