@import "~StyleVariables";

.EditClient {
	&__active-toggle {
		display: flex;
		align-items: center;

		.Text {
			margin-right: 4px;
		}
	}

	.select2-container {
		width: 100%;
		display: inline-grid;

		&.select2-container-multi .select2-choices .select2-search-field {
			max-width: 300px; /* Select2 is stupid */
		}
	}

	.ModalContent {
		padding-bottom: 80px;
	}

	.ModalContent .Row {
		flex-wrap: wrap;
	}

	.ModalHeader {
		border-top: none;
		z-index: 1001;
	}

	.CustomFields__content {
		flex-wrap: wrap;
		flex-direction: row;
		display: flex;
		gap: 14px;

		.FormComponent__label {
			font-weight: $regularFontWeight;
		}

		& > * {
			flex: 0 0 49%;
		}
	}

	.CustomFields__formGroupTitle,
	.CustomFields__formGroupContent {
		margin-bottom: 10px;
	}

	&__spacer {
		flex: 0 0 14px;
	}

	&__half-col {
		flex: 0 0 49%;
	}

	&__required-title {
		display: inline-block;
		margin-left: 5px;
	}

	.NumberInput {
		.Input {
			width: 100%;
		}
	}

	.Checkbox {
		&--disabled {
			background-color: $grey-4;
		}
	}
}
