@import "~StyleVariables";

.BarchartWidgetBar {
	$barHeight: 22px;
	$barHeightSm: 16px;

	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -($barHeight / 2);
	height: $barHeight;
	overflow: hidden;

	.Text {
		white-space: nowrap;
		padding: 0 7px;
		line-height: $barHeight;
		color: $white;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		text-align: right;
		position: relative;
		z-index: 1000;
	}

	&--roundedLeft {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	&--roundedRight {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&--future.Block--bright-blue {
		background-color: lighten($bright-blue, 20%);
	}

	&--small {
		height: $barHeightSm;
		margin-top: -($barHeightSm / 2);

		&--future {
			height: $barHeightSm;
		}
	}

	&--loading {
		background-color: $grey-3;
		width: 83% !important;
	}

	&--negative {
		background-color: $red;
	}
}
