@import "~StyleVariables";

.ListMail {
	&__templateButton {
		padding: 0;
		.Button {
			display: flex;
			align-items: center;
			padding: 14px 12px;
		}
	}
}
