@import "~StyleVariables";
.FileBrowserFilters {
	.Label {
		margin-top: 15px;
		font-weight: $boldFontWeight;
	}

	.select2-container {
		display: block;
	}
}
