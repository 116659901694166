@import "~StyleVariables";

.LostOpportunityModal {
	height: 100%;
	width: 95%;
	/** 
	* @todo  @upsales/components 2.18.5
	* STYLING: Legacy border should be removed
	* @link https://github.com/upsales/ui-components/issues/539
	*/
	.ModalHeader {
		border-top: 0;
	}
}
