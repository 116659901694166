@import "~StyleVariables";
@import "~StyleAnimations";

.CreateTodo {
	$width: 500px;

	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: $grey-1;
	overflow-y: auto;

	&--triggerAction {
		width: $width;
	}

	form {
		height: 100%;
	}

	.Label {
		font-weight: $boldFontWeight;
	}
	.TodoCheckboxCard {
		margin-bottom: 12px;
	}
	&__relationSection {
		// otherwise dropdown will appear behind other toggles
		z-index: 3;

		& > .ClientContactFollowupSelect {
			position: relative;
			z-index: 1;
		}
	}
	&__toggleLabel {
		display: flex;
		align-items: center;
		&--middle {
			margin: 12px 0;
		}
	}
	&__contentWrapper {
		width: $width;
		display: flex;
		flex-direction: column;
	}
	&__customFieldButtonWrapper {
		height: 100%;
	}
	&__buttonWrapper {
		display: flex;
		flex-direction: column;
		top: auto;
		position: sticky;
		bottom: 0;
		z-index: 4;
		margin-top: auto;
		box-shadow: 0 0.5px 5px 0 rgba($black, 0.2);

		&--cannotSave {
			.Button:nth-child(2),
			.Tooltip:nth-child(2) .Button {
				height: 0;
				margin: 0;
				padding: 0;
				overflow: hidden;
			}
		}
		&--canSave {
			position: sticky;
			bottom: 0;
			.Button:nth-child(2),
			.Tooltip:nth-child(2) {
				margin-top: $space * 4;
				overflow: hidden;
			}
		}
	}
	&__enterText {
		height: 20px;
	}
	&__dynamicOptions {
		margin-top: 8px;
	}
	.DrawerHeader {
		position: sticky;
		top: 0;
		z-index: 4;
	}
	&__cancel-button {
		align-self: center;
		width: 70px;
		height: 100%;
	}
	.Progress {
		height: 15px;
		width: 15px;
	}
	&__goalLabel {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		> .Label:first-child {
			display: flex;
			align-items: center;
		}
	}
	&__availableUserLabel {
		margin-top: 10px;
	}

	.TodoTimePicker__content {
		z-index: 1 !important;
	}

	.DrawerHeader {
		.Icon-activity {
			font-size: 18px;
		}
		.Title {
			font-size: 16px;
		}
		.Button {
			margin-right: 10px;
			align-self: center;
		}
		.HeaderUserSelect + .Button {
			margin-right: 0px;
		}
	}

	$taglist-width: 280px;

	&__tag-wrap {
		position: fixed;
		top: 0;
		height: 100vh;
		right: -$taglist-width;
		width: $taglist-width;
		z-index: 10000;

		@include transition(all ease-out 300ms);
		
		&--visible {
			right: 0;
			visibility: visible;
		}

		#tag-list {
			width: $taglist-width;

			#hide-tags-btn {
				position: absolute;
				top: 5px;
				right: 10px;
			}

			> div,
			ul,
			h2,
			p {
				width: $taglist-width !important;
			}
		}
	} 

	.TriggerDatePicker {
		z-index: 1;
	}

	.NotesWithSignature {
		z-index: 0;
	}

	.CustomFields {
		.Input {
			width: 100%;
		}
	}
}
