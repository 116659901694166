.si-sidebar-widget {
	h2 {
		margin: 0;
		padding: 0;
		color: $black;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
	}
}

.si-modal-iframe {
	min-height: 60vh;
}

.si-modal-iframe,
.si-sidebar-iframe,
.si-modal-iframe-in-frame {
	border: none;
	width: 100%;
}

.si-modal-iframe {
	height: 100%;
}

.si-modal-iframe-in-frame {
	height: 100%;
}

.si-modal-iframe-wrap {
	position: absolute;
	top: 46px;
	bottom: 0;
	left: 0;
	right: 0;
}
