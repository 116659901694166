@import "~StyleVariables";

.FullscreenModal > .YourYearWithUpsales {
	position: absolute;
}

.YourYearWithUpsales {
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	transition: background-color ease-out 300ms;
	cursor: pointer;

	&__close {
		$size: 40px;
		width: $size;
		height: $size;
		line-height: $size;
		position: absolute;
		right: 24px;
		top: 24px;
		z-index: 2;
		background-color: rgba($grey-1, 0.6);
		font-size: 18px;
		border-radius: $border-radius;
		cursor: pointer;
		text-align: center;
		transition: all ease-out 300ms;

		&:hover {
			background-color: $grey-1;
		}
	}

	.Loader {
		margin-top: 100px !important;
		margin: auto;
	}

	&__screen {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		&:not(.Animate--visible) {
			z-index: -1;
		}
	}
}
