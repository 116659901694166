.EditEventDomain .Row {
	margin: 0 -10px;
}

.EditEventDomain .Column {
	padding: 0 10px 10px 10px;
}

.EditEventDomain__help {
	display: inline-block;

	.Text {
		display: inline-block;
	}
	.Icon {
		margin-right: 6px;
	}
}
