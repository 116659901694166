.AskForInput {
    &__validationErrors {
        padding: 0 12px;
        margin: 0;
    }

    &__validationError {
        margin: 0;
        padding: 0;
    }
}