.RelationSelectRelations {
	&__labelSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		> .Label {
			display: inline-flex;
		}
		.Button {
			padding: 0px;
		}
	}
}
