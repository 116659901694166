@import "~StyleVariables";

.BouncyThumbtack {
	display: flex;
	justify-content: center;
	align-items: center;
	.Icon {
		transition: all ease-out 200ms;
	}
	:hover {
		cursor: pointer;
		color: $grey-10;
	}

	&__inactive {
		color: $grey-8;
		&:hover {
			transform: translateY(-4px);
		}
	}
}
