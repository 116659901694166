#general-start-root {
	.general-start {
		max-width: 944px;
		margin: 0 auto;

		.top {
			position: relative;
			background-color: $white;
			box-shadow: 0 1px 5px rgba($black, 0.1);
			border-radius: 3px;
			overflow: hidden;
			transition: height 500ms ease;
			display: block;
			width: 100%;
			margin: 0 auto;

			.product-column {
				width: 33.33333%;
				float: left;
				display: block;
				position: relative;

				> h4 {
					text-align: center;
					color: $grey-11;
				}

				.product-column-inner {
					height: 100%;
				}

				.product-column-header {
					height: 200px;
					text-align: center;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;

					.header-img {
						opacity: 0.2;
						position: absolute;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: cover;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 5;
					}

					.header-inner {
						position: relative;
						z-index: 10;

						.active-tag {
							background: rgba($white, 0.7);
							padding: 3px 8px;
							border-radius: 3px;
							font-size: 11px;
							text-transform: uppercase;
							line-height: 1;

							&.crm {
								color: $blue;
							}

							&.ma {
								color: $purple;
							}

							&.followup {
								color: $medium-blue;
							}
						}
					}

					&.crm {
						background-color: $blue;
					}

					&.ma {
						background-color: $purple;
					}

					&.followup {
						background-color: $medium-blue;
					}

					h4 {
						font-size: 24px;
						text-align: center;
						color: $white;
					}

					i.fa {
						color: $white;
						font-size: 36px;
					}

					p {
						text-align: center;
						font-size: 12px;
						color: $white;
						padding: 0 20px;
						line-height: 20px;
						width: 300px;
					}
				}

				.upgrade-btn {
					position: absolute;
					top: 180px;
					left: 50%;
					margin-left: -120px;
					width: 240px;
					box-shadow: 0 10px 20px rgba($black, 0.2);
					z-index: 1000;
				}

				.list-holder {
					padding: 35px;
					border-right: 1px solid $grey-7;
				}

				&:last-child {
					.list-holder {
						border-right: none;
					}
				}

				.top-item {
					margin: 15px 0;
					cursor: pointer;
					width: 100%;
					position: relative;
					clear: both;
					@include transition(color ease 200ms);

					&.inactive {
						h5,
						i {
							color: $grey-10;
						}
					}

					&.non-clickable {
						cursor: default;
					}

					h5 {
						font-weight: $regularFontWeight;
						font-size: 14px;
						line-height: 19px;
						margin: 0;
						display: inline-block;
						color: rgba($bright-blue, 0.8);
					}

					i {
						margin-right: 30px;
						color: rgba($bright-blue, 0.8);
						line-height: 19px;
						width: 20px;
						text-align: center;
					}

					&:hover:not(.non-clickable) {
						h5,
						i {
							color: $bright-blue;
						}
					}
				}

				&.inactive {
					.product-column-header {
						background: $white !important;
						box-shadow: 0 1px 0 rgba($black, 0.1);
						border-radius: 0;

						h4,
						i.fa,
						p {
							color: $dark-blue;
						}
					}

					.list-holder:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: $grey-1;
						box-shadow: inset 0 0 35px rgba($black, 0.1);
					}

					.top-item {
						h5,
						i {
							color: $grey-10;
						}

						&:hover {
							h5,
							i {
								color: $black;
							}
						}
					}
				}

				&.full-width {
					width: 20%;
					float: left;
					padding: 0;

					&:after {
						clear: both;
						content: "";
						display: block;
					}

					.top-item {
						width: 100%;
					}
				}

				&.row-1 {
					width: 100%;
					padding: 0 40px;
					display: flex;
					border: none;
					justify-content: center;

					.top-item {
						align-items: center;
						flex-wrap: wrap;
						flex-direction: row;
						display: flex;
						justify-content: center;

						> i {
							flex: 0 1 100%;
						}
					}
				}

				&.single-row {
					width: 100%;
					margin: 0;
					padding: 20px 40px;
					display: flex;
					justify-content: center;

					.top-item {
						text-align: center;
						width: 145px;
						float: left;
						margin: 15px 0;
						height: 60px;
						vertical-align: middle;
						display: flex;
						align-items: center;
						max-width: none;

						.valign {
							max-width: 100px;
							margin: 0 auto;
						}
					}
				}

				.flxbxwrpr {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;

					.top-item {
						justify-content: center;
						align-items: center;
						flex: 0 1 auto;
					}
				}
			}

			.startpage-picker {
				width: 100%;
				clear: both;
				padding: 20px 5px;
				box-shadow: 0 -1px 0 rgba($black, 0.1);
				// border-top: 1px solid $grey-7;

				> span {
					margin-right: 10px;
					font-weight: normal;
				}

				&:after {
					clear: both;
					content: "";
					display: block;
				}

				.col-md-6 {
					p {
						margin: 0;
					}

					.col-md-6 {
						div,
						button {
							margin-top: 2px;
							width: 100%;
						}
					}
				}
			}
		}

		.low-row {
			margin-left: -10px;
			margin-right: -10px;

			.row {
				margin-left: -10px;
				margin-right: -10px;
			}

			.col-md-6,
			.col-md-12 {
				padding-left: 10px;
				padding-right: 10px;
			}

			.card {
				background: $white;
				padding: 32px;
				border-radius: 3px;
				box-shadow: 0 1px 5px rgba($black, 0.1);
				margin: 20px 0 0;
				position: relative;
				overflow: hidden;

				h2,
				h3 {
					margin: 0;
					font-size: 24px;
					line-height: 32px;
					color: $dark-blue;
					font-family: "Gotham Book", "Roboto", sans-serif;
					font-weight: $regularFontWeight;

					strong {
						display: block;
					}
				}

				p {
					margin: 10px 0 15px 0;
					line-height: 22px;
					font-size: 15px;
					color: $grey-11;
				}

				button {
					min-width: 300px;
					text-align: center;
				}

				.is-background {
					opacity: 0.05;
					z-index: 4;
					position: absolute;
					right: -80px;
					top: 20px;
					font-size: 200px;
				}

				&.audience-bg {
					background: url("../img/audience.jpg") no-repeat;
					background-size: cover;
					background-position: center center;

					h2,
					h3,
					p {
						color: $white;
					}

					button {
						background: $white;
						color: $blue;
						border: none;
						text-shadow: none;
					}
				}

				&.center {
					h2,
					h3,
					p {
						text-align: center;
					}

					> p {
						font-size: 18px;
						line-height: 26px;
						margin: 20px auto 15px;
						width: 360px;
					}

					h2 {
						font-size: 34px;
						line-height: 40px;
						width: 100%;
						font-weight: $regularFontWeight;
					}

					button {
						width: 360px;
						margin: 30px auto 0;
						display: block;
					}

					.support {
						margin-top: 95px;

						h4 {
							font-weight: $boldFontWeight;
							text-align: center;
							font-size: 20px;
							line-height: 36px;
						}

						p {
							text-align: center;
							font-size: 14px;
							margin: 5px 0 10px;
							line-height: 1em;

							.text-green {
								margin-left: 3px;
							}

							.text-grey {
								margin-right: 3px;
								color: $grey-11;
							}
						}

						a {
							font-size: 16px;
							line-height: 29px;
						}

						.row {
							.col-md-6 {
								&:first-child {
									text-align: right;
									border-right: 1px solid $grey-8;
								}
							}
						}
					}
				}
			}

			.col-md-12 {
				h2,
				h3,
				p,
				button {
					max-width: 320px;
					position: relative;
					z-index: 10;
				}
			}
		}

		.sub {
			margin: 40px auto;
			width: 940px;

			&:after {
				clear: both;
				content: "";
				display: block;
			}

			.column {
				width: 50%;
				float: left;
				border-right: 1px solid $grey-8;
				padding: 0 20px;
				padding-left: 0;

				> p {
					margin: 1em 0;
					color: $grey-11;
				}

				& + .column {
					border-right-color: transparent;
					padding-right: 0;
					padding-left: 20px;
				}

				#account-manager-card {
					$padding: 15px;
					background-color: $white;
					border-radius: 3px;
					margin: 20px 0;
					padding: $padding;
					@include box-shadow(0 2px 1px rgba($black, 0.1));
					font-size: 12px;

					#manager-avatar-wrap {
						height: 210px;
						position: relative;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: cover;
						margin: -$padding;
						margin-bottom: $padding;
						color: $white;
						text-align: center;

						.blue-overlay {
							padding: 30px;
							font-weight: $regularFontWeight;
							font-size: 16px;
						}

						.manager-avatar {
							display: block;
							margin: 15px auto 5px auto;
							height: 50px;
							@include border-radius(50%);
							@include box-shadow(0 1px 3px rgba($black, 0.2));
						}

						.manager-name {
							font-size: 12px;
						}

						a {
							color: $white;
							font-size: 10px;
						}
					}

					h3 {
						margin: 0;
						font-weight: normal;
						font-size: 18px;
					}

					a {
						display: block;
					}

					& > span {
						line-height: 30px;
					}

					& > a {
						line-height: 20px;
						font-size: 12px;
					}
				}

				h2 {
					color: $grey-11;
				}

				h3 {
					font-size: 18px;
					font-weight: $regularFontWeight;
					color: $grey-11;
				}

				.course-item {
					width: 47%;
					float: left;
					margin-right: 3%;
					margin-bottom: 3%;

					img {
						width: 100%;
					}

					a {
						text-decoration: none;
					}

					.inner-course {
						padding: 10px;
						background: $white;

						span.course-title {
							display: block;
							font-size: 15px;
							line-height: 1.2em;
							color: $grey-11;
						}

						.date-wrap {
							font-size: 13px;
							margin: 0.5em 0 0 0;
							color: $grey-11;
						}
					}
				}
			}

			&.w60-40 {
				&.column:first-child {
					width: 60%;
				}

				&.column:last-child {
					width: 40%;
				}
			}
		}
	}
}

#product-placeholder-root {
	#product-placeholder-inner {
		$margin: 15px;
		position: fixed;
		left: $sidebar-width + $margin;
		right: $margin;
		bottom: $margin;
		top: $navbar-height + $sub-nav-height + $margin;
		// max-width: 845px;
		// margin: 0 auto;
		background-color: $white;
		@include border-radius(3px);
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			border: none;
		}
	}
}

.onboarding-placeholder-header {
	$manager-width: 250px;
	position: relative;
	background-color: $bright-blue;
	color: $white;
	padding: 20px;
	@include box-shadow(0 1px 5px rgba($black, 0.1));

	h2 {
		margin: 0;
		padding: 0;
		color: $white;
		font-size: 18px;
		font-weight: $regularFontWeight;
		margin-bottom: 10px;
	}

	p {
		margin: 0;
		padding: 0;
		font-weight: $regularFontWeight;
		font-size: 12px;
	}

	#manager-container {
		color: rgba($white, 0.7);
		width: $manager-width;
		font-size: 10px;

		h3 {
			margin: 0;
			padding: 0;
			color: $white;
			font-size: 14px;
			font-weight: $regularFontWeight;
			display: inline-block;
		}

		a {
			color: inherit;
			font-size: 12px;
		}

		button {
			margin-top: 10px;
		}
	}
}

#feature-placeholder-root {
	#feature-placeholder-inner {
		$headerHeight: 50px;

		position: absolute;
		right: 16px;
		left: 15px;
		bottom: 15px;
		top: 0;
		@include box-shadow(0 1px 5px rgba($black, 0.1));
		@include border-radius(3px);
		overflow: hidden;
		background-color: $grey-4;

		.up-header {
			height: $headerHeight;

			#fake-view-picker {
				height: $headerHeight - 4px;
				line-height: $headerHeight - 4px;
				display: inline-block;
				border-right: 1px solid $grey-4;
				vertical-align: top;
				padding: 0 10px 0 20px;
				font-size: 16px;

				.fa {
					font-size: 14px;
					margin-left: 10px;
					color: $grey-10;
				}
			}
		}

		#card-main-view {
			position: absolute;
			top: $headerHeight;
			bottom: 0;
			left: 0;
			right: 0;

			#frame-wrap {
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				height: 100%;
				overflow: hidden;
				overflow-y: scroll;
				z-index: 1000;
				@include transition(top ease 300ms);

				&.visible {
					top: 0;
				}

				#frame-inner-wrap {
					width: 800px;
					margin: 50px auto;
					@include box-shadow(0 1px 5px rgba($black, 0.1));
					@include border-radius(3px);
					overflow: hidden;

					iframe {
						width: 100%;
						padding: 0;
						margin: 0;
						border: none;
					}
				}
			}
		}
	}
}

#table-onboarding {
	position: relative;
	background-color: $grey-4;

	#onboarding-content {
		position: relative;
		display: block;
		width: 600px;
		margin: 0 auto;
		color: $grey-13;
		padding-bottom: 40px;
		padding-top: 30px;
		text-align: center;

		#onboarding-video {
			margin-bottom: 20px;

			iframe {
				@include box-shadow(0 10px 40px rgba($black, 0.2));
			}
		}

		h2 {
			margin-bottom: 20px;
			font-size: 20px;
		}

		p {
			color: $grey-11;
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 20px;
		}
	}

	#create-tooltip {
		@include keyframes(tooltipBounce) {
			0% {
				margin-top: 0;
			}
			15% {
				margin-top: 5px;
			}
			50% {
				margin-top: 0;
			}
			100% {
				margin-top: 0;
			}
		}
		@include keyframes(tooltipSalesboardBounce) {
			0% {
				margin-left: 0;
			}
			15% {
				margin-left: 5px;
			}
			50% {
				margin-left: 0;
			}
			100% {
				margin-left: 0;
			}
		}

		position: fixed;
		background-color: $dark-blue;
		color: $white;
		@include border-radius(3px);
		padding: 7px 20px;
		font-size: 12px;
		@include box-shadow(0 10px 20px rgba($black, 0.1));
		z-index: 1001;
		min-width: 100px;
		text-align: center;
		@include animation(tooltipBounce 2s ease infinite);

		&:before {
			content: "";
			width: 0px;
			height: 0px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid $dark-blue;
			position: absolute;
			top: -5px;
			left: 50%;
			margin-left: -5px;
		}

		&.salesboard {
			z-index: 1030;
			@include animation(tooltipSalesboardBounce 2s ease infinite);

			&:before {
				left: initial;
				right: -10px;
				top: 50%;
				margin-top: -5px;
				margin-left: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid $dark-blue;
				border-right: 5px solid transparent;
			}
		}
	}
}

#onboarding-fake-table {
	position: absolute;
	top: 0;

	#fake-table {
		tr {
			height: 38px;
			border-bottom: 1px solid $grey-4;

			td {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				padding: 0 5px 0 20px;
				font-size: 12px;

				.subtitle {
					text-transform: uppercase;
					color: $grey-10;
					font-size: 10px;
					display: block;
				}
			}
		}
	}

	#fake-table,
	#fake-salesboard {
		width: 100%;
		background-color: $white;
		table-layout: fixed;
		@include opacity(0.8);

		.href {
			color: $bright-blue;
		}
	}

	#fake-salesboard {
		.header-row {
			height: 60px;
			text-align: center;
			color: $grey-10;
			text-transform: uppercase;
			font-size: 10px;

			.money {
				color: $black;
				font-size: 14px;
				display: block;
			}
		}

		.cards-row {
			background-color: $grey-4;

			td {
				padding: 10px;
				vertical-align: top;
			}
		}
	}

	#gradient-overlay {
		position: absolute;
		top: 0;
		height: 120px;
		left: 0;
		right: 0;
		@include linear-gradient(rgba($grey-4, 0.2), $grey-4, 180deg);
	}
}

#onboarding-video-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $blue;
	z-index: 10000;
	visibility: visible;
	@include transition(all ease 500ms);
	@include opacity(1);

	&.done {
		visibility: hidden;
		@include opacity(0);
	}

	&.video-active {
		.wistia_embed {
			@include opacity(1);
		}

		.video-info-wrap {
			visibility: hidden;
			@include opacity(0);
		}
	}

	.wistia_embed {
		width: 100%;
		height: 100%;
		@include opacity(0.2);
		@include transition(all ease 200ms);
	}

	#dismiss-movie-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		visibility: hidden;
		@include opacity(0);
		@include transition(all ease 200ms);

		&.btn-visible {
			visibility: visible;
			@include opacity(1);
		}
	}

	.video-info-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 500px;
		margin-left: -250px;
		color: $white;
		visibility: visible;
		@include opacity(1);
		@include transition(all ease 200ms);

		& > h3 {
			margin: 0;
			padding: 0;
			font-size: 14px;
			font-weight: normal;
			text-transform: uppercase;
			letter-spacing: 3px;
			color: $grey-10;
		}

		& > p {
			margin: 0;
			font-size: 40px;
			padding: 0;
			line-height: 45px;
			margin-top: 10px;
		}

		& > button {
			margin-top: 10px;
		}
	}
}
