@import "~StyleVariables";

#billing-addon-modal {
	position: relative;
	width: auto;
	height: auto;
	margin: 0 0;
	vertical-align: top;
	overflow: hidden;
	background-color: $white;
	z-index: 10002;

	.ModalControls {
		text-align: center;
		justify-content: center;
		align-items: center;
		position: relative;
		bottom: 0;
		padding: 40px;
		height: 150px;
	}

	.ModalContent {
		border: none;
		padding: 0;
		margin: 0;
		position: relative;
		display: inline;
	}

	.priceblock {
		position: absolute;
		margin: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 700px;
		height: auto;
		margin: 0 auto;
	}
	.price-row {
		padding: 15px;
	}

	.price-col {
		justify-content: left;
		align-items: left;
		display: grid;
		.Text {
			display: flex;
			align-items: center;
			padding-right: 5px;
		}
	}

	.button-col {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
	}

	.container {
		border: none;
		padding: 0;
		margin: 0 auto;
	}

	.pdf-content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 290px);
		position: relative;
		.pdf {
			position: absolute;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			border: 0;
		}
	}
}
