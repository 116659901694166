@import "~StyleVariables";

.ActivityOutcomes {
	border-radius: $border-radius;
	border: 1px solid $grey-5;
	width: 100%;
	background: $white;

	&__front {
		z-index: 1;
	}

	.Block {
		border-radius: $border-radius;
	}

	&__comment-section {
		display: none;
	}

	.CardHeader {
		min-height: 40px;
		padding: 0 15px;

		.Title {
			line-height: 24px;
		}

		&--has-subtitle {
			.CardHeader__title-wrap {
				padding-bottom: 10px;

				.Title {
					padding: 10px 0 0;
					line-height: 24px;
				}
			}
		}
	}

	&__main-header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		align-items: center;

		.CardHeader__title-wrap {
			max-width: 90%;
		}
	}

	&__submit-btn {
		.submit-btn-text {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-direction: column;
		}

		margin: 15px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;

		.Button {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: row;
			gap: 16px;
		}

		.btn-subtitle {
			padding: 12px 0px;
		}
	}

	&__active-header {
		transition: background-color 200ms ease-out;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;
		gap: 16px;
		cursor: pointer;

		&:hover {
			transition: none;
			background-color: $grey-2;
		}

		.Button {
			padding: 0px 5px;
		}

		.Icon {
			font-size: 16px;
			font-weight: $regularFontWeight;
			line-height: 16px;
		}

		&--visible {
			padding: 15px;
		}
	}

	.Card:not(.BorderBox):not(:last-child) {
		box-shadow: none;
	}

	.CardContent {
		padding: 0 15px;
	}

	&__outcome-types {
		display: flex;
		align-items: center;
		justify-content: stretch;
		margin-bottom: 15px;
		gap: 12px;

		.OutcomeTypeButton {
			flex-grow: 1;
		}
	}

	.CardHeader__actions {
		height: 40px;
		line-height: 40px;

		.Icon-info-circle {
			font-size: 14px;
		}
	}

	&__action-row {
		max-height: 0;
		transition: max-height ease-in 200ms;

		&--visible {
			max-height: 750px;

			.ActivityOutcomes__comment-section {
				display: block;
				margin-bottom: 8px;
				position: relative;
				z-index: 1;
			}
		}

		.Title {
			margin-bottom: 10px;
		}
	}

	&__sub-action {
		padding-bottom: 10px;
	}

	&__sub-action-btn {
		margin: 0 10px 10px 0;

		.Text {
			display: inline-block;
		}

		.Icon--10 {
			padding-right: 10px;
		}
	}

	&__animation-opacity-enter,
	&__animation-opacity-exit-done,
	&__animation-opacity-exit-active {
		opacity: 0;
	}

	&__animation-opacity-enter-active {
		opacity: 1;
	}

	&__animation-opacity-enter-done {
		opacity: 1;
	}

	&__animation-opacity-enter-active {
		transition: opacity 300ms ease-in;
	}

	&__animation-opacity-exit-active {
		transition: opacity 200ms ease-in;
	}

	.ml-10 {
		margin-left: 10px;
	}

	.mr-10 {
		margin-right: 10px;
	}

	.mb-10 {
		margin-bottom: 10px;
	}

	&__floating-action {
		display: flex;
		width: 300px;
		align-items: center;
		justify-content: space-between;
	}

	&__sub-action-btn:hover {
		.Text--green,
		.Icon--green {
			color: $white;
		}
	}

	.no-padding {
		padding: 0;
	}

	.no-margin {
		margin: 0;
	}

	.pt-10 {
		padding-top: 10px !important;
	}
}
