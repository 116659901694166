@import "~StyleVariables";

.AgreementHistoryFooter {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__textContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	&__leftSide,
	&__rightSide {
		display: flex;
		align-items: center;
	}

	&__clockIcon {
		font-size: 1.7rem;
	}
}
