@import "../../../styles/variables";

.DomainTable__records-table tr > :first-child {
	&:not(.DomainTable__verify-row) {
		width: 5px;
		padding: 0 0 0 5px;
	}

	&.DomainTable__row--valid {
		background: $bright-green;
	}

	&.DomainTable__row--invalid {
		background: $grey-8;
	}
}

.DomainTable__records-table .TableHeader__column:nth-child(3) {
	text-align: right;
	padding-right: 15px;
	width: 160px;
}

.DomainTable__verify-row {
	background: rgba($super-light-yellow, 0.4);
}

.DomainTable__verify-wrapper {
	padding: 20px;
}

.DomainTable__verify-wrapper > div .Icon-times {
	cursor: pointer;
	float: right;
}

.DomainTable__verify-wrapper > div .Title {
	width: calc(100% - 15px);
}

.DomainTable__verify-wrapper > div .Text {
	margin: 12px 0 20px;
}

.DomainTable__dns-table .TableRow > :first-child {
	min-width: 130px;
}

.DomainTable__edit-row .Input--inline :focus {
	color: $bright-blue;
}

.DomainTable__Add-domain-buttons {
	display: flex;
	padding-right: 20px;
}

.DomainTable__Add-domain-buttons .Button {
	flex: 1;
	margin: auto;
}

.DomainTable__error-wrapper {
	text-align: center;
	padding: 20px;

	.Button {
		margin-top: 20px;
	}
}

.Domain__delete-dialog {
	padding: 15px;
}

.Domain__delete-dialog .Title {
	margin-bottom: 12px;
}

.Domain__tools-col {
	width: 60px;
}
