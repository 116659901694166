.SelectedTable {
	.TableRow {
		.show-on-row-hover {
			display: none;
		}

		.hide-on-row-hover {
			display: initial;
		}

		&:hover {
			.show-on-row-hover {
				display: initial;
			}

			.hide-on-row-hover {
				display: none;
			}
		}

		.GroupTreeColumn {
			min-width: 80px;
		}
		.TableColumn {
			&__selection--exists {
				min-width: 220px;
			}
		}
	}
}
