@import "~StyleVariables";

.DropdownItem {
	padding: 0 $space * 3;
	transition: $transition-small-ease;
	cursor: pointer;
	
	&:hover {
		background-color: $grey-3;
	}
	
	&--sm {
		height: $space * 7;
	}

	&--md {
		height: $space * 9;
	}

	&__text-wrap {
		overflow: hidden;
		width: stretch;
	}
}
