@import "~StyleVariables";

.SentMail {
	height: 100%;
	background-color: white;
	width: 750px;
	overflow-y: auto;
	overflow-x: hidden;

	.DrawerHeader {
		position: sticky;
		top: 0;
		z-index: 2;

		.SentMail__rightSide {
			display: flex;
			align-items: center;
			gap: 16px;
			margin-right: 16px;

			& > .Button {
				display: flex;
				align-items: center;
			}
		}
	}

	.SwooshScreen {
		height: 500px;
		position: relative;
	}
}
