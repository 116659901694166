@import "~StyleVariables";

.OpportunityWinLossButtons {
	position: relative;

	&__menu {
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
		position: absolute;
		border-radius: 4px;
		width: 300px;
		bottom: $up-btn-height + 2px;
		right: 0;
		box-shadow: 0 3px 6px rgba($black, 0.3);
		z-index: 9000;
		overflow: hidden;

		&--open {
			visibility: visible;
			opacity: 1;
		}
	}

	&__title {
		text-align: left;
	}

	&__item {
		transition: all ease-out 200ms;
		cursor: pointer;

		.Icon,
		.Text {
			color: $grey-12;
			transition: color ease-out 200ms;
		}

		&:hover {
			background-color: $grey-1;

			.Icon,
			.Text {
				color: $medium-blue;
			}
		}
	}

	.OrderCloseDatePicker {
		position: absolute;
		bottom: $up-btn-height;
		right: 0;
	}

	&__lostButton {
		background-color: $super-light-red;
		color: $red;
		border: none;

		&:hover {
			background-color: darken($super-light-red, 5%);
		}
	}
}
