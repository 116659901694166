@import "~StyleVariables";

.NavbarButton {
	position: relative;
	height: 100%;
	padding: 0 16px;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	text-align: center;
	font-size: 16px;

	#active-job-spinner {
		color: $super-light-green;
	}

	&:hover {
		background-color: rgba($white, 0.2);
	}
}
