@import "~StyleVariables";

.TermsAndConditions {
    &__loading {

        .Block {
            display: flex;
            justify-content: center;
        }
    }

    &__emptyList {
        background-color: $grey-1;
		height: 275px;
    }
}
