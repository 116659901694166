@import "~StyleVariables";

.AIAccountSummary {
	&__header {
		cursor: pointer;

		&:hover {
			.AIAccountSummary__header-sub-title {
				visibility: visible;
			}
		}
	}

	&__header-sub-title {
		visibility: hidden;
	}

	&__header-icon {
		background-color: $super-light-purple;
		padding: $space;
		border-radius: $space;
	}

	&__content {
		background-color: $white;

		.Text {
			display: inline;
		}
	}
}
