.SubscriptionSummary {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	padding-bottom: 313px; // 36 + the height of the preview-graph

	&__multiple-periods-toggle {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		margin: 16px;
	}

	&__multiple-periods-select {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
	}

	&__with-sidebar {
		display: flex;
		flex-direction: row;
	}

	&__main-summary {
		&--mainUp {
			animation: scrollMainUp 0.75s cubic-bezier(0.67,-0.34, 0.36, 1.33);
		}
		&--mainDown {
			animation: scrollMainDown 0.75s cubic-bezier(0.67,-0.34, 0.36, 1.33);
		}
	}

	&__fake-summary {
		top: 0;
		position: absolute;
		&--fakeDown {
			animation: scrollFakeUp 0.75s cubic-bezier(0.67,-0.34, 0.36, 1.33);
		}
		&--fakeUp {
			animation: scrollFakeDown 0.75s cubic-bezier(0.67,-0.34, 0.36, 1.33);
		}
	}


	&--showSplitPage {
		align-items: flex-start !important;
	}

	&__scheduling-page {
		transition: all 0.25s ease-in-out;
		opacity: 1;

		> .Headline {
			margin-top: 16px;
			margin-bottom: 12px;
		}
		&--showSplitPage {
			opacity: 0;
			pointer-events: none;
		}
	}

	.SubscriptionPeriodSelector {
		width: 950px;
		margin-top: 16px;
	}

	@keyframes scrollMainUp {
		0% {
			opacity: 1;
			transform: translateY(0);
		}
		75% { // This is so the animation has time to finish before the animation is removed
			opacity: 0;
			transform: translateY(100%);
		}
		100% {
			opacity: 0;
			transform: translateY(100%);
		}
	}

	@keyframes scrollMainDown {
		0% {
			opacity: 1;
			transform: translateY(0);
		}
		75% {
			opacity: 0;
			transform: translateY(-100%);
		}
		100% {
			opacity: 0;
			transform: translateY(-100%);
		}
	}

	@keyframes scrollFakeUp {
		0% {
			opacity: 0;
			transform: translateY(100%);
		}
		75% {
			opacity: 1;
			transform: translateY(0);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes scrollFakeDown {
		0% {
			opacity: 0;
			transform: translateY(-100%);
		}
		75% {
			opacity: 1;
			transform: translateY(0);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
