@import "~StyleVariables";

.DuplicateIntroModal {
	grid-template-rows: 0.5fr auto 1fr;
	--modalContentWidth: 600px;

	.ModalContent__content {
		min-height: 440px;
	}

	.ModalControls {
		padding: 0;
	}

	svg {
		transform: none !important;
	}
}
