@import "~StyleVariables";

.ColumnChartWidget {
	overflow: hidden;
	overflow-x: auto;
	display: flex;
	position: relative;

	&__items,
	&__fixed-labels {
		display: flex;
	}

	&__items {
		flex-grow: 1;
	}

	&__fixed-labels {
		flex-direction: column;
		position: sticky;
		height: 100%;
		flex: 0 0 9em; // Min basis to allow '-999 999 ZMW(widest currency-string)' on one row
		left: 0;
		background-color: $white;
		z-index: 3;

		.ColumnChartWidget__label .Text {
			text-align: right;
			width: auto;
		}
	}

	&--scrolled {
		.ColumnChartWidget__fixed-labels {
			box-shadow: 0 3px 6px rgba($black, 0.2);
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		width: 7.79em; // Min width to allow '-999 999 ZMW(widest currency-string)' on one row, set to just "width" if column width should be the same at all times, regardless of width of name/group and width of widget
		transition: background-color ease-out 200ms;
		flex-grow: 0;

		&--thinMode {
			width: 4.79em;
		}

		&--clickable:hover {
			background-color: $grey-1;
			cursor: pointer;
		}

		&:last-of-type {
			width: 0;
			flex-grow: 1;
		}
	}

	&__bar {
		width: 60px;
		position: absolute;
		bottom: 0;
		left: 10px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		&--thinMode {
			width: 30px;
		}

		&--goal {
			background-color: $grey-4;
		}

		&--loading {
			background-color: $grey-3;
		}

		&--progress {
			background-color: $green;
		}
	}

	&__bar-wrap {
		flex: 1 0 0;
		position: relative;
		margin-top: 15px;

		.Tooltip {
			position: absolute;
			bottom: 0;
			left: 10px;
			width: 60px;
			height: 100%;
			z-index: 1;
		}
	}

	&--has-data &__bar-wrap {
		border-bottom: 1px solid $green;
	}
	&--loading &__bar-wrap {
		border-bottom: 1px solid $grey-3;
	}

	&__label {
		flex: 0 0 41px;
		padding: 0 10px;
		border-bottom: 1px solid $grey-4;
		display: flex;
		align-items: center;

		.Text {
			max-height: 40px; /* line-height * number of max lines shown, 20px * 2 */
			line-height: 20px;
			width: 7.79em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&--with-year {
			padding-bottom: 20px;
			padding-top: 10px;
		}
		&--dummy-padding {
			padding: 25px;
		}
	}
	&__noResultRowText {
		padding: 10px 15px 0;
		width: 100%;
		border-top: $grey-2 1px solid;
	}
	&__label-year {
		position: absolute;
	}
}
