.MailTemplateSelectButton {
	&__dropdown {
		animation: slideFromTop 200ms;
		& > .Button {
			width: 100%;
			border-radius: 0;
			text-align: left;
		}
	}

	.DropDownMenu__content {
		z-index: 10;
	}

	.MailTemplateSelect {
		width: 400px;
	}

	.DropDownMenu__content {
		min-width: 180px;
	}

	@keyframes slideFromTop {
		from {
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			opacity: 0;
		}
	}
}
