.ReportcenterTV {
	visibility: visible;
	&__curtain {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		z-index: 1;
	}

	.ModalHeader {
		border-top: none;
		
	}

	#play-header {
		display: flex;
		align-items: center;
		text-align: right;
		.Button {
			box-shadow: none;
		}
	}
}