@import "~StyleVariables";

.ClientCard {
	&__main {
		flex-wrap: nowrap;
	}

	&__mainContent {
		overflow-x: clip;
		background-color: $grey-2;
	}

	&--remove-frame {
		margin-top: 0;
		left: 48px;
		top: $main-top - 15px;
		right: 0;
		bottom: 0;

		.Page {
			border-radius: 0;
			box-shadow: none;
			min-height: 100%;
		}
	}
}
