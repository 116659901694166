@import "~StyleVariables";

.OpportunityEditTodoRow {
	&__date-col {
		display: flex;
		align-self: center;
		justify-content: flex-end;
		
		> .Button {
			margin-right: 4px;
		}
		> .Button:hover {
			background-color: $grey-3;
		}
	}
	&__desc-col {
		min-width: 140px;
		max-width: 280px;
		.Label {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: row;
			height: 34px;
		}
	}
	&__contact-col {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;
		padding-left: 20px;
		.Label {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 34px;
		}
	}

	&__text {
		padding-left: 20px;
		position: relative;
		margin-bottom: 4px;
		max-width: 270px;	

		.FakeTooltip {
			left: 8px;
			top: 55px;
		}
	}
}
