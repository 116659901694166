.EditProductPriceCard {	
	&__currency-addon {
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;

		.Text {
			line-height: 32px;
			padding: 0 10px;
		}
	}

	.Row {
		margin: 0 -10px;
	}

	&__padded-col,
	&__currency {
		padding: 0 10px;
	}

	.RadioListItem {
		display: inline-flex;
		vertical-align: top;
		margin-right: 30px;
		cursor: pointer;

		.Text {
			margin-left: 5px;
		}
	}

	&__price-list {
		margin-top: 4px;
		margin-bottom: 4px;
	}

	&__max-discount-help {
		position: absolute;
		top: 0;
		right: 0;
	}
}
