@import "../../../styles/variables";

.SalesModelSettings {
	// TODO: Remove this once implemented in the ui-library
	.Label {
		font-weight: bold;
	}

	&__header .CardHeader__actions {
		display: flex;
		align-items: center;
	}

	&__sales-model-box {
		gap: 2px;
		text-align: left;
		justify-content: flex-start;
	}

	&__sales-model-box-subtitle {
		font-size: 12px;
		letter-spacing: 1px;
	}

	&__billing-card-wrapper {
		z-index: 15;
	}

	&__addon-info-wrapper {
		width: 100%;
		text-align: center;

		.Title {
			line-height: 40px;
			font-weight: $regularFontWeight;
		}

		.Text {
			width: 406px;
			margin: auto;
			padding-bottom: 10px;
		}

		.Button {
			margin-top: 5px;
		}
	}

	&__section-header {
		padding: 0px;

		&--disabled {
			opacity: 50%;
		}
	}

	& > .CardContent {
		width: 660px;
	}
}
