@import "~StyleVariables";

.DeleteGroup {
	.Icon {
		padding: 10px;
	}

	&__dropDown {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;

		width: 260px;

		padding: 20px;
		border-radius: $border-radius;
		cursor: initial;

		&__containsPlannedChange {
			padding: 8px 0px;
		}

		&--hasSplit {
			width: 316px;
		}
	}

	hr {
		all: unset;
		width: 100%;
		border: 1px solid $grey-4;
	}

	.DropDownMenu__content {
		z-index: 10;
	}

	.Button {
		width: 100%;
	}
}
