.form__select-wrap {
	position: relative;

	.form__select {
		&.form__select--closed {
			.select2-choice {
				border: none;

				.select2-chosen {
					color: #71aee8;
					font-size: small;
					display: inline-block;
					margin-right: 5px;
					padding-right: 0;
				}

				.select2-arrow {
					border: none;
					right: initial;
				}
			}

			& + .fa-question-circle {
				display: block;
			}
		}
	}

	.fa-question-circle {
		position: absolute;
		top: 10px;
		right: 10px;
		display: none;
	}
}
