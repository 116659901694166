@import "~StyleVariables";

.RawCode {
	&__Notification {
		background-color: $super-light-green;
		border-radius: 2px;
		padding: 16px;

		.Icon {
			margin: 4px 10px;
			float: left;
		}

		+ .RawCode__Notification {
			margin-top: 20px;
		}

		.Button[type="button"]:not(.btn-link) {
			margin-top: 15px;
		}

		.Label {
			margin-top: 10px;
			font-weight: $boldFontWeight;
		}
	}

	&__Editor {
		height: 100%;
	}

	&__Description {
		padding: 15px 20px;
	}

	&__Warning {
		margin-top: 30px;
	}

	.CopyButton {
		bottom: 12px;
		right: 16px;
	}
}
