.ListSocialEvents {
	&__tabs,
	&__tabs .Tab {
		background-color: transparent;
	}

	&__calendar {
		overflow: hidden;
	}
	
	&__calendar:hover {		
		overflow: visible;
	}
}
