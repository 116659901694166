.AgreementFollowUpChip {
    display: flex;

    &__title {
        display: flex;
    }

    .Chip {
        min-width: 0;
    }
}