@import "~StyleVariables";

.NewMailHeader {
	.Icon-times,
	.Icon-plus {
		cursor: pointer;
	}

	&__recipientsRows {
		position: relative;
		border-bottom: 1px solid $grey-4;
		&__ccButtons {
			position: absolute;
			right: 0;
			bottom: 14px;
			display: flex;
			.Text {
				margin: 0px 12px;
				color: $grey-10;
				cursor: pointer;
				&:hover {
					color: $grey-12;
				}
			}
		}
	}

	&__subjectInput {
		flex: 1;

		&:last-child {
			padding-right: 16px;
		}
	}

	&__subjectInputProgress {
		display: flex;
	}

	&__required {
		margin-right: 4px;
	}

	&__row {
		height: 50px;
		display: flex;
		align-items: center;
		background-color: $white;
		transition: all 0.5s ease;
		border-bottom: 1px solid $grey-4;

		.Input {
			width: 100%;
		}

		& > .Text:first-of-type {
			margin-left: 16px;
		}

		&--hideTemplateRow {
			height: 0;
			border-bottom: 0 !important;
			.Text {
				opacity: 0;
			}
		}
	}

	&__input {
		.Input__input {
			border: none;
			box-shadow: none;
		}
	}
}

.ContactBadge {
	padding: 4px;
	background-color: $super-light-blue;
	border-radius: $border-radius;
	white-space: nowrap;
	margin-left: 8px;

	&--locked {
		background-color: $grey-4;
	}
}
