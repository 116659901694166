@import "~StyleVariables";

.WhatsIncludedCard {
	cursor: pointer;
	transition: all ease-in 0.2s;
	overflow: hidden;

	.CardHeader--md {
		min-height: 30px;
	}

	.CardHeader__title.Title {
		color: $black;
		font-size: 16px;
		font-weight: $boldFontWeight;
		line-height: 30px;
		text-align: left;
	}

	.Text {
		line-height: 20px;
	}

	img {
		position: absolute;
		bottom: -15px;
		right: 25px;
		transition: bottom ease-in 0.2s;
	}

	.Card__slice {
		&:before {
			right: -145px;
		}
	}

	&:hover {
		background: $grey-1;
		box-shadow: 0 2px 6px 0 rgba(30, 37, 46, 0.2);

		img {
			position: absolute;
			bottom: -11px;
			right: 25px;
		}
	}
}
