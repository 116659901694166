@import "~StyleVariables";

.AppOnboarding {
	background-color: $white;
	left: 0;
	top: 0;
	z-index: 9997;
	bottom: 0;
	right: 0;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;

	.BadgeLogo {
		opacity: 0.5;
		position: absolute;
		top: 12px;
		left: 12px;
	}

	&__skip {
		position: absolute;
		top: 0;
		right: 0;
	}

	&__inner {
		position: absolute;
		width: 100%;
		max-height: 100%;
		padding: 20px 10px;
		overflow-y: auto;
		display: grid;
		grid-template-columns: 900px;
		justify-content: space-around;

		&--center {
			text-align: center;
			.Loader {
				margin: 0 auto;
			}
		}

		&--large {
			grid-template-columns: 1150px;
		}

		&--no-scroll {
			overflow-y: initial;
		}

		&--adding-companies {
			grid-template-columns: 450px;
		}

		// Make more specific
		.flag-icon {
			margin-right: 8px;
		}
		& > .Loader {
			display: inline-block;

			& + .Text {
				display: inline-block;
				margin-left: 8px;
			}
		}
	}

	&__box-choice {
		background-color: $grey-1;
		border: 1px solid $grey-6;
		border-radius: $border-radius;
		min-height: 120px;
		padding: 24px 16px;
		transition: all ease-out 300ms;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 1px 2px rgba($black, 0.2);

		&:hover {
			background-color: $white;
			box-shadow: 0 4px 8px rgba($black, 0.2);
		}

		.Headline {
			margin-bottom: 8px;
		}

		&--white {
			background-color: $white;
		}
	}

	&__erp-list {
		margin-bottom: 20px;
	}

	&__animated-icon {
		transition: all ease-out 0.2s;
		overflow: hidden;
	}

	&__erp-image {
		height: 100px;
		object-fit: contain;

		&--small {
			height: 40px;
		}

		&--has-shadow {
			box-shadow: 0 1px 2px rgba($black, 0.2);
		}
	}

	&__turnover-bar {
		background-color: $green;
		border: 2px solid $green;
		text-align: center;
		height: 30px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		flex: 1;

		&:first-of-type {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}

		&:last-of-type {
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}

		&--lost {
			background-color: $super-light-red;
			border-color: $red;
			border-style: dashed;
		}

		&--new {
			background-color: $medium-green;
			border-color: $medium-green;
		}

		.Text {
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	&__identified-growth-headline {
		margin-top: 80px;
	}
}
