@import "~StyleVariables";
@import "../shared";

.PeriodizationListContent {
	background-color: $grey-1;
}

.PeriodizationListContent__header {
	height: $headerHeight;
}

.PeriodizationListContent__year-select {
	display: grid;
	height: 100%;
	grid-auto-columns: minmax(90px, auto);
}

.PeriodizationListContent__year-select > div {
	grid-row: 1;
}

.PeriodizationListContent__year-select .Text {
	position: sticky;
	left: $sideBarWith;
	display: inline;
	padding-left: $columnPadding;
}

.PeriodizationListContent .PeriodizationListTable > div {
	grid-auto-columns: minmax(90px, auto);
}

@media (max-width: 1400px) {
	.PeriodizationListContent .PeriodizationListTable > div,
	.PeriodizationListContent__year-select {
		grid-auto-columns: minmax(80px, auto);
	}

	.PeriodizationListContentColumn {
		.Text--md,
		.Icon-warning {
			font-size: 12px;
		}
	}
}

.PeriodizationListContentColumn {
	display: flex;
	align-items: center;
}

.PeriodizationListContentColumn--border-left {
	border-left: 1px solid $grey-4;
}

.PeriodizationListContentColumn .Tooltip + .Text {
	margin-left: 5px;
}
