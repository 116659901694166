@import "~StyleVariables";

.RiskyOpportunities {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	&__topContent {
		max-width: 600px;
		text-align: center;
		margin-top: 8rem;
		margin-bottom: 2rem;
	}

	&__mainContent {
		flex: 1 0 100%;
		width: 100%;
		height: 100%;
		background-color: $grey-1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__dealCards {
		display: grid;
		grid-template-columns: repeat(auto-fit, 220px);
		grid-gap: 1rem;
		justify-content: center;
		max-width: 700px;
	}
}
