@import "~StyleVariables";

.SupersearchHighlight {
	&__match {
		position: relative;
		display: inline-block;
		color: $white;
		padding: 0 1px;

		span {
			position: relative;
			z-index: 1;
			line-height: 1.6;
		}

		&:before {
			content: "";
			position: absolute;
			top: 4px;
			bottom: 4px;
			left: 0;
			right: 0;
			background-color: $green;
			border-radius: 2px;
		}
	}
}
