@mixin transition($value) {
	-webkit-transition: $value;
	-moz-transition: $value;
	-o-transition: $value;
	transition: $value;
}

@mixin animation($value) {
	-webkit-animation: $value;
	-moz-animation: $value;
	-o-animation: $value;
	animation: $value;
}

@mixin animation-iteration-count($value) {
	-webkit-animation-iteration-count: $value;
	-moz-animation-iteration-count: $value;
	-o-animation-iteration-count: $value;
	animation-iteration-count: $value;
}

@mixin animation-delay($value) {
	-webkit-animation-delay: $value;
	-moz-animation-delay: $value;
	-o-animation-delay: $value;
	animation-delay: $value;
}

@mixin transform($value) {
	-webkit-transform: $value;
	-moz-transform: $value;
	-o-transform: $value;
	transform: $value;
}

@mixin transition-delay($value) {
	-webkit-transition-delay: $value; /* Safari */
	transition-delay: $value;
}

@mixin pulse($duration) {
	-webkit-transition: all ease-in-out $duration;
	-moz-transition: all ease-in-out $duration;
	-o-transition: all ease-in-out $duration;
	transition: all ease-in-out $duration;
}

@mixin pulse-in-out() {
	0% {
		@include transform(scale(0.5, 0.5));
		@include opacity(0.5);
	}
	50% {
		@include transform(scale(1, 1));
		@include opacity(1);
	}
	100% {
		@include transform(scale(0.5, 0.5));
		@include opacity(0.5);
	}
}

@mixin pulse-in-out-freeze($scale: 0.5) {
	0% {
		@include transform(scale(1, 1));
		@include opacity(1);
	}
	25% {
		@include transform(scale($scale, $scale));
		@include opacity($scale);
	}
	50% {
		@include transform(scale($scale, $scale));
		@include opacity($scale);
	}
	100% {
		@include transform(scale(1, 1));
		@include opacity(1);
	}
}

@mixin keyframes($name) {
	@keyframes #{$name} {
		@content;
	}

	/* Firefox < 16 */
	@-moz-keyframes #{$name} {
		@content;
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes #{$name} {
		@content;
	}

	/* Internet Explorer */
	@-ms-keyframes #{$name} {
		@content;
	}

	/* Opera < 12.1 */
	@-o-keyframes #{$name} {
		@content;
	}
}

@mixin animations() {
	.no-animate {
		@include transition(none !important);
	}

	.width-left-animation {
		&.ng-hide-add,
		&.ng-hide-remove {
			@include transition(all linear 0.1s);
		}

		&.ng-hide-add.ng-hide-add-active,
		&.ng-hide-remove {
			opacity: 0;
			margin-left: -10px;
		}

		&.ng-hide-add,
		&.ng-hide-remove.ng-hide-remove-active {
			opacity: 1;
			margin-left: 0px;
		}
	}

	.width-right-animation {
		&.ng-hide-add,
		&.ng-hide-remove {
			@include transition(all linear 0.1s);
		}

		&.ng-hide-add.ng-hide-add-active,
		&.ng-hide-remove {
			opacity: 0;
			margin-right: -10px;
		}

		&.ng-hide-add,
		&.ng-hide-remove.ng-hide-remove-active {
			opacity: 1;
			margin-right: 0px;
		}
	}

	.fade-animation {
		display: block !important;

		&.in.ng-enter,
		&.out.ng-leave {
			@include transition(all linear 0.3s);
		}

		&.in.ng-enter {
			opacity: 0;
		}

		&.out.ng-leave {
			opacity: 1;
		}

		&.in.ng-enter.ng-enter-active {
			opacity: 1;
		}

		&.out.ng-leave.ng-leave-active {
			opacity: 0;
		}
	}

	.fade-out-animation {
		opacity: 1;

		&.ng-leave {
			@include transition(all linear 0.3s);
			opacity: 1;
		}

		&.ng-leave.ng-leave-active {
			opacity: 0;
		}
	}

	.fade-out-up-animation {
		position: relative;

		&.ng-leave {
			@include transition(all linear 0.3s);
			opacity: 1;
			top: 0;
		}

		&.ng-leave.ng-leave-active {
			opacity: 0;
			top: -50px;
		}
	}

	.pulse-animation {
		position: relative;

		&.ng-enter {
			@include pulse(2.5s);
			background-color: $white !important;
		}

		&.ng-enter.ng-enter-active {
			background-color: $super-light-yellow !important;
		}
	}
	.animate-repeat {
		//display: block;
	}

	.animate-repeat.ng-move,
	.animate-repeat.ng-enter,
	.animate-repeat.ng-leave {
		@include transition(all linear 0.3s);
	}

	.animate-repeat.ng-leave.ng-leave-active,
	.animate-repeat.ng-move,
	.animate-repeat.ng-enter {
		opacity: 0;
		max-height: 0;
		display: table-row !important;
	}

	.animate-repeat.ng-leave,
	.animate-repeat.ng-move.ng-move-active,
	.animate-repeat.ng-enter.ng-enter-active {
		opacity: 1;
		max-height: 40px;
		display: table-row !important;
	}
}
