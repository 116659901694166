@import "~StyleVariables";

.CompanyGroupFilters {
	&__filter {
		z-index: 100;

		&__row {
			border-top: 1px solid $grey-3;
			cursor: pointer;
			transition: background-color 0.3s ease-in;

			&:hover {
				background-color: $grey-1;
			}
		}
	}
}
