@import "~StyleVariables";

.OpportunityAI {
	&--roundedEdgesLast {
		.OpportunityRow:last-of-type {
			border-radius: 0px 0px $border-radius $border-radius;
		}
	}

	.OpportunityRow:first-of-type {
		border-radius: $border-radius $border-radius 0px 0px;
	}

	&__completed {
		border-radius: 0px 0px $border-radius $border-radius;
		transition: all ease-in-out 200ms;

		&--grey {
			background-color: $grey-1;
		}

		.OpportunityRow {
			background-color: $grey-1;
		}

		.OpportunityRow:last-of-type {
			border-radius: 0px 0px $border-radius $border-radius;
		}
	}

	&__textWrapper {
		display: flex;
		height: 40px;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&__icon {
			animation: rotateBackAnimation;
			animation-iteration-count: 1;
			animation-duration: 400ms;
			transform: rotate(0deg);

			&--rotate {
				animation: rotateAnimation;
				animation-iteration-count: 1;
				animation-duration: 400ms;
				transform: rotate(180deg);
			}
		}
	}
}

.opportunityHeadline {
	padding-bottom: 7px;
	padding-top: 5px;
}
