.AddLabelsMulti.Modal {
	width: 400px;
}

.AddLabelsMulti .select2-container {
	width: 100%;
}

.AddLabelsMulti .RadioListItem {
	margin: 16px 0;

	.Text {
		margin-left: 12px;
	}
}
