@import "~StyleVariables";

.EditRole {
	.ModalHeader {
		border-top: none;
	}

	.ModalContent {
		margin-top: 51px;
	}

	&__top-section {
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		padding: 0px 20px 20px 20px;
		background-color: $white;

		.Row {
			margin: 0 -10px;
		}

		.Column {
			padding: 0 10px;
		}
	}

	&__access-wrap {
		padding: 20px;
	}

	&__defaultSalesboardLabel {
		display: flex;

		.Help {
			margin-left: 5px;
		}
	}

	&__selectContainer {
		margin-top: 5px;
		width: 200px;
	}
}
