$width: 580px;

.MailTemplatePreviewFooter {
	position: relative;

	&__inner {
		position: relative;
		margin: 0 auto;
		padding: 0 16px;
		width: $width;

		.Column {
			padding-top: 10px;
			padding-bottom: 10px;
			line-height: 1.3em;
			vertical-align: top;
			font-size: 12px;
			margin-bottom: 10px;
		}
	}

	&__unsub {
		padding: 10px 16px;

		.Column {
			margin-bottom: 0;
		}
	}
}
