@import "~StyleVariables";

.AdvancedSearchView {
	min-width: 100px;

	.upsales-loader {
		width: 30px;
		margin: auto;
		height: 30px;
	}

	.view-picker {
		display: inline-block;
		vertical-align: top;
		position: relative;
		height: 45px;

		.dropdown-menu {
			min-width: 250px;

			li {
				a {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					border-left: 5px solid #fff;
					padding: 5px 12px;
					font-size: 16px;
					color: #4b5562;

					&:hover {
						border-left: 5px solid $medium-blue;
						background-color: $white;
						color: $black;
					}
				}

				&.active,
				&:active {
					a {
						background-color: $white;
						color: $black;
						border-left: 5px solid $medium-blue;
					}
				}
			}
		}

		.view-picker-text-wrap {
			display: inline-table;
			padding: 0 10px;
			cursor: pointer;
			height: 100%;
			border-right: 1px solid $grey-4;

			.fa {
				display: table-cell;
				vertical-align: middle;
				font-weight: $boldFontWeight;
				color: $grey-10;
			}

			h2 {
				display: table-cell;
				vertical-align: middle;
				color: $blue;
				padding-right: 20px;
				max-width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.view-picker-save-search {
			display: inline-table;
			vertical-align: top;
			padding: 0 10px;
		}
	}
}

#advanced-search-picker {
	&.SharedViews {
		.DropDownMenu__content {
			padding-top: 10px;
		}
	}
}
