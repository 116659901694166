@import "~StyleVariables";

.SubscriptionListConflict {
	&.Banner {
		padding: $space * 3;
		height: auto;
		margin-bottom: $space * 3;

		.Banner__content {
			align-items: center;
			margin-right: $space * 4;
		}
	}
}
