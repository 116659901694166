@import "~StyleVariables";

.EditDocumentTemplate {
	height: 100%;

	&__up-modal-content {
		height: 100%;
		width: 100%;
		padding: 0;

		.ModalContent__content {
			height: 100%;
			width: 100%;

			.column-wrap {
				display: flex;
				justify-content: space-between;
				height: 100%;

				.left-side,
				.right-side {
					padding: $space * 4;
					width: 50%;
				}

				.left-side {
					flex: 1;

					.left-side-row {
						margin-bottom: $padding;
					}
				}

				.left-side-save-button-wrap {
					width: 45%;
					display: flex;
					flex-direction: column;
					position: absolute;
					bottom: $padding;

					.left-side-cancel-button {
						display: flex;
						justify-content: center;
					}
				}

				.right-side {
					background-color: $grey-4;

					.cancel-button {
						color: $grey-10;
						position: absolute;
						right: 10px;
						top: 10px;
					}

					.right-side-content-wrap {
						margin-top: $space * 15;

						button {
							width: 150px;
						}
					}

					.right-side-file-name {
						text-align: center;

						.right-side-file-size {
							color: $grey-11;
						}
					}

					.right-side-filetype-warning {
						bottom: 0;
						color: $grey-11;
						padding: $padding;
						position: absolute;
						text-align: center;
					}
				}
			}
		}

		.FileUploadDropzone {
			border: 0;
		}

		.FileUploadDropzoneActive {
			width: 395px;
			left: 50%;
			padding: 0;

			&__instructions {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex: 1;
			}
		}
	}
}
