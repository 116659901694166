@import "~StyleVariables";

.MailTemplatePreviewButton {
	&__btn {
		border-collapse: collapse;
		border-radius: 3px;
		font-size: 16px;
		hyphens: none;
		margin: 0 auto 16px auto;
		padding: 0 24px;
		text-align: center !important;
		vertical-align: top;
		word-wrap: break-word;
		font-weight: $boldFontWeight;
		display: inline-block;
		text-align: center;
		min-height: 52px;
		color: $white;
		padding: 16px 25px;
	}

	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
	}

	&--center {
		text-align: center;
	}

	&--expand &__btn {
		display: block;
	}

	&--tiny &__btn {
		font-size: 10px;
	}

	&--small &__btn {
		font-size: 12px;
	}

	&--large &__btn {
		font-size: 16px;
	}
}
