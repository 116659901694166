@import "~StyleVariables";

.AfterSubmitPage {
	display: flex;

	&__Disabled-area {
		border-radius: 0 0 2px 2px;
		box-shadow: 0 -1px 0 0 $grey-5;
		padding: 25px 20px 35px;

		.Label {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.Text {
				margin-left: 9px;
			}

			& + .Text {
				margin-bottom: 10px;
			}
		}
	}

	&__Disabled {
		background-color: $grey-4;
	}

	&__Disabled-message {
		text-align: center;
		padding: 20px 0;
	}

	&__Lock {
		padding-right: 10px;
	}

	&__Preview-box {
		background-color: $white;
		border: 1px solid $grey-4;
		border-radius: 3px;
		width: 330px;
		min-height: 360px;
		margin-right: 18px;

		.CardContent {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.btn,
			.up-file-btn {
				width: 100%;
				padding: 10px;
				border-radius: 0;
				margin-bottom: 10px;
				display: block;
			}
		}

		.ContentType-preview {
			overflow: hidden;
			position: relative;

			#up-form-thanks-on-demand,
			#up-form-thanks {
				padding: 0;
				box-shadow: none;
				border-radius: 0;
			}
		}

		&--before .ContentType-preview {
			#up-form-thanks {
				display: block;
			}
		}

		&--after .ContentType-preview {
			#up-form-thanks-on-demand {
				display: block;
			}
		}
	}

	&__Curtain {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	&__ContentType-area {
		width: 400px;

		h3 {
			font-size: 18px;
			font-weight: $boldFontWeight;
			margin: 5px 0 10px;
		}

		&__Content {
			.ButtonGroup {
				margin-bottom: 20px;
			}

			.Label {
				margin-bottom: 5px;
			}
		}

		.CardContent {
			.Input {
				margin-bottom: 16px;
			}

			.ButtonGroup {
				margin-bottom: 17px;
			}
		}

		.Label {
			.ButtonGroup {
				margin-bottom: 0;
			}
		}
	}

	&__Toggle-area {
		display: flex;
		align-items: center;
		margin-bottom: 13px;

		.Text {
			margin-left: 9px;
		}
	}

	&__ContentType-item {
		margin-bottom: 10px;

		&:nth-child(2n + 3):last-child {
			flex-basis: 66%;
		}

		.Button {
			background-color: $white;
			border: 1px solid $grey-5;
			border-radius: 2px;
			box-shadow: 0 1px 2px 0 rgba($black, 0.2);
			color: $black;
		}
	}

	&__MediaType-List {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;

		.Button {
			width: 130px;
			height: 80px;
		}

		.Tabs__selected-slider {
			display: none;
		}
	}

	&__MediaType {
		background-color: $white;
		border: 1px solid $grey-5;
		border-radius: 2px 0 0 2px;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		width: 102.22px;
		height: 80px;
		text-align: center;
		transition: ease all 200ms;
		color: $grey-10;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0;
		box-sizing: border-box;
		align-items: center;

		.Icon {
			font-size: 24px;
		}

		&.Tab--selected {
			background-color: $bright-blue;
			color: $white;

			&:hover {
				background-color: $bright-blue;
			}
		}

		&.Button {
			&:hover {
				background-color: $grey-1;
			}
		}
	}

	&__Content-type {
		margin-bottom: 20px;
	}

	&__Button-area {
		.Text {
			line-height: 18px;
		}
	}

	&__Section {
		&__Form-control {
			margin-bottom: 10px;
		}
	}

	&__Options {
		margin-bottom: 20px;
	}

	&__Option {
		display: flex;
		align-items: center;

		&__Link {
			margin-left: auto;
		}
	}

	&__Content-type {
		.Title {
			font-weight: $boldFontWeight;
		}
		.Icon {
			padding-right: 10px;
		}
	}

	&__Error {
		background-color: $super-light-red;
		border-radius: 2px;
		width: 290px;
		height: 150px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
	}

	.cke {
		visibility: visible;
		opacity: 1;
	}

	&.Smooth-enter {
		opacity: 0;
	}
	&.Smooth-enter-active {
		opacity: 1;
		transition: all 500ms ease-in;
	}
	&.Smooth-exit {
		opacity: 1;
	}
	&.Smooth-exit-active {
		opacity: 0;
		transition: all 500ms ease-in;
	}

	#up-form,
	#up-form-event-full,
	#up-event-canceled,
	.up-editor-placeholder {
		display: none !important;
	}

	&__Tabs-container {
		width: 45%;
	}
}
