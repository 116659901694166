@import "~StyleVariables";

.AppointmentMonthView {
	position: absolute;
	top: 3px;
	left: 3px;
	right: 3px;
	padding: 2.5px 5px;
	background-color: $grey-3;
	border-radius: $border-radius;
	transition: background-color 0.2s;
	cursor: default;
	height: 35px;

	&--clickable {
		cursor: pointer;

		&__overlay {
			&:hover {
				opacity: 0.1;
			}
		}
	}

	&:hover {
		.transparent-text {
			color: initial;
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $black;
		opacity: 0;
		transition: 0.2s;
	}

	&:not(:last-child) {
		margin-bottom: 0;
	}

	&__header {
		display: flex;
		align-items: center;
	}

	.Text {
		&.noWrap {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			text-align: left;
		}
	}

	.xs {
		font-size: 10px;
		line-height: 10px;

		&.time {
			margin-right: 5px;
			word-break: initial;
		}
	}

	.transparent-text {
		color: transparent;
	}
}

.extraAppointmentsCount {
	font-size: 10px;
	position: absolute;
	top: -21px;
	right: 5px;
}

/**
 * @todo  @upsales/components 2.15.4
 * STYLING: CalendarWeekday assumes that all events are clickable
 * @link https://github.com/upsales/ui-components/issues/529
 */

.CalendarWeekday__Event {

	.CalendarWeekday__EventContent--small {
		padding: 1px 4px;
	}

	&:has(.Appointment--clickable) {
		&:hover .CalendarWeekday__EventContent {
			&--overlay {
				background-color: $white;
				opacity: 0.2;
			}

			box-shadow: $box-shadow-lg;
		}
	}

	&:not(:has(.Appointment--clickable)) {
		cursor: default;

		&:hover .CalendarWeekday__EventContent {
			&--overlay {
				background-color: $white;
				opacity: 0.6;
			}

			&:hover {
				box-shadow: unset;
			}
		}

		.Event {
			cursor: default;
		}
	}

	&:has(.Appointment--editing) {
		.CalendarWeekday__EventContent {
			background-color: $grey-4;
			animation-name: pulsate;
			animation-duration: 2s;
			animation-iteration-count: infinite;

			&--overlay {
				opacity: 0 !important;
			}
		}

		@keyframes pulsate {
			0% {
				background-color: $grey-4;
			}

			50% {
				background-color: $grey-6;
			}

			100% {
				background-color: $grey-4;
			}
		}
	}

	.CalendarWeekday__EventContent {
		&--overlay {
			background-color: revert;
		}
	}
}

.Appointment {
	padding: 2.5px 5px;
	background-color: $grey-3;
	border-radius: $border-radius;
	margin: 3px 0;
	cursor: default;
	transition: background-color 0.2s;
	position: relative;

	&:hover {
		.transparent-text {
			color: initial;
		}
	}

	&--clickable {
		&:hover {
			cursor: pointer;

			.Appointment__overlay {
				opacity: 0.1;
			}
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $black;
		opacity: 0;
		transition: 0.2s;
	}

	&:not(:last-child) {
		margin-bottom: 0;
	}

	&__header {
		display: flex;
		align-items: center;
	}

	.Text {
		&.noWrap {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			text-align: left;
		}
	}

	.xs {
		font-size: 10px;
		line-height: 10px;

		&.time {
			margin-right: 5px;
			word-break: initial;
		}
	}

	.transparent-text {
		color: transparent;
	}
}

.CalendarDay {
	&__wrapper {
		position: relative;
		height: 127px;
		padding: 5px;
		cursor: cell;
	}

	&__AllAppointments {
		z-index: 1;
		position: absolute;
		top: -10px;
		width: 110%;
		background-color: $white;
		border: 1px solid $green;
		box-shadow: 0 10px 20px -4px rgba($black, 0.4);
		padding: 8px 8px 12px;

		&--header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
		}
	}
}

.CalendarView {
	.CalendarMonthView {
		position: relative;
		width: 100%;
		border-bottom: 1px solid $grey-6;

		.CalendarMonthView__StickyHeader {
			z-index: 3;
			position: sticky;
			top: 0;
			display: flex;
			background-color: $white;
			border-top: 1px solid $grey-6;
			border-bottom: 1px solid $grey-6;
			border-right: 1px solid $grey-6;

			tr {
				flex: 1;
				display: flex;

				.Calendar__Day-header {
					flex: 1;
					text-align: left;
					font-size: 12px;
					font-weight: $boldFontWeight;
					padding: 5px 0 5px 10px;
				}
			}
		}

		.CalendarMonthView__Table {
			tr:first-child {
				td {
					border-top: 0;
				}
			}
		}
	}
}
