@import "~StyleVariables";

.IntegrationArrayField {
    position: relative;
    background-color: $white;
    border-radius: $border-radius;

    &__inner {
        position: relative;
        width: 100%;
        padding: 20px;
    }

    &__array-field-section-title {
        font-size: 18px;
        line-height: 20px;
        color: $grey-13;
        margin-bottom: $space * 2;
        font-weight: normal;
    }

    &__input {
        width: 100%;
    }

    &__description-block {
        margin-bottom: 20px;
    }

    &__description-text {
        &--first {
            margin-bottom: 10px;
        }
    }

    &__input-text {
        margin-bottom: 8px;
    }
}
