.AccountInactivationInfo {
	height: 160px;
	padding: 20px;

	> .Row {
		height: stretch;
	}
}

.AccountInactivationInfo__left-side {
	margin-right: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.AccountInactivationInfo__right-side {
	z-index: 1001; // Has to have more than card-slice

	.Card {
		padding: 10px;
	}

	$avatarHeight: 28px;

	.Row {
		align-items: center;
		margin-bottom: 5px;
	}

	.Avatar {
		height: $avatarHeight;
		width: $avatarHeight;
		font-size: $avatarHeight;
		margin-right: 10px;
	}

	.Avatar--initials {
		line-height: $avatarHeight;
	}

	.Text:last-child {
		word-break: break-word;
	}
}

.AccountInactivationInfo .Card {
	height: 100%;
}

.AccountInactivationInfo .Card__slice {
	left: initial;
	width: 320px;
}

.AccountInactivationInfo__reason-wrap {
	height: 45px;
	display: flex;
	align-items: center;
	overflow: hidden;
}
