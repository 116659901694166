@import "~StyleVariables";

.ExistInUpsales {
	padding: 0 $space;
	height: 22px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
	vertical-align: middle;
	cursor: pointer;
}
