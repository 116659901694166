@import "~StyleVariables";

.EditFlowReactWrapper#edit-flow-root {
    .card.full-size {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }
    #edit-flow-sidebar {
        top: 82px;
        bottom: 0;
    }
    .full-size:after {
        content: none;
    }
}