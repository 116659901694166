.FindProspectsGroupTree {
	position: absolute;
	z-index: 1030;
	height: 100%;
	top: 100%;
	width: 100%;
	transition: all ease-out 300ms;

	&--visible {
		top: 0;
	}

	&__controls {
		position: absolute;
		right: 0;
		top: 0;
	}

	&__content {
		position: absolute;
		top: 53px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-x: auto;
	}

	.accounts-card {
		&:first-of-type {
			margin-top: 0;

			thead th {
				padding: 0;
				height: 0;
			}
		}

		.result-table {
			width: 100%;
		}
	}

	.EditorHeader {
		position: absolute;
		right: 0;
		left: 0;
		flex-direction: column;
		padding: 4px 10px;
	}
}
