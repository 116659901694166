@import "~StyleVariables";

.FormPublishingOptions {
	.CardContent:first-child {
		border-bottom: 1px solid rgba($black, 0.1);
	}
	.ShareButtons{
		padding-top: 8px;
	}

	.CopyButton {
		bottom: 0px;
	}
}
