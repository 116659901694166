@import "~StyleVariables";

.SubscriptionGroupScheduling {
	display: flex;
	flex-direction: column;

	&__container {
		display: flex;
		align-items: center;
		&:not(&:first-of-type) {
			margin-top: 8px;
		}
	}

	.Link {
		margin-left: 4px;
	}

	.DateInput--disabled {
		.DateInput__inner {
			.Text {
				margin-left: 4px;
			}
		}
	}
}
