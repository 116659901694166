@import "~StyleVariables";

.EditPhonecallClient {
	padding-top: 25px;
	.Headline {
		max-width: 230px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		@media (max-width: 950px) {
			max-width: 135px;
		}
	}
	> .Row {
		align-items: center;
	}

	&__client-name {
		& > .Block {
			display: inline-block;
			vertical-align: middle;

			.Icon {
				margin: 0 8px;
			}
		}
	}

	&__indicators {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;
		& > .Block {
			display: inline-flex;
			align-items: center;
		}

		.ClientRating {
			height: 15px;
			line-height: 15px;
			margin-left: 0px;
		}

		.EditPhonecallClient__info-icon {
			transition: all ease-out 200ms;

			&--active {
				color: $green;

				&:hover {
					color: $dark-green;
				}
			}

			&--inactive {
				color: $grey-6;

				&:hover {
					color: $grey-8;
				}
			}
		}
	}
	&__tools {
		right: 10px;
		top: 0;
		position: absolute;
	}
	&__edit-col {
		overflow: hidden;
	}

	.RelationsSignalWidget {
		margin: 0 -8px -8px;
	}
}
