@import "~StyleVariables";

.InactivateContacts {
	--modalContentWidth: 800px;
	grid-template-rows: 50px 1fr;

	.EditorHeader {
		width: 100%;
		padding-left: $space * 4;
	}

	.ModalContent {
		padding-top: 0;
	}

	.ModalContent__content {
		max-height: 75%;
	}
	
	.StateFrame {
		margin: $space * 5 0 ;
	}

	.Headline {
		padding-top: $space * 10;
	}

	.TableHeader {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.TableHeader__column {
		width: 50%;
	}

	&__table-loader {
		margin: auto
	}

	&__table-container {
		max-height: 75%;
		overflow: auto;
		border: 1px solid $grey-6;
		border-radius: $space;
	}
}