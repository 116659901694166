@import "~StyleVariables";

.FileUploadDropzoneActive {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $white;
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 200ms;
	z-index: 1000;
	text-align: center;
	padding-top: 30px;

	@keyframes animate-width {
		0% {
			rx: 40;
		}
		50% {
			rx: 50;
		}
		100% {
			rx: 40;
		}
	}

	@keyframes animate-bounce {
		0% {
			transform: translateY(0%);
		}
		50% {
			transform: translateY(10%);
		}
		100% {
			transform: translateY(0%);
		}
	}

	&--visible {
		visibility: visible;
		opacity: 1;
	}

	svg.file-drop-icon .shadow {
		animation: animate-width 2s ease-out infinite;
	}

	svg.file-drop-icon .card {
		animation: animate-bounce 2s ease-out infinite;
	}
}
