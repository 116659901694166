@import "~StyleVariables";

.CalendarViewLegend {

	&__dropdown {
		border: $border;
		border-radius: $space;
	}

	&__circle {
		font-size: x-small;
		color: $super-light-green;

		&--event {
			color: #A3C6F5;
		}
	}

	&__separator {
		border-left: $border;
		height: 5 * $space;
	}

	&__type {
		border-bottom: $border;
	}
	
	&__go-to-admin {
		background-color: $grey-2;
	}
}