@import "~StyleVariables";

.FolderTreeBranch {
	margin: 0 4px;
	
	.Text {
		padding: 6px 8px;
		border-radius: 6px;
		&:hover {
			background-color: $grey-5;
		}
	}

	&--isOver {
		.Text {
			box-shadow: 0 0 0 2px $red;
		}
	}

	&__chevron-icon {
		margin-left: 8px;
		font-size: 14px !important;
	}

	&__dropdown {
		width: 170px;
		border-radius: 4px;
		div {
			padding: 6px;
			cursor: pointer;
			&:hover {
				background-color: $grey-3;
			}
		}
		.Icon {
			margin-right: 4px;
		}
	}
	
	&__edit-dropdown {
		width: 250px;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 18px;
		gap: 12px;
		.Input {
			width: 100%;
		}
		.Button {
			width: 60%;
		}
	}
}