@import "~StyleVariables";

.SidebarOptionsDropdown {
	.Button {
		&:hover {
			background-color: $grey-2;
		}
	}
	&__schedule-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;

		.schedule-button-text {
			padding: 4px 8px;
		}
		&--disabled {
			&:hover {
				cursor: auto;
			}

			.Icon {
				color: $grey-9;
			}
		}

		&--blue {
			.Icon {
				color: $bright-blue;
			}
		}
	}
	.TableColumn {
		padding: 0 4px 0 12px;
	}

	.DropDownMenu__content {
		min-width: 0;
		width: fit-content;
		z-index: 1005;
		text-align: left;
	}
}
