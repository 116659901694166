@import "../../../styles/variables";

.DesiredOutcomeFeedback {
	margin-bottom: 40px;
	display: flex;

	.Label {
		font-weight: $boldFontWeight;
	}
}

.DesiredOutcomeFeedback__chevron-container {
	height: 100%;
	width: 50px;

	.Icon {
		position: relative;
		top: 200px;
		font-size: 16px;
		cursor: pointer;
	}

	.Icon:hover {
		text-shadow: 1px 1px rgb(200, 200, 200);
	}
}

.DesiredOutcomeFeedback__last-question-wrap {
	width: 70%;
}

.DesiredOutcomeFeedback__checkbox-wrap {
	margin-top: 70px;
	margin-bottom: 40px;

	.Row {
		justify-content: space-between;
	}

	.Row:nth-child(1) {
		padding-left: 9px;
		padding-right: 6px;
		margin-bottom: 5px;
	}

	.Row:nth-child(3) {
		margin-left: -34px;
		margin-right: -38px;
		margin-top: 5px;
	}

	.Checkbox {
		border-radius: 100%;
		border: 1px solid lightgray;
	}

	.Checkbox--checked {
		border: 1px solid $bright-blue;
	}
}

// last button {
// 	margin-bottom: 30px;
// }

.DesiredOutcomeFeedback__content {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: calc(100% - 100px);

	.Textarea {
		margin-bottom: 40px;
	}

	.Button:last-child {
		margin-bottom: 25px;
		margin-top: 10px;
	}
}

.DesiredOutcomeFeedback__text {
	width: 100%;

	.Headline,
	.Text {
		white-space: unset;
		word-break: break-word;
	}

	.Headline {
		text-align: center;
	}

	.Text {
		font-size: 22px;
		margin-top: 30px;
	}
}

.DesiredOutcomeFeedback__grid {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(3, 210px);
	grid-auto-rows: 180px;
	grid-row-gap: 25px;
	grid-column-gap: 25px;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: 30px;
}

.DesiredOutcomeFeedback__gridItem {
	cursor: pointer;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	$topDivHeight: 135px;

	div:nth-child(1) {
		font-size: 48px;
		height: $topDivHeight;
	}

	div:nth-child(2) {
		height: calc(100% - #{$topDivHeight});
	}
}

.DesiredOutcomeFeedback__progress-wrap {
	display: flex;
	justify-content: center;
}

.DesiredOutcomeFeedback__progress {
	height: 14px;
	width: 14px;
	border-radius: 100%;
	background-color: blue;
	margin-left: 3px;
	margin-right: 3px;
	background-color: $grey-6;
}

.DesiredOutcomeFeedback__progress--current {
	background-color: $bright-blue;
}

.DesiredOutcomeFeedback__gridItem:hover {
	box-shadow: 0 5px 5px 1px rgba(200, 200, 200, 1);
}
