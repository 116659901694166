@import "~StyleVariables";

.AccountManagerFilters {
	align-items: start;

	& > .Text {
		margin-top: 5px;
	}

	&__toggle-label {
		display: inline-block;
		margin-left: $space;
	}
}