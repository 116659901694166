@import "~StyleVariables";
$toolbar-height: 40px;

.DocumentEditorToolbar {
	position: sticky;
	top: 50px;
	background-color: $white;
	box-shadow: 0px 1px 2px rgba(29, 61, 72, 0.2);
	height: $toolbar-height;
	z-index: 1004;

	.Button {
		height: $toolbar-height;
		line-height: $toolbar-height;
		text-decoration: none;
	}

	&__history-buttons {
		padding-top: 9px;

		.Button {
			height: 23px;
			line-height: 23px;
			padding: 0 7px;
			font-size: 12px;
			color: $black;
			background-color: transparent;
			border: none;
		}
	}

	&__preview {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 0px 16px;
		gap: 10px;
		position: absolute;
		width: 320px;
		right: 0px;

		.Button {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			padding: 0px;
			gap: 8px;
		}

		.Text {
			width: 53px;
			height: 20px;
			font-family: "Inter";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			display: flex;
			align-items: center;
			text-align: center;
			color: $green;
			flex: none;
			order: 1;
			flex-grow: 0;
		}
	}

	.DropDownMenu {
		height: 100%;
	}

	.DropDownMenu__content {
		right: 0;
		top: 42px;
		width: 380px;

		.Tooltip {
			width: 100%;
		}
	}

	&__preview-drop-down {
		background-color: $white;
		padding: 16px 12px;
		gap: 16px;
		display: flex;
		flex-direction: column;
	}

	&__preview-drop-down-info {
		background-color: $super-light-blue;
		padding: 8px;
		border-radius: 4px;
		text-align: left;
		font-size: 12px;
		color: $blue;
	}

	&__preview-drop-down-search {
		height: 274px;
		border: 1px solid $grey-6;
		border-radius: 4px;
	}

	&__preview-drop-down-orders {
		.Text {
			text-align: left;
			font-weight: 600;
			font-size: 14px;
		}
	}

    &__preview-drop-down-search-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

	&__preview-drop-down-search-content {
		position: relative;
	}

    &__preview-drop-down-search-content {
        overflow: auto;
        flex-grow: 1;
        border-top: 1px solid $grey-6;
    }

	&__preview-drop-down-button {
		width: 100%;
	}

	&__preview-drop-down-search-top {
		padding: 4px;

		.Input {
			width: 100%;
			
            .Input__input {
				background-color: $grey-4;
                &::placeholder {
                    color: $grey-10;
                }

			}

            .Icon {
                color: $grey-10;
            }

            
		}
	}

	&__order {
		padding: 4px 12px;
		gap: 12px;
		border-bottom: 1px solid $grey-6;
		text-align: left;
		display: flex;
		flex-direction: row;
		word-break: break-word;

		&-title {
			font-size: 14px;
			color: $black;
		}

		&-text {
			font-size: 12px;
			color: $grey-11;
		}

		.RadioItem {
			margin: auto;
		}
	}

	&__loading-orders {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	&__search-info {
		height: 100%;
		width: 100%;
		padding: $padding;
		text-align: left;
		font-size: 12px;
		color: $grey-11;
	}

}
