@import "variables";

.border-left {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 4px !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 4px !important;
}

.label-inactive {
	background-color: $grey-4;
	color: $grey-11;
	border: 1px solid $grey-7;
}

span.grey,
i.grey {
	color: $grey-8;
}

/* links */
a.remove {
	color: $red;
}

a.toggler {
	text-decoration: none;
	border-bottom: 1px dotted;
}

.table-row-loader {
	$size: 33px;
	text-align: center;
	width: 26px;
	line-height: $size;
	height: $size;
}

/* Modal triggers */
.warning-trigger {
	&:hover,
	&.hover {
		color: $orange !important;
	}
}

.normal-trigger {
	&:hover,
	&.hover {
		color: $blue !important;
	}
}
.similarCompanies {
	background-color: $grey-4 !important;
	color: $grey-10 !important;
	cursor: default !important;
}
.delete-trigger:hover {
	&:hover,
	&.hover {
		color: red !important;
	}
}

.up-subtitle {
	color: $grey-10;
	border-bottom: 1px solid $grey-8;
	font-size: 12px;
	text-transform: uppercase;
	padding: 2px 10px;
	display: block;
}

.avatar-img {
	$size: 30px;
	height: $size;
	width: $size;
	border: 1px solid $grey-8;
	display: inline-block;
	@include border-radius($size);
}

/* INDICATORS */
.indicator {
	$radius: 3px;
	display: inline-block;
	border: 1px solid $grey-8;
	background-color: $grey-2;
	color: $grey-10;
	text-transform: uppercase;
	padding: 4px 8px;
	font-size: 10px;
	margin-left: -1px;
	@include border-radius($radius);

	&.valid {
		color: $white;
		background-color: $bright-green;
	}

	&:first-child {
		@include border-radius($radius 0 0 $radius);
	}

	&:last-child {
		@include border-radius(0 $radius $radius 0);
	}
}
/* END INDICATORS */

/* Dropdown */
.dropdown-menu {
	height: auto;
	max-height: 60vh;
	overflow: auto;

	@include border-radius(0);

	@mixin hover() {
		background-color: $grey-4;
		color: $black;
	}

	&.right {
		left: initial;
		right: 0;
	}

	li {
		&:active a,
		&.active a {
			@include hover();

			&:hover,
			&.hover {
				@include hover();
			}
		}

		&.dropdown-header {
			color: $black;
			font-weight: $boldFontWeight;
			margin-top: 4px;
		}

		a {
			cursor: pointer;

			&:hover,
			&.hover {
				@include hover();
			}

			[class^="up-icon-"],
			.fa {
				width: 20px;
			}

			&.remove {
				color: $red;
			}
		}

		&.input-row {
			padding: 5px;
		}
	}
}

.setIconWidth {
	.Icon {
		width: 20px;
	}
}

div.bootstrap-select {
	.btn.btn-link {
		text-decoration: none;

		&:hover,
		&.hover {
			text-decoration: none;
		}

		&:focus,
		&.focus,
		&:active,
		&.active {
			outline: none !important;
		}
	}

	.dropdown-menu {
		li {
			&.selected a,
			&.hover a,
			&:hover a {
				background-color: $medium-blue;
				color: $white;
			}

			a {
				&:focus {
					outline: none !important;
				}
			}
		}
	}

	&.group-select {
		margin-left: -24px;
	}

	&.btn-group:not(.input-group-btn),
	&.btn-group {
		margin-bottom: 0;
	}
}

.empty-field {
	color: $grey-8;
}

#up-action-menu {
	width: 300px;
	padding: 10px;
	background-color: $white;
	position: fixed;
	right: 0px;
	margin: 20px;
	box-shadow: 0 0 10px $grey-10;
	color: $grey-13;
	font-size: 13px;
	@include opacity(0.8);

	&:hover,
	&.hover {
		@include opacity(1);
	}

	#close {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 14px;
		color: $grey-8;
		cursor: pointer;

		&:hover,
		&.hover {
			color: $black;
		}
	}

	ul#actions {
		list-style-type: none;
		margin: 0;
		margin-top: 10px;
		padding: 0;

		li.action {
			margin-top: 5px;
		}
	}
}

#up-curtain {
	@include curtain();
}

.up-ref-empty {
	color: $grey-8;
}

.up-ref-loader {
	display: none;
}

.up-inline-edit-wrap {
	position: relative;
	display: inline-block;
	border: 1px solid transparent;
	@include border-radius(3px 0 0 3px);
	background-color: transparent;

	.up-inline-edit-value {
		display: inline-block;
		padding: 4px;
		cursor: text;
	}

	.up-inline-edit-icon {
		display: none;
		padding-top: 5px;
		background-color: $grey-2;
		@include border-radius(0px 3px 3px 0px);
		border: 1px solid $grey-8;
		width: 20px;
		position: absolute;
		top: -1px;
		bottom: -1px;
		text-align: center;
		cursor: pointer;
	}

	.up-inline-edit-input {
		display: none;
		position: absolute;
		left: -1px;
		top: -1px;
		@include border-radius(3px);
		border: 1px solid $grey-8;
		height: 29px;
	}

	&:not(.open) {
		&:hover,
		&.hover {
			border-color: $grey-8;
			background-color: $super-light-yellow;

			.up-inline-edit-icon {
				display: inline-block;
			}
		}
	}
}

.create-account-result-wrap {
	&:first-child {
		padding-right: 0;
	}

	&:last-child {
		padding-left: 0;
	}

	.head {
		line-height: 25px;
		border-bottom: 1px solid $grey-8;
		width: 100%;
		display: block;
		padding-left: 5px;
	}

	.item {
		$padding: 10px;
		$line-height: 25px;
		position: relative;
		padding: $padding 0;
		border-bottom: 1px solid $grey-2;
		cursor: pointer;
		padding: 5px;

		&:hover {
			.subtitle {
				color: $black;
			}

			a {
				text-decoration: underline;
			}

			background-color: $grey-2;
		}
		&.matched {
			.subtitle {
				color: $black;
			}

			a {
				text-decoration: underline;
			}
		}

		&.matched {
			background-color: $grey-2;
		}

		&:hover {
			.action-indicator {
				display: inline-block;
			}
		}

		&:last-child {
			border: none;
		}

		a {
			height: $line-height;
			line-height: $line-height;
		}

		.action-indicator {
			display: none;
			position: absolute;
			right: 5px;
			top: $padding;
			background-color: $grey-2;
			color: $grey-10;
			border: 1px solid $grey-8;
		}

		.subtitle {
			color: $grey-10;
			width: 50%;
			height: $line-height;
			line-height: $line-height;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;

			&.pull-right {
				text-align: right;
			}
		}
	}
}

// select 2 style
li.select2-result-with-children.select2-result-unselectable > .select2-result-label {
	color: $blue;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 11px;
	background-color: $grey-4;
	padding-top: 5px;
	padding-bottom: 5px;
}

.select2-container-multi .select2-choices .select2-search-choice {
	font-size: 12px;

	&.user-token {
		color: $white;
		background-color: $blue !important;
	}

	&.conflicting-token {
		color: #fff;
		background-color: $orange !important;
	}

	&.contact-token {
		color: $white;
		background-color: $bright-blue !important;
	}

	& > a.select2-search-choice-close {
		background-image: none !important;
		color: inherit;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		left: 5px;
		@include opacity(0.8);

		&:hover {
			text-decoration: none;
			@include opacity(1);
		}

		&:before {
			content: "\f00d";
		}
	}
}

.select2-result {
	.subtitle {
		color: $blue;
		font-size: 11px;

		&.grey {
			color: $grey-8;
		}
	}

	.icons {
		position: absolute;
		right: 0;
		top: 50%;
		height: 20px;
		margin-top: -10px;

		.Icon {
			color: $grey-8;
			width: 20px;
			text-align: center;
			font-size: 10px;

			&.icon-selected {
				color: $green;
			}
		}
	}

	&.select2-highlighted {
		.subtitle {
			color: $white;
		}

		.icons {
			.Icon {
				color: rgba($white, 0.5);

				&.icon-selected {
					color: $white;
				}
			}
		}
	}
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
	margin-right: 26px;
}

/* the class names cant begin with select2 hence the up prefix */
.up-select2--inline {
	box-shadow: none;
	vertical-align: top;
	display: inline-block;

	&.select2-container .select2-choice {
		background: 0 0;
		border: none;
		display: block;
		margin: 0;
		padding: 0;
		border-radius: 0;
		outline: 0;
		border-bottom: 1px solid $grey-9;
		height: initial;
		line-height: inherit;
		color: inherit;
	}

	&.select2-container.select2-dropdown-open .select2-choice {
		border-bottom-color: $bright-blue;
	}

	.select2-chosen,
	.select2-choice > span:first-child,
	&.select2-container .select2-choices .select2-search-field input {
		padding: 0px;
	}

	&.select2-container .select2-choice > .select2-chosen {
		margin-right: 20px;
	}

	&.select2-container .select2-choice .select2-arrow {
		display: flex;
		justify-content: center;
		border-left: none;
		@extend .fa;
	}

	&.select2-container .select2-choice .select2-arrow:before {
		@extend .fa-sort-down:before;
	}

	&.select2-container .select2-choice .select2-arrow b,
	&.select2-container .select2-choice div b {
		display: none;
	}
}

.up-select2--inline-no-border {
	&.select2-container .select2-choice {
		border-bottom: none;
	}

	&.select2-container.select2-dropdown-open .select2-choice {
		border-bottom: none;
	}
}

.up-select2--inline__dropdown {
	margin-top: 0;
}

// .select2--inline {

// end of select 2 style

// Override fa-spin makes it spin faster
.fa-spin {
	-webkit-animation: fa-spin 0.7s infinite linear;
	-moz-animation: fa-spin 0.7s infinite linear;
	-o-animation: fa-spin 0.7s infinite linear;
	animation: fa-spin 0.7s infinite linear;
}
// end of fa override

.no-overflow {
	width: 100%;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.active-indicators {
	.fa {
		background-color: $white;
		border: 1px solid $grey-8;
		color: $grey-8;
		text-align: center;
		border-radius: 2px;
		width: 15px;
		height: 15px;
		&.active {
			color: $grey-13;
			border-color: $grey-13;
		}
	}
}

// Star indiciator madness
.star-indicator {
	font-size: 18px;
	color: $grey-8;

	&.fa-star {
		color: rgba($yellow, 1);
	}
}

// Lead table new lead
.status-new-lead {
	background: rgba($blue, 0.8);
	padding: 3px;
	border-radius: 4px;
	color: $white;
}

.ace_editor {
	height: 490px;
}

.up-type-ahead-items {
	padding: 0;
	display: none;
	position: absolute;
	list-style: none;
	width: calc(100% - 36px);
	z-index: 11;
	background: $white;
	color: $black;
	border-color: $grey-8;
	border-radius: 0 0 4px 4px;
	-webkit-box-shadow: 0 4px 5px rgba($black, 0.15);
	box-shadow: 0 4px 5px rgba($black, 0.15);

	.type-ahead-item {
		font-size: 14px;
		line-height: 20px;
		padding: 10px;
		cursor: pointer;
		user-select: none;
		height: 39px;

		&.active {
			background-color: $bright-blue;
			color: $white;
		}
	}
}

.up-type-ahead-items.visible {
	display: block;
}

.grey-popover {
	&.low-z-index {
		z-index: 1000 !important;
	}

	&.tooltip {
		opacity: 1;
	}

	.tooltip-inner {
		background-color: $bright-blue;
		color: $white;
	}

	.tooltip-arrow {
		border-right-color: $bright-blue !important;
	}
}

.clickable {
	cursor: pointer !important;
}
