.DetectedDuplicatesListTableHeader th {
	position: sticky;
	top: 0;
	z-index: 999;
	width: 40%;
}

.DetectedDuplicatesListTableHeader th:last-child {
	width: 20%;
}

.DetectedDuplicatesLoadingTitle.Skeleton:before {
	content: 'Upsales Technology AB'; /* Dummy stuff */
}

.DetectedDuplicatesLoadingSubtitle.Skeleton:before {
	content: 'Last nodified: 1 year ago'; /* Dummy stuff */
}

.DetectedDuplicatesListTableRow > .TableColumn {
	padding-top: 5px;
	padding-bottom: 5px;
}

.DetectedDuplicateListTableColumn__no-data {
	padding: 70px 0;
	text-align: center;
	background-color: #f7fafc;
}

.DetectedDuplicateListTableColumn__no-data > .Text {
	margin-top: 10px;
}
