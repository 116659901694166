@import "~StyleVariables";

.SearchInput {
	position: relative;
	margin-right: 12px;
	transition: all 0.5s ease;

	&--active {
		transition: all 0.5s ease;
		.Icon:first-of-type {
			scale: 1 !important;
			opacity: 1 !important;
		}
		.Icon:nth-of-type(2) {
			color: $grey-6;
			cursor: default !important;
			rotate: -360deg !important;
		}
		.Input {
			width: 180px !important;
			opacity: 1 !important;
			.Input__input {
				padding-left: 28px !important;
			}
		}
	}

	.Icon:nth-of-type(2) {
		rotate: 0deg;
		transition: all 0.5s ease;
		z-index: 1;
		position: absolute;
		font-size: 16px;
		top: 3px;
		left: 3px;
		cursor: pointer;
		padding: 6px;
	}

	.Icon:first-of-type {
		position: absolute;
		left: -20px;
		top: 10px;
		transition: all 0.5s ease;
		transition-timing-function: cubic-bezier(0.8, 1.56, 0.8, 1);
		scale: 0;
		opacity: 0;
		cursor: pointer;
	}

	.Input {
		transition: all 0.5s ease;
		opacity: 0;
		width: 34px;
		.Input__input {
			padding-left: 4px;
		}
	}

	&:not(.SearchInput--active) {
		&:hover {
			.Icon:nth-of-type(2) {
				scale: 1.1;
			}
		}
	}
}