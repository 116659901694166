@import "~StyleVariables";

.ListSocialEventsCalendar {
	&__header {
		padding: 15px;
		height: 62px;
		display: flex;
	}

	&__calendar-events {
		position: relative;
		height: 84px;
		padding-top: 30px;
		overflow: hidden;

		&--hoverable:hover {
			position: absolute;
			overflow: visible;
			height: auto;
			z-index: 1000;
			background-color: $white;
			box-shadow: 0 2px 5px rgba($black, 0.5);
			transition: background-color ease-out 200ms;
			left: -10px;
			right: -10px;
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 10px;
			
			.ListSocialEventsCalendar__day-number {
				left: 20px;
			}
		}
	}

	&__day-number {
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 12px;
	}

	&__today {
		display: inline-block;
	}

	.Calendar__Day {
		vertical-align: top;

		&-header {
			font-size: 12px;
			text-align: left;
			padding: 0 10px;
			line-height: 20px;
			height: 20px;
			font-weight: normal;
			color: black;
		}
	}

	.Calendar__Day:hover.Calendar__Day--today {
		background-color: initial;
		border-color: initial;
		color: initial;
	}

	&__event {
		color: $white;
		background-color: $grey-10;
		font-size: 12px;
		height: 18px;
		line-height: 18px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 0 10px;
		border-radius: 3px;
		margin: 4px 5px;
		text-align: left;
		transition: all ease-out 200ms;

		&:hover {
			background-color: $grey-11;
		}

		&--launched {
			background-color: $medium-purple;

			&:hover {
				background-color: $purple;
			}
		}

		&--passed {
			background-color: $grey-6;
			color: $black;

			&:hover {
				background-color: $grey-7;
			}
		}

		&--canceled {
			background-color: $orange;

			&:hover {
				background-color: $dark-orange;
			}
		}
	}
}
