table.timeline {
	$icon-size: 30px;
	font-size: 12px;
	margin: 0 20px 10px 10px;

	// Styles for card timelines inside drilldown in ex: assignedLeads.
	&.drilldown-timeline {
		margin-left: 0;
		//margin-top: 22px;

		tbody {
			background-color: $white;
			border-radius: 2px;
			-webkit-box-shadow: 1px 1px 3px rgba($black, 0.1);
			box-shadow: 1px 1px 3px rgba($black, 0.1);
			-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='$grey-12')";
			filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='$grey-12');
			-khtml-border-radius: 2px;
			display: block;
			margin-bottom: 2px;
			overflow: hidden;
		}

		& > tbody > tr {
			&.drilldown-spacer {
				height: 2px;
				box-shadow: none;
				width: 100%;
				background-color: $grey-2;
				-webkit-box-shadow: inset 1px 1px 2px 0px rgba($black, 0.1);
				-moz-box-shadow: inset 1px 1px 2px 0px rgba($black, 0.1);
				box-shadow: inset 1px 1px 2px 0px rgba($black, 0.1);
			}

			td {
				border-left: 0;
				padding-top: 10px;
				background-color: $white;
			}
		}
	}

	.load-row:hover td,
	.load-row.hover td {
		background-color: transparent;
	}

	.ng-enter {
		&.event-row {
			@include transition(all linear 200ms);
			opacity: 0;
		}
	}

	.ng-enter-active {
		&.event-row {
			opacity: 1;
		}
	}

	.ng-leave {
		&.event-row {
			@include transition(all linear 200ms);
			opacity: 1;
		}
	}

	.ng-leave-active {
		&.event-row {
			opacity: 0;
		}
	}

	& > tbody > tr {
		&.upcoming {
			.icon.overdue {
				.fa {
					color: $white; //$red !important;
				}
			}

			.date.overdue {
				color: $red;
			}
		}

		&.event-row {
			&.created {
				.details-table-arrow {
					display: none;
				}
				.details-table {
					background-color: transparent;
					border: 0;
					margin-bottom: 40px;
					@include box-shadow(none);

					&:hover {
						border: 0;
					}
				}
			}
		}

		& > td {
			padding: 10px;
			padding-top: 0px;
			padding-bottom: 8px;
			position: relative;
			vertical-align: top;
			color: $grey-13;

			&.no-info {
				color: rgba($black, 0.2);
				font-size: $h2-font-size;
				text-align: center;
				padding-top: 30px;
				padding-bottom: 40px;
			}

			&.icon-td {
				z-index: 1 !important;
				padding-top: 20px;
				min-width: 50px;

				.line {
					border: 1px solid $grey-6;
					width: 1px;
					height: 100%;
					position: absolute;
					left: 24px;
					z-index: -1000 !important;
				}

				&.line-break {
					.icon {
						background-color: $grey-2;
						box-shadow: none;
						border-top: 2px solid $grey-6;
						border-radius: 0;
						width: 10px;
						border-bottom: 2px solid $grey-8;
						left: 10px;
					}
				}

				&.active {
					.line {
						border: 1px dashed $grey-6;
					}

					.icon {
						.Icon,
						.fa {
							color: $grey-11;
						}
						&.overdue {
							.Icon,
							.fa {
								color: $red;
							}
						}
					}
				}

				.icon {
					position: relative;
					color: $grey-11;
					border-radius: $icon-size;
					background-color: $grey-2;
					width: $icon-size;
					height: $icon-size;
					text-align: center;
					font-size: 20px;
					margin-bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;

					.Icon {
						display: block;
						line-height: 30px;
						color: $grey-11;
						font-size: 18px;

						&.Icon-envelope {
							line-height: 30px;
						}
						&.Icon-phone {
							line-height: 30px;
						}
						&.Icon-warning {
							position: absolute;
							top: 7px;
							right: 2px;
							line-height: 5px;
							color: $red;
							font-size: 9px;
						}
					}

					&.order,
					&.agreement {
						&.lost {
							background-color: $super-light-red;
							.Icon {
								color: $red;
							}
						}

						background-color: $super-light-green;
						.Icon {
							color: $green;
						}
					}

					&.soft-bounce {
						.Icon {
							color: $bright-orange;
						}
					}

					&.hard-bounce {
						.Icon {
							color: $red;
						}
					}

					&.unsub {
						.Icon {
							color: $red;
						}
					}
				}
			}

			table.drop-down-table {
				table-layout: fixed;
				width: 100%;

				td {
					padding-right: 20px;
					vertical-align: top;
				}
			}

			.details-table-arrow {
				width: 10px;
				height: 10px;
				position: absolute;
				left: 0;
				top: 26px;
				border-style: solid;
				border-width: 9px 6px 9px 0;
				border-color: transparent $grey-4 transparent transparent;
				@include transition(all linear 100ms);

				&::after {
					content: "";
					width: 10px;
					height: 10px;
					position: absolute;
					left: 1px;
					top: -8px;
					border-style: solid;
					border-width: 8px 6px 8px 0;
					border-color: transparent $white transparent transparent;
				}
			}

			table.details-table {
				table-layout: fixed;
				background-color: $white;
				@include box-shadow(1px 1px 3px rgba($black, 0.1));
				@include border-radius($radius: 2px);
				margin-bottom: 0px;
				color: $grey-13;
				width: 100%;
				border-collapse: separate;
				@include transition(all linear 100ms);

				&.no-icon {
					margin-left: -20px;

					& + .details-table-arrow {
						display: none;
					}
				}

				.event-form-removed-user,
				.event-form-removed {
					font-style: italic;
					color: $grey-10;
				}

				.Icon-subaccounts {
					color: $grey-11;
					cursor: pointer;
				}

				.line {
					background-color: $grey-8;
					width: 1px;
					height: 100%;
					position: absolute;
					z-index: -1000 !important;
				}

				.open-article-spacing {
					margin-left: 8px;
				}

				.margin-bottom {
					margin-bottom: 4px;
				}

				.wrap-text {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					vertical-align: middle;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin: 8px 0px;
				}
				.news-timeline-row {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.news-timeline-img {
						margin-left: 20px;
						img {
							max-height: 85px;
							overflow: hidden;
							border-radius: $border-radius;
						}
					}

					.news-timeline-text {
						b {
							margin-bottom: 4px;
						}
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: flex-start;
					}
				}

				.pinned-section {
					vertical-align: middle;
					span {
						transition: all ease-out 200ms;
						font-size: 18px;
					}

					.active {
						color: $green;
						margin-top: 0px;
					}
					.inactive {
						color: $grey-8;
						margin-top: 0px;
					}
					.inactive:hover {
						color: $grey-10;
						margin-top: -4px;
					}
				}

				.grey {
					color: $grey-10;
				}

				.link-wrap {
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 60%;
					display: inline-block;
					white-space: nowrap;
					vertical-align: middle;
				}

				.avatar {
					width: 30px;
					height: 30px;
					border: 1px solid $grey-4;
					@include border-radius(16.5px);
				}

				.date {
					color: $grey-10;
					text-align: right;
					margin-top: 8px;
				}

				.description {
					color: $grey-10;
					font-style: normal;
				}

				&.mini {
					background-color: $white;
					@include box-shadow(0 0 0);
					margin-left: 0;
				}

				.event-expand-header {
					width: 45px;
				}

				&--signals {
					.details-table__row {
						box-shadow: 0px -1px 0px $grey-4;

						&__flex {
							display: flex;
							width: 100%;
							height: 100%;
							align-items: center;
						}

						&__icon {
							display: inline-flex;
							vertical-align: top;
							align-items: center;
							margin-right: 10px;
							font-size: 16px;
						}

						&__text {
							display: inline-block;
							vertical-align: top;
						}

						.Link {
							opacity: 0;
							transition: opacity 200ms ease-in;
							margin-left: auto;
						}

						&:hover .Link {
							opacity: 1;
						}

						&--double-line td {
							padding-top: 6px;
							padding-bottom: 6px;
						}
					}
				}

				th,
				td:not(.Calendar__Day):not(.Calendar__Week-header):not(.Calendar__Day-header):not(.Calendar__Week) {
					padding: 18px 12px 14px 14px;
					padding-right: 20px;
					vertical-align: top;

					&.fat {
						font-weight: $boldFontWeight;
					}

					&.align-right {
						text-align: right;
					}

					&.align-center {
						text-align: center;
					}

					&.no-overflow {
						color: $grey-13;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					&.no-padding {
						padding: 0px;
					}
					&.table-padding {
						padding: 18px 12px 14px 14px;
					}
					&.nowrap {
						white-space: nowrap;
					}
				}

				th {
					color: $grey-13;
					font-size: 13px;
					font-weight: $regularFontWeight;
					& > span.date-header {
						float: right;
						font-style: italic;
						color: $grey-8;
					}

					&.details-table-date {
						text-align: right;
						width: 135px;
						padding-top: 25px;
						padding-left: 10px;
					}

					&.detail-expand-icon {
						text-align: right;
						color: $grey-10;
						width: 40px;
						padding: 25px 0 14px 14px;
					}

					&.lead-source {
						padding-top: 18px;
						width: inherit;

						.no-value {
							margin-top: 11px;
						}

						@media (max-width: 895px) {
							display: none;
						}

						.source-icon {
							display: inline-block;
							vertical-align: top;
							padding: 10px;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.source-text {
							display: inline-block;
							max-width: 65%;
							overflow-x: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							text-align: left;

							.subtitle {
								color: $grey-10;
								font-size: 11px;
							}
						}
					}
				}

				td:not(.Calendar__Day):not(.Calendar__Week-header):not(.Calendar__Day-header):not(.Calendar__Week) {
					word-break: break-word;

					&.bullet {
						color: $grey-11;
						text-align: center;
					}

					.sub-table {
						width: 100%;

						td {
							padding: 6px;
						}
					}
				}

				.social-event-table {
					width: 100%;

					tr:not(.header) {
						border-top: 1px solid $grey-3;
					}

					tr:not(.header) td {
						vertical-align: middle;
						padding: 0 15px;
						height: 50px;
					}

					.green {
						color: $bright-green;
					}

					.red {
						color: $red;
					}

					.subtitle {
						font-size: 10px;
						color: $grey-10;
					}
				}

				&.mail-group,
				&.mail {
					.meta {
						color: $grey-10;
						padding-right: 7px;

						.seen-meta,
						.clicked-meta {
							border-left: 1px solid $grey-4;
							padding: 14px;
						}

						.clicked-meta {
							padding-right: 10px;
						}
					}
					.fa {
						color: $grey-10;
					}

					.have-value {
						color: $bright-blue;

						.fa {
							color: $bright-blue;
						}
					}

					.mail-group-table {
						width: 100%;

						tr {
							height: 40px;

							&.header {
								span {
									color: $grey-10;
								}
							}

							&.mail-row {
								border-bottom: 1px solid $grey-4;

								.title {
									color: $bright-blue;
								}
							}

							&.mail-row:last-child {
								border-bottom: 0;
							}

							td {
								padding: 10px;
								.title {
									color: $bright-blue;
								}
							}
						}
					}
				}

				.has-priority {
					position: relative;
					width: 100%;

					.event-info {
						max-width: calc(100% - 100px);
					}

					.high-priority {
						position: absolute;
						right: 15px;
						top: calc(50% - 7px);

						.priority-flag {
							display: inline-block;
							padding-right: 5px;
							color: $red;
						}
					}
				}
			}
		}
	}

	// Title row separating active and history events
	.timeline-cat-divider {
		width: 100%;
		height: 100px;
		padding: 0 7px;
		margin: 30px 0 -30px 0;

		.title {
			padding-top: 38px;
			float: left;

			&.no-float-title {
				float: initial;
			}
		}

		.counter {
			font-size: 18px;
			color: $grey-10;
		}

		.icon-td {
			padding-top: 40px;

			.icon {
				width: 16px;
				height: 16px;
				border: 2px solid $grey-6;
				border-radius: 50%;
				text-align: center;
				box-shadow: none;
				background-color: $grey-2;
				margin: 0 auto;
			}
		}

		> td:not(.icon-td) {
			width: 100%;
		}

		.timeline-filter-group {
			margin-top: 30px;
			float: right;

			.btn {
				@include transition(all linear 200ms);
				color: $grey-10;
				background-color: $grey-4;
				border: none;

				&.active {
					background-color: $white !important;
					color: $black;
					z-index: 0;

					small {
						color: rgba($white, 0.7);
					}
				}

				.caret {
					border-top-color: rgba($blue, 0.5);
				}

				&:hover:not(.active) {
					background-color: $grey-4 !important;
					box-shadow: none;
					outline: none;

					.caret {
						border-top-color: $blue;
					}
				}

				small {
					color: rgba($grey-13, 0.5);
				}
			}

			.btn-group {
				.btn-news {
					margin-left: 12px;
					border-radius: 0 $border-radius $border-radius 0;
				}
				margin-left: 10px;

				ul li {
					a {
						padding: 6px 10px 6px 10px;

						&:hover {
							background-color: rgba($blue, 0.04);
						}

						&.active {
							background-color: rgba($blue, 0.07);

							&::after {
								content: "";
								color: rgba($blue, 0.3);
								font-family: FontAwesome;
								float: right;
							}
						}
					}

					.fa {
						background-color: rgba($blue, 0.1);
						border-radius: 50%;
						padding: 4px;
						width: 20px;
						height: 20px;
						font-size: 12px;
						text-align: center;
						margin-right: 6px;
						color: $blue;
					}
				}
			}
		}

		.timeline-toggle-no-score {
			position: absolute;
			color: $bright-blue;
			bottom: 0px;
			right: 20px;
			z-index: 5;

			&.no-score {
				color: $grey-10;
			}
		}

		.timeline-toggle-no-score:hover {
			cursor: pointer;
		}
	}

	// Indicator to display months in table
	.timeline-date-divider {
		.title {
			text-transform: uppercase;
			letter-spacing: 0.5px;
			font-size: 10px;
			color: $grey-8;
			padding: 2px 10px 5px;
		}

		.icon-td {
			padding-top: 10px;
		}
	}
}
