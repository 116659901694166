@import "~StyleVariables";

.SchedulingButton {
	width: 264px;
	height: 122px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $white;

	border: 3px solid transparent;
	border-radius: 5px;
	white-space: normal;
	overflow: hidden;
	transition: all ease-out 200ms;
	box-shadow: $box-shadow-md;

	&:hover {
		background-color: $grey-1;
		border-color: $grey-1;
		text-decoration: none;
		box-shadow: $box-shadow-xl;
		cursor: pointer;
	}

	&--selected {
		border: 3px solid $green !important;
		opacity: 1 !important;
		&:hover {
			.check-icon {
				outline: 4px solid $grey-6;
				transition: ease-in-out 0.05s;
			}
		}
	}
	&__iconRow {
		position: relative;
		justify-content: center;
		margin-bottom: 4px;
		.icon {
			font-size: 16px;
		}
	}

	&__checkRow {
		position: absolute;
		height: 10px;
		bottom: 2px;
	}

	&__textRow {
		margin-bottom: 8px;
		justify-content: center;
		align-items: flex-end;
		height: fit-content;

		&--removeMargin {
			margin-bottom: 0px;
		}
	}

	&--disabled {
		.RadioItem {
			.Icon {
				color: $grey-10;
			}
			background-color: $grey-4;
		}
		opacity: 0.5;

		&:hover {
			background-color: $grey-1;
			box-shadow: $box-shadow-md;

			border-color: transparent;
		}
	}

	.Text {
		margin: auto;
		color: $grey-11;
	}

	.Row {
		width: 100%;
		height: 20px;
		text-align: center;

		.check-icon {
			border-radius: 50%;
			border: 1px solid $grey-6;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: auto;
			overflow: hidden;
		}
	}
}
