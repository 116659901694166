@import "~StyleVariables";

.CustomerPortfolioWidget {
	display: flex;
	flex-direction: column;
	border: 1px solid $grey-6;
	border-radius: 6px;
	margin: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	min-height: 130px;
	min-width: 400px;
	flex: 0 0 calc(33.33% - 16px);
	flex-grow: 1;


	&--isBig {
		min-width: 816px;
		flex: 0 0 calc(66.66% - 16px);
	}

	&__content {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		flex-direction: column;

		.ReportcenterWidget__content {
			flex-direction: column;
			max-height: 226px;

			.TableWidget__cell:not(:first-of-type) {
				min-width: 120px;
			}

			.BigNumberWidget .Title {
				font-size: 38px;
			}
		}
	}

	&__content--blur {
		filter: blur(6px);
		pointer-events: none;
	}

	.BarchartWidgetBar {
		height: 12px;
		margin-top: 0px;
		transform: translateY(-50%)
	}

	.TableWidget__cell:last-of-type {
		text-align: end;
		
		.Text {
			justify-content: end;
		}
	}

	.TableHeader__column:last-of-type {
		text-align: end;
		padding-right:15px;
		padding-left:0;
	}
}