@import "~StyleVariables";

.EditSummary {
	display: flex;
	justify-content: center;

	&__content {
		padding-top: 32px;
		width: 800px;

		.Animate {
			margin-top: 32px;
		}
	}

	&__firstSection {
		display: flex;
	}

	.TableHeader {
		&__column:last-of-type {
			text-align: end;
		}
	}

	&__align-end {
		text-align: end;
	}

	&__strikethrough {
		.Icon {
			margin-right: 4px;

			&-arrow-right {
				margin-left: 4px;
			}
		}

		.Text {
			display: inline;
		}

		&__old-value {
			text-decoration: line-through;
		}
	}

	&__topSection {
		display: flex;
		justify-content: space-between;

		&__section {
			display: flex;
			flex-direction: column;

			&__leftSide {
				margin-left: 8px;
			}
		}

		&__rightSide {
			display: flex;
			gap: 24px;
		}

		&__verticalLine {
			width: 0px;
			height: 39px;
			border: 1px solid $grey-4;
		}
	}

	&__lollipop {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		width: 14px;

		&__head {
			background-color: $grey-6;
			border-radius: 50%;
			width: 14px;
			height: 14px;
		}

		&__line {
			border: 1px solid $grey-6;
			width: 0px;
			height: stretch;
			margin-top: 4px;
		}
	}

	&__info {
		width: stretch;
	}

	&__infoBox {
		margin-top: 8px;

		background-color: $grey-1;
		border-radius: $border-radius;
		padding: 16px;
		border: 1px solid $grey-6;

		.Text--lg {
			margin-bottom: 4px;
		}
	}
}
