@import "../../../../../styles/_variables.scss";

.SharedBookingPages {
	&__actions {
		max-width: 110px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;

		.inline-confirm-modal .Icon-trash,
		&--trash:hover .Icon-trash {
			color: $red;
		}

		.Button {
			padding: unset;
		}

		.Button:nth-child(2) {
			padding-left: 5px;
		}
	}

	&__meetingRowsDescriptions {
		td:first-child {
			width: 30%;
		}

		td {
			min-width: 30%;
		}

		.TableHeader__column {
			padding-left: 20px;
			white-space: nowrap;
		}

		.TableHeader__column:nth-child(1) {
			width: 70%;
		}
	}

	&__meetingInformation {
		width: 100%;

		.TableColumn:nth-child(1) {
			padding-left: 20px;
		}

		.TableColumn:nth-child(2) {
			padding-left: 20px;
		}

		.TableColumn:nth-child(3) {
			padding-left: 20px;
		}

		.TableColumn:nth-child(4) {
			padding-left: 10px;

			.Row {
				max-width: fit-content;
			}
		}
	}

	&__sharedBookingHeader {
		a {
			margin-left: 5px;
		}
	}

	&__sharedBookingSubHeader {
		display: flex;
		align-items: center;

		.Help {
			color: $bright-blue;
			margin-left: 5px;
		}
	}

	&__noSharedBookings {
		position: relative;
		left: 55%;
		font-size: 20px;
		width: fit-content;
		margin: 0 auto;
		transform: translateX(-50%);

		.Text {
			text-align: center;
			line-height: 40px;
			height: 20px;
			font-size: 20px;
			color: #a4b3c7;
			position: relative;
			top: -20px;
		}
	}

	&__sharedWith {
		> div {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			width: 15%;
			align-items: center;

			.Tooltip {
				position: relative;

				.generic-avatar {
					box-shadow: 0 0 0 2px $white;
				}
			}
			.Tooltip:nth-child(2) {
				position: relative;
				left: -45%;
			}
			.Tooltip:nth-child(3) {
				position: relative;
				left: -90%;
			}

			.Tooltip:nth-child(4) {
				position: relative;
				left: -25px;
			}
		}

		.Avatar {
			box-shadow: 0 0 0 2px $white;
		}
	}

	&__active {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 20px;
		min-width: 130px;
	}

	&__status {
		display: flex;
		align-items: center;

		.Icon {
			margin-right: 15px;
		}
	}
}
