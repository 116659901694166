@import "../../../../styles/variables";

.SharedViewsAdmin .CardHeader {
	padding: 0 15px;
}

.SharedViewsAdmin .CardHeader .CardHeader__actions {
	display: flex;
	align-items: center;
	justify-content: center;
}

.SharedViewsAdmin .TableColumn .Avatar + .Text {
	margin-left: 5px;
}

.SharedViewsAdmin .TableRow .TableColumn:last-child .Icon {
	opacity: 0;
	transition: all 0.3s;
}

.SharedViewsAdmin .TableRow:hover .TableColumn:last-child .Icon {
	opacity: 1;
}

.SharedViewsModal {
	height: 100%;
	position: relative;
}

.Modals .SharedViewsModal .ModalHeader,
.up-modal .SharedViewsModal .ModalHeader {
	position: absolute;
	padding: 0 $padding;
	border-top: none;
}

.Modals .SharedViewsModal .ModalContent,
.up-modal .SharedViewsModal .ModalContent {
	position: absolute;
	padding: 0;
	margin: 52px 0 56px 0;
	overflow-y: auto;
}

.Modals .SharedViewsModal .ModalControls {
	position: absolute;
}

.SharedViewsModal__Row {
	transition: all 0.3s;
}

.SharedViewsModal__Row .Avatar {
	width: 28px;
	height: 28px;
	line-height: 28px;
	font-size: 28px;
	margin-right: 10px;
}

.SharedViewsModal__Row .Avatar--initials {
	line-height: 28px;
}

.SharedViewsModal__Row .Avatar--gravatar {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	background-size: contain;
}

.SharedViewsModal__Row .Checkbox,
.SharedViewsModal__Row .Checkbox + .Text {
	margin-right: 10px;
}

.SharedViewsModal__Row .Icon {
	margin-right: 5px;
}

.SharedViewsModal__Row .Checkbox .Icon {
	margin-right: 0;
}

.SharedViewsModal__Row .Text {
	line-height: 25px;
}

.SharedViewsModal__Row .Pill {
	padding: 4px 5px;
	border-radius: 2px;
	background-color: $super-light-blue;
	border: 1px solid rgba(74, 144, 226, 0.1);
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	color: $bright-blue;
	font-size: 11px;
	font-weight: $regularFontWeight;
	line-height: 1;
	margin-left: auto;
	height: auto;
	margin-top: -2px;
}

.SharedViewsModal__Row .NotOwner {
	margin-left: auto;
	padding: 0;
	opacity: 0;
	transition: all 0.3s;
}

.SharedViewsModal__Row:hover {
	background-color: $grey-1;

	.NotOwner {
		opacity: 1;
	}
}

.SharedViewsModal__Row--Role {
	display: flex;
	align-items: center;
	background: $grey-1;
	height: 25px;
	box-shadow: inset 0 -1px 0 0 $grey-4;
	padding: 0 $padding;
}

.SharedViewsModal__Row--Role > .Icon {
	font-size: 12px;
}

.SharedViewsModal__Row--Role .Checkbox {
	margin-top: -2px;
}

.SharedViewsModal__Row--User {
	display: flex;
	height: 40px;
	align-items: center;
	background: $white;
	padding: 0 $padding;
}

.SharedViewsModal__Row--User:not(:last-child) {
	box-shadow: inset 0 -1px 0 0 $grey-4;
}

#admin.WrappedSharedViewsAdmin {
	#admin-root {
		left: 0;
	}
}
