@import "~StyleVariables";

.AdvancedSearchSidebar {
	--spacing: 12px;

	overflow-y: auto;

	max-width: 240px;

	height: calc(100% - 100px);

	.Input {
		margin-top: 8px;
		margin-left: var(--spacing);
		margin-right: var(--spacing);
	}

	&__header {
		display: flex;
		align-items: center;
		gap: 4px;

		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: var(--spacing);
		padding-right: var(--spacing);
	}

	&__section {
		border-bottom: 1px solid $grey-3;

		&__filterTitle {
			display: flex;
			cursor: pointer;
			padding-left: 20px;
			padding-top: 4px;
			padding-bottom: 4px;
			justify-content: space-between;
			align-items: center;
			
			&__active {
				background-color: $green;
				border-radius: 8px;
				height: 8px;
				width: 8px;
				margin-right: var(--spacing);
			}
			
			.Text--ellipsis {
				padding-right: 16px;
			}
		}
	}

	&__filterName {
		display: inline;

		&--highlighted {
			background-color: $super-light-green;
		}
	}
}
