@import "~StyleVariables";

.FocusModeTopListUserRows {
	&__list-row {
		height: 40px;
		display: flex;
		align-items: center;
		width: 100%;
		z-index: 1;
		position: relative;

		&::before {
			content: "";
			z-index: -1;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			opacity: 0.5;
			background-color: rgba($white, 0.1);
		}
		&--user-row::before {
			background-color: $greyeen;
		}
	}

	&__list-col {
		&--avatar {
			margin-left: 12px;
		}

		&--num {
			margin-right: 15px;
		}
	}

	&__placeholderRow {
		padding-left: 15px;
		padding-right: 15px;
		height: 80px;
	}
}
