@import "../../../../styles/variables";

.EditSalesboardColumn {
	background-color: white;
	padding: 15px;
	padding-top: 20px;
	padding-bottom: 5px;
	border-radius: 2px;
	border: 1px solid $bright-blue;
}

.EditSalesboardColumn .datepicker-group {
	width: 100%;
}

.EditSalesboardColumn .Label {
	font-weight: $boldFontWeight;
}

.EditSalesboardColumn__title {
	position: relative;

	.Progress {
		height: 16px;
		width: 16px;
		position: absolute;
		right: 0;
		bottom: 2px;
	}
}

.EditSalesboardColumn__save-button {
	width: 100%;
}

.EditSalesboardColumn__delete-button,
.EditSalesboardColumn__abort-button {
	width: 100%;
	color: $grey-10;
}

.EditSalesboardColumn__abort-button {
	margin-top: 8px;
}

.EditSalesboardColumn__toggle-sortdirection-button {
	padding-left: 0;
	margin-right: 5;
	height: 24px;
	line-height: 24px;
}

.EditSalesboardColumn__remove-sort-button {
	position: absolute;
	right: -12px;
	top: 0px;
	height: 22px;
	line-height: 22px;
	color: $grey-10 !important;
}

.EditSalesboardColumn__choose-sort-button {
	font-size: 14px;

	.Icon {
		margin-right: 2px;
	}
}

.EditSalesboardColumn__sorting-select-wrap {
	width: 100%;
	position: relative;
	top: 1px;

	/* No hate over !important, you'r allowed to do this when working with select2 */
	.select2-arrow {
		display: none !important;
	}

	.select2-choice {
		border-bottom: white !important;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.select2-chosen {
		padding-left: 17px !important;
		font-family: Roboto !important;
	}
}

.EditSalesboardColumn__progress {
	width: 16px;
	height: 16px;
	position: absolute;
	position: absolute;
	right: 0;
	top: 3px;
}

.EditSalesboardColumn .Row.margin-bottom {
	margin-bottom: 8px;
}

.EditSalesboardColumn__image-select {
	width: calc(100% + 30px);
	margin-left: -15px;
	margin-right: -15px;
	height: 80px;
	background-color: $grey-4;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 12px;
	cursor: pointer;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100%;
}

.EditSalesboardColumn__image-select-icon-row {
	font-size: 16px;
	z-index: 1000;
}

.EditSalesboardColumn__image-select-text-row {
	z-index: 1000;
}

.EditSalesboardColumn__overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba($blue, 0.5);
}
