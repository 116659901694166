@import "~StyleVariables";

.periodise-root {
	height: 100%;
	display: flex;
}

.Periodization {
	$productTableWidth: 15%;
	$totalTableWidth: 130px;
	$columnWidth: 68px;
	$columnPadding: 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: white;

	.ModalHeader {
		position: relative;
		border-top: none;
	}

	.ModalControls {
		position: unset;
	}

	.TableColumn {
		border: none;
	}

	.Periodization__TableRow {
		border-left: 4px solid rgba(0, 0, 0, 0);
		transition: all ease-in 0.2s;
		background: rbga(0, 0, 0, 0);

		.TableColumn {
			transition: all ease-in 0.2s;

			.TableColumn__subtitle {
				transition: all ease-in 0.2s;
			}
		}
	}

	.TableRow.Periodization__TableRow {
		&--selected {
			border-left: 4px solid $bright-blue;
			background: $grey-2 !important;
		}

		&--disabled {
			color: $grey-5 !important;
		}
	}

	.periodization-date-range-wrapper {
		height: 100px;
		padding: 15px 20px;

		.Label {
			font-weight: $boldFontWeight;
		}

		.period-end {
			padding-left: 15px;
		}

		.ButtonGroup .Button,
		.ButtonSelect {
			margin-left: 20px;
			margin-top: 22px;
		}

		.ButtonGroup,
		.ButtonSelect {
			vertical-align: top;
		}
	}

	.admin-table-header {
		border-bottom: 1px solid #f4f4f4;
		text-transform: uppercase;
		background-color: white;
		height: 30px;

		th {
			font-weight: initial;
		}

		.Text {
			line-height: 16px;
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid #f4f4f4;
		}
	}

	.periodization-data-table-wrap,
	.periodization-product-table-wrap,
	.periodization-total-table-wrap {
		display: inline-block;
		white-space: nowrap;
		vertical-align: top;

		table {
			width: 100%;
		}

		tbody tr {
			background: $white;
			&:nth-child(even) {
				background: rgba($grey-1, 0.7);
			}

			&:last-child {
				background-color: white;
			}
		}

		tbody tr td {
			height: 50px;
			border: none;
		}

		tbody tr th {
			height: 30px;
		}

		.table-footer {
			margin-top: auto;
			border-top: 1px solid #f4f4f4;
			width: 100%;
		}
	}

	.periodization-product-table-wrap {
		flex: 0 0 $productTableWidth;
		box-shadow: 1px 0 6px 0 rgba($black, 0.3);
		border-top-right-radius: 3px;
		z-index: 1;

		& > table {
			table-layout: fixed;
		}

		th {
			padding-left: 15px;
			font-weight: $boldFontWeight;
		}

		tbody tr {
			background: $white;

			&.two-lines td,
			&.two-lines td .ellipsis {
				line-height: 25px;
			}

			td {
				position: relative;
				padding: 0;

				.product-container {
					padding: 0 15px;
					cursor: pointer;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.product-name {
						font-size: 14px;
					}

					.product-details {
						font-size: 14px;
					}
				}
			}

			&.product-table-footer {
				.TableColumn {
					padding-left: 15px;
				}

				font-size: 14px;
			}
		}
	}

	.periodization-data-table-wrap {
		flex: 1;
		overflow-x: auto;
		overflow-y: visible;
		background-color: #f6f9fb;
		width: 0;
		height: fit-content;

		& > table {
			table-layout: fixed;
			max-width: initial;
		}

		td,
		th {
			width: $columnWidth;
			padding: 0 $columnPadding 0 0;
		}

		td:first-child,
		th:first-child {
			width: $columnWidth + $columnPadding;
			padding-left: $columnPadding;
		}

		tbody tr {
			background: #fafafa;
			&:nth-child(even) {
				background: rgba($grey-4, 0.7);
			}
		}

		.periodization-table-footer {
			td {
				background-color: white;
			}
		}

		.inline-edit {
			position: relative;
			color: $grey-13;

			&.disabled input {
				cursor: not-allowed;
				color: $grey-5;
			}

			&.two-lines {
				line-height: 25px;

				input {
					vertical-align: top;
				}
			}

			input {
				border: none;
				width: 100%;
				padding: 0;
				background: transparent;
				height: 25px;
				line-height: 25px;
				display: inline-block;
				border-bottom: 1px solid transparent;
				cursor: pointer;

				&:focus {
					border-bottom: 1px solid $bright-blue;
					outline: none;
				}
			}

			.apply-next,
			.subtitle {
				font-size: 10px;
				margin: 0;
				padding: 0;
				font-weight: $regularFontWeight;
			}

			.subtitle {
				display: block;
				color: $grey-10;
				margin-top: -5px;
			}

			.apply-next {
				height: 25px;
				display: inline-block;
				line-height: 25px;
				width: 25px;
				text-align: center;
				color: $grey-8;
				transition: all ease 200ms;
				font-size: 14px;

				&:hover {
					color: $bright-blue;
				}
			}
		}
	}

	.periodization-total-table-wrap {
		flex: 0 0 $totalTableWidth;
		text-align: right;
		box-shadow: -1px 0 6px 0 rgba($black, 0.3);
		color: $grey-13;
		border-top-left-radius: 3px;
		z-index: 1;

		tr.two-lines td {
			line-height: 25px;
		}

		td {
			position: relative;
			width: $totalTableWidth;
			text-align: right;
			padding: 0 10px;
		}

		.warning {
			color: #d33f42;

			.total-footer-distribute {
				color: #d33f42;
			}
		}

		.total-footer-total {
			font-weight: $boldFontWeight;
		}

		.total-footer-distribute {
			color: grey;
			font-size: 10px;
		}

		.total-table-footer {
			td {
				padding: 0 10px;
			}
		}

		.subtitle {
			font-size: 10px;
			margin: 0;
			padding: 0;
			font-weight: $regularFontWeight;
			display: block;
			color: $grey-10;
			margin-top: -5px;
		}
	}

	.periodization-placeholder {
		background: $grey-1;
		padding: 60px 40px;
		flex: 1;

		&:before {
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			background: $white;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
			left: 0;
			top: 0;
		}

		> .Text {
			width: 230px;
			text-indent: -10px;
		}
	}

	.Periodization_inner-table-container {
		flex: 1;
		overflow-y: auto;
	}

	.Periodization_inner-table-wrapper {
		position: relative;
		display: flex;
	}
}
