@import "_variables.scss";

.EditDynamicLink {
	// Temporary fix for blue top border
	.ModalHeader {
		border-top: none;
	}

	--app-babel-components-modals-modalTagList-width: 280px;

	overflow-x: visible !important;
	overflow-y: visible !important;

	&--showTagList {
		transform: translateX(calc(var(--app-babel-components-modals-modalTagList-width) / -2)) !important;
	}

	.select2-container {
		display: block;
	}

	.ColorSelect {
		display: inline-block;
	}

	.ModalHeader {
		display: grid;
		grid-template-columns: auto min-content;
		align-items: center;
		padding-right: 52px;
	}

	.ModalContent {
		z-index: 1006;
	}

	.ModalControls .Row {
		justify-content: flex-end;
	}

	&__content {
		width: 750px;
	}

	&__spacer {
		flex: 0 0 30px;
	}

	&__toggle-wrapper {
		justify-content: flex-end;
	}

	&__toggle {
		margin-left: 1ch;
	}
}
