@import "~StyleVariables";
.MailSendButton {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.Button {
		border-radius: 4px 0 0 4px;
		padding: 0 4px 0 8px;
		.Text {
			display: inline;
		}
		.Icon {
			margin-right: 8px;
		}
	}

	.DropDownMenu {
		transition: opacity 0.2s ease-out;
		background-color: $green;
		border: 1px solid $green;
		height: 34px;
		border-radius: 0 4px 4px 0;

		.DropDownMenu__content {
			border-radius: 4px;
			min-width: 120px;
			cursor: pointer;
		}
	}

	.DropDownButton {
		height: 28px;
		.DropDownButton__wrapper {
			height: auto;
		}
		.DropDownButton__text-wrapper {
			padding: 0;
		}
		.DropDownButton__icon-wrapper {
			margin: 4px 4px 0 0;
			opacity: 1;
			color: $white;
			align-items: center;
		}
	}

	&--disabled {
		.DropDownMenu {
			opacity: 0.6;
		}
		.DropDownButton {
			cursor: default !important;

			.DropDownButton__icon-wrapper {
				.Icon {
					cursor: default !important;
				}
			}
		}
	}

	&__item-card {
		display: flex;
		align-items: center;
		padding: 8px 16px;
		margin: 1px 0;
		gap: 10px;
		white-space: nowrap;
		&:hover {
			background-color: $grey-1;
		}
	}

	&__modal {
		background-color: $white;
		width: 360px;
		position: absolute;
		cursor: default;
		right: 0;
		top: 0;
		box-shadow: $box-shadow-lg;
		border-radius: $border-radius-sm;
		padding: 10px 20px;
		display: flex;
		flex-direction: column;
	}

	&__cancel-button {
		height: 28px;
		margin-top: 4px;
	}
}
