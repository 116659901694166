@import "../../../../../styles/_variables.scss";
.SharedEasyBookingSettings {
	#admin-page-shared-easy-booking-settings {
		#admin-content {
			position: relative;
			max-width: 1200px !important;
			display: flex;
			flex-direction: column;
			gap: 32px;
			margin-bottom: 25vh;
		}
	}

	&__loaderWrapper {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
	}

	&__z-index {
		z-index: 2;
	}
}
