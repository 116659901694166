@import "~StyleVariables";

.ClientCardSidebar__AccountManagers {

    &__titleAndAddButton {
        justify-content: space-between;
        .Button {
            line-height: 8px;
            height: 22px;
        }
    }
    &__accountManagerRows {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;
    }
    &__accountManagerRow {
        flex-wrap: nowrap;

        .Row {
            align-items: center;
            flex-wrap: nowrap;
            max-width: 280px;
            .Text {
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .Icon {
            opacity: 0;
            &:hover {
                cursor: pointer;
            }
            transition: opacity 200ms;
        }

        &:hover {
            .Icon { 
                opacity: 100;
            }
        }

        opacity: 100%;
        &--deleting {
            opacity: 20%;
            transition: opacity 1s; 
        }

        .Loader {
            margin-left: -2.5px;
            margin-right: -2.5px;
            margin-top: -2.5px;
            margin-bottom: -2.5px;
        }
    }

    .Select__portal {
        top: 0px !important;
        > div {
            width: calc(300px - 16px - 16px); //sidebar width minus left and right padding
        }
    }

    .selectAnchor {
        position: relative;
    }

    .Select {
        margin-top: 5em;
        .Select__arrow {
            display: none;
        }

    }

    .SelectMultiInput {
        margin-top: 8px;
        border: none;
        box-shadow: none;
        padding-left: 0;
        font-style: none;
        &:hover {
            box-shadow: none;
        }

        .SelectMultiInput__input {
            border: none;
            box-shadow: none;

            &::placeholder {
                font-style: normal;
            }
            &:hover {
                &::placeholder {
                    color: $black;
                }
            }


        }

        .Icon-angle-down {
            display: none;
        }
    }
}