@import "~StyleVariables";

$pageTop: 82px;
$headerHeight: 50px;

.ListViewTable {
	&__checkbox-header-col {
		padding-left: 8px;
		width: 57px;
	}

	&__tools-header {
		text-align: right;
		padding-right: 15px;

		.Button:hover {
			background-color: $grey-4;
		}
	}

	.TableColumn {
		word-break: break-all;
	}

	.DisplayTypeArray {
		.Text {
			display: inline;
		}
	}

	.TableHeader {
		position: sticky;
		top: $pageTop + $headerHeight;
		background-color: $white;
		z-index: 10;

		.TableHeader__column {
			white-space: nowrap;
		}
	}
}
