@import "~StyleVariables";

.JourneyStatusesTable {
	margin-bottom: 20px;
}

.JourneyStatusesTable .CardHeader__actions .Help {
	margin-right: 10px;
}

.JourneyStatusesTable__delete {
	top: 3px;
	right: 2px;
	position: absolute;
}
