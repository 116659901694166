@import "../../../styles/variables";

.PortedCreateForm {
	padding-top: 11 * $space;
	display: block;
}

.CreateForm {
	&__inner {
		width: 480px;
		margin: auto;
	}

	&__type {
		width: 480px;
		margin: 35px auto;

		.Icon {
			display: none;
		}

		.Column {
			border: 1px solid $grey-6;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
			padding: 14px;
			height: 190px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			cursor: pointer;
			position: relative;

			&:hover {
				background-color: $grey-4;
			}

			&:first-child {
				border-radius: 2px 0 0 2px;
			}

			&:last-child {
				border-left-color: rgba(0, 0, 0, 0);
				border-radius: 0 2px 2px 0;
			}

			&--selected {
				border-color: $green;

				&:last-child {
					border-left-color: $green;
				}

				.Icon {
					position: absolute;
					display: block;
					top: 10px;
					font-size: 24px;
					right: 10px;
				}
			}
		}
	}

	&__NextStep {
		margin-top: 25px;
	}
}
