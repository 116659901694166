@import "~StyleVariables";
$toolbar-height: 40px;

.MailEditorToolbar {
	position: sticky;
	top: 50px;
	background-color: $white;
	box-shadow: 0 2px 4px 0 rgba($black, 0.2);
	height: $toolbar-height;
	z-index: 1004;

	.Button {
		height: $toolbar-height;
		line-height: $toolbar-height;
		text-decoration: none;
	}

	.mobile-icon {
		font-size: 14px;
		margin-right: 6px;
	}

	&__history-buttons {
		padding-top: 9px;

		.Button {
			height: 23px;
			line-height: 23px;
			padding: 0 7px;
			font-size: 12px;
		}
	}

	&__send-test {
		display: inline-block;
		text-align: left;

		& > .Button {
			position: relative;
			margin-right: 5px;

			&:before {
				content: "";
				background-color: $white;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 4px;
				z-index: 1001;
			}
		}

		&-dropdown {
			background-color: $white;
			width: 360px;
			position: absolute;
			right: 5px;
			top: 40px;
			visibility: hidden;
			opacity: 0;
			box-shadow: 0 3px 6px 0 rgba($black, 0.3);
			border-radius: 2px;
			border-top-right-radius: 0;
			padding: 20px 30px;
			transition: all ease-out 200ms;
		}

		&--visible &-dropdown {
			visibility: visible;
			opacity: 1;
		}

		&--visible > .Button {
			box-shadow: 0 3px 6px 0 rgba($black, 0.3) !important;
		}
	}
}
