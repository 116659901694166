@import "~StyleVariables";

.ProductGridWidget {
	&__cell {
		width: 100%;
		height: 100%;
		display: flex;
		padding: 0 0.5em;
		border-bottom: 1px solid $grey-2;
		transition: background-color ease-in-out 0.2s;
		font-size: 12px;
		align-items: center;

		&--body {
			justify-content: center;
			border-right: 1px solid $grey-2;
		}

		&--check {
			background-color: $super-duper-light-green;
		}

		&--opportunity {
			background-color: $super-light-yellow;
		}

		&--times {
			background-color: $super-light-red;
		}

		&--clickable {
			&:hover {
				cursor: pointer;
			}
		}

		&--clickable.ProductGridWidget__cell--check {
			&:hover {
				background-color: darken($super-duper-light-green, 5%);
			}
		}

		&--clickable.ProductGridWidget__cell--opportunity {
			&:hover {
				background-color: darken($super-light-yellow, 5%);
			}
		}

		&--clickable.ProductGridWidget__cell--times {
			&:hover {
				background-color: darken($super-light-red, 5%);
			}
		}
	}
}
