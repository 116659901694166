@import "~StyleVariables";

.MarketingBoardWidget {
	position: relative;
	padding: 10px 20px 20px 20px;
	transition: all ease-out 200ms;
	width: 25%;

	&:hover:not(&--loading) {
		background-color: $grey-1;

		.MarketingBoardWidget__show-more {
			visibility: visible;
			opacity: 1;
			bottom: 25px;
		}
		.MarketingBoardWidget__sub-stats {
			visibility: hidden;
			opacity: 0;
		}
	}

	.Skeleton > * {
		display: none;
	}

	.Headline {
		display: inline-block;
	}

	&__diff {
		display: inline-block;
		vertical-align: top;
		margin-left: 10px;
	}

	&__text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&.Skeleton:before {
			content: "Number of visitors";
		}
	}

	&__sub-stats {
		margin-top: 10px;
		visibility: visible;
		opacity: 1;
		transition: all ease-out 200ms;
	}

	&__show-more {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		visibility: hidden;
		opacity: 0;
		transition: all ease-out 200ms;
	}
}
