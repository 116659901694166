@import "~StyleVariables";

$header-height: 50px;
$paginator-height: 50px;

.ProductSearch {
	visibility: visible;
	background-color: $white;
	display: flex;

	&__list {
		width: 100%;
	}

	&__filters {
		min-width: 316px;
		border-left: 1px solid $grey-4;
		background-color: $white;

		#list-filters-wrap#list-filters-wrap#list-filters-wrap {
			top: 0;
			width: 315px;

			.list-filter-page {
				overflow-y: auto;
				overflow-x: hidden;
			}

			.list-filter-header {
				pointer-events: none;
				&:hover {
					background-color: unset;
					cursor: unset;
				}
				b {
					display: none;
				}
			}

			.list-row-separator {
				height: fit-content;
			}
		}

		#up-list-filters #list-filters-wrap .list-filter-page #available-filters-search-wrap .search-input {
			width: 296px;
		}

		&__filter {
			border-bottom: 1px solid $grey-3;
			overflow: hidden;

			&--active {
				.ProductSearch__filters__filter__title {
					box-shadow: inset 4px 0 $green;
				}
			}

			&__option, &__title {
				padding: 10px 16px;
				&:hover {
					cursor: pointer;
					background-color: $grey-1;
				}
			}

			&__option {
				background-color: $grey-2;

				.ButtonGroup {
					width: 100%;
				}
			}
	
			&__title {
				display: flex;
				justify-content: space-between;
			}
	
			&__options {
				height: 0px;
	
				&--expanded {
					height: 100%;
				}
			}
		}
		
		#clear-filters {
			width: 34px;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				cursor: pointer;
				background-color: $grey-1;
			}
		}

		#filter-search {
			padding: 12px;
		}
	}

	&__header {
		box-shadow: none;
		border-bottom: 1px solid $grey-4;

		#product-search {
			padding: 8px 16px;
		}

		.Input__input {
			border: none;
			box-shadow: none;
			background-color: $grey-2;
		}

		.Input {
			.Icon {
				color: $grey-10;
			}
		}
	}

	&__categoryBar {
		height: $header-height;
		display: flex; 
		justify-content: space-between;
		align-items: center;

		.DropDownMenu__content {
			z-index: 999;
			max-height: 500px;
			overflow: auto;

			.Text {
				max-width: 320px;
				word-wrap: break-word;
			}
		}

		.DropDownButton__icon-wrapper {
			opacity: 1;
			font-size: large;
		}

		.TableRow--clickable:hover {
			background-color: $grey-2;
		}

		.Input {
			padding: 8px;
			height: 100%;
		}

		.Tabs {
			display: flex;
		}
		
		.Tab {
			text-align: left;
		}

		.Tab--selected {
			.Tooltip {
				.Text {
					font-weight: $boldFontWeight;
				}
			}
			.Tab__subtitle {
				color: $black;
			}
		}
	}

	&__products {
		height: calc(100vh - #{$header-height} * 2 - #{$paginator-height});
		overflow-y: auto;

		.TableHeader {
			position: sticky;
			top: 0;
			z-index: 1;
		}

		.ProductList__edit-column-header {
			padding-right: 28px;
			text-align: right;
		}

		.ProductList__no-filter-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			
			img {
				max-width: 60%;
				height: auto;
				margin-bottom: 28px;
			}
		}

		.TableHeader__column {
			text-transform: capitalize;
		}

		.TableColumn {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.Icon {
				margin-right: 8px;
				color: $greyeen;
			}
		}

		.ReactVirtualized__Table__headerRow {
			border-bottom: 4px solid $grey-4;
		}

		.ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
			margin-right: 0px;
			margin-left: 0px;
		}

		.ReactVirtualized__Grid__innerScrollContainer {
			margin-bottom: 0px;	
		}
	}

	.InlineAbortConfirm__wrapper {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		border: 1px solid $grey-4;
		background-color: $white;
	}
}