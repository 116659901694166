@import "../../../styles/variables";

.MailTemplateHeader {
	display: flex;
	height: 48px;

	position: fixed;
	right: 15px;
	top: 97px;
	left: 65px;
	z-index: 1029;
	transition: top 200ms, bottom 200ms, left 200ms, right 200ms;

	border-bottom: 1px solid $grey-6;
	color: $blue;
	background-color: $white;
	border-radius: 3px 3px 0 0;
	background-clip: padding-box;

	padding: 0 15px;
}

.MailTemplateHeader--fullscreen {
	top: 0;
	left: 0;
	right: 0;
}

.MailTemplateHeader__title,
.MailTemplateHeader__actions {
	height: 100%;
	display: flex;
	align-items: center;
}

.MailTemplateHeader__actions {
	margin-left: auto;
}

.MailTemplateHeader__actions .Button {
	margin-left: 4px;
}

.MailTemplateHeader__icon {
	margin-right: 4px;
}

.MailTemplateHeader__actions .MailTemplateHeader__dropdown-caret {
	margin-left: 0;
	padding: 0 5px;
	.Icon {
		margin-right: 0;
	}
}
