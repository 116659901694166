@import "~StyleVariables";

.CustomerSupport {
	padding-bottom: 120px;

	&__cardInput {
		width: 280px;
		margin-right: 8px;
	}
	.TableColumn {
		overflow-wrap: break-word;
		border-top: none;

		.Select {
			padding-right: $space * 2;
		}
	}

	.TableColumn--align-left {
		width: 500px;
		max-width: 500px;

		.Select {
			width: 475px;
		}
	}

	.TableColumn--align-right {
		width: 150px;
		max-width: 150px;
	}

	&__forwardEmailCard {
		width: 750px;
		height: 450px;

		.TableColumn {
			overflow-wrap: break-word;
			max-width: 300px;
		}
	}

	&__language {
		border-bottom: $border;
		&__bold {
			font-weight: $boldFontWeight;
		}
	}

	&__cardEmail {
		height: 360px;
	}

	&__cardImportColumn {
		margin-top: 70px;
	}

	&__cardInputVerification {
		width: 100%;
	}

	&__loader {
		margin: 0 auto;
	}

	&__forwardCardInput {
		width: 280px;
		margin-right: 8px;
		margin-bottom: 10px;
	}

	&__codeRow {
		margin: 0px auto;
		padding: 70px 0;

		.Title {
			word-break: break-all;
		}
	}
	&__selectTooltip {
		max-width: 400px;
	}
	&__expand {
		&--expanded {
			border-bottom: none;
		}
		border-bottom: $border;
		.TableColumn {
			padding-bottom: $space * 3;
		}
		&__click {
			cursor: pointer;
		}
	}
	&__expandedIcon {
		&--expanded {
			transform: rotate(0deg);
		}

		transform: rotate(180deg);
		transition: transform 200ms linear;
	}
	&__select {
		&--expanded {
			height: auto;
			display: table-row;

			.Text--sm {
				padding-bottom: $space * 3;
			}
		}
		overflow: hidden;
		height: 0;
		display: none;
	}
}
