@import "~StyleVariables";

.ProspectingCardSmal {
	cursor: pointer;
	transition: all ease 200ms;
	border-radius: 3px !important;
	overflow: hidden;

	button {
		padding: 0;
		height: 24px;
		line-height: 24px;
	}

	i {
		margin-right: 10px;
	}
}

.ProspectingCardSmal img,
.ProspectingCardSmal .Title {
	transition: all 600ms ease-in-out;
}

.ProspectingCardSmal .Loader {
	height: 20px;
	width: 20px;
	margin-right: 10px;
	display: inline-block;
}

.ProspectingCardSmal .Loader circle {
	stroke: $grey-10;
}

.ProspectingCardSmal:hover {
	box-shadow: 0 3px 6px 0 rgba($black, 0.2);
}

.ProspectingCardSmal--disabled:hover {
	box-shadow: none;
}

.ProspectingCardSmal--disabled {
	box-shadow: none;
	cursor: default;

	button {
		font-style: italic;
	}

	img {
		filter: grayscale(100%);
		opacity: 0.4;
	}
}

.ProspectingCardSmal .Card__slice:before {
	background-color: $grey-1;
	width: 180px;
}

.ProspectingCardSmal .Card__slice:after {
	background-color: $grey-1;
}

.ProspectingCardSmal img {
	position: absolute;
	z-index: 1001;
	bottom: 0;
	right: 0;
	height: 95%;
}

.ProspectingCardSmal__right-side {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ProspectingCardSmal--with-img .ProspectingCardSmal__right-side {
	max-width: calc(100% - 160px);
}
