@import "~StyleVariables";

.NotificationList2 {
	background-color: $grey-2;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
	padding: 10px;
	height: 100%;
	overflow: auto;

	&__group {
		margin-bottom: 15px;
	}

	&__group-title {
		font-family: Roboto;
		line-height: 24px;
		text-align: left;
		margin-bottom: 10px;
	}

	&__empty {
		text-align: center;
		padding-top: 40px;

		img {
			margin-bottom: 20px;
		}
	}
}
