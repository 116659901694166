// body.modal-open #blur-wrap > *:not(.up-modal) {
// 	@include saturate(0%);
// 	@include transition(filter ease 2000ms);
// }

// This is a mess, but I did not find a clean and confident way around it.
// 'primary-btn' below needs to be replaced by its corresponding color class (eg. 'btn-orange' for warning modal)
@mixin up-btn($bg, $border, $color) {
	background-color: $bg;
	border: 1px solid $border;
	color: $color;

	&[disabled] {
		cursor: not-allowed;
		pointer-events: none;
		@include opacity(0.6);
		@include box-shadow(none);
	}

	&.active,
	&:hover {
		background-color: darken($bg, 5%);
		border-color: $border;
		text-decoration: none;
	}

	&.btn-link {
		background-color: transparent;
		color: $bg;
		box-shadow: none;
		border: none;
		text-shadow: none;

		&:hover {
			color: darken($bg, 25%);
		}

		&.btn-inline {
			padding: 0;
		}
	}

	&.btn-lined {
		background-color: transparent;
		color: darken($bg, 10);
		text-shadow: none;

		&.active,
		&:hover {
			background-color: lighten($bg, 6%);
			border-color: lighten($bg, 6%);
			color: $color;
			text-decoration: none;
		}

		.upsales-loader {
			g .progress-circle {
				stroke: $bg;
			}
		}
	}
}

.up-modal {
	$modal-controls-height: 49px;
	$animationTime: 200ms;
	$margin: 20px;
	$full-screen-content-width: 1000px;
	$new-full-screen-modal-width: 500px;
	$fullscreen-modal-lineheight: 1.337;
	max-height: calc(100% - 76px);

	@mixin fullWidth($width) {
		@media (max-width: $width) {
			& {
				margin-left: 0;
				left: $margin;
				right: $margin;
				width: auto;
			}
		}
	}

	// This mixin is HUGE for like 3 variable lines. TODO: DELETE
	@mixin modal($color) {
		$controls-height: 57px;
		border-top: 5px solid $color;
		background-color: $grey-1;
		@include border-radius(0 0 3px 3px);

		&.pattern {
			background-color: $white;
			background-repeat: repeat;
		}

		&.has-controls {
			.up-modal-content {
				bottom: $controls-height;
			}

			.modal-sidebar {
				bottom: $controls-height;
			}
		}

		&.no-border {
			border-top: none;
		}

		&.wide-sidebar {
			.sidebar-bg,
			.modal-sidebar {
				width: $modal-sidebar-width-wide;
			}

			.up-modal-content.has-sidebar .modal-center {
				padding-left: $modal-sidebar-width-wide + $padding;
			}
		}

		.up-modal-header {
			border: none;
			background-color: transparent;
			padding: 0px 20px;
			color: $grey-13;
			@include transition(all ease 200ms);
			line-height: $modal-header-height;
			height: $modal-header-height;

			&.white-header {
				background-color: $white;
				border-bottom: 1px solid $grey-6;
				z-index: 1001;
			}

			&.no-border-bottom {
				border-bottom: none !important;
			}

			h2 {
				display: inline-block;
				color: $grey-13;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: calc(100% - 20px);

				&.has-subtitle {
					line-height: 18px;
					font-size: 14px;
					padding-top: 6px;

					.subtitle,
					.subtitle-no-uppercase {
						display: block;
					}
				}

				a {
					color: inherit;
				}
			}

			.subtitle {
				color: $grey-10;
				font-size: 10px;
				text-transform: uppercase;
				display: none;
			}

			.Text {
				text-transform: none;
			}

			.template-icon {
				color: $color;
				vertical-align: top;
				height: 46px;
				line-height: 46px;
				font-size: 16px;
				margin-right: 10px;
			}

			.modal-close {
				color: $grey-10;

				&:hover {
					color: $grey-13;
				}
			}

			.modal-tools {
				position: absolute;
				top: 6px;
				right: 50px;

				.form-control {
					display: inline-block;
				}
			}

			.modal-header-info {
				position: absolute;
				top: 0;
				right: 46px;
				width: 46px;
				text-align: center;
				font-size: 14px;
				line-height: 46px;
				color: $grey-10;
			}

			&.header-white {
				background-color: $white;
				border-bottom: 1px solid $grey-8;
			}
		}

		.up-modal-controls {
			background-color: $white;
			height: $controls-height;
			padding: 10px;
			z-index: 1005;

			&.no-padding {
				padding: 0;
			}

			.full-height {
				height: 100%;
			}

			.primary-btn {
				@include up-btn($color, $color, $white);
			}

			.secondary-btn {
				@include up-btn($color, $color, $white);
			}

			& > button.pull-left,
			& > .dropdown.pull-left {
				margin-left: 0;
				margin-right: 10px;
			}

			.inline-block {
				display: inline-block;
			}

			.up-btn {
				margin-left: 10px;
				&.main-button {
					min-width: 110px;
				}
			}

			.dropdown-button {
				margin-left: -5px;
				button {
					padding: 0 2px;
					border-left: 1px solid rgba($grey-10, 0.5);
					color: rgba($grey-10, 0.5);
				}

				.dropdown-menu {
					margin-left: -109px;
					min-width: 125px;

					li {
						.save-button {
							background-color: $green;
							color: $white;
							padding: 10px 15px;
						}
					}
				}

				&--blue {
					button {
						color: $white;
					}
				}

				&--white {
					button {
						color: $green;
					}

					.dropdown-menu {
						li {
							.save-button {
								background-color: $white;
								color: $green;
							}
						}
					}
				}
			}

			.up-btn.no-margin-left {
				margin-left: 0;
			}
			.btn-group > .up-btn {
				margin-left: 0;

				& + .up-btn {
					margin-left: -1px;
				}
			}
		}

		.sidebar-bg {
			background-color: $white;
			position: absolute;
			width: $modal-sidebar-width;
			top: 0;
			bottom: 0;
			left: 0;
			border-right: 1px solid $grey-6;
			z-index: 999;

			&.right {
				width: $modal-sidebar-right-width;
				right: 0;
				left: initial;
				border-left: 1px solid $grey-6;
				border-right: none;
			}
		}

		// Sidebars placed in "root" is fixed and scrolls themselves
		.modal-sidebar {
			position: absolute;
			width: $modal-sidebar-width;
			left: 0;
			top: $modal-header-height;
			bottom: 0;
			font-size: $modal-font-size;
			line-height: 24px;
			padding: $padding;
			z-index: 1006;
			@include opacity(0);

			&.sidebar-right {
				width: $modal-sidebar-right-width;
				right: 0;
				left: initial;
				z-index: 1001;
			}

			h3 {
				padding: 0;
				margin: 0;
				margin-bottom: 5px;
				font-size: 16px;
				display: block;
				word-break: break-all;

				a {
					color: inherit;
				}
			}

			.sidebar-section {
				border-bottom: 1px solid $grey-4;
				padding-bottom: $padding + 5px;
				margin-top: $padding + 5px;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}

				&.no-margin,
				&:first-child {
					margin-top: 0;
				}

				&.no-border {
					border-bottom: none;
				}

				&.edge-to-edge {
					margin-top: -$padding + 1;
					margin-left: -$padding + 1;
					margin-right: -$padding + 1;
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}

		.up-modal-content {
			$padding: 15px;
			background-color: transparent;
			padding: $padding;
			z-index: 1000;

			&.dropzone:focus {
				outline: none;
			}

			&.has-sidebar-x2,
			&.has-sidebar,
			&.no-padding {
				padding: 0;
			}

			&.has-sidebar {
				.modal-center {
					padding-left: $modal-sidebar-width + $padding;
				}
			}

			&.has-sidebar-x2 {
				.modal-center {
					padding-left: $modal-sidebar-width + $padding;
					padding-right: $modal-sidebar-right-width + $padding;
				}
			}

			// Sidebars placed inside content is scrolled together with the rest
			& > .modal-sidebar {
				position: relative;
				min-height: 100%;
				float: left;
				top: initial;
				left: initial;
				right: initial;
				bottom: initial;

				&.sidebar-right {
					float: right;
				}
			}

			.modal-center {
				padding: $padding;
				padding-bottom: 0;

				h2 {
					margin-top: 25px;
				}
			}

			h2 {
				@include h2();
				font-size: 18px;
			}

			textarea.form-control.up-input {
				min-height: 116px;
				resize: vertical;
				&.address {
					min-height: 70px;
				}
			}
		}

		.up-panel {
			background-color: transparent;
			padding: 0px;

			.list-row {
				position: relative;
				background: $white;
				padding: 5px 8px;
				margin-bottom: 3px;
				border: 1px solid $grey-4;

				&:hover {
					border-color: $grey-8;
				}

				.btn.up-btn {
					position: absolute;
					right: 5px;
				}
			}
		}
	}

	position: fixed;
	display: none;
	left: 50%;
	top: 88px;
	margin-left: -($modal-width/2);
	width: 700px;
	background-color: $white;

	@include box-shadow(0 0 10px rgba($black, 0.4));
	@include border-radius(3px);
	overflow: hidden;
	z-index: $modal-z;

	// This is the initial height of all modals (can be overwritten by class below or by data-initial-height attribute on the root elem in the view)
	height: 200px;

	#generic-modal {
		height: 100%;
	}

	&.no-modal-overflow,
	&.generic-modal {
		overflow: visible;
	}

	&:not(.no-animate) {
		@include transition(all linear $animationTime);
	}

	&.hidden {
		@include opacity(0);
		z-index: $modal-z - 1;
	}

	&.initially-hidden {
		opacity: 0 !important;
		z-index: $modal-z - 1 !important;
	}

	&.measuring {
		display: block !important;
		height: initial !important;
		visibility: hidden;
		bottom: initial !important;

		.ModalHeader,
		.up-modal-header {
			position: initial !important;
		}

		ul.tabs {
			position: initial !important;
			display: block !important;
			margin-top: 2px !important;
		}

		.ModalContent,
		.up-modal-content {
			position: initial !important;
		}

		.ModalContent {
			margin: 0;
		}

		.DropDownMenu__content {
			position: initial;
		}

		.ModalControls,
		.up-modal-controls {
			position: initial !important;
			display: block !important;
		}
	}

	&.compiled {
		.up-modal-header {
			h2 {
				@include opacity(1);
			}

			.modal-close {
				@include opacity(1);
			}
		}

		ul.tabs {
			display: block;
		}

		.up-modal-content {
			height: auto;
			@include opacity(1);
		}

		.up-modal-content > .modal-sidebar,
		div.modal-sidebar {
			@include opacity(1);
		}

		.up-modal-controls {
			display: block;
		}
	}

	&.has-controls {
		.up-modal-content {
			bottom: $modal-controls-height;
		}
	}
	&.salesboard-filter {
		.up-modal-content {
			border-top: none;
		}
		#title {
			display: none;
		}
	}

	// styles (colors)
	&.confirm,
	&.prompt,
	&.notify,
	&.confirm-info,
	&.warning {
		width: $modal-confirm-width;
		margin-left: -($modal-confirm-width/2);
		height: 134px;
	}
	&.form-sm {
		width: $modal-form-sm-width;
		margin-left: -($modal-form-sm-width/2);
		height: 134px;
		font-size: $modal-font-size;
	}

	&.form-md {
		width: $modal-form-md-width;
		margin-left: -($modal-form-md-width/2);
		font-size: $modal-font-size;

		.label-fix {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: block;
		}
	}

	&.v2 {
		border: none !important;
		border-radius: 2px;
		box-shadow: 0 0 5px rgba($black, 0.3);
		padding: 25px;
		width: 970px;
		margin-left: -485px;
	}

	&.width550 {
		box-shadow: 0 0 5px rgba($black, 0.3);
		width: 550px;
		margin-left: -275px;
	}

	&.form-sm,
	&.form-wide,
	&.form {
		top: $margin;
	}

	&.auto-height {
		height: auto;
	}

	// Size styles
	&.form {
		width: $modal-form-width;
		margin-left: -($modal-form-width/2);
		font-size: $modal-font-size;

		&.dynamic {
			$extra-width: 120px;
			@media (min-width: ($modal-form-width + $extra-width + 10px)) {
				& {
					width: $modal-form-width + $extra-width;
					margin-left: -(($modal-form-width + $extra-width) /2);

					.HeaderUserSelect,
					.user-select-wrap {
						width: 322px;
					}

					.sidebar-bg.right {
						width: $modal-sidebar-right-width + $extra-width;
					}

					.up-modal-content {
						&.has-sidebar-x2 {
							& > .modal-center {
								padding-right: $modal-sidebar-right-width + $padding + $extra-width;
							}
						}
					}
					.modal-sidebar.sidebar-right {
						width: $modal-sidebar-right-width + $extra-width;
					}
				}
			}

			@media (min-width: ($modal-form-width + ($extra-width * 2) + 10px)) {
				& {
					$width: $modal-form-width + ($extra-width * 2);
					width: $width;
					margin-left: -($width / 2);
				}
			}
		}

		// Make it responsive
		@include fullWidth($modal-form-width);
	}

	&.form-wide {
		width: $modal-form-width + 200px;
		margin-left: -(($modal-form-width + 200px) /2);

		@include fullWidth($modal-form-width + 200px);
	}

	&.full-height {
		top: $margin;
		bottom: $margin;
	}

	&.fullscreen {
		top: $margin;
		right: $margin;
		bottom: $margin;
		left: $margin;
		margin-left: 0;
		height: auto;
		width: auto;
	}

	&.modal600 {
		width: 600px;
		margin-left: -290px;
	}

	&.modal1000 {
		width: 1000px;
		margin-left: -500px;

		@include modal($blue);

		@include fullWidth(1000px);
	}

	.has-navbar {
		.up-modal-content {
			top: $modal-header-height + $sub-nav-height;
		}
	}

	ul.tabs {
		@include sub-page-navigation();
		margin-top: -1px;
		display: none;
		position: absolute;
		top: $modal-header-height;
		z-index: 1001;

		&.content-tabs {
			left: $modal-sidebar-width;
			right: 0;
			width: auto;
		}
	}

	.fa-warning {
		color: $orange;
	}

	.up-modal-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		display: block;
		border-bottom: 1px solid $grey-8;
		@include border-radius($border-radius $border-radius 0 0);
		background-color: $green;
		padding-left: 10px;
		height: $modal-header-height;
		line-height: $modal-header-height;
		z-index: 1000;

		& + .up-modal-content {
			&:not(.full-size) {
				top: $modal-header-height;
			}
		}

		&.transparent {
			background-color: transparent;
			border-bottom: none;
		}

		&.modal-header-transparent {
			background-color: transparent;
			border-bottom: none;

			&.light-grey {
				background-color: $grey-1;
			}

			.fa {
				color: $grey-11;
			}

			h2 {
				display: none;
			}
		}

		h2 {
			@include h2();
			color: $white;
			font-size: 19px;
			line-height: $modal-header-height;
			height: $modal-header-height;
			@include opacity(0);
			@include transition(opacity linear $animationTime);

			.fa {
				margin-right: 7px;
			}

			h2 > a {
				color: inherit;
			}
		}

		.modal-close {
			position: absolute;
			top: 6px;
			right: 5px;
			color: $white;
			font-size: 15px;
			line-height: 20px;
			@include opacity(0);
			@include transition(opacity linear $animationTime);
		}
		&.white-header {
			background-color: $white;
			border-bottom: 1px solid $grey-8;
			padding: 0 20px;

			h2 {
				color: $grey-13;

				.template-icon {
					color: $grey-13;
					vertical-align: top;
					height: 46px;
					line-height: 46px;
					font-size: 16px;
					margin-right: 10px;
				}
			}

			.modal-close {
				color: $green;

				&:hover {
					color: $black;
				}
			}
		}

		&.fullscreen-header {
			border-bottom: none;
			background-color: transparent;

			.modal-close {
				height: 46px;
				width: 46px;
				border-radius: 23px;
				color: $grey-10;
				background: $grey-4;
				transition: all ease 200ms;
				top: 14px;
				right: 14px;
				.fa-times {
					font-size: 16px;
					line-height: 30px;
				}

				&:hover {
					background: $grey-6;
				}
			}
		}
	}

	.up-modal-loader {
		$loader-size: 25px;
		height: $loader-size * 2 + 10;
		line-height: $loader-size * 2 + 10;
		width: 100%;
		overflow: hidden;
		text-align: center;

		&.pior {
			height: $loader-size * 4;
			color: $grey-8;
			background-color: $grey-1;
			line-height: $loader-size * 4;
			@include transition(all ease-out 200ms);
		}

		.spinner {
			display: block;
			font-size: $loader-size;
		}
	}

	.up-panel-content {
		&.pior {
			padding: 30px 0;
		}
	}

	.up-modal-content {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background-color: $white;
		overflow-x: hidden;
		overflow-y: auto;
		@include opacity(0);
		@include transition(opacity linear $animationTime);

		h2 {
			@include h2();
			font-size: 22px;
		}

		.select-list-item {
			display: block;
			line-height: 35px;
			padding: 0 10px;

			&.has-subtitle {
				line-height: 1;
				padding: 5px 10px;
			}

			.subtitle {
				line-height: 1;
				font-size: 12px;
				margin-top: 5px;
			}

			.row-extra,
			.subtitle {
				color: $grey-10;
			}

			&.selected,
			&:hover {
				background-color: $green;
				color: $white;
				cursor: pointer;

				.row-extra,
				.subtitle {
					color: $grey-6;
				}
			}
		}

		.center-note {
			text-align: center;
			color: $grey-11;
			padding: 15px;
			font-size: 18px;
		}

		textarea {
			resize: none;
		}

		&.text-center {
			text-align: center;
		}

		&.pior {
			background-color: $grey-1;

			.modal-placeholder {
				text-align: center;
				color: $grey-11;
				height: 120px;
				line-height: 120px;
			}
		}

		&.fullscreen-content {
			width: $new-full-screen-modal-width;
			margin: 0 auto;
			padding-top: 40px;
			position: initial;

			.content-delete-confirm {
				h1 {
					color: $black;
					font-size: 24px;
					font-weight: $boldFontWeight;
					margin-bottom: 10px;
					line-height: $fullscreen-modal-lineheight;
				}

				.confirm-body {
					.confirm-warning {
						color: $red;
					}
					color: $grey-10;
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 16px;
				}

				.account-items {
					width: 100%px;
					background-color: $grey-1;
					border: 1px solid $grey-4;

					display: grid;

					padding: 14px;
					margin-bottom: 44px;

					.upsales-loader {
						height: 45px;
						width: 45px;
						margin: 0 auto;
					}

					.account-item {
						margin: 4px 0;
					}
				}
			}
		}

		textarea.form-control.up-input {
			min-height: 70px;
			resize: vertical;
		}
	}

	.up-modal-controls {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px;
		text-align: right;
		height: $modal-controls-height;
		border-top: 1px solid $grey-6;
		background-color: $grey-2;
		display: none;

		.info {
			display: inline-block;
			height: 100%;
			float: left;
			margin-top: 7px;
			color: $grey-10;
		}

		.pull-left > span {
			line-height: 25px;
		}

		&.pior {
			background-color: $white;
			@include border-radius(0 0 $border-radius $border-radius);
			border-top: 1px solid $grey-4;
			height: $header-height;
		}

		&.white {
			background-color: $white;
			@include border-radius(0 0 $border-radius $border-radius);
			border-top: 1px solid $grey-4;
			height: 57px;
		}

		.modal-paginator-wrap {
			text-align: center;
			position: absolute;
			left: 300px;
			right: 300px;
			padding-top: 6px;
			.up-paginator {
				padding: 0;
			}
		}

		.btn-grey {
			text-decoration-line: none;
		}
	}

	table {
		width: 100%;
	}

	// COLOR TEMPLATES

	&.success {
		@include modal($bright-green);
	}

	&.info {
		@include modal($green);

		.up-modal-content {
			min-height: 50px; // Preventing overflow scroll with one-liner messages
		}
	}

	&.default {
		@include modal($green);
		border-top: none;
	}

	&.warning {
		@include modal($orange);
	}

	&.error {
		@include modal($red);
	}

	&.reactive-topbar {
		border-top: none;

		.up-modal-header {
			padding: 5px 15px;

			.modal-close {
				padding: 10px;
			}
		}
	}

	&.bg-gray {
		background-color: $grey-4;
	}

	.main-table {
		.main-table-disabled {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			color: $grey-10;
		}

		tr.selectable {
			.main-table-disabled {
				background-color: $grey-1;
				font-style: italic;
				color: $grey-10;
			}

			.icon {
				position: relative;
				border-left: 6px solid $white;

				.fa {
					visibility: hidden;
					@include opacity(0);
					@include transition(all ease 200ms);
					color: $bright-blue;
					position: absolute;
					top: 13px;
					left: 13px;
				}

				@include transition(border-left ease 200ms);
			}

			&.row-loading,
			&.row-selected {
				background-color: $white;
				color: $grey-13;

				&:hover .icon,
				.icon {
					border-left: 6px solid $bright-blue !important;
					text-align: center;
				}
			}

			&.row-loading {
				&:hover .icon,
				.icon {
					.fa.fa-refresh {
						visibility: visible;
						@include opacity(1);
					}
				}
			}

			&.row-selected {
				.title {
					font-weight: $boldFontWeight;
				}

				&:hover .icon,
				.icon {
					.fa.fa-check {
						visibility: visible;
						@include opacity(1);
					}
				}
			}
		}

		tr.unselectable {
			td {
				background-color: $grey-2;
			}

			td:first-child {
				border-left: 4px solid $grey-8;
			}
		}
	}

	#mail-template-chooser .main-table tr > td:last-child > div {
		padding-right: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&.new-full-screen {
		@keyframes slide-from-bottom {
			from {
				top: 100vh;
			}
			to {
				top: 0;
			}
		}

		@include box-shadow(0 0 0 0);
		animation: 600ms slide-from-bottom;
		border: none;
		background-color: $white;
		padding-top: 44px;
		margin-left: 0;
		width: 100%;
		min-height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: fixed;
		overflow: auto;
		z-index: $modal-z - 2;

		&.more-z-index {
			z-index: $modal-z - 1;
		}

		#up-logo-wrapper {
			position: fixed;
			top: 12px;
			left: 15px;
			width: 110px;

			path {
				fill: $grey-6;
			}
		}

		.full-screen-small-header {
			color: $grey-10;
			font-size: 16px;
			font-weight: $regularFontWeight;
			margin-left: 20px;
		}

		.full-screen-modal-content {
			width: 100%;
			max-width: 1600px;
			margin: 0 auto;
		}

		.exit-button {
			position: fixed;
			top: 10px;
			right: 14px;
			color: $grey-10;
			background: rgba($grey-4, 0.6);
			border-radius: 100%;
			width: 34px;
			height: 34px;
			border: none;
			padding: 0;
			z-index: 10000;
			@include transition(background 0.3s);
			text-shadow: none;

			&:hover {
				background: rgba($grey-4, 1);
				// background: rgba(228, 234, 240, 1);
			}

			.fa {
				width: 34px;
				text-align: center;
				// color: #586881;
				font-size: 16px;
			}
		}

		.up-modal-controls {
			width: $new-full-screen-modal-width;
			position: unset;
			margin: 10px auto;
			background-color: transparent;
			border-top: none;
			text-align: center;

			.up-btn {
				width: 100%;
				height: 45px;
			}

			.fa-spinner {
				margin-left: 10px;
			}
		}
	}

	&.above-react-modal {
		z-index: 10002;
	}

	&.no-padding {
		padding: 0;
	}

	.huge-input {
		width: 100%;
		font-size: 26px;
		outline: none;
		border: none;
		border-bottom: 1px solid $grey-6;
		color: $grey-13;
		padding-left: 20px;

		@include placeholder-color($grey-8);

		&.no-border-bottom {
			border-bottom: 1px solid transparent;
		}
	}

	.huge-input-container {
		position: relative;

		.huge-input {
			padding-left: 54px;
		}

		.fa {
			position: absolute;
			top: 6px;
			left: 20px;
			font-size: 26px;
			color: $grey-8;
		}
	}

	.fullmodal-container {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		z-index: 1010;
		margin-top: 46px;
		position: absolute;
		background-color: $white;

		.fullscreen-content.up-modal-content {
			margin: auto !important;
			padding: 0 100px;
		}

		.fullmodal-control {
			margin: 48px 0;
		}
	}

	#export-contact-data-modal {
		.up-modal-header {
			h2 {
				color: $blue;
				font-size: 18px;
				font-weight: $regularFontWeight;
			}
			b {
				position: absolute;
				top: 16px;
				right: 20px;
				color: $grey-11;
				font-size: 14px;
				font-weight: $regularFontWeight;
				cursor: pointer;
			}
		}

		.up-modal-content {
			padding: 5px 20px 20px 20px;

			.contact-wrapper {
				h4 {
					padding-top: 10px;
					padding-left: 40px;
					color: $grey-13;
					font-size: 14px;
					font-weight: $regularFontWeight;
					line-height: 17px;
				}
			}

			.export-info-wrapper {
				h3 {
					margin-top: 35px;
					margin-bottom: 0px;
					color: $grey-13;
					font-size: 16px;
					font-weight: $regularFontWeight;
					line-height: 24px;
				}

				.export-info-row {
					color: $grey-11;
					font-size: 14px;
					font-weight: $regularFontWeight;
					line-height: 26px;
				}

				.spinner-wrapper {
					display: flex;
					font-size: 18px;

					.fa-spinner {
						margin: auto;
					}
				}
			}
		}

		.up-modal-controls {
			.modal-info-btns {
				.spinner-wrapper {
					padding-right: 5px;
				}
			}
		}
	}

	.integration-import-content {
		margin: 10px 0;

		& .integration-import-modal-row {
			display: flex;
			align-items: center;

			& .integration-import-modal-icon {
				display: flex;
				margin-right: 10px;
				align-items: center;
			}

			& .integration-import-modal-text {
				display: flex;
				align-items: center;
			}
		}
	}
}

.up-modal-curtain {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background-color: $black;
	z-index: $modal-z - 1;
	@include opacity(0.3);

	&.full-screen-curtain {
		@include opacity(1);
		background-color: $white;
		@include transition(opacity 600ms);
		z-index: $modal-z - 2;
	}

	&.invisible-curtain {
		background-color: transparent;
	}

	&.above-react-modal {
		z-index: 10001;
	}
}

table > caption,
legend {
	border-bottom: 2px solid $green;
	color: $white;
	font-size: 15px;
	text-align: left;

	> span {
		padding: 5px 10px 2px;
		border-radius: 0 6px 0 0;
		background: $green;
		display: inline-block;
	}
}
legend {
	padding-top: 20px;
	margin-bottom: 10px;
}

.ui-timepicker-wrapper {
	position: fixed !important; /* Makes the element flow outside of the document (fixes overflow hidden)*/
	z-index: 10002;
}

.modal-table {
	$list-row-height-modal: 60px;

	thead.modal-table-header {
		background-color: $grey-4;
		height: 16px;
		text-transform: uppercase;
		font-size: 10px;
		line-height: 16px;
		color: $grey-11;
	}

	tr.list-modal-row {
		height: $list-row-height-modal;
		border-bottom: 1px solid $grey-4;
		color: $grey-11;
		@include transition(all ease 200ms);

		td {
			height: $list-row-height-modal;
		}

		&:hover {
			background-color: $white;
		}

		.icon {
			line-height: $list-row-height-modal - 3px;

			.fa.fa-check {
				// display: none;
				@include opacity(0);
				@include transition(all ease 200ms);
				color: $bright-green;
			}
		}

		.label {
			text-transform: uppercase;
			background-color: $grey-8;
		}

		.att-ratio-wrapper {
			background-color: rgba($black, 0.1);
			height: 20px;
			width: 28px;
			float: left;
			padding: 2px;
			@include border-radius(2px);

			.att-ratio-inside {
				@include border-radius(1px);
			}
		}

		.att-preview-wrapper {
			position: relative;
			width: 40px;
			height: 40px;
			overflow: hidden;
			text-align: center;
			@include border-radius($border-radius);

			.att-preview-img {
				text-align: center;
				margin-bottom: 0 auto;
				max-height: 40px;
			}

			.att-preview-overlay {
				position: absolute;
				opacity: 0;
				@include transition(all ease 300ms);
				@include border-radius($border-radius);
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				text-align: center;
				background-color: rgba($black, 0.4);
				color: $white;
				padding-top: 10px;
			}

			&:hover {
				.att-preview-overlay {
					opacity: 1;
				}
			}
		}
	}

	.typeahead {
		position: fixed !important;
		z-index: $modal-z + 1 !important;
	}
}

.v2 {
	.up-modal-content {
		padding: 0;
	}

	.two-cols {
		.up-modal-content {
			width: 50%;
			display: inline-block;
			float: left;
			position: relative;
			padding: 0;
		}
	}

	.two-cols:after {
		clear: both;
		content: "";
		display: block;
	}
}

#insert-tag-modal {
	.th-inner--translation {
		height: 24px;
		padding-top: 2px;
		padding-bottom: 0;
		text-transform: none;
	}
	.up-panel {
		background-color: transparent;
		padding: 0px;
	}

	.search-holder {
		position: relative;
		margin: 0 10px 5px 10px;

		input[type="text"] {
			width: 100%;
			padding-left: 20px;
		}

		.fa-search {
			color: $grey-11;
			top: 4px;
			position: absolute;
			left: 6px;
			z-index: 500;
		}
	}

	.disbaled-tag {
		background-color: $grey-2;
		color: $grey-10;
		font-style: italic;
	}
}

#segment-multi-action-wrapper {
	.search-holder {
		width: 120px;
		display: block;
		position: absolute;
		top: 0;
		right: 50px;
		height: 30px;
		margin-top: 8px;
		background: $grey-4;
		border: none;
		border-radius: 3px;

		i.fa {
			color: $grey-8;
			top: 6px;
			position: absolute;
			left: 6px;
			z-index: 500;
			font-size: 16px;
		}

		#upload-files-search {
			font-size: 14px;
			width: 100%;
			height: 30px;
			background-color: $grey-4;
			border: none;
			top: 0;
			border-radius: 3px;
			position: absolute;
			padding-left: 27px;
		}
	}

	.up-modal-header {
		background-color: $white;
		box-shadow: 0 1px 2px 0 rgba($black, 0.1), 0 -4px 0 0 $blue;
	}

	.up-modal-content {
		padding: 0;
		height: 320px;
		position: relative;
		z-index: 997;

		table {
			tr {
				border-bottom: 1px solid $grey-2;

				td,
				th {
					&:first-child {
						padding-left: 20px;
						max-width: 50px;
						min-width: 30px;
					}

					&:last-child {
						padding-right: 20px;
					}
				}

				th {
					height: 30px;
					font-size: 12px;
					font-weight: $regularFontWeight;
					vertical-align: middle;
					color: $grey-11;
					line-height: 30px;

					i.fa {
						margin-left: 10px;
					}

					&.activeSort {
						color: $bright-blue;
					}
				}

				td {
					height: 52px;
					background: $white;
					vertical-align: middle;

					i.fa {
						margin-right: 10px;
					}

					span.segment-title,
					span.segment-description {
						display: block;
						text-align: left;
						font-size: 14px;
						line-height: 17px;
						color: $grey-13;
					}

					span.segment-description {
						font-size: 12px;
						line-height: 14px;
						color: $grey-11;
						width: 250px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.status {
						color: $grey-10;
						font-size: 12px;
						font-weight: $regularFontWeight;
						line-height: 14px;
						text-transform: uppercase;
					}
				}

				&.loading-row {
					border: none;

					td {
						padding: 0;
						height: 50px;
						background: rgba($black, 0.01);
						border-top: 1px solid rgba($black, 0.05);

						.upsales-loader {
							width: 30px;
							height: 30px;
							text-align: center;
							margin: 0 auto;
							display: block;
						}
					}
				}
			}
		}
	}
}

#react_alert_modal {
	.confirmation {
		margin-top: 15px;
		position: relative;

		input {
			&.valid {
				color: $bright-green;
				border: 1px solid $bright-green;
				border-right-width: 2.7em;
			}

			&.invalid {
				color: $red;
				border: 1px solid $red;
				border-right-width: 2.7em;
			}
		}

		.validation-icon {
			position: absolute;
			right: 12px;
			top: 34px;
			color: $white;
			font-size: 10px;

			.fa-lock.empty {
				color: $black;
			}
		}
	}
}

.inline-confirm-modal {
	display: inline-block;

	.inline-confirm-tab:not(.keep-position) {
		position: absolute;
		top: 0;
		right: 0;
		height: 38px;
		width: 38px;
		background: $white;
		box-shadow: $box-shadow-lg;
		text-align: center;
		z-index: 1000;

		button.fa {
			line-height: 24px;
			transition: initial;
			color: $grey-10;
		}
	}

	.inline-confirm-container {
		min-width: 188px;
		position: absolute;
		top: 38px;
		right: 0;
		box-shadow: $box-shadow-lg;
		border-radius: $border-radius 0px $border-radius $border-radius;

		background-color: $white;
		z-index: 8000;

		text-align: center;
		padding: 14px;
		cursor: default;
		.inline-confirm-title {
			color: $black;
			font-size: 16px;
			font-weight: $boldFontWeight;
			margin: 0 0 10px 0;
			white-space: nowrap;
		}

		.inline-confirm-body {
			color: $grey-10;
			font-size: 12px;
			font-weight: $regularFontWeight;
		}

		.inline-confirm-control {
			padding-top: 5px;
			.inline-confirm-button.up-btn {
				font-size: 14px;
				font-weight: $regularFontWeight;
				line-height: 22px;
				margin-top: 5px;

				&.btn-red {
					color: $white;
				}
			}
		}
	}

	.inline-confirm-container--top {
		top: unset;
		bottom: 40px;
	}
}

.inline-confirm-modal-hidden {
	display: inline-block;

	button.fa {
		line-height: 0px;
		transition: initial;
		color: $grey-10;

		&:focus {
			color: $grey-10;
		}
	}

	b.fa-spinner {
		padding: 5px;
	}
}

.inline-delete-modal {
	display: inline-block;
}

.inline-change-history {
	display: inline-block;
	float: right;
}
