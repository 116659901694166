@import "~StyleVariables";

.InputRevealButton {
	text-align: left;
	border: 1px solid transparent !important;

	&:hover {
		background-color: $white !important;
		border-color: $grey-6 !important;
	}

	&__required-ball {
		vertical-align: text-top;
		display: inline-block;
		line-height: 1em;
	}

	&__icon {
		width: 20px;
		text-align: center;
	}
}
