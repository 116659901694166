@import "~StyleVariables";

.PlanNextStepDropDown {
    margin-left: 16px;
    margin-top: 16px;
    transition: all ease-in-out 200ms;

    .DropDownButton--sm, .DropDownButton__wrapper {
        height: 34px;
    }

    .DropDownButton {
        border-radius: $border-radius;
    }

    .DropDownMenu__content {
        margin-top: 2px;
        width: 100%;
        border-radius: $border-radius;
        overflow: hidden;
        min-width: 0;
        
        .TableRow {
            .TableColumn {
                padding-top: 0;
                padding-bottom: 0;
                height: 44px;
            }
        }
    }
}