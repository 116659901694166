@import "~StyleVariables";

.ChecklistItem {
	&__content {
		width: 790px;
		align-self: center;
	}

	&__buttonBoxes {
		.ButtonBox {
			flex: 1;
		}
	}

	&__input {
		width: 380px;
	}
}

.PortedChecklistItem {
	display: block;
}
