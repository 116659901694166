@import "~StyleVariables";

.CompanyGroupContactList {
	margin-top: $space * 4;

	.ListView__header {
		.Column:last-of-type {
			flex: 0 0 180px;
		}
	}
}
