@import "~StyleVariables";
.NotesWithSignature {
	display: flex;
	flex-direction: column;

	.Textarea__input {
		height: 200px;
	}
	&__label {
		display: flex;
		justify-content: space-between;
		align-items: center;
		> .Label {
			margin-bottom: 0px;
			font-weight: $boldFontWeight;
		}
		> .Button {
			align-self: flex-end;
			padding-right: 0px;
			text-decoration: none;
		}
	}
}
