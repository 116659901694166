@import "~StyleVariables";

.FormActions {
	position: relative;
	overflow: hidden;

	&__ActionNameColumn {
		width: 50%;
	}

	&__Score {
		width: 50px;
	}

	.TableColumn {
		cursor: pointer;

		&:first-child {
			width: 50%;
			padding-left: 20px;
		}

		&:last-child {
			padding-right: 20px;
		}
	}

	.TableRow {
		&:hover {
			.FormActions__Remove {
				.Button {
					right: 6px;
				}
			}
		}
	}

	&__Emails {
		list-style: none;
		color: $bright-blue;
		font-size: 11px;
		margin: 0;
		padding: 0;

		li {
			background-color: rgba($bright-blue, 0.2);
			display: inline-block;
			padding: 3px 5px;
			margin: 2px 5px 2px 0;
			border-radius: 3px;
		}
	}

	&__AssignedUser {
		display: flex;

		.generic-avatar {
			width: 20px;
		}
	}

	&__Remove {
		width: 50px;

		.Button {
			padding: 0;
			width: 40px;
			transition: right 0.2s ease;
			position: absolute;
			right: -100%;
			top: 9px;

			&:hover {
				color: $red !important;
			}
		}
	}
}
