@import "~StyleVariables";

.UserSurvey {
	$sidebar-width: 450px;
	background-color: $grey-2;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
	padding-left: $sidebar-width;

	&__cornerClose {
		z-index: $modal-z + 2;
		align-self: flex-end;
		bottom: 48%;
	}

	&__inner {
		$width: 620px;
		position: absolute;
		width: $width;
		max-height: 100%;
		padding: 20px 10px;
		display: flex;
		margin: 85px;
		top: $space * 10;
		margin-left: calc(-#{$width / 2} + #{$sidebar-width / 2});
		left: 50%;
		overflow-y: auto;
		bottom: -10%;

		&--center {
			text-align: center;
		}
	}

	&__top-static {
		$width: 620px;
		position: absolute;
		width: $width;
		top: 20px;
		left: 50%;
		transition: all ease-out 300ms;
		margin-left: calc(-#{$width / 2} + #{$sidebar-width / 2});
	}

	&__inner-start {
		width: 357px;
		top: 30%;
		position: absolute;
	}
	&__start-page {
		width: $sidebar-width;
		background-color: transparent;
		transition: all ease-out 300ms;
		position: absolute;
		left: 50%;
		margin-left: -210px;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		&--static-left {
			left: 0;
			margin-left: 0;
			background-color: $grey-3;
		}

		.Logo {
			margin-left: 15px;
			width: 90px;
			height: 22px;
			margin-left: 20px;
			margin-top: 15px;
		}
	}
	&__finish-page {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		background-color: $success-1;
		z-index: 99999;
		right: 0;
		top: 0;
		width: 100%;
		height: 105%;
	}

	&__finish-inner {
		width: 360px;
		margin-bottom: 100px;
	}

	&__finish-icon {
		border-radius: 100%;
		background-color: #c8e6c9;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		left: 140px;
		margin-bottom: 30px;
	}

	.Card {
		box-shadow: $box-shadow-lg;
	}

	&__radio-block {
		width: 620px;
	}

	&__survey-table {
		box-shadow: 0px 1px 2px rgba($black, 0.2);
		border-bottom: 1px solid $grey-4;
	}
	&__table-text {
		margin-right: 140px;
	}

	&__low-review {
		padding-top: 15px;
	}

	&__low-review-text-column {
		padding-top: 13px;

		.Input {
			overflow: hidden;
		}
	}

	&__review-button {
		width: 50px;
	}

	&__other-goal {
		height: 40px;
		transition: height ease-out 300ms;
		overflow: hidden;

		&--expand {
			height: 90px;
		}
	}

	&__other-review {
		height: 57px;
		transition: height ease-out 300ms;
		overflow: hidden;

		&--expand {
			height: 90px;
		}
	}

	&__progressbar {
		height: 4px;
	}

	&__progressbar-row {
		padding-top: 20px;
		padding-bottom: 5%;
	}
	&__progressbar-column {
		padding-right: 15px;
	}
	&__input {
		margin-right: 30px;
	}
	&__extra-field-container {
		margin-bottom: $space * 2;
		display: flex;
		flex-direction: column;
		gap: $space;
	}

	.RadioListItem {
		cursor: pointer;
	}

	&__back-button {
		text-decoration: none !important;
	}
}
