@import "~StyleVariables";

.SalesboardSumSettings {
	&__header {
		border-left: 1px solid $grey-6;
		border-right: 1px solid $grey-6;
		margin-left: 10px;
		height: 50px;
		display: flex;
		align-items: center;
		> div {
			height: 100%
		}
	}

	&__dropdown-wrapper {
		position: absolute;
		top: 100%;
		width: 200px;
		background: $white;
		display: flex;
		flex-direction: column;
		box-shadow: 1px 0 0 0 $grey-4, 0 3px 6px 0 rgba(30, 37, 46, 0.2);

		&--align-right{
			right: 0;
		}
	}

	&__RadioItem {
		display: flex;
		flex-direction: row;
		padding: 10px;
		border-bottom: 1px solid $grey-4;
		cursor: pointer;
		&:hover {
			background-color: $grey-1;
		}
	}
	&__RadioText {
		margin-left: 10px;
	}

	&__header-icon {
		align-self: center;
		margin-left: 6px;
	}
	&__header-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-width: 132px;

		padding-left: 10px;
		padding-right: 4px;
		align-items: center;
		height: 100%;
		transition: all 0.25s ease;
		cursor: pointer;
		&:hover {
			background-color: $grey-3;
		}
	}

	&__WeightedDiv {
		display: flex;
		flex-direction: row;
		padding-left: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
		background: $grey-1;
		cursor: pointer;
		&:hover {
			background-color: $grey-2;
		}
	}
}
