@import "~StyleVariables";

.ReportcenterDescriptionFilter {
	display: inline-block;

	&__editIcon {
		opacity: 0;
	}

	&__trigger + .DropDownMenu__content {
		margin-top: 3rem;
	}

	&__trigger {
		padding: 0;
		outline: none;
		border: none;
		background-color: transparent;
		position: relative;

		&:hover {
			cursor: "pointer";
			.ReportcenterDescriptionFilter__editIcon {
				margin-left: 0.5rem;
				display: inline-block;
				transition: opacity 0.2s ease-in;
				opacity: 1;
			}
		}
		&:hover {
			.ReportcenterContent__editIcon {
				opacity: 1;
				transform: translateX(0);
			}

			.ReportcenterContent__headerDescription {
				.Icon {
					transform: translateX(0);
				}
			}
		}
	}
}

.ReportcenterFilter {
	display: inline-block;

	&__trigger {
		position: relative;
		outline: none;
		border: 1px solid $grey-4;
		border-radius: 4px;
		height: 34px;
		line-height: 34px;
		padding: 0 10px;
		background-color: transparent;
		transition: all ease-out 200ms;
		margin-right: 10px;
		max-width: 300px;

		.Text {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: $boldFontWeight;
		}

		.Text,
		.Icon {
			transition: all ease-out 200ms;
		}

		&:hover:not(.ReportcenterFilter__trigger--active):not(.ReportcenterFilter__trigger--selected) {
			background-color: $grey-1;
			border-color: $grey-6;

			.Text,
			.Icon {
				color: $grey-13;
			}
		}
	}
	&__reset-filter {
		opacity: 0.6;
	}

	&__trigger--selected:not(.ReportcenterFilter__trigger--active) {
		border-color: darken($super-light-green, 10%);
		background-color: $super-light-green;

		.Text {
			font-weight: $boldFontWeight;
			color: $green;
		}
		.Icon {
			font-weight: $regularFontWeight;
			color: $green;
		}
		&:hover {
			background-color: $super-light-green;

			.ReportcenterFilter__reset-filter {
				opacity: 1;
			}
		}
	}

	&__trigger--active {
		border-color: $grey-4;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: -2px;
			height: 4px;
			background-color: $white;
			z-index: 1000;
		}
	}

	.DropDownMenu__content {
		top: 40px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
		max-height: 410px;
		overflow: hidden;
		overflow-y: auto;
		max-width: 980px;
		min-width: 350px;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	.DropDownMenu--alignment-right > .DropDownMenu__content {
		right: 11px !important;
	}

	.DropDownMenu--alignment-left > .DropDownMenu__content {
		left: 1px !important;
	}

	&__info {
		position: sticky;
		bottom: 0;
		background-color: $grey-2;
	}

	.ReportcenterFilter__trigger--selected.ReportcenterFilter__trigger--active {
		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: -2px;
			height: 4px;
			background-color: $white;
			z-index: 1000;
		}
	}
}
