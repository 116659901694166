@import "~StyleVariables";

.SalesTabGroup {
	width: 100%;
	position: relative;
	background-color: $white;

	// /**
	// * @todo  @upsales/components 2.19.19
	// * STYLING: Leche requests the Headline with size ‘sm’ to have a font size of 22px.
	// * @link https://github.com/upsales/ui-components/issues/574
	// */
	.Headline--sm {
		font-size: 22px;
	}

	.SalesListCompanyGroup {
		padding-top: $space * 8;
	}

	&__topSection {
		border-bottom: 1px solid $grey-4;

		&__bigNumbers {
			width: 280px;
		}

		&__widget {
			width: stretch;
			border-radius: $border-radius;
			border: 1px solid $grey-4;
			flex-grow: 1;
			width: 1px; // ehhhhhhh I thought I understund css, I clearly don't

			&:has(.ColumnChartWidget__noResultRowText) {
				background-color: $grey-1;
			}

			.ReportcenterWidget {
				border-radius: $border-radius;
				&__content {
					border: none !important;
				}
			}
		}

		.SalesGraph {
			height: 240px;
		}
	}

	&__growthGrid {
		.ReportcenterWidget__content {
			border-left: none !important;
			border-radius: 0 !important;
		}

		&__infoIcon {
			.Icon {
				border-radius: $border-radius;
				height: 24px;
				width: 24px;
				display: flex;
				justify-content: center;
				align-items: center;

				&-opportunity {
					background-color: $super-light-yellow;
				}

				&-check {
					background-color: $success-1;
				}

				&-times {
					background-color: $danger-1;
				}
			}
		}
	}
}
