.package-box {
	position: relative;
	width: 100%;
	background-color: $white;
	border: 1px solid $grey-4;
	text-align: center;
	margin-bottom: 20px;
	@include transition(all ease-out 200ms);
	padding: 20px;
	min-height: 200px;
	@include box-shadow(0 1px 2px rgba($black, 0.2));

	& > .Icon-check-circle {
		position: absolute;
		left: 7px;
		top: 7px;
		font-size: 24px;
		background-color: $white;
		border-radius: 50%;
		width: 20px;
		height: 20px;

		&:before {
			position: absolute;
			left: -2px;
			top: -2px;
		}
	}

	&.package-box-block {
		width: 100% !important;
	}

	&:hover {
		@include box-shadow(0 1px 2px rgba($black, 0.2));
	}

	&.disabled {
		cursor: default;
		@include opacity(0.7);

		&:hover {
			cursor: default;
		}
	}

	&.package-box-loading,
	&.package-box-loading.cover-img {
		.overlay {
			@include opacity(1);
			background-color: rgba($bright-blue, 0.8);

			.up-btn {
				@include opacity(0);
				visibility: hidden;
			}

			.loading-indicator {
				@include opacity(1);
				visibility: visible;
			}
		}
	}

	&.package-box-selected,
	&.package-box-selected.cover-img {
		border: 1px solid rgba($bright-blue, 0.8);

		.overlay {
			@include opacity(1);
			background-color: rgba($bright-blue, 0.8);

			.up-btn {
				@include opacity(0);
				visibility: hidden;
			}

			.selected-indicator {
				@include opacity(1);
				visibility: visible;
			}
		}

		.package-box-title h2 {
			color: $bright-blue;
		}
	}

	&.package-box-bright-blue {
		background-color: $bright-blue;
		border-color: $bright-blue;
		color: $white;
	}

	&.package-box-white {
		background-color: $white;
		border-color: $white;
		color: $bright-blue;
	}

	h2 {
		margin-bottom: 20px !important;
	}

	p {
		color: $grey-11;
		margin-bottom: 10px;
	}

	.box-icon {
		font-size: 60px;
		text-align: center;
		margin-top: 60px;
		color: rgba($black, 0.2);
	}

	.package-box-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 30px;

		&.show-on-hover {
			bottom: -100%;
			background-color: $white;
			border-top: 1px solid $grey-4;
			padding: 20px 10px;
			height: auto;
		}

		.show-on-hover-child {
			visibility: hidden;
			@include opacity(0);
			@include transition(all ease-out 200ms);
		}

		&.lined {
			border-top: 1px solid $grey-4;
			padding: 15px 10px;
			background-color: $white;
			height: 44px;

			h2 {
				font-size: 16px !important;
				margin: 0 !important;
			}
		}

		.package-box-title {
			position: relative;
			max-width: 88%;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&.as-btn {
		cursor: pointer;

		&:hover {
			@include box-shadow(0 1px 10px rgba($black, 0.3));
		}

		.icon {
			position: absolute;
			width: 100%;
			left: 0;
			top: 40px;

			.fa {
				font-size: 80px;
			}
		}

		h2 {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			font-size: 18px;
			text-align: center;
			color: inherit !important;
			margin-bottom: 30px !important;
			padding: 0 10px;
		}
	}

	&.cover-img {
		padding: 0;
		border: none;
		overflow: hidden;
		max-height: 150px;
		width: 48%;
		display: inline-block;
		margin-right: 10px;
		min-height: 220px;

		&:not(.no-responsive) {
			@media (min-width: $responsiveWidht3) {
				& {
					width: 48%;
				}
			}

			@media (min-width: $responsiveWidht2) {
				& {
					width: 32%;
				}
			}

			@media (min-width: $responsiveWidht1) {
				& {
					width: 24%;
				}
			}
		}

		.preview-image {
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			height: 176px;
		}

		img {
			width: 100%;
		}

		.overlay {
			display: flex;
			flex-direction: column;
			justify-content: center;
			content: "";
			opacity: 0;
			position: absolute;
			padding: 0 20px 20px;
			top: -40px;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($black, 0);
			@include transition(all ease-out 200ms);

			.up-btn {
				@include transition(all ease-out 200ms);
				@include opacity(1);
				visibility: visible;
			}

			.selected-indicator {
				@include transition(all ease-out 200ms);
				@include opacity(0);
				position: absolute;
				top: 52px;
				left: 0;
				display: block;
				text-align: center;
				width: 100%;
				visibility: hidden;
				color: $white;
				font-size: 62px;
			}

			.loading-indicator {
				@include transition(all ease-out 200ms);
				@include opacity(0);
				position: absolute;
				top: 52px;
				left: 0;
				display: block;
				text-align: center;
				width: 100%;
				visibility: hidden;
				color: $white;
				font-size: 62px;
			}
		}

		&:not(.no-animate):not(.disabled) {
			.package-box-footer {
				&.lined {
					&:hover {
						padding: 18px 10px 16px 10px;

						.footer-sub-info {
							opacity: 1;
							margin-bottom: 0px;
						}
					}
				}
			}
		}
		.package-box-footer {
			text-align: left;
			@include transition(all ease-out 200ms);

			&.lined {
				font-size: 12px;
				overflow: hidden;

				.footer-sub-info {
					opacity: 0;
					margin-bottom: -30px;
					display: block;
					@include transition(all ease-out 200ms);
				}

				.avatar {
					position: absolute;
					right: 10px;
					top: 30%;
				}

				h2 {
					font-size: 14px !important;
					margin: 0 !important;
					display: inline-block;
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		&.package-box-loading,
		&:hover {
			.preview-image {
				background-size: 110%;
			}
		}

		&:hover:not(.package-box-loading):not(.disabled) {
			.overlay {
				background-color: rgba($dark-blue, 0.8);
				opacity: 1;
			}

			&:not(.no-animate) {
				.package-box-footer {
					&.lined {
						font-size: 12px;
						overflow: hidden;
						padding: 18px 10px 16px 10px;
						height: 70px;

						.footer-sub-info {
							opacity: 1;
							margin-bottom: 0px;
						}
					}

					&.tall {
						height: 90px;
					}
				}
			}

			.show-on-hover {
				bottom: 0;
			}

			.show-on-hover-child {
				visibility: visible;
				@include opacity(1);
			}
		}
	}
}
