@import "~StyleVariables";

.ReportcenterSchedulingForm {
	background-color: $white;
	width: 100%;
	height: 100vh;

	.Select-control {
		max-height: 50px;
	}

	&__form-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
		background-color: $white;
		padding-bottom: 100px;
	}
	&__form {
		min-width: 635px;
		width: 50vw;
		align-self: center;
		padding-top: 24px;
		& > .Column {
			padding-bottom: 16px;

			.select2-container {
				width: 100%;
				display: inline-grid;

				&.select2-container-multi .select2-choices .select2-search-field {
					max-width: 300px; /* Select2 is stupid */
				}
			}
			 & > .Label {
				font-weight: 600;
			}
		}
	}

	&__label {
		display: flex;
		justify-content: space-between;
	}

	&__result {
		align-self: center;
		min-width: 635px;
		width: 50vw;
		padding: 16px;
		max-height: 78px;
		background-color: $super-light-blue;
		color: $medium-blue;
		border-radius: 5px;
		overflow: hidden;
		transition: all cubic-bezier(0.2, 1.25, 0.58, 1.11) 300ms;

		.Icon {
			padding-right: 4px;
		}
		.Block {
			padding: 8px 0;
			display: flex;
			justify-content: space-between;

			.Select {
				width: 100%;
				padding-right: 10px;
			}

			.Select-menu {
				max-height: 100px;
			}

			.Select-multi-value-wrapper {
				max-height: 30px;
			}
		}

		&--expand {
			max-height: 136px;
			overflow: visible;

			.Button {
				align-self: center;
			}
		}
	}
	&__send-test {
		display: inherit;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		.Icon {
			color: $black;
		}
	}
	&__send-test-text {
		visibility: hidden;
		padding-top: 4px;

		color: $bright-green;
		font-family: "Roboto Mono", monospace;

		justify-content: flex-end;
		align-items: center;
		display: flex;
		opacity: 0;
		transition: visibility 0s linear 200ms, opacity 200ms;

		&--testSent {
			visibility: visible;
			opacity: 1;
			transition: 0s;
		}
		&--error {
			color: $red;
		}
		.Icon {
			padding-right: 8px;
		}
	}

	&__sendButton {
		.Icon {
			padding-right: 8px;
		}
	}

	&__button-group {
		display: flex;
		align-items: flex-start;

		.SchedulingButton {
			width: 180px;
			height: 80px;
			margin-right: 12px;
		}
	}
	&__buttons {
		width: 190px;
	}

	&__send-test-select {
		width: 500px;
	}

	.Select-multi-value-wrapper {
		//Need some dirty hack for the scroll to look presentable
		overflow-y: auto;
		width: 100%;
		max-height: 90px; // max 3 rows of recipients
		padding-bottom: 3px;
		margin-bottom: -4px;
	}

	&__invalidMail {
		display: none;
		&--invalidMail {
			display: flex;
		}
	}

	.Select-value {
		background-color: $green;
		color: $white;
		border: none;
	}

	.Select-value.outside-mail {
		background-color: $medium-blue;
	}

	.Select-value-icon {
		border-right: none;
	}

	.Select-value-label {
		padding: 2px 5px 2px 0;
	}

	.Select--single {
		.Select-value {
			background-color: $white;
		}
	}
}
