@import "~StyleVariables";

.VisitDrawer {
    &__wrapper {
        background-color: $white;
    }
    
    background-color: $grey-2;
    visibility: visible;
    height: 100%;

    &__summary {
        margin: 28px 15px 24px 15px;

    }
    &__pages {
        >.Title {
            margin: 20px 15px 10px 15px;
        }

        .Table {
            .TableHeader {
                .TableHeader__column {
                    border-top: 0;
                    &::after {
                        height: 0;
                    }
                }
            }
        }
    }

    &__lastColumn {
        padding-right: 15px;
    }
}