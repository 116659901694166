@import "~StyleVariables";

.CompanyGroupCardSidebar {
	border-right: 1px solid $grey-6;
	width: 304px;

	.GroupSelector {
		padding-left: $space * 4;
		padding-right: $space * 4;
		padding-bottom: $space * 4;
	}
}
