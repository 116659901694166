.up-page-select {
	.visit-row {
		$visit-row-height: 60px;

		cursor: pointer;
		padding: 5px 5px 5px 10px;
		margin-bottom: 1px;
		height: $visit-row-height;
		position: relative;

		&:hover .list-button {
			display: inline;
		}

		.text-container {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - 23px);
			height: 100%;

			.list-text-wrap {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				height: ($visit-row-height - 10px)/3;
				position: relative;
				vertical-align: middle;
				line-height: ($visit-row-height - 10px)/3;

				&.url-row {
					font-style: italic;
					opacity: 0.5;
				}
			}
		}
	}
}
