@import "~StyleVariables";

.RecipientsDropDown {
	margin-right: 10px;
	&__list {
		width: 350px;
		transition: background-color 200ms ease;
		padding: 2px;
		border-radius: $border-radius;
		display: flex;
		align-items: center;
		cursor: pointer;

		.Text:nth-of-type(2),
		.Icon {
			margin-left: 4px;
		}

		&:hover {
			background-color: $grey-2;
		}
	}

	&__dropDown {
		position: absolute;
		z-index: 1;
		width: 500px;
		border-radius: $border-radius;

		&__section {
			padding-left: 22px;
			border-bottom: 1px solid $grey-1;

			&__type {
				margin-bottom: 8px;
				margin-top: 8px;
			}

			&:last-of-type {
				border-bottom: none;
			}
		}

		&__youRow {
			height: 40px;
			display: flex;
			align-items: center;

			&__you {
				text-transform: capitalize;
				margin-right: 4px;
				margin-left: 4px;
			}
		}
	}

	&__userRow {
		height: 40px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;

		&__text {
			width: 430px;

			.Text {
				display: inline;
			}

			.Text:nth-of-type(2) {
				margin-left: 4px;
			}
		}
	}
}
