.find-prospects-modal {
	input.form-control {
		border-radius: 2px;
	}

	.input-group-addon {
		border-radius: 2px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.select2-container {
		.select2-choice,
		.select2-choices {
			border-radius: 2px;
		}
	}

	.required-icon {
		font-size: 18px;
		line-height: 14px;
		margin-left: 2px;
		color: $red;
	}

	.font-size-16 {
		font-size: 16px;
	}

	&.confirm-buy-single {
		.header {
			text-align: right;
			border-top: 4px solid $blue;
		}

		.content {
			.title {
				margin-bottom: 15px;
				font-size: 24px;
				color: $blue;
			}

			.section {
				padding: 20px 40px;
				padding-bottom: 20px;

				&:not(:last-child) {
					border-bottom: 1px solid rgba($black, 0.1);
				}
			}
		}
	}

	&.confirm-buy {
		$left-column-width: 320px;

		.left-column {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: $left-column-width;
			background-color: $white;
			border-right: 1px solid rgba($black, 0.1);
			overflow-y: auto;
			display: flex;
			flex-direction: column;

			.section {
				padding: 20px;
			}

			.section.section--border-bottom {
				border-bottom: 1px solid rgba($black, 0.1);
			}

			.section.section-action--bottom {
				margin-top: auto;
				padding-top: 0;
				button[type="submit"] {
					height: auto;
					line-height: normal;
					padding: 12px;
					white-space: normal;
				}
			}

			.section-descriptor {
				color: $grey-10;
			}

			.section-title {
				font-size: 18px;
				margin-top: 5px;
				margin-bottom: 10px;
			}

			.section-action {
				margin-top: 8px;
			}

			.section-action .up-ios-toggle {
				margin-right: 5px;
			}

			.section-action .fa-check {
				color: $bright-green;
				margin-right: 5px;
			}

			button.btn-bright-blue {
				min-height: 34px;
				height: auto;
				white-space: initial;
				line-height: initial;
				padding: 5px 12px;
			}
		}

		.right-column {
			position: absolute;
			top: 0;
			bottom: 0;
			left: $left-column-width;
			right: 0;
			background-color: $grey-1;
			overflow-y: auto;

			.section {
				padding: 20px;
				padding-bottom: 10px;

				&:not(:last-child) {
					border-bottom: 1px solid rgba($black, 0.1);
				}
			}
		}
	}
}

.find-prospects {
	$sidebar-width: 420px;
	$sidebar-row-height: 45px;
	$header-border-height: 3px;

	overflow-y: hidden;

	&--remove-frame {
		#find-prospects-body {
			margin-top: 15px;

			#find-prospects-body-header {
				padding: 15px !important;
			}
		}
	}

	#find-prospects-sidebar-wash-warning {
		margin: 20px;
		background-color: $white;
		@include box-shadow(0 2px 5px rgba($black, 0.2));
		@include border-radius(3px);
		overflow: hidden;
		text-align: center;

		.warning-img {
			background-image: url("../img/wash-prospects.png");
			background-size: cover;
			background-position: center center;
			width: 100%;
			height: 120px;
			margin-bottom: 10px;
		}

		h2 {
			font-size: 18px;
			line-height: 25px;
			padding: 0 20px;
		}

		p {
			padding: 0 20px;
			font-size: 14px;
			color: $grey-10;
			margin-top: 10px;
		}

		a {
			display: block;
			border-top: 1px solid $grey-6;
			font-size: 14px;
			height: 40px;
			line-height: 40px;
			margin-top: 20px;
			@include transition(all ease 200ms);

			&:hover {
				text-decoration: none;
				background-color: $grey-1;
				cursor: pointer;
			}
		}
	}

	#fetching-view {
		text-align: center;
		margin-top: 40px;

		#title {
			margin-top: 30px;
			font-size: 26px;
		}

		#subtitle {
			color: $grey-10;
			font-size: 16px;
			margin-top: 10px;
		}

		button {
			height: 40px;
			width: 230px;
			margin-top: 35px;
		}
	}

	#duns-wash-warning-view {
		width: 400px;
		background-color: $white;
		margin: auto;
		margin-top: 20px;
		box-shadow: 0px 0px 6px 0px rgba($black, 0.4);
		text-align: center;

		#row-0 {
			color: $orange;
			padding-top: 25px;
		}

		#row-1 {
			.progressbar {
				display: block;
				width: 100%;
				height: 100%;
				background-color: $grey-6;
				position: relative;
				border-radius: 4px;

				.progressbar-left {
					height: 100%;
					background-color: $orange;
					display: inline-block;
					border-radius: 4px;
					position: absolute;
					left: 0;
				}
			}
		}

		#row-2 {
			margin-top: 20px;
			font-size: 18px;
		}

		#row-3 {
			margin-top: 10px;
			padding: 0 16px;
			color: $grey-11;
		}

		#row-4 {
			margin-top: 10px;
			color: $grey-8;
			text-align: left;
			padding-left: 100px;
		}

		#row-5 {
			color: $orange;
			text-align: left;
			padding-left: 100px;
		}

		#row-6 {
			margin-top: 30px;
		}

		#row-7 {
			margin-top: 15px;
			padding-bottom: 20px;

			a {
				color: $grey-8;
			}
		}
	}

	.minimize-button {
		@include transition(all ease 200ms);
		height: 35px;
		width: 35px;
		position: absolute;
		background-color: $blue;
		top: 60px;
		left: 420px;
		color: $white;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
		text-align: center;
		line-height: 32px;
		font-size: 18px;
		z-index: 2;
		box-shadow: 2px 2px 5px 0px rgba($black, 0.4);
		display: none;

		&.minimized {
			@include transition(all ease 200ms);
			left: 0;
		}
	}

	.btn-group {
		button:not(.btn-light-grey) {
			z-index: 1000;
		}
	}

	.checkbox-fix {
		position: relative;
		top: -1px;
		font-size: 14px;
		height: 14px;
		transition: none;
	}

	.margin-right-5px {
		margin-right: 5px;
	}

	.static-popover {
		position: absolute;
		right: 0;

		.tooltip {
			z-index: 1 !important;
		}

		.tooltip-inner {
			//background-color: $grey-8;
			//color: $grey-13;
			background-color: $bright-blue;
			color: $white;
		}

		.tooltip-arrow {
			border-right-color: $bright-blue;
		}
	}

	.color-grey {
		color: rgba($grey-10, 1);
	}

	.overflow-ellipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	#find-prospects-header {
		.find-prospects-header__add-btn {
			position: absolute;
			bottom: 0;
			border-radius: 0;
			right: 0;
			height: 48px;
			line-height: 48px;
			border-top-right-radius: $border-radius;
		}

		.view-picker {
			display: inline-block;
			vertical-align: top;
			position: relative;
			height: $header-height-sm - $header-border-height;

			.view-picker-text-wrap {
				display: inline-table;
				padding: 0 10px 0 10px;
				cursor: pointer;
				height: 100%;
				border-right: 1px solid $grey-4;

				h2 {
					display: table-cell;
					vertical-align: middle;
					color: $blue;
					padding-right: 20px;
					max-width: 200px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.fa {
					display: table-cell;
					vertical-align: middle;
					font-weight: $boldFontWeight;
					color: rgba($grey-10, 1);
				}
			}

			.view-picker-save-search {
				display: inline-table;
				vertical-align: top;
				padding: 0 10px 0 10px;
			}

			.dropdown-menu {
				li {
					&:active a,
					&.active a {
						background-color: $white;
						color: $black;
						border-left: 5px solid $medium-blue;

						.edit-view,
						.remove-view {
							color: inherit;
						}
					}

					a {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						border-left: 5px solid $white;
						padding: 5px 12px;
						font-size: 16px;
						color: $grey-11;

						&:hover,
						&.hover {
							border-left: 5px solid $medium-blue;
							background-color: $white;
							color: $black;
						}

						.save-btn {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

	#find-prospects-sidebar {
		width: $sidebar-width;
		position: absolute;
		top: $header-height-sm;
		left: 0;
		bottom: 0;
		background-color: $white;
		border-right: 1px solid $grey-6;
		z-index: 200;

		&.inner-scroll {
			overflow-y: auto;
		}

		.row-header {
			height: $sidebar-row-height;
			line-height: $sidebar-row-height;
			border-right: 4px solid $grey-6;
			border-bottom: 1px solid $grey-6;
			position: relative;
			cursor: pointer;
			background-color: $white;
			display: flex;
			flex-direction: row;

			&.open {
				border-right: 4px solid $green;

				.row-header-icon,
				.row-header-title {
					color: $green;
					font-weight: $boldFontWeight;
				}

				.row-header-value {
					color: $green;
				}
			}

			.row-header-icon-wrap {
				flex-grow: 0;

				.row-header-icon {
					margin-left: 10px;
				}
			}

			.row-header-value {
				margin: 0 10px;
				flex-grow: 1;
				text-align: end;
				color: rgba($grey-10, 1);
				font-size: 12px;

				.metadata {
					display: inline-block;
					margin-left: 10px;

					.fa {
						margin-right: 3px;
					}
				}
			}

			.row-header-title {
				flex-grow: 1;
				margin-left: 10px;
			}
		}

		#company-type-filter {
			background-color: $grey-1;

			.search-wrap {
				position: relative;

				.fa {
					color: $grey-10;
					position: absolute;
					left: 20px;
					top: 8px;
				}

				.search-input {
					border: 0;
					border-bottom: 1px solid $grey-6;
					width: 100%;
					display: block;
					background-color: transparent;
					outline: 0;
					padding-left: 25px;
					padding-bottom: 3px;
				}
			}

			.zipcode-filters {
				width: 100%;

				.zipcode-tokens {
					background-color: $green;
					margin-right: 4px;
					padding-right: 4px;
					line-height: 15px;
					display: inline-block;
					box-shadow: 1px 1px 3px rgba($black, 0.3);
					border: none;
					color: $super-light-green;

					.fa {
						margin-left: 3px;
						padding: 3px;
						cursor: pointer;
					}
				}
			}

			.filter-row {
				width: 100%;
				padding: 5px 15px;
			}

			.type-toggle-buttons-container {
				.btn-group {
					display: flex;
					flex-direction: row;

					.type-toggle-buttons {
						flex-grow: 1;
						flex-basis: 50%;
						padding-left: 2px;
						padding-right: 2px;
					}
				}
			}

			.loading-row {
				text-align: center;
			}

			.row-table {
				border-bottom: 1px solid rgba($black, 0.05);
				border-top: 1px solid rgba($black, 0.05);
				padding-bottom: 15px;
				overflow-y: auto;

				.show-more {
					text-align: center;
					color: $grey-10;
					padding-top: 5px;
					cursor: pointer;
					@include transition(all ease 100ms);

					.fa {
						font-size: 24px;
						position: relative;
						top: -12px;
					}

					&:hover {
						color: black;
					}
				}
			}

			.row-table-sales {
				padding: 0;
			}

			.sales-branches-list-row {
				width: 100%;
				display: block;
				cursor: pointer;
				padding: 7px 15px;

				&:hover {
					background-color: $grey-2;
				}

				.sales-branches-input {
					display: inline-block;
					margin-right: 5px;
					cursor: pointer;
				}

				.sales-branches-sni-text {
					width: 290px;
					display: inline-block;
					vertical-align: middle;
				}

				.sales-branches-count {
					display: inline-block;
					text-align: right;
					vertical-align: middle;
					width: 60px;
					color: rgba($grey-10, 1);
					margin-top: 1px;
					float: right;
				}

				.sales-branches-client-names {
					display: inline-block;
					color: $grey-10;
					padding-left: 19px;
					width: 100%;

					.name {
						display: inline-block;
					}

					.additional-clients {
						display: inline-block;
						vertical-align: top;
					}
				}

				.sales-branches-sales {
					float: right;
					text-align: right;
					display: inline-block;
					width: 104px;
					color: rgba($bright-green, 1);
				}
			}

			.all-branches-list-row,
			.list-row {
				width: 100%;
				display: table;
				table-layout: fixed;

				.row-value {
					text-align: right;
					display: table-cell;
				}

				.row-name {
					display: table-cell;
					width: 280px;

					.row-name-text {
						max-width: 240px;
						margin-right: 5px;
						display: inline-block;
						vertical-align: top;
					}
				}

				.row-input {
					display: table-cell;
					width: 20px;
				}

				.outer-row {
					cursor: pointer;
					display: table-row;
				}

				.inner-row {
					display: table-row;

					.row-input {
						padding-left: 20px;
					}

					.row-name {
						padding-left: 20px;

						.row-name-text {
							max-width: 234px;
						}
					}
				}
			}

			.form-group {
				padding: 5px 15px;
				margin-bottom: 0px;
				margin-top: 10px;
			}

			#sort-location {
				padding: 10px 15px;
				position: relative;

				.sort-toggle {
					color: $bright-blue;
					cursor: pointer;
					margin-left: 5px;

					.fa {
						margin-left: 5px;
					}
				}

				.delete-btn {
					position: absolute;
					top: 0;
					right: 0;
				}
			}

			.company-info-section {
				padding: 5px 15px;
				border-bottom: 1px solid rgba($grey-8, 0.5);

				.to-binder {
					position: absolute;
					left: 47%;
					top: 23px;
				}
			}

			.filial-label {
				margin-left: 7px;
			}

			.range-slider {
				.delete-btn {
					position: absolute;
					z-index: 1;
					// right: 0;
					// top: -15px;
					right: -10px;
					top: -26px;
				}

				.graph {
					width: 380px;
				}

				.slider {
					position: absolute;
					top: 96px;
					width: 100%;
					margin-left: 10px;

					input {
						width: 360px;
						-webkit-appearance: none;
						--range-color: transparent;
						background-color: transparent;

						&:focus {
							outline: none !important;
						}
					}

					input[type="range"]::-webkit-slider-thumb {
						-webkit-appearance: none;
						border: 1px solid $bright-blue;
						height: 20px;
						width: 20px;
						border-radius: 50%;
						background: $white;
						cursor: pointer;
						margin-top: -4px;
						margin-left: -10px;
					}

					/* All the same stuff for Firefox */
					input[type="range"]::-moz-range-thumb {
						-webkit-appearance: none;
						border: 1px solid $bright-blue;
						height: 20px;
						width: 20px;
						border-radius: 50%;
						background: $white;
						cursor: pointer;
					}

					/* All the same stuff for IE */
					input[type="range"]::-ms-thumb {
						-webkit-appearance: none;
						border: 1px solid $bright-blue;
						height: 20px;
						width: 20px;
						border-radius: 50%;
						background: $white;
						cursor: pointer;
					}
				}
			}

			#no-filter-label {
				padding: 20px;
				text-align: center;
				color: $grey-10;
				font-size: 12px;
			}

			.input-dropdown {
				position: absolute;
				background-color: $white;
				width: 374px;
				padding: 5px;
				box-shadow: 1px 2px 3px rgba($black, 0.2);
				top: 39px;
				font-style: italic;
				color: $grey-10;
				font-size: 12px;
				border-bottom-left-radius: 1px;
				border-bottom-right-radius: 1px;
				z-index: 1000;
			}

			#feature-sa-placeholder {
				background-color: $bright-blue;
				position: relative;
				margin: 5px 15px 40px;
				text-align: center;
				color: $white;
				border-radius: 5px;

				.content {
					padding: 20px 40px;

					.logo {
						img {
							opacity: 0.4;
							margin: 10px;
						}

						.logo-text {
							letter-spacing: 6px;
							font-size: 10px;
							margin-top: 5px;
							text-transform: uppercase;
						}
					}
					.title {
						font-size: 22px;
						padding: 5px 20px 10px;
					}

					.contact-info {
						margin-top: 15px;
					}
				}

				.bottom-info {
					display: flex;
					width: 100%;
					vertical-align: middle;
					flex: 1 1 auto;
					background: $bright-blue;
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;

					.avatar {
						flex: 1 1 auto;
						vertical-align: middle;
						align-items: center;
						padding: 0 10px 0 30px;
						display: flex;
						max-width: 85px;

						.generic-avatar {
							display: flex;
							vertical-align: middle;
							flex: 1 1 auto;
							align-items: center;
							justify-content: center;
							max-width: 40px;
						}
					}

					.contact-info {
						text-align: left;
						vertical-align: middle;
						justify-content: center;
						margin: 0;
						flex: 1 1 auto;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						padding: 20px 0;

						div {
							flex: 1 1 100%;
							display: flex;
							align-items: center;
						}

						.small {
							font-size: 12px;
						}
					}
				}
			}
		}

		.order-filters {
			display: flex;
			flex-direction: row;
		}

		.select {
			display: inline-block;
			flex-basis: 33.33%;
			flex-grow: 1;

			.select-button {
				position: relative;
				width: 100%;
				padding: 6px 0;

				&.open {
					border-top-left-radius: 2px;
					border-top-right-radius: 2px;
					position: relative;
					background-color: $white;
					color: $bright-blue;
					box-shadow: none;
					border: 1px solid $bright-blue;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					margin-bottom: -1px;
				}
			}

			.menu {
				position: absolute;
				background-color: $white;
				text-align: left;
				padding: 10px 0 10px 0;
				z-index: 1000;
				border: 1px solid $bright-blue;
				box-shadow: 0 6px 12px rgba($black, 0.175);
				cursor: pointer;
				color: $black;
				border-radius: 2px;
				border-top-left-radius: 0;
				border-radius: 0 0 3px 3px;
				left: 15px;
				right: 15px;
				max-height: 390px;
				overflow-y: auto;

				.menu-trash {
					position: absolute;
					right: 14px;
					color: $grey-10;
					top: 20px;

					&:hover {
						color: $black;
					}
				}

				.menu-row {
					height: 25px;
					line-height: 25px;
					padding: 0 10px 0 10px;

					&:hover {
						background-color: $bright-blue;
						color: $white;
					}

					.input {
						margin-right: 5px;
						position: relative;
						top: -1px;
					}
				}
			}
		}

		.single-select-menu {
			background-color: $white;
			position: absolute;
			width: calc(100% + 4px);
			z-index: 10000;
			border-bottom: 1px solid $green;
			border-left: 1px solid $green;
			border-right: 1px solid $green;
			box-shadow: 0 6px 12px rgba($black, 0.175);
			border-radius: 0 0 3px 3px;
			cursor: pointer;
			top: 45px;

			.single-select-option {
				padding: 0 10px;

				&:hover {
					background-color: $green;
					color: $white;
				}
			}
		}

		@media (max-width: 1300px) {
			width: 280px;

			.select {
				.menu {
					left: 8px;
					right: 8px;
				}

				.select-button {
					i {
						display: none;
					}
				}
			}

			#company-type-filter {
				&.company-info-filter {
					.row-header-value {
						display: flex;
						line-height: 12px;
						align-items: center;
						justify-content: flex-end;
						flex-wrap: wrap;
						padding: 5px 0;
						width: 100px;
					}
				}
				#feature-sa-placeholder {
					.content {
						padding: 10px 10px;

						.title {
							font-size: 18px;
							padding: 10px 0px;
						}

						.info {
							font-size: 12px;
						}

						.contact-info {
							font-size: 12px;
						}
					}
				}

				.sales-branches-list-row {
					padding: 7px 8px;

					.sales-branches-sni-text {
						width: 152px;
					}

					.sales-branches-client-names {
						font-size: 12px;
					}
				}

				.range-slider {
					.graph {
						width: 245px;
					}

					.slider {
						//margin-left: 2px;
						margin-left: 6px;

						input {
							//width: 245px;
							width: 240px;
						}
					}
				}

				.filter-row {
					padding: 5px 8px;
				}

				.row-table-sales {
					padding: 0;
				}

				.search-wrap {
					.fa {
						left: 12px;
					}
				}

				.form-group {
					padding: 5px 10px;

					.input-group {
						width: 100%;

						input {
							border-radius: 4px;
						}

						.input-group-addon {
							display: none;
						}
					}
				}

				.all-branches-list-row,
				.list-row {
					.row-input {
						width: 16px;
					}

					.row-value {
						width: 40px;
					}

					.row-name {
						width: 150px;

						.row-name-text {
							max-width: 151px;
						}

						.fa-stack + .row-name-text {
							max-width: 130px;
						}
					}

					.inner-row {
						.row-input {
							padding-left: 10px;
						}

						.row-name {
							padding-left: 10px;

							.row-name-text {
								max-width: 165px;

								&.has-sales {
									max-width: 148px;
								}
							}
						}
					}
				}
			}
		}
	}

	#find-prospects-body {
		@include transition(all ease-in 200ms);
		position: absolute;
		top: $header-height-sm;
		left: $sidebar-width;
		bottom: 0;
		right: 0;

		#find-prospects-body-header {
			padding: 20px 15px 20px 15px;
			background-color: $grey-1;
			margin-bottom: 30px;

			.responsive-small {
				display: none;
			}
			.responsive-large {
				display: inline-block;
			}

			#company-search {
				display: inline-block;

				& + .Block {
					display: inline-block;
				}
			}
		}

		#find-prospects-tabel-wrapper {
			overflow-y: auto;
			position: absolute;
			top: 105px;
			left: 0;
			right: 0;
			bottom: 0;

			#find-prospects-header-background {
				position: fixed;
				top: 218px;
				right: 16px;
				left: 485px;
				height: 32px;
				border-top: none;
				border-bottom: 1px solid $grey-4;
				background-color: $grey-1;
				z-index: 100;
			}

			.name-column {
				line-height: 12px !important;

				.parent-row {
					display: inline-block;
					font-size: 11px;
					margin-top: 4px;

					span {
						color: $grey-10;
					}
				}
			}

			table.main-table.fixed-header {
				margin-top: 0px;

				thead {
					th {
						.th-inner {
							top: 220px;
							z-index: 101;
						}
					}
				}

				tbody {
					tr {
						td {
							height: 42px;
							line-height: 42px;

							&.tools {
								text-align: right;
							}

							.show-group-tree {
								position: relative;
							}

							&.column-checkbox {
								min-width: 35px;
							}
						}

						&.selected {
							td {
								background-color: rgba($grey-1, 1);
								border-left: 1px solid rgba($grey-1, 1);
							}
						}
					}
				}
			}
		}

		#start-info-main {
			text-align: center;
			padding-top: 140px;
			background-color: $grey-1;
			height: 100%;

			.start-info-title {
				margin-bottom: 20px;
				margin-top: 20px;
			}

			#start-info-text {
				width: 70%;
				max-width: 490px;
				font-size: 14px;
				margin: 0px auto;
				color: $grey-10;
			}
		}

		@media (max-width: 1300px) {
			left: 280px;

			#find-prospects-tabel-wrapper {
				#find-prospects-header-background {
					left: 345px;
				}
			}
		}
	}

	@media (max-width: 1599px) {
		#find-prospects-tabel-wrapper {
			overflow-x: auto;
		}
	}

	.up-paginator-wrap {
		height: 70px;
		display: flex;
		justify-content: center;
		align-items: center;

		.pagination {
			height: 24px;
			cursor: pointer;
		}
	}
}

@media (max-width: 1095px) {
	.find-prospects {
		#find-prospects-body {
			#find-prospects-body-header {
				#company-search {
					display: none;
				}
			}
		}
	}
}
