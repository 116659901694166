@import "~StyleVariables";

.FakeTooltip {
	@keyframes tooltip-bounce {
		0% {
			opacity: 0;
			transform: translateY(16px);
		}
		20% {
			opacity: 1;
			transform: translateY(16px);
		}
		100% {
			transform: translateY(0px);
		}
	}

	animation: tooltip-bounce 600ms;
	position: absolute;
	background-color: $super-light-blue;
	color: $bright-blue;
	font-size: 12px;
	padding: 5px 12px;
	box-shadow: $box-shadow-lg;
	border-radius: $border-radius;
	z-index: 10000;

	&__arrow {
		border-right: 8px solid transparent;
		border-left: 8px solid transparent;
		border-bottom: 8px solid $super-light-blue;
		top: -8px;
		left: calc(50% - 8px);
		position: absolute;
	}

	span {
		white-space: nowrap;
	}
}
