@import "~StyleVariables";

.ClientCardContent__Marketing {
	#market-chart {
		padding: 20px;
		background-color: white;
		box-shadow: $box-shadow-md;

		.ButtonSelect {
			margin: 4px;
		}
	}

	.MarketHistoryLog {
		padding: 20px;
	}

	.TimelineLoadMore {
		background-color: $grey-2;

		&__icon {
			background-color: $grey-2;
		}
	}
}
