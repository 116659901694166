@import "_variables.scss";

.EditOrder {
	display: block;
	overflow: hidden;

	// Same as Link uses, and we basically had this behaviour in the Angular code as all elements with ng-click on them 
	// had cursor: pointer.
	a {
		cursor: pointer;
	}

	.date-picker-fix * {
		height: 100%;
		cursor: pointer;
	}

	#select-account-section .Tooltip {
		position: absolute;
		top: 0;
		right: 0;
		height: 20px;
		width: 30px;
	}
}
