@import "~StyleVariables";

.ReportcenterWidgetLibrarySearchDropdown {
    margin-top: 2px;
    border: 1px $grey-6 solid;
    border-radius: 4px;
    box-shadow: $box-shadow-md;
    max-height: 274px; // 8 rows
    overflow-y: auto;
    position: absolute;
    background-color: $white;
    width: calc(20vw - 32px);
    min-width: calc(var(--sidebarMinWidth) - 32px);
    max-width: calc(var(--sidebarMaxWidth) - 32px);

    .Row {
        padding: 8px;
        display: flex;
        align-items: center;
        z-index: 1000;
        .Icon {
            padding-right: 8px;
        }
    }

    &__option {
        display: flex;
        &:hover {
            background-color: $grey-1;
            cursor: pointer;
        }
		.AssistChip {
			margin-left: 8px;
		}
    }

    &__optionCategory {
        background-color: $grey-2;
        position: sticky;
        top: 0;
    }
}
