@import "~StyleVariables";

.ListTable {	
	background-color: $grey-1;
	max-height: 800px;
	overflow: auto;

	.Block {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.TableHeader {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.TableHeader__column {
		padding-left: 3 * $space;
	}

	.Column {
		padding: 2 * $space 3 * $space;
		min-height: 11 * $space;
	}

	.Row {
		align-items: center;
	}

	.TableColumn {
		border-top: none;
	}

	&__briefcase-image {
		background-image: url('../../../../../../img/empty-briefcase.svg');
		height: 100px;
		width: 100px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}

	&__list-item {
		border-top: 1px solid $grey-4;
		align-items: center;
		height: 11 * $space;
		background-color: $grey-1;
	}

	&__list-item--selected {
		background-color: white;
	}

	&__divider {
		background-color: $grey-2;
		width: 100%;
	}

	&__icon {
		&:hover {
			cursor: pointer;

			.Icon {
				color: $green;
			}
		}

		.Icon {
			color: $grey-10;
		}
	}
}