@import "../../../styles/variables";

.ImportSelectTag {
	position: relative;
	padding: 0;

	&.has-success {
		.Select {
			.Select-control {
				border-color: $bright-green;
			}
		}
	}

	&.has-error {
		.Select {
			.Select-control {
				border-color: $red;
			}
		}
	}

	.Select {
		&.is-open {
			z-index: 10;
			width: 380px;

			.Select-control {
				border-color: $grey-6;
			}
		}
	}

	.Select-control {
		width: 100%;
		height: 34px;
	}

	.Select {
		.Select-control {
			border-color: $grey-6;
		}
	}

	.Select-menu-outer {
		max-height: 205px;
		margin-top: 49px;

		.Select-menu {
			max-height: 200px;
		}
	}

	&.hide-btn-group .Select-menu-outer {
		margin-top: 0;
	}

	.import-select-btn-group {
		top: 0;
		padding: 10px;
		position: sticky;
		background-color: $white;
		border-left: 1px solid $grey-6;
		border-right: 1px solid $grey-6;
		width: calc(100% + 2px);
		left: -1px;
	}

	.import-select-header {
		background-color: $grey-2;
		padding: 0 10px;
		height: 25px;
		color: $grey-11;
		font-size: 11px;
		line-height: 25px;
		text-transform: uppercase;
	}

	.import-select-option {
		line-height: 30px;
		font-size: 14px;
		padding: 0 10px;
		background-color: $white;
		cursor: pointer;
	}

	.import-select-option {
		transition: all 0.2s ease-in-out;
		&:hover {
			background: $grey-1;
		}
	}

	.import-select-option.import-select-custom-option {
		border-left: 5px solid $grey-6;
	}
}
