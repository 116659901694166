@import "~StyleVariables";
.EditProduct {
	&--loading {
		.Loader {
			margin: 150px auto;
		}
	}

	.Label {
		.Block,
		.Help {
			display: inline-block;
		}
		.Help {
			float: right;
		}
	}

	&__nameLabel {
		display: inline-block;
	}
	.Column {
		min-width: 0px;
	}
	.Progress {
		height: 15px;
		width: 15px;
		float: right;
	}

	.ButtonSelect {
		display: flex;
	}

	.Label {
		font-weight: $boldFontWeight;
	}

	&__disabled-product-text {
		margin-top: 10px;
		align-items: center;

		.Icon {
			margin-right: 5px;
		}
	}

	&__content-wrapper {
		position: relative;
	}
}

// followed _modal.scss line 666 (nice) when porting is finished and we no longer use the old modal this should be taken from that scss file.
.AddProductModal {
	width: 900px;
	@media (max-width: 900px) {
		margin: 0 20px;
		width: auto;
	}
}
