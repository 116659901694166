@import "~StyleVariables";

.Fullscreen__modal-close {
	position: absolute;
	height: 46px;
	width: 46px;
	border-radius: 23px;
	color: $grey-10;
	background: $grey-4;
	transition: all ease 200ms;
	top: 14px;
	right: 20px;
	z-index: 10000;
	.fa-times {
		font-size: 16px;
		line-height: 30px;
	}

	&:hover {
		background: $grey-6;
	}
}
