@import "~StyleVariables";

.BuyFromDataSourceInline {
	display: inline;
	margin: 0 5px;
	cursor: auto;

	.Button {
		&.btn-link {
			padding: 5px 10px;
			height: 23px;
			line-height: 1;
			display: inline-block;
			border-radius: 2px;

			.Icon {
				margin-right: 5px;
			}
		}
	}

	.flyout-holder {
		width: 80px;
		display: inline-block;

		.expandedButton {
			background: #fff;
			box-shadow: 0 1px 2px 0 rgba(30, 37, 46, 0.4);
			position: relative;
			z-index: 4999;
			border-radius: 2px 2px 0 0;
		}

		.Loader {
			width: 100%;
			height: 70px;
		}

		&.small-flyout {
			.Card {
				width: 180px;
			}
		}

		.Card {
			width: 240px;
			z-index: 5000;
			padding: 15px;
			min-height: 100px;
			background: #fff;
			position: absolute;
			box-shadow: 0 2px 3px 0 rgba(30, 37, 46, 0.2);
		}

		.inside-card {
			h3 {
				font-size: 14px;
				font-weight: $boldFontWeight;
				margin: 0 0 10px 0;
				padding: 0 20px;
				text-align: center;
				line-height: 19px;

				.Icon {
					color: $bright-orange;
				}
			}

			> .Text {
				margin: 0 0 15px 0;
			}

			h4 {
				margin: 0 0 15px 0;
				font-size: 16px;
			}

			p {
				text-align: center;
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 15px;

				a {
					display: block;
				}
			}

			.Row {
				margin-bottom: 10px;

				& + .Row {
					margin-top: 15px;
				}
			}

			.RadioItem {
				margin-top: 2px;
			}

			.Text {
				line-height: 12px;

				&.Text--bold {
					line-height: 22px;
				}
			}

			.pricing-wrapper {
				margin-bottom: 10px;
			}

			.pricing-title {
				display: block;
				font-weight: $boldFontWeight;
				font-size: 14px;
				line-height: 19px;
			}

			.button-darker-background {
				background: $grey-2;
				padding: 15px;
				margin: 0 -15px -15px;
			}
		}
	}
}
