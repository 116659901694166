@import "~StyleVariables";

.ReportcenterWidget {
	background-color: $white;
	display: flex;
	flex-direction: column;
	height: 100%;

	&__header {
		padding: 0 50px 0 15px;
		height: 60px;
		display: flex;
		flex-flow: row;
		justify-content: space-between;
		background-color: $white;
		z-index: 1000;

		&--showAsUnavailable {
			color: $grey-10;
			font-style: italic;
			.Icon {
				margin-right: 12px;
			}
		}

		.Title {
			align-self: center;
		}

		.Tooltip {
			width: inherit;
			align-self: center;
			overflow: hidden;
			.Title {
				line-height: 22px;
				@supports (-webkit-line-clamp: 2) {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: initial;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		> .Text {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&__info {
		display: inline-block;
		margin-left: 4px;
		font-size: 14px;
		vertical-align: top;
	}

	&__content {
		flex: 1;
		overflow: hidden;
	}

	&__widget {
		height: 100%;
	}

	&__unavailable {
		cursor: default;
		display: flex;
		align-items: flex-end;

		z-index: 1001;
		width: 100%;
		position: absolute;
		top: 0;
		height: 100%;
		transition: background-color 1s;

		&:hover {
			background-color: rgba(
				30,
				37,
				46,
				0.2
			); //Not sure how I can use $black here. We will rework the whole Reportcenter Library and the previews anyway, so I think it's fine to leave it like this for now :'( //$black
			.ReportcenterWidget__popup {
				max-height: 100%;
			}
		}
		.ReportcenterWidget__popup {
			padding: 16px;
			background-color: $super-light-blue;
			max-height: 51px;
			transition: max-height 1s;
			overflow: hidden;
			border-radius: 4px;
			width: 100%;

			.Text {
				margin-top: 12px;
			}
			.Row {
				margin-top: 12px;
			}
		}
	}

	&__previewWrapper {
		.ColumnChartWidget__label {
			height: 30px;
			flex: none;
		}

		&--unavailable {
			.ReportcenterWidget__content {
				padding-bottom: 51px;
			}
		}
	}
}