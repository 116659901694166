.EditSocialEventType__Selected-integration .Column {
	display: flex;
}

.EditSocialEventType__Selected-integration .Title {
	line-height: 28px;
	margin-left: 10px;
}
.EditSocialEventType__Selected-integration .Text {
	margin-left: 10px;
}
