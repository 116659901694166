@import "~StyleVariables";

.GroupMailEditorRecipients {
	width: 816px;
	margin: 25px auto 0;

	& > .Card {
		transition: all ease-out 200ms;
		margin-top: 15px;
	}

	&__section {
		border-top: 1px solid $grey-6;
		& > .Column {
			padding: 20px;
		}

		&--main {
			border-top: none;

			.Column:first-child {
				display: flex;
				justify-content: center;
				flex-direction: column;
			}
		}
	}

	&__select-later {
		display: block;
		margin: 20px auto;
	}

	&__info {
		height: 0;
		overflow: hidden;
		transition: height ease-out 300ms;

		&--loading {
			height: 90px;
		}

		.Headline {
			margin-top: 10px;
		}
		.Card {
			margin-bottom: 8px;
			padding: 8px;
			border-radius: 4px;
			box-shadow: none;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.Icon {
				margin-right: 8px;
			}
		}
	}

	&__progress-bar-wrapper {
		margin: 10px 0 15px 0;
	}

	&__status-text-wrapper {
		.Link,
		.Text {
			height: 30px;
			line-height: 30px;
		}
	}

	&__loader {
		margin: 0 auto;
	}

	&__pick-contacts-btn-col {
		align-items: center;
		display: flex;
	}

	&__select-wrapper {
		.select2-container {
			width: 100%;
		}
	}

	&__select-label {
		position: relative;
		.Label {
			font-weight: $boldFontWeight;
		}
		.Link {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	&__headline {
		text-align: center;
		padding: 0 100px;
		margin-bottom: 10px;

		.Headline {
			margin-bottom: 10px;
		}
	}
}
