@import "./styles/variables";
@import "./pages/FullScreenPage/FullScreenPage.scss"; // until all pages are ported

.sentry-error-embed-wrapper {
	z-index: 20000 !important;

	.form-field:not(:last-of-type) {
		display: none;
	}

	.form-submit button[type="submit"] {
		background-color: $bright-blue;
		border: 1px solid $bright-blue;
		color: $white;
		text-shadow: 1px 1px #3483de;
	}

	.close {
		line-height: 44px;
		padding: 0 10px !important;
		opacity: 0.6;
	}
}

body.new-branding {
	$combined-navbar-height: $navbar-height + $main-menu-height + 7px;
	$page-header-height: 70px;

	background-color: $grey-2;

	#coverup {
		display: none;
	}

	#sidebar {
		margin-top: 12px;
	}

	.up-header {
		border-radius: 0;
	}

	#main-wrapper #main-view #main-content .page-head,
	.up-header.fixed {
		height: $page-header-height;
	}

	.Page {
		box-shadow: none;
	}

	#main-wrapper,
	.Page,
	.Page--position,
	#main-wrapper #main-view #main-content .page-head,
	#edit-segment-root #edit-segment-body .navigation-bar,
	#sidebar {
		top: $combined-navbar-height;
		right: 0;
	}
	.Page--full-height {
		bottom: 0;
	}
	.up-header.fixed,
	.Prospecting__header,
	#edit-segment-root #edit-segment-sidebar,
	#up-list-filters #list-filters-wrap {
		top: $combined-navbar-height;
	}

	#main-wrapper .card-header {
		border-radius: 0 !important;
	}

	table.main-table.fixed-header thead th .th-inner,
	#advanced-search-wrapper.minimized #advanced-search-results-wrap .header-background,
	#advanced-search-wrapper.minimized #advanced-search-results-wrap .th-inner,
	.fixed-header-container .header-background {
		top: $combined-navbar-height + $page-header-height;
	}

	#find-prospects #find-prospects-sidebar,
	#find-prospects #find-prospects-body {
		top: $page-header-height;
	}

	#flow-editor:not(.fullscreen) {
		top: $combined-navbar-height;
		bottom: 0;
		right: 0;
	}

	.TableHeader.TableHeader--grey .TableHeader__column,
	.fixed-header-container .header-background {
		border-bottom: 4px solid $grey-4;
		background-color: $grey-1;
	}

	table.main-table.fixed-header {
		margin-top: $page-header-height + 31px;
	}

	.has-up-list-filters:not(.filters-open) {
		.up-header,
		.has-up-list-filters.filters-open .header-background {
			right: 0;
		}
	}
	#find-prospects-header,
	.card.full-size {
		right: 0;
	}

	#main-wrapper #main-view {
		margin: 0 0 45px 15px;
	}

	table.main-table.fixed-header thead th.column-tools .th-inner {
		right: 0;
	}

	#edit-segment-root #edit-segment-sidebar,
	.TodoList {
		bottom: 0;
	}

	.Page {
		border-radius: 0;
	}

	#up-list-filters #toggle-list-filters .fa.fa-filter {
		font-size: 18px;
	}

	#up-list-filters #list-filters-wrap {
		.list-filter-header {
			height: $page-header-height;
			line-height: $page-header-height;
		}
		.list-filter-page {
			top: $page-header-height;
		}
	}

	@media (min-width: 1260px) {
		.filters-open .up-list-view {
			margin-right: $up-filters-width + 15px;
		}
	}

	#account-card.has-navbar-only#account-card:not(.old) #nav,
	#account-card.has-navbar-only#contact-card:not(.old) #nav {
		right: 0;
	}

	.TableHeader__column,
	.TableHeader.TableHeader--grey .TableHeader__column,
	table.main-table.fixed-header thead th {
		text-transform: none;
		font-weight: $boldFontWeight;
		color: $greyeen;
	}

	.TableHeader.TableHeader--grey .TableHeader__column,
	table.main-table.fixed-header thead th .th-inner {
		line-height: 27px;
		padding-right: 10px;
	}

	.TableHeader.TableHeader--grey .TableHeader__column--sortable,
	table.main-table.fixed-header thead th .th-inner.sortable-header {
		transition: all ease-out 200ms;
		cursor: pointer;

		&:hover {
			background-color: $grey-4;
		}
	}

	.TableHeader.TableHeader--grey .TableHeader__column--sorted,
	.TableHeader__column--sorted,
	table.main-table.fixed-header thead th .th-inner.sorted {
		color: $black;
	}

	#advanced-search-wrapper {
		#search-header {
			right: 0;
		}
		#advanced-search-form-wrap.v2 #advanced-search-form #search-content,
		#advanced-search-form-wrap.v2 #advanced-search-form #advanced-search-side-bar {
			top: $page-header-height;
			right: 0;
		}
	}
	#advanced-search-wrapper #advanced-search-form-wrap {
		right: 0;
		top: $combined-navbar-height;
	}
	#advanced-search-wrapper.minimized #advanced-search-results-wrap {
		right: 0;
		top: $page-header-height;
	}

	.CalendarView {
		&__header {
			height: $page-header-height;
		}
		&__SubHeaderWrapper {
			top: $combined-navbar-height;
		}
	}

	.FormOverview {
		top: $combined-navbar-height;
		&__MainView {
			margin-right: 0;
		}
	}

	.Prospecting__header {
		box-shadow: none;
	}

	.InsightsCard {
		right: 0;
		bottom: 0;
	}

	.card.full-size {
		bottom: 0;
		// Jävla ful-grej
		&:after {
			content: none;
		}
	}

	.PeriodizationList {
		height: calc(100vh - (#{$combined-navbar-height} + #{$page-header-height}));
	}

	#admin {
		right: 0;
		bottom: 0;
	}

	.card #card-main-view {
		margin-top: $page-header-height;
	}
}
