.SubscriptionSummaryFooter {
	position: fixed;
	width: stretch;
	z-index: 10003;
	.SubscriptionPreviewGraph {
		height: 180px; // Leche pixel perfection
	}

	&--hasSideBar {
		right: 0;
		width: calc(100% - 280px);
	}

	&__footer-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 0px 8px 0 4px;
	}

	.Hotspot {
		position: fixed;
		margin-left: 100px;
		margin-top: 40px;
	}
}
