@import "~StyleVariables";

.ProspectingAI {
	&__tableHeader {
		display: flex;
		align-items: center;
		box-shadow: 0 -1px 0 0 $grey-4, 0 2px 4px 0 rgba(0, 0, 0, 0.2);

		.Button {
			margin-right: 10px;
		}

		& > .Block {
			display: flex;
		}
	}

	&__sni {
		max-width: 200px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&__ceoOnBoard-name {
		max-width: 350px;
	}

	&__ceoOnBoard-customer {
		max-width: 200px;
	}

	.TableHeader {
		white-space: nowrap;
	}

	.TableRow {
		white-space: nowrap;
	}
}
