@import "~StyleVariables";

$navbar-height: 50px;
$sidebar-width: 535px;
$preview-top-padding: 46px;

.DesignTab {
	transition: margin-right ease-out 200ms;

	&--sidebar-open {
		margin-right: $sidebar-width;
	}

	&__Preview {
		margin: auto;
		display: flex;
		justify-content: center;
		padding: $preview-top-padding 0 0;
	}

	&__SidebarToggle {
		position: absolute;
		z-index: 1001;
		top: 15px;
		left: -65px;
		height: 50px;
		line-height: 47px;
		width: 50px;
		background-color: $green;
		cursor: pointer;
		text-align: center;
		color: $white;
		box-shadow: 0px 0 10px 0 rgba($black, 0.4);
		border-radius: 50%;
		border: none;

		&:hover {
			background-color: darken($green, 5%);
		}
	}

	.Tabs.form-design {
		background-color: $grey-1;
		border-bottom: 1px solid $grey-5;

		.Tab {
			background-color: $grey-1;
		}
	}

	&__Intro {
		color: $grey-10;
		position: absolute;
		margin-left: 800px;
		width: 330px;

		.Icon {
			font-size: 28px;
			transform: rotate(180deg);
		}
	}

	&__SidebarScroll {
		height: calc(100vh - #{$navbar-height});
		overflow-y: auto;
	}

	&__Sidebar {
		width: $sidebar-width;
		background-color: $white;
		box-shadow: -2px 0 8px 0 rgba($black, 0.1);
		transition: box-shadow ease-out 200ms, transform ease-out 200ms;
		transform: translateX(101%);
		position: fixed;
		top: $navbar-height + 3px;
		bottom: 0;
		right: 0;
		z-index: 1003;

		&--open {
			transform: translateX(0%);
		}
	}

	.Tabs {
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
	}

	#editor-toolbox {
		position: relative;
		right: 0;
		left: 0;
		width: 100%;
		background: none;
		box-shadow: none;
		border: none;
		margin-top: 2px;
	}

	button {
		&:focus {
			outline: none;
		}
	}
}
