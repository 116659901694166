.CustomFonts {
	.admin-card {
		padding: 0 !important;
	}

	.Table {
		&Column--align-left {
			padding-left: 20px;
		}

		&Header__column {
			color: #4b5562;
			padding-left: 20px;
		}
	}

	&__header {
		padding: 20px 20px 0 20px;
	}

	&__FontPreview {
		width: 340px;
		float: right;
	}
}
