@import "~StyleVariables";

.SaveView {
	position: absolute;
	top: 0;
	right: 100%;
	background: $white;
	width: 100%;
	height: 100%;
	transition: all 0.2s;

	.Icon {
		cursor: pointer;
	}
}

.SaveView__Title {
	padding: 15px 15px 10px;
	color: $black;
	font-weight: $boldFontWeight;

	.Icon {
		margin-right: 10px;
	}
}

.SaveView__InputGroup {
	padding: 0 15px 10px;
	color: #1e252e;
	font-weight: $boldFontWeight;
}

.SaveView__InputGroup .Label {
	font-size: 14px;
	font-weight: $boldFontWeight;
	line-height: 18px;
}

.SaveView__Footer {
	display: flex;
	flex-direction: row;
	padding: 3px 3px 15px;
	justify-content: flex-end;
}

.SaveView__InputGroup--Select {
	width: 100%;

	> ul {
		max-height: 180px;
		overflow-y: auto !important; // .select2-container-multi .select2-choices is trying to override this
	}
}

.SaveView__InputGroup .Label .Progress {
	width: 15px;
	float: right;
	margin-top: 2px;
}

.SaveView__InputGroup .Textarea {
	.Textarea__input {
		height: 50px;
	}
}
