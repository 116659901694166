@import "~StyleVariables";

.RecipientListItem {
	display: flex;
	align-items: center;
	width: 400px;
	cursor: pointer;
	transition: background-color ease 200ms;
	border-bottom: 1px solid $grey-6;

	& > .Icon {
		padding-left: 12px;
		padding-right: 12px;
	}

	& > div {
		padding: 4px 8px;
	}

	&__info {
		display: flex;
		max-width: 380px;

		&__dot {
			flex-grow: 0.1;
			padding: 0px 4px;
		}
	}

	.Text:nth-of-type(1) {
		max-width: 160px;
	}

	&:hover {
		background-color: $grey-1;
	}
}
