@import "~StyleVariables";

.SaveDashboardButton {
    position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $green;
	border-radius: $border-radius-sm;

    .Button {
        padding: 0 8px 0 8px;

        .Text {
            display: inline;
        }

        .Icon {
            margin-right: 8px;
        }
    }

    &--hasOptions {
        .Button {
			border-radius: $border-radius-sm 0 0 $border-radius-sm;
            // border-right: none;
        }
    }

    .DropDownMenu {
		transition: opacity 0.2s ease-out;
		background-color: $green;
		border: 1px solid $green;
		border-radius: 0 $border-radius-sm $border-radius-sm 0;
        height: 34px;
        border-left: none;

		.DropDownMenu__content {
			border-radius: 4px;
			cursor: pointer;
            min-width: 120px;
            margin-top: 2px;

            & > div {
               background: unset !important; 
            }
		}
	}

    .DropDownButton {
        height: 100%;
        display: flex;
		text-align: center;

        &:hover {
            background-color: darken($green, 5%);
        }

		.DropDownButton__wrapper {
			height: auto;
		}
		.DropDownButton__text-wrapper {
			padding: 0;
		}
		.DropDownButton__icon-wrapper {
			margin: 4px 4px 0 4px;
			opacity: 1;
			color: $white;
			align-items: center;
            width: 100%;
		}
	}

    .Card {
		display: flex;
		align-items: center;
		padding: 8px 16px;
		margin-top: 1px;
		gap: 10px;
		white-space: nowrap;
        border-radius: 4px;

		&:hover {
			background-color: $grey-1;
		}
	}
}