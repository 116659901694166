@import "~StyleVariables";

.TicketWidget {
	position: unset;
	margin-bottom: 12px;
	.Title {
		margin-bottom: $space * 2;
		margin-left: $space * 3;
	}
	.Text {
		margin-left: $space * 4;
	}
	&__widgetColumn {
		cursor: pointer;
	}
	&__unsetblock {
		position: unset;
	}
}
