// Combine with .card class to get cool special styles
.card#admin-card {
	margin-top: 48px;
	min-height: 750px;

	#card-sidebar {
		position: absolute;
		top: 0;
		bottom: 0;

		.sidebar-nav {
			position: relative;
			list-style-type: none;
			margin: 0;
			padding: 0;
			width: 100%;

			li {
				border-bottom: 1px solid $grey-8;

				a {
					$height: 40px;
					height: $height;
					line-height: $height;
					padding: 0 10px;
					color: $grey-11;
					background-color: $white;
					display: block;
					text-decoration: none;
					font-size: 13px;

					&:hover {
						background-color: $grey-1;
					}

					&.current {
						background-color: $bright-blue;
						color: $white;
					}
				}

				.sub-menu {
					list-style-type: none;
					margin: 0;
					padding: 0;

					li {
						border-bottom: 1px solid $grey-2;

						a {
							padding-left: 30px;

							&.link {
								color: $bright-blue;
							}
						}
					}
				}
			}
		}
	}

	#card-main-view {
		margin-top: 0 !important;

		&.has-sidebar-right {
			margin-right: 300px !important;
		}

		.top-actions {
			position: fixed;
			top: 106px;
			right: 140px;
			z-index: 1050;
			width: auto;

			.form-control {
				display: inline-block;
				width: auto;
			}
		}

		.header-box {
			background-color: $white;
			display: block;
			color: $grey-10;
			padding: 15px;
			border-bottom: 1px solid $grey-4;
			font-size: 13px;

			&.has-bg-img {
				background-repeat: no-repeat;
				background-position: right 10px top;
				background-size: contain;
				padding-right: 265px;
			}

			h3 {
				color: $grey-11;
				font-size: 17px;
				margin: 0 0 5px 0;
			}

			p {
				padding: 0;
			}
		}

		label {
			color: $grey-11;
			font-size: 12px;
		}

		.row {
			margin-bottom: 15px;
		}

		h3 {
			color: $grey-11;
			font-size: 20px;
			margin: 20px 0 5px 0;
		}

		p.info {
			padding: 0;
			font-size: 12px;
			color: $grey-10;
		}

		.color-ball {
			$size: 30px;
			position: relative;
			width: $size;
			height: $size;
			line-height: $size - 4px;
			border: 1px solid transparent;
			margin: 3px;
			display: inline-block;
			background-color: $white;
			text-align: center;
			color: $grey-11;
			font-size: 13px;
			vertical-align: top;
			@include box-shadow(0 1px 2px rgba($black, 0.5));
			@include border-radius($size);

			&.add {
				border: 1px dashed $grey-11;
				@include box-shadow(none);
			}

			button.remove-color {
				border: 1px solid rgba($white, 0.5);
				@include border-radius(50%);
				height: 20px;
				width: 20px;
				line-height: 20px;
				text-align: center;
				font-size: 11px;
				background-color: rgba($red, 0.7);
				color: $white;
				padding: 0;
				position: absolute;
				top: -6px;
				right: -6px;
				visibility: hidden;
				@include opacity(0);
				@include transition(all ease-in 200ms);

				&:hover {
					background-color: $red;
				}
			}

			&:hover button.remove-color {
				visibility: visible;
				@include opacity(1);
			}
		}

		#profile-edit-color {
			margin-top: 15px;
			input {
				display: inline-block;

				&#color-name-input {
					width: 120px;
					height: 22px;
				}
			}
		}

		.font-family,
		.font-size {
			height: 22px;
			line-height: 22px;
			padding: 0 5px;
			display: inline-block;
		}

		.font-size {
			width: 50px;
		}

		.font-family {
			width: 170px;
		}

		.page-content {
			margin-top: 0 !important;

			.fixed-header-container .header-background {
				top: 144px;
			}

			table.main-table.fixed-header {
				margin-top: 31px;

				thead th .th-inner {
					top: 144px;
				}
			}

			// .header-background {
			// 	left: 365px;
			// }
		}

		.content-card table.main-table tr td {
			height: 40px;
		}

		#profile-preview {
			padding: 0;
			max-width: 500px;
			margin: 15px;

			&.fixed {
				position: fixed;
				left: 50%;
				margin-left: 55px;
				margin-right: 45px;
				top: 147px;
			}

			#fake-content-wrapper {
				overflow: hidden;
				@include border-radius(3px);
				@include box-shadow(0px 1px 5px rgba($black, 0.2));

				.profile-header {
					display: block;
					padding: 10px 20px;
				}

				.profile-content {
					padding: 25px 15px;
					margin: 15px;
					line-height: 1.3em;
				}
				.profile-button {
					border: none;
					padding: 10px 20px;
				}
				.profile-footer {
					padding: 0px 20px 10px 20px;
					line-height: 20px;
					font-size: 12px;

					& > .pull-right {
						text-align: right;
					}
				}
			}

			.hovered-element {
				position: relative;

				&:before {
					content: "";
					background-color: rgba($orange, 0.6);
					border: 1px solid $orange;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}
		}
	}
}
