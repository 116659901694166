@import "~StyleVariables";

.ReportcenterFilters {
	--max-height: 0px;
	@keyframes show-filters {
		0% {
			height: 0px;
			max-height: 0px;
			opacity: 0;
			transform: translateY(calc(var(--max-height) * -1));
			visibility: hidden;
		}
		100% {
			height: var(--max-height);
			max-height: var(--max-height);
			opacity: 1;
			transform: translateY(0px);
			visibility: visible;
		}
	}
	@keyframes hide-filters {
		0% {
			height: var(--max-height);
			max-height: var(--max-height);
			opacity: 1;
			transform: translateY(0px);
			visibility: visible;
		}
		100% {
			height: 0px;
			max-height: 0px;
			opacity: 0;
			transform: translateY(calc(var(--max-height) * -1));
			visibility: hidden;
		}
	}

	background-color: $white;
	padding: 0 15px;
	z-index: 1003;
	line-height: 42px;
	&--hide {
		animation: hide-filters 0.4s $transition-small-bounce-tf forwards;
	}

	&--show {
		animation: show-filters 0.4s $transition-small-bounce-tf forwards;
	}
}
