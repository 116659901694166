@import "~StyleVariables";

#editor-toolbox {
	.FormFields {
		font-size: 14px;

		&__AddField {
			margin: 24px;

			.fa {
				margin-top: 2px;
			}
		}

		.field-row-header {
			h4 {
				line-height: 36px;
			}
		}

		.btn-text-editor {
			width: 330px;
		}

		.icon-btn {
			padding: 0 20px 0 10px;

			.Icon {
				padding-right: 20px;
			}
		}
	}
}

.FormFields {
	&__DefaultFields {
		.EditFieldRow {
			cursor: default;

			&__Title {
				width: 100%;
			}

			.EditFieldRow__SubTitle {
				display: block;
			}
		}
	}

	&__RootFields {
		padding: 15px 25px 8px;
		border-bottom: 1px solid $grey-6;

		.Input {
			&__input {
				font-size: 14px;
			}

			&--error input {
				border-bottom: 1px solid $red;
			}
		}
	}

	&__RootField {
		margin-bottom: 25px;

		&:last-child {
			margin-bottom: 0;
			margin-top: -10px;
		}
	}

	.Label__required-indicator {
		display: inline-block;
		font: normal normal normal 14px/1 Upsales;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 6px;
		color: transparent;
		margin-top: 3px;

		&:before {
			color: $red;
			content: "\F10A";
		}
	}
}
