.EngagementEmailSettings {
	&__table-title {
		margin-top: 12px;
		font-size: 18px;
		font-weight: 400;
	}
	&__table {
		.TableHeader__column {
			padding-left: 0px;
			&:last-of-type {
				text-align: right;
				padding-left: 16px;
			}
		}
		&__row {
			transition: background-color 0.5s ease;
			background-color: white;
			td {
				transition: padding-left 0.5s ease, box-shadow 0.5s ease, color 0.5s ease;
				color: black;
				box-shadow: inset 0px 0 0 0 #d3dae4;
				&:first-of-type {
					padding-left: 0px;
				}
				&:last-of-type {
					padding-left: 16px;
					.Checkbox {
						float: right;
					}
				}
			}
			&--disabled {
				background-color: #f6f9fb;
				td {
					font-style: italic;
					color: #6b7c93;
					&:first-of-type {
						box-shadow: inset 4px 0 0 0 #d3dae4;
						padding-left: 8px;
					}
				}
			}
		}
	}

	&__card-title {
		display: flex;
	}

	&__card-header {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		.Title {
			display: flex;
			align-items: center;
			margin-right: 15px;
		}
		.Toggle {
			margin-right: 15px;
		}
	}
}
