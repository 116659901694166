@import "~StyleVariables";

.PotentialOpportunityValue {
    .Headline {
        margin-top: 24px;
        margin-left: 16px;
        margin-bottom: 14px;
    }

    .TableRow {
        background-color: $white;
        
        .TableColumn {
            min-width: 120px;
            padding-top: 17px;
            padding-bottom: 17px;
        }
    }

    &__loader {
        display: flex;
        justify-content: center;
    }

    &__quantity-wrapper {
        display: flex;
        border: 1px solid $grey-6;
        border-radius: $border-radius;
        max-width: 200px;

        .Input__input {
            border: none;
            border-radius: 0;
        } 
        
        .NumberInput {
            width: 45%
        }
    }
}