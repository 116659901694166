@import "~StyleVariables";

.MarketingBoardCard {
	position: relative;
	padding: 7px 10px;
	background-color: $white;
	box-shadow: 0 2px 4px rgba($black, 0.2);
	border-bottom: 1px solid $grey-1;
	min-height: 60px;

	&--external {
		background-color: $grey-2;
	}

	&__name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;

		&--avatar {
			padding-right: 25px;
		}
	}

	&__icon {
		color: $grey-10;
	}

	&__sub-info {
		margin-top: 3px;

		.Column {
			.Text {
				display: inline-block;
			}
		}
	}

	&__avatar {
		position: absolute;
		top: 7px;
		right: 10px;
	}

	&__lead-right {
		display: flex;
		flex-wrap: nowrap;
		float: right;
	}

	&__lead-right .Tooltip {
		display: inline-block;
	}

	&__add-to-upsales {
		color: $bright-blue !important;
	}

	&__add-to-upsales,
	.DisqualifyDropDown__disq {
		padding: 0 5px !important;
	}

	.DisqualifyDropDown__disq {
		margin-right: 5px;
	}

	.DisqualifyDropDown__disq,
	&__add-to-upsales,
	.AssignButton {
		vertical-align: top;
		height: 16px !important;
		line-height: 16px !important;
		font-size: 10px !important;
	}
}
