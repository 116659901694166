@import "~StyleVariables";

.SubscriptionAdvanceSettings {
	&__createOrdersInAdvanceTitleContainer {
		display: flex;
		flex-direction: column;
	}

	&__createOrdersInAdvanceDescriptionContainer {
		display: flex;
	}

	&__createOrdersInAdvance {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row-reverse;
	}

	&__createOrdersInAdvanceHelp {
		color: $grey-13;
	}

	.TableHeader {
		.TableHeader__column:first-child {
			font-size: inherit;
			text-transform: none;
		}
	}

	&__emptyContent {
		display: flex;
		justify-content: center;
	}

	&__addDefaultOptions {
		padding-right: 0;
		padding-left: 0.5rem;
		font-size: inherit;
	}

	&__allOptionsRemovedText {
		display: flex;
		align-items: center;
	}

	&__addOption {
		display: flex;
		align-items: center;
		padding-left: 0;
	}

	&__removeOptionTooltip {
		text-align: center;
	}
}
