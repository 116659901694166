.criteria-row--prospecting-financials .select2-container {
	width: 72px;
	margin-left: auto;
	.select2-chosen {
		margin-right: 0px;
	}
}

.ProspectingDrilldown--financials {
	.flex-input-group__addon {
		width: 60px;
		flex-shrink: 0;
	}
}

.CriteriaDrilldownWrapper--open.ProspectingDrilldown--financials .drilldown-animation-wrapper {
	max-height: 350px;
}
