@import "../../../../styles/variables";

.action-square {
	display: inline-flex;
	width: 35px;
	height: 35px;
	justify-content: center;
	align-items: center;
	background:  $rose-4;
	color: white;
	border-radius: 3px;

	b {
		font-size: 16px;
	}
}
