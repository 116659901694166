#flow-inner {
	position: absolute;
	contain: strict;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: $grey-4;

	&.dragging {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}
}

.flow-inner--popup-open {
	.flow-editor-clickToAddTooltip,
	.flow-editor-clickToAddTooltip-text,
	.flow-editor-clickToAddTooltip-pointer {
		display: none;
	}
}

.inactive-flow {
	#edit-flow-sidebar .section:first-child {
		background-color: $grey-4;
	}
}

#segment-flow.standalone {
	top: 0px;
}

#edit-flow-root {
	$sidebar-width: 250px;
	$grey-text: $grey-10;

	#edit-flow-sidebar {
		position: fixed;
		top: 97px;
		bottom: 15px;
		width: $sidebar-width;
		background-color: $white;
		border-top-left-radius: 2px;
		@include box-shadow(1px 2px 3px rgba($black, 0.1));
		z-index: 1001;

		.section {
			padding: 15px;
			border-bottom: 1px solid rgba($black, 0.1);
			@include transition(background-color ease 200ms);
		}

		.section-selection {
			margin-bottom: 10px;

			span {
				font-size: 16px;
			}

			button {
				padding: 0;
				float: right;
			}
		}

		.section-mail-settings {
			b {
				float: right;
			}
		}

		.section-description {
			min-height: 20px;

			.hoverable-placeholder {
				width: 90%;
				background-color: rgba($black, 0.1);
				height: 14px;
				margin-top: 3px;
				float: left;
				transition: all 0.4s;
				opacity: 0;
				border-radius: 3px;
			}

			&:hover {
				.hoverable-placeholder {
					opacity: 1;
				}
			}
		}

		.section-name,
		.section-description {
			color: $grey-13;
			font-size: 20px;
			position: relative;
			word-break: break-word;
		}

		.section-description {
			margin-bottom: 20px;
			font-size: 12px;
			color: $grey-11;
		}

		.section-nocontacts {
			text-align: center;
			font-size: 14px;
			color: $grey-10;

			button {
				padding: 0;
				margin: 20px 0 0 0;
			}
		}

		.section-contacts {
			.no-segment-connected {
				display: block;
				font-size: 16px;
				color: $grey-9;
				margin-bottom: 20px;
			}

			.pick-segment-wrapper {
				position: relative;

				.segment-label {
					color: $black;
					font-size: 14px;
				}

				.btn.btn-link {
					position: absolute;
					right: 0;
					padding: 0;
					line-height: 1;
				}

				.select2-container {
					margin-top: 5px;
				}
			}
		}

		.section-created {
			padding-top: 10px;

			span.title {
				text-transform: uppercase;
				font-size: 12px;
				color: $grey-10;
			}

			.user-holder {
				margin: 5px 0 0;

				&:after {
					clear: both;
					content: "";
					display: block;
				}

				.user-image {
					margin-right: 10px;
					display: table-cell;
					padding-right: 5px;
				}

				.user-user {
					vertical-align: middle;
					display: table-cell;

					span {
						text-align: left;
						float: none;
						display: block;

						&.creation-date {
							font-size: 12px;
							color: $grey-10;
						}

						&.user-name {
							color: $grey-13;
						}
					}
				}
			}
		}

		.section-status {
			margin: 20px 0 0;

			> span:first-child {
				text-transform: uppercase;
				font-weight: normal;
				font-size: 12px;
				color: $grey-10;
			}

			.status-holder > span {
				font-size: 12px;

				&.link {
					float: right;
					color: $bright-blue;
					font-weight: $regularFontWeight;
					cursor: pointer;

					&:hover {
						color: $blue;
						text-decoration: underline;
					}
				}
			}
		}
	}

	#edit-flow-body {
		position: absolute;
		top: 0;
		bottom: 0;
		left: $sidebar-width;
		right: 0;
		background-color: $grey-1;
		border-top-right-radius: 2px;

		.nothing-to-see-wrapper {
			position: relative;
			top: 0px;
			padding: 80px 120px;
			text-align: center;

			h2 {
				font-size: 24px;
			}

			p {
				margin: 20px 0;
			}

			button {
				width: 280px;
				height: 45px;
				font-size: 15px;
				font-weight: normal;
				margin-top: 20px;
			}
		}
	}
}

#flow-zoom-controls {
	$size: 30px;
	position: absolute;
	top: 20px;
	right: 20px;
	width: $size;
	height: $size * 2;
	@include box-shadow(0 10px 20px rgba($black, 0.2));
	@include border-radius(3px);
	background-color: $white;
	overflow: hidden;

	button {
		display: block;
		margin: 0;
		padding: 0;
		text-align: center;
		line-height: $size;
		height: $size;
		width: $size;
		font-size: 12px;
		color: $grey-10;
		outline: none;
		border: none;
		background-color: transparent;
		@include transition(all ease 300ms);

		&:hover {
			background-color: $green;
			color: $super-light-green;
		}

		&[disabled] {
			background-color: $grey-3;
			color: rgba($grey-10, 0.5);
		}
	}
}

#flow-editor {
	$header-height: 47px;
	position: fixed;
	top: $main-top;
	left: $sidebar-width;
	right: 15px;
	bottom: 15px;
	@include transition(all ease 200ms);
	background-color: $grey-4;

	&.fullscreen {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: $modal-z - 2;
	}

	.flow-editor-clickToAddTooltip {
		filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
	}

	#flow-editor-loader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $grey-1;
		visibility: hidden;
		@include opacity(0);
		@include transition(all ease 200ms);

		&.visible {
			visibility: visible;
			@include opacity(1);
		}

		.upsales-loader {
			$size: 100px;
			position: absolute;
			top: 50%;
			left: 50%;
			width: $size;
			height: $size;
			margin-left: -($size/2);
			margin-top: -($size/2);
		}
	}

	#flow-editor-header {
		position: relative;
		height: $header-height;
		background-color: $white;

		h2 {
			line-height: 18px;
			font-size: 18px;
			font-weight: $boldFontWeight;
			color: $grey-13;
			display: block;
			padding: 7px 10px 0;
		}

		.subtitle {
			font-size: 12px;
			color: $grey-11;
			margin: 0 0 7px 10px;
			display: block;
		}

		.header-tools {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;

			button {
				background-color: transparent;
				height: $header-height;
				line-height: $header-height;
				min-width: $header-height;
				font-size: 14px;
				border: none;
				color: $grey-10;
				padding: 0 20px;

				&:not(.save) {
					border-left: 1px solid $grey-8;
				}

				&.save {
					color: $white;
					background-color: $green;
					min-width: 120px;
					@include transition(all ease 200ms);

					&[disabled] {
						@include opacity(0.5);
					}
				}
			}
		}
	}

	#flow-wrap {
		position: absolute;
		top: $header-height;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.snooze-component {
	display: block;
	text-align: center;
	margin: 20px 0 0 0;

	.snooze-tooltip {
		position: relative;
		background-color: $blue;
		@include border-radius(3px);
		display: inline-block;
		color: $white;
		padding: 3px 5px;
		margin-right: 10px;

		&:after {
			content: "";
			position: absolute;
			top: 50%;
			margin-top: -5px;
			right: -10px;
			border: 5px solid transparent;
			border-left-color: $blue;
			width: 0;
			height: 0;
		}

		.fa {
			margin-right: 5px;
		}
	}
}

#popup-curtain {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($black, 0);
	visibility: hidden;

	&.visible {
		visibility: visible;
	}
}

#flow-popup {
	position: absolute;
	min-width: 360px;
	margin-left: -180px;
	background-color: $white;
	@include box-shadow(0px 2px 4px rgba($black, 0.2));
	@include border-radius(3px);
	visibility: hidden;
	@include transition(opacity ease 200ms);
	@include opacity(0);

	&.visible {
		@include opacity(1);
		visibility: visible;
	}

	&.preview-popup {
		margin-left: -160px;
		margin-top: 18px;
	}

	&.snooze-preview {
		width: 180px;
		margin-left: -20px;

		.preview-close {
			left: 0;
			margin-left: 0;
		}
	}

	&.ab-testing-preview {
		width: 450px;

		.preview-content {
			height: 270px;
		}
	}

	.popup-header {
		position: relative;
		width: 100%;
		background-color: $grey-1;
		height: 45px;
		line-height: 45px;
		padding: 0 15px;
		color: $bright-blue;
		border-bottom: 1px solid $grey-4;
		@include border-radius(3px 3px 0 0);

		&.turcose {
			background-color: $turcose;
			color: $white;
		}

		&.orange {
			background-color: $orange;
			color: $white;
		}

		&.bright-blue {
			background-color: $bright-blue;
			color: $white;
		}

		&.purple {
			background-color: $purple;
			color: $white;
		}

		&.dark {
			background-color: $grey-11;
			color: $white;
		}

		.remove-btn,
		.back-btn {
			border: none;
			margin: 0;
			padding: 0;
			outline: none;
			width: 45px;
			height: 45px;
			text-align: center;
			@include transition(color ease 200ms);
			color: rgba($white, 0.7);
			background-color: transparent;

			&:hover {
				color: $white;
			}
		}

		.back-btn {
			margin-left: -20px;
		}

		.remove-btn {
			margin-right: -20px;
			float: right;
		}
	}

	.popup-header-new {
		position: relative;
		width: 100%;
		background-color: $grey-1;
		height: 45px;
		line-height: 45px;
		padding: 0 15px;
		color: $bright-blue;
		border-bottom: 1px solid $grey-4;
		@include border-radius(3px 3px 0 0);

		&.white {
			background-color: $white;
			color: $black;
		}

		.remove-btn,
		.back-btn {
			border: none;
			margin: 0;
			padding: 0;
			outline: none;
			width: 45px;
			height: 45px;
			text-align: center;
			@include transition(color ease 200ms);
			color: $black;
			background-color: transparent;
		}

		.back-btn {
			margin-left: -20px;
		}

		.remove-btn {
			margin-right: -20px;
			float: right;
		}
	}

	.popup-content {
		padding: 15px;

		& > .send-mail-flex {
			display: flex;
			justify-content: space-between;
			gap: 8px;

			& > .TemplateCard {
				height: 230px;
				width: 160px;
			}

			& > div:last-child {
				width: 300px;
			}
		}

		& > .btn-flex {
			display: flex;
			justify-content: right;
			margin-top: 10px;
		}

		&.abtesting-darker {
			background-color: $grey-1;
			border-top: 1px solid $grey-4;
			padding: 30px 15px 15px;

			.pick-variantA,
			.pick-variantB,
			.ab-testing-optout-select {
				margin-top: 10px;
				margin-bottom: 15px;
			}

			#template-select-btn {
				border: 1px solid $bright-blue;
				padding: 0 10px !important;
				position: relative;
				box-sizing: border-box;

				& > span {
					display: block;
					text-align: center;
				}

				.fa-plus {
					position: absolute;
					left: 10px;
				}

				&.got-template {
					border-color: transparent;
					padding: 0 !important;

					.fa-plus {
						visibility: hidden;
					}

					> span {
						text-align: left;
						font-size: 14px;
					}
				}
			}
		}

		label {
			font-size: 12px;
			color: $grey-10;
			font-weight: normal;

			&:not(:first-of-type) {
				margin-top: 10px;
			}
		}

		.info {
			color: $grey-10;
			font-size: 12px;
			display: block;
			margin-top: 10px;
		}

		h3 {
			font-size: 16px;
			font-weight: normal;
		}
	}

	#template-select-btn {
		position: relative;
		text-align: left;
		padding: 0;

		.fa {
			position: absolute;
			right: 0;
			top: 10px;
		}
	}

	.plus {
		$size: 30px;
		position: absolute;
		top: -($size/2);
		left: 50%;
		height: $size;
		width: $size;
		border-radius: $size;
		margin-left: -($size/2);
		background-color: $grey-1;
		color: $bright-blue;
		text-align: center;
		line-height: $size;
		z-index: 1001;

		&.turcose {
			background-color: $turcose;
			color: $white;
		}

		&.orange {
			background-color: $orange;
			color: $white;
		}

		&.bright-blue {
			background-color: $bright-blue;
			color: $white;
		}

		&.purple {
			background-color: $purple;
			color: $white;
		}
		&.rose-4 {
			background-color: $rose-4;
			color: $white;
		}

		&.popup-header-green {
			background: $green;
			color: $white;
		}

		&.dark {
			background-color: $grey-11;
			color: $white;
		}
	}

	.popup-type {
		width: 120px;
		height: 120px;
		border-left: 1px solid $grey-4;
		text-align: center;
		line-height: 17px;
		font-size: 14px;
		display: inline-block;
		vertical-align: top;
		background-color: $white;
		@include transition(background-color ease 200ms);

		&:first-of-type {
			border-left: none;
		}

		&:hover:not(.disabled) {
			background-color: $grey-1;
		}

		&.disabled {
			background-color: $grey-4;
			color: $grey-8 !important;
		}

		.icon-wrap {
			line-height: 30px;
			font-size: 24px;
			margin-top: 30px;
			margin-bottom: 18px;
			display: block;
		}
	}

	.flow-row-header,
	.flow-row {
		width: 100%;
		padding: 0 10px;
		font-size: 14px;
	}

	.flow-row-header {
		background-color: $grey-4;
		line-height: 30px;
		height: 30px;
		text-transform: uppercase;
		font-size: 12px;
	}

	.flow-row {
		position: relative;
		background-color: $white;
		line-height: 40px;
		height: 40px;
		border-bottom: 1px solid $grey-4;
		@include transition(padding-left ease 200ms);
		cursor: pointer;

		&:hover {
			padding-left: 15px;
			.fa:last-of-type {
				color: $black;
			}
		}

		&:last-of-type {
			border-bottom: none;
		}

		[class^="up-icon-"],
		.fa:first-of-type {
			margin-right: 5px;
			width: 20px;
			text-align: center;
		}

		.fa:last-of-type {
			position: absolute;
			right: 10px;
			top: 10px;
			font-size: 18px;
			color: $grey-8;
			@include transition(color ease 200ms);
		}
	}

	.preview-content {
		height: 200px;
		@include border-radius(3px);
		overflow: hidden;

		.preview-left,
		.preview-right {
			display: inline-block;
			height: 100%;
			vertical-align: top;
			position: relative;
			width: 180px;
		}

		.preview-right {
			&.orange {
				background-color: rgba($orange, 0.1);
			}

			&.turcose {
				background-color: rgba($turcose, 0.1);
			}

			&.bright-blue {
				background-color: rgba($bright-blue, 0.1);
			}

			&.purple {
				background-color: rgba($purple, 0.1);
			}

			&.grey {
				background-color: $grey-1;
				border-left: 1px solid $grey-4;
			}

			.title {
				display: block;
				font-size: 14px;
				text-align: center;
				margin-top: 20px;
			}

			&.ab-testing-preview {
				.title {
					margin: 0;
					text-align: left;
				}
			}

			.contacts-counter {
				position: absolute;
				width: 50px;
				height: 50px;
				text-align: center;
				font-size: 16px;
				left: 50%;
				margin-left: -25px;
				top: 80px;

				.fa {
					display: block;
				}
			}

			.chart-wrap {
				position: absolute;
				left: 0;
				top: 47px;
				width: 180px;
				height: 100px;
			}

			.legend {
				position: absolute;
				top: 150px;

				.legend-item {
					position: relative;
					display: block;
					padding-left: 35px;
					font-size: 12px;
					height: 17px;
					line-height: 17px;

					.color {
						$size: 10px;
						position: absolute;
						width: $size;
						height: $size;
						left: 15px;
						top: 3px;
						@include border-radius($size);

						&.orange {
							background-color: $orange;
						}

						&.purple {
							background-color: $purple;
						}

						&.bright-purple {
							background-color: $bright-purple;
						}

						&.dark-purple {
							background-color: $dark-purple;
						}

						&.dark-orange {
							background-color: $dark-orange;
						}

						&.grey {
							background-color: $grey-6;
						}

						&.turcose {
							background-color: $turcose;
						}
					}
				}
			}
		}

		.ab-testing-preview {
			padding: 15px;
			width: 270px;

			label {
				color: $grey-13;
				display: block;
				line-height: 1;
			}

			.title {
				img {
					margin-left: -2px;
					margin-right: 5px;
					font-weight: $boldFontWeight;
					height: 20px;
				}
			}

			.contacts-and-split-count {
				display: block;
				margin: 15px 1px;
				color: $grey-10;
				font-size: 12px;
				font-weight: $boldFontWeight;

				b.fa {
					margin-right: 5px;
				}

				.split {
					margin-left: 20px;
				}
			}

			.data-variant {
				margin-bottom: 20px;

				.data-variant-col {
					display: block;
					width: 50%;
					float: left;

					.percent {
						font-size: 28px;
						color: $grey-11;
						font-weight: $boldFontWeight;
					}

					.open-num {
						display: inline-block;
						vertical-align: top;
						padding: 5px;
						color: $grey-8;
					}

					.text {
						color: $grey-10;
						display: block;
						line-height: 1;
						margin-top: -2px;
					}
				}

				&:after {
					clear: both;
					content: "";
					display: block;
				}
			}
		}

		.step-type {
			padding: 7px 0 7px 40px;
			font-size: 12px;
			line-height: 16px;

			&.one-line {
				line-height: 32px;
			}
		}

		.step-icon {
			position: absolute;
			top: 15px;
			left: 11px;
			width: 18px;
			height: 18px;
			text-align: center;
			color: $white;
			font-size: 10px;
			line-height: 18px;

			&:after {
				position: absolute;
				left: 0;
				top: 0;
				content: "";
				width: 18px;
				height: 18px;
				@include border-radius(2px);
				@include box-shadow(0px 1px 2px rgba($black, 0.1));
			}

			&.orange:after {
				background-color: $orange;
			}

			&.bright-blue:after {
				background-color: $bright-blue;
			}

			&.turcose:after {
				background-color: $turcose;
			}

			&.purple:after {
				background-color: $purple;
			}

			&.rotate:after {
				@include transform(rotate(45deg));
			}

			&.round:after {
				@include border-radius(18px);
			}

			&.dark {
				color: $white;

				&:after {
					background-color: $grey-11;
				}
			}

			b {
				position: relative;
				z-index: 1000;
			}
		}

		h3 {
			margin: 0 10px;
			padding: 0;
			font-size: 18px;
			color: $grey-11;
			max-height: 114px;
			overflow: hidden;
		}

		.bottom-link {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 40px;
			line-height: 40px;
			padding: 0 10px;
			border-top: 1px solid $grey-4;
			font-size: 12px;
		}

		.snooze-bottom {
			position: absolute;
			top: 80px;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $grey-11;
			color: $white;
			padding: 5px 10px;
			font-size: 16px;

			.title {
				display: block;
				color: $grey-8;
				margin-top: 10px;
				font-size: 13px;
			}

			.fa {
				margin-right: 5px;
			}

			b[data-elevio-article] {
				position: absolute;
				right: 0;
				top: 2px;
			}
		}
	}

	.preview-close {
		$size: 40px;
		position: absolute;
		top: -($size - 3px);
		left: 180px;
		height: $size;
		width: $size;
		@include border-radius(3px);
		margin-left: -$size;
		background-color: $white;
		color: $grey-10;
		text-align: center;
		line-height: $size;
		z-index: 1001;
		cursor: pointer;
	}

	&.abTestingPreview {
		&.preview-popup {
			margin-left: -110px;
			width: 220px;

			.preview-close {
				left: 132px;
			}

			.abtesting-preview-content {
				overflow-y: initial;
			}
		}
	}

	.ab-testing-content {
		padding: 30px 15px;
	}

	.ab-testing-header {
		margin: 0;
		text-align: center;
		line-height: 19px;
		color: $grey-11;
	}

	.ab-testing-slider-wrapper {
		padding: 70px 0 15px 0;
		position: relative;
	}

	.ab-testing-variants {
		position: absolute;
		color: $grey-8;
		top: 10px;
		font-size: 12px;

		&.variantA {
			left: 0;
		}

		&.variantB {
			right: 0;
		}
	}

	.warning {
		background-color: rgba($orange, 0.1);
		border: 1px solid rgba($orange, 0.2);
		margin: 0 -15px;
		padding: 10px 15px;
		border-right: none;
		border-left: 3px solid $orange;

		i.fa-exclamation-triangle {
			color: $orange;
			margin-right: 15px;
		}
	}
}
