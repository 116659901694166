.SecondScreen {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	height: 100vh;
	width: 100%;
	cursor: pointer;
	position: absolute;
	top: 0;

	&__thisYear {
		text-align: center;
	}

	&__thisYearValue {
		margin-top: 26px;
		text-align: center;
	}

	img {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 320px;
	}
}
