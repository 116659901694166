.DrawerInfoProgress {
	position: relative;
	height: 90px;
}

.DrawerInfoProgress .Progress {
	position: absolute;
}

.DrawerInfoProgress__progress-info {
	position: absolute;
	left: 50%;
	top: 30px;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
}
