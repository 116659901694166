.ValidDomainModal {
	table {
		width: 100%;
	}

	#valid-domain-modal {
		.up-panel {
			.up-panel-content {
				margin-top: 0;
			}
		}
	}
}
