@import "~StyleVariables";

.NotificationDropdown {
	width: 400px;
	height: 100%;

	&__card {
		height: 100%;
	}

	&__card-header {
		cursor: pointer;
	}

	&__content {
		padding: 0;
		height: calc(100% - 100px);
	}

	&__tabs {
		box-shadow: 0 1px 2px rgba($black, 0.2);
	}
}
