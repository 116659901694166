@import "~StyleVariables";

.ClientCardContent__Sales {
	&__showInactiveAgreementToggler {
		width: 100%;
	}

	background-color: $white;

	.ListView {
		margin-top: 24px;
		> .Page {
			box-shadow: none;
			> .Headline {
				margin-left: 15px;
				margin-bottom: 16px;
			}
		}
	}

	&__tableTitle {
		padding: 16px;
		align-items: center;
		gap: 4px;
	}

	/**
     * @todo @upsales/components 2.12.3
     * DropDownMenu styling: when using noMinWidth prop and ellipsis text elements (here with ClickableItem), the text is cut short.
     * {@link https://github.com/upsales/ui-components/issues/499}
     */
	.SalesAddButton .DropDownMenu__content {
		width: max-content;
	}
}
