.TextEditor .cke {
	opacity: 1;
	border: none;
	width: 100% !important;
}

.cke_panel {
	z-index: 10003 !important;
}

// third party plugin, therefore ignoring 4px increment rule :(
.cke_combo_text {
	width: 55px !important;
}