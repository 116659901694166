// Use mixin for color variations of dropdown menus.

@mixin up-dropdown-menu($bg, $border, $color, $shadow: true) {
	background-color: $bg;
	border: 1px solid $border;
	color: $color;
	margin-top: -1px;

	& > li {
		color: $color;

		&.dropdown-header {
			padding: 4px 20px;
			color: rgba($color, 0.8);
			font-weight: normal;
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		&.divider {
			margin: 0;
			background-color: rgba($black, 0.1);
		}

		& > a,
		& > .dropdown-option{
			color: $color;
			padding: 8px 20px;

			@if $shadow == true {
				text-shadow: 1px 1px darken($bg, 10%);
			}

			&:hover {
				background-color: darken($bg, 8%);
				text-decoration: none;
				color: inherit;
			}
		}

		& > .dropdown-option {
			cursor: pointer;
			white-space: nowrap;
		}

		&.disabled > a {
			color: lighten($color, 50%);

			&:hover {
				background-color: $bg;
			}
		}
	}

	&.lined {
		background-color: $white;
		border-color: $border;
		color: $bg;

		& > li {
			color: $bg;

			&.dropdown-header {
				padding: 4px 20px;
				color: rgba($bg, 0.8);
				font-weight: normal;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			& > a {
				background-color: $white;
				color: $bg;
				text-shadow: none;
			}
		}
	}

	&.limited {
		max-height: 400px;
		overflow: hidden;
		overflow-y: auto;
	}
}

.dropdown {
	.dropdown-menu {
		@include border-radius(0 0 $border-radius $border-radius);
		text-align: left;
		border-top: 1px solid $grey-8;
		z-index: 1002;
		padding: 0;

		&.up-dropdown-white {
			@include up-dropdown-menu($white, $grey-8, $grey-13, false);

			li {
				color: $grey-13;
				border: 0;

				&.dropdown-header {
					color: $grey-11;
				}

				&.divider {
					margin: 0;
					background-color: rgba($black, 0.1);
				}
			}

			a {
				background-color: $white;

				&:hover {
					background-color: $grey-4;
					color: $grey-13;
				}
			}
		}

		&.up-dropdown-orange {
			@include up-dropdown-menu($orange, $orange, $white);
		}

		&.up-dropdown-green {
			@include up-dropdown-menu($bright-green, $bright-green, $white);
		}

		&.up-dropdown-bright-blue {
			@include up-dropdown-menu($bright-blue, $bright-blue, $white);
		}

		&.up-dropdown-blue {
			@include up-dropdown-menu($blue, $blue, $white);
		}
	}

	&.dropup {
		.dropdown-menu {
			@include border-radius($border-radius $border-radius 0 0);
			margin-bottom: -1px;
		}
	}

	&.open {
		#check-btn {
			background-color: $bright-blue;
		}
	}

	.ellipsis-wrap {
		height: 25px;
	}
}

.up-typeahead {
	z-index: 1002;
	margin-top: -1px;
	padding: 0;
	border-radius: 0 0 $border-radius $border-radius;
	border: 1px solid $bright-blue;

	li {
		& > a {
			padding: 10px 20px;

			&:hover {
				background-color: $bright-blue;
				color: $white;
			}
		}
	}
}
