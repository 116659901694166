.up-modal.modal400 {
	width: 500px !important;
	margin-left: -200px !important;
}

.scroll-y {
	overflow-y: auto;
}

#addProductCategoryModal_root {
	.up-modal & {
		width: 500px;
	}

	.close {
		font-size: 16px;
	}
}

#addProductCategoryModal_root,
#multiupdate-product-modal {
	.up-modal & {
		width: 500px;
	}
	padding: 30px 30px 0 30px;
	box-sizing: border-box;

	h2.title {
		margin-bottom: 15px;
	}

	.small-label {
		margin-right: 5px;
		font-weight: $boldFontWeight;
	}

	.Loader {
		margin: 40px auto 50px;
	}
}
