.ShareContentType__social-media-platform {
	display: flex;
}

.ShareContentType__social-media-platform .Text {
	margin-left: 10px;
}

.ShareContentType__content-text > div {
	display: inline-block;
}

.ShareContentType__color-select-wrapper {
	flex-grow: 1;
}

.ShareContentType__color-select-wrapper .color-picker {
	float: right;
}

.ShareContentType__icon-alignment,
.ShareContentType__icon-size {
	display: flex;
}

.ShareContentType__icon-alignment,
.ShareContentType__icon-size {
	select {
		margin-left: auto;
	}
}
