@import "~StyleVariables";

$navbar-height: 50px;
$preview-top-padding: 46px;

.FormPreview {
	.Card {
		width: 350px;
		box-shadow: 0 2px 4px 0 rgba($black, 0.1), 0 10px 20px 0 rgba($black, 0.1);
		max-height: calc(100vh - #{$navbar-height} - #{$preview-top-padding} - 3px);
		overflow-y: auto;
	}

	.CardContent {
		padding: 0;
	}

	&HelpText {
		margin-top: 7px;
	}

	// Use #up-form two times to have higher css priority
	#up-form#up-form {
		padding: 30px 0;

		> * {
			padding: 6px 25px;
			margin-bottom: 0;
			position: relative;

			&:hover,
			.active {
				cursor: pointer;
				background-color: $grey-1;

				&::before {
					font-size: 14px;
					position: absolute;
					display: inline-block;
					font: normal normal normal 14px/1 FontAwesome;
					font-size: 14px;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					content: "\f040";
					color: $grey-10;
					right: 25px;
					top: 8px;
				}
			}
		}

		> h2 {
			display: block;
		}

		> h2,
		> p {
			position: relative;

			&::before {
				right: 12px !important;
			}
		}

		.recaptcha {
			background: none !important;
			margin-bottom: 0 !important;
		}

		.submit-button {
			.btn {
				border-radius: 2px;
			}
		}

		h2 {
			//font-weight: $boldFontWeight;
		}

		> p {
			margin-top: 7px;
			-webkit-font-smoothing: antialiased;
		}
	}

	#up-form-thanks,
	#failedId,
	#up-form-event-full,
	#up-event-canceled,
	.Card__slice {
		display: none;
	}

	#up-form-element-wrapper {
		margin: 0 !important;
		width: 350px !important;

		.form-group {
			position: relative;
			margin-bottom: 0;
			cursor: pointer;

			.form-control {
				border: 1px solid $grey-6;
				border-radius: 2px;
				box-shadow: inset 1px 1px 2px 0 rgba($black, 0.1);
			}

			&.active {
				&::after {
					content: "";
					position: absolute;
					left: -3px;
					width: 3px;
					background-color: $bright-blue;
					top: 0;
					bottom: 0;
				}
			}
		}
	}
}
