@import "~StyleVariables";
@import "mixins";

.DistributionBar {
	display: flex;
	width: 100%;
	height: 10px;
	border-radius: 5px;
	overflow: hidden;

	&--thick {
		height: 16px;
	}

	&__entry {
		&:first-of-type {
			border-radius: 5px 0 0 5px;
		}

		&:last-of-type {
			border-radius: 0 5px 5px 0;
		}

		&:only-of-type {
			border-radius: 5px;
		}
	}

	@each $color, $value in $colors {
		&__entry--#{$color} {
			background-color: $value !important;
		}

		&__entry--#{$color}--striped {
			@include stripes($value, true, 5px);
		}
	}
}