.HiddenSelect {
	position: relative;

	&__hidden-select {
		position: absolute;
		top: 0;
		display: none;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: inherit;

		.select2-container {
			height: 100%;
		}
	}
}
