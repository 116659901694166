@import "~StyleVariables";

.DecisionMakers {
	&__content {
		width: 790px;
		align-self: center;
	}

	&__buttonBoxes {
		.ButtonBox {
			flex: 1;
		}
	}

	&__up-select2 {
		width: 100%;
	}

	&__greenBox--hidden {
		display: none;
	}
}

.PortedDecisionMakers {
	display: block;
}
