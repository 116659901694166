@import "~StyleVariables";

.PhoneInput {
	.iti {
		display: block;
	}

	&.Input--has-icon {
		.Input__icon {
			z-index: 1;
		}

		.iti__selected-flag {
			padding-left: 34px;
		}
		.iti--allow-dropdown {
			input {
				padding-left: 85px;
			}
		}
	}

	&.has-error {
		.iti__selected-flag,
		input {
			background-color: $super-light-red;
		}
		.iti__selected-flag {
			border-right: 1px solid $red;
		}
	}
	.iti__selected-flag {
		background-color: transparent;
		border-color: #ccc;
		border-right: 1px solid $grey-4;
		&:hover {
			background-color: lighten($grey-4, 20%);
		}
	}
	.iti__country-list {
		border-color: $bright-blue;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
		z-index: 1010;
		.iti__highlight {
			background-color: $bright-blue;
			color: $white;
			.iti__dial-code {
				color: $white;
			}
		}
	}
	.iti__flag {
		border-radius: 2px;
		border: 1px solid rgba($black, 0.2);
	}
	.iti--allow-dropdown {
		display: block;
		input {
			padding-left: 60px;
		}
	}
}
