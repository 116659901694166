@import "~StyleVariables";

.SentimentGauge {
	position: relative;
	
	&__smiley {
		width: 46px;
		height: 46px;
		justify-content: center;
		align-items: center;
		border-radius: 28px;
		position: absolute;
		top: 50px;
		left: 67px;
		font-size: 26px;

		&--smile {
			background-color: $success-1;
			color: $success-5;
		}

		&--meh {
			background-color: $yellow-1;
			color: $yellow-4;
		}

		&--frown {
			background-color: $danger-1;
			color: $danger-5;
		}

		&--neutral {
			background-color: $grey-1;
			color: $grey-6;
		}
	}

	&__gauge-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 180px;
		/* Adjust as needed */
		height: 90px;
		overflow: hidden;
		/* This will cut off the bottom half */
	}

	&__gauge {
		width: 100%;
		height: 100%;
	}
}