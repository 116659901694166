@import "~StyleVariables";

.MailEditorReviewAttachments {
	&__new-ball {
		background-color: $bright-blue;
		width: 6px;
		height: 6px;
		border-radius: 100%;
	}

	.CardContent {
		padding-right: 300px;
		min-height: 150px;

		img {
			position: absolute;
			top: 10px;
			right: 80px;
			height: 120px;
		}
	}
}
