@import "~StyleVariables";

.EditSocialEventRocket {
	/*** PRE-LAUNCH ***/
	svg g #space-bakground {
		opacity: 0;
	}
	svg g #fast-stars,
	svg g #slow-stars {
		opacity: 0;
	}
	svg #boom {
		opacity: 0;
	}
	svg #boom-second {
		opacity: 0;
	}
	svg #fire {
		opacity: 0;
	}
	svg #rocket-bottom {
		opacity: 0;
	}
	svg #rocket-wrapper {
		transform: translate(89px, 30px);
	}

	/*** LAUNCH ANIMATION ***/
	svg.launch g #fire {
		opacity: 0;
		transform: translateY(10px);
	}
	svg.launch g #shadow {
		opacity: 0;
	}
	svg.launch g #rocket-wrapper {
		transform: translate(89px, 30px);
	}
	svg.launch g #rocket-bottom {
		transform: translate(19.667px, 50px);
	}
	svg.launch g #rocket-wrapper {
		animation: lift-rocket 2s ease-out normal;
	}
	svg.hover g #rocket-wrapper {
		animation: lift-rocket-hover 2s ease-out infinite;
		/*animation-delay: 2.5s;*/
	}
	svg.launch g #shadow {
		animation: remove-shadow 1.5s ease-out normal;
	}
	svg.launch g #fire {
		animation: show-fire 1s ease-out forwards;
		animation-delay: 1.5s;
	}
	svg.launch g #fire {
		animation: animate-fire 500ms ease-out infinite;
		animation-delay: 1.5s;
	}
	svg.launch g #space-bakground {
		animation: show-space-background 500ms ease-out forwards;
		animation-delay: 1.5s;
	}
	svg.launch g #fast-stars {
		animation: show-space 1.5s linear infinite;
		animation-delay: 1.5s;
	}
	svg.launch g #slow-stars {
		transform: rotate(180);
		animation: slow-stars 3.5s linear infinite;
		animation-delay: 1.5s;
	}
	svg.launch #boom {
		fill: #fff;
		opacity: 0;
		animation: boom 200ms ease-out forwards;
		animation-delay: 1.2s;
	}
	svg.launch #boom-second {
		fill: #fff;
		opacity: 0;
		animation: boom 200ms ease-out forwards;
		animation-delay: 1.3s;
	}

	@keyframes lift-rocket {
		0% {
			transform: translate(89px, 30px);
		}
		10% {
			transform: translate(89px, 25px);
		}
		30% {
			transform: translate(89px, 30px);
		}
		59% {
			transform: translate(89px, 30px);
		}
		60% {
			transform: translate(89px, 0px);
		}
		100% {
			transform: translate(89px, 30px);
		}
	}
	@keyframes lift-rocket-hover {
		0% {
			transform: translate(89px, 30px);
		}
		50% {
			transform: translate(89px, 40px);
		}
		100% {
			transform: translate(89px, 30px);
		}
	}
	@keyframes remove-shadow {
		0% {
			cy: 90;
			opacity: 0.4;
		}
		10% {
			rx: 25;
			opacity: 0.3;
		}
		20% {
			rx: 28;
		}
		70% {
			cy: 90;
			opacity: 0.4;
			ry: 4;
			rx: 31;
		}
		100% {
			cy: 90;
			opacity: 0;
			ry: 2;
			rx: 0;
		}
	}
	@keyframes show-fire {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 0.4;
		}
	}
	@keyframes animate-fire {
		0% {
			opacity: 0.4;
		}
		50% {
			opacity: 0.2;
			transform: translate(0px, 12px);
		}
		100% {
			opacity: 0.4;
		}
	}
	@keyframes show-space-background {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}
	@keyframes show-space {
		0% {
			opacity: 0;
			transform: translate(47px, -100px);
		}
		60% {
			opacity: 1;
		}
		100% {
			transform: translate(47px, 300px);
			opacity: 0;
		}
	}
	@keyframes slow-stars {
		0% {
			opacity: 0;
			transform: translate(47px, -100px);
		}
		60% {
			opacity: 0.8;
		}
		100% {
			transform: translate(47px, 300px);
			opacity: 0;
		}
	}
	@keyframes boom {
		0% {
			fill: $bright-orange;
			opacity: 0;
			rx: -10px;
		}
		30% {
			opacity: 1;
		}
		100% {
			fill: #fff;
			opacity: 0;
			rx: 30;
		}
	}
	@keyframes boom-second {
		0% {
			fill: $bright-orange;
			opacity: 0;
			rx: 0;
		}
		30% {
			opacity: 1;
		}
		100% {
			fill: $white;
			opacity: 0;
			rx: 10;
		}
	}
}
