@import "~StyleVariables";

.OpportunityEditPhonecallRow {
	&__desc-col,
	&__contact-col {
		& > .Text {
			line-height: 18px;
		}
	}

	&__desc-col {
		max-width: 280px;
		.Label {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: row;
			height: 34px;
		}
	}

	&__contact-col {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: flex-start;
		padding-left: 20px;
		.Label {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 34px;
		}
		.CreateCallClientContactSelect {
			width: 100%;
		}

		.HiddenSelect__hidden-select {
			height: 34px;
			z-index: 1;
			min-width: 250px;
		}
	}

	&__date-col {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}
