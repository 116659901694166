@import "~StyleVariables";

.ClosedTicketScreen {
	&__content {
		height: 100%;
		z-index: 20;
		background-color: $success-1;

		position: absolute;
		top: 51px;
		left: 0;
		right: 0;
		bottom: 0;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		padding-bottom: 51px;

		&__lottie {
			--lottie-size: 150px;
			height: var(--lottie-size) !important;
			width: var(--lottie-size) !important;
		}
	}
}
