@import "variables";
@import "mixins";
@import "animations";

table tr.drilldown {
	$bg-color: $grey-2;

	&:hover,
	&.hover {
		td.drilldown-td {
			background-color: $bg-color !important;
			cursor: default;
		}
	}

	&.open td.drilldown-td #wrap {
		max-height: 800px;
		height: auto;
		padding: 30px;
		@include opacity(1);
	}

	td.drilldown-td {
		position: relative;
		background-color: $bg-color;
		border-bottom: 3px solid $blue !important;
		border-top: none;
		color: $grey-13;
		border-left: none;
		vertical-align: top;
		padding: 0;
		height: initial;

		&:hover {
		}

		h2 {
			@include h2();
		}

		#wrap {
			padding: 0px 30px;
			display: block;
			max-height: 0px;
			overflow-x: hidden;
			overflow-y: auto;
			@include opacity(0);

			@include transition(all ease 250ms);

			#drilldown-card-wrapper {
				position: relative;
				display: inline-block;
				width: 270px;
				margin: 0 30px 0 0;
				float: left;

				&:before {
					content: "";
					display: block;
					width: 100%;
					height: 4px;
					background-color: $blue;
					z-index: 1;
				}

				@media (max-width: $responsiveWidht3) {
					& {
						width: auto;
						display: block;
						max-width: 100%;
					}
				}

				.card {
					background-color: $white;
					border: 1px solid $grey-8;
					min-width: 250px;
					margin-bottom: 20px;
					border-radius: 0 0 3px 3px;

					:last-child {
						border-bottom: none;
					}

					@media (max-width: $responsiveWidht3) {
						& {
							height: 160px;
						}
					}

					// Top menu for actions

					.drilldown-card-menu {
						background: $white;
						border-bottom: 1px solid $grey-8;
						margin-bottom: 14px;
						width: 100%;

						a.btn {
							background-color: transparent;
							border: 0;
							color: $blue;
							border-right: 1px solid $grey-8;
							border-radius: 0;
							padding: 12px 16px 10px 16px;
							// ÄNDRA TILL MIXIN NÄR VI MERGAR NOTIFICATIONS
							-webkit-transition: all 300ms ease-out;
							transition: all 300ms ease-out;

							.account-title {
								position: absolute;
								opacity: 0;
								// ÄNDRA TILL MIXIN NÄR VI MERGAR NOTIFICATIONS
								-webkit-transition: all 200ms ease-out;
								transition: all 200ms ease-out;
							}

							&:hover {
								background-color: rgba($black, 0.05);
							}
						}
					}
				}

				// Widget sections inside card

				.sidebar-section {
					position: relative;
					z-index: 1;
					position: relative;
					padding-bottom: 10px;
					margin-bottom: 5px;
					border-bottom: 1px dashed $grey-8;
					padding: 0 20px;

					@media (max-width: $responsiveWidht3) {
						& {
							display: inline-block;
							border-bottom: 0;
							border-right: 1px dashed $grey-8;
							height: 86px;
							float: left;
						}
					}

					h2 {
						font-size: 17px;
					}

					.title {
						position: relative;
						z-index: 1;
						padding-top: 10px;
						color: rgba($black, 0.5);
						text-transform: uppercase;
						font-size: 12px;

						@media (max-width: $responsiveWidht3) {
							& {
								padding-top: 0;
							}
						}

						h2 {
							display: block;
							font-size: 31px;
							padding-bottom: 5px;
							padding-top: 10px;
							word-wrap: break-word;
						}
					}

					.sidebar-item {
						display: block;
						margin: 8px 0;

						.account-manager {
							position: relative;
							display: inline-block;
							margin-bottom: 10px;
						}

						.placeholder {
							color: $grey-8;
							cursor: default;
							font-style: italic;
							font-size: 12px;
						}
					}

					.value {
						vertical-align: top;
						font-size: 22px;
						margin-bottom: 10px;

						&.score-indicator {
							font-size: 40px;
							margin-bottom: 0;
						}
					}

					.sub-value {
						margin-top: 14px;
						display: inline-block;
						color: $grey-8;
					}

					.subtitle {
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.up-widget {
						border-right: 0;
						padding: 0;

						@media (max-width: $responsiveWidht3) {
							& {
								height: auto;
							}
						}
					}
				}
			}

			#lists {
				display: block;
				width: auto;
				vertical-align: top;
				margin: 0 0 20px 0;
				//padding-left: 28px;
				//border-left: 2px solid $grey-6;

				@media (max-width: $responsiveWidht3) {
					& {
						border-left: 0;
						padding-left: 0;
					}
				}

				.list {
					width: 100%;
					background-color: $white;
					color: $black;
					border: 1px solid $grey-8;
					margin-top: 10px;

					th,
					td {
						padding: 5px;
					}

					th {
						background-color: $grey-2;
					}

					tr {
						&:last-child {
							td {
								border-bottom: none;
							}
						}

						td {
							border-bottom: 1px solid $grey-4;
						}
					}
				}
			}
		}
	}
}
