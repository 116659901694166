@import "~StyleVariables";

.EasyBookingLinkDropdown {
	margin-left: 10px;
	margin-top: -35px;
	color: $black;

	&__label {
		cursor: pointer;
		line-height: 40px;
		.DropDownButton {
			margin-left: -7px;
		}
	}

	.TableColumn--align-left {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 200px;
	}
	.TableColumn--align-right {
		padding-right: 3px;
		white-space: nowrap;
	}
	&__header {
		&__titleRow {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 10px 0 15px;

			.Help {
				padding: 5px;
			}
			.Icon {
				display: block;
			}
		}

		&__subtitle {
			margin: 0 15px 8px;
		}
	}
}
