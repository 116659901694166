@include keyframes(pulsate-freeze) {
	@include pulse-in-out-freeze(0.85);
}

.up-modal.esign-narrow {
	width: 430px !important;
	margin-left: -215px !important;

	.sidebar-bg,
	.modal-sidebar {
		width: 430px !important;
	}
}

#esign-modal {
	&.select-app-mode {
		.modal-sidebar:after {
			content: "";
			background-color: rgba($white, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			right: 1px;
			bottom: 0;
			min-height: 600px;
		}
	}

	.modal-close {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 1000;
		color: $grey-10;

		&:hover {
			color: $grey-13;
		}
	}

	.modal-sidebar {
		font-size: 14px;
		padding: 25px;

		h2 {
			display: block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			height: 22px;
			line-height: 22px;

			span {
				font-size: 18px;
				vertical-align: top;
			}

			.fa {
				font-size: 22px;
			}
		}

		label {
			font-size: 12px;
			display: block;
		}

		.form-control {
			display: block;
		}

		#sender-sign-row {
			margin-bottom: 10px;
		}

		#involvees-placeholder {
			color: $grey-10;
			text-align: center;
			font-size: 16px;
			margin-top: 15px;
		}

		.involvee-row-wrap {
			$height: 45px;
			$toggle-btn-width: 55px;
			$edit-contact-btn-width: 35px;
			border-bottom: 1px solid $grey-4;
			margin-left: -15px;
			margin-right: -14px;

			&.row-open {
				#toggle-sender-info {
					display: none;
				}

				.involvee-row {
					.toggle-row {
						background-color: $grey-1;

						.phone-warning {
							display: none;
						}
					}
				}
			}

			&.missing-email {
				.missing-email-indicator {
					display: block;
				}
			}

			.missing-email-indicator {
				display: none;
				position: absolute;
				top: 15px;
				right: 100px;
			}

			&:first-of-type {
				.involvee-row:before {
					top: 10px;
				}
			}

			.involvee-row {
				position: relative;
				height: $height;

				&:before {
					content: "";
					position: absolute;
					background-color: $grey-8;
					width: 1px;
					left: 25px;
					top: 0;
					bottom: 0;
				}

				.subtitle {
					position: absolute;
					left: 50px;
					right: 60px;
					bottom: 1px;
					color: $grey-10;
					font-size: 12px;
				}

				.generic-avatar {
					margin: 7px 10px;
				}

				.person-name {
					position: absolute;
					left: 50px;
					top: 2px;
					right: $toggle-btn-width;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-right: 10px;
				}

				.edit-contact-btn,
				.toggle-row {
					position: absolute;
					height: $height;
					padding: 0;
					background-color: transparent;
					border: none;
					border-left: 1px solid $grey-4;
					color: $grey-10;

					&:hover {
						color: $grey-13;
					}
				}

				.toggle-row {
					width: $toggle-btn-width;
					right: 0;

					.phone-warning {
						position: absolute;
						top: 3px;
						right: 3px;
						font-size: 12px;
					}
				}

				.edit-contact-btn {
					right: $toggle-btn-width;
					width: $edit-contact-btn-width;
				}

				&.is-contact {
					.person-name {
						right: $toggle-btn-width + $edit-contact-btn-width;
					}
				}
			}

			#toggle-sender-info {
				width: 100%;
				border: none;
				background: $grey-1;
				color: $bright-blue;
				padding: 0;
				height: 30px;
				line-height: 30px;
			}

			.involvee-options {
				background-color: $grey-1;

				.option-row {
					padding: 7px 15px;
					font-size: 14px;
					clear: both;

					&.option-label {
						font-size: 12px;
						color: $grey-10;
					}

					label {
						float: left;

						.up-toggle {
							margin-right: 5px;
						}
					}

					.option-value {
						float: right;
						color: $grey-10;
						max-width: 200px;
						overflow: hidden;
						text-overflow: ellipsis;

						.option-input {
							border: none;
							background-color: transparent;
							border-bottom: 1px solid $grey-4;
							height: 23px;
							color: $grey-10;
							outline: none;
							width: 200%;
							@include transition(all ease-in 200ms);

							&:focus {
								border-color: $grey-8;
							}
						}
					}

					.bank-id-info {
						color: $grey-10;
						font-size: 11px;
						line-height: 17px;
						padding-right: 40px;
					}
				}

				.remove-involvee {
					display: block;
					background-color: rgba($black, 0.05);
					border: none;
					width: 100%;
					height: 40px;
					color: $grey-10;
					font-size: 14px;
					padding: 0;
					line-height: 40px;
					@include transition(all ease-in 200ms);

					&:hover {
						color: $grey-13;
						background-color: rgba($black, 0.1);
					}
				}
				.bankIdCountry-select {
					width: 50px;
					position: absolute;
					top: -2px;
					right: 50px;

					.select2-container {
						height: 20px;

						.select2-choice {
							height: 20px;
							padding-top: 0px;
							line-height: 1px;
							font-size: 12px;

							.select2-chosen {
								padding: 3px 12px;
							}
						}

						.select2-arrow {
							b {
								background-position: 0 -3px;
							}
						}
					}
				}
			}
		}

		#involvee-select-wrap {
			position: relative;

			&.no-involvees:before {
				display: none;
			}

			&:before {
				content: "";
				position: absolute;
				background-color: $grey-8;
				width: 1px;
				height: 22px;
				left: 10px;
				top: -21px;
			}
		}
	}

	.modal-center {
		padding-left: 335px;
		padding-right: 0;
		padding-top: 0;
	}

	#file-wrapper {
		width: 100%;
		min-height: 600px;

		#pdf-controls {
			position: absolute;
			top: 15px;
			width: 150px;
			left: 50%;
			margin-left: -75px;

			.btn-group-addon {
				display: inline-block;
				float: left;
				height: 22px;
				line-height: 22px;
				padding: 0 10px;
				border-top: 1px solid $grey-8;
				border-bottom: 1px solid $grey-8;
				background-color: $white;
				font-size: 12px;
			}
		}

		#file-preview {
			font-size: 14px;
			padding: 15px;

			&.is-set {
				position: relative;
				min-height: 600px;
			}

			#filename {
				color: $grey-10;
				font-size: 12px;
			}

			#preview-area {
				text-align: center;
				margin-top: 15px;

				&.loading-preview {
					#pdf-viewer {
						display: none;
					}

					#loading-preview {
						display: block;
					}
				}

				#loading-preview {
					display: none;
				}

				#pdf-viewer {
					display: block;
					width: 100%;

					canvas {
						max-width: 100%;
						@include box-shadow(0 5px 10px rgba($black, 0.2));
					}
				}
			}
		}

		.file-dropzone {
			position: absolute;
			left: 335px;
			right: 0;
			height: 100%;
			@include transition(all ease-in 200ms);

			&:hover {
				.upload-status a {
					text-decoration: underline;
				}
			}

			&.dz-drag-hover {
				border: 2px dashed $bright-blue;
				z-index: 10000;
				background-color: $white;

				.hide-on-hover {
					@include opacity(0);
					visibility: hidden;
				}

				.show-on-hover {
					@include opacity(1);
					visibility: visible;
				}
			}

			.dz-preview {
				display: none;
			}

			.show-on-hover {
				@include opacity(0);
				visibility: hidden;
			}

			#browse-files-btn {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 200px;
				margin-left: -100px;
				margin-top: 100px;
				z-index: 1000;
			}

			#click-zone {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}

			#pulse-icon {
				@include animation(pulsate-freeze 1500ms ease-in-out);
				@include animation-iteration-count(infinite);
			}
		}

		.upload-status {
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -150px;
			margin-top: -100px;
			text-align: center;
			width: 300px;
			height: 200px;
			font-size: 16px;
			line-height: 26px;
			@include opacity(1);
			visibility: visible;
			@include transition(all ease-in 200ms);

			.file-icon.fa {
				font-size: 80px;
				margin-bottom: 20px;
				display: block;
				color: $bright-blue;

				&.fa-frown-o {
					color: $grey-8;
				}
			}
		}
	}
}

#confirm-esign-modal {
	#esign-version-warning {
		color: $orange;
		text-align: center;
	}

	#esign-sending-curtain {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $grey-1;
		visibility: hidden;
		z-index: 10000;
		@include opacity(0);
		@include transition(all ease-in 100ms);

		&.visible {
			@include opacity(1);
			visibility: visible;

			#progress-bar-wrap #progress-bar {
				width: 100%;
			}
		}

		span {
			position: absolute;
			display: block;
			width: 200px;
			text-align: center;
			left: 50%;
			margin-left: -100px;
			top: 50%;
		}

		#progress-bar-wrap {
			background-color: $grey-4;
			height: 4px;
			width: 200px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -100px;
			margin-top: -20px;
			@include border-radius(3px);

			#progress-bar {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 0%;
				background-color: $bright-blue;
				@include border-radius(3px);
				@include transition(width ease-in 1000ms);
			}
		}
	}

	&.view {
		.up-modal-content {
			#involvee-list-wrap {
				.involvee-row {
					padding: 18px 0;

					&:hover {
						padding: 12px 0;

						.involvee-info {
							visibility: visible;
							@include opacity(1);
						}
					}

					.involvee-info {
						visibility: visible;
						@include opacity(0);
						@include transition(all ease-in 100ms);
					}
				}
			}

			#signed-count {
				display: block;
			}
		}
	}

	.modal-close {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 1001;
		color: $grey-8;

		&:hover {
			color: $grey-11;
		}
	}

	.up-modal-content {
		color: $grey-10;

		h2 {
			font-size: 20px;
			display: block;
			margin: 20px 35px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		p {
			margin: 15px;
		}

		#involvee-list-wrap {
			position: relative;
			width: 400px;
			margin: 60px auto 39px auto;
			background-color: $white;
			border: 1px solid $grey-4;
			@include border-radius(3px);
			@include box-shadow(0 1px 2px rgba($black, 0.2));

			&:before,
			&:after {
				content: "";
				position: absolute;
				background-color: $grey-4;
				width: 2px;
				margin-left: -1px;
				left: 50%;
				height: 40px;
			}

			&:before {
				top: -40px;
				bottom: 0px;
			}

			&:after {
				bottom: -40px;
			}

			.involvee-row {
				position: relative;
				border-bottom: 1px solid $grey-4;
				height: 75px;
				padding: 12px 0;
				@include transition(padding ease-in 100ms);

				&:last-of-type {
					border-bottom: none;
				}

				&.signed {
					background-color: rgba($bright-green, 0.07);

					.signed-info {
						color: $green;
					}
				}

				&.rejected {
					background-color: rgba($red, 0.07);

					.signed-info {
						color: $red;
					}
				}

				.row-info {
					position: absolute;
					right: 15px;
					margin: 0;
					padding: 0;
					top: 27px;
				}

				.generic-avatar {
					position: absolute;
					left: 12px;
					top: 20px;
				}

				h3,
				.subtitle {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				h3 {
					position: absolute;
					left: 12px;
					right: 12px;
					margin: 0;
					font-size: 16px;
					margin-left: 50px;
					color: $black;
					font-weight: normal;
				}

				.subtitle {
					margin: 20px 0 0 62px;
					font-size: 12px;
					line-height: 16px;
				}
			}
		}
	}

	#signed-count {
		display: none;
		background-color: $grey-4;
		padding: 5px 10px;
		font-size: 12px;
		@include border-radius(3px);
		margin: 0 auto;
		width: 120px;
		text-align: center;
	}
}

#esign-settings {
	$header-height: 40px;
	#esign-preview-confirm,
	#esign-preview-email {
		position: relative;
		background-color: $grey-1;
		@include transition(all ease 200ms);
		@include box-shadow(0px 1px 5px rgba($black, 0.2));
		@include border-radius(3px);

		.esign-preview-header {
			height: $header-height;
			background-color: transparent;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			@include transition(all ease 200ms);
		}

		.esign-preview-logo {
			background-image: url("../img/powered-by-esign.png");
			background-size: contain;
			background-repeat: no-repeat;
			width: 200px;
			height: $header-height - 8px;
		}
	}

	#esign-preview-confirm {
		text-align: center;
		padding-top: $header-height;
		font-size: 16px;
		padding-bottom: 20px;

		.esign-preview-logo {
			background-position: center center;
			margin: 4px auto;
		}

		.fa-check {
			font-size: 70px;
			color: $bright-blue;
			margin: 10px 0;
		}
	}

	#esign-preview-email {
		padding: 20px;

		.esign-preview-logo {
			background-position: left center;
			margin: 4px 20px;
		}

		.preview-inner-wrap {
			border: 1px solid rgba($black, 0.1);
			background-color: $white;
			padding: 30px;
			// padding-left: 130px;
			margin-top: $header-height;
			color: $black;
			position: relative;
			font-size: 12px;
			line-height: 20px;
			min-height: 150px;
			@include transition(all ease 200ms);

			// .fa-file-o {
			// 	font-size: 110px;
			// 	position: absolute;
			// 	top: 20px;
			// 	left: 20px;
			// 	color: $grey-10;
			// }

			h2 {
				display: block;
				margin-bottom: 10px;
			}

			#esign-fake-btn {
				background-color: $bright-blue;
				color: $white;
				display: inline-block;
				border: 1px solid $bright-blue;
				padding: 10px;
				line-height: 10px;
				margin-top: 15px;
				@include border-radius(3px);
				@include transition(all ease 200ms);
			}
		}
	}

	#esign-preview-toggle {
		margin: 10px auto;
		width: 250px;
		display: block;
		height: 30px;

		button {
			width: 50%;
		}
	}
}

#esign-app-list-placeholder {
	.esign-app-placeholder-inner {
		border-radius: 0 0 3px 3px;
		box-shadow: 0 1px 2px rgba($black, 0.1);
	}
}

#esign-app-modal-placeholder {
	.esign-app-placeholder-inner {
		min-height: 600px;
	}
}
.esign-app-placeholder-inner {
	background-color: $grey-2;
	text-align: center;
	padding: 40px;
	color: $grey-10;
	font-size: 14px;
	line-height: 30px;

	.upsales-loader {
		margin: 200px auto;
	}

	.esign-app-placeholder-header {
		color: $grey-11;
		font-size: 24px;
		line-height: 36px;
		display: block;
		margin: 0 auto;
		max-width: 600px;

		&.select-mode-header {
			margin: 40px 0;
		}
	}

	.esign-app-placeholder-info {
		color: $grey-11;
		font-size: 16px;
		line-height: 22px;
		display: block;
		margin: 0 auto;
		max-width: 600px;
		margin-top: 15px;
	}
}
