@import "~StyleVariables";

.EditTaskTemplate {
	grid-template-rows: auto 0 1fr 0;

	.Label {
		font-weight: bold;
	}

	.ModalHeader {
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
	}

	.ModalHeader__content {
		padding: 0;
	}

	&__taskInformation {
		.NumberInput {
			width: 16%;
		}
		.NumberInput .Input {
			width: 60%;
			margin: 0 auto;
		}
	}

	.ButtonSelect {
		width: 100%;
	}

	.Help {
		display: inline;
		padding-left: 4px;
	}

	.CustomFields {
		.Input {
			width: 100%;
		}
	}
}
