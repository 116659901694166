@import "../../../styles/variables";

.AdminSalesboardList tr td:last-child {
	overflow: hidden;
}

.AdminSalesboardList .Loader {
	display: inline-block;
	margin-right: 3px;
}

.AdminSalesboardList tr:hover .AdminSalesboardList__row-tools {
	visibility: visible;
	opacity: 1;
	right: 0;
}

.AdminSalesboardList__row-tools {
	position: relative;
	visibility: hidden;
	transition: all ease 200ms;
	opacity: 0;
	right: -100%;
}
