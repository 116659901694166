@import "~StyleVariables";

.ActivityQuickLinksMenu{
    min-width: 300px;
    border-radius: $border-radius-sm;
    border: 1px solid $grey-6;
    background: $white;
    box-shadow: $box-shadow-links-menu;

    &__title{
        font-size: $modal-font-size;
        font-weight: $boldFontWeight;
        height: 30px;
        padding-left: 12px;
        margin: 0;
        background-color: $grey-1;
        display: grid;
        align-content: center;
    }

    &__link,
    &__noLinks{
        display: grid;
        padding: 10px 16px;
        background-color: $white;
        box-shadow: $box-shadow-xs, $box-shadow-above-xs;
        color: $black;
    }

    &__link{
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px;
        text-decoration: none;

        &:hover{
            color: $black;
            text-decoration: none;
            background-color: $grey-1;
        }

        b{
            align-self: center;
        }
    }
}