@import "~StyleVariables";

.SupportListTableRow {
	&--hasUnreadComments {
		background-color: $super-light-blue;
	}
	&__subject {
		max-width: 200px;

		.Tooltip {
			.Text {
				height: 100%;
				line-height: 200%;
			}
		}

		& > .Row {
			& > .Column {
				&:first-of-type {
					flex: 0;
				}
				&:last-of-type {
					overflow: hidden;
				}
			}
		}
	}

	&__prio {
		width: 68px;
		.AssistChip {
			width: 100%;
			text-align: center;
		}
	}

	&__dropdown-actions {
		.inline-confirm-modal,
		.inline-confirm-modal-hidden {
			width: 100%;
		}
	}
	&__status {
		.Chip {
			width: fit-content;
		}
	}

	&__contact {
		max-width: 150px;
	}

	&__date {
		width: 150px;
	}

	&__id {
		width: 50px;
	}

	&__type {
		width: 100px;
	}

	&__custom {
		width: 100px;
	}

	.ListViewColumn {
		&__user {
			width: 200px;
		}
	}

	.TableColumn:last-of-type {
		width: 100px;
	}

	@media (max-width: 1500px) {
		.ListViewColumn {
			&__user {
				width: 100px;
			}
		}
		&__user {
			display: none;
		}
	}

	.DropDownMenu__content {
		text-align: initial;
		min-width: 200px;
		width: 50px;
	}

	&__pending {
		position: absolute;
	}

	&__expandedIcon {
		&--expanded {
			transform: rotate(180deg);
			transition: transform 200ms linear;
		}
		transform: rotate(0deg);
		transition: transform 200ms linear;
	}

	&__status {
		.ClickableItem__icon-col {
			margin-left: $space;
		}

		&--selected {
			border-left: $space solid $green;

			.ClickableItem__icon-col {
				margin-left: initial;
			}
		}
	}
}
