@import "../../../styles/variables";
.EditStandardFieldModal__title-body {
	background-color: $white;
	padding: 10px 20px 20px 20px;
	box-shadow: 0 1px 2px 0 rgba($black, 0.1);
	white-space: pre-wrap;
}

.EditStandardFieldModal__input-wrapper {
	margin: 0;
	padding: 15px 5px;
	.EditStandardFieldModal__input {
		padding-bottom: 22px;

		.EditStandardFieldModal__input-label {
			display: flex;

			span {
				margin-right: 6px;
			}
		}
	}
}

.EditStandardFieldModal__progress {
	width: 15px;
	height: 15px;
	margin-left: auto;
}

.EditStandardFieldModal__textarea {
	background-color: $white;
	border: 1px solid $grey-8;
	border-radius: 2px;
	box-shadow: inset 1px 1px 2px 0 rgba($black, 0.1);
	width: 100%;
	height: 64px;
}
