@import "~StyleVariables";

.Mail {
	border-top: 1px solid $grey-4;
	position: relative;
	background-color: $white;

	&__mailBody {
		display: flex;
		flex-direction: column;
	}

	&__attachments {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 50px;
	}
}
