@import "~StyleVariables";

.CreateDocument {

    &__table-column-name {
        padding: $space * 3;
    }

    &__up-panel {
        padding: 0
    }

    &__table,
    &__table-row{
        margin: 0;
    }

    &__table-row:hover {
        background-color: #f5f5f5 !important; // To mimic the angular hover, didn't exist an equivalent in style variables
    }

    &__mid-mid {
        width: 40px;
    }

}