@import "~StyleVariables";

$tab-menu-height: 50px;

.CompanyGroupCard {
	$totalNavHeight: $navbar-height + $sub-menu-height + $tab-menu-height;

	&__header {
		top: $navbar-height + $sub-menu-height;
		// Subtract the app sidebar width
		width: calc(100% - 48px);
		height: $tab-menu-height + 1;
		background-color: $white;
		position: fixed;
		z-index: 1002;
		border-bottom: 1px solid $grey-4;
	}
	&__content {
		min-height: calc(100vh - #{$totalNavHeight});
		&--hasGroupBonaza {
			.ListView:not(.SimpleListView) {
				left: 304px !important;
			}
		}
	}

	&--hasOverdue {
		.Tab__subtitle {
			color: $red !important;
		}
	}
}

.CompanyGroupCard.Page {
	$totalNavHeight: $navbar-height + $sub-menu-height + $tab-menu-height;
	height: fit-content;
	min-height: calc(100% - #{$totalNavHeight});
	overflow-y: visible;
	top: $totalNavHeight;

	.ListView {
		left: 0;
	}

	.ListView__header {
		top: 0;
		border-radius: 0;
	}

	.ListView .TableHeader {
		top: $tab-menu-height;
	}

	.ReportcenterWidget__content {
		border: $border;
		border-radius: $border-radius;

		.ColumnChartWidget__label:last-of-type {
			border-bottom: none;
		}

		.ColumnChartWidget__noResultRowText {
			border-top: none;
			background-color: $grey-1;
			color: $grey-10;
			align-content: center;
			text-align: center;
		}
	}
}
