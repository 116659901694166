@import "~StyleVariables";

.AdvancedSearchFooter {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 -1px 6px rgba($black, 0.1);
	background-color: $white;
	text-align: center;
	border-radius: 0 0 $border-radius $border-radius;
}
