@import "~StyleVariables";

.RecipientBadge {
	padding: 4px;
	background-color: $super-light-blue;
	border-radius: $border-radius;
	white-space: nowrap;
	margin-left: 8px;
	margin-bottom: 8px;
}

.ExtraRecipientsBadge {
	padding: 4px 0;
	border-radius: $border-radius;
	white-space: nowrap;
	margin-left: 8px;
	margin-bottom: 8px;
	display: flex;

	.Icon {
		margin: auto 0 auto 4px;
		cursor: pointer;
	}
}

.NewMailRecipientsRow {
	max-height: 1000px; // This is to achieve transition animation when the height changes
	display: flex;
	align-items: baseline;
	background-color: $white;
	transition: all 0.35s ease;
	position: relative;
	overflow: visible;

	& > .Text:first-of-type {
		margin-left: 16px;
		margin-bottom: 12px;
		opacity: 1;
		white-space: nowrap;
	}

	.Icon-times,
	.Icon-plus {
		cursor: pointer;
	}

	&--hide {
		overflow: hidden;
		max-height: 0px;
		& > .Text:first-of-type {
			transition: opacity 0.35s ease;
			opacity: 0;
		}
	}

	&--required {
		min-height: 50px;
		padding-top: 12px;

		& > .Text:first-of-type::before {
			content: "* ";
			color: $red;
		}
	}

	&__contactSelect {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
		& > .Card {
			position: absolute;
			z-index: 10;
		}
	}

	&__contactList {
		border-radius: $border-radius;
		border: 1px solid $grey-6;
		box-shadow: $box-shadow-xl;
	}

	.Input__input {
		border: none;
		box-shadow: none;
		background-color: transparent;
	}

	&__recepientsWrapper {
		display: flex;
		flex-wrap: wrap;
		width: 64%;
	}

	&__inputField {
		position: relative;
		display: flex;
		align-items: center;
		height: 28px;
		margin-bottom: 8px;
		.Icon {
			position: absolute;
			left: 0;
		}
	}
}
