@import "~StyleVariables";

.SubscriptionList {
	&--fullWidth {
		top: 0 !important;
		left: 0 !important;

		.Page {
			top: 0 !important;
		}

		#list-filters-wrap {
			top: 52px !important;
		}

		.TableHeader {
			position: sticky;
			background-color: white;
			z-index: 1;
			top: 49px;
		}
	}

	.ListView {
		&__footer {
			padding-bottom: 0px;
		}
		.Paginator {
			margin: $space * 4 0;
		}
		.Page {
			margin-bottom: 0px;
		}
	}

	&--content {
		min-width: 900px;
		border: 1px solid $grey-6;
		border-radius: $space;
		padding: 1px 0;

		.Page {
			top: 0 !important;
		}

		.TableColumn {
			&__conflicts {
				padding-right: 2 * $space;
			}
		}

		.TableRow {
			&__conflicts {
				.TableColumn {
					border-top: 1px solid $grey-4;
				}
			}
			&__indexes {
				background-color: $yellow-1;
				.TableColumn {
					border-top: 0;
				}
				.IndexConflicts {
					display: flex;
					.Text:first-child {
						color: $yellow-6;
						margin-right: $space;
					}
				}
			}
		}

		&--hideTopBorder {
			border-top: none;
		}
	}
}
