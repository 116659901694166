@import "~StyleVariables";

.ActivityTimelineStopTrying {
	&--hasDoubleSections {
		height: 110px;

		.TimelineCard__subSection:nth-child(3) {
			border-top: 1px solid $grey-4;
		}
	}
}
