@import "~StyleVariables";

.ClientPlans {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space * 2;

    &--createButton {
        color: $green;
    }

    &__tooltip {
        &__tooltipRow {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            min-width: 160px;
            max-width: 200px;
    
            &__value {
                white-space: nowrap;
                padding-left: 10px;
            }
        }
    }
    
}
