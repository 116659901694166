@import "~StyleVariables";

/* Highlight */
.field-sync-highlight,
.field-sync-highlight .form-control,
.field-sync-highlight .select2-choice {
	border-color: $bright-blue !important;
	transition: all 0.2s ease-in-out !important;
}

.field-sync-highlight#notes {
	border: 1px solid $bright-blue !important;
	transition: all 0.2s ease-in-out !important;
}

.field-sync-disabled {
	opacity: 0.5;
	transition: all 0.2s ease-in-out !important;
}

.field-sync-client-highlight {
	& > .field-sync-client-name {
		padding: 5px !important;
		border: 1px solid $bright-blue;
		border-radius: 3px;
		word-break: normal !important;
	}

	.form-client-sync-header {
		margin-bottom: 5px;
	}

	.form-client-sync-direction {
		color: $black;
	}
}

.field-sync-client-disabled {
	opacity: 0.5;
	transition: all 0.2s ease-in-out;
}

/* Sync Direction */
.form-input-sync-header {
	display: flex;
	align-items: center;

	label {
		padding-top: 0 !important;
	}

	.form-input-sync-direction {
		font-size: 15px;
	}

	.form-input-sync-direction:first-child {
		margin-right: 10px;
		color: $white;
	}

	.form-input-sync-direction:last-child {
		margin-left: 10px;
	}

	.form-input-sync-direction:nth-child(2) {
		margin-left: auto;
	}

	.form-input-sync-direction:nth-of-type(2) {
		margin-left: 10px;
	}

	.pull-right {
		margin-left: auto;
	}
}

.field-sync-header-row .form-input-sync-header {
	.form-input-sync-direction:first-child {
		margin-left: auto;
		margin-right: 0;
		color: $black;
	}
}

.field-sync-custom-margin-top {
	margin-top: 0 !important;
}

/* Field Sync */
#edit-order-modal.fresh ul.flex-tabs {
	display: flex;
}

.up-modal ul.tabs > .FieldSyncLogs__dropdown {
	height: 100%;
	margin-left: auto;

	.FieldSyncLogs__wrap {
		height: 100%;

		.FieldSyncLogs__menu {
			height: 100%;

			.FieldSyncLogs__tab-btn {
				display: flex;
				height: 100%;
				padding: 0 15px;
				border: none;
				border-radius: 0;
				align-items: center;
				justify-content: center;

				.Icon {
					font-size: 20px;
				}
			}

			.FieldSyncLogs__dropdown-card {
				display: flex;
				flex-flow: column nowrap;
				height: fit-content;
				width: 300px;

				.FieldSyncLogs__card-header {
					margin: 0;
					padding: 15px;
					font-size: 16px;
				}

				.FieldSyncLogs__toggle-wrap {
					display: flex;
					padding: 15px;
					border-top: 1px solid $grey-4;
					align-items: center;

					.FieldSyncLogs__label {
						margin-bottom: 0;
						margin-left: 10px;
					}
				}

				.FieldSyncLogs__no-integrations {
					padding: 0 15px 15px 15px;
				}
			}
		}
	}
}
