@import "~StyleVariables";

.ActionsCard {
	margin-bottom: 20px;
}

.ActionsCard__CriteriaTable > table {
	width: 100%;
	margin-bottom: 0px;
}

.ActionsCard__title {
	padding: 0 20px;
	line-height: 56px;
	height: 56px;
}

.ActionsCard__footer {
	background-color: $white;
	height: $space * 11;
	align-items: center;
	border-top: 1px solid $grey-6;
	padding: 0 $space * 3;

	& > .Column:first-child {
		flex: 0 0 auto;
	}

	.Button {
		margin-left: $space * 3;
	}
}
