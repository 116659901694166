@import "~StyleVariables";

.CriteriaWrapper {
	height: 64px;
}

.CriteriaWrapper--locked {
	background-color: $grey-2;
}

.CriteriaWrapper .ellipsis {
	position: initial;
}

.CriteriaWrapper td {
	vertical-align: middle;
}

.CriteriaWrapper .CriteriaWrapper__title-col {
	vertical-align: middle;
	width: 200px;
	padding-left: 15px;
	padding-top: 12px;

	.CriteriaWrapper__title-container {
		display: flex;
		align-items: center;

		.CriteriaWrapper__info-icon {
			.Icon {
				font-size: 16px;
			}
			margin-left: 12px;
		}
	}
}

.CriteriaWrapper .CriteriaWrapper__table-col {
	vertical-align: middle;
}

.CriteriaWrapper .CriteriaWrapper__table-col .criteria-row {
	font-size: 18px;
}

.CriteriaWrapper .CriteriaWrapper__table-col .drilldown-btn {
	margin-left: 0;
	margin-top: 4px;
}

.CriteriaWrapper__title {
	font-weight: $boldFontWeight;
	font-size: 16px;
}
.CriteriaWrapper__entity {
	font-size: 13px;
	color: $grey-11;
}

.CriteriaWrapper--locked .CriteriaWrapper__title,
.CriteriaWrapper--locked .CriteriaWrapper__entity {
	font-style: italic;
	color: $grey-10;
}

.CriteriaWrapper .CriteriaWrapper__last-col {
	width: 50px;
	text-align: center;
	vertical-align: middle;
}

.CriteriaWrapper__lock {
	color: $grey-6;
	line-height: 46px;
}

.CriteriaWrapper--has-on-click {
	cursor: pointer;
}

.CriteriaWrapper__last-col {
	text-align: right;
	padding: 8px;
}

@media (max-width: 1200px) {
	.CriteriaWrapper .CriteriaWrapper__last-col {
		width: 35px;
	}
}

.CriteriaWrapper__remove {
	padding: 0;
}
