@import "~StyleVariables";

.SimpleListView {
	position: relative;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 0;
	margin: 0;
	margin-bottom: 30px;

	.Page__content-loader {
		position: relative;
	}

	.TableRow--inactive {
		td:first-child:before {
			width: 4px;
			background-color: $grey-5;
		}
		background-color: $grey-1;
	}
	.TableRow--inactive:hover {
		background-color: $grey-2;
	}

	.TableRow__delete {
		color: $grey-10;
		transition: color 250ms ease-out;
		&:hover {
			color: $black;
		}
	}

	.ListView__footer {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&__header {
		padding: $space * 2;
	}

	.FlexPadder {
		flex-grow: 1;
	}

	&__noStickyHeader {
		.ListView__header {
			top: 0;
			position: relative;
		}
	}
}
