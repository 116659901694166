@import "~StyleVariables";

.EditRoleAccessTable {
	margin-bottom: 15px;

	&__access-col {
		padding: 0;
	}
	&__access-col-div {
		display: flex;
		align-items: center;
	}

	.Table {
		table-layout: fixed;

		th {
			padding-left: 30px;

			.Help {
				display: inline-block;
			}
		}
	}

	.access-selector {
		background: 0 0;
		border: none;
		display: block;
		margin: 0;
		padding: 0;
		height: 100%;

		&.select2-dropdown-open {
			position: absolute;
			width: 400px;
			top: 0;
			z-index: 1000;

			&.last {
				right: 0;
			}
		}

		a {
			border-radius: 0;
			height: 100%;

			.select2-chosen {
				height: 100%;
				line-height: 25px;
			}
		}

		&:not(.select2-dropdown-open) .select2-choice {
			border-color: transparent;
		}

		&.select2-dropdown-open .select2-arrow,
		&:hover .select2-arrow {
			visibility: visible;
			opacity: 1;
		}

		.select2-arrow {
			border: none;
			visibility: hidden;
			opacity: 0;
			transition: all ease 200ms;
		}
	}
}

.select2-highlighted .role-access-select .access-subtitle {
	color: $white;
}

.role-access-select {
	position: relative;
	height: 100%;
	display: block;

	.color-ball {
		display: inline-block;
		margin-right: 5px;
		width: 8px;
		height: 8px;
		border-radius: 100%;
		background-color: $grey-8;

		&.green {
			background-color: $bright-green;
		}
		&.orange {
			background-color: $orange;
		}
		&.red {
			background-color: $red;
		}
	}

	&.has-sub {
		padding-left: 15px;

		.color-ball {
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -4px;
		}
	}

	.access-subtitle {
		margin-top: 2px;
		font-weight: 400;
		line-height: 18px;
		display: block;
		color: $grey-11;
		font-size: 12px;
	}
}
