@import "variables";

@mixin login() {
	$width: 400px;
	$logo-height: 100px;
	#login-wrap {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $green;
		z-index: 9999;
		overflow: auto;

		.WhatsIncludedCard {
			border-radius: $border-radius;
		}

		h1 {
			color: $super-light-green;
			font-weight: $boldFontWeight !important;
		}

		#login-submit {
			margin-top: 30px;
		}

		.browser-check {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			margin: 0 auto;
			background-color: $bright-orange;
			padding: 20px;

			@media (max-width: $responsiveWidht4) {
				padding: 10px;
			}

			.close-bar-button {
				position: absolute;
				right: 20px;
				font-size: 16px;
				color: $white;
				border-radius: 100%;
				width: 34px;
				height: 34px;
				line-height: 34px;
				box-sizing: border-box;
				display: inline-block;
				text-align: center;
				background-color: lighten($orange, 20);
				cursor: pointer;
			}

			.msg {
				color: $white;
				font-size: 16px;
				text-align: center;
				display: block;

				@media (max-width: $responsiveWidht4) {
					font-size: 14px;
				}

				a {
					text-decoration: underline;
					color: $white;
				}
			}
		}

		.login-post-wrapper-description {
			white-space: pre-wrap;
		}

		.login-img-wrap {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background-color: $dark-green;
			height: 100vh;
			@include transition(all ease-in 200ms);
			min-height: 100%;
			width: 30%;

			.login-env-msg {
				position: relative;
				color: $bright-orange;
				display: inline-block;
				text-transform: uppercase;
				font-weight: $boldFontWeight;
				font-size: 16px;
				top: -14px;
				font-style: italic;
				letter-spacing: -1px;
				text-shadow: 1px 0px 4px rgba($black, 0.4);
			}

			h1 {
				font-weight: $boldFontWeight;
				font-size: 26px;
			}

			@media (max-width: $responsiveWidht4) {
				min-height: 100%;
				width: 100%;
				height: 100%;
				bottom: 0;
			}

			#logo {
				position: relative;
				z-index: 1;
				margin: 20px 20px;
				height: auto;
				color: $white;

				svg#up-logo-wrapper {
					max-width: 160px;
					height: 50px;
				}
				// @media (max-width: $responsiveWidht4) {
				// 	& {
				// 		width: 120px;
				// 	}
				// }

				.logo-letter {
					fill: $white !important;
				}
			}

			.login-post-wrapper {
				position: absolute;
				margin-top: 25%;
				padding: 50px 40px 50px 30px;
				z-index: 1;

				@media (max-width: $responsiveWidht4) {
					display: none;
					margin-top: 0;
					padding: 20px;
					width: 90%;

					h1 {
						margin-top: 0;
					}
				}

				h1 {
					color: $super-light-green;
				}

				p {
					color: $super-light-green;
				}

				.login-post-label {
					color: $super-light-green;
					text-transform: uppercase;
					letter-spacing: 3px;
				}

				.login-post-button {
					margin-top: 20px;
				}
			}
		}

		.login-form-wrap {
			position: relative;
			width: 70%;
			margin-left: 30%;
			@include transition(all ease-in 200ms);

			@media (max-width: $responsiveWidht4) {
				width: auto;
				margin: 20px;
			}

			h2.login-title {
				text-align: center;
				display: block;
				margin-bottom: 10px;
			}

			.alert {
				position: relative;
				@include border-radius(6px);
				text-align: left;
				margin-bottom: 0 !important;
				background-color: $bright-blue;
				color: $white;
				border: none;
				padding: 15px 40px 15px 15px;

				button.close {
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width: 40px;
					transition: all ease 200ms;
				}

				&.incident-alert {
					padding: 20px;
					border-radius: 6px;

					@media (max-width: $responsiveWidht4) {
						& {
							padding: 20px;
						}
					}
				}

				h2 {
					color: $white;
					margin-bottom: 10px;
					display: block;
				}

				a {
					color: $white;
					text-decoration: underline;
				}

				.label {
					font-size: 12px;
				}

				&.alert-blue {
					background-color: $blue;
					color: $white;
					border: none;

					.label {
						background-color: $dark-blue;
						margin-bottom: 10px;
					}
				}

				&.alert-bright-blue {
					background-color: $bright-blue;
					color: $white;
					border: none;

					.label {
						background-color: darken($bright-blue, 10);
						margin-bottom: 10px;
					}
				}

				&.alert-orange {
					background-color: #fad668;
					color: #664c00;
					border: none;

					a {
						color: $bright-blue;
					}

					h2 {
						color: $black;
					}

					.label {
						color: #664c00;
						background-color: rgba($red, 0.2);
						margin-bottom: 10px;
					}
				}

				&.alert-red {
					background-color: $red;
					color: $white;
					border: none;

					.label {
						background-color: darken($red, 10);
						margin-bottom: 10px;
					}
				}
			}

			.login-header {
				position: fixed;
				top: 0;
				right: 0;
				text-align: right;
				height: 50px;
				line-height: 50px;
				padding: 0 20px 0 0;

				@media (max-width: $responsiveWidht4) {
					& {
						top: auto;
						bottom: 0;
						width: 100%;
						text-align: center;

						.subnote {
							color: $white;

							&:hover {
								background-color: $bright-purple;
							}
						}
					}
				}
			}

			.login-form {
				position: relative;
				padding: 30px 30px 0 30px;
				max-width: 500px;
				margin: 0 auto;
				margin-top: 25vh;

				@media (max-width: $responsiveWidht4) {
					& {
						padding: 30px 20px 10px 20px;
					}

					h1 {
						color: $white;
					}
				}

				form {
					$input-height: 50px;
					$font-size: 16px;
					$input-spacing: 20px;
					color: $grey-10;
					text-align: left;

					h1 {
						font-weight: $boldFontWeight;
						color: $black;
					}

					#saved-user-wrap {
						position: relative;
						margin-top: 20px;
						margin-bottom: $input-spacing;
						height: 50px;

						.login-profile-img {
							position: absolute;
							top: 50%;
							left: 10px;
							margin-top: -35px;
							height: $input-height;
							width: $input-height;
							border: 3px solid $dark-green;
							transition: all ease-in 300ms;
							text-align: center;
							z-index: 1;
						}

						#remove-saved {
							cursor: pointer;
							margin-left: 10px;
							line-height: $input-height;
							color: $grey-8;
							transition: all ease-in 300ms;
							float: right;
							margin-right: 20px;
						}

						#saved-user-name {
							position: relative;
							background-color: $dark-green;
							padding-left: 90px;
							display: inline-block;
							height: $input-height;
							line-height: $input-height;
							font-size: 18px;
							margin: 0 auto;
							color: $super-light-green;
							text-align: left;
							width: 100%;
							border-radius: $border-radius;
							font-weight: $boldFontWeight;
							transition: all ease-in 300ms;
							overflow: hidden;
							padding-right: 40px;
							white-space: nowrap;
							text-overflow: ellipsis;

							#remove-saved {
								position: absolute;
								top: 0;
								right: 0;
								color: $super-light-green;
								opacity: 0.5;
							}
						}

						&:hover {
							#saved-user-name {
								background-color: $greyeen;
							}

							.login-profile-img {
								border-color: $greyeen;
							}

							#remove-saved {
								color: $black;
								opacity: 1;
							}
						}

						@media (max-width: $responsiveWidht4) {
							#saved-user-name {
								padding-left: 80px;
							}

							.login-profile-img {
								width: 60px !important;
								height: 60px !important;
								font-size: 24px !important;
								margin-top: -30px;

								.name {
									line-height: 54px !important;
								}
							}
						}
					}

					.input-user {
						margin-top: 20px;
						margin-bottom: 10px;
						.login-input {
							margin-bottom: 0px;
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}
						.input-group-addon {
							background-color: rgba($white, 0.2);
							border: none;
							font-size: 13px;
							color: $super-light-green;
							transition: all ease-in 300ms;
							cursor: pointer;

							&:hover {
								background-color: $super-light-green;
								color: $green;
							}

							&.remembered {
								background-color: $super-light-green;
								color: $green;
							}

							&.error {
								border-color: $orange;
							}
						}
					}

					.input-user-empty-email {
						width: 100%;
						&.input-user-email {
							width: 100%;
						}
						.login-input {
							border-top-right-radius: 3px;
							border-bottom-right-radius: 3px;
							width: 100%;
						}
					}

					.login-input {
						width: 100%;
						height: $input-height;
						padding: 0 10px;
						border: none !important;
						border-radius: $border-radius;
						font-size: $font-size;
						margin-bottom: 10px;
						background-color: $greyeen;
						color: $white;
						@include placeholder-color(rgba($super-light-green, 0.7));

						&:focus,
						&:active {
							color: $green;
							@include placeholder-color($green);
							background-color: $white;
							outline: none;
						}
					}

					.has-error {
						.login-input {
							border-color: $orange;
						}
					}

					label {
						cursor: pointer;
						font-weight: normal;
						height: $input-height;
						line-height: $input-height;
						@include disable-user-select();
					}
				}

				#create-account-link {
					margin-top: 20px;
				}

				#forgot-pass-link {
					display: block;
					text-align: center;
					clear: both;
					margin-top: 20px;

					@media (max-width: $responsiveWidht4) {
						& {
							margin-top: 10px;
							color: $white;
						}
					}
				}

				.reset-password-wrap {
					@include transform(all ease-in 300ms);

					@media (max-width: $responsiveWidht4) {
						& {
							color: $white;
						}
					}

					h1 {
						font-weight: $boldFontWeight;
						margin-bottom: 20px;
					}

					form {
						background-color: transparent;
					}

					.back-to-login-btn {
						margin-top: 45px;

						@media (max-width: $responsiveWidht4) {
							& {
								margin-top: 35px;
								color: $white;
							}
						}
					}
				}
			}
		}

		.login-subnote-wrap {
			.subnote {
				display: inline-block;
				@include transition(all ease-in 200ms);
				color: $super-light-green;
				padding: 0 16px;
				border-radius: 30px;
				height: 30px;
				line-height: 30px;

				&.warning {
					background-color: $bright-orange;
					color: $white;

					a {
						color: $white;
						font-weight: $boldFontWeight;
					}

					&:hover {
						background-color: $orange;
					}
				}

				@media (max-width: $responsiveWidht4) {
					color: $white;
				}

				&:hover {
					background-color: $dark-green;
					text-decoration: none;
				}
			}
		}

		#browser-note {
			margin-top: 10px;
			text-align: center;
			color: $grey-11;
		}
	}

	#sso-logout-warning {
		text-align: center;
		color: $grey-11;
		margin-top: 40px;

		.subtitle {
			margin-top: 10px;
		}
	}

	#login-wrap-settings {
		position: relative;
		height: 720px;
		form {
			$input-height: 50px;
			$font-size: 16px;
			$input-spacing: 20px;
			position: relative;
			width: $width;
			margin: 15px auto;
			padding: 30px;
			background-color: $white;
			border: 1px solid $grey-8;
			color: $grey-10;
			font-size: $font-size;
			@include box-shadow(0 0 10px #cfcfcf);

			#saved-user-wrap {
				margin-bottom: $input-spacing;
			}

			.alert {
				@include border-radius(0 0 3px 3px);
			}

			.login-input {
				margin-bottom: $input-spacing;
				width: 100%;
				height: $input-height;
				border: 1px solid $grey-8;
				padding: 0 10px;

				&:focus,
				&:active {
					border-color: $bright-blue;
					outline: none;
				}
			}

			#login-btn {
				@include border-radius(0);
				margin-right: 10px;
				font-size: $font-size;
				height: $input-height;
				padding-left: 20px;
				padding-right: 20px;
				box-shadow: 0 10px 20px rgba($black, 0.2);
			}

			label {
				cursor: pointer;
				font-weight: normal;
				float: right;
				height: $input-height;
				line-height: $input-height;
				@include disable-user-select();
			}
		}
	}
}
