@import "~StyleVariables";

.RisksCompanyGroup {
	width: 800px;
	height: 100%;
	overflow-y: auto;

	.Drawer__children {
		height: 100%;

		.Table {
			border-bottom: 1px solid $grey-4;
		}

		.ListView {
			border-top: 1px solid $grey-4;

			&__header {
				height: 0;
			}

			&__footer {
				display: none;
			}
		}
	}

	&__header {
		&__controls {
			width: calc(100% - 50px);
		}
	}

	.Icon-circle {
		font-size: 10px;
	}
	&__riskColumn {
		border-top: none !important;
	}
}
