@import "~StyleVariables";

.EditAccountPlan {
	--drawerWidth: 430px;

	width: var(--drawerWidth);
	overflow: auto;

	&__loader {
		width: var(--drawerWidth);
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__leftSide {
			display: flex;
			gap: 12px;
			align-items: center;
			padding-left: 12px;

			.Icon-book {
				font-size: $h3-font-size;
			}

			&__title {
				width: 170px;
			}

			.DropDownMenu__content {
				min-width: 200px;
			}
		}

		.EditorHeaderButton {
			min-width: 100px !important;
		}
	}

	&__selectOption {
		display: flex;
		align-items: center;
		gap: $space * 3;
	}

	&__body {
		position: relative;
		padding: $space * 4;

		.Input {
			width: 100%;
		}

		&__planType {
			&--disabled {
				.Text {
					color: $grey-10;
					font-style: italic;
					padding-right: 1px;
				}
			}
		}

		&__actions {
			display: flex;
			gap: $space * 2;

			.Button {
				width: stretch;
			}
		}

		&__addInternalStakeholders {
			display: inline-flex;
			line-height: $space * 3;

			.Icon {
				margin-right: $space * 2;
			}
		}
	}
}
