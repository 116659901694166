#up-list-filters {
	$search-height: 32px;
	$animationMs: 100ms;

	#list-view-qsearch-wrap {
		position: relative;
		display: inline-block;

		#list-view-qsearch {
			background-color: $grey-4;
			border: 1px solid transparent;
			@include border-radius($border-radius);
			@include transition(all ease-in $animationMs);
			height: $search-height;
			margin-right: 10px;
			padding-left: $search-height;
			outline: none;
			color: $grey-13;
			width: 155px;
			@include placeholder-color($grey-10);

			&:focus {
				border-color: $grey-8;
			}

			&+.fa {
				position: absolute;
				top: 0;
				left: 0;
				line-height: $search-height;
				height: $search-height;
				width: $search-height;
				text-align: center;
				color: $grey-11;
			}
		}
	}

	#toggle-list-filters {
		background-color: transparent;
		position: relative;
		height: 100%;
		border: none;
		color: $grey-10;
		font-size: 14px;
		padding: 0 15px;
		transition: all ease-out $animationMs 400ms;
		transition: background-color ease-out $animationMs;
		overflow: hidden;
		white-space: nowrap;
		border-top-right-radius: $border-radius;

		&:hover {
			background-color: $grey-2;
		}

		.fa.fa-filter {
			margin-right: 5px;
			font-size: 16px;
		}

		.fa.fa-filter-notification {
			margin-right: 0px;
		}
	}

	#list-filters-wrap {
		$listFilterSearchHeight: 40px;
		$toolbarHeight: 36px;

		position: fixed;
		width: $up-filters-width;
		right: -$up-filters-width - 10px;
		bottom: 0;
		top: $main-top;
		background-color: $white;
		@include transition(right ease-in $animationMs);

		&.remove-frame {
			background-color: $grey-2;

			.search-input-wrap {
				width: 100%;

				.search-input {
					width: 240px;
					margin: 4px 12px 4px 8px;
					padding-left: 35px;
					background-color: $grey-5;
					border-radius: 6px;
					height: 30px;
					color: $grey-11;

					&+.fa {
						color: $grey-10;
						margin-left: 8px;
					}
				}
			}

			.list-filter-header {
				background-color: $grey-2;
				font-weight: 600;
			}

			.list-filter-page {
				background-color: $grey-2;

				#available-filters-search-wrap .search-input {
					background-color: $grey-5;
				}
			}

			.list-filters-showall {
				background-color: $grey-2;
				height: 32px;

				.btn-group {
					.show-filter {
						background-color: $grey-2;
						border-bottom: 4px solid $green;
						color: $black;
						font-size: 14px;
						font-weight: 600;
						line-height: 20px;

						&.inactive {
							font-weight: 400;
							color: $grey-10;
							border-color: $grey-6;

							&:hover {
								border-color: $grey-8;
								color: $grey-11;
							}
						}
					}
				}

				#clear-filters {
					display: inline;
					color: $grey-8;
					background-color: $grey-2;
					border-bottom: 4px solid $grey-6;
					height: 32px;

					&:hover {
						border-bottom: 4px solid $grey-8;
						background-color: $grey-2;

						.fa {
							color: $grey-10;
						}
					}

					.fa {
						font-size: 16px;
						color: $grey-8;
					}
				}
			}

			.list-filters-table {
				background-color: $grey-2;

				.list-filters-row {
					.separator {
						font-size: 16px;
						font-weight: $boldFontWeight;
						text-transform: none;
						color: $black;
						border-bottom: 1px solid $grey-6;
						padding-top: 8px;
						height: auto;
					}

					.header {
						background-color: $grey-2;
						color: $grey-11;
						font-size: 12px;
						height: 40px;
						line-height: 40px;
						margin-bottom: 0;
						border-bottom: 1px solid $grey-6;

						&:hover {
							background-color: $grey-1;
						}

						&.active {
							border-left: 0px solid $green;
							background-color: $white;
							padding-left: 12px;
						}

						.delete-button {
							position: relative;
							color: $bright-blue;
							padding: 0px 5px;
							height: 24px;
							width: 24px;
							left: -4px;
							margin: 8px 0;
							border-radius: 4px;

							:before {
								position: relative;
								bottom: 8px;
								left: 2px;
							}

							&:hover {
								color: $dark-red;
								background-color: $super-light-red;
							}
						}
					}
				}
			}

			.list-page {
				.header {
					background-color: $grey-2;

					.back-button {
						width: 30px;
						height: 30px;
						background-color: $green;
						border-radius: 4px;
						margin: 4px 8px 4px 12px;
						color: $super-duper-light-green;

						&:hover {
							background-color: darken($green, 5%);
							border-color: darken($green, 5%);
						}
					}

					.toolbar .toolbar-deselect-all-button {
						color: $black;
					}
				}

				.list-body {
					background-color: $grey-2;
					overflow: auto;

					.list-row:has(:checked) {
						background-color: $white;

						.list-button {
							color: $bright-blue;

							&-item {
								background-color: $white;
							}
						}

						.list-text {
							line-height: 18px;
							font-size: 12px;
							font-weight: 600;
							color: $black;
						}
					}

					.list-row {
						padding: 4px 16px 4px 20px;
						color: $grey-2;
						min-height: 25px;
						line-height: 25px;
						border-bottom: 1px solid $grey-5;
						height: 40px;
						border-radius: 0px;

						&-separator {
							display: flex;
							align-items: center;
							color: $black;
							font-size: 16px;
							font-weight: 600;
						}

						&-group {
							background-color: transparent;
							color: $black;
						}

						&-item {
							background-color: $grey-2;
						}

						.list-text {
							line-height: 32px;
							color: $grey-11;
							font-size: 14px;
							overflow: hidden;
							text-overflow: ellipsis;
							width: inherit;
							white-space: nowrap;

							&-group {
								font-size: 16px;
								.Text {
									font-weight: $boldFontWeight;
								}
							}
						}
					}

					.flex-row:has(:checked) {
						background-color: $white;

						&:hover {
							background-color: $white;
						}

						.list-button {
							background-color: $white;
						}

						.text-container .text {
							line-height: 18px;
							font-size: 12px;
							font-weight: 600;
							color: $black;
						}
					}

					.flex-row {
						background-color: $grey-2;
						color: $grey-2;
						min-height: 25px;
						line-height: 25px;
						border-bottom: 1px solid $grey-5;
						height: 40px;
						margin-right: 12px;
						margin-left: 12px;
						border-radius: 0px;

						&:hover {
							background-color: rgba($grey-2, 1);
						}

						.text-container .text {
							color: $grey-11;
							font-size: 14px;
							line-height: 20px;
						}
					}

					&.lead-sources {
						.value-row {
							border-bottom: 1px solid rgba($black, 0.05);

							&.checked {
								border-left: none;
								padding-left: 7px;
							}
						}

						.list-row {
							background-color: $grey-4;

							.list-input {
								margin-top: 7px;
							}

							.list-text {
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								max-width: 190px;
								color: $black;
							}

							.list-text-number {
								position: absolute;
								right: 10px;
								top: 6px;
								width: 52px;
								text-align: right;
							}

							&.no-result-row {
								background-color: $white;
							}
						}

						.type-row.checked {
							border-left: none;
							padding-left: 7px;
							background-color: $white;
						}

						.source-row.checked {
							border-left: none;
							padding-left: 17px;
							background-color: $white;
						}

						.type-row,
						.source-row {
							color: $black;
							background-color: $grey-2;

							.fa {
								color: $grey-10;
							}

							.fa-angle-down,
							.fa-angle-up {
								position: absolute;
								top: 11px;
								right: 12px;
							}
						}

						.type-row {
							font-weight: $boldFontWeight;
							display: block;
							background-color: $grey-2;
						}

						.source-row {
							font-weight: normal;
							padding-left: 20px;
							background-color: $grey-2;
							display: flex;
							align-items: center;
							color: $black;

							input {
								margin-top: 0;
							}

							.source-text {
								color: $black;
								width: 180px;
								flex: 1;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}

							.source-count {
								text-align: right;
								margin-right: 22px;
								width: 52px;
							}
						}
					}
				}
			}
		}

		&.visible {
			right: 0;

			.FullScreenPage & {
				// offset for scrollbar
				right: 15px;
			}
		}

		.search-input-wrap {
			position: relative;
			display: block;
			width: 100%;

			.search-input {
				position: absolute;
				left: 0;
				padding-left: $listFilterSearchHeight;
				background-color: transparent;
				border: none;
				height: $listFilterSearchHeight;
				outline: none;
				color: $blue;
				z-index: 1000;

				&+.fa {
					position: absolute;
					top: 0;
					left: 0;
					line-height: $listFilterSearchHeight;
					height: $listFilterSearchHeight;
					width: $listFilterSearchHeight;
					text-align: center;
					color: $blue;
					z-index: 1000;
				}
			}
		}

		.list-filter-page {
			position: absolute;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			top: $header-height;
			bottom: 0;
			width: 100%;
			background-color: $grey-4;

			&.light-grey {
				background-color: $grey-2;
			}

			&.scroll-auto {
				overflow-y: auto;
			}

			&.active-page-enter {
				right: -$up-filters-width;
				@include transition(right ease-in $animationMs);
			}

			&.active-page-enter.active-page-enter-active {
				right: 0;
			}

			&.active-page-enter-done {
				z-index: 10;
			}

			&.active-page-exit {
				right: 0;
				@include transition(right ease-in $animationMs);
			}

			&.active-page-exit.active-page-exit-active {
				right: -$up-filters-width;
			}

			&.active-page {
				right: 0;
			}

			#available-filters-search-wrap {
				font-size: 12px;
				$search-height: 27px;
				min-height: $search-height + 20px;
				border-bottom: 1px solid $grey-6;
				box-shadow: 0 2px 4px rgba($black, 0.1);
				z-index: 1;

				.search-input {
					background-color: rgba($grey-8, 0.5);
					height: $search-height;
					padding-left: $search-height;
					border-radius: 3px;
					margin: 10px;
					width: $up-filters-width - 20px;

					&:focus {
						background-color: $grey-8;
					}

					&+.fa {
						width: $search-height;
						height: $search-height;
						line-height: $search-height;
						margin: 10px;
					}
				}

				.fa:last-child {
					position: absolute;
					right: 0;
					width: $search-height;
					height: $search-height;
					line-height: $search-height;
					margin: 10px;
					margin-right: 0;
					z-index: 1000;
					color: $grey-10;
				}
			}
		}

		.list-filter-header {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			background-color: $white;
			height: $header-height;
			line-height: $header-height;
			color: $green;
			padding: 0 $header-height 0 15px;
			border-bottom: $green;
			cursor: pointer;
			transition: all ease-out 300ms;

			&>.fa {
				margin-right: 5px;
			}

			&:hover {
				background-color: $grey-2;
			}
		}

		.list-filters-showall {
			background-color: $white;
			height: 33px;
			border-bottom: 1px solid rgba($white, 0.2);
			padding: 0;

			.btn-group {
				width: 100%;
				position: relative;
				left: 0;
				height: 100%;
				right: 10px;
				margin-right: 40px;

				.show-filter {
					background-color: $white;
					width: 44%;
					border: none;
					border-radius: unset;
					border-bottom: 3px solid $green;
					margin: 0;
					height: 32px;
					color: $green;

					&.inactive {
						color: $grey-11;
						border-color: transparent;

						&:hover {
							background-color: $grey-2;
							border-color: $grey-6;
						}
					}
				}
			}

			button {
				margin-top: 0;
			}

			#clear-filters {
				padding: 0 10px;
				border: none;
				border-radius: unset;
				width: 12%;
				height: 33px;
				transition: all ease-out 300ms;

				&:hover {
					background-color: $grey-6;
				}

				.fa {
					font-size: 16px;
					color: $grey-10;
				}
			}
		}

		&.visible .list-filters-tabs {
			visibility: visible;
			position: sticky;
			top: 0;
			z-index: 1;

			@include opacity(1);

			.btn-group {
				transition: box-shadow ease 0.2s;

				.btn {
					&:first-child {
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
					}

					&:last-child {
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
			}

			&.sticky {
				.btn-group {
					box-shadow: 0 2px 3px rgba($black, 0.1);

					.btn {
						&:first-child {
							border-bottom-left-radius: 4px;
						}

						&:last-child {
							border-bottom-right-radius: 4px;
						}
					}
				}
			}
		}

		.list-filters-tabs {
			height: 33px;
			visibility: hidden;
			@include opacity(0);
			@include transition(all ease 200ms);
			@include transition-delay(200ms);

			.btn-group {
				height: 33px;
				width: 100%;
				overflow: hidden;
				background-color: $grey-4;
				z-index: 1001;
			}

			.tab {
				height: 100%;
				width: 50%;
				font-size: 14px;
				border: none;
				color: $blue;
				background-color: $white;
				box-shadow: 0 0 5px rgba($black, 0.2);

				.fa {
					margin-right: 8px;
				}
			}

			.tab.inactive {
				color: $grey-10;
				background-color: transparent;
				box-shadow: none;
			}
		}

		.list-page {
			z-index: 1000;
			height: 100%;

			.header {
				height: $listFilterSearchHeight + $toolbarHeight;
				display: inline-block;
				width: 100%;
				background-color: $white;
				@include box-shadow(0 2px 1px -1px rgba($grey-10, 1));
				border-bottom: 1px solid $grey-6;

				&.no-toolbar {
					height: $listFilterSearchHeight;
				}

				&.no-shadow {
					box-shadow: none;
				}

				.back-button {
					background-color: $grey-1;
					height: $listFilterSearchHeight;
					width: $listFilterSearchHeight;
					text-align: center;
					border: 0;
					@include transition(background-color ease-in 200ms);

					&:hover {
						background-color: $grey-4;
					}

					&.back-button-text {
						width: stretch;
						text-align: left;
						padding-left: 15px;

						.fa-chevron-left {
							margin-right: 10px;
						}
					}
				}

				.header-extension {
					position: absolute;
					right: 0;
					top: 0;
					height: $listFilterSearchHeight;
					padding-right: 10px;
					display: flex;
					align-items: center;
				}

				.header-tab-wrap {
					display: flex;
					height: $toolbarHeight;
					border-bottom: 1px solid rgba($black, 0.1);

					.header-tab {
						display: flex;
						flex: 1;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						background-color: $grey-4;
						color: $grey-10;

						&.light-grey {
							color: $grey-10;
							background-color: $grey-4;

							&:hover:not(.header-tab-active) {
								background-color: $grey-6;
							}
						}

						&.header-tab-active {
							background-color: $white;
							color: $blue;
							@include box-shadow(0 1px 2px 0 rgba($black, 0.1));
							border-bottom: 2px solid $blue;
						}
					}
				}

				.search-input-wrap {
					position: absolute;
					top: 0;
					left: $listFilterSearchHeight;
				}

				.list-page-spinner {
					display: inline-block;
					position: absolute;
					right: 10px;
					top: 12px;
				}

				.toolbar {
					color: $black;
					border-top: 1px solid rgba($black, 0.1);
					font-size: 12px;
					line-height: $toolbarHeight;
					height: $toolbarHeight;

					.toolbar-deselect-all-button {
						border-left: 1px solid rgba($black, 0.1);
						margin-top: 0;
					}
				}

				.sortToolbar {
					position: relative;
					padding: 5px;

					.btn-wrap {
						height: 26px;
						line-height: 26px;
						display: flex;
						justify-content: center;

						button {
							width: 50%;
							border-radius: 0;
							height: 26px;
							font-size: 12px;
							padding: 0;

							&.active {
								color: $dark-blue;
							}
						}

						.left {
							border-top-left-radius: 2px;
							border-bottom-left-radius: 2px;
						}

						.right {
							border-top-right-radius: 2px;
							border-bottom-right-radius: 2px;
						}

						button:focus {
							text-decoration: none;
						}
					}
				}
			}

			.list-body {
				position: relative;
				background-color: $grey-3;
				width: 100%;
				height: calc(100% - #{$listFilterSearchHeight + $toolbarHeight});

				.list-row {
					cursor: pointer;
					padding: 5px 5px 5px 10px;
					color: $black;
					font-size: $modal-font-size;
					min-height: 25px;
					line-height: 25px;
					position: relative;
					border-bottom: 1px solid #e4eaf0;

					&-group {
						display: flex;
						background-color: transparent;
					}

					&-item {
						display: flex;
						background-color: $white;
					}

					&-group,
					&-item {
						&:hover {
							background-color: rgba($grey-2, 1);
						}
					}

					&-separator {
						color: $grey-11;
						font-weight: $boldFontWeight;
						cursor: default;
					}

					&-missing-row {
						background-color: ghostwhite;

						.list-text-wrap {
							font-weight: $boldFontWeight;
						}
					}

					&:hover .list-button {
						display: inline;
					}

					.list-button {
						margin-right: 4px;
						padding: 0;
						padding-left: 5px;
						border: 0;
						color: $bright-blue;
						display: none;

						&-group {
							background-color: transparent;
						}

						&-item {
							background-color: rgba($grey-2, 0.85);
						}
					}

					.list-input {
						align-self: center;
						margin-right: 5px;
						margin-top: 0;
					}

					.list-text-wrap {
						display: inline-block;
						padding-right: 10px;
						line-height: 25px;
						width: 200px;

						.TooltipOverflowText__inner-text {
							line-height: 32px;
						}
					}

					.list-text {
						line-height: 12px;
						display: inline-block;
						vertical-align: middle;

						&-group {
							font-weight: $boldFontWeight;
						}
						&-secondary {
							position: absolute;
							right: 6px;
							top: 6px;
							bottom: 0;
							color: $grey-10;
						}
					}

					.up-toggle {
						margin-right: 5px;
					}
				}

				.flex-row {
					cursor: pointer;
					padding: 5px 5px 5px 10px;
					margin-bottom: 1px;
					color: $black;
					font-size: $modal-font-size;
					height: 40px;
					position: relative;
					background-color: $white;
					display: flex;

					&:hover {
						background-color: rgba($grey-2, 1);
					}

					&:hover .list-button {
						display: inline;
					}

					.list-button {
						position: absolute;
						right: 5px;
						padding: 0;
						padding-left: 10px;
						border: 0;
						color: $bright-blue;
						display: none;
						background-color: rgba($grey-2, 0.85);
					}

					.list-input-wrap {
						display: flex;
						flex: 0;
						justify-content: center;
						flex-direction: column;

						.list-input {
							margin-right: 10px;
						}
					}

					.text-container {
						display: flex;
						flex: 1;
						justify-content: center;
						flex-direction: column;
						width: calc(100% - 23px);

						.text {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							padding-top: 2px;
							padding-bottom: 2px;
						}

						.subtitle {
							text-transform: none;
							font-style: italic;
							opacity: 0.5;
						}
					}
				}

				.visit-row {
					$visit-row-height: 60px;
					border-bottom: 1px solid $grey-5;
					cursor: pointer;
					padding: $space 0;
					margin-bottom: 1px;
					color: $black;
					font-size: $modal-font-size;
					height: $visit-row-height;
					position: relative;
					margin: 0 $space * 3;

					&:hover {
						background-color: rgba($grey-2, 1);
					}

					&:hover .list-button {
						display: inline;
					}

					.list-button {
						position: absolute;
						right: 5px;
						padding: 0;
						top: 0;
						bottom: 0;
						padding-left: 10px;
						border: 0;
						color: $bright-blue;
						display: none;

						&-group {
							background-color: transparent;
						}

						&-item {
							background-color: $grey-2;
						}
					}

					.list-input-wrap {
						display: inline-block;
						height: 100%;
						vertical-align: middle;
						line-height: $visit-row-height - 10px;
						vertical-align: top;

						.list-input {
							margin-right: 10px;
						}
					}

					.text-container {
						display: inline-block;
						vertical-align: top;
						width: calc(100% - 23px);
						height: 100%;

						.list-text-wrap {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							height: ($visit-row-height - 10px)/3;
							position: relative;
							vertical-align: middle;
							line-height: ($visit-row-height - 10px)/3;

							&.url-row {
								font-style: italic;
								opacity: 0.5;
							}
						}
					}
				}

				&.lead-sources {
					.value-row {
						border-bottom: 1px solid rgba($black, 0.05);

						&.checked {
							border-left: 3px solid $dark-blue;
							padding-left: 7px;
						}
					}

					.list-row {
						background-color: $grey-4;

						.list-input {
							margin-top: 7px;
						}

						.list-text {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							max-width: 190px;
						}

						.list-text-number {
							position: absolute;
							right: 10px;
							top: 6px;
							width: 52px;
							text-align: right;
						}

						&.no-result-row {
							background-color: $white;
						}
					}

					.type-row {
						&.checked {
							border-left: 3px solid $dark-blue;
							padding-left: 7px;
						}
					}

					.source-row {
						&.checked {
							border-left: 3px solid $dark-blue;
							padding-left: 17px;
						}
					}

					.type-row,
					.source-row {
						.fa {
							color: $grey-10;
						}

						.fa-angle-down,
						.fa-angle-up {
							position: absolute;
							top: 11px;
							right: 12px;
						}
					}

					.type-row {
						font-weight: $boldFontWeight;
						display: block;
						background-color: $white;
					}

					.source-row {
						font-weight: normal;
						padding-left: 20px;
						background-color: $grey-1;
						display: flex;
						align-items: center;

						input {
							margin-top: 0;
						}

						.source-text {
							width: 180px;
							flex: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.source-count {
							text-align: right;
							margin-right: 22px;
							width: 52px;
						}
					}
				}
			}
		}

		.list-filters-table {
			position: relative;
			background-color: $grey-3;
			overflow-y: auto;
			flex-grow: 1;

			.list-short-filter-wrap {
				padding: 10px;
				border-bottom: 1px solid $grey-8;

				.list-row-item {
					padding: 5px 0;

					.up-toggle {
						margin-right: 8px;
					}

					.Checkbox {
						margin-right: 8px;
						display: inline-flex;
					}
				}
			}

			.list-filters-row {
				&.active-border {
					border-left: 4px solid $green;
				}

				.separator {
					padding: 0 12px 0 12px;
					margin-bottom: 1px;
					background-color: $grey-2;
					font-size: 12px;
					height: $up-btn-height;
					line-height: $up-btn-height;
					text-transform: uppercase;
					color: $green;

					.title {
						&.active {
							font-weight: $boldFontWeight;
						}
					}
				}

				.header {
					display: flex;
					padding: 0 12px;
					margin-bottom: 1px;
					background-color: $white;
					font-size: $modal-font-size;
					height: $up-btn-height;
					line-height: $up-btn-height;
					color: $grey-11;

					&:hover {
						cursor: pointer;
						background-color: $grey-1;
					}

					&.active {
						border-left: 4px solid $green;
						padding-left: 8px;
					}

					.title {
						flex-grow: 1;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-right: 10px;

						&.active {
							font-weight: $boldFontWeight;
							color: $green;
						}
					}

					.selected-values {
						white-space: nowrap;
						color: $bright-blue;
						padding-right: 5px;
					}

					.selected-values.default {
						color: $grey-11;
					}

					.delete-button {
						color: $grey-11;
						padding: 0 5px;

						&:hover {
							color: $grey-11;
						}
					}
				}

				.drilldown {
					border-bottom: 1px solid $grey-4;
					padding: 5px 10px 5px 10px;
					background-color: rgba($white, 0.5);

					.drilldown-select-row {
						display: block;
						width: 100%;
						background-color: transparent;
						border: none;
						text-align: left;
						height: 30px;
						color: $grey-11;

						&.selected-row {
							color: $bright-blue;
						}

						&:hover:not(.selected-row) {
							color: $black;
						}
					}
				}

				.token-drilldown {
					input {
						width: 160px;
						display: inline-block;
					}

					label {
						font-weight: normal;
						font-size: 12px;
						margin: 0 10px;
					}

					&.is-exclude-filter {
						input {
							width: 100%;
							display: block;
						}
					}

					.token-values {
						.token-item {
							display: inline-block;
							background-color: rgba($bright-blue, 0.7);
							border: 1px solid $bright-blue;
							padding: 2px 5px;
							margin: 6px 2px 0 2px;
							color: $white;
							font-size: 12px;
							@include border-radius(3px);

							button {
								background-color: transparent;
								color: rgba($white, 0.6);
								margin-left: 5px;
								border: none;
								@include transition(all ease 200ms);

								&:hover {
									color: $white;
								}
							}
						}
					}
				}

				.time-drilldown {
					input {
						border-radius: 2px;
						width: 50%;
						display: inline-block;

						&:first-child {
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}

						&:last-child {
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
							margin-left: -1px;
						}
					}

					.TimeInput {
						width: 50%;
						display: inline-block;

						&:first-child {
							input {
								border-right: 0;
								border-top-right-radius: 0;
								border-bottom-right-radius: 0;
							}
						}

						&:last-child {
							input {
								border-top-left-radius: 0;
								border-bottom-left-radius: 0;
								margin-left: -1px;
							}
						}

						input {
							border-radius: 2px;
							width: 100%;
						}
					}
				}

				.address-drilldown {
					label {
						font-size: 12px;
						margin-right: 5px;
					}

					.input-wrap {
						margin-top: 5px;
					}

					.up-comparison-type-toggle {
						cursor: pointer;
					}
				}

				.date-drilldown {
					#datepicker-container {
						position: relative;
						display: flex;
					}

					#datepicker-container .dropdown-menu {
						left: auto !important;
						right: 0px;
					}

					.datepicker-wrap {
						width: 48%;
						display: inline-block;
					}

					.date-period {
						font-size: 12px;
						height: 34px;
						line-height: 34px;
					}
				}

				.range-drilldown {
					display: flex;
					align-items: center;

					input {
						flex: 1;
					}

					input,
					span {
						display: inline-block;
						margin: 0 2px;

						&:first-child {
							margin-left: 0;
						}

						&:last-child {
							margin-right: 0;
						}
					}

					.range-drilldown-table {
						width: 100%;
					}
				}
			}
		}
	}

	.history-filter {
		padding: 10px;

		.select2-container-disabled {
			.select2-arrow {
				display: none;
			}
		}

		.title {
			font-size: 18px;
		}

		.secondary-title {
			font-size: 14px;
		}

		.font-size-12 {
			font-size: 12px;
		}

		.history-filter-row {
			margin-top: 10px;
			min-height: 60px;

			&.thin-row {
				min-height: 40px;
			}

			&.extra-thin-row {
				min-height: 30px;
			}

			.ButtonSelect {
				padding: 0;
			}
		}

		.flex-btn-group {
			display: flex;

			button {
				flex: 1;
				border: 0 !important;

				&:not(:last-child):not(:first-child) {
					border-radius: 0;
				}

				&:last-child {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}

				&:first-child {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			&.outcome {
				button {
					font-size: 12px;
				}
			}
		}

		.history-filter-range {
			display: flex;

			span {
				line-height: 18px;
				padding: 5px;
			}

			.icon-input-wrap {
				position: relative;

				i {
					color: rgba($grey-10, 1);
					position: absolute;
					left: 10px;
					top: 10px;
				}
			}

			input {
				border-radius: 3px;
				padding-left: 30px;
			}
		}
	}

	.history-filter-active-indicator-group {
		display: inline-block;

		.history-filter-active-indicator {
			display: inline-block;
			vertical-align: baseline;
			background-color: $bright-blue;
			color: $white;
			border-radius: 2px;
			width: 45px;
			height: 22px;
			line-height: 22px;

			&:not(:last-child) {
				margin-right: 5px;
			}

			.icon-warp {
				display: inline-block;
				text-align: center;
				cursor: pointer;
				width: 50%;

				.fa-times-circle {
					opacity: 0.6;
				}

				.up-icon-pipeline,
				.up-icon-activity {
					top: 1px;
					position: relative;
				}

				&:hover {
					.fa-times-circle {
						opacity: 1;
					}
				}
			}
		}
	}
}

// Filter open styles
.has-up-list-filters {
	$animationMs: 100ms;

	.up-list-view {
		margin-right: 0;
		@include transition(margin-right ease-in $animationMs);
	}

	#card-main-view,
	.up-header,
	.header-background {
		@include transition(right ease-in $animationMs);
	}

	.quick-prio-filters {
		.Tooltip:last-child {
			.btn-white {
				.Icon {
					color: $red;
				}
			}
		}
	}

	.list-filters-open {
		@media (max-width: 1630px) {
			.quick-prio-filters {
				.Button .Text {
					display: none;
				}
			}
		}
	}

	@media (min-width: 1325px) {
		&.filters-open {
			.up-list-view {
				margin-right: $up-filters-width;
			}

			#card-main-view {
				right: $up-filters-width;
			}

			.up-header,
			.header-background {
				right: $up-filters-width + 15px;
			}

			.column-tools {
				.th-inner {
					@include transition(right ease-in $animationMs);
					right: $up-filters-width + 15px !important;
				}
			}
		}
	}

	@media (max-width: 1310px) {
		& {
			#list-filters-wrap {
				@include box-shadow(0 0 10px rgba($black, 0.5));
			}
		}

		#up-list-filters #list-view-qsearch-wrap {
			&.has-value {
				#list-view-qsearch+.fa {
					color: $bright-blue;
				}
			}

			#list-view-qsearch {
				width: 28px !important;

				&:focus {
					width: 155px !important;
				}
			}
		}
	}

	.listview-appointment {
		@media (max-width: 1360px) {
			& {
				#list-filters-wrap {
					@include box-shadow(0 0 10px rgba($black, 0.5));
				}
			}

			#up-list-filters #list-view-qsearch-wrap {
				&.has-value {
					#list-view-qsearch+.fa {
						color: $bright-blue;
					}
				}

				#list-view-qsearch {
					width: 28px !important;

					&:focus {
						width: 155px !important;
					}
				}
			}
		}
	}

	@media (max-width: 1470px) {
		.hidden-upto-1470 {
			display: none;
		}

		.quick-prio-filters {
			.Button .Text {
				display: none;
			}
		}
	}

	@media (max-width: 1340px) {
		#list-view-qsearch {
			font-size: 12px;
		}
	}
}

/*
	All of the rules here are not needed for salesboard but i wanted to have them in the same place so i moved all the style
	for the buttons down here
*/
.has-up-list-filters .up-header {
	.btn {
		span.larger {
			display: block;
		}

		span.responsive {
			display: none;
		}
	}

	@media (max-width: 1680px) {
		.btn {
			span.larger {
				display: none;
			}

			span.responsive {
				display: block;
			}

			&.calc {
				span.larger {
					display: block;
				}

				span.responsive {
					display: none;
				}
			}
		}
	}

	@media (max-width: 1100px) {
		.btn.calc {
			span.larger {
				display: none;
			}

			span.responsive {
				display: block;
			}
		}
	}
}

.has-up-list-filters {
	&.filters-open {
		@media (max-width: 1500px) {
			.up-header {
				.btn {
					span.larger {
						display: none;
					}

					span.responsive {
						display: block;
					}

					&.calc {
						span.larger {
							display: block;
						}

						span.responsive {
							display: none;
						}
					}
				}
			}
		}
	}
}

.has-up-list-filters {
	&.filters-open {
		.listview-appointment {
			@media (max-width: 1550px) {
				.up-header {
					.btn {
						span.larger {
							display: none;
						}

						span.responsive {
							display: block;
						}

						&.calc {
							span.larger {
								display: block;
							}

							span.responsive {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}