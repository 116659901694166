@import "~StyleVariables";

.OpportunityRowPopUp {
	position: absolute;
	float: right;
	z-index: 1;
	right: 5px;
	top: 42px;
	border-radius: 4px;
	box-shadow: 0px 10px 20px rgba($black, 0.2);
	&__calendar {
		width: 275px;
	}
	&__meny {
		width: 498px;
		&__menyText {
			padding: 15px !important;
			&__inline {
				display: inline;
			}
		}
		.Column:nth-child(1) {
			.Button {
				border-top: 1px solid $grey-6 !important;
				border-right: 1px solid $grey-6 !important;
			}
		}
		.Column:nth-of-type(2) {
			.Button {
				border-top: 1px solid $grey-6 !important;
			}
		}
		.Column:nth-of-type(3) {
			.Button {
				border-top: 1px solid $grey-6 !important;
				border-left: 1px solid $grey-6 !important;
			}
		}

		.Button {
			white-space: break-spaces;
			transition: background-color ease 400ms;

			border-radius: 0px;
			height: 120px;
			width: 166px;
			.Icon {
				font-size: 20px;
				display: block;
			}
			.Text {
				width: 141px;
			}
		}
	}
	&__daysUntil {
		width: 275px;
		.Icon {
			display: block;
			text-align: center;
			font-size: 18px;
			margin-top: 20px;
		}
		.Text--md {
			text-align: center;
			padding: 10px;
		}
		.Text--lg {
			width: inherit;
			padding: 15px 0px 0px 0px;
			text-align: center;
		}
	}
	&__daysUntilWithChangeDay {
		width: 320px;
		.Text--md {
			text-align: center;
			padding: 10px 10px 30px 10px !important;
		}
		.Text--lg {
			width: inherit;
			padding: 15px 0px 0px 0px !important;
			text-align: center;
		}
		.Button {
			width: 320px;
			height: 60px;
			border-top: 1px solid $grey-6 !important;
		}
	}

	.Button:hover {
		background-color: $grey-1;
	}
}
