.Breadcrumbs {
	display: block;
}

.Breadcrumbs--light .Breadcrumbs__item a {
	color: $white;
}

.Breadcrumbs__item {
	display: inline-block;
	font-size: 14px;
	color: $bright-blue;
	cursor: pointer;
}
.Breadcrumbs__item a {
	color: $bright-blue;
}

.Breadcrumbs__item--current {
	color: $grey-10;
	cursor: initial;
}

.Breadcrumbs__separator {
	display: inline-block;
	color: $grey-10;
	margin: 0 5px;
}
