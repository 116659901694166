@import "~StyleVariables";

.ClientCardSidebar__Addresses {
    align-items: center;
    
    &:hover {
        background-color: $grey-1;
        cursor: pointer;
    }

    i {
        margin-right: 6px;
    }
}