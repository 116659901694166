@import "~StyleVariables";

.LandingpageSettings {
	.ModalHeader {
		border-top-color: transparent;
	}
}

.LandingpageSettings__row {
	margin: 0 -5px 15px -5px;
}

.LandingpageSettings__col {
	padding: 0 5px;
}

.LandingpageSettings__col .Label {
	font-weight: $boldFontWeight;
}

.LandingpageSettings .color-picker input {
	padding: 0 10px;
	border-radius: $border-radius;
	height: 34px;
	display: block;
	border: 1px solid #000;
	width: 100%;
}

.LandingpageSettings__toggle-row {
	height: 28px;
	line-height: 28px;
}

.LandingpageSettings__toggle-row > div,
.LandingpageSettings__toggle-row > .Label {
	display: inline-block;
}
.LandingpageSettings__toggle-row > .Label {
	margin-left: 5px;
	font-weight: normal;
}
