@import "~StyleVariables";

.select2-dropdown-hidden {
	display: none !important;
}

.inline-edit-select2--dropdown,
.udo-type-select-dropdown {
	margin-top: 0;
}

$admin-sidebar-width: 230px;

#admin {
	position: absolute;
	top: 0;
	bottom: $padding;
	left: 20px;
	right: $padding;
	background-color: $grey-2;

	.fake-grow {
		transition: all 1s;
	}

	.active-drop-target {
		height: 100px !important;
		overflow: hidden;

		.fake-grow {
			height: 0;

			&.active {
				height: 50px;
				background-color: rgba($black, 0.1);
			}
		}
	}

	#admin-sidebar {
		position: absolute;
		width: $admin-sidebar-width;
		bottom: 0;
		left: 0;
		top: 0;
		background-color: $white;
		border-right: 1px solid $grey-6;

		#admin-sidebar-noresults {
			display: block;
			text-align: center;
			color: $grey-10;
			font-size: 16px;
			height: 30px;
			line-height: 30px;
		}

		#admin-sidebar-search {
			$height: 50px;
			position: relative;
			height: $height;

			.fa-search {
				position: absolute;
				left: 0;
				top: 0;
				font-size: 14px;
				color: $grey-8;
				width: $height;
				text-align: center;
				height: $height;
				line-height: $height;
			}

			input {
				border: none;
				margin: 10px;
				height: $height - 20px;
				width: $admin-sidebar-width - 20px;
				line-height: $height;
				padding: 0 22px;
				padding-left: $height - 20px;
				outline: none;
				background-color: $grey-2;
				border-radius: $border-radius;
				@include placeholder-color($grey-8);
			}

			.clear-search {
				$size: 14px;
				position: absolute;
				right: 20px;
				top: 18px;
				text-align: center;
				width: $size;
				height: $size;
				line-height: $size;
				background-color: transparent;
				font-size: 12px;
				cursor: pointer;
				@include border-radius(3px);
				color: rgba($bright-blue, 0.4);
				@include transition(all 200ms ease);

				&:hover {
					color: $bright-blue;
				}
			}
		}

		#sidebar-list-wrap {
			position: absolute;
			top: 50px;
			bottom: 0;
			right: 0;
			left: 0;
			overflow-x: auto;
		}

		.highlighted {
			background-color: $green;
			color: $super-light-green;
			padding: 4px 0;
			border-radius: $border-radius;
		}

		.sidebar-main-section {
			&.expanded {
				.title .fa {
					transform: rotate(180deg);
				}

				.sidebar-items {
					visibility: visible;
					height: auto;
					@include opacity(1);
				}
			}

			.title {
				$height: 40px;
				padding: 0 15px;
				color: $grey-13;
				font-size: 16px;
				@include transition(color 200ms ease);
				height: $height;
				line-height: $height;
				border-top: 1px solid $grey-4;

				&:hover:not(.no-click) {
					color: $bright-blue;
					cursor: pointer;
				}

				.fa {
					padding: 0 8px;
					position: absolute;
					right: 8px;
					@include transition(all 200ms ease);
					font-size: 16px;
					transform: rotate(0deg);
					height: $height;
					line-height: $height;
				}
			}

			.sidebar-items {
				visibility: hidden;
				overflow: hidden;
				height: 0;
				@include opacity(0);
				@include transition(all 200ms ease);
			}
		}

		#admin-sidebar-profile-item {
			.item-title {
				font-size: 16px;
				color: $grey-13;
				padding-left: 15px;
				height: 35px;
				line-height: 35px;
			}
		}

		.highlighted-item {
			background-color: rgba($super-light-green, 0.5);
			color: $green;
		}

		.sidebar-item {
			display: block;
			color: $grey-11;
			@include transition(color 200ms ease);

			&:hover,
			&.active {
				.item-title {
					color: $green;
					background-color: $grey-1;

					.fa {
						color: $green;
					}
				}
			}
			&.active .item-title {
				color: $black;
				font-weight: $boldFontWeight;
				border-right: 3px solid $green;
			}

			&.expanded {
				.item-title .fa-angle-down {
					transform: rotate(180deg);
				}

				.sub-items {
					visibility: visible;
					height: auto;
					@include opacity(1);
				}
			}

			.item-title {
				$height: 30px;
				font-size: 14px;
				padding: 0 15px;
				padding-left: 25px;
				height: $height;
				line-height: $height;
				cursor: pointer;
				@include transition(color 200ms ease);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&:hover {
					color: $green;
				}

				.fa-angle-down {
					padding: 0 8px;
					position: absolute;
					right: 8px;
					@include transition(all 200ms ease);
					font-size: 12px;
					transform: rotate(0deg);
					height: $height;
					line-height: $height;
				}
			}

			.sub-items {
				visibility: hidden;
				overflow: hidden;
				height: 0;
				@include opacity(0);
				@include transition(all 200ms ease);

				.sub-item {
					font-size: 12px;
					padding-left: 35px;
					height: 30px;
					line-height: 30px;
					cursor: pointer;
					@include transition(all 200ms ease);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					&:hover,
					&.active {
						color: $green;
						background-color: $grey-1;
					}
					&.active {
						color: $black;
						font-weight: $boldFontWeight;
					}
				}
			}
		}

		.sidebar-app-item {
			.app-img-wrapper {
				height: 20px;
				width: 20px;
				margin-right: 5px;
				display: inline-block;
				overflow: hidden;
				border-radius: $border-radius;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 80%;
				vertical-align: middle;
			}
		}
	}

	#component-wrapper {
		visibility: hidden;
		@include opacity(0);
		@include transition(all ease 200ms);

		&.visible {
			@include opacity(1);
			visibility: visible;
		}
	}

	.admin-root-wrapper,
	#admin-root {
		position: absolute;
		left: $admin-sidebar-width;
		right: 0;
		bottom: 0;
		top: 0;
		overflow: hidden;
		overflow-y: auto;

		.admin-page-header {
			background-color: $white;
			display: block;
			position: relative;
			@include box-shadow(0 1px 2px rgba($black, 0.2));

			.Breadcrumbs {
				padding: 30px 40px 0 40px;
			}

			.page-header-text-wrap {
				padding: 40px;
				width: 100%;
				display: inline-block;
				vertical-align: top;
				overflow: hidden;
				padding-right: 33%;

				.page-header-title {
					font-size: 30px;
					margin-bottom: 10px;
					color: $grey-13;
					line-height: 36px;
					font-weight: $regularFontWeight;
				}
			}

			.page-header-icon {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 33%;
				background-image: url("../img/admin-topsection-bg.svg");
				background-repeat: no-repeat;
				background-position: center left;
				background-size: cover;
				text-align: center;

				&.turcose-bg {
					background-image: url("../img/admin-topsection-bg-turcose.svg");
				}

				.header-icon-img {
					position: absolute;
					top: 20px;
					bottom: 20px;
					left: 60%;
					margin-left: -30%;
					width: 60%;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}
			}
		}

		#admin-loader {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $grey-1;
			visibility: hidden;
			@include opacity(0);
			@include transition(all ease 200ms);

			&.visible {
				visibility: visible;
				@include opacity(1);
			}

			.upsales-loader,
			.Loader {
				$size: 100px;
				position: absolute;
				top: 50%;
				left: 50%;
				width: $size;
				height: $size;
				margin-left: -($size/2);
				margin-top: -($size/2);
			}
		}

		.is-touch .admin-row-tools-wrap .admin-row-tools {
			visibility: visible;
			@include opacity(1);
			right: 0;
		}

		.admin-row-tools-wrap {
			overflow: hidden;

			.admin-row-tools {
				position: relative;
				visibility: hidden;
				@include transition(all ease 200ms);
				@include opacity(0);
				right: -100%;
			}
		}

		#admin-content {
			padding: 30px;
			padding-bottom: 80px;
			max-width: 1000px;

			h2 {
				color: $grey-11;
				margin: 0;
				margin-bottom: 10px;
				font-weight: normal;
				font-size: 18px;
				display: block;
			}

			label {
				display: block;
				font-weight: $boldFontWeight;

				&.inline {
					display: inline-block;
				}
			}

			.admin-info-row {
				position: relative;
				display: block;
				height: auto;
				overflow: hidden;
				margin: 0 20px;

				.info-row-content {
					float: none;
					width: auto;
					overflow: hidden;
					margin-bottom: 20px;

					&.no-info {
						padding-right: 20px;
					}
					.Block {
						&.profile-block {
							display: flex;
							align-items: center;
							max-width: 80%;
						}
					}
					.Avatar {
						&.image-margin {
							margin-right: 16px;
						}
					}
				}

				.info-row-info {
					width: 200px;
					float: right;
					border-left: 4px solid $grey-4;
					padding: 10px;
					margin-bottom: 10px;
					margin-left: 20px;
					color: $grey-10;
				}

				&.admin-info-row-flex {
					display: flex;
					overflow: initial;

					.info-row-content {
						flex: 1;
						margin-right: 20px;
						overflow: initial;
					}

					.info-row-info {
						width: 198px;
						float: initial;
						margin-left: 0;
					}
				}
			}

			.admin-section {
				margin-bottom: 30px;
			}

			.admin-table,
			.admin-card {
				background-color: $white;
				box-shadow: 0px 0.5px 1px 1px rgba($black, 0.075);
				position: relative;
				border-radius: $border-radius;
				display: block;
				margin-bottom: 20px;

				&.has-info:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: 218px;
					background-color: $grey-1;
				}

				.admin-card-top,
				.admin-table-top {
					vertical-align: middle;
					padding: 10px 20px;
					line-height: 50px;

					.no-line-height {
						line-height: 1.5;
					}
					.invalid {
						border: 1px solid $red;
					}

					&.table-top-flex {
						display: flex;
						flex-wrap: wrap;
					}

					.admin-card-title,
					.admin-table-title {
						font-size: 18px;
						display: inline-flex;
						align-items: baseline;

						&.table-title-flex {
							flex: 2 0 auto;
						}

						&.admin-card-title-lg,
						&.admin-table-title-lg {
							font-size: 28px;
						}
						.admin-card-title-margin {
							margin-right: 16px;
						}
					}

					.admin-card-top-description,
					.admin-table-top-description {
						color: $grey-11;
						font-size: 14px;
						font-weight: $boldFontWeight;
						line-height: 17px;
						display: block;
					}

					.table-search {
						position: relative;

						input {
							height: 32px;
							background-color: $grey-2;
							border: none;
							outline: none;
							padding-left: 30px;
							@include border-radius(3px);
							@include transition(all ease 200ms);
							width: 150px;

							&:focus {
								@media (min-width: 1200px) {
									width: 200px;
								}
								background-color: $grey-4;
							}
						}

						.fa-search {
							position: absolute;
							left: 10px;
							top: 18px;
							color: $grey-10;
						}
					}
				}
			}

			.admin-card .admin-card-content {
				padding: 0 20px 20px 20px;
			}

			.admin-card .email-tracking,
			.easy-booking-new-mail-link-text {
				.admin-card-title {
					padding-bottom: 15px;
				}

				.description {
					padding-bottom: 20px;
					max-width: 60%;
				}

				.toggle-label-container {
					display: flex;
					align-items: center;
				}

				padding: 20px 30px;
			}
			.admin-card .easy-booking-new-mail-link-text {
				.Input__input {
					width: 50%;
				}
			}

			.admin-table {
				&.has-info:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: 218px;
					background-color: $grey-1;
				}

				.admin-card-top,
				.admin-table-top {
					vertical-align: middle;
					padding: 10px 20px;
					line-height: 50px;

					.admin-card-title,
					.admin-table-title {
						font-size: 18px;
					}

					.table-search {
						position: relative;

						input {
							height: 32px;
							background-color: $grey-2;
							border: none;
							outline: none;
							padding-left: 30px;
							@include border-radius(3px);
							@include transition(all ease 200ms);
							width: 150px;

							&:focus {
								@media (min-width: 1200px) {
									width: 200px;
								}
								background-color: $grey-4;
							}
						}

						.fa-search {
							position: absolute;
							left: 10px;
							top: 18px;
							color: $grey-10;
						}
					}
				}
			}

			.admin-card .admin-card-content {
				padding: 0 20px 20px 20px;
			}

			.table-no-result {
				text-align: center;
				line-height: 40px;
				height: 20px;
				font-size: 20px;
				color: $grey-8;
				position: relative;
				top: -20px;
			}

			.admin-table {
				div.table-wrapper > table,
				& > table {
					width: 100%;
					table-layout: fixed; // DO NOT CHANGE THIS
				}

				div.table-wrapper > table > thead > tr,
				& > table > thead > tr,
				.admin-table-header {
					border-bottom: 1px solid rgba($black, 0.05);

					& > th,
					.admin-table-cell {
						height: 25px;
						line-height: 25px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						text-transform: uppercase;
						color: $grey-11;
						font-weight: normal;
						font-size: 12px;
						padding-left: 20px;

						&.sortable {
							cursor: pointer;
						}

						.fa-caret-down {
							visibility: hidden;
							@include opacity(0);
							@include transition(all ease 200ms);
						}

						&.active {
							color: $bright-blue;

							.fa-caret-down {
								visibility: visible;
								@include opacity(1);
								@include transform(rotate(0deg));
							}

							&.desc .fa-caret-down {
								@include transform(rotate(180deg));
							}
						}
					}
				}

				div.table-wrapper > table > tbody > tr,
				& > table > tbody > tr,
				.admin-table-row {
					cursor: default;
					border-bottom: 1px solid rgba($black, 0.05);
					background-color: $white;
					@include transition(height 0.5s);
					@include transition(background-color 0.2s);

					&.clickable {
						cursor: pointer;

						&:hover {
							background-color: $grey-3;
						}
					}

					&.inactive-row {
						background-color: $grey-2;
						border-bottom: none;

						& > td:first-child:before,
						& > .admin-table-cell:first-child:before {
							content: "";
							border-left: 4px solid $grey-10;
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
						}
					}

					&:hover {
						.admin-row-tools-wrap .admin-row-tools {
							visibility: visible;
							@include opacity(1);
							right: 0;
						}
					}

					&.admin-table-standard-field-row {
						background-color: $grey-1;

						.Help {
							display: inline;
							margin-left: 4px;
						}

						&.clickable {
							background-color: $white;

							&:hover {
								background-color: $grey-3;
							}
						}

						&.active {
							background-color: $white;
						}
					}
				}

				.admin-table-group {
					background-color: white;

					.cheveron {
						transition: transform 0.5s ease-in-out;
						margin-left: 20px;
					}

					&[open] .cheveron {
						transform: rotate(180deg);
					}

					.is-draggable-parent {
						cursor: pointer;
						display: inline;
					}

					.admin-table-group-title {
						font-weight: $boldFontWeight;
						align-self: center;
						padding-left: 10px;
						display: inline;
					}
				}

				.required-header {
					grid-column: 5 /7;
				}

				.table-btn {
					border: none;
					display: block;
					width: 100%;
					height: 45px;
					line-height: 45px;
					padding: 0;
					color: $grey-8;
					background-color: $grey-1;
					outline: none;
					text-align: center;
					@include transition(all ease 200ms);

					&:hover {
						background-color: $grey-4;
						color: $bright-blue;
					}
				}

				& > table > tbody > tr.two-lines > td,
				.admin-table-row.two-lines .admin-table-cell {
					line-height: 25px;
					height: 50px;
				}

				div.table-wrapper > table > tbody > tr > td,
				& > table > tbody > tr > td,
				.admin-table-cell {
					padding: 0 0 0 20px;
					line-height: 40px;
					font-size: 14px;
					line-height: 40px;
					height: 40px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					position: relative;

					.subtitle {
						color: $grey-10;
						font-size: 12px;
					}

					&.no-padding-right {
						padding-right: 0;
					}

					&.delete-btn {
						visibility: hidden;
						color: $grey-10;
						float: right;
						margin-right: 20px;
					}

					&.delete-btn:hover {
						color: $red;
					}
				}

				.admin-table-body {
					.ui-sortable-helper {
						border-top: 1px solid rgba($black, 0.05);
						box-shadow: 0 0 4px rgba($black, 0.2);
					}

					.edit {
						line-height: 70px;
						height: 70px;

						.floating-label-input {
							padding-top: 20px;

							> label {
								font-weight: $regularFontWeight !important;
							}

							&.has-value {
								> label {
									padding: 0;
									bottom: 20px;
								}

								> input {
									padding: 0;
									border-bottom-color: $bright-blue;
								}
							}
						}

						.floating-label-select {
							.select2-container {
								box-shadow: none;
								border-bottom: 1px solid $bright-blue;
								border-radius: 0;

								.select2-choice {
									background: rgba($black, 0);
									border: none;

									.select2-arrow {
										border: none;
										height: 15px;

										> b {
											background-position: 0 -5px;
											background: rgba($black, 0);
											border: 4px solid rgba($black, 0);
											border-top-color: $bright-blue;
											width: 1px;
											height: 1px;
											margin-top: -2px;
											top: 50%;
											position: absolute;
											left: 50%;
											margin-left: -4px;
										}
									}
								}
							}

							&.has-value {
								.select2-choice {
									border-bottom-color: $bright-blue;
								}
							}
						}
					}

					.admin-table-cell {
						display: inline-block;
						vertical-align: middle;
						padding: 0 0 0 20px;

						&.edit-controls {
							float: right;
							padding-right: 20px;
							height: 70px;

							.btn {
								float: right;
								margin: 20px 0;
							}

							&:after {
								clear: both;
								content: "";
								display: block;
							}
						}

						& > .Help {
							display: inline;
							margin-right: 5px;
						}
					}

					.admin-table-header {
						.admin-table-cell {
							height: 25px;
							line-height: 25px;
							padding: 0 0 0 20px;
						}
					}

					.admin-table-row {
						width: 100%;

						.delete-btn:hover {
							visibility: visible;
						}

						.admin-table-cell {
							&.color-grey {
								color: $grey-8;
							}

							&.hover-red:hover {
								color: $red;
							}
						}
					}
				}

				.floating-label-select {
					> label {
						top: 1px;
						padding: 0;
						font-weight: $regularFontWeight !important;
						font-size: 0.8em !important;
					}

					.select2-container {
						position: relative;
						line-height: 40px;
						height: 19px;

						.select2-choice {
							border: none;
							height: 19px;
							border-bottom: 1px solid $grey-8;
							border-radius: 0;
							margin: 0;
							padding: 0;
							line-height: 0;

							.select2-chosen {
								padding: 0;
								position: relative;
								z-index: 1001;
								background-color: rgba($black, 0);
								border: none;
								width: 100%;
								line-height: 1.5em;
								color: $grey-13;
								font-size: 12px;
								text-overflow: ellipsis;
								width: 80%;
							}
						}
					}
				}
			}

			.admin-table + .admin-table {
				margin-top: 30px;
			}

			.error-msg {
				color: $red;
			}
		}

		#admin-page-2fa {
			.credit-info {
				padding: 20px 20px;

				.col-xs-6:nth-child(1) {
					.credits {
						font-size: 40px;

						span:nth-child(1) {
							color: $purple;
						}
						span:nth-child(2) {
							color: $grey-10;
						}
					}
					div:last-child {
						font-size: 14px;
						font-weight: $regularFontWeight;
					}
				}
			}

			.user-info {
				.admin-table-top {
					div:nth-child(2) {
						line-height: 20px;
						color: $grey-11;
					}
				}
			}
		}

		.up-paginator-wrap {
			text-align: center;
			display: block;
			margin-top: 10px;

			.up-paginator {
				display: inline-block;

				li {
					display: inline;
					cursor: pointer;
				}
			}
		}

		#admin-page-apikeys {
			.page-header-text-wrap {
				padding-bottom: 20px;

				a {
					margin-top: 20px;
					display: block;
				}
			}
			.SettingsToggle {
				margin-bottom: 20px;
				&__right-column {
					overflow: hidden; // remove this when we get a new image that fits
				}
			}
		}

		#admin-page-agreement {
			.Column {
				position: relative;
			}

			.ButtonSelect {
				display: flex;
			}

			.Label {
				font-weight: $boldFontWeight;
			}

			.info-block {
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				bottom: 0;
			}

			.lockedStagesInfo {
				white-space: break-spaces;
			}

			.creditOrderSetting {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-direction: row-reverse;
				padding-top: 4px;
				padding-bottom: 12px;
			}

			.admin-page-agreement-display-inline {
				display: inline-block;
			}
		}

		#admin-page-account-profile {
			.admin-card {
				padding: 20px;
			}

			#admin-content {
				max-width: inherit;
				position: relative;

				&.fixed {
					margin-top: 50px;
				}
			}

			.info-row-info {
				@include transition(width ease-in 100ms);
			}

			@media (max-width: 1410px) {
				#admin-content.open {
					.info-row-info {
						width: 350px !important;
					}
				}
			}

			.profile-preview-sidebar {
				@include transition(right ease-in 100ms);
				box-shadow: -3px 0 6px rgba($black, 0.2);
				position: absolute;
				bottom: 0px;
				top: -52px;
				right: calc(-25vw - 6px);
				min-width: 400px;
				width: 25vw;
				background-color: $grey-1;
				border-bottom-left-radius: 2px;
				border-top-left-radius: 2px;
				z-index: 990;

				label {
					font-size: 12px;
					text-transform: uppercase;
					margin-bottom: 5px;
				}

				.fake-banner-wrap {
					display: block;

					.fake-banner {
						display: inline-block;
						margin-right: 10px;
						margin-bottom: 10px;
						box-shadow: 0 1px 5px rgba($black, 0.2);
						vertical-align: top;
					}
				}

				&.fixed {
					position: fixed;
					top: 97px;
					bottom: 15px;
					overflow-y: scroll;
				}

				.header {
					background-color: $white;
					border-top-left-radius: 3px;
					height: 50px;
					line-height: 50px;
					color: $grey-10;

					.header-icon {
						padding-left: 20px;
						padding-right: 10px;
					}

					button {
						height: 40px;
						border: 0;
						background-color: transparent;
					}
				}

				.body {
					.preview {
						padding: 20px;
						padding-top: 10px;

						#fake-content-wrapper {
							margin-bottom: 10px;
						}
					}
				}

				&.open {
					right: 0;
				}
			}

			.admin-info-row {
				margin: 0;
			}

			.settings-column {
				max-width: 900px;
			}

			.package-box.cover-img {
				width: calc(50% - 6px);
				margin-right: 0;

				&:first-child {
					margin-right: 10px;
					margin-left: 1px;
				}
			}

			.card-table {
				tr:first-child {
					border-top: 1px solid rgba($black, 0.05);
				}

				td {
					padding: 0 !important;
				}
			}

			.account-profile-colors .color-picker {
				margin: 10px 0;
			}

			.color-picker {
				display: inline-block;
				height: 20px;

				.color-input {
					height: 100%;
					@include border-radius(3px);
					font-size: 12px;
					width: 80px;
					text-align: center;
					border: 0;
					box-shadow: 0px 1px 2px rgba($black, 0.2);
				}
			}

			td.typography-tools {
				.color-picker .color-input {
					width: 65px;
					margin-right: 5px;
				}

				.row {
					line-height: initial;
					padding: 10px 0;

					div:first-child {
						margin-bottom: 6px;
					}

					.btn-group {
						button {
							padding: 0 9px;
						}
					}
				}
			}

			.color-ball {
				$size: 30px;
				position: relative;
				width: $size;
				height: $size;
				line-height: $size - 4px;
				border: 1px solid transparent;
				margin: 3px;
				display: inline-block;
				background-color: $white;
				text-align: center;
				color: $grey-8;
				font-size: 13px;
				vertical-align: top;
				@include box-shadow(0 1px 2px rgba($black, 0.5));
				@include border-radius($size);

				&.add {
					border: 1px dashed $grey-8;
					@include box-shadow(none);
				}

				button.remove-color {
					border: 1px solid rgba($white, 0.5);
					@include border-radius(50%);
					height: 20px;
					width: 20px;
					line-height: 20px;
					text-align: center;
					font-size: 11px;
					background-color: rgba($red, 0.7);
					color: $white;
					padding: 0;
					position: absolute;
					top: -6px;
					right: -6px;
					visibility: hidden;
					@include opacity(0);
					@include transition(all ease-in 200ms);

					&:hover {
						background-color: $red;
					}
				}

				&:hover button.remove-color {
					visibility: visible;
					@include opacity(1);
				}
			}

			.font-family-select {
				margin-right: 5px;
			}

			.font-family-select,
			.font-size-select {
				width: 170px;
				font-size: 12px;
			}

			.font-size-select {
				width: 40px;
				margin-right: 5px;
			}

			#profile-preview {
				padding: 0;
				max-width: 500px;
				margin: 15px;

				&.fixed {
					position: fixed;
					top: 77px;
				}
			}

			.fake-support-email {
				border: 1px solid $grey-5;
				background-color: $white;
				font-size: 14px;
				margin-bottom: $space * 3;

				&__header {
					font-size: 24px;
					font-weight: bold;
					padding: 16px 28px; // this is to match the email header padding
					border-bottom: 1px solid $grey-5;
				}

				&__item {
					margin: 0 12px;
					padding: 16px;
				}

				&__item:not(:last-child) {
					border-bottom: 1px solid $grey-5;
				}

				&__date {
					font-size: 12px;
					color: $grey-11;
					margin-bottom: 8px;
				}
			}

			#fake-content-wrapper {
				overflow: hidden;
				@include border-radius(3px);
				@include box-shadow(0px 1px 5px rgba($black, 0.2));

				.profile-header {
					position: relative;
					display: block;
					padding: 10px 20px;

					#contact-us {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						font-size: 12px;
						display: flex;
						align-items: center;
						padding: 0 20px;
					}
				}

				.profile-content {
					padding: 25px 15px;
					margin: 15px;
					line-height: 1.3em;
				}
				.profile-button {
					border: none;
					padding: 7px 20px;
					font-style: 12px;
					min-width: 120px;
				}
				.profile-footer {
					padding: 0px 20px 10px 20px;
					line-height: 20px;
					font-size: 12px;

					& > .pull-right {
						text-align: right;
					}
				}
			}

			.hovered-element {
				position: relative;

				&:before {
					content: "";
					background-color: rgba($orange, 0.6);
					border: 1px solid $orange;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}

			.fake-banner {
				width: 300px;
				height: 250px;

				iframe {
					border: none;
				}
			}

			.page-header-tabbar {
				background-color: $white;
				text-align: right;
				box-shadow: 0 1px 2px rgba($black, 0.2);
				border-top: 1px solid rgba($black, 0.1);
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2px 15px 0 0;

				&.fixed {
					position: fixed;
					top: 46px;
					left: 278px;
					z-index: 990;
				}
			}

			.typography-row {
				display: inline-block;
			}

			@media (min-width: 1201px) {
				.email-input,
				.country-input {
					padding-left: 0;
				}
			}

			@media (max-width: 1659px) {
				.profile-preview-sidebar {
					right: -400px;

					&.fixed {
						top: 47px;
					}
				}
			}

			@media (max-width: 1230px) {
				#admin-content.open {
					.package-box {
						display: block;
						width: auto;
					}
				}
			}

			@media (max-width: 1180px) {
				#admin-content.open {
					.typography-row {
						display: block;
					}
				}
			}

			@media (min-width: 1659px) {
				.account-profile-preview-button {
					display: none;
				}

				.profile-preview-sidebar {
					left: 930px;
					top: 30px;
					box-shadow: 0 0.5px 1px 1px rgba($black, 0.075);
					border-radius: $border-radius;
					background-color: $white;

					&.fixed {
						position: fixed;
						left: $sidebar-width + $admin-sidebar-width + 5px + 908px;
						bottom: 15px;
						top: 124px;
					}

					.header {
						display: none;
					}
				}

				.admin-page-header {
					box-shadow: 0 1px 2px rgba($black, 0.2) !important;
				}
			}
		}

		#admin-page-integrations {
			.menu {
				display: flex;
				flex-direction: row;
				padding: 30px 30px 0 30px;
				max-width: 900px;

				.category-select-wrap {
					display: flex;
					align-items: center;
					margin-left: 5px;

					.category-wrap {
						width: 200px;
						margin-right: 40px;
					}
				}

				.checkbox-holder {
					display: inline-flex;
					margin-right: 20px;

					.Text {
						margin-left: 10px;
					}
				}

				.search-input-wrap {
					flex: 1;
					$height: 30px;
					position: relative;
					width: 300px;

					.search-input {
						height: 100%;
						padding-left: 40px;
						border-radius: $border-radius;
						width: 100%;
						padding-left: $height;
						background-color: $grey-5;
						border: none;
						height: $height;
						outline: 0;
					}

					.fa {
						position: absolute;
						top: 0;
						left: 0;
						line-height: $height;
						width: $height;
						height: 100%;
						text-align: center;
						color: $grey-8;
					}
				}
			}
		}

		#admin-page-edit-integration {
			.image-wrap {
				height: 100px;
				width: 100px;
				background-color: $grey-1;
				font-size: 40px;
				line-height: 100px;
				text-align: center;
				color: $grey-8;
				border: 1px solid $grey-8;
				position: relative;

				img {
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
				}
			}

			.upload-button {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				position: relative;

				i {
					margin-right: 5px;
				}

				input {
					color: transparent;
					padding-left: 130px;
					opacity: 0;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 100%;
				}
			}
		}

		#admin-page-anonymization {
			#list {
				padding-bottom: 20px;

				.header-button {
					float: right;
					margin-top: -4px;
					margin-right: 16px;
				}
			}

			.no-padding {
				padding: 0 !important;
			}

			.card {
				margin: 20px 40px;
				padding: 20px 0;
				background: $white;
				box-shadow: 0 1px 2px rgba($black, 0.2);
				border-radius: 4px;

				&.fields {
					padding: 20px 0 0;

					.Help {
						margin-left: 4px;
						display: inline-block;
					}
				}

				h2,
				h3 {
					margin: 0;
					line-height: 1.3em;

					b {
						padding-left: 5px;
					}
				}

				h2 {
					margin-bottom: 15px;
					padding: 0 20px;
					line-height: 32px;
				}

				.list-of-checks {
					margin-top: 20px;
					padding: 0 20px;
				}

				.checker-item {
					margin-bottom: 20px;

					.text-content {
						p {
							color: $grey-11;
							font-size: 14px;
							font-weight: $regularFontWeight;
							letter-spacing: -0.1px;
							line-height: 22px;
						}
					}
				}

				.main-paragraph {
					padding: 0 20px;
					margin-bottom: 8px;

					span.important {
						font-weight: $boldFontWeight;
						text-decoration: underline;
						color: $grey-13;
						margin: 0 4px;
					}
				}

				.subheader {
					padding: 0 20px;
				}
			}

			.regular-table-title {
				color: $grey-11;
				font-size: 18px;
				font-weight: $regularFontWeight;

				b.fa {
					font-size: 14px;
				}
			}

			.checkbox-holder {
				float: left;
				margin-right: 15px;
				padding-bottom: 2.3em;
				margin-top: 4px;
			}

			.huge-button-full-width {
				margin-bottom: -20px;
				font-size: 18px;
				border-radius: 0;
			}

			table {
				width: 100%;

				tr {
					border-bottom: 1px solid rgba($black, 0.05);

					.up-toggle {
						width: 16px;
						font-size: 16px;
						height: 16px;
					}

					th {
						height: 30px;
						font-size: 12px;
						text-transform: uppercase;
						font-weight: $regularFontWeight;
						color: $grey-11;

						&.sortable-th {
							cursor: pointer;

							&.sorting-active {
								color: $bright-blue;
							}
						}

						.sorting-indicator {
							margin-left: 5px;
						}

						&:first-child {
							padding-left: 20px;
							min-width: 50px;
						}

						&:last-child {
							padding-right: 20px;
						}

						&.dropdown-checkmark {
							.dropdown-menu {
								li {
									text-transform: initial;
									display: block;
									padding: 3px 20px;
									clear: both;
									font-weight: normal;
									line-height: 1.42857;
									color: $grey-13;
									white-space: nowrap;
									cursor: pointer;
									background-color: $white;
									transition: all 0.3s;

									&:hover {
										background-color: $medium-blue;
									}
								}
							}
						}
					}

					td {
						height: 50px;

						&:first-child {
							padding-left: 20px;
							min-width: 50px;
						}

						&:last-child {
							padding-right: 20px;
						}

						&:nth-child(4) {
							min-width: 100px;
						}

						&:nth-child(3) {
							padding-right: 20px;
						}

						i.grey {
							font-size: 13px;
						}

						.lead-contact {
							a {
								color: $grey-13;
							}

							.lead-contact-account {
								a {
									font-size: 12px;
									color: $grey-11;
								}
							}
						}

						.account-manager {
							.generic-avatar {
								margin-top: 1px;
								margin-right: 10px;
							}

							> span {
								display: block;
								line-height: 1.1em;
							}
						}
					}
				}
			}
		}

		#admin-page-dashboard {
			.admin-page-header {
				height: 254px;

				.header-overlay {
					background-color: $green;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}

				.dashboard-header-logo {
					width: 160px;
					height: 60px;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center center;
					display: inline-block;
				}

				.dashboard-header-search {
					position: relative;
					display: block;
					background-color: $white;
					border-radius: $border-radius;
					box-shadow: 0 1px 2px 0 rgba($black, 0.2);
					width: 465px;
					height: 49px;
					margin: 15px auto 0 auto;
					overflow: hidden;

					.fa {
						position: absolute;
						top: 0;
						left: 0;
						color: $grey-8;
						font-size: 14px;
						line-height: 49px;
						width: 49px;
						text-align: center;
					}

					input {
						width: 100%;
						height: 100%;
						background: $white;
						padding-left: 40px;
						outline: none;
						border: none;
						transition: all ease 200ms;

						&:focus {
							background-color: $grey-1;
						}
					}
				}

				#dashboard-header-info {
					position: absolute;
					top: 25px;
					left: 0;
					right: 0;
					text-align: center;
					color: $white;

					h3 {
						margin: 0;
						font-weight: normal;
						font-size: 30px;
						line-height: 32px;
					}

					.license-count {
						color: $super-light-green;
						font-size: 12px;
						font-weight: $regularFontWeight;
						line-height: 24px;
						margin-top: 7px;
						display: block;

						b {
							text-transform: uppercase;
						}
					}

					#admin-header-logo {
						height: 32px;
						position: relative;
						top: 10px;

						.logo-letter {
							fill: rgba($white, 0.8);
						}
					}
				}
			}

			#admin-content {
				max-width: 800px;
				padding: 0;
				margin: 0 auto;
			}

			.dashboard-content-inner {
				position: relative;
				margin-top: -20px;
				display: block;
				height: auto;
				overflow: hidden;
				padding: 0 20px;

				.dashboard-content-left {
					float: none;
					width: auto;
					overflow: hidden;
					padding-right: 30px;
				}

				.dashboard-content-right {
					width: 260px;
					float: right;
				}

				.dashboard-widget {
					position: relative;
					box-shadow: 0 1px 2px rgba($black, 0.2);
					background-color: $white;
					border-radius: $border-radius;
					width: 440px;
					width: 100%;
					margin-bottom: 10px;
					overflow: hidden;

					&.widget-halfnhalf {
						background-position: center right 30px;
						background-repeat: no-repeat;

						.widget-content {
							padding-right: 50%;
						}
					}

					&.sliced:before {
						content: "";
						background-color: $grey-1;
						position: absolute;
						top: -20px;
						left: -20px;
						right: -20px;
						height: 120px;
						transform: rotate(-5deg);
						transition: all ease 500ms;
					}

					&.sliced:hover:before {
						height: 110px;
					}

					&.centered .widget-title,
					&.centered .widget-content {
						text-align: center;
					}

					.widget-title-img {
						width: 50px;
						position: absolute;
						top: 25px;
						left: 15px;

						& + .widget-title {
							padding-left: 75px;
						}
					}

					.widget-title {
						position: relative;
						display: block;
						padding: 20px 20px 0 20px;
						color: $grey-11;
						font-size: 18px;
						line-height: 30px;

						.fa {
							margin-right: 5px;
						}

						.widget-title-subtitle {
							color: $grey-10;
							font-size: 14px;
							font-weight: $regularFontWeight;
							line-height: 17px;
						}
					}

					.widget-content {
						position: relative;
						padding: 20px;
						padding-top: 15px;
						font-size: 14px;
						color: $grey-10;

						&.no-padding-bottom {
							padding-bottom: 0;
						}
					}

					.big-link {
						font-size: 18px;
						font-weight: $boldFontWeight;
						line-height: 22px;
						margin-top: 10px;
						display: block;
					}

					.widget-btn {
						display: block;
						outline: none;
						border: none;
						background-color: transparent;
						min-height: 40px;
						line-height: 40px;
						color: $bright-blue;
						font-size: 14px;
						font-weight: $regularFontWeight;
						text-align: center;
						width: 100%;
						transition: all ease 200ms;
						border-top: 1px solid $grey-4;
						text-decoration: none;
						padding: 0;

						&:hover {
							border-top-color: transparent;
							background-color: $grey-1;
							text-decoration: none;
						}
					}

					.widget-img {
						max-height: 170px;
						max-width: 170px;
						position: relative;
						margin: 20px auto 0 auto;
						display: block;
						margin-bottom: 10px;

						& + .widget-title {
							padding-top: 0;
						}
					}

					.widget-icon {
						font-size: 120px;
						display: flex;
						justify-content: center;
						padding-top: 15px;
						color: $green;
					}
				}
			}

			.support-times {
				display: block;
			}

			.shortcut {
				display: inline-block;
				margin-bottom: 20px;
				width: 50%;
				vertical-align: top;
				padding-right: 5px;

				a {
					display: block;
				}

				span {
					color: $grey-10;
					font-size: 12px;
				}
			}

			.app-widget-content {
				button {
					margin-top: 10px;
				}
			}

			.app-widget-content {
				button {
					margin-top: 10px;
				}
			}

			.dashboard-widget .widget-btn.next-onboarding-btn {
				position: relative;
				text-align: left;
				padding: 10px 15px;
				line-height: 20px;

				.fa-angle-right {
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -10px;
					width: 10px;
					height: 20px;
					line-height: 20px;
				}

				.btn-title {
					text-transform: uppercase;
					color: $grey-10;
					display: block;
					font-size: 12px;
				}
			}

			#docebo-btn {
				margin-top: 20px;
			}

			.news-row {
				display: block;
				font-size: 16px;
				color: $grey-11;
				transition: all ease 200ms;
				margin-bottom: 20px;
				text-decoration: none;

				&:hover {
					color: $bright-blue;
					text-decoration: none;

					.news-subtitle {
						color: $black;
					}
				}

				.news-subtitle {
					transition: all ease 200ms;
					font-size: 12px;
					color: $grey-10;
					display: block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		#admin-page-market-reject-list {
			.quick-search-wrap {
				margin-right: 10px;
			}

			.add-account-wrap {
				position: relative;
				top: -2px;
			}
		}

		#admin-page-visit {
			.scoring-description {
				margin-right: 10px;
			}

			.script-card {
				padding: 20px 20px 10px 20px;

				h2 {
					margin-bottom: 15px;
				}

				.admin-info-row {
					margin: 0;
				}

				.border-top {
					padding-top: 10px;

					&:before {
						width: calc(100% + 40px);
						margin: 0 -20px;
						content: "";
						display: block;
						height: 1px;
						background: $grey-5;
						margin-bottom: 10px;
					}
				}

				.info-row-info {
					margin-bottom: 10px;
				}

				.info-row-content {
					margin-bottom: 0;

					.toggle-retargeting {
						padding-left: 1px;

						label {
							display: inline-block;
							margin-right: 10px;
						}
					}
				}

				.send-mail-wrapper {
					margin: 0 auto;
					text-align: center;
					width: 50%;
					min-width: 100px;

					.SystemMail {
						background-color: $white;
						width: 360px;
						position: absolute;
						visibility: hidden;
						opacity: 0;
						box-shadow: 0 3px 6px 0 rgba(30, 37, 46, 0.3);
						border-radius: $border-radius;
						border-top-right-radius: 0;
						padding: 20px 30px;
						transition: all ease-out 200ms;
						z-index: 10;
					}

					&.show-mail {
						.SystemMail {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			.show-no-score-pages {
				display: block;
			}

			.copy-btn {
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: 1000;
			}

			.hidden-input {
				@include opacity(0);
				position: absolute;
				left: -9999;
				top: -9999;
			}

			.page-subtitle {
				color: $grey-10;
				margin-bottom: 10px;
				text-align: right;

				> a {
					cursor: pointer;
				}
			}
		}

		#admin-page-looker {
			#access-header {
				width: 300px;
			}

			.admin-table {
				padding-right: 20px;
			}
		}

		.card--right-col {
			.notification-form {
				margin-left: 10px;
				position: relative;
				z-index: 30;
				height: 100%;
				display: flex;
				justify-content: center;
			}

			.ButtonGroup {
				height: 34px;
				line-height: 32px;
				position: relative;

				input {
					border-radius: 0;
				}
				& > .Block {
					position: absolute;
					top: 30px;
				}
			}
		}

		.soft-app-warining {
			padding: 20px;
			border-radius: 0 0 $border-radius $border-radius;
			border-top: 1px solid $grey-3;
			position: relative;
			z-index: 100;
			background: $white;
		}

		#admin-page-profile {
			#user-profile-header {
				position: relative;
				height: 245px;
				display: block;
				overflow: hidden;
				background-color: $green;

				.stats-wrap {
					position: absolute;
					bottom: -10px;
					left: 40px;
					right: 40px;
					visibility: hidden;
					transition: all ease 200ms;
					opacity: 0;

					&.loaded {
						visibility: visible;
						opacity: 1;
						bottom: 20px;
					}

					.user-stat {
						color: $white;
						font-size: 30px;
						font-weight: $regularFontWeight;
						line-height: 36px;
						text-align: left;
						display: inline-block;
						width: 25%;

						.currency,
						.stat-title {
							color: $super-light-green;
							font-size: 12px;
							font-weight: $boldFontWeight;
							letter-spacing: 1px;
							line-height: 15px;
							display: block;
							text-transform: uppercase;
						}

						.currency {
							display: inline-block;
							vertical-align: top;
							margin-top: 3px;
							margin-left: 3px;
						}
					}
				}

				.name-wrap {
					position: absolute;
					top: 40px;
					left: 150px;
					right: 0;
					color: $white;
					font-size: 32px;
					font-weight: $boldFontWeight;
					line-height: 40px;

					.user-title {
						color: $super-light-green;
						font-size: 12px;
						font-weight: $boldFontWeight;
						letter-spacing: 1px;
						line-height: 15px;
						display: block;
						text-transform: uppercase;
					}
				}
			}

			.half-row {
				margin: 0 -10px;

				.half-column {
					width: 50%;
					display: inline-block;
					padding: 0 10px;
					margin-bottom: 10px;
					vertical-align: top;

					.w40 {
						width: 30%;
						display: inline-block;
					}

					.w65 {
						width: calc(65% - 10px);
						margin-left: 10px;
						display: inline-block;
					}
				}
			}

			#profile-img-dropdown {
				position: absolute;
				top: 90px;
				left: 10px;
				z-index: 1000;
				visibility: hidden;
				opacity: 0;
				transition: all ease 200ms;

				&.open {
					visibility: visible;
					opacity: 1;
				}

				&:before {
					content: "";
					position: absolute;
					width: 10px;
					height: 10px;
					transform: rotate(45deg);
					top: -5px;
					left: 28px;
					background-color: $white;
					z-index: 1000;
				}

				.dropdown-inner {
					background-color: $white;
					box-shadow: 0 1px 5px rgba($black, 0.5);
					border-radius: $border-radius;
					overflow: hidden;
				}

				.dd-row {
					padding: 0 10px;
					font-size: 14px;
					line-height: 30px;
					height: 30px;
					border-bottom: 1px solid $grey-4;
					white-space: nowrap;
					cursor: pointer;
					transition: all ease 200ms;
					position: relative;
					z-index: 1000;

					&:hover {
						background-color: $bright-blue;
						color: $white;
					}

					&:last-child {
						border-bottom: none;
					}

					#file-uploader {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						opacity: 0;
						cursor: pointer;
						width: 100%;
					}
				}
			}

			.image-placeholder {
				position: absolute;
				top: 30px;
				left: 40px;
				height: 90px;

				.profile-img {
					position: relative;
					width: 100%;
					height: 100%;

					.edit-curtain {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						visibility: hidden;
						opacity: 0;
						transition: all ease 200ms;
						background-color: rgba($black, 0.5);
						cursor: pointer;
						color: $white;
						text-align: center;
						line-height: 90px;
						border-radius: 100%;
					}

					&:hover .edit-curtain {
						visibility: visible;
						opacity: 1;
					}
					.dd-row {
						position: absolute;
						z-index: 1;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
					}
					.dd-row input {
						position: absolute;
						width: 100%;
						height: 100%;
						opacity: 0;
						cursor: pointer;
					}
				}
			}

			.icon-background {
				position: absolute;
				top: 0;
				right: 50px;
				font-size: 280px;
				z-index: 2;
				color: rgba($bright-blue, 0.4);
			}

			.password-form {
				display: none;

				&.is-open {
					display: block;
				}

				input {
					margin-bottom: 10px;
				}

				.pw-input {
					position: relative;

					.Icon {
						display: none;
						position: absolute;
						top: 33px;
						right: 14px;
						color: $white;
						font-size: 16px;
					}

					&.weak {
						.Icon {
							display: block;
						}

						input {
							border-color: $red !important;
							border-right-width: 3em !important;
						}
					}

					&.good {
						.Icon {
							display: block;
						}

						input {
							border-color: $yellow !important;
							border-right-width: 3em !important;
						}
					}

					&.strong {
						.Icon {
							display: block;
						}

						input {
							border-color: $bright-green !important;
							border-right-width: 3em !important;
						}
					}

					&.ok {
						.Icon {
							display: block;
						}

						input {
							border-color: $bright-green !important;
							border-right-width: 3em !important;
						}
					}

					&.no {
						input {
							border-color: $red !important;
						}
					}
				}

				.password-strength-text {
					display: block;
					margin-bottom: 10px;
					font-size: 12px;
				}

				button {
					margin: 10px 0 0;
				}
			}

			.TurnOffBisnode {
				display: flex;
				padding: 6px 0;
				font-size: 12px;
				line-height: 1.2em;
				align-items: center;
			}
		}

		#admin-page-products {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			overflow: hidden;
			overflow-y: auto;

			.ListViewActions {
				z-index: 1000;
			}

			.admin-products-active-toggle {
				display: inline-block;
			}

			.admin-table-row {
				display: flex;
			}

			.admin-table-cell.checkbox-col > div {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
			}

			.admin-table-cell.name-col {
				flex: 1;

				.name-inner {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					.Icon {
						margin-left: 10px;
					}
				}
			}

			.admin-table-cell.price-column {
				.price {
					margin-right: 20px;
				}
			}
		}

		.admin-products-filters {
			display: flex;
			column-gap: 16px;
			margin-bottom: 20px;

			.admin-products-filter {
				display: flex;
				column-gap: 8px;

				.Checkbox {
					border: 1px solid $grey-6;
				}
				.Label {
					line-height: unset;
					margin-bottom: unset;
					height: 100%;
					display: flex;
					align-items: center;
				}
			}
		}

		#admin-page-products,
		#admin-page-users-roles {
			#admin-content {
				.admin-table {
					.admin-table-body {
						position: relative;
						z-index: 5;

						.admin-table-row {
							position: relative;
							z-index: 10;
							height: 40px;

							.admin-table-cell {
								display: table-cell;

								&.inactive {
									color: $grey-8;
								}

								&.handle {
									padding-right: 20px;
								}
							}

							&.closed {
								height: 0;
								opacity: 0;
								visibility: hidden;
								border: none;
							}
							&.admin-table-row-user-inactive {
								background-color: $grey-6;

								.user-name-col .user-name-inner {
									color: $grey-12;
									font-style: italic;
								}
							}
							.edit-icon {
								transition: all ease 200ms;
								visibility: hidden;
								opacity: 0;
								position: absolute;
								right: 10px;
								height: 100%;
								line-height: 40px;
								font-size: 12px;
							}

							&:hover {
								.edit-icon {
									visibility: visible;
									opacity: 1;
								}
							}

							.actions {
								position: absolute;
								right: 10px;
								height: 100%;
								line-height: 40px;
								display: flex;
								justify-content: center;
								align-items: center;

								.export-button {
									font-size: 18px;
									cursor: pointer;
									color: #4a90e2;
									&.disabled {
										color: $grey-10;
										cursor: auto;
									}
								}
							}

							&.is-header {
								background-color: $grey-4;
								position: relative;
								height: 34px;
								line-height: 34px;

								&.maxWidth {
									max-width: 355px;
									text-overflow: ellipsis;
									overflow: hidden;
									height: 50px;
									white-space: nowrap;
								}

								&.delete-btn {
									display: inline-block;
									position: absolute;
									top: 0;
									right: 0;
								}
							}

							.user-name-col {
								position: absolute;
								display: inline-block;
								left: 0;
								right: 150px;
								overflow: hidden;
								height: 100%;

								.user-name-inner {
									display: block;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.generic-avatar {
									margin-right: 10px;
								}
							}

							&.closed {
								height: 0;
								opacity: 0;
								visibility: hidden;
								border: none;
							}

							.user-name-inner {
								.email {
									color: $grey-10;
									font-size: 12px;
									margin-left: 5px;
								}
							}

							.user-edit-icon {
								transition: all ease 200ms;
								visibility: hidden;
								opacity: 0;
								position: absolute;
								right: 10px;
								height: 100%;
								line-height: 40px;
								font-size: 12px;
							}

							.is-admin-col {
								position: absolute;
								right: 0;
								top: 0;
								width: 200px;
								text-align: right;
								padding-right: 35px;
								color: $grey-10;
								font-size: 12px;
								font-weight: $regularFontWeight;
								text-transform: uppercase;
							}

							&:hover {
								.user-edit-icon {
									visibility: visible;
									opacity: 1;
								}
							}

							&.is-header {
								background-color: $grey-4;
								position: relative;
								height: 34px;
								line-height: 34px;
								position: sticky;
								z-index: 1000;
								top: 0;
								left: 300px;
								right: 15px;

								.role-name,
								.name {
									position: absolute;
									display: inline-block;
									left: 0;
									right: 50px;
									overflow: hidden;
									height: 100%;

									.name-inner,
									.role-name-inner {
										display: inline-block;
										max-width: calc(100% - 60px);
										overflow: hidden;
										text-overflow: ellipsis;
									}
								}

								.header-title {
									position: absolute;
									right: 0;
									left: 0;
									top: 0;
									margin-left: 20px;
								}

								.count,
								.user-count {
									color: $grey-10;
									margin-left: 10px;
									display: inline-block;
									vertical-align: top;

									.Icon {
										margin-right: 5px;
										font-size: 12px;
									}
								}

								.delete-btn {
									transition: all ease 200ms;
									visibility: hidden;
									opacity: 0;
									position: absolute;
									right: 0;
								}

								.Icon-edit {
									transition: all ease 200ms;
									visibility: hidden;
									opacity: 0;
									position: absolute;
									right: 35px;
									height: 100%;
									line-height: 34px;
									font-size: 12px;
									color: $grey-10;
									top: 1px;
								}

								&:hover {
									.Icon-edit,
									.delete-btn {
										visibility: visible;
										opacity: 1;
									}
								}

								.admin-table-cell {
									height: 34px;
									line-height: 34px;
									color: $grey-11;
									font-size: 14px;

									&:first-child {
										.Icon {
											cursor: pointer;
										}
									}
								}
							}

							.tooltip-wrapper {
								position: absolute;
								top: 0px;
								right: 0;
								height: 100%;
								width: 30px;

								.Tooltip {
									display: block;
									height: 100%;
								}

								.delete-btn {
									position: relative;
								}
							}
						}
					}
				}
			}
		}
		#admin-page-users-roles {
			#admin-content {
				.pull-right {
					button.up-btn {
						margin: 0 0 0 1em;
					}
				}
			}

			.up-modal.open {
				display: block;
				top: 150px;

				.up-modal-header {
					h2 {
						opacity: 1;
					}
				}
			}
		}

		#admin-page-editscript {
			background-color: $black;
			color: $white;
			position: fixed;
			top: $main-top - $sub-menu-height;
			left: $sidebar-width + $admin-sidebar-width + 5;
			right: $padding;
			bottom: $padding;
			z-index: 1000;
			visibility: hidden;
			@include animation(script_fullscreen_animation_out 0ms);

			@include keyframes(script_fullscreen_animation_out) {
				0% {
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: $modal-z - 1;
				}
				100% {
					top: $main-top - $sub-menu-height;
					left: $sidebar-width + $admin-sidebar-width + 5;
					right: $padding;
					bottom: $padding;
					z-index: $modal-z - 1;
				}
			}

			@include keyframes(script_fullscreen_animation) {
				0% {
					top: $main-top - $sub-menu-height;
					left: $sidebar-width + $admin-sidebar-width + 5;
					right: $padding;
					bottom: $padding;
					z-index: $modal-z - 1;
				}
				100% {
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: $modal-z - 1;
				}
			}

			&.visible {
				visibility: visible;
				animation-duration: 200ms;
			}

			&.fullscreen {
				z-index: $modal-z - 1;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				@include animation(script_fullscreen_animation 200ms);
			}

			#edit-script-header {
				padding: 20px;
				padding-top: 15px;

				.Breadcrumbs {
					margin-bottom: 10px;
				}

				h2 {
					color: $white;
					margin: 0;
					display: block;
					margin-bottom: 10px;

					.fa-question-circle {
						margin-left: 10px;
					}
				}

				#fullscreen-toggle,
				#script-active {
					position: absolute;
					top: 0;
					height: 40px;
					background-color: transparent;
					border: none;
					color: $grey-10;
				}

				#script-active {
					right: 60px;
					height: 40px;
					line-height: 40px;

					label {
						margin-right: 10px;
						font-weight: normal;
						font-size: 14px;
					}
				}

				#fullscreen-toggle {
					right: 0;
					width: 50px;
					font-size: 18px;
					@include transition(all ease 200ms);

					&:hover {
						color: $white;
					}
				}

				#script-info-btn {
					display: block;
					cursor: pointer;
					background-color: transparent;
					border: none;
					color: $grey-10;
					@include transition(all ease 200ms);
					padding: 0;
					font-size: 12px;

					&:hover {
						color: $white;
					}
				}

				.script-name,
				.script-trigger {
					display: inline-block;
					vertical-align: top;
				}

				.script-name {
					width: 50%;
					font-size: 16px;

					.floating-label-input.has-value > label {
						bottom: 35px;
					}

					label {
						color: $grey-10;
						display: block;
						font-weight: normal;
						font-size: 14px;
					}

					input {
						border-bottom-color: $grey-10;
						color: $white;
						font-size: 14px;
						background-color: $grey-13;
						border: 1px solid $grey-13;
						display: block;
						height: 34px;
						@include border-radius(3px);
						width: 100%;
						padding: 0 15px;
						outline: none;

						&.has-error {
							background-color: rgba($red, 0.1);
							border-color: rgba($red, 0.2);
						}
					}
				}

				.script-trigger {
					width: 50%;
					padding-left: 10px;

					label {
						color: $grey-10;
						display: block;
						font-weight: normal;
						font-size: 14px;
					}

					.select2-choice {
						background-color: $grey-13;
						border-color: $grey-13;

						&.select2-default .select2-chosen {
							color: $grey-10;
							font-style: italic;
						}

						.select2-chosen {
							color: $white;
						}
					}

					.select2-arrow {
						border-left-color: $black;
					}
				}
			}

			#editor-wrap {
				position: absolute;
				top: 155px;
				bottom: 0;
				left: 0;
				right: 0;
				height: initial;
			}
		}

		#admin-page-fields {
			.truncate {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.admin-table-cell.checkbox-col {
				display: inline-flex !important;
				align-items: center;
			}

			.icon-holder {
				display: inline-block;
				text-align: left;
				padding-right: 5px;

				.icon,
				.Icon {
					color: $green;
					font-weight: $boldFontWeight;
					letter-spacing: -0.07em;
					width: 30px;
					display: inline-block;

					&.inactive {
						color: $grey-8;
					}
				}
			}

			span.tab {
				background: rgba($bright-blue, 0.2);
				padding: 3px 5px;
				color: $bright-blue;
				font-size: 12px;
				line-height: 16px;
				border-radius: $border-radius;
			}

			span.tab + span.tab {
				margin-left: 5px;
			}

			span.tab-more {
				line-height: 20px;
				display: inline-block;
				font-size: 12px;
				margin-left: 4px;
				color: $bright-blue;
			}

			.slide-down {
				width: 100%;

				.address-row {
					background-color: $grey-3 !important;

					.admin-table-cell:first-child {
						padding-left: 40px !important;
					}
				}
			}
		}

		.admin-page-fields2 {
			.admin-table-row.custom-fields,
			.admin-table-header.custom-fields {
				display: grid;
				grid-template-columns: 35px 1.5fr 150px 1fr 45px 60px;
			}
		}

		.AdminOrderStages {
			.AdminOrderStages__Progress {
				height: 5px;
				margin-top: 3px;
			}

			.AdminOrderStages__table-tools-inner {
				position: relative;
				visibility: hidden;
				@include transition(all ease 200ms);
				@include opacity(0);
				display: inline-block;
				right: -100%;
			}

			.AdminOrderStages__table tr:hover .AdminOrderStages__table-tools-inner {
				visibility: visible;
				@include opacity(1);
				right: 0;
			}

			.AdminOrderStages__table-name {
				width: 170px;
			}

			.AdminOrderStages__table-exclude {
				width: 70px;

				.Help {
					display: inline-block;
					margin-left: 5px;
				}
			}

			.AdminOrderStages__table-roles {
				.role-select-wrap {
					display: block;
					line-height: 12px;
					margin-bottom: 0px;

					.select2-container {
						box-shadow: none;
						max-width: 260px;
						z-index: 1000;

						.select2-choices {
							height: 100% !important;
							overflow-y: auto;
						}
					}
				}
			}

			.AdminOrderStages__table-probability {
				width: 130px;
			}

			.AdminOrderStages__table-tools {
				width: 160px;
				overflow: hidden;
			}

			.AdminOrderStages__exclude-label {
				color: $grey-10;
			}

			.AdminOrderStages__exclude-label + div {
				display: inline-block;
			}

			.floating-label-input {
				padding-top: 18px;
				margin: 0;
			}

			#s2id_admin-stages-role-input {
				max-width: 196px !important;
			}
		}

		#admin-page-edit-trigger {
			$line-width: 3px;
			.dontexec {
				box-shadow: inset 4px 0 $orange;
				.Tooltip {
					margin-right: 10px;
				}
			}
			.admin-page-header {
				padding: 15px 20px 20px;

				.Breadcrumbs {
					margin-bottom: 10px;
					padding: 0;
				}

				.trigger-delete {
					right: 40px;
					position: absolute;
					top: 15px;
				}

				.trigger-active {
					margin-top: 8px;

					label {
						margin-right: 10px;
						font-weight: $regularFontWeight;
						font-size: 14px;
					}
				}

				&__header-form {
					max-width: 850px;
				}
			}

			.line-point {
				$size: 10px;
				position: absolute;
				left: 0;
				top: 12px;
				background-color: $grey-8;
				height: $size;
				width: $size;
				@include border-radius($size);
			}

			.line,
			.vert-line {
				position: absolute;
				background-color: $grey-8;
			}

			.line {
				position: absolute;
				width: 20px;
				top: 16px;
				left: 4px;
				height: $line-width;
			}

			.vert-line {
				top: 17px;
				width: $line-width;
				left: 3px;
				height: 200px;
			}

			.name-active-card {
				margin-top: 0px;
				margin-bottom: 30px;
			}

			#trig-trigger-setting {
				position: relative;
				padding-left: 30px;
				height: 34px;
				display: flex;

				.trigger-of {
					flex: 1;
					line-height: 34px;
					text-align: center;
				}

				.vert-line {
					height: 100px;
				}

				.event-buttons {
					margin-left: 10px;
					display: inline-block;

					.Button {
						&:not(:last-child) {
							margin-right: 10px;
						}

						.Icon-stack {
							font-size: 8px;
							text-shadow: none;
							top: -2px;
							margin-right: 4px;
						}

						.Icon-check {
							top: 1px;
						}
					}
				}

				#entity-select {
					flex: 3;
					display: inline-block;
					vertical-align: middle;
				}
			}

			.admin-table {
				position: relative;
				margin-left: 30px;
				margin-top: 40px;

				.vert-line {
					left: -27px;
					height: initial;
					bottom: -65px;
				}

				.line {
					left: -26px;
					width: 26px;
					top: 32px;
				}

				.add-criteria {
					text-align: left;
					padding: 0 20px;
					color: $bright-blue;
				}

				.criteria-row,
				.action-row {
					position: relative;
					height: 40px;
					line-height: 40px;
					padding: 0 35px 0 15px;

					.delete-trigger {
						position: absolute;
						right: 10px;
						top: 5px;

						b {
							font-size: 14px;
						}
					}
				}

				.criteria-row.unsupported-criteria {
					background-color: $super-light-red;

					.attr-select {
						width: 100%;
					}
				}

				.action-row {
					cursor: pointer;
					background-color: $white;
					@include transition(background ease 200ms);

					.fa-question-circle {
						margin-left: 5px;
					}

					&:hover {
						background-color: $grey-2;
					}
				}

				.criteria-row {
					.select2-number-suggestion {
						position: relative;

						input {
							position: absolute;
							top: 1px;
							left: 1px;
							height: calc(100% - 2px);
							width: calc(100% - 20px);
							border: 0;
							box-shadow: none;
						}
					}

					.operator-select,
					.value-select,
					.attr-select {
						width: 33%;
						display: inline-block;
						padding: 0 5px;
						vertical-align: middle;
					}

					.attr-select {
						.select2-container.select2-dropdown-open {
							width: 200%;
							z-index: 1000;
						}
					}

					.boolean-critera {
						width: 100%;
						position: relative;
						top: -1px;

						.btn {
							width: 50%;
						}
					}

					&.btn-operator {
						.attr-select {
							display: inline-flex;

							.select2-container.select2-dropdown-open {
								position: absolute;
								z-index: 1000;
								left: 85px;
								right: 40px;
								width: auto;
							}

							.select2-choice {
								border-bottom-left-radius: 0;
								border-top-left-radius: 0;
								border-left: 0;
							}

							button {
								border-bottom-right-radius: 0;
								border-top-right-radius: 0;
								width: 65px;
							}

							.attr-select-select-wrap {
								flex: 1;
							}
						}

						.value-select,
						.attr-select {
							width: 50%;
						}
					}
				}
			}

			#action-info {
				position: relative;
				margin-top: 30px;
				margin-left: 30px;
				margin-bottom: 0;

				.automation-job-type-wrapper {
					position: absolute;
					top: 20px;
					right: 0;

					color: $grey-10;

					span {
						cursor: pointer;
					}
				}
			}

			#action-table {
				margin-bottom: 40px;
				margin-top: 30px;
				margin-left: 30px;

				.vert-line {
					top: -110px;
					bottom: initial;
					height: 132px;
				}
				.line-point {
					left: -30px;
					top: 16px;
				}
			}
		}

		#admin-page-doctemplates {
			.document-row {
				cursor: pointer !important;
			}

			.document-row:hover {
				background-color: $grey-1;
			}

			.document-tagselect-container {
				line-height: 35px;

				.document-tagselect-button:not(:last-child) {
					margin-right: 10px;
				}
			}

			.document-cell-ellipsis {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.document-cell-tools {
				white-space: nowrap;
				text-align: right;
				padding-right: 10px;

				.icon-wrap {
					width: 30px;
					text-align: center;
					height: 100%;
					line-height: 40px;
					display: inline-block;
					color: $grey-10;

					a {
						color: $grey-10;
					}

					&:hover {
						color: $grey-13;

						a {
							color: $grey-13;
						}
					}
				}
			}

			.template-download-wrap {
				display: inline-block;
				vertical-align: top;
				width: 50%;
			}

			.doc-template {
				display: block;
				background: $white;
				padding: 1em;
				box-shadow: 0 1px 1px rgba($black, 0.1);
				margin-right: 10px;
				margin-bottom: 20px;
				border: 1px solid rgba($black, 0.1);
				border-radius: $border-radius;
				text-decoration: none;
				@include transition(all ease 200ms);

				&:hover {
					background-color: $grey-1;

					> i.fa {
						top: 13px;
					}
				}

				> i.fa {
					font-size: 44px;
					float: left;
					margin-right: 15px;
					color: $grey-6;
					@include transition(all ease 200ms);
					position: relative;
					top: 8px;
				}

				> .row {
					margin: 0;

					.doctemp-dl {
						font-size: 12px;
						color: $grey-8;
						margin: 5px 0 0;
						text-transform: uppercase;
					}

					.doctemp-name {
						font-size: 24px;
						color: $grey-10;
						margin: 0;
					}
				}
			}

			.document-templates-custom {
				overflow: initial !important;

				.inline-confirm-modal,
				.inline-confirm-modal-hidden {
					width: 37px;

					.fa-spinner {
						margin-right: 6px;
					}
				}

				.admin-row-tools {
					visibility: hidden;
					opacity: 1;
					transition: none;
					right: 0;
					.up-btn {
						transition: none !important;
					}
				}
			}

			.document-templates-custom:hover .admin-row-tools {
				visibility: visible;
			}
		}

		#admin-page-udo {
			.floating-label-input {
				padding-top: 18px;
			}

			.fake-floating-label {
				position: absolute;
				font-size: 1em;
				color: $grey-10;
				transition: all ease 0.2s;
				top: 26px;
				left: 23px;

				&.has-value {
					font-size: 12px;
					top: 8px;
					left: 23px;
				}
			}

			.udo-type-select {
				width: 100%;
				top: -2px;

				.select2-choice {
					background: 0 0;
					border: none;
					display: block;
					margin: 0;
					padding: 0;
					border-radius: 0;
					border-bottom: 1px solid $grey-9;
					outline: 0;
				}

				.select2-chosen {
					position: relative;
					top: 8px;
					padding-left: 3px;
					display: block;
				}

				.select2-default {
					.select2-chosen {
						display: none;
					}
				}

				.select2-search-choice-close {
					margin-top: 0px;
				}

				.select2-arrow {
					border-left: 0;
					top: 7px;
				}

				button {
					visibility: visible;
					width: 120px;
					overflow: hidden;
					@include transition(all ease 200ms);
					margin-left: 15px;
					margin-top: 4px;

					&.btn-hidden {
						visibility: hidden;
						width: 0;
						margin-left: 0;
						padding: 0;
					}
				}

				&.select2-dropdown-open {
					.select2-choice {
						border-bottom-color: $bright-blue;
					}
				}
			}

			.trash-wrap {
				width: 50px;
				float: right;
				margin-right: -20px;
				text-align: center;
				color: $grey-10;
				cursor: pointer;

				&:hover {
					color: $grey-13;
				}
			}

			.udo-table-inline-tr {
				.udo-table-inline-td {
					padding-right: 0;
					padding-top: 8px;

					&-3 {
						position: relative;

						.btn-wrap {
							position: absolute;
							top: 10px;
							right: 2px;
						}
					}
				}
			}

			.admin-row-tools-wrap {
				padding-left: 80px !important;
			}

			.udo-table-td {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				line-height: 40px;
			}

			.udo-table-th {
				&-1 {
					width: 50%;
				}
				&-2 {
					width: 30%;
				}
				&-3 {
					width: 20%;
				}
				&-4 {
					width: 140px;
				}
			}

			.udo-tr-custom-delete {
				overflow: initial !important;
				.inline-confirm-modal {
					width: 34px;
				}
				.admin-row-tools {
					visibility: hidden;
					opacity: 1;
					transition: none;
					right: 0;
					.up-btn {
						transition: none !important;
					}
				}
			}

			.udo-tr-custom-delete:hover .admin-row-tools {
				visibility: visible;
			}
		}

		#admin-page-ipblock {
			.invalid-input {
				color: $red;
			}

			.ip-block-textarea {
				.selct2-holder {
					padding: 0 1em;

					.select2-container {
						> .select2-choices {
							min-height: 200px !important;

							.select2-search-field {
								input {
									min-width: 150px;
								}
							}
						}
					}
				}
			}
		}

		#admin-page-activity-types {
			#admin-content {
				.enableAutoFillAppointments {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex-direction: row-reverse;
					padding-top: $space;
					padding-bottom: $space * 3;
				}
			}

			.floating-label-input {
				float: left;
			}

			.input-checkbox {
				padding-top: 2px;
				float: left;
			}

			.admin-table-row {
				height: 40px;
			}

			.checkbox-column {
				width: 20px;
				padding: 0 !important;
				text-align: center;

				input {
					visibility: visible;
					width: 15px;
					@include opacity(1);
					@include transition(all ease 400ms);
				}

				&.checkbox-hidden input {
					visibility: hidden;
					@include opacity(0);
				}
			}

			.merge-wrap {
				color: $grey-11;
				font-size: 16px;
				line-height: 20px;
				text-align: left;
				padding: 0 20px 20px 20px;

				.merge-description {
					margin-bottom: 10px;
				}

				.merge-item {
					display: inline-block;
					width: 200px;
					margin-right: 10px;
					margin-bottom: 20px;
				}

				label {
					color: $grey-13;
					font-size: 14px;
					font-weight: $regularFontWeight;
					line-height: 17px;
					text-align: left;
					margin-bottom: 5px;
				}

				input {
					max-width: 410px;
				}

				.btn-wrap {
					margin-top: 20px;
				}
			}

			.table-footer {
				overflow: hidden;
				text-align: center;
				height: 100px;
				margin-top: 20px;
				margin-bottom: 20px;
				color: $grey-11;
				@include opacity(1);
				visibility: visible;
				@include transition(all ease 400ms);

				&.footer-hidden {
					visibility: hidden;
					margin-top: 0px;
					height: 0;
					@include opacity(0);
				}

				.footer-wrapper {
					width: 500px;
					margin: 0 auto;
					overflow: hidden;
					margin-top: 10px;
				}

				.name-selector {
					float: left;
					width: 250px;
				}

				.btn-wrap {
					float: right;
					width: 250px;
					margin-top: 10px;
				}
			}

			.activity-types-merge-link {
				cursor: pointer;
				padding-right: 20px;
			}

			.activity-type-table-inline-tr {
				background-color: $grey-2;

				.has-error {
					input {
						color: $red;
						border-bottom-color: $red;
					}
				}

				.activity-types-table-inline-td {
					padding-right: 0;
					padding-top: 8px;
					vertical-align: top;

					&-1 {
						&.colorPad-td {
							overflow: visible;
							width: auto;
							.colorPad-wrapper {
								display: flex;
								align-items: center;
								height: 90%;
								.ColorSelect__RealColor {
									height: 25px;
									width: 90px;
								}
								div {
									.ColorSelect__PickerHolder {
										top: 90%;
										left: 0;
									}
								}
							}
						}
					}
					&-2 {
						width: 90px;

						.btn-wrap {
							float: right;
							padding-top: 3px;
						}
					}
					&-3 {
						overflow: unset;
						width: 55%;
						padding-bottom: 8px;

						.role-select-wrap {
							display: block;
							margin-bottom: 8px;
							width: 100%;
							line-height: 12px;
							margin-bottom: 0px;

							.role-select-text {
								cursor: text;
								padding-left: 3px;
								width: 100%;
								font-size: 12px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								display: inline-block;
								padding-bottom: 3px;
								border-bottom: 1px solid $grey-8;
							}

							.select2-container {
								box-shadow: none;
								max-width: 260px;
								z-index: 1000;

								.select2-choices {
									height: 100% !important;
									overflow-y: auto;
								}
							}
						}
					}
				}
			}

			.activity-types-table-td {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				cursor: pointer;

				&-1 {
					width: auto;
					.colorPad {
						height: 20px;
						border-radius: $border-radius;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 90px;
						box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.4);
					}
				}
				&-2 {
					width: 90px;
				}
				&-3 {
					width: 55%;
				}
				&-4 {
					text-align: right;
					color: $grey-10;
					width: 60px;

					&:hover {
						color: $grey-13;
					}
				}

				span.tab {
					background: rgba($bright-blue, 0.2);
					padding: 3px 5px;
					color: $bright-blue;
					font-size: 12px;
					line-height: 16px;
					border-radius: $border-radius;
				}

				span.tab + span.tab {
					margin-left: 5px;
				}

				span.tab-more {
					line-height: 20px;
					display: inline-block;
					font-size: 12px;
					margin-left: 4px;
					color: $bright-blue;
				}
			}

			.activity-types-table-th {
				&-1 {
					width: auto;
				}
				&-2 {
					width: 90px;
				}
				&-3 {
					width: 280px;
				}
				&-4 {
					width: 130px;
				}
				.Help {
					display: inline-block;
				}
			}
		}

		#admin-page-email-settings {
			#admin-content {
				.admin-table {
					table {
						tbody,
						thead {
							tr {
								td {
									&.valid,
									&.not-valid {
										padding: 0 0 0 5px;
									}
								}

								th {
									&:first-child {
										padding: 0 0 0 5px;
									}
								}
							}
						}
					}
				}
			}

			.emails-limit {
				display: flex;
				align-items: center;
				gap: 8px;
				h2 {
					margin-bottom: 0px;
					font-size: 16px;
				}
			}

			.section-row {
				width: 100px;
			}

			.NumberInput {
				width: 70px;
			}

			.input-form-control {
				width: 100px;
			}

			.card {
				background-color: $white;
				-webkit-box-shadow: 0 0.5px 1px 1px rgba($black, 0.075);
				box-shadow: 0 0.5px 1px 1px rgba($black, 0.075);
				position: relative;
				border-radius: $border-radius;
				display: block;
				margin-bottom: 20px;
				padding: 1px 20px 20px;

				.row {
					margin-left: -8px;
					margin-right: -8px;

					.col-md-1,
					.col-md-10,
					.col-md-11,
					.col-md-12,
					.col-md-2,
					.col-md-3,
					.col-md-4,
					.col-md-5,
					.col-md-6,
					.col-md-7,
					.col-md-8,
					.col-md-9 {
						padding-left: 8px;
						padding-right: 8px;
					}
				}

				h1 {
					font-size: 24px;
					font-weight: normal;
					margin: 20px 0;
				}

				.right-block-quote {
					border-left: 4px solid $grey-4;
					padding: 10px 15px;
					margin-left: 15px;

					p {
						color: $grey-10;
						margin: 0;
					}
				}

				label {
					margin-top: 10px;
					line-height: 1.5em;
					font-weight: normal;
				}
			}

			#accept-term-body {
				.content {
					margin: auto;
					max-width: 700px;
					background-color: $white;
					@include box-shadow(0 1px 2px rgba($black, 0.5));
					padding: 40px 50px;
					margin-top: 30px;

					.btn {
						width: auto;
						margin: auto;
						margin-top: 20px;
						padding: 0px 40px;
					}
				}
			}

			.activate-btn {
				width: auto;
				padding: 0 110px;
				margin: auto;
			}

			.verified-text {
				display: block;
				font-weight: $regularFontWeight;
				color: $grey-11;
				text-transform: uppercase;
				font-size: 12px;
				margin: 3px 0 0 0;
			}

			.two-lines {
				line-height: 1.2em !important;
			}

			.valid {
				background-color: $bright-green;
			}

			.not-valid {
				background-color: $grey-8;
			}

			.section-content {
				label {
					margin-top: 10px;
					margin-bottom: 5px;

					.required-field {
						color: $dark-red;
						margin-left: 2px;
					}
				}

				.edit_buttons {
					button {
						float: right;
					}
				}

				.floating-label-input {
					label,
					input {
						margin-bottom: 0;
					}
				}

				textarea + p {
					margin-top: 1em;
				}
			}

			tr.editing {
				td {
					vertical-align: top;
					padding: 0 !important;

					tr {
						display: table;
						width: 100%;

						&:first-child {
							height: 52px;
							vertical-align: middle;
						}

						td {
							padding: 5px 0 5px 20px !important;
							vertical-align: middle;

							&:last-child {
								padding: 5px 20px !important;
							}
						}
					}

					tr + tr {
						border-top: 1px solid $grey-7;
						background: rgba($super-light-yellow, 0.4);
					}
				}

				.inside-holder {
					padding: 1em 0;

					> .close-button {
						float: right;
						cursor: pointer;
						color: $grey-11;
					}

					.dns {
						width: 100%;
						margin: 2em 0 0 0;

						.row {
							border-top: 1px solid $grey-2;
							height: 52px;
							margin: 0 -20px;

							> div {
								margin: 9px 0;
								padding: 0 20px;

								&.cname {
									margin: 13px 0;
									text-transform: uppercase;
								}
							}

							&:first-child {
								height: auto;
								font-size: 12px;
								border-top: none;

								> div {
									margin: 9px 0 0 0;
								}
							}

							&:last-child {
								border-bottom: 1px solid $grey-2;
							}
						}
					}
				}
			}

			.box {
				background: $white;
				border-radius: $border-radius;
				box-shadow: 0 1px 1px rgba($black, 0.1);
				padding: 1em;

				i.fa {
					font-size: 60px;
					color: $grey-4;
					float: right;
				}

				.floating-label-input {
					margin-bottom: 0;

					label {
						bottom: 35px;
						font-weight: $regularFontWeight;
						font-size: 14px;
					}

					input[type="text"] {
						font-size: 26px;
						border-bottom: 2px solid $grey-8;
					}

					&.blue-underline {
						input {
							border-bottom: 2px solid $bright-blue;
						}
					}

					&.color-red {
						input {
							color: $red;
						}
					}
				}
			}
		}

		#admin-page-activity-quota,
		#admin-page-sales-quota {
			position: fixed;
			top: $main-top;
			left: $sidebar-width + $admin-sidebar-width + 5;
			right: $padding;
			bottom: $padding;
			z-index: 1000;
			background-color: $grey-2;
			visibility: hidden;
			overflow-y: auto;

			.QuotaTableUserTableRow__Text {
				font-size: 10px;
				margin-left: 2px;
				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.QuotaTableUserTableRow__Icon {
				display: inline-block;
				cursor: pointer;
			}

			&.visible {
				visibility: visible;
			}

			&.fullscreen {
				z-index: $modal-z - 1;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				#admin-content {
					padding: 0;
				}

				.admin-page-header {
					display: none;
				}
			}

			#toggle-fullscreen {
				font-size: 18px;
				@include transition(all ease 200ms);
			}

			#admin-content {
				max-width: initial;
			}

			.hide {
				display: none !important;
			}

			.inline-select {
				display: inline-block;
				font-size: 10px;
				margin-top: 15px;
				cursor: pointer;
				color: $bright-blue;

				select {
					color: $bright-blue;
					background: transparent;
					border: none;
					outline: none;
					padding-left: 0;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					display: inline-block;
					position: relative;
					padding-right: 15px;
				}

				.fa {
					display: inline-block;
					margin-left: -10px;
				}
			}

			.admin-table {
				.table-tools {
					padding: 20px;

					.tool-filter {
						display: inline-block;
						width: 30.5%;
						max-width: 300px;
						vertical-align: top;

						&.quotaType {
							width: 23%;
							max-width: 200px;

							.btn-group {
								margin-left: 10px;
							}
						}

						&.year {
							width: 16%;
							max-width: 100px;
							&.new-calendar-select {
								width: auto;
								max-width: none;
							}
						}

						&.currency {
							position: absolute;
							right: 5px;
							top: 80px;
							width: auto;

							.Help {
								line-height: 34px;
								float: left;
								margin-right: 2px;
							}

							label {
								float: left;
								line-height: 34px;
							}

							.select2-container {
								float: right;
							}
						}

						&:not(:first-of-type) {
							label,
							.select2-container {
								margin-left: 10px;
								width: auto;
							}
						}

						label {
							color: $grey-10;
							font-size: 10px;
						}

						.select2-container {
							width: auto;
						}
					}

					.toggle-wrap {
						margin-top: 10px;

						label {
							display: inline-block;
							margin-left: 10px;
							font-size: 12px;
							color: $grey-13;
						}
					}
				}

				.upsales-loader {
					width: 70px;
					height: 70px;
					margin: 0px auto;
					padding: 10px;
				}

				.quota-data-table-wrap,
				.quota-user-table-wrap,
				.quota-total-table-wrap {
					position: relative;
					display: inline-block;
					overflow: hidden;
					white-space: nowrap;
					vertical-align: top;
					z-index: 1000;

					table {
						width: 100%;
					}

					tbody tr {
						background: $white;
						&:nth-child(even) {
							background: rgba($grey-1, 0.7);
						}
					}

					tbody tr td {
						height: 50px;
						line-height: 50px;
						border: none;
						padding: 0 20px;
					}

					.big-value {
						font-weight: $boldFontWeight;
						color: $grey-11;
						font-size: 16px;
					}

					.small-value {
						display: block;
						font-weight: $boldFontWeight;
						font-size: 12px;
						color: $grey-11;
					}

					.total-row {
						font-weight: $boldFontWeight;
						@include box-shadow(0 1px 6px 0 rgba($black, 0.3));

						color: $grey-11;
						font-size: 16px;

						.subtitle {
							display: block;
							font-weight: $boldFontWeight;
							font-size: 12px;
							color: inherit;
						}
					}

					tr.two-lines td {
						line-height: 25px;
					}
				}

				.quota-user-table-wrap {
					width: 25% !important;
					@include box-shadow(1px 0 6px 0 rgba($black, 0.3));
					border-top-right-radius: 3px;
					z-index: 1001;

					& > table {
						table-layout: fixed;
					}

					tbody tr {
						background: $white;
					}

					tbody tr {
						&:first-child {
							height: 51px; // sorry but first row becomes 51 in other tables for some reason
						}

						&.two-lines td,
						&.two-lines td .ellipsis {
							line-height: 25px;
						}
						.QuotaTableUserTableCell {
							width: 100%;
							position: relative;

							.ellipsis {
								width: auto;
								top: 0;
								left: 20px;
								right: 20px;
							}
							.QuotaTableUserTableRow {
								display: flex;
								align-items: flex-end;
							}
						}
					}
				}

				.quota-data-table-wrap {
					position: absolute;
					left: 25%;
					right: 130px;
					width: auto !important;
					overflow-x: scroll;

					& > table {
						table-layout: fixed;
						max-width: initial;
					}

					td,
					th {
						// Damn you tables
						min-width: 130px;
						max-width: 130px;
						width: 130px;
					}

					tbody tr {
						background: $grey-1;
						&:nth-child(even) {
							background: rgba($grey-4, 0.7);
						}
					}

					.subtitle {
						display: block;
						color: $grey-10;
						margin-top: -5px;
					}

					.apply-next,
					.subtitle {
						font-size: 10px;
						margin: 0;
						padding: 0;
						font-weight: $regularFontWeight;
					}

					.inline-edit {
						position: relative;
						line-height: 50px;
						color: $grey-13;

						&.two-lines {
							line-height: 25px;

							input {
								vertical-align: top;
							}
						}

						input {
							border: none;
							width: 100%;
							padding: 0;
							background: transparent;
							height: 25px;
							line-height: 25px;
							display: inline-block;
							border-bottom: 1px solid transparent;
							cursor: pointer;

							&:focus {
								border-bottom: 1px solid $bright-blue;
								outline: none;
							}
							&:disabled {
								color: $grey-10;
							}
						}

						.apply-next {
							height: 25px;
							display: inline-block;
							line-height: 25px;
							width: 25px;
							text-align: center;
							color: $grey-8;
							@include transition(all ease 200ms);
							font-size: 14px;

							&:hover {
								color: $bright-blue;
							}
						}
					}
				}

				.quota-total-table-wrap {
					position: absolute;
					width: 130px !important;
					right: 0;
					text-align: right;
					@include box-shadow(-1px 0 6px 0 rgba($black, 0.3));
					color: $grey-13;
					border-top-left-radius: 3px;
					z-index: 1001;

					& > table {
						table-layout: fixed;
					}

					th {
						text-align: right;
						padding-right: 20px;
						font-weight: $boldFontWeight;
					}

					td {
						position: relative;
					}

					.subtitle {
						font-size: 10px;
						margin: 0;
						padding: 0;
						font-weight: $regularFontWeight;
						display: block;
						color: $grey-10;
					}
				}

				.table-sub-info {
					width: 100%;
					padding: 10px 20px;
				}
			}
		}

		#admin-page-currencies {
			table {
				.inactive-row {
					border-bottom: 1px solid rgba($black, 0.05) !important;
				}

				.icon {
					margin-right: 15px;
					margin-top: -1px;
				}

				.no-icon {
					margin-left: 35px;
				}

				.name {
					margin-right: 10px;
				}

				.no-name {
					display: none;
				}

				.iso {
					color: $grey-10;
				}
			}

			.master-currency-card {
				padding-top: 20px;
				padding-bottom: 10px;

				.select2-container-disabled {
					.select2-arrow {
						display: none;
					}
				}

				.toggle-multicurrency {
					padding-left: 1px;
					margin-top: 20px;

					label {
						display: inline-block;
						margin-right: 10px;
					}
				}

				.master-currency-wrap {
					width: 300px;
					padding-top: 20px;
				}

				.title {
					font-size: 18px;
				}
			}

			.master-currency {
				color: $grey-10;
				font-size: 12px;
			}

			.floating-label-input.has-value {
				span,
				button {
					position: relative;
					font-size: 12px;
					line-height: 1.5em;
					top: 3px;
					padding: 0;
					vertical-align: middle;
				}
			}

			.autoupdate-toggle {
				display: inline-block;
				margin-right: 10px;

				label {
					line-height: 20px;
					font-size: 12px;
					display: inline-block;
				}

				.up-ios-toggle {
					margin-left: 5px;
				}
			}
		}

		#admin-page-delete-log {
			.ellipsis-td {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			#select2-drop {
				width: 500px !important;
			}

			td span {
				vertical-align: middle;
			}

			.loading-row > .upsales-loader {
				margin: 0 auto;
				width: 70px;
				height: 70px;
			}

			.no-result {
				color: $grey-11;
				margin: 0;
			}

			.icon-input {
				position: relative;

				& > .fa {
					position: absolute;
					top: 9px;
					left: 10px;
				}

				> input {
					width: 100% !important;
					padding-left: 30px;
					padding-right: 34px;
				}

				.clear-date {
					position: absolute;
					top: 0;
					right: 0;
					height: 34px;
					width: 34px;
					padding: 0;
					text-align: center;
					background-color: transparent;
					border: none;
					color: $grey-8;
					@include transition(all ease 200ms);
					font-size: 12px;

					&:hover {
						color: $black;
					}
				}
			}

			.admin-table-top {
				line-height: 1 !important;

				:last-child {
					margin-right: 0;
				}

				.table-search {
					display: inline-block;
					width: 19%;
					line-height: 1;

					input {
						width: 100% !important;
					}

					.fa {
						top: 9px !important;
					}
				}

				.filter-column {
					display: inline-block;
					width: 19%;
					margin-right: 1%;
				}

				.select2-offset {
					position: relative;
					top: 12px;
				}

				.date-column {
					width: 38%;
					display: inline-block;
					margin: 0 1%;

					.icon-input {
						width: 50%;
						display: inline-block;

						&:first-child input {
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}

						&:last-child input {
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
						}
					}
				}
			}
		}

		#admin-page-category {
			.admin-table-wrap {
				margin-bottom: 30px;
			}

			table {
				th:last-child {
					width: 60px;
				}

				.edit-row {
					height: 62px;
					background-color: $grey-1 !important;

					.floating-label-input {
						margin-bottom: 0;
					}

					td {
						padding-top: 10px !important;
						padding-bottom: 10px !important;
						vertical-align: top;
						overflow: unset !important;
					}

					.column-wrap {
						display: flex;

						.select-wrap {
							flex: 1;
							margin-right: 10px;
							position: relative;
							top: 2px;
						}

						.button-wrap {
							margin-left: auto;
							position: relative;
							top: -2px;
						}
					}
				}
			}

			.admin-table > table > tbody > tr.DefaultNoDataRow {
				background-color: $grey-1;
			}

			.extra-category-section {
				padding: 20px 0px;

				span:first-child {
					font-size: 20px;
				}
			}

			.edit-category-type {
				display: inline-block;
				width: 400px;
				position: relative;
				left: -5px;
			}

			.merge-wrap {
				padding: 10px 20px;

				.merge-description {
					margin-bottom: 10px;
					font-size: 16px;
				}

				.merge-item {
					width: calc(50% - 10px);
					display: inline-block;
					margin-bottom: 20px;
				}

				.merge-item-1 {
					margin-right: 10px;
				}

				label {
					color: $grey-13;
					font-size: 14px;
					font-weight: $regularFontWeight;
					line-height: 17px;
					text-align: left;
					margin-bottom: 5px;
				}

				input {
					max-width: 410px;
				}

				.btn-wrap {
					margin-top: 20px;
				}
			}
		}
	}
}

.edit-custom-fields-modal {
	.up-modal-header {
		height: 60px;
		line-height: 60px;

		h4.title {
			margin-top: 15px;
		}

		a.modal-close {
			color: $grey-10;
		}
	}

	.link-alike {
		color: $bright-blue;
		padding: 0 20px;
	}

	.select2-container-disabled {
		.select2-arrow {
			display: none;
		}
	}

	.textify {
		display: inline-block;
		padding: 0 20px;

		> p {
			display: inline-block;
			margin-right: 5px;
			margin-bottom: 0;
		}

		.select-textselect {
			padding: 3px 5px;
			border: 1px solid transparent;
		}

		.select2-dropdown-open {
			width: 200px;
			background: $white;
			border-color: $bright-blue;
			border-radius: $border-radius $border-radius 0 0;
		}

		.select2-search-choice-close {
			right: -2px;
			z-index: 10000;
		}

		.select2-dropdown-open {
			.select2-search-choice-close {
				display: none;
			}
		}

		a.select2-choice {
			height: auto !important;
			background: none;
			border: none;
			outline: none;

			.select2-chosen {
				padding: 0;
				padding-right: 12px;
				margin: 0;
				background: none;
				color: $bright-blue;
			}

			span.select2-arrow {
				opacity: 0;
			}
		}
	}

	.header {
		height: 60px;
		line-height: 55px;
		border-top: 5px solid $dark-blue;

		.title {
			font-size: 18px;
			margin-left: 20px;
		}

		a {
			position: absolute;
			right: 0;
			top: 16px;
		}
	}

	.type-select-container {
		.type-select {
			height: 75px;
			background-color: $white;
			width: 16.66667%;
			border: 1px solid rgba($black, 0.05);
			vertical-align: middle;
			text-align: center;

			.icon {
				color: $bright-blue;
				font-size: 24px;
				line-height: 26px;

				&.number {
					font-weight: $boldFontWeight;
				}

				&.yn {
					font-size: 16px;
					font-weight: $boldFontWeight;
				}
			}

			.text {
				margin-top: 5px;
				line-height: 1;
			}

			&.active {
				background-color: $bright-blue;
				border-color: $bright-blue;

				.icon,
				.text {
					color: $white;
				}
			}

			&:hover {
				background-color: $bright-blue;
				border-color: $bright-blue;

				.icon,
				.text {
					color: $white;
				}
			}

			&:first-child {
				border-bottom: none;
			}

			& + td.type-select {
				border-left-color: transparent;
				border-bottom: none;
			}

			& + td.type-select:hover {
				border: 1px solid rgba($white, 0.1);
			}
		}

		tr:last-child > td {
			border-bottom: 1px solid rgba($white, 0.1) !important;
		}
	}

	.type-options-container {
		background-color: $grey-2;

		&:after {
			clear: both;
			content: "";
			display: block;
		}
	}

	.up-modal-controls {
		background-color: $white;
	}

	.up-modal-content {
		overflow-y: hidden;

		.pre-defined-fields {
			height: 34px;
			line-height: 40px;
			margin-bottom: 20px;
			padding-top: 5px;

			div.left {
				line-height: 14px;
				padding-left: 20px;
			}

			div.right {
				margin-top: -5px;
				padding-right: 20px;
			}

			span.paragraph {
				color: $grey-10;
				font-size: 12px;
			}
		}

		label {
			font-weight: $regularFontWeight;
			font-size: 14px;
			color: $grey-11;
			display: block;
		}

		#editor {
			height: 250px;
			border: 1px solid $grey-8;
			border-radius: $border-radius;

			.ace_gutter {
				background: $grey-1;
				border-right: 1px solid $grey-7;

				.ace_gutter-layer .ace_gutter-cell {
					padding-left: 9px;
					color: $grey-10;
				}
			}

			.ace_scroller .ace_text-layer {
				padding: 0 6px;
			}

			.ace_gutter-active-line {
				background-color: $grey-1;
			}
		}
	}
}

.up-modal-admin {
	.admin-table {
		background-color: $white;
		box-shadow: 0px 1px 2px 2px rgba($black, 0.075);
		position: relative;
		border-radius: $border-radius;
		width: 100%;

		& > table {
			width: 100%;
		}

		.clickable {
			@include transition(background ease 200ms);
			cursor: pointer;

			&:hover {
				background-color: $grey-2;
			}
		}

		.admin-table-top {
			vertical-align: middle;
			padding: 10px 20px;
			line-height: 50px;

			.admin-table-title {
				font-size: 18px;
			}

			.table-search {
				position: relative;

				input {
					height: 32px;
					background-color: $grey-2;
					border: none;
					outline: none;
					padding-left: 30px;
					@include border-radius(3px);
					@include transition(all ease 200ms);
					width: 150px;

					&:focus {
						@media (min-width: 1200px) {
							width: 200px;
						}
						background-color: $grey-4;
					}
				}

				.fa-search {
					position: absolute;
					left: 10px;
					top: 18px;
					color: $grey-10;
				}
			}
		}

		& > table > thead > tr,
		.admin-table-header {
			border-bottom: 1px solid rgba($black, 0.05);

			& > th,
			.admin-table-cell {
				height: 25px;
				line-height: 25px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				text-transform: uppercase;
				color: $grey-11;
				font-weight: normal;
				font-size: 12px;
				padding-left: 20px;

				&.sortable {
					cursor: pointer;
				}

				.fa-caret-down {
					visibility: hidden;
					@include opacity(0);
					@include transition(all ease 200ms);
				}

				&.active {
					color: $bright-blue;

					.fa-caret-down {
						visibility: visible;
						@include opacity(1);
						@include transform(rotate(0deg));
					}

					&.desc .fa-caret-down {
						@include transform(rotate(180deg));
					}
				}
			}
		}

		& > table > tbody > tr,
		.admin-table-row {
			cursor: default;
			border-bottom: 1px solid rgba($black, 0.05);
			background-color: $white;
			@include transition(height 0.5s);

			&.inactive-row {
				background-color: $grey-1;
			}

			.delete-btn:hover {
				visibility: visible;
			}

			&:hover {
				.admin-row-tools-wrap .admin-row-tools {
					visibility: visible;
					@include opacity(1);
					right: 0;
				}
			}
			.admin-row-tools-wrap {
				overflow: hidden;

				.admin-row-tools {
					position: relative;
					visibility: hidden;
					@include transition(all ease 200ms);
					@include opacity(0);
					right: -100%;
				}
			}
		}

		.table-no-result {
			text-align: center;
			line-height: 40px;
			height: 20px;
			font-size: 20px;
			color: $grey-8;
			position: relative;
			top: -20px;
		}

		.table-show-more {
			border: none;
			display: block;
			width: 100%;
			height: 40px;
			line-height: 40px;
			padding: 0;
			background-color: $grey-1;
			color: $grey-8;
			outline: none;
			@include transition(all ease 200ms);

			&:hover {
				background-color: $grey-4;
				color: $bright-blue;
			}
		}

		& > table > tbody > tr > td,
		.admin-table-cell {
			padding: 0 20px;
			font-size: 14px;

			&.checkbox-row {
				text-align: center;

				&:first-child {
					text-align: left;
				}
			}

			.subtitle {
				color: $grey-10;
				line-height: 20px;
				font-size: 12px;
			}

			&.no-padding-right {
				padding-right: 0;
			}
		}

		.admin-table-body {
			.ui-sortable-helper {
				border-top: 1px solid rgba($black, 0.05);
				box-shadow: 0 0 4px rgba($black, 0.2);
			}

			.edit {
				line-height: 70px;
				height: 70px;

				.floating-label-input {
					padding-top: 20px;

					> label {
						font-weight: $regularFontWeight !important;
					}

					&.has-value {
						> label {
							padding: 0;
							bottom: 20px;
						}

						> input {
							padding: 0;
							border-bottom-color: $bright-blue;
						}
					}
				}

				.floating-label-select {
					.select2-container {
						box-shadow: none;
						border-bottom: 1px solid $bright-blue;
						border-radius: 0;

						.select2-choice {
							background: rgba($black, 0);
							border: none;

							.select2-arrow {
								border: none;
								height: 15px;

								> b {
									background-position: 0 -5px;
									background: rgba($black, 0);
									border: 4px solid rgba($black, 0);
									border-top-color: $bright-blue;
									width: 1px;
									height: 1px;
									margin-top: -2px;
									top: 50%;
									position: absolute;
									left: 50%;
									margin-left: -4px;
								}
							}
						}
					}

					&.has-value {
						.select2-choice {
							border-bottom-color: $bright-blue;
						}
					}
				}
			}

			.admin-table-cell {
				display: inline-block;
				vertical-align: middle;
				padding: 0 0 0 20px;

				&.edit-controls {
					float: right;
					padding-right: 20px;
					height: 70px;

					.btn {
						float: right;
						margin: 20px 0;
					}

					&:after {
						clear: both;
						content: "";
						display: block;
					}
				}
			}

			.admin-table-header {
				.admin-table-cell {
					height: 25px;
					line-height: 25px;
					padding: 0 0 0 20px;
				}
			}

			.admin-table-row {
				width: 100%;
				height: 40px;

				&.clickable {
					cursor: pointer;
				}

				.admin-table-cell {
					&.color-grey {
						color: $grey-8;
					}

					&.delete-btn:hover {
						visibility: visible;
					}

					&.hover-red:hover {
						color: $red;
					}
				}
			}
		}
	}
}

$taglist-width: 250px;
#tag-list {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1010;
	width: 0;
	@include transition(all linear 200ms);
	background-color: $grey-13;
	padding: 15px 0;
	overflow: hidden;

	h2 {
		color: $white;
		font-size: 14px;
		margin-bottom: 10px;
		padding: 0 15px;
		display: block;
		width: $taglist-width;
	}

	.info-text {
		font-size: 10px;
		padding: 0 15px;
		width: $taglist-width;
	}

	#tag-search {
		position: relative;
		padding: 0 15px;
		width: $taglist-width;

		.fa {
			position: absolute;
			left: 25px;
			top: 6px;
			color: $grey-8;
		}

		input {
			background-color: rgba($white, 0.2);
			border: none;
			outline: none;
			height: 26px;
			width: 100%;
			border-radius: $border-radius;
			padding-left: 30px;
			color: $white;
			@include placeholder-color($grey-8);
		}
	}

	#tag-copy-element {
		@include opacity(0);
		position: absolute;
		left: -9999;
		top: -9999;
	}

	#tags-list-ul {
		position: absolute;
		top: 150px;
		bottom: 0;
		width: $taglist-width;
		list-style-type: none;
		padding: 0;
		margin: 0;
		overflow: hidden;
		overflow-y: auto;

		.ModalTagList--header {
			background: $grey-11;
			padding: 5px 10px;
			color: $white;
			display: block;
			position: sticky;
			top: 0;
			z-index: 1;
		}

		li {
			display: block;
			color: $white;
			padding: 5px 20px;
			position: relative;

			&:hover {
				background-color: rgba($white, 0.1);
				cursor: pointer;
			}

			.name {
				display: block;
				font-size: 10px;
				color: rgba($white, 0.6);
			}

			.value {
				display: block;
			}

			.copy,
			.copied {
				position: absolute;
				right: 10px;
				top: 12px;
			}

			.copy {
				display: none;
			}

			.copied {
				color: $white;

				.fa {
					margin-right: 5px;
				}
			}

			&:hover {
				.copy {
					display: block;
				}
			}
		}
	}
}

body.remove-frame {
	$top: 82px;
	$top-no-submenu: $top - $sub-menu-height;
	#admin #admin-root #admin-page-editscript {
		top: $top-no-submenu;
		right: 0;
		left: 278px;
		bottom: 0;
		z-index: 1000;
		@include animation(script_fullscreen_animation_out_remove_frame 200ms);

		@include keyframes(script_fullscreen_animation_out_remove_frame) {
			0% {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: $modal-z - 1;
			}
			100% {
				top: $top-no-submenu;
				right: 0;
				left: 278px;
				bottom: 0;
				z-index: $modal-z - 1;
			}
		}

		@include keyframes(script_fullscreen_animation_remove_frame) {
			0% {
				top: $top-no-submenu;
				right: 0;
				left: 278px;
				bottom: 0;
				z-index: $modal-z - 1;
			}
			100% {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: $modal-z - 1;
			}
		}

		&.fullscreen {
			z-index: $modal-z - 1;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			@include animation(script_fullscreen_animation_remove_frame 200ms);
		}
	}
}

.up-modal {
	$taglist-width: 250px;

	.up-modal-header {
		button#toggle-tags-btn {
			position: absolute;
			right: 50px;
			top: 8px;
			-webkit-transition: all linear 200ms;
			transition: all linear 200ms;
			visibility: visible;
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
			filter: alpha(opacity=100);
			-moz-opacity: 1;
			-khtml-opacity: 1;
			opacity: 1;
		}
	}

	.tags-visible {
		#tag-list {
			width: $taglist-width;

			#tags-list-ul {
				top: 180px;
			}
		}

		.up-modal-header,
		.up-modal-content,
		.up-modal-controls {
			right: $taglist-width;
		}

		.white-header {
			button#toggle-tags-btn {
				overflow: hidden;
				@include opacity(0);
			}
		}
	}
}

#create-activity-action {
	$taglist-width: 250px;

	&.tags-visible {
		#tag-list {
			width: $taglist-width;

			#tags-list-ul {
				top: 180px;
			}
		}

		.up-modal-header,
		.up-modal-content,
		.up-modal-controls {
			right: $taglist-width;
		}

		#toggle-tags-btn {
			overflow: hidden;
			@include opacity(0);
		}
	}

	.up-modal-header,
	.up-modal-content,
	.up-modal-controls {
		@include transition(right linear 200ms);
	}

	#toggle-tags-btn {
		position: absolute;
		right: 50px;
		top: 8px;
		@include transition(all linear 200ms);
		visibility: visible;
		@include opacity(1);
	}
}

#updateActivityMulti {
	.PlanPhonecallsDrawerCallListSelect {
		.InputRevealButton {
			margin-top: 21px;
		}
	}
}

#edit-dynamiclink-modal {
	$taglist-width: 250px;

	&.tags-visible {
		#tag-list {
			width: $taglist-width;
		}

		.up-modal-header,
		.up-modal-content,
		.up-modal-controls {
			right: $taglist-width;
		}

		#toggle-tags-btn {
			overflow: hidden;
			@include opacity(0);
		}
	}

	.up-modal-header,
	.up-modal-content,
	.up-modal-controls {
		@include transition(right linear 200ms);
	}

	#toggle-tags-btn {
		position: absolute;
		right: 50px;
		top: 8px;
		@include transition(all linear 200ms);
		visibility: visible;
		@include opacity(1);
	}

	.up-panel {
		padding-bottom: 30px;
	}

	label {
		position: relative;
		color: $grey-13;
		font-size: 14px;
		font-weight: $regularFontWeight;
		line-height: 17px;
		margin-bottom: 5px;

		&.toggle-label {
			display: block;
			line-height: 40px;
			height: 40px;

			.up-ios-toggle {
				margin-top: 10px;
			}
		}
	}

	.up-color-picker-wrap {
		display: block;
		height: 32px;
		width: 80px;
	}

	#hide-tags-btn {
		position: absolute;
		top: 5px;
		right: 10px;
	}
}

#admin-add-user {
	.half-column {
		.Toggle {
			transform: translateX(1px);
		}
	}
	.separator {
		display: block;
		height: 2px;
		background-color: $grey-5;
		margin-bottom: 30px;
	}

	.toggle-row {
		line-height: 20px;
		margin-bottom: 20px;

		.up-ios-toggle {
			margin-left: 1px;
		}
	}

	button.expand-trigger {
		position: relative;
		text-align: left;
		padding-left: 0;

		.fa {
			position: absolute;
			right: 0;
			top: 10px;
		}
	}

	label.inline {
		display: inline-block;
	}

	label.toggle-label {
		display: block;
		color: $grey-13;
		font-size: 12px;
		font-weight: $boldFontWeight !important;
		line-height: 17px;
	}

	label.has-elevio {
		.fa {
			float: right;
		}
	}

	.status-width {
		width: 100px;
		margin-right: 10px;
	}

	.status-inactive-description {
		margin-bottom: 8px;

		a {
			text-decoration: none;
		}
	}
	.visible-toggle-description {
		margin-left: 8px;
		font-size: 12px;
		line-height: 18px;
		color: $grey-12;
	}
	.visible-toggle-row {
		line-height: 20px;
		display: flex;
		align-items: "center";
		padding: 5px;
	}

	.half-row.role-select {
		flex: 1;
		margin-left: 10px;
	}

	.half-row {
		margin: 0 -10px;

		.half-column {
			width: 50%;
			display: inline-table;
			padding: 0 10px;

			textarea.form-control.up-input {
				min-height: 70px;
				resize: vertical;
			}
		}
	}

	.twofa-warning,
	.licences-warning {
		color: $orange;
		font-size: 12px;
		margin-top: 10px;

		.fa {
			margin-right: 5px;
		}
	}

	#email-section {
		background-color: $white;
		@include box-shadow(0 1px 2px rgba($black, 0.2));

		.inner {
			position: relative;
			padding: 80px 30px 30px;
			max-width: 900px;
		}

		.Breadcrumbs {
			position: absolute;
			top: 25px;
			left: 40px;
			right: 10px;
		}

		#multi-add-info {
			background-color: $white;
			border: 1px solid $grey-6;
			border-radius: $border-radius;
			box-shadow: 0 1px 2px 0 rgba($black, 0.2);
			width: 238px;
			position: absolute;
			top: 110px;
			right: 30px;
			text-align: center;
			padding: 20px;
			color: $grey-11;
			font-size: 18px;
			font-weight: $boldFontWeight;
			line-height: 24px;

			img {
				display: inline-block;
				margin-bottom: 15px;
			}

			button {
				margin-top: 15px;
			}
		}
	}

	#single-add {
		max-width: 500px;

		@media (max-width: 1136px) {
			width: 380px;
		}

		.floating-label-input {
			margin-bottom: 20px;

			label {
				color: $grey-10;
			}

			& > input {
				border-bottom-color: $grey-6;
				border-bottom-width: 2px;
			}

			&.has-value > input {
				border-bottom-color: rgba($bright-blue, 0.5);
			}
			&.has-error {
				& > label {
					color: $red;
				}

				& > input {
					border-bottom-color: rgba($red, 0.5);
				}
			}

			& > input:focus {
				border-bottom-color: $bright-blue;
			}

			&:not(.has-value) > label {
				bottom: 2px;
			}
		}
	}

	#multi-add {
		position: relative;

		#multi-floating-label {
			position: absolute;
			z-index: 1000;
			font-weight: normal;
			color: $grey-10;
			font-size: 20px;
			height: 30px;
			line-height: 30px;
			top: 3px;
			left: 10px;
			@include transition(all ease 200ms);

			&.has-value {
				top: -20px;
				font-size: 14px;
				height: 20px;
				line-height: 20px;
			}
		}

		.select2-container {
			display: block !important;
			z-index: 1001;

			.select2-input {
				font-size: 20px;
				padding: 0 10px;
				height: 40px;
			}

			.select2-choices {
				border: none;
				background-color: transparent;

				.select2-search-choice {
					font-size: 20px;
					padding: 10px 10px 10px 25px;
					color: $bright-blue;
					background-color: rgba($bright-blue, 0.1) !important;
					border: 1px solid rgba($bright-blue, 0.2);
					margin-right: 5px;

					&.select2-search-choice-focus {
						border-color: rgba($bright-blue, 0.4);
						background-color: rgba($bright-blue, 0.2) !important;
					}

					.select2-search-choice-close {
						left: 7px;
						margin-top: -9px;
						font-size: 16px;
					}
				}
			}
		}
	}

	#form-section {
		padding: 30px;
	}
}

@import "admin-import";

// Must be in global scope
.select2-drop.icon-select {
	.select2-results li:not(.select2-no-results) {
		display: inline-block;
		width: 20%;
		height: 47px;
		text-align: center;

		.select2-result-label {
			line-height: 47px;
			height: 100%;
			padding: 0;
		}
	}
}

.admin-user-add-multi-select {
	@include border-radius(4px);
	border: none;
	overflow: hidden;
	margin-top: 10px;

	.select2-results .select2-no-results {
		background-color: $super-light-blue;
		color: $bright-blue;
	}
}

#edit-document-template-modal {
	#role-select-wrap {
		.select2-choices {
			max-height: 80px;
			overflow-y: auto;
		}
	}

	.full-width {
		width: 100%;
	}

	.column-wrap {
		font-size: 0;
	}

	.left-side {
		width: 50%;
		height: 612px;
		display: inline-block;
		padding: 15px;
		font-size: 12px;
		vertical-align: top;
		position: relative;
		box-shadow: 1px 2px 3px rgba($black, 0.1);
		z-index: 1000;

		.left-side-row {
			margin-bottom: 15px;
		}

		.left-side-save-button-wrap {
			width: calc(100% - 30px);
			position: absolute;
			bottom: 15px;

			.left-side-save-button {
				width: 100%;
				height: 45px;
				line-height: 45px;
			}

			.left-side-cancel-button {
				text-align: center;
				font-size: 14px;
				margin-top: 10px;
			}
		}
	}

	.right-side {
		width: 50%;
		height: 612px;
		display: inline-block;
		font-size: 12px;
		vertical-align: top;
		padding: 15px;
		position: relative;
		background-color: $grey-4;

		.right-side-uploader {
			opacity: 0;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
		}

		.right-side-content-wrap {
			text-align: center;
			margin-top: 60px;
		}

		.right-side-filetype-warning {
			position: absolute;
			bottom: 0;
			text-align: center;
			font-size: 13px;
			padding: 15px;
			color: $grey-11;
		}

		.right-side-drop-file {
			font-size: 16px;
			color: $grey-11;
			margin-top: 15px;
			display: inline-block;
		}

		.right-side-file-name {
			margin-top: 20px;
			font-size: 14px;
		}

		.right-side-file-size {
			font-size: 14px;
			color: $grey-11;
		}

		.right-side-remove-file-button {
			background-color: white;
			border-color: white;
			margin-top: 20px;
			width: 150px;
			margin-right: 6px;

			&:hover {
				color: $bright-blue;
			}
		}

		.right-side-choose-from-computer {
			cursor: pointer;
			font-size: 16px;
			height: 16px;
			line-height: 16px;
			margin-top: 5px;
			position: relative;

			input {
				cursor: pointer;
				color: transparent;
				padding-left: 130px;
				opacity: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 100px;
			}
		}
	}

	.select2-chosen {
		height: 34px;
		line-height: 22px;
		font-size: 14px;
	}

	.modal-close {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 1000;
		color: $grey-10;

		&:hover {
			color: $grey-13;
		}
	}

	@keyframes animate-width {
		0% {
			rx: 40;
		}
		50% {
			rx: 50;
		}
		100% {
			rx: 40;
		}
	}

	@keyframes animate-bounce {
		0% {
			transform: translateY(0%);
		}
		50% {
			transform: translateY(10%);
		}
		100% {
			transform: translateY(0%);
		}
	}

	.bounce {
		svg.file-drop-icon .shadow {
			animation: animate-width 2s ease-out infinite;
		}

		svg.file-drop-icon .card {
			animation: animate-bounce 2s ease-out infinite;
		}
	}
}

#edit-apikey-modal {
	.column-wrap {
		min-height: 255px;
		background-color: $grey-13;
		position: relative;

		$left-column-width: 250px;

		.modal-close {
			position: absolute;
			right: 10px;
			top: 10px;
			color: $grey-10;
			z-index: 999999;
		}

		.left-column {
			position: absolute;
			top: 0;
			bottom: 0;
			width: $left-column-width;
			padding: 20px;
			background-color: rgba($white, 0.1);
			color: $grey-6;
			padding-bottom: 70px;

			.header {
				color: $white;
				margin-bottom: 10px;

				.fa {
					font-size: 20px;
					margin-right: 10px;
				}

				.title {
					font-size: 20px;
				}
			}

			.body {
				font-size: 16px;
			}

			.footer {
				position: absolute;
				bottom: 20px;
			}
		}

		.right-column {
			padding: 20px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: $left-column-width;
			right: 0;
			background-color: rgba($black, 0);

			label {
				color: $white;
			}

			.name-input-wrap {
				margin-top: 15px;

				.name-input {
					background-color: rgba($white, 0.1);
					border-radius: $border-radius;
					border: none;
					color: $white;
				}
			}

			.display-key-wrap {
				position: relative;
				top: 30px;

				.key-container {
					background-color: rgba($white, 0.1);
					color: $white;
					font-size: 18px;
					word-wrap: break-word;
					padding: 15px;
					border-radius: $border-radius;
				}

				.copy-button {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					width: 100%;
					background-color: rgba($white, 0.2);
					border: none;
					color: $grey-8;

					&:hover {
						background-color: rgba($white, 0.15);
					}
				}
			}

			.generate-button-wrap {
				position: absolute;
				bottom: 60px;
				left: 20px;
				right: 20px;
			}
		}
	}

	.column-wrap.edit {
		min-height: 330px;
	}
}

.wide-drop {
	width: 300px !important;
}

// have to be in root
.script-select {
	border-color: $grey-13;
	background-color: $grey-13;
	color: $grey-10;

	.select2-results {
		margin-top: 7px;
	}

	.select2-search input {
		background: $grey-11;
		border-color: $grey-11;
		color: $white;
	}

	.select2-results .select2-highlighted {
		background-color: $grey-11;
		color: $white;
	}

	.select2-no-results {
		background-color: transparent;
		color: $grey-8;
	}

	.ul.select2-results {
		margin-top: 7px;
	}
}

.inline-edit-delete {
	display: flex;
	height: 34px;
	line-height: initial;

	.inline-edit-delete-input {
		border: 1px solid transparent;
		font-size: 18px;
		font-weight: $regularFontWeight;
		border-radius: $border-radius;
		padding: 0 35px 1px 5px;
		outline: none !important;
		width: 100%;
		height: 100%;
		transition: border-color ease 200ms;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.inline-edit-delete-edit {
		position: absolute;
		right: 10px;
		top: 9px;
		font-size: 16px;
		color: $grey-10;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 200ms ease;
		pointer-events: none;
	}

	.inline-edit-delete-save {
		display: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.inline-edit-delete-delete {
		display: inline-block;
		visibility: hidden;
		opacity: 0;
		color: $grey-10;
		transition: visibility 0s, opacity 200ms ease;
		background-color: transparent;
		box-shadow: none;
		border: none;
		text-shadow: none;

		&:hover {
			color: $red;
		}
	}

	.inline-edit-delete-input-wrap {
		flex: 1;
		position: relative;
	}

	&:not(.inline-edit-delete--active):hover {
		.inline-edit-delete-input {
			border: 1px solid $grey-6;
		}

		.inline-edit-delete-edit {
			position: absolute;
			visibility: visible;
			opacity: 1;
		}

		.inline-edit-delete-delete {
			visibility: visible;
			opacity: 1;
		}
	}

	&.inline-edit-delete--active {
		.inline-edit-delete-delete {
			display: none;
		}

		.inline-edit-delete-input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			background-color: $grey-4;
			padding-right: 5px;
		}

		.inline-edit-delete-save {
			display: inline-block;
		}
	}
}

.fieldPage {
	box-shadow: 0 -1px 2px 0 rgba($black, 0.1);

	&:after {
		clear: both;
		content: "";
		display: block;
	}

	.col-sm-6 {
		padding: 0;
		min-height: 435px;

		.row-holder {
			overflow-y: scroll;
			padding: 20px;
			position: absolute;
			top: 50px;
			left: 0;
			right: 0;
			bottom: 0;
			border-top: 1px solid $grey-3;
		}

		&:first-child {
			border-right: 1px solid $grey-3;

			.row-holder {
				top: 90px;
			}

			.topRow {
				height: 90px;
			}
		}

		&.no-fields {
			background: $grey-1;
			position: relative;

			.briefcase-holder {
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				left: 50%;
				text-align: center;
				width: 300px;

				.briefcase {
					height: 105px;
					width: 105px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					margin: 0 auto 20px auto;
				}

				.no-fields-title {
					font-size: 16px;
					color: $grey-10;
					line-height: 19px;
					margin: 10px 0;
					font-weight: $regularFontWeight;
				}

				.no-fields-copy {
					font-size: 14px;
					color: $grey-10;
					line-height: 17px;
					font-weight: $regularFontWeight;
				}
			}
		}
	}

	.fieldRow {
		h3 {
			color: $grey-11;
			font-size: 16px;
			font-weight: $regularFontWeight;
			margin-top: 15px;
			line-height: 24px;

			&.row-h3 {
				&:first-child {
					margin-top: 0;
				}
			}
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				line-height: 24px;
				font-size: 14px;
				color: $bright-blue;

				> span {
					cursor: pointer;
				}

				&.selected {
					color: $grey-10;
					font-style: italic;
				}
			}
		}
	}

	.topRow {
		padding: 20px;
		padding-top: 10px;
		padding-bottom: 10px;
		height: 50px;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

		h3 {
			font-size: 18px;
			font-weight: $regularFontWeight;
			margin-top: 0;
			line-height: 30px;

			.choose-all {
				font-size: 14px;
				line-height: 30px;
				float: right;
				cursor: pointer;
				color: $bright-blue;

				&.grey {
					color: $grey-8;
				}
			}
		}

		.field-search {
			background: $grey-3;
			color: $grey-8;
			height: 30px;
			display: table;
			width: 100%;
			vertical-align: middle;
			border-radius: $border-radius;
			margin-top: 5px;

			i.fa-search {
				display: table-cell;
				vertical-align: middle;
				padding: 0 0 0 10px;
				width: 28px;
			}

			input[type="text"] {
				font-size: 13px;
				font-style: italic;
				font-weight: $regularFontWeight;
				line-height: 14px;
				display: inline-block;
				height: 100%;
				width: 100%;
				border: none;
				background: transparent;
				color: $grey-11;

				&:focus {
					outline: none;
				}
			}
		}
	}

	.fieldPageSelected--holder {
		> h3 {
			line-height: 30px;
			color: $grey-11;

			.deselect-all {
				font-size: 14px;
				line-height: 30px;
				float: right;
				cursor: pointer;
				color: $bright-blue;

				&.grey {
					color: $grey-8;
				}
			}
		}

		.fieldPageSelected-selected {
			h3 {
				color: $grey-11;
				font-size: 16px;
				font-weight: $regularFontWeight;
				margin-top: 15px;
				line-height: 24px;

				&:first-child {
					margin-top: 0;
				}
			}

			.fieldPageSelected--fieldsList {
				list-style: none;
				padding: 0;

				li {
					color: $bright-blue;
					font-size: 14px;
					line-height: 24px;

					i.fa.fa-times {
						float: right;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.select2-bigger-dropdown {
	ul.select2-results {
		max-height: 300px;
	}
}

#admin-page-triggers,
#admin-page-automations {
	.dontexec {
		&-titlecol {
			display: flex;
			align-items: center;
			> .Tooltip {
				margin-right: 10px;
			}
		}
		.ellipsis {
			position: relative;
		}
		box-shadow: inset 4px 0 $orange;
	}
}
