.LimitSelect {
	height: fit-content;

	.DropDownMenu__content {
		min-width: unset;
		bottom: 0;
	}

	.DropDownMenu__outside-click {
		position: absolute;
		bottom: 20px;
	}
	.TableRow {
		cursor: pointer;
	}
}
