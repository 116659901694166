@import "~StyleVariables";

// TODO: Remove when REMOVE-FRAME is released
.remove-frame .AccountListBoardOfDirectors {
	&__sticky {
		right: 0;
		top: 190px;
		left: 348px;
	}
}

.AccountListBoardOfDirectors {
	margin-top: 40px;
	margin-bottom: 300px;

	.Help {
		display: inline-block;
		margin-left: 4px;
	}

	&__addonCon {
		position: relative;
		justify-content: center;
		display: flex;
		.Loader {
			margin-top: 30px;
		}
	}

	.BillingAddonBanner {
		width: 100%;
		max-width: 890px;
		margin: 16px;
	}

	&__breaking-text {
		white-space: break-spaces;
	}

	&__addContactBtn {
		border-radius: 0px !important;
		box-shadow: none;
		padding-right: 7px;
		margin-right: 7px;
		margin-left: 0px;
		border-right: 1px solid rgba($black, 0.2) !important;
		height: 33px;
		margin-top: -13px;
		margin-bottom: -7px;
		border-top-left-radius: 4px !important;

		.Icon {
			margin-right: 2px;
		}
	}

	&__alreadyInUpsales {
		align-self: center;
		margin-right: 15px;

		.Text {
			display: inline-block;
		}

		.Icon {
			font-size: 12px;
		}
	}

	.DropDownMenu {
		float: right;
	}

	&__headerPlaceHolder {
		height: 47px;

		.Text {
			padding-top: 0 !important;
			line-height: 47px;
			margin-left: 15px;
		}
	}

	&__header {
		&__pro {
			margin-top: -42px;
			position: absolute;
			right: 10px;
		}

		&__title {
			display: inline-block;
			padding-top: 9px;
			margin-left: 15px;
		}

		&__contacts {
			padding-bottom: 5px;
			margin-left: 15px;
		}
	}

	&__listEntry .Row,
	&__columns {
		padding-left: 15px;
	}

	&__sticky {
		top: 206px;
		right: 16px;
		left: 365px;
		z-index: 1000;
		position: fixed;
		white-space: nowrap;
		background-color: $grey-4;
		font-size: 12px;

		.Text {
			font-size: 12px;
			font-weight: $boldFontWeight;
			line-height: 30px;
			color: $grey-11;
		}
	}

	&__columns {
		font-size: 12px;
		background-color: $grey-4;

		.Column {
			.Text {
				font-size: 12px;
				font-weight: $boldFontWeight;
				line-height: 30px;
				color: $grey-11;
			}
		}
	}

	&__listEntry {
		border-bottom: 1px solid $grey-4;

		&__link {
			position: absolute;
			top: 9px;
			right: 183px;
			font-size: 13px;
			font-weight: $regularFontWeight;
		}

		&__memberSince {
			margin-top: 11px;
			padding-left: 2px;
		}

		&__hideButton {
			margin-left: 5px;
			margin-top: 2px;
			background-color: $grey-2 !important;

			.Icon {
				margin-left: 30px;
			}
		}

		&__showButton {
			padding: 0 6px !important;
			margin-top: 2px;
		}

		&__buttonGroup {
			margin-right: 12px;
			display: flex;
			justify-content: flex-end;

			.DropDownMenu {
				background-color: $green;
				margin-left: -20px;
				height: 34px;
				margin-top: 2px;
				border-radius: 2px;

				.Icon {
					color: $white;
				}

				.DropDownMenu__content {
					min-width: auto;
					margin-top: -16px !important;

					.TableColumn {
						border-top: none;
					}
				}

				.DropDownButton__wrapper {
					margin-top: -2px;

					.DropDownButton__icon-wrapper {
						margin: auto 12px 24px 0px;
					}
				}

				.DropDownButton__text-wrapper {
					padding-left: 0px !important;
					padding-right: 0px !important;
				}
			}
		}

		&__merge {
			border-radius: 5px 0px 0px 5px;
			cursor: pointer;
			text-align: right !important;
			background-color: $green;

			.Text {
				display: inline;
				white-space: nowrap;
				padding-right: 8px;
				padding-left: 6px;
			}

			.Icon {
				display: contents;
				margin-right: 5px;
				color: $white;
			}
		}
	}
	&__additionalCompanies {
		padding-bottom: 10px;

		.Text--center {
			line-height: 80px;
		}

		&__showMoreBtn {
			margin-top: 5px;
		}

		&__additionalAssignments {
			margin-left: 15px;
			margin-top: 18px;
			margin-bottom: 9px;
		}
	}
}
