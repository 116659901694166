@import "~StyleAnimations";
@import "~StyleVariables";
@import "~StyleMixins";

.AdminSaveBtn {
    position: fixed;
    visibility: hidden;
    width: 300px;
    z-index: $modal-z - 1;
    left: 50%;
    bottom: -50px;
    margin-left: -10px;
    @include transition(all cubic-bezier(0.68, -0.55, 0.37, 2) 300ms);

    &--visible {
        visibility: visible;
        bottom: 30px;
    }

    &__disabledReasonsTooltip {
        position: fixed;
        z-index: $modal-z - 1;
        left: 50%;
        bottom: 30px;
        width: 300px;
        left: 50%;
        height: 40px;
        margin-left: -10px;
    }
}
