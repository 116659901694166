.CMOrderRowSummary {
	width: 200px;

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Text {
			display: inline;
		}

		&__editIcon {
			display: flex;
			align-items: center;
		}

		&__editCm {
			display: flex;
			justify-content: space-between;
			cursor: pointer;

			.NumberInput {
				margin-right: 4px;

				.Input__input {
					padding-right: 6px;
					text-align: right;
					width: 75px;
				}
			}

			.Icon {
				display: none;
				margin-right: 4px;
			}

			&--disabled:hover {
				cursor: default;
				
				.Icon {
					visibility: hidden;
				}
			}

			&:hover {
				.Icon {
					display: unset;
				}
			}
		}
	}
}
