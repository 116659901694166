@import "~StyleVariables";

.SalesList {
	width: 100%;
	.ListView {
		top: 237px;
	}

	&__dashboard {
		height: 205px;
	}

	&__widgets {
		width: 20%;
	}

	&__graph {
		width: 80%;
	}
	.ListViewQuickSearch.Input {
		&:hover,
		&:focus-within,
		&:not(:has(input[value=""])) {
			width: 170px !important;
		}
	}
}
