@import "~StyleVariables";

.SocialEventPageHeader {
	margin: 35px auto 30px auto;
	max-width: 690px;
	text-align: center;
}

.SocialEventPageHeader__circle {
	display: inline-block;
	background-color: $grey-10;
	vertical-align: text-bottom;
	color: $white;
	margin-right: 5px;
}

.SocialEventPageHeader__text {
	padding-top: 10px;
}

.SocialEventPageHeader__text ._elevio_underline {
	color: $bright-blue;
}
