@import "~StyleVariables";

.CompanyGroupTreeHeader {
	background-color: $white;

	&__header {
		font-size: 22px;
	}

	&__salesNumbers {
		max-width: 1400px;
	}
}
