@import "~StyleVariables";

.ExternalContactInfo {
	width: 420px;

	.Icon-email {
		width: 14px;
	}

	.Icon-edit {
		color: $grey-8;
	}

	&__contact,
	&__client {
		width: 100%;
	}

	.ClickableItem {
		&__title-col {
			overflow: hidden;
		}
	}
}
