@import "~StyleVariables";

.FormOverview {
	position: absolute;
	left: 50px;
	right: 0;
	bottom: 0;
	top: 97px;
	z-index: 2;
	overflow: auto;

	&__Title-area {
		max-width: calc(100% - 550px);
		height: 50%;
		overflow: hidden;
		margin-right: 10px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.Title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}
	}

	&__MainView {
		margin: 0 15px 45px 15px;
		height: calc(100% - 45px);
		min-width: 866px;

		.Table {
			table-layout: fixed;

			.TableHeader {
				background: $grey-2;
			}
		}
		.Card:first-child {
			margin-bottom: 20px;
		}
	}

	&__Header {
		display: flex;
		align-items: center;
		padding: 0 0 0 10px;
		height: 50px;
		box-shadow: 0 1px 2px 0 rgba($black, 0.2);
		position: sticky;
		top: 0;
		background-color: $white;
		z-index: 5;

		.Title {
			margin-right: 40px;
		}

		.Tabs {
			height: 50px;
			align-items: center;
		}
	}

	&__Loading {
		height: 100vh;

		.Loader {
			position: absolute;
			left: 50%;
			margin-left: -50px;
			top: 100px;
		}
	}

	&__Dropdown-content {
		.TableColumn {
			font-size: 16px;
			line-height: 19px;
		}

		.Icon {
			margin-right: 10px;
		}
	}

	&--no-sidebar {
		left: 15px;
	}
}
