#admin #admin-root #admin-page-import #admin-content,
#single-import #admin-content {
	.admin-card,
	.admin-table {
		box-shadow: 0 1px 3px 0px $grey-8;
		border-radius: 5px;

		.status-col {
			line-height: 20px;
		}

		.waiting-text {
			font-size: 12px;
		}
	}
}

#admin-page-import {
	.import-uploader {
		min-height: 250px;

		.import-uploader-tabs-wrap {
			border-bottom: solid 1px $grey-6;
			display: flex;

			.import-uploader-tab {
				padding: 14px 16px;
				border: none;
				background-color: transparent;
				outline: none;
				border-bottom: solid 2px transparent;
				color: $grey-8;

				&.active {
					border-bottom-color: $green;
					color: $green;
					font-weight: $boldFontWeight;
				}
			}
		}

		.import-uploader-content {
			padding: 16px;
			display: flex;
		}

		.import-dropzone {
			position: relative;
			background-color: $grey-1;
			flex: 1;
			border: 2px dashed $grey-5;
			border-radius: 4px;
			text-align: center;

			&:hover {
				cursor: pointer;
			}

			h3 {
				position: absolute;
				left: 20px;
				right: 20px;
				color: $grey-13;
				font-size: 20px;
				font-weight: $regularFontWeight;
				line-height: 24px;
				margin: 0;
				padding: 0;
			}
		}

		.import-dropzone .import-loading {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $grey-1;

			h3 {
				top: 155px;
			}

			.upsales-loader {
				position: absolute;
				left: 50%;
				margin-left: -50px;
				top: 40px;
				width: 100px;
				height: 100px;
			}
		}

		.import-dropzone {
			&.over {
				background-color: $green;
				border-color: $white;

				h3 {
					color: $white;
				}

				.hide-on-hover {
					opacity: 0;
					visibility: hidden;
				}

				.show-on-hover {
					opacity: 1;
					visibility: visible;
				}

				.on-hover {
					visibility: visible;
					opacity: 1;
				}
			}

			.on-hover {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: $grey-1;
			}

			svg.file-drop-icon {
				position: absolute;
				top: 40px;
				margin-left: -60px;
			}

			svg.file-drop-icon .shadow {
				animation: animate-width 2s ease-out infinite;
			}

			svg.file-drop-icon .card {
				animation: animate-bounce 2s ease-out infinite;
			}

			@keyframes animate-width {
				0% {
					rx: 40;
				}

				50% {
					rx: 50;
				}

				100% {
					rx: 40;
				}
			}

			@keyframes animate-bounce {
				0% {
					transform: translateY(0%);
				}

				50% {
					transform: translateY(10%);
				}

				100% {
					transform: translateY(0%);
				}
			}

			.hide-on-hover {
				opacity: 1;
				visibility: visible;
				transition: all ease 0.4s;
			}

			.show-on-hover {
				opacity: 0;
				visibility: hidden;
				transition: all ease 0.4s;
			}

			h3 {
				top: 85px;
			}

			p {
				position: absolute;
				top: 115px;
				left: 20px;
				right: 20px;
				color: $grey-11;
				font-size: 14px;
				font-weight: $regularFontWeight;
				line-height: 20px;
			}

			input[type="file"] {
				visibility: hidden;
				position: absolute;
				width: 100%;
				height: 100%;
			}

			.accepted-types {
				position: absolute;
				bottom: 15px;
				left: 0;
				right: 0;
				color: $grey-10;
				font-size: 14px;
				font-weight: $regularFontWeight;
				line-height: 20px;
			}
		}

		.import-content {
			flex: 1;
			padding: 35px;
			color: $grey-10;

			h2 {
				&:not(:first-child) {
					margin-top: 20px !important;
				}
			}

			.DropDownMenu {
				display: block;
				margin-top: 16px;

				.DropDownMenu__content {
					min-width: unset;
				}

				.import-template-button {
					display: flex;
					padding: 4px 8px;
					font-size: 14px;
					height: auto;
					align-items: center;
					width: 270px;
					position: relative;
					border-radius: none;
					overflow: hidden;
					border: none;

					&:first-child {
						border-top-left-radius: 5px;
						border-top-right-radius: 5px;
					}

					&:last-child {
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;
					}

					.lang {
						background-color: $white;
						color: $green;
						text-shadow: none;
						box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.2);
						height: 20px;
						padding: 4px;
						padding-right: 6px;
						border-radius: 4px;
						display: flex;
						align-items: center;
						margin-right: 10px;
						text-transform: uppercase;
						font-size: 11px;
						line-height: 11px;

						.Icon {
							line-height: 11px;
							margin-right: 4px;
						}
					}

					.expand-button {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						width: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-left: solid 1px rgba(0, 0, 0, 0.1);
					}
				}

				&--expanded {
					.expand-button {
						background-color: $medium-green;
					}
				}
			}
		}
	}
}

#admin #admin-root {
	&.import {
		left: 0;
	}
}

#single-import {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	background-color: $white;

	#admin-content {
		padding: 30px 30px 80px;
		max-width: 900px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;

		.admin-card {
			padding: 20px;
			margin-bottom: 20px;

			&.admin-card-review {
				padding: 0;

				.admin-card-content {
					padding: 20px;
				}
			}

			&.has-info {
				position: relative;

				.info-background {
					content: "";
					position: absolute;
					z-index: 0;
					top: 0;
					bottom: 0;
					right: 0;
					width: 218px;
					height: 100%;
					background-color: $grey-1;
					border-radius: $border-radius;
				}
			}

			.admin-card-title {
				color: $black;
				font-size: 18px;
				margin-bottom: 16px;
			}

			.admin-card-top-description {
				color: $grey-10;
				font-size: 16px;
				margin-bottom: 16px;
			}
		}

		.admin-info-row {
			display: flex;

			.duplicate-warning {
				display: flex;
				margin-top: 10px;
			}

			.info-row-content {
				flex: 1;
				margin-bottom: 20px;

				.Select.select-error .Select-control {
					border: solid 1px $red;
				}
			}

			.info-row-info {
				width: 200px;
				border-left: 4px solid $grey-4;
				padding-left: 10px;
				margin-bottom: 10px;
				margin-left: 20px;
				color: $grey-10;
				z-index: 1;
			}
		}
	}

	#import-scroll-wrapper {
		flex: 1;
		display: flex;
		margin: 0 20px;
		overflow: hidden;
		overflow-y: auto;
	}

	#inner-wrapper {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	.import-topbar-wrap {
		position: sticky;
		top: 0;
		z-index: 10;
		background-color: $white;

		#import-topbar {
			display: flex;
			align-items: center;
			height: 54px;
			box-shadow: 0 1px 2px rgba($black, 0.2);

			h2 {
				color: $green;
				font-size: 18px;
				font-weight: $boldFontWeight;
				text-align: center;
				padding: 0 16px;
			}

			.import-next-button {
				height: 100%;
				border-radius: 0;
				border: none;
				margin-right: 0;
				display: flex;
				align-items: center;

				&:disabled {
					background-color: $grey-4;
					font-style: italic;
					color: $grey-10;
					text-shadow: none;
				}

				.import-next-button-text {
					display: flex;
					flex-direction: column;
					min-width: 100px;
					padding-right: 10px;
					text-align: left;

					.import-next-button-subtitle {
						font-size: 11px;
						line-height: 16px;
					}

					.import-next-button-title {
						line-height: 16px;
						font-weight: $boldFontWeight;
						font-size: 14px;
					}
				}

				.Icon {
					font-size: 18px;
					font-weight: normal;
				}
			}
		}

		#topbar-file {
			margin-top: 2px;
			border-bottom: 2px solid $grey-4;
			background-color: $white;

			.topbar-file-content {
				height: 50px;
				display: flex;
				align-items: center;
				padding: 5px 15px;

				.Icon {
					color: $grey-10;
				}

				.topbar-subtitle,
				.new-file-error-text {
					line-height: 18px;
					display: flex;
					flex-direction: column;
					margin: 0 10px;

					.filename,
					.title {
						font-size: 14px;
						color: $grey-13;
						display: inline-block;
						max-width: 335px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.file-info,
					.subtitle {
						color: $grey-10;
						font-size: 12px;
					}
				}

				.new-file-error {
					display: flex;
					align-items: center;
					margin: 0 10px;

					.Icon {
						color: $bright-orange;
					}
				}

				.Button .Icon {
					color: inherit;
					margin-right: 8px;
				}
			}

			.topbar-help-icon {
				margin-left: auto;
				color: $black;
			}

			.topbar-file-upload {
				position: relative;
				height: 140px;
				background-color: $grey-1;
				display: flex;
				align-items: center;
				padding-left: 5%;

				svg {
					margin: 0 50px;
				}

				.Button {
					padding: 0;
				}

				.upload-close-btn {
					position: absolute;
					top: 0px;
					right: 0px;
					height: 50px;
					width: 50px;
					display: flex;
					align-items: center;
					justify-content: center;

					.Icon {
						height: 18px;
						width: 18px;
						border-radius: 100%;
						background-color: $grey-8;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $white;
						font-size: 12px;
					}
				}

				.topbar-file-upload-text {
					color: $black;
					display: flex;
					flex-direction: column;

					.Button {
						font-size: 14px;
					}

					.subtitle {
						color: $grey-10;
					}

					.Progressbar {
						margin-top: 14px;
						width: 200px;

						@keyframes animate-progress {
							0% {
								transform: translateX(-60px);
							}

							100% {
								transform: translateX(200px);
							}
						}

						&__progress {
							animation: animate-progress 1.4s ease infinite;
						}
					}
				}
			}

			input[type="file"] {
				display: none;
			}
		}

		#import-tabs {
			height: 54px;
			margin: 0 auto;
			margin-left: 20px;
			padding: 0;
			text-align: center;
			display: flex;
		}

		#import-tabs .import-tab {
			border-bottom: 2px solid transparent;
			padding: 0 14px;
			cursor: pointer;
			text-align: center;
			height: 54px;
			margin: 0;
			display: flex;
			align-items: center;
			color: $grey-10;

			.import-tabs-bullet {
				display: inline-block;
				background-color: $grey-6;
				border-radius: 100%;
				height: 20px;
				width: 20px;
				min-width: 20px;
				line-height: 20px;
				margin-right: 10px;
				color: #fff;
			}

			&:hover:not(.is-disabled):not(.is-active) {
				background-color: $grey-1;
				border-bottom-color: $grey-4;
			}
		}

		#import-tabs .import-tab.is-active {
			color: $grey-13;
			border-bottom-color: $green;

			.import-tabs-bullet {
				background-color: $green;
			}
		}

		#import-tabs .import-tab.is-disabled {
			color: $grey-6;
			cursor: default;
		}
	}

	.import-type-content {
		width: 100%;
		max-width: 800px;
		padding: 40px 20px;
		margin: 0 auto;

		h2 {
			display: block;
			color: $black;
			font-size: 24px;
			margin-bottom: 14px;
		}

		.import-type-subtitle {
			font-size: 16px;
			color: $grey-10;
			margin-bottom: 30px;
		}

		.import-type-bar {
			display: flex;
			margin-bottom: 14px;
			justify-content: space-between;
			align-items: center;

			.import-type-file {
				color: $grey-10;
				font-size: 16px;

				.Icon {
					margin-right: 6px;
				}
			}

			.DropDownMenu {
				.import-type-template-btn {
					display: flex;
					align-items: center;
					text-decoration: none;
					color: $green;
					padding: 0;
					font-size: 14px;

					.template-lang {
						background-color: $green;
						color: $white;
						text-shadow: none;
						box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.2);
						height: 22px;
						padding: 5px 7px;
						border-radius: 4px;
						display: flex;
						align-items: center;
						margin-right: 10px;
						text-transform: uppercase;
						font-size: 11px;
						line-height: 11px;

						.Icon {
							line-height: 11px;
							margin-right: 4px;
						}
					}

					.import-type-template-icon {
						margin-left: 6px;
						height: 34px;
						width: 18px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: all ease 0.2s;

						&:before {
							margin-top: -5px;
						}

						&.expanded {
							transform: rotate(180deg);
						}
					}
				}

				.DropDownMenu__content {
					left: -10px;
					padding: 5px 10px;
					padding-right: 30px;
					border-radius: 5px;
				}
			}
		}

		.import-update-select {
			width: 300px;

			img {
				height: 64px;
			}
		}
	}

	#match-header {
		text-align: center;
		color: $green;

		h1,
		p {
			text-align: center;
		}

		p {
			font-size: 14px;
			margin-bottom: 0;
			color: $grey-13;
		}

		h1 {
			color: $grey-13;
			font-size: 18px;
			height: 20px;
			line-height: 20px;

			.fa-check {
				color: $bright-green;
				height: 20px;
				line-height: 20px;
			}
		}
	}

	.instructions {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: center;
		box-shadow: 0 1px 3px rgba($black, 0.2);
		padding: 15px 50px;
		background-color: $white;
		position: relative;
		color: $grey-11;
		font-size: 14px;
		font-weight: $boldFontWeight;
		line-height: 20px;
		margin: 20px;
		margin: 20px auto;
		margin-bottom: 0;
		min-height: 70px;
		width: 90%;
		max-width: 800px;
		border-radius: 4px;

		&.one-line {
			min-height: 50px;

			.instruction-close,
			.instruction-icon {
				top: 0;
			}
		}

		.instruction-close,
		.instruction-icon {
			position: absolute;
			top: 10px;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			font-size: 30px;
		}

		.instruction-icon {
			left: 0;
			color: $green;
		}

		.instruction-close {
			right: 0;
			color: $grey-8;
			transition: all ease 200ms;
			font-size: 20px;
			cursor: pointer;

			.Icon {
				height: 18px;
				width: 18px;
				border-radius: 100%;
				background-color: $grey-8;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				font-size: 12px;
			}

			&:hover {
				color: $black;
			}
		}
	}

	.columns-holder {
		position: relative;
		height: 100%;
		padding: 20px 0 0 0;

		.columns-inner-holder {
			position: relative;
			height: 100%;
			overflow-x: auto;
			padding-bottom: 20px;
		}

		.scroll-gradient {
			position: absolute;
			top: 2px;
			bottom: 20px;
			width: 30px;
			z-index: 1000;

			&:first-of-type {
				@include linear-gradient(transparent, $white, -90deg);
				left: 0;
			}

			&:last-of-type {
				@include linear-gradient(transparent, $white, 90deg);
				right: 0;
			}
		}

		.columns-inner {
			display: block;
			margin: 0 auto;

			&:after {
				clear: both;
				content: "";
				display: block;
			}

			.column {
				width: 230px;
				float: left;
				display: block;
				background-color: $white;
				box-shadow: 0 1px 2px rgba($black, 0.2);
				margin: 0 6px;
				border-radius: 3px;

				&:first-child {
					margin-left: 26px;

					.sub .fa {
						display: inline-block;
					}
				}

				&:last-child {
					margin-right: 26px;

					.column-head .select-dropdown.select2-dropdown-open {
						float: right;
					}
				}

				.column-status {
					display: block;
					height: 30px;
					line-height: 30px;
					color: $white;
					font-size: 12px;
					font-weight: $boldFontWeight;
					background-color: $red;
					padding: 0 10px;
					border-radius: 3px 3px 0 0;

					.Icon {
						margin-right: 5px;
					}

					b {
						margin-left: 6px;
						color: $white;
					}

					&.green {
						background-color: $bright-green;
					}

					&.grey {
						background-color: transparent;
						color: $grey-10;
					}
				}

				&.valid {
					.column-head {
						.field-name {
							border-color: $bright-green;
						}
					}
				}

				&.skip {
					background-color: $grey-1;

					.column-head {
						.field-name {
							color: $grey-10;
						}
					}
				}

				.column-head {
					position: relative;
					height: 190px;
					display: block;
					vertical-align: middle;
					padding: 0 15px;
					padding-top: 15px;
					transition: all ease 200ms;

					.field-name {
						display: block;
						background-color: $grey-2;
						border: 1px solid $grey-2;
						border-radius: 4px;
						height: 32px;
						line-height: 32px;
						color: $grey-13;
						font-size: 14px;
						font-weight: $boldFontWeight;
						padding: 0 10px;
						margin-top: 3px;
						transition: all ease 200ms;

						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						.fa {
							margin-right: 5px;
						}
					}

					.column-label {
						font-weight: $boldFontWeight;
						visibility: visible;
						opacity: 1;
						transition: all ease 200ms;

						&.column-label-hidden {
							visibility: hidden;
							opacity: 0;
						}
					}

					.import-select-btn-group {
						border-radius: 4px;
					}

					.Select-control {
						border-radius: 4px;
					}

					.connection-link {
						visibility: hidden;
						opacity: 0;
						transition: all ease 200ms;
						color: $white;
						background-color: $bright-green;
						width: 20px;
						height: 20px;
						line-height: 20px;
						text-align: center;
						border-radius: 100%;
						display: block;
						margin: 0 auto;
						position: relative;
						top: 10px;

						&:before {
							content: "";
							position: absolute;
							background-color: $bright-green;
							width: 1px;
							top: -10px;
							bottom: -12px;
							left: 9px;
							z-index: 1000;
						}

						&.connection-link-visible {
							visibility: visible;
							opacity: 1;
						}

						.fa {
							position: relative;
							z-index: 1001;
						}
					}

					.select-dropdown-wrap {
						margin-top: 3px;

						a.select2-choice {
							height: 32px;
							line-height: 32px;
						}
					}

					.column-info {
						position: absolute;
						top: 10px;
						right: 10px;

						b {
							color: $grey-10;
							font-size: 12px;
						}
					}

					.skip-btn {
						margin-top: 10px;
						border-radius: 4px;

						&.btn-lined {
							box-shadow: none;
						}
					}

					.select-dropdown {
						&.select2-dropdown-open {
							z-index: 500;
							width: 350px;

							a.select2-choice {
								border: 1px solid $green;
								border-bottom: none;
							}
						}

						&.select2-container-disabled {
							.select2-choice {
								border-color: transparent !important;
								background-color: $grey-2;
							}
						}
					}

					.normal,
					.has-value {
						.select2-choice {
							height: 32px;

							.select2-chosen {
								padding: 0 10px;
								text-align: left;
							}

							.select2-arrow {
								border-left: none;
							}
						}
					}

					.normal {
						.select2-choice {
							border-color: $red;

							.select2-chosen {
								color: $grey-10;
							}
						}
					}

					.has-value {
						.select2-choice {
							border-color: $bright-green;

							.select2-chosen {
								color: $black;
							}
						}
					}
				}

				ul {
					padding: 0;
					margin: 0;
					list-style: none;
					overflow: hidden;

					li {
						padding: 0 10px;
						border-bottom: 1px solid $grey-4;
						color: $grey-13;
						font-size: 12px;
						line-height: 30px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						&.heading {
							border-bottom: none;
							background-color: transparent;
							border-bottom: 1px solid $grey-4;
							position: relative;
							z-index: 1000;
							font-weight: $boldFontWeight;
						}
					}
				}

				.sub {
					box-shadow: 0 3px 1px rgba($black, 0.05);
					border-top: 1px solid $grey-7;
					font-size: 12px;
					height: 45px;
					line-height: 45px;
					padding: 0 10px;

					.fa {
						margin-left: 5px;
						display: none;
					}
				}

				.column-empty-rows {
					@keyframes show-data-health {
						from {
							max-height: 0px;
							overflow: hidden;
							padding: 0 10px;
						}
					}

					display: flex;
					background-color: $grey-1;
					border-top: 1px solid $grey-4;
					color: $grey-12;
					padding: 10px;
					font-size: 12px;
					max-height: 70px;
					animation: show-data-health 0.3s ease;

					&:last-child {
						border-radius: 0 0 3px 3px;
					}

					.Icon {
						color: $black;
						font-size: 14px;
						margin: 4px 10px;
						margin-right: 12px;
					}
				}
			}

			.column + .column {
				border-left: none;
			}
		}
	}

	.half-row {
		margin: 0 -20px;

		.half-column {
			width: 50%;
			display: inline-block;
			padding: 0 20px;
			vertical-align: top;
		}
	}

	.admin-card-review .half-row .half-column {
		padding: 0;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.setting-type-header {
		color: $grey-13;
		font-size: 18px;
		margin: 20px 0;

		.fa {
			margin-right: 5px;
		}
	}

	.admin-card-review .setting-type-header {
		font-weight: normal;
		font-size: 16px;
		padding: 0 14px;
		margin: 0;
	}

	.setting-type-header-description {
		font-size: 13px;
		color: $grey-9;
		padding: 0 14px;
	}

	.settings-label {
		margin-top: 20px;
		display: block;

		&:first-child {
			margin-top: 0;
		}
	}

	.settings-file-health {
		padding: 10px 14px;
		margin-bottom: 30px;
		display: flex;

		& > div {
			display: flex;
			flex: 1;
			flex-direction: column;
		}

		.settings-file-health-percentage {
			font-size: 20px;
		}
	}

	.campaign-link {
		color: $bright-blue;
		font-weight: normal;
		cursor: pointer;
	}

	.manager-settigns {
		font-size: 12px;
		line-height: 20px;
		margin: 0 0 5px 0;

		&:last-child {
			margin-top: 5px;
		}

		input[type="radio"] {
			position: relative;
			top: 2px;
		}

		.up-ios-toggle {
			margin-right: 5px;
			vertical-align: top;
			margin-top: 3px;
		}

		.fa {
			margin-left: 5px;
		}
	}

	.centered-border {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: 50%;
			width: 1px;
			border-left: 2px solid $grey-6;
			z-index: 1;
		}
	}

	.admin-card-review .admin-card-content:before {
		top: 0px;
		bottom: 0px;
	}

	.notification-email-input .select2-search-choice {
		background-color: $green !important;
		color: $white !important;
	}

	.admin-card-top {
		&.centered {
			text-align: center;
		}

		&.import-review {
			display: flex;
			justify-content: space-between;
			padding: 0 !important;
		}

		.admin-card-top-text-wrap {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 14px;

			.admin-card-top-description {
				font-weight: normal !important;
			}
		}

		.admin-card-top-image-wrap {
			height: 130px;
			width: 240px;
			overflow: hidden;
			position: relative;

			.admin-card-top-image,
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

			.admin-card-top-image {
				background-image: url("../img/import.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: 80px;
				z-index: 2;
			}

			&:after {
				content: "";
				background: $grey-1;
				transform: skew(-13deg);
				transform-origin: bottom;
				z-index: 1;
			}
		}
	}

	.field-preview,
	.action-item,
	.preview-item,
	.issue-item {
		border-bottom: solid 1px $grey-3;

		&:first-child {
			margin-top: 6px;
			border-top: solid 1px $grey-3;
		}
	}

	.field-preview {
		position: relative;
		font-size: 14px;
		display: block;
		padding: 5px 14px;

		.fa {
			margin-right: 5px;
		}

		.column-name {
			color: $grey-10;
			font-size: 12px;
		}

		.nomatch {
			font-style: italic;
			color: $grey-10;
		}

		.match {
			position: absolute;
			color: $grey-9;
			top: 50%;
			transform: translateY(-50%);
			right: 14px;
			font-size: 12px;
		}
	}

	.action-item {
		font-size: 14px;
		display: block;
		padding: 5px;
		padding-left: 44px;
		position: relative;

		.icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 16px;
			color: $green;
			width: 20px;
			text-align: center;
		}

		.value {
			color: $grey-10;
			font-size: 12px;
		}
	}

	.preview-item {
		font-size: 14px;
		padding: 10px;
		display: flex;
		align-items: center;
		min-height: 48px;

		.Icon {
			color: $grey-10;
			margin: 0 10px;
		}

		&.preview-progress {
			flex-direction: column;
			align-items: flex-start;
			padding-left: 16px;

			.Text {
				color: $grey-10;
				margin-bottom: 4px;
			}

			.Progressbar {
				width: 200px;
				margin-bottom: 4px;
			}
		}

		&.preview-start {
			cursor: pointer;
			color: $green;

			.Icon {
				color: $green;
			}

			&:hover {
				background-color: $grey-1;
			}
		}
	}

	.potential-issues-wrap {
		margin-bottom: 30px;
	}

	.issue-item {
		&.issue-item-expanded {
			background-color: $grey-1;
		}

		.issue-item-content {
			display: flex;
			align-items: center;
			padding: 14px 14px;
			cursor: pointer;

			&:hover {
				background-color: $grey-1;
			}

			.issue-item-title {
				flex: 1;
				padding: 0 10px;
			}
		}

		.issue-item-extended-content {
			border-top: solid 1px $grey-3;
			padding-top: 10px;
			padding-bottom: 10px;

			li {
				margin: 3px 0;
				padding-right: 5px;
			}
		}
	}
}
