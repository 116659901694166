@import "variables";
@import "mixins";

.up-comparison-type-toggle {
	display: inline-block;
	background-color: $green;
	color: $white;
	padding: 1px 5px;
	@include border-radius($border-radius);
	font-size: 13px;
}
