@import "~StyleVariables";

.AdminTicketRules {
	margin-bottom: $space * 35;

	.Help {
		display: inline;
		margin-right: $space * 2;
	}

	&__row {
		height: 50px;
		display: flex;
		align-items: center;

		transition: ease-in-out 50ms background-color;

		cursor: pointer;
		border-bottom: 1px solid $grey-3;

		&--inactive {
			background-color: $grey-1;
		}
	}
}
