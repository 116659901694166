@import "~StyleVariables";

.TimelineRow {
	display: flex;
	margin-bottom: 10px;

	&--hasSubSection {
		.TimelineIcon {
			padding-top: 12px;
		}
	}
}
