@import "~StyleVariables";

.MailTemplateSaveDropdown {
	&__dropdown {
		width: 340px;
	}
	&__form {
		padding: 16px 14px;
		.Input {
			margin-bottom: 16px;
		}
		.Button {
			width: 100%;
		}
	}
	&__checkbox {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 16px;
		.Checkbox {
			position: absolute;
		}
		.Text {
			margin-left: 30px;
		}
	}
}
