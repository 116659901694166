@import "~StyleVariables";

.ProspectingTableHeader {
	position: sticky;
	top: 50px;
	z-index: 2000;
	display: flex;
	align-items: center;
	box-shadow: 0 -1px 0 0 $grey-4, 0 2px 4px 0 rgba(0, 0, 0, 0.2);

	.ButtonSelect {
		margin-right: 20px;
		margin-left: 10px;
	}

	& > .Block {
		display: flex;
	}

	.Tabs {
		white-space: nowrap;
	}

	.Tab {
		text-align: left;
	}
	.Tab.blink {
		background-color: $super-light-green;
		border-bottom: 2px solid $super-light-green;
		color: $green;
	}

	@keyframes bounce {
		from {
			transform: translateX(0px);
		}
		to {
			transform: translateX(-10px);
		}
	}

	.FakeTooltip {
		animation: bounce 1s infinite alternate;
		position: absolute;
		background-color: $green;
		color: $super-light-green;
		font-size: 12px;
		padding: 5px 12px;
		border-radius: 2px;
		left: calc(100% + 12px);
		top: 12px;
		z-index: 10000;

		.FakeTooltip__arrow {
			border-right: 7px solid $green;
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			left: -7px;
			top: calc(50% - 7px);
			position: absolute;
		}

		span {
			white-space: nowrap;
		}
	}
}
