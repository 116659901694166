@import "~StyleVariables";

.SupportList {
	top: 0px;

	&.ListView {
		.TableHeader {
			top: 96px !important;
		}
	}

	.Page {
		top: 0px !important;
	}

	.ListView__header #list-filters-wrap {
		top: 47px !important;
	}

	.ListView__header {
		top: 46px;
	}
}
