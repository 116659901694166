.md-iphone-5 .md-body {
	width: 100%;
	height: 100%;
	border-radius: 3.75em;
	border-style: solid;
	border-width: 0.375em;
	position: relative;
}

.md-iphone-5 .md-screen {
	position: relative;
	margin: 0 auto;
	background-color: $grey-4;
	border-radius: 0.2em;
	overflow: hidden;
	box-shadow: 0 0 0px 2px $grey-4;
}
.md-iphone-5 .md-screen img {
	width: 100%;
}

.md-iphone-5 .md-home-button {
	display: block;
	width: 3.625em;
	height: 3.625em;
	margin: 0 auto;
	position: relative;
	border-radius: 100%;
	border: none;
	cursor: default;
	font-size: 100%;

	#up-logo-wrapper {
		margin-top: 10px;

		.logo-letter {
			fill: $grey-8;
		}
	}
}
// .md-iphone-5 .md-home-button:after {
//   content: "";
//   display: block;
//   width: 1.125em;
//   height: 1.125em;
//   margin: 0 auto;
//   position: relative;
//   border-style: solid;
//   border-width: 0.125em;
//   border-radius: 0.25em;
// }

.md-iphone-5 .md-front-camera {
	width: 0.875em;
	height: 0.875em;
	margin: 0 auto;
	position: relative;
	border-radius: 100%;
}
.md-iphone-5 .md-front-camera:after {
	content: "";
	display: block;
	width: 0.375em;
	height: 0.375em;
	position: relative;
	top: 0.25em;
	left: 0.25em;
	background-color: $dark-blue;
	border-radius: 100%;
}

.md-iphone-5.md-glare:before {
	content: "";
	display: block;
	width: 50%;
	height: 83%;
	position: absolute;
	top: 0.625em;
	right: 0.625em;
	border-radius: 0 3.125em 0 0;
	z-index: 1;
}

// .md-iphone-5:after {
//   content: "";
//   display: block;
//   width: 3.3125em;
//   height: 0.1875em;
//   position: absolute;
//   right: 3.625em;
//   top: -0.1875em;
//   border-radius: 0.125em 0.125em 0 0;
// }

.md-iphone-5 {
	width: 22.8125em;
	height: 47em;
	position: relative;
}
.md-iphone-5 .md-front-camera {
	top: 1.25em;
}
.md-iphone-5 .md-top-speaker {
	width: 3.75em;
	height: 0.875em;
	margin: 0 auto;
	position: relative;
	top: 2.25em;
	border-radius: 3.125em;
}
.md-iphone-5 .md-top-speaker:after {
	content: "";
	display: block;
	width: 3.25em;
	height: 0.375em;
	margin: 0 auto;
	position: relative;
	top: 0.25em;
	background-color: $blue;
	border-radius: 3.125em;
}
.md-iphone-5 .md-screen {
	// width: 20em;
	width: 100%;
	height: 35.5em;
	top: 3.75em;
}
.md-iphone-5 .md-home-button {
	bottom: -5.1em;
}
.md-iphone-5 .md-buttons {
	width: 0.1875em;
	height: 1.875em;
	position: absolute;
	left: -0.5em;
	top: 5.9375em;
	border-radius: 0.125em 0 0 0.125em;
}
.md-iphone-5 .md-buttons:after,
.md-iphone-5 .md-buttons:before {
	content: "";
	display: block;
	position: absolute;
	width: 0.1875em;
	height: 1.5625em;
	border-radius: 0.125em 0 0 0.125em;
}
.md-iphone-5 .md-buttons:after {
	top: 3.125em;
}
.md-iphone-5 .md-buttons:before {
	top: 6.25em;
}
.md-iphone-5.md-glare:before {
	background: -webkit-linear-gradient(16deg, rgba($white, 0) 50%, rgba($grey-3, 0.025) 50%, rgba($grey-5, 0.08));
	background: -moz-linear-gradient(16deg, rgba($white, 0) 50%, rgba($grey-3, 0.025) 50%, rgba($grey-5, 0.08));
	background: -o-linear-gradient(16deg, rgba($white, 0) 50%, rgba($grey-3, 0.025) 50%, rgba($grey-5, 0.08));
	background: linear-gradient(74deg, rgba($white, 0) 50%, rgba($grey-3, 0.025) 50%, rgba($grey-5, 0.08));
}
.md-iphone-5.md-landscape {
	-webkit-transform: rotateZ(90deg);
	-moz-transform: rotateZ(90deg);
	-ms-transform: rotateZ(90deg);
	-o-transform: rotateZ(90deg);
	transform: rotateZ(90deg);
}
.md-iphone-5.md-landscape.md-glare:before {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	transform: rotateY(180deg);
	left: 0.625em;
	top: 0.625em;
}

.md-white-device .md-front-camera,
.md-white-device .md-top-speaker,
.md-white-device .md-home-button {
	// background: -webkit-linear-gradient($grey-8, $grey-4);
	// background: -moz-linear-gradient($grey-8, $grey-4);
	// background: -o-linear-gradient($grey-8, $grey-4);
	// background: linear-gradient($grey-8, $grey-4);
	background: $grey-1;
	border: 1px solid $grey-4;
}

.md-black-device .md-front-camera,
.md-black-device .md-top-speaker,
.md-black-device .md-home-button {
	background: -webkit-linear-gradient($black, $grey-13);
	background: -moz-linear-gradient($black, $grey-13);
	background: -o-linear-gradient($black, $grey-13);
	background: linear-gradient($black, $grey-13);
}

.md-white-device.md-landscape .md-home-button,
.md-white-device.md-landscape .md-front-camera,
.md-white-device.md-landscape .md-top-speaker {
	background: -webkit-linear-gradient(left, $grey-8, $grey-4);
	background: -moz-linear-gradient(left, $grey-8, $grey-4);
	background: -o-linear-gradient(left, $grey-8, $grey-4);
	background: linear-gradient(to right, $grey-8, $grey-4);
}

.md-black-device.md-landscape .md-home-button,
.md-black-device.md-landscape .md-front-camera,
.md-black-device.md-landscape .md-top-speaker {
	background: -webkit-linear-gradient(left, $black, $grey-13);
	background: -moz-linear-gradient(left, $black, $grey-13);
	background: -o-linear-gradient(left, $black, $grey-13);
	background: linear-gradient(to right, $black, $grey-13);
}

.md-white-device .md-body {
	background-color: $white;
	border-color: $white;
	@include box-shadow(0px 10px 25px rgba($black, 0.2));
}
.md-white-device .md-buttons {
	background-color: $grey-4;
}
.md-white-device .md-buttons:after,
.md-white-device .md-buttons:before {
	background-color: $grey-4;
}
.md-white-device .md-front-camera:after {
	background-color: #8c8091;
}
.md-white-device .md-top-speaker:after {
	background-color: #4b414a;
}
.md-white-device .md-home-button:after {
	border-color: #eff1e6;
}
// .md-white-device:after {
//   background: $grey-4;
// }

.md-black-device .md-body {
	background-color: $grey-13;
	border-color: $black;
}
.md-black-device .md-buttons {
	background-color: $grey-13;
}
.md-black-device .md-buttons:after,
.md-black-device .md-buttons:before {
	background-color: $grey-13;
}
.md-black-device .md-front-camera:after {
	background-color: $grey-13;
}
.md-black-device .md-top-speaker:after {
	background-color: $black;
}
.md-black-device .md-home-button:after {
	border-color: $grey-12;
}
.md-black-device:after {
	background: $grey-13;
}
