@import "~StyleVariables";

%ReminderContent {
	max-width: 1000px;
}

%ReminderCardHeader {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	.Title {
		display: flex;
		align-items: center;
		margin-right: 15px;
	}
	.Toggle {
		margin-right: 15px;
	}
}

%ReminderCardTitle {
	display: flex;
}

.Reminders {
	&__content {
		@extend %ReminderContent;
	}

	.TimelineCard__title-section {
		font-size: 18px;
		line-height: 24px;
	}
	.HistoryLog {
		.TimelineRow:last-child {
			.TimelineIcon__line {
				opacity: 0;
			}
		}
	}
	.HistoryLog__row {
		.Title {
			color: $grey-11;
			font-size: 14px;
			line-height: 20px;
		}
	}
	&__card-header {
		@extend %ReminderCardHeader;
	}

	&__card-title {
		@extend %ReminderCardTitle;
	}
	&__created-info {
		background-color: $super-light-blue;
		border-radius: 6px;
		padding: 20px 20px;
	}
	&__call-list-wrap {
		width: 320px;
		min-height: 40px;
		padding-bottom: 4px;

		.Link {
			position: absolute;
			right: -12px;
			top: -1px;
		}

		.PlanPhonecallsDrawerCallListSelect__saveButtons {
			margin-bottom: 8px;
		}
	}
	&__read-more {
		color: $bright-blue;
		display: inline-block;
	}
	.PlanPhonecallsDrawerCallListSelect {
		min-width: 264px;
		.Column {
			flex-basis: auto;
		}
		.Label {
			font-weight: $boldFontWeight;
		}
		.Text {
			display: flex;
			font-size: 16px;
			line-height: 22px;
			align-items: center;
		}
	}

	@media screen and (max-width: 1279px) {
		&__card-header {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		  }
		
		  &__card-title {
			text-align: left;
			margin-bottom: $space * 2;
		  }
	  }
}
