@import "~StyleVariables";
.ContactTimelineCreateContact {
	.TimelineRow,
	.TimelineCard {
		margin-bottom: 0px;
	}
	.TimelineCard__cardHeader--hasSubSection {
		border: 0px;
	}
	.TimelineCard__subSection {
		padding-left: 0px;
	}
}

.ContactRegByInfo {
	display: flex;
	padding: 4px 15px;
	width: 100%;
	border-top: 1px solid $grey-4;
	&__col {
		display: flex;
		flex-direction: column-reverse;
		> .Text:first-child {
			font-size: 14px;
		}
	}
	&__col:first-child {
		margin-right: 32px;
	}
}
