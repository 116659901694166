@import "~StyleVariables";

.ProspectingDrawer {
	top: 100vh;
}

.ProspectingDrawer--visible {
	top: 0;
	opacity: 1;
	visibility: visible;
}

.ProspectingDrawerContent__scrollToResult {
	position: sticky;
	bottom: 0;
	display: none;
	z-index: 2001;
}

.ProspectingDrawerContent__scrollToResult--visible {
	display: block;
}

.ProspectingDrawer .CriteriaTable > table,
.ProspectingDrilldown--industry table {
	table-layout: fixed;
}

@media (min-width: 1950px) {
	.ProspectingDrawerContent__scrollToResult--visible {
		display: none;
	}

	.ProspectingDrawerContent--resizable {
		& > .Block {
			display: inline-block;
			width: 55%;
			vertical-align: top;
		}

		& > .Block:first-child {
			display: inline-block;
			width: 45%;
			vertical-align: top;
		}

		.ProspectingResultTable {
			height: calc(100vh - 100px);
			overflow-y: auto;
		}

		.CriteriaSelectSection {
			height: calc(100vh - 50px);
			overflow-y: auto;
		}

		.CriteriaSelectSection {
			border: none;

			.expanded-wrapper {
				background-color: $grey-4;
			}

			.toggle-button-wrap {
				display: none;
			}

			.footer {
				pointer-events: none;

				.collapse-segment-filter {
					display: none;
				}
			}
		}
	}
}
