@import "variables";
@import "mixins";
@import "animations";

// Colors
$toggleColors: $dark-blue $blue $bright-blue $red $orange $black $green;
$colorNames: toggle-deep-blue toggle-blue toggle-bright-blue toggle-red toggle-orange toggle-black toggle-green;

// Variables
$defaultColor: $bright-blue;
$toggleDisabledOpacity: 0.5;
$toggleSize: 20px;
$toggleSize-sm: 15px;
$toggleSize-xs: 10px;

$animationMs: 100ms;

@mixin up-toggle-color($color, $className) {
	&.checked.#{$className} {
		border-color: $color;
		background-color: $color;
	}
}

@mixin up-toggle-next-color($color, $className) {
	&[disabled]:checked + .up-toggle.#{$className}:hover,
	&:checked + .up-toggle.#{$className} {
		border-color: $color;
		background-color: $color;
	}

	&:checked + .up-toggle.#{$className}:hover {
		background-color: darken($color, 10);
	}
}

/* The class that hides the original checkboxes*/
.up-toggle-hidden-checkbox {
	display: none !important;

	// Checked
	&[disabled]:checked + .up-toggle:hover,
	&:checked + .up-toggle {
		border-color: $defaultColor;
		background-color: $defaultColor;
	}

	&:checked + .up-toggle:hover {
		background-color: darken($defaultColor, 10);
	}

	// Disabled
	&[disabled] + .up-toggle {
		@include opacity($toggleDisabledOpacity);
	}

	// Active
	&:active {
	}

	// Styles
	@each $currentColor in $toggleColors {
		$i: index($toggleColors, $currentColor);
		@include up-toggle-next-color($currentColor, nth($colorNames, $i));
	}
}

.up-toggle {
	display: inline-block;
	position: relative;
	font-size: $toggleSize;
	height: $toggleSize;
	width: 1em;
	display: inline-block;
	background-color: $white;
	border: 1px solid $grey-8;
	vertical-align: middle;
	@include border-radius(1px);
	@include opacity(1);
	@include transition(all ease-in $animationMs);
	@include disable-user-select();

	/* focus */
	&.focus,
	&:focus {
		outline: none;
	}

	/* hover */
	&.hover,
	&:hover {
		background-color: rgba($black, 0.1);

		.toggle-check {
			color: $white;
		}
	}

	/* size: sm */
	&.toggle-xs {
		font-size: $toggleSize-xs;
		height: $toggleSize-xs;
	}

	/* size: sm */
	&.toggle-sm {
		font-size: $toggleSize-sm;
		height: $toggleSize-sm;
	}

	/* checked */
	&.checked {
		border-color: $defaultColor;
		background-color: $defaultColor;

		/* handle (active, checked) */
		&.active,
		&:active {
			// checked active
		}
	}

	// active
	&.active,
	&:active {
		// Border color active here
	}

	/* disabled */
	&.disabled {
		background-color: $grey-4;
		@include opacity($toggleDisabledOpacity);
	}

	.toggle-check {
		position: absolute;
		text-align: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		color: $white;
		font-size: 0.7em;
		line-height: 1.3em;
	}

	.fa-minus {
		color: $bright-blue;
		top: 1px;
	}

	// Styles
	@each $currentColor in $toggleColors {
		$i: index($toggleColors, $currentColor);
		@include up-toggle-color($currentColor, nth($colorNames, $i));
	}
}

// .has-error > .up-toggle button {
// 	.fa.unchecked,
// 	.fa.checked {
// 		color: #b94a48;
// 	}
// }

// .up-toggle {
// 	display: inline-block;

// 	& > button {
// 		padding: 0;
// 		width: 18px;

// 		&:focus {
// 			.fa.unchecked,
// 			.fa.checked {
// 				color: $bright-blue;
// 			}
// 		}

// 		&[disabled] {
// 			.fa.unchecked,
// 			.fa.checked {
// 				color: $grey-8;
// 			}
// 		}

// 		.fa {
// 			font-size: 20px;

// 			&.unchecked,
// 			&.disabled {
// 				color: $grey-8;
// 			}

// 			&.checked {
// 				color: $blue;
// 				font-size: 19px;
// 			}
// 		}
// 	}
// }

/* TODO: fixa så man inte får focus style when disabled */

@mixin up-ios-toggle-color($color, $className) {
	&.checked.#{$className}:not(.no-border) {
		border-color: $color;
		@include box-shadow(0 0 0 1px $color);
	}

	&.#{$className}:before {
		background-color: $color;
	}
}

@mixin up-ios-toggle-next-color($color, $className) {
	&:checked + .up-ios-toggle.#{$className}:not(.no-border) {
		border-color: $color;
		@include box-shadow(0 0 0 1px $color);
	}
}

@mixin up-ios-toggle-size($size, $postfix) {
	&.toggle-#{$postfix} {
		font-size: $size;
		height: $size;

		&.checked .toggle-handle {
			margin-left: -$size;
		}

		.toggle-handle {
			height: $size;
			width: $size;
			@include border-radius($size);
		}
	}
}

@mixin up-ios-toggle-handle-size($size, $postfix) {
	&.toggle-#{$postfix} {
		.toggle-handle {
			margin-left: -$size;
		}
	}
}

// TODO: make classes like .toggle-unchecked-blue

// Variables
$iosToggleCheckedBorder: $blue;
$iosToggleDisabledOpacity: 0.5;
$iosToggleActiveHandleWidth: 1.2em;
$iosToggleActiveCheckedLeft: 1.5em;

$iosToggle-width-constant: 1.7em;
$iosAnimationMs: 200ms;

$iosSize: 18px;
$iosSize-sm: 14px;
$iosSize-xs: 10px;
$iosSize-xxs: 10px;
$iosSize-lg: 22px;
$iosSize-xl: 30px;

/* The class that hides the original checkboxes*/
.up-ios-toggle-hidden-checkbox {
	display: none !important;

	// Checked
	&:checked + .up-ios-toggle {
		border-color: $iosToggleCheckedBorder;

		// Fill
		&:before {
			@include opacity(1);
			@include transform(scale(1));
		}

		&:active .toggle-handle {
			left: $iosToggleActiveCheckedLeft;
		}

		@include up-ios-toggle-handle-size($iosSize-sm, sm);
		@include up-ios-toggle-handle-size($iosSize-xs, xs);
		@include up-ios-toggle-handle-size($iosSize-xxs, xxs);
		@include up-ios-toggle-handle-size($iosSize-lg, lg);
		@include up-ios-toggle-handle-size($iosSize-xl, xl);

		.toggle-handle {
			left: $iosToggle-width-constant;
			margin-left: -$iosSize;
		}
	}

	// Disabled
	&[disabled] + .up-ios-toggle {
		@include opacity($iosToggleDisabledOpacity);
	}

	// Active
	&:active {
		.toggle-handle {
			width: $iosToggleActiveHandleWidth;
		}
	}

	// Styles
	@each $currentColor in $toggleColors {
		$i: index($toggleColors, $currentColor);
		@include up-ios-toggle-next-color($currentColor, nth($colorNames, $i));
	}
}

.up-ios-toggle {
	display: inline-block;
	position: relative;
	font-size: $iosSize;
	height: $iosSize;
	width: $iosToggle-width-constant;
	display: inline-block;
	cursor: pointer;
	background-color: $grey-4;
	vertical-align: middle;
	@include box-shadow(0 0 0 1px $grey-8);
	@include border-radius($up-btn-height);
	@include opacity(1);
	@include transition(border-color ease-in $iosAnimationMs);
	@include transition(opacity ease-in $iosAnimationMs);
	@include disable-user-select();

	/* fill */
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		display: inline-block;
		background-color: $iosToggleCheckedBorder;
		border-radius: $up-btn-height;
		top: 0;
		left: 0;
		@include transition(all ease-in $iosAnimationMs);
		@include opacity(0);
		@include transform(scale(0));
	}

	/* focus */
	&.focus,
	&:focus {
		outline: none;
		// @include box-shadow(0 0 .24em rgba(0,119,255,0.5));
	}

	@include up-ios-toggle-size($iosSize-xl, xl);
	@include up-ios-toggle-size($iosSize-lg, lg);
	@include up-ios-toggle-size($iosSize-sm, sm);
	@include up-ios-toggle-size($iosSize-xs, xs);
	@include up-ios-toggle-size($iosSize-xxs, xxs);

	&.transparent {
		background-color: rgba($white, 0.2);
	}

	&.no-border {
		border: none;
		@include box-shadow(none);
	}

	/* checked */
	&.checked {
		border-color: $iosToggleCheckedBorder;

		/* fill (checked) */
		&:before {
			@include opacity(1);
			@include transform(scale(1));
		}

		/* handle (checked) */
		.toggle-handle {
			left: $iosToggle-width-constant;
			margin-left: -$iosSize;
		}

		/* handle (active, checked) */
		&.active,
		&:active {
			.toggle-handle {
				left: $iosToggleActiveCheckedLeft;
			}
		}
	}

	// active
	&.active,
	&:active {
		// Handle
		.toggle-handle {
			width: $iosToggleActiveHandleWidth;
		}
	}

	/* disabled */
	&.disabled {
		@include opacity($iosToggleDisabledOpacity);
	}

	/* handle */
	.toggle-handle {
		position: absolute;
		left: 0;
		top: 0;
		width: $iosSize;
		height: $iosSize;
		display: inline-block;
		background-color: $grey-1;
		margin-left: 0;
		@include border-radius($iosSize);
		@include box-shadow(0 0.024em 0 rgba(0, 0, 0, 0.2));
		@include transition(all ease-in $iosAnimationMs);
	}

	// Styles
	@each $currentColor in $toggleColors {
		$i: index($toggleColors, $currentColor);
		@include up-ios-toggle-color($currentColor, nth($colorNames, $i));
	}
}
