@import "~StyleVariables";

.DocumentTemplatePreview {
	min-height: 100px;

	&__add-first-row {
		margin: 60px auto 0;
		display: block;
	}

	// inky props
	p,
	h3,
	h2 {
		font-style: normal;
		font-weight: normal;
		hyphens: none;
		line-height: 1.3;
		margin: 0;
		margin-bottom: $space * 2;
		padding: 0;
		text-decoration: none;
		white-space: initial;
	}

	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.text-center {
		text-align: center;
	}

	.MailTemplatePreviewHeader__inner {
		width: 640px;
	}

	.MailTemplatePreviewFooter__inner {
		width: 640px;
	}

	&__row {
		position: relative;
		margin: 0 auto;

		&:after {
			content: "";
			position: absolute;
			width: 640px;
			left: 50%;
			top: 0;
			bottom: 0;
			margin-left: -320px;
			z-index: 999;
		}

		&--dragging {
			box-shadow: 0 0 8px 0 rgba($black, 0.2);
			z-index: 1000;
		}

		&--row-is-full {
			opacity: 0.5;
		}

		&--full-width {
			width: 100%;
			margin: 0;

			&:after {
				left: 0;
				margin-left: 0;
				right: 0;
				width: auto;
			}
		}

		.inline-confirm-container {
			top: 0;
			left: 47%;
			right: auto;
		}

		.DocumentTemplatePreview__row-drag-handle {
			display: inline-block;
			width: 34px;
			height: 34px;
			vertical-align: top;
			line-height: 34px;
			text-align: center;
		}

		.DocumentTemplatePreview__edit-options-row {
			visibility: hidden;
			position: absolute;
			top: 5px;
			left: -80px;
			opacity: 0;
			transition: visibility 0s, opacity 200ms linear;
			z-index: 1003;

			.up-btn.btn-red {
				background-color: $danger-5;
				border: 1px solid $danger-5;

				&:hover {
					background-color: $danger-6;
					border: 1px solid $danger-6;
				}
			}

			.DocumentTemplatePreview__edit-options-row-btn {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;

				&--first {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}

		.DocumentTemplatePreview__row-inner {
			width: 640px;
			margin: 0 auto;
			position: relative;
			z-index: 1000;
			word-break: break-word;
			flex-wrap: nowrap;

			.inline-confirm-modal .inline-confirm-tab {
				left: 0;
				right: auto;
				display: none;
			}
		}
	}

	// Outer column style that can not be applied inside the component css
	&__column--hr {
		border: none !important;
	}

	&__column--image {
		text-align: center;
	}

	&__column--producttable {
		max-width: 100%;
	}

	&__empty-content {
		min-height: 50px;
		border: 1px dashed $green;

		.Text {
			line-height: 48px;
		}
	}

	&__column {
		position: relative;

		&--dragging {
			box-shadow: 0 4px 8px rgba($black, 0.2);
		}
	}
}

// Hovering columns
.DocumentTemplatePreview__column--selected,
.MailTemplatePreviewHeader--selected .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter--selected .MailTemplatePreviewFooter__inner,
.DocumentTemplatePreview__column--hovered,
.DocumentTemplatePreview__column:not(.DocumentTemplatePreview__column--read-only):hover,
.MailTemplatePreviewHeader:not(.MailTemplatePreviewHeader--read-only):hover .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter:not(.MailTemplatePreviewFooter--read-only):hover .MailTemplatePreviewFooter__inner {
	&:before {
		content: "";
		position: absolute;
		border: 1px solid $green;
		border-top: 0;
		border-bottom: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.MailTemplatePreviewHeader--selected .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter--selected .MailTemplatePreviewFooter__inner,
.MailTemplatePreviewHeader:not(.MailTemplatePreviewHeader--read-only):hover .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter:not(.MailTemplatePreviewFooter--read-only):hover .MailTemplatePreviewFooter__inner {
	&:before {
		border: 1px solid $green;
	}
}

// selecting columns
.DocumentTemplatePreview__column--selected,
.MailTemplatePreviewHeader--selected .MailTemplatePreviewHeader__inner,
.MailTemplatePreviewFooter--selected .MailTemplatePreviewFooter__inner {
	&:before {
		box-shadow: 0 0 2px 2px $medium-green inset;
	}
}

//Hovering trash Icon
.DocumentTemplatePreview__row--dragging .DocumentTemplatePreview__edit-options-row,
.DocumentTemplatePreview__row:hover .DocumentTemplatePreview__edit-options-row {
	visibility: visible;
	opacity: 1;
}

.DocumentTemplatePreview__row:not(.DocumentTemplatePreview__row--read-only):hover:before,
.DocumentTemplatePreview__row--dragging:before,
.DocumentTemplatePreview__row--hovered:before,
.DocumentTemplatePreview__row--selected:before {
	content: "";
	position: absolute;
	border: 1px solid $green;
	border-left: none;
	border-right: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
}

.MailTemplatePreviewPadding {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 0px solid rgba($green, 0.2);
	visibility: hidden;
	opacity: 0;
	transition: all ease-out 200ms;
}

.DocumentTemplatePreview__column--hovered,
.DocumentTemplatePreview__column:hover:not(.DocumentTemplatePreview__column--selected) {
	.MailTemplatePreviewPadding {
		visibility: visible;
		opacity: 1;
	}
}

.DocumentTemplatePreview__column--hovered,
.DocumentTemplatePreview__column:hover {
	.MailTemplatePreviewColumnTools {
		visibility: visible;
		opacity: 1;
	}
}

.ck-inline-toolbar {
	position: sticky;
	top: 135px;
	z-index: 1003;
	display: none;
	visibility: hidden;
	align-items: center;
	flex-direction: column;
	height: 0px; // so it wont take any place in the dom and push content

	&--visible {
		display: flex;
		visibility: visible;
	}

	.cke {
		position: absolute;
		top: -45px;
	}
}
