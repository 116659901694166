@import "~StyleVariables";

.ReportcenterDrilldown {
	background-color: $white;

	.TableHeader {
		background-color: $grey-1;
	}

	tr > td:last-child {
		padding-right: 15px;
	}

	&__loader-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__column {
		display: flex;
		align-items: center;
	}

	&__column-text--placeholder {
		font-style: italic;
		color: $grey-10;
	}

	&__column-risks {
		display: flex;
		align-items: center;
		gap: 8px;
		:last-child {
			margin-left: auto;
			cursor: pointer;
		}
		&-dropdown {
			border-top: 0px;
			display: flex;
			flex-direction: column;
			gap: 8px;
			height: auto;
			margin-bottom: 8px;
		}
	}

	&__risks-dropdown-item {
		display: flex;
		align-items: center;
	}

	&__empty-wrapper {
		background-color: $grey-1;
		& td img {
			margin-top: 3rem;
			max-width: 10rem;
		}
	}
}
