@import "~StyleVariables";

.SignalsBar {
	width: 100%;
	height: 8px;
	border-radius: 5px;
	overflow: hidden;

	@keyframes skeleton_animation {
		0% {
			opacity: 0.7;
		}
		50% {
			opacity: 0.4;
		}
		100% {
			opacity: 0.7;
		}
	}

	&__entryBar {
		background-color: #3c9d9b;
		height: 8px;
		border-radius: 5px;

		&--loading {
			width: 100%;
			background-color: $grey-6;
			-webkit-animation: skeleton_animation 1.5s infinite linear;
			animation: skeleton_animation 1.5s infinite linear;
		}

		&:first-of-type {
			border-radius: 5px 0 0 5px;
		}

		&:last-of-type {
			border-radius: 0 5px 5px 0;
		}

		&:only-of-type {
			border-radius: 5px;
		}
	}

	@each $color, $value in $colors {
		&__entryBar--#{$color} {
			background-color: $value !important;
		}
	}
}
