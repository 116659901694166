@import "~StyleVariables";

.CustomFilterSelect {
	display: flex;
	&__fields,
	&__values {
		min-width: 350px;
		max-width: 350px;
		text-overflow: ellipsis;
		max-height: 360px;
		overflow: auto;

		.Input {
			padding-left: 6px;
			padding-right: 6px;
			height: 30px;
		}
	}
	&__info {
		position: sticky;
		bottom: 0;
	}
	&__search {
		padding-bottom: 12px;
		padding-top: 12px;
		position: sticky;
		top: 0;
		background-color: $white;
		z-index: 1;
	}

	&__values {
		.CustomFilterSelect__search {
			position: sticky;
			top: 44px;
		}
	}

	&__header {
		position: sticky;
		top: 0;
		display: flex;
		align-items: center;
		text-align: center;
		min-height: 44px;
		border-bottom: 1px solid $grey-1;
		background-color: $white;
		padding-left: 6px;
		padding-right: 6px;
		z-index: 1;

		&:hover {
			background-color: $grey-1;
			cursor: pointer;
		}
		.Icon {
			height: 20px;
			display: flex;
			align-items: center;
			margin-right: 6px;
		}
		.Label {
			margin: 0;
			width: 100%;
		}
	}

	&__divider {
		width: 1px;
		min-height: 100%;
		background: $grey-6;
	}
}
