@import "~StyleVariables";

.NavbarSubMenuDropdown {
	box-shadow: $box-shadow-xl;
	border-radius: $border-radius;
	background-color: $white;
	visibility: hidden;
	position: absolute;
	opacity: 0;
	transition: $transition-small-bounce;
	top: calc(100% + #{$space});
	transform: translateY(-10px);
	width: 250px;
	max-height: 60vh;
	overflow: auto;

	&--open {
		visibility: visible;
		opacity: 1;
		transform: translateY(0px);
	}

	&--right {
		right: $space;
	}

	&--left {
		left: $space;
	}
}
