@import "../../../../../../styles/_variables.scss";

@mixin visible-to-hidden-transition {
	visibility: visible;
	opacity: 1;
	transition-property: visibility, opacity;
	transition-duration: 0ms, 100ms;
	transition-timing-function: unset, ease-out;
	transition-delay: 0ms, 200ms;
}

@mixin hidden-to-visible-transition {
	visibility: hidden;
	opacity: 0;
	transition-delay: 100ms, 0ms;
}

.EasyBookingUserRow {
	&__booking-limit {
		text-align: end;
		grid-column-end: -2;
	}

	&__caret {
		grid-column-end: -1;
		padding: 0 12px;
	}

	&__name-column {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 40px;
		// margin-left: 12px;
		overflow: hidden;
		width: 100%;

		> .Row {
			justify-self: center;

			> .Text {
				user-select: none;
			}
		}
	}

	&__inactive-info {
		width: 100%;
		height: 18px;
		transition: height 300ms cubic-bezier(0.86, 0.62, 0.46, 1.3);
		justify-self: flex-end;

		> .Text {
			transition: transform 300ms cubic-bezier(0.86, 0.62, 0.46, 1.3);
			transform: translateY(0);
			user-select: none;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
		}

		&--hidden {
			height: 0;

			> .Text {
				transform: translateY(20px);
			}
		}
	}

	&__inactive-avatar-badge {
		position: relative;

		> .Icon {
			@include visible-to-hidden-transition;
			position: absolute;
			bottom: 0;
			left: -8px;
			font-size: 10px;
			box-shadow: $box-shadow-md;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background-color: $super-light-yellow;
			padding: 4px;
		}

		> .generic-avatar {
			margin: 0 !important;
		}

		&--hidden {
			> .Icon {
				@include hidden-to-visible-transition;
			}
		}
	}

	&__inactive-badge {
		margin-left: 10px;
		@include visible-to-hidden-transition;

		&--selected {
			@include hidden-to-visible-transition;
		}

		> div {
			display: flex;
			gap: 4px;
			justify-content: space-around;
			align-items: center;
			height: 22px;
			width: auto;
			background-color: $super-light-yellow;
			color: $black;
			border-radius: $border-radius;
			padding: 0 4px;

			> .Icon {
				font-size: 10px;
			}
		}
	}

	&__user-row {
		display: grid;
		grid-template-columns: 20px minmax(0, auto) min-content 42px;
		align-items: center;
		height: 40px;
		border-bottom: 1px solid $grey-2;
		cursor: pointer;
		padding: 0 0 0 32px;
		width: 100%;
		box-sizing: border-box;

		> .Row {
			flex-shrink: 2;
			flex-wrap: nowrap;
			gap: 10px;
		}
	}
}
