@import "~StyleVariables";

.ClientCardActivities {
	&__header {
		padding: 15px;
	}

	.TableRow {
		&__date,
		&__type,
		&__contacts,
		&__iconCol {
			white-space: nowrap;
		}

		&__contacts .Text {
			font-size: 12px;
		}

		&__users .Text {
			line-height: 20px;
		}

		&__type {
			padding-right: 8px;

			.Text {
				line-height: 1.1em;
			}

			.Tooltip .Text {
				font-size: 11px;
			}
		}

		&__iconCol {
			z-index: 1;
			width: 0px;
			font-size: 14px;
			> * {
				margin-right: -10px;
			}
		}

		&__todoTools {
			width: 80px;

			.Button.btn-grey > .Icon {
				color: $grey-8;
				&:hover {
					color: $grey-10;
				}
			}
		}
	}

	&--open {
		z-index: 1;
	}

	&--past .TableRow {
		&__date .Text {
			color: $grey-12;
		}

		&:hover {
			background-color: $grey-2;
		}
	}

	.TableRow--delayed td:first-child:before {
		content: "";
		position: absolute;
		background-color: $red;
		width: 4px;
		top: -1px;
		bottom: -1px;
		left: 0;
	}
}
