.GoalsHeader {
	display: flex;
	.Icon {
		font-size: 18px;
		margin-right: 16px;
	}
	.Icon-calendar {
		margin-top: 19px;
	}
	.Icon-phone,
	.Icon-opportunity {
		margin-top: 20px;
	}
	&__text-wrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		.Title {
			margin-bottom: 5px;
		}
	}
	&__title-wrap {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		.Button {
			padding-left: 5px;
			height: 29px;
			&:hover {
				.Icon {
					opacity: 1;
				}
			}
			.Icon {
				transition: all ease-in-out 200ms;
				opacity: 0;
				width: 20px;
				margin-right: 0;
			}
		}
	}
	&__subtitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__buttons {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		min-width: 200px;
		margin-right: 20px;
		.Button {
			text-decoration: none;
		}
	}
}
