@import "~StyleVariables";

.ForecastingDrawer {
	--header-height: 50px;

	&__wrapper {
		background-color: $white;
		height: 100%;

		.SalesboardFilterButton:last-child {
			.SelectUserGroup__search-wrap {
				display: none;
			}
		}
	}
	width: 1000px;
	background-color: $grey-2;
	visibility: visible;
	height: 100%;

	&__data-wrapper {
		display: flex;
		padding-top: 16px;

	}

	&__legend-wrapper {
		display: flex;
		align-items: center;
	}

	&__content {
		height: calc(100% - var(--header-height));
		overflow-y: auto;
		overflow-x: hidden;

		
	}
	&__main-block {
		padding: 20px 40px;

	}

	&__help-icon {
		margin-left: 8px;
	}


	&__special {
		border-radius: 8px;
		background: $super-light-blue;
		border-color: rgba($medium-blue, 0.1) !important;
		padding-left: 16px;
		
		&:hover {
			border-color: $blue !important;
			box-shadow: 0 2px 4px 0 rgba($black, 0.2);
		}
		
		.Text {
			color: $blue;
		}
		&--success {
			background: $success-1;
			border-color:  rgba($success-5, 0.1) !important;
			.Text {
				color: $success-6;
			}
			&:hover {
				border-color: $success-6 !important;
			}
		}
		&--warning {
			background: $super-light-red;
			border-color: rgba($red, 0.2) !important;
			.Text {
				color: $dark-red;
			}
			&:hover {
				border-color: $dark-red !important;
			}
			
		}
	}

	.Loader {
		width: 1000px;
		margin-top: 300px;
	}

	.DrawerHeader {
		z-index: 2;
	}
	.ForecastingFilters {
		display: flex;
	}

	.SalesboardFilterButton .DropDownButton {
		border-radius: 0;
	}

	&__legend-wrapper-pipeline {
		display: flex;
		align-items: center;
		margin-left: -16px;
		padding-top: 4px;
	}
}
