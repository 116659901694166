@import "@upsales/components/Utils/colors";
@import "@upsales/components/Utils/variables";

.FeatureIntroModal {
	margin-top: 80px;

	&__loader {
		margin: 100px auto;
	}
	&__inner {
		margin: 0 auto;
		max-width: 900px;
		margin-top: 80px;
	}

	&__title {
		margin-bottom: 12px;
	}
	&__description {
		margin-bottom: 45px;
	}
	.Row {
		margin: 0 -20px;
	}
	.Column {
		padding: 0 20px;
	}

	&.new-full-screen {
		@keyframes slide-from-bottom {
			from {
				top: 100vh;
			}
			to {
				top: 0;
			}
		}
	
	}
		box-shadow: (0 0 0 0);
		animation: 600ms slide-from-bottom;
		border: none;
		background-color: $white;
		padding-top: 44px;
		margin-left: 0;
		width: 100%;
		min-height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: fixed;
		overflow: auto;
		z-index: $modal-z - 2;

	.exit-button {
		position: fixed;
		top: 10px;
		right: 14px;
		color: $grey-10;
		background: rgba($grey-4, 0.6);
		border-radius: 100%;
		width: 34px;
		height: 34px;
		border: none;
		padding: 0;
		z-index: 10000;
		//@include transition(background 0.3s);
		transition: (background 0.3s);
		text-shadow: none;

		&:hover {
			background: rgba($grey-4, 1);
			// background: rgba(228, 234, 240, 1);
		}

		.fa {
			width: 34px;
			text-align: center;
			// color: #586881;
			font-size: 16px;
		}
	}

	&.new-full-screen {
		@keyframes slide-from-bottom {
			from {
				top: 100vh;
			}
			to {
				top: 0;
			}
		}

	}
		box-shadow: (0 0 0 0);
		animation: 600ms slide-from-bottom;
		border: none;
		background-color: $white;
		padding-top: 44px;
		margin-left: 0;
		width: 100%;
		min-height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: fixed;
		overflow: auto;
		z-index: $modal-z - 2;

	.exit-button {
		position: fixed;
		top: 10px;
		right: 14px;
		color: $grey-10;
		background: rgba($grey-4, 0.6);
		border-radius: 100%;
		width: 34px;
		height: 34px;
		border: none;
		padding: 0;
		z-index: 10000;
		//@include transition(background 0.3s);
		transition: (background 0.3s);
		text-shadow: none;

		&:hover {
			background: rgba($grey-4, 1);
			// background: rgba(228, 234, 240, 1);
		}

		.fa {
			width: 34px;
			text-align: center;
			// color: #586881;
			font-size: 16px;
		}
	}
}