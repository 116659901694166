.MultiCloseActivity {
	.ModalHeader {
		border-top: none;
	}

	.ModalControls {
		z-index: 1;
	}

	.CustomFields .Label {
		font-weight: unset;
	}

	.CustomFields__formGroupTitle {
		margin-bottom: 8px;
	}

	&__notes .Textarea__input {
		height: 148px;
	}
}
