@import "~StyleVariables";

#contact-card {
	@include card(97px);
	position: relative;

	&:not(.has-timeline) {
		background-color: $grey-2;
	}

	.NotesFilterButtonContainer {
		display: inline;
	}

	#add-button {
		margin-top: 8px;
	}

	#card-sidebar {
		background-color: $white;

		&::before {
			content: "";
			width: $card-sidebar-width;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			background-color: $white;
			border-right: 1px solid rgba($grey-6, 1);
		}

		.email-error {
			a,
			b,
			i {
				color: $red;
			}
		}

		.email-error,
		.email-warn {
			.tooltip {
				width: 65%;
			}
		}

		.email-warn {
			a,
			b,
			i {
				color: $orange;
			}
		}

		.title {
			text-align: center;
			border-bottom: 0;

			#avatar {
				display: block;
				width: 100%;

				.generic-avatar {
					float: none;
					margin: 0 auto;
					border: none;
				}
			}

			.with-title-category {
				.contact-title {
					color: $grey-10;
				}
			}

			.contact-title {
				display: inline;
			}

			& > .subtitle {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.sidebar-section {
			margin: 0 -15px;
			padding: 10px 15px;

			.sidebar-section-heading,
			.sidebar-section-row {
				display: flex;
				justify-content: space-between;
			}

			&.no-bottom-line {
				border-bottom: 0;
				padding-bottom: 0;
			}

			.sub-header {
				color: $grey-10;
				text-transform: uppercase;
			}

			.sidebar-item:not(.optin) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: block;

				& > div {
					display: inline;
				}
			}

			&.sidebar-notes-section {
				.notes-empty {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					color: $grey-8;
					transition: all ease-out 200ms;
					padding-left: 0;

					font-style: italic;
					border-radius: $border-radius;

					i {
						width: 20px;
					}

					&:hover {
						i {
							color: $grey-10;
						}
						color: $grey-10;
						background: $grey-2;
						padding: 8px;
					}
				}
			}

			&.optin-section {
				&.ng-hide-animate {
					display: none !important;
				}

				&.active {
					#optin-false {
						display: none;
					}

					#optin-true {
						display: block;
					}

					#optin-pending {
						display: none;
					}
				}

				&.pending {
					#optin-false {
						display: none;
					}

					#optin-true {
						display: none;
					}

					#optin-pending {
						display: block;
					}
				}

				&.inactive {
					#optin-false {
						display: block;
					}

					#optin-true {
						display: none;
					}

					#optin-pending {
						display: none;
					}
				}

				.sidebar-item {
					&:after {
						clear: both;
						content: "";
						display: block;
					}

					p {
						width: 75%;
						float: left;

						&.message {
							margin: 0;
						}
					}

					.square-holder {
						display: table;
						float: left;
						width: 16px;
						height: 16px;
						background-color: $grey-4;
						margin-right: 10px;
						border-radius: 2px;

						i.fa {
							vertical-align: middle;
							display: table-cell;
							text-align: center;
							font-size: 10px;
						}

						&.green {
							background-color: $bright-green;
							color: $white;
						}

						&.orange {
							background-color: $orange;
							color: $white;

							i {
								font-size: 16px;
								line-height: 16px;
								width: 20px;
								height: 16px;
								text-align: center;
								position: relative;
								display: table-cell;
								vertical-align: middle;
							}
						}
					}

					.arrow-holder {
						display: table;
						float: right;
						width: 24px;
						height: 24px;

						i.fa {
							vertical-align: middle;
							display: table-cell;
							-ms-flex-line-pack: center;
							text-align: center;
							font-size: 16px;
						}
					}

					&.optin {
						// transition: opacity .4s;
						margin: 8px 0 4px;

						.arrow-holder {
							opacity: 0;
							// transition: opacity 400ms ease;
						}

						&:hover {
							cursor: pointer;

							.arrow-holder {
								opacity: 1;
							}
						}

						&.no-cursor {
							&:hover {
								cursor: text;
							}
						}

						.expanded-section {
							// transition: opacity .4s;
							opacity: 0;
							z-index: -1;
							position: absolute;
							float: left;
							width: 100%;
							margin: 15px 0 0;

							h4 {
								font-size: 14px;
								margin: 2px 0;
							}

							p {
								width: 100%;
								margin: 2px 0;
							}
						}

						&.expanded {
							.expanded-section {
								opacity: 1;
								bottom: 0;
								z-index: 400;
								position: relative;
							}
						}
					}
				}
			}
		}

		.first-touch-section {
			overflow: hidden;
			transition: height ease 300ms;

			.toggle-btn {
				&:hover {
					text-decoration: none;
				}
			}

			&.collapsed {
				height: 80px;
			}

			&.expanded {
				height: 140px;
				cursor: auto;
			}

			.referral-link {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				width: 100%;
			}

			.face-touch-icon {
				background: $white !important;
				width: 20px !important;
				height: 20px !important;
			}
		}
	}

	#card-main-view {
		margin-top: 50px !important;
		overflow-x: hidden;
		background-color: $grey-2;

		#top-section {
			border-bottom: none !important;
			margin: 0 !important;
		}

		@media (max-width: 1355px) {
			.timeline-cat-divider .timeline-filter-group .btn-group button {
				span:not(.no-hide) {
					display: none;
				}

				b {
					display: inline-block;
				}
			}
		}

		#sales-chart {
			height: 200px;
			width: 100%;
			background-color: $white;
			display: -webkit-inline-box;
			margin-right: 20px;
			box-shadow: 0 2px 4px rgba($black, 0.2);

			@media (max-width: 1180px) {
				.market-toggle {
					left: 170px !important;
				}
			}

			.market-toggle {
				position: absolute;
				left: 270px;
				top: 20px;

				&:hover {
					background-color: rgba($white, 0.8);
				}

				button {
					background-color: rgba($grey-8, 0.2);
					border: 1px solid rgba($grey-10, 0.2);
					color: $grey-10;

					&:first-child {
						border-radius: 5px 0 0 5px;
					}

					&:last-child {
						border-radius: 0 5px 5px 0;
					}

					&.active {
						background-color: $white;
						color: $bright-blue;
					}
				}
			}

			.circle {
				width: 10px;
				height: 10px;
				background: $bright-green;
				border-radius: 10px;
				float: left;
				margin-right: 5px;
				margin-top: 3px;
			}

			.title {
				text-transform: uppercase;
				color: $grey-10;
				font-size: 12px;
			}

			.sales-widget {
				height: 205px;
				width: 210px;
				padding: 30px 0 0 30px;
				background-color: $white;

				.content {
					margin: 0;

					.currency {
						font-size: 12px;
						margin-top: 2px;
						margin-left: 2px;
						color: $grey-10;
						display: inline-block;
					}

					.value {
						font-size: 22px;
						display: inline-block;
					}

					.next-date {
						font-size: 12px;
						color: $grey-10;
					}
				}

				.up-icon-recurring-order {
					color: $bright-green;
					float: left;
					margin-right: 3px;
				}

				.year-sum {
					height: 35%;
				}

				.next-agreement {
					height: 51%;

					&.no-re-order {
						.re-order-placeholder {
							display: block;
						}

						.re-order-info {
							display: none;
						}
					}

					.re-order-placeholder {
						display: none;
						color: $grey-8;
						margin-top: 20px;
					}
				}

				.contact-title {
					max-width: 180px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: inline-block;
					vertical-align: bottom;
				}
			}

			.chart {
				position: absolute;
				right: 0;
				left: 250px;
				top: 70px;
				height: 135px;
			}

			#green-line {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $bright-green;
				left: 0;
				top: 200px;
				z-index: 1;
			}

			@media (max-width: 1180px) {
				& {
					.sales-widget {
						width: 145px !important;

						.title {
							width: 0px;
							height: 19px;
							overflow: hidden;
							display: block;
							white-space: nowrap;
						}

						.next-agreement {
							.re-order-placeholder {
								font-size: 12px;
							}
						}
					}

					.chart {
						left: 145px !important;
					}
				}
			}
		}

		#sales-chart-cm {
			@extend #sales-chart;
			height: 300px;

			.sales-widget {
				height: 265px;

				.content {
					margin: 0;

					.value {
						margin-left: 15px;
					}

					.rounded-square {
						width: 10px;
						height: 10px;
						border-radius: 2px;
						float: left;
						margin-right: 5px;
						margin-top: 3px;
					}

					.bright-green {
						background: $bright-green;
					}

					.medium-green {
						background: $medium-green;
					}
				}
				.year-sum {
					height: 30%;
				}
			}

			.chart {
				top: 85px;
			}

			#green-line {
				top: 265px;
			}
		}

		.sub-header {
			h4 {
				display: inline-block;
				vertical-align: middle;
			}
		}

		#sales-tables {
			margin-top: -5px;
			background-color: $grey-1;
			border-top: 1px solid $grey-6;

			.sub-header {
				.sub-header-first {
					display: inline-block;
				}

				.sub-header-last {
					cursor: pointer;
					display: inline-block;
					float: right;
					height: 40px;
					padding-top: 10px;
				}
			}

			.agreement-toggle {
				margin-right: 10px;

				.toggle-label {
					float: right;
					padding-top: 2px;
					margin-left: 5px;
					color: $bright-blue;
				}
			}

			.main-table {
				th {
					background-color: $grey-1;
					border-top: 0;
					border-left: 0;
				}

				.th-style {
					td {
						background-color: $grey-1;
						border-top: 0;
						border-left: 0;
					}
				}

				td {
					height: 40px;
				}

				tr {
					h4 {
						display: inline-block;
						vertical-align: middle;
					}

					.grey {
						color: $grey-10;
						vertical-align: middle;
						font-size: 10pt;
					}

					&.table-section {
						background-color: $grey-1;
					}

					.select2-arrow {
						border: none;
					}
				}
			}

			// .account-manager-wrapper:focus {
			// 	.hide-input {
			// 		display:block;
			// 	}
			// }

			.visit-adress-icon {
				position: absolute;
				right: 10px;
				top: 0px;

				&.has-address {
					top: 20px;
				}
			}
		}

		.list-wrap {
			border-top: none;
			margin-top: 0 !important;
			padding-bottom: 10px;

			#title-dashboard {
				margin-bottom: 5px;
				text-transform: uppercase;
				font-size: 12px;
				color: $grey-10;
			}

			@media (max-width: 1120px) {
				#dashboard-navbar {
					display: none;
				}
			}

			.dashboard-button-group {
				margin: 0px 30px;
				position: relative;
				z-index: 1;
			}

			#dashboard-navbar {
				margin-top: 25px;
				margin-left: 35px;
				> .btn-group {
					border: 1px solid $grey-4;
					background-color: $white;
					.up-btn {
						border: 0;
						padding: 0;
						margin-right: 0;
						> .btn-content {
							padding-left: 20px;
							padding-right: 20px;
						}
					}
					> .dropdown {
						display: inline-block;
					}
				}
			}
			.dashboard-button-group {
				margin: 0px 30px;
				position: relative;
			}
		}

		.DashboardPinnedComments {
			margin-top: 24px;
		}

		#market-chart {
			height: 265px;
			background-color: $white;
			box-shadow: 0 2px 4px rgba($black, 0.2);
			position: relative;

			.placeholder {
				color: $grey-8;
				text-align: center;
				margin-top: 88px;
				display: block;
			}

			.loading {
				text-align: center;

				span {
					color: $grey-8;
					margin-top: 120px;
				}
			}

			background-color: $white;

			.date-toggle,
			.market-toggle {
				position: absolute;
				left: 490px;
				top: 20px;

				// &.market-toggle {
				// 	left: 270px;
				// }

				&:hover {
					background-color: rgba($white, 0.8);
				}

				button {
					background-color: rgba($grey-8, 0.2);
					border: 1px solid rgba($grey-10, 0.2);
					color: $grey-10;

					&:first-child {
						border-radius: 5px 0 0 5px;
					}

					&:last-child {
						border-radius: 0 5px 5px 0;
					}

					&.active {
						background-color: $white;
						color: $bright-blue;
					}
				}
			}

			.toggle-buttons {
				text-align: center;
				padding-top: 20px;

				button {
					background-color: rgba($grey-8, 0.2);
					border: 1px solid rgba($grey-10, 0.2);
					color: $grey-10;

					&:first-child {
						border-radius: 5px 0 0 5px;
					}

					&:last-child {
						border-radius: 0 5px 5px 0;
					}

					&.active {
						background-color: $white;
						color: $bright-blue;
					}
				}
			}

			.market-toggle {
				left: 270px;
			}

			.sales-widget {
				height: 220px;
				padding-top: 5px;
				width: 220px;
				border-right: 0.5px solid $grey-8;
				background-color: $white;

				.event-sum {
					height: 23%;
					border-bottom: 1px solid $grey-8;

					.content {
						margin: 5px 5px 5px 10px;

						.title {
							font-size: 10px;
							white-space: nowrap;
							text-transform: uppercase;
							color: $grey-10;
						}

						.icon {
							float: left;
							margin: 10px;
							font-size: 15px;
							color: $bright-blue;

							&.fa-globe {
								color: $blue;
							}
						}

						.value {
							float: none;
							font-size: 20px;
						}

						@media (max-width: 1475px) {
							position: relative;

							.icon {
								margin-top: 2px;
								margin-left: 2px;
							}
							.value {
								position: absolute;
								top: 20px;
								font-size: 17px;
								max-width: 70px;
								left: 0;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}

					.event-text {
						overflow-x: hidden;
						text-overflow: ellipsis;
					}
				}

				.event-sum:last-child {
					.content {
						margin-left: 10px;
					}
				}

				.up-icon-recurring-order {
					color: $bright-green;
					float: left;
					margin-right: 3px;
				}

				.year-sum {
					height: 35%;
					border-bottom: 0.5px solid $grey-6;
				}

				.next-agreement {
					height: 51%;
				}

				.event-sum:last-child {
					border-bottom: 0;
				}
			}

			@media (max-width: 1475px) {
				.market-toggle {
					left: 120px;
				}

				.date-toggle {
					left: 340px;
				}

				.sales-widget {
					width: 100px;

					.event-sum {
						.title {
							display: none;
						}
					}
				}
			}

			.chart {
				position: absolute;
				height: 135px;
				top: 57px;
				right: 0;
				left: 0;

				@media (max-width: 1475px) {
					left: 0;
					right: 0;
				}
			}

			#green-line {
				position: absolute;
				background-color: $blue;
				top: 220px;
				width: 100%;
				height: 2px;
				left: 0;
				z-index: 1;
			}

			.tool {
				position: absolute;
				right: 0px;
				top: 12px;

				i {
					color: $grey-10;
				}

				.dropdown-menu {
					z-index: 22;

					li {
						margin: 10px 5px 11px 10px;
						padding-right: 34px;

						.content {
							display: inline-block;
							white-space: nowrap;
							width: calc(100% + 30px);
						}
					}
				}

				@media (max-width: 1475px) {
					right: 0;
				}
			}
		}
	}

	$addressCardWidth: 352px;

	.addressCard {
		h2 {
			font-size: 18px;
			color: $blue;
		}

		position: relative;
		float: left;
		background-color: $bright-blue;
		width: $addressCardWidth;
		margin-left: 15px;
		margin-top: 10px;
		margin-bottom: 10px;
		border: 1px solid $grey-8;

		.addressText {
			background-color: $white;
			margin-left: 3px;
			padding: 15px;
			height: 157px;
			width: $addressCardWidth - 5px;

			textArea {
				resize: none;
				border: 0px;
				margin-top: 7px;
				width: 250px;

				&:disabled {
					background-color: transparent;
				}
			}
		}

		.remove {
			display: none;
			position: absolute;
			right: 5px;
			top: 5px;
			padding: 5px;
			font-size: 11px;
			width: 25px;
		}
	}

	.big-map-canvas {
		width: 100%;
		height: 400px;
	}

	.addressCard-showAll {
		@extend .addressCard;
		width: $addressCardWidth - 240px;

		.addressText {
			width: $addressCardWidth - 245px;
		}
	}

	.first-touch-timeline {
		display: flex;

		.first-touch-timeline-row {
			margin-right: 40px;
			max-width: 300px;
			overflow: hidden;

			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				width: 100%;
			}
		}
	}
}
.edit-contact-modal {
	.up-modal-header {
		z-index: 10008;
	}

	.iti {
		width: 100%;
	}

	.contacts-email {
		z-index: 10;
	}

	.NumberInput {
		.Input {
			width: 100%;
		}
	}

	.email-suggestion {
		padding: 5px 15px;
		z-index: 0;
		background-color: $grey-4;
		border: 1px solid $grey-6;
		border-radius: 0 0 3px 3px;
		position: relative;
		.btn {
			float: right;
			width: 65px !important;
			margin-top: -4px;
		}
		.fa-info-circle {
			float: right;
			margin-right: 5px;
			margin-top: 5px;
		}
		.email-section {
			display: inline-grid;
			max-width: 70%;
			word-break: break-all;
		}
		.insert-section {
			max-width: 30%;
			float: right;
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}

	.email-suggestion-warning {
		border: 1px solid $grey-6;
		background: $white;
		font-size: 12px;

		.section-row {
			display: flex;
			justify-content: space-between;
			border-top: 1px solid $grey-6;
			padding: 15px;

			&.no-border {
				border: none;
			}

			&.no-padding-top {
				padding-top: 0;
			}

			.section-cell {
				display: flex;
				flex-direction: column;
				padding-right: 10px;

				&-holder {
					display: flex;
					align-items: center;
				}
			}

			&.contact-info {
				padding: 5px 15px;
			}
		}

		.contact-info {
			justify-content: space-between;
			align-items: center;
			font-size: 14px;

			.fa {
				&.fa-user {
					font-size: 22px;
				}

				&.fa-edit {
					opacity: 0;
					transition: opacity 1s;
				}
			}

			&:hover {
				.fa {
					&.fa-edit {
						opacity: 1;
					}
				}
			}

			a:hover {
				color: $bright-blue;
			}

			.contact-link {
				color: $black;
				font-size: 14px;
				margin-bottom: 4px;
			}

			.account-link {
				color: $grey-10;
				font-size: 12px;
			}

			.first,
			.second,
			.third {
				height: auto;
				line-height: 16px;
				font-size: 10px;
				min-width: 23px;
			}

			.lead-status {
				display: flex;

				.indicator-wrapper {
					margin-right: 10px;
				}
			}

			.edit-btn {
				color: $white;
				background-color: $green;
				border-radius: $border-radius;
				border: none;
				padding-left: 2 * $space;
				padding-right: 2 * $space;
				padding-top: $space;
				padding-bottom: $space;
			}
		}
	}

	.show-email-suggestion {
		animation: bounceInDown 1s;
	}

	.hide-email-suggestion {
		animation: bounceInUp 1s;
	}

	.save-btn-container {
		display: inline-block;
	}
}

@keyframes bounceInDown {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0);
	}
	50% {
		opacity: 0.5;
	}
	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	50% {
		opacity: 0.5;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -30px, 0);
		transform: translate3d(0, -30px, 0);
	}
}
