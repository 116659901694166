@import "~StyleVariables";

.ChangeContact {
	.ButtonSelect {
		width: 100%;
	}

	.ButtonSelect .Button {
		overflow: hidden;

		.Text {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}
	}

	.DropDownMenu__content {
		width: 390px;

		.DropDownMenu {
			width: 100%;

			.DropDownMenu__content {
				width: fit-content;
				margin-top: $space;
			}
		}
	}

	&__result {
		max-height: 200px;
		overflow-y: scroll;

		.ClickableItem {
			border-bottom: $border;
			width: 100%;
			text-align: left;
		}
	}

	&__menuStageItem {
		.ClickableItem__icon-col {
			max-width: 38px;
		}
	}

	&__addContact {
		position: sticky;
		bottom: 0;
	}

	&__email {
		width: 100%;
	}

	&__titleRow {
		height: 60px;
		border-bottom: 1px solid $grey-4;
		transition: all ease-out 300ms;
	}

	&__row {
		height: 60px;
		border-bottom: 1px solid $grey-4;
		transition: all ease-out 300ms;
		cursor: pointer;

		&--highlighted,
		&:hover {
			background-color: $grey-2;
		}

		& > .Column:last-child {
			overflow: hidden;
		}

		&__title {
			word-break: break-all;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 100%;
			white-space: nowrap;
		}

		&__subtitle {
			display: block;
		}
	}
	&__userInfo {
		overflow: hidden;
	}
}
