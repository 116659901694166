@import "~StyleVariables";


$ballHeight: 25px;


.EsignInvolvees {

    display: flex; 
    gap: 8px;

    height: $ballHeight;

    &__ballWrapper {

    }

    .OverEightSingular {
        .Avatar:hover {
            cursor: default;
        }
    }

    .Avatar {
        height: $ballHeight;
        width: $ballHeight;

        .Avatar--initials {
            line-height: $ballHeight;
        }

        .Avatar--gravatar {
            display: none;
        }


        &:before {
            content: "";
            position: absolute;
            width: 1em;
            height: 2px;
            background-color: inherit;
            left: 100%;
            top: 45%;
        }
    }

    .Tooltip {
        &:last-of-type {
            .Avatar {
                &:before {
                    display: none;
                }
            }
        }
    }

    &__involvee {
        &--notClickable {
            cursor: default;
        }
        &--signed {
            background-color: $success-5;
        }
        &--declined {
            background-color: $red;
        }
    }
}
