@import "~StyleVariables";

.NoticePeriod {
	border-radius: $border-radius;
	padding: 16px;
	margin-bottom: 16px;

	&--toggleClosed {
		cursor: pointer;
	}
	&__addNoticePeriod {
		display: flex;
		justify-content: flex-start;

		.Text {
			display: inline;
			margin-left: 8px;
			cursor: pointer;
		}
		display: flex;
		justify-content: flex-start;
	}

	&__selectDate {
		display: flex;
		align-items: center;

		.Text:first-child {
			white-space: nowrap;
		}

		.NumberInput {
			width: 48px;
			margin-left: 4px;
			margin-right: 4px;
		}
	}
}
