@import "~StyleVariables";

.RatingLabel {
    background-color: $grey-8;
    width: fit-content;
    float: right;
    font-size: 10px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: $border-radius;

    &--danger {
        background-color: $red;
    }

    &--warning {
        background-color: $orange;
    }

    &--success {
        background-color: $bright-green;
    }
}