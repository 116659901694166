#main-content {
	&.loading-view {
		#advanced-search-wrapper #advanced-search-form-wrap #advanced-search-form {
			#advanced-search-load-curtain {
				opacity: 1;
				visibility: visible;
			}

			.AdvancedSearchFooter .ButtonGroup {
				opacity: 0;
				visibility: hidden;
				transition: all ease 0ms;
			}
		}
	}
}

#advanced-search-wrapper {
	$animationMs: 150ms;
	$headerHeight: $header-height-sm;
	$footer-height: 50px;
	$as-sidebar-width: 260px;

	.nav {
		height: 100%;

		& > li {
			height: 100%;

			& > a {
				height: 100%;
				display: flex;
				align-items: center;

				.fa {
					margin-right: 4px;
				}
			}
		}
	}

	.up-header.up-header-sm ul.nav > li > a {
		&:not(.current) {
			color: $grey-10;
		}
	}

	input.form-control {
		border-radius: 2px;
	}

	.select2-container-multi .select2-choices {
		min-height: 36px;
	}

	.tooltip-wrap,
	.tooltip-flex-wrap {
		display: inline;
		padding-top: 8px;

		.no-borders {
			border-left: 0;
			border-right: 0;
		}
	}

	.tooltip-flex-wrap {
		display: flex;
		flex: 1;
	}

	.dropdown-menu {
		z-index: 19002;
	}

	.up-datepicker {
		.dropdown,
		.dropdown-wrap {
			width: 100%;
		}
		.up-placeholder.not-empty {
			color: $grey-8;
		}
	}

	.label-highlight {
		.input-group {
			animation: animation-input-focus 800ms ease-out;

			@keyframes animation-input-focus {
				0% {
					border-radius: 0;
				}
				25% {
					box-shadow: 0 0 0 10px rgba($bright-blue, 0.6);
				}
				100% {
					box-shadow: 0 0 10px 10px rgba($bright-blue, 0);
				}
			}

			.form-control {
				animation: none;
			}
		}

		input.form-control,
		.select2-container,
		.btn-group {
			animation: animation-input-focus 800ms ease-out;

			@keyframes animation-input-focus {
				0% {
					border-radius: 0;
				}
				25% {
					box-shadow: 0 0 0 10px rgba($bright-blue, 0.6);
				}
				100% {
					box-shadow: 0 0 10px 10px rgba($bright-blue, 0);
				}
			}
		}

		label {
			animation: animation-label-focus 1400ms ease-out;

			@keyframes animation-label-focus {
				25% {
					color: $bright-blue;
				}
			}
		}
	}

	#footer-container {
		width: 400px;
		margin: auto;
		margin-top: 50px;
		color: rgba($black, 0.5);

		img {
			margin-top: 8px;
		}
	}

	.no-padding-top {
		padding-top: 0px;
	}

	&.minimized {
		#search-header {
			.when-minimized {
				visibility: visible;
				@include opacity(1);
			}

			.when-maximized {
				visibility: hidden;
				@include opacity(0);
			}
		}

		#advanced-search-form {
			height: $headerHeight !important;
		}

		#advanced-search-results-wrap {
			position: absolute;
			top: $headerHeight;
			left: $padding;

			.header-background {
				position: fixed;
				top: 144px;
			}

			.th-inner {
				position: fixed;
				top: 144px;
			}
		}
	}

	#search-header {
		.upsales-icons {
			font-size: 17px;
			vertical-align: sub;
		}

		.when-minimized,
		.when-maximized {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			@include transition(all ease-in $animationMs);
		}

		.when-minimized {
			visibility: hidden;
			@include opacity(0);
		}

		.when-maximized {
			visibility: visible;
			@include opacity(1);
		}

		#show-filters {
			position: absolute;
			left: 50%;
			width: 140px;
			margin-left: -70px;
			top: 8px;
			font-size: 13px;
			height: 32px;

			@media (max-width: $responsiveWidht4) {
				& {
					width: 100px;
					margin-left: 0;
					left: initial;
					right: 10px;
				}
			}
		}
	}

	#search-header-left {
		display: flex;
		align-items: center;
		height: 100%;
	}

	// Overriding until REMOVE_FRAME flag is implemented
	#advanced-search-form-wrap:is(.remove-frame) {
		top: 84px;
		right: 0px;
		bottom: 0px;
		left: 48px;
	}

	#advanced-search-form-wrap {
		position: fixed;
		top: $main-top;
		right: $padding;
		bottom: $padding;
		left: $sidebar-width;

		#advanced-search-form {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: 1001;
			@include transition(all ease-in $animationMs);
			overflow: hidden;

			#advanced-search-load-curtain {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: $grey-1;
				z-index: 20000;
				@include opacity(0);
				visibility: hidden;
				@include transition(all ease $animationMs);
				display: flex;
				justify-content: center;
				align-items: center;

				.spinner {
					display: block;
					color: $blue;
				}
			}

			.search-section-divider {
				text-align: center;
				text-transform: uppercase;
				padding: 5px 0 7px;
				letter-spacing: 0.5px;
				color: $grey-11;
			}

			#advanced-search-side-bar {
				overflow-y: scroll;
				overflow-x: hidden;
				position: absolute;
				top: $header-height-sm;
				bottom: $footer-height;
				left: 0;
				width: $as-sidebar-width;
				background-color: $white;
				border-right: 1px solid $grey-8;

				.account-divider {
					cursor: default;
					position: relative;
					left: -12px;
					width: 260px;
					padding-bottom: 12px;
					padding-top: 10px;
					padding-left: 12px;
					border-top: 1px solid rgba($grey-8, 0.5);
				}

				ul {
					list-style-type: none;
				}

				.highlighted {
					background-color: $super-light-blue;
				}

				.search-wrap {
					padding: 6px 6px 10px 6px;
					background-color: $white;
					position: relative;

					.clear-search-button {
						right: 6px;
						top: 6px;
						position: absolute;
						height: 27px;
						width: 28px;
						color: $grey-11;
						cursor: pointer;

						.clear-search-icon {
							position: absolute;
							left: 8px;
							top: 7px;
						}

						&:hover {
							color: $grey-13;
						}
					}

					.search-input {
						width: 100%;
						background-color: rgba($grey-8, 0.35);
						height: 28px;
						padding-left: 27px;
						border-radius: 1px;
						border: 0;
					}

					.search-icon {
						position: absolute;
						left: 6px;
						top: 6px;
						text-align: center;
						width: 27px;
						height: 27px;
						line-height: 27px;
					}
				}

				.outer-list {
					padding: 5px 12px 5px 12px;
					cursor: pointer;
					background-color: $white;
					border-bottom: 1px solid rgba($grey-8, 0.5);

					.active-indicator-outer-wrap {
						position: absolute;
						right: 0px;
						top: 1px;
						display: flex;
					}

					.active-indicator-outer {
						background-color: $green;
						width: 17px;
						height: 16px;
						color: $white;
						border-radius: 3px;
						font-size: 10px;
						display: flex;
						justify-content: center;
						align-items: center;

						.fa {
							position: absolute;
							display: none;
							left: 1px;
							font-size: 16px;
							top: 0px;
							color: $red;
						}
					}

					.active-indicator-outer:hover {
						background-color: $white;

						.fa {
							display: block;
						}

						.text-content {
							display: none;
						}
					}
				}

				.inner-list {
					padding: 5px 10px 5px 10px;
					display: none;
					font-size: 12px;
					position: relative;

					.active-indicator-wraper {
						position: absolute;
						right: -12px;
						top: -2px;
						height: 22px;
						width: 22px;
						padding-top: 7px;
						font-size: 22px;
						text-align: center;
						transition: ease all 150ms;
					}

					.active-indicator-wraper:hover {
						padding-top: 3px;

						.active-indicator {
							background-color: $red;
							font-size: 12px;
							width: 14px;
							height: 14px;
							line-height: 14px;
							top: 0;
							right: -8px;
							color: $white;
						}
					}

					.active-indicator {
						width: 8px;
						height: 8px;
						background-color: $green;
						margin: auto;
						border-radius: 8px;
						font-size: 0px;
						text-align: center;
						transition: ease all 150ms;
					}

					li:not(:last-child) {
						padding-bottom: 5px;
					}
				}

				.section-header.current + .inner-list {
					display: block;
				}

				.section-header {
					display: inline-block;
					width: 90%;

					&.current {
						color: $green;
						font-weight: $boldFontWeight;
					}
				}

				.inner-list.has-search {
					display: block;
				}

				.list-section {
					position: relative;
					padding-bottom: 12px;
				}

				.list-filter {
					position: relative;

					color: $black;
				}
			}

			#search-content {
				position: absolute;
				top: $header-height-sm;
				bottom: $footer-height;
				right: 0;
				overflow-y: scroll;
				left: 0;

				label.target-focus {
					color: $red;
				}

				.btn-white-grey,
				.btn-bright-blue {
					z-index: 1000;
				}

				.search-section {
					border-bottom: 1px solid rgba($black, 0.1);
					color: $grey-13;
					background-color: $grey-1;
					@include transition(all ease $animationMs);
					margin-bottom: 3px;

					&:last-of-type {
						margin-bottom: 0;
					}

					/*&.has-inner-section {
				    	padding-bottom: 10px;
				    }*/

					.section-content {
						padding: 20px;
						max-width: $responsiveWidht2;
						width: 100%;
					}

					&.search-section-inner {
						background-color: transparent;
						padding: 0 0px 10px 0px;
						border: none;
						margin: 0;

						.search-section-header {
							height: 45px;
							line-height: 44px;
							padding: 0 20px;
						}

						&.section-closed {
							background-color: $white;
							padding: 0;
							padding-bottom: 6px;
							border: none;
							border-top: 1px solid $grey-4;

							.search-section-header {
								margin-bottom: 0;
								background-color: transparent;
								border: 0;
								height: 38px;
							}
						}

						h3 {
							font-size: 14px;
							margin-bottom: 0;
							padding: 4px 0;
						}
					}

					&.section-closed {
						background-color: $white;
						padding: 0 15px 4px 15px;

						.section-content {
							@include opacity(0);
							visibility: hidden;
							height: 0;
							display: none;
						}

						.btn-group {
							@include opacity(0);
							visibility: hidden;
							height: 0;
							display: none;
						}
					}

					h3 {
						font-size: 18px;
						font-weight: normal;
						margin: 0;
						padding-top: 10px;
						padding-bottom: 10px;
					}

					.search-section-header {
						position: relative;
						height: 46px;
						line-height: 48px;
						padding: 0 20px;

						label {
							display: inline-block;
							margin: 0;
							height: 100%;
						}

						h3 {
							display: inline-block;
							margin: 0 10px 5px 0;

							.fa {
								width: 20px;
								text-align: center;
							}
						}

						.up-toggle {
							margin-right: 10px;
						}

						.search-section-header-subtitle {
							color: $grey-10;
							font-size: 12px;
						}
					}

					& > .row {
						@include opacity(1);
						visibility: visible;
						display: block;
						@include transition(all ease $animationMs);
						@include transition(display ease $animationMs $animationMs);
						max-width: $responsiveWidht2;
					}

					.input-wrap {
						margin-bottom: 10px;
						$mailActionsWidth: 80px;

						&.with-toggle {
							line-height: 35px;
						}

						label.toggle h3 {
							font-size: 14px;
							margin-bottom: 0;
							padding: 4px 0;
						}

						&.active {
							.up-comparison-type-toggle {
								visibility: visible;
							}

							input,
							.select2-container .select2-choices {
								border-color: $bright-blue;
							}

							.multi-comparitors {
								margin-left: 50px;

								// For selectHelper select2 inputs
								.select2-choice {
									height: 36px;
								}
							}

							.has-mail-actions {
								margin-right: $mailActionsWidth;
							}

							// .select2-choices {
							// 	float: left;
							// 	width: 100%;
							// }

							.up-datepicker {
								.dropdown-trigger {
									border-color: $green;
								}
							}

							.active-indicator {
								display: inline-block;
							}

							label {
								color: $green;
								font-weight: $boldFontWeight;
							}
						}

						.multi-comparitors,
						.has-mail-actions {
							@include transition(all ease $animationMs);
							width: auto;
						}

						label {
							font-size: 13px;
							font-weight: normal;
						}

						.active-indicator {
							display: none;
							height: 6px;
							width: 6px;
							background-color: $green;
							border-radius: 6px;
							position: relative;
							top: -1px;
							margin-right: 2px;
						}

						.control-label.active {
							color: $green;

							.active-indicator {
								display: inline-block;
							}
						}

						.up-comparison-type-toggle {
							position: absolute;
							top: 0px;
							left: 15px;
							line-height: 34px;
							z-index: 1000;
							width: 55px;
							padding-right: 5px;
							text-align: center;
							background-color: $green;
							color: $white;
							visibility: hidden;
							@include border-radius($border-radius);
							@include transition(all ease-out 200ms);

							&:hover {
								background-color: darken($green, 5%);
								border-color: darken($green, 5%);
								text-decoration: none;
							}
						}

						.hidden-when-empty {
							position: absolute;
							top: 0px;
							right: 1px;
							z-index: 1000;
							width: $mailActionsWidth;
						}

						.form-control {
							$search-input-height: 36px;
							position: relative;
							height: $search-input-height;
							z-index: 1001;
							// @include border-radius($border-radius);

							&.select2-container {
								height: auto;
								min-height: $search-input-height;
							}

							&.combined-fields {
								width: 50%;

								&:first-of-type {
									border-radius: $border-radius 0 0 $border-radius;
								}

								&:last-of-type {
									margin-left: -1px;
									border-radius: 0 $border-radius $border-radius 0;
								}
							}
						}

						.input-group {
							width: 100%;
						}

						&.form-group {
							margin-bottom: 8px;
							padding-top: 0;

							.control-label {
								padding-right: 0;
								padding-left: 0;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;

								&.double-line {
									white-space: normal;
									line-height: 14px;
									padding-top: 5px;
								}

								&.triple-line {
									white-space: normal;
									line-height: 14px;
									padding-top: 0;
								}
							}
						}
					}
				}
			}

			.AdvancedSearchFooter {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: $footer-height;
				z-index: 20001;

				.ButtonGroup {
					opacity: 1;
					visibility: visible;
					transition: all ease $animationMs;
				}
			}

			.flex-button-group {
				display: flex;
			}
		}

		&.v2 {
			#advanced-search-form {
				#search-content {
					left: $as-sidebar-width;
				}
			}
		}
	}

	#advanced-search-results-wrap {
		position: fixed;
		left: $sidebar-width;
		right: $padding;
		top: 100%;
		z-index: 1000;
		background-color: $white;
		margin-bottom: 15px;
		@include transition(top ease-in $animationMs);

		.header-background {
			position: absolute;
			top: 0;
		}

		.th-inner {
			position: absolute;
			top: 0;
		}

		.main-table {
			margin-top: 30px;
		}
	}
}

// OLD SHIT 	_____
// 					|
// 					|
// 					|
// 					|
// 					|
// 					|
// 					|
// 					|
// 					V

$animationSpeed: 200ms;

#advanced-search-wrap {
	$margin: 10px;
	$width: (1000px - $margin * 2);

	position: fixed;
	top: 44px;
	left: 50%;
	z-index: 10001;
	width: $width;
	margin-left: -($width/2);
	background-color: $white;
	@include box-shadow(0 0 10px $grey-11);
	@include transition(all linear $animationSpeed);
	@include opacity(1);

	&.ng-hide {
		top: -100px;
		@include opacity(0);
	}

	&.ng-hide-add,
	&.ng-hide-remove {
		display: block !important;
	}

	// Make it responsive
	@media (max-width: $width) {
		& {
			margin-left: 0;
			left: $margin;
			right: $margin;
			width: auto;
		}
	}
	@media (max-height: 765px) {
		& {
			top: $margin;
			bottom: $margin;

			#advanced-search-content #filters-wrap {
				max-height: none !important;
				margin-bottom: 0px !important;
				position: absolute;
				left: 0;
				right: 0;
				top: 49px;
				bottom: 66px;
			}
		}
	}

	// The content style
	#advanced-search-content {
		#header {
			position: relative;
			padding: 20px;
			border-bottom: 1px solid $grey-8;
			background-color: $grey-1;
			padding-bottom: 0;

			&.bordered {
				border-bottom: 1px solid $grey-8;
			}

			h2#main-h2 {
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				font-size: 22px;
				padding-bottom: 4px;

				.fa {
					font-size: 19px;
					vertical-align: top;
					padding-top: 2px;
				}
			}

			#type-select {
				vertical-align: middle;
				margin-left: 20px;
			}

			#search-tabs {
				position: absolute;
				bottom: -1px;
				display: inline-block;
				margin-left: 20px;

				.search-tab {
					display: inline-block;
					background-color: $white;
					border: 1px solid $grey-6;
					border-bottom: 1px solid $grey-8;
					border-right-color: $white;
					padding: 6px 10px;
					color: $grey-10;
					text-transform: uppercase;
					font-size: 11px;

					&.active {
						border: 1px solid $grey-8;
						border-bottom-color: $white;
						color: $black;

						&:last-child {
							border-right-color: $grey-8;
						}

						& + .search-tab {
							border-left-color: $white;
						}
					}

					&:last-child {
						border-right-color: $grey-6;
					}
				}
			}
		}

		#filters-wrap {
			margin-bottom: 66px;
			padding: 0 20px;
			max-height: 600px;
			overflow: hidden;
			overflow-y: scroll;

			h3 {
				font-size: 20px;
			}

			#s2id_add-advanced-filter {
				display: inline-block;
				width: 320px;
				margin-left: 15px;
				margin-top: -4px;
			}

			.form-section {
				margin-bottom: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid $grey-4;

				h3 {
					&.inline-block {
						display: inline-block;
					}
				}

				.filter-section-wrapper {
					margin: 10px 0;
					vertical-align: top;

					label.toggle-label {
						display: inline-block;
						cursor: pointer;
					}

					.filter-string-wrap {
						display: inline-block;

						.selector-wrap > a,
						& > a {
							font-weight: $boldFontWeight;
						}

						.selector-wrap {
							display: inline-block;
							position: relative;

							.up-datepicker {
								position: absolute;
								width: 300px;
								top: -7px;
							}

							.select2-wrapper {
								& > select {
									display: none; // fix to prevent flickering
								}

								.select2-container {
									// Hide when inactive
									display: none;

									&.select2-container-active {
										display: block;
										position: absolute;
										width: 300px;
										top: -7px;
									}
								}
							}
						}
					}
				}

				.header-options {
					display: inline-block;
					margin-left: 15px;
					margin-top: 15px;
					vertical-align: top;

					.btn-group {
						.btn {
							padding: 5px 10px;
							font-size: 13px;
						}
					}
				}
			}
		}
	}

	#toolbar {
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
		padding: 10px;
		border-top: 1px solid $grey-8;
		background-color: $grey-1;

		#show-results {
			display: block;
			margin: 0 auto;

			&[disabled] {
				background: transparent;
				border-color: transparent;
				color: $black;
			}
		}

		#results {
			display: inline-block;
			line-height: 33px;
			font-size: 16px;
		}
	}
}

#advanced-search-curtain {
	@include curtain();
	@include transition(all linear $animationSpeed);
	display: block;

	&.ng-hide {
		@include opacity(0);
	}

	&.ng-hide-add,
	&.ng-hide-remove {
		display: block !important;
	}
}

.advanced-search-select2-dropdown-description {
	font-size: 12px;
	color: $grey-10;
}
