#creative-editor.card {
	background-color: $grey-1;
	@include border-radius($border-radius);
	float: left;
	bottom: 10px;

	#saving-curtain {
		position: absolute;
		top: $header-height-sm;
		left: 0;
		right: 0;
		bottom: $card-footer-height;
		background-color: rgba($black, 0.2);
		z-index: 1002;
		color: $bright-blue;

		#progress-wrap {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -20px;
			margin-left: -100px;
			height: 40px;
			width: 200px;
			text-align: center;
			font-size: 12px;
			line-height: 30px;

			.progress {
				background-color: rgba($black, 0.4);
			}
		}
	}

	#card-sidebar {
		position: absolute;
		overflow: hidden;
		overflow-y: auto;
		top: 0;
		bottom: $card-footer-height;
		margin-top: $header-height-sm;
		@include border-radius(0);

		.sidebar-section {
			border-bottom: 1px solid $grey-4;

			&#hero-section {
				background-color: $blue;
				color: $white;
				margin: -16px -15px 15px -15px;
				padding: 15px;

				.floating-label-input {
					& > label,
					& > input {
						color: rgba($white, 0.5);
						font-size: 14px;
					}
					& > input {
						color: $white;
						border-bottom-color: $grey-10;
					}

					&.has-value,
					& > input:focus {
						border-bottom-color: $white;
						color: $white;
					}
				}
			}

			.sidebar-header {
				background-color: transparent;
				position: relative;
				height: 25px;

				h2 {
					font-size: 12px;
					color: $grey-11;
					text-transform: uppercase;

					.up-ios-toggle {
						margin-left: 5px;
					}
				}
			}

			.change-img-btn {
				text-align: left;

				&:hover {
					.on-hover {
						display: inline-block;
					}
				}

				.on-hover {
					display: none;
				}
			}

			.section-row {
				display: block;
				margin: 10px 0;
			}

			.element-tools {
				position: absolute;
				top: 0;
				right: 0;
				height: 20px;
				text-align: right;
			}

			.up-step-int {
				width: 100px;
			}

			textarea {
				width: 100%;
				border: none;

				&:focus {
					outline: none;
					color: $bright-blue;
				}
			}

			#img-preview {
				display: block;
				border: 1px solid $grey-8;
				height: 80px;
				@include border-radius(3px);
				position: relative;

				#img-element {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-size: cover;
					background-position: center center;
				}

				button {
					position: absolute;
					top: 50%;
					margin-top: -17px;
					left: 50%;
					width: 120px;
					margin-left: -60px;
				}
			}

			.color-picker {
				$size: 20px;
				position: relative;
				display: inline-block;
				vertical-align: top;

				&:not(.open) .trigger {
					border-color: $grey-8 !important;
				}

				&.open {
					.trigger {
						z-index: 1002;
					}

					.color-input {
						visibility: visible;
						width: 100px;
						right: 0;
						z-index: 1000;
						padding-right: 20px;
						padding-left: 10px;
						@include box-shadow(0 0 5px rgba($black, 0.2));
					}
				}

				.color-input {
					width: 20px;
					height: 20px;
					position: absolute;
					top: 0;
					right: 0;
					visibility: hidden;
					z-index: 1002;
					border: 1px solid $grey-8;
					@include border-radius($size);
					outline: none;
				}

				.trigger {
					height: $size;
					width: $size;
					@include border-radius($size);
					border: 1px solid transparent;
					text-align: center;
					margin-left: 5px;
					cursor: pointer;
					z-index: 1000;
					position: relative;

					&.transparent {
						background-image: url("../img/transparent.png");
						background-repeat: repeat;
					}

					.fa {
						font-size: 10px;
					}
				}
			}
		}
	}

	#card-main-view {
		position: absolute;
		bottom: 0;
		top: 0;
		right: 0;

		&.has-sidebar {
			padding: 0;
			margin-top: $header-height-sm;
		}

		.card-content {
			background-color: $white;
			overflow: auto;
			position: absolute;
			top: 0;
			bottom: $card-footer-height;
			left: 0;
			right: 0;
			padding: 15px;

			#preview-btns {
				display: block;
				margin-bottom: 25px;
				height: 35px;
			}

			.frame-wrap {
				margin-bottom: 15px;

				&.inactive {
					.overlay-info {
						display: block;
					}
				}

				&.inactive,
				.inner-wrap:hover {
					.overlay {
						visibility: visible;
						@include opacity(1);
					}
				}

				.format-string {
					color: $grey-10;
					display: block;
					margin-bottom: 2px;
					font-size: 12px;
				}

				.overlay-info {
					top: 50%;
					position: absolute;
					height: 45px;
					line-height: 45px;
					font-size: 18px;
					color: $grey-10;
					margin-top: -22.5px;
					text-align: center;
					display: none;
					width: 100%;
				}

				.inner-wrap {
					position: relative;

					&:hover {
						.overlay {
							background-color: rgba($white, 0.8);
						}
						.exclude-btn {
							visibility: visible;
							@include opacity(1);
						}
						.overlay-info {
							display: none;
						}
					}
				}

				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background-color: rgba($white, 0.7);
					visibility: hidden;
					@include opacity(0);
					@include transition(all ease 200ms);
				}

				.exclude-btn {
					visibility: hidden;
					top: 50%;
					left: 50%;
					margin-left: -60px;
					width: 120px;
					position: absolute;
					margin-top: -22.5px;
					@include opacity(0);
					@include transition(all ease 200ms);
				}
			}

			#fake-news-site {
				background-color: $white;
				max-width: 1200px;

				@media (max-width: 1520px) {
					& {
						.browser-wrapper {
							.top-row {
								background-color: $blue;

								.header-top .header-main {
									margin-left: 20px;
									float: left;
									width: auto;
									padding: 0;
								}

								.header-creative {
									display: none;
								}

								.header-sidebar {
									height: 66px;
									width: 61px;
									overflow: hidden;

									.sidebar-stock {
										display: none;
									}
								}
							}

							.main-container {
								width: auto;
								max-width: initial;

								.main-article-col {
									width: auto;
									margin: 0 10px;
									min-width: initial;

									.main-article {
										width: 75%;
									}

									.most-read-col {
										margin-top: -270px;
									}
								}

								.main-sidebar {
									display: none;
									float: initial;
								}
							}
						}
					}
				}
				@media (max-width: 1440px) {
					& {
						.browser-wrapper {
							.main-container {
								.main-article-col {
									.main-article {
										width: 100%;
										max-width: initial;
									}

									.most-read-col {
										margin-top: 0;
									}
								}
							}
						}
					}
				}

				@media (max-width: 1200px) {
					& {
						.browser-wrapper {
							.main-container {
								.main-article-col {
									.main-article .news-banner-wrap {
										display: none;
									}
								}
							}
						}
					}
				}

				@media (max-width: 1163px) {
					& {
						.browser-wrapper {
							.mobile-banner {
								display: block !important;
							}

							.main-container {
								.main-article-col {
									.most-read-col {
										display: none;
									}

									.sub-article-col {
										width: 100%;

										.sub-article {
											max-width: 100%;
										}
									}
								}
							}
						}
					}
				}

				@media (max-width: 1010px) {
					& {
						.browser-wrapper {
							.top-row {
								.header-top .header-main .header-left {
									display: none;
								}
							}
						}
					}
				}

				@media (max-width: 950px) {
					& {
						.browser-wrapper {
							#first-sub .news-banner-wrap {
								display: none !important;
							}
						}
					}
				}

				p {
					color: transparent;
					display: inline;
					background-color: $grey-10;
					opacity: 0.6;
					font-size: 10px;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-weight: $boldFontWeight;
				}

				.browser-wrapper {
					@include box-shadow(1px 10px 20px rgba($black, 0.2));
					margin: 25px 0;
					background-color: rgba($bright-blue, 0.2);
					overflow: hidden;
					@include border-radius(4px);

					.container {
						position: relative;
					}

					.mobile-banner {
						display: none;
					}

					.browser-header {
						position: relative;
						border-bottom: 1px solid rgba($black, 0.1);
						width: 100%;
						padding: 14px;
						background-color: $white;

						.browser-circle {
							display: inline-block;
							background-color: $grey-4;
							@include border-radius(10px);
							width: 10px;
							height: 10px;
							margin-left: 5px;
						}

						.url {
							position: absolute;
							top: 10px;
							left: 90px;
							right: 100px;
							display: inline-block;
							@include border-radius(20px);
							padding: 4px 20px;
							@include box-shadow(inset 0 1px 1px rgba($black, 0.1));
							background-color: $grey-4;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}

				.top-row {
					margin-bottom: 20px;

					.sidebar-stock {
						width: 60px;
						height: 60px;
						@include border-radius(60px);
						margin-bottom: 10px;
						padding-top: 18px;
						font-weight: $boldFontWeight;
						font-size: 16px;
						background-color: rgba($white, 0.5);
						color: $blue;
						display: inline-block;
						text-align: center;
					}

					.header-top {
						background-color: $blue;
						color: $white;
						@include border-radius(0 0 3px 3px);

						.header-main {
							display: inline-block;
							height: 60px;
							width: 100%;
							padding: 0 8.33333333%;

							.header-left {
								float: left;
							}

							#tomorrow-date {
								display: inline-block;
							}

							h2 {
								font-weight: $boldFontWeight;
								margin-top: 14px;
								display: inline-block;
								letter-spacing: -1px;
								color: $white;
							}
						}
					}

					.header-sidebar {
						color: $white;
						text-align: center;
						height: 200px;
						padding-top: 10px;

						h2 {
							text-align: center;
							font-size: 40px;
							border-bottom: 3px solid $white;
							color: $white;
							margin-bottom: 20px;
						}
					}

					.header-sidebar-article {
						display: inline-block;
						color: $white;
						font-size: 12px;
						padding-left: 40px;
						border-left: 1px solid rgba($white, 0.2);
						margin-left: 20px;
						float: right;
						padding-top: 14px;

						p {
							color: $white;
							font-size: 12px;
							padding-top: 16px;
							line-height: 14px;
							background-color: transparent;
							opacity: 1;
						}
					}

					.header-creative {
						min-height: 200px;
						padding: 0;
					}
				}

				.main-container {
					@include border-radius(3px);

					.most-read-col h5,
					.main-sidebar h4 {
						border-bottom: 3px solid $blue;
						padding-bottom: 6px;
						max-width: 300px;
					}

					.main-sidebar {
						min-width: 250px;

						.article-small {
							border-bottom: 1px solid $bright-blue;
							padding: 10px 0;
							margin-bottom: 10px;
							max-width: 300px;

							h5 {
								border-bottom: none;
							}

							h4 {
								width: 300px;
							}

							p {
								color: transparent;
								background-color: $bright-blue;
								opacity: 0.3;
							}
						}
					}

					.main-article-col {
						background-color: rgba($white, 0.9);
						min-width: 728px;
						box-shadow: 1px 1px 2px rgba($black, 0.2);
						z-index: 2;
						@include border-radius(3px);
						padding-bottom: 10px;

						.most-read-col {
							.article-small {
								margin-bottom: 10px;
							}
						}

						.main-article {
							max-width: 728px;
							border-bottom: 1px solid $grey-10;
							padding: 10px 0;
							margin-bottom: 20px;

							h3 {
								font-size: 36px;
								font-weight: $boldFontWeight;
							}
						}

						.sub-article {
							max-width: 480px;
							border-bottom: 1px solid $grey-10;
							margin-bottom: 20px;
							padding-bottom: 20px;

							h3 {
								font-size: 36px;
								font-weight: $boldFontWeight;
							}

							.sub-article-col {
								padding: 10px 0;
								width: 70%;
							}
						}
					}
				}

				.timestamp {
					display: block;
					width: 60px;
					background-color: $grey-8;
					opacity: 0.6;
					margin-top: 10px;
					height: 6px;
				}
			}
		}
	}

	#card-footer .size-counter {
		position: absolute;
		top: 0;
		left: 20px;
		display: inline-block;
		height: 42px;
		line-height: 42px;
	}
}
