@import "~StyleVariables";

.SubscriptionUiElements {
    width: calc(var(--subscriptionSidebarWidth) - 1px);

    .ui-element-widget {
        padding: 0;
        background-color: inherit;

        > div {
            border-top: 1px solid $grey-4;
        }
    }

    .widget-row {
        padding: 0 8px; // Same as Block large padding, used in sidebar components
    }
}
