@import "~StyleVariables";

.AccountFiles {
	display: flex;
	flex-direction: column;

	&__folders-container {
		margin-bottom: 16px;
		> .Text {
			margin-left: 20px;
		}
		display: flex;
		flex-direction: column;
		&__folders {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__files-container {
		> .Text {
			margin-left: 20px;
		}
		display: flex;
		flex-direction: column;
		&__files {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__last-column {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: 16px;
	}

	&__center {
		margin-top: 10vh;

		.ButtonBox {
			width: 240px;
		}
	}
}
