@import "~StyleVariables";
@import "../shared";

.PeriodizationListFooter {
	display: flex;
	align-items: center;
	position: sticky;
	bottom: 0;
	background-color: white;
	grid-column: span 2;
	z-index: 1010;
	box-shadow: inset 0 1px 0 0 $grey-2;
	display: grid;
	grid-template-columns: $sideBarWith auto;
}

.PeriodizationListFooter--minimize {
	height: 0;
}

.PeriodizationListFooter .Paginator {
	display: inline-flex;
	position: sticky;
	left: calc(50% - 120px);
	width: 380px;
}

.PeriodizationListFooter__row {
	height: $rowHeight;
	display: flex;
	align-items: center;
	width: 100%;
}

.PeriodizationListFooter__row {
	box-shadow: inset 0 -1px 0 0 #edf1f5;
}

.PeriodizationListFooter__row .Text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.PeriodizationListFooter__column--left {
	width: 100%;
	height: 100%;
	z-index: 1000;
	position: sticky;
	left: 0;
	background-color: $white;
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
	padding: 0 $columnPadding;
}

.PeriodizationListFooter__row--summary {
	display: inline-grid;
	grid-auto-columns: minmax(90px, auto);
}

.PeriodizationListFooter__row--summary > div {
	grid-row: 1;
	min-width: 0;
	padding: 0 0 0 $columnPadding;
	height: 100%;
}

.PeriodizationListFooter .btn-white {
	background-color: $grey-1;
}
