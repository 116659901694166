@import "~StyleVariables";

.CreateNewEntity {
	.DropDownButton {
		color: $bright-blue;
	}
	.DropDownMenu__content {
		min-width: 180px;
	}
}
