@import "~StyleVariables";

.SystemNotifications {
	$width: 340px;
	position: fixed;
	bottom: 0px;
	right: 0px;
	z-index: $notification-z;
	width: $width;
	pointer-events: none;

	&__list {
		transition: right 0.6s;
		position: relative;
		right: 0;
		pointer-events: auto;
	}

	&__hideButton {
		position: relative;
		float: right;
		margin-right: 20px;
		margin-bottom: 0px;
		border-radius: 4px 4px 0 0;
		width: 180px;
		transition: bottom ease-in 0.5s, width ease-in 0.5s;
		bottom: -100px;

		&--visible {
			bottom: 0;
			pointer-events: auto;
		}
	}

	&--hidden {
		.SystemNotifications__list {
			right: -$width;
		}

		.SystemNotifications__hideButton {
			pointer-events: auto;
		}
	}

	.SystemNotificationWrap {
		right: -$width;
		transition: all ease-out 300ms;
		position: relative;
		margin: 0 $space * 5;
		margin-bottom: $space * 3;
		max-height: 0;

		&--visible {
			right: 0;
			max-height: 500px; // should be plenty
		}
	}
}

.SystemNotifications .SystemNotification_genericAdd {
	cursor: pointer;
}
