@import "~StyleVariables";

.Page,
.Page--position {
	position: absolute;
	left: 65px;
	top: $main-top;
	z-index: 1000;
	right: 15px;
}

.Page {
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: 0 3px 6px rgba($black, 0.2);
	overflow: hidden;

	&--static {
		overflow: visible;
		position: relative;
		top: initial;
		left: initial;
		right: initial;

		.Page__content-loader {
			margin-top: 0;
		}
	}
	&--transparent {
		background-color: transparent;
		box-shadow: none;
	}

	&--full-height {
		bottom: 15px;

		.Page__content {
			height: 100%;
		}
	}

	&--has-sidebar {
		flex-direction: row;
		display: flex;
		flex: 0 1 auto;
		flex-wrap: nowrap;
	}

	&--has-header {
		.Page__sidebar,
		.Page__content {
			margin-top: 50px;
		}
	}

	&--no-frame:not(.Page--static),
	&--edge-to-edge {
		bottom: 0 !important;
		right: 0;
		top: 82px;
		z-index: 1600;
	}
	&--no-frame:not(.Page--static) {
		left: 48px;
		box-shadow: none;
		border-radius: 0;
	}

	&--edge-to-edge {
		left: 0;
	}

	&__sidebar,
	&__content {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}

	&__sidebar {
		background-color: $grey-1;
		flex: 0 0 50px;
		border-right: 1px solid $grey-2;
		max-height: 100%;
		display: flex;
		align-items: stretch;
		border-radius: $border-radius 0 0 $border-radius;
		&--expanded {
			flex: 0 0 240px;
		}
	}

	&__header {
		position: absolute;
		left: 0;
		right: 0;
		height: 50px;
		box-shadow: 0 1px 2px rgba($black, 0.2);
	}

	&__content {
		position: relative;

		&--overflow {
			overflow-y: auto;
		}
	}

	&__content-loader {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -50px;
		margin-top: -50px;
	}

	&__error {
		align-self: center;
		flex: auto;
		margin-top: 50px;
	}
}

body {
	&.no-submenu {
		.Page,
		.Page--position {
			top: $main-top - $sub-menu-height;
		}

		&.remove-frame {
			.Page,
			.Page--position {
				top: $main-top - $sub-menu-height - 15px;
			}
		}
	}

	.Page {
		&--no-frame:not(.Page--static),
		&--edge-to-edge {
			&.no-submenu {
				top: 82px - $sub-menu-height;
			}
		}
	}
}
