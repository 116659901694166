@import "~StyleVariables";

.MarketingBoardBoard {
	margin-bottom: 20px;
	z-index: 1000;

	.CardHeader {
		padding-right: 0;

		.CardHeader__actions {
			line-height: initial;
			float: initial;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			height: initial;

			// Ugly filter overrides
			.actions {
				height: 57px;
			}

			#list-filters-wrap {
				box-shadow: 0 2px 4px rgba($black, 0.2);
			}
		}
	}

	&__board-wrap {
		min-height: 100px;
	}

	&__no-data {
		position: absolute;
		width: 100%;
		height: 100px;
		line-height: 100px;
		bottom: 0;
	}

	&__more-data {
		background-color: $white;
		height: 40px;
		padding: 5px 0;

		.Button,
		.Loader.Loader--xs {
			height: 30px;
		}

		.Loader.Loader--xs {
			margin: 0 auto;
			width: 30px;
		}
	}

	&__loader {
		position: absolute;
		width: 100%;
		bottom: 25px;
	}

	&__col {
		padding: 10px;
		min-width: calc(100% / 6);
		max-width: calc(100% / 6);

		&:nth-child(even) {
			background-color: $grey-2;
		}
		&:nth-child(odd) {
			background-color: $grey-1;
		}

		& > .Text {
			margin-top: 10px;
		}
	}
}
