@import "~StyleVariables";

.SubscriptionIndexingModal {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: 50px;

	.EditorHeader {
		width: 100%;

		&__title {
			padding-right: 40px;
		}

		.EditorHeaderButton {
			min-width: 0px;
		}
	}

	&__banner-lock {
		padding: 0 20px;
		color: $blue;
		background-color: $super-light-blue;
		height: 10 * $space;
		align-items: center;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: auto;
		width: 100%;
	}
}
