
@import "~StyleVariables";

.RelatedRelations {
    &__title {
        font-weight: 600;
        border-bottom: 1px solid $grey-4;
        padding: 0 12px;
        margin-bottom: 0;
        height: 28px;
        line-height: 28px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
