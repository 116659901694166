$width: 580px;

.MailTemplatePreviewHeader {
	position: relative;

	&--full-width &__inner {
		margin-bottom: 15px;
		width: 100%;
	}

	&--align-center img {
		margin: 0 auto;
	}

	&--align-right img {
		margin: 0 0 0 auto;
	}

	img {
		height: 30px;
		display: block;
	}

	&__inner {
		position: relative;
		height: 50px;
		margin: 0 auto;
		width: $width;

		& > div {
			width: $width;
			margin: 0 auto;
			padding: 10px 16px;
		}
	}
}
