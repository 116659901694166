@import "~StyleVariables";

.FileBrowserFiles {
	position: relative;

	&__header {
		position: sticky;
		top: -15px;
		display: flex;
		align-items: center;
		flex-direction: row;
		background-color: $grey-1;
		z-index: 1000;
		padding: 5px 15px;
		width: calc(100% + 30px);
		margin-left: -15px;
		box-shadow: 0 1px 2px rgba($black, 0.2);

		.ButtonSelect {
			position: absolute;
			right: 0;
			margin-right: 16px;
		}
	}

	&__thumbnails {
		margin: 0 -10px;
	}

	& > .ButtonSelect {
		position: absolute;
		top: 0;
		right: 0;
	}

	&__table {
		background-color: $white;
		margin-top: 15px;
	}

	&__no-files {
		text-align: center;
		margin-top: 20px;
	}

	&__checkedButtons {
		top: 0;
		margin-left: 20px;

		.Button {
			margin-right: 10px;

			.Icon {
				margin-right: 10px;
			}
		}
	}

	&__dropdown-wrapper {
		width: 200px;
		z-index: 1002;
		position: absolute;
		margin-top: 2px;
		border-radius: 4px;
		cursor: pointer;

		.Text {
			padding: 10px;
			border-bottom: 1px solid $grey-4;
			overflow-wrap: break-word;

			&:hover {
				background-color: $grey-1;
			}
		}
	}
}
