@import "../../../styles/variables";

.EditSocialEvent {
	display: flex;
	min-width: 900px;
	padding-bottom: 20px;
	margin-bottom: 30px;
	$heightToSubtract: $main-top + 20px;
	min-height: calc(100vh - #{$heightToSubtract});

	.EditSocialEventDetails,
	.EditSocialEventResource,
	.EditSocialEventContacts,
	.EditSocialEventReview {
		margin-top: 50px;
	}

	.EditSocialEventResource {
		margin-bottom: 80px;
	}

	.Sidebar {
		border-right: 1px solid $grey-6;
		background-color: $white;

		&::before {
			content: unset;
		}
	}

	.SidebarWidget {
		z-index: unset;
	}

	&--no-sidebar {
		left: 15px !important;
		.EditSocialEvent__Topbar {
			width: calc(100% - 30px);
		}
	}

	&__no-resources {
		margin: 20px;
	}
}

.EditSocialEvent > .CardContent {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 0;
}

.EditSocialEvent__Topbar {
	height: 50px;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	position: fixed;
	width: calc(100% - 80px);
	z-index: 1499;
	white-space: nowrap;


	.EditSocialEvent__TopbarNextButton {
		display: inline;
	}

	&.Edit {
		width: calc(100% - 380px);
		display: flex;
		justify-content: space-between;
	}
}

.EditSocialEvent__Topbar .Tab {
	box-sizing: content-box;
}

.EditSocialEvent__Topbar--white {
	background-color: $white;
}

.EditSocialEvent__Topbar--medium-purple {
	background-color: $medium-purple;
}

.EditSocialEvent__Topbar-next-btn {
	position: absolute;
	top: 0;
	right: 0;
	height: 50px;
	padding-top: 16px;
	padding-right: 40px;
	text-align: left;
	font-size: 16px;
	border-radius: 0;

	.Icon {
		margin-left: 6px;
		font-weight: $regularFontWeight;
		position: absolute;
		right: 12px;
		top: 18px;
		font-size: 12px;
	}
}

.EditSocialEvent__Sidebar__Title {
	margin-bottom: 26px;
}

.EditSocialEvent__Topbar-next-button-header {
	font-size: 12px;
	line-height: 12px;
	position: absolute;
	top: 8px;
	color: #fff;
}

.EditSocialEvent__check-in-info {
	padding-right: 30px;
	border-right: $grey-6 solid 1px;
}

.EditSocialEvent__check-in-pin {
	flex-grow: 0;
	margin: auto;
	padding: 0 5px 0 15px;
}

.Card--gradient-background {
	background-image: linear-gradient($white, $grey-1);
}

.EditSocialEvent__copy-btn.Tooltip {
	float: right;
	height: 100%;
}

.EditSocialEvent__copy-btn .Button {
	height: 100%;
}

.EditSocialEvent__Sidebar__landingpage-link-wrap {
	margin-bottom: 15px;
}

.EditSocialEvent__Sidebar__landingpage-link-wrap .Link {
	line-height: inherit;
	display: inline-block;
}

.EditSocialEvent__Sidebar__social-media,
.EditSocialEvent__Sidebar__landingpage-link-wrap {
	position: relative;
	padding-right: 70px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 25px;
}

.EditSocialEvent__Sidebar__social-media:hover .EditSocialEvent__Sidebar__link-copy,
.EditSocialEvent__Sidebar__landingpage-link-wrap:hover .EditSocialEvent__Sidebar__link-copy {
	visibility: visible;
}

.EditSocialEvent__Sidebar__link-copy {
	position: absolute;
	right: 0;
	top: 0;
	height: 25px;
	line-height: 25px;
	visibility: hidden;
	transition: none;
}

.EditSocialEvent__Sidebar__link-elevio {
	position: absolute;
	right: -3px;
	top: 3px;
}

.EditSocialEvent__Sidebar__tracked-links-title {
	position: relative;
}
