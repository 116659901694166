@import "~StyleVariables";
@import "../../../../styles/mixins";
@import "../../../../styles/animations";
.UpAudio {
	display: block;
	height: 30px;
	line-height: 30px;
	@include disable-user-select();
	&__audiotag {
		display: none;
	}
	&__controls {
		display: flex;
		&--istimeline {
			width: 130px;
			flex-direction: column;
			margin-top: -2px;
		}
		&__btn {
			display: inline-block;
			background-color: transparent;
			border: none;
			font-size: 14px;
			color: rgba($bright-blue, 0.8);
			@include transition(all ease 200ms);
			cursor: pointer;
			padding: 0;
			width: 30px;
			height: 30px;
			text-align: center;

			&:hover {
				color: $bright-blue;
			}
		}
		&__playBtn {
			margin-right: 10px;
		}
		&__duration {
			color: $grey-10;
			flex: 1 30%;
			text-align: right;
		}
		&__duration-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 53%;
		}
		&__icon-wrapper {
			display: flex;
			justify-content: space-between;
			.Icon {
				font-size: 18px;
			}
		}
		&__timeline-wrap {
			height: 30px;
			flex: 1 70%;
		}
	}
	&__loader {
		height: 30px;
		width: 30px;
		margin: 0 auto;
	}
	&__error {
		text-align: center;
	}
	&__timeline-wrap {
		&__timeline {
			position: relative;
			height: 2px;
			background-color: $grey-8;
			@include border-radius(1px);
			margin-top: 14px;
			&--istimeline {
				margin-top: 0;
			}
		}

		&__progress {
			height: 2px;
			background-color: $bright-blue;
			@include border-radius(1px);
		}

		&__tracker {
			position: absolute;
			left: 0;
			top: -7px;
			cursor: pointer;
			background-color: rgba($bright-blue, 0.8);
			width: 15px;
			height: 15px;
			margin-left: -7px;
			@include border-radius(50%);
			@include box-shadow(0 2px 2px rgba($black, 0.1));

			&:hover {
				background-color: $bright-blue;
			}
		}
	}
}
