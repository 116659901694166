@import "~StyleVariables";

.DecisionMakersSidebar--Select2-dropdown {
	width: 300px;
}

.DecisionMakersSidebar {
	border-bottom: 1px solid $grey-4;
	width: 205px;

	.Title {
		line-height: 22px;
		padding: $space * 2 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Icon {
			cursor: pointer;
			font-size: 12px;
		}
	}

	.DecisionMakersSidebar--Role {
		margin-left: -15px;
		margin-right: -14px;
		padding-left: 15px;
		padding-right: 15px;

		&:hover {
			background-color: $grey-1;
		}
	}

	.DecisionMakersSidebar--Role_title {
		height: 18px;

		.status-selected,
		.status-not-selected {
			border-radius: 100%;
			width: 6px;
			height: 6px;
			margin-top: 6px;
			float: left;
		}

		.status-selected {
			background-color: $bright-green;
		}

		.status-not-selected {
			background-color: $red;
		}
	}

	.DecisionMakersSidebar--Role_Content {
		margin-left: 2px;
		border-top: solid 1px $grey-4;
		padding-top: 3px;
		padding-bottom: 3px;

		.select2-container {
			width: 300px;
		}

		.Icon {
			font-size: 12px;
			margin-right: 3px;
		}

		.Button {
			padding: 0;
			text-align: left;

			.Icon {
				padding-right: 5px;
			}

			.Text {
				display: inline-block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 170px;
				position: relative;
				top: 17%;
			}
		}

		.DecisionMakersSidebar--Role_Content_more {
			visibility: hidden;
			overflow: hidden;
			display: block;
			height: 0px;
			.Text {
				color: $grey-10;
				font-size: 12px;
			}
		}

		&:hover {
			background-color: $grey-1;
			.DecisionMakersSidebar--Role_Content_more {
				visibility: visible;
				height: 32px;
			}
		}
	}

	.DecisionMakersSidebar--Role-Contact {
		position: relative;
		min-height: 22px;

		.Tooltip {
			position: absolute;
			right: 15px;
			top: 0px;
		}

		.Icon {
			font-size: 12px;
			position: absolute;
			top: 12px;
		}

		.Button {
			position: absolute;
			right: 6px;
		}

		.Text {
			margin-left: 20px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 150px;
		}

		.DecisionMakersSidebar--Role-Contact_title {
			color: $grey-10;
			font-variant: all-small-caps;
			font-size: 13px;
			margin-top: -5px;
		}
	}
}
