@import "../../../styles/variables";

.DeactivateUserDrawer {
	width: 420px;
	top: 4px;
	overflow: auto;

	&__disable_btn {
		margin: 10px;
	}

	.DrawerHeader {
		position: relative;
		z-index: 1000;

		&__title {
			display: none;
		}
	}
	&__info-card {
		margin: 16px;
		border-radius: 4px;
		border: 1px solid $grey-5;
		border-left: 4px solid $bright-blue;
		.Column {
			top: 50%;
		}
	}
	&__card-wrapper {
		margin: 16px;
		border-radius: 4px;
		border: 1px solid $grey-5;
	}
	&__card-content {
		padding: 0px;
	}
	&__info-icon {
		margin-top: 50%;
	}
	&__user-avatar {
		width: 25px;
	}

	&__user-name {
		width: 400px;
	}

	&__role-title {
		width: 20px;
		padding-left: 0px;
	}

	&__folder-icon {
		max-width: 43px;
	}

	&__group-icon {
		display: flex;
		flex-direction: row;
		width: 30px;
		.Icon {
			padding-top: 13px;
		}
		.Text {
			padding: 10px;
			align-self: center;
		}
	}

	.TableHeader__column {
		text-align: right;
		padding-right: 10px;
	}
	.Link {
		font-size: 12px;
	}
}
