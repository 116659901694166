@import "~StyleVariables";

.Prospecting {
	overflow: initial !important;

	// This will override .Page--position until REMOVE_FRAME is released
	&--remove-frame {
		top: $main-top - 15px;
		left: 48px;
		right: 0;

		.Page {
			border-radius: 0;
			box-shadow: none;
		}
	}
}

.Prospecting .Text--nowrap {
	white-space: nowrap;
}

.Text--Inline {
	display: inline;
}

.Prospecting__header {
	position: sticky;
	top: $main-top;
	z-index: 2000;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	border-radius: $border-radius $border-radius 0 0;

	// It looks so ugly when the letter spacing changes with the original bold approach so using this not perfect hack to make it look better
	.Tab.Tab--selected {
		font-weight: 400;
		text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.725);
	}

	// This will override top until REMOVE_FRAME is released
	&--remove-frame {
		top: $main-top - 15px;
		border-radius: 0 0 0 0;		
	}	
}

.Prospecting__body {
	padding-bottom: 700px;
}

.Prospecting__grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, 460px);
	grid-auto-rows: 130px;
	grid-row-gap: 20px;
	grid-column-gap: 20px;
}

.Prospecting__grid--large {
	grid-template-columns: repeat(auto-fill, 460px);
	grid-auto-rows: 190px;
}

.Prospecting__grid .Card {
	border: 1px solid $grey-6;
	border-radius: 2px;
}

.Prospecting__sectionHeader {
	display: grid;
	grid-template-columns: repeat(auto-fill, 460px);
	grid-column-gap: 20px;
}

.Prospecting__sectionHeader > .Block {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Prospecting__sectionHeader .fa-question-circle {
	font-size: 18px;
	margin-left: 10px;
	margin-right: 10px;
}
