@import "~StyleVariables";

.ScheduledDashboardsList {
	.ModalHeader {
		height: 50px;
		padding: 0 15px;
		position: unset;
	}

	--buttonWidth: 26px;
	--dropdownBorderRadius: 5px;

	background-color: white;
	height: fit-content;
	min-height: 100vh;

	.TableHeader {
		height: 50px;

		:nth-child(2) {
			width: 250px;
		}
		:nth-child(3) {
			width: 100px;
		}
		:nth-child(4) {
			width: 90px;
		}
	}

	.ModalHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__content {
		padding: 48px 20px 20px 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 900px;
		margin: auto;

		.Table {
			border: $grey-6 1px solid;
			border-radius: 4px;
			border-collapse: separate;
		}
	}

	&__croppedText {
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__dashboard {
		background-color: $grey-1;
	}

	&__recipientsValue {
		display: flex;
		align-items: center;
	}

	&__allDashboardsLink {
		width: fit-content;
		.Icon {
			font-size: 10px;
		}
		&:hover {
			cursor: pointer;
		}
	}

	&__schedule {
		.TableColumn__subtitle {
			max-height: 0;
			opacity: 0;
			transform: translateY(10px);
			transition: $transition-small-bounce;
		}

		&:hover {
			.TableColumn__subtitle {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				max-height: 25px;
				transition: $transition-small-bounce;
				transform: translateY(0);
				opacity: 1;
			}
		}
	}

	.Icon {
		&:hover {
			color: $black;
		}
	}

	&__delete {
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		border: 1px solid transparent;

		&--selected {
			.Icon {
				color: $black;
			}
			background-color: $white;
			border-color: $grey-5;
			border-radius: 5px 5px 0px 0px;
			box-shadow: 1px 0 0 0 $grey-4, 0 3px 6px 0 $grey-4;
		}
	}

	.DropDownButton {
		height: 30px;
		padding: 0 0.25em;
		margin-left: 0.5em;
		color: $grey-10;
	}

	.DropDownButton__text-wrapper {
		padding: 0 0.125em;
	}

	.DropDownButton__icon-wrapper {
		display: none;
	}

	.DropDownMenu__content {
		background-color: transparent;
		border-radius: var(--dropdownBorderRadius);
		margin-top: -4px;
		text-align: center;
	}

	.DropDownMenu__content::after {
		content: "";
		width: calc(var(--buttonWidth) - 2px);
		height: 4px;
		background-color: $white;
		position: absolute;
		right: 1px;
		top: 0px;
	}

	&__singleListSubtitle {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.Button {
			display: flex;
			align-items: center;
		}
	}

	&__title {
		width: 100%;

		.Headline {
			padding-bottom: 16px;
		}
	}

	&__emptyList {
		background-color: $grey-1;
		height: 275px;
	}

	&__dropdown {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: none;
		background-color: $white;
		border: 1px solid $grey-5;
		border-radius: var(--dropdownBorderRadius);
		border-top-right-radius: 0;
		padding-top: 4px;
	}

	&__expander {
		display: flex;
		justify-content: space-between;
		align-content: center;
		padding-right: 1em;
		font-size: 20px;
	}

	&__header {
		position: sticky;
		top: 0;
		z-index: 10;
		background-color: $white;

		.topbar {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 54px;
			box-shadow: 0 1px 2px rgba($black, 0.2);

			.info {
				padding: 0 14px;
			}
			.Button {
				padding-right: 20px;
				color: $grey-10;
			}
		}
	}
	&__loading {
		display: flex;
		height: 100vh;
		justify-content: center;
		align-items: center;
	}
}
