.ReportcenterFilterSelect {
	// The select overrides are needed in order for the react-select onMenuScrollToBottom to be triggered correctly with the labels margin-bottom offset
	.Select-menu-outer {
		max-height: 205px !important;
		overflow: hidden;
	}
	.Select-menu {
		max-height: 205px !important;
	}
}
