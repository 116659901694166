@import "~StyleVariables";

.ReportcenterWidgetGallery {
    height: 100%;
    background-color: $white;

    &__content {
        height: calc(100% - #{$header-height}); 
        display: flex;
        flex-direction: column;
    }
}

