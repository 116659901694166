@import "~StyleVariables";

.AssignModalLeadSelectAdvanced {
	// Make assist chip multiline
	.Chip {
		height: auto;
		padding-top: 4px;
		padding-bottom: 4px;
		.Text {
			white-space: normal;
		}
	}

	.TableHeader__column::after {
		content: none;
	}

	.Table {
		border-bottom: 1px solid $grey-2;
		margin-bottom: 12px;
		margin-top: 8px;
		white-space: nowrap;

		.TableColumn {
			max-width: 250px;
		}

		.Select__selected {
			border: none;
		}
	}

	.Select {
		overflow: hidden;
		box-shadow: none;
	}

	&__assignedEvery {
		margin-bottom: 4px;

		.Icon {
			margin-right: 4px;
		}
	}

	&__selectBlock {
		margin-bottom: 8px;

		> .Text {
			margin-bottom: 16px;
		} 
		
		.Select {
			margin-bottom: 8px;
		}

		.Label {
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 2px;
		}

		.Row {
			align-items: center;
			.Select {
				margin-bottom: 0px;
				flex-grow: 1
			}
		}
	}

	&__buttonRow {
		align-items: center;
		gap: 8px;
	}
}
