@import "../../../../styles/variables";

.FilesTabContent {
	text-align: center;

	&__NoFiles {
		font-size: 16px;
		color: $grey-10;

		img {
			margin: 22px auto 19px;
			text-align: center;
		}
	}

	.Table {
		&Row {
			background-color: white;
		}

		&Column {
			&__icon,
			&__remove {
				width: 30px;
			}
		}

		&Header {
			&__column {
				line-height: 14px;
			}
		}
	}

	&__dropzone {
		background-color: $white;
		border: 2px dashed $grey-8;
		border-radius: 2px;
		width: 336px;
		height: 179px;
		margin: 49px auto;
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		padding: 35px;
		flex-direction: column;
		color: $grey-10;

		&:focus {
			outline: none;
		}

		h4 {
			color: $black;
		}

		&--active {
			background-color: rgba(67, 179, 146, 0.1);
		}
	}
}
