@import "~StyleVariables";

.RecommendedLead {
	background-color: $white;
	transition: background-color ease-out 200ms;

	&:hover {
		background-color: $grey-1;
	}

	&__location {
		display: flex;
		align-items: center;
	}

	&__addedLeadIcon {
		font-size: 2rem;
		padding: 12px;
	}
}
