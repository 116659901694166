.EditSocialEventCopySvg g .first-paper {
	opacity: 0;
	animation: copy-social-event-slide-up 0.4s ease-in-out forwards;
	animation-delay: 0.4s;
}

.EditSocialEventCopySvg g .second-paper {
	opacity: 0;
	animation: copy-social-event-slide-right 0.8s ease-in-out forwards;
	animation-delay: 1s;
}

@keyframes copy-social-event-slide-right {
	0% {
		transform: translate(60px, 30px);
		opacity: 0;
	}
	75% {
		transform: translate(140px, 30px);
		opacity: 1;
	}
	100% {
		transform: translate(130px, 30px);
		opacity: 1;
	}
}
@keyframes copy-social-event-slide-up {
	0% {
		transform: translate(39px, 42px);
		opacity: 0;
	}
	75% {
		transform: translate(39px, 30px);
		opacity: 1;
	}
	100% {
		transform: translate(39px, 30px);
		opacity: 1;
	}
}
