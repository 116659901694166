@import "~StyleVariables";

.ReportcenterSidebar {
	width: 20vw;
	min-width: 240px;
	max-width: 320px;
	height: 100%;

	&__showHidden {
		&:hover {
			background-color: unset !important;
		}
		height: 34px !important;
		.Tooltip {
			div {
				font-size: 12px;
				color: $bright-blue;
				display: flex;
				align-items: center;

				.Icon {
					margin-right: 12px;
				}
			}
		}
	}
	&__main-title {
		height: 50px;

		.Title {
			padding-left: 20px;
		}

		.Column {
			align-self: center;
		}

		.Column:last-of-type {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}

	&__list {
		height: 95%;
		overflow-y: scroll;
		padding-bottom: 20px;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	&__list::-webkit-scrollbar {
		display: none;
	}

	&__loading-item {
		display: flex;
		align-items: center;
		padding: 0 15px;
		height: 30px;
	}

	&__loading-item {
		&:nth-child(1n) .Text--loading:before {
			content: "The Label";
		}
		&:nth-child(2n) .Text--loading:before {
			content: "The Dashboard to load";
		}
		&:nth-child(3n) .Text--loading:before {
			content: "The Dashboard";
		}
		&:nth-child(4n) .Text--loading:before {
			content: "The best Dashboard";
		}
	}

	.PageSidebarGroup {
		border-bottom: 1px solid $grey-2;

		&__expander {
			right: 12px;
		}

		&:first-of-type {
			border-top: 1px solid $grey-2;
		}

		&__title {
			.Text {
				font-weight: $boldFontWeight;
			}
		}
	}

	#rctv-toggle {
		padding: 0px 12px;
	}
}
