@import "~StyleVariables";

.EditForm {
	> .Tabs {
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		z-index: 1003;
	}
}
