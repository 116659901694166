.ToggleFiscalYearModal {
	display: flex;
	flex-direction: column;
	height: 100%;
	&__top {
		display: flex;
		align-items: center;
	}
	&__bottom {
		display: flex;
		justify-content: flex-end;
	}
}
