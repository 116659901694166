@import "~StyleVariables";
.GoalsTable {
	background: $white;
	width: 100%;
	margin-bottom: auto;
	overflow-y: auto;
	position: relative;

	th {
		position: sticky;
		z-index: 99;
		background-color: $white;
		top: 0;
	}

	&--scrolled {
		th {
			box-shadow: 0 3px 3px -3px rgba($black, 0.2);
			transition: box-shadow 0.2s ease-in-out;
		}
	}

	.TableHeader__column:nth-child(2) {
		padding-left: 25px;
	}
	&__user-col {
		min-width: 300px;
		white-space: nowrap;
		width: 1%;
	}
	&__user-col-content {
		> .Avatar {
			width: 28px;
			height: 28px;
			line-height: 28px;
			font-size: 28px;
			margin-right: 5px;
		}
		.Avatar--initials {
			line-height: 28px;
		}
		.Avatar--gravatar {
			width: 100%;
			height: 100%;
		}
		display: flex;
		align-items: center;
	}
	&__user-col-name {
		> .Text {
			display: inline;
		}
		display: flex;
		flex-direction: column;
	}

	&__ongoing-col {
		padding-left: 25px;
		.GoalsInput {
			max-width: fit-content;
		}
	}
	&__goal-row--dark {
		background: $grey-1;
	}
	&__goal-row--inactive {
		background: $grey-6;
	}
}
