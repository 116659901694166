@import "~StyleVariables";

.PdfGeneric {
	height: 230px;

	.Loader {
		margin: 0 auto;
	}

	.close-button {
		float: right;
	}

	.download,
	.email {
		margin-right: 10px;
		text-decoration: none;
		border: none;
		background: none;
		.fa {
			color: $blue;
			font-size: 16px;
		}
	}

	.up-btn.btn-grey {
		background-color: inherit;
		border: none;
		.Icon {
			color: $blue;
			position: relative;
			bottom: 5px;
		}
	}
	.options {
		button {
			background: none;
			border: none;
		}
		.fa {
			color: $blue;
			font-weight: $boldFontWeight;
		}
	}
	.page_count,
	.page_num {
		font-size: 12px;
	}

	.up-modal-content {
		text-align: center;
		height: calc(100% - 40px - 57px);

		background-color: $white !important;
		overflow-x: auto;
		overflow-y: auto;
		filter: alpha(opacity=0);
		-webkit-transition: opacity linear 0.2s !important;
		transition: opacity linear 0.2s;

		.error-card {
			top: 56px;
		}
	}

	.up-modal-controls {
		border-top: 1px solid #cad2dd;
		position: absolute;
		height: 57px;
		padding: 10px;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: right;
	}

	canvas {
		direction: ltr;
		margin-top: 0em;
	}
	.PreviewPdf__inner {
		margin: 0 auto;
		max-width: 770px;
	}

	.preview--modal--header {
		padding: 10px 10px;
		position: static;
		top: 0;
		left: 0;
		right: 0;
		display: block;
		background-clip: padding-box;
		z-index: 3;
		background: $white;
	}
}
