@import "~StyleVariables";

.EditSocialEventResourceCard {
	margin: 30px auto 0 auto;
	width: 680px;

	&--has-placeholder {
		min-height: 200px;
	}

	&__img-wrapper {
		display: flex;
		position: absolute !important;
		top: 0;
		right: 120px;
		height: 100%;
	}

	&__img {
		transform: translateX(50%);
		height: 100px;
		margin: auto;
	}

	.CardContent {
		padding-right: 270px;
	}

	.CardHeader__title {
		padding-left: 38px;
	}

	&:last-of-type {
		margin-bottom: 30px;
	}

	@media (max-width: 817px) {
		& {
			margin: 30px 30px 0 30px;
			width: initial;
		}
	}

	&__icon-thing {
		display: inline-block;
		width: 30px;
		height: 25px;
		line-height: 25px;
		border-radius: 3px;
		text-align: center;
		color: #fff;
		position: absolute !important;
		left: 15px;
		top: 15px;

		&:before {
			content: "";
			width: 6px;
			height: 6px;
			position: absolute;
			bottom: -3px;
			transform: rotate(45deg);
			left: 50%;
			margin-left: -3px;
		}

		&--purple,
		&--purple:before {
			background-color: $purple;
		}

		&--dark-purple,
		&--dark-purple:before {
			background-color: $dark-purple;
		}

		&--turcose,
		&--turcose:before {
			background-color: $medium-turcose;
		}

		&--medium-blue,
		&--medium-blue:before {
			background-color: $medium-blue;
		}
	}
}
