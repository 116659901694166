@import "~StyleVariables";

.WonOpportunityWithSubs {
	overflow-y: auto;
	background-color: $white;
	display: flex;
	justify-content: center;

	.CardParents {
		margin-bottom: 30px;

		.CardParent {
			border: 1px solid $grey-3;
		}
	}

	&__container {
		width: calc(min(80%, 850px));
		margin-top: 50px;
		display: flex;
		flex-direction: column;

		.Headline {
			margin-bottom: 4px;

			span {
				color: $medium-blue;
			}
		}
	}

	&__exit-button {
		position: absolute;
		right: 20px;
		top: 20px;
		font-size: 16px;
		padding: 8px;
		background-color: $grey-2;
		border-radius: 4px;
		transition: all 0.15s ease;
		cursor: pointer;

		&:hover {
			background-color: $grey-5;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		gap: 16px;

		margin-top: 16px;
		padding-bottom: 250px;

		.Icon {
			margin-right: 4px;
		}
	}
}
