.DesiredOutcomeSelect__header {
	display: flex;
	height: 135px;
}

.DesiredOutcomeSelect__header img {
	width: 180px;
}

.DesiredOutcomeSelect__header-text {
	flex: 1;
	max-width: 560px;
	display: flex;
	flex-direction: column;
	margin: 10px 10px 10px 50px;
	justify-content: space-between;
}

.DesiredOutcomeSelect__overflow-wrap {
	display: flex;
	align-items: center;
}

.DesiredOutcomeSelect__overflow-wrap .Text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.DesiredOutcomeSelect__header-text .btn-link {
	font-size: 16px;
}

.DesiredOutcomeSelect__grid {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(3, 220px);
	grid-auto-rows: 130px;
	grid-row-gap: 25px;
	grid-column-gap: 25px;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: 70px;
}

.DesiredOutcomeSelect .Button {
	max-width: 400px;
	margin: auto;
}
