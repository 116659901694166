@import "../../../styles/variables";

.BillingAddSeatDrawer {
	width: 400px;
	overflow: auto;

	.DrawerHeader {
		position: relative;
		z-index: 1000;
	}

	&__licence-input-col {
		align-self: center;

		.Block {
			width: 50px;
			text-align: center;
			height: 30px;

			.Text {
				line-height: 30px;
			}
		}
	}

	&__pay-now {
		margin-top: 10px;
	}

	&__add-on-row {
		.Column {
			align-self: center;
		}
	}

	&__confirm-block {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
	}

	&__content {
		padding-left: 16px;
		padding-right: 16px;
		&__totalSeats {
			line-height: 30px;
		}
	}

	&__confirm-card {
		margin: 16px;
		border-radius: $border-radius;
		border: 1px solid $grey-5;
		.Column {
			flex-basis: auto;
		}

		&__cta {
			display: flex;

			.Tooltip:first-of-type {
				width: stretch;
				.Button {
					width: 100%;
				}
			}
		}

		&__invoiceAddress {
			margin-top: 8px;
		}

		&__authorized {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			margin-top: 12px;
			margin-bottom: 12px;

			.Text {
				margin-left: 8px;
			}
		}

		&__requiredAsterisk {
			color: $red;
		}

		.CardContent {
			padding: 16px;
		}
	}

	&__authorizedCheckbox {
		&--highlightAuthorizedButton {
			border: 1px $red solid;
		}
	}

	&__expandable-text-left {
		margin: auto;
		margin-left: 16px;
	}
	&__expandable-text-right {
		margin: auto;
		margin-right: 16px;
		.Button {
			padding: 0;
		}
	}
}
