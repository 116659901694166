.StateTabs {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	overflow-x: auto;
}

.StateTabs .Tabs {
	overflow: unset;
}

.StateTabs .Tab {
	box-sizing: content-box;
	white-space: nowrap;
}
