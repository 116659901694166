@import "~StyleVariables";

.ExitConfirmDropdown {
    width: 370px;
    background-color: $white;
    position: absolute;
	z-index: 51;
    right: 10px;
    top: 10px;
    padding: 12px;
    border: 1px solid $grey-3;
    border-radius: 4px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 12px;

	.Icon {
		position: absolute;
		right: 17px;
		top: 17px;
		font-size: 16px;
		cursor: pointer;
		&:hover {
			font-weight: bold;
		}
	}

	&__buttons {
		display: flex;
		.Button {
			width: 100%;
		}
		
	}
}