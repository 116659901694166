.EmbeddCopyButton {
	width: 130px;
	position: absolute;
	bottom: 12px;
	right: 13.5px;

	.Icon {
		padding-right: 4px;
	}
}
