@import "~StyleVariables";

.TimelineMailCampaign {
	&__avatar {
		height: 15px;
		width: 15px;
		font-size: 15px;
		vertical-align: middle;

		.Avatar--initials {
			line-height: 15px;
		}

		.Avatar--gravatar {
			height: 15px;
			width: 15px;
		}
	}
	&__mail-group-table {
		width: 100%;

		th {
			border-bottom: 4px $grey-3 solid;
		}

		tr {
			&.mail-row {
				border-bottom: 1px solid $grey-3;
			}

			&.mail-row:last-child {
				border-bottom: 0;
			}
		}
	}
}
