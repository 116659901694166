@import "~StyleVariables";

.ExportContactDataModal {
	.ModalContent {
		.contact-wrapper {
			h4 {
				padding-top: 10px;
				padding-left: 40px;
				color: $grey-13;
				font-size: 14px;
				font-weight: $regularFontWeight;
				line-height: 17px;
			}
		}

		.export-info-wrapper {
			h3 {
				margin-top: 35px;
				margin-bottom: 0px;
				color: $grey-13;
				font-size: 16px;
				font-weight: $regularFontWeight;
				line-height: 24px;
			}

			.export-info-row {
				color: $grey-11;
				font-size: 14px;
				font-weight: $regularFontWeight;
				line-height: 26px;
			}

			.spinner-wrapper {
				display: flex;
				font-size: 18px;

				.fa-spinner {
					margin: auto;
				}
			}
		}
	}

	#export-data-printarea {
		display: none;
	}
}
