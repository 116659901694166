
.ListLeadsHeader{
    &__wrapper {
        display: flex;
        flex-direction: row;
    }

    &__secondButtonGroup {
        margin-left: 28px;
        margin-right: 4px;
    }
}