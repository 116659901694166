// FONT AWESOME
$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

//includes
@import "variables";
@import "mixins";
@import "responsive";
@import "animations";
@import "sub-page-navigation";
@import "ui-components";
@import "ui-components/input";
@import "ui-components/labels";
@import "ui-components/tooltip";
@import "ui-components/dropdowns";
@import "ui-components/typography";
@import "ui-components/headers";
@import "ui-components/info-section";
@import "ui-components/widget-table";
@import "ui-components/package-box";
@import "ui-components/devices/phone";
@import "ui-components/opacity";
@import "ui-components/form";
@import "ui-components/floating-label-input";
@import "ui-components/content-card";
@import "ui-components/placeholder";
@import "ui-components/editor-tools";
@import "ui-components/loader";
@import "ui-components/breadcrumbs";
@import "appointment-modal";
@import "modal";
@import "modal-components";
@import "login";
@import "navbar";
@import "main-menu";
@import "filters";
@import "sidebar";
@import "table-components";
@import "card";
@import "account";
@import "contact";
@import "widgets";
@import "popover";
@import "mail";
@import "mail-settings";
@import "find-prospects-card";
@import "salesboard-card";
@import "edit-order";
@import "advanced-search";
@import "timeline";
@import "form-editor";
@import "edit-activity";
@import "activities";
@import "looker";
@import "dividers";
@import "notification-dropdown";
@import "progress-bar";
@import "edit-columns";
@import "top-graph";
@import "attatchment-lib";
@import "engage";
@import "integrations";
@import "export-columns";
@import "upload-file";
@import "groupmail";
@import "template-editor";
@import "admin1";
@import "admin";
@import "list-view";
@import "esign";
@import "filter-sidebar";
@import "create-account";
@import "creative-editor";
@import "select-creative-template";
@import "file-browser";
@import "flash";
@import "find-prospects";
@import "landingpage-editor";
@import "form-dashboard";
@import "soliditet-matcher";
@import "experimental-feature";
@import "invite";
@import "voice";
@import "mail-campaign";
@import "onboarding";
@import "processedBy";
@import "segment";
@import "si-widgets";
@import "flow";
@import "mail-template-chooser";
@import "admin/add-product";
@import "form-select";
@import "account-profile";
@import "apps";
@import "action";
@import "ma-dashboard";
@import "opt-in";
@import "flag-icon";

// directive styles
@import "directives/upDatepicker";
@import "directives/upPlaceholder";
@import "directives/upInlineEdit";
@import "directives/upDrilldown";
@import "directives/upPaginator";
@import "directives/upToggle";
@import "directives/upInfo";
@import "directives/upLoadRow";
@import "directives/upErrorRow";
@import "directives/upOrderRow";
@import "directives/upAvatar";
@import "directives/upLeadStatus"; // Stage indicator for activities, opportunities & sales.
@import "directives/upComparisonTypeToggle";
@import "directives/upAvailabilityTable";
@import "directives/upStepInt";
@import "directives/upColorpicker";
@import "directives/upEsignProgress";
@import "directives/upPageSelect";

@-webkit-keyframes spaceboots {
	0% {
		-webkit-transform: translate(2px, 1px) rotate(0deg);
	}
	10% {
		-webkit-transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		-webkit-transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		-webkit-transform: translate(0px, 2px) rotate(0deg);
	}
	40% {
		-webkit-transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		-webkit-transform: translate(-1px, 1px) rotate(-1deg);
	}
	60% {
		-webkit-transform: translate(-3px, -2px) rotate(0deg);
	}
	70% {
		-webkit-transform: translate(2px, 1px) rotate(-1deg);
	}
	80% {
		-webkit-transform: translate(-1px, -2px) rotate(1deg);
	}
	90% {
		-webkit-transform: translate(2px, -1px) rotate(0deg);
	}
	100% {
		-webkit-transform: translate(1px, -2px) rotate(-1deg);
	}
}

@include keyframes(blur_animation) {
	0% {
		@include blur(1);
	}
	100% {
		@include blur(10);
	}
}

@keyframes rotateAnimation {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(180deg);
	}
}

@keyframes rotateBackAnimation {
	from {
		transform: rotate(180deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.pac-container {
	z-index: 10001;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
	-webkit-appearance: none;
	position: absolute;
	width: 1px;
	height: 1px;
	visibility: hidden;
	top: -1px;
	left: -1px;
	// display: none; doing this wold disable the scroll functionality
}

input[type="date"]::-webkit-calendar-picker-indicator {
	color: $white;
	position: relative;
	z-index: 0;
	pointer-events: none;
	cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator:before {
	background: $white;
	content: "\f073";
	font-family: FontAwesome;
	display: block;
	height: 22px;
	right: -10px;
	cursor: pointer;
	position: absolute;
	top: -1px;
	width: 22px;
	z-index: 1;
	color: $black;
}
input[type="date"]:hover::-webkit-calendar-picker-indicator {
	background: $white;
	// -webkit-animation-name: 'spaceboots';
	// -webkit-animation-duration: 0.5s;
	// -webkit-transform-origin:50% 50%;
	// -webkit-animation-iteration-count: 1;
	// -webkit-animation-timing-function: linear;
}

textarea {
	resize: none;
}

html,
body {
	min-height: 100%;
	// min-width: 1024px;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: $h-font-family;
}

[ng-click] {
	cursor: pointer;
}

.text-uppercase {
	text-transform: uppercase;
}
.no-click {
	cursor: default;
}

.font-weight-bold {
	font-weight: $boldFontWeight;
}

.font-weight-normal {
	font-weight: normal;
}

body {
	font-family: $main-font-family;
	font-weight: $regularFontWeight;
	background-color: $grey-5;
	padding: 15px;

	&.file-over-body {
		#main-view.view-container #main-content {
			.file-dropzone-card {
				#card-dropzone {
					bottom: 0;
					height: initial;
					visibility: visible;
					@include opacity(1);
				}
			}
		}
	}

	#blur-wrap {
		&.is-blurred {
			@include animation(blur_animation 200ms);
			@include blur(10);
		}
	}

	&.remove-frame {
		background-color: $white;
		color: $black;
	}

	// login
	@include login();

	// navbar
	//@include navbar();

	// main-menu
	@include main-menu();

	// sidebar
	@include sidebar();

	// animations
	@include animations();

	// filters
	@include filters();

	// popover
	@include popover();

	a {
		color: $link-color;
	}

	h2 {
		@include h2();
		font-size: 20px;
		display: inline-block;
	}

	#up-inline-curtain {
		position: fixed;
		display: none;
		z-index: 20000;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: transparent;
	}

	#main-wrapper {
		position: absolute;
		left: 50px;
		right: 0;
		bottom: 0;
		top: $main-top; //($navbar-height+$main-menu-height);
		// min-width: 1024px;

		#sidebar[open] + #main-view {
			margin-left: 165px;

			& #main-content {
				.page-head {
					left: $sidebar-width-expanded + 15px;
				}
			}
		}

		// card
		.main-card,
		#campaign-card,
		#order-card,
		#settings-card,
		#mail-card,
		#card,
		#admin-card,
		#find-prospects-card,
		#form-card,
		#groupmail-select-group,
		#form-editor {
			@include card($header-height);
		}

		#campaign-card {
			.page-head {
				flex-direction: column;
			}
		}

		&.no-sidebar {
			left: 0;

			.page-head,
			.up-header.fixed,
			.fixed-header-container .header-background,
			#advanced-search-wrapper #advanced-search-form-wrap {
				left: 15px !important;
			}

			.main-card,
			#campaign-card,
			#order-card,
			#settings-card,
			#mail-card,
			#card,
			#admin-card,
			#find-prospects-card,
			#form-card,
			#groupmail-select-group,
			#form-editor {
				left: 0;
			}

			#admin {
				left: 0;
				right: 0;
				bottom: 0;
				top: -15px;
				z-index: 1600;
			}
		}
	}

	// table component
	@include table-components();

	// fixed layout coverup fixed
	#coverup {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: $main-top;
		z-index: 1500;
		background-color: $grey-5;
	}

	&.no-submenu {
		#main-wrapper {
			top: $main-top - $sub-menu-height;
		}
		#coverup {
			height: $main-top - $sub-menu-height;
		}

		#sidebar {
			top: $main-top - $sub-menu-height;
		}

		#navbar.new-branding {
			height: 82px - $sub-menu-height;
		}

		#navbar.old-branding.future-nav {
			height: 82px - $sub-menu-height;
		}

		#sub-menu {
			display: none;
		}
		.up-header {
			&.fixed {
				top: $main-top - $sub-menu-height;
			}
		}

		#advanced-search-wrapper {
			#advanced-search-form-wrap {
				top: $main-top - $sub-menu-height;
			}

			&.minimized {
				#advanced-search-results-wrap {
					.header-background {
						top: 144px - $sub-menu-height;
					}

					.th-inner {
						position: fixed;
						top: 144px - $sub-menu-height;
					}
				}
			}
		}
	}

	&.remove-frame {
		$top: 82px;

		#advanced-search-wrapper {
			&.minimized {
				#advanced-search-results-wrap {
					.header-background {
						top: 144px - 15px;
					}

					.th-inner {
						position: fixed;
						top: 144px - 15px;
					}
				}
			}
		}

		#sidebar {
			background-color: $grey-1;
			top: $top;
			padding-top: 8px;
			border-right: 1px solid $grey-4;
			width: 48px;
		}

		.InsightsCard {
			right: 0px;
			left: 0px;
		}

		.AccountListWrapper {
			top: $top + 50px;
			right: 0;
			left: 347px;
		}

		.AccountList {
			margin-top: 90px;
		}

		.MarketingBoard {
			left: 48px;
			top: $top;
			right: 0;

			.MarketingBoardHeaders {
				top: 138px;
			}

			.CardHeader {
				top: $top;
			}
		}

		#coverup {
			display: none;
		}

		.EditSocialEvent {
			padding-bottom: 0;
			margin-bottom: 0;
			min-height: calc(100vh - #{$top});
		}
		.EditSocialEvent,
		.FormOverview {
			top: $top;
			left: 48px;
			right: 0;
			border-radius: 0;
		}
		.EditSocialEvent__Topbar {
			width: calc(100% - 48px);
			&.Edit {
				width: calc(100% - 346px);
			}
		}
		.FormOverview__MainView {
			margin: 0;
		}

		#main-wrapper {
			left: 48px;
			top: 82px;
			#main-view {
				margin: 0;

				#main-content {
					.page-head,
					.up-header.fixed {
						top: $top;
						left: 48px;
						right: 0;
					}
					#edit-segment-root #edit-segment-body .navigation-bar {
						left: 300px;
						right: 0;
					}
					#flow-editor:not(.fullscreen),
					#edit-flow-sidebar,
					#edit-segment-sidebar {
						top: $top;
						bottom: 0;
					}
					#flow-editor:not(.fullscreen) {
						left: 48px;
						right: 0;
					}
					#mail-campaign,
					.card.has-sidebar-sm.full-size {
						&:after {
							content: none;
						}
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
					}
					#account-card,
					#contact-card {
						min-height: calc(100vh - 81px - 50px);
					}

					.up-list-view {
						margin-right: 0 !important;
					}
					.page-head {
						border-radius: 0;
					}
				}
			}
		}

		.fixed-header-container .header-background {
			left: 48px;
			right: 0;
			top: 132px;
		}

		table.main-table.fixed-header {
			top: 66px;

			thead th {
				.th-inner {
					top: 132px;
				}
				&.column-tools .th-inner {
					right: 0 !important;
				}
			}
		}

		#up-list-filters #list-filters-wrap {
			top: $top;
			box-shadow: 0 0 10px rgba($black, 0.5);
		}

		#main-content.has-up-list-filters {
			@media (min-width: 1440px) {
				&.filters-open {
					table.main-table.fixed-header thead th.column-tools .th-inner,
					.fixed-header-container .header-background,
					.up-header.fixed,
					& {
						right: $up-filters-width + 12px !important;
					}
					.up-list-view {
						margin-right: $up-filters-width + 12px !important;
					}

					#list-filters-wrap {
						box-shadow: none !important;
					}
				}
			}
		}

		#admin {
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			#admin-root #admin-page-editscript:not(.fullscreen) {
				top: $top;
				right: 0;
				left: 278px;
				bottom: 0;
			}
			#admin-root
				#admin-page-users-roles
				#admin-content
				.admin-table
				.admin-table-body
				.admin-table-row.is-header#sticky-role-header {
				top: 46px;
			}
		}

		&.no-submenu {
			#advanced-search-wrapper {
				#advanced-search-form-wrap {
					top: $main-top - $sub-menu-height - 15px;
				}

				&.minimized {
					#advanced-search-results-wrap {
						.header-background {
							top: 144px - $sub-menu-height - 15px;
						}

						.th-inner {
							position: fixed;
							top: 144px - $sub-menu-height - 15px;
						}
					}
				}
			}
			.AppSidebar,
			#sidebar {
				top: $top - $sub-menu-height;
			}
			#main-wrapper {
				top: $top - $sub-menu-height;
				#main-view {
					#main-content {
						.up-header.fixed {
							top: $top - $sub-menu-height;
						}
					}
				}
			}
			#admin {
				#admin-root #admin-page-editscript:not(.fullscreen) {
					top: $top - $sub-menu-height;
				}
			}
		}
		#account-card {
			#address-card-wrap {
				position: fixed;
				top: 132px;
			}
			#card-main-view {
				#address-page {
					.no-result {
						top: 272px;
					}
				}
			}
			#map-wrapper {
				top: 272px;
			}
		}

		.FinancialTab {
			&__tabs {
				&__sticky {
					top: 132px;
				}
				&__filler {
					right: 0px;
					top: 132px;
				}
			}
		}

		.GoalsChangePeriod {
			left: 278px;
			width: calc(100% - 298px);
		}

		#admin #admin-root #admin-page {
			&-activity-quota,
			&-sales-quota {
				left: 278px;
				right: 0px;
				top: $main-top - $sub-menu-height - 15px;
			}
		}
	}
}

.nav-tabs {
	.fa-times {
		margin-left: 5px;
		font-size: 12px;

		&:hover {
			color: $red;
		}
	}
}

.datepicker {
	z-index: 20000;
}
.datepicker .btn-default {
	border: 0;
}

// chat button
.zopim {
	z-index: 1040 !important;
}

.escape-font-awesome {
	font-family: $main-font-family, sans-serif;
}

/* Styling for Select2 with error */
.ng-invalid.ng-dirty > .select2-choice {
	border-color: rgba($red, 1) !important;
}

.ng-invalid.ng-dirty.select2-container-active > .select2-choice {
	border-color: $dark-red;
	-webkit-box-shadow: inset 0 1px 1px rgba($black, 0.075), 0 0 6px $super-light-red;
	box-shadow: inset 0 1px 1px rgba($black, 0.075), 0 0 6px $super-light-red;
}

.isArchived {
	text-decoration: line-through;
}

.flex-container {
	@include flex-container();

	button {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.flex-grow-h {
	@include flex-item();
}

/*
    Legacy Firefox implementation treats all flex containers
    as inline-block elements.
*/

@-moz-document url-prefix() {
	.flex-container {
		width: 100%;
		-moz-box-sizing: border-box;
	}
}

// TODO temp fix for group button
.group-by {
	float: right;
	margin-right: -6px;
	padding-bottom: 4px;
	padding-top: 0px;
	margin-top: -8px;
}

.padding-10 {
	padding: 10px;
}
.padding-20 {
	padding: 20px;
}
.padding-5 {
	padding: 5px;
}
.no-padding {
	padding: 0;
}
.no-padding-top {
	padding-top: 0;
}
.no-padding-right {
	padding-right: 0;
}
.no-padding-bottom {
	padding-bottom: 0;
}
.no-padding-left {
	padding-left: 0;
}

.ellipsis-wrap {
	position: relative;
}

// Will only work if parent element is at least position relative
.ellipsis {
	position: absolute;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: top;
	line-height: normal;
}

.break-all {
	word-break: break-all;
}

.modal-font-size {
	font-size: $modal-font-size;
}

// Up date-icon to input-field
.form-group {
	.date-icon {
		.fa {
			position: absolute;
			top: 32px;
			left: 26px;
			color: $grey-10;
		}
		input[type="text"] {
			padding-left: 30px;
		}
	}

	.date-icon-with-label {
		.fa {
			position: absolute;
			top: 32px;
			left: 26px;
			color: $grey-10;
		}
		input[type="text"] {
			padding-left: 30px;
		}
	}

	.input {
		&.date-icon:before {
			content: "\f133";
			font-family: FontAwesome;
			left: 10px;
			position: absolute;
			height: 32px;
			top: 4px;
			width: 32px;
			z-index: 1;
			color: $grey-10;
			font-size: 14px;
		}

		&.date-icon-with-label:before {
			content: "\f133";
			font-family: FontAwesome;
			left: 26px;
			position: absolute;
			height: 32px;
			top: 28px;
			width: 32px;
			z-index: 1;
			color: $grey-10;
			font-size: 14px;
		}
	}
}

.input-group {
	.date-icon {
		.fa {
			position: absolute;
			top: 10px;
			left: 10px;
			color: $grey-10;
		}
		input[type="text"] {
			padding-left: 30px;
		}
	}
}

.standard-field-input {
	width: 100%;
	table-layout: fixed;

	.date-component-wrap {
		display: table-cell;

		input {
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom-left-radius: 0px;
			border-top-left-radius: 0px;
		}
	}
}

#mixpanel-notification-tagline {
	display: none !important;
}

#no-account-profile {
	margin: 50px auto;
	width: 400px;
	text-align: center;
	color: $grey-10;

	h2 {
		color: $blue;
		font-size: 24px;
		line-height: 40px;
	}

	p {
		margin: 20px 0;
		line-height: 22px;
	}

	#text-edit-btn-wrap {
		margin-top: 10px;
	}
}

#text-edit-package-box,
#empty-template-package-box {
	text-align: center;
	color: $bright-blue;
	padding: 30px;
	font-size: 12px;

	&:hover {
		cursor: pointer;

		h2 {
			text-decoration: underline;
		}
	}

	h2 {
		color: $bright-blue;
		font-size: 22px;
		margin-top: 30px;
		display: block;
	}

	.fa {
		font-size: 40px;
		display: block;
		margin-top: 30px;
		display: block;
	}
}

#sk-messenger-button {
	display: none;
}

#_elev_io._elevio_launcher {
	display: none;
}
.ctxUnderline {
	@include transition(all ease 300ms);
	&.fa {
		border-bottom: none !important;
		cursor: help;
	}
	&:hover {
		color: $bright-blue;
		border-color: $bright-blue;
	}
}

// Chrome added a default border-radius for all buttons in version 62
// This makes our buttons look bad if we assume there is no default br
button {
	border-radius: 0;
}

.colorpicker-self-contained .colorpicker-right.dropdown-menu {
	right: 0;
	left: initial;
}

// Override AppCues container styles
// https://upsales.jira.com/browse/MM-1070
appcues-container {
	z-index: 1111 !important;
}

.overflow-hidden {
	overflow: hidden;
}

.flag-icon {
	border-radius: 2px;
}
