@import "~StyleVariables";

.RelationRow {
	position: relative;
	height: 50px;
	cursor: pointer;
	transition: all ease-out 200ms;
	border-bottom: 1px solid $grey-4;

	&:hover {
		background-color: $grey-1;
	}

	.Column {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow: hidden;

		&:first-of-type {
			padding-left: 12px;
		}
	}

	.Text {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.Checkbox {
		.Icon {
			width: 100%;
		}
	}
}
