@import "~StyleVariables";

.EditTicketResponseTemplate {
	$header-height: 51px; // Header 50px + 1px border
	--modalContentWidth: 400px;

	&--edit {
		--modalContentWidth: 100%;

		.ModalContent {
			padding: 0;
			height: calc(100vh - #{$header-height});
		}
	}
	.TextEditor {
		width: 100%;
	}

	.ModalHeader {
		padding: 0;
	}

	.ModalHeader__content {
		padding: 0 0 0 $space * 5;
	}

	.Tabs {
		white-space: nowrap;
		min-width: fit-content;
	}

	&__controls {
		white-space: nowrap;
		margin-left: auto;
	}
}
