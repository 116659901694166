@import "~StyleVariables";

.FilterCopy {
	bottom: 0;
	width: stretch;
	box-sizing: border-box;
	height: 60px;
	background: linear-gradient(180deg, $white 0%, $grey-1 100%);
	box-shadow: 0 -2px 4px 0 rgba($black, 0.1);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px;
	z-index: 1;

	.fa-question-circle {
		font-size: 14px;
	}
}

.FilterCopy input {
	opacity: 0;
	position: absolute;
	left: -9999px;
	top: -9999px;
}

.FilterCopy .Button {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0;
}
