.ReportcenterFilterSearchbar {
	&__ExcludeColumn {
		flex: 0;
		white-space: nowrap;
	}
	
	&__ExcludeBlock {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}