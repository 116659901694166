@import "~StyleVariables";

$tabHeight: 50px;
$tabHeightHigh: 70px;

.StepTable__tab-wrapper {
	display: flex;
	position: sticky;
	top: 0;
	overflow: hidden;
	height: $tabHeight;
	box-shadow: 0 1px 2px rgba($black, 0.2);
	z-index: 1;
}

.StepTable--high .StepTable__tab-wrapper,
.StepTable--high .Tabs {
	height: $tabHeightHigh;
}

.StepTable__tabs {
	position: absolute;
	z-index: 1;
	padding-right: 30px;
	width: 100%;
	transition: all 200ms ease;
	right: 0;
}
.StepTable__tabs .Tab {
	max-width: 50%;
}

.StepTable__tabs--tabsHidden {
	right: 100%;
}

.StepTable__search {
	display: flex;
	width: 100%;
	height: 100%;
	background: $white;
}

.StepTable__search .Input {
	flex: 1;
	margin: auto 10px;
}

.StepTable__search .Button {
	margin: auto;
	z-index: 10;
}

.StepTable .TableColumn .Loader {
	margin: auto;
}

.StepTable .TableHeader__column .Block {
	padding-right: 15px;
	display: flex;
    justify-content: space-between;
    align-items: center;
}

.StepTable tbody .TableRow {
	background: $white;
}

.StepTable .TableWrapper {
	overflow: hidden auto;
	padding-bottom: 100px;
}
