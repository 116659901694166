@import "~StyleVariables";

.ReportcenterWidgetActiveFilters {
	&__filterIcon {
		height: 50px;
		display: flex;
		align-items: center;
		transition: all ease-out 200ms;

		&:hover {
			cursor: pointer;
			color: $green;
		}
	}

	&__filterTitle,
	&__noActiveFilters,
	&__dashboardFilter,
	&__widgetFilter,
	&__filterContainer {
		margin: 0 -12px;
		padding-left: 12px;
		padding-right: 12px;
		border-bottom: 1px solid $grey-3;
	}

	&__filterTitle {
		box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.1);

		&--dashboardFilter {
			margin-top: -6px;
		}
	}

	&__filterContainer {
		max-height: 220px;
		overflow-y: auto;
		border-bottom: none;
	}

	&__dashboardFilter,
	&__widgetFilter {
		display: flex;
		align-items: center;

		.Icon {
			width: 20px;
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
		}

		.Icon-code-fork {
			transform: rotate(180deg);
		}
	}

	&__widgetFilter {
		&--overridden {
			.Text {
				text-decoration: line-through;
			}
		}
	}
}
