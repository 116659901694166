@import "~StyleVariables";

.ReportcenterWidgetLibrarySearch {
    padding: 16px;
    background-color: inherit;
    z-index: 1002;
    top: 0;
    position: sticky;
    transition: box-shadow 500ms;

    .Input__input {
        box-shadow: none;
        &:hover {
            cursor: pointer;
        }
    }

    &--sidebarScrolled {
        box-shadow: $box-shadow-md;
    }
}