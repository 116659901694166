@import "~StyleVariables";

.CreateRelation {
	height: 100%;
	overflow: auto;
	width: 800px;
	background-color: $white;

	&__content {
		padding: $space * 10;

		&__card {
			height: auto;
			border: 1px solid $grey-4;
			cursor: pointer;
			transition: $transition-small-ease;

			&:hover {
				box-shadow: $box-shadow-lg;
				background-color: $grey-1;
			}

			&__cardText {
				width: 448px;
			}
		}
	}
}
