@import "~StyleVariables";

.Modal.AssignModal {
	@media (max-width: 1040px) {
		margin-left: 0;
		left: 20px;
		right: 20px;
		width: auto;
	}

	height: 85vh;
	width: 1000px;

	// Sidebar overflow is hidden - would cause double scrollbars
	overflow: hidden;
}

.AssignModal__content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 250px;
	right: 0;
	overflow: auto;
	overflow-x: hidden;
}
.measuring .AssignModal__content {
	position: relative;
	overflow: visible;
	overflow-x: visible;
}

.measuring .AssignModal__content-wrap {
	position: initial;
}

.AssignModal__close {
	position: absolute;
	top: 8px;
	right: 16px;
	z-index: 1001;
}

.AssignModal__loader {
	height: 300px;
	background-color: $grey-2;
}

.AssignModal__loader .Loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -50px 0 0 -50px;
}

.AssignModal__BlinkText {
	animation: blinker 500ms ease-in 2;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
