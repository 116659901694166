@import "~StyleVariables";

.NavbarAddMenu {
	position: relative;
	display: inline-block;

	&__trigger {
		$size: 30px;
		outline: none;
		border: none;
		border-radius: 50%;
		line-height: $size;
		height: $size;
		width: $size;
		text-align: center;
		padding: 0;
		margin: 0;
		box-shadow: none;
		transition: all ease-out 200ms;
		opacity: 0.5;

		.Icon {
			transition: all ease-out 200ms;
			font-size: 12px;
		}

		&--open,
		&:hover {
			opacity: 1;
			box-shadow: 0 3px 6px rgba($black, 0.3);
		}

		&--open .Icon {
			transform: rotate(45deg);
		}
	}

	&__item {
		transition: all ease-out 200ms;
		cursor: pointer;

		.Icon,
		.Text {
			color: $grey-12;
			transition: color ease-out 200ms;
		}

		&:hover {
			background-color: $grey-1;

			.Icon,
			.Text {
				color: $medium-blue;
			}
		}

		.Column {
			$height: 40px;
			height: $height;
			line-height: $height;

			.Icon,
			.Text {
				height: $height;
				line-height: $height;
			}

			.Icon {
				font-size: 16px;
			}
		}
	}

	&__hot-key {
		background-color: $grey-6;
		color: $white;
		height: 15px;
		line-height: 15px;
		font-size: 8px;
		text-transform: uppercase;
		display: inline-block;
		width: 12px;
		text-align: center;
	}
}
