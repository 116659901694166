@import "~StyleVariables";

.StagesDropdownMenu {
	&__dropdown {
		display: flex;
		flex-direction: column;
		white-space: nowrap;
		> .Text {
			padding: 16px;
		}
	}

	&__stage-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px;
		border-top: 1px solid $grey-3;
		cursor: pointer;
		&:hover {
			background-color: $grey-4;
		}
	}
}