@keyframes hide-scroll {
	from, to { overflow: hidden; } 
}

.AcceptContract {	
	.AcceptContract__header {
		.AssistChip, .Text {
			display: inline-flex;
		}
	}

	.Expandable__header {
		padding-left: 16px;		
		padding-right: 16px;		
	}

	.Expandable__content {
		overflow-y: hidden;
		padding: 0;
	}

	.Expandable__content--expanded {
		overflow-y: auto;
		animation: hide-scroll 300ms backwards;
	}

	.AcceptContract__content {
		padding: 16px;
	}

	p {
		white-space: pre-line;
	}
}
